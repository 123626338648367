import React from 'react'

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.10369 6.1455C3.29877 9.00618 3.29877 13.6442 6.10369 16.5049C8.90862 19.3656 13.4563 19.3656 16.2612 16.5049C19.0662 13.6442 19.0662 9.00618 16.2612 6.1455C13.4563 3.28483 8.90862 3.28483 6.10369 6.1455ZM7.2126 7.27645C9.40509 5.04037 12.9598 5.04037 15.1523 7.27645C17.3448 9.51252 17.3448 13.1379 15.1523 15.374C12.9598 17.6101 9.40509 17.6101 7.2126 15.374C5.0201 13.1379 5.0201 9.51252 7.2126 7.27645Z" fill="#696974" />
            <path d="M19.7562 18.6204L16.5182 15.3181C15.779 14.5641 14.6701 15.6951 15.4093 16.449L18.6473 19.7514C19.3866 20.5053 20.4955 19.3744 19.7562 18.6204Z" fill="#696974" />
        </svg>
    )
}

export default SearchIcon
