import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toINRFormat } from '../../helpers/helpers';
import AnalyticsSkeleton from './analytics-skeleton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';

const BookingEOI = ({ bookingLeads, loading, companyConfig, fromDate, toDate, selectedDate }) => {
    const [total, setTotal] = useState({
        booking: 0,
        eoi: 0
    })
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        const leadTotal = {
            booking: 0,
            eoi: 0
        }
        if (Array.isArray(bookingLeads) && bookingLeads.length > 0) {
            bookingLeads.forEach(lead => {
                if (lead.event === 'booking') {
                    leadTotal.booking += 1
                }
                if (lead.event === 'eoi') {
                    leadTotal.eoi += 1
                }
            })
            setTotal(leadTotal)
        }

        if (bookingLeads?.totalLeads === 0) {
            setTotal(leadTotal)
        }

    }, [bookingLeads])

    const exportData = () => {
        let temp = bookingLeads.map((lead, index) => {
            return {
                'User': lead.assigned_user || '',
                'Projects': lead.project_name || '',
                'Event': lead.event == 'eoi' ? 'Token' : 'Booking',
                'Date': moment(lead.schedule_date).format("h:mm a, Do MMM YYYY") || '',
                'Purchase Price / Token': `${lead.agreement_price ? toINRFormat(lead.agreement_price || 0) : toINRFormat(lead.amount || 0)}`,
            }
        })
        setTempData(temp);
    };

    return (
        <div className="table-card project-anlatic d-flex flex-fill flex-column booking-eoi-table">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className="row w-100 d-flex align-items-center flex-fill">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center">
                        <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Booking/Token Analysis</h2>
                        <div className="info-alert">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p>Token & Bookings completed by users for different projects for selected date range.</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Button className='bg-tr border-0' bsstyle="default">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="d-flex flex-row justify-content-md-end">
                            <p className='mb-0 fz14 fw-po-medium black-dark-800 '>Total Bookings : <span className='ro fw-po-bold'>{total.booking}</span></p>
                            <p className='mb-0 fz14 fw-po-medium black-dark-800  ml-24'>Total Tokens : <span className='ro fw-po-bold'>{total.eoi}</span></p>
                        </div>
                    </div>
                </div>
                {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                    <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                </button> */}
                <CSVLink
                    className={`!ml-3 flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1`}
                    data={tempData}
                    onClick={exportData}
                    filename={'booking_analysis.csv'}>
                    CSV <DownloadIcon />
                </CSVLink>
            </div>
            {bookingLeads?.length > 0 && <div className="scroll-table">
                <table className="table table-striped table-bg">
                    <thead>
                        <tr>
                            <th >LEAD</th>
                            <th style={{ width: '160px' }}>USERS</th>
                            <th style={{ width: '170px' }}>PROJECTS</th>
                            <th style={{ width: '170px' }}>EVENT</th>
                            <th style={{ width: '230px' }}>DATE</th>
                            <th style={{ width: '170px' }}>Purchase Price</th>
                        </tr>
                    </thead>
                    {!loading &&

                        <tbody>
                            {bookingLeads.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td> {lead.customer_name || '-'} </td>
                                        <td style={{ width: '160px' }}>{lead.assigned_user || '-'}</td>
                                        <td style={{ width: '170px' }}>{lead.project_name || '-'}</td>
                                        <td style={{ width: '170px' }} className={`${lead.event === 'eoi' ? 'text-uppercase' : 'text-capitalize'}`}>{lead.event || '-'}</td>
                                        <td style={{ width: '230px' }}>{moment(lead.schedule_date).format("h:mm a, Do MMM YYYY") || '-'}</td>
                                        <td style={{ width: '170px' }}>&#8377; {lead.agreement_price ? toINRFormat(lead.agreement_price || 0) : toINRFormat(lead.amount || 0)}</td>
                                    </tr>)
                            })}
                        </tbody>
                    }
                    {loading &&
                        <tbody>

                            {Array(7).fill().map((data, index) => {
                                return (
                                    <AnalyticsSkeleton key={String(index)} />
                                )
                            })}
                        </tbody>
                    }
                </table>
            </div>}
            {(!bookingLeads || bookingLeads?.totalLeads === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
            {/* {loading &&
                <div className="bg-white p-4">
                    {Array(7).fill().map((data, index) => {
                        return (
                            <AnalyticsSkeleton key={String(index)} />
                        )
                    })}
                </div>} */}
        </div>
    )
}

export default BookingEOI;