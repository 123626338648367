import React, { useEffect, useState } from 'react'

const EventProgess = ({ eventPublish }) => {
    const [barWidth, setBarWidth] = useState(0);

    useEffect(() => {
        if (barWidth === 100) {
            eventPublish();
        }
    }, [barWidth]);

    useEffect(() => {
        let value = 0;
        let interval = setInterval(function () {
            if (value <= 100) {
                value += 10;
                setBarWidth(value);
            } else {
                clearInterval(interval);
            }
        }, 50);

        return (() => clearInterval(interval))
    }, []);

    return (
        <div className='mt-10'>
            <div className='w-1/2 m-auto text-center' >
                <h1 className='text-4.5 font-semibold black leading-6'>Publishing Event</h1>
                <p className='text-sm text-black700 font-medium'>We're getting your event ready for Project Launchpad</p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-9">
                    <div className="bg-primary h-2.5 rounded-full" style={{ width: `${barWidth}%` }}></div>
                </div>
            </div>
        </div>
    )
}

export default EventProgess;