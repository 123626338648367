import React, { useContext, useEffect, useState } from 'react'
import ProjectHeader from './project-header';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Consumer from '../../helpers/context';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import AddConstructionUpdatesModal from './Modal/AddConstructionUpdatesModal';
import { getConstructionUpdate } from '../../services/private/inventory.service';
import { checkIsSomeItemAvailableInList } from '../../helpers/helpers';
import { ReactComponent as ResultSearchIcon } from '../../assets/icons/searchicon.svg';
import TooltipText from '../Tooltip';

const ConstructionUpdate = () => {

    const [showUpdatationModal, setShowUpdatationModal] = useState(false);
    const [constructionDoc, setConstructionDoc] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState({});

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');
    let { userProfile, allowedPermissions, isRolesModule } = useContext(Consumer);

    const getConstructionDocs = async () => {
        const res = await getConstructionUpdate(uuid);
        if (res.data.status === 200) {
            setConstructionDoc(res.data.data);
        }
    }

    useEffect(() => {
        if (uuid) {
            getConstructionDocs();
        }
    }, [uuid])

    const handleSubmit = async () => {
        // if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_pricing_module', 'edit_pricing_module'])) {
        //     history.push(`/projects/create-projects/pricing/${uuid}?pt=${property}&tt=${transaction}`);
        // } else
        if (checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead'])) {
            history.push(`/projects/create-projects/team-info/${uuid}?pt=${property}&tt=${transaction}`);
        } else {
            history.push(`/projects`);
        }
    }

    return (
        <div className={`${style.creatproject}`}>
            <ProjectHeader style={style} />
            <div className={`${style.creatproject} overflow-hidden `}>
                <div className='!px-6 !pt-6 h-full'>
                    <div className='flex flex-row items-center'>
                        <div className='w-6/12'>
                            <h2 className='inter text-textblack font-semibold text-xl mb-0'>Construction Updates</h2>
                        </div>
                        <div className='w-6/12'>
                            <div className='flex items-center gap-x-3 justify-end'>
                                <div>
                                    <button
                                        className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                        onClick={() => setShowUpdatationModal(true)}
                                    ><PlushIcon />Upload</button></div>

                            </div>
                        </div>
                    </div>
                    <div className='h-[55vh] overflow-auto'>
                        {
                            constructionDoc.map((doc, index) => (
                                <div className='border border-grayLight shadow-bottomshadow rounded-lg !px-3 !py-4 w-3/4 mx-auto !mt-5'>
                                    <div className='flex justify-end items-center'>
                                        {/* <h3 className='text-lg font-semibold'>Milestone 1 completed</h3> */}
                                        <div className='dropdown view-more'>
                                            <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <ThreedotsIcon />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a
                                                    className="dropdown-item text-capitalize"
                                                    onClick={() => {
                                                        setSelectedDocs(doc);
                                                        setShowUpdatationModal(true);
                                                    }}
                                                >
                                                    Edit Update
                                                </a>

                                                {/* <a
                                                    className="dropdown-item del-menu-itme text-capitalize"
                                                >
                                                    Delete Update
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{doc?.update_description}</p>

                                    <div className='grid grid-cols-3 gap-x-3'>
                                        {
                                            doc?.images?.length > 0 && doc?.images.map((docImg, imgIndex) => (

                                                <div className='!mb-7 !mt-9 rounded-lg'>
                                                    {['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'].includes(docImg.file_name.split('.').pop()) ? <div className=' rounded h-[210px] group relative'>
                                                        <img src={docImg.file_url} alt="" className='h-100 w-100 object-contain border rounded-lg' />
                                                        <a href={docImg.file_ur} download className='absolute right-1 top-1 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'>
                                                            <DownloadIcon />
                                                        </a>
                                                    </div>
                                                        :
                                                        <video width="100%" height="250" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                                            <source src={docImg.file_url} type="video/mp4" />
                                                        </video>
                                                    }
                                                    <p className='text-textblack truncate text-base font-semibold inter !mt-1'>
                                                        <TooltipText title={docImg.file_name}>
                                                            <span>{docImg.file_name}</span>
                                                        </TooltipText>
                                                    </p>
                                                </div>
                                            ))
                                        }


                                        {/* <div className='!mb-7 !mt-9 bg-black200'>
                                            <div className=''>
                                                <div className=' '>
                                                    <div className=' rounded h-[210px] group relative'>
                                                        <img src={''} alt="" />
                                                        <a href={''} download className='absolute right-1 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'>
                                                            <DownloadIcon />
                                                        </a>
                                                    </div>
                                                    <p className='text-textblack text-base font-semibold inter !mt-1'>Asdggre</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='!mb-7 !mt-9 bg-black200'>
                                            <div className=''>
                                                <div className=''>
                                                    <div className='rounded h-[210px] group relative'>
                                                        <img src={''} alt="" />
                                                        <a href={''} download className='absolute right-1 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'>
                                                            <DownloadIcon />
                                                        </a>
                                                    </div>
                                                    <p className='text-textblack text-base font-semibold inter !mt-1'>Asdggre</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                        {
                            constructionDoc.length === 0 && <div>
                                <div className='flex justify-center flex-column items-center h-100'>
                                    <figure><ResultSearchIcon /></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Documents Added</h2>
                                    <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p>
                                    <div className='flex items-center gap-x-3 justify-end'>
                                        <div>
                                            <button
                                                className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                                onClick={() => setShowUpdatationModal(true)}
                                            ><PlushIcon />Upload</button></div>
                                    </div>
                                    {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                    {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                    <button
                        className={`${style.cancelbtn}`}
                        onClick={() => {
                            if (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) {
                                history.push(`/projects/create-projects/broker-cp/${uuid}?pt=${property}&tt=${transaction}`);
                            } else {
                                history.push(`/projects/create-projects/pricing/${uuid}?pt=${property}&tt=${transaction}`)
                            }
                        }}
                    ><LeftIcon />Previous </button>
                    <button onClick={handleSubmit} className={`${style.savebtn}`}>Save</button>
                </div>
            </div>
            {
                showUpdatationModal && <AddConstructionUpdatesModal
                    show={showUpdatationModal}
                    uuid={uuid}
                    closeModal={() => setShowUpdatationModal(false)}
                    getConstructionDocs={getConstructionDocs}
                    selectedDocs={selectedDocs}
                />
            }
        </div>
    )
}

export default ConstructionUpdate;