import React, { useEffect, useState } from 'react';
import { ReactComponent as TextFileIcon } from '../../../../assets/icons/fileTextGrey.svg';
import SliderModal from "../../../../components/Modals/SliderModal";
import { getDeadLeadReEngagmentUsers, getUsersForSettings, saveDeadLeadReEngagmentUsers } from '../../../../services/private/company.service';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { getInitials } from '../../../../helpers/helpers';
import { toast } from 'react-toastify';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const HideShowLeadTimeline = ({ show, setShow, hideTimeline, setHideTimeline, handleUpdateStatusPermission, leadStatus, reAssignUser, setReAssignUser }) => {
    // const [hideTimeline, setHideTimeline] = useState(false);

    const [userList, setUserList] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        ...data,
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                setUserList(userData);
                setAllUsers(userData);
            }
        })
    }
    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllReengagedUsers = async () => {
        const res = await getDeadLeadReEngagmentUsers()
        if (res.data.status === 200) {
            const selectedData = allUsers.filter(u => res.data.data.user_id.includes(u.value))
            const filteredData = allUsers.filter(u => !res.data.data.user_id.includes(u.value))
            setUserList(filteredData)
            setAddedUsers(selectedData)
        }
    }

    useEffect(() => {
        if (allUsers.length > 0) {
            getAllReengagedUsers();
        }
    }, [allUsers]);


    const handleSubmit = async () => {
        const payload = addedUsers.map(u => u.id);
        const res = await saveDeadLeadReEngagmentUsers({ user_id: payload });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setShow(false);
        }
    }

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title={`Configure`}
            body={<>
                <div className="flex flex-col h-full overflow-hidden">
                    {/* <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto"> */}
                    <div className="h-full !pl-5 !pr-5 !pb-5 ">
                        <div className={`rounded-lg shadow-bottomshadow border mt-3`}>
                            <div className="flex p-3 justify-between items-start">
                                <div className="mr-2 w-10/12">
                                    <div className="flex text-base items-center font-semibold mb-2">
                                        <span className="mr-2">
                                            <TextFileIcon className="text-grey" />
                                        </span>
                                        <p className={`m-0 text-[#444444]`}>Hide Timeline</p>
                                    </div>
                                    <p className="text-black700">
                                        Converted <b> New lead</b> will have fresh timeline.
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className={`btn btn-lg btn-toggle ${hideTimeline ? 'active' : ''} mr-2`}
                                    value={hideTimeline}
                                    onClick={() => {
                                        setHideTimeline(!hideTimeline);
                                        handleUpdateStatusPermission(leadStatus, !hideTimeline, reAssignUser)
                                    }}
                                >
                                    <div className="handle"></div>
                                    <span className={`${hideTimeline ? 'on' : 'off'}`}>{hideTimeline ? 'YES' : 'NO'}</span>
                                </button>
                                {/* <input type="checkbox" checked={hideTimeline} value={hideTimeline} onChange={() => setHideTimeline(!hideTimeline)} disabled={!isAllLeadDead} /> */}
                            </div>
                        </div>
                        <div className={`rounded-lg shadow-bottomshadow border mt-3 p-3`}>
                            <div className="flex  justify-between items-start">
                                <div className="mr-2 w-10/12">
                                    <div className="flex text-base items-center font-semibold mb-2">
                                        <span className="mr-2">
                                            <TextFileIcon className="text-grey" />
                                        </span>
                                        <p className={`m-0 text-[#444444]`}>Re-assignment to Users</p>
                                    </div>
                                    <p className="text-black700">
                                        Dead Leads will be re-assigned to the Users selected.
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className={`btn btn-lg btn-toggle ${reAssignUser ? 'active' : ''} mr-2`}
                                    value={reAssignUser}
                                    onClick={() => {
                                        setReAssignUser(!reAssignUser);
                                        if (reAssignUser) {
                                            setAddedUsers([])
                                        }
                                        handleUpdateStatusPermission(leadStatus, hideTimeline, !reAssignUser)
                                    }}
                                >
                                    <div className="handle"></div>
                                    <span className={`${reAssignUser ? 'on' : 'off'}`}>{reAssignUser ? 'YES' : 'NO'}</span>
                                </button>

                            </div>
                            {reAssignUser && <div className='flex flex-col !gap-y-4'>
                                <InputSelect
                                    // label='Users'
                                    placeholder='Select User'
                                    value={''}
                                    onChange={(value) => {
                                        const projectIndex = addedUsers.findIndex(t => t.name === value.name);
                                        if (projectIndex === -1) {
                                            setAddedUsers([...addedUsers, value]);
                                        }
                                    }}
                                    styles={customStyles}
                                    options={userList}
                                />
                            </div>}

                        </div>
                        <div className='flex h-100 overflow-auto flex-column'>
                            {addedUsers.length > 0 &&
                                <div className='!mt-4'>
                                    <p className='text-base font-semibold'>Selected Users({addedUsers.length})</p>
                                    <div className='border-b'></div>
                                </div>
                            }
                            <div className='h-44 overflow-auto'>
                                {addedUsers.map((user, index) => (
                                    <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4 mt-2' key={user.email}>
                                        {/* <Link to='/settings/user-roles/teamDetails' className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
                                        <div className='flex justify-between gap-2'>
                                            <div className='flex'>
                                                <span className="flex items-center justify-center mx-2 border p-1 font-semibold rounded-full text-xl h-10 w-10 text-white bg-primary">
                                                    {getInitials(user?.name || '')}
                                                </span>
                                                <div className='leading-[26px] inter'>
                                                    <p className='m-0 text-black text-sm font-bold'>{user?.name}</p>
                                                    <p className='m-0 text-black700 text-sm'>{user?.role_name}</p>
                                                    <p className='m-0 text-black700 text-sm'>{user?.mobile}</p>
                                                    <p className='m-0 text-black700 text-sm'>{user?.email}</p>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <button className='text-red-btn' onClick={() => {
                                                    let newLists = [...addedUsers];
                                                    newLists.splice(index, 1);
                                                    setAddedUsers(newLists);
                                                }}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>

                                        {/* <div className='mt-2 flex gap-2 !flex-wrap'>
                            <div
                                className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'
                            >
                            </div>

                        </div> */}
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button
                            className="cancel outline-btn"
                            onClick={() => {
                                setShow(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="pr-btn"
                            onClick={handleSubmit}
                        >
                            Save

                        </button>

                    </div>
                </div>

            </>}
        />
    )
}

export default HideShowLeadTimeline