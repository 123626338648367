import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';


export async function insertSiteVisit(
    uuid,
    scheduleDate,
    remarks,
    followupScheduleDate,
    projectId,
    intentId,
    siteVisitType,
    meetLink,
    duration,
    duration_unit,
    confirm
) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.siteVisits.insert}/${uuid}`, null, {
        scheduleDate,
        remarks,
        followupScheduleDate,
        projectId,
        intentId,
        siteVisitType,
        meetLink: meetLink || null,
        sv_duration: duration || null,
        sv_duration_unit: duration_unit || null,
        is_confirmed: confirm === 'yes'
    });
}


export async function updateSiteVisit(uuid, scheduleDate, remarks, followupScheduleDate, projectId, intentId, siteVisitType, meetLink, duration, duration_unit, confirm) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.update}/${uuid}`, null, {
        scheduleDate,
        remarks,
        followupScheduleDate,
        projectId,
        intentId,
        siteVisitType,
        meetLink: meetLink || null,
        sv_duration: duration || null,
        sv_duration_unit: duration_unit || null,
        is_confirmed: confirm === 'yes'
    });
}

export async function siteVisiteComplete(uuid, completedTime, completedNotes, followupScheduleDate, projectIds, intentId, duration, duration_unit, siteVisitType) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.markCompletedByUUID}/${uuid}`, null, {
        completedTime,
        completedNotes,
        followupScheduleDate,
        projectIds,
        intentId,
        sv_duration: duration,
        sv_duration_unit: duration_unit,
        siteVisitType
    });
}

export async function siteVisiteCancelled(uuid, reason, remarks, followupScheduleDate, projectIds, intentId) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.markCancelledByUUID}/${uuid}`, null, {
        reason,
        remarks,
        followupScheduleDate,
        projectIds,
        intentId
    });
}