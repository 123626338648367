import React, { useEffect, useState } from 'react'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import InputSelect from '../../../../../components/InputGroup/InputSelect';
// import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success.svg';
import SuccessIcon from '../../../../../assets/icons/success.gif';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import { toast } from 'react-toastify';
import { addNewUser, editUser } from '../../../../../services/private/company.service';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg';
import TooltipText from '../../../../../components/Tooltip';
const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const InviteNewUser = ({ show, closeModal, uuid, roleName, getUsersByUUID, selectedItem, handleBackBtn }) => {
    const [isUserInvited, setisUserInvited] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [inviteLink, setInviteLink] = useState('');
    const [error, setError] = useState(false);


    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem)?.length > 0) {
            // setName(selectedItem.name);
            // setMobile(selectedItem.mobile);
            // setEmail(selectedItem.email);
        }
    }, [selectedItem])

    const handleSubmit = async () => {
        if (!name || !email || !mobile) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        try {
            const res = await addNewUser(uuid, { name, email, mobile })
            if (res.data.status === 200) {
                setInviteLink(res.data.data.invite_link)
                toast.success(res.data.message);
                getUsersByUUID()
                setisUserInvited(true);
            } else {
                console.log(res.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    const handleUpdateUser = async () => {
        const res = await editUser({ uuid: selectedItem.uuid, name, email, mobile, role: uuid })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUsersByUUID()
            closeModal()
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    <button onClick={handleBackBtn}>
                        <BackIcon />
                    </button>
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        {!selectedItem ? 'Invite New' : 'Edit'} User
                    </h1>
                </div>

                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <>
                {!isUserInvited &&
                    <>
                        <div className='p-3 space-y-3 h-full overflow-auto'>
                            <div>
                                <label htmlFor="" className={`block ${error && !name ? 'text-red' : ''}`}>User Name <span className="text-red">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Please Enter User Name"
                                    className="border py-2.5 px-3 rounded-md w-100"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {error && !name && <p className="text-red">Please Enter User Name</p>}
                            </div>
                            <div>
                                <label htmlFor="" className={`block ${error && !mobile ? 'text-red' : ''}`}>Mobile Number <span className="text-red">*</span></label>
                                <input
                                    type="text"
                                    placeholder="9999999999"
                                    className="border py-2.5 px-3 rounded-md w-100"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value
                                        .replace(/^0+/g, '')
                                        .replace(/[^0-9]/g, '')
                                        .substring(0, 10))}
                                />
                                {error && !mobile && <p className="text-red">Please Enter Email</p>}
                            </div>
                            <div>
                                <label htmlFor="" className={`block ${error && !email ? 'text-red' : ''}`}>Email <span className="text-red">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Please Enter Email"
                                    className="border py-2.5 px-3 rounded-md w-100"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {error && !email && <p className="text-red">Please Enter Email</p>}
                            </div>
                            <div>

                                <InputSelect
                                    label={'Role'}
                                    options={[]}
                                    disable={true}
                                    value={{ label: roleName, value: roleName }}
                                    styles={customStyles}
                                />
                            </div>

                        </div>
                        <div className={`border-top border-color w-full p-3`}>
                            <button
                                className='pr-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg'

                                onClick={() => {
                                    if (!selectedItem) {
                                        handleSubmit()
                                    } else {
                                        handleUpdateUser()
                                    }
                                }}>
                                {!selectedItem ? 'Invite' : 'Save'} User
                            </button>
                        </div>
                    </>
                }
                {
                    isUserInvited && <div className='p-3'>

                        <div className='flex flex-col items-center mt-9'>
                            <span>
                                {/* <SuccessIcon /> */}
                                <img src={SuccessIcon} className='h-16 w-16' alt="success" />
                            </span>
                            <p className='m-0 text-center'>
                                Signup Link Sent Successfully to: <br /><span>{email}</span>
                            </p>
                        </div>

                        <div className='border rounded-lg p-3 mt-[40px]'>
                            <p>
                                <span className='font-bold'>User Name:</span> <span className='text-primary'>{name}</span>
                            </p>
                            <p>
                                <span className='font-bold'>Email:</span> <span className='text-primary'>{email}</span>
                            </p>
                            <p>
                                <span className='font-bold'>Mobile Number:</span> <span className='text-primary'>{mobile}</span>
                            </p>

                            <div>
                                <label htmlFor="" className="block">Invite Link</label>
                                <TooltipText title={inviteLink || ''} >
                                    <div className='border rounded-md flex items-center p-2.5'>

                                        <LinkIcon />
                                        <span className='ml-2.5 w-100 truncate ' >
                                            {inviteLink}
                                        </span>
                                    </div>
                                </TooltipText>

                            </div>
                            <button className='p-2.5 border rounded mt-3 flex hover:bg-gray-100' onClick={() => {
                                navigator.clipboard.writeText(inviteLink).then(function () {
                                    toast.success('Invite link Copied');
                                }, function (err) {
                                    toast.error('Could not copy invite link');
                                });
                            }}>
                                Copy Invite Link
                                <CopyIcon className='ml-2' />
                            </button>
                        </div>
                    </div>
                }
            </>

        </RightModal>
    )
}

export default InviteNewUser