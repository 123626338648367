import { combineReducers } from "redux";
import loginReducer from "../Login/reducer";
import signinReducer from "../Signup/reducer";
import forgotReducer from "../Forgot Password/reducer";
import leadReducer from "../Lead/reducer";
import facebookIntegerationReducer from "../Integerations/Facebook Lead Gen/reducer";
import leadDetailReducer from "../Lead Details/reducer";
import { tagsReducer } from "../Lead Details/components/Tags/reducer";
import { notesReducer } from "../Lead Details/components/Create Note Sidebar/reducer";
import { followupReducer } from "../Lead Details/components/Create Followup Sidebar/reducer";
import { svReducer } from "../Lead Details/components/Create Site Visit Sidebar/reducer";
import { timelineReducer } from "../Lead Details/components/Recent Activities/reducer";
import signupFormReducer from "../Signup-Form/reducer";


export const mainReducer = combineReducers({
   login: loginReducer,
   lead: leadReducer,
   leadDetailReducer,
   tagsReducer,
   notesReducer,
   followupReducer,
   svReducer,
   timelineReducer,
   signin: signinReducer,
   forgotPassword: forgotReducer,
   fbIntegeration: facebookIntegerationReducer,
   signupFormReducer: signupFormReducer,
});
