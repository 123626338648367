import React from 'react'

const QotePaymentplan = ({ setSteps }) => {
    return (
        <>
            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <h2 class="inter text-textblack text-lg !mb-5 font-semibold">Payment Plan</h2>
                <div className='flex flex-col gap-5'>
                    <div className='!border border-grayLight rounded-lg !p-4 hover:!border-primary hover:bg-[#F9FAFD]'>
                        <div className='flex justify-between inter items-center !mb-6'>
                            <div className='flex !gap-2 items-center'>
                                <h3 className='text-base text-textblack font-semibold m-0'>Payment Plan 1</h3>
                                <h5 className='text-sm text-textblack font-normal m-0'>12 Milestones</h5>
                            </div>
                            <div className='flex items-center'>
                                <input type="radio" name="" id="" className='w-6 h-6' />
                            </div>
                        </div>
                        <div className='def-table plantable h-auto !overflow-auto !p-0'>
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th className='w-auto text-left !min-w-fit'>Milestones</th>
                                        <th>Description</th>
                                        <th>Due Date</th>
                                        <th>%</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}>Milestone 1</td>
                                        <td>Lorem ipsum dolor sit amet..</td>
                                        <td>07/06/2023</td>
                                        <td>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='!border border-grayLight rounded-lg !p-4 hover:!border-primary hover:bg-[#F9FAFD]'>
                        <div className='flex justify-between inter items-center !mb-6'>
                            <div className='flex !gap-2 items-center'>
                                <h3 className='text-base text-textblack font-semibold m-0'>Payment Plan 1</h3>
                                <h5 className='text-sm text-textblack font-normal m-0'>12 Milestones</h5>
                            </div>
                            <div className='flex items-center'>
                                <input type="radio" name="" id="" className='w-6 h-6' />
                            </div>
                        </div>
                        <div className='def-table plantable h-auto !overflow-auto !p-0'>
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th className='w-auto text-left !min-w-fit'>Milestones</th>
                                        <th>Description</th>
                                        <th>Due Date</th>
                                        <th>%</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}>Milestone 1</td>
                                        <td>Lorem ipsum dolor sit amet..</td>
                                        <td>07/06/2023</td>
                                        <td>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='!border-t border-b !border-grayLigh !pt-4 !pb-4 '>
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold">Grand Total</div>
                            <div className='sm:text-xl text-lg font-semibold leading-6 text-textblack text-right'>
                                <div>₹ 59,71,800</div>
                                <div className='sm:text-sm text-xs text-black700 font-normal'>For $Customer_Name</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center' onClick={() => setSteps('QotePricing')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center' onClick={() => setSteps('QoteTotal')}>Next</button>
                </div>
            </div>
        </>
    )
}

export default QotePaymentplan
