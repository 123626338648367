import React, { useContext, useEffect, useState } from "react";
import { getAllProjects } from "../../../../services/private/company.service";
import { getCustomerEnumsCall } from "../../../../services/private/customer.service";
import BasicInfoTab from "./Tabs/BasicInfoTab";
import CampaignInfoTab from "./Tabs/CampaignInfoTab";
import CustomerInfoTab from "./Tabs/CustomerInfoTab";
import OtherInfoTab from "./Tabs/OtherInfoTab";
import Consumer from '../../../../helpers/context';

export default function LeadDetailLeftBottom(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showBasicInfo, setShowBasicInfo] = useState(true);
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [showOtherInfo, setShowOtherInfo] = useState(false);
  const [showCampaignInfo, setShowCampaignInfo] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    enum_furnishing: [],
    enum_facing: [],
    enum_funding_source: [],
    enum_industry: [],
    enum_possession: [],
    enum_property_category: [],
    enum_property_type: [],
    enum_purpose: [],
    enum_transaction_type: [],
    enum_usage_type: []
  });

  const [projectList, setProjectList] = useState();

  const { companyConfig, allowedPermissions } = useContext(Consumer);

  const ROLE_NAME = localStorage.getItem('role');
  console.log('Jenkins build');

  const handelClick = () => {
    if (currentPage != 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    setProjectList(companyConfig.Project)
  }, [companyConfig])

  // const showTabs = () =>{
  //   if(showBasicInfo){
  //     return <BasicInfoTab currentPage={currentPage} show={showBasicInfo} />
  //   }else if(showCustomerInfo){
  //     return <CustomerInfoTab currentPage={1} show={showCustomerInfo}/>
  //   }
  // }

  // const getAllProjectsByCompanyId = async () => {
  //   await getAllProjects().then((res) => {
  //     if (res.status === 200) {
  //       const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
  //       setProjectList(projects);
  //     }
  //   })
  // }

  useEffect(() => {
    const load = async () => {
      // getAllProjectsByCompanyId()
      const res = await getCustomerEnumsCall()
      setCustomerInfo(res.data.data);
    }
    load();
  }, []);

  return (
    <>
      <div className="tabs mt-18">
        <div className="row">
          <div className="col-md-12">
            {" "}
            <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
              <li
                onClick={() => {
                  setShowBasicInfo(true);
                  setShowCustomerInfo(false);
                  setShowOtherInfo(false)
                  setShowCampaignInfo(false)
                }}
                className="nav-item"
              >
                <a
                  className="nav-link active"
                  id="basic-tab"
                  data-toggle="tab"
                  href="#basic"
                  role="tab"
                  aria-controls="basic"
                  aria-selected="true"
                  onClick={() => setCurrentPage(1)}
                >
                  Overview
                </a>
              </li>
              {/* <li className={`nav-item 'd-none'`} >
            <a className="nav-link" id="campaing-tab" data-toggle="tab" href="#campaing" role="tab" aria-controls="campaing" aria-selected="false">Campaign Info</a>
          </li> */}
              <li
                onClick={() => {
                  setShowCustomerInfo(true);
                  setShowBasicInfo(false);
                  setShowOtherInfo(false);
                  setShowCampaignInfo(false);
                }}
                className="nav-item"
              >
                <a
                  className="nav-link"
                  id="customer-tab"
                  data-toggle="tab"
                  href="#customer"
                  role="tab"
                  aria-controls="customer"
                  aria-selected="false"
                  onClick={() => setCurrentPage(1)}
                >
                  Customer Info
                </a>
              </li>
              <li
                onClick={() => {
                  setShowCustomerInfo(false);
                  setShowBasicInfo(false);
                  setShowOtherInfo(true);
                  setShowCampaignInfo(false);
                }}
                className="nav-item"
              >
                <a
                  className="nav-link"
                  id="other-tab"
                  data-toggle="tab"
                  href="#other"
                  role="tab"
                  aria-controls="customer"
                  aria-selected="false"
                  onClick={() => setCurrentPage(1)}
                >
                  Other Info
                </a>
              </li>
              {props.campaignInfo &&
                ((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('campaign_info')) || ROLE_NAME === 'ADMIN') &&
                <li
                  onClick={() => {
                    setShowCustomerInfo(false);
                    setShowBasicInfo(false);
                    setShowOtherInfo(false);
                    setShowCampaignInfo(true);
                  }}
                  className="nav-item"
                >
                  <a
                    className="nav-link"
                    id="campaign-tab"
                    data-toggle="tab"
                    href="#campaign"
                    role="tab"
                    aria-controls="customer"
                    aria-selected="false"
                    onClick={() => setCurrentPage(1)}
                  >
                    Campaign Info
                  </a>
                </li>}
            </ul>
          </div>
          <div className="col-md-4 d-flex flex-row align-items-center justify-content-end d-none">
            <div className="edit d-none">
              <button
                onClick={() => { }}
                className={`pr-hover border-0 bg-tr fz14  edit-details-active active-edit `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3725 5.96309L16.3944 4.94123L14.1421 2.68892L13.1202 3.71078L15.3725 5.96309ZM14.083 7.25263L11.8307 5.00032L3.40518 13.4258L2.8868 16.1965L5.65749 15.6781L14.083 7.25263ZM17.7384 3.70615C18.4205 4.38827 18.4205 5.49419 17.7384 6.17631L6.74743 17.1673C6.6174 17.2973 6.4511 17.385 6.27035 17.4188L1.91331 18.234C1.28327 18.3519 0.731447 17.8001 0.849326 17.17L1.66451 12.813C1.69833 12.6322 1.78601 12.4659 1.91604 12.3359L12.907 1.34492C13.5891 0.662799 14.6951 0.662799 15.3772 1.34492L17.7384 3.70615ZM17.3424 18.25H9.99093C8.78081 18.25 8.78081 16.4167 9.99093 16.4167H17.3424C18.5525 16.4167 18.5525 18.25 17.3424 18.25Z"
                    fill="#696974"
                  />
                </svg>{" "}
                Edit Fields
              </button>
            </div>
            <div className="next-prev d-flex align-items-center">
              {/* Previous page */}
              {/* <button
              onClick={handelClick}
              className="pr-hover border-0 bg-tr"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11.5"
                  fill="#F1F1F5"
                  stroke="#E2E2EA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z"
                  fill="#171725"
                />
              </svg>
            </button> */}
              {/* <div className="nex-prv-count fz14">
              <span className="ml-12 active">{currentPage}</span>/ <span>3</span>
            </div> */}
              {/* Next page */}
              {/* <button
              onClick={(e) => {
                if(currentPage < 3) {
                  setCurrentPage(currentPage + 1);
                }
              }}
              className="pr-hover border-0 bg-tr"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11.5"
                  fill="#F1F1F5"
                  stroke="#E2E2EA"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5858 12L9.29289 7.70711C8.90237 7.31658 8.90237 6.68342 9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071C8.90237 17.3166 8.90237 16.6834 9.29289 16.2929L13.5858 12Z"
                  fill="#171725"
                />
              </svg>
            </button> */}
            </div>
          </div>
        </div>
        <div className="tab-content">

          {showBasicInfo && <BasicInfoTab leadAccess={props.leadAccess} currentPage={currentPage} show={showBasicInfo} customerInfo={customerInfo} projectList={projectList} />}

          {showCustomerInfo && <CustomerInfoTab leadAccess={props.leadAccess} currentPage={currentPage} show={showCustomerInfo} customerInfoData={props.customerInfoData} customerInfo={customerInfo} />}
          {showOtherInfo && <OtherInfoTab leadAccess={props.leadAccess} currentPage={currentPage} show={showOtherInfo} customerInfoData={props.customerInfoData} customerInfo={customerInfo} />}
          {showCampaignInfo && <CampaignInfoTab leadAccess={props.leadAccess} currentPage={currentPage} show={showCampaignInfo} campaignInfo={props.campaignInfo} customerInfoData={props.customerInfoData} />}

          {/* {showTabs()} */}
        </div>
      </div>
    </>
  );
}
