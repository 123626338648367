import React, { useState } from 'react';
import { ReactComponent as DownICon } from '../../../assets/icons/Down.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/GrayMenu.svg';
import InputText from '../../InputGroup/InputText';
import InputSelect from '../../InputGroup/InputSelect';
import SearchInput from '../../InputGroup/SearchInput';



const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};
const QoteUnitDetail = ({ setSteps }) => {
    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [isHidden, setIsHidden] = useState(true);


    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const spanClassName = isSpanClassAdded ? 'rotate-180' : '';

    const toggleVisibility = () => {
        setIsHidden(!isHidden);
    };

    const divClassName = isHidden ? 'hidden' : '';

    return (
        <>
            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <div className='flex md:items-center md:flex-row flex-col md:gap-0 !gap-4'>
                    <div className='md:w-4/12'>
                        <h2 className='m-0 text-textblack text-lg font-semibold inter'>All Units</h2>
                    </div>
                    <div className='md:w-8/12 flex md:items-center items-start  gap-x-3 sm:flex-row flex-col sm:gap-y-0 gap-y-3'>
                        <div className='!border border-grayLight rounded-lg  py-2.5 !px-4 !bg-white inline-flex items-center sm:w-[218px] sm:basis-[218px] sm:grow-0 sm:shrink-0 w-full justify-between'>
                            <h4 className='mb-0 text-xs font-semibold text-black700 inter'>Show all Available</h4>
                            <label className="relative inline-flex items-center cursor-pointer m-0">
                                <input type="checkbox" value="" className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:!bg-primary"></div>
                            </label>

                        </div>
                        <div className='w-full'><SearchInput /></div>
                    </div>
                </div>
                <div className='!mt-4 '>
                    <div id="accordion" className='flex flex-col !gap-4'>
                        <div className="!border border-grayLight rounded-xl">
                            <div className="d-flex justify-between  !p-5" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={toggleSpanClass}>
                                <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] items-center inter">
                                    Floor 1
                                </h5>
                                <div className='flex items-center gap-x-3 '>
                                    <p className='m-0 text-black700 text-sm inter'>12/24 Available</p>
                                    <span className={spanClassName}><DownICon /></span>
                                </div>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">

                                <div className='!px-4 border border-grayLight !mx-4 rounded-lg !mb-4'>
                                    <div className=' flex sm:items-center justify-between !pt-4 !pb-4 sm:flex-row flex-col'>
                                        <div className='md:w-2/4'>
                                            <h3 className='inter text-base font-semibold text-black700 m-0'>A105</h3>
                                            {/* <div className='sm:hidden bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md !mt-2 text-center'>Available</div> */}
                                        </div>
                                        <div className='md:w-2/4 flex items-center sm:justify-end justify-between gap-x-3 sm:!mt-0 !mt-4'>
                                            <div className='bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md'>Available</div>
                                            <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium'>Block Unit</p>
                                                <input type="radio" name="" id="" className='sm:w-[22px] sm:h-[22px] w-5 h-5' />
                                            </div>
                                            <button><MenuIcon /></button>
                                        </div>
                                    </div>
                                    <div className='!border-t !border-grayLight flex sm:items-center justify-between sm:flex-row flex-col sm:gap-y-0 gap-y-4 m-0 text-textblack inter text-sm font-medium !pt-3 !pb-4'>
                                        <div className='inline-flex !gap-x-6 sm:flex-row flex-col sm:gap-y-0 !gap-4'>
                                            <div>
                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>A1289BH</h4>
                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Unit No.</p>
                                            </div>
                                            <div>
                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>1</h4>
                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Tower </p>
                                            </div>
                                            <div>
                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>11</h4>
                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Floor</p>
                                            </div>
                                            <div>
                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>1304 sq.ft</h4>
                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Area</p>
                                            </div>
                                            <div>
                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>₹ 17.74 Lac</h4>
                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Price</p>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={toggleVisibility} className='text-sm inter !text-primary mb-0 leading-5 font-medium text-right'>View Unit</button>

                                        </div>
                                    </div>


                                </div>
                                <div className={`${divClassName} !border !border-primary !px-4 !py-4 rounded-xl bg-[#F9FAFD] !mx-4 mb-4 `} >
                                    <div className=' flex items-center !pb-4'>
                                        <div className='w-2/4'>
                                            <h3 className='inter text-base font-semibold text-black700 m-0'>A105</h3>
                                        </div>
                                        <div className='w-2/4 flex items-center justify-end gap-x-3'>
                                            <div className='bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md'>Available</div>
                                            <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium whitespace-nowrap'>Block Unit</p>
                                                <input type="radio" name="" id="" className='sm:w-[22px] sm:h-[22px] w-5 h-5' />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='!mt-4'>
                                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                            <InputText
                                                label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputSelect
                                                label='Configuration'
                                                placeholder='2BHK (1273 sq.ft) '
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                            <InputText
                                                label="Building/Phase"
                                                placeholder="1"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputText
                                                label="Block/Wing"
                                                placeholder="A"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputText
                                                label="Floor"
                                                placeholder="6"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputText
                                                label="Unit Type"
                                                placeholder="2BHK"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputText
                                                label="No. of Units per Floor"
                                                placeholder="6"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                            <InputSelect
                                                label='Furnishing'
                                                placeholder='Semi Furnished'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                            <InputSelect
                                                label='Balcony'
                                                placeholder='2'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                            <InputSelect
                                                label='Car Parking'
                                                placeholder='1'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                            <InputSelect
                                                label='Facing'
                                                placeholder='North East'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                            <InputSelect
                                                label='View'
                                                placeholder='Pool View'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-4 flex justify-end'>
                                        <button onClick={toggleVisibility} className='text-sm font-medium !text-primary'>Hide Unit</button>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center' onClick={() => setSteps('CustomerDetails')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center' onClick={() => setSteps('QotePricing')}>Next</button>
                </div>
            </div>
        </>
    )
}

export default QoteUnitDetail
