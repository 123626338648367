import React, { useEffect, useState } from 'react'
import { getAllBanners, getAllRequests, updateBannerVisibilityByUUID } from '../../services/private/company.service';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Searchicon } from '../../assets/icons/searchicon.svg';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/hrdotes.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import { convertToTitleCase } from '../../helpers/helpers';
import TooltipText from '../../components/Tooltip';
import { toast } from 'react-toastify';

const BannerList = () => {
    const [callBookingList, setCallBookingList] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [bannerList, setBannerList] = useState([])

    const history = useHistory();

    const getBanners = async () => {
        const res = await getAllBanners();
        if (res.data.status === 200) {
            setBannerList(res.data.data)
        }
    }
    useEffect(() => {
        getBanners()
    }, []);

    const handleUpdateBanner = async (uuid, is_visible) => {
        const res = await updateBannerVisibilityByUUID(uuid, { is_visible })
        if (res.data.status === 200) {
            getBanners();
            toast.success(res.data.message);
        }
    }

    return (
        <div className="users w-100 h-full flex flex-col">
            <div className="!px-4 !pb-4">
                <div className="float-right">
                    <button
                        onClick={() => {
                            history.push('/cp-manager/banner/create')
                        }}
                        className=" pr-btn text-lg m-0 black fw-po-medium">+ New Banner</button>
                </div>
            </div>
            <div className="w-100 h-[70vh]">
                <>
                    < div className={`${style.cardcenterview}`}>
                        <div className={`${style.project_grid_layout}`} id='gridLayout'
                        >
                            {bannerList?.map((banner, index) => {
                                return (

                                    <div className={`${style.girdProjectCard}`}
                                        key={index}
                                    >
                                        <figure className='position-relative mb-16'>
                                            {/* {banner?.developer_created &&
                                                <div className={`${style.CardLaunchIcon}`}>
                                                    <LaunchIcon className='mr-8' />
                                                </div>
                                            } */}

                                            <div className={`${style.projectcardMenu} view-more dropdown projectcardMenu`}>
                                                <TooltipText title='View Option'>
                                                    <button className="bg-tr border-0 d-table ml-auto hover:bg-[#E5E9EB] cursor-pointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <ThreedotsIcon className='hover:bg-[#E5E9EB]' />
                                                    </button>
                                                </TooltipText>

                                                <div className="dropdown-menu dropdown-menu-right w-auto" aria-labelledby="dropdownMenuButton">
                                                    <a className="dropdown-item text-capitalize fz12 fw-po-medium lh-24" onClick={
                                                        () => {
                                                            history.push(`/cp-manager/banner/create/${banner.uuid}`)
                                                        }
                                                    }>Edit Banner</a>
                                                </div>
                                            </div>
                                            {banner?.web_image_url && <img src={banner?.web_image_url} alt="" className='w-100 !object-fill ' />}
                                        </figure>
                                        <div className='flex justify-end items-center !mb-2'>
                                            <button
                                                type="button"
                                                className={`btn btn-lg btn-toggle ${banner.is_visible ? 'active' : ''} mr-2`}
                                                value={banner.is_visible}
                                                onClick={() => {
                                                    handleUpdateBanner(banner.uuid, !banner.is_visible)
                                                }}
                                            >
                                                <div className="handle"></div>
                                                <span className={`${banner.is_visible ? 'on' : 'off'}`}>{banner.is_visible ? 'ON' : 'OFF'}</span>
                                            </button>
                                        </div>
                                        {banner?.title?.length > 30 ? <TooltipText title={banner?.title}>
                                            <h1 className='black inter fz18 fw-po-semi mb4 lh-22 text-capitalize w-[300px] truncate'>
                                                {banner?.title}
                                            </h1>
                                        </TooltipText> :
                                            <h1 className='black inter fz18 fw-po-semi mb4 lh-22 text-capitalize'>
                                                {banner?.title}
                                            </h1>
                                        }
                                        {/* <p className='fz12 mb4 lh-16 black-dark-600 inter text-capitalize'>
                                            {
                                                banner.configurations ?
                                                    getProjectAddress(banner)
                                                    : '-'
                                            }
                                        </p> */}
                                        {/* <p className='fz14 lh-24 mb-0 fw-po-semi text-black'>
                                            {
                                                getConfigPrice(banner?.configurations, banner)
                                            }
                                        </p> */}

                                        {/* <div className='border-top border-color mt-12 pt-8 fz14 black-dark-700 d-flex flex-column gap-4'>
                                                    <div className='d-flex juistify-content-between gap-4'>
                                                        <div className='w-50 fw-po-medium lh-16 text-capitalize'>{project.developer || '-'}</div>
                                                        <div className='w-50 text-right lh-16'>{project.property_type || '-'}</div>
                                                    </div>
                                                    <div className='d-flex juistify-content-between'>
                                                        <div className='w-50 lh-16'>{getConfigPrice(project?.configurations || [])}</div>
                                                        <div className='w-50 text-right lh-16'>{project?.possession_type === 'in_future' ? 'Under Construction' : convertToTitleCase(project?.possession_type || '-')}</div>
                                                    </div>

                                                </div > */}
                                    </div>
                                    /* </Link> */
                                )
                            })
                            }
                        </div >
                        {/* {
                            allProjects?.length > 6 ? <div className={`${style.loadMorediv}`}>
                                <button className={`${style.loadMore}`} onClick={handleLoadMore}> Load More</button>
                            </div> : ''
                        } */}

                    </div>
                    {bannerList?.length === 0 &&
                        <div className='d-flex justify-content-center flex-column items-center text-center h-100 w-100'>
                            <figure><Searchicon /></figure>
                            <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Banner Added</h2>
                            {/* <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add projects to see them here</p>
                                            <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}

                        </div>
                    }
                </>
            </div>
            {/* <UserLimitExeedModal show={false} /> */}

        </div>
    )
}

export default BannerList