import React, { useEffect, useState } from 'react';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg';
// import { getSvCompletedReadAccess, updateSvCompletedReadAccess } from '../../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { getSvCompletedReadAccess, updateSvCompletedReadAccess } from '../../../../services/private/company.service';

const SvAutomationCard = ({ getUserConfiData, userData }) => {
    const [svStatus, setSvStatus] = useState(false);

    useEffect(() => {
        setSvStatus(userData?.sv_completed_read_access)
    }, [userData]);

    const handleChangeStatus = async (value) => {
        const res = await updateSvCompletedReadAccess({ value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    return (
        <div className='!border border-grayLight hover:border-primary hover:!border-2 rounded-lg shadow-boxshadow !p-4 relative'>
            {/* <Link to={`/settings/user-roles/teamDetails/${team?.uuid}`} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
            <div className='flex justify-between items-center'>
                <div>
                    <LocationIcon />
                </div>
                <button
                    type="button"
                    className={`btn btn-lg btn-toggle ${svStatus ? 'active' : ''} mr-2`}
                    value={svStatus}
                    onClick={() => {
                        handleChangeStatus(!svStatus)
                    }}
                >
                    <div className="handle"></div>
                    <span className={`${svStatus ? 'on' : 'off'}`}>{svStatus ? 'ON' : 'OFF'}</span>
                </button>
            </div>
            <div className='flex flex-row justify-between gap-2 mt-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>SV Completed Access</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>
            <div className='!mt-2'>
                <p className='text-sm text-black700 inter'>After Site Visit is completed it will give view access to all previously lead shared user. </p>
            </div>
            <div className='mt-2 flex gap-2 !flex-wrap relative z-10'>
                {/* <div className='cursor-default p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack  leading-4'>Lead: {team?.leader_name}</div> */}
            </div>
        </div>
    )
}

export default SvAutomationCard;