import React from 'react'
import DateTimePicker from '../../InputGroup/DateTime'
import { ReactComponent as Greentriangle } from '../../../assets/icons/Greentriangle.svg';

const QoteTotal = ({ setSteps }) => {
    return (
        <>
            <div className='h-full flex shrink-1 grow-1 basis-full inter overflow-hidden'>
                <div className='h-full shrink-1 grow-1 basis-full inter overflow-auto'>
                    <div className='flex sm:flex-row flex-col w-100 h-full'>
                        <div className='bg-black100 sm:w-1/2 w-full sm:!pl-8 pl-4 !pt-8 flex flex-col'>
                            <div className='flex flex-col'>
                                <div className='!mb1 flex flex-row items-center gap-x-3'>
                                    <h3 className='m-0 text-2xl font-bold inter leading-9 text-textblack'>₹ 2,84,60,000</h3>
                                    <p className='mb-0 inrer text-sm font-normal text-black700'>₹ 2,84 per sqft.</p>
                                </div>
                                <p className='mb-0 inrer text-sm font-normal text-black700'>Total Sale Price </p>
                            </div>
                            <div className='h-100 overflow-auto !pr-6 sm:!mt-7 !mt-3 !pb-4'>
                                <div className='flex justify-between inter sticky top-0 bg-black100 !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                    <div className='w-1/2'>Amount Breakup</div>
                                    <div className='w-1/2 text-right'>#</div>
                                </div>
                                <div className='flex flex-col bg-white inter'>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-black700 font-normal'>Due Amount</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 84.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-black700 font-normal'>Amount Paid</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-textblack font-semibold'>Total Discount</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-textblack font-semibold'>Token Amt. discount</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-black700 font-normal'>Interest Paid</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack items-center'>
                                        <div className='w-1/2 text-black700 font-normal'>Outstanding with Interest</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>
                                    <div className='flex justify-between inter !border-b !border-grayLight !px-2 !py-2 text-base font-medium inter text-textblack'>
                                        <div className='w-1/2 text-black700 font-normal'>Pending at Finance</div>
                                        <div className='w-1/2 text-right font-semibold'>₹ 24.74 Lac</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='sm:w-1/2 w-full sm:!px-5 px-4 !py-8'>
                            <div>
                                <label htmlFor="" className='flex itms-center gap-x-2 !text-black font-semibold inter text-base'><Greentriangle />Quote Validity Till 24th Aug</label>
                                <DateTimePicker
                                    // label='Transaction Date'
                                    inputclass='mt-0'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div >
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center' onClick={() => setSteps('QotePaymentplan')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center' >Submit</button>
                </div>
            </div>
        </>
    )
}

export default QoteTotal
