import React, { useEffect, useState } from 'react'
import upcardimg from '../../assets/upcardimg.png'

import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { insertReraDocument, uploadFile, verifySeatReserveOTP } from '../../services/public/public.service'
import { ReactComponent as ModalCloseIcon } from '../../assets/RVClose.svg';
import { ReactComponent as SucessfulIcon } from '../../assets/sucessful.svg';
import { ReactComponent as ReAddressIon } from '../../assets/ReAddress.svg';
import { ReactComponent as ResidentalIcon } from '../../assets/Residental.svg';
import { ReactComponent as ReZoomIcon } from '../../assets/ReZoom.svg';
import InputText from '../../components/InputGroup/InputText'
import { ReactComponent as ReplaceIcon } from '../../assets/replace.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as InfoblueIcon } from '../../assets/infoblue.svg';
import UpcomingCard from './UpcomingCard'

const ReserveSeatSidebar = ({ style, close, show, selectedProject, handleEventRegistration, isReraVerified }) => {

    const [isRera, setIsRera] = useState('');
    const [reraDocumentUrl, setReraDocumentUrl] = useState('');
    const [reraNumber, setReraNumber] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');
    const [isError, setIsError] = useState(false);

    const handleOnChangeRera = async (e) => {
        const file = e.target.files[0];
        if (file.size / 1048576 > 5) {
            setFileSizeError('Size exceeds 5mb');
            return;
        }

        const formData = new FormData();
        formData.append("file", file)
        const res = await uploadFile(formData);
        if (res.status === 200) {
            setReraDocumentUrl(res.data.data.s3_url);
        }
    }
    const handleReraSubmit = async () => {
        if (!reraDocumentUrl || !reraNumber) {
            setIsError(true);
            return;
        } else {
            setIsError(false);
        }

        const payload = {
            state: selectedProject.state,
            rera: reraNumber,
            rera_document: reraDocumentUrl
        }

        const response = await insertReraDocument(payload);
        if (response.status === 200) {
            // setIsReraVerified(true);
            handleEventRegistration(selectedProject.uuid);
        }
    };

    return (
        <Modal
            show={show}
            onHide={close}
            backdrop="static"
            keyboard={false}
            className={`${style.reservsetmodal} reservsetmodal`}
        >
            <div className={`${style.ReserveSeatSidebar}`}>
                <div className={`${style.ReserveSeatHeader}`}>
                    <h2 className={`${style.heading}`}>{
                        selectedProject.registered_by ? 'Seat Reserved' : 'Reserve Seat'
                    }</h2>
                    <button className={`${style.close}`} onClick={close}><ModalCloseIcon /></button>
                </div>
                <div className={`${style.sidebarBody}`}>
                    {!isReraVerified && !selectedProject.registered_by &&
                        <>  <figure>
                            <img src={selectedProject.thumbnail} alt="" />
                            <div className={`${style.figContent}`}>
                                <h3>{selectedProject.event_name}</h3>
                                <p>{selectedProject.location}</p>
                            </div>
                        </figure>
                            <div>
                                <h6>Do you have Rera for {selectedProject.city} ({selectedProject.state})?</h6>
                                <div className={`${style.changerara}`}>
                                    <button onClick={() => setIsRera('No')} className={`${isRera === 'No' ? `${style.active}` : ''}`}>No</button>
                                    <button onClick={() => setIsRera('Yes')} className={`${isRera === 'Yes' ? `${style.active}` : ''}`}>Yes</button>
                                </div>

                                {isRera === 'Yes' && <>
                                    <InputText
                                        label='RERA No.'
                                        required={true}
                                        value={reraNumber}
                                        placeholder={'e.g. AABB000665'}
                                        onChange={(e) => setReraNumber(e.target.value)}
                                        error={isError && !reraNumber ? 'Please enter RERA number.' : ''}
                                    />
                                    <div className={`${style.kycfom} kycfom`}>
                                        <div className={`form-group ${(isError || fileSizeError) ? 'invalid' : ''}`} >
                                            <label className='d-inline-flex justify-content-between w-100'>
                                                <span>RERA Document<sup>*</sup></span> <span>Max: 5MB</span>
                                            </label>
                                            <input
                                                type="file"
                                                className='d-none'
                                                id='upload-rera'
                                                onChange={handleOnChangeRera}
                                            />
                                            <div className={`${style.uploaddoc} uploaddoc`}>
                                                <figure className='mb-0'><img src={reraDocumentUrl} alt="" />
                                                    <div className='layer'></div>
                                                </figure>
                                                {!reraDocumentUrl && <button
                                                    className={`${style.uploadbtn}`}
                                                    onClick={() => document.getElementById('upload-rera').click()} >
                                                    + Upload
                                                </button>}
                                                {reraDocumentUrl &&
                                                    <div className={`${style.imageupalodbtngrp} d-flex align-items-center `}>
                                                        <button
                                                            className={`${style.replacebtn} pr-btn`}
                                                            onClick={() => document.getElementById('upload-rera').click()}
                                                        >
                                                            <ReplaceIcon />
                                                            Replace
                                                        </button>
                                                        <button
                                                            className={`${style.removebtn}`}
                                                            onClick={() => setReraDocumentUrl('')}
                                                        >
                                                            <TrashIcon />
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                        {fileSizeError && <div className='invalid'>
                                            <div className="input-feedback mt-8">{fileSizeError}</div>
                                        </div>}
                                        {isError && <div className='invalid'>
                                            <div className="input-feedback mt-8">Please upload RERA document</div>
                                        </div>}
                                    </div>
                                </>}
                                <div className={`${style.rerainfo}`}>
                                    <div><InfoblueIcon /></div>
                                    <p>This is a one time process for registering any event in {selectedProject.city}. </p>
                                </div>
                            </div>
                        </>}

                    {/* sucessfull block */}
                    {(isReraVerified || selectedProject.registered_by) && <div className={`${style.sucessufll} `}>

                        <div> {isReraVerified && <>
                            <div className='d-table mx-auto'><SucessfulIcon /></div>
                            <p className={`${style.sucesmg}`}>Seat Reserved Successfully</p>
                        </>
                        }
                            <UpcomingCard style={style} item={selectedProject} />
                        </div>
                        <div className={`${style.rerainfo} mb-1`}>
                            <div><InfoblueIcon /></div>
                            <p>Your seat has been reserved, Zoom link will be shared 2 hours before the event.</p>
                        </div>
                    </div>}
                </div>
                {(!isReraVerified && !selectedProject.registered_by) && <div className={`${style.sidebarfooter}`}>
                    <button className={`${style.cancbtn} outline-btn`} onClick={close}>Cancel</button>
                    <button className={`${style.sbtbtn} pr-btn ${!isRera ? 'disablebtn' : ''}`} onClick={() => {
                        if (isRera === 'No') {
                            handleEventRegistration(selectedProject.uuid);
                        } else {
                            handleReraSubmit();
                        }
                    }}>Submit</button>
                </div>}
                {(isReraVerified || selectedProject.registered_by) && <div className={`${style.sidebarfooter}`}>
                    <button onClick={close} className={`${style.sbtbtn} pr-btn w-100 text-center flex-fill`}>Got it</button>
                </div>}
            </div >
        </Modal>
    )
}

export default ReserveSeatSidebar
