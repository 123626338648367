import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import kycImage from '../../assets/kyc.png'
import { getCities } from '../../services/private/csv.service';
import { updateInterests } from '../../services/public/public.service';
import { getCustomerEnumsCall } from '../../services/private/customer.service';
import AsyncSelect from 'react-select/async';
import InputSelect from '../../components/InputGroup/InputSelect';
import { property_type } from '../../utils/constants';
import { Oval } from 'react-loader-spinner';

const GST_REGEX = /^[a-z0-9]+$/i;

const KYCModal = ({ style, show, close, getKycStatus, handleUpdateFilter }) => {
    const [steps, setSteps] = useState(1)
    const [city, setCity] = useState([]);
    const [propertyType, setPropertyType] = useState([]);
    const [propertyList, setPropertyList] = useState(property_type);
    const [isError, setIsError] = useState(false);
    const [preferenceSaved, setPreferenceSaved] = useState(false);

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // useEffect(() => {
    //     const load = async () => {
    //         const res = await getCustomerEnumsCall()
    //         setPropertyList(res.data.data.enum_property_category);
    //     }
    //     load();
    // }, [])

    const handleSubmitIntrest = async () => {
        if (city.length === 0 || propertyType.length === 0) {
            setIsError(true);
            return;
        } else {
            setIsError(false);
        }
        const payload = {
            operational_cities: city.map(item => item.value),
            interested_property_type: propertyType.map(item => item.label)
        }
        const res = await updateInterests(payload);
        if (res.status === 200) {
            // getKycStatus();            
            if (city?.length > 0) localStorage.setItem('city', city.map(item => item.value));
            if (propertyType?.length > 0) localStorage.setItem('propertyType', propertyType.map(item => item.label));
            setPreferenceSaved(true)
            handleUpdateFilter(city, propertyType)
            setTimeout(() => {
                close();
                setPreferenceSaved(false);
            }, 2000)
        }
    }


    return (
        <Modal
            show={show}
            onHide={close}
            backdrop="static"
            keyboard={false}
            className={`${style.KYCModal} KYCModal`}
            backdropClassName={'kycbackdrop'}
            centered
        >
            {steps === 1 && <div>
                <figure className='mb-0' style={{ margin: '0 20px' }}><img src={kycImage} alt="" className='w-100' /></figure>
                <h1 className={`${style.heading}`}>Welcome To Project Launchpad </h1>
                <p className={`${style.subheading} d-table mx-auto text-center`}>A single comprehensive platform for brokers and developers
                    <span className='d-md-block'>to connect and access project information</span></p>
                <button className={`${style.kycModbtn} pr-btn d-table mx-auto`} onClick={() => setSteps(2)}>Next</button>

            </div>
            }

            {steps === 2 &&
                <> {!preferenceSaved ? <>
                    <div className={`${style.kyctitle}`}>
                        <h3 className=''>What Projects are you looking for?</h3>
                        <p>This following information will help us create a tailored custom project home page for you</p>
                    </div>
                    <div className={`${style.kycformgroup}`}>
                        <div className={`${style.kycfom} kycfom`}>
                            <div className="form-group">
                                <label>Property Type <sup style={{ color: 'red' }}>*</sup></label>
                                <InputSelect
                                    isMulti={true}
                                    options={propertyList}
                                    placeholder='Select Property Type'
                                    onChange={(value) => setPropertyType(value)}
                                    error={isError && propertyType.length === 0 ? 'Please select Property type' : ''}
                                />
                            </div>
                            <div className={`form-group ${isError && city.length === 0 ? 'invalid' : ''}`}>
                                <label>City of Operation <sup style={{ color: 'red' }}>*</sup></label>
                                <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    defaultOptions
                                    placeholder='Select City'
                                    onChange={(e) => {
                                        setCity(e)
                                    }}
                                />
                                {isError && city.length === 0 && (
                                    <div className="input-feedback mt-8">
                                        Please select operational city
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={`${style.kycfooter}`}>
                        <button
                            className={`${style.kycModbtn} pr-btn d-table mx-auto`}
                            onClick={handleSubmitIntrest}
                        >
                            Customize Home Page
                        </button>
                    </div>
                </> :
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <Oval
                            height={50}
                            width={50}
                            color="#0062FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#0062FF"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                        />
                    </div>
                }
                </>
            }
            <ul className={`${style.navigator}`}>
                <li className={`${steps === 1 ? style.active : ''} `}></li>
                <li className={`${steps === 2 ? style.active : ''} `}></li>
            </ul>
        </Modal >
    )
}

export default KYCModal
