import React, { useContext } from 'react';
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import MultiLevelDropdown from './multi-level-dropdown';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowbackIcon } from '../../assets/icons/Pageback.svg';
import '../../modules/Settings/Projects/style.module.css';
import { propertyTypeEnum, transactionTypeEnum } from '../../helpers/filtersEnum';
import AreYouSureModal from '../Modals/AreYouSureModal';
import Consumer from '../../helpers/context';
import { checkIsSomeItemAvailableInList } from '../../helpers/helpers';
import TooltipText from '../../components/Tooltip';

const isCurrentPath = (fullPath, currentPage) => fullPath.split('/').includes(currentPage);

const ProjectHeader = ({ style }) => {
    const [propertyType, setPropertyType] = useState();
    const [transactionType, setTransactionType] = useState();
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [projectPropertyChange, setProjectPropertyChange] = useState(false);
    const [urlParams, setURLParams] = useState({});

    const { uuid } = useParams();
    const { pathname, search } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);


    let { allowedPermissions, userProfile } = useContext(Consumer);

    // project status -> new or edit
    const projectStatus = localStorage.getItem('projectStatus');
    const projectName = localStorage.getItem('projectName');

    const handleChangeProjectType = (property, transaction) => {
        if (property && transaction) {
            setURLParams({
                property, transaction
            })
            setProjectPropertyChange(true);
            setShowCloseConfirmationModal(true);
        }
    };

    useEffect(() => {
        if (params.get('pt') && params.get('tt')) {
            setPropertyType(params.get('pt'));
            setTransactionType(params.get('tt'));
        }
    }, [params.get('pt'), params.get('tt')]);

    const handleCloseConfirmModal = () => {
        localStorage.removeItem('project_state')
        if (projectPropertyChange) {
            setPropertyType(urlParams?.property);
            setTransactionType(urlParams?.transaction);
            history.replace({ search: (new URLSearchParams({ pt: urlParams?.property, tt: urlParams?.transaction })).toString() });
        } else {
            history.push(redirectURL)
        }
        setShowCloseConfirmationModal(false);
    }

    return (
        <div className='pl-24 pl-sm-12'>
            <div className={`${style.proejctheading} proejctheading mt-16 mb-24 d-flex align-items-lg-center gap-24 flex-lg-nowrap flex-wrap flex-lg-row flex-column`}>
                <h1 className='fz24 black inter fw-po-medium mb-0 d-flex align-items-center '>
                    <a
                        onClick={() => {
                            if (localStorage.getItem('project_state') === 'create') {
                                setRedirectURL('/projects');
                                setShowCloseConfirmationModal(true);
                                return
                            }
                            history.push('/projects')
                        }}
                        className={`${style.backbtn}`}>
                        <ArrowbackIcon />
                    </a>
                    <p className={`${style.msingleline} flex m-0`}>
                        {projectStatus === 'new' ? 'Create New Project' : 'Edit Project'}
                        {projectStatus !== 'new' && <TooltipText title={projectName || ''}>
                            <p className='max-w-[300px] truncate m-0'> ({projectName})</p>
                        </TooltipText>
                        }
                    </p>

                </h1>
                <div className={`${style.creatdropdownmenu}`}>
                    <MultiLevelDropdown
                        handleChangeProjectType={handleChangeProjectType}
                        propertyType={propertyType}
                        transactionType={transactionType}
                        style={style}
                        MenuClassname={`createprojectmenu `}
                        childPosition='right'
                    />
                </div>
            </div>
            <div className={`${style.createprojecttabs} d-flex flex-row align-items-start `}>

                {
                    uuid ?
                        <>
                            <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/basic-details/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/basic-details/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'basic-details') ? style.active : ''}`}
                            >
                                Basic Details
                            </a>

                            <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/mediaupload/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'mediaupload') ? style.active : ''}`}
                            >
                                Media Upload
                            </a>
                            <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/additional-info/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/additional-info/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'additional-info') ? style.active : ''}`}
                            >
                                Additional Info
                            </a>

                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan']) && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/payment-plan/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/payment-plan/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'payment-plan') ? style.active : ''}`}
                            >
                                Payment Plans/Milestones
                            </a>}
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_pricing_module', 'edit_pricing_module']) && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/pricing/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/pricing/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'pricing') ? style.active : ''}`}
                            >
                                Pricing
                            </a>}
                            {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/broker-cp/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/broker-cp/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'broker-cp') ? style.active : ''}`}
                            >
                                Broker/CP
                            </a>}
                            {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/seo-info/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/seo-info/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'seo-info') ? style.active : ''}`}
                            >
                                SEO Info
                            </a>}
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan']) && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/construction-updates/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/construction-updates/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'construction-updates') ? style.active : ''}`}
                            >
                                Construction Update
                            </a>}


                            {checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead']) && <a
                                onClick={() => {
                                    if (localStorage.getItem('project_state') === 'create') {
                                        setRedirectURL(`/projects/create-projects/team-info/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                        setShowCloseConfirmationModal(true);
                                        return
                                    }
                                    history.push(`/projects/create-projects/team-info/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                }}
                                className={`${isCurrentPath(pathname, 'team-info') ? style.active : ''}`}
                            >
                                Team Info
                            </a>}
                            {userProfile?.allowed_modules?.includes('teams_module') &&
                                <a
                                    onClick={() => {
                                        if (localStorage.getItem('project_state') === 'create') {
                                            setRedirectURL(`/projects/create-projects/team/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                                            setShowCloseConfirmationModal(true);
                                            return;
                                        }
                                        history.push(`/projects/create-projects/team/${uuid}?pt=${propertyType}&tt=${transactionType}`)
                                    }}
                                    className={`${isCurrentPath(pathname, 'team') ? style.active : ''}`}
                                >
                                    Teams
                                </a>}
                        </>
                        :
                        <>
                            <a className={`${isCurrentPath(pathname, 'basic-details') ? style.active : ''}`}  >Basic Details</a>
                            <a className={`${isCurrentPath(pathname, 'mediaupload') ? style.active : ''}`} >Media Upload</a>
                            <a className={`${isCurrentPath(pathname, 'additional-info') ? style.active : ''}`} >Additional Info</a>
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan']) && <a className={`${isCurrentPath(pathname, 'payment-plan') ? style.active : ''}`} > Payment Plans/Milestones</a>}
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_pricing_module', 'edit_pricing_module']) && <a className={`${isCurrentPath(pathname, 'pricing') ? style.active : ''}`} >Pricing</a>}
                            {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <a className={`${isCurrentPath(pathname, 'broker-cp') ? style.active : ''}`} >Broker/CP</a>}
                            {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <a className={`${isCurrentPath(pathname, 'seo-info') ? style.active : ''}`} >SEO Info</a>}
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan']) && <a className={`${isCurrentPath(pathname, 'construction-updates') ? style.active : ''}`} > Construction Update</a>}
                            {checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead']) && <a className={`${isCurrentPath(pathname, 'team-info') ? style.active : ''}`} >Team Info</a>}
                            {userProfile?.allowed_modules?.includes('teams_module') && <a className={`${isCurrentPath(pathname, 'team') ? style.active : ''}`} >Teams</a>}

                        </>
                }

            </div>
            {showCloseConfirmationModal &&
                <AreYouSureModal
                    show={showCloseConfirmationModal}
                    title='Proceed Ahead?'
                    subText={'Changes that you made may not be saved.'}
                    closeModal={() => {
                        setPropertyType(params.get('pt'));
                        setTransactionType(params.get('tt'));
                        setShowCloseConfirmationModal(false)
                    }
                    }
                    confirmClose={handleCloseConfirmModal}
                />}
        </div>
    )
}

export default ProjectHeader