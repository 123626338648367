import React, { useEffect, useState } from 'react';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import Configuration from '../../components/Projects/Configuration';
import { deleteProjectRera, deleteProjectSpecifications, getProjectBasicDetailsByUUID, getProjectBuilderInfo, getProjectConfigurations, getProjectDetails, getProjectFaqs, getProjectHighlights, getProjectLocationAdvantages, getProjectRera, getProjectReviews, getProjectSpecifications, insertBasicDetail, insertProjectLocationAdvantages, updateBasicDetailsByUUID, updateListingProjectDetailsByUUID, uploadFile } from '../../services/public/public.service';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopUpModal from '../../components/Modals/PopUpModal';
import ProjectConfig from './ProjectConfig';
import TooltipText from '../../components/Tooltip';
import { ReactComponent as AnCloseIcon } from '../../assets/icons/closeicon.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { ReactComponent as TrashblackIcon } from '../../assets/icons/trashblack.svg';
import { ReactComponent as EditBlackIcon } from '../../assets/icons/EditBlack.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/reraclose.svg';
import { ReactComponent as CheckblackIcon } from '../../assets/icons/checkblack.svg';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as ArrowbackIcon } from '../../assets/icons/arrowback.svg';
import TextEditor from './Editor';
import ProjectHighlightsModal from './Modal/ProjectHighlightsModal';
import ReviewModal from './Modal/ReviewModal';
import LocationAdvantageModal from './Modal/LocationAdvantage';
import InputText from '../../components/InputGroup/InputText';
import AmenitiesModal from './Modal/ProjectAmenities';
import SpecificationModal from './Modal/ProjectSpecification';
import { Link } from 'react-router-dom';
import ListingProjectHeader from '../Settings/Listing/ListingProjectHeader';
import Location from '../../components/Projects/Location';
import SEOModal from './Modal/SEOModal';
import FAQModal from './Modal/FAQModal';
import BuildersModal from './Modal/BuildersModal';
import FloorAndPlanModal from './Modal/FloorAndPlanModal';
import DateTimePicker from '../../components/InputGroup/DateTime';
import InputSelect from '../../components/InputGroup/InputSelect';
import ReraModal from './Modal/ReraModal';
import ProjectHeader from '../../components/Projects/project-header';
import { inventory_list, plot_area, possession_type } from '../../utils/project-constant';
import ProjectKeyPillerModal from './Modal/projectKeyPillers';
import Dropdown from '../../components/InputGroup/dropdown';

const CreateProject = () => {
    const [searchText, setSearchText] = useState('');
    const [projectName, setProjectName] = useState('');
    const [developerName, setDeveloperName] = useState('')
    const [amenities, setAmenities] = useState([]);
    const [showAmenitiesModal, setShowAmenitiesModal] = useState(false);
    const [configurations, setConfigurations] = useState([]);
    const [specifications, setSpecifications] = useState([]);
    const [specificationDescription, setSpecificationDescription] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [selectedSpecification, setSelectedSpecification] = useState('');
    const [showDeleteSpecification, setShowDeleteSpecification] = useState(false)
    const [reraNum, setReraNum] = useState([]);
    const [selectedReraNumber, setSelectedReraNumber] = useState('');
    const [reraIndex, setReraIndex] = useState('');
    const [reraNo, setReraNo] = useState('');
    const [description, setDescription] = useState('');
    const [floorPlan, setFloorPlan] = useState('');


    const [logo, setLogo] = useState('');
    const [logoFileError, setLogoFileError] = useState('');
    const [isLogoMissing, setIsLogoMissing] = useState(false);
    const [logoAltTag, setLogoAltTag] = useState('');
    const [inventoryType, setInventoryType] = useState('');
    const [amenitiesDescription, setAmenitiesDescription] = useState([]);
    // const [projectUuid, setProjectUuid] = useState('17092583-b49f-47af-95cd-022bcab20673');
    const [projectUuid, setProjectUuid] = useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [googleAddress, setGoogleAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locationAdvantages, setLocationAdvantages] = useState([]);
    const [locationDescription, setLocationDescription] = useState('');

    const [showHighlightModal, setShowHighlightModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);

    const [showSEOModal, setShowSEOModal] = useState(false);
    const [showFAQModal, setShowFAQModal] = useState(false);
    const [propertyType, setPropertyType] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [allReviews, setAllReviews] = useState([]);
    const [highlights, setHighlights] = useState([]);
    const [highlightDescription, setHighlightDescription] = useState('');
    // const [locationAdvantagesList, setLocationAdvantagesList] = useState([{}]);
    const [allFaqs, setAllFaqs] = useState([]);
    const [selectedFaqIndex, setSelectedFaqIndex] = useState('');
    const [showBuilderModal, setShowBuilderModal] = useState(false);
    const [buildersData, setBuildersData] = useState([]);
    const [floorDescription, setFloorDescription] = useState('');
    const [showFloorAndPlanModal, setShowFloorAndPlanModal] = useState(false);
    const [possessionDate, setPossessionDate] = useState('');
    const [builderId, setBuilderId] = useState('');
    const [showReraModal, setShowReraModal] = useState(false);
    const [reraList, setReraList] = useState([]);
    const [selectedRera, setSelectedRera] = useState({});
    const [selectedHighlight, setSelectedHighlight] = useState([]);
    const [selectedHighDescription, setSelectedHigDescription] = useState();
    const [selectedSpecifications, setSelectedSpecifications] = useState([]);
    const [selectedSpecificationDescriptiopn, setSelectedSpecificationDescriptions] = useState('');
    const [selectedReview, setSelectedReview] = useState({});
    const [validityUnit, setValidityUnit] = useState('');
    const [validity, setValidity] = useState('')
    const [taggingDescription, setTaggingDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [possessionType, setPossesstionType] = useState('');
    const [size, setSize] = useState('');
    const [sizeUnit, setSizeUnit] = useState('');
    const [launchaDate, setLaunchDate] = useState('');
    const [projectStatus, setProjectStatus] = useState('');
    const [displayAddress, setDisplayAddress] = useState('')

    const { uuid } = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);

    useEffect(() => {
        if (searchParams.get('pt') && searchParams.get('tt')) {
            setPropertyType(searchParams.get('pt'));
            setTransactionType(searchParams.get('tt'));
        }
    }, [searchParams.get('pt'), searchParams.get('tt')]);

    const getConfig = async () => {
        const res = await getProjectConfigurations(uuid);
        if (res.data.status === 200) {
            setConfigurations(res.data.data);
        }
    }

    const handleSelectedAmnities = (amenities) => {
        setAmenities(amenities);
    }

    const handleChangeConfiguration = (configuration) => {
        setConfigurations(configuration)
    }


    const getProjectDetailsByUUID = async () => {
        // const res = await getProjectDetails(uuid);
        const res = await getProjectBasicDetailsByUUID(uuid)
        if (res.data.status === 200) {
            console.log(res.data.data)
            setAmenities(res.data.data?.project_amenities || [])
            setDeveloperName(res.data.data?.developer);
            // setDeleveloperLogoAltTag(res.data.data?.project?.developer_logo_alt_tag)
            setGoogleAddress(res.data.data?.google_address);
            setLatitude(res.data.data?.latitude)
            setLongitude(res.data.data?.longitude)
            setLogo(res.data.data?.logo)
            setLogoAltTag(res.data.data?.logo_alt_tag)
            setProjectName(res.data.data?.name);
            setReraNum(res.data.data?.project?.rera);
            setTaggingDescription(res.data.data.tagging_description || '');
            setValidity(res.data.data?.tagging_validity);
            setValidityUnit(res.data.data?.tagging_validity_unit)
            setConfigurations(res.data.data?.configurations)
            setDescription(res.data.data?.description || '');
            setAmenitiesDescription(res.data.data?.amenities_description || '');
            setFloorDescription(res.data.data?.floor_plan_description || '');
            setSpecificationDescription(res.data.data?.specification_description || '');
            setPossessionDate(res.data.data?.possession_date);
            setCity(res.data.data?.city || '')
            setBuilderId(res.data.data?.builder_id || '')
            setPossesstionType(res.data.data?.possession_type);
            setInventoryType(res.data.data?.inventory_type);
            setSize(res.data.data?.size)
            setSizeUnit(res.data.data?.size_unit);
            setLaunchDate(res.data.data?.launch_date || '');
            setProjectStatus(res.data.data?.project_status);
            setDisplayAddress(res.data.data?.display_address);
        }

    }
    const getSpecifications = async () => {
        const res = await getProjectSpecifications(projectUuid || uuid);
        if (res.data.status === 200) {
            setSpecifications(res.data.data);
        }
    }

    const getReraData = async () => {
        const res = await getProjectRera(projectUuid || uuid);
        if (res.data.status === 200) {
            setReraList(res.data.data);
        }
    }

    const getReviews = async () => {
        const res = await getProjectReviews(projectUuid || uuid)
        if (res.data.status === 200) {
            setAllReviews(res.data.data);
        }
    }

    const getHighLights = async () => {
        const res = await getProjectHighlights(projectUuid || uuid)
        if (res.data.status === 200) {
            setHighlights(res.data.data.highlights);
            setHighlightDescription(res.data.data.highlights_description || '')
        }
    }

    const getAdvantages = async () => {
        const res = await getProjectLocationAdvantages(projectUuid || uuid)
        if (res.data.status === 200) {
            setLocationAdvantages(res.data.data);
        }
    }

    const getAllFaqs = async () => {
        const res = await getProjectFaqs(projectUuid || uuid)
        if (res.data.status === 200) {
            setAllFaqs(res.data.data);
        }
    }

    const getBuildersData = async () => {
        const res = await getProjectBuilderInfo()
        if (res.data.status === 200) {
            setBuildersData(res.data.data.map(b => ({ label: b.builder_name, value: b.id })))
        }
    }

    useEffect(() => {
        getBuildersData();
    }, []);

    useEffect(() => {
        if (uuid) {
            setProjectUuid(uuid)
            getProjectDetailsByUUID();
            getSpecifications();
            getReviews();
            getHighLights();
            getAdvantages();
            getAllFaqs();
            getReraData();
        }
    }, [uuid]);

    const handleDeleteSpecification = async () => {
        const res = await deleteProjectSpecifications(selectedSpecification.uuid);
        if (res.data.status = 200) {
            toast.success(res.data.message);
            getSpecifications();
            setShowDeleteSpecification(false)
        }
    }

    const handleOnChangeLogo = async (e) => {
        const file = e.target.files[0];

        if (file.size / 1048576 > 2) {
            setLogoFileError('File too large. Please upload an image under 2MB in size');
            return;
        }
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            setLogoFileError('Wrong File format Uploaded');
            return;
        }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setLogo(response.data.data.s3_url);
            setIsLogoMissing(false);
            setLogoFileError('');
        }
    }

    const handleUpdateLocation = (city, address, state, lat, long) => {
        setCity(city);
        setGoogleAddress(address);
        setState(state);
        setLatitude(lat);
        setLongitude(long);
    }

    const handleSubmitProject = async () => {
        if (!projectName || !logo || !builderId || !validity || !validityUnit || !taggingDescription || !possessionType || !inventoryType || !size || !sizeUnit || (possessionType === 'under_construction' && !possessionDate)) {
            toast.error('Please fill all required fields')
            return;
        }

        setLoading(true);
        const payload = {
            name: projectName,
            logo: logo,
            rera: reraNum,
            logo_alt_tag: logoAltTag,
            inventory_type: inventoryType,
            project_amenities: amenities.map(i => i.id),
            amenities_description: amenitiesDescription,
            configurations: configurations || [],
            description: description,
            possession_date: possessionDate,
            builder_id: builderId,
            is_listing: true,
            is_broker_visible: true,
            tagging_validity: validity,
            tagging_validity_unit: validityUnit,
            tagging_description: taggingDescription,
            // ...(values.rera) && { rera: values.rera },
            ...(googleAddress) && { google_address: googleAddress || '' },
            ...(city) && { city: city || '' },
            ...(state) && { state: state || '' },
            ...(latitude) && { latitude: latitude },
            ...(longitude) && { longitude: longitude },
            ...(propertyType) && { property_type: propertyType },
            ...(transactionType) && { transaction_type: transactionType },
            possession_type: possessionType,
            inventory_type: inventoryType,
            size: size,
            size_unit: sizeUnit,
            project_status: projectStatus || null,
            launch_date: launchaDate || null,
            display_address: displayAddress || null
        }
        const res = await insertBasicDetail(payload);
        if (res.data.status === 200) {
            // console.log(res.data.data)
            setLoading(false);
            window.scrollBy(0, 150);
            setProjectUuid(res.data.data.uuid);
            toast.success('Project created successfully.');
            if (uuid) {
                history.push(`/projects/create-projects/mediaupload/${projectUuid || uuid}?pt=${propertyType}&tt=${transactionType}`);
            }

        }
    }

    const handleUpdateDetails = async () => {
        if (!projectName || !logo || !builderId || !validity || !validityUnit || !taggingDescription || !possessionType || !inventoryType || !size || !sizeUnit || (possessionType === 'under_construction' && !possessionDate)) {
            toast.error('Please fill all required fields')
            return;
        }
        setLoading(true);
        const payload = {
            name: projectName,
            logo: logo,
            rera: reraNum,
            logo_alt_tag: logoAltTag,
            inventory_type: inventoryType,
            project_amenities: amenities.map(i => i.id),
            amenities_description: amenitiesDescription,
            configurations: configurations || [],
            description: description,
            possession_date: possessionDate,
            builder_id: builderId,
            is_broker_visible: true,
            tagging_validity: validity,
            tagging_validity_unit: validityUnit,
            tagging_description: taggingDescription,
            // ...(values.rera) && { rera: values.rera },
            ...(googleAddress) && { google_address: googleAddress || '' },
            ...(city) && { city: city || '' },
            ...(state) && { state: state || '' },
            ...(latitude) && { latitude: latitude },
            ...(longitude) && { longitude: longitude },
            ...(propertyType) && { property_type: propertyType },
            ...(transactionType) && { transaction_type: transactionType },
            possession_type: possessionType,
            inventory_type: inventoryType,
            size: size,
            size_unit: sizeUnit,
            project_status: projectStatus || null,
            launch_date: launchaDate || null,
            display_address: displayAddress || null
        }
        // const res = await updateListingProjectDetailsByUUID(uuid, payload)
        const res = await updateBasicDetailsByUUID(projectUuid || uuid, payload);
        if (res.data.status === 200) {
            setLoading(false);
            toast.success(res.data.message);
            setProjectUuid(uuid);
            window.scrollBy(0, 150);
            if (uuid) {
                history.push(`/projects/create-projects/mediaupload/${projectUuid || uuid}?pt=${propertyType}&tt=${transactionType}`);
            }
        }
    }

    const handleSubmitLocation = async (list) => {
        const payload = {
            location_advantage_description: locationDescription,
            advantages: list.filter(i => i.title)
        }
        const res = await insertProjectLocationAdvantages(projectUuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAdvantages();
            setShowLocationModal(false)
        }
    }

    const handleDeleteRera = async (uuid) => {
        const res = await deleteProjectRera(uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getReraData();
        }
    }

    return (
        <div className={`${style.creatproject} !px-3`} >
            <div className={`${style.proejctheading} proejctheading mt-16 mb-24 d-flex align-items-lg-center gap-24 flex-lg-nowrap flex-wrap flex-lg-row flex-column border-b border-black200 relative top-0 pb-3.5`}>
                {/* <ListingProjectHeader /> */}
                <ProjectHeader style={style} name={projectName} />
            </div>

            <div className={`${style.overflowcontent} d-flex flex-fill flex-col overflow-auto mb-3 pl-24`}>
                <div className={`${style.basic_form} basic_form`}>
                    <InputText
                        label={'Project Name'}
                        placeholder={'Enter Project Name'}
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        error={''}
                        required={true}
                    />
                    <InputSelect
                        label='Possession Type'
                        placeholder='Select Possesion Type'
                        onChange={(value) => setPossesstionType(value.value)}
                        value={possessionType ? possession_type.find(i => i.value === possessionType) : ''}
                        options={possession_type}
                        required={true}
                    // error={errors?.state?.message ? 'Please select State' : ''}                        
                    />
                    <InputSelect
                        label='Inventory Type'
                        placeholder='Select Inventory Type'
                        onChange={(value) => setInventoryType(value.value)}
                        value={inventoryType ? inventory_list.find(i => i.value === inventoryType) : ''}
                        options={inventory_list}
                        required={true}
                    // error={errors?.state?.message ? 'Please select State' : ''}                        
                    />
                    {possessionType === 'under_construction' && <DateTimePicker
                        label='Possession Date'
                        inputclass='mt-0'
                        placeholder='Select Date'
                        value={possessionDate}
                        onValueChange={(date) => {
                            setPossessionDate(date)
                        }}
                        showTime={false}
                        required={true}
                    />}
                    <InputSelect
                        label={'Select Builder'}
                        placeholder='Select Builder'
                        // styles={customStyles}
                        options={buildersData}
                        value={builderId ? buildersData.find(b => b.value == builderId) : ''}
                        onChange={(value) => {
                            setBuilderId(value.value)
                        }}
                        required={true}
                    />
                    <div>
                        <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                            <span>Project Logo <sup>*</sup></span>
                            <span>PNG, 2MB</span>
                        </div>
                        <div className={`${style.upload}`}>
                            {logo && <img src={logo} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                            <input type="file" className='d-none' name='developer-logo' id='project-logo' accept='image/*' onChange={handleOnChangeLogo} />
                            {!logo && <div className={`${style.uplaodbtngrp}`}>
                                <button
                                    type='button'
                                    className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('project-logo').click()
                                    }}
                                ><PluswhiteIcon />Upload</button>
                            </div>}
                            {logo && <div className={`${style.hoverbox}`}>
                                <button
                                    type='button'
                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('project-logo').click()
                                    }}
                                ><PluswhiteIcon />Replace</button>
                                <button
                                    type='button'
                                    className={`${style.delbtn} inter border-0 bg-white`}
                                    onClick={() => setLogo('')}
                                ><TrashIcon /></button>
                            </div>}
                        </div>
                        {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                        {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoFileError}</p>}
                    </div>

                    <InputText
                        label={'Logo Alt Tag'}
                        placeholder={'Enter Logo Alt Tag'}
                        value={logoAltTag}
                        onChange={(e) => setLogoAltTag(e.target.value)}
                        error={''}
                    />
                    <div className={` form-group position-relative`}>
                        <label className=''>Project Size <sup className='text-red'>*</sup> </label>

                        <Dropdown
                            list={plot_area}
                            onChange={(value) => setSizeUnit(value.value)}
                            value={sizeUnit ? plot_area.find(pl => pl.value === sizeUnit) : ''}
                            style={style}
                        />

                        <input
                            className={'form-control text-input'}
                            type={"number"}
                            placeholder='e.g. 33'
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                        />
                    </div>
                    <DateTimePicker
                        label='Launch Date'
                        inputclass='mt-0'
                        placeholder='Select Date'
                        value={launchaDate}
                        onValueChange={(date) => {
                            setLaunchDate(date)
                        }}
                        showTime={false}
                    />
                    <InputText
                        label={'Project Status'}
                        placeholder={'Enter Project Status'}
                        value={projectStatus}
                        onChange={(e) => setProjectStatus(e.target.value)}
                        error={''}
                    />
                    {/* <div></div> */}
                    {/* Editor */}
                    <div className='border-0'>
                        <label htmlFor="">Project Description</label>
                        <TextEditor data={description} onChange={(value) => setDescription(value)} />
                    </div>
                    {/* Amenities */}
                    <div className='col-span-2'>
                        <div className='flex justify-between border-color border-bottom'>
                            <div className={`${style.projecttitle}  mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Amenities</h1>
                            </div>
                            {amenities.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border rounded-lg px-3 py-2 inter' onClick={() => setShowAmenitiesModal(true)}>Edit</button>
                            </div>}
                        </div>
                        {
                            amenitiesDescription && <p className='text-black' dangerouslySetInnerHTML={{ __html: amenitiesDescription }}></p>
                        }
                        {amenities.length === 0 && <div className={`${style.basic_form} basic_form !mt-5`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' onClick={() => setShowAmenitiesModal(true)} className='pr-outline-btn d-block w-100'>+ Add Amenities</button>
                            </div>
                        </div>}
                        <div>
                            {amenities.length > 0 && <div className='d-flex flex-wrap mt-20 gap-y-14 gap-x-8 mb-4'>
                                {
                                    amenities.map((item, index) => (
                                        <div
                                            className={`${style.animitestags}`}
                                            key={`${item}_${index}`}
                                        >
                                            {item.name} <button className={`${style.closetagbtn}`} type='button' onClick={() => {
                                                const filteredList = amenities.filter(amenity => amenity !== item);
                                                setAmenities(filteredList)
                                            }}  ><AnCloseIcon /></button>
                                        </div>
                                    ))
                                }

                            </div>}
                        </div>
                    </div>
                    {/*  Configuration */}
                    <div className='col-span-2'>
                        <ProjectConfig
                            configurations={configurations}
                            handleChangeConfiguration={handleChangeConfiguration}
                            getConfig={getConfig}
                            inventory={''}
                            allowedPermissions={[]}
                            uuid={uuid}
                        />
                    </div>
                    <div className='col-span-2'>
                        <div className='flex justify-between border-color border-bottom '>
                            <div className={`${style.projecttitle}  mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Lead Tagging</h1>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 !gap-4 !mt-4'>

                            <div className='form-group'>
                                <label className='text-sm text-black700'>Validity <sup>*</sup></label>
                                <div className='grid grid-cols-2 gap-x-2'>
                                    <InputText
                                        placeholder='e.g. 20'
                                        value={validity}
                                        type={'number'}
                                        onChange={(e) => setValidity(e.target.value)}
                                        required={true}
                                    // error={(error && !gracePerioid) ? "Please Enter Grace period" : ''}
                                    />
                                    <InputSelect
                                        inputclass='!mt-0'
                                        value={{ label: validityUnit, value: validityUnit }}
                                        placeholder='e.g. days'
                                        onChange={(value) => setValidityUnit(value.value)}
                                        options={[
                                            {
                                                label: 'Days',
                                                value: 'Days'
                                            },
                                            {
                                                label: 'Months',
                                                value: 'Months'
                                            }
                                        ]}
                                        required={true}
                                    // error={(error && !gracePerioidUnit) ? 'Please Select Grace Period Unit' : ''}
                                    />
                                </div>
                            </div>

                            <div className='border-0 form-group'>
                                <label htmlFor="">Tagging Description  <sup>*</sup> </label>
                                <TextEditor data={taggingDescription} onChange={(value) => setTaggingDescription(value)} />
                            </div>
                        </div>
                    </div>
                    <div className='col-span-2'>
                        <Location
                            googleAddress={googleAddress}
                            previousCity={city}
                            latitude={latitude}
                            longitude={longitude}
                            previousState={state}
                            handleUpdateLocation={handleUpdateLocation}
                            displayAddress={displayAddress}
                            setDisplayAddress={setDisplayAddress}
                            register={() => { }}
                            errors={''}
                        />
                    </div>
                    <div></div>
                    {(!projectUuid && !uuid) && <button
                        className='pr-btn w-max justify-self-end py-2 px-4'
                        type='button'
                        disabled={loading}
                        onClick={() => {
                            if (uuid) {
                                handleUpdateDetails()
                            } else {
                                handleSubmitProject()
                            }
                        }}>
                        Save Project
                    </button>}
                </div>


                {(projectUuid || uuid) && <>
                    <>
                        {/* Rera */}
                        <>
                            <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`} id='rera_num'>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Rera Number</h1>
                                {reraList.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                    <button type='button' className='pr-btn inter ' onClick={() => setShowReraModal(true)}><PlushIcon />Add</button>
                                </div>}
                            </div>
                            {
                                reraList.length === 0 &&
                                <div className={`${style.basic_form} basic_form mt-20`}>
                                    <div className={`${style.col_span_2}`}>
                                        <button
                                            type='button'
                                            onClick={() => setShowReraModal(true)}
                                            className='pr-outline-btn d-block w-100'
                                        // disabled={!projectUuid}
                                        >+ Add Rera Number</button>
                                    </div>
                                </div>
                            }
                            {reraList.length > 0 && <div>
                                <div className='!mt-4'>
                                    <div className={`${style.basic_form}`}>
                                        <div className={`${style.col_span_2}`}>
                                            <div className='def-table pr-0 h-100 pb-0'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Rera Number</th>

                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            reraList.map((rera, index) => (
                                                                <tr key={`${rera.uuid}_${index}`}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{rera.rera_no}</td>
                                                                    <td className='dropdown view-more'>
                                                                        <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <ThreedotsIcon />
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                            <a className="dropdown-item text-capitalize"
                                                                                onClick={() => {
                                                                                    setSelectedRera(rera)
                                                                                    setShowReraModal(true);
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </a>
                                                                            <a className="dropdown-item del-menu text-capitalize" onClick={() => handleDeleteRera(rera.uuid)}> Delete</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </>
                        {/* Specification */}
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Specification</h1>
                            {specifications.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border border-lg rounded-lg py-2 px-3 inter ' onClick={() => {
                                    setSelectedSpecificationDescriptions(specificationDescription);
                                    setSelectedSpecifications(specifications);
                                    setViewModal(true)
                                }}>Edit</button>
                            </div>}
                        </div>
                        {
                            specifications.length === 0 &&
                            <div className={`${style.basic_form} basic_form mt-20`}>
                                <div className={`${style.col_span_2}`}>
                                    <button
                                        type='button'
                                        onClick={() => setViewModal(true)}
                                        className='pr-outline-btn d-block w-100'
                                        disabled={!projectUuid}
                                    >+ Add Specification</button>
                                </div>
                            </div>
                        }
                        {specificationDescription && <p className='text-black mt-3' dangerouslySetInnerHTML={{ __html: specificationDescription }}></p>}
                        {specifications.length > 0 && <div>
                            <div className='!mt-4'>
                                <div className={`${style.basic_form}`}>
                                    <div className={`${style.col_span_2}`}>
                                        <div className='def-table pr-0 h-100 pb-0'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Project Specification Type</th>
                                                        <th>Project Specification Value</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        specifications.map((specification, index) => (
                                                            <tr key={`${specification.type}_${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td>{specification.type}</td>
                                                                <td>{specification.value}</td>

                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedSpecification(specification)
                                                                                setViewModal(true);
                                                                            }}
                                                                        >
                                                                            Edit Specification
                                                                        </a>

                                                                        <a
                                                                            className="dropdown-item del-menu-itme text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedSpecification(specification)
                                                                                setShowDeleteSpecification(true)
                                                                            }}
                                                                        >
                                                                            Delete Specification
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </>

                    {/* Floor Plan and Pricing */}
                    <>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Floor Plan Description</h1>
                            {floorDescription && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border rounded-lg p-2 inter ' onClick={() => setShowFloorAndPlanModal(true)}>Edit</button>
                            </div>}
                        </div>
                        {!floorDescription && <div className={`${style.basic_form} basic_form mt-20`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button'
                                    onClick={() => setShowFloorAndPlanModal(true)}
                                    className='pr-outline-btn d-block w-100'
                                    disabled={!projectUuid}
                                >+ Add Floor Plan Description</button>
                            </div>
                        </div>}
                        {floorDescription && <div className=''>
                            <p className='text-black' dangerouslySetInnerHTML={{ __html: floorDescription }}></p>
                        </div>}
                    </>
                    {/* Location Advantages */}
                    <>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Location Advantages</h1>
                            {locationAdvantages.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border rounded-lg px-3 py-2 inter ' onClick={() => setShowLocationModal(true)}> Edit </button>
                            </div>}
                        </div>
                        {locationAdvantages.length === 0 && <div className={`${style.basic_form} basic_form mt-20`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button'
                                    onClick={() => setShowLocationModal(true)}
                                    className='pr-outline-btn d-block w-100'
                                    disabled={!projectUuid}
                                >+ Add Location Advantages</button>
                            </div>
                        </div>}
                        {locationDescription && <div className='mt-2' dangerouslySetInnerHTML={{ __html: locationDescription }}></div>}
                        {locationAdvantages.length > 0 && <div>
                            <div className='!mt-5'>
                                <div className={`${style.basic_form}`}>
                                    <div className={`${style.col_span_2}`}>
                                        <div className='def-table pr-0 h-100 pb-0'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Title</th>
                                                        <th>Subcontent</th>
                                                        <th>Distance</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        locationAdvantages.map((adv, index) => (
                                                            <tr key={`${allReviews.uuid}_${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td>{adv.title || '-'}</td>
                                                                <td>{adv.subcontent || '-'}</td>
                                                                <td>{adv.distance_from_project || '-'}</td>

                                                                <td className='dropdown view-more'>
                                                                    {/* <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <ThreedotsIcon />
                                                                </button> */}
                                                                    {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    <a className="dropdown-item text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedSpecification(specification)
                                                                            setViewModal(true);
                                                                        }}
                                                                    >
                                                                        Edit Specification
                                                                    </a>

                                                                    <a
                                                                        className="dropdown-item del-menu-itme text-capitalize"
                                                                        onClick={() => {
                                                                            setSelectedSpecification(specification)
                                                                            setShowDeleteSpecification(true)
                                                                        }}
                                                                    >
                                                                        Delete Specification
                                                                    </a>
                                                                </div> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </>
                    {/* Project Highlights */}
                    <>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Project Highlights</h1>

                            {highlights.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border py-1 px-4 rounded-lg inter mr-3 ' onClick={
                                    () => {
                                        setSelectedHigDescription(highlightDescription);
                                        setSelectedHighlight(highlights);
                                        setShowHighlightModal(true);
                                    }}>Edit</button>
                                {/* <button type='button' className='pr-btn inter ' onClick={() => setShowHighlightModal(true)}><PlushIcon />Add</button> */}
                            </div>}
                        </div>

                        {highlights.length === 0 && <div className={`${style.basic_form} basic_form mt-20`}>
                            <div className={`${style.col_span_2}`}>
                                <button
                                    type='button'
                                    onClick={() => setShowHighlightModal(true)}
                                    className='pr-outline-btn d-block w-100'
                                    disabled={!projectUuid}
                                >+ Add Project Highlights</button>
                            </div>
                        </div>}
                        {highlightDescription && <div className='mt-2' dangerouslySetInnerHTML={{ __html: highlightDescription }}></div>}
                        {highlights.length > 0 && <div className='d-flex flex-wrap mt-20 gap-y-14 gap-x-8 mb-4'>
                            {/* {
                                highlights.map((item, index) => (
                                    <div
                                        className={`${style.animitestags}`}
                                        key={`${item}_${index}`}
                                    >
                                        {item.title} <button className={`${style.closetagbtn}`} type='button' onClick={() => {
                                            const filteredList = highlights.filter(high => high !== item);
                                            setHighlights(filteredList)
                                        }}  ><AnCloseIcon /></button>
                                    </div>
                                ))
                            } */}
                            <div className={`${style.basic_form}`}>
                                <div className={`${style.col_span_2}`}>
                                    <div className={`${style.bankDetailtable} def-table pr-6 pb-0 w-100`}>
                                        <table className='table mb-0'>
                                            <thead>
                                                <tr>
                                                    <th >S.No</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    highlights.map((item, index) => (
                                                        <tr key={`${item.uuid}_${index}`}>
                                                            <td >{index + 1}</td>
                                                            <td className='w-[300px]'>
                                                                {item.title ? <TooltipText title={item.title}>
                                                                    <p className='truncate w-[300px] m-0'>{item.title}</p>
                                                                </TooltipText> : '-'}
                                                            </td>
                                                            <td className='w-[300px]'>
                                                                {item.description ?
                                                                    <TooltipText title={item.description}>
                                                                        <p className='truncate w-[300px] m-0'>{item.description}</p>
                                                                    </TooltipText>
                                                                    : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </>

                    {/* Reviews */}
                    <>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Reviews</h1>
                            {allReviews.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='pr-btn inter ' onClick={() => setShowReviewModal(true)}><PlushIcon />Add</button>
                            </div>}
                        </div>
                        {
                            allReviews.length === 0 &&
                            <div className={`${style.basic_form} basic_form mt-20`}>
                                <div className={`${style.col_span_2}`}>
                                    <button
                                        type='button'
                                        onClick={() => setShowReviewModal(true)}
                                        className='pr-outline-btn d-block w-100'
                                        disabled={!projectUuid}
                                    >+ Add Reviews</button>
                                </div>
                            </div>
                        }
                        {allReviews.length > 0 && <div>
                            <div className='!mt-5'>
                                <div className={`${style.basic_form}`}>
                                    <div className={`${style.col_span_2}`}>
                                        <div className='def-table pr-0 h-100 pb-0'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Name</th>
                                                        <th>Review Title</th>
                                                        <th>Rating</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allReviews.map((review, index) => (
                                                            <tr key={`${allReviews.uuid}_${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td>{review.user_name || '-'}</td>
                                                                <td>{review.review_title || '-'}</td>
                                                                <td>{review.rating || '-'}</td>

                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedReview(review)
                                                                                setShowReviewModal(true);
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </a>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </>

                    {/* FAQs */}
                    <>
                        <div className={`${style.projecttitle} border-color border-bottom mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>FAQs</h1>
                            {allFaqs.length > 0 && <div className={`${style.projecttitle} mt-24 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                                <button type='button' className='border px-3 py-2 rounded-lg inter ' onClick={() => setShowFAQModal(true)}>Edit</button>
                            </div>}
                        </div>
                        {
                            allFaqs.length === 0 &&
                            <div className={`${style.basic_form} basic_form mt-20`}>
                                <div className={`${style.col_span_2}`}>
                                    <button
                                        type='button'
                                        onClick={() => setShowFAQModal(true)}
                                        className='pr-outline-btn d-block w-100'
                                        disabled={!projectUuid}
                                    >+ Add FAQs</button>
                                </div>
                            </div>
                        }
                    </>
                    {
                        allFaqs.length > 0 && allFaqs.map((faq, index) => (
                            <div id="accordion-collapse" data-accordion="collapse" key={`${index}`} className='mt-3'>
                                <h2 id={`accordion-collapse-heading-${index + 1}`} className='text-lg'>
                                    <button
                                        onClick={() => {
                                            if (index === selectedFaqIndex) {
                                                setSelectedFaqIndex('')
                                            } else {
                                                setSelectedFaqIndex(index)
                                            }
                                        }}
                                        type="button" class="flex items-center justify-between w-full p-2 font-medium rtl:text-right  border border-gray-200 rounded-lg focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                        <span>{faq.question}</span>
                                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>
                                </h2>
                                <div id={`accordion-collapse-heading-${index + 1}`} class={selectedFaqIndex === index ? 'block w-full' : "hidden"} aria-labelledby={`accordion-collapse-heading-${index + 1}`}>
                                    <div class="p-2 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 w-full">
                                        <p class="mb-2  dark:text-gray-400 text-lg w-full" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                        {/* <p class="text-gray-500 dark:text-gray-400">Check out this guide to learn how to <a href="/docs/getting-started/introduction/" class="text-blue-600 dark:text-blue-500 hover:underline">get started</a> and start developing websites even faster with components on top of Tailwind CSS.</p> */}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </>}
            </div>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button
                    type='button'
                    className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center inter'
                    onClick={() => {
                        history.push('/settings/listing/projects');
                    }}
                >Cancel</button>

                {(projectUuid || uuid) && <button
                    className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter'
                    onClick={() => {
                        handleUpdateDetails()
                    }}
                    disabled={!projectUuid}
                >Next Tab</button>}

                {/***
                 * During creating a new project then this will appear
                 */}
                {(!projectUuid && !uuid) &&
                    <button
                        className='pr-btn w-max justify-self-end py-2 px-4'
                        type='button'
                        onClick={() => {
                            handleSubmitProject()
                        }}>
                        Save Project
                    </button>
                }
            </div>

            {
                showAmenitiesModal &&
                <AmenitiesModal
                    show={showAmenitiesModal}
                    closeModal={() => setShowAmenitiesModal(false)}
                    style={style}
                    handleSelectedAmnities={handleSelectedAmnities}
                    amenities={amenities}
                    setAmenitiesDescription={setAmenitiesDescription}
                    amenitiesDescription={amenitiesDescription}
                    setAmenities={setAmenities}
                />
            }
            {
                viewModal && <SpecificationModal
                    show={viewModal}
                    closeModal={() => {
                        setSelectedSpecification('');
                        setSelectedSpecificationDescriptions('')
                        setSelectedSpecifications([]);
                        setViewModal(false);
                    }}
                    style={style}
                    setSpecifications={setSpecifications}
                    specifications={specifications}
                    selectedSpecification={selectedSpecification}
                    getSpecifications={getSpecifications}
                    projectUuid={projectUuid || uuid}
                    selectedSpecificationDescriptiopn={selectedSpecificationDescriptiopn}
                    selectedSpecifications={selectedSpecifications}
                />
            }
            {
                showFloorAndPlanModal && <FloorAndPlanModal
                    show={showFloorAndPlanModal}
                    closeModal={setShowFloorAndPlanModal}
                    floorDescription={floorDescription}
                    setFloorDescription={setFloorDescription}
                    style={style}
                    projectUuid={projectUuid || uuid}
                />
            }
            {/* {
                <BuildersModal
                    show={showBuilderModal}
                    closeModal={() => setShowBuilderModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getBuildersData={getBuildersData}
                    buildersData={buildersData}
                />
            } */}
            {/* {
                showHighlightModal &&
                <ProjectHighlightsModal
                    show={showHighlightModal}
                    closeModal={() => setShowHighlightModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getHighLights={getHighLights}
                    selectedHighlight={selectedHighlight}
                    selectedHighDescription={selectedHighDescription}
            } */}
            {
                showHighlightModal &&
                <ProjectKeyPillerModal
                    show={showHighlightModal}
                    closeModal={() => setShowHighlightModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getHighLights={getHighLights}
                    selectedHighlight={selectedHighlight}
                    selectedHighDescription={selectedHighDescription}
                />
            }

            {
                showReraModal && <ReraModal
                    show={showReraModal}
                    closeModal={() => {
                        setSelectedRera({})
                        setShowReraModal(false)
                    }
                    }
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getReraData={getReraData}
                    selectedRera={selectedRera}
                />
            }
            {showLocationModal &&
                <LocationAdvantageModal
                    show={showLocationModal}
                    closeModal={() => setShowLocationModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    handleSubmitLocation={handleSubmitLocation}
                    locationAdvantages={locationAdvantages}
                    setLists={setLocationAdvantages}
                    locationDescription={locationDescription}
                    setLocationDescription={setLocationDescription}
                />
            }
            {showReviewModal &&
                <ReviewModal
                    show={showReviewModal}
                    closeModal={() => {
                        setSelectedReview({})
                        setShowReviewModal(false)
                    }}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getReviews={getReviews}
                    selectedReview={selectedReview}
                />
            }
            {showSEOModal &&
                <SEOModal
                    show={showSEOModal}
                    closeModal={() => setShowSEOModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                />
            }
            {
                showFAQModal &&
                <FAQModal
                    show={showFAQModal}
                    closeModal={() => setShowFAQModal(false)}
                    style={style}
                    projectUuid={projectUuid || uuid}
                    getAllFaqs={getAllFaqs}
                    allFaqs={allFaqs}
                />
            }
            {
                showDeleteSpecification ? <PopUpModal
                    setShow={setShowDeleteSpecification}
                    show={showDeleteSpecification}
                    heading={<>
                        Delete {selectedSpecification.type}?
                    </>}
                    body={<div className=' p-3'>
                        <div className="mb-4">
                            Are you sure you want to delete project specification?
                        </div>
                        <div className="justify-content-end d-flex">
                            <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteSpecification(false)}>Cancel</button>
                            <button className="fw-po-medium del-btn px-16" onClick={handleDeleteSpecification}>Confirm Delete</button>

                        </div>
                    </div>}
                /> : null
            }
        </div>
    )
}

export default CreateProject;