import React from 'react';
import { Modal } from 'react-bootstrap';

const FbIntegrationConfirmation = ({ show, closeModal }) => {
    return (
        <Modal size="sm" show={show} onHide={closeModal} backdrop="static" centered dialogClassName='modal-radius testleadProcess'>
            < div className='fb-integration-complete'>
                <figure className='d-table mx-auto mt-5 mb-24'><svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                    <g filter="url(#filter0_d_5869_75028)">
                        <g clipPath="url(#clip0_5869_75028)">
                            <rect x="6" y="6" width="56" height="56" rx="8" fill="#82C43C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.9996 11.5996H47.9996C52.6388 11.5996 56.3996 15.3604 56.3996 19.9996V47.9996C56.3996 52.6388 52.6388 56.3996 47.9996 56.3996H19.9996C15.3604 56.3996 11.5996 52.6388 11.5996 47.9996V19.9996C11.5996 15.3604 15.3604 11.5996 19.9996 11.5996Z" fill="#82C43C" />
                            <path d="M26.6088 31.9845C25.5083 30.8981 23.7355 30.9096 22.6491 32.0101C21.5628 33.1106 21.5742 34.8835 22.6748 35.9698L29.7894 42.993C30.9599 44.1484 32.8694 44.0509 33.9162 42.7823L45.4682 28.7823C46.4524 27.5896 46.2833 25.8248 45.0905 24.8406C43.8978 23.8564 42.133 24.0255 41.1488 25.2182L31.545 36.8571L26.6088 31.9845Z" fill="white" />
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_5869_75028" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dx="2" dy="2" />
                            <feGaussianBlur stdDeviation="4" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.834499 0 0 0 0 0.834499 0 0 0 0 0.86113 0 0 0 0.8 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5869_75028" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5869_75028" result="shape" />
                        </filter>
                        <clipPath id="clip0_5869_75028">
                            <rect x="6" y="6" width="56" height="56" rx="8" fill="white" />
                        </clipPath>
                    </defs>
                </svg></figure>
                <h1 className='text-center black fz22 fw-po-medium black  mb-8'>Your integration is complete!</h1>
                <p className='text-center black-dark-700 mb-0'>Propacity Platform is connected to your <span className='d-md-block'>facebook leads.</span></p>
                <button className='pr-btn d-table mx-auto' onClick={closeModal}>Confirm</button>
            </div>
        </Modal>
    )
}

export default FbIntegrationConfirmation