import React from 'react'
import SmallCenterModal from './small-center-modal'

const NoFormFound = ({ show, closeModal, pageName }) => {

    return (
        <div>
            <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'unprocessed-leads PauseIntegrationModal nopagefound'}>
                <div className=''>
                    <figure className='mb-20'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                            <rect width="48" height="48" transform="translate(1)" fill="white" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="#FBBC05" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint0_linear_7600_82106)" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint1_linear_7600_82106)" fillOpacity="0.2" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="#FBBC05" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint2_linear_7600_82106)" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint3_linear_7600_82106)" strokeOpacity="0.2" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M23 14.9V26.9C23 28.2255 24.0745 29.3 25.4 29.3C26.7255 29.3 27.8 28.2255 27.8 26.9V14.9C27.8 13.5745 26.7255 12.5 25.4 12.5C24.0745 12.5 23 13.5745 23 14.9Z" fill="white" />
                            <path d="M27.8 34.1C27.8 35.4255 26.7255 36.5 25.4 36.5C24.0745 36.5 23 35.4255 23 34.1C23 32.7745 24.0745 31.7 25.4 31.7C26.7255 31.7 27.8 32.7745 27.8 34.1Z" fill="white" />
                            <defs>
                                <linearGradient id="paint0_linear_7600_82106" x1="24.9619" y1="0.74707" x2="24.999" y2="68.501" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF974A" stopOpacity="0" />
                                    <stop offset="1" stopColor="#FF974A" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_7600_82106" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_7600_82106" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF974A" stopOpacity="0" />
                                    <stop offset="1" stopColor="#FDA82C" />
                                </linearGradient>
                                <linearGradient id="paint3_linear_7600_82106" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </figure>
                    <h1 className='fz20 fw-po-medium black mb-8 po'>No From Found!</h1>
                    <p className="black-dark-700 fz14 ls2 ro mb-0">Your selected <b>{pageName}</b>  page has no forms. Please select a page with active forms.</p>

                    <div className='d-flex justify-content-end mt-24'>
                        <button className='pr-btn px-24 py-6 fw-po-medium d-table fz16 w-100 text-center' onClick={closeModal} >Close</button>
                    </div>
                </div>
            </ SmallCenterModal>
        </div>
    )
}

export default NoFormFound;
