import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AreaInput from '../../../../Lead Details/components/Info Components/Area Input/AreaInput'
import { updateCustomerByIdAndKey, updateCustomerByIdAndKey2 } from "../../../../../services/private/customer.service";
import { employment_list } from "../../../../../utils/constants";
import DynamicEditinput from "../../../../../components/InputGroup/DynamicEditInput";
import DynamicDropdown from "../../../../../components/InputGroup/DynamicDropdown";
import { getCitiesFromState } from "../../../../../services/public/public.service";
import AsyncAreaSelector from "../../../../../components/InputGroup/AsyncAreaSelector";
import { getLeadByUuid } from "../../../../../services/private/leads.service";
import Consumer from '../../../../../helpers/context';

export default function CustomerInfoTab(props) {

    const [editActive, setEditActive] = useState({})
    const [customerInfoData, setCustomerInfoData] = useState({})
    const [customerInfo, setCustomerInfo] = useState({});
    const [leadInfoData, setLeadInfoData] = useState({})
    const [cities, setCities] = useState([])
    // const [address, setAddress] = useState({})

    const params = useParams();
    let { userProfile } = useContext(Consumer);

    useEffect(() => {
        const loadCities = () => {
            getCitiesFromState(customerInfoData.data.state).then((res) => {
                if (res.status === 200) {
                    const cities = res.data.data.map((data) => {
                        return {
                            label: data.name,
                            value: data.name,
                        };
                    });
                    setCities(cities);
                }
            });
        }
        if (customerInfoData.data?.state) loadCities()
    }, [customerInfoData.data?.state])

    const getLeadDetails = async () => {
        await getLeadByUuid(params.id).then(res => {
            if (res.status === 200) {
                setCustomerInfoData(res.data.data.customer);
                setCustomerInfo(res.data.data.customer);
                setLeadInfoData(res.data.data?.lead)
                // if (res.data.data.customer?.data.current_residence) {
                //     setAddress(JSON.parse(res.data.data.customer?.data.current_residence))
                // }
            }
        })
    }

    useEffect(() => {
        getLeadDetails()
    }, [])

    const handleEditActive = (name, value) => {
        const index = Object.values(editActive).indexOf(true)
        if (index !== -1 && !value) {
            setEditActive({ ...editActive, [name]: value })
        }
        if (index === -1 && value) {
            setEditActive({ ...editActive, [name]: value })
        }
    }

    const handleValueChange = (name, value) => {
        // if (name === 'current_residence') {
        //     setAddress(value)
        // }
        let data = customerInfoData.data;
        data = { ...data, [name]: value };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }

    const handleCloseCustomerInfo = (name) => {
        let data = customerInfoData.data;
        data = { ...data, [name]: customerInfo?.data[name] };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }

    const updateCustomerInfo = async (key, value, label) => {

        if (!value) {
            toast.error(`Can't save empty value.`)
            return;
        }

        if (customerInfo?.data[key] === customerInfoData.data[key]) {
            handleEditActive(key, false)
            return;
        }
        await updateCustomerByIdAndKey(customerInfoData.data.uuid, { key, value }).then((res) => {
            if (res.status === 200) {
                toast.success(`${label} updated`);
                handleEditActive(key, false)
                getLeadDetails();
            }
        }).catch((err) => {
            console.log(err, "Something went wrong on updating customer info");
        })
    }

    const updateCustomerRangeInfo = async (key, value) => {
        if (!value.value1 || !value.value2) {
            toast.error(`Can't save empty value.`)
            return;
        }
        await updateCustomerByIdAndKey2(customerInfoData.data.uuid, value).then((res) => {
            if (res.status === 200) {
                toast.success(`Size updated`);
                handleEditActive(key, false)

                getLeadDetails();
            }
        }).catch((err) => {
            console.log(err, "Something went wrong on updating customer info");
        })
    }

    const selectedValue = useCallback((dropdownList, valueToFind) => {
        return dropdownList.find((d) => d.value === valueToFind)
    }, [customerInfoData])

    return (
        <>
            <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                <div className={`${props.currentPage == 1 ? '' : 'd-none'}`}>
                    <div className="row mb-24">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="purpose"
                                heading="Purpose"
                                editActive={editActive.purpose}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('purpose')
                                    }
                                    handleEditActive("purpose", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                value={selectedValue(props.customerInfo?.enum_purpose, customerInfoData.data?.purpose)}
                                onValueChange={(value) => handleValueChange("purpose", value.value)}
                                optionConfig={props.customerInfo?.enum_purpose}
                                onEditClick={() => handleEditActive("purpose", true)}
                                editDeactive={() => handleEditActive("purpose", false)}
                                onSave={() => {
                                    updateCustomerInfo("purpose", customerInfoData.data?.purpose, 'Purpose')
                                    // handleEditActive("purpose", false)
                                }}
                                maxMenuHeight={'150px'}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="property_category"
                                heading="Property Type"
                                editActive={editActive.property_category}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('property_category')
                                    }
                                    handleEditActive("property_category", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={""}
                                // defaultLabel={this.props.customer?.furnishing?.display_name}
                                // defaultLabel={ ""}
                                // onClickHandler={() => updateCustomerInfo("property_type", "")}
                                value={selectedValue(props.customerInfo?.enum_property_category, customerInfoData.data?.property_category)}
                                onValueChange={(value) => handleValueChange("property_category", value.value)}
                                optionConfig={props.customerInfo?.enum_property_category}
                                onEditClick={() => handleEditActive("property_category", true)}
                                editDeactive={() => handleEditActive("property_category", false)}
                                onSave={() => {
                                    updateCustomerInfo("property_category", customerInfoData.data?.property_category, 'Property Type')
                                    // handleEditActive("property_category", false)
                                }}
                                maxMenuHeight={'120px'}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="configuration"
                                heading="Configuration Type"
                                editActive={editActive.configuration}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('configuration')
                                    }
                                    handleEditActive("configuration", activeState)
                                }}
                                placeholder="Enter Configuration"
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={false}
                                value={customerInfoData.data?.configuration}
                                onValueChange={(value) => handleValueChange("configuration", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("configuration", true)}
                                editDeactive={() => handleEditActive("configuration", false)}
                                onSave={() => {
                                    updateCustomerInfo("configuration", customerInfoData.data.configuration, 'Configuration Type')
                                    // handleEditActive("configuration", false)
                                }}
                                maxMenuHeight={'120px'}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <AreaInput
                                inputKey="carpet_area"
                                inputKey2="carpet_area_unit"
                                heading="Size"
                                placeholder="Enter Area"
                                editActive={editActive.carpet_area}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('carpet_area')
                                        handleCloseCustomerInfo('carpet_area_unit')
                                    }
                                    handleEditActive("carpet_area", activeState)
                                }}
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={true}
                                carpetArea={customerInfoData.data?.carpet_area}
                                carpetAreaUnit={customerInfoData.data?.carpet_area_unit}
                                onChangeCarpetArea={(value) => handleValueChange("carpet_area", value)}
                                onChangeCarpetAreaUnit={(value) => handleValueChange("carpet_area_unit", value)}
                                onEditClick={() => handleEditActive("carpet_area", true)}
                                editDeactive={() => handleEditActive("carpet_area", false)}
                                onSave={(value) => {
                                    updateCustomerRangeInfo("carpet_area", value)
                                    // handleEditActive("carpet_area", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="requirement_type"
                                heading="Requirement Type"
                                editActive={editActive.property_type}
                                setEditActive={(activeState) => handleEditActive("property_type", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={props.customerInfo?.enum_property_type}
                                onEditClick={() => handleEditActive("property_type", true)}
                                editDeactive={() => handleEditActive("property_type", false)}
                                value={selectedValue(props.customerInfo?.enum_property_type, customerInfoData.data?.property_type)}
                                onValueChange={(value) => handleValueChange("property_type", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("property_type", customerInfoData.data?.property_type, 'Requirement Type')
                                    // handleEditActive("property_type", false)
                                }}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="funding_source"
                                heading="Funding Source"
                                editActive={editActive.funding_source}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('funding_source')
                                    }
                                    handleEditActive("funding_source", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={props.customerInfo?.enum_funding_source}
                                onEditClick={() => handleEditActive("funding_source", true)}
                                editDeactive={() => handleEditActive("funding_source", false)}
                                value={selectedValue(props.customerInfo?.enum_funding_source, customerInfoData.data?.funding_source)}
                                onValueChange={(value) => handleValueChange("funding_source", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("funding_source", customerInfoData.data?.funding_source, 'Funding Source')
                                    // handleEditActive("funding_source", false)
                                }}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="employement_type"
                                heading="Employment Type"
                                editActive={editActive.employement_type}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('employement_type')
                                    }
                                    handleEditActive("employement_type", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={employment_list}
                                onEditClick={() => handleEditActive("employement_type", true)}
                                editDeactive={() => handleEditActive("employement_type", false)}
                                value={selectedValue(employment_list, customerInfoData.data?.employement_type)}
                                onValueChange={(value) => handleValueChange("employement_type", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("employement_type", customerInfoData.data?.employement_type, 'Employment Type')
                                    // handleEditActive("employement_type", false)
                                }}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="facing"
                                heading="Facing"
                                editActive={editActive.facing}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('facing')
                                    }
                                    handleEditActive("facing", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                value={selectedValue(props.customerInfo?.enum_facing, customerInfoData?.data?.facing)}
                                onValueChange={(value) => handleValueChange("facing", value.value)}
                                optionConfig={props.customerInfo?.enum_facing}
                                onEditClick={() => handleEditActive("facing", true)}
                                editDeactive={() => handleEditActive("facing", false)}
                                onSave={() => {
                                    updateCustomerInfo("facing", customerInfoData.data?.facing, 'Facing')
                                    // handleEditActive("facing", false)
                                }}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="current_residence"
                                heading="Customer Address"
                                placeholder="Enter Address"
                                editActive={editActive.current_residence}
                                setEditActive={(activeState) => handleEditActive("current_residence", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                value={customerInfoData.data?.current_residence}
                                onValueChange={(value) => handleValueChange("current_residence", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("current_residence", true)}
                                editDeactive={() => handleEditActive("current_residence", false)}
                                onSave={() => {
                                    updateCustomerInfo("current_residence", customerInfoData.data.current_residence, 'Customer Address')
                                    // handleEditActive("current_residence", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}

                            />
                        </div>
                        {/* New fields  */}
                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                {/* 
                                <DynamicEditinput
                                    inputKey="company"
                                    heading="Company"
                                    placeholder="Enter Company"
                                    editActive={editActive.company}
                                    setEditActive={(activeState) => handleEditActive("comapny", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={customerInfoData.data?.company}
                                    onValueChange={(value) => handleValueChange("company", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("company", true)}
                                    editDeactive={() => handleEditActive("company", false)}
                                    onSave={() => {
                                        updateCustomerInfo("company", customerInfoData.data.company, 'Company')
                                        // handleEditActive("company", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            */}
                                <DynamicDropdown

                                    editActive={editActive?.possession}
                                    setEditActive={(activeState) => {
                                        if (!activeState) {
                                            handleCloseCustomerInfo('possession')
                                        }
                                        handleEditActive("possession", activeState)
                                    }}
                                    heading="Possession Preference"
                                    name="Possession Preference"
                                    placeholder="Possession Preference"
                                    isEditable={true}
                                    value={selectedValue(props.customerInfo?.enum_possession, customerInfoData?.data?.possession)}
                                    optionConfig={props.customerInfo?.enum_possession}
                                    onValueChange={(value) => handleValueChange("possession", value.value)}
                                    onSave={() => {
                                        updateCustomerInfo("possession", customerInfoData?.data?.possession, 'Possession Preference')
                                        // handleEditActive("possession", false)
                                    }}
                                    menuPlacement="top"
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}
                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="profession"
                                    heading="Profession"
                                    placeholder="Enter Profession"
                                    editActive={editActive.profession}
                                    setEditActive={(activeState) => handleEditActive("profession", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={customerInfoData.data?.profession}
                                    onValueChange={(value) => handleValueChange("profession", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("profession", true)}
                                    editDeactive={() => handleEditActive("profession", false)}
                                    onSave={() => {
                                        updateCustomerInfo("profession", customerInfoData.data.profession, 'Profession')
                                        // handleEditActive("profession", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="designation"
                                    heading="Designation"
                                    placeholder="Enter Designation"
                                    editActive={editActive.designation}
                                    setEditActive={(activeState) => handleEditActive("designation", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={customerInfoData.data?.designation}
                                    onValueChange={(value) => handleValueChange("designation", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("designation", true)}
                                    editDeactive={() => handleEditActive("designation", false)}
                                    onSave={() => {
                                        updateCustomerInfo("designation", customerInfoData.data.designation, 'Designation')
                                        // handleEditActive("designation", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="referred_by"
                                    heading="Reference Name"
                                    placeholder="Enter Reference Name"
                                    editActive={editActive.referred_by}
                                    setEditActive={(activeState) => handleEditActive("referred_by", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={leadInfoData.data?.referred_by}
                                    onValueChange={(value) => handleValueChange("referred_by", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("referred_by", true)}
                                    editDeactive={() => handleEditActive("referred_by", false)}
                                    onSave={() => {
                                        updateCustomerInfo("referred_by", leadInfoData.data.referred_by, 'Reference Name')
                                        // handleEditActive("referred_by", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="referred_by_number"
                                    heading="Reference Mobile"
                                    placeholder="Enter Reference Mobile"
                                    editActive={editActive.referred_by_number}
                                    setEditActive={(activeState) => handleEditActive("referred_by_number", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={leadInfoData.data?.referred_by_number}
                                    onValueChange={(value) => handleValueChange("referred_by_number", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("referred_by_number", true)}
                                    editDeactive={() => handleEditActive("referred_by_number", false)}
                                    onSave={() => {
                                        updateCustomerInfo("referred_by_number", leadInfoData.data.referred_by_number, 'Reference Mobile')
                                        // handleEditActive("referred_by_number", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="referred_by_society"
                                    heading="Reference Society"
                                    placeholder="Enter Reference Society"
                                    editActive={editActive.referred_by_society}
                                    setEditActive={(activeState) => handleEditActive("referred_by_society", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={leadInfoData.data?.referred_by_society}
                                    onValueChange={(value) => handleValueChange("referred_by_society", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("referred_by_society", true)}
                                    editDeactive={() => handleEditActive("referred_by_society", false)}
                                    onSave={() => {
                                        updateCustomerInfo("referred_by_society", leadInfoData.data.referred_by_society, 'Reference Society')
                                        // handleEditActive("referred_by_society", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}

                                />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <DynamicEditinput
                                    inputKey="referred_by_flat_no"
                                    heading="Reference Flat no"
                                    placeholder="Enter Address"
                                    editActive={editActive.referred_by_flat_no}
                                    setEditActive={(activeState) => handleEditActive("referred_by_flat_no", activeState)}
                                    isEditable={true}
                                    enableEditing={false}
                                    value={leadInfoData.data?.referred_by_flat_no}
                                    onValueChange={(value) => handleValueChange("referred_by_flat_no", value)}
                                    onClickHandler={() => { }}
                                    onEditClick={() => handleEditActive("referred_by_flat_no", true)}
                                    editDeactive={() => handleEditActive("referred_by_flat_no", false)}
                                    onSave={() => {
                                        updateCustomerInfo("referred_by_flat_no", leadInfoData.data.referred_by_flat_no, 'Reference Flat no')
                                        // handleEditActive("referred_by_flat_no", false)
                                    }}
                                    userProfile={userProfile}
                                    leadAccess={props.leadAccess}
                                />
                            </div>
                        </>}
                    </div>
                </div>
            </div>

        </>
    )
}
