import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'

const ConfirmCsv = ({ show, closeCancelModal, onSubmit, title, bodyText, submitButtonType, cancelButtonText, submitButtonText }) => {

    return (
        <PopUpModal
            setShow={closeCancelModal}
            show={show}

            body={<>
                <figure className='d-table mx-auto mb-20'><svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39"></path></svg></figure>
                <div className="text-center fz24 black fw-po-medium mb-14">Few unmapped values</div>
                <p className="black-dark-800 fz16 worksns text-center">
                    Columns have few unmapped values. If you confirm without mapping these unmapped values, they will be uploaded as blank.
                </p>

                <div className="justify-content-end d-flex modal-footer">
                    {cancelButtonText && <button variant="" className="cancel outline-btn" onClick={() => closeCancelModal(false)}>
                        {cancelButtonText}
                    </button>}
                    <button variant="" className={`${submitButtonType === 'delete' ? 'del-btn btn' : 'pr-btn'}`} onClick={onSubmit}>
                        {submitButtonText}
                    </button>
                </div>
            </>}
        />
    )
}

export default ConfirmCsv;