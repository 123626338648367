import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import SliderModal from './SliderModal';
import InputText from '../InputGroup/InputText';
import { connectFacebookFormSchema } from '../../validators/leads.validator';
import InputSelect from '../InputGroup/InputSelect';
import { getAllProjects, addProjectFormId, getProjectFormIds, getAllApikeys, generateApikey, getCompanyAgency, updateProjectMarketingAgency, getMakeIntegrationRouting } from '../../services/private/company.service';
import { useCallback } from 'react';
import EditTeamUser from '../Projects/EditTeamUser';
import { getAllTeams } from '../../services/public/public.service';
import IntegrationTeamCard from '../Projects/IntegrationTeamCard';
import Consumer from '../../helpers/context';

export default function ConnectFacebookFormModal({
    showConnectFacebookForm,
    setConnectFacebookForm,
    getAllProjectFormIds,
    apiKey,
    getApikey,
    selectedIntegration,
    clearSelectedIntegration
}) {
    const [projects, setProjects] = useState([]);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [teams, setTeams] = useState([]);
    const [addedTeams, setAddedTeams] = useState([]);
    const [routing, setRouting] = useState([]);
    const [showEditTeamUser, setShowEditTeamUser] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({})

    const { userProfile } = useContext(Consumer);
    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjects(projects);
            }
        })
    }

    const addProjectFormIDHandle = async (payload) => {
        await addProjectFormId({ ...payload, routing }).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                setConnectFacebookForm(false);
                clearSelectedIntegration();
                getAllProjectFormIds();
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const updateProjectFormIDHandle = async (payload) => {
        await updateProjectMarketingAgency(payload.formId,
            { marketingAgency: payload.marketingAgency, routing }).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                    setConnectFacebookForm(false)
                    clearSelectedIntegration()
                    getAllProjectFormIds()
                }
            }).catch(e => {
                toast.error(e.response.data.message)
            })
    }

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getIntegrationPageRouting = async () => {
        const res = await getMakeIntegrationRouting(selectedIntegration?.uuid);
        if (res.data.status === 200) {
            console.log(res.data.data, 'this ')
            let mappedTeams = res.data.data.map(t => ({
                ...t, users: t.users?.map(u => ({ ...u, isSelected: true }))
            }))

            mappedTeams.map(m => {
                const obj = teams.find(t => t.id === m.team_id)
                var ids = new Set(m.users.map(d => d.id));
                m.users = [...m?.users, ...obj?.users?.filter(d => !ids.has(d.id))];
            })

            setAddedTeams(mappedTeams);
            let mapped = res.data.data.map(t => ({
                team_id: t.team_id,
                users: t.users?.map(u => u.id)
            }))
            setRouting(mapped)
        }
    }


    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data?.map(t => ({ ...t, label: t.name, value: t.uuid }))
            setTeams(mapped);
        }
    }

    useEffect(() => {
        getAllProjectsByCompanyId();
        getAgencyData();
        getAll();
    }, []);

    useEffect(() => {
        if (selectedIntegration?.uuid && teams?.length) {
            getIntegrationPageRouting();
        }
    }, [selectedIntegration, teams])

    useEffect(() => {
        const load = async () => {
            await generateApikey();
            await getApikey();
        }

        if (!apiKey) load()
    }, [apiKey])

    const closePopup = useCallback(() => {
        setConnectFacebookForm(false);
        clearSelectedIntegration();
    }, []);

    const handleSaveRouting = (userRouting) => {
        const index = routing.findIndex(r => r.team_id === userRouting.team_id);
        if (index > -1) {
            let newRouting = [...routing]
            newRouting[index] = userRouting;
            setRouting(newRouting);
        } else {
            setRouting([...routing, userRouting])
        }
    }

    const handleRemoveAddedTeam = (index, team) => {
        let teamsClone = [...addedTeams];
        teamsClone.splice(index, 1);
        setAddedTeams(teamsClone);
        const routingIndex = routing.findIndex(r => (r.team_id || r.id) === (team.team_id || team.id));
        const routingClone = [...routing];
        routingClone.splice(routingIndex, 1)
        setRouting(routingClone);
    }

    return (
        <>
            <SliderModal
                show={showConnectFacebookForm}
                setShow={closePopup}
                title={selectedIntegration?.form_id ? "Edit Facebook Form" : "Connect Facebook Form"}
                body={
                    <>
                        <Formik
                            initialValues={{
                                formId: selectedIntegration?.form_id ? selectedIntegration?.form_id : '',
                                project: selectedIntegration?.name ? projects?.find(project => project.label === selectedIntegration?.name) : '',
                                agency: selectedIntegration?.marketing_agency ? agencyDetails?.find(agency => agency.label === selectedIntegration?.marketing_agency) : ''
                            }}
                            validationSchema={connectFacebookFormSchema}
                            onSubmit={async (values) => {
                                if (selectedIntegration?.form_id) {
                                    const { formId, agency } = values;
                                    await updateProjectFormIDHandle({ formId: formId.trim(), marketingAgency: agency ? agency.value : null })
                                } else {

                                    const { formId, project: { value: projectId }, agency } = values;
                                    await addProjectFormIDHandle({ formId: formId.trim(), projectId, marketingAgency: agency ? agency.value : null })
                                }
                            }}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize
                        >
                            {
                                (props) => {
                                    const { isValid, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                            <div className='h-full overflow-auto !px-5 !pb-10'>
                                                <InputText
                                                    value={values['formId']}
                                                    onChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    index={0}
                                                    error={errors['formId']}
                                                    touched={touched['formId'] || true}
                                                    type="text"
                                                    placeholder="Enter Form ID"
                                                    name="formId"
                                                    disable={selectedIntegration?.form_id ? true : false}
                                                    label={<> Enter Form ID</>}
                                                />
                                                <InputSelect
                                                    index={4}
                                                    name="project"
                                                    id="project"
                                                    error={errors['project']}
                                                    touched={touched['project']}
                                                    value={values['project']}
                                                    options={projects}
                                                    placeholder="Select Project"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "project", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    disable={selectedIntegration?.name ? true : false}
                                                    label={<>Select Project </>}
                                                />
                                                <InputSelect
                                                    index={4}
                                                    name="agency"
                                                    id="agency"
                                                    error={errors['agency']}
                                                    touched={touched['agency']}
                                                    value={values['agency']}
                                                    options={agencyDetails}
                                                    placeholder="Select Marketing Agency"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "agency", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    label={<>Select Marketing Agency (Optional) </>}
                                                />
                                                {userProfile?.allowed_modules?.includes('teams_module') && <InputSelect
                                                    label='Select Team'
                                                    placeholder='Select Team'
                                                    value={''}
                                                    onChange={(value) => {
                                                        if (!value?.users) {
                                                            toast.warning(`There is no user added in ${value?.name}`)
                                                            return;
                                                        }
                                                        const projectIndex = addedTeams.findIndex(t => t.name === value.name);
                                                        if (projectIndex === -1) {
                                                            setAddedTeams([...addedTeams, value]);
                                                        }
                                                    }}
                                                    // styles={customStyles}
                                                    options={teams}
                                                />}


                                                {addedTeams.map((team, index) => (
                                                    <div className='mt-2'>
                                                        <IntegrationTeamCard
                                                            key={`${team.uuid}_${index}`}
                                                            team={team}
                                                            setShowEditTeamUser={setShowEditTeamUser}
                                                            setSelectedTeam={setSelectedTeam}
                                                            handleRemoveAddedTeam={handleRemoveAddedTeam}
                                                            index={index}
                                                        />
                                                    </div>
                                                ))
                                                }

                                                <div className=" d-flex flex-column align-items-center justify-content-center !pt-20">
                                                    <figure>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                                                            <g opacity="0.9" clipPath="url(#clip0_2708_52056)">
                                                                <path d="M20.5 40C25.8043 40 30.8914 37.8929 34.6421 34.1421C38.3929 30.3914 40.5 25.3043 40.5 20C40.5 14.6957 38.3929 9.60859 34.6421 5.85786C30.8914 2.10714 25.8043 0 20.5 0C15.1957 0 10.1086 2.10714 6.35786 5.85786C2.60714 9.60859 0.5 14.6957 0.5 20C0.5 25.3043 2.60714 30.3914 6.35786 34.1421C10.1086 37.8929 15.1957 40 20.5 40ZM17.5 29C17.5 28.2044 17.8161 27.4413 18.3787 26.8787C18.9413 26.3161 19.7044 26 20.5 26C21.2956 26 22.0587 26.3161 22.6213 26.8787C23.1839 27.4413 23.5 28.2044 23.5 29C23.5 29.7956 23.1839 30.5587 22.6213 31.1213C22.0587 31.6839 21.2956 32 20.5 32C19.7044 32 18.9413 31.6839 18.3787 31.1213C17.8161 30.5587 17.5 29.7956 17.5 29ZM18.532 9.64C18.6155 9.17887 18.8583 8.7617 19.2179 8.4613C19.5776 8.1609 20.0314 7.99634 20.5 7.99634C20.9686 7.99634 21.4224 8.1609 21.7821 8.4613C22.1417 8.7617 22.3845 9.17887 22.468 9.64L22.5 10V20L22.468 20.36C22.3845 20.8211 22.1417 21.2383 21.7821 21.5387C21.4224 21.8391 20.9686 22.0037 20.5 22.0037C20.0314 22.0037 19.5776 21.8391 19.2179 21.5387C18.8583 21.2383 18.6155 20.8211 18.532 20.36L18.5 20V10L18.532 9.64Z" fill="#FD9A39" fillOpacity="0.9" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2708_52056">
                                                                    <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </figure>
                                                    <p className="text-center black-dark-600 fz14 mb-0">Note : Once created, it cannot be modified</p>
                                                </div>
                                            </div>
                                            <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                                <button
                                                    className="cancel outline-btn"
                                                    onClick={() => {
                                                        setConnectFacebookForm(false)
                                                        clearSelectedIntegration()
                                                    }}
                                                    type='button'
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="pr-btn"
                                                    type="submit"
                                                >
                                                    {selectedIntegration?.form_id ? 'Update' : 'Create New'}
                                                </button>

                                            </div>
                                        </form>

                                    )
                                }}
                        </Formik>
                    </>
                }
            />
            {
                showEditTeamUser &&
                <EditTeamUser
                    show={showEditTeamUser}
                    closeModal={() => setShowEditTeamUser(false)}
                    selectedTeam={selectedTeam}
                    handleSaveRouting={handleSaveRouting}
                />
            }
        </>
    )
}