import React, { useEffect, useState } from 'react'
import BasicModal from '../../Modals/BasicModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { toINRFormat } from '../../../helpers/helpers';
import { generateDemandPdf, getEOIForDiscount, getPendingDemandByBookingId, raiseDemand, raiseEOIDemand, updateDemand } from '../../../services/private/inventory.service';
import { toast } from 'react-toastify';
import DateTimePicker from '../../InputGroup/DateTime';
import InputRupees from '../../InputGroup/InputRupees';

const customStyles = {
    menu: (base, state) => ({
        ...base,
        zIndex: 99
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '34px',
        borderRadius: '4px',
        fontSize: '14px',
        height: '32px',
        fontWeight: '400',
        cursor: 'pointer',
        zIndex: 99,
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),

    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        marginTop: '-5px',
        cursor: 'pointer',
        zIndex: 99,
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '34px',
    }),
    indicatorSeparator: () => ({ display: "none" }),

};

const AddDemand = ({ show, closeModal, paymentMilestones, item, onSuccess, demandRaiseFrom, editDemandData }) => {
    const [selectedMilestones, setSelectedMilestones] = useState([]);
    const [interestDue, setInterestDue] = useState('');
    const [interestWaveOff, setInterestWaveOff] = useState('');
    const [interest, setInterest] = useState('');
    const [interestGST, setInterestGst] = useState('');
    const [total, setTotal] = useState('');
    const [remarks, setRemarks] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueAmount, setDueAmount] = useState('');
    const [totalAmountDue, setTotalAmountDue] = useState('');
    const [dueAmountGst, setDueAmountGst] = useState(0);
    const [totalInterest, setTotalInterest] = useState('');
    const [previousDemandDue, setPreviousDemandDue] = useState('');
    const [applyInterest, setApplyInterest] = useState(false);
    const [error, setError] = useState(false);
    const [applyEOIDiscount, setApplyEOIDiscount] = useState(false);
    const [eoiDiscount, setEOIDiscount] = useState(0);
    const [downloadDisable, setDownloadDisable] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [demandUUID, setDemandUUID] = useState('');
    const [previousDemands, setPreviousDemands] = useState([]);
    const [selectAllDemands, setSelectAllDemands] = useState(false);
    const [isDemandRaised, setIsDemandRaised] = useState(false);

    useEffect(() => {
        if (selectedMilestones.length > 0) {
            let dueAmount = 0;
            selectedMilestones.forEach(milestone => {
                if (milestone.payment_percentage) {
                    dueAmount += (parseInt(item?.adjusted_price || item?.final_price || 0) * (parseInt(milestone.payment_percentage) / 100))
                }
                if (milestone.payment_amount) {
                    dueAmount += parseInt(milestone.payment_amount)
                }
            })
            if (eoiDiscount) {
                dueAmount -= parseInt(eoiDiscount)
            }

            setDueAmount(dueAmount)
            // setDueAmountGst(dueAmount * (18 / 100))
        }

    }, [selectedMilestones, eoiDiscount]);

    const getPreviousDemand = async (uuid) => {
        const res = await getPendingDemandByBookingId(uuid);
        if (res.data.status === 200) {
            setPreviousDemands(res.data.data);
        }
    }

    const getEOIDiscount = async () => {
        const res = await getEOIForDiscount(item?.id);
        if (res.data.status === 200) {
            if (res.data.data?.token_amount) {
                // console.log(res.data.data);
                setEOIDiscount(res.data.data?.token_amount);
                setApplyEOIDiscount(true)
            }
        }
    }

    useEffect(() => {
        if (item?.id) {
            getEOIDiscount();
        }
        if (item?.booking_uuid) {
            getPreviousDemand(item?.booking_uuid);
        }
    }, [item]);

    useEffect(() => {
        if (editDemandData?.uuid) {
            if (editDemandData?.milestone_id) {
                let ids = paymentMilestones.filter(item => {
                    return editDemandData?.milestone_id.find(milestone => milestone === item.id)
                })
                ids = ids.map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }))
                setSelectedMilestones(ids)
            }
            setDueAmount(editDemandData?.due_amount);
            // setDueAmountGst(editDemandData?.gst_amount);            
            setInterestDue(editDemandData?.interest_due);
            setInterestWaveOff(editDemandData?.interest_waived_off);
            setInterest(editDemandData?.net_interest);
            setInterestGst(editDemandData?.interest_gst);
            setTotal(parseInt(editDemandData?.net_interest || 0) + parseInt(editDemandData?.interest_gst || 0))
            setTotalInterest(editDemandData?.total_interest);
            setTotalAmountDue(editDemandData?.total_amount_due);
            setPreviousDemandDue(editDemandData?.setPreviousDemandDue);
            setDueDate(editDemandData?.due_date);
            setRemarks(editDemandData?.remarks);
            setEOIDiscount(editDemandData?.eoi_discount)
            if (editDemandData?.interest_due || editDemandData?.interest_waived_off) {
                setApplyInterest(true);
            }
            if (editDemandData?.eoi_discount) {
                setApplyEOIDiscount(true);
            }
        }
    }, [editDemandData]);

    useEffect(() => {
        let totalPrice = 0;
        if (dueAmount) {
            totalPrice += parseInt(dueAmount)
        }
        if (dueAmountGst) {
            totalPrice += parseInt(dueAmountGst)
        }
        if (total) {
            totalPrice += parseInt(total);
        }
        // if (eoiDiscount && dueAmount) {
        //     totalPrice -= parseInt(eoiDiscount);
        // }
        setTotalAmountDue(totalPrice);

    }, [dueAmount, dueAmountGst, total, eoiDiscount])

    const handleSubmit = async () => {
        if (!dueDate || selectedMilestones.length === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        const payload = {
            milestone_id: selectedMilestones.map(item => item.id),
            milestone_no: selectedMilestones.map(item => item.count),
            due_amount: dueAmount ? (parseInt(dueAmount) - parseInt(eoiDiscount)) : null,
            gst_amount: dueAmountGst || null,
            interest_due: interestDue || null,
            interest_waived_off: interestWaveOff || null,
            net_interest: interest || null,
            interest_gst: interestGST || null,
            total_interest: totalInterest || null,
            total_amount_due: totalAmountDue || null,
            previous_demand_dues: previousDemandDue || null,
            remarks: remarks,
            due_date: dueDate || null,
            eoi_discount: eoiDiscount || null
        }
        let res = {};
        if (demandRaiseFrom === 'EOI table') {
            res = await raiseEOIDemand(item?.eoi_uuid, payload);
        } else {
            res = await raiseDemand(item?.booking_uuid, payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            setDownloadDisable(false);
            setDemandUUID(res.data.data.uuid);
            onSuccess();
            setIsDemandRaised(true);
        }
    }

    const handleUpdateDemand = async () => {
        if (!dueDate || selectedMilestones.length === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        const payload = {
            milestone_id: selectedMilestones.map(item => item.id),
            milestone_no: selectedMilestones.map(item => item.count),
            due_amount: dueAmount ? parseInt(dueAmount) - parseInt(eoiDiscount) : null,
            gst_amount: dueAmountGst || null,
            interest_due: interestDue || null,
            interest_waived_off: interestWaveOff || null,
            net_interest: interest || null,
            interest_gst: interestGST || null,
            total_interest: totalInterest || null,
            total_amount_due: totalAmountDue || null,
            previous_demand_dues: previousDemandDue || null,
            remarks: remarks,
            due_date: dueDate || null
        }
        let res = {};
        res = await updateDemand(editDemandData?.uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setDownloadDisable(false);
            setDemandUUID(res.data.data.uuid)
            onSuccess();
            setIsDemandRaised(true);
            // closeModal()
        }
    }

    const handleDownloadPdf = async () => {
        setDownloading(true);
        const res = await generateDemandPdf(demandUUID);
        if (res.data.status === 200) {
            setDownloading(false);
            window.open(res.data.data.url, "_blank");
            // onSuccess();
            closeModal()
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>
                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>{editDemandData?.uuid ? 'Update' : 'Raise'} Demand for {item?.customer_name}</h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div>
                        <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Demand</h2>
                    </div>
                    <div className='!mt-5 relative z-10'>
                        <InputSelect
                            label='Payment Milestone'
                            options={paymentMilestones}
                            value={selectedMilestones}
                            onChange={(value) => {
                                setSelectedMilestones(value)
                                if (value.length == 0) {
                                    setDueAmount('')
                                }
                            }}
                            isMulti={true}
                            customStyles={customStyles}
                            error={error && selectedMilestones.length === 0 ? 'Please Select Payment Milestone' : ''}
                        />
                    </div>
                    {selectedMilestones.length > 0 && <div className='!mt-5'>
                        <div className='def-table plantable h-auto !overflow-auto !p-0'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='!w-auto text-left !min-w-fit !max-w-fit'>Milestone</th>
                                        <th>Description</th>
                                        <th>Part Payment </th>
                                        <th>Due Amount</th>
                                        {/* <th>Pending Due Amt (inc. GST)</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedMilestones.map((milestone, index) => (
                                            <tr>
                                                <td className='!w-auto text-left !min-w-fit !max-w-fit'>Milestone: {milestone.count}</td>
                                                <td>{milestone.description}</td>
                                                <td>{milestone.payment_percentage ? `${Math.trunc(milestone.payment_percentage)}%` : `₹ ${toINRFormat(milestone.payment_amount)}`}</td>
                                                <td>{milestone.payment_percentage ? `₹ ${toINRFormat(parseInt(item?.adjusted_price || item?.final_price || 0) * (parseInt(milestone.payment_percentage) / 100))}` : `₹ ${toINRFormat(milestone.payment_amount)}`}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>}
                    {
                        selectedMilestones.length > 0 && previousDemands.length > 0 &&
                        <>
                            <div className='!mt-5'>
                                <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Previous Dues</h2>
                            </div>
                            <div className='!mt-5'>
                                <div className='def-table plantable h-auto !overflow-auto !p-0'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className='inline-flex gap-x-2 items-center'>
                                                        <input
                                                            type="checkbox"
                                                            name=""
                                                            id=""
                                                            value={selectAllDemands}
                                                            checked={selectAllDemands}
                                                            onChange={() => {
                                                                setSelectAllDemands(!selectAllDemands)
                                                                if (!selectAllDemands) {
                                                                    let newDemands = previousDemands.map(item => ({ ...item, ['isDemandSelected']: true }));
                                                                    setPreviousDemands(newDemands);
                                                                } else {
                                                                    // let demands = [...previousDemands];
                                                                    let newDemands = previousDemands.map(item => ({ ...item, ['isDemandSelected']: false }));
                                                                    setPreviousDemands(newDemands);
                                                                }
                                                                let demandsAmount = 0;
                                                                previousDemands.forEach(item => {
                                                                    demandsAmount += parseInt(item.total_amount_due)
                                                                })
                                                                let totalAmount = 0;
                                                                if (selectAllDemands) {
                                                                    totalAmount = parseInt(dueAmount) - parseInt(demandsAmount);
                                                                    // setDueAmount(amount)
                                                                } else {
                                                                    totalAmount = parseInt(dueAmount) + parseInt(demandsAmount);
                                                                }
                                                                setDueAmount(totalAmount)
                                                            }}
                                                        />
                                                    </span>
                                                </th>
                                                <th className='!w-auto text-left !min-w-fit !max-w-fit'>All Demands</th>
                                                <th>Pending Due Amt</th>
                                                {/* <th>Pending Due Amt (inc. GST)</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                previousDemands.map((dues, index) => (
                                                    <tr key={dues.uuid}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name=""
                                                                id=""
                                                                value={dues?.isDemandSelected}
                                                                checked={dues?.isDemandSelected}
                                                                onChange={() => {
                                                                    let demands = [...previousDemands];
                                                                    demands[index]['isDemandSelected'] = !dues?.isDemandSelected
                                                                    setPreviousDemands(demands)
                                                                    if (!dues?.isDemandSelected) {
                                                                        let amount = parseInt(dueAmount) - parseInt(dues.total_amount_due)
                                                                        setDueAmount(amount)
                                                                    } else {
                                                                        let amount = parseInt(dueAmount) + parseInt(dues.total_amount_due)
                                                                        setDueAmount(amount)
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className='!w-auto text-left !min-w-fit !max-w-fit'># {dues.unique_id} (Milestone: {dues.milestone_no.join(', ')})</td>
                                                        <td>{`₹ ${toINRFormat(dues.total_amount_due)}`}</td>
                                                        {/* <td>1,89,299</td> */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </>
                    }
                    {/* <div className='!mt-5'>
                        <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>Pending Collection Verification</h2>
                    </div>
                    <div className='!mt-5'>
                        <InputText />
                    </div> */}


                    <div className='flex justify-between items-center !mt-5 '>
                        <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>
                            Interest Dues
                        </h2>
                        <div className='inline-flex items-center text-sm inter text-black700 gap-x-2'>
                            Apply Interest
                            <input type="checkbox" name="interes" id="" checked={applyInterest} onChange={() => setApplyInterest(!applyInterest)} />
                        </div>
                    </div>
                    {applyInterest && <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>
                        <InputRupees
                            label='Interest Due'
                            placeholder='0'
                            value={interestDue}
                            onChange={(value) => {
                                setInterestDue(value)
                                if (!value) {
                                    setTotal('')
                                }
                                if (value) {
                                    setTotal(value)
                                }
                            }}
                            handleBlur={(value) => {
                                // if (value) {
                                //     setDueAmount(parseInt(dueAmount) - parseInt(value))
                                // }
                            }}
                        />
                        <InputRupees
                            inputclass='!m-0'
                            label='Interest waved off.'
                            placeholder='0'
                            value={interestWaveOff}
                            onChange={(value) => {

                                if (!value) {
                                    setInterestWaveOff(value);
                                    setInterest('');
                                    setInterestGst('');
                                    setTotal('');
                                }
                                if (value && (parseInt(value) <= parseInt(interestDue))) {
                                    setInterestWaveOff(value)
                                    let interest = parseInt(interestDue) - parseInt(value);
                                    let interestGst = (interest * (18 / 100))
                                    setInterest(interest)
                                    setInterestGst(interestGst)
                                    setTotal(interest + interestGst)
                                }

                            }}
                            handleBlur={() => { }}
                        />
                        <InputRupees
                            inputclass='!m-0'
                            label='Net Interest'
                            placeholder='0'
                            value={parseInt(interestDue) - parseInt(interestWaveOff)}
                            disable={true}
                            handleBlur={() => { }}
                        />
                        <InputRupees
                            inputclass='!m-0'
                            label='GST Amount'
                            placeholder='0'
                            value={interestGST}
                            disable={true}
                            handleBlur={() => { }}

                        />
                        <InputRupees
                            inputclass='!m-0'
                            label='Total Interest Due (Incl. Of Gst)'
                            placeholder='0'
                            value={total}
                            onChange={(value) => setTotal(value)}
                            disable={true}
                            handleBlur={() => { }}
                        />
                    </div>}

                    {/* Token discount  */}
                    {!item?.demand_eoi_discount && eoiDiscount > 0 && <>
                        <div className='flex justify-between items-center !mt-5 '>
                            <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>
                                Token Discount
                            </h2>
                            <div className='inline-flex items-center text-sm inter text-black700 gap-x-2'>
                                Apply Token Discount
                                <input type="checkbox" name="interes" id="" checked={applyEOIDiscount} onChange={() => {
                                    setApplyEOIDiscount(!applyEOIDiscount)
                                    let totalPrice = 0
                                    if (!applyEOIDiscount) {
                                        if (eoiDiscount && dueAmount) {
                                            totalPrice = parseInt(dueAmount) - parseInt(eoiDiscount);
                                        }
                                    } else {
                                        if (eoiDiscount && dueAmount) {
                                            totalPrice = parseInt(dueAmount) + parseInt(eoiDiscount);
                                        }
                                    }
                                    setTotalAmountDue(totalPrice);
                                }} />
                            </div>
                        </div>
                        {applyEOIDiscount && <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>
                            <InputRupees
                                label='Token Discount'
                                placeholder={'Enter Token Discount'}
                                value={eoiDiscount}
                                onChange={(value) => setEOIDiscount(value)}
                                disable={true}
                                handleBlur={() => { }}
                            />
                        </div>
                        }
                    </>}
                    <div className='!mt-5 '>
                        <h2 className='text-textblack sm:text-textblack text-lg leading-7 font-semibold !mb-0 inter'>
                            Due Date / Remarks
                        </h2>
                        <div className='!mt-5 grid grid-cols-2 gap-x-9 gap-y-5'>
                            <DateTimePicker
                                label='Due Date'
                                inputclass='mt-0'
                                placeholder='Select Date'
                                value={dueDate}
                                onValueChange={(date) => setDueDate(date)}
                                showTime={false}
                                error={error && !dueDate ? 'Please Select Due Date' : ''}
                            />
                            <div>
                                <InputTextarea
                                    label='Comments'
                                    placeholder='Enter your comments...'
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='!mt-5 flex flex-col gap-y-3'>
                        {/* <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">Aminities</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ 9,400</div>
                        </div>
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">Aminities Discount</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ 9,400</div>
                        </div> */}
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">Due Amount</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(parseInt(dueAmount) || 0)}</div>
                        </div>
                        {/* <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">GST  : 18%</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {dueAmountGst}</div>
                        </div> */}
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">Total Interest Due</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {toINRFormat(total) || '0'}</div>
                        </div>
                        {/* <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Total Discount</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ 4,28,200</div>
                        </div> */}

                    </div>
                    {/* <div className="!mt-5 flex flex-col gap-y-3">
                        <div className="flex flex-row justify-between items-center inter">
                            <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Unit Price</div>
                            <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">₹ 64,00,000</div>
                        </div>
                        <div className="flex flex-row justify-between items-center inter">
                            <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Total Discount</div>
                            <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">- ₹ 4,28,200</div>
                        </div>

                    </div> */}
                    {/* <div className="!mt-5 !border-t border-b !border-grayLigh !pt-4 !pb-4">
                        <div className="flex flex-row justify-between items-center inter">
                            <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold">Grand Total</div>
                            <div className="sm:text-xl text-lg font-semibold leading-6 text-textblack text-right">
                                <div>- ₹ 9,400</div>
                                <div className="sm:text-sm text-xs text-black700 font-normal">For $Customer_Name</div>
                            </div>
                        </div>
                    </div> */}
                    {totalAmountDue > 0 && <div className='flex justify-between border border-primary bg-blue-light p-3 my-4 rounded-lg'>
                        <h3 className="m-0 inter sm:text-xl text-lg text-textblack font-semibold">Total Demand Raised  </h3>
                        <h3 className="m-0 inter sm:text-xl text-lg text-textblack font-semibold"> ₹ {toINRFormat(totalAmountDue)}</h3>
                    </div>}
                </div>

                <div className="!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col">
                    <div>
                        <h3 className="m-0 inter sm:text-xl text-lg text-textblack font-semibold">Total Due Amt : ₹ {toINRFormat(totalAmountDue)}</h3>
                        <p className="m-0 text-sm inter text-black700 !mt-1">For {item?.unit_name}</p>
                    </div>
                    <div className="flex gap-3 items-center justify-end">
                        {(!downloadDisable || item?.demand_uuid) && <button
                            className="text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[202px] sm:basis-[202px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter inline-flex items-center justify-center gap-x-2"
                            onClick={handleDownloadPdf}
                            disabled={(downloading)}
                        >
                            {
                                downloading ? 'Generating...' : <><DownloadIcon /> Download as PDf</>
                            }

                        </button>}
                        {!isDemandRaised && <button
                            className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[160px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter"
                            onClick={() => {
                                if (editDemandData?.uuid) {
                                    handleUpdateDemand();
                                } else {
                                    handleSubmit();
                                }
                            }
                            }
                        >
                            {editDemandData?.uuid ? 'Update' : 'Raise'} Demand</button>}
                        {isDemandRaised && <button
                            className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[160px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter"
                            onClick={() => closeModal()}
                        >
                            Done</button>}
                    </div>
                </div>

            </div>
        </BasicModal>
    )
}

export default AddDemand
