import React from 'react'

const Countes = ({ heading, subheading }) => {
    return (
        <div className='!border border-grayLight rounded-[10px] !p-4 max-w-[190px] grow-0 shrink-0 basis-[190px]'>
            <h1 className='mb-0 inter text-xl leading-7 !text-black font-semibold'>{heading}</h1>
            <p className='!mt-2 mb-0 inter text-xs text-black700 leading-4 uppercase tracking-wide	'>{subheading}</p>
        </div>
    )
}

export default Countes
