import React from 'react';
import FileInputField from '../form-fields/file-input-fields';
import InputField from '../form-fields/input-field';
import PrimaryButton from '../form-fields/primary-button';
import SelectField from '../form-fields/select-filed';
import TextareaField from '../form-fields/textarea-field';
import InputText from '../InputGroup/InputText';
import InputSelect from '../InputGroup/InputSelect';

const BasicDetails = ({
    id,
    basicDetails,
    handleChangeBasicDetails,
    enabled,
    handelChangeThumbnail,
    category,
    handleSelectCategory,
    categoryVal,
    handleSubmitBasicDetails,
    handleUpdateBasicDetails,
    mentorList,
    handleChangePromoMentor,
    promoMentors,
    basicFieldError
}) => {

    return (
        <>
            <div className="grid grid-cols-2 gap-6  overflow-auto">
                <InputText
                    label='Name'
                    inputRequired={true}
                    placeholder='e.g. Marketing Workshop'
                    type='text'
                    name='name'
                    inputClass='h-[45px]'
                    value={basicDetails.name}
                    onChange={handleChangeBasicDetails}
                    error={basicFieldError && !basicDetails.name}
                />
                <FileInputField
                    label='Card Thumbnail (Less than 1mb, 295 X 186px)'
                    inputRequired={true}
                    placeholder='Upload Thumbnail'
                    type='file'
                    accept='image/*'
                    name='card_thumbnail'
                    inputClass='h-[45px]'
                    onChange={handelChangeThumbnail}
                    error={basicFieldError && !basicDetails.card_thumbnail}
                />
                <FileInputField
                    label='Poster (Detail Page, Less than 1mb, 825px X 520px)'
                    inputRequired={!enabled}
                    placeholder='Upload Poster'
                    type='file'
                    inputClass='h-[45px]'
                    accept='image/*'
                    name='thumbnail'
                    onChange={handelChangeThumbnail}
                    error={basicFieldError && !enabled && !basicDetails.thumbnail}
                />



                <InputText
                    label='Session Date'
                    inputRequired={true}
                    type='date'
                    inputClass='relative'
                    name='session_date'
                    min={new Date().toISOString().split('T')[0]}
                    value={basicDetails.session_date}
                    onChange={handleChangeBasicDetails}
                    error={basicFieldError && !basicDetails.session_date}
                />
                <InputSelect
                    label='Category'
                    inputRequired={true}
                    name='Select Category'
                    placeholder='Select Category'
                    inputClass='h-[45px]'
                    options={category}
                    value={categoryVal || ''}
                    onChange={handleSelectCategory}
                    isMulti={true}
                    error={basicFieldError && Object.keys(categoryVal).length === 0}
                />
                <InputSelect
                    label='Mentor'
                    inputRequired={false}
                    value={promoMentors || ''}
                    options={mentorList}
                    onChange={(value) => handleChangePromoMentor(value)}
                    name='Select Mentor'
                    placeholder='Select Mentor'
                    isMulti={true}
                />
                <div className='grid grid-cols-2 no-label gap-x-3'>
                    <div className='col-span-2 w-full text-14 text-black700 mb-2'>
                        Start Time & End Time {!enabled && <sup className='text-red text-14'>*</sup>}
                    </div>
                    <InputField
                        divClass=''
                        placeholder='Start time'
                        type='time'

                        name='start_time'
                        value={basicDetails.start_time}
                        onChange={handleChangeBasicDetails}

                        error={basicFieldError && !enabled && !basicDetails.start_time}

                    />
                    <InputField
                        placeholder='End time'
                        type='time'
                        name='end_time'
                        value={basicDetails.end_time}
                        onChange={handleChangeBasicDetails}
                        // inputRequired={!enabled}
                        error={basicFieldError && !enabled && !basicDetails.end_time}
                    />

                </div>

                <InputField
                    label='Video URL'
                    inputRequired={false}
                    placeholder='Video URL'
                    type='text'
                    name='video_url'
                    inputClass='h-[45px]'
                    value={basicDetails.video_url}
                    onChange={handleChangeBasicDetails}
                />
                <TextareaField divClass='col-span-2'
                    label='Short Description (Detail Page)'
                    placeholder='Add short discription'
                    name='short_description'
                    value={basicDetails.short_description}
                    onChange={handleChangeBasicDetails}
                />
            </div>

            <div className='h-px bg-black200 mt-2'></div>
            {enabled && <PrimaryButton
                className='px-8 py-2 font-medium text-14 d-table ml-auto mt-4 '
                name='Submit'
                onClick={() => {
                    if (id) {
                        handleUpdateBasicDetails()
                    } else {
                        handleSubmitBasicDetails()
                    }
                }}
            ></PrimaryButton>}
        </>
    )
}

export default BasicDetails