import * as Yup from 'yup';
import { ALL_REGEX } from '../config/static';

export const addLeadSchemaMultiProject = Yup.object().shape({
    "name": Yup.string().required('Lead Name field is required'),
    "mobile": Yup.string().required('Mobile is required').min(10, 'Phone number is not valid'),
    "Lead Source": Yup.object().required("Lead Source is required!"),
    "Project Name": Yup.array().required("Project Name is required!"),
    "email": Yup.string().matches(ALL_REGEX.EMAIL, "Please enter valid email id"),
})

export const addLeadSchemaSingleProject = Yup.object().shape({
    "name": Yup.string().required('Lead Name field is required'),
    "mobile": Yup.string().required('Mobile is required').min(10, 'Phone number is not valid'),
    "Lead Source": Yup.object().required("Lead Source is required!"),
    "Project Name": Yup.object().required("Project Name is required!"),
    "email": Yup.string().matches(ALL_REGEX.EMAIL, "Please enter valid email id"),
})

export const addFollowupSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required('Date is required').nullable(),
    "remarks": Yup.string(),
    // "leadId": Yup.object().required('Lead name is required'),
    "intent": Yup.array().required("Intent is required"),
    "info": Yup.object().required("Reason is required")
})

export const addFinalFollowupSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required('Date is required').nullable(),
    "remarks": Yup.string(),
    // "leadId": Yup.object().required('Lead name is required'),
    "intent": Yup.array().required("Intent is required"),
})

export const inviteUserSchema = Yup.object().shape({
    "name": Yup.string().required('Name is Required'),
    "role": Yup.object().required("Role is required"),
    "email": Yup.string().required("Email is required").matches(ALL_REGEX.EMAIL, "Please enter valid email id"),
    "mobile": Yup
        .string()
        .required('Mobile is required')
        .min(10, "Phone number is not valid")
    // "mobile": Yup.string().required('Mobile is required').matches(
    //     ALL_REGEX.PHONE_NUMBER,
    //     "Phone number is not valid"
    // )
})

export const createAgencySchema = Yup.object().shape({
    "name": Yup.string().required('Agency name is Required'),
})

export const createCustomSourceSchema = Yup.object().shape({
    "display_name": Yup.string().required('Custom Source is Required'),
})

export const addProjectSchema = Yup.object().shape({
    "name": Yup.string().required('Project Name is required'),
    "developer": Yup.string().required('Developer Name is required'),
    "area": Yup.object().required("Project Address is required"),
})

export const addNewFollowupSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Scheduled Date is required").nullable(),
    "remarks": Yup.string(),
    "leadId": Yup.object().required("No Lead Selected"),
})


export const addNewSiteVisitSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Scheduled Date is required").nullable(),
    "remarks": Yup.string(),
    "leadId": Yup.object().required("No Lead Selected"),
})

export const bookingDoneSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Booking Date is required").nullable(),
    "remarks": Yup.string(),
    "projectId": Yup.number().required("Project is Required"),
    "amount": Yup.number().required('Amount is Required'),
    "agreementPrice": Yup.number().required('Net. Purchase Price is Required')
})

export const deadLeadSchema = Yup.object().shape({
    "reason": Yup.object().required('Reason is required'),
    "comment": Yup.string()
})

export const updateSiteVisitSchemaMultiProject = Yup.object().shape({
    "scheduleDate": Yup.string().required("Site Visit Date is required").nullable(),
    "remarks": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.array().required("Project is required"),
    "intent": Yup.array().required("Intent is required"),
    // "leadId": Yup.object().required('Lead name is required')
})

export const updateSiteVisitSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Site Visit Date is required").nullable(),
    "remarks": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.object().required("Project is required"),
    "intent": Yup.array().required("Intent is required"),
    // "leadId": Yup.object().required('Lead name is required')
})

export const siteVisitDoneSchemaMultiProject = Yup.object().shape({
    "scheduleDate": Yup.string().required("Site Visit Date is required").nullable(),
    "remarks": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.array().required("Project is required"),
    "intent": Yup.array().required("Intent is required")
})

export const siteVisitDoneSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Site Visit Date is required").nullable(),
    "remarks": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.object().required("Project is required"),
    "intent": Yup.array().required("Intent is required")
})

export const siteVisitCancelledSchema = Yup.object().shape({
    // "scheduleDate": Yup.string().required("Site Visit Date is required").nullable(),
    "remarks": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.array().required("Project is required"),
    "intent": Yup.object().required("Intent is required"),
    "reason": Yup.object().required("Reason is required")
})

export const connectFacebookFormSchema = Yup.object().shape({
    "formId": Yup.string().required('Form ID is required'),
    "project": Yup.object().required("No Project Selected"),
    // "agency": Yup.object().required("No Agency Selected"),
})

export const connectGoogleAddFormSchema = Yup.object().shape({
    "title": Yup.string().required('Website name is required'),
    "weburl": Yup.string().required('Website address is required'),
    "project": Yup.object().required("No Project Selected"),
    "source": Yup.object().required("No Source Selected"),
    // "agency": Yup.object().required("No Agency Selected"),
})

export const connectHousingAddFormSchema = Yup.object().shape({
    "profileId": Yup.string().required('Profile id is required'),
    "externalProject": Yup.string().required('External project is required'),
    "project": Yup.object().required("No Project Selected"),
    // "agency": Yup.object().required("No Agency Selected"),
})

export const eoiDoneSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required("Token Date is required").nullable(),
    "remarks": Yup.string(),
    "unit_no": Yup.string(),
    "unit_price": Yup.string(),
    "validity": Yup.string(),
    "amount": Yup.string(),
    "followupScheduleDate": Yup.string().required("Follow up date is required").nullable(),
    "projectId": Yup.number().required("Project is required")
})

export const createLeadSchema = Yup.object().shape({
    "scheduleDate": Yup.string().required('Date is required').nullable(),
    "remarks": Yup.string(),
    // "leadId": Yup.object().required('Lead name is required'),
    "intent": Yup.object().required("Intent is required"),
    "assignTo": Yup.object().required("Closing Manager is required")
})