import React, { useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import TextEditor from '../Editor';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import { insertProjectReviews, uploadFile } from '../../../services/public/public.service';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import InputTextarea from '../../../components/InputGroup/InputTextarea';

const SEOModal = ({ show, closeModal, style, projectUuid }) => {
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async () => {
        // const res = await insertProjectReviews(projectUuid, reviews);
        // if ((res.data.status === 200)) {
        //     console.log(res.data.data);
        // }
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    SEO Info</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>

                <div className=''>
                    {/* <div className='!mb-3 flex justify-between'>
                        <h5 className="text-textblack text-sm leading-6 font-semibold tracking-[-0.2px]" >
                            SEO Info
                        </h5>
                    </div> */}
                    <div className='flex flex-col gap-y-4'>
                        <InputText
                            label='Meta Title'
                            type={'text'}
                            placeholder={'Enter Meta Title'}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                        <div className=''>
                            <InputTextarea
                                label={'Meta Description'}
                                placeholder={'Enter Meta Description'}
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.traget.value)
                                }} />
                        </div>
                        <InputText
                            label="Permalink (URL)"
                            placeholder="Enter Permalink (URL)"
                            type="text"
                            value={link}
                            inputclass='m-0 inter'
                            onChange={(e) => {
                                setLink(e.target.value);
                            }}
                        />
                    </div>

                </div>

            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={handleSubmit} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default SEOModal;