import React, { useEffect, useState } from 'react'
import { ReactComponent as PlushIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as Whatsapp } from '../../../assets/icons/whatsapp.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import UploadConstructionDoc from '../Modal/UploadConstructionDoc';
import { getConstructionUpdate } from '../../../services/private/inventory.service';
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/threedots.svg';
import moment from 'moment';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';

const Construction = ({ uuid, selectedProject }) => {
    const [showDocUploadModal, setShowDocUploadModal] = useState(false);
    const [constructionDoc, setConstructionDoc] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState({})

    const getConstructionDocs = async () => {
        const res = await getConstructionUpdate(selectedProject.uuid);
        if (res.data.status === 200) {
            setConstructionDoc(res.data.data);
        }
    }

    useEffect(() => {
        if (selectedProject?.uuid) {
            getConstructionDocs();
        }
    }, [selectedProject])

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div className='flex flex-row items-center'>
                <div className='w-6/12'>
                    <h2 className='inter text-textblack font-semibold text-xl mb-0'>Construction Updates</h2>
                </div>
                {/* {constructionDoc.length > 0 && <div className='w-6/12'>
                    <div className='flex items-center gap-x-3 justify-end'>
                        <div>
                            <button
                                className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                onClick={() => setShowDocUploadModal(true)}
                            >
                                <PlushIcon />Upload
                            </button>
                        </div>
                    </div>
                </div>} */}
            </div>
            <div className='mr-2.5  max-w-[862px] mx-auto divide-y divide-grayLight space-y-5'>
                {
                    constructionDoc?.map((doc, index) => (

                        <div className='!pt-5'>
                            <div className='flex justify-between items-center'>
                                <h2 className='text-xl text-textblack font-semibold m-0 inter'>{moment(doc.update_date).format('Do MMM YYYY')}</h2>
                                {/* <button className='border border-grayLight bg-black200 inline-flex items-center !py-1 !px-2 rounded text-sm font-medium leading-5 text-black700 gap-x-1'><Whatsapp />Share on WhatsApp</button> */}
                                {/* <div className='flex justify-end items-center'>                                    
                                    <div className='dropdown view-more'>
                                        <button className="bg-tr border-0 d-table ml-auto mr-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <ThreedotsIcon />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <a
                                                className="dropdown-item text-capitalize"
                                                onClick={() => {
                                                    setSelectedDocs(doc);
                                                    setShowDocUploadModal(true);
                                                }}
                                            >
                                                Edit Update
                                            </a>                                           
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='!mt-8'>
                                <p className='text-textblack text-sm leading-5 inter'>{doc.update_description}</p>

                            </div>
                            <div className='!mb-7 !mt-9 flex flex-wrap gap-x-6'>
                                {
                                    doc?.images?.length > 0 && doc?.images.map((docImg, imgIndex) => (
                                        <div className='max-w-[310px] grow-0 shrink-0 basis-[310px]'>
                                            {['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'].includes(docImg.file_name.split('.').pop()) ? <div className='!border border-grayLight rounded h-[210px] max-w-[310px] group relative'>
                                                <img src={docImg?.file_url} alt="" className='w-100 h-100 border rounded-lg object-contain ' />
                                                <a href={docImg?.file_url} download className='absolute right-1 top-1 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'>
                                                    <DownloadIcon />
                                                </a>
                                            </div> :
                                                <video width="100%" height="250" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                                    <source src={docImg.file_url} type="video/mp4" />
                                                </video>}
                                            <p className='text-textblack text-base font-semibold inter !mt-1'>{docImg?.file_name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <p className='text-right inter mb-0 text-xs font-medium text-black700'>{doc.added_by} • {moment(doc.update_date).format('Do MMM YYYY')}</p>
                        </div>
                    ))
                }
                {
                    constructionDoc.length === 0 && <div>
                        <div className='flex justify-center flex-column items-center h-100'>
                            <figure><ResultSearchIcon /></figure>
                            <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Documents Added</h2>
                            <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p>
                            {/* <div className='flex items-center gap-x-3 justify-end'>
                                <div>
                                    <button
                                        className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                        onClick={() => setShowDocUploadModal(true)}
                                    ><PlushIcon />Upload</button></div>
                            </div> */}
                            {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                            {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                        </div>
                    </div>
                }
            </div>
            {
                showDocUploadModal &&
                <UploadConstructionDoc
                    show={showDocUploadModal}
                    closeModal={() => {
                        setShowDocUploadModal(false)
                        setSelectedDocs({})
                    }}
                    selectedProject={selectedProject}
                    getConstructionDocs={getConstructionDocs}
                    uuid={uuid}
                    selectedDocs={selectedDocs}
                />
            }
        </div>
    )
}

export default Construction
