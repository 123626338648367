import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import InputSelect from '../InputGroup/InputSelect'
import InputText from '../InputGroup/InputText'
import { bulkLeadShare, getLeadAccess, getUsersForSettings, saveLeadAccess, saveLeadStatusShareConfig } from '../../services/private/company.service'
import { ReactComponent as PlusPrimary } from '../../assets/icons/PlusPrimary.svg';
import { toast } from 'react-toastify'
import moment from 'moment';
import { validityUnit } from '../../helpers/enums'

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const BulkLeadShareModal = ({ show, closeModal, count, selectedLeadName, resetDataMods, selectedTableValues, getLeadsData, getAllLeadsCount }) => {

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([{}]);
    const [assignedTo, setAssignedTo] = useState();
    const [remarks, setRemarks] = useState('')

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    // const getShareLeadAccess = async () => {
    //     const res = await getLeadAccess(selectedItem.id);
    //     if (res.data.status === 200) {
    //         if (res.data.data.length > 0) {
    //             const mapped = res.data.data.map(item => ({
    //                 user_id: item.user_id,
    //                 validity: item.validity,
    //                 validity_unit: item?.validity_unit,
    //                 access: item.access
    //             }))
    //             // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
    //             // setAllUsers(results)
    //             setUsers(mapped);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if (userUpdateFrom && userUpdateFrom === 'statusPermission') {
    //         setAssignedTo(selectedItem?.assigned_users ? selectedItem?.assigned_users[0].user_id : '')
    //         const mapped = selectedItem?.users?.length > 0 ? selectedItem?.users?.map(item => ({
    //             user_id: item.user_id,
    //             validity: item.validity,
    //             validity_unit: item?.validity_unit,
    //             access: item.access
    //         })) : [{}]
    //         // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
    //         // setAllUsers(results)
    //         setUsers(mapped);
    //     } else {
    //         getShareLeadAccess()
    //     }
    // }, [selectedItem, allUsers, userUpdateFrom]);

    useEffect(() => {
        getAllUsers();
    }, []);

    const handleSubmit = async () => {
        let payload = users.filter(item => item.user_id);
        let count = 0
        payload.forEach(item => {
            if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
                count++
            }
        })
        if (count > 0) {
            toast.error('Please fill all values');
            return;
        }

        let res = {}
        // if (userUpdateFrom && userUpdateFrom === 'statusPermission') {
        //     payload = payload.map(item => ({
        //         ...item,
        //         expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString(),
        //     }))
        //     res = await saveLeadStatusShareConfig({ users: payload })
        // } else {
        payload = payload.map(item => ({ ...item, expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString() }))
        res = await bulkLeadShare({ users: payload, leads: selectedTableValues })
        // }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            // onSuccess();
            resetDataMods();
            getAllLeadsCount();
            getLeadsData();
            closeModal();
        }
    }

    return (
        <Modal
            show={show}
            onHide={closeModal}
            className="main-filter leadsDisplayColumns"
        // backdrop='static'
        // keyboard= {false}
        >
            <Modal.Body>
                <div className="modal-conents">
                    <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">

                        <h1 className='fz24 h-font fw-regular mb-0'>Share Lead Access</h1>
                        {/* {!userUpdateFrom && <div className={`form-group  position-relative mt-3`}>
                            <label className=''>Lead Name</label>
                            <div className='text-capitalize fz16 fw-po-medium'>
                                {selectedItem.name}
                            </div>

                        </div>}
                        {userUpdateFrom &&
                            <div className={`form-group  position-relative mt-3`}>
                                <div className='text-capitalize fz16 fw-po-medium'>
                                    {selectedItem.display_name}
                                </div>

                            </div>
                        } */}
                        {/* <p className='fz12 ro black-dark-700 mb4'>{subHeading}</p> */}
                        <a className='close' onClick={closeModal} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725" />
                        </svg>
                        </a>
                    </div>
                    <div className='h-full overflow-auto px-3'>
                        {/* {userUpdateFrom && userUpdateFrom === 'statusPermission' && <div className='mb-3'>
                            <InputSelect
                                label={'Assigned User'}
                                placeholder={'Select User'}
                                options={allUsers}
                                value={assignedTo ? allUsers.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    setAssignedTo(value.value);
                                }}
                                disable={selectedItem?.access === 'view' ? true : false}
                            />
                        </div>} */}
                        {
                            users.map((item, index) => (
                                <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                    <div className='flex justify-between items-center'>
                                        <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                        <button
                                            className='text-primary text-base font-medium'
                                            onClick={() => {
                                                let newUsers = [...users];
                                                newUsers.splice(index, 1);
                                                if (users.length === 1) {
                                                    setUsers([{}])
                                                } else {
                                                    setUsers([...newUsers])
                                                }

                                            }}
                                        >Remove</button>
                                    </div>
                                    <div>
                                        <InputSelect
                                            placeholder={'Select User'}
                                            options={allUsers}
                                            value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                            onChange={(value) => {
                                                let newUsers = [...users];
                                                newUsers[index].user_id = value.value;
                                                // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                // setAllUsers(filteredUser)
                                                setUsers([...newUsers]);
                                            }}
                                        // disable={selectedItem?.access === 'view' ? true : false}
                                        />
                                        <div className='mt-3'>
                                            <label htmlFor={`edit_${index}`} className='mr-4'>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`edit_${index}`}
                                                    value='edit'
                                                    checked={item.access === 'edit'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'edit'
                                                        setUsers(newUsers);
                                                    }}
                                                // disabled={selectedItem?.access === 'view' ? true : false}
                                                />
                                                <span>Edit Access</span>
                                            </label>
                                            <label htmlFor={`view_${index}`}>
                                                <input
                                                    type="radio"
                                                    className='mr-2'
                                                    name={`permission_${index}`}
                                                    id={`view_${index}`}
                                                    value='view'
                                                    checked={item.access === 'view'}
                                                    onChange={() => {
                                                        let newUsers = [...users];
                                                        newUsers[index].access = 'view'
                                                        setUsers(newUsers);
                                                    }}
                                                // disabled={selectedItem?.access === 'view' ? true : false}
                                                />
                                                <span>View Only</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor="">Validity</label>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <InputText
                                                    placeholder={'e.g. 30'}
                                                    value={item.validity}
                                                    onChange={(e) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity = e.target.value
                                                        setUsers(newUsers);
                                                    }}
                                                // disable={selectedItem?.access === 'view' ? true : false}
                                                />
                                                <InputSelect
                                                    inputclass={'m-0'}
                                                    styles={customStyles}
                                                    placeholder={'Select Time'}
                                                    value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                    options={validityUnit}
                                                    onChange={(value) => {
                                                        let newUsers = [...users];
                                                        newUsers[index].validity_unit = value.value;
                                                        setUsers(newUsers);
                                                    }}
                                                // disable={selectedItem?.access === 'view' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <button
                            className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold'
                            onClick={() => setUsers([...users, {}])}
                        >
                            <PlusPrimary />Add another
                        </button>
                        {/* {!userUpdateFrom && <div>
                            <label className='mt-3'>Remarks (Optional)</label>
                            <textarea
                                className="form-control mb-16 "
                                placeholder="Enter remarks..."
                                name="remarks"
                                onChange={(e) => setRemarks(e.target.value)}
                                value={remarks}

                            >
                            </textarea>
                        </div>} */}
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={closeModal}>Cancel</button>
                        <button className="pr-btn" onClick={handleSubmit} >Confirm</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BulkLeadShareModal