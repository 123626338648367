import React from 'react'
import Select from 'react-select';

const customStyles = {
    // Add styles to the placeholder
    placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: 'nowrap',
        marginLeft: '0',
        marginRight: '0',
        color: '#B5B5BE'
    }),
};

const SizeFilterAccordian = ({
    accordionName,
    sizeVal,
    setValue,
    placeholder,
    defaultExapanded,
    label,
    options
}) => {

    return (
        <div id='accordion'>
            <div className="filter-card cursor-pointer">
                <div id="name-heading" className="filter-header" data-toggle="collapse" data-target={"#collapse" + String(accordionName).replaceAll(/\s/g, '')} aria-expanded={defaultExapanded} aria-controls={"collapse" + String(accordionName).replaceAll(/\s/g, '')}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <h2 className="fz16 black mb-0 fw-regular">{label}{Object.keys(sizeVal).length > 0 && <span>1</span>}</h2>
                        <div className="icon mr-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                            </svg>
                        </div>
                    </div>
                </div>
                {/* Name Filter Function */}
                <div id={"collapse" + String(accordionName).replaceAll(/\s/g, '')} className={defaultExapanded ? "filter-body collapse show" : "filter-body collapse"} aria-labelledby={String(accordionName).replaceAll(/\s/g, '') + "-heading"} data-parent="#accordion">
                    <div className="px-20">
                        <div className={`${Object.keys(sizeVal).length > 0 ? "" : 'd-none'}`}>
                            <button className={`border-0 bg-tr clear-btn px-0 `} onClick={() => {
                                setValue({})
                            }}
                            >
                                Clear All
                            </button>
                        </div>
                        <div className="search-filter">
                            {/* <button className={`border-0 bg-tr clear-btn px-0 ${value.length > 0 ? "" : 'd-none'} `} onClick={() => {
                                setValue([]);
                                setInputValues({ ...inputValues, [accordionName]: '' });
                                setInputValuesNotFound({ ...inputValuesNotFound, [accordionName]: '' })
                            }}
                            >
                                Clear All
                            </button> */}
                            <div className='d-flex'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className={`form-group position-relative  ${sizeVal.min ? 'input-active' : ''} `}>
                                            <input
                                                autoComplete="off"
                                                type="number"
                                                min="0"
                                                className="form-control w-100 searchinput"
                                                placeholder={placeholder || 'Min. Size'}
                                                name={accordionName}
                                                value={sizeVal.min || ''}
                                                onChange={(e) => setValue({ ...sizeVal, min: e.target.value })}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-4 pl-md-0 py-sm-0 py-3"> <div className={`form-group position-relative ${sizeVal.max ? 'input-active' : ''} `}>
                                        <input
                                            autoComplete="off"
                                            type="number"
                                            min="0"
                                            className="form-control w-100 searchinput"
                                            placeholder={placeholder || 'Max. Size'}
                                            name={accordionName}
                                            value={sizeVal.max || ''}
                                            onChange={(e) => setValue({ ...sizeVal, max: e.target.value })}
                                        />

                                    </div></div>
                                    <div className="col px-md-0">
                                        <Select
                                            styles={customStyles}
                                            maxMenuHeight={'150px'}
                                            menuPlacement="top"
                                            className='selectsize'
                                            options={options}
                                            value={sizeVal.unit || ''}
                                            placeholder='Select Unit'
                                            onChange={value => setValue({ ...sizeVal, unit: value })}
                                        // defaultValue={{ label: this.state.options[0].label, value: this.state.options[0].value }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SizeFilterAccordian