import React from 'react'
import BasicModal from '../../../../../../components/Modals/BasicModal'
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as InfoICon } from '../../../../../../assets/icons/infoprimary.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/icons/copygray.svg';


const GenerateApi = ({ show, closeModal, apiKey, ivrName, getMyOperatorKey }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[400px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='!px-6 !pt-6 relative flex items-center justify-between '>
                <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Generate API</h1>
                <button onClick={closeModal}><CloseIcon /></button>
            </div>
            <div className='!px-6 !pt-2 !pb-6'>
                <p className='text-black700 text-sm inter leading-5 m-0'>Share the below link with the {ivrName} Dashboard to make the integration with Propacity platform.</p>
                <div className='!mt-5'>
                    <p className='!text-primary text-sm inter font-medium flex gap-x-1 items-center justify-end'>
                        <InfoICon /> View Instruction
                    </p>
                </div>
                <div className='!mt-4'>
                    <label htmlFor="" className='text-sm inter text-black700 mb-2 font-medium'>API</label>
                    <div className='flex gap-x-1'>
                        <input type="text"
                            className='w-full !border !border-grayLight shadow-shadowxs rounded-lg focus:!border-primary outline-0 py-2 !px-4 !text-textblack inter text-base'
                            value={`https://api.propacity.in/api/v1/webhooks/integration/${apiKey}/myOperatorHook`}
                            readOnly
                        />
                        <button className='w-10 inline-flex items-center shrink-0 basis-10 grow-0 justify-center hover:bg-black200 rounded-lg'><CopyIcon /></button>
                    </div>
                </div>
                <div className='!border-t !border-grayLight !mt-6'>
                    <button
                        className='!mt-6 w-full rounded-lg !bg-primary text-white !py-3 !px-4 text-sm font-medium text-center !block inter hover:!bg-primary/90'
                        onClick={() => {
                            getMyOperatorKey();
                            closeModal();
                        }}
                    >
                        Open {ivrName} Dashboard</button>
                </div>
            </div>

        </BasicModal>
    )
}

export default GenerateApi