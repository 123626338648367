export const LOGIN_REQUESTING = 'LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_PAGE_INIT = 'LOGIN_PAGE_INIT';
export const SHOW_HIDE_PASSWORD = 'SIGNUP_SHOW_HIDE_PASSWORD';
export const LOGIN_FORGOT_REQUESTING='LOGIN_FORGOT_REQUESTING';
export const LOGIN_FORGOT_ERROR='LOGIN_FORGOT_ERROR';
export const LOGIN_FORGOT_SUCCESS='LOGIN_FORGOT_SUCCESS';
export const LOGIN_FORGOT_PASSWORD = 'LOGIN_FORGOT_PASSWORD';

export function loginPageInit() {
    return {
        type: LOGIN_PAGE_INIT,
    };
}

export function loginRequest(payload) {
    return {
        type: LOGIN_REQUESTING,
        payload
    };
}

export function loginError(error) {
    return {
        type: LOGIN_ERROR,
        error,
    };
}

export function loginSuccess() {
    return {
        type: LOGIN_SUCCESS,
    };
}


export function forgotPassword(){
    return {
        type: LOGIN_FORGOT_PASSWORD,
    };
}

export function loginForgotPasswordRequesting(payload){


    return {
        type: LOGIN_FORGOT_REQUESTING,
        payload
    };

    

} 

export function loginForgotPasswordSuccess(){


    return {
        type: LOGIN_FORGOT_SUCCESS,
    };

    

} 

export function loginForgotPasswordError(error){


    return {
         type: LOGIN_FORGOT_ERROR,
         error
    };

    

}








export function showHidePassword(){
    return {
        type: SHOW_HIDE_PASSWORD
       
    };
}
