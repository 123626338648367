import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as BuildingIcon } from '../../../../assets/icons/Building.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';

import TeamCard from './TeamCard';
import AddTeamModal from '../../../../components/Modals/TeamModal/AddTeamModal';
import { deleteTeamUser, getTeamDetail, getTeamUsers, updateTeamLead } from '../../../../services/public/public.service';
import { toast } from 'react-toastify';

const TeamDetails = () => {

    const [showModal, setShowModal] = useState(false);
    const [teamsUser, setTeamsUser] = useState([]);
    const [firstTeam, setFirstTeam] = useState({});
    const [teamForEdit, setTeamForEdit] = useState({});

    const { uuid } = useParams();

    const getTeamDetails = async () => {
        const res = await getTeamUsers(uuid);
        if (res.data.status === 200) {
            setTeamsUser(res.data.data);
            setFirstTeam(res.data.data[0]);
        }
    }

    const getDetails = async () => {
        const res = await getTeamDetail(uuid);
        if (res.data.status === 200) {
            setTeamForEdit(res.data.data[0]);
            setShowModal(true)
        }
    }

    useEffect(() => {
        if (uuid) {
            getTeamDetails();
        }
    }, [uuid]);

    const handleChangeTeamLead = async (leaderId) => {
        const res = await updateTeamLead(uuid, { leader_id: leaderId })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getTeamDetails();
        }
    }

    const handleDeleteUser = async (user_id) => {
        const res = await deleteTeamUser(uuid, { user_id });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getTeamDetails();
        }
    }

    console.log(teamsUser, 'teamsUser')
    return (
        <div className='w-100 flex flex-col overflow-auto'>
            <div className="settings-title fbintegration">
                <div className="row">
                    <div className="col-12">
                        <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                            <ul className="d-flex align-items-center justify-content-start flex-row mb-8 inter">
                                <li>
                                    <Link to='/settings/user-roles/team'>Teams </Link>
                                </li>
                                <li className='flex items-center'><RightArrow />
                                    {firstTeam?.team_name}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row d-flex flex-fill flex-xl-nowrap flex-wrap align-items-center">
                    <div className="col-lg-6 col-md-12 d-flex flex-fill flex-column">
                        <h1 className='text-2xl text-black mb-0 font-medium ls5 inter inline-flex items-center !gap-x-2'>
                            <Link

                                to='/settings/user-roles/team'
                                className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                            >
                                <Pageback className="group-hover:stroke-black" />
                            </Link>

                            {firstTeam?.team_name}
                        </h1>
                        {firstTeam?.projects?.length > 0 && <div className='flex flex-wrap gap-2 !mt-4'>
                            {firstTeam?.projects.map((project, index) => (
                                <div key={`${project}_${index}`} className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'>
                                    <div><BuildingIcon /></div>
                                    {project}
                                </div>
                            ))
                            }
                            {/* <div className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'>
                                <div><BuildingIcon /></div>
                                Omaxe
                            </div> */}
                        </div>}
                    </div>
                    <div className="col-lg-6 col-md-12 justify-content-lg-end mt-lg-0 mt-3 d-flex flex-fill align-items-center">
                        <div className="d-inline-block">
                            <button className="pr-btn" onClick={() => {
                                getDetails();
                            }}>Edit Team</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-fill flex-col overflow-auto mt-6 w-100 pl-6'>
                <div className='h-full overflow-auto'>
                    <div className='max-w-[710px]'>
                        <div className="def-table !h-full">
                            <table className="table">
                                <thead>
                                    <tr className="leading-5">
                                        <th>S.No</th>
                                        <th>User Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        teamsUser.map((user, index) => (
                                            <tr key={`${user.id}_${index}`}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {user?.name}
                                                    {user.leader_id === user.user_id && <span className='bg-blueHighlight text-primary font-bold rounded-lg p-1 ml-1'>LEAD</span>}
                                                </td>
                                                <td>{user?.mobile}</td>
                                                <td>{user?.email}</td>
                                                <td className='dropdown view-more'>
                                                    <button className="bg-tr border-0 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <a
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                handleChangeTeamLead(user.user_id);
                                                            }}
                                                        >Make team lead</a>
                                                        {user.leader_id !== user.user_id && <a className="dropdown-item del-menu"
                                                            onClick={() => {
                                                                handleDeleteUser(user.user_id);
                                                            }}
                                                        >Remove User</a>}
                                                    </div>
                                                </td>

                                            </tr>
                                        ))
                                    }


                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            {showModal &&
                <AddTeamModal
                    show={showModal}
                    closeModal={() => {
                        setShowModal(false);
                        setTeamForEdit({});
                    }}
                    selectedTeam={teamForEdit}
                    getAll={getTeamDetails}
                />}
        </div >
    )
}

export default TeamDetails
