import React, { useEffect } from 'react';

const Tabs = ({ selectedTab, setSelectedTab, tabList, tabName, tabRowCount }) => {
    return (
        <div className='tabs-bottom-active-border'>
            <div class="nav nav-tabs border-0 !mb-2 gap-x-6" id="nav-tab" role="tablist">
                <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap" >
                    {tabList.map(item => (
                        <li className="nav-item" key={item.name}>
                            <a
                                className={`nav-link p-0 border-0 text-base text-black700 relative hover:!text-primary ${selectedTab.name === item.name ? 'active' : ''}`}
                                onClick={() => setSelectedTab(item)}
                            >
                                {item.name + ` ${tabRowCount[item.name] ? '(' + tabRowCount[item.name] + ')' : ''}`}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default Tabs;