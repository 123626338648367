import {
    FORGOT_REQUESTING,
    FORGOT_SUCCESS,
    FORGOT_ERROR,
    FORGOT_PAGE_INIT,
    SHOW_HIDE_PASSWORD,
    SHOW_HIDE_CONFIRM_PASSWORD
} from './actions';










// The initial state of the Forgot Reducer

export const initialState = {

    password: '',
    confirmPassword: '',
    requesting: false,
    showPassword: false,
    showConfirmPassword: false,
    code: '',
    email: '',
    passwordReset: false,
    errors: {},
};

export default function (state = initialState, actions) {

    switch (actions.type) {
        case FORGOT_PAGE_INIT:
            return { ...state, code: actions.payload.code, email: actions.payload.email, passwordReset: false };
        case FORGOT_REQUESTING:
            return { ...state, requesting: true, password: actions.password };
        case FORGOT_SUCCESS:
            return { ...state, requesting: false, passwordReset: true };
        case FORGOT_ERROR:
            return { ...state, requesting: false, errors: { ...actions.error } };
        case SHOW_HIDE_PASSWORD:
            return { ...state, showPassword: !state.showPassword };
        case SHOW_HIDE_CONFIRM_PASSWORD:
            return { ...state, showConfirmPassword: !state.showConfirmPassword };
        default:
            return state;
    }
}