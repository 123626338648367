import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';


export async function getAllBrokersSV(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.broker.getAllBrokersSV}/${uuid}`, null, payload, null)
}

export async function leadCheckforBroker(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.leadCheckforBroker}/${uuid}`, null, null, null)
}

export async function getBrokersForAnalytics() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getBrokersForAnalytics}`, null, null, null)
}

export async function getAllBrokerCompanyUsers(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getAllBrokerCompanyUsers}/${uuid}`, null, null, null)
}

export async function getAllSvInBrokerCompany(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getAllSvInBrokerCompany}/${uuid}`, null, null, null)
}

export async function getSvByBrokerId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getSvByBrokerId}/${uuid}`, null, null, null)
}


export async function insertLead(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.broker.insertLead}/${uuid}`, null, payload, null)
}

export async function statusUpdate(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.broker.statusUpdate}/${uuid}`, null, payload, null)
}


export async function brokerProjectListing() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.brokerProjectListing}`, null, null, null)
}

export async function getProjectByUserAccess() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getProjectByUserAccess}`, null, null, null)
}

export async function deleteBrokerSV(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.deleteBrokerSV}/${uuid}`, null, null, null)
}


export async function getAssignedCPs(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getAssignedCPs}`, { searchText }, null, null);
}

export async function sendWhatsappInvitation(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.broker.sendWhatsappInvitation}`, null, payload, null)
}

export async function getClosingManagerByProjectId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.broker.getClosingManagerByProjectId}/${uuid}`, null, null, null)
}