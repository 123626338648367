import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import TextEditor from '../Editor';
import Location from '../../../components/Projects/Location';
import { insertProjectLocationAdvantages } from '../../../services/public/public.service';
import { toast } from 'react-toastify';

const LocationAdvantageModal = ({ show, closeModal, style, projectUuid, locationAdvantages, locationDescription, setLocationDescription, handleSubmitLocation }) => {
    const [lists, setLists] = useState([{}]);

    useEffect(() => {
        if (locationAdvantages.length > 0) {
            setLists(locationAdvantages)
        }
    }, [locationAdvantages]);

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Location Advantages</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>
                <div className=''>
                    <label htmlFor="">Description</label>
                    <TextEditor data={locationDescription} onChange={(value) => setLocationDescription(value)} />
                </div>

                {
                    lists.map((list, index) => (
                        <div key={index}>
                            <div className='flex justify-between'>
                                <label htmlFor=""> Advantage {index + 1}</label>
                                {lists.length > 0 && <button
                                    onClick={() => {
                                        const newList = [...lists]
                                        newList.splice(index, 1)
                                        setLists(newList);
                                    }}
                                ><CloseIcon /></button>}
                            </div>
                            <div>
                                <InputText
                                    value={list?.title}
                                    label={'Title'}
                                    inputclass={'mt-3'}
                                    placeholder={'Enter Title'}
                                    onChange={(e) => {
                                        const newList = [...lists]
                                        newList[index]['title'] = e.target.value;
                                        setLists(newList);
                                    }}
                                />
                                <InputText
                                    value={list?.subcontent}
                                    label={'Subcontent'}
                                    inputclass={'mt-3'}
                                    placeholder={'Enter Subcontent'}
                                    onChange={(e) => {
                                        const newList = [...lists]
                                        newList[index]['subcontent'] = e.target.value;
                                        setLists(newList);
                                    }}
                                />
                                <InputText
                                    value={list?.distance_from_project}
                                    type='number'
                                    label={'Distance (in kms)'}
                                    inputclass={'mt-3'}
                                    placeholder={'e.g 5'}
                                    onChange={(e) => {
                                        const newList = [...lists]
                                        newList[index]['distance_from_project'] = e.target.value;
                                        setLists(newList);
                                    }}
                                />
                                <InputText
                                    value={list?.custom_address}
                                    label={'Custom Address'}
                                    inputclass={'mt-3'}
                                    placeholder={'Enter Custom Address'}
                                    onChange={(e) => {
                                        const newList = [...lists]
                                        newList[index]['custom_address'] = e.target.value;
                                        setLists(newList);
                                    }}
                                />

                            </div>
                        </div>
                    ))
                }
                <div className='flex justify-end'>
                    <button className='bg-primary text-white rounded-lg w-max p-1' onClick={() => {
                        setLists([...lists, {}])
                    }}>+ Add More</button>
                </div>
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => handleSubmitLocation(lists)} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default LocationAdvantageModal;