import React, { useEffect, useState } from 'react'
import { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import InputText from '../../components/InputGroup/InputText';
import SideBanner from '../../components/SideBanner/SideBanner';
import { getEmail, sigupPassword } from '../../services/public/public.service';


const HEADING = `A Real Estate Platform That 'Works For You'`;
const SUBHEADINGTEXT = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;
const PassowrdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

const CreatePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordFormtError, setPasswordFormatError] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { uuid } = useParams();
    let history = useHistory();

    const getSignupEmail = useCallback(() => {
        getEmail(uuid).then(res => {
            // console.log({ res })
            setUserEmail(res.data.data.email)
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    useEffect(() => {
        getSignupEmail()
        const el = document.getElementById('main-container')
        el.classList.add('create-password')

        return (() => el.classList.remove('create-password'))
    }, []);



    const handleBlur = useCallback(() => {
        if (!PassowrdRegex.test(password)) {
            setPasswordFormatError(true);
            return;
        } else {
            setPasswordFormatError(false)
        }
    }, [password])

    const handleConfirmPassword = useCallback(() => {
        if (password !== confirmPassword) {
            setPasswordError(true);
            return;
        } else {
            setPasswordError(false);
        }

        sigupPassword(uuid, { password }).then(res => {
            if (res.data.status === 200) {
                history.push('/login-demo')
            }
        }).catch(err => {
            console.log({ err })
        })
    }, [password, confirmPassword]);

    return (

        <div className='create-password d-flex flex-fill vh-100 w-100'>
            <div className="container-fluid d-flex flex-fill">
                <div className="row d-flex flex-fill">
                    <div className="col-lg-4 col-md-4 col-sm-4 p-0 d-md-flex flex-fill d-none">
                        <SideBanner Heading={HEADING} Text={SUBHEADINGTEXT} />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-4 d-flex flex-fill pr-0">
                        <div className="createpawword-main-area d-flex flex-fill flex-column">
                            <figure>
                                <img src={`${process.env.PUBLIC_URL}/login-logo.svg`} />
                            </figure>
                            <h1 className='fz24 black fw-po-medium po ls1 mb-8'>Create Password</h1>
                            <p className='fz16 black-dark-600 mb-24'>Set your password to get started with your propacity paltform account.</p>
                            <div className="form-group position-relative">
                                <label htmlFor="">Email</label>
                                <input type="text" className='form-control ' placeholder='e.g. ravi@gmail.com' value={userEmail} disabled />
                                <div className="invalid-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#92929D"></path><path d="M7.00195 11C7.00195 10.8687 7.02782 10.7386 7.07807 10.6173C7.12833 10.496 7.20199 10.3857 7.29485 10.2929C7.38771 10.2 7.49794 10.1264 7.61927 10.0761C7.7406 10.0259 7.87063 10 8.00195 10C8.13328 10 8.26331 10.0259 8.38464 10.0761C8.50596 10.1264 8.6162 10.2 8.70906 10.2929C8.80192 10.3857 8.87558 10.496 8.92583 10.6173C8.97609 10.7386 9.00195 10.8687 9.00195 11C9.00195 11.2652 8.8966 11.5196 8.70906 11.7071C8.52152 11.8946 8.26717 12 8.00195 12C7.73674 12 7.48238 11.8946 7.29485 11.7071C7.10731 11.5196 7.00195 11.2652 7.00195 11ZM7.09995 4.995C7.08664 4.86884 7.09999 4.74129 7.13915 4.62062C7.17831 4.49996 7.24241 4.38888 7.32727 4.29458C7.41214 4.20029 7.51588 4.12489 7.63176 4.07328C7.74765 4.02167 7.87309 3.995 7.99995 3.995C8.12681 3.995 8.25226 4.02167 8.36814 4.07328C8.48403 4.12489 8.58777 4.20029 8.67263 4.29458C8.7575 4.38888 8.82159 4.49996 8.86075 4.62062C8.89991 4.74129 8.91327 4.86884 8.89995 4.995L8.54995 8.502C8.53819 8.63977 8.47516 8.76811 8.37331 8.86163C8.27146 8.95515 8.13823 9.00705 7.99995 9.00705C7.86168 9.00705 7.72844 8.95515 7.6266 8.86163C7.52475 8.76811 7.46171 8.63977 7.44995 8.502L7.09995 4.995Z" fill="#92929D"></path></svg>
                                </div>
                            </div>
                            <div className="form-group position-relative">
                                <label htmlFor="">
                                    <span> Password </span>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="button-tooltip" >
                                                <div className='info-msg'>
                                                    <div>Password Format:</div>
                                                    <div>1. Minimum eight characters</div>
                                                    <div>2. At least one uppercase letter</div>
                                                    <div>3. At least one lowercase letter</div>
                                                    <div>4. At least one number and one special character</div>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#92929D"></path><path d="M7.00195 11C7.00195 10.8687 7.02782 10.7386 7.07807 10.6173C7.12833 10.496 7.20199 10.3857 7.29485 10.2929C7.38771 10.2 7.49794 10.1264 7.61927 10.0761C7.7406 10.0259 7.87063 10 8.00195 10C8.13328 10 8.26331 10.0259 8.38464 10.0761C8.50596 10.1264 8.6162 10.2 8.70906 10.2929C8.80192 10.3857 8.87558 10.496 8.92583 10.6173C8.97609 10.7386 9.00195 10.8687 9.00195 11C9.00195 11.2652 8.8966 11.5196 8.70906 11.7071C8.52152 11.8946 8.26717 12 8.00195 12C7.73674 12 7.48238 11.8946 7.29485 11.7071C7.10731 11.5196 7.00195 11.2652 7.00195 11ZM7.09995 4.995C7.08664 4.86884 7.09999 4.74129 7.13915 4.62062C7.17831 4.49996 7.24241 4.38888 7.32727 4.29458C7.41214 4.20029 7.51588 4.12489 7.63176 4.07328C7.74765 4.02167 7.87309 3.995 7.99995 3.995C8.12681 3.995 8.25226 4.02167 8.36814 4.07328C8.48403 4.12489 8.58777 4.20029 8.67263 4.29458C8.7575 4.38888 8.82159 4.49996 8.86075 4.62062C8.89991 4.74129 8.91327 4.86884 8.89995 4.995L8.54995 8.502C8.53819 8.63977 8.47516 8.76811 8.37331 8.86163C8.27146 8.95515 8.13823 9.00705 7.99995 9.00705C7.86168 9.00705 7.72844 8.95515 7.6266 8.86163C7.52475 8.76811 7.46171 8.63977 7.44995 8.502L7.09995 4.995Z" fill="#92929D"></path></svg>
                                    </OverlayTrigger>
                                </label>
                                <input
                                    type={!showPassword ? 'password' : 'text'}
                                    className='form-control'
                                    placeholder='*******'
                                    value={password}
                                    onBlur={handleBlur}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                {!showPassword && <div className='valid-icon' onClick={() => setShowPassword(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D" />
                                </svg>
                                </div>}
                                {showPassword && <div title='View Password' className='valid-icon' onClick={() => setShowPassword(false)} >
                                    <i className="far fa-eye-slash fa-1x"></i>
                                </div>}
                            </div>
                            {passwordFormtError && password && <div className="input-feedback fz14 ro mt-8">Password format does not match</div>}

                            <div className="form-group position-relative">
                                <label htmlFor="">Confirm Password</label>
                                <input
                                    type={!showConfirmPassword ? 'password' : 'text'}
                                    className='form-control'
                                    placeholder='*******'
                                    value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value) }}
                                />
                                {!showConfirmPassword && <div className='valid-icon' onClick={() => setShowConfirmPassword(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D" />
                                </svg>
                                </div>}
                                {showConfirmPassword && <div title='View Password' className='valid-icon' onClick={() => setShowConfirmPassword(false)} >
                                    <i className="far fa-eye-slash fa-1x"></i>
                                </div>}
                            </div>
                            {passwordError && <div className="input-feedback fz14 ro mt-8">Password does not match</div>}
                            <button className='login-btn mt-24' onClick={handleConfirmPassword}>Login now <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                                <path d="M0.5 7.7254C0.5 7.3457 0.782154 7.03191 1.14823 6.98225L1.25 6.9754L14.434 6.976L9.67101 2.23247C9.37749 1.94021 9.37647 1.46534 9.66873 1.17181C9.93442 0.904974 10.351 0.87987 10.6451 1.09709L10.7294 1.16953L16.7794 7.19353C16.8181 7.23205 16.8517 7.27375 16.8802 7.31778C16.8883 7.33103 16.8965 7.34459 16.9042 7.35842C16.9114 7.37028 16.9178 7.38259 16.9239 7.39503C16.9324 7.41314 16.9407 7.43185 16.9482 7.45094C16.9543 7.46566 16.9594 7.47998 16.964 7.49441C16.9696 7.5123 16.9749 7.53137 16.9796 7.55073C16.983 7.56425 16.9857 7.57725 16.9881 7.59031C16.9914 7.60975 16.9943 7.62985 16.9963 7.6502C16.998 7.66572 16.9991 7.68109 16.9997 7.69648C16.9998 7.70582 17 7.71559 17 7.7254L16.9996 7.75445C16.9991 7.76917 16.998 7.78388 16.9966 7.79855L17 7.7254C17 7.77273 16.9956 7.81903 16.9872 7.86393C16.9853 7.87465 16.983 7.88566 16.9804 7.89662C16.9751 7.9192 16.9689 7.94094 16.9618 7.96226C16.9584 7.97285 16.9543 7.98416 16.95 7.99539C16.9412 8.01796 16.9317 8.03947 16.9212 8.06043C16.9163 8.07028 16.9109 8.0806 16.9052 8.09081C16.8958 8.10747 16.8861 8.1233 16.8759 8.13873C16.8687 8.14965 16.8607 8.16101 16.8524 8.1722L16.8459 8.18085C16.8257 8.20724 16.8038 8.23226 16.7803 8.25573L16.7794 8.25643L10.7294 14.2814C10.4359 14.5737 9.96106 14.5727 9.66877 14.2792C9.40306 14.0124 9.37971 13.5957 9.59818 13.3025L9.67097 13.2186L14.432 8.476L1.25 8.4754C0.835786 8.4754 0.5 8.13961 0.5 7.7254Z" fill="white" stroke="white" strokeWidth="0.5" />
                            </svg></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CreatePassword