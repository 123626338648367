import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { Oval } from 'react-loader-spinner';
import { getCities, getCsvValues, updateMappedData } from '../../services/private/csv.service';
import { getStates } from '../../services/public/public.service';
import InputSelect from '../InputGroup/InputSelect';
import ConfirmCsv from '../Modals/ConfirmCsv';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useMemo } from 'react';

const customStyles = {
    option: (styles, state) => ({
        ...styles,
        zIndex: 99,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: (base, state) => ({
        ...base,
        zIndex: 99,
        // You can also use state.isFocused to conditionally style based on the focus state
    }),

};

const headingText = (csvVal, dropdownVal) => {
    return csvVal - dropdownVal === 0 ? ' (All mapped)' : ` (${csvVal - dropdownVal}/${csvVal} left)`
}
const Mapping = ({ uuid, updateStep }) => {
    const [csvValue, setCsvValue] = useState();
    const [platformValue, setPlatformValue] = useState();
    const [states, setStates] = useState([]);
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [loading, setLoading] = useState(false);

    const [dropdownValue, setDropdownValue] = useState({
        city: [],
        state: [],
        project: [],
        assignedTo: [],
        source: []
    });

    const getCsvValuesToMap = () => {
        setLoading(true);
        getCsvValues(uuid)
            .then((res) => {
                if (res.data.status === 200) {
                    setLoading(false);
                    setCsvValue(res.data.data.csv);
                    setPlatformValue(res.data.data.platform);
                    localStorage.setItem('assigned_to', res.data.data.csv.assigned_toDropdown.length)
                    localStorage.setItem('source', res.data.data.csv.sourcesDropdown.length)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log({ err });
            })
    }

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getAllStates = async () => {
        getStates().then((res) => {
            if (res.status === 200) {
                const states = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        value: data.name,
                    };
                });
                setStates(states);
            }
        });
    };

    useEffect(() => {
        getAllStates();
    }, [])

    useEffect(() => {
        if (uuid)
            getCsvValuesToMap();
    }, [uuid])

    const onHnadleDropdown = (type, csv_value, crm_value, index) => {
        let newVal = [...dropdownValue[type]]
        const newIndex = newVal.findIndex(item => item.csv_value === csv_value)
        if (crm_value) {
            if (newIndex > -1) {
                newVal[newIndex] = { type, csv_value, crm_value }
            } else {
                newVal = [...newVal, { type, csv_value, crm_value }]
            }
        } else {
            newVal.splice(index, 1);
        }
        setDropdownValue({ ...dropdownValue, [type]: [...newVal] })
    }

    const submitMapping = useCallback(() => {
        if (dropdownValue.assignedTo.length === 0) {
            localStorage.setItem('assigned_to', dropdownValue.assignedTo.length)
        }
        updateMappedData(uuid, dropdownValue)
            .then((res) => {
                if (res.data.status === 200) {
                    updateStep(4)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [dropdownValue, uuid]);

    const handleSubmitData = () => {

        if (csvValue?.citiesDropdown.length === 0 &&
            csvValue?.statesDropdown.length === 0 &&
            csvValue?.projectDropdown.length === 0 &&
            csvValue?.assigned_toDropdown.length === 0 &&
            csvValue?.sourcesDropdown.length === 0) {
            updateStep(4)
            return;
        }

        if (dropdownValue.source.length !== csvValue?.sourcesDropdown.length) {
            toast.warning('Please map all values of source column to proceed.')
            return;
        }

        if (dropdownValue.city.length !== csvValue?.citiesDropdown.length ||
            dropdownValue.state.length !== csvValue?.statesDropdown.length ||
            dropdownValue.project.length !== csvValue?.projectDropdown.length ||
            dropdownValue.assignedTo.length !== csvValue?.assigned_toDropdown.length ||
            dropdownValue.source.length !== csvValue?.sourcesDropdown.length) {
            setShowCancelModal(true)
            return;
        }

        submitMapping();
    }

    const closeCancelCSVModal = useCallback(() => {
        setShowCancelModal(false)
    }, []);

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" >
            {text}
        </Tooltip>
    );

    return (
        <>
            <div className="field-maping match">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className='black fz24 fw-po-semi mb-18 d-flex align-items-center'>Match Columns
                            <OverlayTrigger
                                placement="auto"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip('Map the values from csv file to values in Propacity platform.')}
                            >
                                <Button className='bg-tr'> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                            </OverlayTrigger>
                        </h2>
                        <div className="line"></div>
                        <div className="fild-according">
                            {
                                loading && <div className='d-flex align-items-center justify-content-center' style={{ height: '90%', width: '100%' }}>
                                    <Oval
                                        height={30}
                                        width={30}
                                        color="#0062FF"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#0062FF"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}

                                    />
                                </div>
                            }
                            {
                                csvValue?.citiesDropdown.length === 0 &&
                                csvValue?.statesDropdown.length === 0 &&
                                csvValue?.projectDropdown.length === 0 &&
                                csvValue?.assigned_toDropdown.length === 0 &&
                                csvValue?.sourcesDropdown.length === 0 &&
                                <p className='d-flex align-items-center justify-content-center' style={{ height: '90%', width: '100%' }}>
                                    No columns detected for mapping, click on next to continue
                                </p>
                            }
                            <div id="accordion-maping">
                                {csvValue?.projectDropdown.length > 0 && <div className="card" >
                                    <div className="card-header collapsed z-50" data-toggle="collapse" data-target="#collapseproject" aria-expanded="false" aria-controls="collapseproject" id="heading-project">
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                Project
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Map the projects found in your CSV file to projects added in Propacity platform.')}
                                                >
                                                    <Button className='bg-pr p-0 ml-8'>
                                                        <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                                </OverlayTrigger>

                                                {csvValue?.projectDropdown.length - dropdownValue?.project.length === 0 && <span className='ml-8'>
                                                    <svg width="20" height="20" class="" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C"></path><circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C"></circle><path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" stroke-linecap="round" strokelinejoin="round"></path></svg>
                                                </span>}
                                                {dropdownValue?.project.length > 0 && csvValue?.projectDropdown.length - dropdownValue?.project.length > 0 &&
                                                    <span className='ml-8'>
                                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39" /></svg>
                                                    </span>}
                                            </div>
                                            <div className="col-md-5 d-flex flex-row justify-content-end align-items-center">
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseproject" className="collapse show " aria-labelledby="heading-project" data-parent="#accordion-maping">
                                        <div className="card-body fz14 fw-po-medium">
                                            <div className="row heading">
                                                <div className="col-md-7 pl-0">
                                                    CSV File Data
                                                    <span className='orange-color orange-hover' style={{ color: `${csvValue?.projectDropdown.length - dropdownValue?.project.length === 0 ? '#82C43C' : ''}` }}>
                                                        {csvValue?.projectDropdown.length ?
                                                            headingText(csvValue?.projectDropdown.length, dropdownValue?.project.length)
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-5 pl-0"><img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} style={{ height: '24px' }} /></div>
                                            </div>
                                            <div>
                                                {
                                                    csvValue?.projectDropdown?.map((project, index) => {
                                                        return (
                                                            <div className="row" key={`${project}_${index}`}>
                                                                <div className="col-md-7 ">{project} </div>
                                                                <div className="col-md-5">
                                                                    <InputSelect
                                                                        options={platformValue?.projects.map((project) => { return { label: project.name, value: project.id } })}
                                                                        placeholder='Search and Select'
                                                                        onChange={(value) => {
                                                                            if (value) {
                                                                                onHnadleDropdown('project', project, value.value, index)
                                                                            } else {
                                                                                onHnadleDropdown('project', project, null, index)
                                                                            }
                                                                        }}
                                                                        styles={customStyles}
                                                                        isClearable={true}
                                                                        menuPosition='fixed'
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {csvValue?.assigned_toDropdown.length > 0 && <div className="card">
                                    <div className="card-header collapsed z-50" data-toggle="collapse" data-target="#collapseassign" aria-expanded="false" aria-controls="collapseassign" id="heading-assign">
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                Assigned User

                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Map the Assigned User column found in your CSV file to assign leads to your users added in Propacity platform')}
                                                >
                                                    <Button className='bg-pr p-0 ml-8' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                                </OverlayTrigger>


                                                {csvValue?.assigned_toDropdown.length - dropdownValue?.assignedTo.length === 0 && <span className='ml-8'>
                                                    <svg width="20" height="20" class="" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C"></path><circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C"></circle><path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" stroke-linecap="round" strokelinejoin="round"></path></svg>
                                                </span>}
                                                {dropdownValue?.assignedTo.length > 0 && csvValue?.assigned_toDropdown.length - dropdownValue?.assignedTo.length > 0 &&
                                                    <span className='ml-8'>
                                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39" /></svg>
                                                    </span>}
                                            </div>
                                            <div className="col-md-5 d-flex flex-row justify-content-end align-items-center">
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseassign" className="collapse" aria-labelledby="heading-assign" data-parent="#accordion-maping">
                                        <div className="card-body fz14 fw-po-medium">
                                            <div className="row heading">
                                                <div className="col-md-7 pl-0">
                                                    CSV File Data
                                                    <span className='orange-color orange-hover' style={{ color: `${csvValue?.assigned_toDropdown.length - dropdownValue?.assignedTo.length === 0 ? '#82C43C' : ''}` }}>
                                                        {csvValue?.assigned_toDropdown.length ?
                                                            headingText(csvValue?.assigned_toDropdown.length, dropdownValue?.assignedTo.length)
                                                            :
                                                            ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-5 pl-0"><img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} style={{ height: '24px' }} /></div>
                                            </div>

                                            {
                                                csvValue?.assigned_toDropdown?.map((assignTo, index) => {
                                                    return (
                                                        <div className="row" key={`${assignTo}_${index}`}>
                                                            <div className="col-md-7 ">{assignTo}</div>
                                                            <div className="col-md-5">
                                                                <InputSelect
                                                                    options={platformValue?.assignedToUsers.map((assignTo) => { return { label: assignTo.name, value: assignTo.uuid } })}
                                                                    placeholder='Search and Select'
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            onHnadleDropdown('assignedTo', assignTo, value.value, index)
                                                                        } else {
                                                                            onHnadleDropdown('assignedTo', assignTo, null, index)
                                                                        }
                                                                    }}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>}
                                {csvValue?.sourcesDropdown.length > 0 && <div className="card">
                                    <div className="card-header collapsed z-50" data-toggle="collapse" data-target="#collapsesource" aria-expanded="false" aria-controls="collapsesource" id="heading-source">
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                Source
                                                <OverlayTrigger
                                                    placement="right"

                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Map the sources found in your CSV file to sources in Propacity platform')}
                                                >
                                                    <Button className='bg-pr p-0 ml-8' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                                </OverlayTrigger>

                                                {csvValue?.sourcesDropdown.length - dropdownValue?.source.length === 0 && <span className='ml-8'>
                                                    <svg width="20" height="20" class="" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C"></path><circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C"></circle><path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" stroke-linecap="round" strokelinejoin="round"></path></svg>
                                                </span>}
                                                {dropdownValue?.source.length > 0 && csvValue?.sourcesDropdown.length - dropdownValue?.source.length > 0 && <span className='ml-8'>
                                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39" /></svg>
                                                </span>}
                                            </div>
                                            <div className="col-md-5 d-flex flex-row justify-content-end align-items-center">
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsesource" className="collapse" aria-labelledby="heading-source" data-parent="#accordion-maping">
                                        <div className="card-body fz14 fw-po-medium">
                                            <div className="row heading">
                                                <div className="col-md-7 pl-0">
                                                    CSV File Data
                                                    <span className='orange-color orange-hover' style={{ color: `${csvValue?.sourcesDropdown.length - dropdownValue?.source.length === 0 ? '#82C43C' : ''}` }}>
                                                        {csvValue?.sourcesDropdown.length ?
                                                            headingText(csvValue?.sourcesDropdown.length, dropdownValue?.source.length) :
                                                            ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-5 pl-0"><img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} style={{ height: '24px' }} /></div>
                                            </div>

                                            {
                                                csvValue?.sourcesDropdown?.map((source, index) => {
                                                    return (
                                                        <div className="row" key={`${source}_${index}`}>
                                                            <div className="col-md-7 ">{source}</div>
                                                            <div className="col-md-5">
                                                                <InputSelect
                                                                    options={platformValue?.sources.map((source) => { return { label: source.display_name, value: source.id } })}
                                                                    placeholder='Search and Select'
                                                                    onChange={(value) => {
                                                                        if (value) {
                                                                            onHnadleDropdown('source', source, value.value, index)
                                                                        } else {
                                                                            onHnadleDropdown('source', source, null, index)
                                                                        }

                                                                    }}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>}
                                {csvValue?.citiesDropdown.length > 0 && <div className="card">
                                    <div className="card-header z-50" id="heading-project" data-toggle="collapse" data-target="#collapseProject" aria-expanded="false" aria-controls="collapseProject">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                City
                                                <OverlayTrigger
                                                    placement="right"

                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Map the cities found in your CSV file to cities in the Propacity platform')}
                                                >
                                                    <Button className='bg-pr' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                                </OverlayTrigger>

                                                {csvValue?.citiesDropdown.length - dropdownValue?.city.length === 0 && <span className='ml-2'>
                                                    <svg width="20" height="20" class="" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C"></path><circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C"></circle><path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" stroke-linecap="round" strokelinejoin="round"></path></svg>
                                                </span>}
                                                {dropdownValue?.city.length > 0 && csvValue?.citiesDropdown.length - dropdownValue?.city.length > 0 &&
                                                    <span className='ml-2'>
                                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39" /></svg>
                                                    </span>}
                                            </div>
                                            <div className="col-md-6 d-flex flex-row justify-content-end align-items-center">
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseProject" className="collapse" aria-labelledby="heading-project" data-parent="#accordion-maping">
                                        <div className="card-body fz14 fw-po-medium">
                                            <div className="row heading">
                                                <div className="col-md-7 pl-0">
                                                    CSV File Data
                                                    <span className='orange-color orange-hover' style={{ color: `${csvValue?.citiesDropdown.length - dropdownValue?.city.length === 0 ? '#82C43C' : ''}` }}>
                                                        {csvValue?.citiesDropdown.length ?
                                                            headingText(csvValue?.citiesDropdown.length, dropdownValue?.city.length) :
                                                            ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-5 pl-0"><img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} style={{ height: '24px' }} /></div>

                                            </div>

                                            {
                                                csvValue?.citiesDropdown?.map((city, index) => {
                                                    return (
                                                        <div className="row" key={`${city}_${index}`}>
                                                            <div className="col-md-7 ">{city}</div>
                                                            <div className="col-md-5">
                                                                {/* <InputSelect
                                                                    options={cities}
                                                                    onChange={(value) => onHnadleDropdown('city', city, value.value)}
                                                                    handleSearch={(val) => console.log(val)}
                                                                    isMulti={true}
                                                                /> */}
                                                                <AsyncSelect
                                                                    cacheOptions
                                                                    loadOptions={loadOptions}
                                                                    defaultOptions
                                                                    placeholder='Search and Select'
                                                                    styles={customStyles}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            onHnadleDropdown('city', city, e.value, index)
                                                                        } else {
                                                                            onHnadleDropdown('city', city, null, index)
                                                                        }
                                                                    }}
                                                                    isClearable={true}
                                                                    menuPosition={'fixed'}
                                                                />

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>}
                                {csvValue?.statesDropdown.length > 0 && <div className="card">
                                    <div className="card-header collapsed z-50" data-toggle="collapse" data-target="#collapsestate" aria-expanded="false" aria-controls="collapsestate" id="heading-state">
                                        <div className="row align-items-center">
                                            <div className="col-md-7">
                                                State
                                                <OverlayTrigger
                                                    placement="right"

                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Map the states found in your CSV file to states in the Propacity platform')}
                                                >
                                                    <Button className='bg-pr' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                                </OverlayTrigger>

                                                {csvValue?.statesDropdown.length - dropdownValue?.state.length === 0 && <span className='ml-2'>
                                                    <svg width="20" height="20" class="" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C"></path><circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C"></circle><path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" stroke-linecap="round" strokelinejoin="round"></path></svg>
                                                </span>}
                                                {dropdownValue?.state.length > 0 && csvValue?.statesDropdown.length - dropdownValue?.state.length > 0 &&
                                                    <span className='ml-2'>
                                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34783 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM10.5 16.5C10.5 16.1022 10.658 15.7206 10.9393 15.4393C11.2206 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4393C13.342 15.7206 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18C11.6022 18 11.2206 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5ZM11.016 6.82C11.0577 6.58944 11.1791 6.38085 11.359 6.23065C11.5388 6.08045 11.7657 5.99817 12 5.99817C12.2343 5.99817 12.4612 6.08045 12.641 6.23065C12.8209 6.38085 12.9423 6.58944 12.984 6.82L13 7V12L12.984 12.18C12.9423 12.4106 12.8209 12.6191 12.641 12.7694C12.4612 12.9196 12.2343 13.0018 12 13.0018C11.7657 13.0018 11.5388 12.9196 11.359 12.7694C11.1791 12.6191 11.0577 12.4106 11.016 12.18L11 12V7L11.016 6.82Z" fill="#FD9A39" /></svg>
                                                    </span>}
                                            </div>
                                            <div className="col-md-5 d-flex flex-row justify-content-end align-items-center">
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsestate" className="collapse" aria-labelledby="heading-state" data-parent="#accordion-maping">
                                        <div className="card-body fz14 fw-po-medium">
                                            <div className="row heading">
                                                <div className="col-md-7 pl-0">
                                                    CSV File Data
                                                    <span className='orange-color orange-hover' style={{ color: `${csvValue?.statesDropdown.length - dropdownValue?.state.length === 0 ? '#82C43C' : ''}` }}>
                                                        {csvValue?.statesDropdown.length ?
                                                            headingText(csvValue?.statesDropdown.length, dropdownValue?.state.length) :
                                                            ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-5 pl-0"><img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} style={{ height: '24px' }} /></div>
                                            </div>

                                            {
                                                csvValue?.statesDropdown?.map((state, index) => {
                                                    return (
                                                        <div className="row" key={`${state}_${index}`}>
                                                            <div className="col-md-7 ">{state}</div>
                                                            <div className="col-md-5">
                                                                <InputSelect
                                                                    maxMenuHeight='150px'
                                                                    options={states}
                                                                    placeholder='Search and Select'
                                                                    onChange={(value) => {

                                                                        if (value) {
                                                                            onHnadleDropdown('state', state, value.value, index)
                                                                        } else {
                                                                            onHnadleDropdown('state', state, null, index)
                                                                        }
                                                                    }}
                                                                    styles={customStyles}
                                                                    isClearable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">
                <button className='outline-btn' onClick={() => updateStep(2)}>Previous</button>
                <button className='pr-btn ml-8' onClick={handleSubmitData}>Next</button>

            </div>

            <ConfirmCsv
                show={showCancelModal}
                closeCancelModal={closeCancelCSVModal}
                onSubmit={submitMapping}

                submitButtonType={'primary'}
                cancelButtonText='Cancel'
                submitButtonText='Confirm'
            />
        </>
    )
}

export default Mapping;