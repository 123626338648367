import React, { useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AnalyticsSkeleton from './analytics-skeleton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';

const SourceAnalysisTable = ({ sourceLeads, loading, companyConfig, fromDate, toDate, selectedDate }) => {
    const { result, masterCount } = sourceLeads;
    const [tempData, setTempData] = useState([]);

    const exportData = () => {
        let temp = result.map((lead, index) => {
            return {
                "Source": lead.source || '',
                "Leads": parseInt(lead.totalLeads) || '',
                "Site Visit Completed": lead.svCompleted || '',
                "Bookings": lead.bookingCompleted || '',
            }
        })
        setTempData(temp);

    };

    const handleAddFilter = (source, status) => {
        let proj = {}
        let usersStatus = [];
        if (status) {
            usersStatus = companyConfig.Status.filter(s => s.label === status);
        }
        const src = companyConfig.Source.filter(s => s.label === source);

        const payload = [];
        const filterPayload = []

        if (status === 'SV Completed') {
            localStorage.setItem('leadType', 'site_visits')
        }
        if (status === 'Booking Completed') {
            localStorage.setItem('leadType', 'booking_only')
        }
        if (!status) {
            localStorage.setItem('leadType', 'all_leads')
        }

        if (usersStatus.length > 0) {
            payload.push({
                Status: usersStatus.map(s => s.value)
            })
        }
        if (src.length > 0) {
            payload.push({
                Source: src.map(s => s.value)
            })
        }
        if (selectedDate !== 'allTime') {
            filterPayload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }

        if (filterPayload.length > 0) {
            localStorage.setItem('new_filters', JSON.stringify(filterPayload));
        }

        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    return (
        <div className="source-table d-flex flex-fill ">
            <div className="table-card d-flex flex-fill flex-column">

                <div className="table-header d-flex align-items-center justify-content-between">
                    <div className='d-flex align-items-center'>
                        <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Source Analysis</h2>
                        <div className="info-alert">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p>Source wise breakup of leads added in platform for selected date range into unique SV Completed & Bookings done.</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Button className='bg-tr border-0' bsstyle="default">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                        <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                    </button> */}
                    <CSVLink
                        className={`!ml-3 flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1`}
                        data={tempData}
                        onClick={exportData}
                        filename={'source_analysis.csv'}>
                        CSV <DownloadIcon />
                    </CSVLink>
                </div>
                {result?.length > 0 && <div className="scroll-table">
                    <table className="table table-striped table-bg">
                        <thead>
                            <tr>
                                <th style={{ width: '210px' }}>SOURCE</th>
                                <th style={{ width: '94px' }}>LEADS</th>
                                <th style={{ width: '160px' }}>SV COMPLETED</th>
                                <th style={{ width: '160px' }}>BOOKINGS</th>

                            </tr>
                        </thead>
                        {!loading &&
                            <>
                                <tbody>
                                    {result.map((lead, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ width: '210px' }} className='cursor-pointer' onClick={() => handleAddFilter(lead.source, '')} >{lead.source || '-'}</td>
                                                <td style={{ width: '94px' }} className={` ${lead.totalLeads ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter(lead.source, '')}>{parseInt(lead.totalLeads) || '-'}</td>
                                                <td style={{ width: '160px' }} className={` ${lead.svCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter(lead.source, 'SV Completed')} >{lead.svCompleted || '-'}</td>
                                                <td style={{ width: '160px' }} className={` ${lead.bookingCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter(lead.source, 'Booking Completed')}>{lead.bookingCompleted || '-'}</td>

                                            </tr>
                                        )
                                    })}

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td style={{ width: '210px' }}>Total</td>
                                        <td style={{ width: '94px' }}>{masterCount?.total}</td>
                                        <td style={{ width: '160px' }}>{masterCount?.sv}</td>
                                        <td style={{ width: '160px' }}>{masterCount?.bookings}</td>
                                    </tr>
                                </tfoot>
                            </>
                        }
                        {loading &&
                            <tbody>
                                {Array(7).fill().map((data, index) => {
                                    return (
                                        <AnalyticsSkeleton key={String(index)} />
                                    )
                                })}
                            </tbody>}
                    </table>

                </div>}
                {(!sourceLeads || sourceLeads?.totalLeads === 0) && <div className='record-notfound text-center'>
                    <figure className='d-table mx-auto'>
                        <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                    </figure>
                    <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                    {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
                </div>}

            </div >
        </div >
    )
}

export default SourceAnalysisTable