// App.jsx / App.tsx

import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TextEditor = ({ data, onChange }) => {
    return (
        <div className="App">
            {/* <h2>Using CKEditor&nbsp;5 build in React</h2> */}
            <CKEditor
                editor={ClassicEditor}
                data={data}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    onChange(editor.getData())
                }}
            // onBlur={(event, editor) => {
            //     console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //     console.log('Focus.', editor);
            // }}
            />
        </div>
    );
}

export default TextEditor;
