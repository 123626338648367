import {
    LOGIN_PAGE_INIT,
    LOGIN_ERROR,
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    SHOW_HIDE_PASSWORD,
    LOGIN_FORGOT_PASSWORD,
    LOGIN_FORGOT_REQUESTING,
    LOGIN_FORGOT_ERROR,
    LOGIN_FORGOT_SUCCESS
} from './actions';

// The initial state of the Login Reducer

export const initialState = {
    id: '',
    password: '',
    email:'',
    requesting: false,
    successful: false,
    messages: [],
    showPassword:false,
    forgotPassWord:false,
    forgotVerificationSent:false,
    errors: {},
  };

export default function(state = initialState,actions){

    switch(actions.type){
        case LOGIN_PAGE_INIT:
        return {...state, errors:{}};
        case LOGIN_REQUESTING:
            return {...state, requesting: true};
        case LOGIN_SUCCESS:
            return {...state,requesting: false, successful: true, user:{...actions.payload}};
        case LOGIN_ERROR:
            return {...state, requesting: false ,  successful: false, errors:{...actions.error}};
        case SHOW_HIDE_PASSWORD:
            return { ...state , showPassword :  !state.showPassword  };   
        case LOGIN_FORGOT_PASSWORD:
            return { ...state  ,forgotPassWord : !state.forgotPassWord , errors:{} , forgotVerificationSent:false }; 
        case LOGIN_FORGOT_REQUESTING:
            return { ...state ,requesting: true , errors:{} ,email : actions.payload.email };  
        case LOGIN_FORGOT_SUCCESS:
            return { ...state ,requesting: true , forgotVerificationSent:true};               
        case LOGIN_FORGOT_ERROR:
            return { ...state ,requesting: false ,errors:{...actions.error} };    
        default:        
            return state;
    }
}