import * as yup from 'yup';

export const validationSchema = yup
    .object()
    .shape({
        customer_name: yup.string().required('Please enter Name'),
        customer_mobile: yup.string().required('Please enter Mobile number').min(10, "Please enter valid Mobile number"),
        scheduleDate: yup.string().required('Please enter Token Date'),
        project: yup.string().required('Please select Project'),
        validity: yup.string().required('Please enter Validity Date'),
        // lead_source: yup.string().required('Please enter Source'),
        followupScheduleDate: yup.string().required('Please enter Followup Date'),
        token_amount: yup.string().required('Please enter Token Amount'),
    }) 