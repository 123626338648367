import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PopUpModal from "../../../../../components/Modals/PopUpModal";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const GoogleAdsTable = ({
  apiKey,
  pageIntegrationList,
  inactiveProjectFormId,
  viewForm,
  makeLeadActive,
  makeLeadInactive,
  setEditLeadDetail,
  integrationPermission
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [selectedProjectFormUUID, setSelectedProjectFormUUID] = useState("");

  const copyFunction = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <>
      {showAlertDialog ? (
        <PopUpModal
          setShow={showAlertDialog}
          show={setShowAlertDialog}
          heading={<>Integration Delete from Make?</>}
          body={
            <div className="p-3">
              <div className="mb-4">
                Make sure Google lead integration is deleted from Make before deleting
                the integration on propacity platform.
              </div>
              <div className="justify-content-end d-flex modal-footer">
                <Button
                  variant=""
                  className="mr-2 cancel"
                  onClick={() => setShowAlertDialog(!showAlertDialog)}
                >
                  Cancel
                </Button>
                <Button
                  variant=""
                  className='del-btn'
                  onClick={() => {
                    inactiveProjectFormId(selectedProjectFormUUID);
                    setShowAlertDialog(!showAlertDialog);
                  }}
                >
                  Confirm Delete
                </Button>
              </div>
            </div>
          }
        />
      ) : null}
      <div className="mt-24 ml-24 lnding-page-table h-full overflow-auto">
        <div className="def-table pr-24 pl-0 !h-full">
          <table className="table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Integration Name</th>
                <th>Landing Page URL</th>
                <th>Project</th>
                <th>Agency</th>
                <th>Total Leads</th>
                <th>Source</th>
                {/* <th>Status</th> */}
                {<th></th>}
              </tr>
            </thead>
            <tbody>
              {pageIntegrationList.map((page, index) => {
                const { uuid, weburl, title, project_name, project_id, allowed, id, apikey, count, source_name, source_id, agency } = page;
                return (
                  <tr key={String(id)}>
                    <td>{index + 1}</td>
                    <td>
                      {title.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{title}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{title}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{title}</span>
                      }

                    </td>
                    <td>
                      {weburl.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className=''>{weburl}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className=' three-dots'>{weburl}</span>
                        </OverlayTrigger>
                        :
                        <span className=' three-dots'>{weburl}</span>
                      }
                    </td>

                    <td>
                      {project_name.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{project_name}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{project_name}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{project_name}</span>
                      }



                    </td>
                    <td>
                      {agency?.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{agency || '-'}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{agency || '-'}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{agency || '-'}</span>
                      }


                    </td>
                    <td>{count}</td>
                    <td>{source_name ? source_name : '-'}</td>
                    {/* <td>{allowed ? 'Active' : 'Inactive'}</td> */}
                    {/* <td>{createDatewithTime(new Date(updated_on))}</td> */}
                    {
                      <td className="dropdown view-more" onClick={() => setSelectedProjectFormUUID(uuid)}>

                        <button
                          className="bg-tr border-0 "
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                              fill="#B5B5BE"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                              fill="#B5B5BE"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                              fill="#B5B5BE"
                            />
                          </svg>
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {<a
                            className="dropdown-item"
                            onClick={() => {
                              viewForm(apikey)
                            }}
                          >
                            View code snippet
                          </a>}
                          {
                            <>
                              {/* <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    makeLeadInactive(uuid)
                                  }}
                                >
                                  Pause integration
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    makeLeadActive(uuid)
                                  }}
                                >
                                  Resume integration
                                </a> */}
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setEditLeadDetail({ title, weburl, uuid, id: project_id, source: { label: source_name, value: source_id }, agency })
                                }}
                              >
                                Edit integration
                              </a>
                            </>}
                          {/* <a
                            className="dropdown-item del-menu"
                            onClick={() => {
                              setShowAlertDialog(!showAlertDialog);
                            }}
                          >
                            Delete
                          </a> */}
                        </div>

                      </td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GoogleAdsTable;
