import React from 'react';
import TooltipText from '../../Tooltip';
import { ReactComponent as MobileIcon } from '../../../assets/icons/copygray.svg';
import { toast } from 'react-toastify';

export default function MobileNo({
    mobileNo
}) {
    const getMobileNumber = (mobile) => {
        if (mobile?.length == 12) {
            return `+${mobile}`;
        } else {
            return mobile;
        }
    }

    return (
        <>
            <a href={`tel:${getMobileNumber(mobileNo)}`} style={{ color: '#171725' }} className='flex items-center'>
                {mobileNo ? getMobileNumber(mobileNo) : '-'}
                <TooltipText title='Copy mobile no.'>
                    <button onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(`${getMobileNumber(mobileNo)}`).then(function () {
                            toast.success('Mobile number Copied');
                        }, function (err) {
                            toast.error('Could not Mobile number api');
                        });
                    }} className='svg-active'>
                        <MobileIcon className='h-4 w-4 ml-2 ' />
                    </button>
                </TooltipText>
            </a>
        </>
    )
}

