import React, { useContext, useEffect, useState } from "react";
import { Image, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LeftPageArrow } from '../../../assets/icons/leftPageArrow.svg';
import { ReactComponent as RightPageArrow } from '../../../assets/icons/rightPageArrow.svg';
import '../../../../src/global.css'
import '../../Lead Details/Leaddetails.css';
import { updateCustomerByIdAndKey } from './../../../services/private/customer.service';
import { toast } from "react-toastify";
import Consumer from '../../../helpers/context';
import TooltipText from "../../../components/Tooltip";

export default function LeadDetailHeader({
    preNNextLead,
    customerInfoData,
    leadAccess,
    leadIds,
    index
}) {

    const history = useHistory();
    const [editCustomerName, seteditCustomerName] = useState(false);
    const [newName, setNewName] = useState('');

    const { userProfile } = useContext(Consumer)
    useEffect(() => {
        setNewName(customerInfoData.data?.name)
    }, [customerInfoData.data?.name])

    const resetCustomerNameForm = (e) => {
        e.preventDefault();
        seteditCustomerName(false);
    }

    const saveLeadData = async (e, key, value) => {
        e.preventDefault();
        if (value === customerInfoData.data?.name) {
            seteditCustomerName(false);
            return;
        }

        await updateCustomerByIdAndKey(customerInfoData.data?.uuid, {
            key, value
        }).then(res => {
            if (res.status === 200) {
                toast.success("Updated " + key)
            }
        })
        seteditCustomerName(false);
    }

    return (

        <>
            <div className="name-pagination d-flex flex-fill w-100 mb-16 mr-24">
                <div className="row flex-fill align-items-center">
                    <div className="col-md-8 d-flex flex-fill">
                        <div className={`black fz24 po fw-po-medium black ls4 d-flex align-items-center`} style={{ cursor: 'pointer' }}>
                            <span className="fz24 pr-hover" style={{ color: '#A6A6A6' }} onClick={() => history.push("/leads")}>
                                Leads
                            </span>
                            <svg className="ml-8 mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.3215 10L7.74408 13.5774C7.41864 13.9028 7.41864 14.4305 7.74408 14.7559C8.06951 15.0814 8.59715 15.0814 8.92259 14.7559L13.0893 10.5893C13.4147 10.2638 13.4147 9.73618 13.0893 9.41074L8.92259 5.24408C8.59715 4.91864 8.06951 4.91864 7.74408 5.24408C7.41864 5.56951 7.41864 6.09715 7.74408 6.42259L11.3215 10Z" fill="#171725" />
                            </svg>
                            {
                                !editCustomerName && <>
                                    <span className="fw-po-semi user-name fz24" style={{ color: '#001B47' }}>{newName || customerInfoData.data?.name} </span>
                                    {(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view') ? <TooltipText title={"You don't have permission"}>
                                        <button
                                            className="ml-8 border-0 bg-tr pr-hover"
                                            disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}
                                        >
                                            <svg onClick={() => seteditCustomerName(true)} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3726 5.96311L16.3944 4.94125L14.1421 2.68894L13.1202 3.7108L15.3726 5.96311ZM14.083 7.25265L11.8307 5.00034L3.40519 13.4259L2.8868 16.1965L5.6575 15.6782L14.083 7.25265ZM17.7384 3.70617C18.4205 4.38828 18.4205 5.49421 17.7384 6.17632L6.74744 17.1673C6.61741 17.2973 6.45111 17.385 6.27036 17.4188L1.91332 18.234C1.28327 18.3519 0.731454 17.8001 0.849334 17.17L1.66452 12.813C1.69834 12.6322 1.78602 12.4659 1.91604 12.3359L12.907 1.34493C13.5891 0.662815 14.6951 0.662815 15.3772 1.34493L17.7384 3.70617ZM17.3424 18.25H9.99093C8.78081 18.25 8.78081 16.4167 9.99093 16.4167H17.3424C18.5525 16.4167 18.5525 18.25 17.3424 18.25Z" fill="#696974" />
                                            </svg>
                                        </button>
                                    </TooltipText> :
                                        <button
                                            className="ml-8 border-0 bg-tr pr-hover"
                                        >
                                            <svg onClick={() => seteditCustomerName(true)} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3726 5.96311L16.3944 4.94125L14.1421 2.68894L13.1202 3.7108L15.3726 5.96311ZM14.083 7.25265L11.8307 5.00034L3.40519 13.4259L2.8868 16.1965L5.6575 15.6782L14.083 7.25265ZM17.7384 3.70617C18.4205 4.38828 18.4205 5.49421 17.7384 6.17632L6.74744 17.1673C6.61741 17.2973 6.45111 17.385 6.27036 17.4188L1.91332 18.234C1.28327 18.3519 0.731454 17.8001 0.849334 17.17L1.66452 12.813C1.69834 12.6322 1.78602 12.4659 1.91604 12.3359L12.907 1.34493C13.5891 0.662815 14.6951 0.662815 15.3772 1.34493L17.7384 3.70617ZM17.3424 18.25H9.99093C8.78081 18.25 8.78081 16.4167 9.99093 16.4167H17.3424C18.5525 16.4167 18.5525 18.25 17.3424 18.25Z" fill="#696974" />
                                            </svg>
                                        </button>
                                    }

                                </>
                            }
                        </div>

                        {editCustomerName && <div className={`edit-input-text`}>
                            <form className='form-inline w-100'>
                                <div className='form-group position-relative search-input edit-input w-100'>
                                    <div className='position-relative'>
                                        <input type="text" placeholder={customerInfoData.data?.name} value={newName} className="fw-po-semi" onChange={(e) => { setNewName(e.target.value) }} />
                                    </div>
                                    <button onClick={(e) => saveLeadData(e, 'name', newName)} className='border-0 bg-tr mx-2'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
                                        <rect width="20" height="20" rx="4" fill="#0062FF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.58613 9.97668C5.25907 9.65288 4.73144 9.65552 4.40764 9.98259C4.08383 10.3097 4.08648 10.8373 4.41354 11.1611L7.60133 14.3005C7.94405 14.6398 8.50236 14.6182 8.81787 14.2535L15.6301 6.37854C15.9312 6.03047 15.8931 5.5042 15.545 5.2031C15.197 4.902 14.6707 4.94008 14.3696 5.28815L8.14051 12.489L5.58613 9.97668Z" fill="white" />
                                    </svg></button>
                                    <button onClick={resetCustomerNameForm} className='border-0 ml4 bg-tr'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
                                        <path d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#B5B5BE" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.1761 10.0006L15.5737 14.4021C15.899 14.7277 15.8987 15.2553 15.5731 15.5806C15.2476 15.9059 14.7199 15.9057 14.3946 15.5801L9.99674 11.1782L5.58865 15.5798C5.26298 15.905 4.73534 15.9046 4.41014 15.5789C4.08495 15.2532 4.08534 14.7256 4.41102 14.4004L8.81876 9.99919L4.41288 5.58931C4.08759 5.26373 4.08783 4.73609 4.41341 4.4108C4.739 4.08551 5.26663 4.08575 5.59192 4.41134L9.99815 8.82155L14.3948 4.43145C14.7205 4.10625 15.2481 4.10664 15.5733 4.43232C15.8985 4.758 15.8981 5.28564 15.5724 5.61083L11.1761 10.0006Z" fill="white" />
                                    </svg></button>
                                </div>

                            </form>
                        </div>}
                        <a href={`https://wa.me/${customerInfoData.data?.mobile}`} target="_blank" className="align-self-center ml-8 whatsapp position-relative ">
                            <img src={`${process.env.PUBLIC_URL}/whatsapp.svg`} className='mx-auto d-table' />
                            <div className="whatsapp-hover">
                                WhatsApp
                            </div>
                        </a>
                    </div>
                    {leadIds?.length > 1 && <div className="col-md-4 d-flex flex-fill align-items-center justify-content-end">
                        <div className="pagination-custom bg-tr mr-24 position-relative">
                            <nav aria-label="Page navigation ">
                                <ul className="d-flex align-items-center justify-content-end m-0 p-0">
                                    {index > 0 && <li className="page-item mr-2 flex items-center cursor-pointer" onClick={() => {
                                        if (index > 0) {
                                            history.replace(`/leads/${leadIds[index - 1]}`)
                                        }
                                    }}>
                                        {/* <LeftPageArrow /> */}
                                        <button className="border-0 bg-tr p-0 bck-btn  hover:text-primary flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#44444F" />
                                            </svg>
                                            <span className="ml-2">Previous</span>
                                        </button>

                                    </li>}
                                    {/* <li>
                                        Page <span>1</span>/ <span>200</span>
                                    </li> */}

                                    {index + 1 < leadIds?.length && <li className="page-item ml-2 flex items-center" onClick={() => {
                                        if (index < leadIds.length) {
                                            history.replace(`/leads/${leadIds[index + 1]}`)
                                        }
                                    }}>
                                        <button className="border-0 bg-tr p-0 bck-btn  hover:text-primary flex items-center">
                                            <span className="mr-2">Next</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="rotate-180">
                                                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#44444F" />
                                            </svg>
                                        </button>
                                        {/* <RightPageArrow /> */}
                                    </li>}
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
            </div>
        </>)
}