import React, { useState } from 'react'
import myimage from '../../../assets/course.jpg'
import LiveSection from './LiveSection'
import { ReactComponent as Pageback } from '../../../assets/icons/Pageback.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const CourseDetailBlock = ({ style, courseDetail, mentors, liveSession }) => {
    const [play, setPlay] = useState(false);

    const handleVideEnded = () => {
        setPlay(false);
    }

    return (
        <div className={`${style.CourseDetailBlock}`}>
            <div className='row'>

                <div className={`${style.CourseDetail_left_block} col-xl-8`}>
                    <h1 className='inter fz28 black mb-20 fw-po-medium inline-flex gap-x-2 items-center'>
                        <Link

                            to='/learning-center'
                            className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                        >
                            <Pageback className="group-hover:stroke-black" />
                        </Link>

                        {courseDetail.name}
                    </h1>
                    {!play && <figure className={`${style.videovisable} position-relative`}
                        onClick={() => {
                            if (courseDetail?.video_url)
                                setPlay(true)
                        }}>
                        {courseDetail?.video_url && <div className={`${style.videoplaybtn} cursor-pointer`}><span><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                            <rect x="34.375" y="28.125" width="32.8125" height="40.625" fill="#171725" />
                            <path d="M50 12.5C42.5832 12.5 35.333 14.6993 29.1661 18.8199C22.9993 22.9404 18.1928 28.7971 15.3545 35.6494C12.5162 42.5016 11.7736 50.0416 13.2206 57.3159C14.6675 64.5902 18.239 71.272 23.4835 76.5165C28.728 81.761 35.4098 85.3325 42.6841 86.7794C49.9584 88.2264 57.4984 87.4838 64.3506 84.6455C71.2029 81.8072 77.0596 77.0007 81.1801 70.8339C85.3007 64.667 87.5 57.4168 87.5 50C87.4876 40.0582 83.5327 30.5271 76.5028 23.4972C69.4729 16.4673 59.9418 12.5124 50 12.5ZM63.1394 52.4014L45.8317 63.9399C45.357 64.2545 44.8003 64.4226 44.2308 64.4231C43.4657 64.4231 42.732 64.1192 42.191 63.5782C41.6501 63.0372 41.3462 62.3035 41.3462 61.5385V38.4615C41.3458 37.9392 41.4873 37.4265 41.7556 36.9783C42.0238 36.5301 42.4088 36.1631 42.8693 35.9166C43.3298 35.67 43.8486 35.5531 44.3704 35.5784C44.8921 35.6037 45.3972 35.7702 45.8317 36.0601L63.1394 47.5986C63.5351 47.8619 63.8596 48.2189 64.084 48.6378C64.3085 49.0568 64.4259 49.5247 64.4259 50C64.4259 50.4753 64.3085 50.9432 64.084 51.3622C63.8596 51.7811 63.5351 52.1381 63.1394 52.4014Z" fill="white" />
                        </svg></span></div>}
                        <img src={courseDetail.thumbnail} alt="" className='w-100' />
                    </figure>}

                    {play && courseDetail?.video_url && <video controls autoPlay controlsList="nodownload" preload='preloadauto' className='learnvideo' onEnded={handleVideEnded} width={'100%'} height={'auto'}>
                        <source src={courseDetail.video_url} type="video/mp4" />
                    </video>}
                    <p className='inter mb-0 fz16 black-dark-800'>{courseDetail.short_description}</p>
                </div>
                {<LiveSection style={style} mentors={mentors} liveSession={liveSession} courseDetail={courseDetail} />}
            </div>
        </div>
    )
}

export default CourseDetailBlock
