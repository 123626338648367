import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipText = ({ title, className, placement = 'bottom', children }) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip id="button-tooltip" className={className || ''}>
                    <div className='info-msg'>
                        {/* <h4>Total Leads</h4> */}
                        <p>{title}</p>
                    </div>
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipText;