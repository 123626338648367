import React, { useCallback } from 'react'
import { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { Oval } from 'react-loader-spinner'
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities'

const MiniTimline = ({ show, setShow, deleteHandler, title, deleteText, closeTimelineDropdown, leadName, ActivityTimeline }) => {

    const leadTimeLineRef = useRef();

    const handleClickOutside = useCallback((e) => {
        if (leadTimeLineRef.current && !leadTimeLineRef.current.contains(e.target)) {
            closeTimelineDropdown();
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('show-mini-timeline')
        document.addEventListener('click', handleClickOutside, true);

        return (() => {
            document.body.classList.remove('show-mini-timeline')
            document.removeEventListener('click', handleClickOutside, true)
        })
    }, [])

    return (
        <Modal
            className="miniTimelineMain modal-background"
            show={show}
            onHide={() => setShow(false)}
            centered
        // backdrop='static'
        // keyboard={false}
        >
            {/* <Modal.Header>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div ref={leadTimeLineRef}>
                    {ActivityTimeline?.length > 0 && <RecentActivities ActivityTimeline={ActivityTimeline} leadName={leadName} closeTimelineDropdown={closeTimelineDropdown} />}
                    {ActivityTimeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                        <Oval
                            height={30}
                            width={30}
                            color="#0062FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#0062FF"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>}
                </div>
            </Modal.Body>
        </Modal>




    )
}

export default MiniTimline