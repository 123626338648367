import React, { useEffect, useState } from 'react'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as PlushIcons } from '../../../assets/icons/whitePlush.svg';
import { ReactComponent as ViewlistIcon } from '../../../assets/icons/Viewlist.svg';
import { ReactComponent as ViewgridIcon } from '../../../assets/icons/Viewgrid.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
import { ReactComponent as FilePng } from '../../../assets/icons/FilePng.svg';
import { ReactComponent as FilePdf } from '../../../assets/icons/FilePdf.svg';
import { ReactComponent as FileXls } from '../../../assets/icons/FileXls.svg';
import { ReactComponent as HeaderMenu } from '../../../assets/icons/HeaderMenu.svg';
import { ReactComponent as Vthreedots } from '../../../assets/icons/threedots.svg';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import SearchBox from '../../InputGroup/Searchbox';
import moment from 'moment';
import Lightbox from 'yet-another-react-lightbox';
import TooltipText from '../../Tooltip';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';
import UploadDocumentModal from '../Modal/UploadDocumentModal';
import PopUpModal from '../../Modals/PopUpModal';
import { toast } from 'react-toastify';
import { deleteLeadBookingDocs } from '../../../services/private/inventory.service';
import { bytesToMegaBytes } from '../../../helpers/helpers';

const Document = ({ documents, name, getAllDocuments, uuid, customer }) => {

    const [showLightModal, setShowLightModal] = useState(false);
    const [selectdImg, setSelectdImg] = useState();
    const [view, setView] = useState('grid');
    const [searchText, setSearchText] = useState('');
    const [documentsList, setDocumentsList] = useState(documents || []);
    const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        setDocumentsList(documents)
    }, [documents]);

    useEffect(() => {
        if (searchText) {
            const filteredList = documents.filter(doc => (doc.name || doc.category).toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            setDocumentsList(filteredList)
        } else {
            setDocumentsList(documents)
        }
    }, [searchText])

    // DOwnload all document in zip
    const downloadFiles = async () => {
        try {

            const zip = new JSZip();

            for (let index = 0; index < documents?.length; index++) {
                const detail = documents[index];

                const response = await fetch(detail.url);
                let filename = '';
                filename = detail.url.substring(detail.url.lastIndexOf('/') + 1);
                if (detail.name) {
                    let temp = filename.split('.')
                    let extension = temp[temp.length - 1]
                    // filename = `${detail.name}.${extension}`;
                    filename = `${detail.name}`;
                }
                const fileData = await response.arrayBuffer();
                zip.file(filename, fileData);
            }


            const zipContent = await zip.generateAsync({ type: 'blob' });

            // Save the zip file using file-saver
            saveAs(zipContent, `${name}.zip`);
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };

    const handleDelete = async () => {
        const payload = {
            documents: [selectedDoc?.id]
        }
        const res = await deleteLeadBookingDocs(payload)
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getAllDocuments()
            setShowDeleteModal(false);
        }
    }

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div>
                <div className='flex flex-row items-center'>
                    <div className='w-6/12'>
                        <h2 className='inter text-textblack font-semibold text-xl mb-0'>{name} Documents</h2>
                    </div>
                    <div className='w-6/12'>
                        <div className='flex items-center gap-x-3 justify-end'>
                            <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <SearchBox
                                    inputClass='!pl-8 !py-1.5 !pr-8 text-sm leading-5 rounded'
                                    searchIconClass='w-5 h-5'
                                    placeholder='Search'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    handelClear={() => setSearchText('')}
                                />
                            </div>
                            <div>
                                <div className='flex flex-row !border border-grayLight whitespace-nowrap h-8 rounded w-[144px] grow-0 shrink-0 basis-[144px]'>
                                    <button
                                        className={`flex items-center h-[30px] !px-2 gap-x-1 border-r border-grayLight text-sm text-black700 tracking-[0.5px] inter ${view === 'grid' ? 'bg-black200' : ''}`}
                                        onClick={() => setView('grid')}
                                    >
                                        <ViewgridIcon />Grid
                                    </button>
                                    <button
                                        className={`flex items-center h-[30px] !px-2 gap-x-1 border-r border-grayLight text-sm text-black700 tracking-[0.5px] inter ${view === 'list' ? 'bg-black200' : ''}`}
                                        onClick={() => setView('list')}
                                    >
                                        <ViewlistIcon />List
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    className='inline-flex items-center gap-x-2 text-sm font-medium !border !border-grayLight rounded bg-black100 h-[32px] !px-4 inter'
                                    onClick={downloadFiles}
                                >
                                    <ExportIcon />Export</button>
                            </div>
                            <div>
                                <button
                                    className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                    onClick={() => setShowUploadDocumentModal(true)}
                                ><PlushIcons /> Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="roles-table ml-24 mt-24 h-full overflow-auto">
                    {/* Table View */}
                    {documentsList.length > 0 && view === 'list' && <div className={`def-table pr-24 h-100 pb-0`}>
                        <table className='table'>
                            <thead className='rounded-lg'>
                                <tr className='border rounded-lg'>
                                    <th>S.No.</th>
                                    <th className='text-left'>Name</th>
                                    <th>Uploaded By</th>
                                    <th>Size</th>
                                    <th>Category</th>

                                    <th className='sticky -right-[10px]'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {documentsList.map((doc, index) => {
                                    return (
                                        <tr key={doc.uuid}>
                                            <td>{index + 1}</td>
                                            <td className='text-left cursor-pointer flex items-center capitalize'
                                                onClick={() => {
                                                    if (!['video/mp4'].includes(doc?.file_type)) {
                                                        setSelectdImg(index)
                                                        setShowLightModal(true)
                                                    } else {
                                                        window.open(doc.url, '_blank')
                                                    }
                                                }}
                                                style={{ paddingLeft: '8px' }}
                                            >
                                                {/* {doc.file_type === 'image/jpeg' && <span className='mr-2'> <FilePng /> </span>} */}
                                                {doc?.name || '-'}
                                            </td>
                                            <td>
                                                {doc?.uploaded_by || '-'}
                                            </td>
                                            <td>{doc?.size ? `${bytesToMegaBytes(doc.size)}` : '-'}</td>
                                            <td>
                                                {doc?.category || '-'}
                                            </td>
                                            {/* <td>{createDatewithTime(new Date(updated_on))}</td> */}
                                            <td className='dropdown view-more'>

                                                <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <TooltipText title='View Option' placement='left'>
                                                        <Vthreedots />
                                                    </TooltipText>
                                                </button>

                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a className="dropdown-item text-capitalize" onClick={
                                                        () => {
                                                            if (!['video/mp4'].includes(doc?.file_type)) {
                                                                setSelectdImg(index)
                                                                setShowLightModal(true)
                                                            } else {
                                                                window.open(doc.url, '_blank')
                                                            }
                                                        }
                                                    }>View Document</a>

                                                    <a
                                                        className="dropdown-item text-capitalize"
                                                        onClick={(e) => {
                                                            // e.stopImmediatePropagation();
                                                            setSelectdImg('')
                                                            setShowLightModal(false)
                                                            const link = document.createElement("a");
                                                            link.href = doc.url;
                                                            link.download = `${doc.name}`;
                                                            link.click();
                                                        }}
                                                    >Download</a>
                                                    <a className="dropdown-item del-menu" onClick={
                                                        () => {
                                                            // handleDelete(doc.id)
                                                            setSelectedDoc(doc)
                                                            setShowDeleteModal(true)
                                                        }
                                                    }>Delete</a>
                                                </div>

                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>}
                    {/* Grid View */}
                    {documentsList.length > 0 && view === 'grid' && <div className='grid sm:grid-cols-3 gap-x-6'>
                        {documentsList.map((item, index) => (
                            <div key={item.uuid} >
                                <div className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative flex items-center justify-center overflow-hidden'
                                >
                                    {!['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                        <img
                                            onClick={() => {
                                                if (!['video/mp4'].includes(item?.file_type)) {
                                                    setSelectdImg(index)
                                                    setShowLightModal(true)
                                                } else {
                                                    window.open(item.url, '_blank')
                                                }

                                            }}
                                            src={item.url} alt="" className='w-full' />}
                                    {['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                        <img
                                            onClick={() => {
                                                window.open(item.url, '_blank')
                                            }}
                                            src={pdfimg} alt="" className='w-full' />}
                                    <div
                                        className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-grayLight !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'
                                        onClick={(e) => {
                                            // e.stopImmediatePropagation();
                                            setSelectdImg('')
                                            setShowLightModal(false)
                                            const link = document.createElement("a");
                                            link.href = item.url;
                                            link.download = `${item.name}`;
                                            link.click();
                                        }}
                                    >
                                        <DownloadIcon />
                                    </div>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <p className='text-textblack text-base font-semibold inter !mt-1 w-100 truncate'>
                                        {item?.category}
                                    </p>
                                    <div className='dropdown view-more'>

                                        <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <TooltipText title='View Option' placement='left'>
                                                <Vthreedots />
                                            </TooltipText>
                                        </button>

                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                            <a className="dropdown-item text-capitalize" onClick={
                                                () => {
                                                    if (!['video/mp4'].includes(item?.file_type)) {
                                                        setSelectdImg(index)
                                                        setShowLightModal(true)
                                                    } else {
                                                        window.open(item.url, '_blank')
                                                    }
                                                }
                                            }>View Document</a>

                                            <a
                                                className="dropdown-item text-capitalize"
                                                onClick={(e) => {
                                                    // e.stopImmediatePropagation();
                                                    setSelectdImg('')
                                                    setShowLightModal(false)
                                                    const link = document.createElement("a");
                                                    link.href = item.url;
                                                    link.download = `${item.name}`;
                                                    link.click();
                                                }}
                                            >Download</a>
                                            <a className="dropdown-item del-menu" onClick={
                                                () => {
                                                    // handleDelete(doc.id)
                                                    setSelectedDoc(item)
                                                    setShowDeleteModal(true)
                                                }
                                            }>Delete</a>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>}
                    {
                        documentsList.length === 0 && <div>
                            <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Documents Added</h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p>

                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                            </div>
                        </div>
                    }

                </div>
            </div >
            {showLightModal && <Lightbox
                open={showLightModal}
                close={() => setShowLightModal(false)}
                slides={documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                carousel={{
                    finite: true
                }}
                index={selectdImg}
                plugins={[Thumbnails]}
            // video={{
            //     controls: true,
            //     playsInline: true,
            //     autoPlay: false,
            //     loop: false,
            //     muted: true,
            //     disablePictureInPicture: false,
            //     disableRemotePlayback: false,
            //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
            //     crossOrigin: '',
            //     preload: '',
            // }}
            />}
            {
                showUploadDocumentModal &&
                <UploadDocumentModal
                    show={showUploadDocumentModal}
                    closeModal={() => setShowUploadDocumentModal(false)}
                    getAllDocuments={getAllDocuments}
                    uuid={uuid}
                    documents={documents}
                    customer={customer}
                />
            }
            {
                showDeleteModal && selectedDoc?.id ? <PopUpModal
                    setShow={setShowDeleteModal}
                    show={showDeleteModal}
                    heading={<>
                        Delete {selectedDoc.name}?
                    </>}
                    body={<div className=' p-3'>
                        <div className="mb-4">
                            Are you sure you want to delete Document?
                        </div>
                        <div className="justify-content-end d-flex">
                            <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                            <button className="fw-po-medium del-btn px-16" onClick={handleDelete}>Confirm Delete</button>

                        </div>
                    </div>}
                /> : null
            }
        </div >
    )
}

export default Document
