import React, {Component } from 'react';
import { Link } from 'react-router-dom';

import './SideBanner.css';

class SideBanner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { Heading , Text } = this.props;
        return (
            
            <div className={`side-banner`} role="alert">
                <div className="logo d-flex align-items-center flex-row position-relative">
                    <Link to="/login"><img src="/assets/logo-whie.png" alt="" /></Link>
                    <div className="beta">BETA</div>
                </div>
                <div className="main-heading d-md-flex d-none">
                    <div className="siebar-text">
                     <h1 >   {Heading } </h1>
                     <p className="sub-heading-text"> { Text }  </p>
                     <div className="abs-logo">
                     <svg xmlns="http://www.w3.org/2000/svg" width="160" height="180" viewBox="0 0 160 180" fill="none">
                        <g clipPath="url(#clip0_585_32030)">
                        <ellipse cx="46" cy="131" rx="92" ry="94" fill="#4C91FF"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M80.5 213C148.155 213 203 157.707 203 89.5C203 21.2928 148.155 -34 80.5 -34C12.8451 -34 -42 21.2928 -42 89.5C-42 107.637 -38.1222 124.86 -31.1568 140.374L-20.4636 127.026L44.0986 46.5029L43.9278 87.6207L12.3583 127.026L43.757 127.055L43.6716 147.627L-27.6235 147.603C-7.00525 186.519 33.6799 213 80.5 213ZM43.5035 188.772L43.6743 147.654L75.2437 108.248L43.8451 108.22L43.9305 87.6472L124.519 87.6753L108.066 108.248L43.5035 188.772Z" fill="#0062FF"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_585_32030">
                        <rect width="160" height="180" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                     </div>
                     </div>

                </div>

                {/* <div className="side-image"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="207" height="222" viewBox="0 0 207 222" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M94.5 221.84C156.632 221.84 207 172.179 207 110.92C207 49.6605 156.632 0 94.5 0C32.368 0 -18 49.6605 -18 110.92C-18 172.179 32.368 221.84 94.5 221.84ZM94.5035 182.772L94.6743 141.654L126.244 102.248L94.8451 102.22L94.9305 81.6472L175.519 81.6753L159.066 102.248L94.5035 182.772ZM94.9278 81.6208L95.0986 40.5029L30.5364 121.026L14.0542 141.599L94.6716 141.627L94.757 121.055L63.3584 121.026L94.9278 81.6208Z" fill="#0062FF"/>
</svg>
                </div> */}
            </div>
        )
    }
}

export default SideBanner;
