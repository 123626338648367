import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { getStates, insertPricingGSTCharges, updatePricingGSTChargesByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';


const AddGstChargesModal = ({ show, closeModal, modalClass, uuid, getGSTChargeData, selectedPrice, statesList }) => {
    const [state, setState] = useState('');
    const [gstPercent, setGstPercent] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            setGstPercent(selectedPrice.payment_percentage);
            setDescription(selectedPrice.description);
            setState(statesList.find(item => item.value === selectedPrice.state));
        }
    }, [selectedPrice]);


    const handleSubmit = async () => {
        if (!state ||
            !gstPercent
        ) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            state: state.value,
            payment_percentage: gstPercent || null,
            payment_amount: null,
            description: description,
        }
        let res = {}
        if (selectedPrice?.uuid) {
            res = await updatePricingGSTChargesByUUID(selectedPrice.uuid, payload);
        } else {
            res = await insertPricingGSTCharges(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            handleClose();
            getGSTChargeData();
        }
    }

    const handleClose = () => {
        closeModal();
        setState('');
        setGstPercent('');
        setDescription('');
    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add GST Charges </h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>
                <InputSelect
                    label='Select State'
                    inputclass='!mt-0'
                    options={statesList}
                    value={state}
                    onChange={(value) => setState(value)}
                    error={(error && !state) ? 'Please select State' : ''}
                />
                <InputText
                    label='GST %'
                    placeholder='Enter Percentage'
                    value={gstPercent}
                    type={'number'}
                    onChange={(e) => setGstPercent(e.target.value)}
                    error={(error && !gstPercent) ? 'Please enter GST Percentage' : ''}
                />

                <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddGstChargesModal
