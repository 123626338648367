import React, { useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import InputField from '../form-fields/input-field';
import SelectField from '../form-fields/select-filed';
import TextareaField from '../form-fields/textarea-field';
import PrimaryButton from '../form-fields/primary-button';
import FileInputField from '../form-fields/file-input-fields';

const Sessions = ({
    session,
    index,
    id,
    handleChangeSession,
    handleSubmitSessions,
    guideList,
    mentorList,
    recordingList,
    mentor,
    guide,
    recording,
    serialList,
    serialOrder,
    sessionUUID,
    contentType,
    handelUpload,
    handleChangeMentor,
    handleChangeGuide,
    handleChangeRecording,
    handleChangeSerialOrder,
    handleChangeContentType,
    handleSubmitUpdateSessions,
    sessionFieldError
}) => {

    const [recordingSection, setRecordingSection] = useState('upload');
    const [guideSection, setGuideSection] = useState('guideupload');

    useEffect(() => {
        if (session?.upload_link) {
            setRecordingSection('upload');
            setGuideSection('guideupload');
        } else {
            setRecordingSection('collection');
            setGuideSection('guidecollection');
        }
    }, [session?.upload_link])

    return (
        <div className="w-full ">
            <div className="w-full rounded-2xl bg-white  flex flex-col disclosure">
                <Disclosure as='div'>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full items-center justify-between text-left text-sm font-medium text-black text-16 leading-10 !border-b border-black400 rounded-none	">
                                <span>{index + 1}. Add Sessions</span>
                                <div
                                    className={`${open ? 'rotate-180 transform mr-4' : 'mr-4'}`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579Z" fill="#696974"></path></svg>
                                </div>
                            </Disclosure.Button>
                            <Disclosure.Panel className=" !mt-5 mb-2">
                                <div className='flex items-center gap-2'>
                                    <button
                                        className={`${contentType === 'live' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[184px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                        onClick={() => handleChangeContentType('live', index)}>
                                        Live Session</button>
                                    <button
                                        className={`${contentType === 'recording' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[184px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                        onClick={() => handleChangeContentType('recording', index)}>
                                        Recording </button>
                                    <button
                                        className={`${contentType === 'guides' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[184px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                        onClick={() => handleChangeContentType('guides', index)}
                                    >
                                        Guides
                                    </button>
                                </div>
                                <div className="grid grid-cols-2 !gap-6 mt-4">
                                    {contentType === 'live' &&
                                        <InputField
                                            label='Session Name'
                                            placeholder='Session Name'
                                            inputRequired={true}
                                            type='text'
                                            name='name'
                                            value={session.name}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.name}
                                        />}
                                    {contentType === 'live' &&
                                        <InputField
                                            label='Zoom Link or Meet Link'
                                            placeholder='Copy zoom or Meet link'
                                            inputRequired={false}
                                            type='text'
                                            name="zoom_link"
                                            value={session.zoom_link}
                                            onChange={(e) => handleChangeSession(e, index)}
                                        />}
                                    {contentType === 'live' &&
                                        <InputField
                                            label='Session Date'
                                            placeholder='dd/mm/yyyy'
                                            inputRequired={true}
                                            inputClass='relative h-[45px]'
                                            type='date'
                                            min={new Date().toISOString().split('T')[0]}
                                            name='session_date'
                                            value={session.session_date}
                                            error={sessionFieldError && !session.session_date}
                                            onChange={(e) => handleChangeSession(e, index)}
                                        />

                                    }
                                    {contentType === 'live' && <div className='grid grid-cols-2 no-label gap-x-4'>
                                        <div className='col-span-2 w-full text-14 text-black700 mb-2'>
                                            Start Time & End Time<sup className='text-red text-14'>*</sup>
                                        </div>
                                        <InputField
                                            label=''
                                            placeholder='hh:mm'
                                            type='time'
                                            inputClass='relative'
                                            name='session_start_time'
                                            value={session.session_start_time}
                                            error={sessionFieldError && !session.session_start_time}
                                            onChange={(e) => handleChangeSession(e, index)}
                                        />
                                        <InputField
                                            placeholder='hh:mm'
                                            type='time'
                                            inputClass='relative'
                                            name='session_end_time'
                                            value={session.session_end_time}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.session_end_time}
                                        />
                                    </div>}
                                    {contentType === 'recording' &&
                                        <div className='col-span-2'>
                                            <p className='block text-14 text-black700 mb-2'>Select upload type</p>
                                            <div className='flex items-center gap-2'>
                                                <button
                                                    className={`${recordingSection === 'upload' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[184px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                                    onClick={() => setRecordingSection('upload')}
                                                >
                                                    Upload Recording
                                                </button>
                                                <button
                                                    className={`${recordingSection === 'collection' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[190px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                                    onClick={() => setRecordingSection('collection')}
                                                >
                                                    Select From Collection
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {contentType === 'recording' &&
                                        <InputField
                                            label='Recording Name'
                                            inputRequired={true}
                                            placeholder='Recording Name'
                                            type='text'
                                            name='name'
                                            value={session.name}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.name}
                                        />}

                                    {contentType === 'recording' &&
                                        <InputField
                                            label='Recording Length'
                                            inputRequired={true}
                                            placeholder='In minutes'
                                            type='text'
                                            name='recording_length'
                                            value={session.recording_length}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.recording_length}
                                        />}

                                    {contentType === 'recording' && recordingSection === 'upload' &&
                                        <InputField
                                            label='Recording Url'
                                            placeholder='Recording Url'
                                            inputRequired={true}
                                            type='text'
                                            name='upload_link'
                                            value={session.upload_link}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.upload_link}
                                        />}
                                    {contentType === 'recording' && recordingSection === 'collection' &&
                                        <SelectField
                                            label='Select Recording'
                                            inputRequired={true}
                                            value={session?.workshop_id ? recordingList.find(item => item.value === session.workshop_id) : ''}
                                            options={recordingList}
                                            onChange={(value) => handleChangeRecording(value, index)}
                                            name='Select Recording'
                                            placeholder='Select Recording'
                                            error={sessionFieldError && !session.workshop_id}
                                            isMulti={false}
                                        />}

                                    {(contentType === 'recording' || contentType === 'live') &&
                                        <SelectField
                                            label='Mentor'
                                            inputRequired={contentType === 'live'}
                                            value={mentor[index] || ''}
                                            options={mentorList}
                                            onChange={(value) => handleChangeMentor(value, index)}
                                            name='Select Mentor'
                                            placeholder='Select Mentor'
                                            isMulti={true}
                                            error={sessionFieldError && !mentor[index]?.length && contentType === 'live'}
                                        />}

                                    {contentType === 'guides' && <div className='col-span-2'>
                                        <p className='block text-14 text-black700 mb-2'>Select upload type</p>
                                        <div className='flex items-center gap-2'>
                                            <button
                                                className={`${guideSection === 'guideupload' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[184px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                                onClick={() => setGuideSection('guideupload')}
                                            >
                                                Upload Guide
                                            </button>
                                            <button
                                                className={`${guideSection === 'guidecollection' ? '!bg-primary border-primary text-white' : 'border-[#D0D5DD] text-black800 bg-white'} w-[190px] border rounded-lg text-14 font-medium  tracking-05px py-2.5 `}
                                                onClick={() => setGuideSection('guidecollection')}
                                            >
                                                Select From Collection
                                            </button>
                                        </div>
                                    </div>}
                                    {contentType === 'guides' &&
                                        <InputField
                                            label='Guides Name'
                                            inputRequired={true}
                                            placeholder='Name'
                                            type='text'
                                            name='name'
                                            value={session.name}
                                            onChange={(e) => handleChangeSession(e, index)}
                                            error={sessionFieldError && !session.name}

                                        />}
                                    {contentType === 'guides' && guideSection === 'guideupload' &&
                                        <FileInputField
                                            label='Guide Upload'
                                            inputRequired={true}
                                            placeholder='Upload from system'
                                            type='file'
                                            name='upload_link'
                                            accept='application/pdf'
                                            onChange={(e) => handelUpload(e, index)}
                                            error={sessionFieldError && !session.upload_link}
                                        />}

                                    {contentType === 'guides' && guideSection === 'guidecollection' &&
                                        <SelectField
                                            label='Select Guide'
                                            inputRequired={true}
                                            value={session.guide_id ? guideList.find(item => item.value === session.guide_id) : ''}
                                            onChange={(value) => handleChangeGuide(value, index)}
                                            options={guideList}
                                            isMulti={false}
                                            name='Select from collection'
                                            placeholder='Select from collection'
                                            error={sessionFieldError && !session.guide_id}
                                        />
                                    }
                                    <SelectField
                                        label='Serial Order'
                                        value={serialOrder[index] || ''}
                                        options={serialList}
                                        onChange={(value) => handleChangeSerialOrder(value, index)}
                                        name='Select Serial'
                                        placeholder='Select Serial'
                                        isMulti={false}

                                    />

                                    {(contentType === 'recording' || contentType === 'live') && <TextareaField divClass='col-span-2'
                                        label='Short Description'
                                        placeholder='Add short description'
                                        name='short_description'
                                        value={session.short_description}
                                        onChange={(e) => handleChangeSession(e, index)}
                                    />}
                                </div>
                                <PrimaryButton
                                    className='px-8 py-2 font-medium text-16 float-right my-4'
                                    name={'Save'}
                                    onClick={() => {
                                        if (sessionUUID[index]) {
                                            handleSubmitUpdateSessions(index)
                                        } else {
                                            handleSubmitSessions(index)
                                        }
                                    }}
                                ></PrimaryButton>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    )
}

export default Sessions;
