import React, { useState, useEffect } from 'react';
import moment from 'moment';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import InputSelect from '../InputGroup/InputSelect';
import { eoiDoneSchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import { getLeadByUuid, getProjectsByLead, insertEoiDone } from '../../services/private/leads.service';
import DateTimePicker from '../InputGroup/DateTime';
import { getAllProjects } from '../../services/private/company.service';
import InputText from '../InputGroup/InputText';
import { toINRFormat } from '../../helpers/helpers';
import { validityUnit } from '../../helpers/enums';
import { getDetailsByLeadId } from '../../services/private/inventory.service';
import InputRupees from '../InputGroup/InputRupees';

export default function EoiDoneModal({
    show,
    setShow,
    leadId,
    onSuccess,
    EOIData,
    data,
    isEdit = false
}) {

    const [projects, setProjects] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [leadDetails, setLeadDetails] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tokenAmount, setTokenAmount] = useState();
    const [unitPrice, setUnitPrice] = useState();
    const [scheduleDate, setScheduleDate] = useState('');
    const [remarks, setRemarks] = useState('');
    const [followupScheduleDate, setFollowupScheduleDate] = useState('');
    const [projectId, setProjectId] = useState('');
    const [amount, setAmount] = useState('');
    const [validity, setValidity] = useState(moment(new Date()).add('days', 30));
    const [unitNo, setUnitNo] = useState('');
    const [unit_price, setUnit_price] = useState('');
    const [errors, setErrors] = useState(false)

    const getLeadProjects = async (leadId) => {
        await getProjectsByLead(leadId).then(res => {
            if (res.status === 200) {
                const projectData = res.data.data.map((data) => {
                    return {
                        value: data.id,
                        label: data.name
                    }
                })
                setProjectId(projectData[0]?.value)
                setProjects(projectData)
            }
        })
    }

    const handleEoiComplete = async () => {
        if (!scheduleDate || !followupScheduleDate || !projectId) {
            setErrors(true)
            return;
        }

        setIsSubmitting(true);
        await insertEoiDone(selectedLeadId, {
            "scheduleDate": scheduleDate,
            "remarks": remarks,
            "projectId": projectId,
            "followupScheduleDate": followupScheduleDate,
            tokenAmount: amount || null,
            validity: validity,
            unit_no: unitNo || null,
            unit_price: unit_price || null
        }).then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                toast.success(`Token Done for ${EOIData?.name || data?.name}`)
                onSuccess();
                setShow(false)
            }
        }).catch(e => {
            toast.warn("Something Went Wrong")
        })
        setIsSubmitting(false);
    }


    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    const getLeadDetails = async () => {
        try {
            getDetailsByLeadId(leadId).then(res => {
                if (res.status === 200) {
                    setUnitPrice(`₹ ${toINRFormat(res.data.data?.booking?.unit_price)}`)
                    setTokenAmount(`₹ ${toINRFormat(res.data.data?.booking?.token_amount)}`);
                    setUnit_price(res.data.data?.booking?.unit_price)
                    setAmount(res.data.data?.booking?.token_amount || '');
                    setFollowupScheduleDate(res.data.data?.followup?.schedule_date || '')
                    setProjectId(res.data.data?.project?.id || '')
                    setScheduleDate(res.data.data?.booking.schedule_date || '');
                    setRemarks(res.data.data?.booking?.remarks || '')
                    setValidity(res.data.data?.booking?.validity);
                    setUnitNo(res.data.data?.booking?.unit_no)
                }
            }).catch(err => {
                console.log(err.response)
            })
        } catch (err) {
            console.log(err.response)
        }
    }

    useEffect(() => {
        getLeadDetails();
        getAllProjectsByCompanyId();
    }, [])

    useEffect(() => {
        if (leadId) {
            setSelectedLeadId(leadId)
            getLeadProjects(leadId)
        }
    }, [leadId]);

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                "Token Done & Followup"
            }
            body={
                <>
                    {/* <Formik
                        initialValues={{
                            scheduleDate: leadDetails?.schedule_date ? leadDetails?.schedule_date : '',
                            remarks: leadDetails?.remarks ? leadDetails?.remarks : '',
                            followupScheduleDate: leadDetails?.followupScheduleDate ? leadDetails?.followupScheduleDate : '',
                            projectId: leadDetails?.projectId ? leadDetails.projectId : projects[0]?.value,
                            amount: leadDetails?.token_amount ? leadDetails?.token_amount : '',
                            validity: leadDetails?.validity ? leadDetails?.validity : moment(new Date()).add('days', 30),
                            unit_no: leadDetails?.unit_no ? leadDetails?.unit_no : '',
                            unit_price: leadDetails?.unit_price ? leadDetails?.unit_price : ''
                        }}
                        onSubmit={async (values, actions) => {
                            await handleEoiComplete(values)
                        }}
                        validationSchema={eoiDoneSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    > */}
                    {/* {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return ( */}
                    <div className='flex flex-col h-full overflow-hidden'>
                        <div className='h-full overflow-auto !px-5'>
                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''} `}>
                                <label className='mt-3'>Lead Name</label>
                                <div className='text-capitalize fz16 fw-po-medium'>
                                    {EOIData?.name || data?.name}
                                </div>
                            </div>
                            <DateTimePicker
                                onValueChange={(e) => {
                                    setScheduleDate(e);
                                    setValidity(moment(e).add('days', 30))
                                }}
                                label="Token Date & Time"
                                name="scheduleDate"
                                error={errors && !scheduleDate ? 'Token Date is required' : ''}
                                value={scheduleDate}
                            />
                            <label className='mt-3'>Project</label>

                            <InputSelect
                                index={4}
                                name="Project"
                                error={errors && !projectId ? 'Project is required' : ''}
                                // touched={touched['projectId']}
                                isMulti={false}
                                options={[...projectList]}
                                value={projectList.find(proj => proj.value === projectId)}
                                placeholder="Select Project"
                                onChange={(e) => {
                                    setProjectId(e.value)
                                }}
                            />
                            <InputRupees
                                value={amount}
                                index={3}

                                type="text"
                                placeholder={`e.g. 10,00,000`}
                                name="amount"
                                handleBlur={(value) => {
                                    setAmount(value)
                                }}
                                onChange={(value) => {
                                    setAmount(value)
                                    // setTokenAmount(value.value)
                                }}
                                label="Token Amount"
                            />
                            <DateTimePicker
                                onValueChange={(e) => {
                                    // const value = {
                                    //     target: {
                                    //         name: "validity",
                                    //         value: e
                                    //     }
                                    // }
                                    // handleChange(value)
                                    setValidity(e)
                                }}
                                label="Validity Date"
                                name="validity"
                                error={errors['validity']}
                                handelBlur={() => { }}
                                value={validity}
                                showTime={false}
                            />

                            <InputRupees
                                value={unit_price}


                                index={5}
                                type="text"
                                placeholder={`e.g. 10,00,000`}
                                name="unit_price"
                                handleBlur={(value) => {
                                    setUnit_price(value)
                                }}
                                onChange={(value) => {

                                    setUnit_price(value)
                                    // handleChange(value)
                                    // setUnitPrice(value.value)
                                }}
                                label="Total unit price"
                            />
                            <InputText
                                label={'Unit no'}
                                type="text"
                                name={'unit_no'}
                                error={errors['unitNo']}
                                // touched={touched['unit_no']}
                                placeholder={'e.g. B101'}
                                value={unitNo}
                                inputclass={'!mt-4'}
                                onChange={(e) => {
                                    const value = {
                                        target: {
                                            name: "unit_no",
                                            value: e.target.value

                                        }
                                    }
                                    // handleChange(value)
                                    setUnitNo(e.target.value)
                                }}
                            />

                            <label className='mt-3'>Any Comments (optional)</label>
                            <textarea
                                className="form-control mb-16 "

                                placeholder=" Enter any Token comments"
                                onChange={(e) => setRemarks(e.target.value)}
                                value={remarks}
                                name="remarks"
                            ></textarea>

                            <DateTimePicker
                                onValueChange={(e) => {
                                    // const value = {
                                    //     target: {
                                    //         name: "followupScheduleDate",
                                    //         value: e
                                    //     }
                                    // }
                                    // handleChange(value)
                                    setFollowupScheduleDate(e)
                                }}
                                label="Select Followup Date & Time"
                                name="followupScheduleDate"
                                error={errors && !followupScheduleDate ? 'Followup Date is required' : ''}
                                value={followupScheduleDate}

                            />
                            {followupScheduleDate ?
                                <div className="w-100 text-center mt-2 px-24" >

                                    <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(followupScheduleDate).format("hh:mm a, Do MMM YYYY")}</span></p>
                                </div>
                                : " "}
                        </div>

                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                            <button
                                className="cancel outline-btn"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="pr-btn"
                                type="submit"
                                disabled={isSubmitting}
                                onClick={handleEoiComplete}
                            >
                                Confirm
                            </button>

                        </div>
                    </div>
                    {/* </form>
                                )
                            }}
                    </Formik> */}

                </>
            }
        />
    )
}