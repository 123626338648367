import React, { useContext, useState } from 'react';

import StatusDropDownDynamic from "../../Status Dropdown/StatusDropdownDynamic"
import EoiDoneModal from '../../Modals/EoiDoneModal'
import BookingsDoneModal from '../../Modals/BookingsDoneModal'
import UpdateFollowupModal from '../../Modals/FollowupModal/UpdateFollowupModal';

import { toast } from 'react-toastify';
import { updateleadByKeyValue } from '../../../services/private/company.service';
import UpdateSiteVistModal from '../../Modals/SiteVistModal/UpdateSiteVisitModal';
import SiteVisiteDoneModal from '../../Modals/SiteVisiteDoneModal';
import MarkLeadDead from '../../Modals/MarkLeadDead';
import { getLatestActivityByUuid } from '../../../services/private/leads.service';
import BookingModal from '../../Modals/BookingModal';
import Consumer from '../../../helpers/context';
import EOIModal from '../../Modals/EOIModal';
import SiteVisiteCancelledModal from '../../Modals/SitevisiteCancelledModal';
import NewEOIModal from '../../Modals/NewEOIModal';
import NewBookingModal from '../../Modals/NewBookingModal';

export default function StatusDropDown({
    value,
    options,
    id,
    reloadData,
    leadName,
    intentOptions,
    data,
    width,
    updateLead,
    handleOpenVerifyPage
}) {

    const [followUp, setFollowUp] = useState(false)
    const [siteVisit, setSiteVisit] = useState(false)
    const [eoiDone, setEoiDone] = useState(false)
    const [bookingDone, setBookingDone] = useState(false)
    const [svCompleted, setSvCompleted] = useState(false)
    const [svCancelled, setSvCancelled] = useState(false);
    // state to update status change 
    const [changeDataTemp, setChangeDataTemp] = useState()
    const [markDead, setMarkDead] = useState(false)
    const [deadUuid, setDeadUuid] = useState();
    const [selectedOption, setSelectedOption] = useState({});

    const { userProfile } = useContext(Consumer)

    const handleSvCompleted = () => {
        const leadId = changeDataTemp?.uuid || id
        getLatestActivityByUuid(leadId).then(res => {
            if (!res?.siteVisit || Object.keys(res?.siteVisit)?.length === 0) {
                toast.error('Cannot change status as no SV is scheduled.')
                return;
            }
            setSvCompleted(true)
        });
    }

    const handleSvCancelled = () => {
        const leadId = changeDataTemp?.uuid || id
        // getLatestActivityByUuid(leadId).then(res => {
        //     if (!res?.siteVisit || Object.keys(res?.siteVisit)?.length === 0) {
        //         toast.error('Cannot change status as no SV is scheduled.')
        //         return;
        //     }
        setSvCancelled(true)
        // });
    }

    const handleStatusChange = async (value, uuid) => {
        // await updateLeadData(uuid, { key: "status_id", value: value.value })
        setChangeDataTemp({ uuid: uuid, value: value.value, label: value.label })
        //@as discussed with ankit and uday
        switch (value.label) {
            case "Not Connected":
                //Not connected
                setFollowUp(true)
                break;
            case "In Progress":
                //in progress
                setFollowUp(true)
                break
            case "Final Negotiation":
                //in progress
                setFollowUp(true)
                break
            case "SV Scheduled":
                //sv -sheduled
                setSiteVisit(true)
                break;
            case "SV Completed":
                //sv-completed
                // setSvCompleted(true)
                handleSvCompleted();
                break;
            case "SV Cancelled":
                //sv-completed
                // setSvCompleted(true)
                handleSvCancelled();
                break;
            case "EOI Completed":
                //eoi - completed
                setEoiDone(true)
                break;
            case "Token Done":
                //eoi - completed
                setEoiDone(true)
                break;

            case "Booking Completed":
                //booking completed
                setBookingDone(true)
                break;
            case "Dead Lead":
                setDeadUuid(uuid)
                setSelectedOption(value)
                setMarkDead(true)
                break;
            case "Dump":
                setDeadUuid(uuid)
                setSelectedOption(value)
                setMarkDead(true)
                break;
        }

    }

    const updateLeadData = async (uuid, { key, value }) => {
        if (updateLead) {
            updateLead(value)
        }
        // updateLead(value)
        await updateleadByKeyValue(uuid, { key, value }).then(res => {
            if (res.status === 200) {
                reloadData();
            }
        }).catch(e => {
            console.log(e.response);
            // toast.error(e.response?.data?.message);
        })
    }

    return (
        <>
            {followUp && <UpdateFollowupModal
                setShow={setFollowUp}
                show={followUp}
                leadName={leadName}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentOptions}
                selectedIntent={data.intent}
                selectedStatus={data.status}
                statusLabel={changeDataTemp.label}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
            />}

            {siteVisit && <UpdateSiteVistModal
                show={siteVisit}
                setShow={setSiteVisit}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentOptions}
                selectedIntent={data.intent}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
            />}
            {/* 
                New EOI Modal flow
            */}
            {/* {
                (userProfile?.allowed_modules?.includes('new_booking') && eoiDone && +userProfile?.company_id !== +process.env.REACT_APP_COMPANY_ID) &&
                <EOIModal
                    show={eoiDone}
                    closeModal={() => setEoiDone(false)}
                    lead={changeDataTemp}
                    data={{ ...data, status: changeDataTemp.value }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            } */}
            {
                (userProfile?.allowed_modules?.includes('new_booking') && eoiDone) &&
                // +userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) &&
                <NewEOIModal
                    show={eoiDone}
                    closeModal={() => setEoiDone(false)}
                    lead={changeDataTemp}
                    data={{ ...data, status: changeDataTemp.value }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            }
            {(!userProfile?.allowed_modules?.includes('new_booking') && eoiDone) &&
                <EoiDoneModal
                    show={eoiDone}
                    setShow={setEoiDone}
                    leadId={changeDataTemp.uuid}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                    intentOptions={intentOptions}
                    selectedIntent={data.intent}
                    data={data}
                />}

            {/* 
                New Booking Modal flow
            */}
            {/* {
                (userProfile?.allowed_modules?.includes('new_booking') && bookingDone && (+userProfile?.company_id !== +process.env.REACT_APP_COMPANY_ID)) &&
                <BookingModal
                    show={bookingDone}
                    closeModal={() => setBookingDone(false)}
                    lead={changeDataTemp}
                    data={{ ...data, status: changeDataTemp.value }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            } */}
            {
                (userProfile?.allowed_modules?.includes('new_booking') && bookingDone) &&
                // (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) &&
                <NewBookingModal
                    show={bookingDone}
                    closeModal={() => setBookingDone(false)}
                    lead={changeDataTemp}
                    data={{ ...data, status: changeDataTemp.value }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            }

            {(!userProfile?.allowed_modules?.includes('new_booking') && bookingDone) && <BookingsDoneModal
                show={bookingDone}
                setShow={setBookingDone}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                data={data}
            />}

            {svCompleted && <SiteVisiteDoneModal
                show={svCompleted}
                setShow={setSvCompleted}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentOptions}
                selectedIntent={data.intent}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
            />}

            {svCancelled && <SiteVisiteCancelledModal
                show={svCancelled}
                setShow={setSvCancelled}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentOptions}
                selectedIntent={data.intent}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
            />}

            {markDead && <MarkLeadDead
                show={markDead}
                setShow={setMarkDead}
                uuid={deadUuid}
                updateData={reloadData}
                selectedOption={selectedOption}
                data={data}
                statusId={changeDataTemp.value}
                reassindedUserUuid={data?.assigned_user_uuid}
            />}

            <StatusDropDownDynamic
                value={value}
                onChange={e => handleStatusChange(e, id)}
                options={options}
                placeholder='Select Status'
                data={data}
                width={width}
                handleOpenVerifyPage={handleOpenVerifyPage}
            />
        </>
    )
}