import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { getUsersForSettings } from "../../services/private/company.service";
import InputSelect from "../InputGroup/InputSelect";
import SliderModal from "./SliderModal";
import Consumer from '../../helpers/context';
import { getAllTeams } from "../../services/public/public.service";
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/NavRight.svg';
import { ReactComponent as UserPlusIcon } from '../../assets/icons/userplus.svg';
import { ReactComponent as TextFileIcon } from '../../assets/icons/fileTextGrey.svg';
import { ReactComponent as InfoIcon } from '../../assets/infoblue.svg';
import { ReactComponent as BlueRightIcon } from '../../assets/icons/blueRightIcon.svg';
import { reassignLeads } from "../../services/private/leads.service";
import { toast } from "react-toastify";
import TooltipText from "../Tooltip";
import { getInitials } from "../../helpers/helpers";
import ReassignLeadsManageModal from "./ReassignLeadsManageModal";
import { Oval } from "react-loader-spinner";

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',

        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

export default function ReassignLeadToTeamAndUserModal({
    setShow,
    show,
    handleSubmit,
    assignedTo,
    count,
    selectedLeadName,
    resetDataMods,
    selectedTableValues,
    getLeadsData,
    getAllLeadsCount,
    setSelectedTableData,
    selectedTableData,
    setSelectedTableValues
}) {

    const [allTeams, setAllTeams] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [error, setError] = useState();
    const [remarks, setRemarks] = useState();
    const [selectedTeam, setSeletecedTeam] = useState([]);
    const [viewMoreIndex, setViewMoreIndex] = useState([]);
    const [teamAndUsersList, setTeamAndUsersList] = useState([]);
    const [isAllLeadDead, setIsAllLeadDead] = useState(false);
    const [showMangeLeadModal, setShowMangeLeadModal] = useState(false);
    const [newLead, setNewLead] = useState(true);
    const [hideTimeline, setHideTimeline] = useState(true);
    const [loading, setLoading] = useState(false);

    const { userProfile } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data.map(data => ({ ...data, label: data.name, value: data.id, isTeam: true }))
            setAllTeams(mapped);
        }
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        name: data.name,
                        email: data.email,
                        value: data.uuid,
                        isTeam: false
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    const handleSubmitUser = () => {
        if (!selectedUser || count === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        handleSubmit(selectedUser, remarks);
    }

    useEffect(() => {
        getAll();
        getAllUsers();
    }, []);

    useEffect(() => {
        if (allTeams && allUsers) {
            setTeamAndUsersList([...allTeams, ...allUsers])
        }
    }, [allTeams, allUsers])

    useEffect(() => {
        if (selectedTableData.length > 0) {
            let isDead = selectedTableData.every(i => (i.status_name === "Dead Lead" || i.status_name === "Dump"));
            setIsAllLeadDead(isDead);
        }
    }, [selectedTableData]);

    const handleReassignModalSubmit = () => {
        let user_array = [];
        // Getting individual user ids
        let ids = selectedTeam.filter(i => !i.isTeam).map(i => i.value);

        if (Object.keys(selectedUser)?.length === 0 && ids.length === 0) {
            toast.error('Please select users from team')
            return;
        }


        // Getting selected user ids from teams
        if (Object.keys(selectedUser)?.length > 0) {
            Object.keys(selectedUser).forEach((item, index) => {
                let userArray = selectedUser[item].map(u => u);
                user_array = [...user_array, ...userArray];
            })
        }

        let mergedArr = [...new Set(user_array)];

        mergedArr = [...mergedArr, ...ids]
        let selectedTableIds = selectedTableData.map(i => i.id);
        const payload = {
            ...(isAllLeadDead) && { new_lead: newLead },
            ...(isAllLeadDead) && { hide_timeline: hideTimeline }
        }

        setLoading(true)
        reassignLeads(selectedTableIds, mergedArr, '', payload).then(res => {
            if (res.status === 200) {
                setLoading(false)
                toast.success("Lead Reassigned");
                getAllLeadsCount();
                getLeadsData();
                setShow(false);
                setSelectedUser({});
                setSeletecedTeam([]);
                resetDataMods()
            }
        }).catch(e => {
            setLoading(false)
            toast.error(e.response.data.message)
            resetDataMods();
        })

    }

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title={`Reassign Lead (${selectedTableData.length})`}
            body={<>
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto">
                        {count === 1 &&
                            <div className="form-group">
                                <label className=''>Lead Name</label>
                                <div className='text-capitalize fz16 fw-po-medium '>
                                    {selectedLeadName ? selectedLeadName : '-'}
                                </div>
                            </div>}

                        <InputSelect
                            index={4}
                            name="user"
                            menuPlacement="bottom"
                            value={''}
                            options={teamAndUsersList}
                            onChange={(value) => {
                                const index = selectedTeam.findIndex(i => i.value === value.value)
                                if (index === -1) {
                                    setSeletecedTeam([...selectedTeam, value])
                                    let usersList = [...teamAndUsersList];
                                    let uIndex = usersList.findIndex(i => i.value === value.value)
                                    usersList.splice(uIndex, 1);
                                    setTeamAndUsersList(usersList);
                                }
                            }}
                            placeholder="Select Team(s)"
                            label="Select Team(s)"
                            error={error && !selectedTeam ? 'Please Select Team' : ''}
                            styles={customStyles}
                        // isMulti={userProfile?.mulit_user_assign && count > 1}
                        />
                        {(userProfile?.allowed_modules?.includes('dead_to_new_lead') &&
                            (ROLE_NAME === 'Super Admin' || userProfile?.role?.allowed_permissions?.includes('dead_to_new'))) &&
                            <div className={`rounded-lg shadow-bottomshadow border mt-3 ${!isAllLeadDead ? 'bg-[#F1F1F6]' : ''}`}>
                                <div className={`flex p-3 items-start justify-between border-b border-grayLight `}>
                                    <div className="mr-2">
                                        <div className="flex text-base items-center font-semibold mb-2">
                                            <span className="mr-2">
                                                <UserPlusIcon />
                                            </span>
                                            <p className={`m-0 ${!isAllLeadDead ? 'text-[#444444]' : ''}`}>Share as New Lead</p>
                                        </div>
                                        <p className="text-black700">
                                            All selected dead leads will be converted to <b> New lead</b> before re-assigning.
                                        </p>
                                    </div>
                                    <input type="checkbox" checked={newLead} value={newLead} onChange={() => setNewLead(!newLead)} disabled={!isAllLeadDead} />
                                </div>
                                <div className="flex p-3 justify-between items-start">
                                    <div className="mr-2">
                                        <div className="flex text-base items-center font-semibold mb-2">
                                            <span className="mr-2">
                                                <TextFileIcon className="text-grey" />
                                            </span>
                                            <p className={`m-0 ${!isAllLeadDead ? 'text-[#444444]' : ''}`}>Hide Timeline</p>
                                        </div>
                                        <p className="text-black700">
                                            Converted <b> New lead</b> will have fresh timeline.
                                        </p>
                                    </div>
                                    <input type="checkbox" checked={hideTimeline} value={hideTimeline} onChange={() => setHideTimeline(!hideTimeline)} disabled={!isAllLeadDead} />
                                </div>
                            </div>}
                        {(userProfile?.allowed_modules?.includes('dead_to_new_lead') &&
                            (ROLE_NAME === 'Super Admin' || userProfile?.role?.allowed_permissions?.includes('dead_to_new'))) &&
                            <div className="">
                                <div className="flex border border-primary p-2 rounded-lg my-3">
                                    <span className="mr-2">
                                        <InfoIcon />
                                    </span>
                                    <p className="m-0 ">
                                        This feature is available only if all selected leads are dead.
                                        <a className="text-primary ml-1" onClick={() => setShowMangeLeadModal(true)}>Manage selection <BlueRightIcon className="inline" /> </a>
                                    </p>
                                </div>
                            </div>}
                        <div className="">
                            {
                                selectedTeam.length > 0 && selectedTeam.map((item, index) => (
                                    item?.isTeam ? <div className="mt-4 border p-4 rounded-lg" key={`${item.id}_${index}`}>
                                        <div className="flex justify-between items-start mb-3">
                                            <div>
                                                <p className="m-0 text-base font-semibold">{item.name}</p>
                                                <p className="m-0 text-xs text-black700 font-medium">Lead: {item.leader_name}</p>
                                            </div>
                                            <button onClick={() => {
                                                let usersList = [...teamAndUsersList];
                                                usersList.push(item);
                                                setTeamAndUsersList(usersList);
                                                let selectedTeamCopy = [...selectedTeam];
                                                selectedTeamCopy.splice(index, 1);
                                                let selectedUserCopy = { ...selectedUser };
                                                delete selectedUserCopy[index]
                                                setSeletecedTeam(selectedTeamCopy);
                                                setSelectedUser({ ...selectedUserCopy })
                                            }}>
                                                <CloseIcon className="h-5 w-5 text-black700" />
                                            </button>
                                        </div>
                                        {viewMoreIndex?.includes(index) && item.users?.length > 0 && item.users.map((user, userIndex) => (
                                            <div className="flex justify-between px-4 border-b pb-2 bg-blue-light rounded-lg">
                                                <div className="py-2">
                                                    <p className="m-0 text-sm font-semibold">{user.name}</p>
                                                    <p className="m-0 text-xs text-black700 font-medium">{user?.email}</p>
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUser && selectedUser[index]?.includes(user.uuid)}
                                                    onChange={() => {

                                                        // Need to change this logic                                                        
                                                        if (Object.keys(selectedUser).length > 0) {
                                                            let userIds = [...selectedUser[index] || []]
                                                            const uIndex = userIds?.indexOf(user.uuid);
                                                            if (uIndex > -1) {
                                                                let selectedUserCopy = { ...selectedUser };
                                                                userIds.splice(uIndex, 1);
                                                                selectedUserCopy[index] = userIds
                                                                setSelectedUser({ ...selectedUserCopy })
                                                            } else {
                                                                let selectedUserCopy = { ...selectedUser };
                                                                if (!selectedUserCopy[index]) {
                                                                    selectedUserCopy[index] = []
                                                                }
                                                                selectedUserCopy[index].push(user.uuid)
                                                                setSelectedUser(selectedUserCopy)
                                                            }
                                                        } else {
                                                            let selectedUserCopy = { ...selectedUser };
                                                            if (!selectedUserCopy[index]) {
                                                                selectedUserCopy[index] = []
                                                            }
                                                            selectedUserCopy[index].push(user.uuid);
                                                            setSelectedUser(selectedUserCopy)
                                                        }
                                                    }}
                                                    name=""
                                                    id="" />
                                            </div>
                                        ))
                                        }
                                        <div className="flex justify-end mt-2">
                                            <button
                                                className="flex items-center"
                                                onClick={() => {
                                                    if (viewMoreIndex?.includes(index)) {
                                                        let indexes = [...viewMoreIndex];
                                                        indexes.splice(index, 1);
                                                        setViewMoreIndex(indexes)
                                                    } else {
                                                        setViewMoreIndex([...viewMoreIndex, index])
                                                    }
                                                }}>
                                                {viewMoreIndex?.includes(index) ? 'Hide' : 'View'} Users
                                                <RightIcon className={`ml-1 h-5 w-5 ${viewMoreIndex?.includes(index) ? '-rotate-90' : 'rotate-90'}`} />
                                            </button>
                                        </div>
                                    </div> :
                                        <div className='flex justify-between items-center border rounded-md p-2.5 mt-2' key={`${item.name}_${index}`}>

                                            <span className="mx-2 border p-1 font-bold rounded-md text-2xl bg-[#F2F2F2] text-grey-300" title={item.name}>
                                                {getInitials(item.name || '')}
                                            </span>

                                            <div>
                                                <h6 className='m-0 truncate w-[220px]'>{item.name}</h6>
                                                <TooltipText title={item.email}>
                                                    <p className='m-0 text-grey200 truncate w-[220px]'>{item.email}</p>
                                                </TooltipText>
                                            </div>
                                            <div>
                                                <button
                                                    className=' text-red font-semibold text-xs rounded px-2.5 p-1'
                                                    onClick={() => {
                                                        let usersList = [...teamAndUsersList];
                                                        usersList.push(item);
                                                        setTeamAndUsersList(usersList);
                                                        let selectedTeamCopy = [...selectedTeam];
                                                        selectedTeamCopy.splice(index, 1);
                                                        setSeletecedTeam(selectedTeamCopy);
                                                    }} >Remove</button>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                        {/* 
                    {!(count > 1) &&
                        <>
                            <label className='mt-3'>Comments (optional)</label>
                            <textarea
                                className="form-control mb-16"
                                name="remarks"
                                value={remarks}
                                placeholder="Enter Comments..."
                                onChange={(e) => setRemarks(e.target.value)}
                            >
                            </textarea>
                        </>
                    } */}
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button
                            className="cancel outline-btn"
                            onClick={() => {
                                setShow(false)
                                // if (selectedTableData.length === 0) {
                                //     resetDataMods();
                                // }
                            }}
                        >
                            Cancel
                        </button>
                        {!loading && <button
                            className="pr-btn"
                            onClick={handleReassignModalSubmit}
                            disabled={loading}
                        >
                            Reassign
                            {selectedTableData.length > 0 && <span className="ml-1 fz14">{`(${selectedTableData.length})`}</span>}
                        </button>}
                        {loading && <button className="pr-btn px-20 flex items-center justify-center" type='button'>
                            <Oval
                                height={24}
                                width={24}
                                color="#ffffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#ffffff"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                            />
                        </button>}

                    </div>
                </div>
                {
                    showMangeLeadModal && <ReassignLeadsManageModal
                        show={showMangeLeadModal}
                        setShow={setShowMangeLeadModal}
                        selectedTableData={selectedTableData}
                        setSelectedTableData={setSelectedTableData}
                        selectedTableValues={selectedTableValues}
                        setSelectedTableValues={setSelectedTableValues}
                    />
                }
            </>}
        />
    )
}