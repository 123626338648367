import React, { useEffect, useState } from 'react'
// import successIcon from '../../assets/Icons/success.gif';
// import ClientLogo from '../../assets/Icons/item.png';
// import sucessCheck from '../../assets/Icons/check.svg';
// import { ReactComponent as ZoomIcon } from '../../assets/Icons/Zoom.svg';
// import { ReactComponent as AddressIcon } from '../../assets/Icons/address.svg';
// import { ReactComponent as ResidanceIcon } from '../../assets/Icons/phome.svg';
// import { ReactComponent as DeveloperIcon } from '../../assets/Icons/developer.svg';
// import { ReactComponent as InfowhiteIcon } from '../../assets/Icons/infowhite.svg'
// import PrimaryButton from '../Button/PrimaryButton';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import makeRequest from 'src/httpHandler/httpHandler';
// import { ReactComponent as FoodIcon } from 'src/assets/Icons/foodw.svg';


const EventPublished = ({ name, propertyType, address, developerName, thumbnail, startTime, endTime, event, date, uuid, banner }) => {

    const bgimage = {
        backgroundImage: `url('${banner}')`,
        backgroundSize: 'content',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: 'contain'
    };


    return (
        <div className='overflow-auto w-full'>
            <div className='w-full overflow-auto md:h-[calc(100vh-127px)] sm:h-[calc(100vh-300px)] h-[calc(100vh-210px)] pt-8 pb-10'>
                <div className='mx-auto text-center mt-2.5'>
                    <h1 className='text-3xl font-semibold black leading-6'>Sent For Review</h1>
                    <p className='text-sm text-black700 font-medium leading-[22px] sm:px-0 !px-5'>Your event is currently being reviewed. Once approved by <span className='sm:block'> Propacity, it will be live on Platform Launchpad shortly.</span></p>
                    {/* <figure className='table mx-auto mt-9 mb-9'><img src={sucessCheck} alt="" className='h-[64px]' /></figure> */}
                </div>
                <div>
                    <div className="publiscard max-w-[1174px] p-8 rounded-xl relative mx-auto overflow-hidden border border-grayLight sm:h-[400px] h-auto" style={bgimage}>
                        <div className='relative z-10 w-[335px] '>
                            <figure className='mb-0 sm:hidden '><img src={thumbnail} alt="" /></figure>
                            <div className='flex items-start justify-start gap-2 date_logo'>
                                <div className='rounded-md text-white text-sm font-semibold bg-[rgba(255,255,255,0.12)] py-2 px-2.5'>
                                    {/* May 7, 6PM - 10PM */}
                                    {`${moment(date).format("MMM DD")}, 
                                        ${moment(new Date(`${date} ${startTime}`).toISOString()).format('h:mmA')} - 
                                        ${moment(new Date(`${date} ${endTime}`).toISOString()).format('h:mmA')}`
                                        // :
                                        // ` ${moment(date).format("MMM DD")},
                                        //     ${moment(startTime).format('hh A')} -
                                        //     ${moment(endTime).format('hh A')}`
                                    }
                                </div>
                                <div className='rounded-md text-white text-sm font-semibold bg-[rgba(255,255,255,0.12)] py-2 px-2.5 capitalize'>
                                    {event} {event === 'online' ? '(Zoom)' : ''}
                                </div>
                            </div>
                            <div className='mobile-bg'>
                                <div className='mt-6'>
                                    <h1 className='text-2xl font-bold leading-8  text-white mb-2'>{name}</h1>
                                    {/* <p className='text-sm text-[rgba(255,255,255,0.56)] '>{description}</p> */}
                                </div>
                                <div className='flex mt-[22px] items-start flex-col gap-2 '>
                                    <div
                                        className='text-sm font-medium leading-4 rounded-md border border-[rgba(255,255,255,0.3)] gap-2.5 text-white inline-flex items-start py-2 px-2.5'>
                                        {/* <div className='w-4 basis-4' ><DeveloperIcon /></div> */}
                                        {developerName}
                                    </div>
                                    {propertyType.length > 0 && <div
                                        className='text-sm font-medium leading-4 rounded-md border border-[rgba(255,255,255,0.3)] gap-2.5 text-white inline-flex items-start py-2 px-2.5'>
                                        {/* <div className='w-4 basis-4' ><ResidanceIcon /></div> */}
                                        {propertyType.map(item => item.label).join(', ')}
                                    </div>}
                                    {/* <div
                                        className='text-sm font-medium leading-4 rounded-md border border-[rgba(255,255,255,0.3)] gap-2.5 text-white inline-flex items-start py-2 px-2.5'>
                                        <div className='w-5 basis-5' ><ZoomIcon /></div>
                                        {event} {event === 'online' ? '(Zoom)' : ''}
                                    </div> */}
                                    {/* {event === 'offline' && <div className='text-sm font-medium leading-4 rounded-md border border-[rgba(255,255,255,0.3)] gap-2.5 text-white inline-flex items-start py-2 px-2.5'>
                                        <div className='w-5 basis-5' ><FoodIcon /></div>
                                        {food || 'Food & Drinks'}
                                    </div>} */}
                                    {address && event === 'offline' && <div
                                        className='text-sm font-medium leading-4 rounded-md border border-[rgba(255,255,255,0.3)] gap-2.5 text-white inline-flex items-start py-2 px-2.5'>
                                        {/* <div className='w-4 basis-4' ><AddressIcon /></div> */}
                                        {address}
                                    </div>}
                                </div>
                                <div className='mt-9 flex items-center gap-4'>
                                    {/* <PrimaryButton className={'w-full py-2 opacity-60 cursor-default'}
                                        text={'Reserve Seat'} /> */}
                                    <button className={'w-full py-2 opacity-60 cursor-default'}>Reserve Seat</button>

                                    {/* <div className='sm:inline hidden'><InfowhiteIcon /></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center items-center pt-5 pb-5 px-5 border-t border-black200' >
                <div className='sm:w-auto'>
                    <Link to='/settings/listing/events'
                        form='event-create'
                        className='py-2 leading-[22px] font-medium px-16 bg-primary text-sm text-white rounded  w-full text-center' type='submit'>
                        Back to dashboard
                    </Link>
                </div>

            </div>
        </div >
    )
}

export default EventPublished;