import React, { useState, useCallback } from 'react'
import { useEffect } from 'react';
import Assigment from '../../../components/Data-import/assigment';
import CSVUpload from '../../../components/Data-import/csv-upload';
import Instruction from '../../../components/Data-import/instructions';
import Mapping from '../../../components/Data-import/mapping';
import CancelCSV from '../../../components/Modals/cancel-csv';
import CenterModal from '../../../components/Modals/center-modal';
import { deleteCSVUpload } from '../../../services/private/csv.service';

const ImportModal = ({ show, closeModal, getAllDataJobs }) => {
    const [steps, setSteps] = useState(1)
    const [uuid, setUuid] = useState();
    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const jobId = localStorage.getItem('jobId')

    const updateCsvUuid = useCallback((uuid) => {
        setUuid(uuid);
    }, []);

    useEffect(() => {
        if (jobId && !showCancelModal && steps !== 2) {
            setSteps(3);
            setUuid(jobId);
        }
    }, [jobId]);

    const updateStep = useCallback((step) => {
        setSteps(step);
    }, []);

    const closeCancelCSVModal = useCallback(() => {
        setShowCancelModal(false)
    }, []);

    const showCancelCSVModal = useCallback(() => {
        setShowCancelModal(true)
    }, []);

    const handleSubmit = useCallback(() => {
        if (uuid) {
            deleteCSVUpload(uuid)
                .then((res) => {
                    // console.log({ res })
                })
                .catch((err) => {
                    console.log({ err })
                })
        }
        closeModal()
    }, [uuid])

    return (
        <>
            <CenterModal show={show} closeModal={showCancelCSVModal}>
                <div className='d-flex align-items-center justify-content-between px-2'>
                    <div className="import-header w-100 pt-20 px-40">
                        <div className="row d-flex align-items-center">
                            <div className="col-6">
                                <h1 className='black fz24 fw-po-semi mb-24'>CSV Import</h1>
                            </div>
                            <div className="col-6 pr-0">
                                <a onClick={showCancelCSVModal} className='cursor-pointer close'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725"></path></svg></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-bradcum w-100">
                    <nav aria-label="breadcrumb " className="w-100">
                        <ol className="breadcrumb justify-content-center">
                            <li className={`breadcrumb-item active`}>
                                <a><span>1</span> Prepare </a>
                            </li>
                            <li className={`breadcrumb-item ${steps !== 1 ? 'active' : ''}`}>
                                <a onClick={() => setSteps(2)}><span>2</span> Upload</a>
                            </li>
                            <li className={`breadcrumb-item ${steps === 3 || steps === 4 ? 'active' : ''}`}>
                                <a><span>3</span> Match</a>
                            </li>
                            <li className={`breadcrumb-item ${steps === 4 ? 'active' : ''}`}>
                                <a><span>4</span> Assign</a>
                            </li>
                        </ol>
                    </nav>
                </div>
                {
                    steps === 1 && <Instruction updateStep={updateStep} />
                }
                {
                    steps === 2 && <CSVUpload updateCsvUuid={updateCsvUuid} updateStep={updateStep} showCancelCSVModal={showCancelCSVModal} />
                }
                {
                    steps === 3 && <Mapping uuid={uuid} updateStep={updateStep} />
                }
                {
                    steps === 4 && <Assigment uuid={uuid} closeModal={closeModal} getAllDataJobs={getAllDataJobs} updateStep={updateStep} />
                }
                <div className="help-box active">
                    {/* <button className='bg-tr border-0' onClick={() => setShowHelpDialog(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="84" height="86" viewBox="0 0 84 86" fill="none">
                            <g filter="url(#filter0_d_2642_50721)">
                                <circle cx="43" cy="39" r="25" fill="white" />
                                <circle cx="43" cy="39" r="24.5" stroke="#DEDEDE" />
                            </g>
                            <path d="M43 49C48.5228 49 53 44.5228 53 39C53 33.4772 48.5228 29 43 29C37.4772 29 33 33.4772 33 39C33 44.5228 37.4772 49 43 49Z" stroke="#171725" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M40.0898 36.0006C40.3249 35.3322 40.789 34.7687 41.3998 34.4097C42.0106 34.0507 42.7287 33.9195 43.427 34.0393C44.1253 34.1591 44.7587 34.5221 45.2149 35.0641C45.6712 35.6061 45.9209 36.2921 45.9198 37.0006C45.9198 39.0006 42.9198 40.0006 42.9198 40.0006" stroke="#171725" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M43 44H43.01" stroke="#171725" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <defs>
                                <filter id="filter0_d_2642_50721" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="9" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2642_50721" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2642_50721" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </button> */}
                    {showHelpDialog && <div className="help-dialog">
                        <div className="help-header d-flex align-items-center justify-content-between">
                            <h2>Help</h2>
                            <button className='border-0 bg-tr' onClick={() => setShowHelpDialog(false)}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></button>
                        </div>
                        <ul>
                            <li>Export your lead data as a CSV file from its current location.(e.g. CRM, spreadsheet)</li>
                            <li>Download our Sample Excel file Format here.</li>
                            <li>Name your columns in the excel file to match the example. </li>
                            <li>Make sure all the mandatory fields have data</li>
                        </ul>
                    </div>}
                </div>
                <CancelCSV
                    show={showCancelModal}
                    closeCancelModal={closeCancelCSVModal}
                    onSubmit={handleSubmit}
                    title={'Cancel CSV Upload?'}
                    bodyText={'On cancellation, your csv uploaded data will be removed'}
                    submitButtonType={'delete'}
                    cancelButtonText='No'
                    submitButtonText='Yes, Cancel'
                />
            </CenterModal>
        </>
    )
}

export default ImportModal;