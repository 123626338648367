import React from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { ReactComponent as FilterDownIcon } from '../../assets/filterDown.svg';
import { ReactComponent as FilterSearchIcon } from '../../assets/filterSearch.svg';
import { ReactComponent as SearchCloseIcon } from '../../assets/searchClose.svg';

const CustomDropdown = React.forwardRef(({
    show,
    toggleDropdown,
    label,
    dropdownItems,
    selectedItems,
    handleSearchOnChange,
    searchText,
    handleChange,
    handleClearAll,
    handleSearchClear,
    searchEnable,

}, ref) => {

    return (
        <>
            <div className='assigned-drop'>
                <Dropdown align="start" show={show} ref={ref}>
                    {/* Add active class when filtter is applyed */}
                    <Dropdown.Toggle variant="short" className={`${selectedItems.length > 0 ? 'active' : ''} outline `} id="dropdown-autoclose-true" onClick={toggleDropdown} >
                        <div className='flex items-center'>

                            {selectedItems.length === 0 && label}
                            {
                                selectedItems.length === 1 && <span>{selectedItems[0]?.label || ''}</span>
                            }
                            {selectedItems.length > 1 &&
                                <>
                                    <span>{selectedItems[0]?.label}</span> +
                                    <span>{selectedItems.length - 1 || ''} </span>
                                </>
                            }
                            <FilterDownIcon className='m-0' />
                        </div>
                    </Dropdown.Toggle>
                    {/* add class for d-none for mobile */}
                    <Dropdown.Menu className=''>
                        {/* <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear">
                        <a className='fz12 black-dark-700 fw-po-medium pr-hover' >Select all</a>
                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' >Clear all</a>
                    </div> */}
                        {searchEnable && <div className='drop-search'>
                            <div className={`${searchText ? 'input-active' : ''} form-group position-relative search-input`}>
                                <div className="serch-icon">
                                    <FilterSearchIcon />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className={`searchinput`}
                                    value={searchText}
                                    onChange={handleSearchOnChange}
                                />

                                {searchText && <div className="close-icon" onClick={handleSearchClear}>
                                    <SearchCloseIcon />
                                </div>}
                            </div>
                        </div>}
                        <div className='input-list'>
                            {
                                selectedItems.map((item, index) => (
                                    <div className='dropdown-item' key={`${item.label}_${item.value}_${index}`}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                id={`${item.label}_${item.value}_${index}`}
                                                type="checkbox"
                                                name={item.label}
                                                onChange={() => handleChange(item)}
                                                checked={true}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={`${item.label}_${item.value}_${index}`}>
                                                {item.label}
                                            </label>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                dropdownItems.map((item, index) => (
                                    <div className='dropdown-item' key={`${item.label}_${item.value}_${index}`}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                id={`${item.label}_${item.value}_${index}`}
                                                type="checkbox"
                                                name={item.label}
                                                onChange={() => handleChange(item)}
                                                checked={false}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={`${item.label}_${item.value}_${index}`}>
                                                {item.label}
                                            </label>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="aply-btn mt-2">
                            <button className='outline-btn d-block w-100 fz12' onClick={handleClearAll}>Clear All</button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

            </div>


            {show &&
                <Modal show={show} backdropClassName='filtermodalback' className='FilterModal'>
                    <div className='d-flex justify-content-between align-items-center flex-row FilterModalHeader'>
                        <h4>
                            {label}
                        </h4>
                        <a onClick={toggleDropdown} className='cursor-pointer bg-tr border-0'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#44444f"></path></svg></a>
                    </div>
                    <div>
                        {/* <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear">
                        <a className='fz12 black-dark-700 fw-po-medium pr-hover' >Select all</a>
                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' >Clear all</a>
                    </div> */}
                        {searchEnable && <div className='drop-search'>
                            <div className={`form-group position-relative search-input  input-active  `}>
                                <div className="serch-icon">
                                    <FilterSearchIcon />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className={`searchinput`}
                                    value={searchText}
                                    onChange={handleSearchOnChange}
                                />

                                {searchText && <div className="close-icon" onClick={handleSearchClear}>
                                    <SearchCloseIcon />
                                </div>}
                            </div>
                        </div>}
                        <div className='input-list'>
                            {
                                selectedItems.map((item, index) => (
                                    <div className='dropdown-item' key={`${item.label}_${item.value}_${index}`}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                id={`${item.label}_${item.value}_${index}`}
                                                type="checkbox"
                                                name={item.label}
                                                onChange={() => handleChange(item)}
                                                checked={true}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={`${item.label}_${item.value}_${index}`}>
                                                {item.label}
                                            </label>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                dropdownItems.map((item, index) => (
                                    <div className='dropdown-item' key={`${item.label}_${item.value}_${index}`}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                id={`${item.label}_${item.value}_${index}`}
                                                type="checkbox"
                                                name={item.label}
                                                onChange={() => handleChange(item)}
                                                checked={false}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={`${item.label}_${item.value}_${index}`}>
                                                {item.label}
                                            </label>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="aply-btn">
                            <button className='outline-btn d-block' onClick={handleClearAll}>Clear</button>
                            <button className='pr-btn d-block' onClick={toggleDropdown}>Apply Now</button>
                        </div>
                    </div>
                </Modal>
            }

        </>
    )
})

export default CustomDropdown