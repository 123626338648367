import {

    SIGNUP_ERROR,
    SIGNUP_REQUESTING,
    SIGNUP_SUCCESS,
    SIGNUP_VERFICATIONL_LINK_SENT,
    SHOW_HIDE_PASSWORD,
    SIGNUP_RESEND_VERIFICATION_LINK,
    SIGNUP_RESEND_VERIFICATION_LINK_SENT,
    SINGUP_ACTIONS
} from './actions';

// The initial state of the SINUP Reducer
export const initialState = {
    isValid: false,
    isCompleted: false
};

export default function signupFormReducer(state = initialState, actions) {
    switch (actions.type) {
        case SINGUP_ACTIONS.SET_VALID_STATE:
            return { ...state, isValid: actions.payload };
        case SINGUP_ACTIONS.SET_URL_COMPLETED:
            return { ...state, isCompleted: actions.payload };
        default:
            return { ...state };
    }
}