import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import SearchInput from '../../InputGroup/SearchInput';
import { useForm } from 'react-hook-form';
import { insertProjectAdditionalCost, updateProjectAdditionalCostByUUID, updateProjectAdditionalDetailsByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { ReactComponent as RupayIcon } from '../../../assets/icons/rupay.svg';
import { useEffect } from 'react';

const textareastyle = {
    minHeight: 'unset!important',
    important: true

};

const AdditionalCost = ({ show, closeModal, style, getAdditionCost, uuid, selectedAdditionalDetail }) => {
    let error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            name: '',
            amount: '',
            comment: '',
        },
        // resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (Object.keys(selectedAdditionalDetail).length > 0) {
            reset({
                name: selectedAdditionalDetail.name,
                amount: selectedAdditionalDetail.amount,
                comment: selectedAdditionalDetail.comment
            },
                { keepDefaultValues: true }
            )
        }
    }, [selectedAdditionalDetail])

    const onFormSubmit = async (values) => {
        const payload = {
            name: values.name || null,
            comment: values.comment || null,
            amount: values.amount || null,
        }

        let res = {}
        if (selectedAdditionalDetail?.uuid) {
            res = await updateProjectAdditionalCostByUUID(selectedAdditionalDetail?.uuid, payload);
        } else {
            res = await insertProjectAdditionalCost(uuid, payload);
        }

        if (res.status === 200) {
            toast.success(res.message);
            getAdditionCost();
            reset();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`${style.configurationModal} ${style.addbanckAccountModal}  configurationModal`}>

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Additional Costs</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form id='cost-form' className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>
                <div className='form-group position-relative'>
                    <label >Cost Name</label>
                    <input
                        type='text'
                        className={error ? 'form-control text-input error ' : 'form-control text-input '}
                        placeholder='e.g. Club Membership'
                        {...register('name')}
                    />

                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                </div>

                <div className='form-group  position-relative'>
                    <label >Amount</label>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error  pad_in_input' : 'form-control text-input  pad_in_input'}
                        placeholder='e.g. 100000'
                        {...register('amount')}
                    />
                    <RupayIcon className={`${style.absrupay}`} />
                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                </div>
                <div className='form-group'>
                    <label>Comments</label>
                    <textarea label='Project Description'
                        placeholder='Enter your comments...'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        rows="4"
                        style={textareastyle}
                        {...register('comment')}
                    ></textarea>
                </div>

            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={() => {

                    closeModal();
                }}>Cancel</button>
                <button type='button' disabled={!isDirty || isSubmitting} onClick={handleSubmit(onFormSubmit)} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal >
    )
}

export default AdditionalCost
