import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';
import { getDeadReasons } from '../../services/public/public.service';

const DeadLeadCard = ({ deadLead, companyConfig, fromDate, toDate, selectedDate }) => {
    const [tempData, setTempData] = useState([]);
    const [deadLeadReasons, setDeadLeadReasons] = useState([])

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setDeadLeadReasons(reasonsData);
                // setConfig({ ...config, Dead: reasonsData });
            }
        })
    }

    useEffect(() => {
        getDeadReasonsStatus();
    }, []);

    const exportData = () => {
        let temp = deadLead?.reasonWise.map((lead, index) => {
            return {
                "Reasons": lead.reason || '',
                "TOTAL LEADS": lead.total || '',
            }
        })
        setTempData(temp);

    };

    const handleAddFilter = (reason) => {
        let proj = {}

        const deadReason = deadLeadReasons.filter(s => s.label === reason);

        const payload = [];
        const filterPayload = [];

        if (selectedDate !== 'allTime') {
            filterPayload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }
        if (deadReason.length > 0) {
            payload.push({
                "Dead Reasons": deadReason.map(s => s.value)
            })
        }

        if (filterPayload.length > 0) {
            localStorage.setItem('new_filters', JSON.stringify(filterPayload));
        }

        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }
    return (
        <div className="table-card dead-lead-table d-flex flex-fill flex-column">
            <div className="table-header d-flex align-items-center justify-content-between position-relative">
                <div className='d-flex'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Dead Lead Analysis</h2>
                    <div className="info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>Reason wise breakup of leads marked as dead status for selected date range.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Button className='bg-tr border-0 px-0 !ml-px' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                            </svg>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                {/* <button className='flex items-center border rounded-lg p-1'>
                    <DownloadIcon className='' />
                </button> */}
                <CSVLink
                    className={`flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded !px-2 py-1.5 text-sm gap-x-1`}
                    data={tempData}
                    onClick={exportData}
                    filename={'dump_lead_analysis.csv'}>
                    <DownloadIcon />
                </CSVLink>
            </div>
            {deadLead.total > 0 &&
                <>
                    <div className="dead-count">
                        <h5>{deadLead.total}</h5>
                    </div>
                    <div className="ded-scroll-table">
                        <table className="table table-striped table-bg">
                            <thead>
                                <tr>
                                    <th>Reasons</th>
                                    <th>TOTAL LEADS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deadLead?.reasonWise?.map((lead, index) => {
                                        // if (index >= 5) return null;
                                        return (
                                            <tr key={`${index}_${lead.total}`}>
                                                <td className='cursor-pointer' onClick={() => handleAddFilter(lead.reason)}>{lead.reason}</td>
                                                <td>{lead.total}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </>}
            {(!deadLead || deadLead?.totalLeads === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
        </div>
    )
}

export default DeadLeadCard