import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { WorkshopService } from '../../services/workshop'

import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';

const EnrolledUsers = () => {
    let params = useParams();

    let usersPerPage = 5;
    let [startIdx, setStartIdx] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    let [dispUsers, setDispUsers] = useState([]);


    let [users, setUsers] = useState([]);

    let [csvData, setCsvData] = useState([]);
    let [workshop, setWorkshop] = useState({});
    let [search, setSearch] = useState("")

    const handleSearch = (e) => {
        let searchQuery = e.target.value;
        setSearch(searchQuery);
        console.log(searchQuery)
        if (searchQuery == "") {
            setDispUsers(users);
        }
        else {
            let obj = [];
            obj = users.filter(user => user.company_name.includes(searchQuery) || user.name.includes(searchQuery) || user.mobile.includes(searchQuery) || JSON.stringify(user.created_on).includes(searchQuery));
            console.log(obj)
            setDispUsers(obj);
        }
    }
    let headers = [
        { label: "Company Name", key: "company_name" },
        { label: "User Name", key: "user_name" },
        { label: "Mobile Number", key: "mobile" },
        { label: "Enrolled Date", key: "created_on" },
        { label: "Email Id", key: "email" },
    ];

    const formatDate = (date) => {
        return ((moment(date)).format('DD-MMM-YYYY hh:mm a'))
    }

    const getWorkshopBy_uuid = async (uuid) => {
        await WorkshopService.getWorkshopBy_uuid(uuid).then(res => {
            setWorkshop(res);
        }).catch(e => {
            console.log(e);
            // toast.error(e.response.data)
        })
    }

    useEffect(() => {
        getWorkshopBy_uuid(params.workshop_uuid);
        WorkshopService.getWorkshopUsersbyUuid(params.workshop_uuid).then(data => {
            setUsers(data.data);
            setDispUsers(data.data.slice(pageNumber * usersPerPage, ((pageNumber + 1) * usersPerPage)));
            let totalUsers = data.data.length;
            if (totalUsers % usersPerPage === 0) {
                setTotalPages(parseInt(totalUsers / usersPerPage));
            }
            else {
                setTotalPages(parseInt(totalUsers / usersPerPage) + 1);
            }

            let arr = [];
            if (data.data.length)
                for (let i of data.data) {
                    let date = formatDate(i.created_on);
                    let obj = { user_name: i.name, company_name: i.company_name, mobile: String(i.mobile), created_on: date, email: i.email };
                    arr.push(obj);
                }
            setCsvData(arr);

        });
    }, [])

    useEffect(() => {
        setDispUsers(users.slice((pageNumber - 1) * usersPerPage, ((pageNumber) * usersPerPage)));
        setStartIdx((pageNumber - 1) * (usersPerPage));
    }, [pageNumber, users])


    return (
        <div className="w-full basis-full">
            <div className="title-wraper !p-6 border-b w-full">
                <div className="flex w-full basis-full items-center">
                    <div className='w-50 basis-6/12	'>
                        <h5 className='text-xs text-[#92929D] font-medium'>{workshop.name}</h5>
                        <div className="flex items-center">
                            <span
                                className="mr-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.location.href = "/settings/learning-centre/all-workshops"; }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                </svg>
                            </span>
                            <h1 className="fz24 black fw-po-medium">
                                Enrolled Users ({dispUsers.length})
                            </h1>
                        </div>
                    </div>
                    <div className='w-50 basis-6/12	 items-end flex justify-end'>
                        <button className='mx-3 bg-black200 text-14 font-medium text-black800 px-4 py-1 rounded border border-solid	border-black200 ml-2 hover:bg-primary hover:text-white'>
                            <CSVLink
                                filename={"enrolled-users-" + params.workshop_uuid}
                                data={csvData}
                                headers={headers}
                                onClick={() => {
                                    const toastId = toast.loading('Downloading..')
                                    setTimeout(
                                        toast.success("Downloaded", {
                                            id: toastId
                                        })
                                        , 1000);
                                }}
                            >
                                Download CSV
                            </CSVLink>
                        </button>
                        <label className="relative flex items-center m-0">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                {
                                    search
                                        ?
                                        <span onClick={() => setSearch("")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m-15 0l15 15" />
                                            </svg>
                                        </span>
                                        :

                                        <svg className="h-3 w-3 fill-slate-200" viewBox="4 2 20 20">  <path fillRule="evenodd" clipRule="evenodd" d="M6.10369 6.1455C3.29877 9.00618 3.29877 13.6442 6.10369 16.5049C8.90862 19.3656 13.4563 19.3656 16.2612 16.5049C19.0662 13.6442 19.0662 9.00618 16.2612 6.1455C13.4563 3.28483 8.90862 3.28483 6.10369 6.1455ZM7.2126 7.27645C9.40509 5.04037 12.9598 5.04037 15.1523 7.27645C17.3448 9.51252 17.3448 13.1379 15.1523 15.374C12.9598 17.6101 9.40509 17.6101 7.2126 15.374C5.0201 13.1379 5.0201 9.51252 7.2126 7.27645Z" fill="#696974" />
                                            <path d="M19.7562 18.6204L16.5182 15.3181C15.779 14.5641 14.6701 15.6951 15.4093 16.449L18.6473 19.7514C19.3866 20.5053 20.4955 19.3744 19.7562 18.6204Z" fill="#696974" /></svg>

                                }
                            </span>
                            <input className="placeholder:text-slate-400 block bg-white w-full border border-solid border-black400 rounded py-1 pl-8 pr-3 focus:outline-none focus:border-black400 focus:ring-black400 "
                                value={search}
                                onChange={(e) => {
                                    handleSearch(e);
                                }}
                                placeholder="Search" type="text" name="search" />


                            {/* <button
                                onClick={handleSearch}
                                className='bg-black200 text-14 font-medium text-black800 px-4 py-1 rounded border border-solid	border-black200 ml-2 hover:bg-primary hover:text-white'>
                                Search
                            </button> */}
                        </label>
                        {/* <button className='bg-primary text-14 font-medium text-white px-4 py-1 rounded border border-solid	border-black200 ml-2 hover:bg-primary hover:text-white'>

                            <CSVLink
                                filename={"enrolled-users-" + params.workshop_uuid}
                                data={csvData}
                                headers={headers}>
                                Download CSV
                            </CSVLink>
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="p-6">
                <div className="company-table w-full">
                    {dispUsers.length !== 0 ?
                        <table className="table w-full font-worksans">
                            <thead className='text-left bg-black100 rounded-lg '>
                                <tr className='px-4 py-2'>
                                    <th className='w-10'>S.No</th>
                                    <th className='w-44'>Company Name</th>
                                    <th>User Name</th>
                                    <th>Mobile Number</th>
                                    <th>Enrolled Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dispUsers && dispUsers.map((user, index) =>
                                    <tr>
                                        <td className='pl-6'>{startIdx + index + 1}</td>
                                        <td className='compnay-name'><span className='' title={(user.company_name)}>{user.company_name}</span></td>
                                        <td>{user.name}</td>
                                        <td>{user.mobile}</td>
                                        <td>{formatDate(user.created_on)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : <>No users found !</>}

                </div>
            </div>
            <div className="flex items-center justify-center border-lg flex-row p-3">
                <div className="mx-3">
                    <button
                        className="btn border-lg"
                        disabled={pageNumber > 1 ? false : true}
                        onClick={() =>
                            setPageNumber(pageNumber - 1)
                        }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>

                    </button>
                </div>
                <div>
                    <input
                        className="form-control border-solid"
                        value={pageNumber}
                        onChange={(e) => setPageNumber(parseInt(e.target.value))}
                        type="number"
                    />
                </div>
                <div>
                    <button
                        disabled={pageNumber === totalPages}
                        onClick={() =>
                            setPageNumber(pageNumber + 1)
                        }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                    </button>
                </div>
            </div>
        </div>
    )
}

export default EnrolledUsers;

// [
//     {
//         id: "1",
//         company_name: "Reality Nexus",
//         name: "Aditya Pratap Singh",
//         mobile_number: "+91-8800304300",
//         enrolled_date_time: "12:30pm, 21 Sept'22"
//     },
//     {
//         id: "2",
//         company_name: "Reality Nexus",
//         name: "Aditya Pratap Singh",
//         mobile_number: "+91-8800304300",
//         enrolled_date_time: "12:30pm, 21 Sept'22"
//     },
//     {
//         id: "3",
//         company_name: "Reality Nexus",
//         name: "Aditya Pratap Singh",
//         mobile_number: "+91-8800304300",
//         enrolled_date_time: "12:30pm, 21 Sept'22"
//     },

// ]





// data = [
//   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
//   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
//   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
// ];

