import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkAuthorization } from '../helpers/helpers';
import NavAndSideBar from '../components/NavBar/NavBar';

const PrivateRoute = ({
  component: Component,
  redirect: pathname,
  ...rest
}) => {
  const Routes = (props) => {
    if (rest.authorized === true) {
      return (
        <Route
          {...rest}
          render={props => <Component {...rest} {...props} />
          }
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }
  }
  return (
    <Routes />
  );
};

PrivateRoute.defaultProps = { redirect: '/login' };

// PrivateRoute.propTypes = {
//   component: PropTypes.object.isRequired,
//   redirect: PropTypes.string,
// };

export default PrivateRoute;


