export function convertToFormData(formData_json) {
    let form_data = new FormData();

    for (let key in formData_json) {
        form_data.append(key, formData_json[key]);
    }
    return form_data;
}

export function checkFileSize(fileSize) {
    if (fileSize < 1000000) {
        return false
    }
    return true
}

export function cleanObj(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj
  }