import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { getAllJobs } from '../../../services/private/csv.service';
import { toast } from 'react-toastify';
import ImportModal from './import-modal';
import './import.css';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DataImport = () => {
    const [show, setShow] = useState(false);
    const [mappedLeads, setMappedLeads] = useState();

    const getAllDataJobs = () => {
        getAllJobs()
            .then((res) => {
                if (res.data.status === 200) {
                    setMappedLeads(res.data.data)
                }
            })
            .catch((err) => {
                console.log({ err })
            })
    }
    useEffect(() => {
        getAllDataJobs();
    }, []);

    const closeModal = useCallback(() => {
        localStorage.removeItem('jobId');
        setShow(false);
    }, []);

    return (

        <>
            <div className='d-lg-none import-for-mobile'>
                <h1 className='fz28 mb-16 black fw-po-medium mt-24 pl-2'>CSV Import Leads</h1>
                <div className="card">
                    <figure className='d-table mx-auto mb-16'><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <path d="M31.9997 58.6673C39.0721 58.6673 45.8549 55.8578 50.8559 50.8568C55.8568 45.8559 58.6663 39.0731 58.6663 32.0006C58.6663 24.9282 55.8568 18.1454 50.8559 13.1445C45.8549 8.1435 39.0721 5.33398 31.9997 5.33398C24.9272 5.33398 18.1445 8.1435 13.1435 13.1445C8.14252 18.1454 5.33301 24.9282 5.33301 32.0006C5.33301 39.0731 8.14252 45.8559 13.1435 50.8568C18.1445 55.8578 24.9272 58.6673 31.9997 58.6673ZM27.9997 44.0007C27.9997 42.9398 28.4211 41.9224 29.1712 41.1722C29.9214 40.4221 30.9388 40.0006 31.9997 40.0006C33.0605 40.0006 34.078 40.4221 34.8281 41.1722C35.5782 41.9224 35.9997 42.9398 35.9997 44.0007C35.9997 45.0615 35.5782 46.0789 34.8281 46.8291C34.078 47.5792 33.0605 48.0006 31.9997 48.0006C30.9388 48.0006 29.9214 47.5792 29.1712 46.8291C28.4211 46.0789 27.9997 45.0615 27.9997 44.0007ZM29.3757 18.1873C29.487 17.5725 29.8107 17.0163 30.2903 16.6157C30.7698 16.2152 31.3748 15.9958 31.9997 15.9958C32.6245 15.9958 33.2295 16.2152 33.7091 16.6157C34.1887 17.0163 34.5123 17.5725 34.6237 18.1873L34.6663 18.6673V32.0006L34.6237 32.4806C34.5123 33.0955 34.1887 33.6517 33.7091 34.0522C33.2295 34.4528 32.6245 34.6722 31.9997 34.6722C31.3748 34.6722 30.7698 34.4528 30.2903 34.0522C29.8107 33.6517 29.487 33.0955 29.3757 32.4806L29.333 32.0006V18.6673L29.3757 18.1873Z" fill="#FD9A39" />
                    </svg></figure>
                    <h2 className='fz18 black fw-po-medium text-center mb-16'>Not available on Mobile</h2>
                    <p className='ro fz14 mb-0 text-center'>CSV Import is not available on mobile, please login with your account on desktop or laptop to import your leads.</p>
                </div>
                <Link to='/' className='pr-btn'>Back to leads manager</Link>
            </div>
            <div className="w-100 d-lg-flex d-none  flex-col h-full">
                <div className="settings-title">
                    <div className="row d-flex flex-fill">
                        <div className="col-md-6 d-flex flex-fill flex-column">
                            <h1 className="fz24 mb-16 black fw-po-medium">Import Leads</h1>
                        </div>
                        <div className="col-md-6 justify-content-end d-flex align-items-center">

                            <button onClick={() => { setShow(true) }} className="pr-btn flex">
                                <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="path-1-inside-1_1117_451" fill="white">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                    </mask>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                    <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                </svg>
                                New Import
                            </button>
                        </div>
                    </div>
                </div>

                <div className="ml-24 mt-24 import-table-main h-full overflow-auto">
                    <div className="def-table pr-24 import-leads-table !h-full">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>File Name</th>
                                    <th>Upload Date</th>
                                    <th>Imported by</th>
                                    <th>Status</th>
                                    <th>Uploaded</th>
                                    <th>Not uploaded</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {mappedLeads?.length > 0 ? mappedLeads.map((leads, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            {/* file-name */}
                                            <td className=''>
                                                {leads.filename.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{leads.filename}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{leads.filename}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize three-dots'> {leads.filename}</span>
                                                }
                                            </td>
                                            <td className='' >{moment(leads.created_on).format('h:mm a, Do MMM YYYY')}</td>
                                            <td className='name'>
                                                {leads.imported_by.length > 20 ?

                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{leads.imported_by}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{leads.imported_by}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize three-dots'>{leads.imported_by}</span>
                                                }


                                            </td>
                                            <td className='import-status'><span className={` text-capitalize ${leads.status === 'imported' ? 'green-color-btn' : 'orange-color-btn'} ${leads.inserted === 0 ? 'red-color-btn' : ''}`}>{leads.status}</span></td>
                                            {/* change the class accroding the color clasname is: black, orange-color, green-color, red-color */}
                                            <td className='text-center'>
                                                {leads.inserted}/{leads.total_leads}
                                            </td>
                                            <td className='text-center'>{leads.failed}/{leads.total_leads}</td>
                                            <td>
                                                <div className="dropdown view-more ">
                                                    <button className="bg-tr border-0 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu w-auto dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        {leads.report && <a className='dropdown-item' href={leads.report} download>
                                                            Download Failed Leads
                                                        </a>}
                                                        {!leads.report && <a className='dropdown-item' onClick={() => {
                                                            toast.warning('No Failed Leads Found');
                                                        }}>
                                                            Download Failed Leads
                                                        </a>}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                    <><tr className='nohover'>
                                        <td colSpan={9}>
                                            <div className='d-flex justify-content-center flex-column text-center lead-pagination'>
                                                <figure className='self-center'><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize"> No CSV Found</h2>
                                                <p className='text-center black-dark-600 fz14 mb-0 text-capitalize'>Please add new CSV.</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </>
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
                {
                    show && <div className='center-modal'>
                        <ImportModal show={show} closeModal={closeModal} getAllDataJobs={getAllDataJobs} />
                    </div>
                }
            </div >
        </>
    )
}

export default DataImport
