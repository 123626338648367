import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap';
import fbicon from '../../../../../assets/fb-in.png'
import Final from '../../../../../components/FacebookIntegration/final';
import { ReactComponent as BackCircle } from '../../../../../assets/icons/backCircle.svg';
import { ReactComponent as Pageback } from '../../../../../assets/icons/Pageback.svg';

import {
    createFbIntegration,
    facebookLinkedAccounts,
    generateFacebookToken,
    getAllProjects,
    getCompanyAgency,
    getCRMValues,
    getFacebookLoginUrl,
    getFacebookPageForms,
    getFacebookPages,
    getFbIntegrations,
    getInetgrationsPageForms,
    getIntegratedPageToEdit,
    getMappingByFormId,
    getPageFormByIntegrationId,
    getTestInetgrationsPageForms,
    sendTestLead,
    sendtestLead,
    updateIntegration
} from '../../../../../services/private/company.service'
import { getUnion } from '../../../../../helpers/helpers';
import ConnectForm from '../../../../../components/FacebookIntegration/connectForm';
import FormMapping from '../../../../../components/FacebookIntegration/formMapping';
import LeadMapping from '../../../../../components/FacebookIntegration/leadMapping';
import Success from '../../../../../components/FacebookIntegration/success';
import TestLeadResponse from '../../../../../components/FacebookIntegration/testLeadResponse';
import TestleadProcess from '../../../../../components/Modals/TestleadProcess';
import IntegrationCreatedModal from '../../../../../components/Modals/IntegrationCreatedModal';
import AreYouSureModal from '../../../../../components/Modals/AreYouSureModal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import NoFormFound from '../../../../../components/Modals/NoFormFound';
import { getAllTeams } from '../../../../../services/public/public.service';


const Integration = ({ }) => {
    const [steps, setSteps] = useState(1);
    const [facebookAccountList, setFacebookAccountList] = useState([]);
    const [selectedFbAccount, setSelectedFbAccount] = useState();
    const [pageList, setPageList] = useState([]);
    const [selectedPage, setSelectedPage] = useState();
    const [formList, setFormList] = useState([]);
    const [selectedForm, setSelectedForm] = useState();
    const [platformField, setPlatformField] = useState([]);
    const [projects, setProjects] = useState([]);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [leadMapping, setLeadMapping] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [selectedAgency, setSelectedAgency] = useState();
    const [facebookQuestions, setFacebookQuestions] = useState([]);
    const [formMapping, setFormMapping] = useState([]);
    const [unmappedForms, setUnmappedForm] = useState(true);
    const [formIdList, setFormIdList] = useState([]);
    const [pageUuid, setPageUuid] = useState();
    const [count, setCount] = useState(0);
    const [selectedMappedFormList, setSelectedMappedFormList] = useState([]);
    const [testLeadProcessModal, setTestLeadProcessModal] = useState(false);
    const [pageMappingValidation, setPageMappingValidation] = useState(false);
    const [integratedPage, setIntegratedPage] = useState();
    const [submitingIntegration, setSubmitingIntegration] = useState(false);
    const [isSendingLead, setIsSendingLead] = useState(false);
    const [sendTestLeadIndex, setSendTestLeadIndex] = useState();
    const [testLeadFailed, setTestLeadFailed] = useState('-');
    const [integrationCreated, setIntegrationCreated] = useState(false)
    const [showCloseConfirmationModal, setShowCloseConfirmationModa] = useState(false);
    const [connectPageError, setConnectPageError] = useState(false);
    const [editFormMapping, setEditFormapping] = useState();
    const [editLeadMapping, setEditLeadMapping] = useState();
    const [editMapping, setEditMapping] = useState(false);
    const [isLoading, setLoading] = useState(false)
    const [integrationState, setIntegrationState] = useState('new');
    const [integrations, setIntegrations] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [noPageFormFound, setNoPageFormFound] = useState(false);
    const [formMappingCount, setFormMappingCount] = useState(0);
    const [pageFetching, setPageFetching] = useState(false);
    const [allTeams, setAllTeams] = useState([]);

    const { search, pathname } = useLocation();
    const query = new URLSearchParams(search);

    const history = useHistory();

    const updateStep = useCallback((step) => {
        if (step === 1) {
            setEditMapping(false);
        }
        localStorage.setItem('fb_steps', step);
        if (integrationCreated) setIntegrationCreated(false);
        setSteps(step);
    }, []);

    const getFacebookIntegrations = () => {
        getFbIntegrations().then(res => {
            setIntegrations(res.data.data);
        }).catch(err => {
            console.log({ err })
        })
    };

    const getFacebookLinkedPages = useCallback(() => {
        facebookLinkedAccounts().then(res => {
            const leadList = res.data.data.map(item => { return { label: item.name, value: item.uuid, ...item } })
            setFacebookAccountList(leadList)
            if (localStorage.getItem('fb_modal')) {
                setSelectedFbAccount(leadList[0]);
                localStorage.removeItem('fb_code')
                localStorage.removeItem('fb_modal')
            }
        }).catch(err => {
            console.log({ err })
        })
    }, []);


    // console.log({ selectedFbAccount })
    const generateFbAccessToken = useCallback((code) => {
        generateFacebookToken({ code }).then(res => {
            getFacebookLinkedPages();
        }).catch(err => {
            console.log({ err })
        })
    }, [])

    const getFbLoginUrl = useCallback(() => {
        getFacebookLoginUrl().then(res => {
            window.open(res.data.data, "_self")
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const getFbAccountPages = useCallback(() => {
        setPageFetching(true);
        getFacebookPages(selectedFbAccount.value).then(res => {
            if (res.data.status === 200) {
                // if (res.data.data.data.length === 0) {
                //     setNoPageFormFound(true);
                // }
                setPageFetching(false);
                const pages = res.data.data.data.map(item => {
                    return { label: item.name, value: item.id, ...item }
                })
                setPageList(pages)
            }
        }).catch(err => {
            setPageFetching(false);
            console.log({ err })
        })
    }, [selectedFbAccount]);

    const getFbPageForms = useCallback(() => {
        const integratedPage = integrations?.find(integration => integration.page_id === selectedPage.id);
        setIntegratedPage(integratedPage)
        if (!integratedPage) {
            getFacebookPageForms({ id: selectedPage.value, token: selectedPage.access_token }).then(res => {
                if (res.data.data.data.length === 0) {
                    setNoPageFormFound(true);
                }
                const forms = res.data.data.data.map(item => {
                    return { label: item.name, value: item.id, ...item }
                })
                setFormList(forms);
            }).catch(err => {
                console.log({ err })
            })
        }
    }, [selectedPage])

    const getCRMValuesForMapping = useCallback(() => {
        getCRMValues().then(res => {
            setPlatformField(res.data.data)
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const getAllProjectsByCompanyId = useCallback(async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjects(projects);
            }
        })
    })

    const getAgencyData = useCallback(async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }, [])

    const getEditFbPageForms = useCallback((id, token) => {
        getFacebookPageForms({ id, token }).then(res => {
            if (res.data.data.data.length === 0) {
                setNoPageFormFound(true);
                return;
            }
            const forms = res.data.data.data.map(item => {
                return { label: item.name, value: item.id, ...item }
            })
            setFormList([...forms]);
            setEditMapping(true);
        }).catch(err => {
            console.log({ err })
        })
    }, [])

    const handleEditMapping = useCallback((uuid, integrationState) => {
        setLoading(true);
        setIntegrationState(integrationState);
        getIntegratedPageToEdit(uuid).then(res => {
            if (res.data.status === 200) {
                setUnmappedForm(res.data.data.unmapped_forms)
                setEditFormapping(res.data.data.formMapping);
                setEditLeadMapping(res.data.data.mappings);
                getEditFbPageForms(res.data.data.page_id, res.data.data.page_access_token);
            }
            setLoading(false);
        }).catch(err => {
            console.log({ err });
            setLoading(false);
        })
    }, []);

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data.map(data => ({ ...data, label: data.name, value: data.id }))
            setAllTeams(mapped)
        }
    }

    useEffect(() => {
        if (query.get('uuid')) {
            handleEditMapping(query.get('uuid'), 'edit')
            updateStep(2);
        }
        localStorage.setItem('fb_steps', 1)
        getCRMValuesForMapping();
        getAgencyData();
        getFacebookIntegrations();
        getAllProjectsByCompanyId();
        getAll();
        if (localStorage.getItem('fb_code')) {
            generateFbAccessToken(localStorage.getItem('fb_code'))
        } else {
            getFacebookLinkedPages();
        }

        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        const handleBackButton = () => {
            console.log('browser baack')
            const splitedPath = pathname.split('/');
            if ((splitedPath.includes('new-integration') ||
                splitedPath[splitedPath.length - 1].split('?').includes('new-integration')) && steps < 4) {
                setShowCloseConfirmationModa(true);
            }
        }

        window.addEventListener("popstate", handleBackButton);
        window.addEventListener("beforeunload", unloadCallback);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
            window.removeEventListener("beforeunload", unloadCallback);
        };

    }, []);

    const selectedFormList = useMemo(() => {
        if (editFormMapping && formList) {
            let newForm = formList.map(item => {
                const result = editFormMapping.find(form => form.formId === item.id);

                if (result && Object.keys(result).length > 0) {
                    return {
                        label: item.name,
                        value: item.id,
                        projectId: result.projectId,
                        marketingAgency: result.marketingAgency,
                        formMappingUUID: result.formMappingUUID,
                        team_names: result.team_names,
                        routing_id: result.routing_id,
                        ...item
                    }
                } else {
                    return { label: item.name, value: item.id, ...item }
                }
            })

            return newForm;
        }
    }, [editFormMapping, formList]);

    useEffect(() => {
        if (selectedFormList) {
            let queUnion = []
            // Getting union of facebook questions which is already selected
            selectedFormList.forEach((item) => {
                if (item.projectId) {
                    queUnion = getUnion(queUnion, item.questions)
                }
            })

            // mapping question with crm values
            let newList = queUnion.map(item => {
                const result = editLeadMapping.find(lead => lead.facebook_value === item.key)
                if (result && Object.keys(result).length > 0) {
                    return { ...item, crmValue: result.crm_value }
                } else {
                    return { ...item }
                }
            })
            // setQuestionList(newList)
            setFacebookQuestions(newList);
            let queMapping = [];
            queMapping = newList.map(que => {
                return {
                    crmValue: que.crmValue,
                    facebookValue: que.key,
                    facebookLabel: que.label
                }
            })
            setLeadMapping([...queMapping])
        }
    }, [selectedFormList, editLeadMapping])

    const handleFbAccountChange = useCallback((value) => {
        setSelectedFbAccount(value);
        setSelectedPage('')
    }, []);

    const handlePageChange = useCallback((value) => {
        setSelectedPage(value);
        setFacebookQuestions([])
    }, []);

    const handleFormChange = useCallback((value) => {
        setSelectedForm(value);
    }, []);

    // const getFormMapping = useCallback(() => {
    //     getMappingByFormId(selectedIntegration.form_id).then(res => {
    //         const leadVal = res.data.data.map(lead => {
    //             return {
    //                 key: lead.facebook_value,
    //                 value: lead.crm_value,
    //                 label: lead.facebook_value
    //             }
    //         })
    //         setLeadMapping(leadVal);
    //     }).catch(err => {
    //         console.log({ err })
    //     })
    // }, [selectedIntegration, platformField]);

    // const getFormByIntegrationId = useCallback(() => {
    //     getPageFormByIntegrationId(selectedIntegration.uuid).then(res => {
    //         setSelectedForm(res.data.data)
    //     }).catch(err => {
    //         console.log({ err })
    //     })
    // }, [selectedIntegration]);

    useEffect(() => {
        if (editMapping) {
            setFormList(selectedFormList);
            updateStep(2);
            let mapping = []
            // selection for pre-fill form mapping list
            mapping = selectedFormList
                .filter(form => form.projectId)
                .map(form => {
                    return {
                        formId: form.id,
                        formName: form.name,
                        projectId: form.projectId
                    }
                })
            setFormMapping([...mapping]);
            setFormMappingCount(mapping.length);
        }
    }, [editMapping]);

    useEffect(() => {
        let interval;
        if (count > 0 && count < 4) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount + 1)
                getPageLinkForm();
            }, 3000)
        } else {
            clearInterval(interval);
            setCount(0)
        }

        return (() => clearInterval(interval))
    }, [count]);

    useEffect(() => {
        const selectedFormList = formMapping.map(item => {
            return formList.find(form => form.id === item.formId)
        })
        setSelectedMappedFormList(selectedFormList)
    }, [formMapping])

    useEffect(() => {
        if (selectedFbAccount) {
            getFbAccountPages();
        }
    }, [selectedFbAccount]);

    useEffect(() => {
        if (selectedPage) {
            getFbPageForms();
        }
    }, [selectedPage]);

    const updateLeadMapping = (facebookQue) => {
        let mapping = []

        platformField.forEach(item => {
            facebookQue.forEach(question => {
                if (['email'].includes(question.key) && item.value === 'email') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['name', 'full_name'].includes(question.key) && item.value === 'name') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['mobile', 'phone_number'].includes(question.key) && item.value === 'mobile') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['city'].includes(question.key) && item.value === 'city') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                } if (['alternate_mobile'].includes(question.key) && item.value === 'alternate_mobile') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (!(['name', 'email', 'mobile', 'city', 'full_name', 'phone_number', 'alternate_mobile'].includes(question.key)) &&
                    !(['name', 'email', 'mobile', 'city', 'alternateMobile'].includes(item.value))) {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
            })
        })
        setLeadMapping(mapping);
    }

    useEffect(() => {
        if (steps === 2 && !editMapping) {
            updateLeadMapping(facebookQuestions);
        }
    }, [facebookQuestions])

    const handleChangeFormMapping = useCallback((value, form) => {
        const forms = [...formMapping];
        const index = forms.findIndex(item => item.formId === form.id)
        const mapIndex = formList.findIndex(item => item.id === form.id)
        const newFormList = [...formList];
        if (!value) {
            forms[index].projectId = null
            newFormList[mapIndex].projectId = null;
            delete newFormList[mapIndex].marketingAgency;
            setFormList([...newFormList])
            setFormMapping([...forms]);
            const formIDList = formIdList.filter(item => item != form.id)
            setFormIdList(formIDList)
            setFormMappingCount(prev => prev - 1);
            return;
        }
        const mappingVal = {
            formId: form.id,
            formName: form.name,
            projectId: value.value
        }

        if (index > -1) {
            forms[index] = mappingVal
        } else {
            forms.push(mappingVal)
        }

        newFormList[mapIndex].projectId = value.value;
        setFormList([...newFormList])
        setFormMapping([...forms]);
        setFormIdList([...formIdList, form.id])
        setFacebookQuestions(getUnion(facebookQuestions, form.questions))
        let formLength = 0;
        forms.forEach(item => {
            if (item.projectId) {
                formLength += 1
            }
        });

        setFormMappingCount(formLength);
    }, [facebookQuestions, formList, formIdList]);

    const handleChangeAgency = useCallback((form, value) => {
        const forms = [...formMapping];
        const index = forms.findIndex(item => item.id === form.formId)
        const mapIndex = formList.findIndex(item => item.id === form.id)
        const newFormList = [...formList];
        if (index > forms.length) {
            toast.warning('Please select platform project first.')
            return;
        } else {
            forms[index]['marketingAgency'] = value?.value || null
            newFormList[mapIndex]['marketingAgency'] = value?.value || null
        }
        setFormMapping([...forms]);
        setFormList([...newFormList])
    }, [formMapping, formList]);

    const handleChangeTeamRouting = (form, value, teamNames) => {
        const forms = [...formMapping];
        const index = forms.findIndex(item => item.formId === form.id)
        const mapIndex = formList.findIndex(item => item.id === form.id)
        const newFormList = [...formList];
        if (index > forms.length) {
            toast.warning('Please select platform project first.')
            return;
        } else {
            forms[index]['routing'] = value.length > 0 ? value : null
            newFormList[mapIndex]['routing'] = value.length > 0 ? value : null
            forms[index]['team_names'] = teamNames.length > 0 ? teamNames : null
            newFormList[mapIndex]['team_names'] = teamNames.length > 0 ? teamNames : null

        }
        setFormMapping([...forms]);
        setFormList([...newFormList])
    }

    const handleChangeMapping = useCallback((value, key, label, mapIndex) => {
        const mappingVal = {
            crmValue: value.value,
            facebookValue: key,
            facebookLabel: label
        }
        const leads = [...leadMapping];
        const index = leads.findIndex(lead => lead.key === key);
        if (index > -1) {
            leads[index] = mappingVal;
        } else {
            leads.push(mappingVal)
        }
        const questionList = [...facebookQuestions];
        questionList[mapIndex].crmValue = value.value;
        setFacebookQuestions([...questionList]);
        setLeadMapping(leads);
    }, [leadMapping, facebookQuestions]);

    const handleChangeProject = useCallback((value) => {
        setSelectedProject(value)
    }, []);

    const handleUnmappedForm = useCallback((e, val) => {
        e.stopPropagation();
        setUnmappedForm(val)
    }, []);

    const handleSumitMapping = useCallback(() => {
        const payload = {
            pageId: selectedPage.id,
            pageName: selectedPage.name,
            marketingAgency: selectedAgency?.value,
            accessToken: selectedPage.access_token,
            mapping: leadMapping,
            profileId: selectedFbAccount.profile_id,
            formMapping,
            unmappedForms
        }

        setSubmitingIntegration(true);
        createFbIntegration(payload).then(res => {
            if (res.data.status === 200) {
                setSubmitingIntegration(false);
                setPageUuid(res.data.data.uuid)
                // updateStep(4);
                setIntegrationCreated(true);
            }
        }).catch(err => {
            console.log({ err })
            setSubmitingIntegration(false);
        })
    }, [leadMapping, selectedPage, selectedForm, selectedAgency, selectedProject, selectedFbAccount, formMapping]);

    const updateFbIntegration = useCallback(() => {
        if (query.get('uuid') || integratedPage.uuid) {
            const payload = {
                mapping: leadMapping,
                formMapping,
                unmappedForms
            }
            updateIntegration(query.get('uuid') || integratedPage.uuid, payload).then(res => {
                if (res.data.status === 200) {
                    setPageUuid(res.data.data.uuid);
                    setIntegrationCreated(true);
                }
            }).catch(err => {
                console.log({ err })
            })
        }
    }, [formMapping, leadMapping, unmappedForms, integratedPage]);

    const getPageLinkForm = useCallback(() => {
        getTestInetgrationsPageForms(pageUuid, { isTest: 'Test' }).then(res => {
            if (res.data.status === 200) {
                res.data.data.forms.forEach(item => {
                    if (item.test_status || count === 3) {
                        setIsSendingLead(false);
                        setCount(0);
                    }
                    if (!item.status && count === 3) {
                        setTestLeadFailed('Failed');
                    }
                    if (item.test_status) {
                        setTestLeadFailed('Success');
                    }
                })
                setSelectedMappedFormList(res.data.data.forms);
            }
        }).catch(err => {
            console.log(err)
            if (err.response.data.status === 500) {
                setTestLeadProcessModal(true);
            }
        })
    }, [pageUuid])

    const handleTestLead = useCallback((form, index) => {
        if (pageUuid) {
            setIsSendingLead(true);
            setSendTestLeadIndex(index)
            sendTestLead(pageUuid, { formIds: [form.form_id] }).then(res => {
                if (res.data.status === 200) {
                    if (count === 0) {
                        setCount(count + 1);
                    }
                }
                // updateStep(4);
            }).catch(err => {
                console.log({ err })
                if (err.response.data.status === 500) {
                    setTestLeadProcessModal(true);
                    setIsSendingLead(false);
                }
            })
        }
    }, [pageUuid, count]);

    const closeTestLeadProcessModal = useCallback(() => {
        setTestLeadProcessModal(false);
    }, []);

    const handlePageValidation = useCallback(() => {
        if (formMapping.length === 0 || !formMapping.find(item => item.projectId)) {
            setPageMappingValidation(true);
            toast.error('Map at least one facebook form to the platform projects to receive leads for those projects.')
            // return;
        } else {
            let formsQuestion = [];
            if (unmappedForms) {
                formList.forEach(form => {
                    formsQuestion = (getUnion(formsQuestion, form.questions))
                })
            } else {
                formMapping.forEach((mappedForm) => {
                    formList.forEach(form => {
                        if (mappedForm.projectId && mappedForm.formId === form.id) {
                            formsQuestion = (getUnion(formsQuestion, form.questions))
                        }
                    })
                })
            }
            updateLeadMapping(formsQuestion);
            setFacebookQuestions(formsQuestion);
            setPageMappingValidation(false);
            updateStep(3)
        }
    }, [formMapping, unmappedForms]);

    const handleConnectAccountNext = useCallback(() => {
        if (!selectedFbAccount || !selectedPage) {
            setConnectPageError(true);
            return;
        } else {
            setConnectPageError(false);
        }
        if (integratedPage && Object.keys(integratedPage).length > 0) {
            handleEditMapping(integratedPage.uuid, 'new')
        } else {
            updateStep(2)
            setShowInfoModal(true);
        }
    }, [integratedPage, selectedFbAccount, selectedPage]);

    const closeIntegrationCreatedModal = useCallback(() => {
        setIntegrationCreated(false);
    }, []);

    const handleCloseInfoModal = useCallback(() => {
        setShowInfoModal(false);
        // updateStep(2);
    }, [])

    // const handleCloseFbModal = () => {
    //     if (steps <= 3) {
    //         setShowCloseConfirmationModa(true);
    //     } else {
    //         showFinalConfirmaionModal();
    //     }
    // }

    // const closeConfirmationModal = () => {
    //     setShowCloseConfirmationModa(false);
    //     closeFbModal();
    // }

    const closeNoPageFoundModal = useCallback(() => {
        if (query.get('uuid')) {
            history.push('/settings/integrations/all-integrations/facebook-forms');
            return;
        }
        setSelectedPage('')
        setNoPageFormFound(false);
    }, []);

    const formMappingPrevious = useCallback(() => {
        updateStep(1);
        setFacebookQuestions([]);
        setFormMappingCount(0);
        setFormMapping([]);
    }, []);

    const handleLeadMappingPrevious = useCallback(() => {
        updateStep(2);
        setFacebookQuestions([]);
    }, []);

    return (
        <div className='facebook-integration w-100 h-full flex flex-col'>
            <div className="settings-title fbintegration pb-0 d-xl-block d-none">
                <div className="row d-flex flex-fill flex-xl-nowrap flex-wrap align-items-end">
                    <div className="col-12 d-flex flex-fill flex-column">
                        <div className='d-flex align-items-center mb-20'>
                            <h1 className='fz24 black fw-po-medium ls5 mb-0 flex items-center !gap-x-2'>
                                <a
                                    onClick={() => {
                                        if (steps < 4) {
                                            setShowCloseConfirmationModa(true);
                                            return;
                                        }
                                        history.push('/settings/integrations/all-integrations/facebook-forms');
                                    }}
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </a>

                                {/* 
                                <a className='' onClick={() => {
                                    if (steps < 4) {
                                        setShowCloseConfirmationModa(true);
                                        return;
                                    }
                                    history.push('/settings/integrations/all-integrations/facebook-forms');
                                }} ><BackCircle className='mr-8' /></a> */}
                                {!editMapping ? 'Create FB Integration' : 'Edit FB Integration'}
                            </h1>
                        </div>
                        {
                            (steps === 1 ||
                                steps === 2 ||
                                steps === 3 ||
                                steps === 4
                            ) &&
                            <>
                                <div className="modal-bradcum 100">
                                    <nav aria-label="breadcrumb " className="">

                                        <ol className="breadcrumb justify-content-start d-xl-flex d-none border-0 !pb-[11px]">
                                            <li className={`breadcrumb-item ${steps === 1 ? 'active' : ''}`}><a className='pl-0 pr-0'><span className='mr-2'>1</span> <span>Connect Account</span> </a></li>
                                            <li className={`breadcrumb-item  ${steps === 2 ? 'active' : ''}`}><a className='pl-0 pr-0 flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.4536 11L8.51849 7.06485C8.1605 6.70687 8.1605 6.12647 8.51849 5.76849C8.87647 5.4105 9.45687 5.4105 9.81485 5.76849L14.3982 10.3518C14.7562 10.7098 14.7562 11.2902 14.3982 11.6482L9.81485 16.2315C9.45687 16.5895 8.87647 16.5895 8.51849 16.2315C8.1605 15.8735 8.1605 15.2931 8.51849 14.9352L12.4536 11Z" fill="#92929D" />
                                            </svg><span className='mr-2'>2</span> <span>Form Mapping</span></a></li>
                                            <li className={`breadcrumb-item ${steps === 3 ? 'active' : ''}`}><a className='pl-0 pr-0 flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.4536 11L8.51849 7.06485C8.1605 6.70687 8.1605 6.12647 8.51849 5.76849C8.87647 5.4105 9.45687 5.4105 9.81485 5.76849L14.3982 10.3518C14.7562 10.7098 14.7562 11.2902 14.3982 11.6482L9.81485 16.2315C9.45687 16.5895 8.87647 16.5895 8.51849 16.2315C8.1605 15.8735 8.1605 15.2931 8.51849 14.9352L12.4536 11Z" fill="#92929D" />
                                            </svg><span className='mr-2'>3</span> <span>Lead Mapping</span></a></li>
                                            <li className={`breadcrumb-item ${steps === 4 || steps === 4 ? 'active' : ''}`}><a className='pl-0 pr-0 flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.4536 11L8.51849 7.06485C8.1605 6.70687 8.1605 6.12647 8.51849 5.76849C8.87647 5.4105 9.45687 5.4105 9.81485 5.76849L14.3982 10.3518C14.7562 10.7098 14.7562 11.2902 14.3982 11.6482L9.81485 16.2315C9.45687 16.5895 8.87647 16.5895 8.51849 16.2315C8.1605 15.8735 8.1605 15.2931 8.51849 14.9352L12.4536 11Z" fill="#92929D" />
                                            </svg><span className='mr-2'>4</span> <span>Test Integration</span> </a></li>
                                        </ol>
                                    </nav>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            <figure className='d-table mx-auto d-xl-none mt-24 mb-16'><img src={fbicon} alt="" /></figure>
            <nav aria-label="breadcrumb " className="fb-mobile-breadcrumbt-text d-xl-none">
                <ol className="breadcrumb justify-content-center d-flex">
                    <li className={`breadcrumb-item ${steps === 1 ? 'active' : ''}`}>Connect Account</li>
                    <li className={`breadcrumb-item ${steps === 2 ? 'active' : ''}`}>Form Mapping</li>
                    <li className={`breadcrumb-item ${steps === 3 ? 'active' : ''}`}>Lead Mapping</li>
                    <li className={`breadcrumb-item ${steps === 4 ? 'active' : ''}`}>Test Integration</li>

                </ol>
            </nav>
            <nav aria-label="breadcrumb " className="fb-mobile-breadcrumb-circle d-xl-none">
                <ol className="breadcrumb justify-content-center d-flex">
                    <li className={`breadcrumb-item ${steps === 1 ? 'active' : ''}`}><span></span></li>
                    <li className={`breadcrumb-item ${steps === 2 ? 'active' : ''}`}><span></span></li>
                    <li className={`breadcrumb-item ${steps === 3 ? 'active' : ''}`}><span></span></li>
                    <li className={`breadcrumb-item ${steps === 4 ? 'active' : ''}`}><span></span></li>
                </ol>
            </nav>
            {
                steps === 1 &&
                <ConnectForm
                    updateStep={updateStep}
                    facebookAccountList={facebookAccountList}
                    getFbLoginUrl={getFbLoginUrl}
                    handleFbAccountChange={handleFbAccountChange}
                    selectedFbAccount={selectedFbAccount}
                    pageList={pageList}
                    handlePageChange={handlePageChange}
                    selectedPage={selectedPage}
                    formList={formList}
                    selectedForm={selectedForm}
                    handleFormChange={handleFormChange}
                    selectedAgency={selectedAgency}
                    handleChangeAgency={handleChangeAgency}
                    handleConnectAccountNext={handleConnectAccountNext}
                    isLoading={isLoading}
                    connectPageError={connectPageError}
                    pageFetching={pageFetching}
                />
            }
            {
                steps === 2 &&
                <FormMapping
                    updateStep={updateStep}
                    formList={formList}
                    selectedForm={selectedForm}
                    platformField={platformField}
                    handleChangeFormMapping={handleChangeFormMapping}
                    editMapping={editMapping}
                    projects={projects}
                    handleUnmappedForm={handleUnmappedForm}
                    unmappedForms={unmappedForms}
                    formMapping={formMapping}
                    handlePageValidation={handlePageValidation}
                    pageMappingValidation={pageMappingValidation}
                    integrationState={integrationState}
                    agencyDetails={agencyDetails}
                    getAgencyData={getAgencyData}
                    handleChangeAgency={handleChangeAgency}
                    formMappingCount={formMappingCount}
                    formMappingPrevious={formMappingPrevious}
                    showInfoModal={showInfoModal}
                    handleCloseInfoModal={handleCloseInfoModal}
                    allTeams={allTeams}
                    handleChangeTeamRouting={handleChangeTeamRouting}
                />
            }
            {
                steps === 3 &&
                <LeadMapping
                    updateStep={updateStep}
                    selectedForm={selectedForm}
                    platformField={platformField}
                    handleChangeMapping={handleChangeMapping}
                    leadMapping={leadMapping}
                    editMapping={editMapping}
                    facebookQuestions={facebookQuestions}
                    handleSumitMapping={handleSumitMapping}
                    updateFbIntegration={updateFbIntegration}
                    submitingIntegration={submitingIntegration}
                    integrationState={integrationState}
                    handleLeadMappingPrevious={handleLeadMappingPrevious}
                />
            }
            {/* {steps === 3 &&
            <Final
                updateStep={updateStep}
                projects={projects}
                agencyDetails={agencyDetails}
                handleSumitMapping={handleSumitMapping}
                handleChangeAgency={handleChangeAgency}
                handleChangeProject={handleChangeProject}
                selectedAgency={selectedAgency}
                selectedProject={selectedProject}
            />} */}
            {steps === 8 && <Success updateStep={updateStep} />}
            {

                steps === 4 && <TestLeadResponse
                    updateStep={updateStep}
                    formList={selectedMappedFormList}
                    handleTestLead={handleTestLead}
                    sendTestLeadIndex={sendTestLeadIndex}
                    isSendingLead={isSendingLead}
                    testLeadFailed={testLeadFailed}
                    getPageLinkForm={getPageLinkForm}
                />
            }

            {
                testLeadProcessModal && <TestleadProcess
                    show={testLeadProcessModal}
                    closeModal={closeTestLeadProcessModal}
                />
            }

            {
                integrationCreated &&
                <IntegrationCreatedModal
                    show={integrationCreated}
                    heading={editMapping ? 'Integration Updated' : 'Integration Created'}
                    closeModal={closeIntegrationCreatedModal}
                    updateStep={updateStep}
                />
            }

            {showCloseConfirmationModal && <AreYouSureModal
                show={showCloseConfirmationModal}
                closeModal={() => setShowCloseConfirmationModa(false)}
                subText={'Do you want to cancel the project creation? Any unsaved changes you made may be lost.'}
                confirmClose={() => history.push('/settings/integrations/all-integrations/facebook-forms')}
            />}

            {noPageFormFound && <NoFormFound show={noPageFormFound} pageName={selectedPage.name} closeModal={closeNoPageFoundModal} />}
        </ div >
    )
}

export default Integration
