import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import SliderModal from './SliderModal';
import InputText from '../InputGroup/InputText';
import { connectFacebookFormSchema, connectGoogleAddFormSchema } from '../../validators/leads.validator';
import InputSelect from '../InputGroup/InputSelect';
import { getAllProjects, addProjectFormId, getProjectFormIds, addPageIntegration, updatePageIntegration, getCompanyAgency, getLandingPageIntegrationRouting } from '../../services/private/company.service';
import Consumer from '../../helpers/context'
import { getAllTeams } from '../../services/public/public.service';
import ProjectTeamCard from '../Projects/ProjectTeamCard';
import IntegrationTeamCard from '../Projects/IntegrationTeamCard';
import EditTeamUser from '../Projects/EditTeamUser';

export default function GoogleAddFormModal({
    showFormIntegration,
    closeModal,
    getAllPgeIntegration,
    formApiKey,
    viewCodeSnipet,
    leadDetail,
    projects
}) {
    const [formIntegrated, setFormIntegrated] = useState(viewCodeSnipet);
    const [apiKey, setApiKey] = useState(formApiKey || '');
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [teams, setTeams] = useState([]);
    const [addedTeams, setAddedTeams] = useState([]);
    const [routing, setRouting] = useState([]);
    const [showEditTeamUser, setShowEditTeamUser] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({})

    let { companyConfig, userProfile } = useContext(Consumer);

    const addNewPageIntegration = async (payload) => {
        await addPageIntegration({ ...payload, routing: routing }).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                setApiKey(res.data.data.apikey)
                // setShowFormIntegration(false)
                setFormIntegrated(true)
                getAllPgeIntegration()
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const updateLandingPageIntegration = (payload) => {
        updatePageIntegration({ ...payload, routing: routing }, payload.uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                setApiKey(res.data.data.apikey)
                // setShowFormIntegration(false)
                setFormIntegrated(true)
                getAllPgeIntegration()
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getlandingPageRouting = async () => {
        const res = await getLandingPageIntegrationRouting(leadDetail?.uuid);
        if (res.data.status === 200) {
            let mappedTeams = res.data.data.map(t => ({
                ...t, users: t.users?.map(u => ({ ...u, isSelected: true }))
            }))

            mappedTeams.map(m => {
                const obj = teams.find(t => t.id === m.team_id)
                var ids = new Set(m.users.map(d => d.id));
                m.users = [...m.users, ...obj.users.filter(d => !ids.has(d.id))];
            })

            setAddedTeams(mappedTeams);
            let mapped = res.data.data.map(t => ({
                team_id: t.team_id,
                users: t.users?.map(u => u.id)
            }))
            setRouting(mapped)
        }
    }

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data?.map(t => ({ ...t, label: t.name, value: t.uuid }))
            setTeams(mapped);
        }
    }

    useEffect(() => {
        getAgencyData();
        getAll();
    }, []);

    useEffect(() => {
        if (leadDetail?.uuid && teams.length) {
            getlandingPageRouting();
        }
    }, [leadDetail, teams])


    const handleSaveRouting = (userRouting) => {
        const index = routing.findIndex(r => r.team_id === userRouting.team_id);
        if (index > -1) {
            let newRouting = [...routing]
            newRouting[index] = userRouting;
            setRouting(newRouting)
        } else {
            setRouting([...routing, userRouting])
        }
    }


    const handleRemoveAddedTeam = (index, team) => {
        let teamsClone = [...addedTeams];
        teamsClone.splice(index, 1);
        setAddedTeams(teamsClone);
        const routingIndex = routing.findIndex(r => (r.team_id || r.id) === (team.team_id || team.id));
        const routingClone = [...routing];
        routingClone.splice(routingIndex, 1)
        setRouting(routingClone);
    }

    return (
        <>
            <SliderModal
                show={showFormIntegration}
                setShow={closeModal}
                title={"Connect your landing page"}
                body={
                    <>
                        {!formIntegrated &&
                            <Formik
                                validationSchema={connectGoogleAddFormSchema}
                                initialValues={{
                                    title: leadDetail?.title || '',
                                    weburl: leadDetail?.weburl || '',
                                    project: leadDetail?.id ? projects.find(p => p.value === leadDetail.id) : '',
                                    source: leadDetail?.source ? leadDetail?.source : '',
                                    agency: leadDetail?.agency ? agencyDetails?.find(agency => agency.label === leadDetail?.agency) : ''
                                }}
                                onSubmit={async (values) => {
                                    const { title, weburl, project: { value: projectId }, source: { value: sourceId }, agency } = values;
                                    if (leadDetail?.uuid) {
                                        await updateLandingPageIntegration({ title, weburl, projectId, sourceId, uuid: leadDetail?.uuid, marketingAgency: agency ? agency.value : null })
                                    } else {
                                        await addNewPageIntegration({ title, weburl, projectId, sourceId, marketingAgency: agency ? agency.value : null })
                                    }
                                }}
                                validateOnBlur={false}
                                validateOnChange={false}
                                enableReinitialize
                            >
                                {
                                    (props) => {
                                        const { isValid, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;

                                        return (
                                            <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                                <div className='h-full overflow-auto !px-5'>
                                                    <InputText
                                                        value={values['title']}
                                                        onChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        index={0}
                                                        error={errors['title']}
                                                        touched={touched['title']}
                                                        type="text"
                                                        placeholder="eg. Piramal LP"
                                                        name="title"
                                                        label="Integration Name"
                                                        disable={!!leadDetail?.title}
                                                    />
                                                    <InputText
                                                        value={values['weburl']}
                                                        onChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        index={0}
                                                        error={errors['weburl']}
                                                        touched={touched['weburl']}
                                                        type="text"
                                                        placeholder="e.g. www.piramal.in"
                                                        name="weburl"
                                                        label="Landing Page URL"
                                                    />
                                                    <InputSelect
                                                        index={4}
                                                        name="project"
                                                        id="project"
                                                        error={errors['project']}
                                                        touched={touched['project']}
                                                        value={values['project']}
                                                        options={projects}
                                                        placeholder="e.g. Godrej Green"
                                                        onChange={(e) => {
                                                            const value = { target: { name: "project", value: e } }
                                                            handleChange(value)
                                                        }}
                                                        label={<>Select Project</>}
                                                    />
                                                    <InputSelect
                                                        index={5}
                                                        name="source"
                                                        id="source"
                                                        error={errors['source']}
                                                        touched={touched['source']}
                                                        value={values['source']}
                                                        options={[...companyConfig.Source]}
                                                        placeholder="Select Source"
                                                        onChange={(e) => {
                                                            const value = { target: { name: "source", value: e } }
                                                            handleChange(value)
                                                        }}
                                                        label={<>Select Source</>}
                                                    />
                                                    <InputSelect
                                                        index={6}
                                                        name="agency"
                                                        id="agency"
                                                        error={errors['agency']}
                                                        touched={touched['agency']}
                                                        value={values['agency']}
                                                        options={agencyDetails}
                                                        placeholder="Select Marketing Agency"
                                                        onChange={(e) => {
                                                            const value = { target: { name: "agency", value: e } }
                                                            handleChange(value)
                                                        }}
                                                        label={<>Select Marketing Agency (Optional)</>}
                                                    />


                                                    {userProfile?.allowed_modules?.includes('teams_module') && <InputSelect
                                                        label='Select Team'
                                                        placeholder='Select Team'
                                                        value={''}
                                                        onChange={(value) => {
                                                            if (!value?.users) {
                                                                toast.warning(`There is no user added in ${value?.name}`)
                                                                return;
                                                            }
                                                            const projectIndex = addedTeams.findIndex(t => t.name === value.name);
                                                            if (projectIndex === -1) {
                                                                setAddedTeams([...addedTeams, value]);
                                                            }
                                                        }}
                                                        // styles={customStyles}
                                                        options={teams}
                                                    />}


                                                    {addedTeams.map((team, index) => (
                                                        <div className='mt-2'>
                                                            <IntegrationTeamCard
                                                                key={`${team.uuid}_${index}`}
                                                                team={team}
                                                                setShowEditTeamUser={setShowEditTeamUser}
                                                                setSelectedTeam={setSelectedTeam}
                                                                handleRemoveAddedTeam={handleRemoveAddedTeam}
                                                            />
                                                        </div>
                                                    ))
                                                    }

                                                    <div className="no-result-find d-flex flex-column align-items-center justify-content-center">
                                                        <figure>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                                                                <g opacity="0.9" clipPath="url(#clip0_2708_52056)">
                                                                    <path d="M20.5 40C25.8043 40 30.8914 37.8929 34.6421 34.1421C38.3929 30.3914 40.5 25.3043 40.5 20C40.5 14.6957 38.3929 9.60859 34.6421 5.85786C30.8914 2.10714 25.8043 0 20.5 0C15.1957 0 10.1086 2.10714 6.35786 5.85786C2.60714 9.60859 0.5 14.6957 0.5 20C0.5 25.3043 2.60714 30.3914 6.35786 34.1421C10.1086 37.8929 15.1957 40 20.5 40ZM17.5 29C17.5 28.2044 17.8161 27.4413 18.3787 26.8787C18.9413 26.3161 19.7044 26 20.5 26C21.2956 26 22.0587 26.3161 22.6213 26.8787C23.1839 27.4413 23.5 28.2044 23.5 29C23.5 29.7956 23.1839 30.5587 22.6213 31.1213C22.0587 31.6839 21.2956 32 20.5 32C19.7044 32 18.9413 31.6839 18.3787 31.1213C17.8161 30.5587 17.5 29.7956 17.5 29ZM18.532 9.64C18.6155 9.17887 18.8583 8.7617 19.2179 8.4613C19.5776 8.1609 20.0314 7.99634 20.5 7.99634C20.9686 7.99634 21.4224 8.1609 21.7821 8.4613C22.1417 8.7617 22.3845 9.17887 22.468 9.64L22.5 10V20L22.468 20.36C22.3845 20.8211 22.1417 21.2383 21.7821 21.5387C21.4224 21.8391 20.9686 22.0037 20.5 22.0037C20.0314 22.0037 19.5776 21.8391 19.2179 21.5387C18.8583 21.2383 18.6155 20.8211 18.532 20.36L18.5 20V10L18.532 9.64Z" fill="#FD9A39" fillOpacity="0.9" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2708_52056">
                                                                        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </figure>
                                                        <p className="text-center black-dark-600 fz14 mb-0">Note : Once created, it cannot be modified</p></div>
                                                </div>

                                                <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                                    <button
                                                        className="cancel outline-btn"
                                                        onClick={closeModal}
                                                        type='button'
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="pr-btn"
                                                        type="submit"
                                                    >
                                                        {leadDetail?.uuid ? 'Update' : 'Create New'}
                                                    </button>

                                                </div>
                                            </form>
                                        )
                                    }}
                            </Formik>}

                        {formIntegrated &&
                            <div className='black-dark-700 h-full overflow-auto md:!px-5 !px-4'>
                                <p className='fz16 pr-3'>
                                    Ask the developer to use the API to make an API call on form submission to get leads from website.
                                </p>
                                <div className='api-box rounded-top mt-4'>
                                    <div className='api-box__header rounded-top d-flex align-items-center'>
                                        <span className='ml-md-3 ml-2 mr-md-2 mr-1'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 14V9C11 8.44771 10.5523 8 10 8C9.44771 8 9 8.44771 9 9V14C9 14.5523 9.44771 15 10 15C10.5523 15 11 14.5523 11 14Z" fill="white" />
                                                <path d="M9 6C9 5.44771 9.44771 5 10 5C10.5523 5 11 5.44771 11 6C11 6.55229 10.5523 7 10 7C9.44771 7 9 6.55229 9 6Z" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10Z" fill="white" />
                                            </svg>
                                        </span>
                                        API Code Snippet for Developers <span title="Copy API Key" className="cursor-pointer d-md-inline d-block" onClick={() => {
                                            navigator.clipboard.writeText(`https://api.propacity.in/api/v1/webhooks/integration/${apiKey}/insertLead`).then(function () {
                                                toast.success('api Copied');
                                            }, function (err) {
                                                toast.error('Could not copy api');
                                            });
                                        }}><svg className="ml-12" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V12C0 13.6569 1.34315 15 3 15H9C10.6569 15 12 13.6569 12 12V5.06569C12 4.7219 11.8819 4.38855 11.6655 4.12143L8.77662 0.555737C8.49181 0.204205 8.06356 0 7.61113 0H3ZM8.71875 2.86712V4.05H9.6771L8.71875 2.86712ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3V12C1.5 12.8284 2.17157 13.5 3 13.5H9C9.82843 13.5 10.5 12.8284 10.5 12V5.55H8.71875C7.89032 5.55 7.21875 4.87843 7.21875 4.05V1.5H3Z" fill="#ffff"></path></svg></span>
                                    </div>
                                    <div className='api-box__body'>
                                        <p className='fz14 mb-1'><span style={{ fontWeight: 'bold' }}>METHOD: </span>POST</p>
                                        <p className='fz14 mb-4 mt-4'> <span style={{ fontWeight: 'bold' }}>API URL: </span> {`https://api.propacity.in/api/v1/webhooks/integration/${apiKey}/insertLead`} </p>
                                        <p className='fz14 mb-1'> <span style={{ fontWeight: 'bold' }}> Payload: </span> &#123; </p>
                                        <p className='fz14 mb-1'>name: "name here"</p>
                                        <p className='fz14 mb-1'>email: "email here"</p>
                                        <p className='fz14 mb-1'>mobile: "mobile here"</p>
                                        <p className='fz14 mb-1'>meta: "meta data here"</p>
                                        <p>&#125;</p>
                                    </div>
                                </div>
                            </div>
                        }

                    </>
                }
            />
            {
                showEditTeamUser &&
                <EditTeamUser
                    show={showEditTeamUser}
                    closeModal={() => setShowEditTeamUser(false)}
                    selectedTeam={selectedTeam}
                    handleSaveRouting={handleSaveRouting}
                />
            }
        </>
    )
}