import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';
import { getDeadReasons } from '../../services/public/public.service';

const SourcingManagerCompaniesCard = ({ sourcingManagerCPList, companyConfig, fromDate, toDate, selectedDate }) => {
    const [tempData, setTempData] = useState([]);
    const [deadLeadReasons, setDeadLeadReasons] = useState([])

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setDeadLeadReasons(reasonsData);
                // setConfig({ ...config, Dead: reasonsData });
            }
        })
    }

    useEffect(() => {
        getDeadReasonsStatus();
    }, []);

    const exportData = () => {
        let temp = sourcingManagerCPList?.map((lead, index) => {
            return {
                "Reasons": lead.sm_name || '',
                "Total Company": lead?.company_count || '',
            }
        })
        setTempData(temp);

    };

    // const handleAddFilter = (reason) => {
    //     let proj = {}

    //     const deadReason = deadLeadReasons.filter(s => s.label === reason);

    //     const payload = []
    //     if (selectedDate !== 'allTime') {
    //         payload.push({
    //             'Created Date': {
    //                 From: fromDate,
    //                 To: toDate
    //             }
    //         })
    //     }
    //     if (deadReason.length > 0) {
    //         payload.push({
    //             "Dead Reasons": deadReason.map(s => s.value)
    //         })
    //     }

    //     localStorage.setItem('filter', JSON.stringify(payload));
    //     const link = document.createElement("a");
    //     link.href = '/';
    //     link.target = '_blank'
    //     link.click();        
    // }

    return (
        <div className="table-card project-anlatic d-flex flex-fill flex-column booking-eoi-table">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className='d-flex'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Sourcing Manager Company Analysis</h2>
                </div>
                {/* <button className='flex items-center border rounded-lg p-1'>
                    <DownloadIcon className='' />
                </button> */}
                <CSVLink
                    className={`flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded !px-2 py-1.5 text-sm gap-x-1`}
                    data={tempData}
                    onClick={exportData}
                    filename={'sourcing_manager_company_analysis.csv'}>
                    CSV <DownloadIcon />
                </CSVLink>
            </div>

            <div className="scroll-table">
                <table className="table table-striped table-bg">
                    <thead>
                        <tr>
                            <th>SM Name</th>
                            <th>TOTAL Company</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sourcingManagerCPList?.map((lead, index) => {
                                // if (index >= 5) return null;
                                return (
                                    <tr key={`${index}_${lead.company_count}`}>
                                        <td className=''
                                        // onClick={() => handleAddFilter(lead.reason)}
                                        >{lead.sm_name}</td>
                                        <td>{lead.company_count}</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
            {sourcingManagerCPList?.length === 0 && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
        </div>
    )
}

export default SourcingManagerCompaniesCard