export const SINGUP_ACTIONS = {
    COMPANY_SIGNUP: 'COMPANY_SIGNUP',
    IS_VALID_INVITE_URL: 'IS_VALID_INVITE_URL',
    SET_VALID_STATE: 'SET_VALID_STATE',
    SET_URL_COMPLETED: 'SET_URL_COMPLETED'
}

export function action_SingnupCompany(uuid, payload) {

    return {
        type: SINGUP_ACTIONS.COMPANY_SIGNUP,
        uuid,
        payload

    };
}

export function action_isValidInvite(uuid) {
    return {
        type: SINGUP_ACTIONS.IS_VALID_INVITE_URL,
        uuid
    }
}

export function action_setValidState(payload) {
    return {
        type: SINGUP_ACTIONS.SET_VALID_STATE,
        payload
    }
}

export function action_setUrlCompleted(payload) {
    return {
        type: SINGUP_ACTIONS.SET_URL_COMPLETED,
        payload
    }
}