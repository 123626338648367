import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import InputSelect from '../../../../../components/InputGroup/InputSelect';
import { addExistingUser, getExistingUsers } from '../../../../../services/private/company.service';
import { getInitials } from '../../../../../helpers/helpers';
import { toast } from 'react-toastify';
import ExistingUserConfimationModal from './ExistingUserConfimationModal';
import TooltipText from '../../../../../components/Tooltip';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const InviteExistingUsers = ({ show, closeModal, uuid, roleName, getUsersByUUID, handleBackBtn }) => {

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const getUsers = async () => {
        const res = await getExistingUsers();
        if (res.data.status === 200) {
            const userData = res.data.data
                .filter(user => user.role_name !== 'Super Admin')
                .map(item => ({ ...item, label: `${item.name} (${item.role_name})`, value: item.uuid }))
            setUsers(userData)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const handleRemove = (user) => {
        const filteredData = selectedUsers.filter(item => item.uuid !== user.uuid)
        setSelectedUsers(filteredData);
        setUsers([...users, user]);
    }

    const handleSubmit = async () => {
        const usersId = selectedUsers.map(item => item.uuid);
        const res = await addExistingUser(uuid, { users: usersId });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setShowConfirmationModal(false);
            getUsersByUUID();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal=
            {() => {
                closeModal();
            }}
            modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    <button onClick={handleBackBtn}>
                        <BackIcon />
                    </button>
                    <p className='m-0 black font-semibold inter fz20 leading-5 ml-2'>
                        Add Existing User
                    </p>
                </div>

                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='!px-4 !pt-4 !pb-6'>
                <label className='text-md leading-5'>Choose existing user</label>
                <InputSelect
                    index={4}
                    name="project"
                    id="project"
                    value={[]}
                    options={users}
                    styles={customStyles}
                    placeholder="Please select user"
                    onChange={(e) => {
                        const newusers = users.filter(item => item.value !== e.value)
                        setUsers(newusers)
                        console.log(e)
                        setSelectedUsers([...selectedUsers, e])
                    }}
                // label={<></>}
                />
            </div>
            <div className='h-full overflow-auto !px-4  pb-6'>
                <div className=' space-y-4'>
                    {
                        selectedUsers.map((user, index) => (
                            <div className='flex justify-between items-center border rounded-md px-2.5 py-3' key={`${user.uuid}_${index}`}>
                                <div className='flex items-center'>
                                    <TooltipText title={user.role_name || ''}>
                                        <span className="mr-2.5 border p-1 rounded-md text-xl bg-[#F2F2F2] font-bold text-grey-300">
                                            {getInitials(user.role_name)}
                                        </span>
                                    </TooltipText>

                                    <div>
                                        <TooltipText title={user.label || ''}>
                                            <h6 className='m-0 truncate w-[200px]'>{user.label}</h6>
                                        </TooltipText>
                                        <TooltipText title={user.email || ''}>
                                            <p className='m-0 text-grey200 truncate w-[200px]'>{user.email}</p>
                                        </TooltipText>
                                    </div>
                                </div>
                                <div>
                                    <button className='!border !border-primary hover:!bg-primary hover:!text-white font-medium  !text-primary text-xs rounded px-2.5 py-1.5' onClick={() => handleRemove(user)} >Remove</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {selectedUsers.length > 0 && <div className={` flex flex-row items-center justify-between border-top border-color w-100 space-x-3 p-3`}>

                <button className='pr-btn w-100 !py-1.5 !text-lg !font-medium !rounded-lg' onClick={() => setShowConfirmationModal(true)}>Move {selectedUsers.length > 1 ? selectedUsers.length : ''} Users to Role</button>
            </div>}

            {
                showConfirmationModal && <ExistingUserConfimationModal
                    show={showConfirmationModal}
                    closeModal={() => setShowConfirmationModal(false)}
                    users={selectedUsers}
                    roleName={roleName}
                    handleSubmit={handleSubmit}
                />
            }
        </RightModal>
    )
}

export default InviteExistingUsers
