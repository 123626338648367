import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';



const TestleadProcess = ({ show, closeModal }) => {

    const handleSendManually = () => {
        closeModal();
        window.open('https://developers.facebook.com/tools/lead-ads-testing', '_blank')
    }

    return (
        <Modal size="sm" show={show} onHide={closeModal} backdrop="static" centered dialogClassName='modal-radius testleadProcess'>
            <>
                <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                        <rect width="48" height="48" transform="translate(1)" fill="white" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="#FBBC05" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint0_linear_6217_80535)" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint1_linear_6217_80535)" fillOpacity="0.2" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="#FBBC05" strokeWidth="2.26374" strokeLinejoin="round" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint2_linear_6217_80535)" strokeWidth="2.26374" strokeLinejoin="round" />
                        <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint3_linear_6217_80535)" strokeOpacity="0.2" strokeWidth="2.26374" strokeLinejoin="round" />
                        <path d="M23 14.9V26.9C23 28.2255 24.0745 29.3 25.4 29.3C26.7255 29.3 27.8 28.2255 27.8 26.9V14.9C27.8 13.5745 26.7255 12.5 25.4 12.5C24.0745 12.5 23 13.5745 23 14.9Z" fill="white" />
                        <path d="M27.8 34.1C27.8 35.4255 26.7255 36.5 25.4 36.5C24.0745 36.5 23 35.4255 23 34.1C23 32.7745 24.0745 31.7 25.4 31.7C26.7255 31.7 27.8 32.7745 27.8 34.1Z" fill="white" />
                        <defs>
                            <linearGradient id="paint0_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.999" y2="68.501" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FF974A" stopOpacity="0" />
                                <stop offset="1" stopColor="#FF974A" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FF974A" stopOpacity="0" />
                                <stop offset="1" stopColor="#FDA82C" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="white" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </figure>
                <h1 className='fz20 black fw-po-medium mb-8'>Test Lead process not working</h1>
                <p className='fz14 po black-dark-700 mb-0'>Do you wish to send test leads manually on the forms to check the integration?</p>
                <div className="d-flex flex-row align-items-center skiptbtn-group">
                    <button className='skip-btn' onClick={closeModal}>Skip</button>
                    <button className='pr-btn' onClick={handleSendManually}>Send Manually</button>
                </div>
            </>
        </Modal >
    )
}

export default TestleadProcess