import React from 'react'
import PropTypes from 'prop-types';

const TextareaField = ({ label, name, placeholder, error, errorMessage, divClass, inputClass, fieldRef, ...props }) => {
    return (
        <div className={`${divClass} w-full`}>
            <label className="block text-14 text-black700 mb-2" htmlFor={name}>{label}</label>
            <textarea

                id={name}
                name={name}
                placeholder={placeholder}
                ref={fieldRef}
                {...props}
                className={`${inputClass}  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
            />
        </div>
    )
}

TextareaField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    defaultValue: PropTypes.any,
    fieldRef: PropTypes.any
};

TextareaField.defaultProps = {
    type: 'text',
    placeholder: '',
    error: false,
    errorMessage: '',
    fieldRef: undefined,
    divClass: '',
    inputClass: ''
};

export default TextareaField
