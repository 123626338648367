import React, { useCallback, useContext, useEffect, useState } from "react";
import { statesDropDown, countriesDropdown } from "../../../../../helpers/enums";
import { updateCustomerByIdAndKey, updateCustomerByIdAndKey2 } from "../../../../../services/private/customer.service";
import { toast } from "react-toastify";
import { gender_list } from "../../../../../utils/constants";
import DynamicEditinput from "../../../../../components/InputGroup/DynamicEditInput";
import DynamicDropdown from "../../../../../components/InputGroup/DynamicDropdown";
import { getAllCities, getCitiesFromState } from "../../../../../services/public/public.service";
import { getLeadByUuid, updateLeadByIdAndKey } from "../../../../../services/private/leads.service";
import { useParams } from "react-router-dom";
import TextCalendar from "../../../../Lead Details/components/Info Components/Text Calendar/TextCalendar";
import moment from "moment/moment";
import Consumer from '../../../../../helpers/context';

export default function OtherInfoTab(props) {

    const [editActive, setEditActive] = useState({})
    const [customerInfoData, setCustomerInfoData] = useState({})
    const [customerInfo, setCustomerInfo] = useState({})
    const [leadInfoData, setLeadInfoData] = useState({})
    const [leadInfo, setLeadInfo] = useState({})

    const params = useParams();
    let { userProfile } = useContext(Consumer);

    useEffect(() => {
        setCustomerInfoData(props.customerInfoData)
    }, [props.customerInfoData])


    const getLeadDetails = async () => {
        await getLeadByUuid(params.id).then(res => {
            if (res.status === 200) {
                setCustomerInfoData(res.data.data.customer)
                setCustomerInfo(res.data.data.customer)
                setLeadInfoData(res.data.data.lead)
                setLeadInfo(res.data.data.lead)
            }
        })
    }

    useEffect(() => {
        getLeadDetails()
    }, [])

    const handleEditActive = (name, value) => {
        const index = Object.values(editActive).indexOf(true)
        if (index !== -1 && !value) {
            setEditActive({ ...editActive, [name]: value })
        }
        if (index === -1 && value) {
            setEditActive({ ...editActive, [name]: value })
        }
    }

    const handleValueChange = (name, value) => {
        let data = customerInfoData.data;
        data = { ...data, [name]: value };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }

    const handleCloseCustomerInfo = (name) => {
        let data = customerInfoData.data;
        data = { ...data, [name]: customerInfo?.data[name] };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }

    const handleLeadValueChange = (name, value) => {
        let data = leadInfoData.data;
        data = { ...data, [name]: value };
        setLeadInfoData({ ...leadInfoData, data: data, })
    }

    const handleCloseLeadInfo = (name) => {
        let data = leadInfoData.data;
        data = { ...data, [name]: leadInfo?.data[name] };
        setLeadInfoData({ ...customerInfoData, data: data })
    }

    const updateCustomerInfo = async (key, value, label) => {
        let newVal = value;
        if (!value) {
            toast.error(`Can't save empty value.`)
            return;
        }

        if (customerInfo?.data[key] === customerInfoData.data[key]) {
            handleEditActive(key, false)
            return;
        }

        if (key === 'birthdate') {
            newVal = moment(value).add('5:30');
        }

        await updateCustomerByIdAndKey(customerInfoData.data.uuid, { key, value: newVal }).then((res) => {
            if (res.status === 200) {
                toast.success(`${label} updated`);
                handleEditActive(key, false)
                getLeadDetails();
            }
        }).catch((err) => {
            console.log(err, "Something went wrong on updating customer info");
        })
    }

    const selectedValue = useCallback((dropdownList, valueToFind) => {
        return dropdownList.find((d) => d.value === valueToFind)
    }, [])


    const saveLeadData = async (key, value, label) => {
        if (!value) {
            toast.error(`Can't save empty value.`)
            return;
        }

        if (leadInfoData?.data[key] === leadInfo.data[key]) {
            handleEditActive(key, false)
            return;
        }

        await updateLeadByIdAndKey(params.id, {
            key, value
        }).then(res => {
            if (res.status === 200) {
                toast.success(`${label} updated`);
                getLeadDetails();
                handleEditActive(key, false)
            }
        })
    }

    const loadOptions = (city) => {
        return getAllCities(city)
            .then((res) => {
                if (res.data.status === 200) {
                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <>
            <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
                <div className={props.currentPage == 1 ? '' : 'd-none'}>
                    <div className="row mb-24">
                        {/* <div className="col-md-4">
                            <DynamicEditinput
                                inputKey="area"
                                heading="Customer Location"
                                placeholder="Enter Area Location"
                                editActive={editActive.area}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('area')
                                    }
                                    handleEditActive("area", activeState)
                                }}
                                isEditable={true}
                                enableEditing={true}
                                value={customerInfoData.data?.area}
                                onValueChange={(value) => handleValueChange("area", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("area", true)}
                                editDeactive={() => handleEditActive("area", false)}
                                onSave={() => {
                                    updateCustomerInfo("area", customerInfoData.data.area)
                                    handleEditActive("area", false)
                                }}
                            />
                        </div> */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="age"
                                heading="Age"
                                placeholder="Enter Age"
                                editActive={editActive.age}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('age')
                                    }
                                    handleEditActive("age", activeState)
                                }}
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={false}
                                value={customerInfoData.data?.age}
                                onValueChange={(value) => handleValueChange("age", value
                                    .replace(/^0+/g, '')
                                    .replace(/[^0-9]/g, ''))
                                }
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("age", true)}
                                editDeactive={() => handleEditActive("age", false)}
                                onSave={() => {
                                    updateCustomerInfo("age", customerInfoData.data.age, 'Age')
                                    // handleEditActive("age", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="referred_by"
                                heading="Referred By"
                                placeholder="Referred By"
                                editActive={editActive.referred_by}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseLeadInfo('referred_by')
                                    }
                                    handleEditActive("referred_by", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                value={leadInfoData && leadInfoData.data ? leadInfoData.data.referred_by : "-"}
                                onClickHandler={() => { }}
                                onValueChange={(value) => handleLeadValueChange("referred_by", value)}
                                onSave={() => {
                                    saveLeadData("referred_by", leadInfoData.data.referred_by, 'Referred By')
                                    // handleEditActive("referred_by", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="gender"
                                heading="Gender"
                                editActive={editActive.gender}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('gender')
                                    }
                                    handleEditActive("gender", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={gender_list}
                                onEditClick={() => handleEditActive("gender", true)}
                                editDeactive={() => handleEditActive("gender", false)}
                                value={selectedValue(gender_list, customerInfoData.data?.gender)}
                                onValueChange={(value) => handleValueChange("gender", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("gender", customerInfoData.data?.gender, 'Gender')
                                    // handleEditActive("gender", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <TextCalendar
                                inputKey="birthdate"
                                heading="Birthday"
                                placeholder="Birthday"
                                editActive={editActive.birthdate}
                                isEditable={true}
                                enableEditing={false}
                                value={customerInfoData && customerInfoData.data ? customerInfoData.data?.birthdate : '-'}
                                onValueChange={(value) => handleValueChange('birthdate', value)}
                                onEditClick={() => handleEditActive("birthdate", true)}
                                editDeactive={() => {
                                    handleCloseCustomerInfo('birthdate')
                                    handleEditActive("birthdate", false)
                                }}
                                onSave={() => {
                                    updateCustomerInfo("birthdate", customerInfoData.data?.birthdate, 'Birthday')
                                    // handleEditActive("birthdate", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                editActive={editActive?.preferred_location}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('preferred_location')
                                    }
                                    handleEditActive("preferred_location", activeState)
                                }}
                                heading="Preferred Location"
                                name="Preferred Location"
                                placeholder="Enter Preferred Location"
                                isEditable={true}
                                value={customerInfoData.data?.preferred_location || ''}
                                type="text"
                                onValueChange={(value) => handleValueChange("preferred_location", value)}
                                onSave={() => {
                                    updateCustomerInfo("preferred_location", customerInfoData.data.preferred_location, 'Preferred Location')
                                    // handleEditActive("preferred_location", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="industry"
                                heading="Industry"
                                editActive={editActive.industry}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('industry')
                                    }
                                    handleEditActive("industry", activeState)
                                }}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={props.customerInfo?.enum_industry}
                                onEditClick={() => handleEditActive("industry", true)}
                                editDeactive={() => handleEditActive("industry", false)}
                                value={selectedValue(props.customerInfo?.enum_industry, customerInfoData.data?.industry)}
                                onValueChange={(value) => handleValueChange("industry", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("industry", customerInfoData.data?.industry, 'Industry')
                                    // handleEditActive("industry", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="company"
                                heading="Company"
                                placeholder="Enter Company"
                                editActive={editActive.company}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('company')
                                    }
                                    handleEditActive("company", activeState)
                                }}
                                isEditable={true}
                                enableEditing={true}
                                value={customerInfoData.data?.company}
                                onValueChange={(value) => handleValueChange("company", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("company", true)}
                                editDeactive={() => handleEditActive("company", false)}
                                onSave={() => {
                                    updateCustomerInfo("company", customerInfoData.data.company, 'Company')
                                    // handleEditActive("company", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                inputKey="income"
                                heading="Annual Income"
                                editActive={editActive.income}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('income')
                                    }
                                    handleEditActive("income", activeState)
                                }}
                                placeholder="Enter Income"
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={false}
                                value={customerInfoData.data?.income}
                                onClickHandler={() => { }}
                                onValueChange={(value) => handleValueChange("income", value
                                    .replace(/^0+/g, '')
                                    .replace(/[^0-9]/g, ''))
                                }
                                onEditClick={() => handleEditActive("income", true)}
                                editDeactive={() => handleEditActive("income", false)}
                                onSave={() => {
                                    updateCustomerInfo("income", customerInfoData.data.income, 'Annual Income')
                                    // handleEditActive("income", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                inputKey="city"
                                heading="City"
                                editActive={editActive.city}
                                setEditActive={(activeState) => handleEditActive("city", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                loadOptions={loadOptions}
                                // defaultValue={customerInfoData.data?.state}
                                // defaultLabel="Delhi"
                                // onClickHandler={() => { }}
                                // optionConfig={cities}
                                onEditClick={() => handleEditActive("city", true)}
                                editDeactive={() => handleEditActive("city", false)}
                                value={customerInfoData.data?.city}
                                onValueChange={(value) => handleValueChange("city", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("city", customerInfoData.data?.city, 'City')
                                    handleEditActive("city", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                    </div>


                </div>

                {/* <div className={props.currentPage == 2 ? '' : 'd-none'}>
                    <div className="row mb-24">
                       
                        <div className="col">
                            <DynamicDropdown
                                inputKey="relationship"
                                heading="Relationship"
                                editActive={editActive.relationship}
                                setEditActive={(activeState) => handleEditActive("relationship", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={customerInfoData.data?.relationship}
                                // defaultLabel="Married"
                                value={selectedValue(relationship_list, customerInfoData.data?.relationship)}
                                onValueChange={(value) => handleValueChange("relationship", value.value)}
                                optionConfig={relationship_list}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("relationship", true)}
                                editDeactive={() => handleEditActive("relationship", false)}
                                onSave={() => {
                                    updateCustomerInfo("relationship", customerInfoData.data?.relationship)
                                    handleEditActive("relationship", false)
                                }}
                            />
                        </div>                                               
                    </div>
                    <div className="row mb-24">                                               
                        <div className="col">
                            <DynamicDropdown
                                inputKey="state"
                                heading="State"
                                editActive={editActive.state}
                                setEditActive={(activeState) => handleEditActive("state", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={customerInfoData.data?.state}
                                // defaultLabel="Delhi"
                                // onClickHandler={() => { }}
                                optionConfig={statesDropDown}
                                onEditClick={() => handleEditActive("state", true)}
                                editDeactive={() => handleEditActive("state", false)}
                                value={selectedValue(statesDropDown, customerInfoData.data?.state)}
                                onValueChange={(value) => handleValueChange("state", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("state", customerInfoData.data?.state)
                                    handleEditActive("state", false)
                                }}
                            />
                        </div>
                        <div className="col">
                            <DynamicDropdown
                                inputKey="city"
                                heading="City"
                                editActive={editActive.city}
                                setEditActive={(activeState) => handleEditActive("city", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={customerInfoData.data?.state}
                                // defaultLabel="Delhi"
                                // onClickHandler={() => { }}
                                optionConfig={cities}
                                onEditClick={() => handleEditActive("city", true)}
                                editDeactive={() => handleEditActive("city", false)}
                                value={selectedValue(cities, customerInfoData.data?.city)}
                                onValueChange={(value) => handleValueChange("city", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("city", customerInfoData.data?.city)
                                    handleEditActive("city", false)
                                }}
                            />
                        </div>                                                                   
                    </div>
                    <div className="row mb-24"> 
                        <div className="col">
                            <DynamicEditinput
                                inputKey="zip_code"
                                heading="Pincode"
                                placeholder="Enter Pincode"
                                editActive={editActive.zip_code}
                                setEditActive={(activeState) => handleEditActive("zip_code", activeState)}
                                isEditable={true}
                                enableEditing={true}
                                value={customerInfoData.data?.zip_code}
                                onValueChange={(value) => handleValueChange("zip_code", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("zip_code", true)}
                                editDeactive={() => handleEditActive("zip_code", false)}
                                onSave={() => {
                                    updateCustomerInfo("zip_code", customerInfoData.data.zip_code)
                                    handleEditActive("zip_code", false)
                                }}
                            />
                        </div>
                        <div className="col">
                            <DynamicDropdown
                                inputKey="country"
                                heading="Country"
                                editActive={editActive.country}
                                setEditActive={(activeState) => handleEditActive("country", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={customerInfoData.data?.country}
                                // defaultLabel="India"
                                optionConfig={countriesDropdown}
                                // onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("country", true)}
                                editDeactive={() => handleEditActive("country", false)}
                                value={selectedValue(countriesDropdown, customerInfoData.data?.country)}
                                onValueChange={(value) => handleValueChange("country", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("country", customerInfoData.data?.country)
                                    handleEditActive("country", false)
                                }}
                            />
                        </div>
                    </div>

                </div> */}


                {/* Page - 3 */}
                {/* <div className={props.currentPage == 3 ? '' : 'd-none'}>
                   
                    <div className="row mb-24">
                        <div className="col">
                            <DynamicDropdown
                                inputKey="time_to_buy"            // ignore key as it's not getting used
                                heading="Time to Buy"
                                editActive={editActive.time_to_buy}
                                setEditActive={(activeState) => handleEditActive("time_to_buy", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                optionConfig={time_to_buy_list}
                                onEditClick={() =>  handleEditActive("time_to_buy", true)}
                                editDeactive={() => handleEditActive("time_to_buy", false)}
                                value={selectedValue(time_to_buy_list, `${customerInfoData.data?.min_possession_date}-${customerInfoData.data?.max_possession_date}`)}
                                onValueChange={(value) =>{
                                    const splitedTime = value.value.split('-')                                   
                                    setCustomerInfoData({ ...customerInfoData, data: {...customerInfoData.data, 'min_possession_date': splitedTime[0],  'max_possession_date': splitedTime[1]} })
                                    }}
                                onSave={() => onSaveTimeToBuy()}
                            />
                        </div>
                        
                        <div className="col">
                            <DynamicEditinput
                                inputKey="bathroom_preferences"
                                heading="Bathroom Pref."
                                placeholder="Enter Pre."
                                editActive={editActive.bathroom_preferences}
                                setEditActive={(activeState) => handleEditActive("bathroom_preferences", activeState)}
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={false}
                                value={customerInfoData.data?.bathroom_preferences}
                                onValueChange={(value) => handleValueChange("bathroom_preferences", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("bathroom_preferences", true)}
                                editDeactive={() => handleEditActive("bathroom_preferences", false)}
                                onSave={() => {
                                    updateCustomerInfo("bathroom_preferences", customerInfoData.data.bathroom_preferences)
                                    handleEditActive("bathroom_preferences", false)
                                }}
                            />
                        </div>
                         <div className="col">
                            <AreaInput
                                inputKey="super_area"
                                inputKey2="super_area_unit"
                                heading="Super Area"
                                placeholder="Enter Super Area"
                                isEditable={true}
                                inputType={'number'}
                                enableEditing={false}
                                value={customerInfoData.data?.super_area}
                                value2={customerInfoData.data?.carpet_area}
                                onClickHandler={() => { }}
                                editActive={editActive}
                                onEditClick={() => handleEditActive("super_area", true)}
                                editDeactive={() => handleEditActive("super_area", false)}
                            />
                        </div>
                        <div className="col">
                            <DynamicDropdown
                                inputKey="transaction_type"
                                heading="Transaction Type"
                                editActive={editActive.transaction_type}
                                setEditActive={(activeState) => handleEditActive("transaction_type", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={""}
                                // defaultLabel={this.props.customer?.furnishing?.display_name}
                                // defaultLabel={""}
                                optionConfig={props.customerInfo?.enum_transaction_type}
                                // onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("transaction_type", true)}
                                editDeactive={() => handleEditActive("transaction_type", false)}
                                value={selectedValue(props.customerInfo?.enum_transaction_type, customerInfoData.data?.transaction_type)}
                                onValueChange={(value) => handleValueChange("transaction_type", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("transaction_type", customerInfoData.data?.transaction_type)
                                    handleEditActive("transaction_type", false)
                                }}
                            />
                        </div>
                    </div>
                </div> */}

            </div>

        </>
    )
}
