import React from 'react'

const StatsCard = ({ statsType, statsCount, handleFilter, selectedStats }) => {

    return (
        <div
            className={`  border rounded-md p-2 cursor-pointer ${selectedStats === statsType ? 'border-primary' : 'border-grayLight'}`}
            onClick={() => handleFilter(statsType)}
        // key={`${stat.uuid}_${index}`}
        >
            <h4 className='m-0'>{statsCount || '-'}</h4>
            <p className='m-0 text-primary'>{statsType || '-'}</p>
        </div>
    )
}

export default StatsCard