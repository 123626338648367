
import React from "react"
import { Modal } from "react-bootstrap"
import SmallCenterModal from "./small-center-modal";


const FormMapingRulesModal = ({ show, closeModal }) => {
    return (
        <div>
            <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'unprocessed-leads FormMapingRulesModal'}>
                <div className=''>
                    <figure className='mb-24'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                            <rect width="48" height="48" transform="translate(1)" fill="white" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" fill="#FBBC05" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" fill="url(#paint0_linear_7691_129032)" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" fill="url(#paint1_linear_7691_129032)" fillOpacity="0.2" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" stroke="#FBBC05" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" stroke="url(#paint2_linear_7691_129032)" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M22.0175 5.32146C23.3954 3.17965 26.5265 3.17965 27.9044 5.32146L47.4718 35.7371C48.9703 38.0663 47.2979 41.1307 44.5283 41.1307H5.39357C2.62396 41.1307 0.951611 38.0663 2.45008 35.7371L22.0175 5.32146Z" stroke="url(#paint3_linear_7691_129032)" strokeOpacity="0.2" strokeWidth="2.26374" strokeLinejoin="round" />
                            <path d="M23 14.9V26.9C23 28.2255 24.0745 29.3 25.4 29.3C26.7255 29.3 27.8 28.2255 27.8 26.9V14.9C27.8 13.5745 26.7255 12.5 25.4 12.5C24.0745 12.5 23 13.5745 23 14.9Z" fill="white" />
                            <path d="M27.8 34.1C27.8 35.4255 26.7255 36.5 25.4 36.5C24.0745 36.5 23 35.4255 23 34.1C23 32.7745 24.0745 31.7 25.4 31.7C26.7255 31.7 27.8 32.7745 27.8 34.1Z" fill="white" />
                            <defs>
                                <linearGradient id="paint0_linear_7691_129032" x1="24.9609" y1="0.746094" x2="24.998" y2="68.5" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF974A" stopOpacity="0" />
                                    <stop offset="1" stopColor="#FF974A" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_7691_129032" x1="24.9609" y1="0.746094" x2="24.9609" y2="54.5923" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_7691_129032" x1="24.9609" y1="0.746094" x2="24.9609" y2="54.5923" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FF974A" stopOpacity="0" />
                                    <stop offset="1" stopColor="#FDA82C" />
                                </linearGradient>
                                <linearGradient id="paint3_linear_7691_129032" x1="24.9609" y1="0.746094" x2="24.9609" y2="54.5923" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </figure>
                    <h1 className='fz20 fw-po-medium black mb-12 po'>Form Mapping Rules</h1>
                    <ul>
                        <li>Map at least one Facebook form(s) to your projects in the Propacity Platform.</li>
                        <li>Map Marketing agency for the facebook forms.</li>
                        <li>You will receive leads from unmapped forms as well if you do not disable “ Receive unmapped leads “ toggle.</li>
                    </ul>

                    <div className='d-flex justify-content-center mt-36'>
                        <button className="pr-btn w-100 text-center py-10 fz16" onClick={closeModal}>Got it</button>
                    </div>
                </div>
            </ SmallCenterModal >
        </div >
    )
}

export default FormMapingRulesModal
