import React from 'react'
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import Secure from '../Secure';

const EmailIntegration = ({ updateStep, response, profileId, closeModal, selectedProfileId, mainSentTo, mailSubject, apiUrl, emailFor, profile }) => {

    const copyEmail = (email) => {
        navigator.clipboard.writeText(email).then(function () {
            toast.success('To Copied');
        }, function (err) {
            toast.error('Could not copy To');
        });
    };

    const copySubject = (subject) => {
        navigator.clipboard.writeText(subject).then(function () {
            toast.success('Subject Copied');
        }, function (err) {
            toast.error('Could not copy Subject');
        });
    };

    const copyBody = (body) => {
        navigator.clipboard.writeText(body).then(function () {
            toast.success('Body Copied');
        }, function (err) {
            toast.error('Could not copy Body');
        });
    };

    const copyText = useMemo(() => `
    Hi,

    Please integrate my ${emailFor} platform with Propacity Platform with 
    
    ${profile}: ${profileId}

    Project: ${response.projects.join(', ')}

    Also, use the below code snippet for the integration :
    
    HTTP Method: POST
    
    API URL: ${`${apiUrl}/${response.key}/insertLead`}
    
    Payload: {
        name: "Test Lead",
        email: "test_lead@test.com",
        mobile: "+910000000000",
        locality: "Test",
        project_name: "Test project",
        date: "dd/mm/yyyy"   
    }

    Thanks!`, [profileId, response]);

    const acresCopyText = useMemo(() => `
    Hi,

    Please integrate my ${emailFor} platform with Propacity Platform with 
    
    ${profile}: ${profileId}

    Project: ${response.projects.join(', ')}

    Also, use the below code snippet for the integration :
    
    HTTP Method: POST
    
    API URL: ${`${apiUrl}/${response.key}/insertLead`}
    
    Payload: {
        name: "Test Lead",
        email: "test_lead@test.com",
        mobile: "+910000000000",
        locality: "Test",
        project_name: "Test project",
        date: "dd/mm/yyyy",
        compact_label:'Listing name',
        city:'City Name',
        query:'Lead query'
    }

    Thanks!`, [profileId, response]);

    const copyMagicbricksText = useMemo(() => `
    Hi,

    Please integrate my Magicbricks platform with Propacity Platform 
 
    1. CET client or not : (To be filled by your Key Account Manager)

    2. CRM service provider name: Propacity Platform

    3. Confirm Integration type: PUSH

    4. Login Id of user's account: ${profileId}

    5. Working Endpoint: POST

    6. Sample URL:${`${apiUrl}/${response.key}/insertLead`}
    
    7. API Key: ${response.key}
    
    Payload: {
        name: "Test Lead",
        email: "test_lead@test.com",
        mobile: "+910000000000",
        locality: "Test",
        project_name: "Test project",
        date: "dd/mm/yyyy"
    }

    Project: ${response.projects.join(', ')}

    Thanks!`, [profileId, response])

    return (
        <>
            <div className="body-modal send-mail">
                <div className="card mw-100 w-100">
                    <div className="card-header d-flex align-items-start">
                        <figure className='mb-0'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                                <path d="M4.75 19.0625H17.25C18.2446 19.0625 19.1983 18.6675 19.9016 17.9643C20.605 17.2609 21 16.3072 21 15.3125V4.6875C21 3.69286 20.605 2.73917 19.9016 2.03571C19.1983 1.33248 18.2447 0.9375 17.25 0.9375H4.75C3.75536 0.9375 2.80167 1.33248 2.09844 2.03571C1.39503 2.73912 1 3.6928 1 4.6875V15.3125C1 16.3071 1.39498 17.2608 2.09844 17.9643C2.80167 18.6675 3.7553 19.0625 4.75 19.0625ZM3.50011 4.6875C3.50011 4.35595 3.63177 4.03789 3.8661 3.80357C4.10065 3.56924 4.41847 3.43736 4.75003 3.43736H17.25C17.5816 3.43736 17.8994 3.56924 18.134 3.80357C18.3683 4.0379 18.5 4.35594 18.5 4.6875V5.24989L12.5407 9.50619C12.0888 9.82161 11.5511 9.99099 11 9.99099C10.449 9.99099 9.91117 9.82161 9.45933 9.50619L3.50007 5.24989L3.50011 4.6875ZM3.50011 8.32193L8.00647 11.5407H8.00625C8.87969 12.1648 9.92646 12.5002 11 12.5002C12.0736 12.5002 13.1203 12.1648 13.9938 11.5407L18.4999 8.32193V15.3124C18.4999 15.644 18.3682 15.9621 18.1339 16.1964C17.8994 16.4307 17.5815 16.5626 17.25 16.5626H4.74997C4.41842 16.5626 4.10059 16.4307 3.86604 16.1964C3.63171 15.962 3.50005 15.644 3.50005 15.3124L3.50011 8.32193Z" fill="#171725" stroke="#FAFAFB" strokeWidth="0.5" />
                            </svg>
                        </figure>

                        <div>
                            <h1 className='fz18 fw-po-semibold mb4 black d-md-none'>Copy the email format below and send it via your registered email address to <span className='text-lowercase'>{emailFor}</span>.</h1>
                            <h1 className='fz18 fw-po-semibold mb4 black d-md-block d-none'>Send Email via your registered <span className='text-lowercase'>{emailFor}</span> email to complete integration</h1>
                            <p className='fz14 mb-0 black-dark-700'>Copy the email format (to, subject & body) below, send email and click on confirm</p>
                        </div>
                    </div>
                    <div className="card-body pr-0 py-0">
                        <div className="copy-content">
                            <div className="box">
                                <div className="row">
                                    <div className="col-left">To</div>
                                    <div className="col-center"><p className='mb-0'>{mainSentTo}</p></div>
                                    <div className="col-right">
                                        <button onClick={() => copyEmail(mainSentTo)} className='flex items-center p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className='inline' width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D" />
                                            </svg>
                                            Copy</button></div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="row">
                                    <div className="col-left">Subject</div>
                                    <div className="col-center"><p className='mb-0'>{mailSubject}</p></div>
                                    <div className="col-right">
                                        <button onClick={() => copySubject(mailSubject)} className='flex items-center p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className='inline' width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D" />
                                            </svg>Copy</button></div>
                                </div>

                            </div>
                            {emailFor === '99 Acres' && <div className="box custom-mailbox">
                                <div className="row mb-4">
                                    <div className="col-left">Body</div>
                                    <div className="col-center">
                                        <p>Hi,</p>
                                        <p>Please integrate my {emailFor} platform with Propacity Platform with {profile} : {profileId}</p>
                                        <p>Project : {response.projects.join(', ')}</p>
                                        <p>Also, use the below code snippet for the integration :</p>
                                        <p>HTTP Method:POST </p>
                                        <p>API URL: {`${apiUrl}/${response.key}/insertLead`}</p>
                                        <p>Payload:&#123; </p>
                                        <p>name: "Test Lead",</p>
                                        <p>email: "test_lead@test.com",</p>
                                        <p>mobile: "+910000000000",</p>
                                        <p>locality: "Test",</p>
                                        <p>project_name: "Test project",</p>
                                        <p>date: "dd/mm/yyyy"</p>
                                        <p>compact_label: "Listing name",</p>
                                        <p>city: "City name",</p>
                                        <p>query:"Lead query"</p>
                                        <p>&#125;</p>
                                        <p>Thanks!</p>
                                    </div>
                                    <div className="col-right">
                                        <button onClick={() => copyBody(acresCopyText)} className='flex items-center p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className='inline' width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D" />
                                            </svg>Copy</button></div>
                                </div>
                            </div>}
                            {emailFor === 'Housing.com' && <div className="box custom-mailbox">
                                <div className="row mb-4">
                                    <div className="col-left">Body</div>
                                    <div className="col-center">
                                        <p>Hi,</p>
                                        <p>Please integrate my {emailFor} platform with Propacity Platform with {profile} : {profileId}</p>
                                        <p>Project : {response.projects.join(', ')}</p>
                                        <p>Also, use the below code snippet for the integration :</p>
                                        <p>HTTP Method:POST </p>
                                        <p>API URL: {`${apiUrl}/${response.key}/insertLead`}</p>
                                        <p>Payload:&#123; </p>
                                        <p>name: "Test Lead",</p>
                                        <p>email: "test_lead@test.com",</p>
                                        <p>mobile: "+910000000000",</p>
                                        <p>locality: "Test",</p>
                                        <p>project_name: "Test project",</p>
                                        <p>date: "dd/mm/yyyy"</p>
                                        <p>&#125;</p>
                                        <p>Thanks!</p>
                                    </div>
                                    <div className="col-right">
                                        <button onClick={() => copyBody(copyText)} className='flex items-center p-1'>

                                            <svg xmlns="http://www.w3.org/2000/svg" className='inline' width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D" />
                                            </svg>

                                            Copy</button></div>
                                </div>
                            </div>}
                            {emailFor === 'Magicbricks' && <div className="box custom-mailbox">
                                <div className="row mb-4">
                                    <div className="col-left">Body</div>
                                    <div className="col-center">
                                        <p>Hi,</p>
                                        <p>Please integrate my Magicbricks platform with Propacity Platform</p>
                                        <p className='mt-3'>1. CET client or not : (To be filled by your Key Account Manager)</p>
                                        <p className='mt-3'>2. CRM service provider name: Propacity Platform</p>
                                        <p className='mt-3'>3. Confirm Integration type: PUSH</p>
                                        <p className='mt-3'>4. Login Id of user's account: {profileId}</p>
                                        <p className='mt-3'>5. Working Endpoint: POST</p>
                                        <p className='mt-3'>6. Sample URL:{`${apiUrl}/${response.key}/insertLead`}</p>
                                        <p className='mt-3'>7. API Key: {response.key}</p>

                                        <p>Payload:&#123; </p>
                                        <p>name: "Test Lead",</p>
                                        <p>email: "test_lead@test.com",</p>
                                        <p>mobile: "+910000000000",</p>
                                        <p>locality: "Test",</p>
                                        <p>project_name: "Test project",</p>
                                        <p>date: "dd/mm/yyyy"</p>
                                        <p>&#125;</p>
                                        <p>Project : {response.projects.join(', ')}</p>
                                        <p>Thanks!</p>
                                    </div>
                                    <div className="col-right">
                                        <button onClick={() => copyBody(copyMagicbricksText)} className='flex items-center p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className='inline' width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D" />
                                            </svg>Copy</button></div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="footer-modal d-flex align-items-center justify-content-center">
                    {/* <button className="outline-btn fz16 py-8 px-40 mr-24">Back</button> */}
                    <button className='pr-btn fz16 py-8 px-40' onClick={() => {
                        if (selectedProfileId) {
                            closeModal();
                        } else {
                            updateStep(3)
                        }
                    }}
                    >
                        {selectedProfileId ? 'Close' : 'Confirm'}
                    </button>

                </div>
            </div>
            <Secure />
        </>
    )
}
export default EmailIntegration;