import React, { useState } from 'react';
import BasicModal from '../../../../../../components/Modals/BasicModal'
import { ReactComponent as NavRight } from '../../../../../../assets/icons/NavRight.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import InputText from '../../../../../../components/InputGroup/InputText';
import InputSelect from '../../../../../../components/InputGroup/InputSelect';
import UserMaping from './UserMaping';
import IVRDetails from './IVRDetails';
import CallerIdMapping from './CallerIdMapping';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const NewIntegration = ({
    show,
    closeModal,
    lead,
    onSuccess,
    data,
    ivrName,
    handleCreateIntegration,
    handleSaveUserMapping,
    formSteps,
    setFormSteps,
    selectedIntegration,
    handleUpdateIntegration,
    useMappingData,
    getUserMapping,
    handleSaveCallerMapping,
    getCallerIdMapping,
    callerIdMappingData
}) => {

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='pt-6 relative'>
                    <div className='sm:mx-6 mx-3 flex items-center justify-between'>
                        <h1 className=' text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-4 inter'>
                            {Object.keys(selectedIntegration).length > 0 ? 'Edit ' : 'New '}
                            Integration
                        </h1>
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                    <div className='flex items-center no-scrollbar border-b border-[#E2E2EA]'>
                        <ul className='flex gap-x-3 items-center mb-0 pb-2  md:!px-0 lg:w-[100%] sm:w-[90%] w-[80%] md:!mx-5  !mx-auto no-scrollbar lg:overflow-hidden overflow-auto'>
                            <li
                                className="flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 cursor-pointer"
                                onClick={() => {
                                    if (Object.keys(selectedIntegration).length > 0) {
                                        setFormSteps('basic')
                                    }
                                }}
                            >
                                <span className={`${formSteps === 'basic' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}
                                >
                                    IVR Details
                                </span>
                                <NavRight />
                            </li>
                            <li
                                className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 cursor-pointer'
                                onClick={() => {
                                    if (Object.keys(selectedIntegration).length > 0) {
                                        getUserMapping(selectedIntegration.integration.uuid);
                                        // setFormSteps('mapping')
                                    }
                                }}
                            >
                                <span
                                    className={`${formSteps === 'mapping' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}
                                >
                                    User Mapping
                                </span>
                                <NavRight />
                            </li>

                            <li
                                className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 cursor-pointer'
                                onClick={() => {
                                    if (Object.keys(selectedIntegration).length > 0) {
                                        getCallerIdMapping(selectedIntegration.integration.uuid);
                                        // setFormSteps('caller')
                                    }
                                }}
                            >
                                <span
                                    className={`${formSteps === 'caller' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}
                                >
                                    Click To Call
                                </span>
                            </li>

                        </ul>
                    </div>
                </div>
                {
                    formSteps === 'basic' &&
                    <IVRDetails
                        closeModal={closeModal}
                        ivrName={ivrName}
                        handleCreateIntegration={handleCreateIntegration}
                        selectedIntegration={selectedIntegration}
                        handleUpdateIntegration={handleUpdateIntegration}
                    />
                }
                {
                    formSteps === 'mapping' &&
                    <UserMaping
                        setSteps={setFormSteps}
                        ivrName={ivrName}
                        handleSaveUserMapping={handleSaveUserMapping}
                        useMappingData={useMappingData}
                    />
                }
                {
                    formSteps === 'caller' &&
                    <CallerIdMapping
                        setSteps={setFormSteps}
                        ivrName={ivrName}
                        handleSaveCallerMapping={handleSaveCallerMapping}
                        callerIdMappingData={callerIdMappingData}
                    />
                }

            </div>

        </BasicModal >
    )
}

export default NewIntegration
