import React, { useRef } from "react";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { createRoles, deletedRoleByUUID, getAddedUsers, getColumnPreferenceNew, getMasterRoles, getPermissions, getRoleDetail, getRoles, saveColumnPreferenceNew, setPermissions, updateRoleDetails, updateRolesPermissions } from "../../../../services/private/company.service"
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as ResetIcon } from '../../../../assets/icons/reset.svg';
import ResetPermission from "./Modal/ResetPermission";
import { ReactComponent as BackIcon } from '../../../../assets/icons/leftArrow.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../assets/icons/searchClose.svg';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import TooltipText from "../../../../components/Tooltip";
import { ReactComponent as Vthreedots } from '../../../../assets/icons/threedots.svg';
import CustomColumnModal from "../../../../components/Modals/CustomColumnModal";
import ConfirmDeletion from "../../../../components/Modals/ConfirmDeletion";
import WarningModal from "./Modal/WarningModal";
import CopyPermissionModal from "./Modal/CopyPermissionModal";
import './Roles.css'

const ListPermissions = () => {
    // const [roles, setRoles] = useState([]);    
    const [modulePermissions, setModulePermissions] = useState({});
    const [allPermission, setAllPermission] = useState();
    const [searchText, setSearchText] = useState('');
    const [allowedPermissions, setAllowedPermissions] = useState([]);
    const [initialPermissions, setInitialPermissions] = useState([]);
    const [showResetPermissionModal, setShowResetPermissionModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [showCustomiseColumnsModal, setShowCustomiseColumnsModal] = useState(false);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [roleId, setRoleId] = useState();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const [users, setUsers] = useState([]);
    const [newRoleName, setNewRoleName] = useState('');
    const [editRoleName, setEditRoleName] = useState(false);
    const [masterRoles, setMasterRoles] = useState([]);
    const [showCopyPermissionModal, setShowCopyPermissionModal] = useState(false);
    const [selectedRoleForPermission, setSelectedRoleForPermission] = useState({});

    const headingRefs = useRef([]);

    const history = useHistory();
    const location = useLocation();
    const { uuid } = useParams();

    const searchParams = new URLSearchParams(location.search);
    const roleParam = searchParams.get('role');
    const redirectedPage = searchParams.get('p');
    const roleName = searchParams.get('name');
    const roleDescription = searchParams.get('description');
    const redirectFrom = searchParams.get('from');
    const restricted = searchParams.get('restricted');
    const pageUuid = searchParams.get('pageuuid')

    const getRolesData = async () => {
        await getRoles().then((res) => {
            if (res.status === 200) {
                setRoles(res.data.data.filter(i => i.name !== 'Super Admin'));
                // setAllRoles(res.data.data);
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        })
    }

    const getAllMasterRoles = async () => {
        const res = await getMasterRoles()
        if (res.data.status === 200) {
            const masterRole = res.data.data.filter(item => item.name !== 'Super Admin');
            setMasterRoles(masterRole)
        }
    }

    const handleDeleteRole = async () => {
        const res = await deletedRoleByUUID(selectedRole.uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getRolesData();
            setShowConfirmDeleteModal(false);
        }
    }

    const getMaterPermissions = async () => {
        const res = await getPermissions(searchText)
        if (res.data.status === 200) {
            setInitialPermissions(res.data.data);

        }
    }

    const getRolesDetail = async () => {
        const res = await getRoleDetail(uuid);
        if (res.data.status === 200) {
            if (res.data.data.allowed_permissions) {
                setAllowedPermissions(res.data.data.allowed_permissions)
            }
        }

    }

    useEffect(() => {
        // getMaterPermissions();
        // getRolesDetail();
        if (searchText) {
            let module = {}
            for (const [key, value] of Object.entries(allPermission)) {
                let val = []
                value.forEach((item, index) => {
                    const newPermission = item.permissions.filter(perm => perm.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    if (newPermission?.length > 0) {
                        const newItem = { ...item, permissions: newPermission }
                        val.push(newItem)
                        // value[index] = newItem
                        module[key] = val
                    }

                })

            }
            setModulePermissions(module);
        } else {
            setModulePermissions(allPermission);
        }
    }, [searchText]);

    useEffect(() => {
        getMaterPermissions();
        if (uuid) {
            getRolesDetail()
        }
    }, [uuid])

    useEffect(() => {
        if (localStorage.getItem('allowedPermissions')) {
            setAllowedPermissions(JSON.parse(localStorage.getItem('allowedPermissions')))
        }
        getRolesData();
        getAllMasterRoles();
    }, [])

    useEffect(() => {
        if (editRoleName && selectedRole)
            document.getElementById(`${selectedRole.name}_input`).focus();
    }, [editRoleName, selectedRole]);

    const addDefaultPermissions = () => {
        let module = {}
        let currentSubOModule = '';
        initialPermissions.forEach((item) => {
            let is_sub_module = false;
            let tempPermission = {
                name: item.name,
                value: item.value,
                description: item.description,
                linked_permissions: item.linked_permissions,
                // is_selected: allowedPermissions?.includes(item.value) || false
                // is_selected: false
            };

            if (!module[item.module]) {
                // Adding new module
                module[item.module] = [];
                // adding new sub module
                currentSubOModule = item.sub_module;
                if (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)) {
                    // is_sub_module = true;
                    is_sub_module = false;
                }
                let temp = {
                    subModule: item.sub_module,
                    is_sub_module: is_sub_module,
                    showChildern: false,
                    permissions: [tempPermission]
                }
                module[item.module].push(temp);

            } else {
                if (item.sub_module != currentSubOModule) {
                    // adding new sub module
                    currentSubOModule = item.sub_module;
                    if (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)) {
                        // is_sub_module = true;
                        is_sub_module = false;

                    }
                    let temp = {
                        subModule: item.sub_module,
                        is_sub_module: is_sub_module,
                        showChildern: false,
                        permissions: [tempPermission]
                    }
                    module[item.module].push(temp);
                } else {
                    // adding permission
                    const index = module[item.module].findIndex(md => md.subModule === currentSubOModule)
                    if (is_sub_module || (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value))) {
                        // module[item.module][index].is_sub_module = is_sub_module || item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)
                        module[item.module][index].is_sub_module = false;
                    }
                    module[item.module][index].permissions.push(tempPermission);
                }
            }

        })

        setModulePermissions(module);
        setAllPermission(module);
        setShowResetPermissionModal(false)
    }

    useEffect(() => {
        if (initialPermissions || allowedPermissions) {
            addDefaultPermissions();
        }
    }, [allowedPermissions, initialPermissions]);

    const getColumnPreferenceByRoleId = (roleUUID) => {
        getColumnPreferenceNew(roleUUID, 'customise_column').then(res => {
            if (res.data.status === 200 && res.data.data?.length > 0) {
                let dashboardColumns = [];
                setShowCustomiseColumnsModal(true)
                res.data.data.forEach(items => {
                    let columnsList = [];
                    let selectedColumn = [];
                    items.columns.forEach(column => {
                        if (column.is_selected) {
                            selectedColumn.push(column);
                        }

                        if (!column.is_selected) {
                            columnsList.push(column);
                        }
                    })

                    dashboardColumns.push({
                        tab: items.tab,
                        columns: {
                            selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                            notSelected: columnsList
                        }
                    })

                })
                setDashboardColumns(dashboardColumns)
            }

        }).catch(err => {
            console.log(err.response, 'Error');
            toast.warning(err.response.data.message)
        })
    }

    const handleGetUsersByUuid = async (uuid) => {
        const res = await getAddedUsers(uuid);
        if (res.data.status === 200) {
            setUsers(res.data.data)
            setShowConfirmationModal(true);
        }
    }
    /**
  * Updating re-arranged list items
  */
    const updateColumnList = useCallback((tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    }, [dashboardColumns]);

    const handleChangeSelection = useCallback((tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        setDashboardColumns([...columnClone]);
    }, [dashboardColumns]);

    const saveCurrentUserColumnPrefrence = useCallback(() => {
        const preference = dashboardColumns.map(items => {
            items.columns.selected.forEach((item, index) => {
                item.placement = index + 1;
            })
            return {
                tab: items.tab,
                columns: [...items.columns.selected, ...items.columns.notSelected]
            }
        })
        saveColumnPreferenceNew({ role_id: roleId, preference }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                setShowCustomiseColumnsModal(false);
            }
        }).catch(err => {
            console.log({ err })
        })

    }, [dashboardColumns]);

    const handleSubmitCopyPermission = (permission) => {
        let newRoles = [...roles]
        const index = newRoles.findIndex(i => selectedRoleForPermission.uuid === i.uuid);
        newRoles[index] = { ...newRoles[index], allowed_permissions: permission }
        setRoles([...newRoles]);
        setSelectedRoleForPermission({})
    }

    const scrollToNewHeading = () => {
        if (headingRefs.current[roles?.length - 1]) {
            headingRefs.current[roles?.length - 1].scrollIntoView({ behavior: 'smooth' });
        }
    };


    const handlesubmit = async () => {
        const payload = roles.map(i => ({ role_uuid: i.uuid, permissions: i.allowed_permissions }))
        /**
         * New api added for update permiasion 8 june 25
         */
        const res = await updateRolesPermissions(payload);

        if (res.data.status === 200) {
            toast.success(res.data.message);
            localStorage.removeItem('allowedPermissions');
            // redirect page based on from its navigate
            if (redirectedPage === 'detail') {
                history.push({
                    pathname: `/settings/user-roles/roles/detail/${pageUuid}`,
                    search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                })
            } else {
                history.push('/settings/user-roles/roles')
            }
        }
        // else {
        //     history.push({
        //         pathname: `/settings/user-roles/roles/new/invite-user/${213423}`,
        //         // state: { name: roleName, description: roleDescription },
        //         // search: `?role=param&from=detail&name=name&description=description`,
        //     })
        // }
        // } 
        else {
            console.log(res.data.message)
        }
    }


    const handleChangeRolePermissions = (e, permission, uuid) => {
        e.stopPropagation()
        let newRoles = [...roles];
        const index = newRoles.findIndex(i => i.uuid === uuid);
        if (newRoles[index]?.allowed_permissions) {
            if (newRoles[index]?.allowed_permissions?.includes(permission)) {
                let removeIndex = newRoles[index]?.allowed_permissions.findIndex(i => i === permission)
                newRoles[index]?.allowed_permissions.splice(removeIndex, 1)
            } else {
                newRoles[index].allowed_permissions.push(permission)
            }

        } else {
            newRoles[index].allowed_permissions = [permission]
        }
        setRoles([...newRoles])
    }
    const handleChangeRoleName = async (e) => {
        if (e.key === 'Enter') {
            const res = await updateRoleDetails(selectedRole.uuid, { name: newRoleName });
            if (res.data.status === 200) {
                toast.success(res.data.message);
                setSelectedRole({});
                setNewRoleName('');
                setEditRoleName(false);
                getRolesData();
            }
        }
    }


    const checkAllPermission = (subMod, role) => {
        let isSelected = false;
        if (subMod.permissions?.length) {
            isSelected = subMod.permissions.every(i => role.allowed_permissions?.includes(i.value))
        }
        return isSelected;
    }

    const handleDuplicateRole = async (index) => {
        let newRole = [...roles];
        let newItem = { ...newRole[index] }
        newItem = {
            name: `${newItem.name} copy`,
            permissions: newItem.allowed_permissions,
            description: newItem.description || ''
        }
        handleCreateRole(newItem);
    }

    const handleCreateRole = async (role) => {
        const res = await createRoles(role);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getRolesData();
            scrollToNewHeading();
        }
    }
    const handleChangeModulePermission = (e, role, module) => {
        e.stopPropagation();
        let newRoles = [...roles];
        const index = newRoles.findIndex(i => i.uuid === role.uuid);
        const permission = module.permissions.map(i => i.value);
        const isEveryValue = permission.every(i => newRoles[index].allowed_permissions?.includes(i))
        if (!isEveryValue) {
            if (!newRoles[index].allowed_permissions) {
                newRoles[index].allowed_permissions = [...permission]
            } else {
                newRoles[index].allowed_permissions.push(...permission)
            }
        } else {
            const filtered = newRoles[index].allowed_permissions.filter(i => !permission?.includes(i))
            newRoles[index].allowed_permissions = filtered
        }
        setRoles([...newRoles])
    }

    return (
        <>
            <div className="settings-title w-full">
                <nav aria-label="breadcrumb" className=''>
                    {redirectedPage === 'roles' &&
                        <ul className="flex items-center justify-start flex-row mb-8 leading-6">
                            <li className="!text-primary pr-1">
                                <Link to={{
                                    pathname: `/settings/user-roles/roles`,

                                }}>Roles </Link>
                            </li>
                            <li className="flex items-center !text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                </svg>
                                <span className="pl-2 pr-1">Assign Permissions</span>
                            </li>

                        </ul>}
                    {
                        redirectedPage === 'detail' &&
                        <>
                            <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                                <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                                    <li>
                                        <Link to='/settings/user-roles/roles'>Roles</Link>
                                    </li>
                                    <li className='flex items-center'>
                                        <RightArrow />
                                        <Link to={{
                                            pathname: `/settings/user-roles/roles/detail/${pageUuid}`,
                                            search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                        }}>{roleName} </Link>

                                    </li>
                                    {/* <li className='flex items-center'><RightArrow />
                                        Edit {roleName}  Permissions
                                    </li> */}
                                </ul>
                            </nav>
                        </>
                    }
                    {!redirectedPage && !roleParam && !redirectFrom &&
                        <ul className="flex items-center justify-start flex-row mb-8 leading-6">
                            <li className="text-primary pr-1">
                                <Link to='/settings/user-roles/roles/new'>Create New Role </Link>
                            </li>
                            <li className="flex items-center text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                </svg>
                                <span className="pl-2 pr-1">Assign Permissions</span>
                            </li>
                            {/* <li className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                </svg>
                                <span className="pl-2">Invite / Add Users</span>
                            </li> */}
                        </ul>
                    }
                </nav>
                <div className="w-100 flex">
                    <div className="w-1/2">
                        <h3 className="text-2xl !text-black font-medium flex items-center !gap-x-2 m-0">
                            <a
                                onClick={() => {
                                    if (redirectedPage === 'detail') {
                                        history.push({
                                            pathname: `/settings/user-roles/roles/detail/${pageUuid}`,
                                            search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                        })
                                        return;
                                    }
                                    if (redirectedPage === 'roles') {
                                        history.push('/settings/user-roles/roles')
                                        return;
                                    }
                                    if (redirectedPage === 'new') {
                                        history.push(
                                            {
                                                pathname: `/settings/user-roles/roles/new`,
                                                // search: `?role=${roleParam}&from=new&name=${roleName}&description=${roleDescription}`
                                            }
                                        )
                                    }
                                }}
                                // to={{
                                //     pathname: `/settings/user-roles/roles/detail`,
                                //     // search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                // }}
                                className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                            >
                                <Pageback className="group-hover:stroke-black" />
                            </a>
                            Assign Permissions</h3>
                    </div>
                    <div className="w-1/2 flex items-start space-x-2 justify-end">

                        {/* <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                            <SearchIcon className='mr-2' />
                            <input
                                type="text"
                                placeholder='Search For Permissions'
                                className=' w-100 focus:outline-none text-sm'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText &&
                                <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                    <SearchCloseIcon />
                                </div>}
                        </div> */}
                        <div>
                            <button className="pr-btn flex !border !border-grayLight rounded !px-3 !py-1.5 text-sm font-medium leading-5 text-black700 items-center" onClick={() => {
                                const newRole = {
                                    name: `Role ${roles?.length + 1}`,
                                    description: '',
                                    permissions: []
                                }
                                handleCreateRole(newRole)
                            }
                            }>
                                {/* <ResetIcon className="mr-2" /> */}
                                + Add New Role
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col h-full overflow-auto w-full">
                <div className="w-full h-full overflow-auto ">
                    <div className="w-full h-full">
                        <div className="permission-table">
                            <div className="lead-table !h-full">
                                <table className="permission table-permission relative all_lead-table">
                                    <thead>
                                        <tr>
                                            <th className="text-left !py-2">
                                                <div className={`relative`}>
                                                    <div className="absolute left-0 top-1"><SearchIcon className='mr-2' /></div>
                                                    <input
                                                        type="text"
                                                        className="w-full h-full !pl-6 !pr-4 outline-0 bg-black100 rounded-lg flex items-center hover:border hover:border-primary focus:border focus:border-primary py-1 "
                                                        placeholder="Search for permission"
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                    />
                                                    {searchText &&
                                                        <div className="cursor-pointer absolute right-1 top-2.5" onClick={() => setSearchText('')}>
                                                            <SearchCloseIcon />
                                                        </div>}
                                                </div>
                                            </th>
                                            {
                                                roles.map((role, index) => (
                                                    <th
                                                        className={` text-left !py-2`}
                                                        key={role.uuid}
                                                        ref={(el) => (headingRefs.current[index] = el)}
                                                    >
                                                        <div className="flex justify-between dropdown view-more show">
                                                            <div className="role-text" onClick={() => {
                                                                setNewRoleName(role.name);
                                                                setSelectedRole(role);
                                                            }}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className={`w-[100px] h-full outline-0 !pl-2 rounded-lg py-1 bg-black100 `}
                                                                    placeholder="Enter Role"
                                                                    id={`${role.name}_input`}
                                                                    value={selectedRole.uuid === role.uuid ? newRoleName || role.name : role.name}
                                                                    onChange={(e) => setNewRoleName(e.target.value)}
                                                                    onKeyPress={handleChangeRoleName}
                                                                    autofocus={true}
                                                                />
                                                            </div>
                                                            {/* } */}
                                                            {/* {(selectedRole.uuid !== role.uuid) &&
                                                                <div className=""> {role.name} </div>
                                                            } */}
                                                            <div className="hover:bg-black200 rounded-lg !ml-2 w-5 flex items-center justify-center cursor-pointer" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <button className="bg-tr d-table " type="button">
                                                                    <Vthreedots className="h-4" />
                                                                </button>
                                                            </div>

                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                {/* <a
                                                                    onClick={() => {
                                                                        setNewRoleName(role.name);
                                                                        setSelectedRole(role);
                                                                        setEditRoleName(true);
                                                                    }}
                                                                    className="dropdown-item" >
                                                                    Edit Role Name
                                                                </a> */}
                                                                <a
                                                                    onClick={() => {
                                                                        handleDuplicateRole(index)
                                                                    }}
                                                                    className="dropdown-item" >
                                                                    Duplicate Role
                                                                </a>
                                                                {(!role?.allowed_permissions || role?.allowed_permissions?.length === 0) && <a
                                                                    onClick={() => {
                                                                        // handleDuplicateRole(index)
                                                                        setShowCopyPermissionModal(true);
                                                                        setSelectedRoleForPermission(role)
                                                                    }}
                                                                    className="dropdown-item" >
                                                                    Copy Permissions From
                                                                </a>}
                                                                <a
                                                                    onClick={() => {
                                                                        history.push({
                                                                            pathname: `/settings/user-roles/roles/detail/${role.uuid}`,
                                                                            search: `?name=${role.name === 'ADMIN' ? 'Admin' : role.name === 'STANDARD' ? 'Standard' : role.name}&description=${role.description}&restricted=${role?.restrict_edit}`,
                                                                        })
                                                                    }}
                                                                    className="dropdown-item" >
                                                                    View/Edit Users
                                                                </a>
                                                                {allowedPermissions.includes('customise_column_role') && <a className="dropdown-item"
                                                                    onClick={() => {
                                                                        setRoleId(role.uuid);
                                                                        getColumnPreferenceByRoleId(role.uuid)
                                                                    }}> Customise Column</a>}
                                                                <a className="dropdown-item del-menu text-capitalize"
                                                                    onClick={() => {
                                                                        setSelectedRole(role);
                                                                        if (+role.user_count === 0) {
                                                                            setShowConfirmDeleteModal(true);
                                                                        } else {
                                                                            handleGetUsersByUuid(role.uuid)
                                                                        }
                                                                    }}>
                                                                    Delete
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modulePermissions && Object.keys(modulePermissions)?.map((item, mainIndex) => (
                                            modulePermissions[item]?.map((subMod, subIndex) => (
                                                <>
                                                    {/* <tr className={`${subMod?.showChildern ? '!bg-[#0062FF0D]/10' : ''} `} key={subMod.subModule}> */}
                                                    <tr className={`${subMod?.showChildern ? '!bg-blueHighlight' : ''}`} key={subMod.subModule}>
                                                        {/* <td className={`text-left !px-2 !py-2 ${subMod?.showChildern ? '!bg-[#0062FF0D]/10' : 'bg-white'}`}> */}
                                                        {/* <td className={`text-left !px-2 !py-2`}> */}
                                                        <td className={`text-left !px-2 !py-2 ${subMod?.showChildern ? '!bg-blueHighlight' : ''}`}>
                                                            <div className="flex justify-between">
                                                                <div className="font-semibold flex items-center cursor-pointer " onClick={(e) => {
                                                                    // e.stopPropagation();
                                                                    let newModule = { ...modulePermissions };
                                                                    newModule[item][subIndex].showChildern = !newModule[item][subIndex].showChildern;
                                                                    setModulePermissions(newModule);
                                                                }}>
                                                                    <span className={`${subMod?.showChildern ? 'rotate-180' : ''} !mr-2`} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D" />
                                                                        </svg>
                                                                    </span>
                                                                    {
                                                                        subMod.subModule?.length > 30 ? <TooltipText title={subMod.subModule}>
                                                                            <span className=" w-[100px] truncate">{subMod.subModule}</span>
                                                                        </TooltipText> :

                                                                            subMod.subModule
                                                                    }
                                                                </div>
                                                                <div className="border rounded-full px-1 !h-6 !w-6 flex items-center justify-center">
                                                                    {subMod.permissions?.length > 0 && subMod.permissions?.length}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {roles.map((role, index) => (
                                                            // <td className={`text-left !py-2 ${subMod?.showChildern ? '!bg-[#0062FF0D]/5' : ''}`} key={`${role.uuid}_${index}`}>
                                                            <td className={`text-left !py-2 ${subMod?.showChildern ? '!bg-blueHighlight' : ''}`} key={`${role.uuid}_${index}`}>
                                                                <div className="flex items-center justify-center w-100 h-100">
                                                                    <input
                                                                        type="checkbox"
                                                                        name=""
                                                                        id=""
                                                                        checked={checkAllPermission(subMod, role)}
                                                                        value={checkAllPermission(subMod, role)}
                                                                        onChange={(e) => handleChangeModulePermission(e, role, subMod)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    {subMod.showChildern && subMod.permissions?.map((permission, itemIndex) => (
                                                        // <tr className="hover:!bg-[#0062FF0D]/5" key={`${permission.value}_${itemIndex}`}>
                                                        <tr className="" key={`${permission.value}_${itemIndex}`}>
                                                            <td className="text-left !px-3 !py-2 flex items-center justify-between">
                                                                <div>{permission.name}</div>
                                                                {permission.description && <TooltipText title={permission.description}>
                                                                    <span className="!w-5">
                                                                        <InfoIcon className="ml-1 h-4 w-4 opacity-60 hover:stroke-primary stroke-[#A3A9B6]" />
                                                                    </span>
                                                                </TooltipText>}
                                                            </td>
                                                            {roles.map((role, index) => (
                                                                <td className="text-left !py-2" key={`${role.name}_${index}`}>
                                                                    <div className="flex items-center justify-center w-100 h-100">
                                                                        <input
                                                                            type="checkbox"
                                                                            name=""
                                                                            id=""
                                                                            checked={role.allowed_permissions?.includes(permission.value)}
                                                                            value={role.allowed_permissions?.includes(permission.value)}
                                                                            onChange={(e) => handleChangeRolePermissions(e, permission.value, role.uuid)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}

                                                </>
                                            ))
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            searchText && Object.keys(modulePermissions)?.length === 0 &&
                            <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                    <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                            </div>
                        }

                    </div>
                </div >
                <div className={` flex flex-row items-center bg-white justify-between border-top border-color w-100 space-x-3 p-3`}>
                    <button className='border rounded-md text-black  py-2 px-[12px] flex items-center !bg-white hover:!bg-black100'
                        onClick={() => {

                            if (redirectedPage === 'detail') {
                                history.push({
                                    pathname: `/settings/user-roles/roles/detail/${pageUuid}`,
                                    search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                })
                                return;
                            }
                            if (redirectedPage === 'roles') {
                                history.push('/settings/user-roles/roles')
                                return;
                            }
                            if (redirectedPage === 'new') {
                                history.push(
                                    {
                                        pathname: `/settings/user-roles/roles/new`,
                                        // search: `?role=${roleParam}&from=new&name=${roleName}&description=${roleDescription}`
                                    }
                                )
                            }
                        }}
                    >
                        <BackIcon />
                        Previous </button>
                    <button className='text-white bg-primary font-base rounded-lg flex px-[45px] py-2 font-medium' style={{ fontSize: '16px' }} onClick={() => {
                        handlesubmit()
                    }
                    }>
                        Save Permission
                    </button>
                </div>
            </div >

            {
                showResetPermissionModal && <ResetPermission show={showResetPermissionModal} closeModal={() => setShowResetPermissionModal(false)} handleSubmit={addDefaultPermissions} />
            }
            {showCustomiseColumnsModal && dashboardColumns?.length > 0 &&
                <CustomColumnModal
                    show={showCustomiseColumnsModal}
                    dashboardColumns={dashboardColumns}
                    handleChangeSelection={handleChangeSelection}
                    updateColumnList={updateColumnList}
                    setShow={() => setShowCustomiseColumnsModal(false)}
                    roleId={roleId}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                />}
            {
                showConfirmDeleteModal && selectedRole && <ConfirmDeletion
                    show={showConfirmDeleteModal}
                    setShow={() => setShowConfirmDeleteModal(false)}
                    deleteHandler={handleDeleteRole}
                    deleteText={`${selectedRole.name} role`}

                />
            }
            {
                showConfirmationModal && selectedRole && <WarningModal
                    show={showConfirmationModal}
                    closeModal={() => setShowConfirmationModal(false)}
                    role={selectedRole.name}
                    users={users}
                />
            }
            {
                showCopyPermissionModal && <CopyPermissionModal
                    show={showCopyPermissionModal}
                    closeModal={() => setShowCopyPermissionModal(false)}
                    handleSubmit={(permissions) => handleSubmitCopyPermission(permissions)}
                    masterRoles={masterRoles}
                />
            }
        </>
    );
}

export default ListPermissions;
