import { Switch } from '@headlessui/react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import { companyService } from '../../services/company';
import courses from '../../services/courses';
import { guideServices } from '../../services/guides';
import { MentorService } from '../../services/mentor';
import PrimaryButton from '../form-fields/primary-button'
import BasicDetails from './basicDetails';
import FAQ from './faq';
import Sessions from './sessions';


const LIVE_SESSION_REQUIRED_FIELD = ['name', 'session_date', 'session_start_time', 'session_end_time',];
const RECORDING_REQUIRED_FIELD = ['name', 'recording_length'];
const GUIDE_REQUIRE_FIELD = ['name'];

const CreateCourses = () => {
    const [enabledPromo, setEnabledPromo] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [guideList, setGuideList] = useState([]);
    const [mentorList, setMentorList] = useState([]);
    const [recordingList, setRecordingList] = useState([]);
    const [steps, setSteps] = useState(1);
    const [basicDetails, setBasicDetails] = useState({})
    const [thumbnailSizeExceedError, setThumbnailSizeExceedError] = useState(false);
    const [imageTypeError, setImageTypeError] = useState(false);
    const [category, setCategory] = useState([]);
    const [basicCourseUuid, setBasicCourseUuid] = useState();
    const [sessions, setSessions] = useState([{}]);
    const [sessionUUID, setSessionUUID] = useState({})
    const [guide, setGuide] = useState({});
    const [mentor, setMentor] = useState([]);
    const [recording, setRecording] = useState({});
    const [serialList, setSerialList] = useState([]);
    const [serialOrder, setSerialOrder] = useState([{ label: 1, value: 1 }]);
    const [faqQuestions, setFaqQuetions] = useState([{}]);
    const [learnSections, setLearnSections] = useState(['', ''])
    const [showCertificate, setShoCertificate] = useState(true);
    const [promoMentors, setPromoMentors] = useState([]);
    const [basicFieldError, setBasicFieldError] = useState(false);
    const [sessionFieldError, setSessionFieldError] = useState({});

    const history = useHistory();
    const { id } = useParams();

    // put all get api in promise.all
    const getGuides = async () => {
        const res = await guideServices.getAllGuides();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
            setGuideList(list)
        }
    };

    const getMentors = async () => {
        await MentorService.getAllMentors().then(res => {
            if (res.status === 200) {
                const mentors = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
                setMentorList(mentors)
            }
        }).catch(e => {
            console.log(e);
            toast.error(e.response.data)
        });

    }

    const getCategory = async () => {
        const res = await courses.getActiveCategory();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ label: item.name, value: item.id }))
            setCategoryList(list);
        }
    }

    const getRecordings = async () => {
        const res = await courses.getRecordingCollection()
        if (res.data.status) {
            const list = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
            setRecordingList(list)
        }
    }

    useEffect(() => {
        getGuides();
        getMentors();
        getRecordings();
        getCategory();
    }, [])

    useEffect(() => {
        let newArray = Array.from({ length: sessions.length }, (value, index) => { return { value: index + 1, label: index + 1 } });
        setSerialList(newArray);
        if (!id) {
            handleChangeSerialOrder(newArray[0], 0)
        }
    }, [sessions]);

    // put all get using uuid api in promise.all
    const getCourseContentByUUId = async () => {
        const res = await courses.getCourseContent(id)
        if (res.data.status === 200) {
            let session = [...sessions];
            res.data.data.forEach((item, index) => {
                if (item.content_type === 'live') {
                    session[index] = {
                        ...(item.name) && { name: item.name },
                        ...(item.content_type) && { content_type: item.content_type },
                        ...(item.session_date) && { session_date: moment(item.session_date).format('YYYY-MM-DD') },
                        ...(item.session_end_time) && { session_end_time: moment(item.session_end_time).format('HH:mm') },
                        ...(item.session_start_time) && { session_start_time: moment(item.session_start_time).format('HH:mm') },
                        ...(item.short_description) && { short_description: item.short_description },
                        ...(item.zoom_link) && { zoom_link: item.zoom_link }
                    }
                }
                if (item.content_type === 'recording') {
                    session[index] = {
                        ...(item.name) && { name: item.name },
                        ...(item.content_type) && { content_type: item.content_type },
                        ...(item.recording_length) && { recording_length: item.recording_length },
                        ...(item.short_description) && { short_description: item.short_description },
                        ... (item.upload_link) && { upload_link: item.upload_link },
                        ... (item.workshop_id) && { workshop_id: item.workshop_id, }
                    }
                }
                if (item.content_type === 'guides') {
                    session[index] = {
                        ...(item.name) && { name: item.name },
                        ...(item.content_type) && { content_type: item.content_type },
                        ...(item.short_description) && { short_description: item.short_description },
                        ...(item.upload_link) && { upload_link: item.upload_link },
                        ...(item.guide_id) && { guide_id: item.guide_id },
                    }
                }

                setSerialOrder(prev => ({
                    ...prev,
                    [index]: { value: item.serial_order, label: item.serial_order }
                }))
                setMentor(prev => ({
                    ...prev,
                    [index]: item?.mentors?.map(item => ({ ...item, label: item.name, value: item.id }))
                }))
                setSessionUUID(prev => ({
                    ...prev,
                    [index]: item.uuid
                }))
            })

            setSessions([...session]);
        }
    }

    const getAllFAQByUUID = async () => {
        const res = await courses.getAllFaqs(id);
        if (res.data.status === 200) {
            const faqs = res.data.data.map(item => ({ question: item.question, answer: item.answer }));
            if (faqs.length > 0) {
                setFaqQuetions(faqs);
            } else {
                setFaqQuetions([{}])
            }
        }
    }

    const getCourseBasicDetailsByUUID = useCallback(async () => {
        const res = await courses.getCourseByUUID(id);
        if (res.data.status === 200) {
            setBasicDetails(prev => ({
                ...prev,
                name: res.data.data.name,
                short_description: res.data.data.short_description,
                card_thumbnail: res.data.data.card_thumbnail,
                thumbnail: res.data.data.thumbnail,
                video_url: res.data.data.video_url,
                session_date: res.data.data.session_date ? moment(res.data.data.session_date).format('YYYY-MM-DD') : '',
                start_time: res.data.data.start_time ? moment(res.data.data.start_time).format('HH:mm') : '',
                end_time: res.data.data.end_time ? moment(res.data.data.end_time).format('HH:mm') : ''
            }));
            setEnabledPromo(res.data.data.is_promo);
            setShoCertificate(res.data.data.display_certificate)
            if (res.data.data.learn_section?.length > 0) {
                setLearnSections(res.data.data.learn_section);
            } else {
                setLearnSections(['', '']);
            }
            const mentors = res.data.data?.mentor_id?.map(item => {
                return mentorList?.find(mentor => {
                    if (item === mentor.id) return { label: mentor.name, value: mentor.id }
                })
            })
            setPromoMentors(mentors || [])
            const cat = res.data.data.category.map(item => {
                return categoryList.find(category => item === category.value
                )
            })
            setCategory(cat);
        }
    }, [id, categoryList, mentorList])

    useEffect(() => {
        if (id && categoryList?.length > 0 && mentorList.length > 0) {
            getCourseContentByUUId();
            getAllFAQByUUID();
            getCourseBasicDetailsByUUID();
        }
    }, [id, categoryList, mentorList])

    const handleChangeContentType = useCallback((value, index) => {
        const newSession = [...sessions];
        newSession[index]['content_type'] = value
        setSessions(newSession);
    }, [sessions]);

    const handleChangeBasicDetails = useCallback((e) => {
        let detail = { ...basicDetails };
        detail[e.target.name] = e.target.value;
        setBasicDetails(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }, [basicDetails])

    const handleAddMoreSession = useCallback(() => {
        let session = [...sessions];
        session.push({});
        setSessions(session)
        handleChangeSerialOrder({ label: session.length, value: session.length }, session.length - 1)
    }, [sessions]);

    const handleAddMoreFAQs = useCallback(() => {
        let FAQs = [...faqQuestions];
        FAQs.push({});
        setFaqQuetions(FAQs)
    }, [faqQuestions]);

    const handleAddMoreLearnSection = useCallback(() => {
        let sections = [...learnSections];
        sections.push('')
        setLearnSections(sections)
    }, [learnSections])

    const handelChangeThumbnail = async (event) => {
        if (event.target.files[0]) {
            if ((event.target.files[0].type !== "image/png") && (event.target.files[0].type !== "image/jpeg")) {
                setImageTypeError(true);
                return
            } else {
                setImageTypeError(false)
            }
            if (event.target.files[0].size / 1048576 > 1) {
                // setThumbnailSizeExceedError(true);
                toast.error('Image should be less than 1 mb.')
                return;
            }
            const formData = new FormData();
            formData.append("file", event.target.files[0])
            const url = await companyService.uploadFile(formData)
            if (url.data.status === 200) {
                setBasicDetails(prev => ({
                    ...prev,
                    [event.target.name]: url.data.data.s3_url
                }));
            }
        }
    }

    const handelUpload = useCallback(async (event, index) => {
        if (event.target.files[0]) {
            const formData = new FormData();
            formData.append("file", event.target.files[0])
            const url = await companyService.uploadFile(formData)
            if (url.data.status === 200) {
                const newSession = [...sessions];
                newSession[index][event.target.name] = url.data.data.s3_url
                setSessions(newSession);
            }
        }
    }, [sessions]);

    const handleSelectCategory = useCallback((value) => {
        setCategory(value)
    }, []);

    const handleChangeSession = useCallback((e, index) => {
        const newSession = [...sessions];
        newSession[index][e.target.name] = e.target.value
        setSessions(newSession);
    }, [sessions]);


    const handleChangeMentor = useCallback((value, index) => {
        setMentor(prev => ({
            ...prev,
            [index]: value
        }))
    }, [sessions]);

    const handleChangePromoMentor = useCallback((value) => {
        setPromoMentors(value)
    }, [])

    const handleChangeGuide = useCallback((value, index) => {
        setGuide(value)
        const newSession = [...sessions];
        newSession[index]['guide_id'] = value.value
        setSessions(newSession);
    }, [sessions]);

    const handleChangeRecording = useCallback((value, index) => {
        setRecording(value)
        const newSession = [...sessions];
        newSession[index]['workshop_id'] = value.value
        setSessions(newSession);
    }, [sessions]);

    const handleChangeFAQs = useCallback((e, index) => {
        const questions = [...faqQuestions]
        questions[index][e.target.name] = e.target.value;
        setFaqQuetions(questions);
    }, [faqQuestions]);

    const handleChangeLearnSection = useCallback((e, index) => {
        const sections = [...learnSections];
        sections[index] = e.target.value;
        setLearnSections(sections)
    }, [learnSections]);

    const handleChangeCertificate = useCallback((value) => {
        setShoCertificate(value)
    }, []);

    const handleChangeSerialOrder = useCallback((value, index) => {
        setSerialOrder(prev => ({
            ...prev,
            [index]: value
        }))
    }, [])

    const handleSubmitBasicDetails = useCallback(async () => {
        if (enabledPromo && (!basicDetails.name || !basicDetails.card_thumbnail || !basicDetails.session_date || category.length === 0)) {
            toast.error('Please fill required fields.')
            setBasicFieldError(true)
            return
        }
        if (!enabledPromo &&
            (!basicDetails.name ||
                !basicDetails.card_thumbnail ||
                !basicDetails.session_date ||
                !basicDetails.thumbnail ||
                !basicDetails.start_time ||
                !basicDetails.end_time ||
                category.length === 0)) {
            toast.error('Please fill required fields.')
            setBasicFieldError(true)
            return
        }

        setBasicFieldError(false);
        console.log(category, 'category')
        const payload = {
            ...basicDetails,
            category: category?.map(item => item.value),
            end_time: (basicDetails.session_date && basicDetails.end_time) ? new Date(`${basicDetails.session_date} ${basicDetails.end_time}`).toISOString() : undefined,
            start_time: (basicDetails.session_date && basicDetails.start_time) ? new Date(`${basicDetails.session_date} ${basicDetails.start_time}`).toISOString() : undefined,
            is_promo: enabledPromo,
            mentor_id: promoMentors?.map(item => item.value)
        }
        const res = await courses.createBasicCourse(payload);
        if (res.data.status === 200) {
            setBasicCourseUuid(res.data.data.uuid)
            toast.success('Basic Details Saved.');
            if (!enabledPromo) {
                if (steps < 3) setSteps(steps + 1);
            } else {
                history.push('/admin/learning-center/workshops/courses');
            }
        } else {
            toast.error('Something went wrong');
        }
    }, [basicDetails, category, steps, promoMentors, enabledPromo]);

    const handleUpdateBasicDetails = useCallback(async () => {
        if (enabledPromo &&
            (!basicDetails.name ||
                !basicDetails.card_thumbnail ||
                !basicDetails.session_date ||
                category.length === 0)) {
            toast.error('Please fill required fields.')
            setBasicFieldError(true)
            return
        }
        if (!enabledPromo &&
            (!basicDetails.name ||
                !basicDetails.card_thumbnail ||
                !basicDetails.session_date ||
                !basicDetails.thumbnail ||
                !basicDetails.start_time ||
                !basicDetails.end_time ||
                category.length === 0)) {
            toast.error('Please fill required fields.')
            setBasicFieldError(true)
            return
        }

        setBasicFieldError(false);
        console.log(category, 'category')
        const payload = {
            ...basicDetails,
            end_time: (basicDetails.session_date && basicDetails.end_time) ? new Date(`${basicDetails.session_date} ${basicDetails.end_time}`).toISOString() : undefined,
            start_time: (basicDetails.session_date && basicDetails.start_time) ? new Date(`${basicDetails.session_date} ${basicDetails.start_time}`).toISOString() : undefined,
            category: category?.map(item => item.value),
            is_promo: enabledPromo,
            mentor_id: promoMentors?.map(item => item?.value)
        }
        const res = await courses.updateBasicDetails(id, payload)
        if (res.data.status === 200) {
            setBasicCourseUuid(res.data.data.uuid)
            toast.success('Basic Details Updated.');
            if (!enabledPromo) {
                if (steps < 3) setSteps(steps + 1);
            } else {
                history.push('/settings/learning-centre/courses');
            }
        } else {
            toast.error('Something went wrong');
        }
    }, [basicDetails, category, steps, id, promoMentors, enabledPromo])

    const handleSubmitSessions = useCallback(async (index) => {
        if (sessions[index].content_type === 'live' || !sessions[index].content_type) {
            const result = LIVE_SESSION_REQUIRED_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || !mentor[index] || mentor[index]?.length === 0) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }
        if (sessions[index].content_type === 'recording') {
            const result = RECORDING_REQUIRED_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || (!sessions[index].upload_link && !sessions[index].workshop_id)) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }
        if (sessions[index].content_type === 'guides') {
            const result = GUIDE_REQUIRE_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || (!sessions[index].upload_link && !sessions[index].guide_id)) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }

        setSessionFieldError({ [index]: false });
        let payload = {
            ...sessions[index],
            session_date: sessions[index].session_date ? sessions[index].session_date : undefined,
            session_end_time: (sessions[index].session_end_time && sessions[index].session_date) ? new Date(`${sessions[index].session_date} ${sessions[index].session_end_time}`).toISOString() : undefined,
            session_start_time: (sessions[index].session_start_time && sessions[index].session_date) ? new Date(`${sessions[index].session_date} ${sessions[index].session_start_time}`).toISOString() : undefined,
            serial_order: serialOrder[index]?.value,
            content_type: sessions[index].content_type || 'live',
            mentor_id: mentor[index]?.map(item => item.value)
        }
        const res = await courses.addCourseContent(basicCourseUuid, payload)
        if (res.data.status === 200) {
            setSessionUUID(prev => ({
                ...prev,
                [index]: res.data.data.uuid
            }));
            toast.success('Session Inserted.');
        } else {
            toast.error('Something went wrong');
        }
    }, [sessions, serialOrder, mentor, LIVE_SESSION_REQUIRED_FIELD, RECORDING_REQUIRED_FIELD, GUIDE_REQUIRE_FIELD]);

    const handleSubmitUpdateSessions = useCallback(async (index) => {
        if (sessions[index].content_type === 'live' || !sessions[index].content_type) {
            const result = LIVE_SESSION_REQUIRED_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || !mentor[index] || mentor[index]?.length === 0) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }
        if (sessions[index].content_type === 'recording') {
            const result = RECORDING_REQUIRED_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || (!sessions[index].upload_link && !sessions[index].workshop_id)) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }
        if (sessions[index].content_type === 'guides') {
            const result = GUIDE_REQUIRE_FIELD.every(item => Object.keys(sessions[index]).includes(item) && sessions[index][item])
            if (!result || (!sessions[index].upload_link && !sessions[index].guide_id)) {
                toast.error('Please fill all required fields.');
                setSessionFieldError({ [index]: true });
                return;
            }
        }

        setSessionFieldError({ [index]: false });

        let payload = {
            ...sessions[index],
            session_date: sessions[index].session_date ? sessions[index].session_date : undefined,
            session_end_time: (sessions[index].session_end_time && sessions[index].session_date) ? new Date(`${sessions[index].session_date} ${sessions[index].session_end_time}`).toISOString() : undefined,
            session_start_time: (sessions[index].session_start_time && sessions[index].session_date) ? new Date(`${sessions[index].session_date} ${sessions[index].session_start_time}`).toISOString() : undefined,
            serial_order: serialOrder[index]?.value,
            content_type: sessions[index].content_type || 'live',
            mentor_id: mentor[index]?.map(item => item.value)
        }
        const res = await courses.updateCourseContent(sessionUUID[index], payload)
        if (res.data.status === 200) {
            setSessionUUID(prev => ({
                ...prev,
                [index]: res.data.data.uuid
            }))
            toast.success('Session Updated');
        } else {
            toast.error('Something went wrong');
        }
    }, [sessions, serialOrder, mentor]);

    const handleSubmitExtradetails = async () => {
        const payload = {
            learn_section: learnSections.filter(item => item),
            faqs: faqQuestions.filter(item => item.question && item.answer),
            display_certificate: showCertificate
        }
        const res = await courses.updateCourseExtraDetails(basicCourseUuid, payload)
        if (res.data.status === 200) {
            toast.success('Course created successfully');
            history.push('/settings/learning-centre/courses');
        }
    }

    return (
        <>
            <div className="users w-100 h-full flex flex-col">
                <div className="settings-title">
                    {/* <div className="w-full">
                        <div className='Breadcrumb '>
                            <ul className='flex items-center gap-2.5'>
                                <li>
                                    <a className='text-14 text-black300 hover:text-black800'>Settings</a>
                                </li>
                                <li className='gap-2.5	flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path d="M4 3.5L8.5 8L4 12.5" stroke="#696974"></path></svg>
                                    <Link to="/admin/learning-center/workshops/courses" className='text-14 text-black300 hover:text-black800'>All Courses</Link>
                                </li>
                                <li className='gap-2.5	flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path d="M4 3.5L8.5 8L4 12.5" stroke="#696974"></path></svg>
                                    <a className='text-14 text-black300 hover:text-black800'>Create Course</a>
                                </li>
                            </ul>
                        </div> 
                </div>*/}
                    <div className="row d-flex d-flex flex-md-fill flex-wrap">
                        <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                            <h1 className='fz24 text-black font-medium'>Create Course {enabledPromo ? 'Promo' : ''}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                            <div className='Breadcrumb '>
                                <ul className='flex items-center gap-2.5 m-0'>
                                    <li><a className={`text-sm hover:text-primary ${steps === 1 ? 'text-black800' : 'text-black700'}`}>Basic Details</a></li>
                                    <li className='gap-2.5	flex items-center'>
                                        < svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path d="M4 3.5L8.5 8L4 12.5" stroke="#696974"></path></svg>
                                        <a className={`text-sm hover:!text-primary ${steps === 2 ? '!text-black800' : '!text-black700'}`}>Add Course Videos</a></li>
                                    <li className='gap-2.5	flex items-center'>
                                        < svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path d="M4 3.5L8.5 8L4 12.5" stroke="#696974"></path></svg>
                                        <a className={`text-sm hover:text-primary ${steps === 3 ? '!text-black800' : '!text-black700'}`}>Extra details</a></li>
                                </ul>
                            </div>
                        </div>
                        {steps === 1 && <div className="col-md-5 col-6 d-flex justify-content-end aling-items-center d-flex flex-md-fill">
                            <div className='flex items-center justify-center gap-2'>
                                <p className='text-sm m-0 text-black font-medium'>Create Promo</p>
                                <Switch
                                    checked={enabledPromo}
                                    onChange={setEnabledPromo}
                                    className={`${enabledPromo ? '!bg-primary' : 'bg-[#E2E2EA]'
                                        } relative inline-flex h-6 w-12 items-center rounded-full togglebtn`}
                                >
                                    {enabledPromo && <i className='on absolute not-italic text-white text-xs font-medium left-1.5 uppercase'>On</i>}
                                    {!enabledPromo && <i className='off absolute text-[#92929D] not-italic text-xs font-medium right-1.5 uppercase'>off</i>}
                                    <span
                                        className={`${enabledPromo ? 'translate-x-7' : 'translate-x-0.5'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                        </div>}
                    </div>
                </div>

                <div className='CreateCoursescroll h-full overflow-auto'>
                    <div className='p-6 w-11/12'>
                        {steps === 1 &&
                            <BasicDetails
                                id={id}
                                enabled={enabledPromo}
                                basicDetails={basicDetails}
                                handleChangeBasicDetails={handleChangeBasicDetails}
                                handelChangeThumbnail={handelChangeThumbnail}
                                category={categoryList}
                                categoryVal={category}
                                handleSelectCategory={handleSelectCategory}
                                handleSubmitBasicDetails={handleSubmitBasicDetails}
                                handleUpdateBasicDetails={handleUpdateBasicDetails}
                                basicFieldError={basicFieldError}
                                handleChangePromoMentor={handleChangePromoMentor}
                                mentorList={mentorList}
                                promoMentors={promoMentors}
                            />
                        }
                        {steps === 2 && (
                            <>
                                {sessions.map((session, index) => (
                                    <Sessions
                                        id={id}
                                        session={session}
                                        index={index}
                                        key={index}
                                        guideList={guideList}
                                        mentorList={mentorList}
                                        recordingList={recordingList}
                                        categoryList={categoryList}
                                        guide={guide}
                                        mentor={mentor}
                                        recording={recording}
                                        serialList={serialList}
                                        serialOrder={serialOrder}
                                        sessionUUID={sessionUUID}
                                        contentType={session?.content_type || 'live'}
                                        handelUpload={handelUpload}
                                        handleChangeSession={handleChangeSession}
                                        handleSubmitSessions={handleSubmitSessions}
                                        handleChangeMentor={handleChangeMentor}
                                        handleChangeGuide={handleChangeGuide}
                                        handleChangeRecording={handleChangeRecording}
                                        handleChangeSerialOrder={handleChangeSerialOrder}
                                        handleChangeContentType={handleChangeContentType}
                                        handleSubmitUpdateSessions={handleSubmitUpdateSessions}
                                        sessionFieldError={sessionFieldError[index]}
                                    />
                                ))}
                                <div className='flex items-center justify-center !mt-6'>
                                    <button className='rounded-lg !py-3 !px-6 text-sm font-medium !bg-primary text-white text-14 hover:opacity-90' onClick={handleAddMoreSession}>+ Add Session</button>
                                </div>
                            </>
                        )}
                        {steps === 3 &&
                            <FAQ
                                handleAddMoreFAQs={handleAddMoreFAQs}
                                faqQuestions={faqQuestions}
                                learnSections={learnSections}
                                showCertificate={showCertificate}
                                handleChangeFAQs={handleChangeFAQs}
                                handleChangeLearnSection={handleChangeLearnSection}
                                handleAddMoreLearnSection={handleAddMoreLearnSection}
                                handleChangeCertificate={handleChangeCertificate}
                            />
                        }

                    </div>
                </div>
                {(!enabledPromo) && <div className='flex justify-end !border-t broder-black400 !px-6 !py-2.5'>
                    {steps > 2 && <button onClick={() => { if (steps > 2) setSteps(steps - 1) }} className='text-black700 border border-text-black700 text-16 px-8 py-2 font-medium mr-4 rounded'>Previous</button>}
                    <PrimaryButton
                        className='px-10 py-2 font-medium text-sm '
                        name='Next'
                        onClick={() => {
                            if (steps === 1) {
                                if (id) {
                                    handleUpdateBasicDetails()
                                } else {
                                    handleSubmitBasicDetails()
                                }
                            }
                            if (steps === 2) {
                                setSteps(3)
                            }
                            if (steps === 3) {
                                handleSubmitExtradetails()
                            }
                        }}
                    ></PrimaryButton>
                </div>
                }
            </div >
        </>
    )
}


export default CreateCourses
