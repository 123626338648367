import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import { settingsBaseUrl } from '../../../routes/settings.routes';
import { getAuthRolePermissions } from '../../../services/private/company.service';
import AreYouSureModal from '../../../components/Modals/AreYouSureModal';
import Consumer from '../../../helpers/context';
import { checkIsSomeItemAvailableInList } from '../../../helpers/helpers';

const getSubText = () => {
    if (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4) {
        return `Do you wish to cancel Integration? Changes that you have made may not have been saved.`
    }
    if (localStorage.getItem('project_state') === 'create') {
        return "Do you want to cancel the project creation? Any unsaved changes you made may be lost."
    }
}

export default function SettingsSidebar({ settingsRoutes, closeSideBar, importPermission }) {
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const [redirectURL, setRedirectURL] = useState();
    const currentRoute = window.location.href.split('/');

    const history = useHistory();
    const params = useParams();
    const ROLE_NAME = localStorage.getItem('role');

    let { allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

    return (
        <>
            {
                settingsRoutes?.length && settingsRoutes?.map((section) => {
                    const { name: sectionName, url: sectionUrl, childRoutes } = section
                    if (!isRolesModule && ROLE_NAME !== 'ADMIN' && (sectionName === 'Company Details' || sectionName === 'Users & Roles' || sectionName === 'Leads')) {
                        return null
                    } else {
                        return (
                            <div id="accordion" key={sectionName}>
                                <div className="card">
                                    <div className="card-header" id={"heading" + sectionUrl} data-toggle="collapse" data-target={"#collapse" + sectionUrl} aria-expanded={params.section === sectionUrl ? "true" : "false"} aria-controls={"collapse" + sectionUrl}>
                                        <span className="cursor-pointer text-capitalize">{sectionName}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#92929D" />
                                        </svg>
                                    </div>
                                    <div id={"collapse" + sectionUrl} className={params.section === sectionUrl ? "collapse show" : "collapse"} aria-labelledby={"heading" + sectionUrl} data-parent="#accordion">
                                        <ul>
                                            {childRoutes.map((component) => {
                                                const { name: componentName, url: componentUrl } = component;
                                                // Old Permissions
                                                if (!isRolesModule && ROLE_NAME !== 'ADMIN' && componentName === 'Import' && !importPermission.includes('bulk_insert')) return null
                                                if (!isRolesModule && ROLE_NAME !== 'ADMIN' && componentName === 'Export' && !importPermission.includes('csv_export')) return null
                                                // New Permissions
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !allowedPermissions.includes('import_leads')) && componentName === 'Import') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !allowedPermissions.includes('export_leads_without_approval')) && componentName === 'Export') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !checkIsSomeItemAvailableInList(allowedPermissions, ['view_users', 'add_new_user'])) && componentName === 'Users') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !allowedPermissions.includes('lead_duplication')) && componentName === 'Lead Duplication') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !allowedPermissions.includes('lead_routing')) && componentName === 'Lead Assignment') return null
                                                if ((!userProfile?.allowed_modules?.includes('lead_sharing')) && componentName === 'Status') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !checkIsSomeItemAvailableInList(allowedPermissions, ['view_roles', 'create_role'])) && componentName === 'Roles') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !userProfile?.allowed_modules?.includes('teams_module')) && componentName === 'Teams') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !allowedPermissions.includes('edit_company_detail')) && componentName === 'Company Info') return null
                                                if ((isRolesModule && ROLE_NAME !== 'Super Admin' && !checkIsSomeItemAvailableInList(allowedPermissions, ['view_marketing_agency', 'create_marketing_agency'])) && componentName === 'Marketing Agency') return null
                                                return (
                                                    componentName ? <li key={componentName}>
                                                        <a onClick={() => {
                                                            if (((currentRoute.includes('new-integration') ||
                                                                currentRoute[currentRoute.length - 1].split('?').includes('new-integration')) &&
                                                                (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4)) || localStorage.getItem('project_state') === 'create') {

                                                                if (currentRoute.includes('create-projects') || currentRoute[currentRoute.length - 1].split('?').includes('new-integration')) {
                                                                    setRedirectURL(settingsBaseUrl + "/" + section.url + "/" + componentUrl);
                                                                    setShowCloseConfirmationModal(true);
                                                                    closeSideBar()
                                                                    return;
                                                                }
                                                            }
                                                            history.push(settingsBaseUrl + "/" + section.url + "/" + componentUrl)
                                                            closeSideBar()
                                                        }
                                                        }
                                                            className={params.component === componentUrl ? 'active' : " "}
                                                        >
                                                            {componentName}
                                                        </a>
                                                    </li> : null
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            {showCloseConfirmationModal &&
                <AreYouSureModal
                    show={showCloseConfirmationModal}
                    subText={getSubText()}
                    closeModal={() => setShowCloseConfirmationModal(false)}
                    confirmClose={() => {
                        localStorage.removeItem('fb_steps')
                        localStorage.removeItem('project_state')
                        history.push(redirectURL)
                    }}
                />}
        </>
    )
}