import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getSelectedLabel } from '../../../utils/selectedOptionUtils';

export default function SourceLabel({
    allSources,
    sources
}) {
    return (
        allSources.length > 0 ? getSelectedLabel(sources, allSources) : <Skeleton variant="text" />
    )
}