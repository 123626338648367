import React, { useCallback, useEffect, useState } from 'react'
import Guides from '../../components/learning-center/tabs/guides';
import Shorts from '../../components/learning-center/tabs/shorts';
import Workshops from '../../components/learning-center/tabs/workshops';
import Category from '../../components/learning-center/Category/category';
import { getAllCourses, getAllGuides, getAllLiveWorkshops, getCategory } from '../../services/public/public.service';
import './LearningCenter.css';
import SearchIcon from '../../assets/icons/search.svg'
import Courses from '../../components/learning-center/tabs/Courses';
import style from '../../components/learning-center/CourseDetails/style.module.css'
import moment from 'moment';

const LearningCenter = () => {

    const [workshops, setWorkshops] = useState([]);
    const [allWorkshops, setAllWorkshop] = useState([]);
    const [recordings, setRecordings] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('workshop');
    const [guides, setGuides] = useState([]);
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // filter live sessions and recordings from workshops
    const filterWorkshop = useCallback((workshops) => {
        let recordings = workshops.filter(el => el.status === 'COMPLETED' && el.recording_url)
        let workshop = workshops.filter(el => !el.recording_url);
        setRecordings(recordings);
        setWorkshops(workshop);
    }, []);

    const getWorkshop = async () => {
        await getAllLiveWorkshops()
            .then((res) => {
                filterWorkshop(res.data)
                setAllWorkshop(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getAllActiveCourses = async (searchText) => {
        const res = await getAllCourses({ name: searchText });
        if (res.data.status === 200) {
            let courses = []
            res.data.data.forEach((course, index) => {
                courses[index] = { ...course }
                courses[index]['liveSession'] = course?.course_content?.find(item => {
                    return item?.content_type === 'live' && (moment(item.session_start_time).add('5:30') >= moment() || moment(item.session_end_time).add('5:30') >= moment())
                });
                if (courses[index]['liveSession']) {
                    courses[index]['liveSession'].session_start_time = moment(courses[index]['liveSession'].session_start_time).add('5:30')
                    courses[index]['liveSession'].session_end_time = moment(courses[index]['liveSession'].session_end_time).add('5:30')
                }
            })
            setCourses(courses)
        }
    }

    const getAllGuide = async (searchText) => {
        const res = await getAllGuides({ searchText })
        if (res.data.status === 200) {
            setGuides(res.data.data);
        }
    }
    const getAllCategory = async () => {
        const res = await getCategory();
        if (res.data.status === 200) {
            setCategories(res.data.data)
        }
    }

    useEffect(() => {
        getWorkshop();
        getAllGuide();
        getAllCategory();
        // getAllActiveCourses('');
        if (localStorage.getItem('tab') === 'courses') {
            document.getElementById('courses-tab').click();
            localStorage.removeItem('tab')
        }
        const el = document.getElementById('main-container')
        el.classList.add('learning-center')

        return (() => el.classList.remove('learning-center'))
    }, []);

    useEffect(() => {
        if (searchText.length > 2 && activeTab === 'guide') {
            getAllGuide(searchText);
        }
        if (searchText.length === 0 && activeTab === 'guide') {
            getAllGuide('');
        }
        if (searchText.length > 2 && activeTab === 'courses') {
            getAllActiveCourses(searchText)
        }
        if (searchText.length === 0 && activeTab === 'courses') {
            getAllActiveCourses('')
        }
    }, [activeTab, searchText, allWorkshops]);

    const handleSearch = useCallback((e) => {
        setSearchText(e.target.value);
        const filteredData = allWorkshops.filter(item => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        filterWorkshop(filteredData);
    }, [workshops]);

    const handleClearSearch = useCallback(() => {
        setSearchText('');
        filterWorkshop(allWorkshops)
    }, [allWorkshops]);

    const handleSelectCategory = useCallback((category) => {
        console.log(selectedCategories, category)
        const index = selectedCategories.findIndex(item => item.id === category.id);
        console.log({ index }, 'selected')
        if (index === -1) {
            let selected = [...selectedCategories];
            selected.push(category)
            setSelectedCategories(selected)
            // setSelectedCategories(prev => ([
            //     ...prev,
            //     { ...category }
            // ]))
        }
    }, [selectedCategories]);
    // console.log({ selectedCategories }, selectedCategories.length)

    const handleRemoveCategory = useCallback((category) => {
        const categories = selectedCategories.filter(item => item.id != category.id);
        setSelectedCategories(categories);
    }, [selectedCategories]);

    return (
        <div className={`${style.learingMainSection} main-section position-relative`}>
            <div className="lead-head">
                <div className='lead-title learing-center-main mb-18'>
                    <div className="row align-items-center">
                        <div className="col-md-5 col-sm-6 d-flex align-items-center">
                            <h1 className="mb-0 mr-2 fz24 black h-font fw-po-medium">
                                Learning Center
                            </h1>
                        </div>
                        {/* Mobile view search box */}
                        <div className="col-md-7 col-sm-6 align-items-center justify-content-end d-lg-none d-flex pr-sm-4">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <img src={SearchIcon} className="" /></div>
                                <input type="text" placeholder="Search" onChange={(e) => handleSearch(e)} className="searchinput" value={searchText} />
                                {searchText &&
                                    <div className="close-icon" onClick={handleClearSearch} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                        </svg>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video-tabs all-tabs">
                    <div className="row">
                        <div className="col-md-8">
                            <ul className="nav nav-tabs" id="video-tabs" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="workshops-tab"
                                        data-toggle="tab"
                                        href="#workshops"
                                        role="tab"
                                        aria-controls="workshops"
                                        aria-selected="true"
                                        onClick={() => setActiveTab('workshop')}
                                    >
                                        Live Sessions
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="courses-tab"
                                        data-toggle="tab"
                                        href="#courses"
                                        role="tab"
                                        aria-controls="courses"
                                        aria-selected="false"
                                        onClick={() => setActiveTab('courses')}
                                    >
                                        Courses
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="recording-tab"
                                        data-toggle="tab"
                                        href="#recording"
                                        role="tab"
                                        aria-controls="recording"
                                        aria-selected="false"
                                        onClick={() => setActiveTab('recording')}
                                    >
                                        Recordings
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="guides-tab"
                                        data-toggle="tab"
                                        href="#guides"
                                        role="tab"
                                        aria-controls="guides"
                                        aria-selected="false"
                                        onClick={() => setActiveTab('guide')}
                                    >
                                        Guides
                                    </a>
                                </li>

                            </ul>
                        </div>
                        {/* Desktop search box */}
                        <div className="col-md-4 justify-content-end  d-lg-flex d-none pr-md-5">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <img src={SearchIcon} className="" /></div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => handleSearch(e)}
                                    className="searchinput"
                                    value={searchText}
                                />
                                {searchText &&
                                    <div className="close-icon" onClick={handleClearSearch}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                        </svg>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {activeTab === 'courses' && <div className='mt-24'>
                <Category
                    categories={categories}
                    handleRemoveCategory={handleRemoveCategory}
                    handleSelectCategory={handleSelectCategory}
                    selectedCategories={selectedCategories}
                />
            </div>} */}
            <div className="video-cards-main mt-20">
                <div className="tab-content pt4" id="video-tabsContent">
                    <div className="tab-pane fade show active" id="workshops" role="tabpanel" aria-labelledby="workshops-tab">
                        {/* <Category /> */}
                        <Workshops workshops={workshops} />
                    </div>
                    <div className="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                        <Courses courses={courses} />
                    </div>
                    <div className="tab-pane fade" id="recording" role="tabpanel" aria-labelledby="recording-tab">
                        {/* <Category /> */}
                        <Workshops workshops={recordings} />
                    </div>
                    <div className="tab-pane fade" id="guides" role="tabpanel" aria-labelledby="guides-tab">
                        {/* <Category /> */}
                        <Guides guides={guides} />
                    </div>

                </div>
            </div>
        </div>
    )
}
export default LearningCenter;