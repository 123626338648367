import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusgrayIcon } from '../../assets/plusgray.svg';
import { ReactComponent as TrendingIcon } from '../../assets/trending.svg';
import { ReactComponent as NewIcon } from '../../assets/new.svg';
import { ReactComponent as CallbtnIcon } from '../../assets/callbtn.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'
import CarDummyIMg from '../../assets/icons/ProjectcardImg.png';
import { unit_type } from '../../utils/project-constant';
// import ContactModal from '../../components/Modals/ContactModal';
// import AddYourListModal from '../../components/Modals/AddYourListModal';

const Card = ({ style, item, handleAddToList, index, handleShowAddToList, handleShowContactModal }) => {

    const { configurations } = item;
    console.log(item)

    const findMin = (key) => {
        let min = ''
        if (configurations?.length > 1) {
            configurations.reduce((previous, current) => {
                if (current && previous) {
                    min = current[key] < previous[key] ? current[key] : previous[key];
                }
            });
        }
        return min || ''
    }

    const findMax = (key) => {
        let max = ''
        if (configurations?.length > 1) {
            configurations.reduce((previous, current) => {
                if (current && previous) {
                    max = current[key] > previous[key] ? current[key] : previous[key];
                }
            });
        };

        return max || ''
    }

    return (
        <div className={`${style.projectcard}`}>
            <figure className='position-relative'>
                <div className={`${style.tags_plusgroup} d-flex align-items-center justify-content-between position-absolute`}>
                    <div className={`${style.projectTag}`}>
                        {item?.is_trending && <div className={`${style.treadingtags}`}>Trending <TrendingIcon /></div>}
                        {index === 1 && <div className={`${style.newtags}`}>New <NewIcon /></div>}
                        {index === 2 && <div className={`${style.newtags} ${style.newUpdatetags}`}>New Update <NewIcon /></div>}
                    </div>
                    {!item?.added_by && <div>
                        <button className={`${style.prplus}`} onClick={() => handleShowAddToList(item)}><PlusgrayIcon /> <span>Add to my list</span></button>
                    </div>}
                    {item?.added_by && <div>
                        <button className={`${style.prplus} ${style.added}`}><span className=''>Added</span> <CheckIcon /> </button>
                    </div>}

                </div>
                <img src={item.thumbnail || CarDummyIMg} alt="" />
            </figure>
            {item.property_type && item.transaction_type && <div className={`${style.projectpropertytype} mb-8 inter`}>
                {`${item.property_type} - ${item.transaction_type}`} | New Launch
            </div>}
            <h1 className='black fw-po-bold fz20 inter mb4 lh-22'>{item.name}</h1>
            {item?.city && item?.state && <p className='fz14 black-dark-700 mb-0 lh-16'>{item.city}, {item.state}</p>}
            {
                item.property_type === 'Residential' &&
                item.transaction_type === 'Sale' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0 lh-16'>
                            {
                                configurations ?
                                    configurations?.length === 1 ?
                                        configurations[0].unit_price ?
                                            `₹ ${configurations[0].unit_price}` : '-' :
                                        `₹ ${findMin('unit_price')} - ${findMax('unit_price')}` : '-'}
                        </p>
                        <p className='mb-0 lh-16'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ? `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'}
                        </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0 lh-16'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].bhk_type || '-' :
                                    `${findMin('bhk_type')} - ${findMax('bhk_type')}` : '-'
                        }
                        </p>
                        <p className='mb-0 lh-16'>{configurations ? 'Possession (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }
            {
                item.property_type === 'Residential' &&
                item.transaction_type === 'Rent' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0'> {
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].unit_rent_price ?
                                        `₹ ${configurations[0].unit_rent_price}` : '-' :
                                    `₹ ${findMin('unit_rent_price')} - ${findMax('unit_rent_price')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ?
                                        `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'
                        }
                        </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].bhk_type || '-' :
                                    `${findMin('bhk_type')} - ${findMax('bhk_type')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{configurations ? 'Available (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }
            {
                item.property_type === 'Commercial' &&
                item.transaction_type === 'Rent' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0'> {
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].unit_rent_price ?
                                        `₹ ${configurations[0].unit_rent_price}` : '-' :
                                    `₹ ${findMin('unit_rent_price')} - ${findMax('unit_rent_price')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ?
                                        `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'} </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].bhk_type || '-' :
                                    `${findMin('bhk_type')} - ${findMax('bhk_type')}` : '-'}
                        </p>
                        <p className='mb-0'>{configurations ? 'Available (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }
            {
                item.property_type === 'Commercial' &&
                item.transaction_type === 'Sale' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].unit_price ?
                                        `₹ ${configurations[0].unit_price}` : '-' :
                                    `₹ ${findMin('unit_price')} - ${findMax('unit_price')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ?
                                        `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'}
                        </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0'>{configurations ? unit_type.find(item => item.value === configurations[0].unit_type)?.label : '-'}</p>
                        <p className='mb-0'>{configurations ? 'Available (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }
            {
                item.property_type === 'Commercial' &&
                item.transaction_type === 'Lease' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0'> {
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].unit_rent_price ? `₹ ${configurations[0].unit_rent_price}` : '-' :
                                    `₹ ${findMin('unit_rent_price')} - ${findMax('unit_rent_price')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ? `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'
                        }
                        </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0'>Commercial (Retail Lease)</p>
                        <p className='mb-0'>{configurations ? 'Available (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }
            {
                item.property_type === 'Commercial' &&
                item.transaction_type === 'Retail-lease' &&
                <div className={`${style.costsize} d-flex justify-content-between`}>
                    <div className={`${style.projectcost}`}>
                        <p className='mb-0'> {
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].unit_rent_price ?
                                        `₹ ${configurations[0].unit_rent_price}` : '-' :
                                    `₹ ${findMin('unit_rent_price')} - ${findMax('unit_rent_price')}` : '-'}
                        </p>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].built_up_area ?
                                        `${configurations[0].built_up_area} Sq.ft.` : '-' :
                                    `${findMin('built_up_area')} - ${findMax('built_up_area')} Sq.ft.` : '-'
                        }
                        </p>
                    </div>
                    <div className={`${style.projectsize} text-right`}>
                        <p className='mb-0'>{
                            configurations ?
                                configurations?.length === 1 ?
                                    configurations[0].bhk_type || '-' :
                                    `${findMin('bhk_type')} - ${findMax('bhk_type')}` : '-'
                        }
                        </p>
                        <p className='mb-0'>{configurations ? 'Available (Jul 2023)' : '-'}</p>
                    </div>
                </div>
            }

            <div className={`${style.projectbtngroup}`}>
                <Link to={`/project-launchpad/details/${item.uuid}`} className={`${style.viewbtn}`}>View Project</Link>
                <button className={`${style.contactbtn}`} onClick={() => handleShowContactModal(item)}><CallbtnIcon />Contact</button>
            </div>
        </div>
    )
}

export default Card;