import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import RightModal from '../../Modals/RightModal'
import InputText from '../../InputGroup/InputText';
import InputSelect from '../../InputGroup/InputSelect';
import CreatableSelect from 'react-select/creatable';

import { bhk_type, carpet_area, facing, furnished_unit, maintenance_payee, plot_area, property_age, sharing, preferred_tenant, project_area, usages_status, heightMeasurment } from '../../../utils/project-constant';
import Dropdown from '../../InputGroup/dropdown';
import { insertConfiguration, updateConfigurationByUUID, uploadFile } from '../../../services/public/public.service';
import { useLocation } from 'react-router-dom';
import { toINRFormat } from '../../../helpers/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as RupayIcon } from '../../../assets/icons/rupay.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { ReactComponent as PercentIcon } from '../../../assets/icons/percent.svg';
import { transactionTypeEnum } from '../../../helpers/filtersEnum';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const textareastyle = {
    minHeight: 'unset!important',
    important: true

};

const ConfigurationModal = ({
    show,
    closeModal,
    style,
    getConfig,
    uuid,
    selectedConfig,
    setConfigurations,
    configurationsData,
    unitTypes,
    handleChangeUnitType,
    inventory,
    preferredTenent,
    selectedConfigIndex
}) => {

    const [showSuperArea, setShowSuperArea] = useState(false);
    const [showBuildupArea, setShowBuildupArea] = useState(false);
    const [showPlotArea, setShowPlotArea] = useState(false);
    const [configImage, setConfigImage] = useState('');

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const propertyType = searchParams.get('pt');
    const transactionType = searchParams.get('tt');

    let error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            unit_type: '',
            unit_configuration: '',
            rent_price: '',
            rent_escalation: '',
            security_deposit: '',
            minimum_rent_tenure: '',
            preferred_tenant: '',
            lockin_period: '',
            front_dimension_width: '',
            front_dimension_height: '',
            maintenance_cost: '',
            maintenance_cost_payee: '',
            rent_start_date: '',
            rent_end_date: '',
            available_for_rent: '',
            carpet_area: '',
            carpet_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            super_area: '',
            super_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            built_up_area: '',
            built_up_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            plot_area: '',
            plot_area_unit: {
                label: 'Acres',
                value: 'acres'
            },
            furnishing: '',
            facing: '',
            unit_sharing: '',
            usage_status: '',
            parking_slot: '',
            floor: '',
            tower: '',
            unit_no: '',
            similar_unit_count: '',
            comment: '',
            price: '',
            assured_return: '',
            lease_guarantee: '',
            seat_count: '',
            cabin_count: '',
            office_cabin_count: '',
            rent_escalation_period: '',
            minimum_rent_tenure_period: '',
            lockin_period_unit: '',
            front_dimension_width_unit: '',
            front_dimension_height_unit: '',
            unit_no_is_private: false,
            comment_is_private: false,
            price_per_unit: '',
            price_per_unit_type: '',
            alt_tag: ''
        },
        // resolver: yupResolver(basicDetailsvalidationSchema)
    });

    useEffect(() => {
        if (Object.keys(selectedConfig).length > 0) {
            reset({
                unit_type: selectedConfig?.unit_type ? unitTypes.find(item => item.value == selectedConfig.unit_type) : '',
                price: selectedConfig?.price ? toINRFormat(selectedConfig?.price) : '',
                rent_price: selectedConfig?.rent_price ? toINRFormat(selectedConfig?.rent_price) : '',
                unit_sharing: selectedConfig?.unit_sharing ? sharing.find(item => item.value === selectedConfig.unit_sharing) : '',
                unit_configuration: selectedConfig?.unit_configuration ? bhk_type.find(item => item.value === selectedConfig.unit_configuration) : '',
                furnishing: selectedConfig?.furnishing ? furnished_unit.find(item => item.value === selectedConfig.furnishing) : '',
                usage_status: selectedConfig?.usage_status ? usages_status.find(item => item.value === selectedConfig.usage_status) : '',
                floor: selectedConfig?.floor || '',
                carpet_area: selectedConfig?.carpet_area || '',
                carpet_area_unit: selectedConfig?.carpet_area_unit ? project_area.find(item => item.value === selectedConfig.carpet_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                super_area: selectedConfig?.super_area || '',
                super_area_unit: selectedConfig?.super_area_unit ? project_area.find(item => item.value === selectedConfig.super_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                built_up_area: selectedConfig?.built_up_area || '',
                built_up_area_unit: selectedConfig?.built_up_area_unit ? project_area.find(item => item.value === selectedConfig.built_up_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                facing: selectedConfig?.facing ? facing.find(item => item.value === selectedConfig.facing) : '',
                parking_slot: selectedConfig?.parking_slot || '',
                property_type: propertyType,
                transaction_type: transactionType,
                plot_area: selectedConfig?.plot_area || '',
                plot_area_unit: selectedConfig?.plot_area ? plot_area.find(item => item.value === selectedConfig.plot_area) : {
                    label: 'Acres',
                    value: 'acres'
                },
                remarks: selectedConfig?.remakrs || '',
                tower: selectedConfig?.tower || '',
                front_dimension_width: selectedConfig?.front_dimension_width,
                front_dimension_width_unit: selectedConfig?.front_dimension_width_unit ? heightMeasurment.find(item => item.value === selectedConfig.front_dimension_width_unit) : '',
                front_dimension_height: selectedConfig?.front_dimension_height,
                front_dimension_height_unit: selectedConfig?.front_dimension_height_unit ? heightMeasurment.find(item => item.value === selectedConfig.front_dimension_height_unit) : '',
                maintenance_cost: selectedConfig?.maintenance_cost ? toINRFormat(selectedConfig?.maintenance_cost) : '',
                maintenance_cost_payee: selectedConfig?.maintenance_cost_payee ? maintenance_payee.find(item => item.value === selectedConfig.maintenance_cost_payee) : '',
                rent_start_date: selectedConfig?.rent_start_date ? new Date(selectedConfig.rent_start_date) : '',
                rent_end_date: selectedConfig?.rent_end_date ? new Date(selectedConfig.rent_end_date) : '',
                available_for_rent: selectedConfig?.available_for_rent ? new Date(selectedConfig.available_for_rent) : '',
                similar_unit_count: selectedConfig?.similar_unit_count || '',
                comment: selectedConfig?.comment || '',
                assured_return: selectedConfig?.assured_return || '',
                lease_guarantee: selectedConfig?.lease_guarantee || '',
                seat_count: selectedConfig?.seat_count || '',
                cabin_count: selectedConfig?.cabin_count || '',
                office_cabin_count: selectedConfig?.office_cabin_count || '',
                rent_escalation: selectedConfig?.rent_escalation || '',
                rent_escalation_period: selectedConfig?.rent_escalation_period ? property_age.find(item => item.value === selectedConfig?.rent_escalation_period) : '',
                minimum_rent_tenure: selectedConfig?.minimum_rent_tenure || '',
                minimum_rent_tenure_period: selectedConfig?.minimum_rent_tenure_period ? property_age.find(item => item.value === selectedConfig.minimum_rent_tenure_period) : '',
                lockin_period_unit: selectedConfig?.lockin_period_unit ? property_age.find(item => item.value === selectedConfig.lockin_period_unit) : '',
                unit_no_is_private: selectedConfig?.unit_no_is_private || true,
                unit_no: selectedConfig?.unit_no || '',
                comment_is_private: selectedConfig?.comment_is_private || true,
                price_per_unit: selectedConfig?.price_per_unit ? toINRFormat(selectedConfig?.price_per_unit) : '',
                price_per_unit_type: selectedConfig?.price_per_unit_type ? carpet_area.find(item => item.value === selectedConfig.price_per_unit_type) : '',
                security_deposit: selectedConfig?.security_deposit ? toINRFormat(selectedConfig?.security_deposit) : ''
            },
                { keepDefaultValues: true }
            )

            setShowSuperArea(!!selectedConfig?.super_area || false);
            setShowBuildupArea(!!selectedConfig?.built_up_area || false);
            setShowPlotArea(!!selectedConfig?.plot_area || '');
        }
    }, [selectedConfig])

    const unitTypeVal = watch('unit_type');

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);
        if (response.data.status === 200) {
            setConfigImage(response.data.data.s3_url)

        }
    }

    const onFormSubmit = async (values) => {

        const payload = {
            unit_plan_image: configImage,
            unit_plan_image_alt_tag: values.alt_tag,
            ...(values.unit_type) && { unit_type: values.unit_type.value },
            ...(values.unit_configuration) && { unit_configuration: values.unit_configuration.value },
            ...(values.rent_price) && { rent_price: parseInt(values.rent_price.replace(/,/g, '')) },
            ...(values.rent_escalation) && { rent_escalation: values.rent_escalation || null },
            ...(values.security_deposit) && { security_deposit: parseInt(values.security_deposit.replace(/,/g, '')) || null },
            ...(values.minimum_rent_tenure) && { minimum_rent_tenure: values.minimum_rent_tenure || null },
            ...(values.preferred_tenant) && { preferred_tenant: values.preferred_tenant.value },
            ...(values.lockin_period) && { lockin_period: values.lockin_period || null },
            ...(values.front_dimension_width) && { front_dimension_width: +values.front_dimension_width },
            ...(values.front_dimension_height) && { front_dimension_height: +values.front_dimension_height },
            ...(values.maintenance_cost) && { maintenance_cost: parseInt(values.maintenance_cost.replace(/,/g, '')) || null },
            ...(values.maintenance_cost_payee) && { maintenance_cost_payee: values.maintenance_cost_payee.value },
            ...(values.rent_start_date) && { rent_start_date: values.rent_start_date },
            ...(values.rent_end_date) && { rent_end_date: values.rent_end_date },
            ...(values.available_for_rent) && { available_for_rent: values.available_for_rent },
            ...(values.carpet_area) && { carpet_area: +values.carpet_area || null },
            ...(values.carpet_area_unit) && { carpet_area_unit: values.carpet_area_unit.value },
            ...(values.super_area) && { super_area: +values.super_area || null },
            ...(values.super_area_unit) && { super_area_unit: values.super_area_unit.value },
            ...(values.built_up_area) && { built_up_area: +values.built_up_area || null },
            ...(values.built_up_area_unit) && { built_up_area_unit: values.built_up_area_unit.value },
            ...(values.plot_area) && { plot_area: +values.plot_area || null },
            ...(values.plot_area_unit) && { plot_area_unit: values.plot_area_unit.value },
            ...(values.furnishing) && { furnishing: values.furnishing.value },
            ...(values.facing) && { facing: values.facing.value },
            ...(values.unit_sharing) && { unit_sharing: values.unit_sharing.value || null },
            ...(values.usage_status) && { usage_status: values.usage_status.value || null },
            ...(values.parking_slot) && { parking_slot: values.parking_slot || null },
            ...(values.floor) && { floor: +values.floor || null },
            ...(values.tower) && { tower: +values.tower || null },
            ...(values.unit_no) && { unit_no: values.unit_no || null },
            ...(values.similar_unit_count) && { similar_unit_count: values.similar_unit_count || null },
            ...(values.comment) && { comment: values.comment },
            ...(values.price) && { price: parseInt(values.price.replace(/,/g, '')) || null },
            ...(values.assured_return) && { assured_return: values.assured_return || null },
            ...(values.lease_guarantee) && { lease_guarantee: values.lease_guarantee || null },
            ...(values.seat_count) && { seat_count: +values.seat_count || null },
            ...(values.cabin_count) && { cabin_count: +values.cabin_count || null },
            ...(values.office_cabin_count) && { office_cabin_count: +values.office_cabin_count || null },
            ...(values.rent_escalation_period) && { rent_escalation_period: values.rent_escalation_period.value || null },
            ...(values.minimum_rent_tenure_period) && { minimum_rent_tenure_period: values.minimum_rent_tenure_period.value || null },
            ...(values.lockin_period_unit) && { lockin_period_unit: values.lockin_period_unit.value || null },
            ...(values.front_dimension_width_unit) && { front_dimension_width_unit: values.front_dimension_width_unit.value || null },
            ...(values.front_dimension_height_unit) && { front_dimension_height_unit: values.front_dimension_height_unit.value || null },
            ...(values.unit_no_is_private) && { unit_no_is_private: values.unit_no_is_private },
            ...(values.comment_is_private) && { comment_is_private: values.comment_is_private },
            ...(values.price_per_unit) && { price_per_unit: parseInt(values.price_per_unit.replace(/,/g, '')) || null },
            ...(values.price_per_unit_type) && { price_per_unit_type: values.price_per_unit_type.value || null },
        }

        let res = {};
        if (selectedConfig?.uuid) {
            res = await updateConfigurationByUUID(selectedConfig?.uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getConfig();
            }

        } else if (uuid) {
            res = await insertConfiguration(uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getConfig();
            }
        } else if (selectedConfigIndex >= 0) {
            const configList = [...configurationsData];
            configList[selectedConfigIndex] = { ...payload }
            setConfigurations(configList)
        } else {
            setConfigurations([...configurationsData, { ...payload }])
        }

        reset();
        closeModal();

    }

    return (
        <RightModal show={show} closeModal={() => {
            reset();
            closeModal();
        }} modalClass={`${style.configurationModal} configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {Object.keys(selectedConfig).length > 0 ? 'Edit' : 'Add'} Configuration
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    reset();
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} id='config-form' className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                {/* unit type*/}

                <div>
                    <Controller
                        control={control}
                        name='alt_tag'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputText
                                    label='Unit Plan Alt Tag'
                                    inputclass={'mt-3'}
                                    placeholder='Enter Unit Plan Alt Tag'
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                    required={true}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                <div className={`form-group position-relative`}>
                    <Controller
                        control={control}
                        name='unit_type'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <label>
                                    Unit Type
                                </label>
                                <CreatableSelect
                                    placeholder='Select Type'
                                    onCreateOption={(value) => {
                                        handleChangeUnitType({ label: value, value: value })
                                        onChange({ label: value, value: value })
                                    }}
                                    onChange={onChange}
                                    value={value}
                                    options={unitTypes}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                {/* unit configuration */}
                <div>
                    <Controller
                        control={control}
                        name='unit_configuration'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Unit Configuration'
                                    placeholder='Select Unit Configuration'
                                    onChange={onChange}
                                    value={value}
                                    options={bhk_type}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                {/* Price */}
                {unitTypeVal?.value !== 'Plot' && transactionType === 'sale' &&
                    ['residential', 'commercial'].includes(propertyType) && <div className='form-group position-relative'>
                        <label >Price</label>
                        <Controller
                            control={control}
                            name='price'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <input
                                        type='text'
                                        className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                        placeholder='e.g. 120000'
                                        value={value}
                                        onChange={(e) => {
                                            onChange(
                                                toINRFormat(
                                                    e.target.value
                                                        .replace(/^0+/g, '')
                                                        .replace(/[^0-9]/g, '')
                                                ))
                                        }}
                                    />
                                </>
                            )}
                        />
                        <RupayIcon className={`${style.absrupay}`} />
                    </div>}
                {/* Unit rent price */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&

                    <div className='form-group position-relative'>
                        <label >Unit rent Price (Monthly)</label>
                        <Controller
                            control={control}
                            name='rent_price'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <input
                                        type='text'
                                        className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                        placeholder='e.g. 120000'
                                        value={value}
                                        onChange={(e) => {
                                            onChange(
                                                toINRFormat(
                                                    e.target.value
                                                        .replace(/^0+/g, '')
                                                        .replace(/[^0-9]/g, '')
                                                ))
                                        }}
                                    />
                                </>
                            )}
                        />

                        <RupayIcon className={`${style.absrupay}`} />
                    </div>}

                {/* Rent Escalation */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='form-group position-relative'>
                        <label>Rent Escalation </label>
                        <Controller
                            control={control}
                            name='rent_escalation_period'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <Dropdown
                                        list={property_age}
                                        onChange={onChange}
                                        value={value}
                                        style={style}
                                    />
                                </>
                            )}
                        />
                        <input
                            type='number'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            placeholder='E.g. 5% Annually'
                            {...register('rent_escalation')}
                        />
                    </div>
                }
                {/* Security Deposit */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='form-group position-relative'>
                        <label >Security Deposit</label>
                        <Controller
                            control={control}
                            name='security_deposit'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <input
                                        type='text'
                                        className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                        placeholder='e.g. 120000'
                                        value={value}
                                        onChange={(e) => {
                                            onChange(
                                                toINRFormat(
                                                    e.target.value
                                                        .replace(/^0+/g, '')
                                                        .replace(/[^0-9]/g, '')
                                                ))
                                        }}
                                    />
                                </>
                            )}
                        />
                        {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                        <RupayIcon className={`${style.absrupay}`} />
                    </div>}
                {/* Minimum Rent Tenure */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='form-group position-relative'>
                        <label >Minimum Rent Tenure </label>
                        <Controller
                            control={control}
                            name='minimum_rent_tenure_period'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <Dropdown
                                        list={property_age}
                                        onChange={onChange}
                                        value={value}
                                        style={style}
                                    />
                                </>
                            )}
                        />
                        <input
                            type='number'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            placeholder='Enter Monthly Tenure'
                            {...register('minimum_rent_tenure')}
                        />
                    </div>}
                {/* Preferred Tenure */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div>
                        <Controller
                            control={control}
                            name='preferred_tenant'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <InputSelect
                                        label='Preferred Tenant'
                                        placeholder='Select Tenant '
                                        onChange={onChange}
                                        value={value}
                                        options={preferredTenent}
                                        styles={customStyles}
                                    />
                                </>
                            )}
                        />
                    </div>}
                {/* Price per parameter */}
                {transactionType === 'sale' &&
                    ['new_launch', 'sustenance'].includes(inventory) && <div className='form-group position-relative'>
                        <label >Price (per sq.ft)</label>
                        <Controller
                            control={control}
                            name='price_per_unit_type'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <Dropdown
                                        list={carpet_area}
                                        onChange={onChange}
                                        value={value}
                                        style={style}
                                    />
                                </>
                            )}
                        />
                        <Controller
                            control={control}
                            name='price_per_unit'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <input
                                        type='text'
                                        className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                        placeholder='e.g. 120000'
                                        value={value}
                                        onChange={(e) => {
                                            onChange(
                                                toINRFormat(
                                                    e.target.value
                                                        .replace(/^0+/g, '')
                                                        .replace(/[^0-9]/g, '')
                                                ))
                                        }}
                                    />
                                </>
                            )}
                        />
                        <RupayIcon className={`${style.absrupay}`} />
                    </div>}
                {/* Maintentance cost */}
                {unitTypeVal?.value !== 'Plot' && <div className='form-group position-relative'>
                    <label >Maintenance Cost</label>
                    <Controller
                        control={control}
                        name='maintenance_cost'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <input
                                    type='text'
                                    className={error ? 'form-control  pad_in_input text-input error' : 'form-control text-input  pad_in_input'}
                                    placeholder='e.g. 120000'
                                    value={value}
                                    onChange={(e) => {
                                        onChange(
                                            toINRFormat(
                                                e.target.value
                                                    .replace(/^0+/g, '')
                                                    .replace(/[^0-9]/g, '')
                                            ))
                                    }}
                                />
                            </>
                        )}
                    />
                    <RupayIcon className={`${style.absrupay}`} />
                </div>}
                {/* Maintenance Cost Payee */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) && <div>
                    <Controller
                        control={control}
                        name='maintenance_cost_payee'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Maintenance Cost Payee'
                                    placeholder='Select Maintenance Cost Payee'
                                    onChange={onChange}
                                    value={value}
                                    options={maintenance_payee}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>}
                {/* Rent Start Date */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='relative'>
                        <Controller
                            control={control}
                            name='rent_start_date'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <div className='position-relative'>
                                    <label className=''>Rent Start Date</label>
                                    <DatePicker
                                        placeholderText='Select Rent Start Date'
                                        onChange={onChange}
                                        dateFormat="dd MMM yyyy "
                                        selected={value}
                                        className={`form-control text-input ${error ? 'error' : ''}`}
                                    // error={errors?.state?.message ? 'Please select State' : ''}
                                    />
                                    <CalendarIcon className={`${style.calicon}`} />
                                </div>
                            )}
                        />
                    </div>}
                {/* Rent End Date */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='position-relative'>
                        <Controller
                            control={control}
                            name='rent_end_date'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <div className='position-relative'>
                                    <label className=''>Rent Finish Date</label>
                                    <DatePicker
                                        placeholderText='Select Rent Finish Date'
                                        onChange={onChange}
                                        dateFormat="dd MMM yyyy "
                                        selected={value}
                                        className={`form-control text-input ${error ? 'error' : ''}`}
                                    // error={errors?.state?.message ? 'Please select State' : ''}
                                    />
                                    <CalendarIcon className={`${style.calicon}`} />
                                </div>
                            )}
                        />
                    </div>}
                {/* Available for rent */}
                {['pre_lease', 'rent', 'retail_lease'].includes(transactionType) &&
                    <div className='relative'>
                        <Controller
                            control={control}
                            name='available_for_rent'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <div className='position-relative'>
                                    <label className=''>Available for rent Date</label>
                                    <DatePicker
                                        placeholderText='Select Available for rent Date'
                                        onChange={onChange}
                                        dateFormat="dd MMM yyyy "
                                        selected={value}
                                        className={`form-control text-input ${error ? 'error' : ''}`}
                                    // error={errors?.state?.message ? 'Please select State' : ''}
                                    />
                                    <CalendarIcon className={`${style.calicon}`} />
                                </div>
                            )}
                        />
                    </div>}
                {/* Carpet area */}
                <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Carpet Area </label>
                    <Controller
                        control={control}
                        name='carpet_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={carpet_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g 1200'
                        {...register('carpet_area')}
                    />
                </div>
                {(!showSuperArea || !showBuildupArea || !showPlotArea) && <div className='d-flex flex-wrap align-items-start gap-8'>
                    {!showSuperArea && <button type='button' onClick={() => setShowSuperArea(true)} className={`${style.btntags}`}>+ Super Area</button>}
                    {!showBuildupArea && <button type='button' onClick={() => setShowBuildupArea(true)} className={`${style.btntags}`}>+ Built up Area</button>}
                    {!showPlotArea && <button type='button' onClick={() => setShowPlotArea(true)} className={`${style.btntags}`}>+ Plot Area</button>}

                </div>}
                {/* Super Area */}
                {showSuperArea && <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Super Area </label>
                    <Controller
                        control={control}
                        name='super_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={project_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g. 1400'
                        {...register('super_area')}
                    />
                </div>}
                {/* built up Area */}
                {showBuildupArea && <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Built up Area </label>
                    <Controller
                        control={control}
                        name='built_up_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={project_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g. 1300'
                        {...register('built_up_area')}
                    />
                </div>}
                {/* Plot area */}
                {showPlotArea &&
                    <div className={` form-group position-relative`}>
                        <label className='block text-black700 text-sm mb-2 font-medium'>Plot Area </label>
                        <Controller
                            control={control}
                            name='plot_area_unit'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <Dropdown
                                        list={plot_area}
                                        onChange={onChange}
                                        value={value}
                                        style={style}
                                    />
                                </>
                            )}
                        />
                        <input
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            type={"number"}
                            placeholder='e.g. 33'
                            {...register('plot_area')}
                        />

                    </div>}
                {/* Furnishing */}
                <div>
                    <Controller
                        control={control}
                        name='furnishing'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Unit Furnishing'
                                    placeholder='Select Furnishing'
                                    onChange={onChange}
                                    value={value}
                                    options={furnished_unit}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                {/* facing */}
                <div>
                    <Controller
                        control={control}
                        name='facing'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Facing'
                                    placeholder='Select Facing'
                                    onChange={onChange}
                                    value={value}
                                    options={facing}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                {/* Unit Sharing */}
                {['retail_lease', 'rent', 'pre_lease'].includes(transactionType) && <div className='form-group position-relative'>
                    <Controller
                        control={control}
                        name='unit_sharing'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Unit Sharing'
                                    placeholder='Select Unit Sharing'
                                    onChange={onChange}
                                    value={value}
                                    options={sharing}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>}
                {/* usage status */}
                {['rent', 'pre_lease', 'retail_lease'].includes(transactionType) && inventory === 'resale' && <div>
                    <Controller
                        control={control}
                        name='usage_status'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Usage Status'
                                    placeholder='Select Usage Status'
                                    onChange={onChange}
                                    value={value}
                                    options={usages_status}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>}
                {/* Parking Slot */}
                <div className='form-group'>
                    <label >Parking Slots</label>
                    <input
                        type='text'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 1'
                        {...register('parking_slot')}
                    />
                </div>
                {/* Floor */}
                <div className='form-group'>
                    <label className='flex items-center gap-1'>Floor <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4> */}
                                    <p className=''>On which floor is it situated
                                    </p>
                                </div>
                            </Tooltip>
                        }
                    >
                        <InfoIcon className='cursor-pointer' />
                    </OverlayTrigger></label>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 3'
                        {...register('floor')}
                    />

                </div>
                {/* Tower No. */}
                <div className='form-group'>
                    <label >Tower No.</label>
                    <input
                        type='text'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 8'
                        {...register('tower')}
                    />
                </div>
                {/* Unit No.  */}
                <div className='form-group'>
                    <div className='d-flex justify-content-between align-items-center mb-8'>
                        <label className='mb-0'>Unit No.</label>
                        {/* <div className='d-inline-flex align-items-center gap-8'>
                            <input type="checkbox" {...register('unit_no_is_private')} style={{ width: '16px', height: '16px' }} readOnly />
                            <div className={`${style.privateinfo} border border-color radius-6 gap-4 d-inline-flex align-items-center`}>
                                <LockIcon />
                                <span>Private</span>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>                                                
                                                <p className=''>If checked, This field will only be visible to you.
                                                </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <InfoIcon className='cursor-pointer' />
                                </OverlayTrigger>
                            </div>
                        </div> */}
                    </div>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 1'
                        {...register('unit_no')}
                    />
                </div>

                {transactionType === 'pre_lease' && (['commercial'].includes(propertyType)) && <div className='form-group position-relative'>
                    <label >Lock In Period</label>
                    <Controller
                        control={control}
                        name='lockin_period_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={property_age}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='Enter Lock In Period'
                        {...register('lockin_period')}
                    />
                </div>}
                {propertyType === 'commercial' && transactionType === "sale" &&
                    <div className='form-group'>
                        <label >No. of Seats</label>
                        <input
                            type='number'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            {...register('seat_count')}
                            placeholder='e.g. 100'
                        />


                    </div>
                }
                {propertyType === 'commercial' && transactionType === "sale" &&
                    <div className='form-group'>
                        <label >No. of Office Cabins</label>
                        <input
                            type='number'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            {...register('office_cabin_count')}
                            placeholder='e.g. 100'
                        />
                    </div>
                }
                {propertyType === 'commercial' && transactionType === "sale" &&
                    <div className='form-group'>
                        <label >No. of Cabins</label>
                        <input
                            type='number'
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            {...register('cabin_count')}
                            placeholder='e.g. 100'
                        />
                    </div>
                }

                {propertyType === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transactionType) && <div className='form-group position-relative'>
                    <label >Front Dimension</label>
                    <Controller
                        control={control}
                        name='front_dimension_width_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={[
                                        {
                                            label: 'Feet',
                                            value: 'ft'
                                        },
                                        {
                                            label: 'Meter',
                                            value: 'meter'
                                        }]}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='Enter Front Dimension'
                        {...register('front_dimension_width')}
                    />
                </div>}
                {propertyType === 'commercial' && ['pre_lease', 'rent', 'retail_lease'].includes(transactionType) && <div className='form-group position-relative'>
                    <label >Height Dimension</label>
                    <Controller
                        control={control}
                        name='front_dimension_height_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={heightMeasurment}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='Enter Height Dimension'
                        {...register('front_dimension_height')}
                    />
                </div>}
                {propertyType === 'commercial' && transactionType === "sale" && <div className='form-group position-relative'>
                    <label>Assured Return</label>
                    <input
                        type='text'
                        className={error ? 'form-control text-input error' : 'form-control text-input '}
                        placeholder='e.g. 10 %'
                        {...register('assured_return', { required: false })}
                    />
                    <PercentIcon className={`${style.calicon}`} />
                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                </div>}
                {propertyType === 'commercial' && transactionType === "sale" && <div className='form-group position-relative'>
                    <label>Lease Guarantee </label>
                    <input
                        type='number'
                        className={`${error ? 'error' : ''} form-control text-input`}
                        placeholder='Enter Lease Guarantee'
                        {...register('lease_guarantee', { required: false })}
                    />
                    <PercentIcon className={`${style.calicon}`} />
                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}
                </div>}


                {/* No. of Simillar unit */}
                <div className='form-group'>
                    <label >No. of Similar Units</label>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 100'
                        {...register('similar_unit_count')}
                    />

                </div>

                <div className={``}>
                    <Controller
                        control={control}
                        name='comment'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <div className='form-group'>
                                <div className='d-flex justify-content-between align-items-center mb-8'>
                                    <label className='mb-0'>Any Comments</label>
                                    {/* <div className='d-inline-flex align-items-center gap-8 d-none'>
                                        <input type="checkbox" {...register('comment_is_private')} style={{ width: '16px', height: '16px' }} />
                                        <div className={`${style.privateinfo} border border-color radius-6 gap-4 d-inline-flex align-items-center`}>
                                            <LockIcon />
                                            <span>Private</span>
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                    <Tooltip id="button-tooltip" >
                                                        <div className='info-msg'>
                                                            
                                                            <p className=''>If checked, This field will only be visible to you.
                                                            </p>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <InfoIcon className='cursor-pointer' />
                                            </OverlayTrigger>

                                        </div>
                                    </div> */}
                                </div>
                                <textarea
                                    placeholder='Add Comments'
                                    onChange={onChange}
                                    value={value}
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    rows="4"
                                    style={textareastyle}

                                ></textarea>

                            </div>
                        )}
                    />

                </div>
            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    reset();
                    closeModal();
                }}>Cancel</button>
                <button type='button' onClick={handleSubmit(onFormSubmit)} disabled={!isDirty} form='config-form' className='pr-btn w-100'>Add</button>
            </div>
        </RightModal>

    )
}

export default ConfigurationModal
