import React, { useCallback, useState } from 'react'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';

import { deleteConfigurationByUUID, getPreferredTenant, getProjectConfigurations, getUnitType } from '../../services/public/public.service';
import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { ReactComponent as EditICon } from '../../assets/icons/NameEdit.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { convertToTitleCase, numDifferentiation, removeDecimalPlaces } from '../../helpers/helpers';
import { toast } from 'react-toastify';
import moment from 'moment';
import ConfigurationModal from './Modal/ProjectConfigModal';

const ProjectConfig = ({ handleChangeConfiguration, configurations, getConfig, inventory, allowedPermissions }) => {
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [configurationsData, setConfigurations] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState({});
    const [preferredTenent, setPreferredTenant] = useState([])
    const [unitType, setUnitType] = useState([]);
    const [selectedConfigIndex, setSelectedConfigIndex] = useState();

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const property = searchParams.get('pt');
    const transaction = searchParams.get('tt');


    const getConfigUnitType = async () => {
        const res = await getUnitType(property);
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item, index) => ({ label: item.name, value: item.name }))
            setUnitType(mapped);
        }
    }

    const getTenant = async () => {
        const res = await getPreferredTenant(property);
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item, index) => ({ label: item.name, value: item.name }))
            setPreferredTenant(mapped);
        }
    }


    const handleChangeUnitType = useCallback((newType) => {
        setUnitType([...unitType, newType])
    }, [unitType])

    const handleDeleteConfig = async (configuuid) => {
        const res = await deleteConfigurationByUUID(configuuid);
        if (res.data.status) {
            getConfig();
            toast.success(res.data.message);
        }
    }
    useEffect(() => {
        if (configurationsData.length > 0)
            handleChangeConfiguration(configurationsData)
    }, [configurationsData])

    useEffect(() => {
        setConfigurations(configurations)
    }, [configurations])

    useEffect(() => {
        if (property) {
            getConfigUnitType();
            getTenant();
        }
    }, [property]);

    const handleSubmit = () => {
        history.push(`/settings/projects/create-projects/location/${uuid}?pt=${property}&tt=${transaction}`);
    }

    return (

        <>
            {configurationsData.length === 0 && <div className={`${style.basic_form} basic_form mt-20`}>
                <div className={`${style.basic_form} basic_form mt-16`}>
                    <div className={`${style.col_span_2} border-color d-flex align-items-center justify-content-between border-bottom `}>
                        <h1 className="fz18 black fw-po-semi inter mb-2">Configuration</h1>
                    </div>
                </div>

                <div className={`${style.col_span_2}`}>
                    <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add Configuration</button>
                </div>
            </div>}
            {configurationsData.length > 0 && <div className={`${style.creatproject}`}>
                <div className={`${style.projecttitle} border-color pt-20 pb-10 d-flex align-items-center justify-content-between pr-6`}>
                    <h1 className='fz18 black fw-po-semi inter mb-0'>Configuration</h1>
                    <div className='d-flex flex-row align-items-center  gap-12 gap-sm-8'>
                        {configurationsData.length === 1 && <button
                            className={`${style.configeditbtn}`}
                            type='button' onClick={() => {
                                setSelectedConfig(configurationsData[0]);
                                setSelectedConfigIndex(0)
                                setShowConfigModal(true);
                            }}>
                            <EditICon /><span className='d-md-inline d-none'>Edit</span>
                        </button>}
                        {/* {allowedPermissions.includes('delete_project_configuration') && configurationsData.length === 1 && <button
                            className={`${style.configdelbtn} strokered hover:!text-red`} type='button' onClick={() => {
                                if (Object.keys(configurationsData[0]).includes('uuid')) {
                                    handleDeleteConfig(configurationsData[0].uuid)
                                } else {
                                    handleChangeConfiguration([])
                                }
                            }}>
                            <TrashIcon />
                            <span className='d-md-inline d-none'> Delete</span>
                        </button>} */}
                        <button className='pr-btn inter' type='button' onClick={() => setShowConfigModal(true)}><PlushIcon />Add</button>
                    </div>
                </div>
                <div className={`${style.overflowcontent} d-flex flex-fill flex-col border-top border-color pt-20`}>
                    <div className='w-100'>
                        {/* {configurationsData.length === 0 &&
                            <div className={`${style.col_span_2}`}>
                                <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add Configuration</button>
                            </div>
                        } */}
                        {configurationsData.length === 1 &&
                            <>
                                <div className={`${style.overflowcontentrow} d-flex flex-md-wrap flex-nowrap align-items-start gap-y-14 gap-x-20`}>
                                    {configurationsData[0]?.unit_type && <div className={`${style.confiboxview}`}>
                                        <h6>{convertToTitleCase(configurationsData[0].unit_type || '')}</h6>
                                        <p>Unit Type</p>
                                    </div>}
                                    {configurationsData[0]?.unit_configuration && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].unit_configuration}</h6>
                                        <p>Unit Configuration</p>
                                    </div>}
                                    {configurationsData[0]?.price_range && <div className={`${style.confiboxview}`}>
                                        <h6>{numDifferentiation(configurationsData[0].price_range)}</h6>
                                        <p>Price Range</p>
                                    </div>}
                                    {configurationsData[0]?.carpet_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].carpet_area)} {configurationsData[0].carpet_area_unit}</h6>
                                        <p>Carpet Area</p>
                                    </div>}
                                    {configurationsData[0]?.super_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].super_area)} {configurationsData[0].super_area_unit}</h6>
                                        <p>Super Area</p>
                                    </div>}
                                    {configurationsData[0]?.built_up_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].built_up_area)} {configurationsData[0].built_up_area_unit}</h6>
                                        <p>Built up area</p>
                                    </div>}
                                    {configurationsData[0]?.plot_area && <div className={`${style.confiboxview}`}>
                                        <h6>{removeDecimalPlaces(configurationsData[0].plot_area)} {configurationsData[0].plot_area_unit}</h6>
                                        <p>Plot Area</p>
                                    </div>}
                                    {configurationsData[0]?.unit_no && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].unit_no}</h6>
                                        <p>Unit No.</p>
                                    </div>}
                                    {configurationsData[0]?.floor && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].floor}</h6>
                                        <p>Floor</p>
                                    </div>}
                                    {configurationsData[0]?.tower && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].tower}</h6>
                                        <p>Tower Number</p>
                                    </div>}
                                    {configurationsData[0]?.similar_unit_count && <div className={`${style.confiboxview}`}>
                                        <h6>{configurationsData[0].similar_unit_count}</h6>
                                        <p>Similar Unit Count</p>
                                    </div>}
                                </div>
                                {/* <div className={`${style.col_span_2} mt-12`}>
                                    <button type='button' onClick={() => setShowConfigModal(true)} className='pr-outline-btn d-block w-100'>+ Add More</button>
                                </div> */}
                            </>
                        }

                        {configurationsData.length > 1 &&
                            <div className={`${style.createprojecttable} def-table pr-0 !h-[332px] pb-0`}>
                                <table className='table mb-0'>
                                    <thead className='!z-10'>
                                        <tr>
                                            <th >S.No.</th>
                                            <th >Unit Type</th>
                                            <th>Unit Configuration</th>
                                            <th> Price Range</th>
                                            <th>Carpet Area</th>
                                            <th>Super Area</th>
                                            <th>Built Up Area</th>
                                            <th>Plot Area</th>
                                            <th>Floor</th>
                                            <th>Tower Number</th>
                                            <th>Unit No.</th>
                                            <th>Similar Unit Count</th>
                                            <th className='sticky right-0'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            configurationsData?.map((data, index) => (
                                                <tr className='text-left' key={`${data.uuid}_${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.unit_type ? convertToTitleCase(data.unit_type) : '-'}</td>
                                                    <td>{data?.unit_configuration ? convertToTitleCase(data?.unit_configuration) : '-'}</td>
                                                    {/* <td>2BHK, Furnished</td> */}
                                                    <td>{data?.price_range ? '₹ ' + data?.price_range : '-'}</td>
                                                    <td>{data.carpet_area ? `${removeDecimalPlaces(data.carpet_area)} ${data.carpet_area_unit}` : '-'}</td>
                                                    <td>{data.super_area ? `${removeDecimalPlaces(data.super_area)} ${data.super_area_unit}` : '-'}</td>
                                                    <td>{data.built_up_area ? `${removeDecimalPlaces(data.built_up_area)} ${data.built_up_area_unit} ` : '-'}</td>
                                                    <td>{data.plot_area ? `${removeDecimalPlaces(data.plot_area)} ${data.plot_area_unit}` : '-'}</td>
                                                    <td>{data.floor || '-'}</td>
                                                    <td>{data.tower ? data.tower : '-'}</td>
                                                    <td>{data.unit_no ? data.unit_no : '-'}</td>
                                                    <td>{data.similar_unit_count ? data.similar_unit_count : '-'}</td>
                                                    <td className='dropdown view-more !sticky right-0'>
                                                        <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <ThreedotsIcon />
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            <a className="dropdown-item text-capitalize"
                                                                onClick={() => {
                                                                    setSelectedConfig(data);
                                                                    setSelectedConfigIndex(index)
                                                                    setShowConfigModal(true);
                                                                }
                                                                }>Edit Configuration
                                                            </a>
                                                            <a className="dropdown-item text-capitalize"
                                                                onClick={() => {
                                                                    let config = [...configurationsData]
                                                                    config.splice(index, 1);
                                                                    setConfigurations(config)
                                                                }
                                                                }>Delete Configuration
                                                            </a>

                                                            {/* {allowedPermissions.includes('delete_project_configuration') && <a className="dropdown-item del-menu-itme text-capitalize"
                                                                onClick={() => {
                                                                    if (data?.uuid) {
                                                                        handleDeleteConfig(data.uuid)
                                                                    } else {
                                                                        const filteredList = configurationsData.filter((item, itemIndex) => itemIndex !== index)
                                                                        handleChangeConfiguration(filteredList)
                                                                    }
                                                                }
                                                                }>Delete Configuration</a>} */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
            </div>}
            {
                showConfigModal &&
                < ConfigurationModal
                    show={showConfigModal} closeModal={() => {
                        setSelectedConfig({})
                        setSelectedConfigIndex();
                        setShowConfigModal(false)
                    }} style={style}
                    getConfig={getConfig}
                    uuid={uuid}
                    selectedConfig={selectedConfig}
                    setConfigurations={setConfigurations}
                    configurationsData={configurationsData}
                    unitTypes={unitType}
                    handleChangeUnitType={handleChangeUnitType}
                    inventory={inventory}
                    preferredTenent={preferredTenent}
                    selectedConfigIndex={selectedConfigIndex}
                />
            }
        </>
    )
}

export default ProjectConfig
