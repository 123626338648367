import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import TextEditor from '../Editor';
import { insertProjectHighlights } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputTextarea from '../../../components/InputGroup/InputTextarea';

const ProjectKeyPillerModal = ({ show, closeModal, style, projectUuid, getHighLights, selectedHighlight, selectedHighDescription, setHighlightDescriptionBasic, setHighlights }) => {
    const [lists, setLists] = useState([{}]);
    const [highlightsDescription, setHighlightsDescription] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (selectedHighDescription) {
            setHighlightsDescription(selectedHighDescription)
        }
        if (selectedHighlight?.length > 0) {
            setLists(selectedHighlight)
        }
    }, [selectedHighlight, selectedHighDescription]);

    const handleSubmit = async () => {

        const payload = {
            highlights_description: highlightsDescription,
            highlights: lists.filter(t => t.title)
        }
        if (payload.highlights.length === 0) {
            toast.error('Please add at least one highlights');
            return;
        }
        // setHighlightDescriptionBasic(highlightsDescription)
        // setHighlights(lists.filter(t => t.title))
        const res = await insertProjectHighlights(projectUuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            closeModal();
            console.log(res.data.data)
            getHighLights()
        }
        closeModal();
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Project highlights</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100  overflow-auto flex-column px-20`}>
                <div className=''>
                    <TextEditor data={highlightsDescription} onChange={(value) => setHighlightsDescription(value)} />
                </div>
                {
                    lists.map((list, index) => (
                        <div key={index}>
                            <div className='flex justify-between'>
                                <label htmlFor=""> Title {index + 1}</label>
                                {lists.length > 0 && <button
                                    onClick={() => {
                                        if (lists.length > 1) {
                                            const newList = [...lists]
                                            newList.splice(index, 1)
                                            setLists(newList);
                                        } else {
                                            setLists(['']);
                                        }
                                    }}
                                ><CloseIcon /></button>}
                            </div>
                            <InputText
                                value={list?.title}
                                placeholder={'Enter Title'}
                                onChange={(e) => {
                                    const newList = [...lists]
                                    newList[index]['title'] = e.target.value;
                                    setLists(newList);
                                }}
                            />
                            <InputTextarea
                                label={'Description'}
                                placeholder={'Enter Description'}
                                value={list?.description}
                                inputclass={'!mt-4'}
                                onChange={(e) => {
                                    const newList = [...lists]
                                    newList[index]['description'] = e.target.value;
                                    setLists(newList);
                                }}
                            />

                        </div>
                    ))
                }
                <div className='flex justify-end'>
                    <button className='bg-primary text-white rounded-lg w-max p-1' onClick={() => {
                        setLists([...lists, {}])
                    }}>+ Add More</button>
                </div>

            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={handleSubmit} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default ProjectKeyPillerModal;