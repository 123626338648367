import React, { useEffect, useState } from 'react'
import CenterModal from '../../../../components/Modals/center-modal';
import { ReactComponent as BackICon } from '../../../../assets/icons/back.svg';
import InputText from '../../../../components/InputGroup/InputText';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import AddCourseModal from './AddCourseModal';
import AddWorkshopModal from './AddWorkshopModal';
import AddGuideModal from './AddGuideModal';
import Dropdown from '../../../../components/InputGroup/dropdown';
import style from '../../../../modules/Settings/Projects/style.module.css';
import { getAllCourses, getAllGuides, getAllLiveWorkshops, insertSubscriptionPaymentPlan, updatePaymentPlan } from '../../../../services/public/public.service';
import TooltipText from '../../../../components/Tooltip';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import AddVideoModal from './AddVideoModal';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

const PLAN_TYPE = [
    {
        label: 'Free',
        value: 'Free'
    },
    {
        label: 'Premium',
        value: 'Premium'
    },
    {
        label: 'Freemium',
        value: 'Freemium'
    }
]
const PLAN_DURATION = [
    {
        label: 'Days',
        value: 'days'
    },
    {
        label: 'Week',
        value: 'week'
    },
    {
        label: 'Month',
        value: 'month'
    },

    {
        label: 'Year',
        value: 'year'
    }
]

const PAYMENT_TYPE = [
    {
        label: 'One Time',
        value: 'one time'
    },
    {
        label: 'Subscription',
        value: 'subscription'
    }
]

const CreatePlanModal = ({ show, closeModal, getAll, selectedPlan }) => {
    const [planName, setPlanName] = useState('');
    const [validity, setValidity] = useState('');
    const [validityUnit, setValidityUnit] = useState('')
    const [planType, setPlanType] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentType, setPaymentType] = useState('')
    const [duration, setDuration] = useState('');
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState([]);
    const [selectedGuide, setSelectedGuide] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [videos, setVideos] = useState([]);
    const [showAddCourseModal, setShowAddCourseModal] = useState(false);
    const [showAddWorkshopModal, setShowAddWorkshopModal] = useState(false);
    const [showGuideModal, setShowAddGuideModal] = useState(false);
    const [showAddVideoModal, setShowAddVideoModal] = useState(false);
    const [workshopName, setWorkshopName] = useState('');
    const [guides, setGuides] = useState([]);
    const [courses, setCourses] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [viewAllCourse, setViewAllCourse] = useState(false);
    const [viewAllGuide, setViewAllGuide] = useState(false);
    const [viewAllWorkshop, setViewAllWorkshop] = useState(false);
    const [viewAllVideo, setViewAllVideo] = useState(false);

    const getAllGuide = async (searchText) => {
        const res = await getAllGuides({ searchText })
        if (res.data.status === 200) {
            setGuides(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })));
        }
    }

    const getAllActiveCourses = async (searchText) => {
        const res = await getAllCourses({ name: searchText });
        if (res.data.status === 200) {
            setCourses(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })));
        }
    }

    const filterWorkshop = (workshops) => {
        let recordings = workshops.filter(el => el.status === 'COMPLETED' && el.recording_url)
        let workshop = workshops.filter(el => !el.recording_url);
        setVideos(recordings.map(i => ({ ...i, label: i.name, value: i.id })));
        setWorkshops(workshop.map(i => ({ ...i, label: i.name, value: i.id })));
    };

    const getWorkshop = async () => {
        await getAllLiveWorkshops()
            .then((res) => {
                filterWorkshop(res.data)
                // setWorkshops(res.data.map(i => ({ ...i, label: i.name, value: i.id })));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getWorkshop();
        getAllActiveCourses();
        getAllGuide();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedPlan).length > 0 &&
            courses.length > 0 && workshops.length > 0 &&
            guides.length > 0 && videos.length > 0
        ) {
            console.log(selectedPlan)
            setPlanName(selectedPlan?.name);
            setValidity(selectedPlan?.validity)
            setValidityUnit(selectedPlan?.validity_unit)
            setPlanType(selectedPlan?.type)
            setPaymentAmount(selectedPlan?.amount)
            setPaymentType(selectedPlan?.payment_type)
            setDuration(selectedPlan?.video_duration)
            setSelectedCourse(selectedPlan?.courses ? courses.filter(i => selectedPlan?.courses?.includes(i.id)) : [])
            setSelectedWorkshop(selectedPlan?.workshops ? workshops.filter(i => selectedPlan?.workshops?.includes(i.id)) : [])
            setSelectedGuide(selectedPlan?.guides ? guides.filter(i => selectedPlan?.guides?.includes(i.id)) : [])
            setSelectedVideo(selectedPlan?.videos ? videos.filter(i => selectedPlan?.videos?.includes(i.id)) : [])

            setWorkshopName(selectedPlan.workshopName)

            setViewAllCourse(selectedPlan?.view_all_courses || false)
            setViewAllGuide(selectedPlan?.view_all_guides || false)
            setViewAllWorkshop(selectedPlan?.view_all_workshops || false)
            setViewAllVideo(selectedPlan?.view_all_videos || false)

        }
    }, [selectedPlan, courses, workshops, guides, videos]);

    const handleSubmit = async () => {
        const payload = {
            name: planName,
            validity: validity,
            validity_unit: validityUnit,
            type: planType,
            payment_type: paymentType,
            amount: paymentAmount,
            workshops: selectedWorkshop.length ? selectedWorkshop.map(i => i.id) : null,
            view_all_workshops: viewAllWorkshop,
            videos: selectedVideo.length ? selectedVideo.map(i => i.id) : null,
            view_all_videos: viewAllVideo,
            guides: selectedGuide.length ? selectedGuide.map(i => i.id) : null,
            view_all_guides: viewAllGuide,
            courses: selectedCourse.length ? selectedCourse.map(i => i.id) : null,
            view_all_courses: viewAllCourse,
            can_purchase_again: false
        }

        let res = {}
        if (selectedPlan?.uuid) {
            res = await updatePaymentPlan(selectedPlan?.uuid, payload);
        } else {
            res = await insertSubscriptionPaymentPlan(payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAll();
            closeModal();
        }
    };

    return (
        <>
            <CenterModal show={show} closeModal={closeModal}>
                <div className='relative'>
                    <div className='p-4 flex items-center justify-between'>
                        {/* <button onClick={closeModal} className='mr-2'>
                            <BackICon />
                        </button> */}
                        <h3 className='text-lg font-semibold m-0'>{selectedPlan?.uuid ? 'Edit' : 'Create'} Plan</h3>
                        <button onClick={closeModal} className='mr-2'>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className='!px-4 h-[85vh] overflow-auto' >
                        <div className='grid grid-cols-2 !gap-4'>
                            <InputText
                                label={'Plan Name'}
                                inputclass='m-0 inter'
                                placeholder={'Enter Plan Name'}
                                value={planName}
                                onChange={(e) => setPlanName(e.target.value)}
                            />
                            <div className='form-group position-relative m-0'>
                                <label className='block text-black700 text-sm mb-2 font-medium'>Plan Validity </label>

                                <Dropdown
                                    list={PLAN_DURATION}
                                    onChange={(value) => setValidityUnit(value.value)}
                                    value={validityUnit ? PLAN_DURATION.find(i => i.value === validityUnit) : ''}
                                    style={style}
                                />

                                <input
                                    type='number'
                                    className={'form-control text-input'}
                                    placeholder='e.g. 10'
                                    value={validity}
                                    onChange={(e) => setValidity(e.target.value)}
                                />
                            </div>
                            <InputSelect
                                label={'Plan Type'}
                                inputclass='m-0 inter'
                                options={PLAN_TYPE}
                                placeholder={'Select Plan Type'}
                                value={planType ? PLAN_TYPE.find(i => i.value === planType) : ''}
                                onChange={(value) => setPlanType(value.value)}
                            />
                            <InputText
                                label={'Payment Amount'}
                                inputclass='m-0 inter'
                                placeholder={'Enter Payment Amount'}
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                            />
                            <InputSelect
                                label={'Payment Type'}
                                inputclass='m-0 inter'
                                options={PAYMENT_TYPE}
                                placeholder={'Select Payment Type'}
                                value={paymentType ? PAYMENT_TYPE.find(i => i.value === paymentType) : ''}
                                onChange={(value) => setPaymentType(value.value)}
                            />
                            {/* Courses */}
                            <div>

                                <div className='flex justify-between items-center'>
                                    <label htmlFor="">Select Course</label>
                                    <div className='flex items-center'>
                                        <input type="checkbox" value={viewAllCourse} checked={viewAllCourse} onChange={() => setViewAllCourse(!viewAllCourse)} />
                                        <label htmlFor="" className='ml-2 mb-0'>View All Course</label>
                                    </div>
                                </div>
                                {
                                    selectedCourse.length > 0 ?
                                        <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                            <div className="flex flex-wrap items-center">

                                                {
                                                    selectedCourse.map((course, index) => (
                                                        index < 3 &&
                                                        <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 p-1 w-max">
                                                            {course.length > 30 ? <TooltipText title={course.name || ''}>
                                                                <p className=" truncate m-0">{course.name}</p>
                                                            </TooltipText> : <p className="m-0">{course.name}</p>}

                                                            {/* <CloseIcon className="h-4 w-4 ml-1 cursor-pointer" onClick={() => handleRemoveProject(guide.uuid)} /> */}
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    selectedCourse.length > 3 &&
                                                    <div className="tag-dropdown" >
                                                        <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            +{selectedCourse.length - 3}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {selectedCourse.map((item, index) => {
                                                                return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                </a>

                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <TooltipText title='Edit'>
                                                <div className="border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                    setShowAddCourseModal(true)
                                                    // setSelectedItem(user)
                                                }}>
                                                    <EditIcon />
                                                </div>
                                            </TooltipText>
                                        </div>
                                        :
                                        <div className='cursor-pointer'>
                                            <button
                                                className='border w-full text-left py-2.5 !pl-4 rounded-lg text-black700 disabled:bg-transparent disabled:hover:bg-transparent disabled:text-black700 '
                                                onClick={() => setShowAddCourseModal(true)}
                                                disabled={viewAllCourse}
                                            >
                                                Select Course
                                            </button>
                                        </div>
                                }
                            </div>
                            {/* Guides */}

                            <div>

                                <div className='flex justify-between items-center'>
                                    <label htmlFor="">Select Guide</label>
                                    <div className='flex items-center'>
                                        <input type="checkbox" value={viewAllGuide} checked={viewAllGuide} onChange={() => setViewAllGuide(!viewAllGuide)} />
                                        <label htmlFor="" className='ml-2 mb-0'>View All Guides</label>
                                    </div>
                                </div>
                                {
                                    selectedGuide.length > 0 ?
                                        <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                            <div className="flex flex-wrap items-center">

                                                {
                                                    selectedGuide.map((guide, index) => (
                                                        index < 3 &&
                                                        <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 p-1 w-max">
                                                            {guide.length > 30 ? <TooltipText title={guide.name || ''}>
                                                                <p className=" truncate m-0">{guide.name}</p>
                                                            </TooltipText> : <p className="m-0 w-full">{guide.name}</p>}

                                                            {/* <CloseIcon className="h-4 w-4 ml-1 cursor-pointer" onClick={() => handleRemoveProject(guide.uuid)} /> */}
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    selectedGuide.length > 3 &&
                                                    <div className="tag-dropdown" >
                                                        <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            +{selectedGuide.length - 3}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {selectedGuide.map((item, index) => {
                                                                return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                </a>

                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <TooltipText title='Edit'>
                                                <div className="border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                    setShowAddGuideModal(true)
                                                    // setSelectedItem(user)
                                                }}>
                                                    <EditIcon />
                                                </div>
                                            </TooltipText>
                                        </div>
                                        :
                                        <div className='cursor-pointer'>
                                            <button
                                                className='border w-full text-left py-2.5 !pl-4 rounded-lg text-black700 disabled:bg-transparent disabled:hover:bg-transparent disabled:text-black700 '
                                                onClick={() => setShowAddGuideModal(true)}
                                                disabled={viewAllGuide}
                                            >
                                                Select Guide
                                            </button>
                                        </div>
                                }
                            </div>
                            {/* Workshop */}
                            <div>

                                <div className='flex justify-between items-center'>
                                    <label htmlFor="">Select Workshop</label>
                                    <div className='flex items-center'>
                                        <input type="checkbox" value={viewAllWorkshop} checked={viewAllWorkshop} onChange={() => setViewAllWorkshop(!viewAllWorkshop)} />
                                        <label htmlFor="" className='ml-2 mb-0'>View All Workshop</label>
                                    </div>
                                </div>
                                {
                                    selectedWorkshop.length > 0 ?
                                        <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                            <div className="flex flex-wrap items-center">

                                                {
                                                    selectedWorkshop.map((workshop, index) => (
                                                        index < 3 &&
                                                        <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 p-1 w-max">
                                                            {workshop.length > 30 ? <TooltipText title={workshop.name || ''}>
                                                                <p className=" truncate m-0">{workshop.name}</p>
                                                            </TooltipText> : <p className="m-0">{workshop.name}</p>}

                                                            {/* <CloseIcon className="h-4 w-4 ml-1 cursor-pointer" onClick={() => handleRemoveProject(guide.uuid)} /> */}
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    selectedWorkshop.length > 3 &&
                                                    <div className="tag-dropdown" >
                                                        <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            +{selectedWorkshop.length - 3}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {selectedWorkshop.map((item, index) => {
                                                                return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                </a>

                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <TooltipText title='Edit'>
                                                <div className="border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                    setShowAddWorkshopModal(true)
                                                    // setSelectedItem(user)
                                                }}>
                                                    <EditIcon />
                                                </div>
                                            </TooltipText>
                                        </div>
                                        :
                                        <div className='cursor-pointer'>
                                            <button
                                                className='border w-full text-left py-2.5 !pl-4 rounded-lg text-black700 disabled:bg-transparent disabled:hover:bg-transparent disabled:text-black700 '
                                                onClick={() => setShowAddWorkshopModal(true)}
                                                disabled={viewAllWorkshop}
                                            >
                                                Select Workshop
                                            </button>
                                        </div>
                                }
                            </div>
                            {/* Videos */}
                            <div>
                                <div className='flex justify-between items-center'>
                                    <label htmlFor="">Select Video</label>
                                    <div className='flex items-center'>
                                        <input type="checkbox" value={viewAllVideo} checked={viewAllVideo} onChange={() => setViewAllVideo(!viewAllVideo)} />
                                        <label htmlFor="" className='ml-2 mb-0'>View All Video</label>
                                    </div>
                                </div>
                                {
                                    selectedVideo.length > 0 ?
                                        <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                            <div className="flex flex-wrap items-center">

                                                {
                                                    selectedVideo.map((video, index) => (
                                                        index < 3 &&
                                                        <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 p-1 w-max">
                                                            {video.length > 30 ? <TooltipText title={video.name || ''}>
                                                                <p className=" truncate m-0">{video.name}</p>
                                                            </TooltipText> : <p className="m-0">{video.name}</p>}

                                                            {/* <CloseIcon className="h-4 w-4 ml-1 cursor-pointer" onClick={() => handleRemoveProject(guide.uuid)} /> */}
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    selectedVideo.length > 3 &&
                                                    <div className="tag-dropdown" >
                                                        <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            +{selectedVideo.length - 3}
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {selectedVideo.map((item, index) => {
                                                                return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                </a>

                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <TooltipText title='Edit'>
                                                <div className="border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                    setShowAddVideoModal(true)
                                                    // setSelectedItem(user)
                                                }}>
                                                    <EditIcon />
                                                </div>
                                            </TooltipText>
                                        </div>
                                        :
                                        <div className='cursor-pointer'>
                                            <button
                                                className='border w-full text-left py-2.5 !pl-4 rounded-lg text-black700 disabled:bg-transparent disabled:hover:bg-transparent disabled:text-black700'
                                                onClick={() => setShowAddVideoModal(true)}
                                                disabled={viewAllVideo}
                                            >
                                                Select Video
                                            </button>
                                        </div>
                                }
                            </div>
                            <InputText
                                label={'Video Duration'}
                                inputclass='m-0 inter'
                                placeholder={'Enter Video Duration'}
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                            <InputText
                                label={'Workshop Name'}
                                inputclass='m-0 inter'
                                placeholder={'Enter Workshop Name'}
                                value={workshopName}
                                onChange={(e) => setWorkshopName(e.target.value)}
                            />

                        </div>

                        <div className=" flex justify-end !mt-5 !px-4">
                            <button className=' text-black700 border rounded-lg !px-4 !py-2 !mr-4' onClick={closeModal}>Cancel</button>
                            <button className='bg-primary text-white rounded-lg !px-4 !py-2' onClick={handleSubmit}>{selectedPlan?.uuid ? 'Update' : 'Save'} Plan</button>
                        </div>
                    </div>
                </div>

            </CenterModal>
            {showAddCourseModal && <AddCourseModal
                show={showAddCourseModal}
                closeModal={() => setShowAddCourseModal(false)}
                courses={courses}
                selectedCourse={selectedCourse}
                setSelectedCourse={setSelectedCourse}
            />}
            {showAddWorkshopModal && <AddWorkshopModal
                show={showAddWorkshopModal}
                closeModal={() => setShowAddWorkshopModal(false)}
                workshops={workshops}
                selectedWorkshop={selectedWorkshop}
                setSelectedWorkshop={setSelectedWorkshop}
            />}
            {showGuideModal && <AddGuideModal
                show={showGuideModal}
                closeModal={() => setShowAddGuideModal(false)}
                guides={guides}
                selectedGuide={selectedGuide}
                setSelectedGuide={setSelectedGuide}
            />}
            {
                showAddVideoModal && <AddVideoModal
                    show={showAddVideoModal}
                    closeModal={() => setShowAddVideoModal(false)}
                    videos={videos}
                    selectedVideo={selectedVideo}
                    setSelectedVideo={setSelectedVideo}
                />
            }
        </>
    )
}

export default CreatePlanModal