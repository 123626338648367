import React from 'react'
import SmallCenterModal from '../../../../../components/Modals/small-center-modal';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert.svg';

const WarningModal = ({ show, closeModal, role, users }) => {
    return (
        <SmallCenterModal show={show} closeModal={closeModal}>
            <div className='p-4'>
                <div className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                    <AlertIcon />
                </div>
                <h6 className='mb-2.5'>
                    Caution!
                </h6>
                <p className='black-dark-800 fz16 po'>There are users present in this role, please change their role in order to delete <b>{role}</b> role</p>
                <div className='h-[150px] overflow-auto pr-2 relative '>
                    <div class="grid grid-cols-2 border border-b-0 sticky top-0 bg-white">
                        <div className='p-2 bg-grey-400 font-semibold  border-r'>S.No</div>
                        <div className='p-2 bg-grey-400 font-semibold'>Name</div>
                    </div>

                    {
                        users.map((item, index) => (
                            <div class="grid grid-cols-2 border" key={`${item.uuid}_${index}`}>
                                <div className='p-2 border-r'>{index + 1}</div>
                                <div className='p-2 border-r capitalize '>{item.name}</div>
                            </div>

                        ))
                    }
                </div>
                <div className={` flex flex-row items-center justify-between w-100 space-x-3 mt-4`}>

                    <button className='pr-btn w-100 py-2' style={{ fontSize: '16px' }} onClick={() => {
                        closeModal();
                    }}>Okay</button>
                </div>
            </div>

        </SmallCenterModal>
    )
}

export default WarningModal