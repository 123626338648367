import React from 'react'
import CenterModal from '../../../components/Modals/center-modal'
import { ReactComponent as InfoIcon } from '../../../assets/icons/Blue_info.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import SmallCenterModal from '../../../components/Modals/small-center-modal';

const AddBokingModal = ({ show, closeModal, setShowBookingModal, setLeadType, closeUnitModal }) => {
    return (
        <SmallCenterModal show={show} size='md-down' closeModal={closeModal} dialogClassName={'w-[400px]'}>
            <div className='!p-6'>
                <div className='flex items-center justify-between'>
                    <InfoIcon />
                    <button>
                        <CloseIcon />
                    </button>
                </div>
                <div className='!mt-5 text-lg font-semibold'>
                    Booking Unit for New Lead?
                </div>
                <p className='mb-0 !mt-2 text-sm text-grey-500'>Select if you are booking this unit for and existing Lead or a new Lead.</p>
                <div className='!mt-8 grid grid-cols-2 gap-3'>
                    <button
                        className='border border-grayLight py-2.5 text-center rounded-xl font-medium'
                        onClick={() => {
                            setShowBookingModal(true);
                            setLeadType('Existing');
                            closeModal();
                            closeUnitModal();
                        }}
                    >
                        Existing Lead
                    </button>
                    <button
                        className='border border-primary bg-primary  text-white  py-2.5 rounded-xl text-center font-medium'
                        onClick={() => {
                            setShowBookingModal(true);
                            setLeadType('New');
                            closeModal();
                            // closeUnitModal();
                        }}
                    >
                        New Lead
                    </button>
                </div>
            </div>
        </SmallCenterModal>
    )
}

export default AddBokingModal