import makeRequest from "../services/api/makeRequest";
import url from "../services/api/urls";
import { RequstMethods } from "../services/api/requestMethods";

const courses = {

    async getAll() {
        return await makeRequest(url.learning_center.courses.get, RequstMethods.GET)
    },
    async getActiveCategory() {
        return await makeRequest(url.learning_center.category.getActiveCategory, RequstMethods.GET);
    },

    async createBasicCourse(payload) {
        return await makeRequest(url.learning_center.courses.createBasicCourse, RequstMethods.POST, payload);
    },

    async updateBasicDetails(uuid, payload) {
        return await makeRequest(`${url.learning_center.courses.updateBasicDetails}/${uuid}`, RequstMethods.PUT, payload);
    },

    async addCourseContent(uuid, payload) {
        return await makeRequest(`${url.learning_center.courses.addCourseContent}/${uuid}`, RequstMethods.POST, payload);
    },

    async updateCourseContent(uuid, payload) {
        return await makeRequest(`${url.learning_center.courses.updateCourseContent}/${uuid}`, RequstMethods.PUT, payload);
    },

    async getRecordingCollection() {
        return await makeRequest(url.learning_center.courses.getRecordingCollection, RequstMethods.GET)
    },

    async updateCourseExtraDetails(uuid, payload) {
        return await makeRequest(`${url.learning_center.courses.updateCourseExtraDetails}/${uuid}`, RequstMethods.PUT, payload)
    },

    async getCourseContent(uuid) {
        return await makeRequest(`${url.learning_center.courses.getCourseContent}/${uuid}`, RequstMethods.GET);
    },
    async getAllFaqs(uuid) {
        return await makeRequest(`${url.learning_center.courses.getAllFaqs}/${uuid}`, RequstMethods.GET);
    },

    async getCourseByUUID(uuid) {
        return await makeRequest(`${url.learning_center.courses.getCourseByUUID}/${uuid}`, RequstMethods.GET);
    },

    async updateCourseState(uuid, payload) {
        return await makeRequest(`${url.learning_center.courses.updateCourseState}/${uuid}`, RequstMethods.PUT, payload);
    },
}

export default courses;