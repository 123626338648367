import moment from 'moment';
import React from 'react'
import { useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DisableReEngageModal = ({ show, closeModal, onSubmit }) => {

    return (
        <Modal
            className="del-modal enablereengagementmodal modal-background"
            show={show} onHide={closeModal}
            centered
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <div className='csv-export-modal'>
                    {/* <div className='csv-export-header'>
                        <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0361 16.7539V26.7539C23.0361 27.8585 23.9316 28.7539 25.0361 28.7539C26.1407 28.7539 27.0361 27.8585 27.0361 26.7539V16.7539C27.0361 15.6493 26.1407 14.7539 25.0361 14.7539C23.9316 14.7539 23.0361 15.6493 23.0361 16.7539Z" fill="white" />
                            <path d="M27.0361 32.7539C27.0361 33.8585 26.1407 34.7539 25.0361 34.7539C23.9316 34.7539 23.0361 33.8585 23.0361 32.7539C23.0361 31.6493 23.9316 30.7539 25.0361 30.7539C26.1407 30.7539 27.0361 31.6493 27.0361 32.7539Z" fill="white" />
                            <path d="M21.827 4.93212C23.3123 2.62331 26.6877 2.62331 28.173 4.93213L47.2403 34.5704C48.8556 37.0813 47.0529 40.3846 44.0673 40.3846H5.9327C2.94714 40.3846 1.14439 37.0813 2.75971 34.5704L21.827 4.93212Z" stroke="white" strokeWidth="3.39562" strokeLinejoin="round" />
                        </svg>

                    </div> */}
                    <h5 className='fz14 black fw-po-medium ls1 flex justify-center'><svg className='mr-8' xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
                        <path d="M16.8554 4.97363C17.8456 3.43442 20.0958 3.43442 21.086 4.97363L35.9626 28.0979C37.0395 29.7718 35.8377 31.974 33.8473 31.974H4.09411C2.10373 31.974 0.901903 29.7718 1.97878 28.0979L16.8554 4.97363Z" fill="#FF974A" stroke="#FF974A" strokeWidth="2.26374" strokeLinejoin="round" />
                        <path d="M17.5 14.25V21.75C17.5 22.5784 18.1716 23.25 19 23.25C19.8284 23.25 20.5 22.5784 20.5 21.75V14.25C20.5 13.4216 19.8284 12.75 19 12.75C18.1716 12.75 17.5 13.4216 17.5 14.25Z" fill="white" />
                        <path d="M20.5 26.25C20.5 27.0784 19.8284 27.75 19 27.75C18.1716 27.75 17.5 27.0784 17.5 26.25C17.5 25.4216 18.1716 24.75 19 24.75C19.8284 24.75 20.5 25.4216 20.5 26.25Z" fill="white" />
                    </svg>  Allow Duplicate Leads in platform?</h5>
                    <p className='black-dark-800 fz16 po'>Allow creation of duplicate leads with same mobile no. in the platform.</p>
                    <div className="justify-content-center d-flex modal-footer">
                        <button variant="" className="cancel outline-btn" onClick={closeModal}>
                            Cancel
                        </button>
                        <button variant="" className='pr-btn text-white' onClick={onSubmit}>
                            Yes, Confirm
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DisableReEngageModal;