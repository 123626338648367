import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as SearchCloseIcon } from '../../../assets/icons/searchClose.svg';
import { ReactComponent as Vthreedots } from '../../../assets/icons/threedots.svg';
import { Link } from 'react-router-dom';
import CreatePlanModal from './Modal/CreatePlanModal';
import { getAllSubscription } from '../../../services/public/public.service';

const Plans = () => {
    const [searchText, setSearchText] = useState('');
    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false)
    const [allPlans, setAllPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});

    const getAll = async () => {
        const res = await getAllSubscription();
        if (res.data.status === 200) {
            setAllPlans(res.data.data)
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    return (
        <div className="users w-100 h-full flex flex-col">
            <div className="!p-5">
                <div className="row d-flex d-flex flex-md-fill flex-wrap">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="fz24 m-0 black fw-po-medium">Plus App Plans</h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <div>
                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder='Search Plan'
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                        </div>
                        <div className="mr-12">
                            {/* <Link  className={`pr-btn`} to="/settings/cp-manager/plans/create-plans" >
                                + New Plan
                            </Link> */}
                            <button className={`pr-btn`} onClick={() => setShowCreatePlanModal(true)} >
                                + New Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user-table ml-24">
                <div className="def-table pr-24 !h-[82vh]">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Plan Name</th>
                                <th>User Subscribed</th>
                                <th> Plan Validity</th>
                                <th>Type of Plan</th>
                                <th>Date Added</th>
                                <th>Workshops </th>
                                <th>Videos</th>
                                <th>Guides</th>
                                <th>Plan URL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allPlans.map((plan, index) => (
                                    <tr key={plan.uuid}>
                                        <td>{index + 1}</td>
                                        <td>{plan.name || '-'}</td>
                                        <td>{'-'}</td>
                                        <td>{plan.validity ? `${plan.validity} ${plan.validity_unit}` : '-'}</td>
                                        <td className='capitalize'>{plan?.type || '-'}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td className='dropdown view-more'>
                                            <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <Vthreedots />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item"
                                                    onClick={() => {
                                                        setSelectedPlan(plan);
                                                        setShowCreatePlanModal(true)
                                                    }}
                                                >
                                                    Edit Plan
                                                </a>
                                                <a className="dropdown-item del-menu"
                                                >
                                                    Delete
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {showCreatePlanModal && <CreatePlanModal
                show={showCreatePlanModal}
                closeModal={() => {
                    setSelectedPlan({});
                    setShowCreatePlanModal(false)
                }}
                getAll={getAll}
                selectedPlan={selectedPlan}
            />}
        </div>
    )
}

export default Plans