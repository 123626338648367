import React, { useState } from 'react';
import BasicModal from './BasicModal';
import { ReactComponent as NavRight } from '../../assets/icons/NavRight.svg';
import { ReactComponent as Owlleft } from '../../assets/icons/owlleft.svg';
import { ReactComponent as OwlRight } from '../../assets/icons/owlright.svg';
import CustomerDetails from '../Booking_EOI/Qote/CustomerDetails';
import QoteUnitDetail from '../Booking_EOI/Qote/QoteUnitDetail';
import QotePricing from '../Booking_EOI/Qote/QotePricing';
import QotePaymentplan from '../Booking_EOI/Qote/QotePaymentplan';
import QoteTotal from '../Booking_EOI/Qote/QoteTotal';



const QoteModal = ({ show, closeModal }) => {

    const [steps, setSteps] = useState('QoteTotal');

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='pt-6 relative'>
                    <h1 className='sm:mx-6 mx-3 text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-4 inter'>Quote for $Customer_Name</h1>
                    <div className='flex items-center no-scrollbar border-b border-[#E2E2EA]'>
                        <button className='absolute left-1 lg:hidden	'><Owlleft /></button>
                        <ul className='flex gap-x-3 items-center mb-0 pb-2  md:!px-0 lg:w-[100%] sm:w-[90%] w-[80%] md:!mx-5  !mx-auto no-scrollbar lg:overflow-hidden overflow-auto'>
                            <li className="flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 "><span className={`${steps === 'CustomerDetails' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Customer Details</span> <NavRight /></li>
                            <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'><span className={`${steps === 'QoteUnitDetail' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Unit Details</span> <NavRight /></li>
                            <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'><span className={`${steps === 'QotePricing' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Pricing</span> <NavRight /></li>
                            <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'><span className={`${steps === 'QotePaymentplan' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Payment Plan</span><NavRight /></li>
                            <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'><span className={`${steps === 'QoteTotal' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Total </span></li>

                        </ul>
                        <button className='absolute right-1 lg:hidden'><OwlRight /></button>
                    </div>
                </div>
                {steps === 'CustomerDetails' && <CustomerDetails setSteps={setSteps} />}
                {steps === 'QoteUnitDetail' && <QoteUnitDetail setSteps={setSteps} />}
                {steps === 'QotePricing' && <QotePricing setSteps={setSteps} />}
                {steps === 'QotePaymentplan' && <QotePaymentplan setSteps={setSteps} />}
                {steps === 'QoteTotal' && <QoteTotal setSteps={setSteps} />}


            </div>

        </BasicModal >
    )
}

export default QoteModal
