const url = {
    notes: {
        insert: "/notes/insert",
        getAllByLeadUUID: "/auth/getAllByLeadUUID",
    },
    followups: {
        insert: "/followups/insert",
        getAllByLeadUUID: "/followups/getAllByLeadUUID",
    },
    siteVisits: {
        insert: "/siteVisits/insert",
        getAllByLeadUUID: "/siteVisits/getAllByLeadUUID",
    },
    getUsers: {
        insert: "/company/getUser",
        getAllByUsersUUID: "/company/getUser/getAllByUsersUUID"
    },
    addUsers: {
        insert: "/company/inviteUser",
        addAllByUsersUUID: "/company/inviteUser/addAllByUsersUUID"
    },
    learning_center: {
        workshop: {
            getAllWorkshop: "/workshop/crm/getAllWorkshop",
            getWorkshopBy_uuid: "/workshop/crm/getWorkshopByUuid",
            getWorkshopFaqs: "/workshop/crm/getWorkshopFaqs",
            getWorkshopEnrolledUsers: "/workshop/crm/getWorkshopEnrolledUsers",
            getWorkshopUsersbyUuid: "/workshop/crm/getAllWorkshopUsers",
            createWorkshop: "/workshop/crm/createWorkshop",
            getAllWorkshopStatuses: "/workshop/crm/getAllWorkshopStatuses",
            changeWorkshopState: "/workshop/crm/changeWorkshopState",
            updateWorkshopByUUID: "/workshop/crm/updateWorkshopByUUID",
            deleteWorkshopByUUID: "/workshop/crm/deleteWorkshopById",
            getWorkshopMentors: "/workshop/crm/getWorkshopMentor"
            // update:"/workshop/updateWorkshop/:id",
            // delete:"/workshop/:id"
        },
        mentor: {
            getAllMentor: "/mentors/crm/getallmentors",
            createMentor: "/mentors/crm/addmentor",
            getAllCategories: "/mentors/crm/getAllCategories",
            updateMentorData: "mentors/crm/updateMentorData",
            getMentorById: "/mentors/crm/getMentorByUUID",
            deletementor: "/mentors/crm/deletementor",
            // create:"/mentor/newMentor",
            // update:"/mentor/updateMentor/:id",
            // delete:"/mentor/:id"
        },
        guide: {
            get: "/guides/crm/getAllAdmin",
            insert: "/guides/crm/insert",
            update: "/guides/crm/updateByUUID",
            updateStatus: '/guides/crm/updateStatusByUUID',
            // delete:"/guide/:id"
            upload: "/guides/crm/insert"
        },
        category: {
            get: "/courses/crm/getLcCategories",
            create: '/courses/crm/insertCategory',
            update: '/courses/crm/updateCategory',
            updateStatus: '/courses/crm/updateCategoryState',
            getActiveCategory: '/courses/getLcActiveCategories'
        },
        courses: {
            get: '/courses/crm/getAllCourses',
            createBasicCourse: '/courses/crm/create',
            updateBasicDetails: '/courses/crm/updateCourseBasicDetails',
            addCourseContent: '/courses/crm/insertContent',
            updateCourseContent: '/courses/crm/updateCourseContent',
            getRecordingCollection: '/courses/crm/getCompletedWorkshops',
            updateCourseExtraDetails: '/courses/crm/updateCourseExtraDetails',
            getCourseContent: '/courses/getCourseContent',
            getAllFaqs: '/courses/getAllFaqs',
            getCourseByUUID: '/courses/getCourseByUUID',
            updateCourseState: '/courses/crm/updateCourseState'
        },
    },
    company: {
        getAllCompany: '/pr0p/getAllCompanies',
        getCompanyUsers: '/pr0p/getCompanyUsers',
        getCompanyLeads: '/pr0p/getCompanyLeads',
        userLogout: '/pr0p/doUserLogout',
        inviteUser: '/pr0p/resetUserPassword',
        uploadFile: '/pr0p/crm/getS3Url',
    }
}

export default url;