import React, { useState } from 'react'
import RightModal from '../../../../../components/Modals/RightModal';
import TooltipText from '../../../../../components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';

const CopyPermissionModal = ({ show, closeModal, masterRoles, handleSubmit }) => {
    const [selectedPermission, setSelectedPermissions] = useState('');

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Copy Permissions From
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className="px-4 mt-4">
                <div className="">
                    <div className="flex flex-col space-y-2.5 ">
                        {
                            masterRoles.map((item, index) => (
                                <div className="flex justify-between cursor-pointer border rounded-xl p-3"
                                    key={item.uuid}
                                    onClick={() => setSelectedPermissions(item)}
                                >
                                    <div className=" m-0 text-md leading-5 flex items-center">
                                        <span>{item.name}</span>
                                        {item.description && <TooltipText title={item.description}>
                                            <InfoIcon className="ml-1" />
                                        </TooltipText>}
                                    </div>
                                    <input type="radio" name='role' value={item.name} checked={selectedPermission.uuid === item.uuid} className="h-5 w-5" />
                                </div>
                            ))
                        }

                        {/* <div className="flex justify-between border rounded-xl p-3">
                            <p className=" m-0 font-md flex items-center">
                                Custom New Role
                                <TooltipText title={'Create a new role & define permissions either copied from existing role types or completely by yourself'}>
                                    <InfoIcon className="ml-1" />
                                </TooltipText>
                            </p>
                            <input type="radio" name='role' value={'custom'} onChange={() => setSelectedRole('custom')} className="h-5 w-5" />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={` flex flex-row items-center justify-between border-top border-color bg-white absolute bottom-0 w-100 space-x-3 p-3`}>
                <button className='outline-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg hover:bg-black100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg' onClick={() => {
                    if (selectedPermission) {
                        handleSubmit(selectedPermission.allowed_permissions);
                        closeModal();
                    }
                }}>Save</button>
            </div>
        </RightModal>
    )
}

export default CopyPermissionModal