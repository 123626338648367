import React, { useEffect, useState, useCallback } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import SliderModal from "./SliderModal";
import AllowCsvExport from "./AllowCsvExport"
import {
  getRolePermissions,
  updateRolePermissions,
} from "../../services/private/company.service";
import CancelCSV from "./cancel-csv";

const EditRolesModal = ({ show, setShow, currentRoleUUID }) => {
  const [role, setRole] = useState({});
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [payload, setPayload] = useState({});

  const getRole = async () => {
    await getRolePermissions(currentRoleUUID)
      .then((res) => {
        if (res.status === 200) {
          setRole(res.data.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        console.error(e.response.data);
      });
  };

  const updateRole = async (rolesPayload) => {
    if (Object.keys(rolesPayload).length > 0) {
      await updateRolePermissions(currentRoleUUID, rolesPayload)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setShow(false);
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
          console.error(e.response.data);
        });
    }
  };

  const handleUpdatedDate = (rolesPayload) => {
    if (rolesPayload?.import_leads?.includes('csv_export')) {
      setShowCancelModal(true);
      return;
    }
    updateRole(rolesPayload);
  };

  const closeCancelCSVModal = useCallback(() => {
    setShowCancelModal(false)
  }, []);

  useEffect(() => {
    getRole();
  }, []);

  return (
    <SliderModal
      show={show}
      setShow={setShow}
      title={<div>Edit Permissions</div>}
      // titleRight={
      //   <div className="fz12 mr-3" style={{ fontSize: "12px !important" }}>
      //     Reset all
      //   </div>
      // }
      body={
        <>
          <Formik
            enableReinitialize
            initialValues={{ ...role }}
            onSubmit={async (values, actions) => {
              setPayload(values);
              handleUpdatedDate(values);
            }}
          >
            {(props) => {
              const { values, handleSubmit, setFieldValue } = props;
              return (
                <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                  <div className="sidebar-modal h-full overflow-auto !px-5 !pb-10">
                    <div id="accordion" >
                      <div className="card">
                        <div
                          className="card-header"
                          id="headingOne"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span>Lead Permissions</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z"
                              fill="#92929D"
                            ></path>
                          </svg>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse show px-20 card-data"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          <label
                            className="d-flex w-100 justify-content-between align-items-center"
                            htmlFor="3"
                          >
                            <span>Re-assign Leads</span>
                            <input
                              type="checkbox"
                              id="3"
                              name="re_assign_leads"
                              checked={values["leads"]?.includes("re_assign_leads")}
                              onChange={(e) => {
                                const reassignLeads = values["leads"] || [];
                                if (e.target.checked) {
                                  reassignLeads.push("re_assign_leads");
                                } else {
                                  reassignLeads.splice(reassignLeads.indexOf("re_assign_leads"), 1);
                                }
                                setFieldValue("leads", reassignLeads);
                              }}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between align-items-center"
                            htmlFor="4"
                          >
                            <span>Past Date Followup/Site Visit</span>
                            <input
                              type="checkbox"
                              id="4"
                              name="past_date_creation"
                              checked={values["leads"]?.includes("past_date_creation")}
                              onChange={(e) => {
                                const pastDate = values["leads"] || [];
                                if (e.target.checked) {
                                  pastDate.push("past_date_creation");
                                } else {
                                  pastDate.splice(pastDate.indexOf("past_date_creation"), 1);
                                }
                                setFieldValue("leads", pastDate);
                              }}
                            />
                          </label>
                        </div>

                      </div>
                      <div className="card">
                        <div
                          className="collapsed card-header"
                          id="headingTwo"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span>Project Permissions</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z"
                              fill="#92929D"
                            ></path>
                          </svg>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse px-20 card-data"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="1"
                          >
                            <span>User can view projects</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="projects"
                              checked={values["projects"]?.includes("read")}
                              onChange={(e) => {
                                const projects = values["projects"] || [];
                                if (e.target.checked) {
                                  projects.push("read");
                                } else {
                                  projects.splice(projects.indexOf("read"), 1);
                                }
                                setFieldValue("projects", projects);
                              }}
                              disabled={values["projects"]?.includes("write")}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="2"
                          >
                            <span>User can add, update & delete project</span>
                            <input
                              type="checkbox"
                              id="2"
                              name="projects"
                              checked={values["projects"]?.includes("write")}
                              onChange={(e) => {
                                let projects = values["projects"] || [];
                                if (e.target.checked) {
                                  projects = ["read", "write", "update", "delete"];
                                } else {
                                  projects = [];
                                }
                                setFieldValue("projects", projects);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      {/* <div className="card">
                        <div
                          className="collapsed card-header"
                          id="headingTwo"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span>User Management Permissions</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z"
                              fill="#92929D"
                            ></path>
                          </svg>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse px-20 card-data"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="1"
                          >
                            <span>Allow user to add user</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="users"
                              checked={values["users"]?.includes("write")}
                              onChange={(e) => {
                                const users = values["users"];
                                if (e.target.checked) {
                                  users.push("write");
                                } else {
                                  users.splice(users.indexOf("write"), 1);
                                }
                                setFieldValue("users", users);
                              }}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="2"
                          >
                            <span>Allow user to view all users</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="users"
                              checked={values["users"]?.includes("read")}
                              onChange={(e) => {
                                const users = values["users"];
                                if (e.target.checked) {
                                  users.push("read");
                                } else {
                                  users.splice(users.indexOf("read"), 1);
                                }
                                setFieldValue("users", users);
                              }}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="3"
                          >
                            <span>Allow user to update user</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="users"
                              checked={values["users"]?.includes("update")}
                              onChange={(e) => {
                                const users = values["users"];
                                if (e.target.checked) {
                                  users.push("update");
                                } else {
                                  users.splice(users.indexOf("update"), 1);
                                }
                                setFieldValue("users", users);
                              }}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="4"
                          >
                            <span>Allow user to delete user</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="users"
                              checked={values["users"]?.includes("delete")}
                              onChange={(e) => {
                                const users = values["users"];
                                if (e.target.checked) {
                                  users.push("delete");
                                } else {
                                  users.splice(users.indexOf("delete"), 1);
                                }
                                setFieldValue("users", users);
                              }}
                            />
                          </label>
                        </div>
                      </div> */}
                      <div className="card">
                        <div
                          className="collapsed card-header"
                          id="headingTwo"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <span>Import/Export Leads Permissions</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z"
                              fill="#92929D"
                            ></path>
                          </svg>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse px-20 card-data"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="5"
                          >
                            <span>User can import data</span>
                            <input
                              type="checkbox"
                              id="5"
                              name="import_leads"
                              value={'bulk_insert'}
                              checked={values["import_leads"]?.includes("bulk_insert")}
                              onChange={(e) => {
                                const reassignLeads = values["import_leads"] || [];
                                if (e.target.checked) {
                                  reassignLeads.push("bulk_insert");
                                } else {
                                  reassignLeads.splice(reassignLeads.indexOf("bulk_insert"), 1);
                                }
                                setFieldValue("import_leads", reassignLeads);
                              }}
                            />
                          </label>

                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="6"
                          >
                            <span>User can export data</span>
                            <input
                              type="checkbox"
                              id="6"
                              name="export_leads"
                              value={'csv_export'}
                              checked={values["import_leads"]?.includes("csv_export")}
                              onChange={(e) => {
                                const reassignLeads = values["import_leads"] || [];
                                if (e.target.checked) {
                                  reassignLeads.push("csv_export");
                                } else {
                                  reassignLeads.splice(reassignLeads.indexOf("csv_export"), 1);
                                }
                                setFieldValue("import_leads", reassignLeads);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="collapsed card-header"
                          id="headingTwo"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <span>Integration Permissions</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z"
                              fill="#92929D"
                            ></path>
                          </svg>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse px-20 card-data"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="7"
                          >
                            <span>User can view integration</span>
                            <input
                              type="checkbox"
                              id="7"
                              name="integration"
                              checked={values["integration"]?.includes("read")}
                              onChange={(e) => {
                                const integration = values["integration"] || [];
                                if (e.target.checked) {
                                  integration.push("read");
                                } else {
                                  integration.splice(
                                    integration.indexOf("read"),
                                    1
                                  );
                                }
                                setFieldValue("integration", integration);
                              }}
                              disabled={values["integration"]?.includes("write")}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="8"
                          >
                            <span>User can add, update & delete integration</span>
                            <input
                              type="checkbox"
                              id="8"
                              name="integration"
                              checked={values["integration"]?.includes("write")}
                              onChange={(e) => {
                                let integration = values["integration"] || [];
                                if (e.target.checked) {
                                  // integration.push("read");
                                  integration = ["read", "write", "update", "delete"]
                                } else {
                                  // integration.splice(
                                  //   integration.indexOf("read"),
                                  //   1
                                  // );
                                  integration = []

                                }
                                setFieldValue("integration", integration);
                              }}
                            />
                          </label>
                          {/* <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="3"
                          >
                            <span>Allow user to update</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="integration"
                              checked={values["integration"]?.includes(
                                "update"
                              )}
                              onChange={(e) => {
                                const integration = values["integration"];
                                if (e.target.checked) {
                                  integration.push("update");
                                } else {
                                  integration.splice(
                                    integration.indexOf("update"),
                                    1
                                  );
                                }
                                setFieldValue("integration", integration);
                              }}
                            />
                          </label>
                          <label
                            className="d-flex w-100 justify-content-between"
                            htmlFor="4"
                          >
                            <span>Allow user to delete</span>
                            <input
                              type="checkbox"
                              id="1"
                              name="integration"
                              checked={values["integration"]?.includes(
                                "delete"
                              )}
                              onChange={(e) => {
                                const integration = values["integration"];
                                if (e.target.checked) {
                                  integration.push("delete");
                                } else {
                                  integration.splice(
                                    integration.indexOf("delete"),
                                    1
                                  );
                                }
                                setFieldValue("integration", integration);
                              }}
                            />
                          </label> */}
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                    <button className="cancel outline-btn" type="button" onClick={() => setShow(false)}>
                      Cancel
                    </button>
                    <button className="pr-btn" type="submit">
                      Edit Role
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
          {showCancelModal && <AllowCsvExport
            show={showCancelModal}
            closeCancelModal={closeCancelCSVModal}
            onSubmit={() => updateRole(payload)}

          />}
        </>
      }
    />
  );
};

export default EditRolesModal;
