import makeRequest, { formRequest } from "../services/api/makeRequest";
import url from "../services/api/urls";
import { RequstMethods } from "../services/api/requestMethods";

export const companyService = {
    async getAllCompany() {
        return await makeRequest(url.company.getAllCompany, RequstMethods.GET);
    },

    async getCompanyUsers(uuid) {
        return await makeRequest(url.company.getCompanyUsers + "/" + uuid, RequstMethods.GET);
    },
    async getCompanyLeads(uuid, limit, pageCount) {
        return await makeRequest(`${url.company.getCompanyLeads}/${uuid}?limit=${limit}&pageCount=${pageCount}`, RequstMethods.POST, {});
    },
    async userLogout(uuid) {
        return await makeRequest(`${url.company.userLogout}/${uuid}`, RequstMethods.GET);
    },
    async inviteUser(uuid) {
        return await makeRequest(`${url.company.inviteUser}/${uuid}`, RequstMethods.PUT, {});
    },
    async uploadFile(payload) {
        return await makeRequest(url.company.uploadFile, RequstMethods.POST, payload);
    },
}