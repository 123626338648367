import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { updateleadByKeyValue } from '../../../services/private/company.service';
import IntentSelect from '../../Custom Select/IntentSelect';
import MarkLeadDead from '../../Modals/MarkLeadDead';


export default function DynamicIntent({
    selectedIntent,
    intentOptions,
    reloadData,
    id,
    data,
    rowLength,
    tableName,
    statusName
}) {

    const handleIntentChange = async (option, uuid) => {
        await updateLeadData(uuid, { key: "intent_id", value: option.value })
        toast.success(` Lead intent changed to ${option.label} `)
    }

    const updateLeadData = async (uuid, { key, value }) => {
        await updateleadByKeyValue(uuid, { key, value }).then(res => {
            if (res.status === 200) {
                reloadData();
            }
        }).catch(e => {
            console.log(e.response.data.data);
            toast.error(e.response.data.data.message);
        })
    }

    return (
        <>
            <IntentSelect
                selectedIntent={selectedIntent}
                intentOptions={intentOptions}
                onChange={(e) => handleIntentChange(e, id)}
                statusName={statusName}
                data={data}
                rowLength={rowLength}
                tableName={tableName}
            />
        </>
    )
}