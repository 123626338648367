import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputText from '../../InputGroup/InputText'
import InputSelect from '../../InputGroup/InputSelect'
import { ReactComponent as DownIcon } from '../../../assets/icons/Down.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal'
import CheckAccordion from '../../Modals/FilterModal/Check.accordion';
import {
    bhk_type,
    facing,
    furnished_unit,
    plot_area,
    possession_type,
    preferred_tenant,
    project_area,
    property_type,
    tenant_background,
    transaction_type,
    unit_type,
    usages_status,
    filter_property_age
} from '../../../utils/project-constant';
import SizeFilterAccordian from '../../Modals/FilterModal/SizeFilterAccordian';
import StaticFieldAccordian from '../../Modals/FilterModal/StaticFieldAccordian';
import DynamicAccordion from '../../Modals/FilterModal/Dynamic.accordion';
import { getAllCities } from '../../../services/public/public.service';

const FilterProjectsModal = ({ show, closeModal, style, handleApplyFilters }) => {
    const [propertyType, setPropertyType] = useState([]);
    const [possessionType, setPossessionType] = useState([]);
    const [unitType, setUnitType] = useState([]);
    const [transactionType, setTransactionType] = useState([]);
    const [furnished, setFurnished] = useState([]);
    const [configurations, setConfigurtion] = useState([]);
    const [facingVal, setFacingVal] = useState([]);
    const [usageStatus, setUsageStatus] = useState([]);
    const [amenitiesVal, setAmenitiesVal] = useState([]);
    const [builtUpArea, setBuiltupArea] = useState({});
    const [superArea, setSuperArea] = useState({});
    const [plotArea, setPlotArea] = useState({});
    const [tenantBackground, setTenantBackground] = useState([]);
    const [propertyAge, setPropertyAge] = useState([]);
    const [developerName, setDeveloperName] = useState('');
    const [city, setCity] = useState([]);
    const [availableDate, setAvailableDate] = useState({});
    const [possessionDate, setPossessionDate] = useState({});
    const [leaseTenureDate, setLeaseTenureDate] = useState({});
    const [preferredTenant, setPreferredTenant] = useState([]);
    const [inputValues, setInputValues] = useState({
        City: ''
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        City: ''
    });
    const [searchResults, setSearchResults] = useState({
        City: []
    });


    const getAllCitiesByName = async (searchText) => {
        const res = await getAllCities(searchText)

        if (res.data.status === 200) {
            const cities = res.data?.data.map((city) => { return { label: city.name, value: city.name } });
            return cities
        } else {
            return []
        }
    }

    //   handleChanges
    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };

        let filterData = [];

        filterData = await getAllCitiesByName(inputValuesData[fieldName])


        if (!filterData.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }
        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }

    const handleAvailableDateChange = (name, date) => {
        setAvailableDate({ ...availableDate, [name]: date });
    }

    const handlePossessionDateChange = (name, date) => {
        setPossessionDate({ ...possessionDate, [name]: date });
    }

    const handleLeaseTenureDateChange = (name, date) => {
        setLeaseTenureDate({ ...leaseTenureDate, [name]: date })
    }

    const handleApply = () => {
        let filterArr = {};
        if (propertyType.length > 0) {
            filterArr['property_type'] = propertyType.map(item => item.value)
        }

        if (transactionType.length > 0) {
            filterArr['transaction_type'] = transactionType.map(item => item.value)
        }

        if (possessionType.length > 0) {
            filterArr['possession_type'] = possessionType.map(item => item.value)
        }

        if (unitType.length > 0) {
            filterArr['unit_type'] = unitType.map(item => item.value)
        }

        if (furnished.length > 0) {
            filterArr['furnished'] = furnished.map(item => item.value)
        }

        if (configurations.length > 0) {
            filterArr['configurations'] = configurations.map(item => item.value)
        }

        if (facingVal.length > 0) {
            filterArr['facing'] = facingVal.map(item => item.value)
        }

        if (usageStatus.length > 0) {
            filterArr['usages_status'] = usageStatus.map(item => item.value)
        }

        if (tenantBackground.length > 0) {
            filterArr['tenant_background'] = tenantBackground.map(item => item.value)
        }
        if (preferredTenant.length > 0) {
            filterArr['preferred_tenant'] = preferredTenant.map(item => item.value)
        }
        if (Object.keys(builtUpArea).length > 0) {

            // if ((!Object.keys(size).includes('min') || !size.min) &&
            //     (!Object.keys(size).includes('max') || !size.max)) {
            //     toast.error('Please specify size');
            //     return;
            // }

            // if (!Object.keys(size).includes('unit')) {
            //     toast.error('Please select size unit');
            //     return;
            // }

            filterArr['build_up_area'] = { ...builtUpArea, unit: builtUpArea.unit.value }
        }

        if (Object.keys(superArea).length > 0) {
            filterArr['super_area'] = { ...superArea, unit: superArea.unit.value }
        }
        if (Object.keys(plotArea).length > 0) {
            filterArr['plot_area'] = { ...plotArea, unit: plotArea.unit.value }
        }

        if (developerName) {
            filterArr['developer'] = developerName
        }
        if (availableDate.startDate) {
            let endDate = availableDate.endDate || availableDate.startDate;
            filterArr.push({
                "followup_date": {
                    "available_date_from": availableDate.startDate,
                    "available_date_to": endDate
                }
            })
        }
        if (possessionDate.startDate) {
            let endDate = possessionDate.endDate || possessionDate.startDate;
            filterArr.push({
                "followup_date": {
                    "possession_date_from": possessionDate.startDate,
                    "possession_date_to": endDate
                }
            })
        }
        if (leaseTenureDate.startDate) {
            let endDate = leaseTenureDate.endDate || leaseTenureDate.startDate;
            filterArr.push({
                "followup_date": {
                    "lease_start_date": leaseTenureDate.startDate,
                    "lease_end_date": endDate
                }
            })
        }
        if (city.length > 0) {
            filterArr['city'] = city.map(item => item.value)
        }
        handleApplyFilters(filterArr);

    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`${style.configurationModal} ${style.projectfilterModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>Filter Projects</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <div className="d-flex h-100 overflow-auto flex-column px-20">
                <div className={`${style.projectFilterform} mt-12 mb-12`} id="accordion">
                    <div className='filter '>
                        <div className="all-fliters">
                            <CheckAccordion
                                value={propertyType}
                                setValue={setPropertyType}
                                selectionValues={property_type}
                                accordionName="Property_Type"
                                label="Property Type"
                            />
                            <CheckAccordion
                                value={possessionType}
                                setValue={setPossessionType}
                                selectionValues={possession_type}
                                accordionName="Possesstion_Filter"
                                label="Possession Type"
                            />
                            <CheckAccordion
                                value={unitType}
                                setValue={setUnitType}
                                selectionValues={unit_type}
                                accordionName="Unit_Type"
                                label="Unit Type"
                            />
                            <CheckAccordion
                                value={transactionType}
                                setValue={setTransactionType}
                                selectionValues={transaction_type}
                                accordionName="Transaction_Type"
                                label="Transaction Type"
                            />
                            <CheckAccordion
                                value={furnished}
                                setValue={setFurnished}
                                selectionValues={furnished_unit}
                                accordionName="furnishing"
                                label="Furnishing"
                            />
                            <CheckAccordion
                                value={configurations}
                                setValue={setConfigurtion}
                                selectionValues={bhk_type}
                                accordionName="configurations"
                                label="Configurations"
                            />
                            <CheckAccordion
                                value={facingVal}
                                setValue={setFacingVal}
                                selectionValues={facing}
                                accordionName="facing"
                                label="Facing"
                            />
                            <CheckAccordion
                                value={usageStatus}
                                setValue={setUsageStatus}
                                selectionValues={usages_status}
                                accordionName="property_usage_status"
                                label="Property Usage Status"
                            />
                            <CheckAccordion
                                value={tenantBackground}
                                setValue={setTenantBackground}
                                selectionValues={tenant_background}
                                accordionName="tenant_background"
                                label="Tenant Background"
                            />
                            <CheckAccordion
                                value={propertyAge}
                                setValue={setPropertyAge}
                                selectionValues={filter_property_age}
                                accordionName="property_age"
                                label="Property Age"
                            />

                            <CheckAccordion
                                value={amenitiesVal}
                                setValue={setAmenitiesVal}
                                selectionValues={[]}
                                accordionName="amenities"
                                label="Amenities"
                            />
                            <CheckAccordion
                                value={preferredTenant}
                                setValue={setPreferredTenant}
                                selectionValues={preferred_tenant}
                                accordionName="preferred_tenant"
                                label="Preferred Tenant"
                            />
                            <div className="filter-card cursor-pointer">
                                <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedate" aria-expanded="false" aria-controls="collapsedate" id="headingDate">
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <h2 className="fz16 black mb-0 fw-regular">
                                            Availability Date
                                            {Object.keys(availableDate).length > 0 && <span>{Object.keys(availableDate).length}</span>}
                                        </h2>
                                        <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                        </svg></div>
                                    </div>
                                </div>
                                <div id="collapsedate" className="filter-body collapse" aria-labelledby="headingDate" data-parent="#accordion">
                                    <div className="d-flex justify-content-between align-center mx-3" >
                                        <div className='mr-8 mb-20 w-100'>
                                            <Form.Label>
                                                Start Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={availableDate.startDate ? new Date(availableDate.startDate) : ''}
                                                placeholderText={'Start Date'}
                                                maxDate={new Date()}
                                                onChange={(date) => handleAvailableDateChange('startDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                        <div className='w-100'>
                                            <Form.Label>
                                                End Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={availableDate.endDate ? new Date(availableDate.endDate) : ''}
                                                placeholderText={'End Date'}
                                                maxDate={new Date()}
                                                minDate={availableDate.startDate}
                                                onChange={(date) => handleAvailableDateChange('endDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-card cursor-pointer">
                                <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedate" aria-expanded="false" aria-controls="collapsedate" id="headingDate">
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <h2 className="fz16 black mb-0 fw-regular">
                                            Possession Date
                                            {Object.keys(possessionDate).length > 0 && <span>{Object.keys(possessionDate).length}</span>}
                                        </h2>
                                        <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                        </svg></div>
                                    </div>
                                </div>
                                <div id="collapsedate" className="filter-body collapse" aria-labelledby="headingDate" data-parent="#accordion">
                                    <div className="d-flex justify-content-between align-center mx-3" >
                                        <div className='mr-8 mb-20 w-100'>
                                            <Form.Label>
                                                Start Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={possessionDate.startDate ? new Date(possessionDate.startDate) : ''}
                                                placeholderText={'Start Date'}
                                                maxDate={new Date()}
                                                onChange={(date) => handlePossessionDateChange('startDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                        <div className='w-100'>
                                            <Form.Label>
                                                End Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={possessionDate.endDate ? new Date(possessionDate.endDate) : ''}
                                                placeholderText={'End Date'}
                                                maxDate={new Date()}
                                                minDate={possessionDate.startDate}
                                                onChange={(date) => handlePossessionDateChange('endDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-card cursor-pointer">
                                <div className="filter-header collapsed" data-toggle="collapse" data-target="#leaseDate" aria-expanded="false" aria-controls="leaseDate" id="leasetenureDate">
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <h2 className="fz16 black mb-0 fw-regular">
                                            Lease Tenure
                                            {Object.keys(availableDate).length > 0 && <span>{Object.keys(availableDate).length}</span>}
                                        </h2>
                                        <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                        </svg></div>
                                    </div>
                                </div>
                                <div id="leaseDate" className="filter-body collapse" aria-labelledby="leasetenureDate" data-parent="#accordion">
                                    <div className="d-flex justify-content-between align-center mx-3" >
                                        <div className='mr-8 mb-20 w-100'>
                                            <Form.Label>
                                                Start Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={leaseTenureDate.startDate ? new Date(leaseTenureDate.startDate) : ''}
                                                placeholderText={'Start Date'}
                                                maxDate={new Date()}
                                                onChange={(date) => handleLeaseTenureDateChange('startDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                        <div className='w-100'>
                                            <Form.Label>
                                                End Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={leaseTenureDate.endDate ? new Date(leaseTenureDate.endDate) : ''}
                                                placeholderText={'End Date'}
                                                maxDate={new Date()}
                                                minDate={leaseTenureDate.startDate}
                                                onChange={(date) => handleLeaseTenureDateChange('endDate', date)}
                                                dateFormat="dd MMM yyyy"
                                                className={`form-control text-input`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DynamicAccordion
                                accordionName="City"
                                placeholder='Enter city to search'
                                value={city}
                                setValue={setCity}
                                searchResults={searchResults.City}
                                setSearchResults={(data) => setSearchResults({ ...searchResults, City: data })}
                                inputValues={inputValues.City}
                                handleInputChange={handleInputChange}
                                notFound={inputValuesNotFound.City}
                                setInputValues={setInputValues}
                                inputValuesNotFound={inputValuesNotFound}
                                setInputValuesNotFound={setInputValuesNotFound}
                            />
                            <StaticFieldAccordian
                                accordionName={"developer_name"}
                                type='text'
                                label={'Developer Name'}
                                value={developerName}
                                placeholder='Enter developer name to search'
                                setValue={setDeveloperName}
                                defaultExapanded={false}
                            />
                            <SizeFilterAccordian
                                accordionName={"buildup_area"}
                                label={'Buildup Area'}
                                sizeVal={builtUpArea}
                                placeholder=''
                                setValue={setBuiltupArea}
                                defaultExapanded={false}
                                options={project_area}
                            />
                            <SizeFilterAccordian
                                accordionName={"super_area"}
                                label={'Super Area'}
                                sizeVal={superArea}
                                placeholder=''
                                setValue={setSuperArea}
                                defaultExapanded={false}
                                options={project_area}
                            />
                            <SizeFilterAccordian
                                accordionName={"plot_area"}
                                label={'Plot Area'}
                                sizeVal={plotArea}
                                placeholder=''
                                setValue={setPlotArea}
                                defaultExapanded={false}
                                options={plot_area}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={closeModal}>Cancel</button>
                <button onClick={handleApply} className='pr-btn w-100'>Apply Filter</button>
            </div>
        </RightModal>
    )
}

export default FilterProjectsModal
