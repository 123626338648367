import React, { useEffect, useState } from 'react'
import RightModal from '../Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg';
import TooltipText from '../Tooltip';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditTeamUser = ({ show, closeModal, selectedTeam, handleSaveRouting }) => {

    const [allowedUsersForRouting, setAllowedUsersForRouting] = useState([]);

    useEffect(() => {
        if (selectedTeam?.team_uuid) {
            const ids = selectedTeam?.users
                .filter(u => u?.isSelected)
                .map(t => t.id)
            setAllowedUsersForRouting(ids);
        }
    }, [selectedTeam]);

    const handleSubmit = () => {
        // if (allowedUsersForRouting.length > 0) {
        const routing = {
            team_id: selectedTeam?.id || selectedTeam?.team_id,
            users: allowedUsersForRouting
        }
        handleSaveRouting(routing)
        closeModal();
        // } else {
        //     toast.error('Please select at least one user or remove team')
        // }
    }

    return (
        <Modal

            show={show}
            onHide={closeModal}
            className="main-filter"
        // backdrop='static'
        // keyboard= {false}

        >
            <Modal.Body>

                <div className="modal-conents">

                    <div className='!px-5 !py-4 overflow-auto'>
                        <div className='d-flex justify-content-between'>
                            <h1 className='text-black text-xl inter m-0 flex items-center'>
                                <button onClick={closeModal} className='mr-2'>
                                    <BackIcon />
                                </button>
                                Edit Users
                            </h1>
                            <button className='border-0 bg-tr ' onClick={() => {
                                closeModal();
                            }}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className={`form-group  position-relative mt-3`}>
                            <label className='fz16 fw-po-medium text-black' style={{ color: '#000' }}>Team Name</label>
                            <div className='text-capitalize text-black700 '>
                                {selectedTeam?.name || selectedTeam?.team_name}
                            </div>
                        </div>
                        <p className='mt-4 mb-2 font-medium text-sm'>
                            Select Lead receiving Users {allowedUsersForRouting.length > 0 ? `(${allowedUsersForRouting.length})` : ''}
                        </p>
                        {
                            selectedTeam?.users.map((user, index) => (
                                <div className='flex justify-between items-center border rounded-md p-2.5 mt-3' key={`${user.id}_${index}`}>
                                    <div>
                                        <h6 className='m-0 truncate w-[220px]'>{user.name}</h6>
                                        <TooltipText title={user.email}>
                                            <p className='m-0 text-grey200 truncate w-[220px]'>{user.email || '-'}</p>
                                        </TooltipText>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className={`btn btn-lg btn-toggle ${allowedUsersForRouting.includes(user.id) ? 'active' : ''} mr-2`}
                                            value={allowedUsersForRouting.includes(user.id)}
                                            onClick={() => {
                                                if (allowedUsersForRouting.includes(user.id)) {
                                                    let userIndex = allowedUsersForRouting.indexOf(user.id);
                                                    let userIds = [...allowedUsersForRouting];
                                                    userIds.splice(userIndex, 1);
                                                    setAllowedUsersForRouting(userIds);
                                                } else {
                                                    setAllowedUsersForRouting([...allowedUsersForRouting, user.id]);
                                                }
                                            }}
                                        >
                                            <div className="handle"></div>
                                            <span className={`${allowedUsersForRouting.includes(user.id) ? 'on' : 'off'}`}>{allowedUsersForRouting.includes(user.id) ? 'ON' : 'OFF'}</span>
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="justify-content-between !px-5 filter-ok-cancel absolute bottom-0">
                        <button type='button' className="" onClick={closeModal}>Cancel</button>
                        <button type='button' className="pr-btn" onClick={handleSubmit} >Save</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditTeamUser;