import React from 'react'
import BasicModal from './BasicModal';
import { ReactComponent as StatusIcon } from '../../assets/icons/status_icon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success_layer.svg'
import moment from 'moment';

const LeadExistInBookingModal = ({ show, closeModal, leadExistResponse, setIsMerge, selectedProject }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='!w-[400px]'>
            <div className='!p-6'>
                <div className='!mb-5'>
                    <SuccessIcon />
                </div>
                <h1 className='text-lg font-semibold'>Lead Already Exists</h1>
                <p className='text-sm text-grey-500 !mb-5'> <span className='text-[#344054]'>{leadExistResponse?.customer?.data?.name}</span> already exists with <span className='text-[#344054]'>{leadExistResponse?.customer?.data?.mobile}</span> in ous system.</p>
                <div className='!p-3.5 border-y border-r rounded-lg border-l-4 border-l-green'>
                    <h1 className='text-base font-bold text-textblack'>{leadExistResponse?.customer?.data?.name}</h1>
                    <p className='text-xs !mb-2.5 text-grey-500 flex items-center'>
                        <span>{leadExistResponse?.customer?.data?.mobile}</span> <span className='h-1 w-1 mx-1.5 rounded-full inline-block bg-grey-500 !border !border-[#667085]'></span>
                        <span>{selectedProject?.name}</span>
                    </p>
                    <div className='!gap-3 grid grid-cols-2'>
                        <div className='flex rounded-lg !mb-2 bg-[#F2F3F6] items-center !p-2 !w-[125px] '>
                            <StatusIcon className='mr-1.5 !h-5 !w-5' />
                            <p className='text-sm mb-0'>{leadExistResponse?.intent?.data?.display_name}</p>
                        </div>
                        <div className='flex rounded-lg !mb-2 bg-[#F2F3F6] items-center !p-2 !w-[125px] '>
                            {/* <StatusIcon className='mr-1.5 !h-5 !w-5' /> */}
                            <p className='text-sm mb-0'>{leadExistResponse?.status?.data?.display_name}</p>
                        </div>
                    </div>
                    {leadExistResponse?.assigned_to?.data?.name && <p className='text-sm mb-2.5 font-medium text-grey-500'>
                        <span>Assigned User: </span>
                        <span>{leadExistResponse?.assigned_to?.data?.name}</span>
                    </p>}
                    {leadExistResponse?.broker_user_name && <p className='text-sm mb-2.5 font-medium text-grey-500'>
                        <span>CP Name: </span>
                        <span>{leadExistResponse?.broker_user_name}</span>
                    </p>}
                    {leadExistResponse?.broker_company_name && <p className='text-sm mb-2.5 font-medium text-grey-500'>
                        <span>CP Firm: </span>
                        <span>{leadExistResponse?.broker_company_name}</span>
                    </p>}
                    {leadExistResponse?.lead?.data?.tag_expiry && <p className='text-sm mb-2.5 font-medium text-grey-500'>
                        <span>Tag Expiry: </span>
                        <span>{leadExistResponse?.lead?.data?.tag_expiry ? moment(leadExistResponse?.lead?.data?.tag_expiry).format('Do MMM YYYY') : '-'}</span>
                    </p>}
                    <p className='text-sm font-medium text-grey-500'>
                        <span>Created Date: </span>
                        <span>{moment(leadExistResponse?.lead?.data?.created_on).format('Do MMM YYYY')}</span>
                    </p>
                </div>
                <div className='w-full !mt-5 !gap-3 grid grid-cols-2'>
                    <button
                        type='button'
                        className='text-base font-medium inter col-span-2 leading-6 rounded-lg !border !border-grayLight !text-black w-full h-[44px] text-center inter'
                        onClick={() => {
                            setIsMerge(true);
                            closeModal();
                        }}
                    >Merge Lead</button>
                    {/* <button
                        type='submit'
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary w-full h-[44px] text-center inter'
                        onClick={() => {
                            setIsMerge(false);
                            closeModal();
                        }}
                    >Make New Lead</button> */}
                </div>
            </div>
        </BasicModal>
    )
}

export default LeadExistInBookingModal