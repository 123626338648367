import React, { useState } from 'react';
import { ReactComponent as DownICon } from '../../../assets/icons/Down.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as InfoYellow } from '../../../assets/icons/infoYellow.svg';
import InputText from '../../InputGroup/InputText';
import InputSelect from '../../InputGroup/InputSelect';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const QotePricing = ({ setSteps }) => {
    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const rotatetwo = isSpanClassAdded ? 'rotate-180' : '';
    const rotateone = isSpanClassAdded ? 'rotate-180' : '';
    const rotattree = isSpanClassAdded ? 'rotate-180' : '';

    return (
        <>
            <div className='!pt-5 sm:!px-6 !px-3 pb-8 h-full overflow-auto'>
                <h2 className="inter text-textblack text-lg !mb-5 font-semibold">Pricing</h2>
                <div>
                    <div id="accordion" className='flex flex-col !gap-4'>
                        <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer grp" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter"><input type="radio" name="" id="" className='w-5 h-5' />
                                    <span>Amenities </span>
                                    <div className='inline-flex items-center gap-2 text-sm text-textblack font-normal'><InfoYellow />Approval Required</div>
                                </h5>
                                <div className='flex items-center sm:gap-3 !gap-1'>
                                    <h6 className='inter sm:text-base text-sm text-black700 m-0'>Total : <span className='text-textblack'> ₹ 40,000</span></h6>
                                    <span className=''><DownICon /></span>
                                </div>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Car Parking</div>
                                                <div className='text-textblack'>₹ 6,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Club Membership Charges</div>
                                                <div className='text-textblack'>₹ 40,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Waste Management</div>
                                                <div className='text-textblack'>₹ 9,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>EEC</div>
                                                <div className='text-textblack'>₹ 4,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input type="radio" name="" id="" className='w-5 h-5' />
                                    SDR Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    <h6 className='inter sm:text-base text-sm text-black700 m-0'><span className='text-textblack'> ₹ 40,000</span></h6>
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Car Parking</div>
                                                <div className='text-textblack'>₹ 6,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Club Membership Charges</div>
                                                <div className='text-textblack'>₹ 40,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Waste Management</div>
                                                <div className='text-textblack'>₹ 9,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>EEC</div>
                                                <div className='text-textblack'>₹ 4,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer collapsed collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input type="radio" name="" id="" className='w-5 h-5' />
                                    GST  : 18%

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    <h6 className='inter sm:text-base text-sm text-black700 m-0'><span className='text-textblack'> ₹ 40,000</span></h6>
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Car Parking</div>
                                                <div className='text-textblack'>₹ 6,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Club Membership Charges</div>
                                                <div className='text-textblack'>₹ 40,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Waste Management</div>
                                                <div className='text-textblack'>₹ 9,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>EEC</div>
                                                <div className='text-textblack'>₹ 4,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='!border !border-primary !py-4 !px-4 bg-[#F9FAFD] !mt-5 rounded-lg gap-y-4 flex flex-col'>
                    <div>
                        <h4 className='inter text-sm text-black700 !mb-2'>Discount type 1</h4>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <InputSelect
                                placeholder='Rate per $unit'
                                isMulti={false}
                                styles={customStyles}

                            />

                            <InputText
                                // label="Unit Number"
                                placeholder="₹0"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                            />
                        </div>
                    </div>
                    <div>
                        <h4 className='inter text-sm text-black700 !mb-2'>Discount type 2</h4>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <InputSelect
                                placeholder='Select type'
                                isMulti={false}
                                styles={customStyles}

                            />

                            <InputText
                                // label="Unit Number"
                                placeholder="₹ Enter Discount"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                            />
                        </div>
                    </div>
                    <div>
                        <button className='bg-blueHighlight !text-primary text-sm font-medium inter flex items-center gap-1 !px-4 !py-2 rounded-lg float-right'><PlusPrimary />Add Discount</button>
                    </div>

                </div>
                <div className='!mt-5 flex flex-col gap-y-3'>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Unit Price</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ 64,00,000</div>
                    </div>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Total Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ 4,28,200</div>
                    </div>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Aminities</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ 9,400</div>
                    </div>

                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">SDR Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ 9,400</div>
                    </div>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">GST  : 18%</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ 9,400</div>
                    </div>
                </div>
                <div className='!mt-5 !border-t border-b !border-grayLigh !pt-4 !pb-4 '>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold">Grand Total</div>
                        <div className='sm:text-xl text-lg font-semibold leading-6 text-textblack text-right'>
                            <div>- ₹ 9,400</div>
                            <div className='sm:text-sm text-xs text-black700 font-normal'>For $Customer_Name</div>
                        </div>
                    </div>
                </div>
                <div className='!border border-[#FBBC05] rounded-lg !p-4 flex flex-row items-center gap-3 !mt-5 bg-[#FBBC05] bg-opacity-5'>
                    <div className='w-6 basis-6 shrink-0 '><InfoYellow /></div>
                    <span className='text-sm inter text-black700'>The Quote will be sent to your senior for Approval. Edit Quote to fix the total discount limit.</span>

                </div>
                <div className="!mt-5">

                    <button className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2'>
                        <PlusPrimary /> Add Payment Proof</button>
                </div>
            </div>

            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center' onClick={() => setSteps('QoteUnitDetail')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center' onClick={() => setSteps('QotePaymentplan')}>Next</button>
                </div>
            </div>
        </>
    )
}

export default QotePricing
