import React, { useEffect, useState } from 'react'
import RightModal from './RightModal';
import InputText from '../InputGroup/InputText';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { getAllCities, insertProjectBuilderInfo, updateProjectBuilderInfo, uploadFile } from '../../services/public/public.service';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { getCities } from '../../services/private/csv.service';
import TextEditor from '../../modules/ListingProjects/Editor';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

const CreateBuilderModal = ({ show, closeModal, buildersData, getBuildersData }) => {
    const [name, setName] = useState('');
    const [developerExp, setDeveloperExp] = useState('');
    const [totalProject, setTotalProject] = useState('');
    const [city, setCity] = useState([]);
    const [builderLogo, setBuilderLogo] = useState('');
    const [deleveloperLogoAltTag, setDeleveloperLogoAltTag] = useState('');
    const [builderDescription, setBuilderDescription] = useState('');

    useEffect(() => {
        if (buildersData?.uuid) {
            setBuilderDescription(buildersData?.builder_description);
            setBuilderLogo(buildersData?.builder_logo);
            setDeleveloperLogoAltTag(buildersData?.builder_logo_alt_tag);
            setTotalProject(buildersData?.total_projects);
            setName(buildersData?.builder_name);
            setDeveloperExp(buildersData?.builder_experience)
            setCity(buildersData?.operational_cities.length > 0 ? buildersData?.operational_cities.map(c => ({ label: c, value: c })) : [])
        }
    }, [buildersData]);

    const handleOnChangeDeveloperLogo = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setBuilderLogo(response.data.data.s3_url);
            // setIsDeveloperLogoMissing(false);
            // setDeveloperLogoFileError('');
        }
    }

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleSubmit = async () => {
        const payload = {
            builder_name: name,
            builder_description: builderDescription,
            builder_experience: developerExp,
            total_projects: totalProject,
            builder_logo: builderLogo,
            builder_logo_alt_text: deleveloperLogoAltTag,
            operational_cities: city.map(item => item.value)
        }
        await insertProjectBuilderInfo(payload).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                getBuildersData();
                closeModal();
            }
        }).catch((err) => {
            console.log("Something went wrong", { err });
            toast.error(err.response.data.message)
        });
    }

    const handleUpdate = async () => {
        const payload = {
            builder_name: name,
            builder_description: builderDescription,
            builder_experience: developerExp,
            total_projects: totalProject,
            builder_logo: builderLogo,
            builder_logo_alt_text: deleveloperLogoAltTag,
            operational_cities: city.map(item => item.value)
        }
        await updateProjectBuilderInfo(buildersData?.uuid, payload).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getBuildersData();
                closeModal();

            }
        }).catch((err) => {
            console.log("Something went wrong", { err });
            toast.error(err.response.data.message)
        });
    }

    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Builder Info</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`d-flex h-100 overflow-auto flex-column px-20 gap-3 mt-3`}>

                <InputText
                    value={name}
                    inputclass={''}
                    label='Builder Name'
                    placeholder={'Enter Builder Name'}
                    onChange={(e) => setName(e.target.value)}
                />

                <div>
                    <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                        <span>Builder Logo</span>
                        <span>PNG, 2MB</span>
                    </div>
                    <div className={`${style.upload}`}>
                        {builderLogo && <img src={builderLogo} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                        <input type="file" className='d-none' name='developer-logo' id='developer-logo' accept='image/*' onChange={handleOnChangeDeveloperLogo} />
                        {!builderLogo && <div className={`${style.uplaodbtngrp}`}>
                            <button
                                type='button'
                                className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('developer-logo').click()
                                }}
                            ><PluswhiteIcon />Upload</button>
                        </div>}
                        {builderLogo && <div className={`${style.hoverbox}`}>
                            <button
                                type='button'
                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('developer-logo').click()
                                }}
                            ><PluswhiteIcon />Replace</button>
                            <button
                                type='button'
                                className={`${style.delbtn} inter border-0 bg-white`}
                                onClick={() => setBuilderLogo('')}
                            ><TrashIcon /></button>
                        </div>}
                    </div>
                    {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                    {/* {logoDeveloperFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoDeveloperFileError}</p>} */}
                </div>
                <InputText
                    label={'Builder Logo Alt Tag'}
                    placeholder={'Enter Builder Logo Alt Tag'}
                    value={deleveloperLogoAltTag}
                    onChange={(e) => setDeleveloperLogoAltTag(e.target.value)}
                    error={''}
                />
                <InputText
                    value={developerExp}
                    inputclass={''}
                    label='Builder Experience'
                    placeholder={'Enter builder experience'}
                    onChange={(e) => setDeveloperExp(e.target.value)}
                />
                <InputText
                    value={totalProject}
                    inputclass={''}
                    label='Total No. of Projects'
                    type='number'
                    placeholder={'Enter total no. of projects'}
                    onChange={(e) => setTotalProject(e.target.value)}
                />
                <div>
                    <label htmlFor="">Operational City</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        placeholder='Search and Select'
                        isMulti={true}
                        value={city}
                        // styles={customStyles}
                        onChange={(e) => {
                            setCity(e)
                        }}
                    />
                </div>
                <div className=''>
                    <label htmlFor=""> Description</label>
                    <TextEditor data={builderDescription} onChange={(value) => setBuilderDescription(value)} />
                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-end gap-x-3 border-top border-color px-20 righmodalfooter py-2`}>
                <button type='button' className='outline-btn ' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => {
                        if (buildersData?.uuid) {
                            handleUpdate()
                        } else {
                            handleSubmit()
                        }
                    }} className='pr-btn '>
                    {buildersData?.uuid ? 'Update' : 'Submit'}
                </button>
            </div>
        </RightModal>
    )
}

export default CreateBuilderModal;