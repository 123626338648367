import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../InputGroup/InputText';
import { insertBrokerage, updateBrokerageSlabByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';


const AddBrokrageModal = ({ show, closeModal, uuid, getBrokrageDetails, selectedBrokerage }) => {
    const [brokerage, setBrokrage] = useState('');
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (selectedBrokerage && Object.keys(selectedBrokerage).length) {
            setBrokrage(selectedBrokerage?.brokerage_percentage);
            setMinValue(selectedBrokerage?.min_value);
            setMaxValue(selectedBrokerage?.max_value);
        }
    }, [selectedBrokerage]);

    const handleSubmit = async () => {
        if (!minValue || !maxValue || !brokerage) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        const payload = {
            project_id: uuid,
            min_value: minValue,
            max_value: maxValue,
            brokerage_percentage: brokerage
        }
        let res = {}
        if (selectedBrokerage?.uuid) {
            res = await updateBrokerageSlabByUUID(selectedBrokerage?.uuid, payload);
        } else {
            res = await insertBrokerage(payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            getBrokrageDetails();
            closeModal();
        }

    }
    return (
        <RightModal show={show} closeModal={closeModal} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Brokrage</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <div className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                <div>
                    {/* <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Brokrage</label> */}
                    {/* <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input
                                type="radio"
                                name="brokrage"
                                id=""
                                value={'range'}
                                checked={brokrage?.type === 'range'}
                                onChange={() => {
                                    const brokragesCopy = [...brokrages];
                                    brokragesCopy[index]['type'] = 'range'
                                    console.log(brokragesCopy, 'copy')
                                    setBrokrages(brokragesCopy);
                                }} />
                            Booking Range
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="brokrage" id="" value='absolute' checked={brokrage.type === 'absolute'} onChange={() => {
                                const brokragesCopy = [...brokrages];
                                brokragesCopy[index]['type'] = 'absolute'
                                setBrokrages(brokragesCopy);
                            }} />
                            Absolute
                        </label>

                    </div> */}
                    {/* {brokrage.type === 'absolute' &&
                        <div className='flex gap-x-5 [&>*]:w-6/12'>
                            <InputText
                                inputclass='!mt-0'
                                value={brokrage?.bookingNo}
                                placeholder={'e.g. 1'}
                                onChange={(e) => {
                                    const brokragesCopy = [...brokrages];
                                    brokragesCopy[index]['bookingNo'] = e.target.value
                                    setBrokrages(brokragesCopy);
                                }}
                                label={'No. of Booking'}

                            />
                            <InputText
                                inputclass='!mt-0'
                                value={brokrage?.brokrage}
                                placeholder={'e.g. 2%'}
                                onChange={(e) => {
                                    const brokragesCopy = [...brokrages];
                                    brokragesCopy[index]['brokrage'] = e.target.value
                                    setBrokrages(brokragesCopy);
                                }}
                                label={'Brokerage'}
                            />
                        </div>
                    } */}
                    {/* {brokrage.type === 'range' && */}
                    <div>
                        <div className='flex gap-x-5 [&>*]:w-6/12 !mb-4'>
                            <InputText
                                inputclass='!mt-0'
                                placeholder='Enter Start value'
                                value={minValue}
                                onChange={(e) => {
                                    setMinValue(e.target.value)
                                }}
                                label={'Start Range Value'}
                                error={error && !minValue ? 'Please enter start value' : ''}
                            />
                            <InputText
                                inputclass='!mt-0'
                                placeholder='Enter End value'
                                value={maxValue}
                                onChange={(e) => {
                                    setMaxValue(e.target.value)
                                }}
                                label={'End Range Value'}
                                error={error && !maxValue ? 'Please enter end value' : ''}
                            />

                        </div>
                        <InputText
                            inputclass='!mt-0'
                            type='number'
                            value={brokerage}
                            placeholder={'e.g. 2%'}
                            onChange={(e) => {
                                setBrokrage(e.target.value)
                            }}
                            label={'Brokerage (in %)'}
                            error={error && !brokerage ? 'Please enter brokerage' : ''}
                        />
                    </div>
                    {/* } */}
                </div>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={closeModal}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>
        </RightModal>
    )
}

export default AddBrokrageModal