import React from 'react'
import moment from 'moment/moment';
// import DummyImg from 'src/assets/Icons/dummy.png'

const Event = ({ name, propertyType, thumbnail, startTime, endTime, event, date, uuid, city, address }) => {

    return (
        <div className='rounded-lg  shadow-[0px_20px_50px_rgba(18,17,39,0.08)] relative border border-grayLight overflow-hidden'>
            <div className='relative'>
                <figure className='h-[300px]' >
                    {/* <img
                        src={thumbnail || DummyImg}
                        className='object-cover h-full	w-full'
                        alt='event_thumbnail' /> */}
                    {event === 'online' &&
                        <div
                            className='text-white bg-primary px-2.5 py-2 rounded-l-lg text-base font-semibold  absolute right-0 top-4'>
                            ONLINE
                        </div>}
                </figure>
            </div>

            <div className='py-2 px-3'>
                <div className='flex items-center justify-start gap-2'>
                    {(date && startTime && endTime) && <div className='text-sm leading-4 text-black700 font-semibold' >
                        <span>
                            {`${moment(date).format("MMM DD")}, 
                        ${moment(new Date(`${date} ${startTime}`).toISOString()).format('h:mmA')} - 
                        ${moment(new Date(`${date} ${endTime}`).toISOString()).format('h:mmA')}`}
                            {/* :
                                ` ${moment(date).format("MMM DD")},
                            ${moment(startTime).format('h:mmA')} -
                            ${moment(endTime).format('h:mmA')}`
                            } */}
                        </span>
                    </div>}


                </div>
                <h1 className='text-xl font-semibold mt-3 text-black'>
                    {name || 'Sample Event Name'}
                </h1>
                <div className='mt-1 text-sm text-black700 font-normal'>
                    {propertyType?.length > 0 && <> <span> {propertyType?.map(item => item.label)?.join(', ')}  </span> </>}
                    {address && <> | <span>{address}</span></>}
                    {city?.value && <span> | {city?.value}</span>}
                </div>
            </div>
        </div>
    )
}

export default Event;
