import SmallCenterModal from "./small-center-modal";
import React from "react";


const RequestRaisedModal = ({ show, closeModal }) => {
    return (
        <div>
            <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'unprocessed-leads requestraised'}>
                <div className=''>
                    <figure className='mb-20'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                            <rect x="4" y="4" width="64" height="64" rx="32" fill="#DBFCB9" />
                            <circle cx="36" cy="36" r="15" stroke="#6FB328" strokeWidth="2" />
                            <path d="M31.6754 35.3687C31.0577 34.759 30.0628 34.7655 29.4531 35.3831C28.8434 36.0007 28.8498 36.9957 29.4675 37.6054L33.4604 41.5469C34.1173 42.1953 35.1889 42.1406 35.7764 41.4287L42.2596 33.5716C42.812 32.9022 42.7171 31.9118 42.0477 31.3594C41.3783 30.807 40.3878 30.9019 39.8355 31.5713L34.4457 38.1033L31.6754 35.3687Z" fill="#6FB328" />
                            <rect x="4" y="4" width="64" height="64" rx="32" stroke="#EBFED7" strokeWidth="8" />
                        </svg>
                    </figure>
                    <h1 className='fz20 fw-po-medium black mb4 po'>Request Raised</h1>
                    <p className="black-dark-700 fz14 ls2 ro mb-0">Your request for contact support has been raised with the team. Our support team will get back to you as soon as possible.</p>


                    <button className='pr-btn py-6 fw-po-medium w-100 fz16 mt-16' onClick={closeModal}>
                        Got it
                    </button>

                </div>
            </ SmallCenterModal >
        </div >
    )
}

export default RequestRaisedModal
