import React, { useState, useEffect, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { getRolePermissions, getRoles } from '../../../../services/private/company.service';
import SliderModal from '../../../../components/Modals/SliderModal';
import CheckAccordion from '../../../../components/Modals/FilterModal/Check.accordion';
import RadioCheckAccordion from '../../../../components/Modals/FilterModal/RadioCheck.accordion';


export default function UserFilters({
    show,
    setShow,
    setFiltersData,
    filterData,
    setFilterCount,
    filtersData,
    filterToaster,
    setCurrentPage,
}) {
    // Filter Values

    const [project, setProject] = useState([]);
    const [source, setSource] = useState([])
    const [status, setStatus] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [filterSelected, setFilterSelected] = useState(false);
    const [roles, setRoles] = useState([]);

    // console.log(filtersData, 'filtersData')
    const ROLE_NAME = localStorage.getItem('role');

    const getRole = async () => {
        await getRoles()
            .then((res) => {
                if (res.status === 200) {
                    setRolesList(res.data.data.map((i => ({ ...i, label: i.name, value: i.role_id }))));
                }
            })
            .catch((e) => {
                toast.error(e.response.data.message);
                console.error(e.response.data);
            });
    };

    useEffect(() => {
        getRole()
    }, []);

    useEffect(() => {
        if (status.length || roles.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [status, roles,])

    const sendFilterRequest = () => {
        let filterArr = {};

        if (source.length > 0) {
            filterArr['source'] = true
        }
        if (roles.length > 0) {
            filterArr['roles'] = roles.map(i => i.value)
        }

        if (status.length > 0) {
            filterArr['status'] = status.map(i => i.value)
        }

        setFiltersData(filterArr);
        setShow(false)
    }

    const handelRemoveClick = () => {
        if (filterSelected || status.length > 0 || roles.length > 0) {
            toast.success("All filters Removed");
            setStatus([]);
            setRoles([]);
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = { ...filtersData };
        // for (const filterData of filters) {
        //     filtersArr = { ...filtersArr, ...filterData }
        // }

        let filterKeys = Object.keys(filtersArr);

        if (filterKeys.includes("roles")) {
            const filtered = filtersArr['roles'].map(int => {
                return rolesList?.find(list => list.value === int)
            })
            setRoles(filtered)
        }

        if (filterKeys.includes("status")) {
            let statuss = []
            filtersArr.status.forEach(item => {
                if (item) {
                    statuss.push({
                        label: 'Active',
                        value: true
                    })
                }
                if (!item) {
                    statuss.push({
                        label: 'Inactive',
                        value: false
                    })
                }

            })
            setStatus(statuss)
        }
    }


    useEffect(() => {
        updateFilterValaue();
    }, [
        filtersData,
        show,
        rolesList
    ])

    useEffect(() => {
        // let filters = filtersData;
        const filtersArr = Object.keys(filtersData);
        if (!filtersArr.includes('roles')) {
            setRoles([]);
        }
        if (!filtersArr.includes('status')) {
            setStatus([]);
        }

    }, [filtersData])

    console.log(status, 'status')
    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || filtersData?.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'></div>
                        <div className="all-fliters">
                            <div id="accordion">
                                <RadioCheckAccordion
                                    value={status}
                                    setValue={setStatus}
                                    selectionValues={[
                                        {
                                            label: 'Active',
                                            value: true
                                        },
                                        {
                                            label: 'Inactive',
                                            value: false
                                        },
                                        // {
                                        //     label: 'In-Active',
                                        //     value: 'In-Active'
                                        // }
                                    ]}
                                    accordionName="Status"
                                    label="Status"
                                    showSearch={false}
                                />
                                <CheckAccordion
                                    value={roles}
                                    setValue={setRoles}
                                    selectionValues={rolesList}
                                    accordionName="role"
                                    label="Role"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}