import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';

import RightModal from '../../../components/Modals/RightModal';
import TextEditor from '../Editor';
import { toast } from 'react-toastify';
import { saveListingFloorPlanDescription } from '../../../services/public/public.service';

const FloorAndPlanModal = ({ show, closeModal, style, floorDescription, setFloorDescription, projectUuid }) => {
    const [floorPlan, setFloorPlan] = useState('');

    useEffect(() => {
        setFloorPlan(floorDescription);
    }, [floorDescription])

    const handleSubmit = async () => {
        const payload = {
            floor_plan_description: floorPlan
        }
        const res = await saveListingFloorPlanDescription(projectUuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setFloorDescription(floorPlan)
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={() => { closeModal(); }}
            modalClass={`${style.configurationModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Add Floor and Plan Description
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='!px-5 mt-3 h-[80vh]'>
                <label htmlFor="">Description</label>
                <TextEditor data={floorPlan} onChange={(value) => setFloorPlan(value)} />
            </div>

            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => { closeModal(); }}>Cancel</button>
                <button type='button'
                    className='pr-btn w-100'
                    onClick={() => {
                        handleSubmit()
                    }}>
                    Submit
                </button>
            </div>
        </RightModal>
    )
}

export default FloorAndPlanModal
