import React, { Component, useContext, useMemo } from 'react';
import Consumer from '../../helpers/context';

export default function StatusDropDownDynamic({
    value,
    onChange,
    options,
    placeholder,
    data,
    width,
    handleOpenVerifyPage
}) {

    let { userProfile } = useContext(Consumer);

    const svCount = useMemo(() => {
        if (value?.label === 'SV Completed' || value?.label === 'SV Scheduled') {
            if (value?.label === 'SV Completed') {
                return data?.sitevisit_completed;
            }
            if (value?.label === 'SV Scheduled' && data?.sitevisit_completed > 0) {
                return data?.sitevisit_completed + 1;
            }
        }
    }, [value, data?.sitevisit_completed])

    // console.log({ data }, 'eoi')
    return (
        <div className={`assigned-drop  ${(!value) ? 'new-lead-status' : ''}`}>
            {/* Selected */}
            <button
                className={`outline-leads ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} ${width === 'full' ? 'w-full' : ''}`}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
            >
                <span>
                    {value && value.label ? value.label === 'Date' ? 'Created Date' : value.label : placeholder}
                    {svCount > 0 && <span>{svCount}</span>}
                </span>
                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#44444f" />
                </svg>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    options.map(option => {

                        return <a
                            key={option.value}
                            onClick={() => {
                                if (option.label === 'Booking Completed' && data?.approval_status === 'pending') {
                                    handleOpenVerifyPage(data);
                                    return;
                                }
                                if (option.value !== value?.value) {
                                    onChange(option)
                                }

                            }}
                            className={`dropdown-item text-capitalize ${value?.value == option.value ? 'selected' : ''}`}
                        // disabled={data?.approval_status === 'pending'}
                        >
                            {option.label}
                        </a>
                    })
                }
            </div>
        </div>
    )
}