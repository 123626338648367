import axios, { Method } from 'axios';
import Auth from '../../Auth/auth';
import axiosHandler from '../../helpers/axioHandler';

export default async function makeRequest(url, method, inputPayload){
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_DEV_API_URL}`,
        url: url,
        method: method,
        headers: {
            Authorization: localStorage.getItem("authToken") || ""
        },
        data: {}
    };

    if (method !== 'get' && inputPayload) {
        requestConfig.data = inputPayload;
    }

    try {
        let response = await axios.request(requestConfig);
        return response;
    } catch (error) {
        axiosHandler(error);
        if(error.response.status === 401){
            Auth.signout()
        }
        throw error;
    }

}


export async function formRequest(url, method, form_data){
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VER}`,
        url: url,
        method: method,
        headers: {
            Authorization: localStorage.getItem("authToken") || "",
            'content-type': 'multipart/form-data'
        },
        data: form_data
    };
    
    try {
        let response = await axios.request(requestConfig);
        return response;
    } catch (error) {
        axiosHandler(error);
        throw error;
    }

}

export function makeParams (params=[]){
    let paramString = "?"
    for(const param in params){
        if(params[param].value){
            if(Number(param) != 0 ) paramString = paramString + "&"
            paramString = paramString + params[param].index+"="+params[param].value
        }
    }
    return paramString;
}