import React, { useState, useEffect } from 'react';
// import SideBanner from '../../components/SideBanner/SideBanner';
import { Link } from 'react-router-dom';
import MobileInput from '../../../components/InputGroup/MobileInput';
import SideBanner from '../../../components/SideBanner/SideBanner';
import { toast } from 'react-toastify';
import { forgotPasswordChangeOTP, sendForgotPasswordOTPEmail } from '../../../services/public/public.service';
import { useHistory } from 'react-router-dom';

export default function MobileForOTP() {
    const Heading = `A Real Estate Platform That 'Works For You'`;
    const SubHeadingText = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;

    const [mobileNumber, setMobileNumber] = useState("");
    const [otpSent, setOTPSent] = useState(false);
    const [countDown, setCountDown] = useState(30);
    const [otpVal, setOTPVal] = useState([])
    const [disableResend, setDisableResend] = useState(true);
    const [otpError, setOTPError] = useState(false);

    const [confirmpassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('')
    const [enableBtn, setEnableBtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let timer = setInterval(() => {
            if (countDown <= 0) {
                clearInterval(timer);
                setDisableResend(false);
            } else {
                setCountDown(countDown - 1)
            }
        }, 1000)

        return (() => clearInterval(timer))
    }, [countDown])

    useEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('user-login')

        return (() => el.classList.remove('user-login'))
    }, []);

    const inputfocus = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            let newVal = [...otpVal]
            newVal[e.target.tabIndex] = e.target.value
            setOTPVal(newVal)
            const previousElementSibling =
                e.target.previousElementSibling;

            if (previousElementSibling) {
                previousElementSibling.focus();
            }
        }
        else {
            const nextElementSibling = e.target.nextElementSibling;
            let newVal = [...otpVal]
            newVal[e.target.tabIndex] = e.target.value
            setOTPVal(newVal)
            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        }
    }

    const handleResend = (() => {
        // resendEmailOTP(uuid).then(res => {
        //     if (res.data.status === 200) {
        //         toast.success('OTP sent please check you email')
        //     }
        // }).catch(err => {
        //     console.log({ err });
        // })
    }, []);

    const handleSubmitOTP = async () => {
        const res = await sendForgotPasswordOTPEmail({ mobile: mobileNumber })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setOTPSent(true);
        }
    }

    const onForgotPasswordChangeSubmit = (otp) => {
        if (password !== confirmpassword) {
            toast.error('Password did not match.')
        }
        if (!otp) {
            return;
        }
        const payload = {
            mobile: mobileNumber,
            otp: otp,
            password: password
        }
        setLoading(true);
        forgotPasswordChangeOTP(payload).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                setOTPError(false);
                setLoading(false);
                history.push('/login')
            }
        }
        ).catch(err => {
            console.log({ err });
            setOTPError(true);
            setLoading(false);
        })
    }

    return (
        <>
            <div className="container-fluid d-flex flex-fill">
                <div className="row d-flex flex-fill">
                    <div className="col-lg-4 p-0 d-lg-flex flex-fill d-none">
                        <SideBanner Heading={Heading} Text={SubHeadingText} />
                    </div>
                    <div className="col-lg-8 login-div d-flex flex-fill">
                        <div className="my-login-page d-flex flex-fill align-items-center justify-content-center">
                            <div className={`card  ${otpSent ? 'forget-msg' : 'forget-pass'}`}>
                                <div className="card-body">
                                    <div className="login-log d-flex align-items-center flex-row position-relative justify-content-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} />
                                        <div className="beta">BETA</div>
                                    </div>
                                    {!otpSent && <>
                                        <h3 className="fz24 black text-center mt-40 mb-12">Enter Mobile no.</h3>
                                        <p className="fz14 text-center black-dark-700 ro">You will receive an SMS for verification code that may apply message and data rates.</p>
                                        <div className={`form-group position-relative mt-40`}>
                                            <label htmlFor="email" className="custom-txt" >Enter your Mobile No.</label>
                                            <MobileInput
                                                value={mobileNumber}
                                                onChange={(e) => {
                                                    setMobileNumber(e.phone)
                                                }}
                                                className="form-control text-input'"
                                                name="mobile"
                                            />

                                            <div className="form-group mt-24">
                                                <button type="submit" onClick={handleSubmitOTP}
                                                    className="pr-btn w-100 fz18 py-10">
                                                    Send OTP
                                                </button>


                                            </div>
                                        </div>
                                    </>}
                                    {
                                        otpSent && <>
                                            <h3 className="fz24 black text-center mt-40 mb-12">Verify OTP</h3>
                                            <p className="fz14 text-center black-dark-700 ro">Enter the verification code we’ve sent you on {mobileNumber}</p>
                                            <div className={`${otpError ? 'invalid' : ''}`}>
                                                <div className="verify-input-group">
                                                    <input type="text" placeholder='0' maxLength="1" tabIndex="0" onKeyUp={e => inputfocus(e)} />
                                                    <input type="text" placeholder='0' maxLength="1" tabIndex="1" onKeyUp={e => inputfocus(e)} />
                                                    <input type="text" placeholder='0' maxLength="1" tabIndex="2" onKeyUp={e => inputfocus(e)} />
                                                    <input type="text" placeholder='0' maxLength="1" tabIndex="3" onKeyUp={e => inputfocus(e)} />
                                                </div>
                                                {otpError && <div className="input-feedback fz14 ro mt-8 ml-2">Verification code did not match</div>}
                                            </div>
                                            {/* <button className='pr-btn w-100 fz18 py-10 mt-24' onClick={() => handleOTPVerification(otpVal.join(''))} disabled={loading}>Verify</button> */}
                                            <div className='d-flex align-items-center justify-content-between flex-column'>
                                                <p className='fz16 ro ls5 mb-0 black-dark-600 mt-10'>OTP not received? <a style={{ pointerEvents: disableResend ? 'none' : '', color: !disableResend ? '#0062FF' : '' }} className="black-dark-600 fw-po-medium" onClick={handleSubmitOTP}>Resend code </a>{countDown > 0 && <><span className="black-dark-600 fw-po-medium"> in </span> <span className='black'> {countDown}sec</span></>}</p>
                                            </div>
                                            {/* <h3 className="fz24 mb-24 text-center mt-24">Reset your Password</h3> */}
                                            <div className={`form-group position-relative mt-40`}>
                                                <label htmlFor="email" className="custom-txt" >New Password</label>
                                                <div className="position-relative">
                                                    {!showPassword && <span className="view-pass" onClick={() => setShowPassword(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D"></path></svg>
                                                    </span>}
                                                    {showPassword && <span className="view-pass" onClick={() => setShowPassword(false)}>
                                                        <i className="far fa-eye-slash fa-1x"></i>
                                                    </span>}
                                                    <input
                                                        type={!showPassword ? "password" : 'text'}
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        className="form-control text-input'"
                                                        name="password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email" className="custom-txt" >Confirm Password</label>
                                                <div className="position-relative">
                                                    {!showConfirmPassword && <span className="view-pass" onClick={() => setShowConfirmPassword(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D"></path></svg>
                                                    </span>}
                                                    {showConfirmPassword && <span className="view-pass" onClick={() => setShowConfirmPassword(false)}>
                                                        <i className="far fa-eye-slash fa-1x"></i>
                                                    </span>}

                                                    <input
                                                        type={!showConfirmPassword ? "password" : 'text'}
                                                        value={confirmpassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        className="form-control text-input'"
                                                        name="cnfPassword"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <button type="submit"
                                                    // disabled={!enableBtn}
                                                    onClick={() => onForgotPasswordChangeSubmit(otpVal.join(''))}
                                                    className="pr-btn rest-btn">
                                                    Confirm
                                                </button>
                                            </div>
                                        </>
                                    }
                                    <div className="text-center mt-24">
                                        <Link to="/login" className='fz16' >Back To Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}