import React, { useState, useEffect } from 'react'

import { toast } from 'react-toastify';
import { updatePassword } from '../../../../services/private/company.service';
import { Button } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';

export default function PasswordChange() {

    const [password, setPassword] = useState();
    const [confirmPass, setConfirmPass] = useState();
    const [currentPass, setCurrentPass] = useState();
    const [allowedChange, setAllowedChange] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const updatePasswordSubmit = () => {
        if (!currentPass || !password || !confirmPass) {
            setError(true);
            return;
        } else {
            setError(false);
        }

        if (password != confirmPass) {
            toast.warning("Passwords dont match")
            return false;
        }

        setLoading(true);
        updatePassword(currentPass, password).then(res => {
            if (res.status === 200) {
                toast.success("Your password changed successfully")
                setPassword('')
                setConfirmPass('')
                setCurrentPass('');
                setLoading(false);
                localStorage.clear();
                window.location.reload();

            }
        }).catch(e => {
            toast.error("Unable to change Password " + e.response.data.msg)
            setLoading(false);
        })

    }

    useEffect(() => {
        if (currentPass && password && password == confirmPass) {
            setAllowedChange(true)
        }
        else {
            setAllowedChange(false)
        }
    }, [password, confirmPass, currentPass])

    return (
        <div className="w-100">
            <div className="settings-title mb-16">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <h1 className="fz28  mb-16 black fw-po-medium">Change Password</h1>
                        {/* <p className="fz16 black-dark-700 mb-0">You can change your password by entering a new one below.</p> */}
                    </div>
                </div>
            </div>
            <div className="setting-change-password ml-24 mt-24 mr-24">
                <div className="chang-passowrd">
                    <div className="card box-shadow">
                        <div className={`form-group ${error && !currentPass ? 'invalid' : ''}`}>
                            <label htmlFor="" >Old Password </label>
                            <div className='position-relative'>
                                {!showCurrentPassword &&
                                    <span className="view-pass" onClick={() => setShowCurrentPassword(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D"></path></svg>
                                    </span>
                                }
                                {showCurrentPassword &&
                                    <span className="view-pass" onClick={() => setShowCurrentPassword(false)}>
                                        <i className="far fa-eye-slash fa-1x"></i>
                                    </span>
                                }
                                <input type={showCurrentPassword ? 'text' : "password"} className='form-control' name="currentPass" placeholder='********' value={currentPass} onChange={(e) => setCurrentPass(e.target.value)} />
                            </div>
                            {error && !currentPass && <div className='text-danger'>Old password is required</div>}
                        </div>
                        <div className={`form-group ${error && !password ? 'invalid' : ''}`}>
                            <label htmlFor="" className='d-block'>New Password </label>
                            <div className="position-relative">
                                {!showPassword &&
                                    <span className="view-pass" onClick={() => setShowPassword(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D"></path></svg>
                                    </span>
                                }
                                {showPassword &&
                                    <span className="view-pass" onClick={() => setShowPassword(false)}>
                                        <i className="far fa-eye-slash fa-1x"></i>
                                    </span>
                                }
                                <input type={showPassword ? 'text' : 'password'} className='form-control' name="password" placeholder='********' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {error && !password && <div className='text-danger'>New password is required</div>}
                        </div>
                        <div className={`form-group ${error && !confirmPass ? 'invalid' : ''}`}>
                            <label htmlFor="" className='d-block'>Enter New Password Again </label>
                            <div className="position-relative">
                                {!showConfirmPassword &&
                                    <span className="view-pass" onClick={() => setShowConfirmPassword(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D"></path></svg>
                                    </span>
                                }
                                {showConfirmPassword &&
                                    <span className="view-pass" onClick={() => setShowConfirmPassword(false)}>
                                        <i className="far fa-eye-slash fa-1x"></i>
                                    </span>
                                }
                                <input type={showConfirmPassword ? 'text' : "password"} className='form-control' name="confirmPass" value={confirmPass} placeholder='********' onChange={(e) => setConfirmPass(e.target.value)} />
                            </div>
                            {error && !confirmPass && <div className='text-danger'>Confirm password is required</div>}
                        </div>
                        <div className="form-group d-flex align-items-center justify-content-end">
                            {/* <button className='outline-btn'>Cancel</button> */}
                            {!loading && <button className='pr-btn w-100' onClick={updatePasswordSubmit}>Change Password</button>}
                            {loading && <button className="pr-btn w-100 flex items-center justify-center" type='button'>
                                <Oval
                                    height={24}
                                    width={24}
                                    color="#ffffff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#ffffff"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}