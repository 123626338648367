import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { getAllSubscription } from '../../../services/public/public.service';
import InputSelect from '../../../components/InputGroup/InputSelect';
import { getUsersForSettings, updateRm } from '../../../services/private/company.service';
import { toast } from 'react-toastify';

const UpdateCompanyRMModal = ({ show, closeModal, selectedItem, getCompanyData }) => {

    const [usersList, setUsersList] = useState([]);
    const [user, setUser] = useState('');
    const [error, setError] = useState(false);

    const getUsers = async () => {
        const res = await getUsersForSettings();
        if (res.data.status === 200) {
            setUsersList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
        }
    }
    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedItem).length > 0) {
            setUser(selectedItem?.rm_id)
        }
    }, [selectedItem])

    const handleUpdateRM = async () => {
        if (!user) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        const payload = {
            user_id: user
        }
        const res = await updateRm(selectedItem.uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getCompanyData();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Update Sourcing Manager</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className="resent-notes px-4 !pt-4 h-[63vh] overflow-auto">
                <InputSelect
                    value={user ? usersList.find(i => i.id === user) : ''}
                    label={'Select Sourcing Manager'}
                    placeholder={'Select Sourcing Manager'}
                    options={usersList}
                    onChange={(e) => {
                        setUser(e.value)
                    }}
                    required={true}
                    error={error && !user ? 'Please Select Sourcing Manager' : ''}
                // onBlur={() => setTouchedMentorId(true)}
                />
            </div>
            <div className={`absolute bottom-0 flex flex-row items-center justify-between bg-white border-top border-color w-100 space-x-3 p-3`}>
                <button className='w-1/2 outline-btn !py-1.5 !text-lg !font-medim hover:bg-black100 !rounded-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-1/2 !py-1.5 !text-lg !font-medim !rounded-lg' onClick={handleUpdateRM}>Save</button>
            </div>
        </RightModal>
    )
}

export default UpdateCompanyRMModal; 