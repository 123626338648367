import React from 'react'
import CenterModal from '../../../../components/Modals/center-modal'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import SmallCenterModal from '../../../../components/Modals/small-center-modal';
import { enums } from '../../../../helpers/filtersEnum';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner';

const ExportDashboardLeads = ({ show, closeModal, count, filters, handleSubmit, exportLoading, leadCount, renderFilterList, leadType }) => {

    const getCount = () => {
        switch (leadType) {
            case 'all_leads':
                return count?.leadsCount
            case 'followups':
                return count?.followupsCount
            case 'site_visits_scheduled':
                return count?.siteVisitScheduledCount;
            case 'site_visits_completed':
                return count?.siteVisitCompletedCount;
            case 'new_leads':
                return count?.newLeadsCount;
            case 'booking_only':
                return count?.bookingsOnlyCount;
            case 'eoi_only':
                return count?.eoiOnlyCount;
            case 'final_negotiation':
                return count?.finalNegotiationCount;
            case 'dead_leads':
                return count?.deadLeadsCount
        }
    }

    return (
        <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'w-[600px]'}>
            <div className='!p-4'>
                <div className='flex justify-between'>
                    <div className='border rounded-lg p-2'>
                        <DownloadIcon />
                    </div>
                    <button className='' onClick={() => {
                        closeModal();
                    }}><CloseIcon /></button>
                </div>
                <div className='!mt-4'>
                    <p className='text-lg m-0 font-medium'>Export Leads ({getCount()}) </p>
                    <div className='text-sm !mt-3 text-[#667085]'>Leads will be exported in a CSV format. </div>
                </div>
                {filters?.length > 0 && <div className='!mt-5 '>
                    <p className='m-0 mb-2 text-sm font-medium text-[#667085]'>Selected Filters to Export </p>
                    <div className='flex items-center'>
                        {filters?.map((data, index) => (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                        <div className='info-msg'>
                                            <ul className="mb-0">
                                                {renderFilterList(data)}
                                                {/* <li>1. Signed up by mistake</li> */}
                                                {/* <li>2. Signed up by mistake</li> */}
                                            </ul>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <p className='text-sm font-medium border rounded-lg w-max p-2 m-0 bg-black200 mr-2'>
                                    {Object.keys(data)[0] === 'Assigned To' ? enums['Assigned User'] : enums[Object.keys(data)]}
                                </p>
                            </OverlayTrigger>
                        ))
                        }
                    </div>

                </div>}
                <div className={` flex items-center justify-end !mt-8`}>
                    <button className='border !text-base !font-medium !px-5 !py-1.5 w-[150px] rounded-lg hover:bg-black100' onClick={() => {
                        closeModal();
                    }}>Cancel</button>
                    {!exportLoading && <button className=' bg-primary text-white !text-base !px-5 !ml-3 w-[150px] !font-medium !py-1.5 rounded-lg' onClick={handleSubmit}>Export</button>}
                    {exportLoading && <button className=' bg-primary flex items-center justify-center !text-base !px-5 !ml-3 w-[150px] !font-medium !py-1.5 rounded-lg' >
                        <Oval
                            height={20}
                            width={20}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}

                        />
                    </button>}
                </div>
            </div>
        </SmallCenterModal>
    )
}

export default ExportDashboardLeads