import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import { bhk_type, unit_type } from '../../../utils/project-constant';
import { insertPricingBooking, updatePricingBookingByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputRupees from '../../InputGroup/InputRupees';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const AddBookingPriceModal = ({ show, closeModal, modalClass, configurations, uuid, getBookingPricing, selectedPrice }) => {
    const [unitTypeAvailble, setUnitTypeAvailable] = useState();
    const [unitConfigurationAvailable, setUnitConfigurationAvailble] = useState();
    const [absoluteAmount, setAbsoluteAmount] = useState();
    const [percentage, setPercentage] = useState();
    const [gstPercentage, setGstPercentage] = useState();
    const [tokenSelection, setTokenSelection] = useState('absolute');
    const [error, setError] = useState(false);


    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            const unitType = unit_type.filter(item => selectedPrice.unit_type?.includes(item.label));
            const unitConfig = configurations.filter(item => selectedPrice.config?.includes(item.value));
            setUnitTypeAvailable(unitType);
            setUnitConfigurationAvailble(unitConfig);
            setPercentage(selectedPrice.payment_percentage);
            setAbsoluteAmount(selectedPrice.payment_amount);
            setGstPercentage(selectedPrice.gst_percentage);
            if (selectedPrice.payment_amount) {
                setTokenSelection('absolute')
            }
            if (selectedPrice.payment_percentage) {
                setTokenSelection('percent')
            }
        }
    }, [selectedPrice]);

    const handleSubmit = async () => {
        if (!unitConfigurationAvailable || unitConfigurationAvailable?.length === 0) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (tokenSelection === 'absolute' && !absoluteAmount) {
            setError(true);
            return;
        } else if (tokenSelection === 'percent' && !percentage) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            unit_type: null,
            unit_configuration: unitConfigurationAvailable.map(item => item.value),
            payment_percentage: percentage || null,
            payment_amount: absoluteAmount || null,
            gst_percentage: gstPercentage || null
        }

        let res = {};
        if (selectedPrice?.uuid) {
            res = await updatePricingBookingByUUID(selectedPrice.uuid, payload)
        } else {
            res = await insertPricingBooking(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getBookingPricing();
            closeModal();
        }
    }

    const handleClose = () => {
        closeModal();
        setUnitTypeAvailable('');
        setUnitConfigurationAvailble('');
        setAbsoluteAmount('');
        setPercentage('');
        setGstPercentage('');
        setTokenSelection('');
    }


    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Booking Price
                </h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                {/* <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Type Applicability'
                    isMulti={true}
                    options={unit_type}
                    value={unitTypeAvailble}
                    onChange={(value) => setUnitTypeAvailable(value)}
                /> */}
                <InputSelect
                    inputclass='!mt-0'
                    styles={customStyles}
                    label='Unit Configuration Applicability'
                    value={unitConfigurationAvailable}
                    options={configurations}
                    onChange={(value) => setUnitConfigurationAvailble(value)}
                    isMulti={true}
                    error={(error && (!unitConfigurationAvailable || unitConfigurationAvailable?.length === 0)) ? 'Please select unit configuration' : ''}
                />
                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Booking Value Type
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='absolute' checked={tokenSelection === 'absolute'} onChange={() => {
                                setTokenSelection('absolute')
                                setPercentage('')
                            }} />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='percent' checked={tokenSelection === 'percent'} onChange={() => {
                                setTokenSelection('percent');
                                setAbsoluteAmount('')
                            }} />
                            Percentage of Base
                        </label>
                    </div>
                    {tokenSelection === 'percent' &&
                        <InputText
                            placeholder='Enter Percentage'
                            value={percentage}
                            onChange={(e) => setPercentage(e.target.value)}
                            type='number'
                        />}
                    {tokenSelection === 'absolute' &&
                        <InputRupees
                            placeholder='Enter Absolute'
                            value={absoluteAmount}
                            onChange={(value) => setAbsoluteAmount(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}
                        />}
                    {error && ((tokenSelection === 'absolute' && !absoluteAmount) || (tokenSelection === 'percent' && !percentage)) &&
                        <div className='invalid'>
                            <div className="input-feedback mt-8">
                                Please Enter Booking Value
                            </div>
                        </div>
                    }
                </div>
                {/* <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>EOI Token Value Type
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" checked />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" />
                            Percentage of Base
                        </label>
                    </div>
                    <InputText
                        placeholder='Enter value' />
                </div> */}
                <InputText
                    label='GST %'
                    placeholder='Enter Percentage'
                    value={gstPercentage}
                    onChange={(e) => setGstPercentage(e.target.value)}
                    type={'number'}
                />
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button type='button' className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddBookingPriceModal;