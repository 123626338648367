import React from "react";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getColumnPreferenceNew, getPermissions, getRoleDetail, getRoles, saveColumnPreferenceNew, setPermissions } from "../../../../services/private/company.service"
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as ResetIcon } from '../../../../assets/icons/reset.svg';
import ResetPermission from "./Modal/ResetPermission";
import { ReactComponent as BackIcon } from '../../../../assets/icons/leftArrow.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../assets/icons/searchClose.svg';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import TooltipText from "../../../../components/Tooltip";
import ListPermissions from "./ListPermissions";

const Permissions = () => {
    const [currentRoleUUID, setCurrentRoleUUID] = useState('')
    const [ShowEditRoleModal, setShowEditRoleModal] = useState(false)
    // const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [showCustomiseColumnsModal, setShowCustomiseColumnsModal] = useState(false);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [modulePermissions, setModulePermissions] = useState({});
    const [allPermission, setAllPermission] = useState();
    const [selectedPermission, setSelectedPermissions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [allowedPermissions, setAllowedPermissions] = useState([]);
    const [initialPermissions, setInitialPermissions] = useState([]);
    const [showResetPermissionModal, setShowResetPermissionModal] = useState(false);
    const [isListView, setIsListView] = useState(true)

    const history = useHistory();
    const location = useLocation();
    const { uuid } = useParams();

    const searchParams = new URLSearchParams(location.search);
    const roleParam = searchParams.get('role');
    const roleName = searchParams.get('name');
    const roleDescription = searchParams.get('description');
    const redirectFrom = searchParams.get('from');
    const restricted = searchParams.get('restricted');

    const getMaterPermissions = async () => {
        const res = await getPermissions(searchText)
        if (res.data.status === 200) {
            setInitialPermissions(res.data.data);

        }
    }

    const getRolesDetail = async () => {
        const res = await getRoleDetail(uuid);
        if (res.data.status === 200) {
            if (res.data.data.allowed_permissions) {
                setAllowedPermissions(res.data.data.allowed_permissions)
            }
        }

    }


    useEffect(() => {
        // getMaterPermissions();
        // getRolesDetail();
        if (searchText) {
            let module = {}
            for (const [key, value] of Object.entries(allPermission)) {
                let val = []
                value.forEach((item, index) => {
                    const newPermission = item.permissions.filter(perm => perm.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                    if (newPermission.length > 0) {
                        const newItem = { ...item, permissions: newPermission }
                        val.push(newItem)
                        // value[index] = newItem
                        module[key] = val
                    }

                })

            }
            setModulePermissions(module);
        } else {
            setModulePermissions(allPermission);
        }
    }, [searchText]);

    useEffect(() => {
        getMaterPermissions();
        if (uuid) {
            getRolesDetail()
        }
    }, [uuid])

    useEffect(() => {
        if (localStorage.getItem('allowedPermissions')) {
            setAllowedPermissions(JSON.parse(localStorage.getItem('allowedPermissions')))
        }
    }, [])

    const addDefaultPermissions = () => {
        let module = {}
        let currentSubOModule = '';
        initialPermissions.forEach((item) => {
            let is_sub_module = false;
            let tempPermission = {
                name: item.name,
                value: item.value,
                description: item.description,
                linked_permissions: item.linked_permissions,
                is_selected: allowedPermissions?.includes(item.value) || false
            };

            if (!module[item.module]) {
                // Adding new module
                module[item.module] = [];
                // adding new sub module
                currentSubOModule = item.sub_module;
                if (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)) {
                    is_sub_module = true;
                }
                let temp = {
                    subModule: item.sub_module,
                    is_sub_module: is_sub_module,
                    permissions: [tempPermission]
                }
                module[item.module].push(temp);

            } else {
                if (item.sub_module != currentSubOModule) {
                    // adding new sub module
                    currentSubOModule = item.sub_module;
                    if (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)) {
                        is_sub_module = true;
                    }
                    let temp = {
                        subModule: item.sub_module,
                        is_sub_module: is_sub_module,
                        permissions: [tempPermission]
                    }
                    module[item.module].push(temp);
                } else {
                    // adding permission
                    const index = module[item.module].findIndex(md => md.subModule === currentSubOModule)
                    if (is_sub_module || (item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value))) {
                        module[item.module][index].is_sub_module = is_sub_module || item.sub_module === currentSubOModule && allowedPermissions?.includes(item.value)
                    }
                    module[item.module][index].permissions.push(tempPermission);
                }
            }

        })

        setModulePermissions(module);
        setAllPermission(module);
        setShowResetPermissionModal(false)
    }

    useEffect(() => {
        if (initialPermissions || allowedPermissions) {
            addDefaultPermissions();
        }
    }, [allowedPermissions, initialPermissions]);

    const onPress = (e) => {
        e.preventDefault();
        const target = window.document.getElementById(
            e.currentTarget.href.split("#")[1]
        );
        if (target) {
            var headerOffset = 20;
            var elementPosition = target.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollBy({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    const handleSearch = (e) => {
        // console.log(Object.entries(allPermission), 'entries')

        // const result = Object.entries(allPermission).filter(([key, value], index) => value.filter(item => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1))
        // console.log(result);
        // for (const [key, value] of Object.entries(allPermission)) {
        //     const result = value.filter(item => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
        //     // console.log({ result })
        // }
    }

    const handleChangePermission = (event, item, subIndex, itemIndex, value, name, subModule) => {
        event.stopPropagation();
        let perms = { ...modulePermissions };
        let allPerms = { ...allPermission };

        perms[item][subIndex].permissions[itemIndex].is_selected = value;
        // change in copy permission
        const sIndex = allPerms[item].findIndex(i => i.subModule === subModule)
        const pIndex = allPerms[item][sIndex].permissions.findIndex(i => i.name === name)
        allPerms[item][subIndex].permissions[pIndex].is_selected = value;

        if (perms[item][subIndex].permissions[itemIndex].linked_permissions) {
            perms[item][subIndex].permissions[itemIndex].linked_permissions.map(linkItem => {
                perms[item][subIndex].permissions.forEach(pItem => {
                    if (linkItem === pItem.value) {
                        pItem.is_selected = value
                    }
                })
            })
        }

        if (allPerms[item][subIndex].permissions[itemIndex].linked_permissions) {
            allPerms[item][subIndex].permissions[itemIndex].linked_permissions.map(linkItem => {
                allPerms[item][subIndex].permissions.forEach(pItem => {
                    if (linkItem === pItem.value) {
                        pItem.is_selected = value
                    }
                })
            })
        }
        // perms[item][subIndex].permissions[itemIndex]

        /**
         * check every value of permissions is false or not 
         * If all is_selected value is false it return true otherwise false
         */
        const everySelectedVal = perms[item][subIndex].permissions.every(permiss => !permiss.is_selected)
        if (everySelectedVal) {
            perms[item][subIndex].is_sub_module = false
            allPerms[item][subIndex].is_sub_module = false
        } else {
            perms[item][subIndex].is_sub_module = true
            allPerms[item][subIndex].is_sub_module = true
        }
        setModulePermissions(perms);
        setAllPermission(allPerms);
    }

    const handlesubmit = async () => {
        let selectedPermission = []
        Object.values(allPermission).forEach(permissions => {
            permissions.forEach(permis => {
                let permisnVal = permis.permissions.filter(item => item.is_selected).map(item => item.value)
                selectedPermission = [...selectedPermission, ...permisnVal];
            })
        })

        if (selectedPermission.length === 0) {
            toast.warning('Please select at one permission');
            return
        }
        const res = await setPermissions(uuid, { permissions: selectedPermission });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            localStorage.removeItem('allowedPermissions');
            // redirect page based on from its navigate
            if (roleParam === 'edit') {
                if (redirectFrom === 'detail') {
                    history.push({
                        pathname: `/settings/user-roles/roles/detail/${uuid}`,
                        search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}`,
                    })
                } else {
                    history.push('/settings/user-roles/roles')
                }
            } else {
                history.push({
                    pathname: `/settings/user-roles/roles/new/invite-user/${uuid}`,
                    state: { name: roleName, description: roleDescription },
                    search: `?role=${roleParam}&from=detail&name=${roleName}&description=${roleDescription}`,
                })
            }
        } else {
            console.log(res.data.message)
        }
    }

    const handleMainModuleChange = (e, item, index, value) => {
        e.stopPropagation();
        let newModule = { ...modulePermissions };
        let allPerms = { ...allPermission };
        if (value) {
            newModule[item][index].permissions.map(permis => permis.is_selected = false);
            newModule[item][index].is_sub_module = false;
            allPerms[item][index].permissions.map(permis => permis.is_selected = false);
            allPerms[item][index].is_sub_module = false;
        } else {
            newModule[item][index].permissions.map(permis => permis.is_selected = true);
            newModule[item][index].is_sub_module = true;
            allPerms[item][index].permissions.map(permis => permis.is_selected = true);
            allPerms[item][index].is_sub_module = true;
        }
        setModulePermissions(newModule);
        setAllPermission(allPerms);
    }

    return (
        <>
            {!isListView && <>
                <div className="settings-title mb-2.5 w-full">
                    <nav aria-label="breadcrumb" className=''>
                        {roleParam === 'edit' && redirectFrom === 'listing' &&
                            <ul className="flex items-center justify-start flex-row mb-8 leading-6">
                                <li className="!text-primary pr-1">
                                    <Link to={{
                                        pathname: `/settings/user-roles/roles`,

                                    }}>Roles </Link>
                                </li>
                                <li className="flex items-center !text-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                        <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                    </svg>
                                    <span className="pl-2 pr-1">Assign Permissions</span>
                                </li>

                            </ul>}
                        {
                            roleParam === 'edit' && redirectFrom === 'detail' &&
                            <>
                                <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                                    <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                                        <li>
                                            <Link to='/settings/user-roles/roles'>Roles</Link>
                                        </li>
                                        <li className='flex items-center'>
                                            <RightArrow />
                                            <Link to={{
                                                pathname: `/settings/user-roles/roles/detail/${uuid}`,
                                                search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                            }}>{roleName} </Link>

                                        </li>
                                        <li className='flex items-center'><RightArrow />
                                            Edit {roleName}  Permissions
                                        </li>
                                    </ul>
                                </nav>
                            </>
                        }
                        {!roleParam && !redirectFrom &&
                            <ul className="flex items-center justify-start flex-row mb-8 leading-6">
                                <li className="text-primary pr-1">
                                    <Link to='/settings/user-roles/roles/new'>Create New Role </Link>
                                </li>
                                <li className="flex items-center text-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                        <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                    </svg>
                                    <span className="pl-2 pr-1">Assign Permissions</span>
                                </li>
                                <li className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                        <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                    </svg>
                                    <span className="pl-2">Invite / Add Users</span>
                                </li>
                            </ul>
                        }
                    </nav>
                    <div className="w-100 flex">
                        <div className="w-1/2">
                            <h3 className="text-2xl !text-black font-medium flex items-center !gap-x-2 m-0">
                                <Link
                                    to={{
                                        pathname: `/settings/user-roles/roles/detail/${uuid}`,
                                        search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}&restricted=${restricted}`,
                                    }}
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>
                                Assign Permissions ({roleName})</h3>
                            {/* <p className="fz16 black-dark-700 mb-0">
                                Lorem ipsum dolor sit amet consectetur. Praesent vitae pellentesque ut hendrerit semper odio ornare. Adipiscing facilisis risus nunc fringilla viverra.
                            </p> */}
                        </div>
                        <div className="w-1/2 flex items-start space-x-2 justify-end">

                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder='Search For Permissions'
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                            <div>
                                <button className="flex !border !border-grayLight rounded !px-3 !py-1.5 text-sm font-medium leading-5 !bg-white hover:!bg-black100 text-black700 items-center" onClick={() => {
                                    setShowResetPermissionModal(true);
                                }
                                }>
                                    {/* <ResetIcon className="mr-2" /> */}
                                    Reset to Default
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col h-full overflow-auto w-full">
                    <div className="flex pl-6 pr-6 pb-10 !mb-2 w-full h-full overflow-auto ">

                        <div className="w-full h-full !mb-6">
                            {Object.keys(modulePermissions).length > 0 && <ScrollSpy
                                offsetBottom={100}
                                scrollThrottle={80}
                                useBoxMethod={false}>
                                {
                                    modulePermissions && Object.keys(modulePermissions)?.map((item, index) => (
                                        <div key={`${item}_${index}`} id={`scroll_${index}`} className={`${index !== 0 ? 'mt-4' : ''}`}>
                                            <h6 className="text-xl text-textblack font-medium inter mb-0">{item}</h6>
                                            <div className="border-b !mt-2"></div>
                                            <>
                                                {modulePermissions[item]?.map((subMod, subIndex) => (
                                                    <div className="space-y-6" key={`${subMod.subModule}_${subIndex}`}>
                                                        <div className="flex items-center mt-4">

                                                            <p className="text-sm  !m-0 !mr-2 text-black700 leading-[22px] inter">{subMod.subModule}</p>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className={`btn btn-lg btn-toggle ${subMod.is_sub_module ? 'active' : ''}  mr-2`}
                                                                    data-toggle="button"
                                                                    aria-pressed='true'
                                                                    autoComplete="off"
                                                                    onClick={(e) => handleMainModuleChange(e, item, subIndex, subMod.is_sub_module)}
                                                                >
                                                                    <div className="handle"></div>
                                                                    <span className={`${subMod.is_sub_module ? 'on' : 'off'}`}>{subMod.is_sub_module ? 'ON' : 'OFF'}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-2 gap-y-4 gap-x-9 mt-2">
                                                            {subMod.permissions?.map((permission, itemIndex) => (
                                                                <div
                                                                    className="!border !border-grayLight rounded-md px-3 py-2.5 cursor-pointer"
                                                                    key={`${permission}_${itemIndex}`}
                                                                    onClick={(event) => {
                                                                        handleChangePermission(event, item, subIndex, itemIndex, !permission.is_selected, permission.name, subMod.subModule)
                                                                    }}
                                                                >
                                                                    <div className="flex justify-between items-center">
                                                                        <p className="m-0 font-semibold leading-5 text-xs flex items-center text-textblack inter">
                                                                            {permission.name}
                                                                            {permission.description && <TooltipText title={permission.description}>
                                                                                <InfoIcon className="ml-1" />
                                                                            </TooltipText>}
                                                                        </p>
                                                                        <div>
                                                                            <button
                                                                                type="button"
                                                                                className={`btn btn-lg btn-toggle ${permission.is_selected ? 'active' : ''}  mr-2`}
                                                                                data-toggle="button"
                                                                                aria-pressed='true'
                                                                                autoComplete="off"

                                                                            >
                                                                                <div className="handle"></div>
                                                                                <span className={`${permission.is_selected ? 'on' : 'off'}`}>{permission.is_selected ? 'ON' : 'OFF'}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </>
                                        </div>
                                    ))
                                }
                            </ScrollSpy>}
                            {
                                searchText && Object.keys(modulePermissions).length === 0 &&
                                <div className='flex justify-center flex-column items-center h-100'>
                                    <figure><ResultSearchIcon /></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                    <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                        <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                                </div>
                            }

                        </div>

                        {/* <div className="w-1/5 drop-shadow-lg">
                        <div className="">
                            {
                                modulePermissions && Object.keys(modulePermissions).map((item, index) => (
                                    // <a onClick={(e) => onPress(e)} href={`#scroll_${index}`} key={`${item}_${index}`}>
                                    <p className="py-1 px-3 m-0" data-to-scrollspy-id={`scroll_${index}`} key={`${item}_${index}`}>{item}</p>
                                    // </a>
                                ))
                            }

                        </div>
                    </div> */}
                    </div>
                    <div className={` flex flex-row items-center bg-white justify-between border-top border-color w-100 space-x-3 p-3`}>
                        <button className='border rounded-md text-black  py-2 px-[12px] flex items-center !bg-white hover:!bg-black100'
                            onClick={() => {
                                if (roleParam === 'edit') {
                                    if (redirectFrom === 'detail') {
                                        history.push({
                                            pathname: `/settings/user-roles/roles/detail/${uuid}`,
                                            search: `?role=edit&from=detail&name=${roleName}&description=${roleDescription}`,
                                        })
                                    } else {
                                        history.push('/settings/user-roles/roles')
                                    }
                                } else {
                                    history.push(
                                        {
                                            pathname: `/settings/user-roles/roles/new/${uuid}`,
                                            search: `?role=${roleParam}&from=new&name=${roleName}&description=${roleDescription}`
                                        }
                                    )
                                }
                            }}
                        >
                            <BackIcon />
                            Previous </button>
                        <button className='text-white bg-primary font-base rounded-lg flex px-[45px] py-2 font-medium' style={{ fontSize: '16px' }} onClick={() => {
                            handlesubmit()
                        }
                        }>
                            Save Permission
                        </button>
                    </div>
                </div>
            </>}

            {
                showResetPermissionModal && <ResetPermission show={showResetPermissionModal} closeModal={() => setShowResetPermissionModal(false)} handleSubmit={addDefaultPermissions} />
            }
            {
                isListView && <ListPermissions />
            }

        </>
    );
}

export default Permissions;
