import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import '../../App.css'
import * as Yup from 'yup';
import './login.css';
import PropTypes from 'prop-types';
import { showHideConfirmPassWord, showHidePassword, forgotRequest, forgotPageInit } from './actions';
import * as qs from 'qs';
import SideBanner from '../../components/SideBanner/SideBanner';
import { ALL_REGEX } from '../../config/static';


class ForgotPassWord extends Component {
    constructor(props) {
        super(props);

        this.state = {

            password: React.createRef(''),
            confirmPassword: React.createRef(''),
            formRef: React.createRef(''),

        }



    }


    render() {

        let Heading = `A Real Estate Platform That 'Works For You'`;
        let SubHeadingText = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;
        const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const code = queryParams['confirmation_code'];
        const email = queryParams['user_name'];

        if (!code || !email) return (<div>  Invalid Url Params!!!!!!  </div>)
        else if (code && email && !this.props.passwordReset) { this.props.onValidRequest({ email, code }) }

        return (

            <div className="row">

                <div className="col-lg-4 col-md-4 col-sm-4 no-padding">

                    <SideBanner Heading={Heading} Text={SubHeadingText} />
                </div>

                <div className="col-lg-8 col-md-8 col-sm-4 d-flex flex-fill">
                    <div className="container h-100">
                        <div className="row justify-content-md-center">
                            <div className="card-wrapper">
                                {!this.props.passwordReset ? (
                                    <>
                                        <div className="card fat">
                                            <div className="card-body">
                                                <div className="login-log">
                                                    <img src={`${process.env.PUBLIC_URL}/Logo.png`} />
                                                </div>
                                                <p> Forgot PassWord </p>
                                                <Formik ref={this.state.formRef} validateOnMount={true} initialValues={{ confirmPassword: this.state.confirmPassword && this.state.confirmPassword.current ? this.state.confirmPassword.current.value : '', password: this.state.password && this.state.password.current ? this.state.password.current.value : '' }} onSubmit={this.props.onSubmitForgotPasswordForm} validationSchema={Yup.object().shape({
                                                    password: Yup.string().required('Required').matches(
                                                        ALL_REGEX.PASSWORD,
                                                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                                    ),
                                                    confirmPassword: Yup.string().required('Required')
                                                        .oneOf([Yup.ref('password'), null], 'Passwords must match')

                                                })}>
                                                    {props => {
                                                        const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = props;
                                                        return (
                                                            <form method="POST" className="my-login-validation" onSubmit={handleSubmit}>


                                                                <div className="form-group password">
                                                                    <label htmlFor="password" className="custom-txt"  >Enter your password</label>
                                                                    {!this.props.showPassword ? (

                                                                        <div className='position-relative'>
                                                                            <span onClick={() => this.props.onShowHidePassword()} ><i className="far fa-eye fa-1x" >   </i> </span>
                                                                            <input id="password" ref={this.state.password} autoComplete="off" onChange={handleChange} value={values.password}
                                                                                onBlur={handleBlur}
                                                                                className={
                                                                                    errors.password && touched.password ? 'form-control text-input error' : 'form-control text-input'
                                                                                } type="password" name="password" />
                                                                        </div>) : (<div className='position-relative'> <span onClick={() => this.props.onShowHidePassword()} >  <i className="far fa-eye-slash fa-1x"></i>  </span> <input ref={this.state.password} id="password" autoComplete="off" onChange={handleChange} value={values.password}

                                                                            onBlur={handleBlur}
                                                                            className={
                                                                                errors.password && touched.password ? 'form-control text-input error' : 'form-control text-input'
                                                                            } type="text" name="password" /></div>)}
                                                                    {errors.password && touched.password && (
                                                                        <div className="input-feedback">{errors.password}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group password">
                                                                    <label htmlFor="confirmPassword" className="custom-txt">Confirm your password</label>
                                                                    {!this.props.showConfirmPassword ? (

                                                                        <div>
                                                                            <span onClick={() => this.props.onShowHideConfirmPassword()} ><i className="far fa-eye fa-1x" >   </i> </span>


                                                                            <input id="confirmPassword" ref={this.state.confirmPassword} autoComplete="off" onChange={handleChange} value={values.confirmPassword}

                                                                                onBlur={handleBlur}
                                                                                className={
                                                                                    errors.confirmPassword && touched.confirmPassword ? 'form-control text-input error' : 'form-control text-input'
                                                                                } type="password" name="confirmPassword" />


                                                                        </div>) : (<div> <span onClick={() => this.props.onShowHideConfirmPassword()} >  <i className="far fa-eye-slash fa-1x"></i>  </span> <input ref={this.state.confirmPassword} id="confirm-password" autoComplete="off" onChange={handleChange} value={values.confirmPassword}

                                                                            onBlur={handleBlur}
                                                                            className={
                                                                                errors.confirmPassword && touched.confirmPassword ? 'form-control text-input error' : 'form-control text-input'
                                                                            } type="text" name="confirmPassword" /></div>)}
                                                                    {errors.confirmPassword && touched.confirmPassword && (
                                                                        <div className="input-feedback">{errors.confirmPassword}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group m-0">
                                                                    <button type="submit" className="pr-btn">
                                                                        RESET PASSWORD
                                                                    </button>

                                                                    {this.props.errors ? <div className="input-feedback text-center mt-10">{this.props.errors.message}</div> : <></>}

                                                                </div>


                                                            </form>
                                                        );
                                                    }}
                                                </Formik>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="card-body">
                                        <div className="login-log">
                                            <img src={`${process.env.PUBLIC_URL}/logo.png`} />
                                        </div>
                                        <p>Forgot PassWord</p>

                                        <p> You Password has been reset </p>
                                        <p> Navigate to login <Link to='/login'> click here </Link> </p>


                                    </div>


                                )}
                            </div>
                        </div>
                    </div>




                </div>

            </div>

        );
    }
}

ForgotPassWord.propTypes = {
    onSubmitForgotPasswordForm: PropTypes.func,
    errors: PropTypes.object
};

function mapStateToProps(state) {


    return { errors: state.forgotPassword.errors, showPassword: state.forgotPassword.showPassword, showConfirmPassword: state.forgotPassword.showConfirmPassword, passwordReset: state.forgotPassword.passwordReset };
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitForgotPasswordForm: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();

            dispatch(forgotRequest(evt.password));
        },
        onShowHidePassword: (evt) => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(showHidePassword(evt));
        },
        onShowHideConfirmPassword: (evt) => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(showHideConfirmPassWord(evt));
        },
        onValidRequest: (evt) => {
            dispatch(forgotPageInit(evt))
        }


    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ForgotPassWord));