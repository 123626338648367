import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import TextEditor from '../Editor';
import { setProjectFaq, } from '../../../services/public/public.service';
import { toast } from 'react-toastify';

const FAQModal = ({ show, closeModal, style, projectUuid, getAllFaqs, allFaqs, setAllFaqs }) => {
    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        if (allFaqs?.length > 0) {
            setFaqs([...allFaqs])
        } else {
            setFaqs([{}])
        }
    }, [allFaqs]);

    const handleSubmit = async () => {
        // setAllFaqs(faqs);        
        const res = await setProjectFaq(projectUuid, faqs);
        if ((res.data.status === 200)) {
            toast.success(res.data.message);
            getAllFaqs();
            closeModal();
        }
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    FAQ
                </h1>
                <button className='border-0 bg-tr' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>
                {
                    faqs.map((faq, index) => (
                        <div className='' key={`${index}`}>
                            <div className='flex flex-col gap-y-4'>
                                <div>
                                    <div className='flex justify-between'>
                                        <label htmlFor="">Question {index + 1}</label>
                                        <button className='border-0 bg-tr ' onClick={() => {
                                            if (index > 1) {
                                                let newFaqs = faqs
                                                newFaqs.splice(index, 1);
                                                setFaqs(newFaqs);
                                            } else {
                                                setFaqs([{}])
                                            }
                                        }}><CloseIcon /></button>
                                    </div>
                                    <InputText
                                        type={'text'}
                                        placeholder={'Enter Question'}
                                        value={faq.question}
                                        onChange={(e) => {
                                            let newFaqs = [...faqs]
                                            newFaqs[index]['question'] = e.target.value;
                                            setFaqs(newFaqs);
                                        }}
                                    />
                                </div>
                                <div className=''>
                                    <label htmlFor="">Answer {index + 1}</label>
                                    <TextEditor
                                        data={faq.answer}
                                        onChange={(value) => {
                                            let newFaqs = [...faqs];
                                            newFaqs[index]['answer'] = value;
                                            setFaqs(newFaqs);
                                        }}
                                    />
                                </div>

                            </div>

                        </div>
                    ))
                }

                <div className="!mt-3 flex items-center justify-end">
                    <button
                        type='button'
                        className=' !text-white p-2 text-sm font-medium inter bg-primary rounded-lg gap-2 inter'
                        onClick={() => {
                            setFaqs([...faqs, {}])
                        }}
                    >
                        + Add FAQ</button>
                </div>
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={handleSubmit} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default FAQModal;