import React from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import RightModal from '../../../../components/Modals/RightModal';

const AddVideoModal = ({ show, closeModal, videos, selectedVideo, setSelectedVideo }) => {

    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between !px-5 !py-4'>
                <h1 className='text-black text-2xl inter m-0'>Add Video</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='flex h-100 overflow-auto flex-column !px-5'>
                <div className='flex flex-col !gap-y-4'>
                    <InputSelect
                        label='Select Workshop'
                        placeholder='Select Workshop'
                        value={''}
                        onChange={(value) => {
                            const index = selectedVideo.findIndex(t => t.value === value.value);
                            if (index === -1) {
                                setSelectedVideo([...selectedVideo, value])
                            }

                        }}
                        options={videos}
                    />
                </div>
                {/* {addedUsers.length > 0 &&
                    <div className='!mt-4'>
                        <p className='text-base font-semibold'>Selected Users({addedUsers.length})</p>
                        <div className='border-b'></div>
                    </div>
                } */}
                <div className='h-[60vh] overflow-auto'>
                    {selectedVideo.map((workshop, index) => (
                        <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4 mt-2'>
                            <div className='flex justify-between gap-2'>
                                <div className='flex'>
                                    <div className='leading-[26px] inter'>
                                        <p className='m-0 text-black text-sm font-medium'>{workshop?.name}</p>
                                    </div>
                                </div>
                                <div className=''>
                                    <button className='' onClick={() => {
                                        let newLists = [...selectedVideo];
                                        newLists.splice(index, 1);
                                        setSelectedVideo(newLists);
                                    }}>
                                        <CloseIcon className='h-4 w-4' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow`}>
                <button className='outline-btn w-36 basis-36 shrink-0 grow-0  !text-lg' onClick={() => {
                    setSelectedVideo([])
                    closeModal();
                }}>Cancel</button>
                <button
                    type='button'
                    // disabled={addedUsers.length === 0}
                    onClick={() => closeModal()}
                    className='pr-btn w-100 !text-lg'
                >
                    Confirm
                </button>
            </div>
        </RightModal>
    )
}

export default AddVideoModal;
