import React, { useContext, useState, useEffect } from "react"
import { toast } from "react-toastify";

import { getLeadStatusShareConfig, getUnassignedLeadProjectDistribution, getUserProfile, updateAutoLeadShare, } from "../../../../services/private/company.service"
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import { ReactComponent as HeaderMenuIcon } from '../../../../assets/icons/HeaderMenu.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as PagebackIcon } from '../../../../assets/icons/Pageback.svg';

import { checkIsSomeItemAvailableInList, getInitials } from "../../../../helpers/helpers";
import TooltipText from "../../../../components/Tooltip";
import ShareLeadAccessModal from "../../../../components/Modals/ShareLeadAccessModal";
import { Link } from "react-router-dom";
import Consumer from '../../../../helpers/context';
import AddProjectUsers from "../Modals/AddProjectUsers";

const ProjectWiseLeads = () => {

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [projects, setProjects] = useState();

    let { allowedPermissions } = useContext(Consumer);

    const getAutomationData = async () => {
        const res = await getUnassignedLeadProjectDistribution();
        if (res.data.status === 200) {
            setProjects(res.data.data.projects);
        }
    }

    useEffect(() => {
        getAutomationData();
    }, []);

    if (!projects) {
        return null;
    }

    return (
        <>
            <div className="roles w-100 flex flex-col h-full">
                <div className="settings-title">
                    <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                        <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                            <li>
                                <Link to={`/settings/automation/lead-automation`}>All Automation</Link>
                            </li>
                            <li className='flex items-center'><RightArrowIcon />
                                Project wise distribution
                            </li>
                        </ul>
                    </nav>

                    {/* <nav aria-label="breadcrumb" className=''>

                        <ul className="flex items-center justify-start flex-row mb-8 leading-6">
                            <li className="hover:!text-primary  pr-1">
                                <Link to={`/settings/automation/lead-automation`}
                                    className='text-black700'
                                >All Automation </Link>
                            </li>
                            <li className="flex items-cente">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                </svg>
                                <span className="pl-2 pr-1">Status Permission</span>
                            </li>
                        </ul>
                    </nav> */}
                    <div className="w-100 flex justify-between">

                        <div className="">
                            <div className="flex items-center">
                                <h2 className="text-2xl  mb-2 mr-2 black fw-po-medium flex items-center gap-x-2">
                                    <Link

                                        to='/settings/automation/lead-automation'
                                        className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                    >
                                        <PagebackIcon className="group-hover:stroke-black" />
                                    </Link>
                                    Unassigned lead distribution project wise
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" ml-24 mt-24 h-full overflow-auto">
                    <div className="def-table pr-0 !h-full">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Project</th>
                                    <th>Users</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    projects && projects?.length > 0 && projects?.map((item, index) => (
                                        <tr key={`${item.value}`}>
                                            <td>{index + 1}</td>
                                            <td className="leading-5">{item.name}  </td>
                                            <td>
                                                {item?.users?.length > 0 ? <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                    <div className="flex flex-wrap items-center">

                                                        {
                                                            item.users.map((user, index) => (
                                                                index < 3 && <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 w-min p-1">
                                                                    {user.user_name && <TooltipText title={user.user_name || ''}>

                                                                        <span className="mr-2.5 border p-1 rounded-md text-sm bg-primary font-medium text-white">
                                                                            {getInitials(user.user_name || '')}
                                                                        </span>
                                                                    </TooltipText>}
                                                                    {user.user_name}
                                                                    {/* <CloseIcon className="h-4 w-4 cursor-pointer" onClick={() => handleRemoveTeam(user.uuid, user.team, item.uuid)} /> */}
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            item.users.length > 3 &&
                                                            <div className="tag-dropdown" >
                                                                <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    +{item.users.length - 3}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {item.users.map((user, index) => {
                                                                        return <a key={index} className="dropdown-item text-capitalize"  >{user.user_name}
                                                                        </a>

                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {/* <TooltipText title='Edit'>
                                                        <div className="bg-white border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center"
                                                            onClick={() => {
                                                                setShowAddUserModal(true)
                                                                setSelectedItem(item)
                                                            }}>
                                                            <EditIcon />
                                                        </div>
                                                    </TooltipText> */}
                                                </div> : <button
                                                    type="button"
                                                    className={`border rounded-lg w-20 hover:bg-black200 flex items-center justify-center `} onClick={() => {
                                                        setShowAddUserModal(true)
                                                        setSelectedItem(item)
                                                    }}
                                                // disabled={!checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads', 'edit_lead_sharing'])}
                                                >
                                                    + Add
                                                </button>}

                                            </td>
                                            <td>
                                                <div className="dropdown view-more ">
                                                    <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <HeaderMenuIcon />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                                        <button
                                                            className={`dropdown-item ${!checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads', 'edit_lead_sharing']) ? 'cursor-not-allowed bg-black200 text-black700' : 'cursor-pointer'}`}
                                                            onClick={() => {
                                                                setShowAddUserModal(true)
                                                                setSelectedItem(item)
                                                            }}
                                                            disabled={!checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads', 'edit_lead_sharing'])}
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                {/* {
                    showAssignedToModal && <AssignedTo
                        user={{}}
                        show={showAssignedToModal}
                        getUsersByUUID={() => { }}
                        closeModal={() => {
                            setSelectedItem()
                            setShowAssignedToModal(false)
                        }}
                    />
                } */}
                {showAddUserModal && <AddProjectUsers
                    show={showAddUserModal}
                    closeModal={() => setShowAddUserModal(false)}
                    selectedProject={selectedItem}
                    getAutomationData={getAutomationData}
                />}
            </div>
        </>
    );
}

export default ProjectWiseLeads;
