import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SliderModal from './SliderModal';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import InputSelect from '../InputGroup/InputSelect';
import { bookingDoneSchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import { getLatestActivityByUuid, getLeadByUuid, getProjectsByLead, insertBookingDone, searchLeadByName } from '../../services/private/leads.service';
import DateTimePicker from '../InputGroup/DateTime';
import { getAllProjects } from '../../services/private/company.service';
import InputText from '../InputGroup/InputText';
import { toINRFormat } from '../../helpers/helpers';
import { getBookingDoneDetailsByLeadId } from '../../services/private/inventory.service';

export default function BookingsDoneModal({
    show,
    setShow,
    leadId,
    onSuccess,
    isEdit,
    bookingsData,
    data
}) {

    const [projects, setProjects] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [projectList, setProjectList] = useState([]);
    const [bookingAmount, setBookingAmount] = useState();
    const [agreementAmount, setAgreementAmount] = useState();
    const [leadDetails, setLeadDetails] = useState();
    const [siteVisitData, setsiteVisitData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getLeadProjects = async (leadId) => {
        await getProjectsByLead(leadId).then(res => {
            if (res.status === 200) {
                const projectData = res.data.data.map((data) => {
                    return {
                        value: data.id,
                        label: data.name
                    }
                })
                setProjects(projectData)
            }
        })
    }

    const handleBookingComplete = async (values) => {
        setIsSubmitting(true);
        await insertBookingDone(selectedLeadId, {
            "scheduleDate": values.scheduleDate,
            "remarks": values.remarks,
            "projectId": values.projectId,
            amount: values.amount,
            agreement_price: values.agreementPrice,
            unit_no: values.unit_no
        }).then((res) => {
            if (res.status === 200) {
                toast.success(`Booking completed for ${leadDetails?.customer?.data?.name || data?.name}`)
                onSuccess();
                setShow(false)
            }
        }).catch(e => {
            toast.warn("Something Went Wrong")
        })
        setIsSubmitting(false);
    }

    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    const getLeadDetails = async () => {
        getBookingDoneDetailsByLeadId(leadId).then(res => {
            if (res.status === 200) {
                setAgreementAmount(toINRFormat(res.data.data?.booking?.agreement_price));
                setBookingAmount(toINRFormat(res.data.data?.booking?.amount))
                setLeadDetails({
                    ...res.data.data?.booking,
                    projectId: res.data.data?.project?.id
                })
            }
        })
    }

    const getAllLeadsForInput = async (leadName) => {
        return searchLeadByName(leadName).then(res => {
            if (res.status === 200) {
                const leadOptionsReq = res.data.data.map((data) => {
                    return {
                        label: <>
                            <span>
                                {data.name}
                            </span>
                            <span>
                                {" (" + data.mobile + ") "}
                            </span>
                        </>,
                        value: data.uuid
                    }
                })
                return (leadOptionsReq)
            }
        })
    }

    const getActivity = async (leadId) => {
        const activity = await getLatestActivityByUuid(leadId)
        if (activity.siteVisit) {
            setsiteVisitData(activity.siteVisit)
        }
    }

    useEffect(() => {
        getAllProjectsByCompanyId()
    }, [])

    useEffect(() => {
        if (leadId) {
            getLeadDetails()
            getActivity(leadId)
            setSelectedLeadId(leadId)
            getLeadProjects(leadId)
        }
    }, [leadId]);

    console.log(data, 'leadDetails')
    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                "Booking Done"
            }
            body={
                <>
                    <Formik
                        initialValues={{
                            scheduleDate: leadDetails?.schedule_date ? leadDetails.schedule_date : '',
                            remarks: leadDetails?.remarks ? leadDetails.remarks : '',
                            projectId: leadDetails?.projectId ? leadDetails?.projectId : data?.project?.length > 0 ? projectList.find(proj => proj.label === data?.project[0])?.value : '',
                            amount: leadDetails?.amount ? leadDetails.amount : '',
                            agreementPrice: leadDetails?.agreement_price ? leadDetails?.agreement_price : '',
                            leadId: bookingsData?.name ? { label: bookingsData?.name, value: leadId } : '',
                            unit_no: leadDetails?.unit_no ? leadDetails.unit_no : ''
                        }}
                        onSubmit={(values, actions) => {
                            handleBookingComplete(values)
                        }}
                        validationSchema={bookingDoneSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (

                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-10'>
                                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''} `}>
                                                <label className='mt-3'>Lead Name</label>

                                                {!leadId ?
                                                    <>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={getAllLeadsForInput}
                                                            defaultOptions
                                                            value={values['leadId']}
                                                            onChange={(e) => {
                                                                const value = { target: { name: "leadId", value: e } }
                                                                handleChange(value)
                                                                setSelectedLeadId(e.value)
                                                                getLeadProjects(e.value)
                                                                getActivity(e.value)
                                                            }}
                                                        // onInputChange={getAllLeadsForInput}
                                                        />
                                                        {errors['leadId'] && (
                                                            <>
                                                                <div className="input-feedback mt-8">{errors['leadId']}
                                                                </div>
                                                            </>

                                                        )}
                                                    </>
                                                    : <div className='text-capitalize fz16 fw-po-medium'>
                                                        {bookingsData?.name || data?.name}
                                                    </div>
                                                }
                                            </div>
                                            <DateTimePicker
                                                onValueChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "scheduleDate",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label="Booking Date & Time"
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                handelBlur={handleBlur}
                                                value={values['scheduleDate']}
                                            />
                                            <label className='mt-3'>Projects</label>

                                            <InputSelect
                                                index={4}
                                                name="Project"
                                                error={errors['projectId']}
                                                touched={touched['projectId']}
                                                isMulti={false}
                                                options={[...projectList]}
                                                value={projectList.find(proj => proj.value === values['projectId'])}
                                                placeholder="Select Project"
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "projectId",
                                                            value: e.value
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                            />
                                            <InputText
                                                value={agreementAmount || values.agreementPrice}
                                                index={2}
                                                error={errors['agreementPrice']}
                                                touched={touched['agreementPrice']}
                                                type="text"
                                                placeholder={`e.g. 10,00,000`}
                                                name="agreementPrice"
                                                handleBlur={(e) => {
                                                    setAgreementAmount(`₹ ${toINRFormat(values.agreementPrice.trim())}`)
                                                }}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "agreementPrice",
                                                            value: e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        }
                                                    }
                                                    handleChange(value)
                                                    setAgreementAmount(value.value)
                                                }}
                                                label="Net. Purchase Price"
                                            />
                                            <InputText
                                                value={bookingAmount || values.amount}
                                                index={2}
                                                error={errors['amount']}
                                                touched={touched['amount']}
                                                type="text"
                                                placeholder={`e.g. 10,00,000`}
                                                name="amount"
                                                handleBlur={(e) => {
                                                    setBookingAmount(`₹ ${toINRFormat(values.amount.trim())}`)
                                                }}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "amount",
                                                            value: e.target.value
                                                                .replace(/^0+/g, '')
                                                                .replace(/[^0-9]/g, '')
                                                        }
                                                    }
                                                    handleChange(value)
                                                    setBookingAmount(value.value)
                                                }}
                                                label="Amount Paid"

                                            />
                                            <InputText
                                                label={'Unit no'}
                                                placeholder={'e.g. B101'}
                                                value={values.unit_no}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "unit_no",
                                                            value: e.target.value

                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                            />
                                            <label className='mt-3'>Any Comments (optional)</label>
                                            <textarea
                                                className="form-control mb-16 "

                                                placeholder="Enter any booking comments."
                                                onChange={handleChange}
                                                value={values.remarks}
                                                name="remarks"
                                            ></textarea>

                                            {/* {values && values.scheduleDate ?
                                            <div className="w-100 text-center mt-2" >
                                                <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you 15 mins before <span className='black fw-po-medium'>{moment(values.scheduleDate).subtract(15, 'minutes').format("hh:mm a, Do MMM YYYY")}</span></p>
                                            </div>
                                            : " "} */}
                                        </div>

                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Confirm
                                            </button>

                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}