import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getPersonalInfo, updateOwnerByKeyAndValue } from "../../../../services/private/company.service";
import DynamicEditinput from "../../../../components/InputGroup/DynamicEditInput";
import DynamicMobileInput from "../../../../components/InputGroup/DynamicMobileInput";



export default function PersonalInfo() {
  const [personalInfoData, setPersonalInfoData] = useState({});
  const [editActive, setEditActive] = useState({});

  const ROLE_NAME = localStorage.getItem('role');

  const handleEditActive = (name, value) => {
    setEditActive({ ...editActive, [name]: value });
  };

  const handleValueChange = (name, value) => {
    const data = { ...personalInfoData, [name]: value };
    setPersonalInfoData({ ...data })
  };

  const savePersonalInfo = async (key, value) => {
    await updateOwnerByKeyAndValue({
      key, value
    }).then(res => {
      if (res.status === 200) {
        toast.success(`${key} updated`)
      }
    }).catch((e) => {
      toast.error(e.response.data.message);
    });
  };

  const getPersonalInfoData = async () => {
    getPersonalInfo().then((res) => {
      setPersonalInfoData(res.data.data)
    }).catch((e) => {
      toast.error(e.response.data.message);
    });
  };

  useEffect(() => {
    getPersonalInfoData();
  }, []);

  return (
    <>
      <div className="w-100">
        <div className="settings-title mb-16">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="fz24 mb-16 black fw-po-medium">Personal Info</h1>
              {/* <p className="fz16 black-dark-700 fw-po-medium">
              Supercharge your Platform and connect the tool you use everyday{" "}
            </p> */}
            </div>
          </div>
        </div>
        <div className="personal-info-content ml-24 mr-24 mt-24">
          <div className="row flex-md-row flex-column">
            <div className="col-lg-4">
              <DynamicEditinput
                editActive={editActive.name}
                setEditActive={(activeState) =>
                  handleEditActive("name", activeState)
                }
                heading="Name"
                name="Name"
                placeholder="Name"
                isEditable={ROLE_NAME === 'ADMIN'}
                value={personalInfoData.name}
                type="text"
                onValueChange={(value) => handleValueChange("name", value)}
                onSave={() => {
                  savePersonalInfo("name", personalInfoData.name);
                  handleEditActive("name", false);
                }}
              />
            </div>
            <div className="col-lg-4">
              <DynamicEditinput
                editActive={editActive.email}
                setEditActive={(activeState) =>
                  handleEditActive("email", activeState)
                }
                heading="Owner Email"
                name="Owner Email"
                placeholder="Owner Email"
                isEditable={ROLE_NAME === 'ADMIN'}
                value={personalInfoData.email}
                type="text"
                onValueChange={(value) => handleValueChange("email", value)}
                onSave={() => {
                  savePersonalInfo("email", personalInfoData.email);
                  handleEditActive("email", false);
                }}
              />
            </div>
            <div className="col-lg-4">
              <DynamicMobileInput
                editActive={editActive.mobile}
                setEditActive={(activeState) =>
                  handleEditActive("mobile", activeState)
                }
                heading="Mobile No."
                name="Mobile No."
                placeholder="Mobile No."
                isEditable={ROLE_NAME === 'ADMIN'}
                value={personalInfoData.mobile}
                // countryCode='+91'
                type="text"
                onValueChange={({ phone }) => handleValueChange("mobile", phone)}
                // handleBlur={(e) => handleValueChange("mobile", `${e.target.value ? '+91' + e.target.value : ''}`)}
                onSave={() => {
                  savePersonalInfo("mobile", personalInfoData.mobile);
                  handleEditActive("mobile", false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
