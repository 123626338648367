export const FORGOT_REQUESTING = 'FORGOT_REQUESTING';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';
export const FORGOT_PAGE_INIT = 'FORGOT_PAGE_INIT';
export const SHOW_HIDE_PASSWORD = 'FORGOT_SHOW_HIDE_PASSWORD';
export const SHOW_HIDE_CONFIRM_PASSWORD = 'FORGOT_SHOW_HIDE_CONFIRM_PASSWORD'



export function forgotPageInit( payload ) {
    
    return {
        type: FORGOT_PAGE_INIT,
        payload : payload
    };
}

export function forgotRequest(password) {
    return {
        type: FORGOT_REQUESTING,
        password : password
    };
}

export function forgotError(error) {
    return {
        type: FORGOT_ERROR,
        error,
    };
}

export function forgotSuccess() {
    return {
        type: FORGOT_SUCCESS,
    };
}


export function  showHideConfirmPassWord(){

    return {
        type: SHOW_HIDE_CONFIRM_PASSWORD
       
    };

}



export function showHidePassword(){
    return {
        type: SHOW_HIDE_PASSWORD
       
    };
}
