import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const SharedWithUserName = ({
    shareWithNameArr
}) => {
    return (
        <div className='flex'>
            <span className='text-capitalize'>{shareWithNameArr.length > 0 ? <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip" >
                        <div className='info-msg'>
                            {/* <h4>Total Leads</h4>*/}
                            <p className='text-capitalize'>{shareWithNameArr[0]}</p>
                        </div>
                    </Tooltip >
                }
            >
                <span className='three-dots cursor-pointer'>{shareWithNameArr[0]}</span>
            </OverlayTrigger > : '-'}
            </span>
            {
                shareWithNameArr.length > 1 ? <div className="tag-dropdown ml-1" >
                    <button className="border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        +{shareWithNameArr.length - 1}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {shareWithNameArr.map((item, index) => {
                            return <a key={index} className="dropdown-item text-capitalize"  >{item}</a>

                        })}
                    </div>
                </div> :
                    " "}
        </div>
    )
}

export default SharedWithUserName