import React, { useEffect, useState } from 'react';
import { ReactComponent as UserCheckIcon } from '../../../../assets/icons/user_check.svg';
// import { getSvCompletedReadAccess, updateSvCompletedReadAccess } from '../../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { reVisitTagAutomation } from '../../../../services/private/company.service';


const RevisitTagExtend = ({ getUserConfiData, userData }) => {

    const [value, setValue] = useState(false);

    useEffect(() => {
        setValue(userData?.project_tag_validity_automation);
    }, [userData]);

    const handleChange = async (value) => {
        const res = await reVisitTagAutomation({ value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    return (
        <div className='!border border-grayLight hover:border-primary hover:!border-2 rounded-lg shadow-boxshadow !p-4 relative'>
            {/* <Link to={`/settings/automation/unassigned-leads`} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
            <Link to={'/settings/automation/revisit-tagging-extended'} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link>
            <div className='flex justify-between items-center'>
                <div>
                    <UserCheckIcon />
                </div>
                <button
                    type="button"
                    className={`btn btn-lg btn-toggle ${value ? 'active' : ''} mr-2`}
                    value={value}
                    onClick={() => {
                        handleChange(!value)
                    }}
                >
                    <div className="handle"></div>
                    <span className={`${value ? 'on' : 'off'}`}>{value ? 'ON' : 'OFF'}</span>
                </button>
            </div>
            <div className='flex flex-row justify-between gap-2 mt-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>Re-Visit Tag extend Automation  </div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>
            <div className='!mt-2'>
                <p className='text-sm text-black700 inter'>On Revisit tagging  will get extended for tagged leads.</p>
            </div>
            <div className='mt-2 flex justify-end'>
                <button type='button'
                    className='!text-primary bg-blueHighlight !py-2 !px-4 rounded-lg inter font-medium'
                >
                    Configure
                </button>
            </div>
        </div>
    )
}

export default RevisitTagExtend;