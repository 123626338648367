import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import { request } from '../../helpers/requests';
import { browserRedirect } from '../../helpers/helpers';
import { urls } from '../../helpers/urls';
import  AWSConfig from '../../aws-exports';
import { SIGNUP_REQUESTING , SIGNUP_RESEND_VERIFICATION_LINK } from './actions';
import AmplifySdk from '../../helpers/amplify';
import { SignupError, SignUpCode ,SignupSuccess ,SignupVerificationLinkSent ,SignupVerificationLinkReSent  } from "./actions";
// Amplify.configure({ Auth: { region:  AWSConfig.aws_project_region, userPoolId: AWSConfig.aws_user_pools_id,  userPoolWebClientId: AWSConfig.aws_user_pools_web_client_id } });

const Amplify = AmplifySdk();

function signUpCall ({payload}) {
  return    Amplify.Auth.signUp({ username: payload.email, password : payload.password, attributes: { email : payload.email ,  'custom:password' : payload.password   } })
}


function resendVerificationEmail(payload){
 return Amplify. Auth.resendSignUp(payload.email) 

}

function* resendVerificationWorker(payload){
  try {

    let response = yield call(resendVerificationEmail, payload );
    yield put(SignupVerificationLinkReSent());
  
  } catch (err) {
    console.log(err,"Error Occured===>");
    yield put(SignupError( { ...err , message:err.message } ));
  }
    
}

// SINGUP Worker
function* signupWorker( payload ) {
  try {

    let response = yield call(signUpCall, payload );
    yield put(SignupVerificationLinkSent());
  
  } catch (err) {
    yield put(SignupError({ ...err , message : err.message }));
  }
}

// Login Watcher
export default function* signupSaga() {
  yield all([
    takeLatest(SIGNUP_REQUESTING, signupWorker),
    takeLatest(SIGNUP_RESEND_VERIFICATION_LINK,resendVerificationWorker)
    
  ]);
}