import axios, { AxiosRequestConfig, Method } from 'axios';
import { RequstMethods } from './requestMethods';


export default async function makeUploadRequest(url, method, formData) {
    let requestConfig= {
        baseURL: `${process.env.REACT_APP_DEV_API_URL}`,
        url: url,
        method: method,
        headers: {
            Authorization: localStorage.getItem("authToken") || "",
            "Content-Type": "multipart/form-data",
        }
    };

    if (method !== RequstMethods.GET && formData) {
        requestConfig.data = formData;
    }
    return await axios.request(requestConfig);
}