import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AddUserInUnassignedLeadsModal from '../Modals/AddUsers'
import { getPendingFollowupAssignment, getUnassignedLeadDistributionUsers } from '../../../../services/private/company.service';
import AddFollowupUserModal from '../Modals/AddFollowupUsers';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';


const FollowupUsers = () => {
    const [showAddNewUserModal, setShowddNewUserModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({})

    const getFollowupLeadsUser = async () => {
        const res = await getPendingFollowupAssignment();
        if (res.data.status === 200) {
            console.log(res.data.data, 'followupsss')
            const mapped = res.data.data.map(u => ({ ...u, label: u.name, value: u.id }))
            setUsers(mapped);
        }
    }

    useEffect(() => {
        getFollowupLeadsUser();
    }, []);

    return (
        <>
            <div className="roles w-100 h-full flex flex-col">
                <div className="settings-title">
                    <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                        <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                            <li>
                                <Link to={`/settings/automation/lead-automation`}>All Automation</Link>
                            </li>
                            <li className='flex items-center'><RightArrow />
                                Followup automation
                            </li>
                        </ul>
                    </nav>

                    <div className="w-100 flex justify-between">
                        <div className="w-1/2">
                            <div className='flex items-center'>
                                <h2 className="text-2xl  !mb-2 black fw-po-medium flex items-center !gap-x-2">
                                    <Link

                                        to='/settings/automation/lead-automation'
                                        className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                    >
                                        <Pageback className="group-hover:stroke-black" />
                                    </Link>
                                    Followup automation
                                </h2>
                            </div>
                            <p className="text-sm black-dark-700 mb-0 !pl-10">
                                Selected users will get overdue followup re-assigned to them.
                            </p>
                        </div>
                        {/* <div className="w-1/2 flex items-start space-x-2 justify-end">
                            <div>
                                <button className='btn-primary px-3 py-2 rounded-lg' onClick={() => setShowddNewUserModal(true)}>Add New</button>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="roles-table ml-24 mt-24 h-fll overflow-auto">
                    <div className="def-table pr-0 !h-full">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Lead Status</th>
                                    <th>Users</th>
                                    {/* <th>Edited by</th>
                                    <th>Edited Time</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((item, index) => (
                                    <tr
                                    // key={`${item.value}`}
                                    >
                                        <td>{index + 1}</td>
                                        <td className="leading-5"> {item.display_name}</td>
                                        <td className="leading-5"> {item.user_names ? item.user_names.join(', ') : '-'}</td>
                                        {/* <td className="leading-5"> Status</td>
                                        <td className="leading-5"> Status</td> */}
                                        <td className="dropdown view-more ">

                                            <button
                                                className="bg-tr border-0"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                        fill="#B5B5BE"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                        fill="#B5B5BE"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu dropdown-menu-right w-auto"
                                                aria-labelledby="dropdownMenuButton"
                                            >


                                                <a
                                                    className="dropdown-item"
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    onClick={
                                                        () => {
                                                            setSelectedStatus(item);
                                                            setShowddNewUserModal(true);
                                                        }
                                                    }
                                                >
                                                    Edit
                                                </a>


                                            </div>

                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showAddNewUserModal && <AddFollowupUserModal
                    show={showAddNewUserModal}
                    closeModal={() => setShowddNewUserModal(false)}
                    getFollowupLeadsUser={getFollowupLeadsUser}
                    selectedUsers={users}
                    selectedStatus={selectedStatus}
                />}
            </div>
        </>
    )
}

export default FollowupUsers