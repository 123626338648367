import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

const CreateDateFilter = ({ handleFilter, leadTopFilters }) => {

    const [showCreatedDate, setShowCreatedDate] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('')
    const [appliedFilter, setAppliedFilter] = useState(false);
    const [filters, setFilters] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const createdDateRef = useRef();

    // Listen to screen resizing to update isMobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleOutsideClick = (e) => {
        if ((createdDateRef.current && !createdDateRef.current.contains(e.target))) {
            setShowCreatedDate(false);
            // if (!isFilterApplied) {
            //     setSelectedEmployee([]);
            //     setSelectedEmployeeName([]);
            // }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, []);

    useEffect(() => {
        if (leadTopFilters.length > 0) {
            const dateSelected = localStorage.getItem('dateFilter')
            let newFilters = [];
            let filtersArr = {};
            for (const filterData of leadTopFilters) {
                filtersArr = { ...filtersArr, ...filterData }
                if (!['Created Date'].includes(Object.keys(filterData)[0])) {
                    newFilters.push(filterData);
                }
            }
            let filterKeys = Object.keys(filtersArr);
            if (filterKeys.includes("Created Date")) {
                const { From, To } = filtersArr['Created Date']
                if (dateSelected === 'custom') {
                    setStartDate(From);
                    setEndDate(To)
                } else {
                    setFromDate(From);
                    setToDate(To);
                }
                setAppliedFilter(true);
                setSelectedDate(dateSelected)
            }

            setFilters(newFilters)

        }
    }, [leadTopFilters])

    const handleCurrentDate = (e) => {
        e.preventDefault();
        setFromDate(moment(Date.now()).format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).format("YYYY-MM-DD"));
        setSelectedDate('today');
    };

    const handleYesterdayDate = (e) => {
        e.preventDefault();
        setFromDate(moment(Date.now()).subtract(1, 'day').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).subtract(1, 'day').format("YYYY-MM-DD"));
        setSelectedDate('yesterday');
    };

    const handleCurrentWeek = (e) => {
        e.preventDefault();
        setFromDate(moment(Date.now()).startOf('week').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).endOf('week').format("YYYY-MM-DD"));
        setSelectedDate('week');
    };

    const handleCurrentMonth = (e) => {
        e.preventDefault();
        setFromDate(moment(Date.now()).startOf('month').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).endOf('month').format("YYYY-MM-DD"));
        setSelectedDate('month');
    }

    return (
        <div className="assigned-drop" ref={createdDateRef}>
            <Dropdown align="start" className={"short-btn"} show={showCreatedDate}>
                {/* Add active class when filtter is applyed */}
                <Dropdown.Toggle variant="short" className={`inter border border-[#CFD5DE] !text-[#344054] !text-sm !font-semibold hover:!bg-white !flex items-center !rounded-3xl ${appliedFilter ? 'active ' : ' '}`} id="dropdown-autoclose-true" onClick={() => setShowCreatedDate(!showCreatedDate)}>

                    {/* {startDate && appliedFilter && <span className='text-[#2064FA] bg-[#E7F0FF] !px-2 !py-1 rounded-3xl !text-xs font-semibold  !ml-2'> 1 selected </span>} */}
                    {(startDate && endDate) || (fromDate && toDate) && appliedFilter ? <span className='text-[#2064FA]  !px-2 !py-1 !text-xs font-semibold  !ml-2'>
                        {moment(startDate || fromDate).format("Do MMM yyyy")} {`-${moment(endDate || toDate).format("Do MMM yyyy")}`}
                    </span>
                        :
                        'Created Date'
                    }
                    <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className='!w-[297px] inter !fixed'
                    flip={isMobile ? false : undefined}
                    popperConfig={
                        isMobile
                            ? { strategy: 'fixed', modifiers: [{ name: 'preventOverflow', options: { boundary: 'viewport' } }] }
                            : undefined
                    }>
                    <div className="d-flex justify-end align-itmes-center flex-nowrap all-clear">

                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' onClick={() => {
                            let oldFilters = leadTopFilters;
                            let newFilters = []
                            for (const filterData of oldFilters) {
                                // Check filter values and select element at pos 0 to compare with selected key
                                if (!['Created Date'].includes(Object.keys(filterData)[0])) {
                                    newFilters.push(filterData);
                                }
                            }
                            if (newFilters.length > 0) {
                                localStorage.setItem('new_filters', JSON.stringify(newFilters))
                            } else {
                                localStorage.removeItem('new_filters')
                            }
                            handleFilter(newFilters);
                            setStartDate('');
                            setEndDate('')
                            setFromDate('')
                            setToDate('')
                            setAppliedFilter(false);
                            setSelectedDate('');
                        }}>Clear all</a>
                    </div>
                    <div className="d-flex justify-content-between align-center w-full !gap-4 !mt-3" >
                        <div className='!w-1/2'>
                            <DatePicker
                                selected={startDate ? new Date(startDate) : ''}
                                placeholderText={'Start Date'}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    setStartDate(date)
                                    setSelectedDate('custom')
                                }}
                                dateFormat="dd MMM yyyy"
                                className={`border text-input !rounded-3xl !py-1 !px-3 !outline-none w-full`}
                            />
                        </div>
                        <div className='!w-1/2'>
                            <DatePicker
                                selected={endDate ? new Date(endDate) : ''}
                                placeholderText={'End Date'}
                                maxDate={new Date()}
                                minDate={startDate}
                                onChange={(date) => {
                                    setEndDate(date)
                                    setSelectedDate('custom')
                                }}
                                dateFormat="dd MMM yyyy"
                                className={`border text-input !rounded-3xl !py-1 !px-3 !outline-none w-full`}
                            />
                        </div>
                    </div>
                    <div className='max-h-[200px] overflow-auto '>
                        {startDate && <div className='flex justify-between !mt-2 hover:bg-[#e9ecef] rounded-lg !p-2 cursor-pointer' onClick={() => setSelectedDate('custom')}>
                            <label className="form-check-label cursor-pointer" htmlFor={`custom`}>
                                {moment(startDate).format("Do MMM yyyy")} {endDate ? `, ${moment(endDate).format("Do MMM yyyy")}` : ''}
                            </label>
                            <input
                                className=""
                                id={`custom`}
                                type="radio"
                                name={'created_date'}
                                // onChange={() => handleCurrentDate()}
                                checked={selectedDate === 'custom'}
                            />
                        </div>}
                        <div className='flex justify-between !mt-2 hover:bg-[#e9ecef] rounded-lg !p-2 cursor-pointer' onClick={(e) => handleCurrentDate(e)}>
                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`today`}>
                                Today
                            </label>
                            <input
                                className=""
                                id={`today`}
                                type="radio"
                                name={'created_date'}
                                // onChange={() => handleCurrentDate()}
                                checked={selectedDate === 'today'}
                            />
                        </div>

                        <div className='flex justify-between hover:bg-[#e9ecef] rounded-lg !p-2 cursor-pointer' onClick={(e) => handleYesterdayDate(e)}>
                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`yesterday`}>
                                Yesterday
                            </label>
                            <input
                                className=""
                                id={`yesterday`}
                                type="radio"
                                name={'created_date'}
                                // onChange={() => handleYesterdayDate()}
                                checked={selectedDate === 'yesterday'}
                            />
                        </div>
                        <div className='flex justify-between hover:bg-[#e9ecef] rounded-lg !p-2 cursor-pointer' onClick={(e) => handleCurrentWeek(e)}>
                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`week`}>
                                This Week
                            </label>
                            <input
                                className=""
                                id={`week`}
                                type="radio"
                                name={'created_date'}
                                // onChange={() => handleCurrentWeek()}
                                checked={selectedDate === 'week'}
                            />
                        </div>
                        <div className='flex justify-between hover:bg-[#e9ecef] rounded-lg !p-2 cursor-pointer' onClick={(e) => handleCurrentMonth(e)}>
                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`month`}>
                                This Month
                            </label>
                            <input
                                className=""
                                id={`month`}
                                type="radio"
                                name={'created_date'}
                                // onChange={() => handleCurrentMonth()}
                                checked={selectedDate === 'month'}
                            />
                        </div>
                    </div>
                    <div className="aply-btn w-full !mt-4">
                        <button className='pr-btn d-block w-full' onClick={() => {
                            if (startDate || fromDate) {
                                if (selectedDate === 'custom' && endDate && !startDate) {
                                    toast.error('Please select start date');
                                    return;
                                }
                                handleFilter([
                                    ...filters,
                                    {
                                        "Created Date": {
                                            "From": selectedDate === 'custom' ? startDate : fromDate,
                                            "To": selectedDate === 'custom' ? (endDate ? endDate : startDate) : toDate
                                        }
                                    }]);

                                localStorage.setItem('new_filters', JSON.stringify([
                                    ...filters,
                                    {
                                        "Created Date": {
                                            "From": selectedDate === 'custom' ? startDate : fromDate,
                                            "To": selectedDate === 'custom' ? endDate ? endDate : startDate : toDate
                                        }
                                    }
                                ]))
                                localStorage.setItem('dateFilter', selectedDate)
                                if (selectedDate === 'custom') {
                                    setStartDate('');
                                    setFromDate('');
                                }
                                setAppliedFilter(true);
                            }
                            setShowCreatedDate(false);
                        }}>Apply filter</button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div >
    )
}

export default CreateDateFilter