import React, { useEffect, useState } from 'react';
import { ReactComponent as EOIIcon } from '../../../../assets/icons/eoi-automation.svg';
// import { getSvCompletedReadAccess, updateSvCompletedReadAccess } from '../../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getUserProfile, updateAutoEoiCancel, updateAutoLeadShare } from '../../../../services/private/company.service';

const EOICancelCard = ({ getUserConfiData, userData }) => {
    const [svStatusToggle, setSvStatusToggle] = useState(false);
    const [companyId, setCompanyId] = useState('')

    useEffect(() => {
        setCompanyId(userData?.company_id)
        setSvStatusToggle(userData?.auto_expired_eoi_cancel)
    }, [userData]);

    const handleUpdateStatusPermission = async (value) => {
        const res = await updateAutoEoiCancel({ value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    return (
        <div className='!border border-grayLight hover:border-primary hover:!border-2 rounded-lg shadow-boxshadow !p-4 relative'>
            {/* <Link to={'/settings/automation/status-permissions'} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
            <div className='flex justify-between items-center'>
                <div>
                    <EOIIcon />
                </div>

                <button
                    type="button"
                    className={`btn btn-lg btn-toggle ${svStatusToggle ? 'active' : ''} mr-2`}
                    value={svStatusToggle}
                    onClick={() => {
                        handleUpdateStatusPermission(!svStatusToggle)
                    }}
                >
                    <div className="handle"></div>
                    <span className={`${svStatusToggle ? 'on' : 'off'}`}>{svStatusToggle ? 'ON' : 'OFF'}</span>
                </button>

            </div>
            <div className='flex flex-row justify-between gap-2 mt-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>{companyId === 581 ? 'Token' : 'EOI'} Cancellation</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>
            <div className='!mt-2'>
                <p className='text-sm text-black700 w-100 inter'>After {companyId === 581 ? 'Token' : 'EOI'} validity expires it’ll be cancelled automatically.</p>
            </div>
            {/* <div className='mt-2 flex justify-end'>
                <button type='button' className='text-primary inter'>
                    Configure
                </button>
            </div> */}
        </div>
    )
}

export default EOICancelCard;