import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { getKnowlarityIvrCallerIdByCompanyId } from '../../services/private/company.service';
import SliderModal from './SliderModal';
import InputSelect from '../InputGroup/InputSelect';

const AdvanceCallModal = ({ show, closeModal, handleSubmit, callerList }) => {
    const [useAlternate, setUseAlternate] = useState(false);
    const [selectedCaller, setSelectedCaller] = useState()

    useEffect(() => {
        if (callerList.length > 0) {
            setSelectedCaller(callerList[0].value);
        }
    }, [callerList]);

    return (
        <SliderModal
            setShow={closeModal}
            show={show}
            title={`Advanced call`}
            body={<>
                <div className="modal-conents mt-4">
                    {/* <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">
                        <h1 className='fz24 h-font fw-regular mb-0'>Advance call</h1>
                    </div> */}
                    <div className='px-3 h-[75vh]'>

                        <div className='flex items-center mb-4 '>
                            <input type="checkbox" className='mr-2' value={useAlternate} checked={useAlternate} onChange={() => setUseAlternate(!useAlternate)} />
                            <label htmlFor="" className='m-0'>Use Alternate Mobile no.</label>
                        </div>
                        <InputSelect

                            value={selectedCaller ? callerList.find(item => item.value === selectedCaller) : ''}
                            options={callerList}
                            onChange={(value) => {
                                setSelectedCaller(value.value)
                            }}
                            placeholder="Select Caller ID"
                            label="Select Caller ID"

                        // isMulti={userProfile?.mulit_user_assign && count > 1}
                        />
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="pr-btn" onClick={() => handleSubmit(useAlternate, selectedCaller)} >Confirm</button>
                    </div>
                </div>
            </>
            }
        />
    )
}

export default AdvanceCallModal;