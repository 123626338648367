import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';
import URLS from '../../helpers/urls';


export async function getLeadsFilter(params) {
    const queryParams = {
        filterType: params.filterType,
        value: params.value
    }
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.filter}`, queryParams, null, null);
}

export async function getNextandPreviousLead(currentLead) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getNextandPreviousLead + "/" + currentLead, null, null, null);
}

export async function createNewLead(payload) {
    return await request("POST", `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/insert`, null, payload, null)
}


export async function getLeadByUuid(uuid) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getLeadDetail + "/" + uuid, null, null, null);
}

export async function markLeadAsDead(uuid, payload) {
    return await request(API_METHOD.PUT, API_URLS.baseurl + API_URLS.lead.markleadAsDead + "/" + uuid, null, payload, null);
}

export async function mergeLeads(uuid) {
    return await request(API_METHOD.POST, API_URLS.baseurl + API_URLS.lead.mergeLead, null, { uuid }, null)
}

export async function checkLeadExistence(payload) {
    return await request(API_METHOD.POST, API_URLS.baseurl + API_URLS.lead.checkLeadExistence, null, payload, null)
}


export async function deleteLeads(uuid) {
    return await request(API_METHOD.POST, API_URLS.baseurl + API_URLS.lead.deleteLead, null, { uuid }, null)
}

export async function reassignLeads(uuid, userId, remarks, payload) {
    let multi_assign = Array.isArray(userId)
    return await request(API_METHOD.POST, API_URLS.baseurl + API_URLS.lead.reassignLead, null, { uuid, userId, multi_assign, remarks, ...payload }, null)
}

export async function bulkSourceChange(payload) {
    return await request(API_METHOD.PUT, API_URLS.baseurl + API_URLS.lead.bulkSourceChange, null, payload, null)
}

export async function searchLeadByName(searchString) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.searchLeadByName + "?name=" + searchString, null, null, null)
}

export async function getActivityStatus(leadUUId) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getActivityStatsByLeadUUID + "/" + leadUUId, null, null, null)
}

export async function getAllNotesForLead(leadUUID) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getAllNotes + "/" + leadUUID, null, null, null)
}

export async function createNoteCall(leadUUID, description) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.notes.insert}/${leadUUID}`, null, { description }, null);
}

export async function updateNoteCall(noteUUID, description) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.notes.update}/${noteUUID}`, null, { description }, null);
}

export async function removeNoteCall(noteUUID) {
    return request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.notes.removeFromLead}/${noteUUID}`, null, null, null);
}

export async function createFollowupCall(leadUUID, payload) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.followups.insert}/${leadUUID}`, null, payload, null);
}

export async function getFollowupCall(leadUUID) {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.followups.getAllByLeadUUID}/${leadUUID}`, null, null, null);
}

export async function updateFollowupCall(followupUUID, payload) {
    return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.followups.update}/${followupUUID}`, null, payload, null);
}

export async function canCreateFollowupCall(leadUUID) {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.followups.canCreateFollowup}/${leadUUID}`, null, null, null);
}

export async function createSiteVisitCall(leadUUID, payload) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.siteVisits.insert}/${leadUUID}`, null, payload, null);
}

export async function getSiteVisitsCall(leadUUID) {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.siteVisits.getAllByLeadUUID}/${leadUUID}`, null, null, null);
}

export async function updateSiteVisitCall(siteVisitUUID, payload) {
    return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.update}/${siteVisitUUID}`, null, payload, null);
}

export async function markCompletedCall(siteVisitUUID) {
    return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.siteVisits.markCompletedByUUID}/${siteVisitUUID}`, null, null, null);
}

export async function removeSiteVisitCall(siteVisitUUID) {
    return request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.siteVisits.removeFromLead}/${siteVisitUUID}`, null, null, null);
}

export async function getLatestActivityByUuid(uuid) {
    const response = await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getLatestActivityByLeadUUID + "/" + uuid, null, null, null);
    return response.data.data;
}

export async function canCreateSiteVisit(leadUUID) {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.siteVisits.canCreateSV}/${leadUUID}`, null, null, null);
}

export async function markBookingDone(leadUUID, bookingDate, remarks) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.bookingDone}/${leadUUID}`, null, {
        remarks,
        bookingDate
    }, null);
}

export async function getProjectsByLead(leadUUID) {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getProjectsByLead}/${leadUUID}`, null, null, null);
}

export async function insertEoiDone(leadUUID, payload) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.insertEoiDone}/${leadUUID}`, null, payload, null)
}

export async function insertBookingDone(leadUUID, payload) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.insertBookingDone}/${leadUUID}`, null, payload, null)
}

export async function updateLeadByIdAndKey(leadId, { key, value }) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.updateByKeyValue}${"/" + leadId}`, null, { key, value }, null)
}

export async function getCampaignInfo(uuid) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getCampaignInfo + "/" + uuid, null, null, null);
}


export async function getAdAnalytics(uuid) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.lead.getAdAnalytics + "/" + uuid, null, null, null);
}
export async function getProjectsForFilter() {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getProjectsForFilter}`, null, null, null);
}