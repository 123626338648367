import { Disclosure, Switch } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import InputField from '../form-fields/input-field'

const FAQ = ({
    handleAddMoreFAQs,
    faqQuestions,
    handleChangeFAQs,
    handleChangeLearnSection,
    learnSections,
    handleAddMoreLearnSection,
    handleChangeCertificate,
    showCertificate
}) => {

    return (
        <div className=' basis-6/12	'>
            <Disclosure as='div'>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg text-left text-20 font-medium text-black">
                            <span className='flex items-center gap-1'>What you will learn? Section  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8.6665 10.6667V7.33341C8.6665 6.96522 8.36803 6.66675 7.99984 6.66675C7.63165 6.66675 7.33317 6.96522 7.33317 7.33341V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#92929D" />
                                <path d="M7.33317 5.33341C7.33317 4.96522 7.63165 4.66675 7.99984 4.66675C8.36803 4.66675 8.6665 4.96522 8.6665 5.33341C8.6665 5.7016 8.36803 6.00008 7.99984 6.00008C7.63165 6.00008 7.33317 5.7016 7.33317 5.33341Z" fill="#92929D" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00008C1.33317 4.31818 4.31793 1.33341 7.99984 1.33341C11.6817 1.33341 14.6665 4.31818 14.6665 8.00008C14.6665 11.682 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.682 1.33317 8.00008ZM13.3332 8.00008C13.3332 5.05456 10.9454 2.66674 7.99984 2.66674C5.05432 2.66674 2.6665 5.05456 2.6665 8.00008C2.6665 10.9456 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9456 13.3332 8.00008Z" fill="#92929D" />
                            </svg></span>
                            <div
                                className={`${open ? 'rotate-180 transform mr-4' : 'mr-4'
                                    }`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579Z" fill="#696974"></path></svg>
                            </div>

                        </Disclosure.Button>
                        <Disclosure.Panel className=" !mt-5 mb-2">
                            <h2 className='flex items-center text-black font-medium text-20 mb-3'>
                                <span>
                                </span>
                            </h2>

                            <div className="grid grid-cols-2 !gap-4 ">
                                {
                                    learnSections?.map((item, index) => (
                                        <InputField
                                            key={`${index}`}
                                            label={`Bullet Point (${index + 1})`}
                                            type='text'
                                            name='learnSection'
                                            value={item}
                                            onChange={(e) => handleChangeLearnSection(e, index)}
                                        />
                                    ))
                                }
                                <div className='col-span-2'>
                                    <button className='!text-primary text-sm font-medium table ml-auto mt-2 mb-2' onClick={handleAddMoreLearnSection}>+ Add More</button>
                                </div>

                            </div>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <div className='h-px bg-black200 mt-2 mb-2'></div>
            <Disclosure as='div'>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg text-left text-20 font-medium text-black">
                            <span className='flex items-center gap-1'>FAQ Section</span>
                            <div
                                className={`${open ? 'rotate-180 transform mr-4' : 'mr-4'
                                    }`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579Z" fill="#696974"></path></svg>
                            </div>

                        </Disclosure.Button>
                        <Disclosure.Panel className="!mt-5 mb-2">
                            <h2 className='flex items-center text-black font-medium text-20 mb-3'>
                                <span>
                                </span>
                            </h2>

                            <div className="grid grid-cols-2 !gap-4 ">
                                {
                                    faqQuestions.map((item, index) => (
                                        <Fragment key={`${index}`}>
                                            <InputField
                                                label={`Question (${index + 1})`}
                                                type='text'
                                                name='question'
                                                value={item.question}
                                                onChange={(e) => handleChangeFAQs(e, index)}

                                            />
                                            <InputField
                                                label={`Answer (${index + 1})`}
                                                type='text'
                                                name='answer'
                                                value={item.answer}
                                                onChange={(e) => handleChangeFAQs(e, index)}

                                            />
                                        </Fragment>
                                    ))
                                }
                                <div className='col-span-2'>
                                    <button className='text-primary text-sm font-medium table ml-auto mt-4' onClick={handleAddMoreFAQs}>+ Add More</button>
                                </div>

                            </div>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <div className='h-px bg-black200 mt-2 mb-5'></div>

            <div className='flex items-center  justify-start gap-2'>
                <p className='text-sm text-black font-medium m-0'>Display Certificate Section</p>
                <Switch
                    checked={showCertificate}
                    onChange={handleChangeCertificate}
                    className={`${showCertificate ? 'bg-primary' : 'bg-[#E2E2EA]'
                        } relative inline-flex h-6 w-12 items-center rounded-full togglebtn`}
                >
                    {showCertificate && <i className='on absolute not-italic text-white text-12 font-medium left-1.5 uppercase'>On</i>}
                    {!showCertificate && <i className='off absolute text-[#92929D] not-italic text-12 font-medium right-1.5 uppercase	'>off</i>}
                    <span
                        className={`${showCertificate ? 'translate-x-7' : 'translate-x-0.5'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
            </div>
            <div className='h-px bg-black200 mt-5 mb-2'></div>
        </div>
    )
}

export default FAQ;
