import React from 'react'
import LeadDetailBodyLeft from './LeadDetailBodyLeft/LeadDetailBodyLeft.index'
import LeadDetailBodyRight from './LeadDetailBodyRight/LeadDetailBodyRight.index'

export default function LeadDetailBody(props) {
    return (
        <div className='row w-100 mx-0 justify-content-between'>
            <div className="lead-detail-left">
                <div className='board'>
                    <LeadDetailBodyLeft leadAccess={props.leadAccess} leadInfoData={props.leadInfoData} customerInfoData={props.customerInfoData} campaignInfo={props.campaignInfo} />

                </div>
            </div>
            <h1 className='d-lg-none fz24 black mb-8 ml-3 mt-4 fw-medium'>Widgets</h1>

            <div className="lead-detail-right">
                <LeadDetailBodyRight leadInfoData={props.leadInfoData} leadAccess={props.leadAccess} />
            </div>
        </div>
    )
}



