import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'
import { Oval } from 'react-loader-spinner'

const ConfirmDeletion = ({ show, setShow, deleteHandler, title, deleteText, subText, deleteLeading }) => {
    return (

        <PopUpModal
            setShow={setShow}
            show={show}
            heading={<>
                {title ? title : 'Confirm Delete?'}
            </>}
            body={<>

                <p className='black-dark-800 fz16 po m-0'>Are you sure you want to delete {` ${deleteText ? deleteText : 'Lead(s)'}`}?</p>
                {subText && <p className='black-dark-800 fz16 po m-0'>{subText}</p>}

                <div className="justify-content-end d-flex modal-footer mt-3">
                    <Button variant="" className="cancel" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    {!deleteLeading && <Button variant="" className='del-btn' onClick={deleteHandler}>
                        Delete
                    </Button>}
                    {deleteLeading && <button className="del-btn px-20 flex items-center justify-center" type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}
                </div>
            </>}
        />
    )
}

export default ConfirmDeletion