import React, { useEffect, useState } from 'react'
import InputText from '../../../../../../components/InputGroup/InputText'
import InputSelect from '../../../../../../components/InputGroup/InputSelect'
import { ReactComponent as PlusPrimary } from '../../../../../../assets/icons/PlusPrimary.svg';
import { getUsersForSettings } from '../../../../../../services/private/company.service';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const CallerIdMapping = ({ setSteps, ivrName, handleSaveCallerMapping, callerIdMappingData }) => {
    const [users, setUsers] = useState(['']);
    const [allUsers, setAllUsers] = useState([])

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        // label: data.name + " - " + data.email,
                        label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        if (callerIdMappingData.length > 0) {
            let mappedData = callerIdMappingData.map(item => item.caller_id)
            setUsers([...mappedData]);
        }
    }, [callerIdMappingData]);

    console.log(callerIdMappingData, 'callerIdMappingData')
    useEffect(() => {
        getAllUsers();
    }, []);

    const handleSubmit = () => {
        const payload = users.filter(item => item);
        handleSaveCallerMapping({ caller_id: payload })
    }
    return (
        <>
            <div className='!pt-5 sm:!px-6 !px-3 pb-8 h-full overflow-auto scroll-smooth'>
                <h2 className='m-0 text-textblack text-base font-semibold inter'>Caller ID</h2>
                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 [&>*]:!mt-0 !mt-5'>

                    {users.map((item, index) => (
                        <>
                            <InputText
                                label={`Caller Id ${index + 1}`}
                                placeholder='Enter Mobile number'
                                value={item}
                                onChange={(e) => {
                                    let newUsers = [...users];
                                    newUsers[index] = e.target.value
                                    setUsers(newUsers)
                                }}
                            />
                            {/* <div className=''>
                                <label htmlFor="">Select Propacity User</label>
                                <div className='flex items-center'>
                                    <InputSelect
                                        placeholder='Select User'
                                        inputclass={'w-11/12'}
                                        styles={customStyles}
                                        value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                        options={allUsers}
                                        onChange={(value) => {
                                            let newUsers = [...users];
                                            newUsers[index].user_id = value.value;
                                            setUsers(newUsers)
                                        }}
                                    />
                                    {users.length > 1 && <button className='w-1/12 ml-2' onClick={() => {
                                        let newUsers = [...users];
                                        newUsers.splice(index, 1)
                                        setUsers(newUsers)
                                    }}>
                                        <CloseIcon />
                                    </button>}
                                </div>
                            </div> */}
                        </>
                    ))
                    }
                </div>
                <div className='flex items-center justify-end'>
                    <button
                        className='inline-flex !mt-3 items-center justify-end gap-x-2 !text-primary inter text-sm font-semibold'
                        onClick={() => {
                            setUsers([...users, ''])
                        }}
                    ><PlusPrimary />Add another</button>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center' onClick={() => setSteps('basic')}>Previous</button>
                <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center' onClick={handleSubmit}>Save</button>
            </div>
        </>
    )
}

export default CallerIdMapping
