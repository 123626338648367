import React from 'react';
import { filtersEnum } from '../../../helpers/filtersEnum';
import OwlCarousel from 'react-owl-carousel';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { bhk_type, facing, furnished_unit, possession_type, property_type, transaction_type, unit_type, usages_status } from '../../../utils/project-constant';

const FiltersListing = ({ filters, removeFilterValue }) => {
    const getList = (lists, item) => {
        return lists.map((list, index) => {
            if (list.value === item) {
                return <li key={`${list.label}_${index}`}>{list.label}</li>
            }
        })
    }

    const renderFilterList = (filterLists) => {

        if (filterLists === 'property_type') {
            return filters[filterLists].map(item => getList(property_type, item))
        }
        if (filterLists === 'possession_type') {
            return filters[filterLists].map(item => getList(possession_type, item))
        }
        if (filterLists === 'unit_type') {
            return filters[filterLists].map(item => getList(unit_type, item))
        }
        if (filterLists === 'transaction_type') {
            return filters[filterLists].map(item => getList(transaction_type, item))
        }
        if (filterLists === 'furnished') {
            return filters[filterLists].map(item => getList(furnished_unit, item))
        }
        if (filterLists === 'configurations') {
            return filters[filterLists].map(item => getList(bhk_type, item))
        }
        if (filterLists === 'facing') {
            return filters[filterLists].map(item => getList(facing, item))
        }
        if (filterLists === 'usages_status') {
            return filters[filterLists].map(item => getList(usages_status, item))
        }
        if (['build_up_area', 'super_area', 'plot_area'].includes(filterLists)) {
            return <li>{filters[filterLists].min}-{filters[filterLists].max} {filters[filterLists].unit}</li>
        }
    }
    return (
        <>
            {Object.keys(filters)?.length > 0 &&
                <div className="all-leads filter-applyed pb-2 pl-3 d-flex align-items-center">
                    <span className="">Filtered by: &nbsp;</span>

                    <ul className="tags align-items-center pl-1" id="scroll-tag">
                        <OwlCarousel
                            className='owl-theme'
                            dots={false}
                            res='true'
                            autoWidth='true'
                            loop={false}
                            navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                            margin={10}
                            responsive={{
                                0: {
                                    items: 1.3,
                                    nav: true,
                                    margin: 0

                                },
                                600: {
                                    items: 3,
                                    nav: true
                                },
                                900: {
                                    items: 4,
                                    nav: true
                                },
                                1000: {
                                    items: 5,
                                    nav: true
                                },
                                1200: {
                                    items: 6,
                                    loop: false,
                                    nav: true,
                                    margin: 0

                                }
                            }
                            }>
                            {Object.keys(filters).map((data, index) => {
                                return (
                                    <div className="item" key={String(index)}>
                                        <li >
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                                        <div className='info-msg'>
                                                            <ul className="mb-0">
                                                                {renderFilterList(data)}
                                                                {/* <li>1. Signed up by mistake</li> */}
                                                                {/* <li>2. Signed up by mistake</li> */}
                                                            </ul>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => removeFilterValue(Object.keys(data))} className='bg-tr border-0 p-0' bsstyle="default" style={{ fontSize: 0 }}>
                                                    <svg className="ml-0 mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M7.02344 5.07518C7.02344 5.33376 7.12616 5.58176 7.30901 5.76461C7.49186 5.94746 7.73985 6.05018 7.99844 6.05018C8.25702 6.05018 8.50502 5.94746 8.68787 5.76461C8.87071 5.58176 8.97344 5.33376 8.97344 5.07518C8.97344 4.81659 8.87071 4.5686 8.68787 4.38575C8.50502 4.2029 8.25702 4.10018 7.99844 4.10018C7.73985 4.10018 7.49186 4.2029 7.30901 4.38575C7.12616 4.5686 7.02344 4.81659 7.02344 5.07518ZM7.35884 11.3672C7.38597 11.517 7.46487 11.6526 7.58177 11.7503C7.69867 11.8479 7.84613 11.9014 7.99844 11.9014C8.15074 11.9014 8.29821 11.8479 8.41511 11.7503C8.532 11.6526 8.6109 11.517 8.63804 11.3672L8.64844 11.2502V8.00018L8.63804 7.88318C8.6109 7.73331 8.532 7.59773 8.41511 7.5001C8.29821 7.40247 8.15074 7.34899 7.99844 7.34899C7.84613 7.34899 7.69867 7.40247 7.58177 7.5001C7.46487 7.59773 7.38597 7.73331 7.35884 7.88318L7.34844 8.00018V11.2502L7.35884 11.3672Z" fill="#92929D" />
                                                        <rect x="0.75" y="-0.75" width="11.5" height="11.5" rx="5.75" transform="matrix(1 0 0 -1 1.5 13)" stroke="#92929D" strokeWidth="1.5" />
                                                    </svg>

                                                </Button>
                                            </OverlayTrigger>
                                            {filtersEnum[data]}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F"></path></svg>
                                        </li>
                                    </div>
                                )
                            })}

                        </OwlCarousel>
                    </ul>
                </div>
            }
        </>
    )
}

export default FiltersListing