import React, { useState, useEffect, useMemo, useContext } from 'react';

import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { getAllCities } from '../../../services/public/public.service';
import { getLeadsFilter } from '../../../services/private/leads.service';
import SliderModal from '../../../components/Modals/SliderModal';
import DynamicAccordion from '../../../components/Modals/FilterModal/Dynamic.accordion';
import { getMyUsers } from '../../../services/private/company.service';
import CheckAccordion from '../../../components/Modals/FilterModal/Check.accordion';

const defaultStaticFilterList = [
    'Subscription',
    'Date Purchased',
    'Amount Paid',
    'Referral Code',
    'Date Joined'
];

const searchFilterkey = [
    'Assigned To',
    'Assigned User',
    'Shared With',
    'Marked By'
]

const SUBSCRIPTION_TYPE = [
    {
        label: 'Premium',
        value: 'premium'
    },
    {
        label: 'Freemium',
        value: 'freemium'
    },
    // {
    //     label: 'Free',
    //     value: 'free'
    // },
]

export default function InventoryFilters({
    show,
    setShow,
    handleFilter,
    // filterData,
    // setFilterCount,
    filtersData,
    // filterToaster,
    // setCurrentPage,
    unitConfigs
}) {

    // Filter Values

    const [dateJoined, setJoinedDate] = useState({});
    const [filterSelected, setFilterSelected] = useState(false)
    const [filterDate, setFilterDate] = useState({});
    const [defaultFilterList, setDefaultFilterList] = useState(defaultStaticFilterList);
    const [city, setCity] = useState([]);
    const [selectedRM, setSelectedRM] = useState([]);
    const [units, setUnits] = useState([])

    // console.log(filtersData, 'filtersData')


    const ROLE_NAME = localStorage.getItem('role');

    const sendFilterRequest = () => {
        let filterArr = {};

        if (units?.length > 0) {
            filterArr['unit_config'] = units

        }

        // setFilterCount(filterArr.length)
        handleFilter(filterArr);
        // filterData(filterArr);
        // localStorage.setItem('filter', JSON.stringify(filterArr))
        // localStorage.removeItem('currentPage');
        // filterToaster();
        // setCurrentPage(1)
        setShow(false)
    }

    const handelRemoveClick = () => {
        if (filterSelected || filtersData.length > 0 || units?.length || selectedRM.length) {
            toast.success("All filters Removed");
            // setFilterCount(0);
            handleFilter([]);
            // filterData([]);            
            setUnits([])
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = { ...filtersData };
        // for (const filterData of filters) {
        //     filtersArr = { ...filtersArr, ...filterData }
        // }

        let filterKeys = Object.keys(filtersArr);


        if (filterKeys.includes('unit_config')) {
            setUnits(filtersArr['unit_config'])
        }
    }

    useEffect(() => {
        updateFilterValaue();
    }, [
        filtersData,
        show,
    ])

    useEffect(() => {
        let filters = filtersData;
        const filtersArr = Object.keys(filtersData);

        if (!filtersArr.includes("unit_config")) {
            setUnits([])
        }
        // setFilterCount(filtersArr.length);
    }, [filtersData])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || Object.keys(filtersData)?.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'></div>
                        <div className="all-fliters">
                            <div id="accordion">
                                {/* {
                                    <DynamicAccordion
                                        accordionName="Sourcing Manager"
                                        placeholder='Enter user name to search'
                                        value={selectedRM}
                                        setValue={setSelectedRM}
                                        searchResults={searchResults['Sourcing Manager']}
                                        setSearchResults={(data) => setSearchResults({ ...searchResults, ["Sourcing Manager"]: structuredClone(data) })}
                                        inputValues={inputValues["Sourcing Manager"]}
                                        handleInputChange={handleInputChange}
                                        notFound={inputValuesNotFound["Sourcing Manager"]}
                                        setInputValues={setInputValues}
                                        inputValuesNotFound={inputValuesNotFound}
                                        setInputValuesNotFound={setInputValuesNotFound}
                                        searchDefultResults={searchDefultResults}
                                    />
                                } */}
                                {/* <DynamicAccordion
                                    accordionName="City"
                                    placeholder='Enter city to search'
                                    value={city}
                                    setValue={setCity}
                                    searchResults={searchResults.City}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, City: data })}
                                    inputValues={inputValues.City}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound.City}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                /> */}
                                <CheckAccordion
                                    value={units}
                                    setValue={setUnits}
                                    selectionValues={unitConfigs}
                                    accordionName="Unit_Configuration"
                                    label="Unit Configuration"
                                    showSearch={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}