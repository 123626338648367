import React from 'react';
import SliderModal from './SliderModal';
import InputText from '../InputGroup/InputText';
import { createAgencySchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import { Oval } from 'react-loader-spinner';

const CreateAgencyModal = ({
    show,
    setShow,
    createNewAgency,
    selectedAgency,
    updateCompanyAgency,
    loading
}) => {

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                'Create Marketing Agency'
            }
            body={
                <>
                    <Formik
                        initialValues={{ name: selectedAgency?.name ? selectedAgency?.name : '' }}
                        onSubmit={(values) => {
                            if (selectedAgency?.uuid) {
                                updateCompanyAgency(selectedAgency?.uuid, values)
                            } else {
                                createNewAgency(values)
                            }
                        }}
                        validationSchema={createAgencySchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {
                            (props) => {
                                const { isValid, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                return (

                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-10'>
                                            <InputText
                                                value={values['name']}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                index={0}
                                                error={errors['name']}
                                                touched={touched['name']}
                                                type="text"
                                                placeholder="Enter Agency Name"
                                                name="name"
                                                label="Agency Name"
                                            />
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                                type='button'
                                            >
                                                Cancel
                                            </button>
                                            {!loading && <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {selectedAgency?.uuid ? 'Update' : 'Create'}
                                            </button>}
                                            {loading && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>
                </>
            }
        />
    )
}

export default CreateAgencyModal;