import React from 'react'

const InputBox = ({ title, name, error, touched, placeholder, handleChange, handleBlur, value }) => {
    return (<>
        <label>{title}</label>
        <input
            type="text"
            name={name}
            className={"form-control"}
            style={(error && touched) && { border: "2px solid #ef4444" }}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value || ''}
        />
    </>
    )
}

export default InputBox