import React from 'react'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';

const AddUser = ({ show, closeModal, handleExistingUser, handleAddNewUser }) => {
    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add User</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div className='p-3'>
                <div className='border flex flex-col p-2.5 space-y-3'>
                    <button className='pr-btn py-2' onClick={handleAddNewUser} >Invite New User</button>
                    <button className=' text-center font-medium border border-primary py-2 text-primary rounded-md' onClick={handleExistingUser}>Add Existing User</button>
                </div>

            </div>

        </RightModal>
    )
}

export default AddUser