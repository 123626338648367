import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as UserRIghtIcon } from '../../../../assets/icons/user-right.svg';
import { toast } from 'react-toastify';
import { getUserProfile, updateAutoDeadLeadToNewLead, updateNewLeadReEngagement } from '../../../../services/private/company.service';
import HideShowLeadTimeline from '../Modals/HideShowLeadTimeline';
import Consumer from '../../../../helpers/context';
import AddUserForNewLeadReassign from '../Modals/AddUserForNewLeadReassign';

const NewLeadReengagementCard = ({ getUserConfiData, userData }) => {
    const [leadStatus, setLeadStatus] = useState(false);
    const [showTimelineModal, setShowTimelineModal] = useState(false);
    const [hideTimeline, setHideTimeline] = useState(false);

    let { userProfile } = useContext(Consumer);

    useEffect(() => {
        // setHideTimeline(userData?.hide_dead_lead_timeline)
        setLeadStatus(userData?.new_lead_re_engagement_automation)
    }, [userData]);

    const handleUpdateStatusPermission = async (leadStatus) => {
        const res = await updateNewLeadReEngagement({ value: leadStatus });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    return (
        <div className='!border border-grayLight hover:border-primary hover:!border-2 rounded-lg shadow-boxshadow !p-4 relative'>
            {/* <Link to={'/settings/automation/status-permissions'} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
            <div className='flex justify-between items-center'>
                <div>
                    <UserRIghtIcon />
                </div>

                <button
                    type="button"
                    className={`btn btn-lg btn-toggle ${leadStatus ? 'active' : ''} mr-2`}
                    value={leadStatus}
                    onClick={() => {
                        setLeadStatus(!leadStatus)
                        handleUpdateStatusPermission(!leadStatus)
                    }}
                >
                    <div className="handle"></div>
                    <span className={`${leadStatus ? 'on' : 'off'}`}>{leadStatus ? 'ON' : 'OFF'}</span>
                </button>

            </div>
            <div className='flex flex-row justify-between gap-2 mt-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>New lead re-engagement </div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>
            <div className='!mt-2'>
                <p className='text-sm text-black700 w-100 inter'>Distribute new lead when re-engage.</p>
            </div>
            <div className='mt-2 flex justify-end'>
                <div className='mt-2 flex justify-end'>
                    <button type='button' onClick={() => setShowTimelineModal(true)}
                        className='!text-primary bg-blueHighlight !py-2 !px-4 rounded-lg inter font-medium'
                    >
                        Configure
                    </button>
                </div>
            </div>
            {
                showTimelineModal &&
                <AddUserForNewLeadReassign
                    show={showTimelineModal}
                    setShow={setShowTimelineModal}
                />
            }
        </div>
    )
}

export default NewLeadReengagementCard;