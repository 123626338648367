import React from 'react'
import BasicModal from './BasicModal'
import { ReactComponent as LeftIcin } from '../../assets/icons/owlleft.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as GrayCallIcon } from '../../assets/icons/grayCall.svg';
import { ReactComponent as TawerIcon } from '../../assets/icons/tawer.svg';
import { ReactComponent as EmailICon } from '../../assets/icons/Email.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/map.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as PrinterICon } from '../../assets/icons/printer.svg';

const PreviewModal = ({ show, closeModal }) => {
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-7 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>
                        <button><LeftIcin /></button>
                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Preview Quote </h1>
                    </div>
                    <button onClick={closeModal}><CloseIcon /></button>
                </div>
                <div className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div className='!border-b border-grayLight !pb-5'>
                        <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>$Customer Name _ Quote </h1>

                    </div>
                    <div className='!py-5 flex sm:flex-row flex-col sm:gap-y-0 gap-y-8'>
                        <div className='sm:w-1/2 w-full flex flex-col gap-y-2'>
                            <h2 className='inter text-xs leading-5 text-black700 font-normal m-0'>Customer Details</h2>
                            <h1 className='inter text-textblack font-semibold text-xl m-0'>Candice Wu </h1>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><UserIcon />$lead_ID</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><GrayCallIcon />+91 8248961133</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><TawerIcon />Omaxe World</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><EmailICon />adityapratap239@gmail.com</div>
                        </div>
                        <div className='sm:w-1/2 w-full flex flex-col gap-y-2'>
                            <h2 className='inter text-xs leading-5 text-black700 font-normal m-0'>Buyer Details</h2>
                            <h1 className='inter text-textblack font-semibold text-xl m-0'>Candice Wu </h1>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><UserIcon />$lead_ID</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><GrayCallIcon />+91 8248961133</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><TawerIcon />Omaxe World</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-center'><EmailICon />adityapratap239@gmail.com</div>
                            <div className='flex gap-x-1 text-sm text-black700 items-start'><span className='w-4 !mt-1'><MapIcon /> </span>Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.</div>
                        </div>
                    </div>
                    <div className='!py-5 flex sm:flex-row flex-col sm:gap-y-0 gap-y-8 !border-b !border-grayLight'>
                        <div className='sm:w-1/2 w-full flex flex-col gap-y-2'>
                            <h2 className='inter text-xs leading-5 text-black700 font-normal m-0'>Issued On:</h2>
                            <h1 className='inter text-textblack font-semibold text-xl m-0'>12:00 PM, 25 Mar 2023 </h1>
                        </div>
                        <div className='sm:w-1/2 w-full flex flex-col gap-y-2'>
                            <h2 className='inter text-xs leading-5 text-black700 font-normal m-0'>Quote Validity :</h2>
                            <h1 className='inter text-textblack font-semibold text-xl m-0'>24th Aug</h1>
                        </div>
                    </div>
                    <div className='!py-5'>
                        <h2 class="inter text-textblack text-lg !mb-4 font-semibold">Unit Details</h2>
                        <div className=' flex flex-wrap gap-y-4'>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Project</div>
                                    <div className='text-textblack text-base font-semibold'>Godrej Avenues</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Carpet Area</div>
                                    <div className='text-textblack text-base font-semibold'>875 sq.ft.</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Balcony Area</div>
                                    <div className='text-textblack text-base font-semibold'>120 sq.ft.</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Tower Number</div>
                                    <div className='text-textblack text-base font-semibold'>H</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Super Built Up Area</div>
                                    <div className='text-textblack text-base font-semibold'>724 sq.ft.</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Base Price</div>
                                    <div className='text-textblack text-base font-semibold'>₹ 6254000</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Floor Number</div>
                                    <div className='text-textblack text-base font-semibold'>3</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Furnishing</div>
                                    <div className='text-textblack text-base font-semibold'>Semi-Furnished</div>
                                </div>
                            </div>

                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>PLC Charges</div>
                                    <div className='text-textblack text-base font-semibold'>₹ 254000</div>
                                </div>
                            </div>

                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Unit Number</div>
                                    <div className='text-textblack text-base font-semibold'>03</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Facing</div>
                                    <div className='text-textblack text-base font-semibold'>North East</div>
                                </div>
                            </div>

                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>All Pricing Charges</div>
                                    <div className='text-textblack text-base font-semibold'>₹ 8254000</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Configuration</div>
                                    <div className='text-textblack text-base font-semibold'>1BHK-874sq.ft.</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Allocated Car Parking</div>
                                    <div className='text-textblack text-base font-semibold'>1</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Possession Date</div>
                                    <div className='text-textblack text-base font-semibold'>18 August 2023</div>
                                </div>
                            </div>
                            <div className='sm:w-4/12 w-1/2 inter'>
                                <div className='flex flex-col gap-y-1'>
                                    <div className='text-sm font-normal text-black700'>Net. Purchase Price</div>
                                    <div className='text-textblack text-base font-semibold'>₹ 9254000</div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='!py-5'>
                        <h2 class="inter text-textblack text-lg !mb-4 font-semibold">Payment Plan</h2>
                        <div className='def-table plantable h-auto !overflow-auto !p-0'>
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th className='w-auto text-left !min-w-fit'>Milestones</th>
                                        <th>Description</th>
                                        <th>Due Date</th>
                                        <th>%</th>
                                        <th>Net. Purchase Price (Incl GST)</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}>Milestone 1</td>
                                        <td>Lorem ipsum dolor sit amet..</td>
                                        <td>07/06/2023</td>
                                        <td>10%</td>
                                        <td>189299</td>
                                    </tr>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}>Milestone 1</td>
                                        <td>Lorem ipsum dolor sit amet..</td>
                                        <td>07/06/2023</td>
                                        <td>10%</td>
                                        <td>189299</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='def-table plantable h-auto !overflow-auto !p-0 !mt-5'>
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th className='w-auto text-left !min-w-fit'>S. No.</th>
                                        <th>Name</th>
                                        <th>Description</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}> 1</td>
                                        <td>Configuration</td>
                                        <td>2BHK</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='!border-t border-b !border-grayLigh !pt-4 !pb-4 '>
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold inter">Grand Total</div>
                            <div className='sm:text-xl text-lg font-semibold leading-6 text-textblack text-right'>
                                <div>₹ 59,71,800</div>
                                <div className='sm:text-sm text-xs text-black700 font-normal inter'>For $Customer_Name</div>
                            </div>
                        </div>
                    </div>
                    <div className='!mt-5 flex-col flex gap-y-5'>
                        <p className='text-sm m-0 inter text-black700'>This is system generated document Not required any authorization. Date : $time</p>
                        <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold inter m-0">Terms & Condition</div>

                        <p className='text-sm m-0 inter text-black700'>text here</p>

                    </div>
                </div>
                <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                    <button className='sm:!px-5 !px-2 whitespace-nowrap py-2.5 sm:text-base text-xs justify-center inter font-medium inline-flex gap-x-1 text-[#344054] rounded-lg !border border-grayLight sm:w-auto w-100'>Download as PDF <DownloadIcon /></button>
                    <button className='sm:!px-5 !px-2 whitespace-nowrap py-2.5 sm:text-base text-xs justify-center inter font-medium inline-flex gap-x-1 text-[#344054] rounded-lg !border border-grayLight sm:w-auto w-100'>Print Quote <PrinterICon /></button>
                    <button className='sm:!px-5 !px-2 whitespace-nowrap py-2.5 sm:text-base text-xs justify-center inter font-medium inline-flex gap-x-1 text-[#344054] rounded-lg !border border-grayLight sm:w-auto w-100'>Share <ShareIcon /></button>

                </div>



            </div>

        </BasicModal>
    )
}

export default PreviewModal
