import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import RightModal from '../../../../components/Modals/RightModal';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { addReportingUser, getAllTeamLeads, getExistingUsers, updateFinalNegotationAutomationWithoutTeamAssignedTo } from '../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../assets/icons/searchClose.svg';
import TooltipText from '../../../../components/Tooltip';
import { getInitials } from '../../../../helpers/helpers';
import { ReactComponent as UserIcon } from '../../../../assets/icons/userIcon.svg';

const AddTeamModal = ({ show, closeModal, user, selectedTeamItem, getUserWithoutTeam }) => {

    const [users, setUsers] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [selectedUser, setSelecteduser] = useState([]);
    const [searchText, setSearchText] = useState('');

    const getUsers = async () => {
        const res = await getAllTeamLeads();
        if (res.data.status === 200) {
            let userData = res.data.data
            if (selectedTeamItem?.assigned_to?.length > 0) {
                const fileteredData = res.data.data.filter(item => selectedTeamItem.assigned_to.includes(item.id));
                const allUsersFiltered = res.data.data.filter(item => !selectedTeamItem.assigned_to.includes(item.id));
                setSelecteduser(fileteredData)
                setUsers(allUsersFiltered)
                setAllUsers(allUsersFiltered)
            } else {
                setUsers(userData)
                setAllUsers(userData)
            }
        }
    }

    // useEffect(() => {
    //     if (allusers.length > 0 && selectedTeamItem.assigned_to.length > 0) {
    //         const fileteredData = allusers.filter(item => selectedTeamItem.assigned_to.includes(item.id));
    //         const allUsersFiltered = allusers.filter(item => !selectedTeamItem.assigned_to.includes(item.id));
    //         setSelecteduser(fileteredData)
    //         setUsers(allUsersFiltered)
    //         setAllUsers(allUsersFiltered)
    //     }
    // }, [selectedTeamItem])

    useEffect(() => {
        getUsers()
    }, [selectedTeamItem])

    const handleAddNew = (user) => {
        const filteredData = users.filter(item => item.id !== user.id);
        const filterdAllUsers = allusers.filter(item => item.id !== user.id)
        setUsers(filteredData);
        setAllUsers(filterdAllUsers);
        setSelecteduser([...selectedUser, user]);
    }

    const handleRemove = (user) => {
        const filteredData = selectedUser.filter(item => item.id !== user.id)
        setSelecteduser(filteredData);
        setUsers([...users, user]);
        setAllUsers([...allusers, user])
    }

    const handleSearch = () => {
        const searchedData = allusers.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        setUsers(searchedData)
    }

    useEffect(() => {
        handleSearch()
    }, [searchText]);

    const handleSubmit = async () => {
        const userIds = selectedUser.map(item => item.id);
        const res = await updateFinalNegotationAutomationWithoutTeamAssignedTo(selectedTeamItem.uuid, { assigned_to: userIds })
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getUserWithoutTeam();
            closeModal();
        } else {
            toast.error(res.data.message)
        }
    }

    return (
        <RightModal show={show} closeModal=
            {() => {
                closeModal();
            }}
            modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Edit Team Lead</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='h-4/5 px-4 pt-4 '>
                <div className='h-1/2 space-y-4'>
                    {selectedUser.length > 0 && <><span className='text-black text-md font-semibold'>
                        Added Users {selectedUser.length > 0 ? `(${selectedUser.length})` : ''}
                    </span>
                        <div className='overflow-auto h-[80%] space-y-4'>
                            {
                                selectedUser.map((user, index) => (

                                    <div className='flex justify-between items-center border rounded-md p-2.5' key={`${user.uuid}_${index}`}>
                                        {/* <TooltipText title={user.role_name || ''}>
                                            <span className="mx-2 border p-1 font-bold rounded-md text-2xl bg-[#F2F2F2] text-grey-300" title={user.role_name}>
                                                {getInitials(user.role_name || '')}
                                            </span>
                                        </TooltipText> */}
                                        <div>
                                            <h6 className='m-0 truncate w-[200px]'>{user.name}</h6>
                                            <TooltipText title={user.team_name.join(', ')}>
                                                <p className='m-0 text-xs text-grey200 truncate w-[200px]'>{user.team_name.join(', ')}</p>
                                            </TooltipText>
                                        </div>
                                        <div>
                                            <button className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1' onClick={() => handleRemove(user)}>Remove</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div> </>}

                    {
                        selectedUser.length === 0 && <div className='h-[80%]'>
                            <div className='flex items-center justify-center h-100 flex-col text-black700'>
                                <UserIcon />
                                No Users Added
                            </div>
                        </div>
                    }

                </div>
                <div className='h-1/2 space-y-4'>
                    <div className='flex items-center justify-between'>

                        <span className='text-black text-md font-semibold'>
                            Add users
                        </span>

                        <div className={`' border rounded px-2.5 py-1 w-[70%] flex items-center ${searchText ? 'border-primary' : ''}`}>
                            <SearchIcon className=' ' />
                            <input
                                type="text"
                                placeholder='Search For User'
                                className=' w-100 focus:outline-none text-base pl-2'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText &&
                                <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                    <SearchCloseIcon />
                                </div>}
                        </div>
                    </div>
                    <div className='overflow-y-auto overflow-x-hidden h-[65%] space-y-4'>
                        {
                            users.map((user, index) => (
                                <div className='flex justify-between items-center border rounded-md p-2.5' key={`${user.uuid}_${index}`}>
                                    {/* <TooltipText title={user.role_name || ''}>
                                        <span className="mx-2 border p-1 font-bold rounded-md text-2xl bg-[#F2F2F2] text-grey-300" title={user.role_name}>
                                            {getInitials(user.role_name || '')}
                                        </span>
                                    </TooltipText> */}
                                    <div>
                                        <h6 className='m-0 truncate w-[220px]'>{user.name}</h6>
                                        <TooltipText title={user.team_name.join(', ')}>
                                            <p className='m-0 text-grey200 text-xs truncate w-[200px]'>{user.team_name.join(', ')}</p>
                                        </TooltipText>
                                    </div>
                                    <div>
                                        <button
                                            className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1'
                                            onClick={() => handleAddNew(user)} >Add</button>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            users.length === 0 && searchText && <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon className='h-10 w-10' /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Sorry, we couldn’t find any results </h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>
                                    Please try again with different keywords.
                                    {/* We're sorry, but we couldn't find any results for your search.
                                    <span className='d-md-block d-none'>Please try again with different keywords.</span> */}
                                </p>
                            </div>
                        }
                        {
                            users.length === 0 && !searchText && <div className='flex items-center justify-center flex-column h-100'>
                                <p className='text-center text-black700 black-dark-600 fz14 mb-4 text-capitalize'>
                                    No Users Available
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={` flex flex-row items-center justify-between border-top border-color bg-white absolute bottom-0 w-100 space-x-3 p-3`}>
                <button className='outline-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg hover:bg-black100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg' onClick={handleSubmit}>Save</button>
            </div>
        </RightModal>
    )
}

export default AddTeamModal
