import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg';
import { ReactComponent as Vthreedots } from '../../../../assets/icons/threedots.svg';
import TeamForFinalNegotiationModal from '../Modals/TeamForFinalNegotiationModal';
import AddTeamModal from '../cards/AddTeamModal';
import AutomationUsersModal from '../cards/AddUsersModal';
import { deleteFinalNegotationAutomationTeamsConfigByUUID, getFinalNegotationAutomationWithoutTeamConfig, getFinalNegotiationAutomationTeams, getUserProfile, insertFinalNegotiationAutomationWithoutTeam, updateFinalNegotationAutomation, updateFinalNegotationAutomationTeamStatusByUUID, updateFinalNegotationAutomationWithoutTeamStatus } from '../../../../services/private/company.service';
import { createDatewithTime } from '../../../../helpers/date';
import { toast } from 'react-toastify';
import TooltipText from '../../../../components/Tooltip';
import PopUpModal from '../../../../components/Modals/PopUpModal';

const FinalNegotiation = () => {
    const [showTeamModal, setShowTeamModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([])
    const [showAddTeamModal, setShowAddTeamModal] = useState(false);
    const [showAutomationUserModal, setShowAutomationUserModal] = useState(false);
    const [selectedUserItem, setSelectedUserItem] = useState({});
    const [selectedTeamItem, setSelectedTeamItem] = useState({});
    const [finalNegotiationAutomation, setFinalNegotiationAutomation] = useState(false);
    const [showDeleteAutomation, setShowDeleteAutomation] = useState(false);

    const getUserConfiData = async () => {
        const res = await getUserProfile();
        if (res.data.status === 200) {
            // setUserData(res.data.data)
            setFinalNegotiationAutomation(res.data.data?.final_negotiation_automation)
        }
    }

    const handleUpdateStatusPermission = async (value) => {
        const res = await updateFinalNegotationAutomation({ value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    const getSelectedTeams = async () => {
        const res = await getFinalNegotiationAutomationTeams()
        if (res.data.status === 200) {
            const teams = res.data.data?.teams?.teams || []
            setSelectedTeam(res.data.data?.teams || [])
        }
    }

    const handleUpdateTeamStatus = async (uuid, value) => {
        const res = await updateFinalNegotationAutomationTeamStatusByUUID(uuid, { is_active: !value })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getSelectedTeams();
        }
    }

    const handleUpdateUserStatus = async (uuid, value) => {
        const res = await updateFinalNegotationAutomationWithoutTeamStatus(uuid, { is_active: !value })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserWithoutTeam();
        }
    }

    const getUserWithoutTeam = async () => {
        const res = await getFinalNegotationAutomationWithoutTeamConfig();
        if (res.data.status === 200) {
            setUsers(res.data.data);
        }
    }

    useEffect(() => {
        getSelectedTeams();
        getUserWithoutTeam();
        getUserConfiData();
    }, [])

    const handleAddUser = async () => {
        const res = await insertFinalNegotiationAutomationWithoutTeam();
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getUserWithoutTeam();
        }
    }

    const handleDeleteAutomation = async () => {
        const res = await deleteFinalNegotationAutomationTeamsConfigByUUID(selectedUserItem?.uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserWithoutTeam();
            setSelectedUserItem({});
            setShowDeleteAutomation(false);
        }
    }

    return (
        <div className="roles w-100 flex flex-col h-full">
            <div className="settings-title">
                <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                    <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                        <li>
                            <Link to='/settings/automation/lead-automation'>All Automation</Link>
                        </li>
                        <li className='flex items-center'><RightArrow />
                            Final Negotiation
                        </li>
                    </ul>
                </nav>
                <div className="w-100 flex justify-between ">
                    <div className="w-10/12">
                        <div className='flex items-center mb-2'>
                            <h2 className="text-2xl mb-0 mr-2 black fw-po-medium flex items-center !gap-x-2">
                                <Link
                                    to='/settings/automation/lead-automation'
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>
                                Final Negotiation
                            </h2>
                            <button
                                type="button"
                                className={`btn btn-lg btn-toggle ${finalNegotiationAutomation ? 'active' : ''} mr-2`}
                                value={finalNegotiationAutomation}
                                onClick={() => {
                                    setFinalNegotiationAutomation(!finalNegotiationAutomation)
                                    handleUpdateStatusPermission(!finalNegotiationAutomation)
                                }}
                            >
                                <div className="handle"></div>
                                <span className={`${finalNegotiationAutomation ? 'on' : 'off'}`}>{finalNegotiationAutomation ? 'ON' : 'OFF'}</span>
                            </button>
                        </div>
                        <p className="text-sm black-dark-700 mb-0 !pl-10">
                            Final Negotiation leads will be reassigned to selected Team Leads.
                        </p>
                    </div>
                    <div className="w-2/12 flex items-start space-x-2 justify-end">

                    </div>
                </div>
            </div>
            <div className='p-4 h-[70vh] overflow-auto'>

                {selectedTeam.length === 0 && <div className='flex shadow-bottomshadow w-max border rounded-lg p-4'>
                    <div className='mr-4 flex flex-col'>
                        <p className='m-0 font-semibold text-base'>Select Team</p>
                        <p className='m-0 mt-2 text-sm font-medium'>Select teams to apply Final Negotiation automation</p>
                    </div>
                    <button
                        className='bg-primary px-2 py-1 text-white rounded-lg font-semibold text-sm h-10'
                        onClick={() => setShowTeamModal(true)}
                    > + Add Teams</button>
                </div>}
                {
                    selectedTeam.length > 0 && <div className="p-3 border rounded-lg">
                        <div className='flex justify-between'>
                            <div className='mr-4 flex flex-col'>
                                <p className='m-0 font-semibold text-base'>Select Team</p>
                                <p className='m-0 mt-2 text-sm font-medium'>Select teams to apply Final Negotiation automation</p>
                            </div>
                            <button
                                className='px-2 py-1 text-primary bg-blueHighlight rounded-lg font-semibold text-sm h-10'
                                onClick={() => setShowTeamModal(true)}
                            > Manage Teams</button>
                        </div>
                        <div className="roles-table mt-3 overflow-auto">
                            <div className="def-table pr-0 !mt-3" style={{ height: '150px' }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Teams</th>
                                            <th>Team Lead</th>
                                            <th>Lead Assignment</th>
                                            <th>Edited by</th>
                                            <th>Edited Time</th>
                                            <th></th>
                                            {/*<th>Edited Time</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedTeam.map((item, index) => (
                                            <tr
                                            >
                                                <td>{index + 1}</td>
                                                <td className="leading-5">
                                                    {item.name || -''}
                                                </td>
                                                <td className="leading-5">
                                                    {item.team_lead || '-'}
                                                </td>
                                                <td className="leading-5">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-lg btn-toggle ${item.is_active ? 'active' : ''} mr-2`}
                                                        data-toggle="button"
                                                        aria-pressed={item.is_active}
                                                        autoComplete="off"
                                                        value={item.is_active}
                                                        onClick={() => handleUpdateTeamStatus(item.uuid, item.is_active)}
                                                    >
                                                        <div className="handle"></div>
                                                        <span className={`${item.is_active ? 'on' : 'off'}`}>{item.is_active ? 'ON' : 'OFF'}</span>
                                                    </button>
                                                </td>
                                                <td>{item?.edited_by || '-'}</td>
                                                <td>{item?.updated_on ? createDatewithTime(item?.updated_on) : '-'}</td>
                                            </tr>
                                        ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {users?.length === 0 && <div className='flex shadow-bottomshadow w-max border rounded-lg p-4 mt-4'>
                    <div className='mr-4 flex flex-col'>
                        <p className='m-0 font-semibold text-base'>Single Users</p>
                        <p className='m-0 mt-2 text-sm font-medium'>Select users to apply Final Negotiation automation</p>
                    </div>
                    <button
                        className='bg-primary px-2 py-1 text-white rounded-lg font-semibold text-sm h-10'
                        onClick={handleAddUser}
                    > + Add Automation</button>
                </div>}

                {
                    users.length > 0 && <div className="p-3 border rounded-lg mt-4">
                        <div className='flex justify-between'>
                            <div className='mr-4 flex flex-col'>
                                <p className='m-0 font-semibold text-base'>Single Users</p>
                                <p className='m-0 mt-2 text-sm font-medium'>Select users to apply Final Negotiation automation</p>
                            </div>
                            <button
                                className='bg-primary px-2 py-1 text-white rounded-lg font-semibold text-sm h-10'
                                onClick={handleAddUser}
                            > + Add Automation</button>
                        </div>
                        <div className="roles-table mt-3 overflow-auto">
                            <div className="def-table pr-0 !mt-3" style={{ height: '150px' }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Users</th>
                                            <th>Assigned Team Leads</th>
                                            <th>Lead Assignment</th>
                                            <th></th>
                                            {/*<th>Edited Time</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((item, index) => (
                                            <tr
                                            >
                                                <td>{index + 1}</td>
                                                <td className="leading-5">
                                                    {
                                                        item?.user_names?.length > 0 ?
                                                            <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                                <div className="flex flex-wrap items-center">

                                                                    {
                                                                        item?.user_names.map((user, index) => (
                                                                            index < 3 && <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 w-min p-1">
                                                                                {/* {user && <TooltipText title={user || ''}>

                                                                    <span className="mr-2.5 border p-1 rounded-md text-sm bg-primary font-medium text-white">
                                                                        {getInitials(item.role_name || '')}
                                                                    </span>
                                                                </TooltipText>} */}
                                                                                {user}
                                                                                {/* <CloseIcon className="h-4 w-4 cursor-pointer" onClick={() => handleRemoveTeam(user.uuid, user.team, item.uuid)} /> */}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {
                                                                        item?.user_names > 3 &&
                                                                        <div className="tag-dropdown" >
                                                                            <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                +{item?.user_names - 3}
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {item?.user_names.map((user, index) => {
                                                                                    return <a key={index} className="dropdown-item text-capitalize"  >{user}
                                                                                    </a>

                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <TooltipText title='Edit'>
                                                                    <div className="bg-white border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                                        setShowAutomationUserModal(true)
                                                                        setSelectedUserItem(item)
                                                                    }}>
                                                                        <EditIcon />
                                                                    </div>
                                                                </TooltipText>
                                                            </div>
                                                            :
                                                            <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                                setShowAutomationUserModal(true)
                                                                setSelectedUserItem(item)
                                                            }}>
                                                                + Add
                                                            </span>
                                                    }
                                                </td>
                                                <td className="leading-5">
                                                    {
                                                        item?.assigned_to_names?.length > 0 ?
                                                            <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                                <div className="flex flex-wrap items-center">

                                                                    {
                                                                        item?.assigned_to_names.map((user, index) => (
                                                                            index < 3 && <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 w-min p-1">
                                                                                {/* {user && <TooltipText title={user || ''}>
    
                                                                            <span className="mr-2.5 border p-1 rounded-md text-sm bg-primary font-medium text-white">
                                                                                {getInitials(item.role_name || '')}
                                                                            </span>
                                                                        </TooltipText>} */}
                                                                                {user}
                                                                                {/* <CloseIcon className="h-4 w-4 cursor-pointer" onClick={() => handleRemoveTeam(user.uuid, user.team, item.uuid)} /> */}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {
                                                                        item?.assigned_to_names > 3 &&
                                                                        <div className="tag-dropdown" >
                                                                            <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                +{item?.assigned_to_names - 3}
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                {item?.assigned_to_names.map((user, index) => {
                                                                                    return <a key={index} className="dropdown-item text-capitalize"  >{user}
                                                                                    </a>

                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <TooltipText title='Edit'>
                                                                    <div className="bg-white border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                                        setShowAddTeamModal(true)
                                                                        setSelectedTeamItem(item)
                                                                    }}>
                                                                        <EditIcon />
                                                                    </div>
                                                                </TooltipText>
                                                            </div>
                                                            :
                                                            <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                                // setShowProjectModal(true)
                                                                setSelectedTeamItem(item)
                                                                setShowAddTeamModal(true);
                                                            }}>
                                                                + Add
                                                            </span>
                                                    }
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className={`btn btn-lg btn-toggle ${item.is_active ? 'active' : ''} mr-2`}
                                                        data-toggle="button"
                                                        aria-pressed={item.is_active}
                                                        autoComplete="off"
                                                        value={item.is_active}
                                                        onClick={() => handleUpdateUserStatus(item.uuid, item.is_active)}
                                                    >
                                                        <div className="handle"></div>
                                                        <span className={`${item.is_active ? 'on' : 'off'}`}>{item.is_active ? 'ON' : 'OFF'}</span>
                                                    </button>
                                                </td>
                                                <td className="dropdown view-more right-0 " style={{ position: 'sticky' }}>
                                                    <button
                                                        className="bg-tr border-0"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <Vthreedots />
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item text-capitalize"
                                                            onClick={() => {
                                                                setShowDeleteAutomation(true)
                                                                setSelectedUserItem(item)
                                                            }}>
                                                            Delete Automation
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                showTeamModal &&
                <TeamForFinalNegotiationModal
                    show={showTeamModal}
                    setShow={setShowTeamModal}
                    teams={selectedTeam.map(item => item.team_id)}
                    getSelectedTeams={getSelectedTeams}
                />
            }
            {
                showAddTeamModal && <AddTeamModal
                    show={showAddTeamModal}
                    closeModal={() => {
                        setShowAddTeamModal(false)
                        setSelectedTeamItem({})
                    }}
                    getUserWithoutTeam={getUserWithoutTeam}
                    selectedTeamItem={selectedTeamItem}
                />
            }
            {
                showAutomationUserModal &&
                <AutomationUsersModal
                    show={showAutomationUserModal}
                    closeModal={() => {
                        setShowAutomationUserModal(false)
                        setSelectedUserItem({})
                    }}
                    selectedUserItem={selectedUserItem}
                    getUserWithoutTeam={getUserWithoutTeam}
                />
            }
            {showDeleteAutomation && selectedUserItem?.uuid ? <PopUpModal
                setShow={setShowDeleteAutomation}
                show={showDeleteAutomation}
                heading={<>
                    Confirm Delete?
                </>}
                body={<div className=' p-3'>
                    <div className="mb-4">
                        Are you sure you want to delete automation?
                    </div>
                    <div className="justify-content-end d-flex">
                        <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => {
                            setShowDeleteAutomation(false)
                            setSelectedUserItem({})
                        }}>Cancel</button>
                        <button className="fw-po-medium del-btn px-16" onClick={handleDeleteAutomation}>Confirm Delete</button>

                    </div>
                </div>}
            /> : null}
        </div>
    )
}

export default FinalNegotiation