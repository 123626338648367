import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { insertProjectSpecifications, updateSpecifications } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { specificationsList } from '../../../utils/project-constant';
import InputText from '../../../components/InputGroup/InputText';
import InputSelect from '../../../components/InputGroup/InputSelect';
import InputTextarea from '../../../components/InputGroup/InputTextarea';
import RightModal from '../../../components/Modals/RightModal';
import TextEditor from '../Editor';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const SpecificationModal = ({ show, closeModal, style, setSpecifications, specifications, selectedSpecification, getSpecifications, projectUuid, selectedSpecificationDescriptiopn, selectedSpecifications, setSpecificationDescriptionBasic }) => {
    const [addedSpecifications, setAddedSpecifications] = useState([{}, {}]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [specificationDescription, setSpecificationDescription] = useState('');

    useEffect(() => {
        if (Object.keys(selectedSpecification).length) {
            const formatted = {
                type: {
                    label: selectedSpecification.type,
                    value: selectedSpecification.type
                },
                value: selectedSpecification.value
            }
            setAddedSpecifications([formatted])
        }
        else if (specifications.length) {
            const formatted = specifications.map(i => ({
                type: {
                    label: i.type,
                    value: i.type
                },
                value: i.value
            }))
            setAddedSpecifications(structuredClone(formatted))
        }
    }, [selectedSpecification, specifications]);

    useEffect(() => {
        if (selectedSpecificationDescriptiopn) {
            setSpecificationDescription(selectedSpecificationDescriptiopn)
        }
        if (selectedSpecifications?.length > 0) {
            let newSpecifications = selectedSpecifications.map(spe => ({ ...spe, type: { label: spe.type, value: spe.type } }))
            setAddedSpecifications(newSpecifications);
        }
    }, [selectedSpecificationDescriptiopn, selectedSpecifications])

    const handleSubmit = async () => {
        const mapping = addedSpecifications.map(item => ({ type: item?.type?.value || item?.other, value: item.value }))
        const payload = {
            specification_description: specificationDescription,
            specifications: mapping.filter(m => m.type && m.value)
        }

        if (payload.specifications.length === 0) {
            toast.error('Please add at least one  specification')
            return;
        }
        setSpecifications(mapping.filter(m => m.type && m.value))
        const res = await insertProjectSpecifications(projectUuid, payload)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getSpecifications();
        }
        closeModal();
    }

    const handleUpdate = async () => {
        const payload = {
            type: typeof addedSpecifications[0].type === 'object' ? addedSpecifications[0].type.value : addedSpecifications[0].type,
            value: addedSpecifications[0].value
        }
        const res = await updateSpecifications(selectedSpecification.uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getSpecifications();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={() => { closeModal(); }}
            modalClass={`${style.configurationModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Add Project Specification
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            {!selectedSpecification?.uuid && <div className='!px-5 mt-3'>
                <label htmlFor="">Specification Content</label>
                <TextEditor data={specificationDescription} onChange={(value) => setSpecificationDescription(value)} />
            </div>}
            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>
                {
                    addedSpecifications.map((specification, index) => (
                        <div
                            className='!border !border-grayLight  !py-4 !px-4 bg-[#F9FAFD] rounded-lg gap-y-4 flex flex-col'
                            key={`specification_${index}`}
                        >
                            <div>
                                <div className='flex justify-between'>
                                    <h4 className='inter text-sm text-black700 !mb-2'>Specification {index + 1}</h4>
                                    <button className='border-0 bg-tr ' onClick={() => {
                                        if (index > 1) {
                                            let newAddedSpecifications = addedSpecifications
                                            newAddedSpecifications.splice(index, 1);
                                            setAddedSpecifications(newAddedSpecifications);
                                            // setAddedSpecifications([...faqs,])
                                        } else {
                                            setAddedSpecifications([{}])
                                        }
                                    }}><CloseIcon /></button>
                                </div>
                                <div className='grid grid-cols-1 gap-x-9	gap-y-4'>
                                    <InputSelect
                                        placeholder='Select Specification Type'
                                        isMulti={false}
                                        styles={customStyles}
                                        options={specificationsList}
                                        value={specification.type ? specificationsList.find(item => item.value === specification.type.value) : ''}
                                        onChange={(value) => {
                                            if (value.value !== 'Other Specifications') {
                                                let speciValues = [...addedSpecifications];
                                                speciValues[index].type = value
                                                setAddedSpecifications([...speciValues])
                                            } else {
                                                setSelectedIndex(index)
                                            }
                                        }}
                                    />
                                    {selectedIndex === index && <InputText
                                        type="text"
                                        label="Specification Type"
                                        placeholder="Specification Type"
                                        name="name"
                                        inputclass='m-0 inter'
                                        value={specification.other}
                                        onChange={(e) => {
                                            let speciValues = [...addedSpecifications];
                                            speciValues[index].other = e.target.value
                                            setAddedSpecifications([...speciValues])
                                        }}
                                    />}
                                    <InputTextarea
                                        type="text"
                                        // label="Payment Plan Description"
                                        placeholder="Specification Description"
                                        name="name"
                                        inputclass='m-0 inter'
                                        row='2'
                                        value={specification.value}
                                        onChange={(e) => {
                                            let speciValues = [...addedSpecifications];
                                            speciValues[index].value = e.target.value
                                            setAddedSpecifications([...speciValues])
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    ))
                }

                {/* <div className='!border !border-grayLight  !py-4 !px-4 bg-[#F9FAFD] rounded-lg gap-y-4 flex flex-col'>
                    <div>
                        <h4 className='inter text-sm text-black700 !mb-2'>Specification 2</h4>
                        <div className='grid grid-cols-1 gap-x-9 gap-y-4'>

                            <InputSelect
                                placeholder='Select Specification Type'
                                isMulti={false}
                                styles={customStyles}

                            />
                            <InputTextarea
                                type="text"
                                // label="Payment Plan Description"
                                placeholder="Specification Description"
                                name="name"
                                inputclass='m-0 inter'
                                row='2'
                            />

                        </div>
                    </div>
                </div> */}
                {!selectedSpecification?.uuid && <div>
                    <button
                        className='bg-blueHighlight !text-primary text-sm font-medium inter flex items-center gap-1 !px-4 !py-2 rounded-lg float-right'
                        onClick={() => setAddedSpecifications([...addedSpecifications, {}])}
                    >
                        <PlusPrimary />Add Specification</button>
                </div>}

            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => { closeModal(); }}>Cancel</button>
                <button type='button'
                    className='pr-btn w-100'
                    onClick={() => {
                        if (selectedSpecification?.uuid) {
                            handleUpdate();
                        } else {
                            handleSubmit()
                        }
                    }}>
                    {selectedSpecification?.uuid ? 'Update' : 'Submit'}
                </button>
            </div>
        </RightModal>
    )
}

export default SpecificationModal
