import React, { useCallback } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import searchIcon from "../../../../../assets/icons/search.svg";
import { createDatewithTime } from '../../../../../helpers/date';
import { deleteAccount, facebookLinkedAccounts, getFacebookLoginUrl } from '../../../../../services/private/company.service';
import CancelCSV from '../../../../../components/Modals/cancel-csv';
import { toast } from 'react-toastify';
import { ReactComponent as Pageback } from '../../../../../assets/icons/Pageback.svg';


const ManageAccounts = () => {
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [selectedAccount, setSelectAccount] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const getFacebookLinkedPages = useCallback(() => {
        facebookLinkedAccounts().then(res => {
            setLinkedAccounts(res.data.data)
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const handleShowDeleteModal = (item) => {
        setSelectAccount(item);
        setShowDeleteModal(true);
    }
    useEffect(() => {
        getFacebookLinkedPages();
    }, []);

    const getFbLoginUrl = useCallback(() => {
        getFacebookLoginUrl().then(res => {
            window.open(`${res.data.data}&state=re_auth`, "_self")
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const handleDeleteAccount = async () => {
        if (selectedAccount.uuid) {
            const res = await deleteAccount(selectedAccount.uuid);
            if (res.status === 200) {
                getFacebookLinkedPages();
                toast.success('Account deleted successfully.')
                setShowDeleteModal(false);
            }
        }
    }

    return (
        <>
            <div className="fbintegration w-100 h-full flex flex-col">
                <div className="settings-title">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                                <ul className="d-flex align-items-center justify-content-start flex-row mb-8">

                                    <li>
                                        <Link to='/settings/integrations/all-integrations'>All integrations </Link>
                                    </li>
                                    <li >
                                        <Link to='/settings/integrations/all-integrations/facebook-forms' className='flex items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                                <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                            </svg>
                                            Facebook Integration</Link>
                                    </li>
                                    <li className='flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                        <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                                    </svg>
                                        Manage Facebook Profiles
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h1 className='fz24 black mb-8 fw-po-medium ls5 flex items-center !gap-x-2'>
                                <Link

                                    to='/settings/integrations/all-integrations/facebook-forms'
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>
                                Manage Facebook Profiles
                            </h1>
                            {/* <p className='fz16 black-dark-700 ls5 mb-0'>Manage your Facebook account connections</p> */}
                        </div>
                    </div>
                </div>


                <div className='fb-manage-account !h-full overflow-auto mt-24 ml-24 '>
                    <div className="def-table pr-24 pl-0 !h-full">
                        <table className='table '>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Account Name</th>
                                    <th>Created By</th>
                                    <th>Forms Connected</th>
                                    <th>Date Created</th>

                                    <th style={{ width: '60px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    linkedAccounts.length > 0 && linkedAccounts.map((account, index) => {
                                        return (
                                            <tr key={`${account.uuid}-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {account.name.length > 20 ?
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id="button-tooltip" >
                                                                    <div className='info-msg'>

                                                                        <p className='text-capitalize'>{account.name}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className='three-dots'>
                                                                {account.name}
                                                            </span>
                                                        </OverlayTrigger>
                                                        :
                                                        <>
                                                            {account.name}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {account.added_by.length > 20 ?
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Tooltip id="button-tooltip" >
                                                                    <div className='info-msg'>

                                                                        <p className=''>{account.added_by}</p>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className='three-dots'>
                                                                {account.added_by}
                                                            </span>
                                                        </OverlayTrigger>
                                                        :
                                                        <>
                                                            {account.added_by}
                                                        </>
                                                    }


                                                </td>
                                                <td className=''>{account.forms_connected}</td>
                                                <td>{createDatewithTime(account.created_on)}	</td>
                                                <td className='dropdown view-more' style={{ width: '60px' }}>

                                                    <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a className="dropdown-item" onClick={getFbLoginUrl}>Reconnect Account</a>
                                                        <Link className="dropdown-item" to={`/settings/integrations/all-integrations/facebook-forms?created_by=${account.uuid}`}>View integrated page</Link>
                                                        <a className="dropdown-item del-menu-itme" onClick={() => handleShowDeleteModal(account)} >Delete Account</a>
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {linkedAccounts?.length === 0 && <div className='d-flex justify-content-center items-center flex-column text-center mt-64'>
                            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                            <h2 className="po black fz16 fw-po-mediumm mb-8"> No Integrations Found</h2>
                        </div>}
                    </div>
                </div>

            </div>
            {showDeleteModal && <CancelCSV
                show={showDeleteModal}
                closeCancelModal={setShowDeleteModal}
                onSubmit={handleDeleteAccount}
                title={'Delete Account Data'}
                bodyText={'Are you sure you want to proceed with deleting your token, pages, and account data? This action cannot be undone.'}
                submitButtonType={'delete'}
                cancelButtonText='Cancel'
                submitButtonText='Confirm'
            />}
        </>
    )
}

export default ManageAccounts;