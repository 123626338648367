import React, { useEffect, useState } from 'react'
import ListingProjectHeader from '../Settings/Listing/ListingProjectHeader';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import InputText from '../../components/InputGroup/InputText';
import InputTextarea from '../../components/InputGroup/InputTextarea';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getProjectSEOInfo, setProjectSeoInfo } from '../../services/public/public.service';
import ProjectHeader from '../../components/Projects/project-header';

const SeoInfo = () => {
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [transactionType, setTransactionType] = useState('');

    const { uuid } = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);

    useEffect(() => {
        if (searchParams.get('pt') && searchParams.get('tt')) {
            setPropertyType(searchParams.get('pt'));
            setTransactionType(searchParams.get('tt'));
        }
    }, [searchParams.get('pt'), searchParams.get('tt')]);

    const getSeoInfo = async () => {
        const res = await getProjectSEOInfo(uuid)
        if (res.data.status === 200) {
            // console.log(res.data.data, 'seo')
            setDescription(res.data.data?.meta_description || '');
            setTitle(res.data.data?.meta_title || '');
            setLink(res.data.data?.permalink || '')
        }
    }

    useEffect(() => {
        if (uuid) {
            getSeoInfo();
        }
    }, [uuid]);

    const handleSubmit = async () => {
        const payload = {
            meta_title: title,
            meta_description: description,
            permalink: link
        }
        const res = await setProjectSeoInfo(uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            // history.push('/settings/listing/projects');
            history.push(`/projects/create-projects/construction-updates/${uuid}?pt=${propertyType}&tt=${transactionType}`);
        }
    }

    return (
        <div className={`${style.creatproject} !px-3`} >
            <div className={`${style.proejctheading} proejctheading mt-16 mb-24 d-flex align-items-lg-center gap-24 flex-lg-nowrap flex-wrap flex-lg-row flex-column border-b border-black200 sticky top-0 pb-3.5`}>
                <ProjectHeader style={style} />
            </div>

            <div className={`${style.overflowcontent} d-flex flex-fill flex-col overflow-auto mb-3 pl-24`}>
                <div className='grid grid-cols-2 gap-6'>

                    <InputText
                        label='Meta Title'
                        type={'text'}
                        placeholder={'Enter Meta Title'}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                    <div className=''>
                        <InputTextarea
                            label={'Meta Description'}
                            placeholder={'Enter Meta Description'}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }} />
                    </div>
                    <InputText
                        label="Permalink (URL)"
                        placeholder="Enter Permalink (URL)"
                        type="text"
                        value={link}
                        inputclass='m-0 inter'
                        onChange={(e) => {
                            setLink(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-between shadow-topshadow'>
                <button
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        // history.push(`/settings/listing/projects/create-project/listing-media-upload/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                        history.push(`/projects/create-projects/broker-cp/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                ><LeftIcon />Previous</button>

                <button
                    type='button'
                    className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter'
                    onClick={handleSubmit}
                // className={`${style.savebtn}`}
                > Save & Next</button>
            </div>
        </div>
    )
}

export default SeoInfo