import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Disclosure, Switch, Transition } from '@headlessui/react'
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import InputBox from '../InputGroup/InputBox';
import { MentorService } from '../../services/mentor';
import { checkFileSize, cleanObj, convertToFormData } from '../../helpers/form.helper'
import makeUploadRequest from '../../services/api/uploadRequest'
import ConfirmBack from '../Modals/ConfirmBack';
import { toast } from 'react-toastify';
import EditModal from './EditModal';

const EditMentor = () => {

  let [isOpen, setIsOpen] = useState(false)
  // let [doEdit, setDoEdit] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  let history = useHistory();

  let [categories, setCategories] = useState([])

  const [files, setFiles] = useState({})

  const [mentorData, setMentorData] = useState({});
  const [showCancel, setShowCancel] = useState(false);
  let [updatedData, setUpdatedData] = useState({});

  const params = useParams();

  const getCategories = async () => {
    await MentorService.getAllCategories().then(res => {
      if (res.status === 200) {
        setCategories(res.data)
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data)
    })
  }

  const getMentorById = async () => {
    await MentorService.getMentorById(params.uuid).then(res => {
      if (res.status === 200) {
        setMentorData(res.data.data);
      }
    }).catch(e => {
      console.error(e)
      toast.error(e.response.data);
    })
  }

  const updateMentorData = async (formData) => {
    await MentorService.updateMentorData(params.uuid, formData).then(res => {
      if (res.status === 200) {
        toast.success("Updated Mentor!")
        history.push("/settings/mentors/all-mentors")
      }
    }).catch(e => {
      console.error(e);
      toast.error("Something went wrong while trying to update")
    })

  }

  async function updateMentor() {
    await updateMentorData(updatedData);
  }

  useEffect(() => {
    getMentorById();
    getCategories();
  }, [])

  return (
    <>
      {isOpen && <> <EditModal
        isOpen={isOpen}
        closeModal={closeModal}
        updateMentor={updateMentor}
      />

      </>}
      <ConfirmBack
        handleTrue={() => {
          setShowCancel(false)
          window.location.href = 'mentors/all-mentors';
        }}
        isOpen={showCancel}
        closeModal={() => setShowCancel(false)}
      />

      <div className='main-div flex w-full overflow-hidden'>
        <div className="flex w-full">
          <div className="w-full basis-full	h-full overflow-auto pb-8">
            <div className="title-wraper p-6 w-full sticky top-0 bg-white z-10">
              <div className="flex w-full basis-full">
                <div className='settings-title	w-full'>
                  <h1 className='text-2xl text-black font-medium	'>Edit Mentor</h1>
                </div>
              </div>
            </div>
            <Formik
              initialValues={
                {
                  name: mentorData.name,
                  // picture_url:mentorData.picture_url,
                  designation: mentorData.designation,
                  company: mentorData.company,
                  category: mentorData.category,
                  short_description: mentorData.short_description,
                  // logo_1:mentorData.logo_1,
                  // logo_2:mentorData.logo_2,
                  // logo_3:mentorData.logo_3
                }
              }
              enableReinitialize={true}
              validate={values => {
                const errors = {};
                if (!values.name) errors.name = 'Required';
                if (!values.designation) errors.designation = 'Required';
                if (!values.company) errors.company = 'Required';
                if (!values.category) errors.category = 'Required';
                if (!values.short_description) errors.short_description = 'Required';
                if (!values.picture_url && !mentorData.picture_url) errors.picture_url = 'Required';
                if (!values.logo_1 && !mentorData.logo_1) errors.logo_1 = 'Required';
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let pictureUrl = '', logo_1 = '', logo_2 = '', logo_3 = '';
                if (files.hasOwnProperty('picture_url')) {
                  const formData1 = new FormData();
                  formData1.append("document_upload", files.picture_url)
                  let pictureUrl_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData1);
                  pictureUrl = pictureUrl_obj.data.data.s3_url;
                  console.log("pictureUrl new image upload");
                }
                if (files.hasOwnProperty('logo_1')) {
                  const formData1 = new FormData();
                  formData1.append("document_upload", files.logo_1)
                  let logo_1_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData1);
                  logo_1 = logo_1_obj.data.data.s3_url;
                  console.log("logo_1 new image upload");
                }
                if (files.hasOwnProperty('logo_2')) {
                  const formData1 = new FormData();
                  formData1.append("document_upload", files.logo_2)
                  let logo_2_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData1);
                  logo_2 = logo_2_obj.data.data.s3_url;
                  console.log("logo_2 new image upload");
                }
                if (files.hasOwnProperty('logo_3')) {
                  const formData1 = new FormData();
                  formData1.append("document_upload", files.logo_3)
                  let logo_3_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData1);
                  logo_3 = logo_3_obj.data.data.s3_url;
                  console.log("logo_3 new image upload");
                }

                const filtered_mentorData = {
                  name: values.name,
                  picture_url: pictureUrl ? pictureUrl : mentorData.picture_url,
                  designation: values.designation,
                  company: values.company,
                  category: values.category,
                  short_description: values.short_description,
                  logo_1: logo_1 ? logo_1 : mentorData.logo_1,
                  logo_2: logo_2 ? logo_2 : mentorData.logo_2,
                  logo_3: logo_3 ? logo_3 : mentorData.logo_3
                };
                setUpdatedData(cleanObj(filtered_mentorData))
                openModal();
                // const form_data_body = convertToFormData({ ...filtered_mentorData, ...values });
                // if (doEdit) await updateMentorData(cleanObj(filtered_mentorData));
                // setSubmitting(false);
                // setDoEdit(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className='!px-6 pt-8' onSubmit={handleSubmit}>
                  <div className="w-10/12">
                    <div className="grid grid-cols-2 gap-9">
                      <div>
                        <div className="form-group">
                          <InputBox
                            title="Mentor Name"
                            name="name"
                            error={errors.name}
                            touched={touched.name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.name}
                          />
                        </div>
                      </div>
                      <div className="...">
                        <div className="form-group">
                          <label>Mentor Picture(Less than 1mb, 295 X 230px) <span className="font-bold text-red-500">{errors.picture_url && touched.picture_url && errors.picture_url}</span>
                            <span className="text-sky-900 font-bold">{mentorData && mentorData.picture_url && "Uploaded"}
                            </span>
                            {
                              files.picture_url ?
                                <img src={URL.createObjectURL(files.picture_url)} height="221px" width="248px" />
                                :
                                mentorData && mentorData.picture_url && <img src={mentorData.picture_url} height="221px" width="248px" />}
                          </label>
                          <input type="file" className='form-control' placeholder=' '
                            name="picture_url"
                            style={(errors.picture_url && touched.picture_url) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {

                              if (e.target.files.length === 0) {
                                handleChange(e);
                                setFiles({ ...files, [e.target.name]: '' });
                                return;
                              }

                              if (checkFileSize(e.target.files[0].size)) {
                                toast.error("File Limit Exceed")
                                return
                              }

                              setFiles({ ...files, [e.target.name]: e.target.files[0] })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            value={values.picture_url}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="grid grid-cols-2 gap-9 mt-4">
                      <div>
                        <div className="form-group">
                          <InputBox
                            title="Designation"
                            name="designation"
                            error={errors.designation}
                            touched={touched.designation}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.designation}
                          />
                        </div>
                      </div>
                      <div className="...">

                        <div className="form-group">
                          <InputBox
                            title="Company"
                            name="company"
                            error={errors.company}
                            touched={touched.company}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.company}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="grid grid-cols-2 gap-9 mt-4">
                      <div>
                        <div className="form-group">
                          <label>Mentor Category <span className="font-bold text-red-500">{errors.category && touched.category && errors.category}</span> </label>
                          <select id="" className='form-control'
                            name="category"
                            style={(errors.category && touched.category) && { border: "2px solid #ef4444" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}
                          >
                            {categories.map((data, index) => {
                              return (
                                <option value={data} key={`${data}_${index}`}>{data}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="...">
                        <div className="form-group">
                          <label>Company Logo 1
                            <span className="font-bold text-red-500">{errors.logo_1 && touched.logo_1 && errors.logo_1}</span>
                            <span className="text-sky-900 font-bold">{mentorData && mentorData.logo_1 && "Uploaded"}
                            </span>
                            {files.logo_1 ?
                              <img src={URL.createObjectURL(files.logo_1)} height="130px" width="50px" /> :
                              mentorData && mentorData.logo_1 && <img src={mentorData.logo_1} height="130px" width="50px" />}
                            {(files.logo_1 || mentorData.logo_1) && <a className='hover:text-primary' onClick={() => {
                              if (files?.logo_1) {
                                setFiles(prev => ({
                                  ...prev,
                                  logo_1: ''
                                }))
                              }
                              if (mentorData?.logo_1) {
                                setMentorData(prev => ({
                                  ...prev,
                                  logo_1: ''
                                }))
                              }
                            }}>Remove Logo</a>}
                          </label>
                          <input type="file" className='form-control' placeholder=' '
                            name="logo_1"
                            style={(errors.logo_1 && touched.logo_1) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {

                              if (e.target.files.length === 0) {
                                handleChange(e);
                                setFiles({ ...files, [e.target.name]: '' });
                                return;
                              }
                              if (checkFileSize(e.target.files[0].size)) {
                                toast.error("File Limit Exceed")
                                return
                              }
                              setFiles({ ...files, [e.target.name]: e.target.files[0] })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            value={values.logo_1 || ''}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="grid grid-cols-2 gap-9 mt-4">
                      <div>
                        <div className="form-group">
                          <label>Short Description <span className="font-bold text-red-500">{errors.short_description && touched.short_description && errors.short_description}</span></label>
                          <textarea id="" cols="5" rows="5" className='form-control'
                            name="short_description"
                            style={(errors.short_description && touched.short_description) && { border: "2px solid #ef4444" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description}
                          ></textarea>
                        </div>
                      </div>
                      <div className="...">
                        <div className="form-group">
                          <label>Company Logo 2
                            <span className="text-sky-900 font-bold">{mentorData && mentorData.logo_2 && "Uploaded"}</span>
                            {files.logo_2 ?
                              <img src={URL.createObjectURL(files.logo_2)} height="130px" width="50px" />
                              : mentorData && mentorData.logo_2 && <img src={mentorData.logo_2} height="130px" width="50px" />}
                            {(files.logo_2 || mentorData.logo_2) && <a className='hover:text-primary' onClick={() => {
                              if (files?.logo_2) {
                                setFiles(prev => ({
                                  ...prev,
                                  logo_2: ''
                                }))
                              }
                              if (mentorData?.logo_2) {
                                setMentorData(prev => ({
                                  ...prev,
                                  logo_2: ''
                                }))
                              }
                            }}>Remove Logo</a>}
                          </label>
                          <input type="file" className='form-control' placeholder=' '
                            name="logo_2"
                            style={(errors.logo_2 && touched.logo_2) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {
                              if (e.target.files.length === 0) {
                                handleChange(e);
                                setFiles({ ...files, [e.target.name]: '' });
                                return;
                              }
                              if (checkFileSize(e.target.files[0].size)) {
                                toast.error("File Limit Exceed")
                                return
                              }
                              setFiles({ ...files, [e.target.name]: e.target.files[0] })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            value={values.logo_2 || ''}
                          />
                        </div>
                        <div className="form-group">
                          <label>Company Logo 3
                            <span className="text-sky-900 font-bold">{mentorData && mentorData.logo_3 && "Uploaded"}</span>
                            {files.logo_3 ?
                              <img src={URL.createObjectURL(files.logo_3)} height="130px" width="50px" /> :
                              mentorData && mentorData.logo_3 && <img src={mentorData.logo_3} height="130px" width="50px" />}
                            {(files.logo_3 || mentorData?.logo_3) && <a className='hover:text-primary' onClick={() => {
                              if (files?.logo_3) {
                                setFiles(prev => ({
                                  ...prev,
                                  logo_3: ''
                                }))
                              }
                              if (mentorData?.logo_3) {
                                setMentorData(prev => ({
                                  ...prev,
                                  logo_3: ''
                                }))
                              }
                            }}>Remove Logo</a>}
                          </label>
                          <input type="file" className='form-control' placeholder=' '
                            name="logo_3"
                            style={(errors.logo_3 && touched.logo_3) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {
                              if (e.target.files.length === 0) {
                                handleChange(e);
                                setFiles({ ...files, [e.target.name]: '' });
                                return;
                              }
                              if (checkFileSize(e.target.files[0].size)) {
                                toast.error("File Limit Exceed")
                                return
                              }
                              setFiles({ ...files, [e.target.name]: e.target.files[0] })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            value={values.logo_3 || ''}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="flex mt-6">
                      <div className='text-primary border border-primary text-16 font-medium rounded mr-4 px-10 py-2 cursor-pointer'
                        onClick={() => { setShowCancel(true) }}
                      >Cancel</div>
                      <button className='bg-primary text-16 font-medium text-white px-8 py-2 rounded '
                        onClick={
                          () => openModal()
                        }
                      >Save</button>
                    </div>
                  </div>

                </form>
              )}

            </Formik>
          </div>

        </div>

      </div>

    </>
  )
}

export default EditMentor



