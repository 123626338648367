import React, { Fragment } from 'react'
import { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



const UploadSuccess = ({ uploadReport, updateStep, closeSuccesPage, showCancelCSVModal }) => {

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" >
            {text}
        </Tooltip>
    );

    return (
        <>
            <div className="upload-sucessfull w-100">
                <h2 className='d-flex flex-column justify-content-center align-items-center black fz16 fw-medium mb4'>
                    <svg width="64" height="64" className="mb-4" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#32BA7C" />
                        <circle cx="31.5" cy="31.5" r="24.5" fill="#32BA7C" />
                        <path d="M46.6668 21L26.5002 41.1667L17.3335 32" stroke="white" strokeWidth="3.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Successful Upload!
                </h2>
                <p className='text-center fz14 worksans fw-medium black-dark-700 mb-0'>Your file has been successfully uploaded</p>
                <div className="result-according">
                    <div className="resutl-heading p-0 d-flex align-items-center">
                        <div className="one p-0">Total No.</div>
                        <div className="two">Status</div>

                    </div>
                    <div>

                        <p className='d-flex align-items-center m_row' >
                            <span className='blank'>{uploadReport.totalLeads}</span>
                            <span className='d-flex align-items-center'>
                                Leads Detected
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip('Total number of Leads detected from the file you have uploaded.')}
                                >
                                    <Button className='bg-tr border-0' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                </OverlayTrigger>

                            </span>
                        </p>
                        <p className='d-flex align-items-center m_row'>
                            <span className='blank'>{uploadReport.columnsMapped}</span>
                            <span className='d-flex align-items-center'>
                                Columns Automatched

                                <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            {uploadReport.mappedColumns && uploadReport.mappedColumns.map((item, index) => {
                                                return (
                                                    <a className='dropdown-item' key={`${item}_${index}`}>
                                                        {item}
                                                    </a>
                                                )
                                            })}
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                </OverlayTrigger>
                            </span>
                        </p>
                        <p className='d-flex align-items-center m_row '>
                            <span className='blank'>{uploadReport.columnsNeedToMap}</span>
                            <span className='d-flex align-items-center'>
                                Columns Unmatched
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            {uploadReport.mappableColumns && uploadReport.mappableColumns.map((item, index) => {
                                                return (
                                                    <a className='dropdown-item' key={`${item}_${index}`}>
                                                        {item}
                                                    </a>
                                                )
                                            })}
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default"> <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
                                </OverlayTrigger>
                            </span>
                        </p>

                        {/* <p className='d-flex align-items-center m_row'>
                            <span className='blank'>{uploadReport.columnIgnored}</span>
                            <span>Column ignored</span>
                        </p> */}
                    </div>

                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">
                <button className='outline-btn' onClick={showCancelCSVModal}>Previous</button>
                <button className='pr-btn ml-8' onClick={() => { updateStep(3) }}>Continue</button>

            </div>
        </>
    )
}

export default UploadSuccess