import { put, all, call, takeLatest ,select  } from "redux-saga/effects";
import { request } from '../../helpers/requests';
import { browserRedirect } from '../../helpers/helpers';
import { urls } from '../../helpers/urls';
import  AWSConfig from '../../aws-exports';
import AmplifySdk from "../../helpers/amplify";
import {
  FORGOT_REQUESTING,
  forgotSuccess,
  forgotError
} from "./actions";

const Amplify =  AmplifySdk();


function forgotPasswordSubmit(payload){

  return   Amplify.Auth.forgotPsasswordSubmit( payload.email, payload.code, payload.password , { 'custom:password' : payload.password  } );

}
const getState = (state) => state.forgotPassword;

// FORGOT Worker
function* forgotWorker( payload ) {
  try {
    let state = yield select(getState);
    payload['code'] = state.code;
    payload['email'] = state.email;
    let response = yield call(forgotPasswordSubmit, payload);  
    yield put(forgotSuccess( ));  
  } catch (err) {
    let message = err.message == "Invalid code provided, please request a code again." ? "Link Experied!! Please verify again" :err.message
    yield put(forgotError( { ...err , message : message  }));
  }
}


// FORGOT Watcher
export default function* forgotSaga() {
  yield all([
    takeLatest(FORGOT_REQUESTING, forgotWorker),
  ]);
}


