
import React, { useState, useEffect } from 'react';
import SideBanner from '../../components/SideBanner/SideBanner';
import ConfirmMailSend from './PasswordViews/ConfirmMailSend';
import EnterMailModal from './PasswordViews/EnterMailModal';
import { Link, useHistory } from 'react-router-dom';

export default function ForgotPasswordOptions() {
    const Heading = `A Real Estate Platform That 'Works For You'`;
    const SubHeadingText = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;

    const [email, setEmail] = useState("");
    const [sentMail, setSentMail] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('user-login')

        return (() => el.classList.remove('user-login'))
    }, [])

    return (
        <>
            <div className="container-fluid d-flex flex-fill">
                <div className="row d-flex flex-fill">
                    <div className="col-lg-4 p-0 d-lg-flex flex-fill d-none">
                        <SideBanner Heading={Heading} Text={SubHeadingText} />
                    </div>
                    <div className="col-lg-8 login-div d-flex flex-fill">
                        <div className="my-login-page d-flex flex-fill align-items-center justify-content-center">
                            <div className={`card  ${sentMail ? 'forget-msg' : 'forget-pass'}`}>
                                <div className="card-body">
                                    <div className="login-log d-flex align-items-center flex-row position-relative justify-content-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} />
                                        <div className="beta">BETA</div>
                                    </div>
                                    {/* {sentMail ?
                                        <ConfirmMailSend
                                            email={email}
                                        />
                                        :
                                        <EnterMailModal
                                            setEmail={setEmail}
                                            email={email}
                                            setSentMail={setSentMail}
                                        />
                                    } */}
                                    <>
                                        <h3 className="fz24 black text-center mt-40 mb-12">Forgot your password</h3>
                                        <p className="fz14 text-center black-dark-700 ro">Please select either of the two options to login to your Propacity account</p>
                                        <div className={`form-group position-relative mt-40`}>
                                            <div className="form-group mt-24">
                                                <button type="submit" onClick={() => { history.push('/forgotpassword-email') }}
                                                    className="w-100 fz18 py-10 bg-blueHighlight text-primary rounded-lg">
                                                    Send Recovery Email
                                                </button>
                                            </div>
                                            <div className='flex items-center justify-center mt-2'>
                                                Or
                                            </div>
                                            <div className="form-group mt-2">
                                                <button type="submit" onClick={() => { history.push('/forgotpassword-otp') }}
                                                    className="pr-btn w-100 fz18 py-10">
                                                    Reset password with OTP
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    <div className="form-group text-center mt-24">
                                        <Link to="/login" className='pr-color fz16 pr-hover' >Back To Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}