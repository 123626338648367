import React, { useState, useEffect, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Tags from '../../../Lead Details/components/Tags/Tags'
import IntentDropDown from '../../../Lead Details/components/Intent Dropdown/IntentDropdown'
import StatusDropDown from '../../../../components/Status Dropdown/StatusDropdown'
import LatestActivity from './LatestActivity'
import { toast } from 'react-toastify'
import { deleteLeads, getLatestActivityByUuid, reassignLeads } from '../../../../services/private/leads.service'
import ConfirmDeletion from '../../../../components/Modals/ConfirmDeletion'
import IntentSelect from '../../../../components/Custom Select/IntentSelect'
import StatusDropDownDynamic from '../../../../components/Status Dropdown/StatusDropdownDynamic'
import { getSelectedValue } from '../../../../utils/selectedOptionUtils'
import Skeleton from 'react-loading-skeleton'
import { getAuthRolePermissions, getMasterEnumsCall, updateleadByKeyValue } from '../../../../services/private/company.service';
import NewFollowUpModal from '../../../../components/Modals/NewFollowUpModal'
import EoiDoneModal from '../../../../components/Modals/EoiDoneModal';
import BookingsDoneModal from '../../../../components/Modals/BookingsDoneModal';
import MarkLeadDead from '../../../../components/Modals/MarkLeadDead';
import { useParams } from 'react-router-dom';
import ReassignLeadModal from '../../../../components/Modals/ReassignLeadModal';
import SiteVisiteDoneModal from '../../../../components/Modals/SiteVisiteDoneModal';
import UpdateSiteVistModal from '../../../../components/Modals/SiteVistModal/UpdateSiteVisitModal';
import UpdateFollowupModal from '../../../../components/Modals/FollowupModal/UpdateFollowupModal';
import EOIModal from '../../../../components/Modals/EOIModal';
import BookingModal from '../../../../components/Modals/BookingModal';
import Consumer from '../../../../helpers/context';
import SiteVisiteCancelledModal from '../../../../components/Modals/SitevisiteCancelledModal'
import ShareLeadAccessModal from '../../../../components/Modals/ShareLeadAccessModal'
import { checkIsSomeItemAvailableInList } from '../../../../helpers/helpers'
import NewEOIModal from '../../../../components/Modals/NewEOIModal'
import NewBookingModal from '../../../../components/Modals/NewBookingModal'

export default function LeadDetailLeftTop({
    leadInfoData,
    customerInfoData,
    leadAccess
}) {

    const params = useParams();

    let [intentStatuses, setIntentStatuses] = useState([]);
    let [statuses, setStatuses] = useState([]);
    let [sourceStatuses, setSourceStatuses] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedIntent, setSelectedIntent] = useState({
        label: leadInfoData.intent?.display_name,
        value: leadInfoData.intent?.id
    })
    const [latestActivity, setLatestActivity] = useState({})
    const [showReassignModal, setShowReassignModal] = useState(false);

    const [changeDataTemp, setChangeDataTemp] = useState({ uuid: "", value: "" })

    const [followUp, setFollowUp] = useState(false)
    const [siteVisit, setSiteVisit] = useState(false)
    const [eoiDone, setEoiDone] = useState(false)
    const [bookingDone, setBookingDone] = useState(false)
    const [markDead, setMarkDead] = useState(false)
    const [markDeadObj, setMarkDeadObj] = useState(false)
    const [deadUuid, setDeadUuid] = useState('');
    const [svCompleted, setSvCompleted] = useState(false);
    const [deletedSuccess, setDeletedSuccess] = useState(false)
    const [reAssignRole, setReAssignRole] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [svCancelled, setSvCancelled] = useState(false);
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false)

    const ROLE_NAME = localStorage.getItem('role');
    const { userProfile, allowedPermissions } = useContext(Consumer);
    let history = useHistory();

    console.log(customerInfoData, 'customer info');
    console.log(leadInfoData, 'lead info')
    useEffect(() => {
        const load = async () => {
            const response = await getLatestActivityByUuid(params.id);
            setLatestActivity(response)
        }
        if (params.id) load()
    }, [params.id])

    const getStatusandIntent = async () => {
        await getMasterEnumsCall().then(res => {
            if (res.status === 200) {
                const intentOptions = res.data.data.intents.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.display_name
                    }
                })
                const sourceOptions = res.data.data.sources.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.display_name
                    }
                })
                const statusesOptions = res.data.data.statuses.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.display_name
                    }
                })
                setIntentStatuses(intentOptions)
                setSourceStatuses(sourceOptions)
                setStatuses(statusesOptions)
            }
        })
    }

    const postDeleteLead = (selectedValue) => {
        deleteLeads(selectedValue).then(res => {
            if (res.status === 200) {
                toast.success("Lead Deleted")
                setShowDeleteModal(false);
                setDeletedSuccess(true)
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSvCompleted = () => {
        if (Object.keys(latestActivity?.siteVisit)?.length === 0) {
            toast.error('Cannot change status as no SV is scheduled.')
            return;
        }
        setSvCompleted(true)
    }

    const handleStatusChange = async (value, uuid) => {
        // await updateLeadData(uuid, { key: "status_id", value: value.value })
        setChangeDataTemp({ uuid: uuid, value: value.value, label: value.label })
        switch (value.label) {
            case "Not Connected":
                //Not connected
                setFollowUp(true)
                break;
            case "In Progress":
                //in progress
                setFollowUp(true)
                break
            case "Final Negotiation":
                //in progress
                setFollowUp(true)
                break
            case "SV Scheduled":
                //sv -sheduled
                setSiteVisit(true)
                break;
            case "SV Completed":
                //sv-completed
                // setSvCompleted(true)
                handleSvCompleted();
                break;
            case "SV Cancelled":
                //sv-completed
                // setSvCompleted(true)
                setSvCancelled(true);
                break;
            case "EOI Completed":
                //eoi - completed
                setEoiDone(true)
                break;

            case "Booking Completed":
                //booking completed
                setBookingDone(true)
                break;
            case "Dead Lead":
                setDeadUuid(uuid)
                setSelectedOption(value)
                setMarkDead(true)
                break;
            case "Dump":
                setDeadUuid(uuid)
                setSelectedOption(value)
                setMarkDead(true)
                break;
        }

    }

    const updateLeadData = async (uuid, { key, value }) => {
        await updateleadByKeyValue(uuid, { key, value }).then(res => {
            if (res.status === 200) {
                window.location.reload()
            }
        }).catch(e => {
            console.log(e.response.data);
            toast.error(e.response.data.message);
        })
    }

    const handleIntentChange = async (value, uuid) => {
        await updateLeadData(uuid, { key: "intent_id", value: value.value })
        toast.success("Intent Changed")
    }

    useEffect(() => {
        getStatusandIntent()
        setSelectedIntent({
            label: leadInfoData.intent?.display_name,
            value: leadInfoData.intent?.id
        })
    }, [leadInfoData])

    const handleReassignModalSubmit = (userDetails, remarks) => {
        reassignLeads([params.id], userDetails.value, remarks).then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned");
                setShowReassignModal(false);
                if (ROLE_NAME === 'ADMIN') {
                    window.location.reload();
                } else {
                    history.push('/')
                }
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    useEffect(() => {
        const getUsersAuthRolePermissions = () => {
            getAuthRolePermissions().then((res) => {
                res.data.data.map((role) => {
                    if (role.module === "leads") {
                        setReAssignRole(role.permissions)
                    }
                })
            })
        }
        getUsersAuthRolePermissions()
    }, []);

    return (
        <>
            {showDeleteModal && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                deleteHandler={() => postDeleteLead(leadInfoData.data.uuid)}
            />}

            {followUp && <UpdateFollowupModal
                show={followUp}
                leadId={changeDataTemp.uuid}
                leadName={customerInfoData.data?.name}
                setShow={setFollowUp}
                selectedIntent={selectedIntent.value}
                selectedStatus={leadInfoData.status}
                intentOptions={intentStatuses}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                statusLabel={changeDataTemp.label}
                statusId={changeDataTemp.value}
                reassindedUserUuid={leadInfoData?.assignedTo?.uuid}
            />}

            {siteVisit && <UpdateSiteVistModal
                show={siteVisit}
                setShow={setSiteVisit}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentStatuses}
                selectedIntent={selectedIntent.value}
                statusId={changeDataTemp.value}
                reassindedUserUuid={leadInfoData?.assignedTo?.uuid}
            />}
            {svCompleted && <SiteVisiteDoneModal
                show={svCompleted}
                setShow={setSvCompleted}
                leadId={params.id}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentStatuses}
                selectedIntent={selectedIntent.value}
                statusId={changeDataTemp.value}
                reassindedUserUuid={leadInfoData?.assignedTo?.uuid}
            />}

            {svCancelled && <SiteVisiteCancelledModal
                show={svCancelled}
                setShow={setSvCancelled}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                intentOptions={intentStatuses}
                selectedIntent={selectedIntent.intent}
                statusId={changeDataTemp.value}
                reassindedUserUuid={leadInfoData?.assignedTo?.uuid}
            />}

            {/* 
                New EOI Modal flow
            */}
            {/* {
                (userProfile?.allowed_modules?.includes('new_booking') && eoiDone) &&
                <EOIModal
                    show={eoiDone}
                    closeModal={() => setEoiDone(false)}
                    lead={changeDataTemp}
                    data={leadInfoData}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            } */}
            {
                (userProfile?.allowed_modules?.includes('new_booking') && eoiDone) &&
                // +userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) &&
                <NewEOIModal
                    show={eoiDone}
                    closeModal={() => setEoiDone(false)}
                    lead={changeDataTemp}
                    data={{ ...leadInfoData?.data, ...customerInfoData?.data, id: leadInfoData?.data?.uuid }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            }
            {(!userProfile?.allowed_modules?.includes('new_booking') && eoiDone) && <EoiDoneModal
                show={eoiDone}
                setShow={setEoiDone}
                leadId={changeDataTemp.uuid}
                intentOptions={intentStatuses}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                selectedIntent={selectedIntent.value}
            />}

            {/* 
                New Booking Modal flow
            */}
            {/* {
                (userProfile?.allowed_modules?.includes('new_booking') && bookingDone) &&
                <BookingModal
                    show={bookingDone}
                    closeModal={() => setBookingDone(false)}
                    lead={changeDataTemp}
                    data={leadInfoData}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            } */}
            {
                (userProfile?.allowed_modules?.includes('new_booking') && bookingDone) &&
                // (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) &&
                <NewBookingModal
                    show={bookingDone}
                    closeModal={() => setBookingDone(false)}
                    lead={changeDataTemp}
                    data={{ ...leadInfoData?.data, ...customerInfoData?.data, id: leadInfoData?.data?.uuid }}
                    onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
                />
            }
            {(!userProfile?.allowed_modules?.includes('new_booking') && bookingDone) && <BookingsDoneModal
                show={bookingDone}
                setShow={setBookingDone}
                leadId={changeDataTemp.uuid}
                onSuccess={() => updateLeadData(changeDataTemp.uuid, { key: "status_id", value: changeDataTemp.value })}
            />}
            {showShareLeadAccessModal &&
                <ShareLeadAccessModal
                    show={showShareLeadAccessModal}
                    selectedItem={leadInfoData}
                    closeModal={() => {
                        setShowShareLeadAccessModal(false)
                        // setSelectedItem({})
                    }}
                    onSuccess={() => window.location.reload()}
                    allowedPermissions={allowedPermissions}
                />
            }
            {markDead && <MarkLeadDead
                show={markDead}
                setShow={setMarkDead}
                uuid={deadUuid}
                selectedOption={selectedOption}
                updateData={() => { window.location.reload() }}
                data={{ customer_uuid: customerInfoData.data.uuid, name: customerInfoData.data.name, is_re_engaged: leadInfoData.data?.is_re_engaged }}
            />}
            {showReassignModal && <ReassignLeadModal
                setShow={setShowReassignModal}
                show={showReassignModal}
                assignedTo={leadInfoData?.assignedTo?.uuid}
                handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
            />}

            {deletedSuccess && <Redirect to='/' />}
            <div className="pb-0">
                <div className="name-row">
                    <div className="row">
                        <div className="col-md-10 col-sm-10">
                            <div className="row">
                                <div className="col-md-12  d-flex flex-row align-items-center">
                                    <div className="mr-2">
                                        <StatusDropDownDynamic
                                            value={getSelectedValue(leadInfoData.status?.id, statuses)}
                                            onChange={e => handleStatusChange(e, params.id)}
                                            options={statuses}
                                            placeholder='Select Status'
                                            data={{ sitevisit_completed: leadInfoData.data.sitevisit_completed, access: leadAccess }}
                                        />
                                    </div>
                                    <IntentSelect
                                        selectedIntent={selectedIntent.value}
                                        intentOptions={intentStatuses}
                                        onChange={(e) => handleIntentChange(e, params.id)}
                                        status={getSelectedValue(leadInfoData.status?.id, statuses)}
                                        statusName={leadInfoData.status?.display_name}
                                        data={{ reason: leadInfoData.dead_reason?.reason, comment: leadInfoData.dead_lead?.comment, access: leadAccess }}
                                        tableName='AllLead'
                                    />
                                    {leadInfoData?.data?.is_re_engaged && <div className="re-engagedbtn flex items-center">
                                        {leadInfoData?.data?.re_engaged_count > 0 ? leadInfoData?.data?.re_engaged_count : ''}
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.1013 6.55114C4.93404 6.36494 4.69755 6.25561 4.44736 6.24861C4.19718 6.24176 3.95512 6.33789 3.77798 6.51454L1.58987 8.70264C1.2119 9.08045 0.999611 9.59293 0.999611 10.1273C0.999611 10.6619 1.21191 11.1743 1.58987 11.5522L3.75508 13.7196C3.90325 13.8719 4.09932 13.9687 4.31035 13.9936C4.52138 14.0185 4.73463 13.9701 4.91414 13.8566C5.1457 13.7067 5.29688 13.4602 5.32571 13.1858C5.35451 12.9116 5.25775 12.639 5.06248 12.4444L3.65482 11.0389H8.29528C9.66077 11.0399 10.9678 10.4845 11.9149 9.50078C12.862 8.51708 13.3674 7.19013 13.3146 5.82561C13.2073 3.11056 10.8894 1.00019 8.1722 1.00019H5.13997C4.90762 0.99557 4.68179 1.07721 4.50607 1.22936C4.33036 1.3815 4.21738 1.59317 4.18858 1.82378C4.16343 2.08096 4.24825 2.33655 4.42236 2.5275C4.59631 2.71832 4.84298 2.8267 5.10128 2.82543H8.29533C9.17128 2.82431 10.0093 3.18303 10.6132 3.81756C11.2171 4.45207 11.534 5.30671 11.4894 6.18151C11.4325 7.01129 11.0601 7.78762 10.4488 8.35148C9.83732 8.91535 9.03349 9.22376 8.20179 9.21374H3.65463L5.02363 7.84474H5.02347C5.19853 7.67939 5.30388 7.45356 5.31821 7.21325C5.33269 6.97294 5.25503 6.73629 5.10113 6.5512L5.1013 6.55114Z" fill="#0062FF" stroke="white" strokeWidth="0.3"></path></svg>
                                        <span>
                                            Re-Engaged
                                        </span>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-2 d-flex flex-row justify-content-end align-items-center">
                            <div className="dropdown ml-auto asign-del view-more">
                                <button className="border-0  bg-tr" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    {((ROLE_NAME === 'ADMIN' || reAssignRole?.includes('re_assign_leads')) ||
                                        (ROLE_NAME === 'Super Admin' || allowedPermissions.includes('re_assign_leads_single'))) &&
                                        <a className="dropdown-item" onClick={() => { setShowReassignModal(true) }}>
                                            Re-assign Lead
                                        </a>}

                                    {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                        checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) && <a className="dropdown-item" onClick={() => {
                                            // setSelectedItem(data)
                                            setShowShareLeadAccessModal(true)
                                        }}>
                                            Share Lead Access
                                        </a>}
                                    {(ROLE_NAME === 'ADMIN' || (ROLE_NAME === 'Super Admin' || allowedPermissions.includes('delete_leads'))) && <a className="dropdown-item del-menu" onClick={() => setShowDeleteModal(true)}>Delete Lead</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {((latestActivity?.followup && latestActivity?.followup?.schedule_date) ||
                    (latestActivity?.siteVisit && latestActivity?.siteVisit?.schedule_date)) && <LatestActivity
                        latestActivity={latestActivity}
                    />}

                {/* Tags Module */}
                <Tags leadAccess={leadAccess} userProfile={userProfile} />

            </div>

        </>
    )
}
