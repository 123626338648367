import React from 'react';
import InputSelect from './InputSelect';
import TooltipText from '../Tooltip';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import moment from 'moment';

const ReengageSourceView = ({
    infoData,
    heading
}) => {

    return (
        <>
            <div className='main-box'>
                <div className="name-box">
                    <div className="row">
                        <div className="col-md-10 col-10">
                            <h3 className="fz16 mb-6">{heading}</h3>
                            <div className='tag-show d-flex align-items-center'>
                                <h4 className="fz16 fz16 mb-0 truncate ...">{infoData?.length ? infoData[0].source_name : '-'}</h4>
                                <div className="dropdown tag-dropdown view-more">
                                    {
                                        infoData?.length > 1 ?
                                            <>
                                                <button className="border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    +{infoData?.length - 1}
                                                </button>
                                                <div className="dropdown-menu max-w-[300px] !w-[300px] max-h-[250px] overflow-auto" aria-labelledby="dropdownMenuButton">
                                                    <div className='flex justify-between'>
                                                        <h4 className='text-base font-medium'>Reengaged Source</h4>
                                                        <button>
                                                            <CloseIcon className='h-4 w-6' />
                                                        </button>
                                                    </div>
                                                    {
                                                        infoData.map((item, index) => {
                                                            return <div className='!mt-3 border-b border-grayLight !pb-2'>
                                                                <a key={index} className="m-0 pl-0 !text-black700 !font-semibold dropdown-item truncate ..." >{item.source_name}
                                                                    {/* {index == 0 ? <span className='drop-tag'>Primary</span> : ''} */}
                                                                </a>
                                                                <p className='m-0 text-xs flex justify-end text-black700'>{moment(item.created_on).format('h:mm a, Do MMM YYYY')}</p>
                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            </>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReengageSourceView;