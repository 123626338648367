import React from 'react'

const SuccesDataImport = ({ closeModal, getAllDataJobs }) => {
    return (
        <div className="import-complete d-flex flex-column align-items-center justify-content-center">
            <figure className='d-table mx-auto'><svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="32" cy="32" r="32" fill="#32BA7C" />
                <path d="M31.9998 50.3334C42.1251 50.3334 50.3332 42.1252 50.3332 32C50.3332 21.8748 42.1251 13.6667 31.9998 13.6667C21.8746 13.6667 13.6665 21.8748 13.6665 32C13.6665 42.1252 21.8746 50.3334 31.9998 50.3334Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32 21V32L39.3333 35.6667" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            </figure>
            <h2 className='fz18 black fw-medium text-center mb-12'>CSV Import is in Progress!</h2>
            <p className='fz14 worksans black-dark-700 text-center'>Your leads are being imported safely into Propacity Platform
                <span className='d-md-block'>
                    Don’t worry, we will send you an update once it’s done.
                    You can click on done and continue with your work.
                </span>
            </p>

            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">

                <button className='pr-btn ml-8' onClick={() => {
                    localStorage.removeItem('jobId');
                    getAllDataJobs();
                    closeModal();
                }}>Done</button>
            </div>

        </div>
    )
}

export default SuccesDataImport;