import React from 'react'
import { Link } from 'react-router-dom'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getCustomVLineClass } from '../../../utils/getCustomVLineClass'

export default function LeadName({
    data
}) {
    return (
        <Link
            to={`leads/${data?.id}`}
            target="_blank"
            className={`v-line name ${!data.status ? 'new-lead' : ''} border-0 text-capitalize`}
        >
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip" >
                        <div className='info-msg'>
                            {/* <h4>Total Leads</h4> */}
                            <p className='text-capitalize'>{data?.name}</p>
                        </div>
                    </Tooltip>
                }
            >
                <span className='lead_name'> {data?.name ? data?.name : '-'}</span>
            </OverlayTrigger>

            {data.is_re_engaged &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="button-tooltip" >
                            <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p>Re-Engaged</p>
                            </div>
                        </Tooltip>
                    }
                >
                    <span className='ml-2 inline-flex bg-blueHighlight rounded-lg px-1'>
                        {data?.re_engaged_count > 0 && <span className='mr-1 text-primary'>{data?.re_engaged_count}</span>}
                        <svg className='mt-1 leadnametooltip' width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.1013 6.55114C4.93404 6.36494 4.69755 6.25561 4.44736 6.24861C4.19718 6.24176 3.95512 6.33789 3.77798 6.51454L1.58987 8.70264C1.2119 9.08045 0.999611 9.59293 0.999611 10.1273C0.999611 10.6619 1.21191 11.1743 1.58987 11.5522L3.75508 13.7196C3.90325 13.8719 4.09932 13.9687 4.31035 13.9936C4.52138 14.0185 4.73463 13.9701 4.91414 13.8566C5.1457 13.7067 5.29688 13.4602 5.32571 13.1858C5.35451 12.9116 5.25775 12.639 5.06248 12.4444L3.65482 11.0389H8.29528C9.66077 11.0399 10.9678 10.4845 11.9149 9.50078C12.862 8.51708 13.3674 7.19013 13.3146 5.82561C13.2073 3.11056 10.8894 1.00019 8.1722 1.00019H5.13997C4.90762 0.99557 4.68179 1.07721 4.50607 1.22936C4.33036 1.3815 4.21738 1.59317 4.18858 1.82378C4.16343 2.08096 4.24825 2.33655 4.42236 2.5275C4.59631 2.71832 4.84298 2.8267 5.10128 2.82543H8.29533C9.17128 2.82431 10.0093 3.18303 10.6132 3.81756C11.2171 4.45207 11.534 5.30671 11.4894 6.18151C11.4325 7.01129 11.0601 7.78762 10.4488 8.35148C9.83732 8.91535 9.03349 9.22376 8.20179 9.21374H3.65463L5.02363 7.84474H5.02347C5.19853 7.67939 5.30388 7.45356 5.31821 7.21325C5.33269 6.97294 5.25503 6.73629 5.10113 6.5512L5.1013 6.55114Z" fill="#0062FF" stroke="white" strokeWidth="0.3" />
                        </svg>
                    </span>
                </OverlayTrigger>
            }
        </Link>
    )
}