import React, { useCallback, useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner';
import { Image, Modal } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import style from './style.module.css';
import './style.css'
import LaunchingTodaySlider from './LaunchingTodaySlider';
import searchIcon from '../../assets/icons/search.svg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import leftrLaunch from './leftrLaunch.svg'
import rightLaunch from './rightLaunch.svg'
import lpleft from '../../assets/lpLeft.svg'
import lpright from '../../assets/lpLright.svg'
import LPFilter from './LPFilter';
import ReserveSeatSidebar from './ReserveSeatSidebar';
import KYCModal from './KYCModal';
import { eventRegisteration, getAllDevelopers, getAllLaunchPad, getReraStatus } from '../../services/public/public.service';
import { getCities } from '../../services/private/csv.service';
import UpcomingCard from './UpcomingCard';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchicon.svg';
import { ReactComponent as SearchCloseIcon } from '../../assets/searchClose.svg';
import { ReactComponent as FilterDownIcon } from '../../assets/filterDown.svg';
import CustomDropdown from '../../components/custom dropdown';
import { useRef } from 'react';
import { calenderFilterItems, property_type } from '../../utils/constants';
import InputSelect from '../../components/InputGroup/InputSelect';
import { dateRange } from '../../helpers/helpers';
import { Link } from 'react-router-dom';
import Projects from './Project';
import { toast } from 'react-toastify';
import { ReactComponent as LaunchPadLogoIcon } from '../../assets/icons/lunchpadLogo.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import FilterProjectsModal from '../../components/Projects/Modal/FilterProjectsModal';

const EVENT_TYPE = [
    {
        label: 'Online',
        value: 'online'
    },
    {
        label: 'Offline',
        value: 'offline'
    }
]

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: state.hasValue ? '#0062ff' : '#DEDEDE',
        width: '145px',
        backgroundColor: state.hasValue ? "#f0f6ff" : 'white',
        minHeight: '38px',
        borderRadius: '6px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:hover': { borderColor: '#44444f' },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.hasValue ? "#0062ff" : '#44444f',
        fontWeight: '500'
    }),
    menu: base => ({
        ...base,
        marginTop: 5,
        marginBottom: '20px',
        paddingTop: 0,
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#44444f',
            fontWeight: '500'
        }
    },
    option: (styles, state) => ({
        ...styles,
        fontSize: '12px',
        fontWeight: '500',
        color: "#44444f",
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f8f9fa" : styles.color,
        "&:hover": {
            color: "#44444F",
            backgroundColor: "#f8f9fa"
        }
    }),
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    // multiValueLabel: (styles, { data }) => ({
    //     ...styles,
    //     color: data.color,
    // }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: data.color,
        },
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: state.hasValue ? '#0062ff' : "#44444F" // Custom colour
    })
};

const difference = (a, b) => {
    const result = [];
    for (const obj of a) {
        if (!b.some(bObj => bObj.value === obj.value)) {
            result.push(obj);
        }
    }
    return result;
}

const ProjectLaunchpad = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [openKyc, setOpenKyc] = useState(true);
    const [todaysLaunch, setTodaysLaunch] = useState([])
    const [launchedProject, setLaunchedProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [kycCompleted, setKycCompleted] = useState('');
    const [cityList, setCityList] = useState([]);
    const [propertyList, setPropertyList] = useState(property_type);
    const [searchText, setSearchText] = useState('')
    const [isSticky, setIsSticky] = useState(false);
    const [initialLuanchedList, setInitialLaunchedList] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [registeredEvents, setRegisteredEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openCityDropdown, setOpenCityDropdown] = useState(false);
    const [openEventDropdown, setOpenEventDropdown] = useState(false);
    const [openPropertyDropdown, setOpenPropertyDropdown] = useState(false);
    const [openDeveloperDropdown, setOpenDeveloperDropdown] = useState(false);
    const [city, setCity] = useState([]);
    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState([]);
    const [selectedDeveloper, setSelectedDeveloper] = useState([]);
    const [eventList, setEventList] = useState(EVENT_TYPE);
    const [isReraVerified, setIsReraVerified] = useState(false);
    const [developerList, setDeveloperList] = useState([]);
    const [citySearchText, setCitySearchText] = useState('');
    const [calenderDateRage, setCalenderDateRage] = useState(calenderFilterItems);
    const [selectedDate, setSelectedDate] = useState('');
    const [showDateModal, setShowDateModal] = useState(false);
    const [tab, setTab] = useState('Launch');
    const [initial, setInitial] = useState(true);
    const [allEvents, setAllevents] = useState();
    const [showfilter, setShowfilter] = useState(false);
    const [filters, setFilters] = useState([]);

    const eventRef = useRef();
    const cityRef = useRef();
    const propertyRef = useRef();
    const developerRef = useRef();

    const handleScroll = () => {
        const offset = window.pageYOffset;
        setIsSticky(offset > 50); // Update the state based on scroll position
    };


    // close filters dropdown on outside click
    const handleOutsideClick = (e) => {
        if (eventRef.current && !eventRef.current.contains(e.target)) {
            setOpenEventDropdown(false)
        }
        if (cityRef.current && !cityRef.current.contains(e.target)) {
            setOpenCityDropdown(false)
        }
        if (propertyRef.current && !propertyRef.current.contains(e.target)) {
            setOpenPropertyDropdown(false)
        }
        if (developerRef.current && !developerRef.current.contains(e.target)) {
            setOpenDeveloperDropdown(false)
        }
    }

    const getSearchedCity = () => {
        getCities(citySearchText)
            .then((res) => {
                if (res.data.status === 200) {
                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    setCityList(cities);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getAllLaunchpadDevelopers = async () => {
        const res = await getAllDevelopers();
        if (res.status === 200) {
            const developers = res.data.data.map(item => ({ label: item.name, value: item.uuid }));
            setDeveloperList(developers);
        }
    }

    const handleUpdateFilter = useCallback((city, property) => {
        if (city.length > 0) setCity(city);
        if (property.length > 0) setSelectedPropertyType(property);
    }, []);

    useEffect(() => {
        getSearchedCity();
    }, [citySearchText])

    // Attach scroll event listener
    useEffect(() => {
        getAllLaunchpadDevelopers();
        if (localStorage.getItem('city') && localStorage.getItem('propertyType')) {
            setOpenKyc(false);
        }

        if (localStorage.getItem('propertyType')) {
            let selectedType = [];
            const props = localStorage.getItem('propertyType')?.split(',');
            props.forEach(item => {
                propertyList.forEach(type => {
                    if (type.label === item) {
                        selectedType.push(type);
                    }
                });
            })
            setSelectedPropertyType(selectedType);
            setPropertyList(difference(propertyList, selectedType))
        }

        if (localStorage.getItem('city')) {
            let city = '';
            city = localStorage.getItem('city')?.split(',')?.map(item => ({ label: item, value: item }));
            setCity(city)
        }

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleOutsideClick)
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleOutsideClick)
        };
    }, []);


    const getAllLaunchPadProject = async () => {
        // this is to restrict initial blanck api call if city and property type available        
        if (initial && !allEvents) {
            if (localStorage.getItem('city') && localStorage.getItem('propertyType')) {
                if (!selectedPropertyType.length && !city.length) {
                    return;
                }
            }
        }
        const payload = {
            searchText: searchText,
            city: city.length > 0 ? city.map(item => item.value) : null,
            property_type: selectedPropertyType.length > 0 ? selectedPropertyType.map(item => item.label) : null,
            developers: selectedDeveloper.length > 0 ? selectedDeveloper.map(item => item.value) : null,
            type: selectedEvent.length > 0 ? selectedEvent.map(item => item.value) : null,
            ...dateRange[selectedDate.value]
        }

        const res = await getAllLaunchPad(payload);
        setLoading(false);
        setInitial(false);
        if (res.status === 200) {
            setAllevents(res.data.data);
            setTodaysLaunch(res.data.data.todayEvents);
            setRegisteredEvents(res.data.data.registeredEvents);
            setPastEvents(res.data.data.pastEvents)
            const splited = res.data.data.upcomingEvents.splice(0, 9);
            setUpcomingEvents(splited);
            setInitialLaunchedList(res.data.data.upcomingEvents)
        }
    }

    useEffect(() => {
        if (tab === 'Launch') {
            getAllLaunchPadProject()
        }
    }, [searchText, city, selectedPropertyType, selectedEvent, selectedDeveloper, selectedDate])

    const handleEventRegistration = async (uuid) => {
        const response = await eventRegisteration(uuid);
        if (response.status === 200) {
            setIsReraVerified(true);
            getAllLaunchPadProject();
            setShowSidebar(true);
            toast.success('Seat Reserved successfully.');
        }

    };


    const handleGetReraStatus = async (item) => {
        setSelectedProject(item);
        if (!item.registered_by) {
            const response = await getReraStatus({ state: item.state });
            if (response.status === 200) {
                if (!response.data.data.rera) {
                    setShowSidebar(true);
                } else {
                    handleEventRegistration(item.uuid);
                }
            }
        } else {
            setShowSidebar(true);
        }
    };

    const handleLoadMore = () => {
        let newList = [...initialLuanchedList];
        const spliced = newList.splice(0, 9);
        setUpcomingEvents([...upcomingEvents, ...spliced]);
        setInitialLaunchedList(newList);
    }
    const handleSearchChange = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    const handleClearSearch = useCallback(() => {
        setSearchText('');
    }, []);

    const closeSidebar = () => {
        setShowSidebar(false)
        setIsReraVerified(false)
    }

    const showKycModal = () => {
        setOpenKyc(true)
    }

    const closeKycModal = () => {
        setOpenKyc(false)
    }

    const handleToggleEventDropdown = useCallback(() => {
        setOpenEventDropdown(!openEventDropdown)
        setOpenCityDropdown(false);
        setOpenDeveloperDropdown(false);
        setOpenPropertyDropdown(false);
    }, [openEventDropdown]);

    const handleToggleCityDropdown = useCallback(() => {
        setOpenCityDropdown(!openCityDropdown);
        setOpenEventDropdown(false)
        setOpenDeveloperDropdown(false);
        setOpenPropertyDropdown(false);
    }, [openCityDropdown]);

    const handleToggleDeveloperDropdown = useCallback(() => {
        setOpenDeveloperDropdown(!openDeveloperDropdown);
        setOpenEventDropdown(false);
        setOpenCityDropdown(false);
        setOpenPropertyDropdown(false);
    }, [openDeveloperDropdown]);

    const handleTogglePropertyDropdown = useCallback(() => {
        setOpenPropertyDropdown(!openPropertyDropdown);
        setOpenEventDropdown(false)
        setOpenCityDropdown(false);
        setOpenDeveloperDropdown(false);
    }, [openPropertyDropdown]);

    const handleChangeCity = useCallback((cityVal) => {
        const index = city.findIndex(item => item.value === cityVal.value)
        if (index < 0) {
            let cities = cityList.filter(item => item.value != cityVal.value);
            setCity([...city, cityVal]);
            setCityList(cities)
        } else {
            let cities = city.filter(item => item.value != cityVal.value);
            setCity(cities);
            setCityList([...cityList, cityVal])
        }
    }, [city, cityList]);

    const handleChangeProperty = useCallback((propertyVal) => {
        const index = selectedPropertyType.findIndex(item => item.value === propertyVal.value);
        if (index < 0) {
            let properties = propertyList.filter(item => item.value != propertyVal.value);
            setSelectedPropertyType([...selectedPropertyType, propertyVal]);
            setPropertyList(properties)
        } else {
            let properties = selectedPropertyType.filter(item => item.value != propertyVal.value);
            setSelectedPropertyType(properties);
            setPropertyList([...propertyList, propertyVal])
        }
    }, [propertyList, selectedPropertyType]);

    const handleChangeEvent = useCallback((eventVal) => {
        const index = selectedEvent.findIndex(item => item.value === eventVal.value);
        if (index < 0) {
            let event = eventList.filter(item => item.value != eventVal.value);
            setSelectedEvent([...selectedEvent, eventVal]);
            setEventList(event);
        } else {
            let event = selectedEvent.filter(item => item.value != eventVal.value);
            setSelectedEvent(event);
            setEventList([...eventList, eventVal]);
        }
    }, [selectedEvent, eventList]);

    const handleChangeDeveloper = useCallback((developerVal) => {
        const index = selectedDeveloper.findIndex(item => item.value === developerVal.value);
        if (index < 0) {
            let developer = developerList.filter(item => item.value != developerVal.value);
            setSelectedDeveloper([...selectedDeveloper, developerVal]);
            setDeveloperList(developer)
        } else {
            let developer = selectedDeveloper.filter(item => item.value != developerVal.value);
            setSelectedDeveloper(developer);
            setDeveloperList([...developerList, developerVal]);
        }
    }, [selectedDeveloper, developerList]);

    const handleClearCity = useCallback(() => {
        setCityList([...cityList, ...city]);
        setCity([]);
    }, [city, cityList]);

    const handleClearEvent = useCallback(() => {
        setEventList([...eventList, ...selectedEvent]);
        setSelectedEvent([]);
    }, [eventList, selectedEvent]);

    const handleClearProperty = useCallback(() => {
        setPropertyList([...propertyList, ...selectedPropertyType]);
        setSelectedPropertyType([]);
    }, [propertyList, selectedPropertyType]);

    const handleClearDevelopers = useCallback(() => {
        setDeveloperList([...developerList, ...selectedDeveloper]);
        setSelectedDeveloper([]);
    }, [selectedDeveloper, developerList]);

    const handleApplyFilters = useCallback((filtersArr) => {
        setFilters(filtersArr);
        setShowfilter(false)
    }, [])

    return (
        <>
            <div className={`${style.lucnchpad} main-section`}>

                <div className={`${style.lunchtoday}`}>
                    <div className={`${style.lucnchpadHeadermain} mb-18`}>
                        <div className={`${style.lucnchpadHeader} mb-18`}>
                            <div className={`${style.w_50}`}>
                                <h2 className='fz24 black h-font fw-po-medium'>Launchpad</h2>
                            </div>
                            <div className={`${style.w_50} d-flex justify-content-end`}>
                                <div className={`${tab === 'Project' ? 'justify-content-end' : ''} an-title d-flex align-items-center flex-fill lpFilterarea`}>
                                    {tab === 'Launch' && <div className='download-btn-group d-flex align-items-center flex-fill flex-nowrap'>
                                        <CustomDropdown
                                            ref={window.innerWidth > 768 ? eventRef : null} // removing ref for mobile device (using ref to dismis dropdown outside click)
                                            show={openEventDropdown}
                                            toggleDropdown={handleToggleEventDropdown}
                                            label='Event Type'
                                            dropdownItems={eventList}
                                            selectedItems={selectedEvent}
                                            handleClearAll={handleClearEvent}
                                            handleChange={handleChangeEvent}
                                        />
                                        <CustomDropdown
                                            ref={window.innerWidth > 768 ? cityRef : null} // removing ref for mobile device (using ref to dismis dropdown outside click)
                                            show={openCityDropdown}
                                            toggleDropdown={handleToggleCityDropdown}
                                            label='City'
                                            dropdownItems={cityList}
                                            searchText={citySearchText}
                                            handleSearchClear={() => setCitySearchText('')}
                                            handleSearchOnChange={(e) => setCitySearchText(e.target.value)}
                                            selectedItems={city}
                                            handleClearAll={handleClearCity}
                                            handleChange={handleChangeCity}
                                            searchEnable={true}
                                        />
                                        <CustomDropdown
                                            ref={window.innerWidth > 768 ? propertyRef : null} // removing ref for mobile device (using ref to dismis dropdown outside click)
                                            show={openPropertyDropdown}
                                            toggleDropdown={handleTogglePropertyDropdown}
                                            label='Property Type'
                                            dropdownItems={propertyList}
                                            selectedItems={selectedPropertyType}
                                            handleClearAll={handleClearProperty}
                                            handleChange={handleChangeProperty}
                                        />
                                        <CustomDropdown
                                            ref={window.innerWidth > 768 ? developerRef : null} // removing ref for mobile device (using ref to dismis dropdown outside click)
                                            show={openDeveloperDropdown}
                                            toggleDropdown={handleToggleDeveloperDropdown}
                                            label='Developers'
                                            dropdownItems={developerList}
                                            selectedItems={selectedDeveloper}
                                            handleClearAll={handleClearDevelopers}
                                            handleChange={handleChangeDeveloper}
                                        />

                                        <div className='assigned-drop  d-md-inline d-none'>
                                            <InputSelect
                                                options={calenderDateRage}
                                                value={selectedDate}
                                                placeholder={'Date'}
                                                onChange={(value) => setSelectedDate(value)}
                                                styles={customStyles}

                                            />
                                        </div>
                                        <div className='d-md-none mobselectdate' onClick={() => setShowDateModal(true)}>
                                            {selectedDate.label || 'Date'} <FilterDownIcon />
                                        </div>
                                        {(selectedEvent.length > 0 ||
                                            selectedDate ||
                                            selectedDeveloper.length > 0 ||
                                            selectedPropertyType.length > 0 ||
                                            city.length > 0) &&
                                            <button className={`${style.clearbtn}`} onClick={() => {
                                                handleClearCity();
                                                handleClearDevelopers();
                                                handleClearEvent();
                                                handleClearProperty();
                                                setSelectedDate('');
                                            }}>Clear All</button>}
                                    </div>}
                                    <div className={`${style.lpSearch}`}>
                                        <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                            <div className="serch-icon">
                                                <Image src={searchIcon} />
                                            </div>
                                            <input type="text" placeholder="Search" className="searchinput" value={searchText} onChange={(e) => handleSearchChange(e)} />
                                            {searchText &&
                                                <div className="close-icon" onClick={handleClearSearch}>
                                                    <SearchCloseIcon />
                                                </div>}

                                        </div>
                                    </div>
                                    {tab === 'Project' && <div>
                                        <button
                                            className={`${style.filter_btn} ${Object.keys(filters).length > 0 ? style.filter_btn_active : ''} inter fz14 fw-po-medium black-dark-700 inline-flex items-center`}
                                            onClick={() => setShowfilter(true)}
                                        >
                                            <FilterIcon />
                                            Filter
                                        </button>

                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className={`${style.lptabgrp}`}>
                            <a className={`${style.lptagNav} ${tab === 'Launch' && style.active}`} onClick={() => setTab('Launch')}>Launches</a>
                            <a className={`${style.lptagNav} ${tab === 'Project' && style.active}`} onClick={() => setTab('Project')}>Projects</a>
                        </div>

                        {/* {kycCompleted === 'not_verified' && <div className={`${style.w_50} d-flex`}>
                            <div className={`${style.kyc_block} d-inline-flex ml-auto align-items-center`}>
                                <div className={`${style.kyctext} fz14 black`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <g clipPath="url(#clip0_38_10455)">
                                            <path d="M11 16.5C11.3117 16.5 11.5731 16.3944 11.7843 16.1832C11.9955 15.972 12.1007 15.7109 12.1 15.4C12.1 15.0883 11.9944 14.8269 11.7832 14.6157C11.572 14.4045 11.3109 14.2993 11 14.3C10.6883 14.3 10.4269 14.4056 10.2157 14.6168C10.0045 14.828 9.89927 15.0891 9.9 15.4C9.9 15.7117 10.0056 15.9731 10.2168 16.1843C10.428 16.3955 10.6891 16.5007 11 16.5ZM9.9 12.1H12.1V5.5H9.9V12.1ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5553 4.2075 19.7718 3.2175 18.7825C2.2275 17.7925 1.44393 16.6283 0.8668 15.29C0.289667 13.9517 0.000733333 12.5217 0 11C0 9.47833 0.288933 8.04833 0.8668 6.71C1.44467 5.37167 2.22823 4.2075 3.2175 3.2175C4.2075 2.2275 5.37167 1.44393 6.71 0.8668C8.04833 0.289667 9.47833 0.000733333 11 0C12.5217 0 13.9517 0.288933 15.29 0.8668C16.6283 1.44467 17.7925 2.22823 18.7825 3.2175C19.7725 4.2075 20.5564 5.37167 21.1343 6.71C21.7122 8.04833 22.0007 9.47833 22 11C22 12.5217 21.7111 13.9517 21.1332 15.29C20.5553 16.6283 19.7718 17.7925 18.7825 18.7825C17.7925 19.7725 16.6283 20.5564 15.29 21.1343C13.9517 21.7122 12.5217 22.0007 11 22Z" fill="#0062FF" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_38_10455">
                                                <rect width="22" height="22" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Your KYC is still incomplete</div>
                                <div><button className={`${style.kycbtn} pr-btn`} onClick={() => setOpenKyc(true)} >Complete KYC</button></div>
                            </div>
                        </div>} */}
                    </div>

                    {tab === 'Project' && <Projects style={style} filters={filters} tab={tab} searchText={searchText} />}
                    {/* <div className={`LPfiltersticky ${isSticky ? ' sticky' : ''}`}>
                        <LPFilter
                            style={style}
                            loadOptions={loadOptions}
                            propertyList={propertyList}
                            handleChangePropertyType={handleChangePropertyType}
                            handleChangeCity={handleChangeCity}
                            handleClearSearch={handleClearSearch}
                            handleSearchChange={handleSearchChange}
                            searchText={searchText}
                            city={city}
                            propertyType={propertyType}
                        />
                    </div> */}
                    {tab === 'Launch' && <>
                        {todaysLaunch.length > 0 && <>
                            <h1 className={`${style.lanuchHead} fz18 black fw-po-semi inter`}>Launching Today</h1>
                            <div className={`${style.lunchtodyaOwl} lunchtodyaOwl d-md-block d-none`}>
                                <OwlCarousel
                                    className='owl-theme owl-launch vertical-carousel'
                                    dots={true}
                                    res='true'
                                    loop={true}
                                    autoplay={false}
                                    nav={true}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    slideBy={1}
                                    navText={[`<img src=${lpleft} />`, `<img src=${lpright} />`]}
                                    responsive={{
                                        0: {
                                            items: 1,

                                        }
                                    }}
                                >
                                    {todaysLaunch.map((item, index) => (
                                        <div key={`${item.uuid}_${index}`}>
                                            <LaunchingTodaySlider item={item} style={style} opensidebar={() => setShowSidebar(true)} handleSendOTP={handleGetReraStatus} />
                                        </div>
                                    ))}

                                </OwlCarousel>
                            </div>
                            <div className={`${style.lunchtodyaOwl} lunchtodyaOwl d-md-none`}>
                                <OwlCarousel
                                    className='owl-theme owl-launch vertical-carousel'
                                    dots={false}
                                    res='true'
                                    loop={true}
                                    autoplay={false}
                                    nav={false}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    // autoWidth={true}
                                    slideBy={1}
                                    margin={10}
                                    // navText={[`<img src=${lpleft} />`, `<img src=${lpright} />`]}
                                    responsive={{
                                        0: {
                                            items: 1.05,
                                            autoWidth: true,

                                        },
                                        600: {
                                            items: 2,
                                            autoWidth: true,

                                        }
                                    }}
                                >
                                    {todaysLaunch.map((item, index) => (
                                        <div key={`${item.uuid}_${index}`}>
                                            <UpcomingCard item={item} style={style} opensidebar={() => setShowSidebar(true)} handleSendOTP={handleGetReraStatus} />
                                            {/* <LaunchingTodaySlider item={item} style={style} opensidebar={() => setShowSidebar(true)} handleSendOTP={handleSendOTP} /> */}
                                        </div>
                                    ))}

                                </OwlCarousel>
                            </div>
                        </>}
                        <div>
                            {registeredEvents.length > 0 && <div className={`${style.registerdEventes} registerdEventes`}>
                                <h1 className={`${style.registerdEventesTitle} inter`}>Registered Events</h1>

                                <OwlCarousel
                                    className='owl-theme owl-launch vertical-carousel'
                                    dots={false}
                                    res='true'
                                    loop={registeredEvents.length > 3}
                                    autoplay={false}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    autoWidth={true}
                                    slideBy={1}
                                    margin={30}
                                    navText={[`<img src=${lpleft} />`, `<img src=${lpright} />`]}
                                    responsive={{
                                        0: {
                                            items: 1.2,
                                            nav: false,
                                            autoWidth: false,
                                            margin: 10

                                        },
                                        600: {
                                            items: 3,
                                            nav: true,
                                            autoWidth: true,
                                            margin: 30
                                        }
                                    }}
                                >
                                    {
                                        registeredEvents.map((item, index) => (
                                            <div key={`${item.uuid}_${index}`} className='positoin-relative'>
                                                <div onClick={() => handleGetReraStatus(item)} className={`${style.openmodalinMobile}`}></div>
                                                <UpcomingCard style={style} item={item} handleSendOTP={handleGetReraStatus} />
                                            </div>
                                        ))
                                    }

                                </OwlCarousel>
                            </div>}
                        </div>

                        {upcomingEvents.length > 0 && <div className={`${style.upcominglunchessection}`}>
                            <h1 className={`${style.upcomingtitle} inter`}>Upcoming Launches</h1>
                            <div className={`${style.upcomingLunces}`}>
                                {
                                    upcomingEvents.map((item, index) => (
                                        <UpcomingCard style={style} item={item} key={`${item.uuid}_${index}`} handleSendOTP={handleGetReraStatus} />
                                    ))
                                }
                            </div>
                        </div>
                        }
                        {upcomingEvents.length > 9 && <div className={`${style.loadmore}`}>
                            <button className={`${style.loadmorebtn}`} onClick={handleLoadMore}>Load more</button>
                        </div>}
                        <div>
                            {pastEvents.length > 0 && <div className={`${style.registerdEventes} ${style.pastEvents} registerdEventes past-events`}>
                                <h1 className={`${style.registerdEventesTitle} inter`}>Past Events</h1>
                                <OwlCarousel
                                    className='owl-theme owl-launch vertical-carousel'
                                    dots={false}
                                    res='true'
                                    loop={pastEvents.length > 3}
                                    autoplay={false}
                                    nav={true}
                                    mouseDrag={true}
                                    touchDrag={true}
                                    autoWidth={true}
                                    slideBy={1}
                                    margin={30}
                                    navText={[`<img src=${lpleft} />`, `<img src=${lpright} />`]}
                                    responsive={{
                                        0: {
                                            items: 1.3,
                                            nav: false,
                                            autoWidth: false,
                                            margin: 10

                                        },
                                        600: {
                                            items: 3,
                                            nav: true,
                                            autoWidth: true,
                                            margin: 30
                                        }
                                    }}
                                >
                                    {
                                        pastEvents.map((item, index) => (
                                            <div key={`${item.uuid}_${index}`}>
                                                <UpcomingCard style={style} item={item} handleSendOTP={handleGetReraStatus} isPast={true} />
                                            </div>
                                        ))
                                    }

                                </OwlCarousel>
                            </div>}
                        </div>

                        {(upcomingEvents.length === 0 && pastEvents.length === 0 && todaysLaunch.length === 0 && registeredEvents.length === 0) && <>
                            {!loading && <div className='d-flex justify-content-center flex-column align-items-center lpnofound px-sm-0 px-4'>
                                <SearchIcon />
                                {(searchText || city.length > 0 || selectedPropertyType.length > 0 || selectedEvent.length > 0 || selectedDeveloper.length > 0 || selectedDate) ? <> <h4 className='po black fz16 fw-po-mediumm mb-8'>Didn’t find any matches </h4>
                                    {/* <p className='text-center black-dark-600 fz14 mb-0'>We're sorry, but we couldn't find any results for your search.
                                    <span className='d-md-block'> Please try again with different keywords.</span></p> */}
                                    <p className='text-center black-dark-600 fz14 mb-0'>Apologies, but we couldn't find any results matching your filters. <span className='d-md-block'>Please consider adjusting your filters and try again for better results.</span></p>
                                </>
                                    :
                                    <h4 className='po black fz16 fw-po-medium mb-8 inter'>No Events Found.</h4>
                                }
                            </div>
                            }
                            {loading && <div className='d-flex justify-content-center flex-column align-items-center lpnofound'>
                                <Oval
                                    height={50}
                                    width={50}
                                    color="#0062FF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#0062FF"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}
                                />
                            </div>}
                        </>}
                    </>}
                </div>
            </div >
            {showSidebar && <ReserveSeatSidebar
                style={style}
                close={closeSidebar}
                show={showSidebar}
                selectedProject={selectedProject}
                handleResendOTP={handleGetReraStatus}
                getAllLaunchPadProject={getAllLaunchPadProject}
                handleEventRegistration={handleEventRegistration}
                isReraVerified={isReraVerified}
            />
            }
            {openKyc && <KYCModal
                style={style}
                close={closeKycModal}
                show={openKyc}
                handleUpdateFilter={handleUpdateFilter}
            // getKycStatus={getKycStatus} 
            />}
            <Modal show={showDateModal} backdropClassName='filtermodalback' className='FilterModal'>
                <div className='d-flex justify-content-between align-items-center flex-row FilterModalHeader'>
                    <h4>
                        Select Date
                    </h4>
                    <a onClick={() => setShowDateModal(false)} className='cursor-pointer bg-tr border-0'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#44444f"></path></svg></a>
                </div>


                <div className='input-list filterdateModal'>
                    {
                        calenderDateRage.map((item, index) => (
                            <div className='dropdown-item' key={`${index}_${item.value}`} onClick={() => {
                                setSelectedDate(item);
                                setShowDateModal(false);
                            }}>
                                {item.label}
                            </div>
                        ))
                    }
                </div>
                <div className="aply-btn">
                    <button className='outline-btn d-block' onClick={() => setSelectedDate('')} >Clear</button>
                    <button className='pr-btn d-block' onClick={() => setShowDateModal(false)} >Apply Now</button>
                </div>
            </Modal>

            <FilterProjectsModal show={showfilter} closeModal={() => setShowfilter(false)} style={style} handleApplyFilters={handleApplyFilters} />
        </>
    )
}

export default ProjectLaunchpad;