import React, { useCallback, useContext, useEffect, useState } from "react";
import DynamicEditinput from "../../../../../components/InputGroup/DynamicEditInput";
import { getCitiesFromState } from "../../../../../services/public/public.service";
import CampaignInfoModal from "../Modal/CampaignInfoModal";
import { getAdAnalytics } from "../../../../../services/private/leads.service";
import Consumer from '../../../../../helpers/context';
import { useParams } from "react-router-dom";

export default function CampaignInfoTab(props) {

    const [cities, setCities] = useState([]);
    const [showCampaignInfoModal, setShowCampaignInfoModal] = useState(false);
    const [analyticsData, setAnalyticsData] = useState([]);

    const params = useParams();
    const { userProfile } = useContext(Consumer);


    useEffect(() => {
        const loadCities = () => {
            getCitiesFromState(props.customerInfoData.data?.state).then((res) => {
                if (res.status === 200) {
                    const cities = res.data.data.map((data) => {
                        return {
                            label: data.name,
                            value: data.name,
                        };
                    });
                    setCities(cities);
                }
            });
        }
        if (props.customerInfoData.data?.state) loadCities()
    }, [props.customerInfoData.data?.state])

    const selectedValue = useCallback((dropdownList, valueToFind) => {
        const city = dropdownList.find((d) => d.value === valueToFind)
        return city?.label;
    }, [])

    const handleGetCampaignInfo = async () => {
        const res = await getAdAnalytics(params?.id);
        if (res.data.status === 200) {
            console.log(res.data.data.data)
            setAnalyticsData(res.data.data.data)
            setShowCampaignInfoModal(true)

        }
    }

    return (
        <>
            <div className="tab-pane fade" id="campaign" role="tabpanel" aria-labelledby="campaign-tab">
                <div className={props.currentPage == 1 ? '' : 'd-none'}>
                    <div className="row mb-24">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Medium"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.medium || 'Facebook Ads'}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Campaign Name"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.name || props.campaignInfo?.campaign_name}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Adset Name"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.adset || props.campaignInfo?.ad_set_name}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Form Name"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.form || props.campaignInfo?.form_name}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Ad Name"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.ad || props.campaignInfo?.ad_name}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Landing Page URL"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.landing_page}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Form Id"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={props.campaignInfo?.form_id}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="City"
                                editActive={false}
                                isEditable={false}
                                enableEditing={false}
                                value={selectedValue(cities, props.customerInfoData.data?.city)}
                            />

                            {/* <DynamicDropdown
                                inputKey="city"
                                heading="City"
                                editActive={editActive.city}
                                setEditActive={(activeState) => handleEditActive("city", activeState)}
                                isEditable={true}
                                enableEditing={false}
                                // defaultValue={customerInfoData.data?.state}
                                // defaultLabel="Delhi"
                                // onClickHandler={() => { }}
                                optionConfig={cities}
                                onEditClick={() => handleEditActive("city", true)}
                                editDeactive={() => handleEditActive("city", false)}
                                value={selectedValue(cities, customerInfoData.data?.city)}
                                onValueChange={(value) => handleValueChange("city", value.value)}
                                onSave={() => {
                                    updateCustomerInfo("city", customerInfoData.data?.city)
                                    handleEditActive("city", false)
                                }}
                            /> */}
                        </div>
                        {userProfile?.company_id === 1 && <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className=" hover:bg-[#F7F7F7] cursor-pointer">
                                <div className="form-group mb-0">
                                    <div className="row">
                                        <div className="w-100 !py-5 ">
                                            <button className="w-100" onClick={() =>
                                                handleGetCampaignInfo()
                                                // setShowCampaignInfoModal(true)
                                            }> View Facebook Insights</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>


                </div>
            </div>
            {
                showCampaignInfoModal &&
                <CampaignInfoModal
                    show={showCampaignInfoModal}
                    closeModal={() => setShowCampaignInfoModal(false)}
                    analyticsData={analyticsData}
                />
            }
        </>
    )
}
