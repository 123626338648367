import React from 'react'
import InputText from '../../InputGroup/InputText'
import InputSelect from '../../InputGroup/InputSelect'
import { ReactComponent as DownIcon } from '../../../assets/icons/Down.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { insertBankDetail, updateBankDetailByUUID } from '../../../services/public/public.service';
import { useEffect } from 'react';
import { validationSchema } from '../../Booking_EOI/BookingBasicDetailValidation';
import { bankValidationSchema } from '../validations/bankValidation';
import { yupResolver } from '@hookform/resolvers/yup';

const AddBankModal = ({ show, closeModal, style, uuid, getBankDetails, selectedBankDetail }) => {

    let error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            paymentName: '',
            bankName: '',
            bankBranch: '',
            accountNo: '',
            ifscCode: '',
            paymentLink: ''
        },
        resolver: yupResolver(bankValidationSchema)
    });

    useEffect(() => {
        if (Object.keys(selectedBankDetail).length > 0) {
            reset({
                // paymentName: selectedBankDetail.payment_name,
                bankName: selectedBankDetail.bank_name,
                bankBranch: selectedBankDetail.bank_branch,
                accountNo: selectedBankDetail.account_no,
                ifscCode: selectedBankDetail.ifsc,
                paymentLink: selectedBankDetail.payment_link
            })
        }
    }, [selectedBankDetail]);

    const onFormSubmit = async (values) => {

        const payload = {
            // payment_name: values.paymentName,
            bank_name: values.bankName,
            bank_branch: values.bankBranch,
            account_no: values.accountNo,
            ifsc: values.ifscCode,
            payment_link: values.paymentLink
        }

        let res = {}
        if (selectedBankDetail?.uuid) {
            res = await updateBankDetailByUUID(selectedBankDetail.uuid, payload);
        } else {
            res = await insertBankDetail(uuid, payload);
        }

        if (res.status === 200) {
            toast.success(res.message);
            getBankDetails();
            reset();
            closeModal();
        }
    }

    console.log(errors, 'error')
    return (
        <RightModal
            show={show}
            closeModal={() => {
                reset();
                closeModal();
            }}
            modalClass={`${style.configurationModal} ${style.addbanckAccountModal}  configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {selectedBankDetail?.uuid ? 'Edit' : 'Add'} Bank Account Details</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    reset();
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} id='bank-detail' className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                {/* <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                    <label>Payment Name</label>
                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='e.g. Downpayment'
                        {...register('paymentName')}
                    />
                </div> */}
                <div className={`form-group ${errors?.bankName ? 'invalid' : ''} position-relative`}>
                    <label>Bank Name</label>
                    <input
                        className={errors ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='e.g. State Bank Of India'
                        {...register('bankName')}
                    />
                </div>
                <div className={`form-group ${errors?.bankBranch ? 'invalid' : ''} position-relative`}>
                    <label>Bank Branch</label>
                    <input
                        className={errors ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='e.g. Mountroad, Chennai'
                        {...register('bankBranch')}
                    />
                </div>
                <div className={`form-group ${errors?.accountNo ? 'invalid' : ''} position-relative`}>
                    <label>Account No.</label>
                    <input
                        className={errors ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='Enter Bank Account Number'
                        {...register('accountNo')}
                    />
                </div>
                <div className={`form-group ${errors?.ifscCode ? 'invalid' : ''} position-relative`}>
                    <label>IFSC Code</label>
                    <input
                        className={errors ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='Enter Bank IFSC Code'
                        {...register('ifscCode')}
                    />
                </div>
                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                    <label>Payment Link</label>
                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"text"}
                        placeholder='Enter Payment Link Here'
                        {...register('paymentLink')}
                    />
                </div>
            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={() => {
                    reset();
                    closeModal();
                }}>Cancel</button>
                <button type='submit' form='bank-detail' className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default AddBankModal
