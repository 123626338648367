import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const AnalyticsSkeleton = () => {
    return (
        <tr>
            <td style={{ width: '45px' }}><Skeleton count={1} height="10px" width="100%" /></td>
            <td><Skeleton count={1} height="10px" width="100%" /></td>
            <td><Skeleton count={1} height="10px" width="100%" /></td>
            <td><Skeleton count={1} height="10px" width="100%" /></td>
            <td><Skeleton count={1} height="10px" width="100%" /></td>


        </tr>
    )
}

export default AnalyticsSkeleton