import makeRequest, { formRequest } from "../services/api/makeRequest";
import url from "../services/api/urls";
import { RequstMethods } from "../services/api/requestMethods";

const category = {
    async getAll() {
        return await makeRequest(url.learning_center.category.get, RequstMethods.GET);
    },
    async update(uuid, payload) {
        return await makeRequest(`${url.learning_center.category.update}/${uuid}`, RequstMethods.PUT, payload);
    },
    async updateStatus(uuid, payload) {
        return await makeRequest(`${url.learning_center.category.updateStatus}/${uuid}`, RequstMethods.PUT, payload);
    },
    async create(payload) {
        return await makeRequest(url.learning_center.category.create, RequstMethods.POST, payload);
    }
}

export default category;