import React, { useContext } from 'react';
import CenterModal from '../../../../../components/Modals/center-modal';
import { ReactComponent as BackICon } from '../../../../../assets/icons/back.svg';
import moment from 'moment';

const CampaignInfoModal = ({ show, closeModal, analyticsData }) => {
    console.log(analyticsData, 'analyticsData');
    return (
        <CenterModal show={show} closeModal={closeModal}>
            <div className='p-4 flex items-center'>
                <button onClick={closeModal} className='mr-2'>
                    <BackICon />
                </button>
                <h3 className='text-lg font-semibold m-0'> Campaign info</h3>
            </div>
            <div className="user-table ml-24 mt-24">
                <div className="def-table pr-24">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>No. of Clicks</th>
                                <th>Campaign Start</th>
                                <th>Campaign End</th>
                                {/* <th>Status</th> */}
                                <th>Impressions </th>
                                <th>Reach</th>
                                <th>Page Engagement</th>
                                <th>Leads</th>
                                <th>Post Reaction</th>
                                <th>Video View</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                analyticsData?.length > 0 && analyticsData.map((item, index) => (
                                    <tr key={1}>
                                        <td>{1}</td>
                                        <td className="">
                                            {item.clicks || '-'}
                                        </td>
                                        <td>{item?.date_start ? moment(item.date_start).format('DD MMM YYYY') : '-'}</td>
                                        <td>{item?.date_stop ? moment(item.date_stop).format('DD MMM YYYY') : '-'}</td>
                                        <td>{item?.impressions || '-'}</td>
                                        <td>{item?.reach || '-'}</td>
                                        <td>{getValueFromActions(item.actions, "page_engagement") || '-'}</td>
                                        <td>{getValueFromActions(item.actions, "lead") || '-'}</td>
                                        <td>{getValueFromActions(item.actions, "post_reaction") || '-'}</td>
                                        <td>{getValueFromActions(item.actions, "video_view") || '-'}</td>
                                        <td>{getValueFromActions(item.actions, "comment") || '-'}</td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </CenterModal>
    )
}

function getValueFromActions(actions, actionType) {
    for (const action of actions) {
        if (action.action_type === actionType) {
            return action.value;
        }
    }
    return null; // If no match is found
}

export default CampaignInfoModal