export const SIGNUP_REQUESTING = 'SIGNUP_REQUESTING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_PAGE_INIT = 'SIGNUP_PAGE_INIT';
export const SIGNUP_VERFICATIONL_LINK_SENT='SIGNUP_VERFICATION_LINK_SENT';

export const SHOW_HIDE_PASSWORD = 'SIGNUP_SHOW_HIDE_PASSWORD';
export const SIGNUP_RESEND_VERIFICATION_LINK = 'SIGNUP_RESEND_VERIFICATION_LINK';
export const SIGNUP_RESEND_VERIFICATION_LINK_SENT = 'SIGNUP_RESEND_VERIFICATION_LINK_SENT'; 


export function SignupPageInit(){
    return {
        type: SIGNUP_PAGE_INIT
    };

}

export function SignupVerificationLinkSent(){
    return {
        type : SIGNUP_VERFICATIONL_LINK_SENT
    }
}

export function SignupVerificationLinkReSent(){
    return {
        type : SIGNUP_RESEND_VERIFICATION_LINK_SENT
    }
}
 
export function ShowHidePassword(){
    return {
        type: SHOW_HIDE_PASSWORD
       
    };
}


export function SignupRequest(payload) {

    return {
        type: SIGNUP_REQUESTING,
        payload
        
    };
}

export function SignupError(error) {
   
    return {
        type: SIGNUP_ERROR,
        error,
    };
}
export function SignResentVerficationLink(){
    return {
        type: SIGNUP_RESEND_VERIFICATION_LINK,
    }
}
export function SignupSuccess( ) {
    return {
        type: SIGNUP_SUCCESS,
    };
}