import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import { insertProjectLatestUpdates, insertProjectReviews, updateProjectLatestUpdates, updateProjectReviews, uploadFile } from '../../../services/public/public.service';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { toast } from 'react-toastify';
import TextEditor from '../../../modules/ListingProjects/Editor';
import InputTextarea from '../../InputGroup/InputTextarea';

const AddLatestUpdateModal = ({ show, closeModal, style, projectUuid, getUpdates, selectedUpdate }) => {
    const [update, setUpdate] = useState(selectedUpdate || {});

    useEffect(() => {
        if (selectedUpdate?.uuid) {
            setUpdate(selectedUpdate);
        }
    }, [selectedUpdate]);

    const handleSubmit = async () => {
        if (!update?.title || !update?.description || !update?.media) {
            toast.error('Please fill all values');
            return;
        }
        const res = await insertProjectLatestUpdates(projectUuid, update);
        if ((res.data.status === 200)) {
            console.log(res.data.data);
            toast.success(res.data.message);
            getUpdates();
        }
        // setAllReviews(review)
        closeModal();
    }

    const handleUpdate = async () => {
        if (!update?.title || !update?.description || !update?.media) {
            toast.error('Please fill all values');
            return;
        }
        const res = await updateProjectLatestUpdates(selectedUpdate?.uuid, update);
        if ((res.data.status === 200)) {
            toast.success(res.data.message);
            getUpdates();
            closeModal();
        }
    }

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];

        // console.log(file)
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);
        // console.log({ response })
        if (response.data.status === 200) {
            let newReviews = { ...update };
            newReviews['media'] = response.data.data.s3_url
            setUpdate(newReviews);
        }
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Latest Update</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>

                {/* {
                    reviews.map((review, index) => ( */}
                <div className=''>
                    <div className='!mb-3 flex justify-between'>
                        {/* <h5 className="text-textblack text-sm leading-6 font-semibold tracking-[-0.2px]" >
                            Review
                        </h5> */}
                        {/* {reviews.length > 1 &&
                                    <button className='border-0 bg-tr ' onClick={() => {
                                        const newReviews = [...reviews];
                                        newReviews.splice(index, 1)
                                        setReviews(newReviews);
                                    }}
                                    >
                                        <CloseIcon />
                                    </button>} */}
                    </div>
                    <div className='flex flex-col gap-y-4'>
                        <InputText
                            label="Title"
                            placeholder="Enter title"
                            type="text"
                            value={update?.title}
                            inputclass='m-0 inter'
                            onChange={(e) => {
                                let newReviews = { ...update };
                                newReviews['title'] = e.target.value;
                                setUpdate(newReviews);
                            }}
                            required={true}
                        />
                        <div className=''>
                            <InputTextarea
                                label={'Description'}
                                required={true}
                                value={update.description}
                                onChange={(e) => {
                                    let newReviews = { ...update };
                                    newReviews['description'] = e.target.value;
                                    setUpdate(newReviews);
                                }} />
                        </div>


                        <div>
                            <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                <span>Image</span>
                                <span>PNG, 2MB</span>
                            </div>
                            <div className={`${style.upload}`}>
                                {update.media && <img src={update.media} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                <input type="file" className='d-none' name='developer-logo' id='update-img' accept='image/*' onChange={(e) => handleOnChangeImage(e)} />
                                {!update.media && <div className={`${style.uplaodbtngrp}`}>
                                    <button
                                        type='button'
                                        className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('update-img').click()
                                        }}
                                    ><PluswhiteIcon />Upload</button>
                                </div>}
                                {update.media && <div className={`${style.hoverbox}`}>
                                    <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('update-img').click()
                                        }}
                                    ><PluswhiteIcon />Replace</button>
                                    <button
                                        type='button'
                                        className={`${style.delbtn} inter border-0 bg-white`}
                                        onClick={() => {
                                            let newReviews = { ...update };
                                            newReviews['media'] = '';
                                            setUpdate(newReviews);
                                        }}
                                    ><TrashIcon /></button>
                                </div>}
                            </div>
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                        </div>
                    </div>

                </div>
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => {
                        if (selectedUpdate?.uuid) {
                            handleUpdate()
                        } else {
                            handleSubmit()
                        }
                    }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default AddLatestUpdateModal;