import React, { useContext, useEffect, useState } from 'react'
import InputText from '../../../../../../components/InputGroup/InputText';
import InputSelect from '../../../../../../components/InputGroup/InputSelect';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info.svg';
import { ReactComponent as PlusPrimary } from '../../../../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import Consumer from '../../../../../../helpers/context';
import { getProjectsForFilter } from '../../../../../../services/private/leads.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const IVRDetails = ({ setSteps, closeModal, ivrName, handleCreateIntegration, selectedIntegration, handleUpdateIntegration }) => {
    const [ivrNumber, setIvrNumber] = useState('');
    const [ivrToken, setIvrToken] = useState('');
    const [apiKey, setApikey] = useState('');
    const [allowCallForwarding, setAllowCallForwarding] = useState(false);
    const [callForwardingsDetails, setCallForwardingDetails] = useState([{}]);
    const [sourceList, setSourceList] = useState([]);
    const [projectList, setProjectList] = useState([]);

    let { companyConfig } = useContext(Consumer);

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setSourceList(companyConfig.Source)
            // setProjectList(companyConfig.Project)
        }
    }, [companyConfig]);

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
        }
    }

    useEffect(() => {
        if (Object.keys(selectedIntegration).length > 0) {
            setIvrNumber(selectedIntegration?.integration.ivr_number);
            setIvrToken(selectedIntegration?.integration.auth_token);
            setApikey(selectedIntegration?.integration.knowlarity_api_key);
            setAllowCallForwarding(selectedIntegration?.integration.call_forwarding);
            let callForwarding = selectedIntegration?.call_forwarding_numbers.map(item => ({
                project_id: item.project_id,
                source_id: item.source_id,
                lp_url: item.lp_url,
                forwarding_number: item.forwarding_number
            }))
            console.log(callForwarding, 'callforwor')
            if (callForwarding.length > 0) {
                setCallForwardingDetails(callForwarding)
            } else {
                setCallForwardingDetails([{}]);
            }
        }
    }, [selectedIntegration]);

    useEffect(() => {
        getProjects();
    }, [])

    const handleSubmit = () => {
        const payload = {
            ivr_number: ivrNumber,
            auth_token: ivrToken,
            knowlarity_api_key: apiKey,
            call_forwarding: allowCallForwarding,
            call_forwarding_numbers: callForwardingsDetails.filter(item => item.forwarding_number),
            ...(!allowCallForwarding) && { project_id: callForwardingsDetails[0].project_id }
        }
        if (selectedIntegration?.integration?.uuid) {
            handleUpdateIntegration(payload);
        } else {
            handleCreateIntegration(payload);
        }
    }

    return (
        <>
            <div className='!pt-5 sm:!px-6 !px-3 pb-8 h-full overflow-auto scroll-smooth	'>

                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                    <InputText
                        label={`${ivrName} IVR Number`}
                        placeholder='e.g. 1800 232 6666'
                        value={ivrNumber}
                        onChange={(e) => setIvrNumber(e.target.value)}
                    />
                    <div>
                        <div className='flex items-center justify-between !mb-2'>
                            <label className='m-0 text-sm text-black700'>{ivrName} IVR Token</label>
                            <button><InfoIcon /></button>
                        </div>
                        <InputText
                            placeholder='e.g. 1800 232 6666'
                            value={ivrToken}
                            onChange={(e) => setIvrToken(e.target.value)}
                        />
                    </div>
                    <InputText
                        label={`${ivrName} API Key`}
                        placeholder='Enter Api Key'
                        value={apiKey}
                        onChange={(e) => setApikey(e.target.value)}
                    />
                    {/* <InputSelect
                        label='User Mapping'
                        styles={customStyles}
                    /> */}
                </div>
                <div className='flex items-center gap-x-3 mt-6'>
                    <h2 className='m-0 text-textblack font-semibold inter text-base'>Call Forwarding</h2>
                    <div className='inline-flex gap-x-4'>
                        <div className='flex items-center inter text-sm font-medium text-black700 gap-x-2'>
                            <input
                                type="radio"
                                name="call_forwarding"
                                id=""
                                value={'Yes'}
                                checked={allowCallForwarding}
                                onChange={() => {
                                    setCallForwardingDetails([{}])
                                    setAllowCallForwarding(!allowCallForwarding)
                                }}
                            />
                            Yes
                        </div>
                        <div className='flex items-center inter text-sm font-medium text-black700 gap-x-2'>
                            <input
                                type="radio"
                                name="call_forwarding"
                                id=""
                                value={'No'}
                                checked={!allowCallForwarding}
                                onChange={() => {
                                    setCallForwardingDetails([{}])
                                    setAllowCallForwarding(!allowCallForwarding)
                                }}
                            />
                            No
                        </div>
                    </div>
                </div>
                {
                    !allowCallForwarding && callForwardingsDetails.map((item, index) => (
                        <div key={`${index}`} className='mt-3'>
                            <InputSelect
                                label='Project'
                                placeholder='Select Project'
                                styles={customStyles}
                                options={projectList}
                                value={item.project_id ? projectList.find(source => source.value === item.project_id) : ''}
                                onChange={(value) => {
                                    let forwardings = [...callForwardingsDetails];
                                    forwardings[index].project_id = value.value;
                                    setCallForwardingDetails(forwardings)
                                }}
                            />
                        </div>))
                }
                {allowCallForwarding && callForwardingsDetails.map((item, index) => (
                    <React.Fragment key={`${index}`}>
                        <div className='mt-6 '>
                            <div className='flex justify-between items-center'>
                                <h2 className='m-0 text-textblack font-semibold inter text-base'>Call Forwarding {index + 1}</h2>
                                {callForwardingsDetails.length > 1 && <button onClick={() => {
                                    let forwardings = [...callForwardingsDetails];
                                    forwardings.splice(index, 1)
                                    setCallForwardingDetails(forwardings)
                                }}>
                                    <CloseIcon />
                                </button>}
                            </div>
                            <div className='grid grid-cols-1 gap-y-4 [&>*]:!mt-3 '>
                                <InputText
                                    label='Call Forwarding Number'
                                    placeholder='e.g. 1800 232 6666'
                                    value={item.forwarding_number}
                                    onChange={(e) => {
                                        let forwardings = [...callForwardingsDetails];
                                        forwardings[index].forwarding_number = e.target.value;
                                        setCallForwardingDetails(forwardings)
                                    }}
                                />
                                {/* <InputText
                            label='Call Forwarding Number'
                            placeholder='e.g. 1800 232 6666'
                        /> */}
                            </div>
                        </div>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-2 [&>*]:!mt-3'>
                            <InputSelect
                                label='Project'
                                placeholder='Select Project'
                                styles={customStyles}
                                options={projectList}
                                value={item.project_id ? projectList.find(source => source.value === item.project_id) : ''}
                                onChange={(value) => {
                                    let forwardings = [...callForwardingsDetails];
                                    forwardings[index].project_id = value.value;
                                    setCallForwardingDetails(forwardings)
                                }}
                            />
                            <InputSelect
                                label='Source'
                                placeholder='Select Source'
                                styles={customStyles}
                                options={sourceList}
                                value={item.source_id ? sourceList.find(source => source.value === item.source_id) : ''}
                                onChange={(value) => {
                                    let forwardings = [...callForwardingsDetails];
                                    forwardings[index].source_id = value.value;
                                    setCallForwardingDetails(forwardings)
                                }}
                            />
                            <div className='col-span-2'>
                                <InputText
                                    label='LP URL'
                                    placeholder='Enter Url'
                                    value={item.lp_url}
                                    onChange={(e) => {
                                        let forwardings = [...callForwardingsDetails];
                                        forwardings[index].lp_url = e.target.value;
                                        setCallForwardingDetails(forwardings)
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))
                }
                {allowCallForwarding &&
                    <button
                        className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold'
                        onClick={() => setCallForwardingDetails([...callForwardingsDetails, {}])}
                    >
                        <PlusPrimary />Add another
                    </button>}
            </div>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center' onClick={closeModal}>Cancel</button>
                <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center' onClick={handleSubmit}>Continue</button>
            </div>
        </>
    )
}

export default IVRDetails
