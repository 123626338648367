import React from 'react'
import SmallCenterModal from './small-center-modal'

const DeleteIntegrationModal = ({ show, closeModal, handleDeletePage, selectedIntegration }) => {
    const { page_name } = selectedIntegration;
    return (
        <div>
            <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'unprocessed-leads PauseIntegrationModal DeleteIntegrationModal'}>
                <div className=''>
                    <figure className='mb-24'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
                            <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#FC5A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8" />
                        </svg>
                    </figure>
                    <h1 className='fz20 fw-po-medium black mb-8 po'>Delete Integration</h1>
                    <p className="black-dark-700 fz14 ls2 ro mb-0">Note that the platform will no longer receive any leads from <span className='black'>{page_name}</span> page even if your campaign is still active.</p>

                    <div className='d-flex justify-content-end mt-24'>
                        <button className='fw-po-medium outline-btn d-table fz16 w-50' onClick={closeModal} >Cancel</button>
                        <button className='dead-btn  fw-po-medium d-table ml-12 fz16 w-50 ' onClick={handleDeletePage} >Delete</button>
                    </div>
                </div>
            </ SmallCenterModal>
        </div>
    )
}

export default DeleteIntegrationModal
