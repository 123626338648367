import React from 'react'
import Tabs from '../../components/HOC/Tabs'
import Schedule from './schedule'
import Completed from './completed'
import Cancelled from './cancelled'
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar'
import InternalTabs from '../../components/HOC/InternalTabs'

export const SVEvents = ({
    events,
    isLoading,
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    maxPage,
    leadsCount,
    selectedTab,
    setSelectedTab,
    searchText,
    tabRowCount,
    tabList
}) => {

    return (
        <>
            <div className=''>
                <InternalTabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='broker-page'>
                {selectedTab?.name === 'Scheduled' &&
                    <Schedule
                        leads={events}
                        isLoading={isLoading}
                    />}
                {selectedTab?.name === 'Completed' && <Completed
                    leads={events}
                    searchText={searchText}
                    isLoading={isLoading}
                />}
                {/* {selectedTab?.name === 'Attempts' && <SitiVisitAttempts leads={events} />} */}
                {selectedTab?.name === 'Cancelled' && <Cancelled
                    leads={events}
                    searchText={searchText}
                    isLoading={isLoading}
                />}
            </div>
            {/* <LeadsFooterBar
                showingResults={showingResults}
                setShowingResults={setShowingResults}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={maxPage}
                leadsCount={leadsCount}
                classname='!relative !basis-0 !w-full'
            /> */}
        </>
    )
}
