import React from 'react'

const Instruction = ({ updateStep }) => {
    return (
        <div className="Prepare-content w-100 pt-4">
            <div className="row">

                <div className="col-md-6">
                    <h3 className='fz18 black fw-medium flex items-center'>
                        <svg width="20" height="20" className="mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12 8V12" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12 16H12.01" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                        Important instructions for importing your leads
                    </h3>
                    <ul>

                        <li>
                            <span>1.</span>
                            Download & use only the sample CSV file from below
                        </li>
                        <a href="https://propacitybackend.s3.ap-south-1.amazonaws.com/sample_csv_propacity.csv" download className='pr-btn fz14 fw-medium px-8 py4 items-center' style={{ display: 'inline-flex' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18.875 13.625V16.4583C18.875 16.8341 18.7257 17.1944 18.4601 17.4601C18.1944 17.7257 17.8341 17.875 17.4583 17.875H7.54167C7.16594 17.875 6.80561 17.7257 6.53993 17.4601C6.27426 17.1944 6.125 16.8341 6.125 16.4583V13.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.9585 10.0833L12.5002 13.625L16.0418 10.0833" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.5 13.625V5.125" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Download Sample CSV
                        </a>
                        <li>
                            <span>2.</span>
                            Don't change or add any new column names in the sample file

                        </li>
                        <li>
                            <span>3.</span>
                            Avoid any blank rows in the sample file
                        </li>
                        <li>
                            <span>4.</span>
                            Copy & paste all your leads in the sample file

                        </li>
                        <li>
                            <span>5.</span>
                            CSV File size &#60;5 MB
                        </li>
                        <li>
                            <span>6.</span>
                            Only Mobile number & source are mandatory fields
                        </li>
                        <li>
                            <span>7.</span>Add a single value only in each table cell
                        </li>
                        <li>
                            <span>8.</span>Refer to right table for recommended data format
                        </li>

                    </ul>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    <div className='main-import-table'>
                        <div className="tbale-head">
                            <div>Field Type</div>
                            <div>Lead Data Format (Recommended)</div>
                        </div>
                        <div className="csv-import-table">

                            <table className='table'>

                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td><span>Example:</span> Rahul</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile No.<sup>*</sup></td>
                                        <td><span className='text-red'>Mandatory Field</span> <span>Example:</span> 919819232121</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td><span>Example:</span> rahul@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>City</td>
                                        <td><span>Example:</span> New Delhi</td>
                                    </tr>
                                    <tr>
                                        <td>Created Date</td>
                                        <td><span>Example:</span> 23/10/2022</td>
                                    </tr>
                                    <tr>
                                        <td>Assigned User</td>
                                        <td><span>Example: </span>Ankit Verma</td>
                                    </tr>
                                    <tr>
                                        <td>Project</td>
                                        <td><span>Example: </span>Orchid Island</td>
                                    </tr>
                                    <tr>
                                        <td>Alt Mobile No.</td>
                                        <td><span>Example: </span>919819232121</td>
                                    </tr>
                                    <tr>
                                        <td>Pincode</td>
                                        <td><span>Example:</span> 123121</td>
                                    </tr>
                                    <tr>
                                        <td>Source</td>
                                        <td><span>Example:</span> Facebook</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td><span>Example:</span> Delhi</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">

                <button className='pr-btn px-16 py-8' onClick={() => updateStep(2)}>Continue</button>
            </div>
        </div >
    )
}

export default Instruction;