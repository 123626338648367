import React from 'react'
import InputText from '../../InputGroup/InputText'
import InputSelect from '../../InputGroup/InputSelect'
import DateTimePicker from '../../InputGroup/DateTime'
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};



const CustomerDetails = ({ setSteps }) => {
    return (
        <>
            <div className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                <div className="">
                    <div>
                        <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Customer Details
                        </h5>

                    </div>

                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-3'>
                        <InputText
                            label="Name"
                            placeholder="Enter Customer name"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputText
                            label="Mobile"
                            placeholder="Enter Customer Mobile Number"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputText
                            label="Email"
                            placeholder="Enter Customer Email Id"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputSelect
                            label='Project'
                            placeholder='Select Project'
                            inputclass='m-0 inter'
                            isMulti={false}
                            styles={customStyles}
                        />
                        <DateTimePicker
                            label='Expected Closure Date'
                            inputclass='mt-0'
                            placeholder='Select Date'
                        />
                        <DateTimePicker
                            label='Token Date'
                            inputclass='mt-0'
                            placeholder='Select Date'
                        />
                        <InputText
                            label="Agreement Amount"
                            placeholder="Enter Amount"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <DateTimePicker
                            label='Token Validity'
                            inputclass='mt-0'
                            placeholder='Select Date'
                        />
                        <InputText
                            label="Token Amount"
                            placeholder="Enter Amount"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputSelect
                            label='Source'
                            placeholder='Select Source'
                            inputclass='m-0 inter'
                            isMulti={false}
                            styles={customStyles}
                        />
                    </div>
                </div>

                <div className='!mt-4'>
                    <div className="flex items-center justify-between cursor-pointer">
                        <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Buyer Details
                        </h5>
                        <div className="flex items-center">
                            <div className='flex items-center text-sm text-black700 inter'><input type="checkbox" checked className='!mr-2' /> <span className='!p-1 !border !border-grayLight rounded-md'>Same as Customer Details</span></div>

                        </div>
                    </div>



                </div>
                <div className='!mt-4 grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 '>
                    <InputText
                        label="Name"
                        placeholder="Enter Customer Email Id"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                    />
                    <InputText
                        label="Email"
                        placeholder="Enter Customer Email Id"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                    />
                    <InputText
                        label="Mobile"
                        placeholder="Enter Customer Mobile Number"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                    />
                    <InputText
                        label="Nearby Landmark"
                        placeholder="Enter Nearby Landmark"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                    />
                    <InputText
                        label="Permanent Address"
                        placeholder="Enter Permanent Address"
                        type="text"
                        name="name"
                        inputclass='m-0 inter'
                    />
                    <div>
                        <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                            <span className='text-sm text-black700 '>Communication Address</span>
                            <div className='flex items-center text-sm text-black700 inter'><input type="checkbox" checked className='!mr-2' /><span className='!p-1 !border !border-grayLight rounded-md'>Same as Permanent</span></div>
                        </div>
                        <InputText
                            placeholder="Enter Communication Address"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                    </div>
                </div>

                <div className='!mt-4'>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Customer Bank Details
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <InputText
                            label="Account No."
                            placeholder="Enter Account No."
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputText
                            label="Bank Name"
                            placeholder="Enter Bank Name"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputText
                            label="IFSC Code"
                            placeholder="Enter IFSC Code"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />
                        <InputText
                            label="Bank Branch"
                            placeholder="Enter Bank Name"
                            type="text"
                            name="name"
                            inputclass='m-0 inter'
                        />

                    </div>
                </div>

                <div className="!mt-3">
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Co-applicant 1
                    </h5>
                    <button className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2'>
                        <PlusPrimary /> Add Co-Applicant</button>
                </div>

            </div >
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center'>Cancel</button>
                <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center' onClick={() => setSteps('QoteUnitDetail')}>Next</button>
            </div>
        </>
    )
}

export default CustomerDetails
