import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import DatePicker from 'react-datepicker';
import SliderModal from './SliderModal';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from 'moment';

import { getLeadsFilter } from '../../services/private/leads.service';
import DynamicAccordion from './FilterModal/Dynamic.accordion';
import CheckAccordion from './FilterModal/Check.accordion';
import { getCompanyAgency, getCompanyConfig, getExportLimitNumber } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { leadTypesEnum } from '../../config/enums';
import { getAllCities } from '../../services/public/public.service';
import Consumer from '../../helpers/context';


export default function ExportFilterModal({
    show,
    closeModal,
    handleFilter,
    filtersData,
    leadType,
    reAssignRole,
    setCurrentPage,
    filterToaster
}) {

    const [config, setConfig] = useState({
        "Intent": [],
        "Project": [],
        "Source": [],
        "Status": []
    })
    // Input Values
    const [inputValues, setInputValues] = useState({
        "Assigned To": "",
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        "Assigned To": "",
    });

    const [searchResults, setSearchResults] = useState({
        "Assigned To": [],
    });

    // Filter Values   
    const [intent, setintent] = useState([]);
    const [project, setproject] = useState([]);
    const [status, setstatus] = useState([]);
    const [source, setSource] = useState([]);
    const [filterDate, setFilterDate] = useState({});
    const [updatedDate, setUpdatedDate] = useState({})
    const [assign, setAssign] = useState([]);
    const [filterSelected, setFilterSelected] = useState(false)
    const [createdDateError, setCreatedDateError] = useState(false);
    const [agency, setAgency] = useState([]);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [exportLimit, setExportLimit] = useState();
    const [reengagedDate, setReengagedDate] = useState({});
    const [reengagement, setReengagement] = useState([]);
    const [reengageSource, setReengageSource] = useState([]);

    const currentDate = new Date();
    const ROLE_NAME = localStorage.getItem('role');

    let { allowedPermissions } = useContext(Consumer);

    //   handleChanges
    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };

        let filterData = [];

        filterData = await getFilterData(fieldName === 'Assigned To' ? 'Assign To' : fieldName, inputValuesData[fieldName])

        if (!filterData.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }
        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }


    const handleDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
    }

    const handleUpdatedDateChange = (name, date) => {
        setUpdatedDate({ ...updatedDate, [name]: date });
    }

    const handleReengagedDateChange = (name, date) => {
        setReengagedDate({ ...reengagedDate, [name]: date });
    }
    // API CALLS
    const getFilterData = async (type, value) => {

        // NOTE TODO : Change this on backend as this format is not acceptable
        if (type == "Mobile No") type = "Mobile No.";

        const res = await getLeadsFilter({ filterType: type, value: value });
        if (res.status === 200) {
            return res.data.data
        }
        else {
            return []
        }
    }

    const getCompanyConfigData = async () => {
        await getCompanyConfig().then(res => {
            if (res.status === 200) {
                setConfig(res.data.data.result)
            }
        })
    }

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getExportLimit = useCallback(() => {
        getExportLimitNumber().then(res => {
            if (res.status === 200) {
                setExportLimit(res.data.data)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }, [])

    useEffect(() => {
        getCompanyConfigData();
        getAgencyData();
        getExportLimit();
    }, [])

    useEffect(() => {
        if (intent.length || project.length || source.length || Object.keys(filterDate).length || agency.length || reengagement.length || assign.length || status.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [intent, project, source, filterDate, assign, status, agency, reengagement, reengagedDate])

    const sendFilterRequest = () => {
        let filterArr = [];

        if (filterDate.startDate || filterDate.endDate) {
            // setCreatedDateError(false)
            let endDate = filterDate.endDate || filterDate.startDate;

            filterArr.push({
                "Created Date": {
                    "From": filterDate.startDate,
                    "To": endDate
                }
            })
        }
        // else {
        //     setCreatedDateError(true);
        //     return;
        // }

        if (updatedDate.startDate) {
            let endDate = updatedDate.endDate || updatedDate.startDate;

            filterArr.push({
                "Updated Date": {
                    "From": updatedDate.startDate,
                    "To": endDate
                }
            })
        }

        if (reengagedDate.startDate) {
            let endDate = reengagedDate.endDate || reengagedDate.startDate;
            filterArr.push({
                "re_engage_date": {
                    "From": reengagedDate.startDate,
                    "To": endDate
                }
            })
        }

        if (reengagement.length > 0) {
            filterArr.push({
                is_re_engagement: true
            })
        }

        if (intent.length > 0) {
            filterArr.push({
                Intent: intent.map((data) => {
                    return data.value
                })
            })
        }

        if (status.length > 0) {
            filterArr.push({
                Status: status.map((data) => {
                    return data.value
                })
            })
        }
        if (project.length > 0) {
            filterArr.push({
                Project: project.map((data) => {
                    return data.value
                })
            })
        }

        if (source.length > 0) {
            filterArr.push({
                Source: source.map((data) => {
                    return data.value
                })

            })
        }
        if (reengageSource.length > 0) {
            filterArr.push({
                re_engage_sources: reengageSource.map((data) => {
                    return data.value
                })

            })
        }

        if (agency.length > 0) {
            filterArr.push({
                'Marketing Agency': agency.map((data) => {
                    return data.value
                })

            })
        }
        if (assign.length > 0) {
            filterArr.push({
                "Assigned To": assign
            })
        }

        handleFilter(filterArr);
    }

    const handelRemoveClick = () => {
        if (filterSelected || intent.length > 0 || status.length > 0 || project.length > 0 || agency.length || reengagement.length || Object.keys(reengagedDate).length || (filterDate.startDate) || filtersData?.length) {
            toast.success("All filters Removed");
            setInputValues({
                "Assigned To": "",
            })
            setFilterDate({})
            setUpdatedDate({})
            setintent([])
            setAgency([])
            setproject([])
            setstatus([])
            setSource([])
            setAssign([]);
            setReengagedDate({});
            setReengagement([])
            setSearchResults({
                "Assigned To": [],
            })
        }
    }

    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = {};
        for (const filterData of filters) {
            filtersArr = { ...filtersArr, ...filterData }
        }


        if (Object.keys(filtersArr).includes("Created Date")) {
            setFilterDate(filtersArr['Created Date'] || {})
        }

        if (Object.keys(filtersArr).includes("Updated Date")) {
            setUpdatedDate(filtersArr['Updated Date'] || {})
        }

        if (Object.keys(filtersArr).includes("Intent")) {
            const filteredintent = filtersArr.Intent.map(int => {
                return config["Intent"]?.find(conf => conf.value === int)
            })
            setintent(filteredintent || [])
        }

        if (Object.keys(filtersArr).includes("Status")) {
            const filteredStatus = filtersArr.Status.map(int => {
                return config["Status"]?.find(conf => conf.value === int)
            })
            setstatus(filteredStatus || [])
        }

        if (Object.keys(filtersArr).includes("Project")) {
            const filteredProject = filtersArr.Project.map(int => {
                return config["Project"]?.find(conf => conf.value === int)
            })
            setproject(filteredProject || [])
        }

        if (Object.keys(filtersArr).includes("Source")) {
            const filteredSource = filtersArr.Source.map(int => {
                return config["Source"]?.find(conf => conf.value === int)
            })
            setSource(filteredSource || [])
        }

        if (Object.keys(filtersArr).includes("Assigned To")) {
            setAssign(filtersArr['Assigned To'] || [])
        }
    }

    // useEffect(() => {
    //     let filters = filtersData;
    //     const filtersArr = [];
    //     for (const filterData of filters) {
    //         filtersArr.push(Object.keys(filterData)[0])
    //     }

    //     if (!filtersArr.includes("Created Date")) {
    //         setFilterDate({})
    //     }

    //     if (!filtersArr.includes("Intent")) {
    //         setintent([])
    //     }

    //     if (!filtersArr.includes("Status")) {
    //         setstatus([])
    //     }

    //     if (!filtersArr.includes("Project")) {
    //         setproject([])
    //     }

    //     if (!filtersArr.includes("Source")) {
    //         setSource([])
    //     }

    //     if (!filtersArr.includes("Assigned To")) {
    //         setAssign([])
    //     }
    // }, [filtersData])

    // useEffect(() => {
    //     updateFilterValaue();
    // }, [config, filtersData])

    const handleAssign = useMemo(() => {
        const index = searchResults['Assigned To'].findIndex(result => result.value === 'Unassigned')
        const assignIndex = assign.findIndex(result => result.value === 'Unassigned')
        if (assignIndex >= 0) {
            return searchResults["Assigned To"]
        }

        if (index === -1) {
            return [{ value: 'Unassigned', id: null }, ...searchResults["Assigned To"]]
        }

        return searchResults["Assigned To"]
    }, [searchResults["Assigned To"], assign])


    return (
        <SliderModal
            show={show}
            setShow={closeModal}
            title={'Export Leads(CSV)'}
            titleRight={<>
                {filterSelected || filtersData?.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover " onClick={handelRemoveClick}>Remove all</a>
                    : " "
                }
            </>
            }
            body={
                <>
                    <p className='fz16 black-dark-700 !px-5'>{`You can export leads in csv by using filters below. You cannot export more than ${exportLimit} leads in a single export. For more, contact support@propacity.in`}</p>
                    <div className="filter">

                        <div className="all-fliters">
                            <div id="accordion">
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedate" aria-expanded="true" aria-controls="collapsedate" id="headingDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Created Date <sup>*</sup>
                                                {Object.keys(filterDate).length > 0 && <span>{Object.keys(filterDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapsedate" className="filter-body collapse show" aria-labelledby="headingDate" data-parent="#accordion">
                                        <div className={`d-flex justify-content-between align-center mx-3 ${createdDateError ? 'invalid' : ''}`} >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                {/* <Form.Control type="date" max="2099-10-10" name="startDate" value={filterDate.startDate} onChange={handleDateChange} /> */}
                                                <DatePicker
                                                    selected={filterDate.startDate ? new Date(filterDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}

                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                {/* <Form.Control type="date" max="2099-10-10" name="c" value={filterDate.endDate} onChange={handleDateChange} /> */}
                                                <DatePicker
                                                    selected={filterDate.endDate ? new Date(filterDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    minDate={filterDate.startDate}
                                                    onChange={(date) => handleDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                        {createdDateError && <div className="input-feedback mx-3">
                                            Please enter created date
                                        </div>}
                                    </div>
                                </div>

                                {leadType === leadTypesEnum.BOOKINGS ? "" :
                                    <CheckAccordion
                                        value={status}
                                        setValue={setstatus}
                                        selectionValues={config["Status"]}
                                        accordionName="Status"
                                        label="Status"
                                    />
                                }
                                <CheckAccordion
                                    value={intent}
                                    setValue={setintent}
                                    selectionValues={[...config["Intent"]]}
                                    accordionName="Intent"
                                    label="Intent"
                                />
                                <CheckAccordion
                                    value={project}
                                    setValue={setproject}
                                    selectionValues={config["Project"]}
                                    accordionName="Project"
                                    label="Project"
                                />
                                <CheckAccordion
                                    value={source}
                                    setValue={setSource}
                                    selectionValues={config["Source"]}
                                    accordionName="Source"
                                    label='Source'
                                />
                                {/* <CheckAccordion
                                    value={reengageSource}
                                    setValue={setReengageSource}
                                    selectionValues={config["Source"]}
                                    accordionName="Re_engage_source"
                                    label='Re-Engaged Source'
                                /> */}
                                <CheckAccordion
                                    value={agency}
                                    setValue={setAgency}
                                    selectionValues={agencyDetails}
                                    accordionName="Agency"
                                    label="Marketing Agency"
                                />
                                {((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('filter_assigned_to')) || ROLE_NAME === 'ADMIN') &&
                                    <DynamicAccordion
                                        accordionName="Assigned User"
                                        placeholder='Enter user name to search'
                                        value={assign}
                                        setValue={setAssign}
                                        searchResults={handleAssign}
                                        setSearchResults={(data) => setSearchResults({ ...searchResults, ["Assigned To"]: data })}
                                        inputValues={inputValues["Assigned To"]}
                                        handleInputChange={handleInputChange}
                                        notFound={inputValuesNotFound["Assigned To"]}
                                        setInputValues={setInputValues}
                                        inputValuesNotFound={inputValuesNotFound}
                                        setInputValuesNotFound={setInputValuesNotFound}
                                    />
                                }
                                <CheckAccordion
                                    value={reengagement}
                                    setValue={setReengagement}
                                    selectionValues={[{
                                        label: 'Show Re-Engaged Leads',
                                        value: 'reengage'
                                    }]}
                                    accordionName="re-engaged"
                                    label="Re-Engaged Leads"
                                    showSearch={false}
                                />
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#updateddate" aria-expanded="false" aria-controls="updateddate" id="updatingDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Assigned Date
                                                {Object.keys(updatedDate).length > 0 && <span>{Object.keys(updatedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="updateddate" className="filter-body collapse" aria-labelledby="updatingDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                {/* <Form.Control type="date" max="2099-10-10" name="startDate" value={filterDate.startDate} onChange={handleDateChange} /> */}
                                                <DatePicker
                                                    selected={updatedDate.startDate ? new Date(updatedDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleUpdatedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}

                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                {/* <Form.Control type="date" max="2099-10-10" name="c" value={filterDate.endDate} onChange={handleDateChange} /> */}
                                                <DatePicker
                                                    selected={updatedDate.endDate ? new Date(updatedDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    minDate={updatedDate.startDate}
                                                    onChange={(date) => handleUpdatedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapseengageddate" aria-expanded="false" aria-controls="collapseengageddate" id="headingReengagedDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Re-engage Date
                                                {Object.keys(reengagedDate).length > 0 && <span>{Object.keys(reengagedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapseengageddate" className="filter-body collapse" aria-labelledby="headingReengagedDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reengagedDate.startDate ? new Date(reengagedDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleReengagedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reengagedDate.endDate ? new Date(reengagedDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={reengagedDate.startDate}
                                                    onChange={(date) => handleReengagedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => closeModal(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Export Leads</button>
                    </div>
                </>
            }
        />
    )
}