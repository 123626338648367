import React, { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as SearchClosIoicn } from '../../../../assets/icons/searchClose.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TeamCard from './TeamCard';
import AddTeamModal from '../../../../components/Modals/TeamModal/AddTeamModal';
import { getAllTeams } from '../../../../services/public/public.service';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg'




const Teams = () => {

    const [showModal, setShowModal] = useState(false);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({})

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            setTeams(res.data.data);
        }
    }

    useEffect(() => {
        getAll();
    }, [])

    return (
        <div className='w-100 flex flex-col overflow-auto'>
            <div className="settings-title ">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <h1 className="fz24 mb-0 black fw-po-medium">Teams</h1>
                    </div>
                    <div className="col-md-5 justify-content-end d-flex">
                        <button onClick={() => setShowModal(true)} className="pr-btn flex leading-5">
                            <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="path-1-inside-1_1117_451" fill="white">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                </mask>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                            </svg>
                            Add Team
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex flex-fill flex-col overflow-auto mt-6 w-100'>
                <div className='max-w-[1050px] mx-auto table'>
                    {teams.length > 0 ? <div className='pl-6 pr-6 grid grid-cols-3 gap-x-4 gap-y-5'>
                        {
                            teams.map((team, index) => (
                                <TeamCard
                                    key={`${team.uuid}_${index}`}
                                    team={team}
                                    setShowAddTeamModal={setShowModal}
                                    setSelectedTeam={setSelectedTeam}
                                />
                            ))

                        }
                    </div> :
                        <div style={{ height: '60vh' }}>
                            <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Teams Added</h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add teams to see them here</p>

                            </div>
                        </div>
                    }
                </div>
            </div>
            {showModal &&
                <AddTeamModal
                    show={showModal}
                    closeModal={() => {
                        setShowModal(false);
                        setSelectedTeam({});
                    }}
                    selectedTeam={selectedTeam}
                    getAll={getAll}
                />}
        </div>
    )
}

export default Teams
