import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import { insertProjectRera, updateProjectReraByUUID, uploadFile } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

const ReraModal = ({ show, closeModal, style, projectUuid, selectedRera, getReraData }) => {
    const [reraNo, setReraNo] = useState('');
    const [reraLink, setReraLink] = useState('');

    const handleSubmit = async () => {
        if (!reraNo) {
            toast.error('Please enter rera number');
            return;
        }
        const payload = {
            rera_no: reraNo,
            rera_link: reraLink
        }
        const res = await insertProjectRera(projectUuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getReraData();
        }
        closeModal();
    }

    useEffect(() => {
        if (selectedRera?.uuid) {
            setReraNo(selectedRera?.rera_no);
            setReraLink(selectedRera?.rera_link);
        }
    }, [selectedRera]);

    const handleUpdate = async () => {
        const payload = {
            rera_no: reraNo,
            rera_link: reraLink
        }
        const res = await updateProjectReraByUUID(selectedRera?.uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getReraData();
            closeModal();
        }
    }

    const handleOnChangeDeveloperLogo = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setReraLink(response.data.data.s3_url)
        }
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Project Rera
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>

                <div>
                    <InputText
                        label={'Rera Number'}
                        placeholder={'Enter Rera Number'}
                        value={reraNo}
                        onChange={(e) => {
                            setReraNo(e.target.value)
                        }}
                        error={''}
                        required={true}
                    />

                    <div className='mt-3'>
                        <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                            <span>Rera Link</span>
                            {/* <span>PNG, 2MB</span> */}
                        </div>
                        <div className={`${style.upload}`}>
                            {reraLink && <img src={reraLink} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                            <input type="file" className='d-none' name='rera-link' id='rera-link' accept='image/*' onChange={(e) => handleOnChangeDeveloperLogo(e)} />
                            {!reraLink && <div className={`${style.uplaodbtngrp}`}>
                                <button
                                    type='button'
                                    className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('rera-link').click()
                                    }}
                                ><PluswhiteIcon />Upload</button>
                            </div>}
                            {reraLink && <div className={`${style.hoverbox}`}>
                                <button
                                    type='button'
                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('rera-link').click()
                                    }}
                                ><PluswhiteIcon />Replace</button>
                                <button
                                    type='button'
                                    className={`${style.delbtn} inter border-0 bg-white`}
                                    onClick={() => {
                                        setReraLink('')
                                    }}
                                ><TrashIcon /></button>
                            </div>}
                        </div>
                        {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                        {/* {logoDeveloperFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoDeveloperFileError}</p>} */}
                    </div>
                </div>
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => {
                        if (selectedRera?.uuid) {
                            handleUpdate()
                        } else {
                            handleSubmit()
                        }
                    }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default ReraModal;