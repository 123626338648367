import React, { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/Pageback.svg';
import { Link } from 'react-router-dom';
import { getCompaniesForAnalytics } from '../../services/private/company.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createDatewithTime } from '../../helpers/date';

const UsersReferral = () => {
    const [companyDetails, setCompanyDetails] = useState([]);

    const getCompanyData = async () => {
        await getCompaniesForAnalytics().then(res => {
            if (res.status === 200) {
                setCompanyDetails(res.data.data.companies)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    useEffect(() => {
        getCompanyData()
    }, []);

    return (
        <div className="roles w-100 h-full flex flex-col">
            <div className="settings-title">
                <div className="w-100 flex justify-between">
                    <div className="w-1/2">
                        <h2 className="text-2xl !text-black font-medium flex items-center !gap-x-2 m-0">
                            <Link
                                // onClick={() => history.goBack()}
                                to='/cp-manager'
                                className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                            >
                                <BackIcon className="group-hover:stroke-black" />
                            </Link>
                            User name
                        </h2>
                    </div>
                    {/* <div className="w-1/2 flex items-center space-x-3 justify-end">
                            <div>
                                <div className={`border rounded px-2 py-1  flex items-center ${searchText ? 'border-primary' : ''} `}>
                                    <SearchIcon className='mr-2' />
                                    <input
                                        type="text"
                                        placeholder='Search For Users'
                                        className=' w-100 focus:outline-none text-sm'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {searchText &&
                                        <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                            <SearchCloseIcon />
                                        </div>}
                                </div>
                            </div>                            
                            
                        </div> */}
                </div>
            </div>

            <div className="users w-100 h-full flex flex-col !mt-4">
                <div className="urer-table ml-24">
                    <div className="def-table pr-24 !h-[68vh]">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Company Name</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    {/* <th>Status</th> */}
                                    <th>Email</th>
                                    <th>Signup Date</th>
                                    <th> Subscription Status </th>
                                    <th>Subscription Date</th>
                                    {/* <th></th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyDetails && companyDetails.length > 0 ? companyDetails.map((company, index) => {
                                    const { uuid, name, owner_name, owner_mobile, owner_email, is_subscribed, subscription_expiry, created_on, subscription_date } = company;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">
                                                {name.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize'>{name || '-'}</span>
                                                }
                                            </td>
                                            <td>
                                                {owner_email.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className=''>{owner_name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='three-dots'>{owner_name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className=''>{owner_name}</span>
                                                }
                                            </td>
                                            <td>{owner_mobile ? owner_mobile : "-"}</td>
                                            {/* <td> <CustomBadge
                                            value={is_active}
                                            label={{ true: "Active", false: "In-Active" }}
                                            reverse={false}
                                        />
                                        </td> */}
                                            {/* <td className="">{role_name || '-'}</td> */}
                                            <td>{owner_email ? owner_email : "-"}</td>
                                            <td>{createDatewithTime(created_on)}</td>
                                            <td >
                                                <button className={`${is_subscribed ? 'hot-btn' : 'dead-btn'} w-20`}>
                                                    {is_subscribed ? 'Yes' : 'No'}
                                                </button>
                                            </td>
                                            <td>{subscription_date ? createDatewithTime(subscription_date) : '-'}</td>

                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersReferral