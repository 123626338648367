import moment from 'moment';
import React from 'react'
import { Modal } from 'react-bootstrap';

const LeadDuplicateModal = ({ show, closeModal, duplicateData, handleLeadRoutes, leadChangedFrom }) => {

    return (
        <Modal
            className="leadduplication"
            show={show} onHide={closeModal}
            centered
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <a className="close" onClick={closeModal}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725"></path></svg></a>
                <h1 className='fz24 black fw-po-medium ls1 po text-center d-flex align-items-center mb-0 mx-auto justify-content-center'><svg className='mr-8' xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
                    <path d="M16.8554 4.97363C17.8456 3.43442 20.0958 3.43442 21.086 4.97363L35.9626 28.0979C37.0395 29.7718 35.8377 31.974 33.8473 31.974H4.09411C2.10373 31.974 0.901903 29.7718 1.97878 28.0979L16.8554 4.97363Z" fill="#FF974A" stroke="#FF974A" strokeWidth="2.26374" strokeLinejoin="round" />
                    <path d="M17.5 14.25V21.75C17.5 22.5784 18.1716 23.25 19 23.25C19.8284 23.25 20.5 22.5784 20.5 21.75V14.25C20.5 13.4216 19.8284 12.75 19 12.75C18.1716 12.75 17.5 13.4216 17.5 14.25Z" fill="white" />
                    <path d="M20.5 26.25C20.5 27.0784 19.8284 27.75 19 27.75C18.1716 27.75 17.5 27.0784 17.5 26.25C17.5 25.4216 18.1716 24.75 19 24.75C19.8284 24.75 20.5 25.4216 20.5 26.25Z" fill="white" />
                </svg> Lead Already Exists</h1>

                <p className='mt-16 mb-24 black-dark-700 text-center ro'><span className='black fw-po-medium'>{duplicateData?.currentLeadName}</span> cannot be {leadChangedFrom === 'new' ? 'created' : 'updated'} with <span className='black fw-po-medium'>{duplicateData?.mobile}</span> as it already exists in the platform with the following details.</p>
                <div className='duplicat-lead-list'>
                    <div className='row'>
                        <div className="col-5">  Name</div>
                        <div className="col-7"> <span className='three-dots'>{duplicateData?.name || '-'}</span> </div>
                    </div>
                    <div className='row'>
                        <div className="col-5">  Mobile No.</div>
                        <div className="col-7"> {duplicateData?.mobile || '-'}</div>
                    </div>
                    <div className='row'>
                        <div className="col-5">  Status</div>
                        <div className="col-7">  {duplicateData?.status || '-'}</div>
                    </div>
                    <div className='row'>
                        <div className="col-5">  Created Date</div>
                        <div className="col-7">  {moment(duplicateData?.created_on).format("h:mm a, Do MMM YYYY") || '-'}</div>
                    </div>
                    <div className='row'>
                        <div className="col-5">  Assigned User</div>
                        <div className="col-7">  <span className='three-dots'>{duplicateData?.assigned_to || '-'}</span></div>
                    </div>
                </div>
                <div className="justify-content-center border-0 d-flex modal-footer p-0">
                    <button className="outline-btn" onClick={closeModal}>Cancel</button>
                    <button className='pr-btn ml-12' onClick={() => handleLeadRoutes(`/leads/${duplicateData?.uuid}`)}>
                        Open Lead
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LeadDuplicateModal;