import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { addNewProject, getProjects } from '../../../../../services/private/company.service';
import { ReactComponent as HomeWhiteIcon } from '../../../../../assets/icons/homeWhite.svg';
import { ReactComponent as ProjectIcon } from '../../../../../assets/icons/project_logo.svg';
import { ReactComponent as ResultSearchIcon } from '../../../../../assets/icons/searchicon.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../../assets/icons/searchClose.svg';
import { ReactComponent as NoProjecsIcon } from '../../../../../assets/icons/projectIcon.svg';
import { toast } from 'react-toastify';
import TooltipText from '../../../../../components/Tooltip';

const Projects = ({ show, closeModal, user, getUsersByUUID }) => {

    const [projects, setProjects] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        if (user.projects.length > 0)
            setSelectedProject([...selectedProject, ...user.projects])
    }, [user])

    const getAllProjects = async () => {
        const res = await getProjects('');
        if (res.data.status === 200) {
            let mappedProjects = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.uuid }))
            if (user.projects.length > 0) {
                mappedProjects = mappedProjects.filter(item2 => {
                    return !user.projects.some(item1 => item1.uuid === item2.uuid);
                });
            }
            setProjects(mappedProjects);
            setAllProjects(mappedProjects)
        }
    }

    useEffect(() => {
        getAllProjects()
    }, [])
    const handleAddNew = (project) => {
        const filteredData = projects.filter(item => item.uuid !== project.uuid)
        const filterdAllProject = allProjects.filter(item => item.uuid !== project.uuid)
        setProjects(filteredData);
        setAllProjects(filterdAllProject);
        setSelectedProject([...selectedProject, project]);
    }

    const handleRemove = (project) => {
        const filteredData = selectedProject.filter(item => item.uuid !== project.uuid)
        setSelectedProject(filteredData);
        setProjects([...projects, project]);
        setAllProjects([...allProjects, project])
    }

    const handleSearch = () => {
        const searchedData = allProjects.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        setProjects(searchedData)
    }

    useEffect(() => {
        handleSearch()
    }, [searchText]);

    const handleSubmit = async () => {
        const projectIds = selectedProject.map(item => item.uuid)
        const res = await addNewProject(user.uuid, { projects: projectIds })
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getUsersByUUID();
            closeModal();
        } else {
            toast.error(res.data.message)
        }

    }

    return (
        <RightModal show={show} closeModal=
            {() => {

                closeModal();
            }}
            modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Edit Projects</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='h-4/5 px-4 pt-4 '>
                <div className='h-1/2 space-y-4'>
                    {selectedProject.length > 0 && <>
                        <span className='text-black text-md font-semibold'>
                            Added Projects {selectedProject.length > 0 ? `(${selectedProject.length})` : ''}
                        </span>
                        <div className='overflow-y-auto h-[80%] space-y-4'>
                            {
                                selectedProject.map((project, index) => (
                                    <div className='flex justify-between items-center border rounded-md px-2.5 py-2' key={`${project.uuid}_${index}`}>
                                        <div className='flex items-center w-[80%]'>
                                            {/* <figure className='w-12 h-12 mr-3 mb-0'>
                                                {project?.thumbnail && <img src={project?.thumbnail} alt="" className='h-100 object-fill rounded' />}
                                                {!project?.thumbnail && <span className='bg-[#B2D0FF] w-12 h-12 flex items-center justify-center rounded'> <ProjectIcon /> </span>}
                                            </figure> */}
                                            <div className='w-100'>
                                                <TooltipText title={project.name || ''}>
                                                    <h6 className='m-0 truncate w-100'>{project.name}</h6>
                                                </TooltipText>
                                                {/* <p className='m-0 text-grey200'>{project.developer}</p> */}
                                            </div>
                                        </div>
                                        <div className='w-[20%]'>
                                            <button className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1'
                                                onClick={() => handleRemove(project)}
                                            >Remove</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>}
                    {
                        selectedProject.length === 0 && <div className='h-[80%]'>
                            <div className='flex items-center justify-center h-100 text-black700 flex-col'>
                                <NoProjecsIcon />
                                No Projects Added
                            </div>
                        </div>
                    }

                </div>
                <div className='h-1/2 space-y-4'>
                    <div className='flex items-center justify-between'>

                        <span className='text-black text-md font-semibold'>
                            Add Projects
                        </span>

                        <div className={`' border rounded px-2.5 py-1 w-[70%] flex items-center ${searchText ? 'border-primary' : ''}`}>
                            <SearchIcon className=' ' />
                            <input
                                type="text"
                                placeholder='Search For Project'
                                className=' w-100 focus:outline-none text-md   pl-2'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText &&
                                <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                    <SearchCloseIcon />
                                </div>}
                        </div>
                    </div>
                    <div className='overflow-y-auto overflow-x-hidden h-[65%] space-y-4'>
                        {
                            projects.length > 0 && projects.map((project, index) => (
                                <div className='flex justify-between w-100 items-center border rounded-md px-2.5 py-2' key={`${project.uuid}_${index}`}>
                                    <div className='flex items-center w-[80%]'>
                                        {/* <figure className='w-12 h-12 mr-3 mb-0'>
                                            {project?.media && <img src={project?.media[0]?.url} alt="" className='h-100 object-fill rounded' />}
                                            {!project?.media && <span className='bg-[#B2D0FF] w-12 h-12 flex items-center justify-center rounded'> <ProjectIcon /> </span>}

                                        </figure> */}
                                        <div className='w-100'>
                                            <TooltipText title={project.name || ''}>
                                                <h6 className='m-0 truncate w-100'>{project.name}</h6>
                                            </TooltipText>
                                            {/* <p className='m-0 text-grey200'>{project.developer}</p> */}
                                        </div>
                                    </div>
                                    <div className='w-[15%]'>
                                        <button
                                            className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1'
                                            onClick={() => handleAddNew(project)} >Add</button>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            projects.length === 0 && searchText && <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon className='h-10 w-10' /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Sorry, we couldn’t find any results </h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>
                                    Please try again with different keywords.
                                    {/* We're sorry, but we couldn't find any results for your search.
                                    <span className='d-md-block d-none'>Please try again with different keywords.</span> */}
                                </p>
                            </div>
                        }
                        {
                            projects.length === 0 && !searchText && <div className='flex items-center justify-center flex-column h-100'>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize text-black700'>
                                    No Projects Available
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={` flex flex-row items-center justify-between border-top bg-white border-color absolute bottom-0 w-100 space-x-3 p-3`}>
                <button className='outline-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg hover:bg-black100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-100 !text-lg !font-medium !py-1.5 rounded-lg' style={{ fontSize: '16px' }} onClick={handleSubmit}>Save</button>
            </div>
        </RightModal>
    )
}

export default Projects
