import React from 'react'
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert.svg'
import { Modal } from 'react-bootstrap';

const ExistingUserConfimationModal = ({ show, closeModal, users, roleName, handleSubmit }) => {
    return (
        <Modal show={show} onHide={closeModal} backdrop="static" centered className='w-[400px]'>
            <div className='p-4'>
                <div className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                    <AlertIcon />
                </div>
                <h6 className='mb-2.5'>
                    Caution!
                </h6>
                {
                    users.length === 1 && <div className='mb-2.5'>
                        Are you sure you want to move {users[0].name} from {users[0].role_name} to {roleName}
                    </div>
                }
                {
                    users.length > 1 && <div className="">
                        <div className='mb-2.5'>
                            Are you sure you want to move {users.length} Users from {users.length} roles to {roleName} role
                        </div>
                        <div className='h-[150px] overflow-auto pr-2 relative '>
                            <div class="grid grid-cols-2 border border-b-0 sticky top-0 bg-white">
                                <div className='p-2 bg-grey-400  border-r'>Users</div>
                                <div className='p-2 bg-grey-400'>Exisiting Role</div>
                            </div>
                            {
                                users.map((item, index) => (
                                    <div class="grid grid-cols-2 border">
                                        <div className='p-2 border-r'>{item.name}</div>
                                        <div className='p-2 border-r'>{item.role_name}</div>
                                    </div>

                                ))
                            }
                        </div>
                    </div>
                }

                <div className='grid grid-cols-2 gap-3 mt-3'>
                    <button className='border rounded-lg py-2.5' onClick={closeModal}>Cancel</button>
                    <button className='bg-red text-white rounded-lg py-2.5' onClick={handleSubmit}>Yes</button>
                </div>
            </div>

        </Modal>
    )
}

export default ExistingUserConfimationModal;