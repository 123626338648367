
import React, { useCallback, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageErrorModal from '../../../../../components/Modals/PageErrorModal';
import PreviousLeadsModal from '../../../../../components/Modals/PreviousLeadsModal'
import { createDatewithTime } from '../../../../../helpers/date';
import { getImportJobs, getInetgrationsPageForms, importPreviousLeads } from '../../../../../services/private/company.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ImportPreviousLeads = () => {
  const [showModal, setShowModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [pageForms, setPageForms] = useState();
  const [dateRange, setDateRange] = useState();
  const [pageFormList, setpageFormList] = useState([]);
  const [ignoreDuplicate, setIgnoreDuplicate] = useState('IGNORE');
  const [selectedFormId, setSelectedFormId] = useState()
  const [importedLead, setImportedLead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const location = useLocation();

  const getPageLinkForm = useCallback(() => {
    getInetgrationsPageForms(id).then(res => {
      if (res.data.status === 200) {
        const pageFormList = res.data.data.facebookForms.data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })

        if (localStorage.getItem('formId')) {
          setSelectedFormId(pageFormList.find(item => item.value === localStorage.getItem('formId')))
          setShowModal(true);
        }
        setpageFormList(pageFormList)
        setPageForms(res.data.data);
      }
    }).catch(err => {
      console.log(err)
    })
  }, [])

  const importJobs = useCallback(() => {
    getImportJobs(id).then(res => {
      if (res.data.status === 200) {
        setImportedLead(res.data.data)
      }
    }).catch(err => {
      console.log(err)
    })
  }, []);

  useEffect(() => {
    if (id) {
      getPageLinkForm();
      importJobs();
    }
  }, [id])

  useEffect(() => {
    return (() => {
      if (localStorage.getItem('formId')) {
        localStorage.removeItem('formId')
      }
    })
  }, []);

  const handleFormChange = useCallback((value) => {
    setSelectedFormId(value)
  }, []);

  const handleDateRange = useCallback((date) => {
    setDateRange(date)
  }, []);

  const hndleDuplicateIgnore = useCallback((val) => {
    setIgnoreDuplicate(val)
  }, []);

  const getPreviousLeads = useCallback(() => {

    if (!selectedFormId) {
      toast.error('Please select form');
      return;
    }
    if (!dateRange) {
      toast.error('Please select date range');
      return;
    }

    const payload = {
      from: dateRange.from,
      to: dateRange.to,
      lead_duplicacy: ignoreDuplicate
    }
    setIsLoading(true);
    importPreviousLeads(selectedFormId.value, payload).then(res => {
      setShowModal(false);
      setSelectedFormId('');
      setDateRange();
      importJobs();
      if (localStorage.getItem('formId')) {
        localStorage.removeItem('formId')
      }
      setIsLoading(false);
      toast.success('Import lead success');
      // closeModal();
    }).catch(err => {
      console.log({ err })
      setSelectedFormId('');
      setDateRange();
      setIsLoading(false);
      if (err.response.data.status === 404) {
        toast.warning(err.response.data.message)
      } else {
        toast.error(err.response.data.message)
      }
      closeModal();
    })
  }, [dateRange, ignoreDuplicate, selectedFormId]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const closeSideModal = useCallback(() => {
    setOpenErrorModal(false);
  }, [])

  return (
    <>
      <div className="w-100 fbintegration">
        <div className="settings-title">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                <ul className="d-flex align-items-center justify-content-start flex-row mb4">

                  <li>
                    <Link to='/settings/integrations/all-integrations'>All Integration</Link>
                  </li>
                  <li className='flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                  </svg>
                    <Link to='/settings/integrations/all-integrations/facebook-forms'>Facebook Integration</Link>
                  </li>
                  <li className='flex items-center'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                    <path d="M4 3.5L8.5 8L4 12.5" stroke="#696974" />
                  </svg> {location.state.pageName}</li>

                </ul>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-sm-8">
              <h1 className='fz24 black mb-8 fw-po-medium ls5'>
                {/* <Link to='/settings/integrations/all-integrations/facebook-forms' className='pr-hover'>
                  <svg className='mr-8' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9949 9.34939 20.9397 6.80881 19.0655 4.93455C17.1912 3.06028 14.6506 2.00508 12 2ZM12 20.4615C10.3265 20.4615 8.69052 19.9653 7.29902 19.0355C5.90753 18.1057 4.823 16.7842 4.18256 15.2381C3.54213 13.6919 3.37456 11.9906 3.70105 10.3492C4.02754 8.70786 4.83343 7.20016 6.01679 6.01679C7.20016 4.83342 8.70786 4.02754 10.3492 3.70105C11.9906 3.37456 13.6919 3.54212 15.2381 4.18256C16.7842 4.82299 18.1057 5.90753 19.0355 7.29902C19.9653 8.69051 20.4615 10.3265 20.4615 12C20.459 14.2434 19.5667 16.3941 17.9804 17.9804C16.3941 19.5667 14.2434 20.459 12 20.4615ZM16.6154 12C16.6154 12.204 16.5343 12.3997 16.3901 12.5439C16.2458 12.6882 16.0502 12.7692 15.8462 12.7692H10.0096L11.9615 14.7212C12.1054 14.8654 12.1862 15.0607 12.1862 15.2644C12.1862 15.4681 12.1054 15.6635 11.9615 15.8077C11.8899 15.8798 11.8044 15.9365 11.7102 15.9745C11.616 16.0125 11.515 16.031 11.4135 16.0288C11.3133 16.0308 11.2139 16.0122 11.1213 15.9742C11.0286 15.9362 10.9448 15.8795 10.875 15.8077L7.60577 12.5481C7.46133 12.4022 7.38029 12.2053 7.38029 12C7.38029 11.7947 7.46133 11.5978 7.60577 11.4519L10.875 8.19231C11.0213 8.05972 11.213 7.98849 11.4104 7.99335C11.6078 7.99821 11.7958 8.07879 11.9354 8.21842C12.0751 8.35804 12.1556 8.54601 12.1605 8.74341C12.1654 8.94081 12.0941 9.13252 11.9615 9.27884L10.0096 11.2308H15.8462C16.0502 11.2308 16.2458 11.3118 16.3901 11.4561C16.5343 11.6003 16.6154 11.796 16.6154 12Z" fill="#44444F" />
                  </svg>
                </Link> */}
                Import previous leads
              </h1>
              {/* <p className='fz16 black-dark-700 ls5 mb-0'>{pageForms && pageForms.length} forms Connected from this page.</p> */}

            </div>
            <div className="col-xl-7 col-sm-4 d-flex justify-content-sm-end align-items-sm-end align-items-start mt-xl-0 mt-3">
              <button
                onClick={() => {
                  // setConnectFacebookForm(!showConnectFacebookForm);
                  // clearSelectedIntegration();
                  setShowModal(true)
                }}
                className="pr-btn px-16 flex items-center"
              >
                <svg className="mr-10" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.16699 6.66699L8.50033 10.0003L11.8337 6.66699" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.5 10V2" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                New Import
              </button>
            </div>
          </div>
        </div>
        <div className="mt-24 ml-24">
          <div className={`${importedLead?.length === 0 ? 'def-table pr-24 pl-0 fb-lead-integration overflowY-hidden' : 'def-table pr-24 pl-0 fb-lead-integration'}`}>
            <table className='table'>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Imported By</th>
                  <th>Import Count</th>
                  <th>Failed Count</th>
                  <th>Total Leads</th>
                  <th>Project</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {importedLead && importedLead.map((lead, index) => {
                  return (
                    <tr key={`${index}_${lead.id}`}>
                      <td>{index + 1}</td>
                      <td>
                        {lead.created_by > 20 ?
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Tooltip id="button-tooltip" >
                                <div className='info-msg'>

                                  <p className=''>{lead.created_by}</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className='virticledots cursor-pointer'>{lead.created_by}</span>
                          </OverlayTrigger>
                          :
                          <span className=''>{lead.created_by}</span>
                        }


                      </td>
                      <td>{lead.inserted}</td>
                      <td>{lead.failed}</td>
                      <td>{lead.total_leads}</td>
                      <td>{lead.project_name}</td>
                      <td><button className={`hot-btn fz14 fw-po-medim px-32 ${lead.status === 'imported' ? 'hot-btn' : 'dead-btn'} remove-hover text-capitalize`} style={{ cursor: 'auto' }}>{lead.status}</button></td>
                      <td>{createDatewithTime(new Date(lead.created_on))}</td>
                      <td className='dropdown view-more'>
                        <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                        </button>
                        <div className="dropdown-menu w-auto" aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" href={lead.report} download>Download Failed Record</a>
                        </div>
                      </td>
                    </tr>

                    // <tr key={`${index}_${form.id}`}>
                    //   <td >{index + 1}</td>
                    //   <td onClick={() => setOpenErrorModal(true)}>
                    //     {form.form_name}
                    //   </td>
                    //   <td>
                    //     {form.form_id}
                    //   </td>
                    //   <td>{form.project_name}</td>
                    //   <td>{form.total_leads}</td>
                    //   <td>234</td>
                    //   {/* <td className='hot-btn fz14 fw-po-medim px-32'>{form.is_active ? 'Active' : 'Inactive'}</td> */}
                    //   <td><button className='hot-btn fz14 fw-po-medim px-32'>{form.is_active ? 'Active' : 'Inactive'}</button></td>
                    //   {/* <td className='file-name'>{form.created_by}</td> */}
                    //   <td>{createDatewithTime(new Date(form.created_on))}	</td>
                    //   <td className='dropdown view-more'>
                    //     <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    //       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                    //     </button>
                    //     <div className="dropdown-menu w-auto" aria-labelledby="dropdownMenuButton">
                    //       <a className="dropdown-item">Download Failed Record</a>
                    //     </div>
                    //   </td>

                    // </tr>
                  )
                })}
              </tbody>
            </table>
            {importedLead?.length === 0 && <div className='d-flex justify-content-center flex-column align-items-center text-center h-100'>
              <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
              <h2 className="po black fz16 fw-po-mediumm mb-0">No Imports Yet</h2>
              <button className="pr-btn px-32 py-8 mt-24" onClick={() => {
                // setConnectFacebookForm(!showConnectFacebookForm);
                // clearSelectedIntegration();
                setShowModal(true)
              }}>New Import</button>
            </div>}
          </div>
        </div >
      </div>
      {
        showModal && <PreviousLeadsModal
          showModal={showModal}
          closeModal={closeModal}
          handleDateRange={handleDateRange}
          getPreviousLeads={getPreviousLeads}
          pageFormList={pageFormList}
          ignoreDuplicate={ignoreDuplicate}
          hndleDuplicateIgnore={hndleDuplicateIgnore}
          selectedFormId={selectedFormId}
          handleFormChange={handleFormChange}
          isLoading={isLoading}
        />
      }
    </>
  )
}

export default ImportPreviousLeads