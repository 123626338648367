import React, { useState } from 'react'
import { Disclosure, Switch } from '@headlessui/react'
import { Formik } from 'formik';
import makeUploadRequest from '../../services/api/uploadRequest';
import { useHistory } from 'react-router-dom';
import InputBox from '../InputGroup/InputBox';
import { MentorService } from '../../services/mentor';
import { WorkshopService } from '../../services/workshop';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useEffect } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TooltipText from '../Tooltip';
import InputSelect from '../InputGroup/InputSelect';
import { getAllSubscription } from '../../services/public/public.service';

const CreateWorkshop = () => {

  let history = useHistory();
  let [section_included, setSection_included] = useState(false);
  let [certificate_section, setCertificate_section] = useState(false);
  let [categories, setCategories] = useState([])
  let [mentors, setMentors] = useState([])
  let [mentor_opt, setMentorOpt] = useState([]);
  let [selected_mentor_id, setSelectedMentorId] = useState([]);
  let [touched_mentor_id, setTouchedMentorId] = useState(false);
  let [section_bps, setSectionBps] = useState(['', '', '', '', '', '', '', '']);
  let [faq_pts, setFaqPts] = useState([]);
  let [detail_bps, setDetailBps] = useState(['', '', '', '']);
  const [planList, setPlanList] = useState([]);

  const handleDetailPtsChange = (e, index) => {
    let bulletData = detail_bps;
    bulletData[index] = e.target.value;
    setDetailBps(bulletData);
  }
  const handleBulletChange = (e, index) => {
    let bulletData = section_bps;
    bulletData[index] = e.target.value;
    setSectionBps(bulletData);
  }
  const handleFaqQuestionChange = (e, index) => {
    let bulletData = faq_pts;
    bulletData[index].question = e.target.value;
    setFaqPts(bulletData);
  }
  const handleFaqAnswerChange = (e, index) => {
    let bulletData = faq_pts;
    bulletData[index].answer = e.target.value;
    setFaqPts(bulletData);
  }

  const createFaq = () => {
    let arr = [];
    for (let i = 0; i < 8; i++) arr.push({ question: '', answer: '' });
    setFaqPts(arr);
  }
  const [files, setFiles] = useState({})

  const getCategories = async () => {
    await MentorService.getAllCategories().then(res => {
      if (res.status === 200) {
        setCategories(res.data.map(c => ({ label: c, value: c })))
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data.message)
    })
  }
  const getMentors = async () => {
    await MentorService.getAllMentors().then(res => {
      if (res.status === 200) {
        console.log(res.data)
        let ar2 = res.data.map((data) => { return { value: data.id, label: data.name } });
        setMentorOpt(ar2)
        // setMentors(res.data)
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data.message)
    })
  }

  const getPlans = async () => {
    const res = await getAllSubscription();
    if (res.data.status === 200) {
      setPlanList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
    }
  }

  const addWorkshop = async (formData) => {
    const toastId = toast.loading('Uploading Workshop Data');

    return await WorkshopService.addWorkshop(formData).then(res => {
      if (res.status === 200) {
        console.log(res.data.data);
        toast.success(res.data.message, {
          id: toastId
        });
        window.location.href = ("/settings/learning-centre/all-workshops");
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data, {
        id: toastId
      })
    })

  }

  useEffect(() => {
    getCategories();
    getMentors();
    createFaq();
    getPlans();
  }, [])

  return (
    <>
      {/* <input type="file" className='form-control' placeholder=' ' id="test" onChange={()=>{
  let formData=new FormData();
  formData.append("item",document.getElementById('test').files[0]);
  makeUploadRequest("naspidn","POST",formData);
  // console.log(document.getElementById('test').files[0]);
}}/> */}
      {/* <br/><hr/><br/> */}
      <div className="w-full basis-full	h-full overflow-auto pb-8">
        <div className="title-wraper !p-6 border-b w-full sticky top-0 bg-white z-10">
          <div className="flex w-full basis-full">
            <div className='w-100 basis-6/12'>
              <h1 className="fz24 black fw-po-medium">Create Workshop</h1>
            </div>
          </div>
        </div>
        <Formik
          initialValues={
            {
              name: '',
              zoom_link: '',
              card_thumbnail_temp: '',
              start_date: '',
              end_date: '',
              start_time: '',
              end_time: '',
              detail_thumbnail_temp: '',
              category: '',
              short_description: '',
              section_included_points: [],
              faq_questions: [],
              detail_card_points: [],
              payment_plan_id: ''
            }
          }
          validate={values => {
            const errors = {};
            if (!values.name) errors.name = 'Required';
            if (!values.zoom_link) errors.zoom_link = 'Required';
            if (!values.start_date) errors.start_date = 'Required';
            // if (!values.end_date) errors.end_date = 'Required';
            if (!values.start_time) errors.start_time = 'Required';
            if (!values.end_time) errors.end_time = 'Required';
            if (!values.category) errors.category = 'Required';
            if (!values.short_description) errors.short_description = 'Required';
            if (!values.card_thumbnail_temp) errors.card_thumbnail_temp = 'Required';
            if (!values.detail_thumbnail_temp) errors.detail_thumbnail_temp = 'Required';
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {

            let bullet_pts = [], card_pts = [], faq_q = [], faq_a = [], faq = [];

            values.section_included_points = section_bps.filter(pt => pt !== "");
            values.faq_questions = faq_pts.filter(faq => faq.question !== "" && faq.answer !== "");

            for (let i of document.querySelectorAll('.detail_card_points')) {
              if (i.value !== "") card_pts.push(i.value);
            }
            values.detail_card_points = detail_bps.filter(pt => pt !== "");


            // console.log(obj);

            // const form_data_body = convertToFormData({ ...obj, ...files });

            console.log("before form 1")

            const formData1 = new FormData();
            formData1.append("document_upload", files.card_thumbnail_temp)
            let card_thumbnail_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData1);

            const formData2 = new FormData();

            console.log("before form 2")

            formData2.append("document_upload", files.detail_thumbnail_temp)
            let detail_thumbnail_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData2);

            let card_thumbnail = card_thumbnail_obj.data.data.s3_url;
            let detail_thumbnail = detail_thumbnail_obj.data.data.s3_url;
            let mentorIds = [];
            mentorIds = selected_mentor_id.map(id => parseInt(id.value));
            if (selected_mentor_id.length === 0) {
              toast.error("Please select atleast 1 mentor")
              return;
            }

            console.log("before creating object")
            let obj = {
              name: values.name,
              zoom_link: values.zoom_link,
              start_date: moment((values.start_date), "YYYY-MM-DD").toISOString(),
              end_date: moment((values.end_date), "YYYY-MM-DD").toISOString(),
              start_time: moment(`${moment(values.start_date).format('YYYY-MM-DD')} ${moment(values.start_time).format('hh:mm a')}`).toISOString(),
              end_time: moment(`${moment(values.end_date).format('YYYY-MM-DD')} ${moment(values.end_time).format('hh:mm a')}`).toISOString(),
              category: values.category?.value,
              mentor_id: mentorIds,
              short_description: values.short_description,
              section_included_points: values.section_included_points,
              faq_questions: values.faq_questions,
              detail_card_points: values.detail_card_points,
              payment_plan_id: values.payment_plan_id,
              section_included, certificate_section, card_thumbnail, detail_thumbnail
            }

            console.log(obj);
            addWorkshop(obj).then(data => console.log(data));
            setSubmitting(false);
          }}

        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className='!px-6' onSubmit={handleSubmit}>
              <div className="w-10/12" >
                <Disclosure>
                  {({ open }) => (
                    < div className=' border-b py-3.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-base text-black font-medium focus:outline-none">
                        <span>Basic Details</span>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="!mt-4 grid grid-cols-2 !gap-4">
                        <div>
                          <div className="form-group">
                            <InputBox
                              title="Workshop Name"
                              name="name"
                              error={errors.name}
                              touched={touched.name}
                              placeholder="e.g. Marketing Workshop"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.name}
                            />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <InputBox
                              title="Zoom Link"
                              name="zoom_link"
                              error={errors.zoom_link}
                              touched={touched.zoom_link}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.zoom_link}
                            />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Workshop Start Date</label>
                            <DatePicker
                              selected={values.start_date}
                              className="form-control"
                              style={(errors.start_date && touched.start_date) && { border: "2px solid #ef4444" }}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'start_date'
                                  }
                                }
                                handleChange(e)
                              }} />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">

                            <label>Workshop Start time </label>
                            <DatePicker
                              selected={values.start_time}
                              className="form-control" style={(errors.start_time && touched.start_time) && { border: "2px solid #ef4444" }}
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'start_time'
                                  }
                                }
                                handleChange(e)
                              }
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Workshop End Date</label>
                            <DatePicker selected={values.end_date} className="form-control" style={(errors.end_date && touched.end_date) && { border: "2px solid #ef4444" }}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'end_date'
                                  }
                                }
                                handleChange(e)
                              }} />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Workshop End time </label>
                            <DatePicker
                              selected={values.end_time}
                              className="form-control" style={(errors.end_time && touched.end_time) && { border: "2px solid #ef4444" }}
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'end_time'
                                  }
                                }
                                handleChange(e)
                              }
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>

                        <div className="form-group w-full">
                          <label className='block text-14 text-black700 mb-2'>Card Thumbnail (Less than 1mb, 267 X 168px)</label>
                          <input
                            type="file"
                            className='h-[45px] w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-sm placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                            placeholder=' '
                            id="card_thumbnail_temp"
                            name="card_thumbnail_temp"
                            style={(errors.card_thumbnail_temp && touched.card_thumbnail_temp) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {
                              setFiles({ ...files, [e.target.name]: e.target.files[0] })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            value={values.card_thumbnail_temp}
                          />
                        </div>
                        <div></div>
                        <div>
                          <div className="form-group">
                            <label>Workshop Detail Thumbnail </label>
                            <input type="file"
                              id="detail_thumbnail_temp"
                              className='h-[45px] w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-sm placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                              placeholder=' '
                              name="detail_thumbnail_temp"
                              style={(errors.detail_thumbnail_temp && touched.detail_thumbnail_temp) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                setFiles({ ...files, [e.target.name]: e.target.files[0] })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.detail_thumbnail_temp} />
                          </div>
                        </div>
                        <div></div>
                        <div>
                          <div className="form-group">
                            <InputSelect
                              label={'Workshop Category'}
                              defaultValue={[]}
                              isMulti={false}
                              name="category"
                              placeholder={'Select Category'}
                              options={categories}
                              className="basic-multi-select form-control"
                              classNamePrefix="select"
                              value={values.category}
                              style={(errors.category && touched.category) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                const value = { target: { name: "category", value: e } }
                                handleChange(value)
                              }}
                              onBlur={handleBlur}
                            />
                            {/* <label>Workshop Category <span className="font-bold text-red-500">{errors.category && touched.category && errors.category}</span></label>
                              <select className='form-control'                                                                                                                                                                
                              >
                                <option value="">Select Category </option>
                                {categories && categories.map(category => <option value={category}>{category}</option>)}
                              </select> */}
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Mentor <span className="font-bold text-red-500">{selected_mentor_id.length === 0 && touched_mentor_id && "Required"}</span></label>
                            {/* <select className='form-control' id=""
                                style={(errors.mentor_id && touched.mentor_id) && { border: "2px solid #ef4444" }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mentor_id}
                                name="mentor_id"
                              >
                                <option value="">Select Mentor</option>
                                {mentors && mentors.map(mentor => <option value={mentor.id}>{mentor.name}</option>)}
                              </select> */}
                            <InputSelect
                              defaultValue={[]}
                              isMulti
                              name="mentor_id"
                              options={mentor_opt}
                              className="basic-multi-select form-control"
                              classNamePrefix="select"
                              style={(selected_mentor_id.length === 0 && touched_mentor_id) && { border: "2px solid #ef4444" }}
                              onChange={(e) =>
                                setSelectedMentorId(e)
                              }
                              onBlur={() => setTouchedMentorId(true)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Plan </label>
                          <InputSelect
                            value={values.payment_plan_id ? planList.find(i => i.id === values.payment_plan_id) : ''}
                            name="payment_plan_id"
                            options={planList}
                            className="basic-multi-select form-control"
                            classNamePrefix="select"
                            // style={(values.selected_mentors.length === 0 && touched.selected_mentors) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {
                              const changeVal = {
                                target: {
                                  value: e.id,
                                  name: "payment_plan_id"
                                }
                              }
                              // console.log(e);
                              handleChange(changeVal)
                            }
                            }
                          // onBlur={() => setTouchedMentorId(true)}
                          />
                        </div>
                        <div className="form-group col-span-2">
                          <label>Short Description <span className="font-bold text-red-500">{errors.short_description && touched.short_description && errors.short_description}</span></label>
                          <textarea id="" cols="2" rows="10" className='form-control' placeholder='Add short description'
                            style={(errors.short_description && touched.short_description) && { border: "2px solid #ef4444" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description}
                            name="short_description"
                          ></textarea>
                        </div>


                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className='w-10/12 border-b py-2.5 flex'>
                <div className='w-3/12'>
                  <h2 className='text-base text-black font-medium'>What’s included Section</h2>
                </div>
                <div className='w-4/12'>
                  <div className="">
                    <Switch
                      checked={section_included}
                      onChange={() => setSection_included(!section_included)}
                      onBlur={handleBlur}
                      className={`${section_included ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${section_included ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b py-2.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-basetext-black font-medium focus:outline-none">
                        <div className='flex items-center text-base'>
                          What you’ll learn in the workshop
                          <div className='info-hover ml-2'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#92929D" />
                              <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#92929D" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#92929D" />
                            </svg> */}
                            <div className="hover-data drop-shadow-default">
                              <TooltipText title={'Even number of inputs is advised'}>
                                <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#0062FF" />
                                  <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#0062FF" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#0062FF" />
                                </svg>
                              </TooltipText>

                            </div>
                          </div>
                        </div>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="">

                        <div className='grid grid-cols-2 !gap-4 !mt-4'>
                          {
                            section_bps.map((pt, index) =>
                              <div className="">
                                <label>Bullet Point ({index + 1})</label>
                                <input type="text" className='included_bullet_points form-control' placeholder=''
                                  defaultValue={pt}
                                  onChange={(e) => handleBulletChange(e, index)}
                                />
                              </div>
                            )
                          }
                        </div>

                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className='w-10/12 border-b py-2.5 flex mb-2.5'>
                <div className='w-3/12'>
                  <h2 className='text-base text-black font-medium'>Certificate Section </h2>
                </div>
                <div className='w-4/12'>
                  <div className="">
                    <Switch
                      checked={certificate_section}
                      onChange={() => setCertificate_section(!certificate_section)}
                      onBlur={handleBlur}
                      className={`${certificate_section
                        ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${certificate_section
                          ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-basetext-black font-medium focus:outline-none">
                        <div className='flex items-center text-base'>
                          FAQ Fold
                          <div className='info-hover ml-2'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#92929D" />
                              <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#92929D" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#92929D" />
                            </svg> */}
                            <div className="hover-data drop-shadow-default">
                              <TooltipText title={'Even number of inputs is advised'}>
                                <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#0062FF" />
                                  <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#0062FF" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#0062FF" />
                                </svg>
                              </TooltipText>

                            </div>
                          </div>
                        </div>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="">
                        {
                          faq_pts.map((faq, index) =>
                            <div className="grid grid-cols-2 !gap-4 !mt-4 ">
                              <div>
                                <div className="form-group">
                                  <label>Question ({index + 1})</label>
                                  <textarea type="text" className='form-control faq_question' placeholder=''
                                    defaultValue={faq.question}
                                    onChange={(e) => handleFaqQuestionChange(e, index)}
                                  />
                                </div>
                              </div>
                              <div className="...">
                                <div className="form-group">
                                  <label>Answer ({index + 1})</label>
                                  <textarea type="text" className='form-control faq_answer' placeholder=''
                                    defaultValue={faq.answer}
                                    onChange={(e) => handleFaqAnswerChange(e, index)}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5 mt-2.5'>
                      <Disclosure.Button className="flex w-full text-base justify-between rounded text-basetext-black font-medium focus:outline-none">
                        <span>Detail Card Summary</span>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="">
                        <div className="grid grid-cols-2 !gap-4 !mt-4">
                          {detail_bps.map((pt, index) =>

                            <div className="">
                              <label>Bullet Point ({index + 1})</label>
                              <input type="text" className='detail_card_points form-control' placeholder=''
                                defaultValue={pt}
                                onChange={(e) => handleDetailPtsChange(e, index)}
                              />

                            </div>
                          )
                          }
                        </div>

                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className="w-10/12 mt-6" >
                <div className="flex">
                  <div className='!text-primary border border-primary text-base font-medium rounded mr-4 px-10 py-2 cursor-pointer'
                    onClick={() => { window.location.href = '/settings/learning-centre/all-workshops'; }}
                  >Cancel</div>
                  <button
                    type="submit"
                    className='!bg-primary text-base font-medium text-white px-8 py-2 rounded hover:opacity-90'
                  >Save & Review</button>
                </div>
              </div>
            </form>
          )}

        </Formik>
      </div>
    </>
  )
}

export default CreateWorkshop