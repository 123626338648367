import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import SmallCenterModal from '../../../../components/Modals/small-center-modal';
import ExpiryInfoIcon from '../../../../assets/icons/subscription_expiry.png';
import { useHistory } from "react-router-dom";
import moment from 'moment';

const CRMExpiryModal = ({ show, closeModal, userProfile }) => {
    const history = useHistory();
    return (
        <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'w-[552px] subscription-expiry-modal'}>
            <div className={`!px-6 !pb-6 !pt-12 relative`}>
                <div className='absolute -top-6 left-0 mx-auto text-center w-full flex items-center justify-center'>
                    <img src={ExpiryInfoIcon} className='h-16 w-16' />
                </div>
                <h2 className='text-2xl black text-center font-semibold mb-0 inter'>Propacity CRM Closure Notice</h2>
                <button onClick={closeModal} className='border-0 bg-tr absolute right-5 top-5'><CloseIcon /></button>
                <div className='!my-6 bg-[#F2F6FF] border-2 border-[#D7E4FF]  rounded-xl !p-6 inter sm:text-base '>
                    <p className='inter'>
                        We regret to inform you that our app will no longer be operational after <span className='font-semibold'>{moment(userProfile?.company?.subscription_expiry).format('Do MMM YYYY')}</span> .
                    </p>
                    <p className='inter'>
                        Please export all your leads from the system before this date to avoid any data loss.
                    </p>
                    <p className='inter'>
                        Thank you for your understanding and support.
                    </p>
                </div>


                <div className='w-full '>
                    {/* <button className='border border-primary !py-3 text-primary rounded-lg' onClick={closeModal}>Got It</button> */}
                    <button className='pr-btn btn-primary-bg !py-3 !rounded-[10px] w-100 mt-0 font-semibold' onClick={() => {
                        history.push('/settings/import-export/export')
                        localStorage.removeItem('check_expiry')
                        closeModal()
                    }}>Export Leads</button>

                </div>
            </div>
        </SmallCenterModal>
    )
}

export default CRMExpiryModal