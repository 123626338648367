import makeRequest, { formRequest } from "../services/api/makeRequest";
import url from "../services/api/urls";
import { RequstMethods } from "../services/api/requestMethods";

export const MentorService = {
    async getAllMentors() {
        return await makeRequest(url.learning_center.mentor.getAllMentor, RequstMethods.GET);
    },
    async getAllCategories() {
        return await makeRequest(url.learning_center.mentor.getAllCategories, RequstMethods.GET);
    },
    async addMentor(formData){
        return await makeRequest(url.learning_center.mentor.createMentor, RequstMethods.POST, formData);
    },
    async getMentorById(uuid){
        return await makeRequest(url.learning_center.mentor.getMentorById+"/"+uuid, RequstMethods.GET);
    },
    async deleteMentor(uuid){
        return await makeRequest(url.learning_center.mentor.deletementor+"/"+uuid, RequstMethods.DELETE);
    },
    async updateMentorData(uuid, mentorData) {
        return await makeRequest(url.learning_center.mentor.updateMentorData+"/"+uuid, RequstMethods.PUT, mentorData);
    }

}
