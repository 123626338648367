import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner';
import InputSelect from '../InputGroup/InputSelect'
import Secure from '../Secure'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let RE_ARRANGE_LIsT = ["full_name", 'name', 'mobile', "phone_number", "email"];

const LeadMapping = ({
    updateStep,
    platformField,
    handleChangeMapping,
    editMapping,
    facebookQuestions,
    handleSumitMapping,
    leadMapping,
    updateFbIntegration,
    submitingIntegration,
    integrationState,
    handleLeadMappingPrevious
}) => {

    const [isError, setError] = useState(false);
    const [sortedQuestions, setSortedQuestions] = useState([]);

    const customSort = (a, b) => {
        for (let i = 0; i < RE_ARRANGE_LIsT.length; i++) {
            let prop = RE_ARRANGE_LIsT[i];
            if (a.key === prop) {
                return -1;
            } else if (b.key === prop) {
                return 1;
            }
        }
        return 0;
    }
    useEffect(() => {
        const sortedQuestions = facebookQuestions.sort(customSort);
        setSortedQuestions(sortedQuestions)
    }, [facebookQuestions]);

    const mappedValue = (question) => {
        let value;
        leadMapping.forEach(field => {
            platformField.forEach(platform => {
                if (field.facebookValue === question.key && platform.value === field.crmValue) {
                    value = platform
                    return;
                }
            })
        })
        return value
    }

    const handleValidation = () => {
        if (facebookQuestions.length > leadMapping.length) {
            setError(true);
        } else {
            setError(false);
            handleSumitMapping();
        }
    }

    return (
        <>
            <div className="blankdiv"></div>
            <div className='pl-24 h-full overflow-hidden'>

                <div className='scroll-div !pb-5'>
                    <div className='formmapingmain'>
                        <div className="lead-maping leadMapping ">

                            <div className="mpaing-body">
                                <div className="maphead">
                                    <div className="s_no">S.No</div>
                                    <div className="forms_fields">Forms Fields (FB)</div>
                                    <div className="platform_fields">Propacity Platform Fields</div>
                                </div>
                                <div className="map-fileds">
                                    {
                                        sortedQuestions && sortedQuestions.map((question, index) => {
                                            return (
                                                <div className="ledmapingrow" key={`${question.key}_${index}`}>
                                                    <div className="s_no d-lg-inline-flex d-none">
                                                        {index + 1}
                                                    </div>

                                                    <div className="forms_fields">
                                                        <span className='d-lg-none'>Field {index + 1} : </span>
                                                        {question.label.length > 56 ?
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            <p className=''>{question.label} <sup>*</sup></p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >

                                                                <span className='cursor-pointer'>{question.label.substring(0, 56)}... <sup>*</sup></span>

                                                            </OverlayTrigger>
                                                            :
                                                            <span className=''>{question.label} <sup>*</sup></span>
                                                        }


                                                    </div>
                                                    <div className="platform_fields">
                                                        <InputSelect
                                                            name='Facebook Accounts'
                                                            options={platformField}
                                                            value={mappedValue(question)}
                                                            onChange={(value) => handleChangeMapping(value, question.key, question.label, index)}
                                                            placeholder='Select Platform field'
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {isError && <div className="invalid">
                                    <p className='input-feedback'> Please map all the fields! </p>
                                </div>}
                            </div>
                        </div>
                        <div className="unmapedform">
                            <div className='receive-unmapped-forms'>
                                {/* <h2 className='ro fz14 fw-po-medium black mb-6 ro ls5'>Capture unmapped form leads</h2> */}
                                <p className='ro fz14 mb-0 black-dark-700 ro ls5'>Facebook fields are automatically mapped with Platform fields. You can change them if needed.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="footer-modal d-flex align-items-center justify-content-end">
                <Secure />
                <button className="outline-btn fz16 py-6 d-table ml-auto" onClick={handleLeadMappingPrevious}>Previous</button>
                <button
                    className="pr-btn fz16 py-6 mr-20"
                    onClick={() => {
                        if (editMapping) {
                            updateFbIntegration();
                        } else {
                            handleValidation();
                        }
                    }}
                    disabled={submitingIntegration}
                >
                    {!submitingIntegration ? 'Submit' : ''}
                    {submitingIntegration &&
                        <Oval
                            height={17}
                            width={30}
                            color="#FFFFFF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#FFFFFF"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    }
                </button>
            </div>

        </>
    )
}

export default LeadMapping