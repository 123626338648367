import moment from 'moment';
import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Image, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import searchIcon from "../../../../../assets/icons/search.svg";
import CancelCSV from '../../../../../components/Modals/cancel-csv';
import { ReactComponent as ResultSearchIcon } from '../../../../../assets/icons/searchicon.svg'
import { acresToggleUnmappedLeads, get99AcresProfiles } from '../../../../../services/private/company.service';
import { ReactComponent as Pageback } from '../../../../../assets/icons/Pageback.svg';

const Manage99AcresAccounts = () => {
    const [leads, setLeads] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [unmappedLeadsModal, setUnmappedLeadsModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const getAllPgeIntegration = async () => {
        await get99AcresProfiles(searchText).then((res) => {
            if (res.status === 200) {
                setLeads(res.data.data);
            }
        }).catch((err) => {
            console.log({ err })
        });
    };

    const handleToggle = async (uuid, toggle, index) => {
        await acresToggleUnmappedLeads(uuid, { allow_unmapped_leads: toggle }).then((res) => {
            if (res.status === 200) {
                let newLeads = [...leads]
                newLeads[index].allow_unmapped_leads = toggle
                setLeads(newLeads);
                if (toggle) {
                    toast.success('Receiving unmapped project leads');
                } else {
                    toast.success('Blocking unmapped project leads ');
                }
            }
        }).catch((err) => {
            console.log({ err })
        });

        setUnmappedLeadsModal(false);
    }

    useEffect(() => {
        getAllPgeIntegration();
    }, [searchText]);

    const clearSearch = useCallback(() => {
        setSearchText('');
    })

    return (
        <>
            <div className="w-100 flex flex-col h-full">
                <div className="settings-title mb-16 manage-account-title">
                    <div className="row">
                        <div className="col-md-7 col-sm-7 d-flex flex-fill flex-column">
                            <h1 className='fz24 black mb-8 fw-po-medium ls5 flex items-center !gap-x-2'>
                                <Link

                                    to='/settings/integrations/all-integrations/99-acres'
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>

                                Manage Accounts
                            </h1>
                            <p className='fz16 black-dark-700 ls5 !ml-10 mb-0'>Manage your 99 acres account connections</p>
                        </div>
                        <div className="col-md-5 col-sm-5 justify-content-end d-flex flex-fill align-items-sm-end align-items-center">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <Image src={searchIcon} />
                                </div>
                                <input type="text" placeholder="Search Registered Email" className="searchinput" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                {searchText && <div className="close-icon" onClick={clearSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                    </svg>
                                </div>}
                            </div>


                        </div>
                    </div>
                </div>

                <div className="mt-24 ml-24 lead-assignment_table !h-full overflow-auto">
                    <div className="def-table pr-24 account-table !h-full">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th className='max-width-180'>Registered Email</th>
                                    <th>Created By</th>
                                    <th>Project Connected</th>
                                    <th>Date Created</th>
                                    <th>Unmapped Projects</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    leads.length > 0 && leads?.map((lead, index) => {
                                        return <tr key={`${lead.profile_id}_${index}`}>
                                            <td>{index + 1}</td>
                                            <td className='max-width-180'>
                                                {lead.profile_id.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className=''>{lead.profile_id}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='three-dots'>{lead.profile_id}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='three-dots'>{lead.profile_id}</span>
                                                }

                                            </td>
                                            <td>
                                                {lead.created_by.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{lead.created_by}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{lead.created_by}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize three-dots'>{lead.created_by}</span>
                                                }



                                            </td>
                                            <td className='text-left'>{lead.count}</td>
                                            <td>{moment(lead.created_on).format("h:mm a, Do MMM YYYY")}	</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={`btn btn-lg btn-toggle ${lead.allow_unmapped_leads ? 'active' : ''} mr-2`}
                                                    // data-toggle="button"
                                                    aria-pressed="true"
                                                    value={lead.allow_unmapped_leads}
                                                    onClick={() => {
                                                        if (lead.allow_unmapped_leads) {
                                                            setSelectedData({
                                                                uuid: lead.uuid,
                                                                toggle: !lead.allow_unmapped_leads,
                                                                index
                                                            })
                                                            setUnmappedLeadsModal(true);
                                                        } else {
                                                            handleToggle(lead.uuid, !lead.allow_unmapped_leads, index)
                                                        }
                                                    }}
                                                >
                                                    <div className="handle"></div>
                                                    <span className="on ">{lead.allow_unmapped_leads ? 'YES' : 'NO'}</span>
                                                </button>
                                            </td>
                                            {/* <td className='dropdown view-more'>
                                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a className="dropdown-item" href="#">Reconnect Account</a>
                                                    <a className="dropdown-item" href="#">View Forms</a>
                                                    <a className="dropdown-item del-menu-itme" href="#">Delete</a>
                                                </div>
                                            </td> */}
                                        </tr>
                                    })
                                }
                                {
                                    searchText && leads.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                                    <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                             <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                         } */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {!searchText && leads.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center h-100'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Accounts Added</h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add integration to see them here</p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                                            </div>
                                        </td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                unmappedLeadsModal && <CancelCSV
                    show={unmappedLeadsModal}
                    closeCancelModal={setUnmappedLeadsModal}
                    onSubmit={() => handleToggle(selectedData.uuid, selectedData.toggle, selectedData.index)}
                    title={''}
                    bodyText={'Are you sure don\'t want Propacity platform to receive leads from the 99 Acres projects/listings which are not added on platform.'}
                    submitButtonType={'delete'}
                    cancelButtonText='Cancel'
                    submitButtonText='Yes, Confirm'
                />
            }
        </>
    )
}

export default Manage99AcresAccounts;