import { loadingBar } from 'aws-amplify';
import React, { useEffect, useCallback, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { create99AcresIntegration, update99AcresIntegration, view99AcresIntegration } from '../../services/private/company.service';
import EmailIntegration from '../HousingIntegration/emailIntegration';
import Integration from '../HousingIntegration/integration';
import Success from '../HousingIntegration/success';
import CancelCSV from './cancel-csv';
import { getAllTeams } from '../../services/public/public.service';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AcresIntegrationModal = ({ show, closeModal, projects, getAllPgeIntegration, selectedProfileId, editProjectMappping, selectedIntegration }) => {
    const [step, setStep] = useState(1);
    const [profileId, setProfileId] = useState('');
    const [response, setResponse] = useState({});
    const [profileIdError, setProfileIdError] = useState(false);
    const [projectErrorList, setProjectErrorList] = useState([]);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [projectError, setProjectError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isInValidProfile, setIsInValidProfile] = useState(false);
    const [unmappedLead, setUnmappedLead] = useState(true);
    const [allTeams, setAllTeams] = useState([])
    const [projectMapping, setProjectMapping] = useState([
        {
            externalProject: '',
            projectId: '',
            routing: ''
        },
        {
            externalProject: '',
            projectId: '',
            routing: ''
        }
    ])

    const updateProfileId = useCallback((value) => {
        setProfileId(value)
    }, []);

    const addMoreProject = useCallback(() => {
        setProjectMapping([...projectMapping, {
            externalProject: '',
            projectId: '',
            routing: ''
        }]);
        document.getElementById('project_mapping').scrollTop = document.getElementById('project_mapping').scrollHeight;
    }, [projectMapping]);

    const updateExternalProject = useCallback((value, index) => {
        let newValue = [...projectMapping];
        newValue[index].externalProject = value;
        setProjectMapping(newValue)
    }, [projectMapping]);

    const updateProjectId = useCallback((value, index) => {
        let newValue = [...projectMapping];
        newValue[index].projectId = value;
        setProjectMapping(newValue)
    }, [projectMapping]);

    const updateStep = useCallback((modalStep) => {
        setStep(modalStep)
    }, []);

    const getHousingIntegration = () => {
        view99AcresIntegration(profileId || selectedProfileId).then(res => {
            if (res.status === 200) {
                const projects = res.data.data.projects.map(project => project.external_project)
                setResponse({
                    projects,
                    key: res.data.data.key
                })
                updateStep(2)
            }
        })
    }

    useEffect(() => {
        if (selectedProfileId) {
            setProfileId(selectedProfileId);
            getHousingIntegration();
        }
    }, [selectedProfileId]);

    useEffect(() => {
        if (editProjectMappping && Object.keys(selectedIntegration).length > 0) {
            setProfileId(selectedIntegration.profile_id);
            setProjectMapping([{
                externalProject: selectedIntegration.external_project,
                projectId: selectedIntegration.project_id,
                routing: selectedIntegration?.routing
            }])
        }
    }, [selectedIntegration, editProjectMappping]);

    const checkProjectError = () => {
        let isEmpty = false;
        let count = 0;
        let error = [];
        projectMapping.forEach((project, index) => {
            if ((project.externalProject && project.projectId)) {
                count++
            }
            if ((project.externalProject && !project.projectId)) {
                isEmpty = false
                error[index] = { 'projectId': 'Please select project' };
            }
            if (!project.externalProject && project.projectId) {
                isEmpty = false
                error[index] = { 'externalProject': 'Please enter project' };
            }

            if ((!project.externalProject && !project.projectId) && error.length === 0 && count === 0) {
                isEmpty = true;
            }
        })

        if (isEmpty && count === 0) {
            setProjectError(true);
            return true;
        } else {
            setProjectError(false);
        }

        if (error.length > 0) {
            setProjectErrorList(error);
            return true;
        } else {
            setProjectErrorList([])
            isEmpty = false
            return false;
        }
    }

    const addNewPageIntegration = async () => {
        if (!profileId) {
            setProfileIdError(true);
            return;
        } else {
            setProfileIdError(false)
        }

        if (!EMAIL_REGEX.test(profileId)) {
            setIsInValidProfile(true);
            return;
        } else {
            setIsInValidProfile(false)
        }

        if (checkProjectError()) {
            return;
        }

        const mappedProject = projectMapping.filter(proj => proj.externalProject && proj.projectId)
        const payload = {
            profile_id: profileId,
            projectMapping: mappedProject,
            allow_unmapped_leads: unmappedLead
        }

        setLoading(true);
        await create99AcresIntegration(payload).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getHousingIntegration();
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
        setLoading(false);
    }
    const updateProjectMapping = async () => {
        if (checkProjectError()) {
            return;
        }

        setLoading(true);
        await update99AcresIntegration(selectedIntegration.uuid, {
            external_project: projectMapping[0].externalProject,
            projectId: projectMapping[0].projectId,
            routing: projectMapping[0]?.routing
        }).then((res) => {
            toast.success(res.data.message)
            getAllPgeIntegration();
            closeModal();
        }).catch((err) => {
            console.log({ err })
        })
        setLoading(false);
    }

    const closeCancelVModal = useCallback(() => {
        closeModal();
        setShowCancelModal(false);
    }, []);

    const toggleUnmappedLead = useCallback((e) => {
        e.stopPropagation();
        setUnmappedLead(!unmappedLead);
    }, [unmappedLead]);

    const updateTeam = (routing, index) => {
        let newValue = [...projectMapping];
        newValue[index].routing = routing;
        setProjectMapping(newValue)
    };

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data.map(data => ({ ...data, label: data.name, value: data.id }))
            setAllTeams(mapped)
        }
    }

    useEffect(() => {
        getAll();
    }, [])


    return (
        <Modal show={show} onHide={closeModal} backdrop="static" fullscreen centered dialogClassName="modal-radius housing-import-modal">
            {step === 1 && <a className="cursor-pointer close" onClick={() => setShowCancelModal(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725"></path></svg>
            </a>}

            {step !== 3 && <div className="header-modal">
                <h2 className='flex items-center justify-center mx-auto fz28 po fw-po-medium black mb-0'><img className='mr-8' src={`${process.env.PUBLIC_URL}/99acre.png`} alt="" style={{ height: '32px' }} /> {selectedProfileId || editProjectMappping ? 'Edit' : 'Create'} Integration (99 acres)</h2>
            </div>}

            {step === 1 &&
                <Integration
                    updateStep={updateStep}
                    addMoreProject={addMoreProject}
                    projectMapping={projectMapping}
                    projects={projects}
                    profileId={profileId}
                    updateProfileId={updateProfileId}
                    updateExternalProject={updateExternalProject}
                    updateProjectId={updateProjectId}
                    addNewPageIntegration={addNewPageIntegration}
                    profileIdError={profileIdError}
                    editProjectMappping={editProjectMappping}
                    updateProjectMapping={updateProjectMapping}
                    projectErrorList={projectErrorList}
                    projectError={projectError}
                    loading={loading}
                    intergrationName={'99 Acres'}
                    profileLabel={'Registered Email Id'}
                    projectLabel={'99 Acres'}
                    isInValidProfile={isInValidProfile}
                    subHeading={'Copy exact project names from 99 Acres portal & paste in project mapping section.'}
                    toggleUnmappedLead={toggleUnmappedLead}
                    unmappedLead={unmappedLead}
                    allTeams={allTeams}
                    updateTeam={updateTeam}
                />
            }
            {step === 2 &&
                <EmailIntegration
                    updateStep={updateStep}
                    response={response}
                    profileId={profileId}
                    closeModal={closeModal}
                    selectedProfileId={selectedProfileId || ''}
                    mainSentTo='(Add Your Registered 99 Acres Concerned Person’s Email), support@propacity.in'
                    mailSubject='(Important) 99 Acres <> Propacity Platform Integration'
                    apiUrl='https://api.propacity.in/api/v1/webhooks/99acres'
                    emailFor='99 Acres'
                    profile='Registered Email Id'
                />}
            {step === 3 &&
                <Success
                    closeModal={closeModal}
                    getAllPgeIntegration={getAllPgeIntegration}
                    integrationFor='99 Acres'
                />}


            {showCancelModal && <CancelCSV
                show={showCancelModal}
                closeCancelModal={setShowCancelModal}
                onSubmit={closeCancelVModal}
                title={''}
                bodyText={'Do you want to cancel?'}
                submitButtonType={'delete'}
                cancelButtonText='No'
                submitButtonText='Yes, Cancel'
            />}
        </Modal>
    )
}

export default AcresIntegrationModal;