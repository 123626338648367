import React, { useState } from 'react';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import InputText from '../InputGroup/InputText';
import { inviteUserSchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import InputSelect from '../InputGroup/InputSelect';
import { inviteUser, editUser } from "../../services/private/company.service";
import MobileInput from '../InputGroup/MobileInput';
import { Oval } from 'react-loader-spinner';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

export default function InviteUserModal({
    show,
    setShow,
    selectedUser,
    getUserData,
    roles
}) {

    const [loading, setLoading] = useState(false);

    const inviteUserHandle = (payload) => {
        setLoading(true);
        inviteUser(payload).then(res => {
            if (res.status === 200) {
                setLoading(false);
                toast.success(`${payload.name} invited successfully`)
                setShow(false)
                getUserData();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
            setLoading(false);
        });
    }

    const editUserHandle = (payload) => {
        setLoading(true);
        editUser(payload).then(res => {
            if (res.status === 200) {
                toast.success(`${selectedUser.name} info updated `)
                setShow(false)
                getUserData();
                setLoading(false);
            }
        }).catch(e => {
            toast.error(e.response.data.message)
            setLoading(false);
        });

    }

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                selectedUser?.uuid ? "Edit User" : 'Invite user'
            }
            body={
                <>
                    <Formik
                        initialValues={{ ...selectedUser }}
                        onSubmit={(values) => {
                            if (selectedUser) {
                                editUserHandle({
                                    ...values,
                                    mobile: values.mobile,
                                    uuid: selectedUser.uuid,
                                    role: values.role.value,
                                })
                            } else {
                                inviteUserHandle({
                                    ...values,
                                    mobile: values.mobile,
                                    role: values.role.value
                                })
                            }
                        }}
                        validationSchema={inviteUserSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {
                            (props) => {
                                const { isValid, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                return (

                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5'>
                                            <InputText
                                                value={values['name']}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                index={0}
                                                error={errors['name']}
                                                touched={touched['name']}
                                                type="text"
                                                placeholder="Enter User Name"
                                                name="name"
                                                label="User Name"
                                            />
                                            <MobileInput
                                                index={1}
                                                // countryCode="+91"
                                                type='text'
                                                value={values['mobile']}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "mobile", value: e.phone
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                error={errors['mobile']}
                                                touched={touched['mobile']}
                                                placeholder="e.g. 9810139281"
                                                name="mobile"
                                                label="Mobile No."
                                            />
                                            <InputText
                                                value={values['email']}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                index={2}
                                                error={errors['email']}
                                                touched={touched['email']}
                                                type="text"
                                                placeholder="Enter Email"
                                                name="email"
                                                label="Email"
                                            />

                                            <InputSelect
                                                index={489}
                                                name="role"
                                                error={errors['role']}
                                                touched={touched['role']}
                                                value={values['role']}
                                                options={roles}
                                                placeholder="Select Role"
                                                disable={selectedUser?.role.label === 'Super Admin'}
                                                onChange={(e) => {
                                                    const value = { target: { name: "role", value: e } }
                                                    handleChange(value)
                                                }}
                                                label="Role"
                                                styles={customStyles}
                                            />
                                        </div>


                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                                type='button'
                                            >
                                                Cancel
                                            </button>
                                            {!loading && <button
                                                className="pr-btn"
                                                type="submit"
                                            >
                                                {
                                                    selectedUser ? "Update User" : "Invite"
                                                }
                                            </button>
                                            }
                                            {loading && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>
                </>
            }
        />
    )
}