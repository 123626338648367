import React from 'react'
import Secure from '../Secure';

const Success = ({ closeModal, getAllPgeIntegration, integrationFor }) => {
    return (
        <>
            <div className="body-modal">
                <div className="card w-100 mw-100 border-0 process-card">
                    {integrationFor === 'Housing.com' && <figure className='d-table mx-auto mb-0'>
                        <img src={`${process.env.PUBLIC_URL}/modalogo.png`} alt="" style={{ height: '48px' }} />
                    </figure>}

                    {integrationFor === 'Magicbricks' && <figure className='d-table mx-auto mb-0'>
                        <img src={`${process.env.PUBLIC_URL}/magicbricks.png`} alt="" style={{ height: '48px' }} />
                    </figure>}
                    {integrationFor === '99 Acres' && <figure className='d-table mx-auto mb-0'>
                        <img src={`${process.env.PUBLIC_URL}/99acre.png`} alt="" style={{ height: '48px' }} />
                    </figure>}

                    <h1>Your integration is in progress!</h1>
                    <p>On successfully sending the email, {integrationFor} team will revert back to you in 24-48 hours only during weekdays via email for integration completion.</p>
                    <button className='pr-btn d-table mx-auto fz16 py-8 px-40' onClick={() => {
                        getAllPgeIntegration();
                        closeModal();
                    }}
                    >
                        Close
                    </button>
                </div>
                {/* <div className="footer-modal d-flex align-items-center justify-content-center">
                <button className="outline-btn fz16 py-8 px-40 mr-24">Back</button>
                <button className='pr-btn fz16 py-8 px-40'>Submit</button>
            </div> */}
            </div>
            <Secure />
        </>
    )
}

export default Success