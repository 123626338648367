import * as yup from 'yup';

export const validationSchema = yup
    .object()
    .shape({
        eventName: yup.string().required('Please enter Event Name'),
        developerName: yup.string().required('Please enter Developer Name'),
        date: yup.string().required('Please enter Event Date'),
        startTime: yup.string().required('Please enter Event Start time'),
        endTime: yup.string().required('Please enter Event End time'),
        // description: yup.string().required('Please enter Description'),
        eventType: yup.object().required('Please select Event type'),
        // foodType: yup.object().required('Please select Food type').nullable(),
        // address: yup.string().required('Please enter Address').nullable(),
        city: yup.object().required('Please select City'),
        state: yup.object().required('Please select State'),
        propertyType: yup.array().required('Please select Property Type'),
    })
    .defined();