import React from "react";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomColumnModal from "../../../../components/Modals/CustomColumnModal";
import EditRolesModal from "../../../../components/Modals/EditRolesModal";
import { getColumnPreferenceNew, getRoles, saveColumnPreferenceNew } from "../../../../services/private/company.service"

export default function RolesPrevious() {
    const [currentRoleUUID, setCurrentRoleUUID] = useState('')
    const [ShowEditRoleModal, setShowEditRoleModal] = useState(false)
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [showCustomiseColumnsModal, setShowCustomiseColumnsModal] = useState(false);
    const [dashboardColumns, setDashboardColumns] = useState([])

    const getRolesData = async () => {
        await getRoles().then((res) => {
            if (res.status === 200) {
                setRoles(res.data.data);
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        })
    }

    const getColumnPreferenceByRoleId = useCallback((id) => {
        getColumnPreferenceNew(id).then(res => {
            if (res.data.status === 200 && res.data.data.length > 0) {
                let dashboardColumns = [];
                res.data.data.forEach(items => {
                    let columnsList = [];
                    let selectedColumn = [];
                    items.columns.forEach(column => {
                        if (column.is_selected) {
                            selectedColumn.push(column);
                        }

                        if (!column.is_selected) {
                            columnsList.push(column);
                        }
                    })

                    dashboardColumns.push({
                        tab: items.tab,
                        columns: {
                            selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                            notSelected: columnsList
                        }
                    })

                })
                setDashboardColumns(dashboardColumns)
            }

        }).catch(err => {
            console.log({ err })
        })
    }, [roleId])

    useEffect(() => {
        getRolesData();
        // getColumnPreferenceByRoleId();
    }, [])

    /**
     * Updating re-arranged list items
     */
    const updateColumnList = useCallback((tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    }, [dashboardColumns]);

    const handleChangeSelection = useCallback((tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        console.log(columnClone[index])
        setDashboardColumns([...columnClone]);
    }, [dashboardColumns]);

    const saveCurrentUserColumnPrefrence = useCallback(() => {
        const preference = dashboardColumns.map(items => {
            items.columns.selected.forEach((item, index) => {
                item.placement = index + 1;
            })
            return {
                tab: items.tab,
                columns: [...items.columns.selected, ...items.columns.notSelected]
            }
        })
        saveColumnPreferenceNew({ role_id: roleId, preference }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                closeModal();
            }
        }).catch(err => {
            console.log({ err })
        })
    }, [dashboardColumns])

    const closeModal = useCallback(() => {
        setShowCustomiseColumnsModal(false)
    }, []);

    return (
        <>
            <div className="roles w-100">
                <div className="settings-title mb-16">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <h1 className="fz28  mb-16 black fw-po-medium">Roles</h1>
                            <p className="fz16 black-dark-700 mb-0">
                                Edit permissions for all role types.
                            </p>
                        </div>
                        <div className="col-md-5 justify-content-end d-flex"></div>
                    </div>
                </div>

                <div className="roles-table ml-24 mt-24">
                    <div className="def-table pr-24">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Role Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles && roles.length > 0 ? roles.map((role, index) => {
                                    const { name, uuid: roleUUID, id } = role;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="">
                                                {name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}
                                            </td>
                                            <td className='dropdown view-more'>
                                                <>
                                                    <button className="bg-tr border-0 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        {name !== 'ADMIN' && <a className="dropdown-item" onClick={() => {
                                                            setCurrentRoleUUID(roleUUID);
                                                            setShowEditRoleModal(!ShowEditRoleModal);
                                                        }}>
                                                            Edit Permissions
                                                        </a>}
                                                        <a className="dropdown-item" onClick={() => {
                                                            setRoleId(id);
                                                            getColumnPreferenceByRoleId(roleUUID);
                                                            setShowCustomiseColumnsModal(true)
                                                        }}>Customise Columns</a>
                                                    </div>
                                                </>
                                            </td>
                                            {/* <td className="dropdown view-more ">
                        {name !== "ADMIN" ?
                          <button
                            className="outline-btn pr-hover"
                            type="button"
                            onClick={() => {
                              setCurrentRoleUUID(roleUUID);
                              setShowEditRoleModal(!ShowEditRoleModal);
                            }}
                          >
                            Edit Permissions
                          </button>
                          : null}
                      </td> */}
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* role modal */}
                {ShowEditRoleModal ?
                    <EditRolesModal
                        show={ShowEditRoleModal}
                        setShow={setShowEditRoleModal}
                        currentRoleUUID={currentRoleUUID} />
                    : null}

                {showCustomiseColumnsModal && dashboardColumns.length > 0 &&
                    <CustomColumnModal
                        show={showCustomiseColumnsModal}
                        dashboardColumns={dashboardColumns}
                        handleChangeSelection={handleChangeSelection}
                        updateColumnList={updateColumnList}
                        setShow={closeModal}
                        roleId={roleId}
                        saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    />}
            </div>
        </>
    );
}