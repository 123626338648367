import React, { useState } from 'react';
import BookingModal from '../components/Modals/BookingModal'
import EOIModal from '../components/Modals/EOIModal';
import QoteModal from '../components/Modals/QoteModal';
import PreviewModal from '../components/Modals/PreviewModal';



const BookingEOI = () => {

    const [showModal, setShowModal] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [visableModal, setVisableModal] = useState(false)

    return (
        <>
            <div>
                <button className='btn btn-primary' onClick={() => setShowModal(true)}>Booking Modal</button>
                <button className='btn btn-primary' onClick={() => setOpenModal(true)}>EOI Modal</button>
                <button className='btn btn-primary' onClick={() => setViewModal(true)}>Qote Modal</button>
                <button className='btn btn-primary' onClick={() => setVisableModal(true)}>Preview Modal</button>
            </div>

            {/* <BookingModal show={showModal} closeModal={() => setShowModal(false)} /> */}
            {/* <EOIModal show={openModal} closeModal={() => setOpenModal(false)} /> */}
            <QoteModal show={viewModal} closeModal={() => setViewModal(false)} />
            <PreviewModal show={visableModal} closeModal={() => setVisableModal(false)} />
        </>
    )
}



export default BookingEOI
