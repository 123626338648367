import {

    SIGNUP_ERROR,
    SIGNUP_REQUESTING,
    SIGNUP_SUCCESS,
    SIGNUP_VERFICATIONL_LINK_SENT,
    SHOW_HIDE_PASSWORD,
    SIGNUP_RESEND_VERIFICATION_LINK,
    SIGNUP_RESEND_VERIFICATION_LINK_SENT
} from './actions';

// The initial state of the SINUP Reducer
export const initialState = {
    id: '',
    password: '',
    showPassword:false,
    requesting: false,
    successful: false,
    codeSent : false , 
    resentLink :  false,
    messages: [],
    errors: {},
  };

export default function(state = initialState,actions){
    switch(actions.type){
    
        case SIGNUP_REQUESTING:
            return {...state, requesting: true , errors :{ }};
        case SIGNUP_SUCCESS:
            return {...state, successful: true, user:{...actions.payload} ,errors:{} ,  requesting: false};
        case SIGNUP_ERROR:
            return {...state, successful: false, errors:{ ...actions.error} , requesting: false};
        case SIGNUP_VERFICATIONL_LINK_SENT:
            return {...state, codeSent: true };    
        case SHOW_HIDE_PASSWORD:
            return { ...state , showPassword :  !state.showPassword };
        case SIGNUP_RESEND_VERIFICATION_LINK:
            return { ...state ,  requesting : true };  
        case SIGNUP_RESEND_VERIFICATION_LINK_SENT:
                return { ...state ,  resentLink : !state.resentLink };            
        default:        
            return { ...state};
    }
}