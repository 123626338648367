import React, { useContext } from "react";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomColumnModal from "../../../../components/Modals/CustomColumnModal";
import EditRolesModal from "../../../../components/Modals/EditRolesModal";
import { createRoles, deletedRoleByUUID, getAddedUsers, getColumnPreferenceNew, getRoles, saveColumnPreferenceNew } from "../../../../services/private/company.service"
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import Consumer from '../../../../helpers/context';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import ConfirmDeletion from "../../../../components/Modals/ConfirmDeletion";
import WarningModal from "./Modal/WarningModal";
import { ReactComponent as RoleDummyIcon } from '../../../../assets/icons/role_dummy.svg';
import { ReactComponent as SearchClosIoicn } from '../../../../assets/icons/searchClose.svg';
import { createDatewithTime } from "../../../../helpers/date";
import { checkIsSomeItemAvailableInList } from "../../../../helpers/helpers";
import { ReactComponent as EditBorderIcon } from '../../../../assets/icons/edit.svg';

export default function Roles() {
  const [currentRoleUUID, setCurrentRoleUUID] = useState('')
  const [ShowEditRoleModal, setShowEditRoleModal] = useState(false)
  const [roles, setRoles] = useState([]);
  const [alllRoles, setAllRoles] = useState([]);
  const [roleId, setRoleId] = useState();
  const [showCustomiseColumnsModal, setShowCustomiseColumnsModal] = useState(false);
  const [dashboardColumns, setDashboardColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [users, setUsers] = useState([]);

  let { allowedPermissions, isRolesModule } = useContext(Consumer);

  const history = useHistory();

  const getRolesData = async () => {
    await getRoles().then((res) => {
      if (res.status === 200) {
        setRoles(res.data.data);
        setAllRoles(res.data.data);
      }
    }).catch((err) => {
      console.log("Something went wrong", err);
    })
  }

  const getColumnPreferenceByRoleId = (roleUUID) => {
    getColumnPreferenceNew(roleUUID, 'customise_column').then(res => {
      if (res.data.status === 200 && res.data.data.length > 0) {
        let dashboardColumns = [];
        setShowCustomiseColumnsModal(true)
        res.data.data.forEach(items => {
          let columnsList = [];
          let selectedColumn = [];
          items.columns.forEach(column => {
            if (column.is_selected) {
              selectedColumn.push(column);
            }

            if (!column.is_selected) {
              columnsList.push(column);
            }
          })

          dashboardColumns.push({
            tab: items.tab,
            columns: {
              selected: selectedColumn.sort((a, b) => a.placement - b.placement),
              notSelected: columnsList
            }
          })

        })
        setDashboardColumns(dashboardColumns)
      }

    }).catch(err => {
      console.log(err.response, 'Error');
      toast.warning(err.response.data.message)
    })
  }

  // useEffect(() => {
  //   if (roleId) {
  //     getColumnPreferenceByRoleId();
  //   }
  // }, [roleId]);

  useEffect(() => {
    getRolesData();

  }, [])

  /**
   * Updating re-arranged list items
   */
  const updateColumnList = useCallback((tab, list) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    const itemColumn = columnClone[index].columns
    columnClone[index].columns = { ...itemColumn, selected: list }
    setDashboardColumns([...columnClone]);
  }, [dashboardColumns]);

  const handleChangeSelection = useCallback((tab, selected, notSelected) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    columnClone[index].columns = { selected, notSelected }
    setDashboardColumns([...columnClone]);
  }, [dashboardColumns]);

  const saveCurrentUserColumnPrefrence = useCallback(() => {
    const preference = dashboardColumns.map(items => {
      items.columns.selected.forEach((item, index) => {
        item.placement = index + 1;
      })
      return {
        tab: items.tab,
        columns: [...items.columns.selected, ...items.columns.notSelected]
      }
    })
    saveColumnPreferenceNew({ role_id: roleId, preference }).then(res => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        closeModal();
      }
    }).catch(err => {
      console.log({ err })
    })

  }, [dashboardColumns]);

  const handleSearch = () => {
    const searchedData = alllRoles.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
    setRoles(searchedData)
  }

  useEffect(() => {
    handleSearch()
  }, [searchText])

  const handleGetUsersByUuid = async (uuid) => {
    const res = await getAddedUsers(uuid);
    if (res.data.status === 200) {
      setUsers(res.data.data)
      setShowConfirmationModal(true);
    }
  }

  const handleDeleteRole = async () => {
    const res = await deletedRoleByUUID(selectedRole.uuid);
    if (res.data.status === 200) {
      toast.success(res.data.message);
      getRolesData();
      setShowConfirmDeleteModal(false);
    }
  }
  const closeModal = useCallback(() => {
    setShowCustomiseColumnsModal(false)
  }, []);

  return (
    <>
      <div className="sm:hidden">
        <div className="flex items-center justify-center h-screen w-screen">
          <div className="w-[240px] flex items-center justify-center flex-col">
            <figure className="self-end">
              <RoleDummyIcon />
            </figure>
            <p className="text-center font-semibold text-base">This module is only available on desktop version.</p>
            <p className="text-center text-[#667085]">To work on this module please open it in desktop version.</p>
          </div>
        </div>

      </div >
      <div className="roles w-100 hidden sm:flex flex-col h-full">
        <div className="settings-title">
          <div className="flex justify-between sm:gap-4 lg:gap-0 lg:items-center sm:items-start sm:flex-col lg:flex-row">
            <div className="">
              <h1 className="fz24 mb-0 black fw-po-medium">Roles ({roles.length})</h1>
              {/* <p className="fz16 black-dark-700 mb-0">
                Edit permissions for all role types.
              </p> */}
            </div>
            <div className="justify-content-end d-flex">
              <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                <SearchIcon className='mr-2' />
                <input
                  type="text"
                  placeholder='Search For Roles'
                  className={`w-100 focus:outline-none text-sm `}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText &&
                  <div className="cursor-pointer" onClick={() => setSearchText('')}>
                    <SearchClosIoicn />
                  </div>}
              </div>
              <div className="mr-3">
                <button className="flex border rounded p-1.5 !px-2 leading-5 hover:bg-black100 text-black700"
                  onClick={() => {
                    history.push({
                      pathname: `/settings/user-roles/roles/permissions`,
                      search: `?p=roles`,
                      // search: `?role=edit&from=detail&name=${roleName === 'ADMIN' ? 'Admin' : roleName === 'STANDARD' ? 'Standard' : roleName}&description=${roleDescription}&restricted=${restricted}`,
                    })
                  }}
                >
                  <EditBorderIcon className="mr-2" />
                  Edit Permission
                </button>
              </div>
              {allowedPermissions.includes('create_role') &&
                <Link to={'/settings/user-roles/roles/new'} className="pr-btn flex leading-5">
                  <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <mask id="path-1-inside-1_1117_451" fill="white">
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                    </mask>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                    <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                  </svg>
                  Create New Role
                </Link>}
            </div>
          </div>
        </div>

        <div className="roles-table ml-24 mt-24 h-full overflow-auto">
          {roles && roles.length > 0 && <div className="def-table pr-24 !h-full">
            <table className="table mb-0">
              <thead>
                <tr className="leading-5">
                  <th>S.No</th>
                  <th>Role Name</th>
                  <th>No. Of Users</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th>Updated Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => {
                  const { name, uuid: roleUUID, id } = role;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {allowedPermissions.includes('create_role') ? <button onClick={() => {
                          localStorage.setItem('allowedPermissions', JSON.stringify(role.allowed_permissions));
                          history.push({
                            pathname: `/settings/user-roles/roles/detail/${role.uuid}`,
                            search: `?name=${name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}&description=${role.description}&restricted=${role?.restrict_edit}`,
                            // state: { name: name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name, description: role.description }
                          })
                        }}
                          className=' hover:!text-primary'
                        >
                          {name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}
                        </button> :
                          name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name
                        }
                      </td>
                      <td>
                        {allowedPermissions.includes('create_role') ? <button className="hover:!text-primary px-2 py-1 cursor-pointer"
                          onClick={() => {
                            localStorage.setItem('allowedPermissions', JSON.stringify(role.allowed_permissions));
                            history.push({
                              pathname: `/settings/user-roles/roles/detail/${role.uuid}`,
                              search: `?name=${name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}&description=${role.description}`,
                              // state: { name: name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name, description: role.description }
                            })
                          }}
                        >
                          {role.user_count > 0 ? role.user_count : '-'}
                        </button> :
                          role.user_count > 0 ? role.user_count : '-'
                        }
                      </td>
                      <td>{role.created_by || 'Propacity'}</td>
                      <td>{role.updated_by || '-'}</td>
                      <td>{role.updated_on ? createDatewithTime(new Date(role.updated_on)) : '-'}</td>
                      {name !== 'Super Admin' && <td className='dropdown view-more'>
                        {checkIsSomeItemAvailableInList(allowedPermissions, ['create_role', 'customise_column_role', 'delete_role']) && <>
                          <button className="bg-tr border-0 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                          </button>
                          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            {allowedPermissions.includes('create_role') && !role?.restrict_edit && <a
                              onClick={() => {
                                history.push({
                                  pathname: `/settings/user-roles/roles/detail/${role.uuid}`,
                                  search: `?name=${name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}&description=${role.description}&restricted=${role?.restrict_edit}`,
                                  // state: { name: name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name, description: role.description }
                                })
                              }}
                              className="dropdown-item" >
                              View/Edit Role
                            </a>}
                            {allowedPermissions.includes('create_role') && !role?.restrict_edit && <a className="dropdown-item" onClick={() => {
                              localStorage.setItem('allowedPermissions', JSON.stringify(role.allowed_permissions));
                              history.push({
                                pathname: `/settings/user-roles/roles/permissions`,
                                search: `?p=roles`,
                                // pathname: `/settings/user-roles/roles/new/permissions/${role.uuid}`,
                                // search: `?role=edit&from=listing&name=${name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}&description=${role.description}&restricted=${role?.restrict_edit}`,
                                // state: { name: name, role: name }
                              })
                            }}>
                              Edit permissions
                            </a>}
                            {allowedPermissions.includes('create_role') && !role?.restrict_edit && <a
                              onClick={() => {
                                history.push({
                                  pathname: `/settings/user-roles/roles/detail/${role.uuid}`,
                                  search: `?name=${name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name}&description=${role.description}&restricted=${role?.restrict_edit}`,
                                  // state: { name: name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name, description: role.description }
                                })
                              }}
                              // to={{
                              //   pathname: `/settings/user-roles/roles/new/invite-user/${role.uuid}`,
                              //   state: { name: name }
                              // }} 
                              className="dropdown-item" >
                              View/Edit Users
                            </a>}
                            {allowedPermissions.includes('customise_column_role') && <a className="dropdown-item"
                              onClick={() => {
                                setRoleId(role.uuid);
                                getColumnPreferenceByRoleId(role.uuid)
                              }}> Customise Column</a>}

                            {allowedPermissions.includes('delete_role') && <a className="dropdown-item del-menu" onClick={() => {
                              setSelectedRole(role);
                              if (+role.user_count === 0) {
                                setShowConfirmDeleteModal(true);
                              } else {
                                handleGetUsersByUuid(role.uuid)
                              }
                            }}>
                              Delete
                            </a>}
                          </div>
                        </>}
                      </td>}
                      {/* <td className="dropdown view-more ">
                        {name !== "ADMIN" ?
                          <button
                            className="outline-btn pr-hover"
                            type="button"
                            onClick={() => {
                              setCurrentRoleUUID(roleUUID);
                              setShowEditRoleModal(!ShowEditRoleModal);
                            }}
                          >
                            Edit Permissions
                          </button>
                          : null}
                      </td> */}
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>}
          {
            searchText && roles.length === 0 &&
            <div className="flex flex-col h-full overflow-hidden">
              <div className="def-table !pr-6 !h-auto !p-0 !overflow-visible">
                <table className="table mb-0">
                  <thead>
                    <tr className="leading-5">
                      <th>S.No</th>
                      <th>Role Name</th>
                      <th>No. Of Users</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th>Updated Time</th>
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className='flex justify-center flex-column items-center w-full h-full '>
                <figure><ResultSearchIcon /></figure>
                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                  <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                {/* {projectPermission?.includes('write') &&
                                       <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                   } */}
              </div>
            </div>
          }
        </div>
        {/* role modal */}
        {ShowEditRoleModal ?
          <EditRolesModal
            show={ShowEditRoleModal}
            setShow={setShowEditRoleModal}
            currentRoleUUID={currentRoleUUID} />
          : null}

        {showCustomiseColumnsModal && dashboardColumns.length > 0 &&
          <CustomColumnModal
            show={showCustomiseColumnsModal}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            setShow={closeModal}
            roleId={roleId}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
          />}

        {
          showConfirmDeleteModal && selectedRole && <ConfirmDeletion
            show={showConfirmDeleteModal}
            setShow={() => setShowConfirmDeleteModal(false)}
            deleteHandler={handleDeleteRole}
            deleteText={`${selectedRole.name} role`}

          />
        }
        {
          showConfirmationModal && selectedRole && <WarningModal
            show={showConfirmationModal}
            closeModal={() => setShowConfirmationModal(false)}
            role={selectedRole.name}
            users={users}
          />
        }
      </div >
    </>
  );
}
