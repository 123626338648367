import React, { useEffect, useState } from 'react'
import { uploadFile } from '../../services/public/public.service';
import style from '../../modules/Settings/Projects/style.module.css';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import InputText from '../../components/InputGroup/InputText';
import InputTextarea from '../../components/InputGroup/InputTextarea';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { addBanner, getBannerByUUID, updateBannerByUUID } from '../../services/private/company.service';
import { ReactComponent as BackICon } from '../../assets/icons/back.svg';
import InputSelect from '../../components/InputGroup/InputSelect';

const RedirectURL = [
    {
        label: 'Workshops',
        value: 'workshops'
    },
    {
        label: 'Courses',
        value: 'courses'
    },
    {
        label: 'Video',
        value: 'video'
    },
    {
        label: 'Guides',
        value: 'guides'
    },
    {
        label: 'Projects',
        value: 'projects'
    }
]

const MobileRedirectURL = [
    {
        label: 'Workshops',
        value: 'propacityplus://propacity.com/learningCentre?selectedTab=Workshops'
    },
    {
        label: 'Courses',
        value: 'propacityplus://propacity.com/learningCentre?selectedTab=Courses'
    },
    {
        label: 'Recordings',
        value: 'propacityplus://propacity.com/learningCentre?selectedTab=Recordings'
    },
    {
        label: 'Guides',
        value: 'propacityplus://propacity.com/learningCentre?selectedTab=Guides'
    },
    {
        label: 'Projects',
        value: 'propacityplus://propacity.com/projects'
    },
    {
        label: 'Notifications',
        value: 'propacityplus://propacity.com/notifications'
    }
]


const CreateBanner = () => {
    const [webImg, setWebImg] = useState('');
    const [mobileImg, setMobileImg] = useState('');
    const [title, setTitle] = useState('');
    const [redirectURL, setRedirectURL] = useState('');
    const [mobileRedirectTo, setMobileRedirectTo] = useState('');
    const [description, setDescription] = useState('');
    const [showBanner, setShowBanner] = useState(false);
    const [showProjectBanner, setShowProjectBanner] = useState(false);
    const [showDashboardBanner, setShowDashboardBanner] = useState(false);

    const { uuid } = useParams();
    const history = useHistory();

    const getBannerDetails = async () => {
        const res = await getBannerByUUID(uuid);
        if (res.data.status === 200) {
            setWebImg(res.data.data?.web_image_url)
            setMobileImg(res.data.data?.mobile_image_url)
            setTitle(res.data.data?.title)
            setRedirectURL(res.data.data?.redirect_to)
            setDescription(res.data.data?.body)
            setShowBanner(res.data.data?.is_visible)
            setShowDashboardBanner(res.data.data?.plus_app_dashboard)
            setShowProjectBanner(res.data.data?.plus_app_project)
            setMobileRedirectTo(res.data.data?.mobile_redirect_to)
        }
    }

    useEffect(() => {
        if (uuid) {
            getBannerDetails();
        }
    }, [uuid]);

    const handleOnChangeWebImg = async (e) => {
        const file = e.target.files[0];

        // if (file.size / 1048576 > 2) {
        //     setLogoFileError('File too large. Please upload an image under 2MB in size');
        //     return;
        // }
        // if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
        //     setLogoFileError('Wrong File format Uploaded');
        //     return;
        // }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setWebImg(response.data.data.s3_url);
            // setIsLogoMissing(false);
            // setLogoFileError('');
        }
    }

    const handleOnChangeMobileImg = async (e) => {
        const file = e.target.files[0];

        // if (file.size / 1048576 > 2) {
        //     setLogoFileError('File too large. Please upload an image under 2MB in size');
        //     return;
        // }
        // if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
        //     setLogoFileError('Wrong File format Uploaded');
        //     return;
        // }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setMobileImg(response.data.data.s3_url);
            // setIsLogoMissing(false);
            // setLogoFileError('');
        }
    }

    const handleSubmit = async () => {
        const payload = {
            title: title,
            body: description,
            web_image_url: webImg,
            mobile_image_url: mobileImg,
            redirect_to: redirectURL,
            mobile_redirect_to: mobileRedirectTo,
            // is_visible: showBanner,
            plus_app_dashboard: showDashboardBanner,
            plus_app_project: showProjectBanner
        }
        let res = [];
        if (uuid) {
            res = await updateBannerByUUID(uuid, payload);
        } else {
            res = await addBanner(payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            history.push('/cp-manager?tab=Banners')
        }
    }

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap mb-2">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium flex items-center">
                            <button onClick={() => {
                                history.push('/cp-manager?tab=Banners')
                            }} className='mr-2'>
                                <BackICon />
                            </button>
                            Create Banner
                        </h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                </div>
            </div>
            <div className={`${style.creatproject} overflow-auto !px-5 !pt-5`}>
                <div className='w-100'>
                    <div className={`${style.basic_form} basic_form`}>
                        <InputText label='Title' placeholder={'Enter Title'} value={title} onChange={e => setTitle(e.target.value)} />
                        <div></div>
                        <div>
                            <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                <span>Web Banner </span>
                                <span>343px x 168px</span>
                            </div>
                            <div className={`${style.upload}`}>
                                {webImg && <img src={webImg} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                <input type="file" className='d-none' name='developer-logo' id='web-img' accept='image/*' onChange={handleOnChangeWebImg} />
                                {!webImg && <div className={`${style.uplaodbtngrp}`}>
                                    <button
                                        type='button'
                                        className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('web-img').click()
                                        }}
                                    ><PluswhiteIcon />Upload</button>
                                </div>}
                                {webImg && <div className={`${style.hoverbox}`}>
                                    <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('web-img').click()
                                        }}
                                    ><PluswhiteIcon />Replace</button>
                                    <button
                                        type='button'
                                        className={`${style.delbtn} inter border-0 bg-white`}
                                        onClick={() => setWebImg('')}
                                    ><TrashIcon /></button>
                                </div>}
                            </div>
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoFileError}</p>} */}
                        </div>
                        <div>
                            <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                <span>Mobile Banner </span>
                                <span>343px x 168px</span>
                            </div>
                            <div className={`${style.upload}`}>
                                {mobileImg && <img src={mobileImg} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                <input type="file" className='d-none' name='developer-logo' id='mobile-img' accept='image/*' onChange={handleOnChangeMobileImg} />
                                {!mobileImg && <div className={`${style.uplaodbtngrp}`}>
                                    <button
                                        type='button'
                                        className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('mobile-img').click()
                                        }}
                                    ><PluswhiteIcon />Upload</button>
                                </div>}
                                {mobileImg && <div className={`${style.hoverbox}`}>
                                    <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('mobile-img').click()
                                        }}
                                    ><PluswhiteIcon />Replace</button>
                                    <button
                                        type='button'
                                        className={`${style.delbtn} inter border-0 bg-white`}
                                        onClick={() => setMobileImg('')}
                                    ><TrashIcon /></button>
                                </div>}
                            </div>
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoFileError}</p>} */}
                        </div>
                        <InputSelect
                            label='Redirect URL'
                            placeholder={'Select Redirect URL'}
                            options={RedirectURL}
                            value={redirectURL ? RedirectURL.find(i => i.value === redirectURL) : ''}
                            onChange={e => setRedirectURL(e.value)}
                        />

                        {/* <div className='flex items-center'>
                            <label htmlFor="" className='!mr-2 mb-0'>Show Banner</label>
                            <input type="checkbox" value={showBanner} checked={showBanner} onChange={() => setShowBanner(!showBanner)} />
                        </div> */}
                        <div className='flex justify-between'>
                            <div className='flex items-center'>
                                <label htmlFor="" className='!mr-2 mb-0'>Display on Dashboard</label>
                                <input type="checkbox" value={showDashboardBanner} checked={showDashboardBanner} onChange={() => setShowDashboardBanner(!showDashboardBanner)} />
                            </div>
                            <div className='flex items-center'>
                                <label htmlFor="" className='!mr-2 mb-0'>Display on Project Dashboard</label>
                                <input type="checkbox" value={showProjectBanner} checked={showProjectBanner} onChange={() => setShowProjectBanner(!showProjectBanner)} />
                            </div>
                        </div>

                        <InputSelect
                            label='Mobile Redirect URL'
                            placeholder={'Select Mobile Redirect URL'}
                            options={MobileRedirectURL}
                            value={mobileRedirectTo ? MobileRedirectURL.find(i => i.value === mobileRedirectTo) : ''}
                            onChange={e => setMobileRedirectTo(e.value)}
                        />

                        <div className='col-span-2'>
                            <InputTextarea label='Description' value={description} onChange={e => setDescription(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-end align-items-center`}>
                {/* <button
                    type='button'
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                ><LeftIcon />Previous </button> */}
                <button className={`${style.savebtn}`} onClick={handleSubmit}>
                    Save
                </button>
            </div>
        </div>
    )
}

export default CreateBanner;