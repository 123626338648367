import React, { useState, useEffect, useMemo, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { getMyUsers, getPlusAppFilterUsers } from '../../../services/private/company.service';

import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

import { getAllCities } from '../../../services/public/public.service';
import { getLeadsFilter } from '../../../services/private/leads.service';
import SliderModal from '../../../components/Modals/SliderModal';
import CheckAccordion from '../../../components/Modals/FilterModal/Check.accordion';
import DynamicAccordion from '../../../components/Modals/FilterModal/Dynamic.accordion';
import { brokerProjectListing, getAssignedCPs } from '../../../services/private/borker.service';
import { SVTrackerLeadSource } from '../../../helpers/enums';

const defaultStaticFilterList = [
    'Project',
    'CP Firm',
    'CP User',
    'Marked date',
    'Marked By',
    'RM'
];

const searchFilterkey = [
    'Assigned To',
    'Assigned User',
    'Shared With',
    'Marked By',
    'CP Firm',
    'Lead Created By',
    'Completed By'
]

const SUBSCRIPTION_TYPE = [
    {
        label: 'Premium',
        value: 'premium'
    },
    {
        label: 'Freemium',
        value: 'freemium'
    },
    // {
    //     label: 'Free',
    //     value: 'free'
    // },
]

export default function BrokerLeadsFilter({
    show,
    setShow,
    setFiltersData,
    filterData,
    setFilterCount,
    filtersData,
    filterToaster,
    setCurrentPage,
    projects
}) {

    // Filter Values
    const [subscription, setSubscription] = useState([]);
    const [amountPaid, setAmountPaid] = useState([]);
    const [datePurchased, setDatePurchased] = useState({});
    const [dateJoined, setJoinedDate] = useState({});
    const [referralCode, setReferralCode] = useState([]);
    const [filterSelected, setFilterSelected] = useState(false)
    const [filterDate, setFilterDate] = useState({});
    const [defaultFilterList, setDefaultFilterList] = useState(defaultStaticFilterList);
    const [createdTime, setCreatedTime] = useState({});
    const [city, setCity] = useState([])
    const [markedDate, setMarkedDate] = useState({});
    const [createdDate, setCreatedDate] = useState({})
    const [markedBy, setMarkedBy] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [completedBy, setCompletedBy] = useState([])
    const [project, setProject] = useState([]);
    const [source, setSource] = useState([])
    const [leadFrom, setLeadFrom] = useState([])
    const [cpRegistered, setCpRegistered] = useState([]);
    const [taggedLead, setTaggedLead] = useState([])
    const [leadExist, setLeadExist] = useState([])
    const [brokerCompany, setBrokerCompany] = useState([]);

    // console.log(filtersData, 'filtersData')

    const [inputValues, setInputValues] = useState({
        City: '',
        'RM List': '',
        'Marked By': '',
        'CP Firm': '',
        'Lead Created By': '',
        'Completed By': ''
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        City: '',
        'RM List': '',
        'Marked By': '',
        'CP Firm': '',
        'Lead Created By': '',
        'Completed By': ''

    });

    const [searchResults, setSearchResults] = useState({
        City: [],
        'RM List': [],
        'Marked By': [],
        'CP Firm': [],
        'Lead Created By': [],
        'Completed By': []
    });

    const [searchDefultResults, setSearchDefaultResults] = useState({
        'RM List': [],
        'Marked By': [],
        'CP Firm': [],
        'Lead Created By': [],
        'Completed By': []
    });

    const ROLE_NAME = localStorage.getItem('role');


    const handleDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
        setDatePurchased({ ...datePurchased, [name]: date });
    }
    const handleJoinDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
        setJoinedDate({ ...dateJoined, [name]: date });
    }

    const handleMarkedDateChange = (name, date) => {
        setMarkedDate({ ...markedDate, [name]: date });
    }

    const handleCreatedDateChange = (name, date) => {
        setCreatedDate({ ...createdDate, [name]: date });
    }
    const getFilterData = async (type, value) => {
        // const res = await getPlusAppFilterUsers({ filterType: type, value: value });
        const res = await getLeadsFilter({ filterType: type, value: value });
        if (res.status === 200) {
            return res.data.data.map(i => ({ ...i, id: i.user_id }))
        }
        else {
            return []
        }
    }

    const getDefaultUser = async () => {
        const res = await getMyUsers();
        if (res.data.status === 200) {
            const defaultUser = res.data.data.map(u => ({ ...u, value: u.name, user_id: u.id }))
            setSearchResults((prevState) => ({
                ...prevState,
                'Marked By': [...defaultUser],
                'RM List': [...defaultUser],
                'Lead Created By': [...defaultUser],
                'Completed By': [...defaultUser]
            }));

            setSearchDefaultResults((prevState) => ({
                ...prevState,
                'Marked By': [...defaultUser],
                'RM List': [...defaultUser],
                'Lead Created By': [...defaultUser],
                'Completed By': [...defaultUser]
            }))
            getBrokerCompany();
        }
    }

    const getBrokerCompany = async () => {
        await getAssignedCPs('')
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                value: company.name,
                            }
                        });
                    setSearchResults((prevState) => ({
                        ...prevState,
                        'CP Firm': [...companies],
                    }))

                    setSearchDefaultResults((prevState) => ({
                        ...prevState,
                        'CP Firm': [...companies],
                    }))
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getAllCitiesByName = async (searchText) => {
        const res = await getAllCities(searchText)

        if (res.data.status === 200) {
            const cities = res.data?.data.map((city) => { return { label: city.name, value: city.name } });
            return cities
        } else {
            return []
        }
    }

    useEffect(() => {
        getDefaultUser();
        getBrokerCompany();
    }, []);

    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)
        //if user make input field blank using backspace
        if (searchFilterkey.includes(e.target.name) && !e.target.value) {
            setSearchResults({ ...searchResults, [e.target.name]: searchDefultResults[e.target.name] });
            return false
        }

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };
        let filterData = [];
        if (fieldName === 'City') {
            filterData = await getAllCitiesByName(inputValuesData[fieldName])
        } else if (fieldName === 'CP Firm') {
            filterData = await getCompanyName(inputValues[fieldName]);
        } else {
            filterData = await getFilterData(searchFilterkey.includes(fieldName) ? 'Assign To' : fieldName, inputValuesData[fieldName])
        }

        if (!filterData?.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }

        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }

    const getCompanyName = async (searchText) => {
        return getAssignedCPs(searchText)
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                value: company.name,
                                id: company.id
                            }
                        });
                    return companies;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (subscription?.length || project.length || source.length || leadFrom || cpRegistered.length || taggedLead.length || leadExist || Object.keys(markedDate).length || Object.keys(createdDate).length || brokerCompany.length || markedBy.length || createdBy.length || completedBy.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [amountPaid, referralCode, subscription, datePurchased, project, source, leadFrom, cpRegistered, taggedLead, leadExist, markedDate, createdDate, markedBy, brokerCompany, createdBy, completedBy])

    const sendFilterRequest = () => {
        let filterArr = {};

        // if (subscription.length > 0) {
        //     filterArr.push({
        //         'subscription_type': subscription.map((sub) => {
        //             return sub.value
        //         })
        //     })
        // }

        // if (project.length > 0) {
        //     filterArr['projects'] = [...project]
        // }

        if (source.length > 0) {
            filterArr['source'] = source.map(i => i.value)
        }
        if (leadFrom.length > 0) {
            filterArr['sv_source'] = leadFrom.map(i => i.value)
        }

        if (cpRegistered.length > 0) {
            filterArr['is_cp_registered'] = true;
        }
        if (taggedLead.length > 0) {
            filterArr['tagged'] = true;
        }
        if (leadExist.length > 0) {
            filterArr['lead_existed'] = true;
        }

        if (brokerCompany.length) {
            filterArr['cpFirm'] = [...brokerCompany]
        }
        if (markedBy.length) {
            filterArr['markedBy'] = [...markedBy]
        }
        if (createdBy.length) {
            filterArr['lead_created_by'] = [...createdBy]
        }

        if (completedBy.length) {
            filterArr['completed_by'] = [...completedBy]
        }
        if (Object.keys(markedDate).length > 0) {
            let startDate = markedDate.startDate;
            let endDate = markedDate.endDate || markedDate.startDate;
            filterArr['markedDate'] = {
                'from': startDate,
                'to': endDate
            }
        }

        if (Object.keys(createdDate).length > 0) {
            let startDate = createdDate.startDate;
            let endDate = createdDate.endDate || createdDate.startDate;
            filterArr['lead_created_time'] = {
                'from': startDate,
                'to': endDate
            }
        }
        // setFilterCount(filterArr.length)
        setFiltersData(filterArr);
        // filterData(filterArr);
        // localStorage.setItem('filter', JSON.stringify(filterArr))
        // localStorage.removeItem('currentPage');        
        // setCurrentPage(1)
        setShow(false)
    }

    const handelRemoveClick = () => {
        if (filterSelected || Object.keys(filtersData).length > 0 || subscription.length > 0 || project.length > 0 || source.length > 0 || leadFrom.length > 0 || cpRegistered.length > 0 || taggedLead.length > 0 || leadExist.length > 0 || Object.keys(markedDate).length > 0 || Object.keys(createdDate).length > 0 || markedBy.length || createdBy.length || completedBy.length || brokerCompany.length) {
            toast.success("All filters Removed");
            // setFilterCount(0);
            setFiltersData({});
            // setCurrentPage(1)
            setSubscription([])
            setProject([]);
            setSource([]);
            setLeadFrom([]);
            setCpRegistered([]);
            setTaggedLead([]);
            setLeadExist([]);
            setMarkedBy([]);
            setBrokerCompany([]);
            setMarkedDate({});
            setCreatedDate({})
            setCreatedBy([]);
            setCompletedBy([])
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = { ...filtersData };
        // for (const filterData of filters) {
        //     filtersArr = { ...filtersArr, ...filterData }
        // }

        let filterKeys = Object.keys(filtersArr);

        if (filterKeys.includes("subscription_type")) {

            const filteredType = filtersArr.subscription_type.map(int => {
                return SUBSCRIPTION_TYPE?.find(conf => conf.value === int)
            })
            setSubscription(filteredType || [])
        }
        if (filterKeys.includes("projects")) {
            setProject(filtersArr['projects'])
        }
        if (filterKeys.includes("source")) {
            setSource(filtersArr['source'].map(i => ({ label: i, value: i })))
        }

        if (filterKeys.includes('sv_source')) {
            setLeadFrom(filtersArr['sv_source'].map(i => {
                if (i === 'with_cp') {
                    return {
                        label: 'Channel Partner',
                        value: 'with_cp'
                    }
                }
                if (i === 'self') {
                    return {
                        label: 'Direct',
                        value: 'self'
                    }
                }
            }))
        }
        if (filterKeys.includes("is_cp_registered")) {
            setCpRegistered([
                {
                    label: 'Registered',
                    value: true
                }
            ])
        }

        if (filterKeys.includes("lead_existed")) {
            setLeadExist([
                {
                    label: 'Show Lead Exist',
                    value: true
                }
            ])
        }
        if (filterKeys.includes("tagged")) {
            setTaggedLead([
                {
                    label: 'Show Tagged Lead',
                    value: true
                }
            ])
        }
        if (filterKeys.includes("cpFirm")) {
            setBrokerCompany(filtersArr['cpFirm'])
        }
        if (filterKeys.includes("markedBy")) {
            setMarkedBy(filtersArr['markedBy'])
        }
        if (filterKeys.includes("lead_created_by")) {
            setCreatedBy(filtersArr['lead_created_by'])
        }
        if (filterKeys.includes("completed_by")) {
            setCompletedBy(filtersArr['completed_by'])
        }

        if (filterKeys.includes("markedDate")) {
            const { from, to } = filtersArr.markedDate
            const date = {
                'startDate': from,
                'endDate': to,
            }
            setMarkedDate(date || {})
        }


        if (filterKeys.includes("lead_created_time")) {
            const { from, to } = filtersArr.lead_created_time
            const date = {
                'startDate': from,
                'endDate': to,
            }
            setCreatedDate(date || {})
        }
    }


    useEffect(() => {
        updateFilterValaue();
    }, [
        filtersData,
        show,
    ])

    useEffect(() => {
        // let filters = filtersData;
        const filtersArr = Object.keys(filtersData);
        // for (const filterData of filters) {
        //     filtersArr.push(Object.keys(filterData)[0])
        // }
        if (!filtersArr.includes("markedBy")) {
            setMarkedBy([])
        }
        if (!filtersArr.includes("projects")) {
            setProject([])
        }
        if (!filtersArr.includes('source')) {
            setSource([]);
        }
        if (!filtersArr.includes('sv_source')) {
            setLeadFrom([]);
        }

        if (!filtersArr.includes("is_cp_registered")) {
            setCpRegistered([])
        }
        if (!filtersArr.includes("tagged")) {
            setTaggedLead([])
        }
        if (!filtersArr.includes("lead_existed")) {
            setLeadExist([])
        }
        if (!filtersArr.includes("cpFirm")) {
            setBrokerCompany([])
        }
        if (!filtersArr.includes("lead_created_by")) {
            setCreatedBy([])
        }

        if (!filtersArr.includes("completed_by")) {
            setCompletedBy([])
        }
        if (!filtersArr.includes("lead_created_time")) {
            setCreatedDate({})
        }
        if (!filtersArr.includes("markedDate")) {
            setMarkedDate({})
        }
        // setFilterCount(filtersArr.length);
    }, [filtersData])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || filtersData?.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'></div>
                        <div className="all-fliters">
                            <div id="accordion">


                                {/* <CheckAccordion
                                    value={subscription}
                                    setValue={setSubscription}
                                    selectionValues={SUBSCRIPTION_TYPE}
                                    accordionName="Subscription"
                                    label="Subscription"
                                    showSearch={false}
                                /> */}
                                {/* {projects.length > 0 && <CheckAccordion
                                    value={project}
                                    setValue={setProject}
                                    selectionValues={projects}
                                    accordionName="Project"
                                    label="Project"
                                />} */}

                                <CheckAccordion
                                    value={source}
                                    setValue={setSource}
                                    selectionValues={SVTrackerLeadSource}
                                    accordionName="source"
                                    label="Source"
                                />

                                <CheckAccordion
                                    value={leadFrom}
                                    setValue={setLeadFrom}
                                    selectionValues={[
                                        {
                                            label: 'Channel Partner',
                                            value: 'with_cp'
                                        },
                                        {
                                            label: 'Direct',
                                            value: 'self'
                                        }
                                    ]}
                                    accordionName="leadBy"
                                    label="Channel Partner/Direct"
                                    showSearch={false}
                                />

                                <DynamicAccordion
                                    accordionName="CP Firm"
                                    placeholder='Enter CP Firm to search'
                                    value={brokerCompany}
                                    setValue={setBrokerCompany}
                                    searchResults={structuredClone(searchResults['CP Firm'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["CP Firm"]: structuredClone(data) })}
                                    inputValues={inputValues["CP Firm"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["CP Firm"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />
                                <DynamicAccordion
                                    accordionName="Marked By"
                                    placeholder='Enter user name to search'
                                    value={markedBy}
                                    setValue={setMarkedBy}
                                    searchResults={structuredClone(searchResults['Marked By'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Marked By"]: structuredClone(data) })}
                                    inputValues={inputValues["Marked By"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Marked By"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />
                                <DynamicAccordion
                                    accordionName="Lead Created By"
                                    placeholder='Enter user name to search'
                                    value={createdBy}
                                    setValue={setCreatedBy}
                                    searchResults={structuredClone(searchResults['Lead Created By'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Lead Created By"]: structuredClone(data) })}
                                    inputValues={inputValues["Lead Created By"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Lead Created By"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />

                                <DynamicAccordion
                                    accordionName="Completed By"
                                    placeholder='Enter user name to search'
                                    value={completedBy}
                                    setValue={setCompletedBy}
                                    searchResults={structuredClone(searchResults['Completed By'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Completed By"]: structuredClone(data) })}
                                    inputValues={inputValues["Completed By"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Completed By"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#markeddate" aria-expanded="false" aria-controls="markeddate" id="headingMarked">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Marked Date
                                                {Object.keys(markedDate).length > 0 && <span>{Object.keys(markedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="markeddate" className="filter-body collapse" aria-labelledby="headingMarked" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${markedDate.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setMarkedDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={markedDate.startDate ? new Date(markedDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleMarkedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={markedDate.endDate ? new Date(markedDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={markedDate.startDate}
                                                    onChange={(date) => handleMarkedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#createddate" aria-expanded="false" aria-controls="createddate" id="headingMarked">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Lead Created Date
                                                {Object.keys(createdDate).length > 0 && <span>{Object.keys(createdDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="createddate" className="filter-body collapse" aria-labelledby="headingMarked" data-parent="#accordion">
                                        <button className={`border-0 bg-tr mx-3 clear-btn px-0 ${createdDate.startDate ? "" : 'd-none'} `} onClick={() => {
                                            setCreatedDate({})
                                        }}
                                        >
                                            Clear All
                                        </button>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdDate.startDate ? new Date(createdDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleCreatedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdDate.endDate ? new Date(createdDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={createdDate.startDate}
                                                    onChange={(date) => handleCreatedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {defaultFilterList.includes('Date Joined') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedatejoined" aria-expanded="false" aria-controls="collapsedatejoined" id="headingDateJoined">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Date Joined
                                                {Object.keys(dateJoined).length > 0 && <span>{Object.keys(dateJoined).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsedatejoined" className="filter-body collapse" aria-labelledby="headingDateJoined" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={dateJoined.startDate ? new Date(dateJoined.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleJoinDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={dateJoined.endDate ? new Date(dateJoined.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={dateJoined.startDate}
                                                    onChange={(date) => handleJoinDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <CheckAccordion
                                    value={cpRegistered}
                                    setValue={setCpRegistered}
                                    selectionValues={[
                                        {
                                            label: 'Show Registered CP',
                                            value: true
                                        }
                                    ]}
                                    accordionName="cp_registered"
                                    label="Registered CP"
                                    showSearch={false}
                                />
                                <CheckAccordion
                                    value={taggedLead}
                                    setValue={setTaggedLead}
                                    selectionValues={[
                                        {
                                            label: 'Show Tagged Lead',
                                            value: true
                                        }
                                    ]}
                                    accordionName="lead_tagged"
                                    label="Lead Tagged"
                                    showSearch={false}
                                />
                                <CheckAccordion
                                    value={leadExist}
                                    setValue={setLeadExist}
                                    selectionValues={[
                                        {
                                            label: 'Show Lead Exist',
                                            value: true
                                        }
                                    ]}
                                    accordionName="lead_exist"
                                    label="Lead Exist"
                                    showSearch={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}