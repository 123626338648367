import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputSelect from '../../InputGroup/InputSelect';
import InputText from '../../InputGroup/InputText';
import InputTextarea from '../../InputGroup/InputTextarea';
import { getStates, insertPricingRegisterationCharges, updatePricingRegisterationChargesByUUID } from '../../../services/public/public.service';
import { toast } from 'react-toastify';
import InputRupees from '../../InputGroup/InputRupees';

const AddRegistrationModal = ({ show, closeModal, modalClass, uuid, getRegistrantionData, selectedPrice, statesList }) => {
    const [state, setState] = useState('');
    const [registrationCharge, setRegistrantionCharge] = useState('absolute');
    const [absoluteAmount, setAbsoluteAmount] = useState('');
    const [changesPercentage, setChangesPercentage] = useState('');
    const [includePrice, setIncludePrice] = useState(true);
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (Object.keys(selectedPrice).length > 0) {
            setState(statesList.find(item => item.value === selectedPrice.state));
            setIncludePrice(selectedPrice.include_in_price);
            setDescription(selectedPrice.description);
            setChangesPercentage(selectedPrice.payment_percentage);
            setAbsoluteAmount(selectedPrice.payment_amount)
            if (selectedPrice.payment_percentage) {
                setRegistrantionCharge('percent');
            }
            if (selectedPrice.payment_amount) {
                setRegistrantionCharge('absolute');
            }
        }
    }, [selectedPrice])

    const handleSubmit = async () => {
        if (!state ||
            !registrationCharge
        ) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (registrationCharge === 'absolute' && !absoluteAmount) {
            setError(true);
            return;
        } else if (registrationCharge === 'percent' && !changesPercentage) {
            setError(true)
            return
        } else {
            setError(false)
        }
        const payload = {
            payment_amount: absoluteAmount || null,
            description: description,
            state: state.value,
            payment_percentage: changesPercentage || null,
            // category: category.vale,
            // description: description,
            include_in_price: includePrice
        }

        let res = {}
        if (selectedPrice?.uuid) {
            res = await updatePricingRegisterationChargesByUUID(selectedPrice?.uuid, payload)
        } else {
            res = await insertPricingRegisterationCharges(uuid, payload);
        }
        if (res.data.status === 200) {
            toast.success(res.data.message);
            handleClose();
            getRegistrantionData();
        }
    }

    const handleClose = () => {
        closeModal();
        setState('');
        setRegistrantionCharge('absolute');
        setAbsoluteAmount('');
        setChangesPercentage('');
        setIncludePrice('');
        setDescription('');
    }

    return (
        <RightModal show={show} closeModal={handleClose} >
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Add Registration Charges</h1>
                <button className='border-0 bg-tr ' onClick={handleClose}><CloseIcon /></button>
            </div>
            <form action="" className='d-flex h-100 overflow-auto flex-column !px-5 gap-y-4 !py-5'>

                <InputSelect
                    inputclass='!mt-0'
                    label='Select State'
                    options={statesList}
                    value={state}
                    onChange={(value) => setState(value)}
                    error={(error && !state) ? 'Please select state' : ''}
                />
                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Registration Charge (%)
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='absolute' checked={registrationCharge === 'absolute'} onChange={() => {
                                setRegistrantionCharge('absolute');
                                setChangesPercentage('')
                            }} />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="token" id="" value='percent' checked={registrationCharge === 'percent'} onChange={() => {
                                setRegistrantionCharge('percent');
                                setAbsoluteAmount('')
                            }} />
                            Percentage
                        </label>
                    </div>
                    {registrationCharge === 'percent' &&
                        <InputText
                            placeholder='Enter Percentage'
                            value={changesPercentage}
                            type={'number'}
                            onChange={(e) => setChangesPercentage(e.target.value)}
                        />}
                    {registrationCharge === 'absolute' &&
                        <InputRupees
                            placeholder='Enter Absolute'
                            value={absoluteAmount}
                            onChange={(value) => setAbsoluteAmount(value)}
                            rupayclass='top-4 left-3'
                            handleBlur={() => { }}
                        />}
                    {error && (!registrationCharge || (registrationCharge === 'absolute' && !absoluteAmount) || (registrationCharge === 'percent' && !changesPercentage)) &&
                        <div className='invalid'>
                            <div className="input-feedback mt-8">
                                Please enter registration charges
                            </div>
                        </div>
                    }
                </div>
                {/* <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Registration Charge (%)
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" checked />
                            Absolute
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="" id="" />
                            Percentage
                        </label>
                    </div>
                    <InputText
                        placeholder='Enter value' />
                </div> */}
                {/* <InputText
                    placeholder='Enter Stamp Duty Charge'
                    label='Stamp Duty Charge (%)'
                    value={stampDutyChange}
                    onChange={(e) => setStampDutyCharge(e.target.value)}
                /> */}

                <div>
                    <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 !mb-2'>Include in price
                    </label>
                    <div className='flex gap-x-5'>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={true} onChange={() => setIncludePrice(true)} checked={includePrice} />
                            Yes
                        </label>
                        <label htmlFor="" className='flex items-center gap-x-2 text-sm text-black700 font-medium'>
                            <input type="radio" name="price" id="" value={false} onChange={() => setIncludePrice(false)} checked={!includePrice} />
                            No
                        </label>
                    </div>
                    {/* <InputText
                        placeholder='Enter Percentage' /> */}
                </div>

                <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </form>
            <div className='d-flex flex-row align-items-center justify-content-between !border-t !border-grayLight !px-5 !py-5 gap-x-4'>
                <button className='outline-btn w-[130px] shrink-0 grow-0 basis-[130px] h-[44px] !text-lg !font-medium !rounded-lg cursor-pointer' onClick={handleClose}>Cancel</button>
                <button type='submit' onClick={handleSubmit} className='pr-btn w-100 h-[44px] !text-lg  !font-medium !rounded-lg cursor-pointer'>Save</button>
            </div>


        </RightModal>
    )
}

export default AddRegistrationModal
