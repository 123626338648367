import React from 'react'

import { ReactComponent as BuildingIcon } from '../../assets/icons/Building.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import TooltipText from '../Tooltip';

const IntegrationTeamCard = ({ team, setShowEditTeamUser, setSelectedTeam, handleRemoveAddedTeam, index }) => {

    return (
        <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4'>
            <div className='flex flex-row justify-between gap-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>{team?.name || team?.team_name}</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0:'>
                    <button type='button' onClick={() => handleRemoveAddedTeam(index, team)}>
                        <CloseIcon />
                    </button>

                </div>
            </div>

            <div className='mt-2 flex gap-2 !flex-wrap'>
                <div className='cursor-default p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack  leading-4'>Lead: {team?.leader_name}</div>
                <div
                    className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'
                >
                    {/* <div><BuildingIcon /></div> */}
                    Users: <span>{team?.users?.length}</span>
                </div>
                {team?.projects?.length && <TooltipText title={team.projects.join(', ')}>
                    <div className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'>
                        <div><BuildingIcon /></div>
                        {team?.projects[0]} {team.projects.length > 1 ? `+${team.projects.length - 1}` : ''}
                    </div>
                </TooltipText>}
                <div className='flex justify-end w-full'>
                    <button
                        type='button'
                        className='text-black700'
                        onClick={() => {
                            setSelectedTeam(team);
                            setShowEditTeamUser(true)
                        }}
                    >Edit User</button>
                </div>
            </div>
        </div>
    )
}

export default IntegrationTeamCard;
