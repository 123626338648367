import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as BackICon } from '../../assets/icons/back.svg';
import { ReactComponent as HeaderMenu } from '../../assets/icons/HeaderMenu.svg';

import Construction from '../../components/Collection-Manager/CollectionDetail/Construction';
import Paymenttab from '../../components/Collection-Manager/CollectionDetail/Paymenttab';
import Searchbox from '../../components/InputGroup/Searchbox';
import SearchBox from '../../components/SearchBox';
import Collectionstab from '../../components/Collection-Manager/CollectionDetail/Collectionstab';
import Demannotes from '../../components/Collection-Manager/CollectionDetail/Demannotes';
import Document from '../../components/Collection-Manager/CollectionDetail/Document';
import UnitDetail from '../../components/Collection-Manager/CollectionDetail/UnitDetail';
import Overview from '../../components/Collection-Manager/CollectionDetail/Overview';
import CustomerDetail from '../../components/Collection-Manager/CollectionDetail/CustomerDetail';
import CreateCustomer from '../../components/Collection-Manager/Modal/CollectionDetail/CreateCustomer';
import { getAllBookingDocs, getBookingDoneDetails, getBookingPaymentMilestonesByBookingId, getCollectionByBookingId, getCollectionDetailStat, getPaymentLedger, getRaisedDemands, getTransactionProof } from '../../services/private/inventory.service';
import { toINRFormat } from '../../helpers/helpers';
import AddCollection from '../../components/Collection-Manager/Modal/AddCollection';
import AddDemand from '../../components/Collection-Manager/Modal/AddDemand';
import DocumentsGallery from '../../components/Collection-Manager/Modal/CollectionDetail/DocumentsGallery';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReassignLeadModal from '../../components/Modals/ReassignLeadModal';
import { deleteLeads, reassignLeads } from '../../services/private/leads.service';
import ConfirmDeletion from '../../components/Modals/ConfirmDeletion';

const getMobileNumber = (mobile) => {
  if (mobile?.length == 12) {
    return `+${mobile}`;
  } else {
    return mobile;
  }
}

const getStatusClasses = (status) => {
  switch (status) {
    // case 'pending':
    //     return 'text-yellow-dark bg-yellow-100'
    // case 'rejected':
    //     return 'text-red bg-red-light'
    case 'approved':
      return 'text-green bg-green100'
    default:
      return 'text-yellow-dark bg-yellow-100'
  }
}

const CollectionDetail = () => {
  const [showModal, setShowModal] = useState(false);
  const [collections, setCollections] = useState([]);
  const [demands, setDemands] = useState([]);
  const [payments, setPayments] = useState([]);
  const [bookingDetails, setBookingDetails] = useState();
  const [collectionStats, setCollectionStats] = useState();
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [paymentMilestones, setPaymentMilestones] = useState([]);
  const [showRaiseDemand, setShowRaiseDemand] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [customerDocument, setCustomerDocument] = useState([]);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { uuid } = useParams();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const getBookingCollections = async () => {
    const res = await getCollectionByBookingId(uuid);
    if (res.data.status === 200) {
      setCollections(res.data.data)
    }
  }

  const getCollectionStatsCount = async () => {
    const res = await getCollectionDetailStat(uuid);
    if (res.data.status === 200) {
      setCollectionStats(res.data.data);
    }
  }
  const getDemands = async () => {
    const res = await getRaisedDemands(uuid);
    if (res.data.status === 200) {
      setDemands(res.data.data)
    }
  }

  const getPaymentsDetails = async () => {
    const res = await getPaymentLedger(uuid);
    if (res.data.status === 200) {
      setPayments(res.data.data);
    }
  }
  const getAllDocuments = async () => {
    const res = await getAllBookingDocs(uuid);
    if (res.data.status === 200) {
      setCustomerDocument([...res.data.data])
    }
  }

  const getBookingDetails = async () => {
    // setBookingUUID(uuid)
    const res = await getBookingDoneDetails(uuid);
    if (res.data.status === 200) {
      if (res.data.data?.customer?.uuid) {
        setSelectedProject(res.data.data.project)
        setBookingDetails(res.data.data);
        // console.log(res.data.data, 'bookingDetails')
        // setShowDetailsModal(true);
      }
    }
  }

  const getPaymentMilestones = async () => {
    const res = await getBookingPaymentMilestonesByBookingId(uuid);
    if (res.data.status === 200) {
      const milestones = res.data.data.filter(item => !item.is_completed).map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }));
      setPaymentMilestones(milestones);
      setShowRaiseDemand(true);
    }
  }

  useEffect(() => {
    if (uuid) {
      getBookingCollections();
      getCollectionStatsCount();
      getDemands();
      getPaymentsDetails();
      getBookingDetails();
      getAllDocuments();
    }
  }, [uuid]);

  const getPaymentProof = async (uuid) => {
    const res = await getTransactionProof(uuid);
    if (res.data.status === 200) {
      if (res.data.data.length > 0) {
        setDocuments(res.data.data);
        setShowDocumentModal(true)
      } else {
        toast.info('No Documents found.')
      }
    }
  }

  const handleReassignModalSubmit = (userDetails, remarks) => {
    let user_array = [];
    if (userDetails?.length > 0) {
      user_array = userDetails.map(u => u.value)
    }
    reassignLeads([bookingDetails?.lead?.uuid], userDetails?.length ? user_array : userDetails.value, remarks).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned")
        getBookingDetails();
        setShowReassignModal(false)
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    // resetDataMods()
  }

  const postDeleteLead = (selectedValue) => {
    deleteLeads(selectedValue).then(res => {
      if (res.status === 200) {
        toast.success("Lead Deleted");
        history.push(`/collection-manager?project=${searchParams.get('project')}&tab=${searchParams.get('tab')}`)
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
  }

  return (
    <div className='w-100 !pt-5 bg bg-black100 flex flex-col overflow-hidden'>
      <div className='flex flex-row items-center w-100 !px-5'>
        <div className='w-4/12'>
          <div className='flex items-center'>
            <Link className='pr-hover' to={`/collection-manager?project=${searchParams.get('project')}&tab=${searchParams.get('tab')}`}>
              <BackICon />
            </Link>
            <h2 className='inter text-textblack text-xl font-semibold !mb-0 !ml-5'>{bookingDetails?.customer?.customer_name} ( #{bookingDetails?.booking?.unique_id})</h2>
          </div>
        </div>
        <div className='w-8/12 inline-flex justify-end items-center'>

          <div className='inline-flex gap-x-3 items-center'>
            <a className='inline-flex items-center gap-x-3' href={`tel:${getMobileNumber(bookingDetails?.customer?.customer_mobile)}`}>
              <div className='w-[34px] h-[34px] shrink-0 grow-0 basis-[34px] !border !border-grayLight rounded-lg bg-white shadow-boxshadow inline-flex justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M2.62125 0.19236C4.75425 -0.519097 6.90971 0.817014 7.50842 2.98023C7.91639 4.45429 7.53002 5.99994 6.58787 7.05833C6.78083 7.30824 6.99168 7.54852 7.22057 7.77759C7.44836 8.00554 7.6872 8.2156 7.93554 8.40791C8.99355 7.45831 10.5439 7.0676 12.0222 7.47736C14.185 8.07685 15.5186 10.234 14.8082 12.3669C14.3422 13.7663 13.4731 14.6166 12.314 14.8965C11.237 15.1566 10.0758 14.8801 9.05163 14.4544C6.98887 13.5969 4.95202 11.9078 4.02179 10.9769C3.0696 10.024 1.38785 7.98421 0.538138 5.92533C0.116188 4.90293 -0.155499 3.74602 0.105165 2.67396C0.385601 1.52059 1.23155 0.655861 2.62125 0.19236ZM5.80821 3.45079C5.44419 2.13556 4.23468 1.51388 3.17944 1.86585C2.25186 2.17522 1.93014 2.63512 1.81935 3.09076C1.68879 3.62772 1.80232 4.36423 2.16885 5.25232C2.89608 7.01443 4.40431 8.86392 5.26968 9.72993C6.11411 10.575 7.96164 12.0908 9.7288 12.8254C10.6196 13.1957 11.3597 13.3121 11.8999 13.1817C12.3581 13.071 12.8221 12.7474 13.1345 11.8095C13.4868 10.7517 12.8641 9.54135 11.551 9.17739C10.5737 8.90652 9.56904 9.22035 8.97109 9.86073C8.58497 10.2743 7.84895 10.51 7.22273 10.0729C6.78212 9.76533 6.36367 9.41583 5.97268 9.02455C5.58107 8.63265 5.23143 8.21319 4.92382 7.7716C4.48947 7.14805 4.72124 6.41489 5.13177 6.02786C5.76741 5.42861 6.07814 4.42608 5.80821 3.45079Z" fill="#92929D" />
                </svg>
              </div>
              <span className='text-textblack inter text-base font-semibold leading-6'>{getMobileNumber(bookingDetails?.customer?.customer_mobile)}</span>
            </a>
            <div className="w-2 h-2 rounded-full bg-black700"></div>
            {bookingDetails?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
              <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                B
              </span>{bookingDetails?.channel_partner_name}
            </div>}
            {!bookingDetails?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
              <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
                S
              </span>{bookingDetails?.assigned_user.name}
            </div>}
          </div>

          <div className="dropdown view-more ">
            <button className="bg-tr border-0 !px-4 !py-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <HeaderMenu />
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
              <div className="head-itme pb-2 m-0">Actions <span></span></div>
              {/* <a className='dropdown-item active:!text-black800 active:!bg-black200' onClick={() => setShowModal(true)}>Create Customer link</a> */}
              {/* <a className='dropdown-item active:!text-black800 active:!bg-black200'>Add New Booking</a> */}
              <a
                className='dropdown-item active:!text-black800 active:!bg-black200'
                onClick={() => getPaymentMilestones()}
              >Raise New Demand</a>
              <div className="head-itme !mt-3 whitespace-nowrap">Bulk Action <span></span></div>
              <a className='dropdown-item' onClick={() => setShowReassignModal(true)}>Re-assign Booking</a>
              <a className="dropdown-item !text-red hover:!bg-red hover:!text-white" onClick={() => setShowDeleteModal(true)}>Delete Booking</a>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-between !border border-grayLight rounded-lg shadow-boxshadow divide-x divide-grayLight !mx-5 !mt-5'>
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Booking Date</h3>
          <p className='inter text-textblack text-base leading-6 m-0'>{moment(bookingDetails?.booking?.schedule_date).format('Do MMM YYYY')}</p>
        </div>
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Sold By</h3>
          {bookingDetails?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
              B
            </span>{bookingDetails?.channel_partner_name}
          </div>}
          {!bookingDetails?.channel_partner_name && <div className='inline-flex items-center !border !border-grayLight rounded-[28px] !py-1 !pl-1 !pr-2 gap-x-1 text-textblack text-xs tracking-[0.24px]'>
            <span className='w-4 h-4 text-[9px] font-medium text-textblack inline-flex items-center justify-center bg-[#D9E2FC] rounded-full'>
              S
            </span>{bookingDetails?.assigned_user.name}
          </div>}

        </div>
        {/* <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Followup - Date</h3>
          <p className='inter text-textblack text-base leading-6 m-0'>10:08 AM, 4 Feb 2023</p>
        </div> */}
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Pending Amt.</h3>
          <p className='inter text-textblack text-base leading-6 m-0'>
            {bookingDetails?.bookingPricing && (bookingDetails?.bookingPricing.adjusted_price || bookingDetails?.bookingPricing.final_price) ? `₹ ${toINRFormat(parseFloat(bookingDetails?.bookingPricing.adjusted_price || bookingDetails?.bookingPricing.final_price || 0) - (bookingDetails?.booking?.received_amount ? parseFloat(bookingDetails?.booking?.received_amount) : 0))}` : '-'}
          </p>
        </div>
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Net. Purchase Price</h3>
          <p className='inter text-textblack text-base leading-6 m-0'>{bookingDetails?.bookingPricing && bookingDetails?.bookingPricing.adjusted_price ? `₹ ${toINRFormat(bookingDetails?.bookingPricing.adjusted_price || bookingDetails?.bookingPricing.final_price || 0)}` : '-'} </p>
        </div>
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>
            Last Collection
            {/* {
              bookingDetails?.collection ?
                <span className={`${getStatusClasses(bookingDetails?.collection.approval_status)}  text-sm font-medium !px-2.5 !py-1 rounded capitalize w-20`}>{bookingDetails?.collection.approval_status || 'Pending'}</span>
                :
                ''
            } */}
          </h3>
          <p className='inter text-textblack text-base leading-6 m-0'>{bookingDetails?.collection && bookingDetails?.collection?.total_amount_paid ? `₹ ${toINRFormat(bookingDetails?.collection?.total_amount_paid)}` : '-'} </p>
        </div>
        <div className='flex flex-col gap-y-3 py-4 px-9'>
          <h3 className='text-sm inter text-black700 font-medium leading-5 m-0'>Last Demand</h3>
          <p className='inter text-textblack text-base leading-6 m-0'>{bookingDetails?.demand && bookingDetails?.demand?.total_amount_due ? `₹ ${toINRFormat(bookingDetails?.demand?.total_amount_due)}` : '-'} </p>
        </div>

      </div>

      <div className='!mt-5 !px-5'>
        <nav className='tabs-bottom-active-border !border-b !border-grayLight'>
          <div className="nav nav-tabs border-0 !mb-2 gap-x-6" id="nav-tab" role="tablist">
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'overview' ? 'active' : ''}`}
              onClick={() => setActiveTab('overview')}
            >
              Overview
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'customer' ? 'active' : ''}`}
              onClick={() => setActiveTab('customer')}
            >
              Customer Details
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'unit' ? 'active' : ''}`}
              onClick={() => setActiveTab('unit')}
            >
              Unit Details
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'document' ? 'active' : ''}`}
              onClick={() => setActiveTab('document')}
            >
              Documents
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'demand' ? 'active' : ''}`}
              onClick={() => setActiveTab('demand')}
            >
              Demand Notes
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'collection' ? 'active' : ''}`}
              onClick={() => setActiveTab('collection')}
            >
              Collection
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'payment' ? 'active' : ''}`}
              onClick={() => setActiveTab('payment')}
            >
              Payment Ledger
            </a>
            <a
              className={`nav-item inter !text-base font-medium !text-textblack bg-transparent !p-0 border-0 m-0 nav-link hover:!text-primary ${activeTab === 'construction' ? 'active' : ''}`}
              onClick={() => setActiveTab('construction')}
            >
              Construction Updates
            </a>
          </div>
        </nav>
      </div>
      <div className='h-100 overflow-hidden'>
        <div className='flex h-100 overflow-x-hidden overflow-y-auto bg-black100 !pt-5 !pl-5'>
          <div className="tab-content w-100" id="nav-tabContent">
            {activeTab === 'overview' && <div className={`tab-pane !pb-6 fade ${activeTab === 'overview' ? 'show active' : ''}`} id="nav-booking" role="tabpanel" aria-labelledby="nav-booking-tab">
              <Overview
                bookingDetails={bookingDetails}
                demand={bookingDetails?.demand}
                collection={bookingDetails?.collection}
                payments={payments}
                lead_uuid={bookingDetails?.lead?.uuid}
                collections={collections}
                pdc={bookingDetails?.pdc[0]}
              />
            </div>}
            {activeTab === 'customer' && <div className={`tab-pane !pb-6 fade ${activeTab === 'customer' ? 'show active' : ''}`} id="nav-collections" role="tabpanel" aria-labelledby="nav-collections-tab">
              {bookingDetails && <CustomerDetail bookingDetails={bookingDetails} />}
            </div>}
            {activeTab === 'unit' && <div className={`tab-pane !pb-6 fade ${activeTab === 'unit' ? 'show active' : ''}`} id="nav-demand" role="tabpanel" aria-labelledby="nav-demand-tab">
              {bookingDetails && <UnitDetail bookingDetails={bookingDetails} />}
            </div>}
            {activeTab === 'document' && <div className={`tab-pane !pb-6 fade ${activeTab === 'document' ? 'show active' : ''}`} id="nav-document" role="tabpanel" aria-labelledby="nav-document-tab">
              <Document
                documents={customerDocument}
                name={bookingDetails?.customer?.customer_name}
                getAllDocuments={getAllDocuments}
                uuid={uuid}
                customer={bookingDetails?.customer}
              />
            </div>}
            {activeTab === 'demand' && <div className={`tab-pane !pb-6 fade ${activeTab === 'demand' ? 'show active' : ''}`} id="nav-demandnotes" role="tabpanel" aria-labelledby="nav-demandnotes-tab">
              <Demannotes
                demands={demands}
                collectionStats={collectionStats}
                planName={bookingDetails?.paymentPlan}
                getPaymentMilestones={getPaymentMilestones}
              />
            </div>}
            {activeTab === 'collection' && <div className={`tab-pane !pb-6 fade ${activeTab === 'collection' ? 'show active' : ''}`} id="nav-collectionstab" role="tabpanel" aria-labelledby="nav-collectionstab-tab">
              <Collectionstab
                collections={collections}
                collectionStats={collectionStats}
                planName={bookingDetails?.paymentPlan}
                totalPrice={bookingDetails?.bookingPricing?.total_price}
                setShowAddCollectionModal={setShowAddCollectionModal}
              />
            </div>}
            {activeTab === 'payment' && <div className={`tab-pane !pb-6 fade ${activeTab === 'payment' ? 'show active' : ''}`} id="nav-paymenttab" role="tabpanel" aria-labelledby="nav-paymenttab-tab">
              <Paymenttab
                payments={payments}
                collectionStats={collectionStats}
                planName={bookingDetails?.paymentPlan}
                totalPrice={bookingDetails?.bookingPricing?.total_price}
                setShowAddCollectionModal={setShowAddCollectionModal}
                getPaymentMilestones={getPaymentMilestones}
                getPaymentProof={getPaymentProof}
              />
            </div>}
            {activeTab === 'construction' && <div className={`tab-pane !pb-6 fade ${activeTab === 'construction' ? 'show active' : ''}`} id="nav-construction" role="tabpanel" aria-labelledby="nav-construction-tab">
              <Construction uuid={uuid} selectedProject={selectedProject} />
            </div>}

          </div>
        </div>

      </div >
      <CreateCustomer show={showModal} closeModal={() => setShowModal(false)} />

      {showAddCollectionModal && <AddCollection
        show={showAddCollectionModal}
        closeModal={() => setShowAddCollectionModal(false)}
        item={{ booking_uuid: uuid }}
        onSuccess={() => {
          getBookingCollections();
          getPaymentsDetails();
        }}
        selectedProject={selectedProject}
        allBookings={[]}
      />}

      {showRaiseDemand && <AddDemand
        show={showRaiseDemand}
        closeModal={() => {
          setShowRaiseDemand(false)
        }}
        item={{
          booking_uuid: uuid,
          customer_name: bookingDetails?.customer?.customer_name,
          adjusted_price: bookingDetails?.bookingPricing?.adjusted_price
        }}
        paymentMilestones={paymentMilestones}
        onSuccess={() => {
          getDemands();
        }}
      />}
      {showDocumentModal &&
        <DocumentsGallery
          show={showDocumentModal}
          closeModal={() => setShowDocumentModal(false)}
          documents={documents}
        />
      }
      {showReassignModal && <ReassignLeadModal
        setShow={setShowReassignModal}
        show={showReassignModal}
        handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
        count={1}
        selectedLeadName={bookingDetails?.customer?.customer_name}
      />}
      {showDeleteModal && <ConfirmDeletion
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        title={`Delete ${bookingDetails?.customer?.customer_name}?`}
        deleteHandler={() => postDeleteLead(bookingDetails?.lead?.uuid)}
        subText={`After removal, unit ${bookingDetails?.bookingUnit?.block}, unit ${bookingDetails?.bookingUnit?.block} will be unblocked.`}
      />}
    </div >
  )
}

export default CollectionDetail
