import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'

const ConfirmDeletionCustom = ({ show, setShow, deleteHandler, deleteText }) => {
    return (

        <PopUpModal
            setShow={setShow}
            show={show}
            heading={<>
                Confirm Delete?
            </>}
            body={<>
                <div className='!p-4'>

                    <div className="mb-4">
                        Are you sure you want to delete {deleteText}?
                    </div>
                    <div className="justify-content-end d-flex">
                        <Button className="mr-2 border !p-2 rounded-lg !font-medium bg-white !text-textblack " size="sm" onClick={() => setShow(false)}>
                            Cancel
                        </Button>
                        <Button className="mr-2 border !p-2 rounded-lg !bg-red !font-medium !text-white " size="sm" onClick={deleteHandler}>
                            Confirm Delete
                        </Button>
                    </div>
                </div>
            </>}
        />
    )
}

export default ConfirmDeletionCustom