import React, { useState } from 'react'
import LeadsFooterBar from '../../LeadsManager/LeadsFooterBar';
import { getPriceFromConfig, toINRFormat } from '../../../helpers/helpers';

const TableView = ({
    unitList,
    basePriceData,
    setSelectedUnit,
    setShowUnitDetailModal,
    setShowEditUnitModal,
    searchText,
    getSelectedUnitBookings,
    handleMarkAvailableAndUnavailable,
    basePriceConfigMap,
    allowedPermission,
    getEOILeadDetails,
    isUnitTypePlot
}) => {

    const getStatusClass = (status) => {
        switch (status) {
            case 'Available':
                return 'text-green bg-green100'
            case 'Unavailable':
                return 'text-black700 bg-black200'
            case 'Blocked':
                return 'text-primary bg-blueHighlight'
            case 'Pending confirmation':
                return 'text-primary bg-blueHighlight'
            case 'Booked':
                return 'text-white bg-primary'
        }
    }

    const getBasePrice = (unit) => {
        if (basePriceConfigMap && unit.configuration && unit?.configuration?.id) {
            return basePriceConfigMap[unit?.configuration?.id] || '-';
        } else {
            return ''
        }
    }

    return (

        <div
            className={`${unitList.length > 0 ? 'def-table ' : 'overflow-hidden'}  def-table pr-0 !h-full z-0`}
            id='allLeadsTable'
        // onScroll={handleScroll}
        >
            <table className="table tableisscrolling text-transform: capitalize">
                <thead>
                    <tr className='bg-black100 rounded-lg'>
                        <th className='bg-black100 rounded-l-lg'>S. No.</th>
                        <th className='bg-black100'>Unit No.</th>
                        <th className='bg-black100'>Block/Tower</th>
                        {!isUnitTypePlot && <th className='bg-black100'>Floor No.</th>}
                        <th className='bg-black100'>Configuration Type</th>
                        <th className='bg-black100'>Status</th>
                        <th className='bg-black100'>Unit Type</th>
                        {!isUnitTypePlot && <th className='bg-black100'>Carpet Area</th>}
                        {!isUnitTypePlot && <th className='bg-black100'>Super Area</th>}
                        {!isUnitTypePlot && <th className='bg-black100'>Built Up Area</th>}
                        {isUnitTypePlot && <th className='bg-black100'>Plot Area</th>}
                        <th className='bg-black100'>Unit Facing</th>
                        <th className='bg-black100'>Base Rate</th>
                        <th className='bg-black100'>Total Pricing</th>
                        <th className='bg-black100'>PLC Charges 1</th>
                        <th className='bg-black100'>PLC Charges Type 1</th>
                        <th className='bg-black100'>PLC Charges 2</th>
                        <th className='bg-black100'>PLC Charges Type 2</th>
                        {/* <th className='bg-black100'>PLC Charges 3</th>
                        <th className='bg-black100'>PLC Charges Type 3</th>
                        <th className='bg-black100'>PLC Charges 4</th>
                        <th className='bg-black100'>PLC Charges Type 4</th> */}
                        {/* <th className='bg-black100'>No. of Bathrooms</th> */}
                        {/* <th className='bg-black100'>Balcony Area</th> */}
                        {/* <th className='bg-black100'>Furnishing</th> */}
                        {/* <th className='bg-black100'>No. of Car Parking</th>
                        <th className='bg-black100'>Office Cabin</th>
                        <th className='bg-black100'>Unit Plan Link</th> */}
                        <th className='!sticky -right-0'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        unitList.length > 0 && unitList.map((unit, index) => (
                            <tr key={`${unit.uuid}_${index}`}>
                                <td>{index + 1}</td>
                                <td
                                    className='cursor-pointer hover:text-primary'
                                    onClick={() => {
                                        getSelectedUnitBookings(unit.uuid, 'view', unit);
                                    }}
                                >{unit.name || '-'}</td>
                                <td>{unit.block || '-'}</td>
                                {!isUnitTypePlot && <td>{unit.floor_no || '-'}</td>}
                                <td>{unit?.configuration?.unit_configuration || '-'}</td>
                                <td>
                                    <div className={`text-sm text-center rounded-lg p-1 capitalize ${getStatusClass(unit.status)}`}>
                                        {unit.status}{`${(unit.status === 'Pending confirmation' || unit.status === 'Blocked') ? ' (' + unit.booking_stage + ')' : ''}`}
                                    </div>
                                </td>
                                <td>{unit?.configuration?.unit_type || '-'}</td>
                                {!isUnitTypePlot && <td> {unit?.configuration?.carpet_area ? `${unit?.configuration?.carpet_area} ${unit?.configuration?.carpet_area_unit}` : '-'}</td>}
                                {!isUnitTypePlot && <td>{unit?.configuration?.super_area ? `${unit?.configuration?.super_area} ${unit?.configuration?.super_area_unit}` : '-'}</td>}
                                {!isUnitTypePlot && <td>{unit?.configuration?.built_up_area ? `${unit?.configuration?.built_up_area} ${unit?.configuration?.built_up_area_unit}` : '-'}</td>}
                                {isUnitTypePlot && <td>{unit?.configuration?.plot_area ? `${unit?.configuration?.plot_area} ${unit?.configuration?.plot_area_unit}` : '-'}</td>}
                                <td>{unit.facing || '-'}</td>
                                <td>{getBasePrice(unit) ? `₹ ${toINRFormat(getBasePrice(unit))}` : '-'}</td>
                                <td>{getPriceFromConfig(unit.configuration, getBasePrice(unit)) ? `₹ ${toINRFormat(getPriceFromConfig(unit.configuration, getBasePrice(unit)))}` : '-'}</td>
                                <td>{unit.plc_charge1 ? `₹ ${toINRFormat(unit.plc_charge1)}` : '-'}</td>
                                <td>{unit.plc_charge1_type || '-'}</td>
                                <td>{unit.plc_charge2 ? `₹ ${toINRFormat(unit.plc_charge2)}` : '-'}</td>
                                <td>{unit.plc_charge2_type || '-'}</td>
                                {/* <td>{unit.plc_charge3 ? `₹ ${toINRFormat(unit.plc_charge3)}` : '-'}</td>
                                <td>{unit.plc_charge3_type || '-'}</td>
                                <td>{unit.plc_charge4 ? `₹ ${toINRFormat(unit.plc_charge4)}` : '-'}</td>
                                <td>{unit.plc_charge4_type || '-'}</td>
                                <td>{unit.bathroom_count || '-'}</td>
                                <td>{unit?.configuration?.balcony_area ? `${unit?.configuration?.balcony_area} ${unit?.configuration?.balcony_area_unit}` : '-'}</td>
                                <td>{unit.furnishing || '-'}</td> */}
                                {/* <td>{unit.car_parking || '-'}</td>
                                <td>{unit.office_cabins || '-'}</td>
                                <td>-</td> */}
                                <td className='dropdown view-more bg-white !sticky -right-0' >
                                    <>
                                        <button className="bg-tr border-0 px-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#696974" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#696974" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#696974" />
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                            {/* {unit.status === 'Blocked' && <a className="dropdown-item mb-8"> Create Quotation </a>} */}
                                            {/* {unit.status !== 'Unavailable' && <a className="dropdown-item mb-8"> Share Quotation </a>} */}
                                            {allowedPermission.includes('view_inventory_unit') &&
                                                <a
                                                    className="dropdown-item mb-8"
                                                    onClick={() => {
                                                        getSelectedUnitBookings(unit.uuid, 'view', unit);
                                                    }}

                                                > View Unit Details </a>}
                                            {/* {unit.status === 'Available' && <a className="dropdown-item mb-8"> Book Unit </a>} */}
                                            {/* {unit.status === 'Available' && <a className="dropdown-item mb-8"> Register EOI </a>} */}
                                            {allowedPermission.includes('change_inventory_unit_status') && unit.status === 'Available' && <a className="dropdown-item mb-8" onClick={() => handleMarkAvailableAndUnavailable(unit.uuid, 'Unavailable')}> Mark Un-available </a>}
                                            {allowedPermission.includes('change_inventory_unit_status') && unit.status === 'Unavailable' && <a className="dropdown-item mb-8" onClick={() => handleMarkAvailableAndUnavailable(unit.uuid, 'Available')}> Mark Available </a>}
                                            <div className="head-itme">Edit <span style={{ width: '80px' }}></span></div>
                                            {allowedPermission.includes('edit_inventory_unit') &&
                                                <a
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        getSelectedUnitBookings(unit.uuid, 'edit', unit);
                                                    }} >Edit detail</a>}
                                        </div>
                                    </>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {unitList.length === 0 && <div className='d-flex justify-content-center flex-column text-center h-full'>
                <div className='flex items-center flex-col'>

                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                    {searchText &&
                        <>
                            <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                            <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                            {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                        </>
                    }
                    {
                        !searchText &&
                        <>
                            <h2 className="po black fz16 fw-po-medium mb-8"> No Units Found</h2>
                            {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p> */}
                            {/* <button className="pr-btn px-32 py-8 fz16 mt-24 d-table mx-auto" onClick={showCreateLeadModal}>Create new lead</button> */}
                        </>
                    }
                </div>
            </div>}
        </div>
    )
}

export default TableView;