import moment from 'moment'
import React, { useContext } from 'react'
import TooltipText from '../../components/Tooltip';
import { Oval } from 'react-loader-spinner';

const Cancelled = ({ leads, searchText, isLoading }) => {

    return (
        <div className="user-table mt-24">
            <div className={`def-table pr-24 ${leads.length === 0 ? 'overflow-hidden' : ''}`}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th className='sticky-th'>Name</th>
                            <th>Mobile No.</th>
                            <th>Schedule Date</th>
                            <th>CP User</th>
                            <th>CP User Mobile</th>
                            <th>CP Firm</th>
                            <th>CP Owner Mobile</th>
                            <th>CP User Registered</th>
                            <th>Project</th>
                            <th>Sourcing Manager</th>
                            <th>Email</th>
                            <th>SV Type </th>
                            <th>Remarks</th>
                            {/* <th>Status</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (leads.length > 0 && !isLoading) && leads.map((lead, index) => (
                                <tr key={lead.uuid}>
                                    <td>{index + 1}</td>
                                    <td className="text-capitalize sticky-td">
                                        {lead.customer_name.length > 50 ? <div className='w-[100px] truncate'>
                                            <TooltipText title={lead.customer_name}>
                                                {lead.customer_name || '-'}
                                            </TooltipText>
                                        </div> : lead.customer_name}

                                    </td>
                                    <td>{lead?.customer_mobile || '-'}</td>
                                    <td>{lead?.schedule_date ? moment(lead?.schedule_date).format("h:mm a, DD MMM YY") : '-'}</td>
                                    <td>{lead?.broker_name || '-'}</td>
                                    <td>{lead.broker_mobile || '-'}</td>
                                    <td>{lead.company_name || '-'}</td>
                                    <td>{lead.company_owner_mobile || '-'}</td>
                                    <td>{lead.broker_company_id ? 'Yes' : 'No'}</td>
                                    <td>{lead?.project_name || '-'}</td>
                                    <td>{lead.closing_manager || '-'}</td>
                                    <td>{lead.customer_email || '-'}</td>
                                    <td className='capitalize'>{lead?.sv_type || '-'}</td>
                                    <td>
                                        {lead?.remarks ? <TooltipText title={lead?.remarks}>
                                            <div className='w-[200px] truncate'>
                                                {
                                                    lead?.remarks
                                                }
                                            </div>
                                        </TooltipText> : '-'}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {(leads?.length === 0 && !isLoading) && <div className='d-flex justify-content-center flex-column text-center h-full'>
                    <div className='flex items-center flex-col'>

                        <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                        {leads?.length == 0 && (searchText) &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
                            </>
                        }
                        {
                            leads?.length == 0 && !searchText &&
                            <>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Leads Found</h2>
                                {/* <p className='text-center black-dark-600 fz14 mb-0'>Please add new leads to view</p>                                */}
                            </>
                        }
                    </div>
                </div>}
                {isLoading && <div className='d-flex justify-content-center flex-column text-center h-full'>
                    <div className='flex items-center flex-col'>
                        <Oval
                            height={50}
                            width={50}
                            color="#0062FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#0062FF"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />

                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Cancelled;