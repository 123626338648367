import React, { useState, useEffect, useCallback, useContext } from "react";
import { toast } from 'react-toastify';
import { getCompanyDetails, getCompanyAgency, insertCompanyAgency, updateAgency, addCustomSource, getAllSourcesData, renameSource, deleteSource } from "../../../../services/private/company.service";
// import { createDatewithTime } from "../../../helpers/date"
import CreateAgencyModal from "../../../../components/Modals/CreateAgencyModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Consumer from '../../../../helpers/context';
import { createDatewithTime } from "../../../../helpers/date";
import CreateCustomSourceModal from "../../../../components/Modals/CreateCustomSourceModal";
import SearchInput from "../../../../components/InputGroup/SearchInput";
import PopUpModal from "../../../../components/Modals/PopUpModal";
import { useHistory } from "react-router-dom";


const USER_UUID = localStorage.getItem('source');

const LeadSource = () => {
    const [showNewSourceModal, setShowSourceModal] = useState(false);
    const [sourceList, setSourceList] = useState([]);
    const [allSources, setAllSources] = useState([]);
    const [companyDetails, setCompanyDetails] = useState({});
    const [selectedSource, setSelectedSource] = useState();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showDeleteSourceModal, setShowDeleteSourceModal] = useState(false);
    const [showIntegrationPopup, setShowIntegrationPopup] = useState(false)

    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const history = useHistory();

    const getAgencyData = async () => {
        await getAllSourcesData().then(res => {
            if (res.status === 200) {
                setAllSources(res.data.data);
                setSourceList(res.data.data);
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getCompanyInfo = async () => {
        await getCompanyDetails().then(res => {
            if (res.status === 200) {
                setCompanyDetails(res.data.data)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const handleCreateNewSource = useCallback(async (payload) => {
        setLoading(true);
        await addCustomSource(payload).then(res => {
            if (res.status === 200) {
                toast.success('New custom source created.')
                setShowSourceModal(false)
                getAgencyData();
            }
        }).catch((err) => {
            console.log("Something went wrong", { err });
            toast.error(err.response.data.message)
        });
        setLoading(false);
    }, []);

    const handleUpdateSource = useCallback(async (uuid, payload) => {
        setLoading(true);
        await renameSource(uuid, payload).then(res => {
            if (res.status === 200) {
                toast.success(`${selectedSource.display_name} Updated.`)
                setShowSourceModal(false);
                getAgencyData();
                setSelectedSource({})
            }
        }).catch((err) => {
            console.log("Something went wrong", { err });
            toast.error(err.response.data.message)
        });
        setLoading(false);
    }, [selectedSource]);

    useEffect(() => {
        getCompanyInfo();
        getAgencyData();
    }, [])

    useEffect(() => {
        if (searchText) {
            let sources = allSources.filter(s => s.display_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            setSourceList(sources);
        } else {
            setSourceList(allSources);
        }
    }, [searchText])

    const handleDeleteSource = async () => {
        if (selectedSource.uuid) {
            await deleteSource(selectedSource.uuid).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                    getAgencyData();
                }
            }).catch((e) => {
                console.log({ e })
                // toast.error(e.response.data.message);
                setShowIntegrationPopup(true)
            });
            setShowDeleteSourceModal(false)
        }
    }

    return (
        <>
            {showNewSourceModal &&
                <CreateCustomSourceModal
                    show={showNewSourceModal}
                    setShow={setShowSourceModal}
                    handleCreateNewSource={handleCreateNewSource}
                    selectedSource={selectedSource}
                    handleUpdateSource={handleUpdateSource}
                    loading={loading}
                />
            }

            <div className="w-100 market-agency h-full flex flex-col">
                <div className="settings-title">
                    <div className="row d-flex d-flex flex-md-fill flex-wrap">
                        <div className="col-md-6 col-sm-6 d-flex flex-md-fill flex-column">
                            <h1 className="fz24 mb-16 black fw-po-medium">Lead Source</h1>
                            <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Custom lead source for {companyDetails.name}</p>
                        </div>
                        <div className="col-md-6 col-sm-6 d-flex justify-content-end align-items-md-end aling-items-center flex-md-fill">
                            <div className='w-3/5'>
                                <SearchInput
                                    placeholder={'Search Source'}
                                    searchText={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    handelClear={() => setSearchText('')}
                                /></div>
                            <button
                                onClick={() => {
                                    setSelectedSource({})
                                    setShowSourceModal(true)
                                }}
                                className="pr-btn  ml-3 flex py-2">
                                <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="path-1-inside-1_1117_451" fill="white">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                    </mask>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                    <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                </svg>Create New</button>

                        </div>
                    </div>
                </div>

                <div className="agency-table ml-24 mt-24 h-full overflow-auto">
                    <div className="def-table pr-24 !h-full">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>Created Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sourceList && sourceList?.length > 0 && sourceList.map((source, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">
                                                {source.display_name?.length > 20 ?

                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{source.display_name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{source.display_name}</span>
                                                    </OverlayTrigger>
                                                    :

                                                    <span className="text-capitalize">{source.display_name}</span>
                                                }
                                            </td>
                                            <td>{createDatewithTime(source.created_on)}</td>
                                            <td>
                                                <div className="view-more ml-auto d-table">
                                                    <button
                                                        className="bg-tr border-0"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div
                                                        className="dropdown-menu dropdown-menu-right"
                                                        aria-labelledby="dropdownMenuButton"
                                                    >
                                                        <a
                                                            className="dropdown-item"
                                                            data-toggle="modal"
                                                            data-target="#exampleModal"
                                                            onClick={
                                                                () => {
                                                                    setSelectedSource(source);
                                                                    setShowSourceModal(true)
                                                                }
                                                            }
                                                        >
                                                            Edit Source
                                                        </a>
                                                        <a
                                                            className="dropdown-item del-menu"
                                                            data-toggle="modal"
                                                            data-target="#exampleModal"
                                                            onClick={
                                                                () => {
                                                                    setSelectedSource(source);
                                                                    setShowDeleteSourceModal(true)
                                                                }
                                                            }
                                                        >
                                                            Delete Source
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {sourceList?.length === 0 && <div className='d-flex justify-content-center flex-column text-center lead-pagination'>
                            <div className="flex items-center flex-col">
                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Custom Source Found</h2>
                                <p className='text-center black-dark-600 fz14 mb-0'>Please add new source to view</p>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {/* {showIntegrationPopup && <PopUpModal
                setShow={setShowIntegrationPopup}
                show={showIntegrationPopup}
                heading={<>
                    Project cannot be deleted.
                </>}
                body={<div className=' p-3'>
                    <div className="mb-4">
                        {integrationMsg}
                    </div>
                    <div className="justify-content-end d-flex modal-footer">
                        <Button variant="" className="cancel" size="sm" onClick={() => setShowIntegrationPopup(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>}
            />} */}
            {showDeleteSourceModal ? <PopUpModal
                setShow={setShowDeleteSourceModal}
                show={showDeleteSourceModal}
                heading={<>
                    Confirm Delete?
                </>}
                body={<div className=' p-3'>
                    <div className="mb-4">
                        Are you sure you want to delete source?
                    </div>
                    <div className="justify-content-end d-flex">
                        <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteSourceModal(false)}>Cancel</button>
                        <button className="fw-po-medium del-btn px-16" onClick={handleDeleteSource}>Confirm Delete</button>

                    </div>
                </div>}
            /> : null}
            {showIntegrationPopup && <PopUpModal
                setShow={setShowIntegrationPopup}
                show={showIntegrationPopup}
                heading={<>
                    <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
                            <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#FC5A5A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8" />
                        </svg>
                    </figure>
                    {/* <AlertIcon /> */}
                    <h1 className="text-lg font">
                        Warning: Unable to Delete Source.
                    </h1>

                </>}
                body={<div className='!px-5 !pb-3'>
                    <div className="mb-4">
                        This source cannot be deleted because there are leads currently associated with it in the system.
                    </div>
                    <div className="">
                        To delete this source, please reassign the leads to a different source first.
                    </div>

                    <div className="justify-content-end d-flex !mt-4">
                        <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowIntegrationPopup(false)}>Cancel</button>
                        <button className="pr-btn flex py-2 px-4" size="sm" onClick={() => {
                            // history.push('/')
                            setShowIntegrationPopup(false)
                            window.open("/", "_blank");
                        }}>
                            Go to Dashboard
                            {/* <Pageback className=" stroke-white fill-white " /> */}
                        </button>
                    </div>
                </div>}
            />}
            {/* <PaginationFooter
                countPerPage={countPerPage}
                setCountPerPage={setCountPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={100}
            /> */}
        </>
    )
}

export default LeadSource;