import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as SearchCloseIcon } from '../../../assets/icons/searchClose.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';
import style from '../Projects/style.module.css';
import MultiLevelDropdown from '../../../components/Projects/multi-level-dropdown';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkIsSomeItemAvailableInList, convertToTitleCase, numDifferentiation } from '../../../helpers/helpers';
import { ReactComponent as LaunchIcon } from '../../../assets/icons/Launch.svg';
import { getListingProjects, updateProjectStatusByUUID } from '../../../services/public/public.service';
import downArrow from "../../../assets/icons/downarrow.svg";
import { Image, Dropdown } from 'react-bootstrap';
import { ReactComponent as Vthreedots } from '../../../assets/icons/threedots.svg';
import { toast } from 'react-toastify';
import TooltipText from '../../../components/Tooltip';

const ProjectStatusEnum = {
    active: 'ACTIVE',
    draft: 'DRAFT',
    published: 'PUBLISHED'
}

const StatusList = [
    {
        label: 'ACTIVE',
        value: 'active'
    },
    {
        label: 'DRAFT',
        value: 'draft'
    },
    {
        label: 'PUBLISHED',
        value: 'published'
    }
]

const ListingProjects = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [allProjects, setAllProjects] = useState([]);

    const history = useHistory();

    // const handleChangeProjectType = useCallback((property, transaction) => {
    //     if (property && transaction) {
    //         // localStorage.setItem('property_type', property);
    //         // localStorage.setItem('transaction_type', transaction)
    //         // setPropertyType(property);
    //         // setTransactionType(transaction);
    //         const param = {
    //             pt: property,
    //             tt: transaction
    //         }
    //         localStorage.setItem('projectStatus', 'new');
    //         history.push(`/settings/listing/projects/create-project/basic?pt=${property}&tt=${transaction}`)
    //     }
    // }, []);

    const getAllProjects = async () => {
        setLoading(true);
        await getListingProjects().then((res) => {
            setLoading(false);
            if (res.status === 200) {
                console.log(res.data.data, 'data')
                let projects = [...res.data.data.projects];
                // setProjectList(res.data.data.projects)
                // const spliced = projects.splice(0, 12);
                // setProjects(spliced);
                setAllProjects(projects)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        })
    }

    const getIntentClassName = (intent) => {
        switch (intent) {
            case 'active':
                return 'pr-btn';
            case 'published':
                return 'hot-btn';

            case 'draft':
                return 'warm-btn';
        }
    }

    const handleStatusChange = async (option, uuid) => {
        try {
            const res = await updateProjectStatusByUUID(uuid, { status: option.value });
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getAllProjects()
            }
        } catch (err) {
            console.log({ err })
            toast.error(err?.response?.data?.message)
        }
    }

    useEffect(() => {
        getAllProjects();
    }, []);

    return (
        <>
            {/* <img src={userProfile?.logo} alt="" className='sm:h-full h-[60px] object-contain' /> */}
            <div className='!p-5 border-b border-black400 w-full'>
                <div className='flex items-center justify-between sm:flex-nowrap flex-wrap'>
                    <h2 className='fz24 mb-0 black fw-po-medium inter'>Projects</h2>
                    {/* <div className='sm:hidden text-center block w-full mt-2 mb-7'>
                        <h2 className='text-2xl font-bold text-black traking-[0.1px] leading-[30px] mt-2 '>Launchpad Dashboard</h2>
                    </div> */}
                    <div className='sm:w-2/4 w-full !mt-4 sm:mt-0'>
                        <div className='flex justify-end items-start gap-2'>
                            <div className='relative md:max-w-[266px] max-w-full md:w-auto w-full'>
                                {/* <SearchIcon className='absolute left-2 top-[3px] z-10' /> */}
                                <input
                                    type="text"
                                    placeholder='Search'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className={`${searchText ? 'border-primary' : ''} text-black700 text-sm rounded border border-solid border-black400 block w-full bg-white placeholder:text-black700 placeholder:text-[14px] placeholder:font-normal placeholder:leading-[22px] focus:border-primary outline-0 leading-[22px] py-1 pl-9 pr-9 relative`}
                                />
                                {searchText &&
                                    <SearchCloseIcon
                                        className='absolute right-2 bottom-2 cursor-pointer hover:path searchCloseIcon w-3'
                                        onClick={() => setSearchText('')}
                                    />}
                            </div>
                            {/* <Link to='/settings/listing/projects/create-project/basic' className='text-14 bg-primary text-white inline-flex gap-x-2 rounded text-sm font-medium items-center py-1 px-2 traking-[0.5px] outline-none whitespace-nowrap'> + New Project</Link> */}
                            {/* <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} /> */}
                        </div>

                    </div>
                </div>
            </div>

            <div className={`${style.all_project_table} all-project-table d-flex flex-fill flex-col overflow-auto  pl-24 pt-20 w-100`}>
                <div className='w-100 h-100'>
                    {allProjects.length > 0 ? <div className={`${style.projectlistdeftable} def-table pr-24 h-100 pb-0`}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Project Name</th>
                                    <th>Developer</th>
                                    <th>Status</th>
                                    <th>Permalink</th>
                                    <th className='sticky -right-[10px]'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {allProjects.map((project, index) => {
                                    const { uuid, name, developer, area, city, state, district, zip_code, site_address, updated_on } = project
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className=' capitalize '>
                                                {name?.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >

                                                        <div className='d-inline-flex align-items-center gap-8'>
                                                            {project.developer_created && <LaunchIcon className='' />}
                                                            <a href={`http://design.dev.propacity.in.s3-website.ap-south-1.amazonaws.com/project/${project?.permalink}`} target='_blank' className='pr-hover black three-dots'>{name}</a>
                                                            {/* <span className={`${style.newupdate} ml-0`}>New Update</span> */}
                                                        </div>
                                                    </OverlayTrigger>
                                                    :
                                                    <>
                                                        {project.developer_created && <LaunchIcon className='mr-8' />}
                                                        <a href={`http://design.dev.propacity.in.s3-website.ap-south-1.amazonaws.com/project/${project?.permalink}`} target='_blank' className='pr-hover black three-dots'>{name}</a>
                                                        {/* <span className={`${style.newupdate}`}>New Update</span> */}
                                                    </>
                                                }
                                            </td>
                                            <td className='text-capitalize'>
                                                {project?.builder_name?.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{project?.builder_name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{project?.builder_name || '-'}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize'>{project?.builder_name || '-'}</span>
                                                }
                                            </td>

                                            <td>
                                                <button
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    className={`${getIntentClassName(project.status)} border rounded-lg p-2 flex `}
                                                >
                                                    {ProjectStatusEnum[project.status]}
                                                    <Image src={downArrow} />
                                                </button>

                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        StatusList.map((option, index) => {
                                                            return (

                                                                <a tabIndex={''}
                                                                    key={index}
                                                                    className={`dropdown-item  text-capitalize`}
                                                                    onClick={() => {
                                                                        handleStatusChange(option, project.uuid)
                                                                    }}
                                                                >{option.label}
                                                                </a>

                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                            <td>{project?.permalink || '-'}</td>
                                            <td className='dropdown view-more'>
                                                <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <TooltipText title='View Option' placement='left'>
                                                        <Vthreedots />
                                                    </TooltipText>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    {/* <a className="dropdown-item text-capitalize" onClick={
                                                        () => {
                                                            localStorage.setItem('projectStatus', 'edit');
                                                            localStorage.setItem('projectName', name);
                                                            history.push(`/settings/listing/projects/create-project/basic/${uuid}?pt=${project.property_type}&tt=${project.transaction_type}`)
                                                        }
                                                    }>Edit Project</a> */}
                                                    <a className="dropdown-item text-capitalize" onClick={
                                                        () => {
                                                            navigator.clipboard.writeText(`https://www.propacity.com/projects/${project?.permalink}`).then(function () {
                                                                toast.success('Project Link Copied');
                                                            }, function (err) {
                                                                toast.error('Could not copy Project Link');
                                                            });
                                                        }
                                                    }>Copy Project Link</a>

                                                </div>

                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                        :
                        <>
                            {!loading && <div className='mt-16 w-full h-full'>
                                <div className='flex flex-col justify-center h-full items-center mt-[50px]'>

                                    {searchText ? <>
                                        <figure><ResultSearchIcon /></figure>
                                        <h2 className='black text-base font-medium mb-2'>Didn’t find any matches </h2>
                                        <p className='text-center text-black600'>We're sorry, but we couldn't find any results for your search.
                                            <span className='sm:block'>Please try again with different keywords.</span></p>
                                    </>
                                        :
                                        <>
                                            <h2 className='black text-base font-medium mb-2'>No Project Found </h2>
                                            <p className='text-center text-black600'>Create a New Project to see it here.</p>
                                            {/* <Link to='/settings/listing/projects/create-project/basic' className='text-14 border mt-6 border-primary text-primary inline-flex gap-x-2 rounded text-sm font-medium items-center py-1 px-2 traking-[0.5px] whitespace-nowrap'>
                                        {/* <PlusIcon />
                                        + New Project</Link> */}
                                            {/* <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} /> */}
                                        </>}

                                </div>
                            </div>}
                            {loading &&
                                <div className='flex justify-center items-center h-full w-full'>
                                    {/* <Sppiner /> */}
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            {/* {showDeleteModal && <DeleteModal
                title="Delete Project?"
                subText='Deleting this project will permanently remove all associated data, including Media and Documents. Please note that once you delete a project, it cannot be restored.'
                show={showDeleteModal}
                closeModal={closeDeleteModal}
                onClick={() => handleDelete()}
            />} */}
        </>
    )
}

export default ListingProjects;