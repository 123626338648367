import React, { useEffect, useState } from 'react';
import { ReactComponent as SharingIcon } from '../../../../assets/icons/sharing.svg';
// import { getSvCompletedReadAccess, updateSvCompletedReadAccess } from '../../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { updateAutoLeadShare } from '../../../../services/private/company.service';

const StatusAutomationCard = ({ getUserConfiData, userData }) => {
    const [svStatusToggle, setSvStatusToggle] = useState(false);

    useEffect(() => {
        setSvStatusToggle(userData?.auto_lead_share)
    }, [userData]);

    const handleUpdateStatusPermission = async (value) => {
        const res = await updateAutoLeadShare({ value });
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUserConfiData();
        }
    }

    return (
        <div className='!border border-grayLight hover:border-primary hover:!border-2 rounded-lg shadow-boxshadow !p-4 relative'>
            <Link to={'/settings/automation/status-permissions'} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link>
            <div className='flex justify-between items-center'>
                <div>
                    <SharingIcon />
                </div>

                <button
                    type="button"
                    className={`btn btn-lg btn-toggle ${svStatusToggle ? 'active' : ''} mr-2`}
                    value={svStatusToggle}
                    onClick={() => {
                        handleUpdateStatusPermission(!svStatusToggle)
                    }}
                >
                    <div className="handle"></div>
                    <span className={`${svStatusToggle ? 'on' : 'off'}`}>{svStatusToggle ? 'ON' : 'OFF'}</span>
                </button>

            </div>
            <div className='flex flex-row justify-between gap-2 mt-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>Status wise Lead Sharing & Lead Assignment</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>
                </div>
            </div>
            <div className='!mt-2'>
                <p className='text-sm text-black700 w-100 inter'>Lead is shared with selected users for a particular status.</p>
            </div>
            <div className='mt-2 flex justify-end'>
                <button type='button'
                    className='!text-primary bg-blueHighlight !py-2 !px-4 rounded-lg inter font-medium'
                >
                    Configure
                </button>
            </div>
        </div>
    )
}

export default StatusAutomationCard;