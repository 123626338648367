import React from 'react'
import InputField from '../form-fields/input-field'
import RightModal from '../Modals/RightModal'
import InputText from '../InputGroup/InputText'

const CreateCategoryModal = ({ show, closeModal, isEdit, name, updateCategory, handleSubmit, handleCategoryChanges }) => {

    return (
        <RightModal closeModal={closeModal} show={show}>
            <div className="modal-header sticky top-0 left-0 bg-white !px-5  !py-4 flex">
                <h1 className='text-black text-2xl inter m-0'>{isEdit ? 'Edit' : 'Create'} Category</h1>
                <button onClick={closeModal} className='modal-close'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725" />
                </svg></button>
            </div>
            <div className="modal-body !px-5 h-5/6 overflow-y-auto space-y-4">
                <InputText
                    label='Name'
                    placeholder='Category name'
                    type='text'
                    name='name'
                    value={name}
                    onChange={handleCategoryChanges}
                />
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                <button onClick={closeModal} className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                <button onClick={() => {
                    if (!isEdit) {
                        handleSubmit()
                    } else {
                        updateCategory()
                    }
                }} className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>{isEdit ? 'Update' : 'Save'}</button>

            </div >
        </RightModal>
    )
}

export default CreateCategoryModal