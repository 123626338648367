import React, { useContext, useEffect, useState } from "react";


import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/NavRight.svg';

import { toast } from "react-toastify";
import SliderModal from "../../Modals/SliderModal";
import InputSelect from "../../InputGroup/InputSelect";


const TeamIntegrationModal = ({ show, closeModal, handleSetTeamRouting, allTeams, selectedProject }) => {

    const [selectedUser, setSelectedUser] = useState([]);
    const [error, setError] = useState();
    const [remarks, setRemarks] = useState();
    const [selectedTeam, setSeletecedTeam] = useState([]);
    const [viewMoreIndex, setViewMoreIndex] = useState(0);

    // edit team from local data
    useEffect(() => {
        if (selectedProject?.routing?.length && allTeams.length) {
            let teams = [];
            let allTeamsCopy = [...allTeams];
            selectedProject?.routing.forEach((routing, index) => {
                teams[index] = { ...routing }
                allTeamsCopy.forEach(team => {
                    if (team.id === routing.team_id) {
                        routing.users.forEach(user => {
                            team.users.forEach(tUser => {
                                if (tUser.id === user.id) {
                                    tUser.isSelected = true;
                                    setSelectedUser([...selectedUser, user.id])
                                }
                            })
                        })
                        teams[index].users = team.users
                    }
                })
            })
            setSeletecedTeam(teams)
        }
    }, [selectedProject, allTeams])

    const handleReassignModalSubmit = () => {
        if (selectedUser?.length === 0) {
            toast.error('Please select users from team')
            return;
        }

        const teamName = selectedTeam.map(item => (item.name || item.team_name));
        const payload = selectedTeam.map(item => ({
            team_id: item.id || item.team_id,
            name: item.name || item.team_name,
            users: item.users?.filter(u => u.isSelected).map(i => i.id)
        }))

        handleSetTeamRouting(payload, teamName);
        closeModal();
    }

    return (
        <SliderModal
            setShow={closeModal}
            show={show}
            title={`Add Team ${selectedTeam.length > 0 ? `(${selectedTeam.length})` : ''}`}
            body={<>
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto">
                        {/* {count === 1 &&
                            <div className="form-group">
                                <label className=''>Lead Name</label>
                                <div className='text-capitalize fz16 fw-po-medium '>
                                    {selectedLeadName ? selectedLeadName : '-'}
                                </div>
                            </div>} */}

                        <InputSelect
                            index={4}
                            name="user"
                            menuPlacement="bottom"
                            value={''}
                            options={allTeams}
                            onChange={(value) => {
                                const index = selectedTeam.findIndex(i => i.value === value)
                                if (index === -1) {
                                    setSeletecedTeam([...selectedTeam, value])
                                }
                            }}
                            placeholder="Select Team(s)"
                            label="Select Team(s)"
                            error={error && !selectedTeam ? 'Please Select Team' : ''}
                        // isMulti={userProfile?.mulit_user_assign && count > 1}
                        />
                        <div className="">

                            {
                                selectedTeam.length > 0 && selectedTeam.map((item, index) => (
                                    <div className="mt-4 border p-4 rounded-lg" key={`${item.id}_${index}`}>
                                        <div className="flex justify-between items-start mb-3">
                                            <div>
                                                <p className="m-0 text-base font-semibold">{item.name || item.team_name}</p>
                                                <p className="m-0 text-xs text-black700 font-medium">Lead: {item.leader_name}</p>
                                            </div>
                                            <button onClick={() => {
                                                let selectedTeamCopy = [...selectedTeam];
                                                selectedTeamCopy.splice(index, 1)
                                                setSeletecedTeam(selectedTeamCopy)
                                            }}>
                                                <CloseIcon className="h-5 w-5 text-black700" />
                                            </button>
                                        </div>
                                        {viewMoreIndex === index && item.users?.length > 0 && item.users.map((user, userIndex) => (
                                            <label className=" flex justify-between items-center px-4 border-b pb-2 m-0 bg-blue-light rounded-lg" key={`${user.name}_${index}`}>
                                                <div className="py-2">
                                                    <p className="m-0 text-sm text-black font-semibold">{user.name}</p>
                                                    <p className="m-0 text-xs text-black700 font-medium">{user?.email}</p>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className={`btn btn-lg btn-toggle ${selectedTeam[index].users[userIndex].isSelected ? 'active' : ''} mr-2`}
                                                        value={selectedTeam[index].users[userIndex]?.isSelected}
                                                        onClick={() => {
                                                            let teamsCopy = [...selectedTeam];
                                                            if (teamsCopy[index].users[userIndex]?.isSelected) {
                                                                teamsCopy[index].users[userIndex].isSelected = false;
                                                            } else {
                                                                teamsCopy[index].users[userIndex].isSelected = true;
                                                            }
                                                            setSeletecedTeam(teamsCopy);
                                                            setSelectedUser([...selectedUser, user.uuid])
                                                        }}
                                                    >
                                                        <div className="handle"></div>
                                                        <span className={`${selectedTeam[index].users[userIndex].isSelected ? 'on' : 'off'}`}>{selectedTeam[index].users[userIndex].isSelected ? 'ON' : 'OFF'}</span>
                                                    </button>
                                                </div>

                                            </label>
                                        ))
                                        }
                                        <div className="flex justify-end mt-2">
                                            <button
                                                className="flex items-center"
                                                onClick={() => {
                                                    if (viewMoreIndex === index) {
                                                        setViewMoreIndex('')
                                                    } else {
                                                        setViewMoreIndex(index)
                                                    }
                                                }}>
                                                {viewMoreIndex === index ? 'Hide' : 'View'} Users
                                                <RightIcon className={`ml-1 h-5 w-5 ${viewMoreIndex === index ? '-rotate-90' : 'rotate-90'}`} />
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {/* 
                    {!(count > 1) &&
                        <>
                            <label className='mt-3'>Comments (optional)</label>
                            <textarea
                                className="form-control mb-16"
                                name="remarks"
                                value={remarks}
                                placeholder="Enter Comments..."
                                onChange={(e) => setRemarks(e.target.value)}
                            >
                            </textarea>
                        </>
                    } */}
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button
                            className="cancel outline-btn"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="pr-btn"
                            onClick={handleReassignModalSubmit}
                        >
                            Save
                        </button>

                    </div>
                </div>
            </>}
        />
    )
}

export default TeamIntegrationModal;