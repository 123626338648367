import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';

export async function getStatusLead(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByStatus}`, null, payload, null);
}

export async function getSourceLead(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountBySource}`, null, payload, null);
}

export async function getIntentLead(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByIntent}`, null, payload, null);
}

export async function getAssignedToLead(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByAssignedTo}`, null, payload, null);
}

export async function getEmployeeEventAnalysis(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.employeeEventAnalysis}`, null, payload, null);
}

export async function getProjectLead(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByProject}`, null, payload, null);
}

export async function getProjectLeadWithStatus(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByProjectWithStatus}`, null, payload, null);
}

export async function getLeadCountByProjectWithSource(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.leadCountByProjectWithSource}`, null, payload, null);
}

export async function getDeadLeads(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.deadLeadCountByReason}`, null, payload, null);
}

export async function getBookingLeads(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.bookingLeadCount}`, null, payload, null);
}

export async function getEventsCount(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getEventsCount}`, null, payload, null);
}

export async function getEmployeeInsights(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.employeeInsights}`, null, payload, null);
}

export async function getCountForConversionAnalysis(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.countForConversionAnalysis}`, null, payload, null);
}

export async function getAllAdsByCompanyId() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.analytics.getAllAdsByCompanyId}`, null, null, null);
}

export async function getSourcongManagerToCpAnalysis(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getSourcongManagerToCpAnalysis}`, null, payload, null);
}

export async function getCpLeadAnalysis(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getCpLeadAnalysis}`, null, payload, null);
}

export async function getLeadsAnalysisBySource(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getLeadsAnalysisBySource}`, null, payload, null);
}

export async function getLeadsAnalysisByClosingManager(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getLeadsAnalysisByClosingManager}`, null, payload, null);
}

export async function getNotConnectedLeadsAnalysisByReason(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.analytics.getNotConnectedLeadsAnalysisByReason}`, null, payload, null);
}