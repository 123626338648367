import React, { useEffect, useState } from 'react'
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import InputText from '../../components/InputGroup/InputText';
import SideBanner from '../../components/SideBanner/SideBanner';
import { userLogin } from '../../services/public/public.service';

const HEADING = `A Real Estate Platform That 'Works For You'`;
const SUBHEADINGTEXT = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;


const LoginDemo = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory();

    useEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('login-demo')

        return (() => el.classList.remove('login-demo'))
    }, []);

    const handleLogin = useCallback(() => {
        userLogin(email, password).then(res => {
            localStorage.setItem("authToken", res.data.data.token)
            // history.push('/leads')
            window.location.reload();
        }).catch(err => {
            console.log({ err })
        })
    }, [email, password]);

    return (
        <div className='loginuser d-flex flex-fill vh-100 w-100'>
            <div className="container-fluid d-flex flex-fill">
                <div className="row d-flex flex-fill">
                    <div className="col-lg-4 col-md-4 col-sm-4 p-0 d-md-flex flex-fill d-none">
                        <SideBanner Heading={HEADING} Text={SUBHEADINGTEXT} />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-4 d-flex flex-fill pr-0">
                        <div className="loginuser-main-area d-flex flex-fill flex-column">
                            <figure>
                                <img src={`${process.env.PUBLIC_URL}/login-logo.svg`} />
                            </figure>
                            <h1 className='fz24 black fw-po-medium po ls1 mb-24'>Login</h1>

                            {/* <div className="form-group position-relative">
                                <label htmlFor="">Email</label>
                                <input type="password" className='form-control ' placeholder='*******' />
                                <div className="invalid-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#92929D"></path><path d="M7.00195 11C7.00195 10.8687 7.02782 10.7386 7.07807 10.6173C7.12833 10.496 7.20199 10.3857 7.29485 10.2929C7.38771 10.2 7.49794 10.1264 7.61927 10.0761C7.7406 10.0259 7.87063 10 8.00195 10C8.13328 10 8.26331 10.0259 8.38464 10.0761C8.50596 10.1264 8.6162 10.2 8.70906 10.2929C8.80192 10.3857 8.87558 10.496 8.92583 10.6173C8.97609 10.7386 9.00195 10.8687 9.00195 11C9.00195 11.2652 8.8966 11.5196 8.70906 11.7071C8.52152 11.8946 8.26717 12 8.00195 12C7.73674 12 7.48238 11.8946 7.29485 11.7071C7.10731 11.5196 7.00195 11.2652 7.00195 11ZM7.09995 4.995C7.08664 4.86884 7.09999 4.74129 7.13915 4.62062C7.17831 4.49996 7.24241 4.38888 7.32727 4.29458C7.41214 4.20029 7.51588 4.12489 7.63176 4.07328C7.74765 4.02167 7.87309 3.995 7.99995 3.995C8.12681 3.995 8.25226 4.02167 8.36814 4.07328C8.48403 4.12489 8.58777 4.20029 8.67263 4.29458C8.7575 4.38888 8.82159 4.49996 8.86075 4.62062C8.89991 4.74129 8.91327 4.86884 8.89995 4.995L8.54995 8.502C8.53819 8.63977 8.47516 8.76811 8.37331 8.86163C8.27146 8.95515 8.13823 9.00705 7.99995 9.00705C7.86168 9.00705 7.72844 8.95515 7.6266 8.86163C7.52475 8.76811 7.46171 8.63977 7.44995 8.502L7.09995 4.995Z" fill="#92929D"></path></svg>
                                </div>

                            </div> */}
                            <InputText
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                index={0}
                                error={''}
                                touched={''}
                                type="email"
                                placeholder="e.g. ravi@gmail.com"
                                name="email"
                                label="Email"
                            />
                            <div className="form-group position-relative">
                                <label htmlFor="">Password</label>
                                <input
                                    type={!showPassword ? 'password' : 'text'}
                                    className='form-control'
                                    placeholder='*******'
                                    value={password}
                                    // onBlur={handleBlur}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                {!showPassword && <div className='valid-icon' onClick={() => setShowPassword(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.40682 7.62495C2.63632 4.60541 5.17298 2.66663 8.00016 2.66663C10.8273 2.66663 13.364 4.60541 14.5935 7.62495C14.6913 7.86506 14.6913 8.13486 14.5935 8.37496C13.364 11.3945 10.8273 13.3333 8.00016 13.3333C5.17298 13.3333 2.63632 11.3945 1.40682 8.37496C1.30905 8.13486 1.30905 7.86506 1.40682 7.62495ZM8.00016 12.0997C10.2795 12.0997 12.3693 10.5245 13.4334 7.99996C12.3693 5.47546 10.2795 3.90019 8.00016 3.90019C5.7208 3.90019 3.631 5.47546 2.56689 7.99996C3.631 10.5245 5.7208 12.0997 8.00016 12.0997ZM8.00016 10.4308C6.68098 10.4308 5.61157 9.34247 5.61157 7.99996C5.61157 6.65744 6.68098 5.56912 8.00016 5.56912C9.31935 5.56912 10.3888 6.65744 10.3888 7.99996C10.3888 9.34247 9.31935 10.4308 8.00016 10.4308ZM8.00016 9.19724C8.64991 9.19724 9.17663 8.6612 9.17663 7.99996C9.17663 7.33872 8.64991 6.80268 8.00016 6.80268C7.35042 6.80268 6.82369 7.33872 6.82369 7.99996C6.82369 8.6612 7.35042 9.19724 8.00016 9.19724Z" fill="#92929D" />
                                </svg>
                                </div>}
                                {showPassword && <div title='View Password' className='valid-icon' onClick={() => setShowPassword(false)} >
                                    <i className="far fa-eye-slash fa-1x"></i>
                                </div>}
                            </div>

                            <button className='login-btn mt-24 mb-24' onClick={handleLogin}>Login</button>
                            <p className='foget-password-para mb-0'>Have an account? <Link to='/forgotpassword'>Forgot password</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LoginDemo