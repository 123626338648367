import React, { useMemo, useCallback, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import CenterModal from '../Modals/center-modal';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';

const Card = ({ workshop }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [startTime, setStartTime] = useState();
    let timeDiff = moment(workshop.start_time).diff(moment(), 'hours');
    let dateDiff = moment(workshop.start_date).diff(moment(), 'days');

    // console.log(workshop, 'workshop');
    let date = String(new Date(workshop.start_date)).split(" ")
    let now = new Date().getTime()
    let todayHours = (Math.abs(new Date(workshop.end_time).getTime() - new Date().getTime()) / 36e5).toFixed(2)
    let momentStartDate = moment(workshop.start_date);
    let momentStartTime = moment(workshop.start_time).format('hh:mm a');

    let momentEndDate = moment(workshop.end_date);
    let momentEndTime = moment(workshop.end_time).format('hh:mm a');

    let momentFinalStarttime = moment(`${momentStartDate.format('YYYY-MM-DD')} ${momentStartTime}`);
    let momentFinalEndtime = moment(`${momentEndDate.format('YYYY-MM-DD')} ${momentEndTime}`);

    let hoursDiff = momentFinalEndtime.diff(momentFinalStarttime, 'minutes');
    // console.log(hours, 'hours diff');
    // console.log(hours, 'hours 1', workshop.name);
    const hours = Math.floor(hoursDiff / 60);
    const minutes = hoursDiff % 60;
    // let hours = (Math.abs(new Date(workshop.end_time).getTime() - new Date(workshop.start_time).getTime()) / 36e5).toFixed(2)
    const buttonName = useMemo(() => {
        const today = moment();
        const workshopDate = moment(workshop.start_date);
        const difference = workshopDate.diff(today, 'day');

        // workshop just finished 
        if (parseInt(momentFinalEndtime.diff(moment(), 'minutes')) <= 0 && workshop.status === 'ACTIVE') {
            return {
                isDisable: true,
                name: 'Completed'
            }
        }

        // Past date
        if (difference <= 0) {

            if (workshop.status === 'COMPLETED') {
                return {
                    isDisable: (workshop.status === 'COMPLETED' && workshop.recording_url) ? false : true,
                    name: (workshop.status === 'COMPLETED' && workshop.recording_url) ? 'View Recording' : 'Completed'
                }
            }
        }

        // Future date
        if (difference > 0) {
            return {
                isDisable: true,
                name: workshop.status !== 'ACTIVE' ? workshop.status : 'Upcoming'
            }
        }

        // Today
        return {
            isDisable: workshop.status !== 'ACTIVE' ? true : false,
            name: workshop.status !== 'ACTIVE' ? workshop.status : 'Join Now'
        }

    }, [workshop, momentFinalEndtime, startTime])

    const btnColor = useMemo(() => {
        if (buttonName.name === 'REG. CLOSED') return '#FF974A';
        if (buttonName.name === 'CANCELLED') return '#FC5A5A';

    }, [buttonName])

    const closeVideoModal = useCallback(() => {
        setShowVideo(false)
    }, []);

    const handleJoinNow = () => {
        if (momentFinalEndtime < moment()) {
            toast.warning('Workshop has been ended.')
        } else {
            window.open(workshop.zoom_link, '_blank')
        }
    }

    return (
        <div className='learning-block'>

            <div className="card d-flex flex-fill">
                <figure className='position-relative mb-0'>
                    <div className={`${(['Completed', 'View Recording'].includes(buttonName.name) ||
                        (momentFinalStarttime <= moment() && momentFinalEndtime >= moment())) ? 'joinnow' : ''}`}>

                        {/*   <span className='compbtn'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.79451 11.519L11.3086 13.0136L14.1453 9.57572C14.3796 9.29173 14.7998 9.25147 15.0838 9.48581C15.3678 9.72014 15.408 10.1403 15.1737 10.4243L11.8731 14.4243C11.6239 14.7264 11.1693 14.7496 10.8906 14.4745L8.85782 12.4679C8.59579 12.2092 8.59306 11.7871 8.85172 11.5251C9.11037 11.263 9.53247 11.2603 9.79451 11.519Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.33341 4.66669H14.6667C17.2441 4.66669 19.3334 6.75602 19.3334 9.33335V14.6667C19.3334 17.244 17.2441 19.3334 14.6667 19.3334H9.33341C6.75609 19.3334 4.66675 17.244 4.66675 14.6667V9.33335C4.66675 6.75602 6.75609 4.66669 9.33341 4.66669ZM9.33341 6.00002C7.49247 6.00002 6.00008 7.4924 6.00008 9.33335V14.6667C6.00008 16.5076 7.49247 18 9.33341 18H14.6667C16.5077 18 18.0001 16.5076 18.0001 14.6667V9.33335C18.0001 7.4924 16.5077 6.00002 14.6667 6.00002H9.33341Z" fill="white" />
                            <rect x="6" y="6" width="12" height="12" rx="3" fill="white" />
                            <path d="M10.135 11.1856L11.6491 12.6802L14.4858 9.24239C14.7201 8.95839 15.1403 8.91814 15.4243 9.15247C15.7083 9.38681 15.7485 9.80699 15.5142 10.091L12.2136 14.091C11.9644 14.393 11.5098 14.4162 11.2311 14.1411L9.19832 12.1345C8.93629 11.8759 8.93356 11.4538 9.19222 11.1917C9.45088 10.9297 9.87298 10.927 10.135 11.1856Z" fill="#92929D" />
                        </svg>Completed</span>
                         */}

                        {workshop?.zoom_link &&
                            <>
                                {(buttonName.name === 'Join Now' && (momentFinalStarttime <= moment() && momentFinalEndtime >= moment())) && <a onClick={handleJoinNow}> <span className='joinbtn'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.002 4.00195C10.4201 4.00195 8.87375 4.47103 7.55848 5.34987C6.24321 6.2287 5.21808 7.47783 4.61272 8.93928C4.00737 10.4007 3.84898 12.0089 4.15759 13.5603C4.4662 15.1118 5.22794 16.5369 6.34648 17.6555C7.46503 18.774 8.89014 19.5358 10.4416 19.8444C11.9931 20.153 13.6012 19.9946 15.0627 19.3892C16.5241 18.7839 17.7732 17.7587 18.6521 16.4435C19.5309 15.1282 20 13.5819 20 12C19.9974 9.8796 19.1539 7.8468 17.6545 6.34745C16.1552 4.8481 14.1224 4.0046 12.002 4.00195ZM14.8043 12.5122L11.1129 14.9731C11.0117 15.0402 10.8929 15.0761 10.7715 15.0762C10.6083 15.0762 10.4518 15.0113 10.3364 14.896C10.2211 14.7806 10.1563 14.6241 10.1563 14.4609V9.53906C10.1562 9.42765 10.1864 9.31831 10.2436 9.22271C10.3008 9.12712 10.3829 9.04885 10.4811 8.99627C10.5793 8.94368 10.69 8.91876 10.8013 8.92415C10.9125 8.92954 11.0203 8.96505 11.1129 9.02688L14.8043 11.4878C14.8887 11.544 14.9579 11.6201 15.0058 11.7095C15.0537 11.7988 15.0787 11.8986 15.0787 12C15.0787 12.1014 15.0537 12.2012 15.0058 12.2905C14.9579 12.3799 14.8887 12.456 14.8043 12.5122Z" fill="white" />
                                </svg>{buttonName.name}</span>
                                </a>
                                }
                                {buttonName.name === 'Completed' && <button
                                    onClick={() => { window.open(`${workshop && workshop.zoom_link}`) }}
                                    className='compbtn'
                                    style={{ background: btnColor }}
                                    disabled={buttonName.isDisable}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.79451 11.519L11.3086 13.0136L14.1453 9.57572C14.3796 9.29173 14.7998 9.25147 15.0838 9.48581C15.3678 9.72014 15.408 10.1403 15.1737 10.4243L11.8731 14.4243C11.6239 14.7264 11.1693 14.7496 10.8906 14.4745L8.85782 12.4679C8.59579 12.2092 8.59306 11.7871 8.85172 11.5251C9.11037 11.263 9.53247 11.2603 9.79451 11.519Z" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.33341 4.66669H14.6667C17.2441 4.66669 19.3334 6.75602 19.3334 9.33335V14.6667C19.3334 17.244 17.2441 19.3334 14.6667 19.3334H9.33341C6.75609 19.3334 4.66675 17.244 4.66675 14.6667V9.33335C4.66675 6.75602 6.75609 4.66669 9.33341 4.66669ZM9.33341 6.00002C7.49247 6.00002 6.00008 7.4924 6.00008 9.33335V14.6667C6.00008 16.5076 7.49247 18 9.33341 18H14.6667C16.5077 18 18.0001 16.5076 18.0001 14.6667V9.33335C18.0001 7.4924 16.5077 6.00002 14.6667 6.00002H9.33341Z" fill="white" />
                                        <rect x="6" y="6" width="12" height="12" rx="3" fill="white" />
                                        <path d="M10.135 11.1856L11.6491 12.6802L14.4858 9.24239C14.7201 8.95839 15.1403 8.91814 15.4243 9.15247C15.7083 9.38681 15.7485 9.80699 15.5142 10.091L12.2136 14.091C11.9644 14.393 11.5098 14.4162 11.2311 14.1411L9.19832 12.1345C8.93629 11.8759 8.93356 11.4538 9.19222 11.1917C9.45088 10.9297 9.87298 10.927 10.135 11.1856Z" fill="#92929D" />
                                    </svg>  {buttonName.name}
                                </button>}

                                {buttonName.name === 'View Recording' && <button
                                    onClick={() => setShowVideo(true)}
                                    className='joinbtn'
                                    style={{ background: btnColor }}
                                    disabled={buttonName.isDisable}
                                >
                                    {buttonName.name}
                                </button>}
                            </>
                        }
                    </div>
                    {workshop.status === 'ACTIVE' &&
                        <>
                            {(dateDiff === 0 && timeDiff < 24 && momentFinalEndtime > moment()) && <div className='livein'>
                                <Countdown
                                    date={`${workshop.start_time}`}
                                    renderer={
                                        ({ days, hours, minutes, seconds, completed }) => {
                                            if (completed && minutes === 0 && seconds === 0) {
                                                setStartTime(workshop.start_time);
                                                return <> <div className="an_circles">
                                                    <div className="circle1"></div>
                                                    <div className="circle2"></div>
                                                    <div className="circle3"></div>
                                                </div>
                                                    Live </>
                                            }
                                            if (!(days > 0)) {
                                                if (hours > 0) {
                                                    return <div className='liveintimeonly py-0'> Live in {hours < 10 ? `0${hours}` : hours} : {minutes < 10 ? `0${minutes}` : minutes} hrs</div>
                                                } else if (minutes + 1 > 0) {
                                                    return <div className='liveintimeonly py-0'> Live in {minutes + 1 < 10 ? `0${minutes + 1}` : `${minutes}`} mins</div>
                                                }
                                            } else {
                                                return null;
                                            }
                                        }
                                    }
                                />

                            </div>}
                            {(dateDiff >= 1 || timeDiff >= 24) && <div className='livein'>Coming Soon</div>}
                        </>
                    }
                    <img src={workshop.card_thumbnail} />
                    <div className="video-lenth flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M8.85385 2.0564C8.76958 2.0564 8.68876 2.08987 8.62917 2.14946C8.56958 2.20905 8.53611 2.28987 8.53611 2.37414V3.00963C8.53611 3.0939 8.56958 3.17472 8.62917 3.23431C8.68876 3.2939 8.76958 3.32738 8.85385 3.32738H9.33047V4.16861C5.8931 4.5642 3.21387 7.49128 3.21387 11.0327C3.21387 14.843 6.31459 17.9437 10.1248 17.9437C13.9351 17.9437 17.0358 14.843 17.0358 11.0327C17.0369 9.72857 16.6666 8.45109 15.9682 7.34972L16.6415 6.80463L16.9414 7.17512C16.9677 7.20756 17.0001 7.23452 17.0367 7.25445C17.0734 7.27438 17.1136 7.2869 17.1551 7.29128C17.1966 7.29566 17.2386 7.29182 17.2786 7.27999C17.3186 7.26816 17.3559 7.24855 17.3883 7.2223L17.8824 6.82226C17.9478 6.76919 17.9895 6.69233 17.9983 6.60856C18.007 6.52478 17.9822 6.44096 17.9291 6.37551L16.3296 4.39977C16.3034 4.36732 16.271 4.34037 16.2343 4.32044C16.1977 4.30051 16.1574 4.28799 16.1159 4.28361C16.0744 4.27923 16.0325 4.28306 15.9925 4.2949C15.9524 4.30673 15.9151 4.32634 15.8827 4.35259L15.3886 4.75263C15.3232 4.80569 15.2815 4.88256 15.2728 4.96633C15.264 5.05011 15.2889 5.13393 15.3419 5.19938L15.642 5.56987L14.9716 6.11226C13.9012 5.05766 12.4893 4.34973 10.9192 4.16893V3.32738H11.3958C11.4801 3.32738 11.5609 3.2939 11.6205 3.23431C11.6801 3.17472 11.7136 3.0939 11.7136 3.00963V2.37414C11.7136 2.28987 11.6801 2.20905 11.6205 2.14946C11.5609 2.08987 11.4801 2.0564 11.3958 2.0564H8.85385ZM10.1248 5.23385C13.3341 5.23385 15.9237 7.82348 15.9237 11.0327C15.9237 14.2419 13.3341 16.8316 10.1248 16.8316C6.9156 16.8316 4.32598 14.2419 4.32598 11.0327C4.32598 7.82348 6.9156 5.23385 10.1248 5.23385ZM10.1252 6.41634L10.1248 11.0327L13.8161 13.8046C14.331 13.1189 14.6447 12.3033 14.7221 11.4493C14.7995 10.5953 14.6375 9.73667 14.2543 8.96959C13.871 8.20253 13.2817 7.55736 12.5524 7.10642C11.8231 6.65548 10.9826 6.41658 10.1252 6.4165V6.41634Z" fill="white" />
                        </svg>
                        {/* {hours.split('.')[1] === '00' ? hours.split('.')[0] : hours}{parseInt(hours.split('.')[0]) >= 2 ? ' hrs' : ' hr'} */}
                        {hours >= 1 ? `${hours}:${minutes < 10 ? '0' + minutes : minutes}hrs` : `${minutes}min`}
                    </div>
                </figure>
                {workshop.name.length >
                    70 ?
                    <OverlayTrigger className=''
                        placement="auto"
                        overlay={
                            <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4> */}
                                    <p className='d-flex fz18 w-auto inter'>{workshop.name}</p>
                                </div>
                            </Tooltip>
                        }
                    >
                        <h1 className='fz16 black fw-po-medium mt-8 mb-0 inter'>{workshop.name}</h1>
                    </OverlayTrigger>
                    :
                    <h1 className='fz16 black fw-po-medium mt-8 mb-0 inter'>{workshop.name}</h1>}

                {
                    workshop.mentors && workshop.mentors?.join(', ').length > 30 ?
                        <OverlayTrigger className=''
                            placement="auto"
                            overlay={
                                <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                    <div className='info-msg'>
                                        <p className='d-flex fz18 w-auto inter'>By {workshop.mentors.join(', ')}</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <p className='fz14 black-dark-700 worksans mb-0 mt-8 mentor inter'>By {workshop.mentors.join(', ')}</p>
                        </OverlayTrigger>
                        :
                        workshop.mentors && <p className='fz14 black-dark-700 worksans mb-0 mt-8 inter'>By {workshop.mentors?.join(', ')}</p>
                }


                <div className="video-time-date mt-8 d-flex align-items-start inter fw-po-medium fz14 black"><svg className='mr-8' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D" />
                </svg>
                    {/* {new Date(workshop.start_date).getTime() > now ? `Starts on • ${date[1]} ${date[2]}, ${date[3]}` : todayHours > 0 ? `Starts Today • ${date[1]} ${date[2]}, ${date[3]}` : "Expired"}  */}
                    {/* {new Date(workshop.start_date).getTime() > now ? `Starts at ${moment(workshop.start_time).format("h:mm a")} • ${moment(workshop.start_date).format(" Do MMM YYYY")}` : todayHours > 0 && moment(workshop.start_time) >= moment() ? `Starts at ${moment(workshop.start_time).format("h:mm a")} • ${moment(workshop.start_date).format("Do MMMM YYYY")}` : "Completed"} */}

                    {(dateDiff > 1 || timeDiff >= 1) ? <div>Starts at {moment(workshop.start_time).format("h:mm a")} <span></span> {moment(workshop.start_date).format(" Do MMM YYYY")}</div> :
                        <div>Started at {moment(workshop.start_time).format("h:mm a")} <span></span> {moment(workshop.start_date).format(" Do MMM YYYY")}</div>}


                </div>
                {/* <Link to={`workshops-details/${workshop.uuid}`} className='pr-btn mt-20 text-center'>View Workshop</Link> */}
                {workshop?.zoom_link &&
                    <>
                        {/* {buttonName.name !== 'View Recording' && <button
                            onClick={() => { window.open(`${workshop && workshop.zoom_link}`) }}
                            className='pr-btn py-12 dead-reason'
                            style={{ background: btnColor }}
                            disabled={buttonName.isDisable}
                        >
                            {buttonName.name}
                        </button>}

                        {buttonName.name === 'View Recording' && <button
                            onClick={() => setShowVideo(true)}
                            className='pr-btn py-12'
                            style={{ background: btnColor }}
                            disabled={buttonName.isDisable}
                        >
                            {buttonName.name}
                        </button>} */}

                        {buttonName.name !== 'Completed' &&
                            buttonName.name !== 'Join Now' &&
                            <div className="dead-reason-notes card-btn-notes dead-reason-hide position-absolute">
                                <div className="left-icon">
                                    <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </div>
                                <div className="right-text">
                                    {buttonName.name === 'Upcoming' && <p className='black-dark-700 fz14 mb4'>The workshop will be live on the scheduled date.</p>}
                                    {buttonName.name === 'CANCELLED' && <p className='black-dark-700 fz14 mb4'>The workshop has been cancelled due to unexpected reasons.</p>}
                                    {buttonName.name === 'REG. CLOSED' && <p className='black-dark-700 fz14 mb4'>Registration for this workshop has been closed.</p>}
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            {
                showVideo && <Modal size="xl" show={showVideo} onHide={closeVideoModal} backdrop="static" fullscreen centered dialogClassName="modal-radius">
                    <a onClick={closeVideoModal} className='cursor-pointer close position-absolute video-close'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#ffff"></path></svg></a>
                    <video controls autoPlay controlsList="nodownload" preload='preloadauto' className='learnvideo' width={'100%'} height={'auto'}>
                        <source src={workshop.recording_url} type="video/mp4" />
                    </video>
                </Modal>
            }
        </div >
    )
}

export default Card;