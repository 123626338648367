import { useState } from 'react'
import Select from 'react-select';


const SelectField = ({ value, onChange, name, label, error, isMulti, placeholder, className, options, inputRequired }) => {


    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: "none",
            borderColor: '#ced4da',
            // backgroundColor: '#FAFAFB',
            minHeight: '44px',
            borderRadius: '8px',
            fontSize: '14px',
            fontWeight: '400',
            cursor: 'pointer',
            '&:focus': { borderColor: '#0062ff' },
            // '&:hover': { borderColor: '#ced4da' },



            // You can also use state.isFocused to conditionally style based on the focus state
        }),
        option: (styles, state) => ({
            ...styles,
            fontSize: '14px',
            cursor: 'pointer',
            backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
            color: '#44444f',
            "&:hover": {
                color: "#44444f",
                backgroundColor: "#f1f1f5"
            }
        }),
    };

    return (
        <div className='w-full relative'>
            <label className=" text-14 text-black700 mb-2">{label}{inputRequired && <sup className='text-red text-14'>*</sup>}</label>
            <Select
                isMulti={isMulti}
                name={name}
                value={value}
                styles={customStyles}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
            // styles={{
            //     control: (baseStyles) => ({
            //         ...baseStyles,
            //         border: error ? "2px solid #ef4444" : '',
            //     }),
            // }}
            />
        </div>
    )
}

SelectField.defaultProps = {
    placeholder: '',
    error: false,
    errorMessage: '',
    fieldRef: undefined,
    className: '',
    options: [],
    value: {}
};

export default SelectField
