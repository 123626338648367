import React, { useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Consumer from '../helpers/context';

export default function RenderComponent({
    routes,
    baseUrl,

}) {

    let { isRolesModule } = useContext(Consumer);

    return (
        <Switch>
            {routes.map((section) => {
                return section.childRoutes.map((component) => {
                    return (
                        <Route
                            path={`${baseUrl}/${section.url}/${component.url}`}
                            exact
                        >
                            {!isRolesModule && component.name === 'Roles' ? <component.oldComponent /> : <component.component />}
                        </Route>
                    );
                });
            })}
            {/* <Route
                path={`${baseUrl}/*`}>
                <Redirect
                    to={`${baseUrl}/${routes[0].url + "/" + routes[0].childRoutes[0].component}`}
                />
            </Route> */}
        </Switch>
    )
}