import React, { useEffect } from 'react';
import Secure from '../Secure';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TestLeadResponse = ({ formList, handleTestLead, closeFbModal, isSendingLead, sendTestLeadIndex, testLeadFailed, getPageLinkForm }) => {

    useEffect(() => {
        getPageLinkForm()
    }, [])

    return (
        <>

            {/* {testLeadFailed === 'Success' && <h2 className='text-center fz28 ls5 black po fw-po-medim mb4'>Test lead Inserted Successfully</h2>} */}
            {/* <p className='text-center black-dark-700 ls1 fz14 ro'>The following lead is inserted in your leads manager.</p> */}
            <div className="blankdiv"></div>
            <div className='pl-24 h-full overflow-hidden'>

                <div className='sucess-table scroll-div !pb-5'>
                    <div className="lead-maping test_integration">
                        <div className='maphead'>

                            <div className="s_no">S.No</div>
                            <div className="form-name">Form Name</div>
                            <div className="form-id">Form ID</div>
                            <div className="form_status">Status</div>
                            <div className="form_sendTest">Send Test</div>

                        </div>

                        <div className='map-fileds'>
                            {
                                formList.map((form, index) => {
                                    return (
                                        <div className='test_integration_row' key={`${form.value}_${index}`}>
                                            <div className='s_no d-lg-inline-flex d-none'> {index + 1}</div>
                                            <div className='form-name'><span className='d-lg-none'>Form {index + 1} : </span>
                                                {form?.name?.length > 24 || form?.form_name?.length > 24 ?
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>

                                                                    <p className=''> {form.name || form.form_name} </p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='virticledots cursor-pointer'> {form.name || form.form_name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className=''> {form.name || form.form_name}</span>
                                                }
                                            </div>
                                            <div className='form-id'><span className='d-lg-none'>Form ID : </span>{form.value || form.form_id}</div>
                                            <div className={`${!form.test_status ? 'form_status failed' : 'form_status'}`}>
                                                {!form.test_status && '-'}
                                                {(form.test_status || testLeadFailed === 'Failed') && <button className={`${form.test_status ? 'hot-btn' : 'dead-btn'} fz14 fw-po-medim px-32`}>{form.test_status ? form.test_status : testLeadFailed}</button>}
                                            </div>
                                            <div className='form_sendTest'>
                                                <button
                                                    className='fz14 fw-po-medim px-32 test-btn bg-tr'
                                                    onClick={() => handleTestLead(form, index)}
                                                    disabled={isSendingLead && sendTestLeadIndex === index}
                                                >
                                                    {isSendingLead && sendTestLeadIndex === index ? <Oval
                                                        height={17}
                                                        width={30}
                                                        color="#0062FF"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#0062FF"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    /> : 'Send Test lead'}
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='footer-modal d-flex align-items-center justify-content-end'>
                <Secure />
                {/* <button className="outline-btn fz16 py-8 px-40 d-table ml-auto">Previous</button> */}
                <Link to='/settings/integrations/all-integrations/facebook-forms' className="pr-btn fz16 py-8 px-40 d-table mr-20">Done</Link>
            </div>




        </>
    )
}

export default TestLeadResponse