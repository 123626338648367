import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { addUserTeam, getExistingUsers } from '../../../../../services/private/company.service';
import { toast } from 'react-toastify';
import { getInitials } from '../../../../../helpers/helpers';
import { ReactComponent as ResultSearchIcon } from '../../../../../assets/icons/searchicon.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../../assets/icons/searchClose.svg';
import TooltipText from '../../../../../components/Tooltip';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/userIcon.svg';

const TeamUsers = ({ show, closeModal, user, getUsersByUUID }) => {

    const [users, setUsers] = useState([]);
    const [allusers, setAllusers] = useState([]);
    const [selectedUser, setSelecteduser] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (user.team.length > 0)
            setSelecteduser([...selectedUser, ...user.team])
    }, [user])

    const getUsers = async () => {
        const res = await getExistingUsers();
        if (res.data.status === 200) {
            let combineSelection = [...user.team, ...user.reporting_to, { ...user }];
            let userData = res.data.data
                .filter(uItem => uItem.role_name !== 'Super Admin')
                .map(item => ({ ...item, label: `${item.name} (${item.role_name})`, value: item.uuid }))

            if (combineSelection.length > 0) {
                userData = userData.filter(item2 => {
                    return !combineSelection.some(item1 => item1.uuid === item2.uuid);
                });
            }
            setUsers(userData)
            setAllusers(userData)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    const handleAddNew = (user) => {
        const filteredData = users.filter(item => item.uuid !== user.uuid)
        const filterdAllUsers = allusers.filter(item => item.uuid !== user.uuid)
        setUsers(filteredData);
        setAllusers(filterdAllUsers);
        setSelecteduser([...selectedUser, user]);
    }

    const handleRemove = (user) => {
        const filteredData = selectedUser.filter(item => item.uuid !== user.uuid)
        setSelecteduser(filteredData);
        setUsers([...users, user]);
        setAllusers([...allusers, user]);
    }

    const handleSearch = (e) => {
        const searchedData = allusers.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        setUsers(searchedData)
    }
    useEffect(() => {
        handleSearch()
    }, [searchText])

    const handleSubmit = async () => {
        const userIds = selectedUser.map(item => item.uuid);
        const res = await addUserTeam(user.uuid, { users: userIds })
        if (res.data.status === 200) {
            toast.success(res.data.message)
            getUsersByUUID();
            closeModal();
        } else {
            toast.error(res.data.message)
        }
    }

    return (
        <RightModal show={show} closeModal=
            {() => {

                closeModal();
            }}
            modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Edit Team Users</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='h-full overflow-hidden flex flex-col !mt-5'>
                <div className='h-1/2 space-y-4 overflow-hidden'>
                    {selectedUser.length > 0 && <> <span className='text-black text-md font-semibold !px-5 '>
                        Added Users {selectedUser.length > 0 ? `(${selectedUser.length})` : ''}
                    </span>
                        <div className='overflow-auto h-full space-y-4 !px-5'>
                            {
                                selectedUser.map((user, index) => (

                                    <div className='flex justify-between items-center border rounded-md p-2.5' key={`${user.uuid}_${index}`}>
                                        <div className='flex items-center gap-x-2'>
                                            <TooltipText title={user.role_name || ''}>
                                                <span className="w-8 h-8 !border !border-grayLight font-semibold rounded-md text-sm bg-[#F2F2F2] text-grey-300 inline-flex items-center justify-center" title={user.role_name}>
                                                    {getInitials(user.role_name || '')}
                                                </span>
                                            </TooltipText>
                                            <div>
                                                <h6 className='m-0 truncate w-[200px]'>{user.name}</h6>
                                                <TooltipText title={user.email || ''}>
                                                    <p className='m-0 text-grey200 truncate w-[200px]'>{user.email}</p>
                                                </TooltipText>
                                            </div>
                                        </div>
                                        <div>
                                            <button className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1' onClick={() => handleRemove(user)}>Remove</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div></>}
                    {
                        selectedUser.length === 0 && <div className='h-[80%]'>
                            <div className='flex items-center justify-center h-100 flex-col text-black700'>
                                <UserIcon />
                                No Users Added
                            </div>
                        </div>
                    }

                </div>
                <div className='flex items-center justify-between !my-5 !px-5'>

                    <span className='text-black text-md font-semibold'>
                        Add users
                    </span>

                    <div className={`' border rounded px-2.5 py-1 w-[70%] flex items-center ${searchText ? 'border-primary' : ''}`}>
                        <SearchIcon className=' ' />
                        <input
                            type="text"
                            placeholder='Search For User'
                            className=' w-100 focus:outline-none text-base pl-2'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        {searchText &&
                            <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                <SearchCloseIcon />
                            </div>}
                    </div>
                </div>
                <div className='h-1/2 space-y-4'>


                    <div className='overflow-y-auto overflow-x-hidden h-full space-y-4 !px-5 !pb-6'>
                        {
                            users.map((user, index) => (
                                <div className='flex justify-between items-center border rounded-md p-2.5' key={`${user.uuid}_${index}`}>
                                    <div className='flex items-center gap-x-2'>
                                        <TooltipText title={user.role_name || ''}>
                                            <span className="w-8 h-8 !border !border-grayLight font-semibold rounded-md text-sm bg-[#F2F2F2] text-grey-300 inline-flex items-center justify-center" title={user.role_name}>
                                                {getInitials(user.role_name || '')}
                                            </span>
                                        </TooltipText>
                                        <div>
                                            <h6 className='m-0 truncate w-[210px]'>{user.name}</h6>
                                            <TooltipText title={user.email}>
                                                <p className='m-0 text-grey200 truncate w-[210px]'>{user.email}</p>
                                            </TooltipText>
                                        </div>
                                    </div>
                                    <div>
                                        <button className='border border-primary text-[#0062FF] hover:bg-primary hover:text-white text-xs rounded px-2.5 p-1' onClick={() => handleAddNew(user)} >Add</button>
                                    </div>
                                </div>
                            ))
                        }

                        {
                            users.length === 0 && searchText && <div className='flex justify-center flex-column items-center h-100'>
                                <figure><ResultSearchIcon className='h-10 w-10' /></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Sorry, we couldn’t find any results </h2>
                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>
                                    Please try again with different keywords.
                                    {/* We're sorry, but we couldn't find any results for your search.
                                    <span className='d-md-block d-none'>Please try again with different keywords.</span> */}
                                </p>
                            </div>
                        }
                        {
                            users.length === 0 && !searchText && <div className='flex items-center justify-center flex-column h-100'>
                                <p className='text-center text-black700 black-dark-600 fz14 mb-4 text-capitalize'>
                                    No Users Available
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={` flex flex-row items-center justify-between bg-white border-top border-color relative w-100 space-x-3 p-3`}>
                <button className='w-1/2 outline-btn !py-1.5 !text-lg !font-medim hover:bg-black100 !rounded-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-1/2 !py-1.5 !text-lg !font-medim !rounded-lg' onClick={handleSubmit}>Save</button>
            </div>
        </RightModal>
    )
}

export default TeamUsers
