import {
   LEAD_PAGE_INIT,
   LEAD_HIDE_SIDE_BAR,
   LEAD_SHOW_SIDE_BAR,
   LEAD_CREAT_NEW_REQUEST,
   LEAD_PAGE_INIT_SUCCESS,
   LEAD_PAGE_INIT_ERROR,
   LEAD_CREAT_ERROR,
   LEAD_CREAT_SUCCESS,
   LEAD_LOAD_MORE_SUCCESS,
   LEAD_LOAD_MORE_STOP,
   LEAD_LOAD_MORE,
   LEAD_FILTERS_HIDE,
   LEAD_FILTERS_SHOW,
   LEAD_FILTER_ON_SEARCH_SUCCESS,
   LEAD_FILTER_ON_SEARCH_CLICK,
   LEAD_FILTER_ON_SUBMIT,
   LEAD_FILTER_ON_REMOVE,

   

} from './actions';


export const initialState = {
        rows : [],
        toggleSideBar : false,
        totalLeadCount : 0,
        columns : [],
        loadMoreLeads   : true,
        alerttype : null,
        alertmessage : '',
        showFilterSideBar : false, 


} 



export default function (state = initialState, actions) {
    switch (actions.type) {
        case LEAD_PAGE_INIT:
            return { ...state, requesting: false };
        case LEAD_SHOW_SIDE_BAR:
            return { ...state, requesting: false, toggleSideBar: true };
        case LEAD_PAGE_INIT_SUCCESS:

            return { ...state, columns: actions.columns, rows: actions.rows, totalLeadCount: actions.totalcount, requesting: false, toggleSideBar: false };
        case LEAD_PAGE_INIT_ERROR:
            return { ...state, error: actions.error, requesting: false }
        case LEAD_HIDE_SIDE_BAR:
            return { ...state, toggleSideBar: false, loadMoreLeads: true }
        case LEAD_CREAT_NEW_REQUEST:
            return { ...state, requesting: true };
        case LEAD_CREAT_SUCCESS:
            return { ...state, requesting: false, columns: actions.columns, rows: actions.rows, totalLeadCount: actions.totalcount, loadMoreLeads: true, alerttype: 'Success', alertmessage: 'Lead inserted !!' };
        case LEAD_CREAT_ERROR:
            return { ...state, requesting: false, alerttype: 'Error', alertmessage: 'Error Creating Lead !!' };
        case LEAD_LOAD_MORE_STOP:
            return { ...state, requesting: false, loadMoreLeads: false };
        case LEAD_LOAD_MORE:
            return { ...state, loadMoreLeads: true, requesting: false }
        case LEAD_LOAD_MORE_SUCCESS:
            return { ...state , requesting : false ,totalLeadCount : actions.action.leads.TotalCount[0].count ,   columns : actions.action.leads['Columns'] , leads : state.leads.concat(actions.action.leads.Result)} 
        case LEAD_FILTERS_HIDE:
            return { ...state ,  showFilterSideBar : false }
        case LEAD_FILTERS_SHOW :
            return { ...state ,  showFilterSideBar : true }
        case LEAD_FILTER_ON_SEARCH_SUCCESS:
            return { ...state ,  filterSearchResults : actions.result}
        case LEAD_FILTER_ON_SEARCH_CLICK:
            return { ...state ,  filterSearchResults : {} } 
         
        case LEAD_FILTER_ON_SUBMIT:
            return { ...state , leadDashboardUiFilters :  actions.uiFilters && actions.uiFilters.length ? actions.uiFilters : []  , leadDashBoardFilter : actions.filters }
        
        case LEAD_FILTER_ON_REMOVE:
            return { ...state , leadDashboardUiFilters : actions.uiFilters && actions.uiFilters.length ? actions.uiFilters : []  , leadDashBoardFilter : actions.filters }    

        default:        
            return { ...state }

    }
}


