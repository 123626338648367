import React from 'react'

const UploapError = ({ fileTypeError, sizeError, closeErrorPage, columnNotMatchError }) => {
    return (
        <>

            {(fileTypeError || sizeError || columnNotMatchError) && <div className="error-show" >
                <h1 className='fz18 black fw-po-medium mb-16'>
                    <svg width="20" height="20" className='mr-2' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 8V12" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 16H12.01" stroke="#E80053" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Errors Identified
                </h1>
                <div className="line"></div>
                <div className="resutl-heading p-0 d-flex align-items-center">
                    <div className="one p-0">Number</div>
                    <div className="two">ERROR</div>

                </div>
                <div className='show-errors'>
                    {fileTypeError && <p className='d-flex align-items-center m_row' >
                        <span className='blank'>1</span>
                        <span>{fileTypeError}</span>
                    </p>}
                    {sizeError && <p className='d-flex align-items-center m_row' >
                        <span className='blank'>1</span>
                        <span>{sizeError}</span>
                    </p>}
                    {columnNotMatchError && <p className='d-flex align-items-center m_row' >
                        <span className='blank'>1</span>
                        <span>{columnNotMatchError}</span>
                    </p>}
                </div>
            </div>}

            {/* {!fileTypeError && !sizeError && <div className="not-upload">
                <figure className='d-table mx-auto'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                        <path d="M32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64V64ZM27.2 46.4C27.2 45.127 27.7057 43.9061 28.6059 43.0059C29.5061 42.1057 30.727 41.6 32 41.6C33.273 41.6 34.4939 42.1057 35.3941 43.0059C36.2943 43.9061 36.8 45.127 36.8 46.4C36.8 47.673 36.2943 48.8939 35.3941 49.7941C34.4939 50.6943 33.273 51.2 32 51.2C30.727 51.2 29.5061 50.6943 28.6059 49.7941C27.7057 48.8939 27.2 47.673 27.2 46.4ZM28.8512 15.424C28.9848 14.6862 29.3732 14.0187 29.9487 13.5381C30.5242 13.0574 31.2502 12.7941 32 12.7941C32.7498 12.7941 33.4758 13.0574 34.0513 13.5381C34.6268 14.0187 35.0152 14.6862 35.1488 15.424L35.2 16V32L35.1488 32.576C35.0152 33.3138 34.6268 33.9813 34.0513 34.4619C33.4758 34.9426 32.7498 35.2059 32 35.2059C31.2502 35.2059 30.5242 34.9426 29.9487 34.4619C29.3732 33.9813 28.9848 33.3138 28.8512 32.576L28.8 32V16L28.8512 15.424Z" fill="#FD9A39" />
                    </svg>
                </figure>
                <h1 className='text-center fz18 black fw-po-medium mb-12'>No columns name matched with Sample CSV</h1>
                <p className='text-center fz14 fw-po-medium black-dark-700'>Retry uploading the CSV useing the same column names as in the sample CSV file.
                    {/* <span className='d-md-block'>Download the sample CSV Here.</span>
                </p>
                {/* <a className="pr-btn fz14 fw-medium px-8 py4 d-table mx-auto mt-30"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18.875 13.625V16.4583C18.875 16.8341 18.7257 17.1944 18.4601 17.4601C18.1944 17.7257 17.8341 17.875 17.4583 17.875H7.54167C7.16594 17.875 6.80561 17.7257 6.53993 17.4601C6.27426 17.1944 6.125 16.8341 6.125 16.4583V13.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.9585 10.0833L12.5002 13.625L16.0418 10.0833" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.5 13.625V5.125" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>Download Sample CSV</a>

            </div>} */}
            
            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">
                <button className='pr-btn ml-8' onClick={closeErrorPage}>Re-upload CSV</button>
            </div>
        </>
    )
}

export default UploapError;