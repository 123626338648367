import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { getAllSubscription } from '../../../services/public/public.service';
import InputSelect from '../../../components/InputGroup/InputSelect';

const MakeCompanyFreemium = ({ show, closeModal, handleSubmit }) => {

    const [planList, setPlanList] = useState([]);
    const [plan, setPlan] = useState('');

    const getPlans = async () => {
        const res = await getAllSubscription();
        if (res.data.status === 200) {
            setPlanList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
        }
    }
    useEffect(() => {
        getPlans();
    }, []);

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Freemium Plans</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className="resent-notes px-4 !pt-4 h-[63vh] overflow-auto">
                <InputSelect
                    value={plan ? planList.find(i => i.id === plan) : ''}
                    label={'Select Plan'}
                    options={planList}
                    // className="basic-multi-select form-control"
                    // classNamePrefix="select"
                    // style={(values.selected_mentors.length === 0 && touched.selected_mentors) && { border: "2px solid #ef4444" }}
                    onChange={(e) => {
                        setPlan(e.value)
                    }
                    }
                // onBlur={() => setTouchedMentorId(true)}
                />
            </div>
            <div className={`absolute bottom-0 flex flex-row items-center justify-between bg-white border-top border-color w-100 space-x-3 p-3`}>
                <button className='w-1/2 outline-btn !py-1.5 !text-lg !font-medim hover:bg-black100 !rounded-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-1/2 !py-1.5 !text-lg !font-medim !rounded-lg' onClick={() => { handleSubmit(plan) }}>Save</button>
            </div>
        </RightModal>
    )
}

export default MakeCompanyFreemium