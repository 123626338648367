import React, { useEffect, useState } from 'react'
import RightModal from '../../Modals/RightModal'
import { toast } from 'react-toastify';
import { getMediaName, uploadFile } from '../../../services/public/public.service';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as PlusBlueIcon } from '../../../assets/icons/plusBlue.svg'
import InputTextarea from '../../InputGroup/InputTextarea';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import CreatableSelect from 'react-select';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Imageplacehoder } from '../../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { createConstructionUpdate, updateConstructionUpdate, uploadCustomerBookingDocs } from '../../../services/private/inventory.service';
import { ReactComponent as BackICon } from '../../../assets/icons/back.svg';
import DateTimePicker from '../../InputGroup/DateTime';
import moment from 'moment';
import InputSelect from '../../InputGroup/InputSelect';
import { CustomerDocumentList, customerDocList, documentCategory } from '../../../helpers/enums';
import CenterModal from '../../Modals/center-modal';
import InputText from '../../InputGroup/InputText';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Uploadicon } from '../../../assets/icons/uploadicon.svg';
import pdfimg from '../../../assets/icons/pdfview.jpg';

let customerDoc = [...customerDocList, {
    fileName: 'Booking Form',
    type: '',
    url: '',
    size: '',
    is_private: false,
    extension: '',
    file_type: '',
    category: 'Booking Form',
    showCategory: false
},]

const UploadDocumentModal = ({ show, closeModal, uuid, getAllDocuments, documents, customer }) => {

    const [constructionDoc, setConstructionDoc] = useState([]);
    const [customerDocuments, setCustomerDocuments] = useState([]);
    const [aadharNo, setAadharNo] = useState('')
    const [panNo, setPanNo] = useState('');
    const [bookingNo, setBookingNo] = useState('');
    const [passportNo, setPassportNo] = useState('');
    const [newDocUpload, setNewDocUpload] = useState([])

    useEffect(() => {
        if (customer) {
            setAadharNo(customer?.customer_aadhaar_no || '');
            setPanNo(customer?.customer_pan_no || '');
            setPassportNo(customer?.customer_passport_no || '');
            setBookingNo(customer?.customer_booking_form_no || '')
        }
    }, [customer]);

    useEffect(() => {
        if (documents.length > 0) {
            setCustomerDocuments(documents)
            documents?.forEach(item => {
                customerDoc.forEach((doc, index) => {
                    if (doc.fileName === item?.meta) {

                        customerDoc[index] = {
                            fileName: item.meta,
                            meta: item.meta,
                            type: item.file_type,
                            url: item.url,
                            size: item.size,
                            is_private: false,
                            extension: item.extension,
                            file_type: item.file_type,
                            category: item.category,
                            showCategory: false
                        }
                    }
                    if (item?.docType === 'other') {
                        customerDoc.push(item);
                    }
                })
            })
        }
    }, [documents])

    // const uploadFileToS3 = async (file, index) => {
    //     // if (file.type !== 'video/' && file.size / 1048576 > 5) {
    //     //     toast.warning('File size exceeds 5mb');
    //     //     return;
    //     // }
    //     const formData = new FormData();
    //     formData.append("file", file)
    //     const response = await uploadFile(formData);
    //     if (response.status === 200) {
    //         const newFile = {
    //             name: file.name,
    //             fileName: file.name,
    //             type: file.type,
    //             url: response.data.data.s3_url,
    //             size: file.size,
    //             is_private: false,
    //             extension: file.name.split('.').pop(),
    //             file_type: file.type,
    //             category: '',
    //         }
    //         if (index >= 0) {
    //             let newDocs = [...constructionDoc];
    //             newDocs[index] = newFile;
    //             setConstructionDoc(newDocs)
    //         } else {
    //             setConstructionDoc([...constructionDoc, newFile])
    //         }

    //     }
    // }

    // const handleOnChange = async (e, index) => {
    //     uploadFileToS3(e.target.files[0], index);
    // }

    const handleCustomerDocumentUpload = async (e, fileName, index, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: fileName,
                meta: fileName,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: customerDoc[index].category || ''
            }
            const stateIndex = state.findIndex(f => f.fileName === fileName)
            if (stateIndex > -1) {
                let docState = [...state];
                docState[stateIndex] = newFile;
                setState(docState);
            } else {
                setState([...state, newFile])
            }
            setNewDocUpload([...newDocUpload, newFile]);
            customerDoc[index] = newFile
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const handleUploadOtherDocs = async (e, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: '',
                showCategory: true,
                docType: 'other'
            }
            setState([...state, newFile])
            setNewDocUpload([...newDocUpload, newFile]);
            customerDoc.push(newFile)
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }


    const handleSubmit = async () => {
        const payload = {
            documents: newDocUpload,
            ...(aadharNo) && { customer_aadhaar_no: aadharNo },
            ...(panNo) && { customer_pan_no: panNo },
            ...(passportNo) && { customer_passport_no: passportNo },
            ...(bookingNo) && { customer_booking_form_no: bookingNo }
        }
        let res = {};


        res = await uploadCustomerBookingDocs(uuid, payload)

        if (res.data.status === 200) {
            // console.log(res.data.data)
            getAllDocuments();
            closeModal();
        }
    }

    return (
        <CenterModal show={show} closeModal={closeModal}>
            <div className='p-4 flex items-center'>
                <button onClick={closeModal} className='mr-2'>
                    <BackICon />
                </button>
                <h3 className='text-lg font-semibold m-0'> Customer Document</h3>
            </div>
            <div className='px-4 h-[80vh] overflow-y-auto'>
                <div className='!mt-4'>
                    {/* <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                        Customer Document
                    </h5> */}

                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                            onClick={() => {
                                document.getElementById(`upload-customer-doc`).click();
                            }}>
                            <input type="file" className='d-none' id={`upload-customer-doc`} accept="application/pdf, image/*" onChange={(e) => handleUploadOtherDocs(e, customerDocuments, setCustomerDocuments)} />
                            <figure className='!table !mx-auto'>
                                <Uploadicon className='h-[64px] mt-2' />
                            </figure>
                            <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload More Documents </p>
                            <p className='text-center !text-black text-sm font-medium inter'>More Documents </p>
                        </div>
                        {
                            customerDoc.map((doc, index) => (
                                <React.Fragment key={`${doc.fileName}`}>
                                    <input type="file" className='d-none' accept="application/pdf, image/*" id={`upload-customer-${index}`} onChange={(e) => {
                                        handleCustomerDocumentUpload(e, doc.fileName, index, customerDocuments, setCustomerDocuments)
                                    }} />
                                    {!doc?.url ? <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                        onClick={() => {
                                            document.getElementById(`upload-customer-${index}`).click();
                                        }}>
                                        <figure className='!table !mx-auto'>
                                            <Uploadicon className='h-[64px] mt-2' />
                                        </figure>
                                        <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload {doc.fileName} </p>
                                        <p className='text-center !text-black text-sm font-medium inter'> {doc.fileName} </p>
                                    </div> :
                                        <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                            <figure className='mb-0 !table !mx-auto relative group'>

                                                {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                    <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                    : <img src={pdfimg} className='h-[100px] w-[175px]' />}
                                                <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                    <button
                                                        type='button'
                                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                        onClick={() => {
                                                            document.getElementById(`upload-customer-${index}`).click();
                                                        }}
                                                    ><PluswhiteIcon />Replace</button>

                                                    <button
                                                        type='button'
                                                        className={`${style.delbtn} inter border-0 bg-white`}
                                                        onClick={() => {
                                                            let newFiles = [...customerDocuments];
                                                            newFiles.splice(index, 1);
                                                            setCustomerDocuments(newFiles);
                                                            if (customerDoc[index]?.docType === 'other') {
                                                                customerDoc.splice(index, 1);
                                                            } else {
                                                                customerDoc[index].url = ''
                                                                customerDoc[index].fileName = ''
                                                                customerDoc[index].type = ''
                                                                customerDoc[index].size = ''
                                                                customerDoc[index].extension = ''
                                                                customerDoc[index].file_type = ''
                                                            }
                                                        }}
                                                    ><TrashIcon /></button>
                                                </div>
                                            </figure>
                                            <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'>
                                                {/* <FilePdf />  */}
                                                {doc.category}
                                            </p>
                                            {doc.showCategory && <InputSelect
                                                // label='Source'
                                                placeholder='Select Category'
                                                inputclass='m-0'
                                                // styles={customStyles}
                                                value={doc.category ? CustomerDocumentList.find(item => item.value === doc.category) : ''}
                                                options={CustomerDocumentList}
                                                onChange={(value) => {
                                                    let customers = [...customerDocuments]
                                                    let fileIndex = customers.findIndex(item => item.fileName === doc.fileName);
                                                    customers[fileIndex].category = value.value
                                                    setCustomerDocuments(customers)
                                                    customerDoc[index].category = value.value
                                                }}
                                            />}
                                            {doc.fileName === 'Aadhar Front' && <div>

                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Aadhaar Number"
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => {
                                                        setAadharNo(e.target.value
                                                            .replace(/^0+/g, '')
                                                            .replace(/[^0-9]/g, ''))
                                                    }}
                                                    value={aadharNo}
                                                />

                                            </div>}
                                            {doc.fileName === 'PAN' && <div>

                                                <InputText
                                                    type="text"
                                                    placeholder="Enter PAN Number"
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => setPanNo(e.target.value)}
                                                    value={panNo}
                                                />

                                            </div>}
                                            {doc.fileName === 'Booking Form' && <div>

                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Booking Number"
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => setBookingNo(e.target.value)}
                                                    value={bookingNo}
                                                />

                                            </div>}
                                            {doc.fileName === 'Passport' && <div>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Passport Number"
                                                    inputclass='m-0 inter'
                                                    onChange={(e) => setPassportNo(e.target.value)}
                                                    value={passportNo}
                                                />

                                            </div>}

                                        </div>}
                                </React.Fragment>
                            ))
                        }
                    </div>
                    {/* <div>

                        <div className='my-3'>

                            <label htmlFor=""> Upload Document</label>
                            <div
                                className={`relative group border border-primary bg-blueHighlight h-[100px] rounded-lg flex flex-col items-center justify-center`}
                            >
                                <input type="file" className='d-none' id={`upload-construction-doc`} onChange={(e) => handleOnChange(e)} />

                                <div className={`${constructionDoc.length > 0 ? 'flex-col' : ''} flex items-center`}>
                                    <button
                                        type='button'
                                        className='!text-primary  flex items-center justify-center py-1 px-2 gap-1 text-sm font-medium'
                                        onClick={() => {
                                            document.getElementById(`upload-construction-doc`).click();
                                        }}
                                    >
                                        <PlusBlueIcon />
                                        Add Document
                                    </button>

                                </div>

                            </div>
                        </div>
                        {constructionDoc.length > 0 && <div className='h-[50vh]'>
                            {
                                constructionDoc.map((doc, index) => (
                                    <div className=' bg-[#FAFAFB] !p-4 rounded-lg relative '>
                                        <input type="file" className='d-none' id={`upload-payment-proof-${index}`} onChange={(e) => handleOnChange(e, index)} />
                                        <figure className='mb-0 !table !mx-auto relative group'>
                                            {/* <Imageplacehoder className='w-full ' /> 
                                            {['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'].includes((doc.fileName).split('.').pop()) ? <img src={doc.url} alt="" className='h-100 w-100' style={{ objectFit: 'cover' }} />
                                                :
                                                <video width="100%" height="250" controls muted style={{ objectFit: 'cover', borderRadius: '8px' }}>
                                                    <source src={doc.url} type="video/mp4" />
                                                </video>
                                            }
                                            <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                <button
                                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                    onClick={() => {
                                                        document.getElementById(`upload-payment-proof-${index}`).click();
                                                    }}
                                                ><PluswhiteIcon />Replace</button>

                                                <button
                                                    className={`${style.delbtn} inter border-0 bg-white`}
                                                    onClick={() => {
                                                        let newFiles = [...constructionDoc];
                                                        newFiles.splice(index, 1);
                                                        setConstructionDoc(newFiles);

                                                    }}
                                                ><TrashIcon /></button>
                                            </div>
                                        </figure>
                                        <div className='mt-3'>
                                            <InputSelect
                                                placeholder='Select Document Category'
                                                onChange={(value) => {
                                                    const newList = [...constructionDoc];
                                                    newList[index].category = value.value;
                                                    setConstructionDoc([...newList]);
                                                }}
                                                value={doc.category ? { label: doc.category, value: doc.category } : ''}
                                                options={documentCategory}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    </div> */}
                </div>
            </div>
            <div className='px-4 py-4 w-full'>
                <div className='flex justify-end w-full'>
                    <button onClick={closeModal} className="text-base font-medium inter leading-6 border mr-4 rounded-lg !text-black700 sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Cancel</button>
                    <button onClick={handleSubmit} className="text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[130px] sm:basis-[130px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter">Save</button>
                </div>
            </div>
        </CenterModal>
    )
}

export default UploadDocumentModal