import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteTagValidityAutomation, getTagValidityAutomation } from '../../../../services/private/company.service';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import AddTagging from './Modal/AddTagging';
import { ReactComponent as Vthreedots } from '../../../../assets/icons/threedots.svg';
import DeleteTaggingModal from './Modal/DeleteTaggingModal';
import { toast } from 'react-toastify';

const RevisitTagging = () => {
    const [showAddTaggingModal, setShowAddTaggingModal] = useState(false);
    const [taggingData, setTaggingData] = useState([]);
    const [selectedTagging, setSelectedTagging] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const getTaggingData = async () => {
        const res = await getTagValidityAutomation();
        if (res.data.status === 200) {
            setTaggingData(res.data.data)
        }
    }

    useEffect(() => {
        getTaggingData();
    }, []);

    const handleDelete = async () => {
        const res = await deleteTagValidityAutomation(selectedTagging?.uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getTaggingData();
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <div className="roles w-100 flex flex-col h-full">
                <div className="settings-title">
                    <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                        <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                            <li>
                                <Link to='/settings/automation/lead-automation'>All Automation</Link>
                            </li>
                            <li className='flex items-center'><RightArrow />
                                Re-Visit Tag extend Automation
                            </li>
                        </ul>
                    </nav>

                    <div className="w-100 flex justify-between ">
                        <div className="w-10/12">
                            <div className='flex items-center'>
                                <h2 className="text-2xl  mb-2 mr-2 black fw-po-medium flex items-center !gap-x-2">
                                    <Link
                                        to='/settings/automation/lead-automation'
                                        className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                    >
                                        <Pageback className="group-hover:stroke-black" />
                                    </Link>
                                    Re-Visit Tag extend Automation
                                </h2>
                            </div>
                            <p className="text-sm black-dark-700 mb-0 !pl-10">
                                On Revisit tagging  will get extended for tagged leads.
                            </p>
                        </div>
                        <div className="w-2/12 flex items-start space-x-2 justify-end">
                            <div>
                                <button className='btn-primary px-3 py-2 rounded-lg' onClick={() => setShowAddTaggingModal(true)}>Add Tagging</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="roles-table ml-24 mt-24 h-full overflow-auto">
                    <div className="def-table pr-0 h-full!">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Project</th>
                                    <th>Tagging</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {taggingData.length > 0 && taggingData.map((item, index) => (
                                    <tr
                                        key={`${item.name}_${index}`}
                                    >
                                        <td>{index + 1}</td>
                                        <td className="leading-5"> {item.project_name || '-'}</td>
                                        <td className="leading-5"> {item.tag_validity} {item.tag_validity_unit}</td>
                                        <td className="leading-5"> {item.is_active ? 'Active' : 'Inactive'}</td>
                                        <td className='dropdown view-more'>
                                            <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <Vthreedots />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item text-capitalize"
                                                    onClick={() => {
                                                        setSelectedTagging(item);
                                                        setShowAddTaggingModal(true);
                                                    }}>
                                                    Edit
                                                </a>
                                                <a className="dropdown-item del-menu text-capitalize"
                                                    onClick={() => {
                                                        setSelectedTagging(item);
                                                        setShowDeleteModal(true);
                                                    }}>
                                                    Delete
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                                {
                                    taggingData.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize"> No Tagging Added </h2>
                                                <div>
                                                    <button className='btn-primary px-3 py-2 rounded-lg' onClick={() => setShowAddTaggingModal(true)}>Add Tagging</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showAddTaggingModal && <AddTagging
                    show={showAddTaggingModal}
                    closeModal={() => {
                        setShowAddTaggingModal(false)
                        setSelectedTagging({})
                    }}
                    getTaggingData={getTaggingData}
                    selectedTagging={selectedTagging}
                />}
                {
                    showDeleteModal && <DeleteTaggingModal
                        show={showDeleteModal}
                        closeModal={() => {
                            setShowDeleteModal(false);
                            setSelectedTagging({})
                        }}
                        selectedTagging={selectedTagging}
                        handleDelete={handleDelete}
                    />
                }
            </div>
        </>
    )
}

export default RevisitTagging