import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { getInitials } from '../../../../helpers/helpers';
import RightModal from '../../../../components/Modals/RightModal';
import { getUnassignedLeadDistributionUsers, getUsersForSettings, saveUnassignedLeadDistributionUsers } from '../../../../services/private/company.service';
import { toast } from 'react-toastify';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const AddUserInUnassignedLeadsModal = ({ show, closeModal, getUnassignedLeadsUser, selectedUsers }) => {
    const [teams, setTeams] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [userList, setUserList] = useState([])

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        ...data,
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                setUserList(userData)
            }
        })
    }
    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        if (selectedUsers.length > 0) {
            setAddedUsers(selectedUsers);
        }
    }, [selectedUsers])

    const handleSubmit = async () => {
        const payload = { users: addedUsers.map(u => u.id) }
        const res = await saveUnassignedLeadDistributionUsers(payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUnassignedLeadsUser();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between !px-5 !py-4'>
                <h1 className='text-black text-2xl inter m-0'>Add Users</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='flex h-100 overflow-auto flex-column !px-5'>
                <div className='flex flex-col !gap-y-4'>
                    <InputSelect
                        label='Users'
                        placeholder='Select User'
                        value={''}
                        onChange={(value) => {
                            const projectIndex = addedUsers.findIndex(t => t.name === value.name);
                            if (projectIndex === -1) {
                                setAddedUsers([...addedUsers, value]);
                            }
                        }}
                        styles={customStyles}
                        options={userList}
                    />
                </div>
                {addedUsers.length > 0 &&
                    <div className='!mt-4'>
                        <p className='text-base font-semibold'>Selected Users({addedUsers.length})</p>
                        <div className='border-b'></div>
                    </div>
                }
                <div className='h-[60vh] overflow-auto'>
                    {addedUsers.map((user, index) => (
                        <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4 mt-2'>
                            {/* <Link to='/settings/user-roles/teamDetails' className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link> */}
                            <div className='flex justify-between gap-2'>
                                <div className='flex'>
                                    <span className="flex items-center justify-center mx-2 border p-1 font-semibold rounded-full text-xl h-10 w-10 text-white bg-primary">
                                        {getInitials(user?.name || '')}
                                    </span>
                                    <div className='leading-[26px] inter'>
                                        <p className='m-0 text-black text-sm font-bold'>{user?.name}</p>
                                        <p className='m-0 text-black700 text-sm'>{user?.role_name}</p>
                                        <p className='m-0 text-black700 text-sm'>{user?.mobile}</p>
                                        <p className='m-0 text-black700 text-sm'>{user?.email}</p>
                                    </div>
                                </div>
                                <div className=''>
                                    <button className='text-red-btn' onClick={() => {
                                        let newLists = [...addedUsers];
                                        newLists.splice(index, 1);
                                        setAddedUsers(newLists);
                                    }}>
                                        Remove
                                    </button>
                                </div>
                            </div>

                            {/* <div className='mt-2 flex gap-2 !flex-wrap'>
                            <div
                                className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'
                            >
                            </div>

                        </div> */}
                        </div>
                    ))
                    }
                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow`}>
                <button className='outline-btn w-36 basis-36 shrink-0 grow-0  !text-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button
                    type='button'
                    // disabled={addedUsers.length === 0}
                    onClick={handleSubmit}
                    className='pr-btn w-100 !text-lg'
                >
                    Confirm
                </button>
            </div>
        </RightModal>
    )
}

export default AddUserInUnassignedLeadsModal;
