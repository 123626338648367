import moment from 'moment';
import React from 'react'
import { useCallback } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { Oval } from 'react-loader-spinner';
import { DateRange } from 'react-date-range'
import { createDatewithTime } from '../../helpers/date';
import InputSelect from '../InputGroup/InputSelect';
import CustomDateModal from './CustomDateModal';
import { getReEngagement } from '../../services/private/company.service';
import { useEffect } from 'react';

const PreviousLeadsModal = ({
    showModal,
    closeModal,
    handleDateRange,
    getPreviousLeads,
    ignoreDuplicate,
    hndleDuplicateIgnore,
    pageFormList,
    selectedFormId,
    handleFormChange,
    isLoading
}) => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: moment().add(7, 'days'),
            key: 'selection'
        }
    ]);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [date, setDate] = useState('');
    const [reEngagement, setReEngagement] = useState(false);

    const getReEngagementStatus = async () => {
        const res = await getReEngagement();
        if (res.data.status === 200) {
            setReEngagement(res.data.data);
        }
    };

    useEffect(() => {
        getReEngagementStatus()
    }, [])

    const handleCustomDate = useCallback((date) => {
        const parsedDate = {
            from: Math.floor(Date.parse(new Date(date[0].startDate).toISOString()) / 1000),
            to: Math.floor(Date.parse(new Date(date[0].endDate).toISOString()) / 1000)
        }
        setDate(`${moment(new Date(date[0].startDate)).format("Do MMM YYYY")}` + '-' + `${moment(new Date(date[0].endDate)).format("Do MMM YYYY")}`)
        handleDateRange(parsedDate)
        closeCustomDateModal();
    }, []);

    const closeCustomDateModal = useCallback(() => {
        setShowCustomDate(false);
    }, []);



    return (
        <Modal show={showModal} onHide={closeModal}
            //  backdrop="static"
            fullscreen centered dialogClassName="modal-radius fb-intr-modal previous-leads-import">
            <>
                <a className="cursor-pointer close position-absolute" onClick={closeModal}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#000000"></path></svg></a>
                <figure className="d-table mx-auto mb-20">
                    <img src={`${process.env.PUBLIC_URL}/fb-import.png`} style={{ height: '78px' }} />
                </figure>
                <h1 className='text-center d-table mx-auto po fz24 black ls1 mb-0'>Importing previous leads </h1>
                <div>
                    <div className='date-range mb-8 mt-20'>
                        <div className="left-ic">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.00065 5.33301H16.0007C17.4734 5.33301 18.6673 6.52692 18.6673 7.99967V15.9997C18.6673 17.4724 17.4734 18.6663 16.0007 18.6663H8.00065C6.52789 18.6663 5.33398 17.4724 5.33398 15.9997V7.99967C5.33398 6.52692 6.52789 5.33301 8.00065 5.33301ZM8.00065 6.66634C7.26427 6.66634 6.66732 7.26329 6.66732 7.99967V15.9997C6.66732 16.7361 7.26427 17.333 8.00065 17.333H16.0007C16.737 17.333 17.334 16.7361 17.334 15.9997V7.99967C17.334 7.26329 16.737 6.66634 16.0007 6.66634H8.00065ZM8.66732 9.33301C8.29913 9.33301 8.00065 9.03453 8.00065 8.66634C8.00065 8.29815 8.29913 7.99967 8.66732 7.99967H15.334C15.7022 7.99967 16.0007 8.29815 16.0007 8.66634C16.0007 9.03453 15.7022 9.33301 15.334 9.33301H8.66732ZM8.66732 12.6663C8.29913 12.6663 8.00065 12.3679 8.00065 11.9997C8.00065 11.6315 8.29913 11.333 8.66732 11.333H15.334C15.7022 11.333 16.0007 11.6315 16.0007 11.9997C16.0007 12.3679 15.7022 12.6663 15.334 12.6663H8.66732ZM8.66732 15.9997C8.29913 15.9997 8.00065 15.7012 8.00065 15.333C8.00065 14.9648 8.29913 14.6663 8.66732 14.6663H12.0007C12.3688 14.6663 12.6673 14.9648 12.6673 15.333C12.6673 15.7012 12.3688 15.9997 12.0007 15.9997H8.66732Z" fill="#92929D" />
                            </svg>
                        </div>
                        <div className="right-date">
                            <div className="form-group">
                                <label htmlFor="" className='black'>Select Form</label>
                                {/* <input type="button" className='form-control' value={date} readOnly onClick={() => setShowCustomDate(true)} /> */}
                                <InputSelect
                                    name='Form fields'
                                    options={pageFormList}
                                    value={selectedFormId}
                                    onChange={(value) => handleFormChange(value)}
                                    placeholder='Select Facebook Form'
                                />

                            </div>
                        </div>
                    </div>
                    <div className='date-range mb-8 mt-20'>
                        <div className="left-ic"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.66305 7.86634C9.6296 8.20323 9.34536 8.46634 8.99967 8.46634C8.65398 8.46634 8.36975 8.20323 8.3363 7.86634H6.66634V8.93301H17.333V7.86634H16.263C16.2296 8.20323 15.9454 8.46634 15.5997 8.46634C15.254 8.46634 14.9697 8.20323 14.9363 7.86634H9.66305ZM8.33301 6.53301V5.99967C8.33301 5.63148 8.63148 5.33301 8.99967 5.33301C9.36786 5.33301 9.66634 5.63148 9.66634 5.99967V6.53301H14.933V5.99967C14.933 5.63148 15.2315 5.33301 15.5997 5.33301C15.9679 5.33301 16.2663 5.63148 16.2663 5.99967V6.53301H17.3997C18.0992 6.53301 18.6663 7.10011 18.6663 7.79967V16.6663C18.6663 17.7709 17.7709 18.6663 16.6663 18.6663H7.33301C6.22844 18.6663 5.33301 17.7709 5.33301 16.6663V7.79967C5.33301 7.10011 5.90011 6.53301 6.59967 6.53301H8.33301ZM17.333 10.2663H6.66634V16.6663C6.66634 17.0345 6.96482 17.333 7.33301 17.333H16.6663C17.0345 17.333 17.333 17.0345 17.333 16.6663V10.2663ZM8.39967 12.6663C8.03148 12.6663 7.73301 12.3679 7.73301 11.9997C7.73301 11.6315 8.03148 11.333 8.39967 11.333H10.7997C11.1679 11.333 11.4663 11.6315 11.4663 11.9997C11.4663 12.3679 11.1679 12.6663 10.7997 12.6663H8.39967ZM8.39967 15.0663C8.03148 15.0663 7.73301 14.7679 7.73301 14.3997C7.73301 14.0315 8.03148 13.733 8.39967 13.733H13.1997C13.5679 13.733 13.8663 14.0315 13.8663 14.3997C13.8663 14.7679 13.5679 15.0663 13.1997 15.0663H8.39967Z" fill="#92929D" />
                        </svg></div>
                        <div className="right-date">
                            <div className="form-group">
                                <label htmlFor="" className='black'>Date Range</label>
                                <input type="text" className='form-control cursor-pointer' placeholder='Select date' value={date} readOnly onClick={() => setShowCustomDate(true)} />

                            </div>
                        </div>
                    </div>
                    <div className="lead-igone position-relative">

                        <div className="top-btn">
                            <label htmlFor="ignore">
                                <input
                                    type="radio"
                                    name="leads"
                                    id="ignore"
                                    value={'IGNORE'}
                                    checked={ignoreDuplicate === 'IGNORE'}
                                    onChange={() => hndleDuplicateIgnore('IGNORE')} />
                                <h2>Ignore Duplicate Leads</h2>
                            </label>
                        </div>
                        <div className="bottom-desc">
                            <p>All duplicate leads with same mobile number will be ignored & non duplicate leads will be uploaded as new lead.</p>
                        </div>
                    </div>
                    <div className="lead-igone position-relative">

                        <div className="top-btn">
                            <label htmlFor="import">
                                <input
                                    type="radio"
                                    name="leads"
                                    id="import"
                                    checked={ignoreDuplicate === 'NEW_LEAD'}
                                    value={'NEW_LEAD'}
                                    onChange={() => hndleDuplicateIgnore('NEW_LEAD')} />
                                <h2>Import all Lead as New</h2>
                            </label>
                        </div>
                        <div className="bottom-desc">
                            <p>All leads will be uploaded as new lead.</p>
                        </div>
                    </div>
                    {reEngagement && ignoreDuplicate === 'NEW_LEAD' && <div className='leadmodalnotes'>
                        <p>Note : Your Lead Re-engagement is switched on. The lead with same mobile number will be marked as Re-engaged.
                            <a href='/settings/leads/lead-duplication' target='_blank' className='pr-color hover-pr'>Click here</a> to manage Re-engagement </p>
                    </div>}

                    <div className="footer-modal d-flex align-items-center justify-content-center mt-24">
                        <button className="pr-btn fz16 py-8 px-8 flex items-center" onClick={getPreviousLeads} disabled={isLoading}>
                            {isLoading ? <Oval
                                height={17}
                                width={30}
                                color="#0062FF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#0062FF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            /> : <>
                                <svg xmlns="http://www.w3.org/2000/svg" className='mr-2' width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.16699 6.66699L8.50033 10.0003L11.8337 6.66699" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.5 10V2" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Import Leads
                            </>}
                        </button>
                    </div>
                </div>
                {showCustomDate && <CustomDateModal show={showCustomDate} closeCustomDateModal={closeCustomDateModal} handleCustomDate={handleCustomDate} />}
            </>
        </Modal>
    )
}

export default PreviousLeadsModal