import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
// import { Tooltip } from 'react-tooltip';
import { Controller, useForm } from 'react-hook-form';
// import InputGroup from 'src/components/Inputs/InputGroup';
// import { validationSchema } from 'src/components/create-event/validations';
// import Event from 'src/components/card/event';
// import SelectField from 'src/components/Inputs/select';
// import makeRequest, { formRequest } from 'src/httpHandler/httpHandler';
// import Plus from 'src/components/SvgComponents/Plus';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
// import JoinzoomIcon from '../assets/Icons/joinzoom.svg'
// import Cardpreview from '../assets/Icons/cardpreview.svg'
// import { ReactComponent as DateIcon } from '../assets/Icons/date.svg';
// import { ReactComponent as TimeIcon } from '../assets/Icons/time.svg';
// import { ReactComponent as BackIcon } from '../assets/Icons/back.svg';
// import { ReactComponent as InfoblueIcon } from '../assets/Icons/infoblue.svg';
// import { ReactComponent as InfograyIcon } from '../assets/Icons/infogray.svg';
// import overlayImg from 'src/assets/Overlay.png';
// import EventProgess from 'src/components/create-event/eventProgess';
// import EventPublished from 'src/components/create-event/eventPublished';
// import { ReactComponent as InfoIcon } from '../assets/Icons/infoicon.svg';
// import RefreshModal from 'src/components/Modal/RefreshModal';
// import { EVENT_TYPE, FOOD_TYPE, PROPERTY_TYPE } from 'src/utils/constans';
// import Sppiner from 'src/components/spinner';
import EventProgess from './ProgressBar';
import EventPublished from './EventPublished';
import RefreshModal from './Modal/RefreshModal';
import InputText from '../../components/InputGroup/InputText';
import InputSelect from '../../components/InputGroup/InputSelect';
import Event from './Event';
import { validationSchema } from './validations';
import { getAllCities, getCitiesFromState, getStates, uploadFile } from '../../services/public/public.service';
import { getEventDetail, insertEvent, updateEventByUUID } from '../../services/private/company.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#F1F1F5',
        backgroundColor: '#FAFAFB',
        height: '44px',
        cursor: 'pointer',
        borderRadius: '8px',
        fontSize: '14px',
        '&:hover': { borderColor: '#F1F1F5' }

        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        color: "#44444f",
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

export const EVENT_TYPE = [
    {
        label: 'Online',
        value: 'online'
    },
    {
        label: 'Offline',
        value: 'offline'
    }
]

export const PROPERTY_TYPE = [
    {
        value: 1,
        label: "Residential"
    },
    {
        value: 2,
        label: "Commercial"
    },
]

export const FOOD_TYPE = [
    {
        label: 'No Food',
        value: 'no food'
    },
    {
        label: 'Food Only',
        value: 'food only'
    },
    {
        label: 'Food & Drinks',
        value: 'food & drinks'
    }
]

const CreateEvent = () => {

    const [thumbnailURL, setThumbnailURL] = useState('');
    const [isThumbnailMissing, setIsThumbnailMissing] = useState(false);
    const [thumbnailFileError, setThumbnailFileError] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [propertyList, setPropertyList] = useState([]);
    const [createEventForm, setCreateEventForm] = useState(true);
    const [progress, setProgress] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isExitModalOpen, setIsExitModalOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false);
    const [bannerUrl, setBannerUrl] = useState('');
    const [isBannerMissing, setIsBannerMissing] = useState(false);
    const [bannerFileError, setBannerFileError] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [videoName, setVideoName] = useState('');
    const [videoFileError, setVideoFileError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [loading, setLoading] = useState(false);

    const bgimage = {
        backgroundImage: `url('${bannerUrl}')`,
        backgroundSize: 'content',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    };

    const handleScroll = () => {
        const div = document.getElementById('scrollingDiv');
        const scrollPosition = div.scrollTop;

        if (scrollPosition > 20) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const closeExitModal = () => {
        setIsExitModalOpen(false)
    }

    const { uuid } = useParams();

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting }
    } = useForm({
        defaultValues: {
            eventName: '',
            developerName: '',
            project: '',
            date: '',
            startTime: '',
            endTime: '',
            description: '',
            eventType: '',
            foodType: '',
            address: '',
            propertyType: '',
            city: '',
            state: '',
            otherDeveloper: ''
        },
        resolver: yupResolver(validationSchema)
    });

    const getAllState = async () => {
        const response = await getStates();
        if (response.data.status === 200) {
            const state = response.data.data.map(item => ({ label: item.name, value: item.name }));
            setStateList(state);
        }
    }

    useEffect(() => {
        getAllState();
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        // const handleBackButton = () => {
        //     console.log('browser baack')
        //     const splitedPath = pathname.split('/');
        //     if ((splitedPath.includes('new-integration') ||
        //         splitedPath[splitedPath.length - 1].split('?').includes('new-integration')) && steps < 4) {
        //         setShowCloseConfirmationModa(true);
        //     }
        // }

        // window.addEventListener("popstate", handleBackButton);
        window.addEventListener("beforeunload", unloadCallback);

        return () => {
            // window.removeEventListener("popstate", handleBackButton);
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, []);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const res = await getEventDetail(uuid)
            // await makeRequest(`launchpad/getEventDetail/${uuid}`, 'get');
            setLoading(false);
            if (res.data.status === 200) {
                reset({
                    eventName: res.data.data.name,
                    developerName: res.data.data.developer_name,
                    otherDeveloper: res.data.data.other_develoer,
                    project: res.data.data.project,
                    date: moment(res.data.data.start_date).format('YYYY-MM-DD'),
                    startTime: moment(res.data.data.start_time).format('HH:mm'),
                    endTime: moment(res.data.data.end_time).format('HH:mm'),
                    description: res.data.data.description,
                    // eventType: EVENT_TYPE.find(item => item.value === res.data.data.type),
                    // foodType: FOOD_TYPE.find(item => item.value === res.data.data.food_type),
                    address: res.data.data.location,
                    city: res.data.data.city ? { label: res.data.data.city, value: res.data.data.city } : '',
                    state: res.data.data.state ? { label: res.data.data.state, value: res.data.data.state } : '',
                    propertyType: res.data.data.property_type.map(item => item && propertyList.find(list => list.label === item))
                },
                    { keepDefaultValues: true }
                )
                setUserDetails(res.data.data);
                setThumbnailURL(res.data.data.thumbnail);
                setBannerUrl(res.data.data.banner);
                setVideoUrl(res.data.data.video_url);
                setVideoName(res.data.data.video_url.substring(res.data.data.video_url.lastIndexOf('/') + 1).split('-')[1])
            }
        }
        if (uuid && propertyList.length > 0) load();
    }, [uuid, propertyList]);

    const name = watch('eventName');
    const description = watch('description');
    const food = watch('foodType');
    const address = watch('address');
    const startTime = watch('startTime');
    const endTime = watch('endTime');
    const date = watch('date');
    const eventType = watch('eventType');
    const propertyType = watch('propertyType');
    const city = watch('city');
    const state = watch('state');
    const developerName = watch('developerName');

    const getCityByState = async (state) => {
        const response = await getCitiesFromState(state)
        if (response.data.status === 200) {
            const city = response.data.data.map(item => ({ label: item.name, value: item.name }))
            setCityList(city);
        }
    }

    const handleOnChange = async (e) => {
        const file = e.target.files[0];
        if (file.size / 1048576 > 2) {
            setThumbnailFileError('Size exceeds 5mb');
            return;
        }
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            setThumbnailFileError('Wrong File format Uploaded');
            return;
        }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData)
        // await formRequest('/public/getS3Url', 'post', formData);

        if (response.data.status === 200) {
            setThumbnailURL(response.data.data.s3_url);
            setIsThumbnailMissing(false);
            setThumbnailFileError('');
        }
    }

    const handleBannerOnChange = async (e) => {
        const file = e.target.files[0];

        if (file.size / 1048576 > 2) {
            setBannerFileError('Size exceeds 5mb');
            return;
        }
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            setBannerFileError('Wrong File format Uploaded');
            return;
        }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData)
        // await formRequest('/public/getS3Url', 'post', formData);

        if (response.data.status === 200) {
            setBannerUrl(response.data.data.s3_url);
            setIsBannerMissing(false);
            setBannerFileError('');
        }
    }


    const loadOptions = (city) => {
        return {}
        getAllCities(city)
            .then((res) => {
                if (res.status === 200) {
                    const cities = res.data?.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const onSubmit = async (payload) => {
        if (!uuid) {
            const res = await insertEvent(payload)
            if (res.data.status === 200) {
                // toast.success('events created successfully');
                // navigate('/dashboard');
                setCreateEventForm(false);
                setProgress(true);
            }
        } else {
            const res = await updateEventByUUID(uuid, payload)
            if (res.data.status === 200) {
                setCreateEventForm(false);
                setProgress(true);
                // toast.success('events created successfully');
                // navigate('/dashboard');
            }
        }
    }

    const onFormSubmit = (values) => {
        // if (values.eventType?.value === 'offline' && !values.foodType) {
        //     setError('foodType', { type: 'custom', message: 'Please select Food type' });
        //     return;
        // }
        if (values.eventType?.value === 'offline' && !values.address) {
            setError('address', { type: 'custom', message: 'Please enter Address' })
            return;
        }
        if (!thumbnailURL) {
            setIsThumbnailMissing(true)
            return
        }
        if (!bannerUrl) {
            setIsBannerMissing(true)
            return
        }

        const payload = {
            name: values.eventName,
            developer_name: values.developerName,
            other_develoer: values.otherDeveloper,
            project: null,
            start_date: values.date,
            start_time: new Date(`${values.date} ${values.startTime}`).toISOString(),
            end_time: new Date(`${values.date} ${values.endTime}`).toISOString(),
            // description: values.description,
            thumbnail: thumbnailURL,
            type: values.eventType.value,
            food_type: values.eventType.value === 'offline' ? values.foodType.value : null,
            location: values.eventType.value === 'offline' ? values.address : '',
            property_type: values.propertyType.map(item => item.label),
            city: values.city.value,
            is_submitted: false,
            banner: bannerUrl,
            video_url: videoUrl,
            state: values.state.value
        }
        onSubmit(payload);
    }

    const onFormSubmitForReview = (values) => {
        // if (values.eventType.value === 'offline' && !values.foodType) {
        //     setError('foodType', { type: 'custom', message: 'Please select Food type' });
        //     return;
        // }
        if (values.eventType.value === 'offline' && !values.address) {
            setError('address', { type: 'custom', message: 'Please enter Address' })
            return;
        }
        if (!thumbnailURL) {
            setIsThumbnailMissing(true)
            return
        }
        if (!bannerUrl) {
            setIsBannerMissing(true)
            return
        }

        const payload = {
            name: values.eventName,
            developer_name: values.developerName,
            other_develoer: values.otherDeveloper,
            project: null,
            start_date: values.date,
            start_time: new Date(`${values.date} ${values.startTime}`).toISOString(),
            end_time: new Date(`${values.date} ${values.endTime}`).toISOString(),
            // description: values.description,
            thumbnail: thumbnailURL,
            type: values.eventType.value,
            food_type: values.eventType.value === 'offline' ? values.foodType.value : null,
            location: values.eventType.value === 'offline' ? values.address : '',
            property_type: values.propertyType.map(item => item.label),
            city: values.city.value,
            is_submitted: true,
            banner: bannerUrl,
            video_url: videoUrl,
            state: values.state.value
        }
        onSubmit(payload);
    }

    const publishEvent = useCallback(() => {
        setProgress(false);
        setIsPublished(true);
    }, []);

    const handleVideoOnChange = async (e) => {
        const file = e.target.files[0];
        let videoDuration = ''
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            videoDuration = video.duration;
        };

        if (Math.floor(+videoDuration) > 120) {
            setVideoFileError('Video length can not be greater then 2 min');
            return;
        }
        if (file.size / 1048576 > 50) {
            setVideoFileError('Size exceeds 50mb');
            return;
        }
        if (file.type != 'video/mp4' && file.type != 'video/WMV' && file.type != 'video/avi' && file.type != 'video/mov') {
            setVideoFileError('Wrong File format Uploaded');
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file)
        const res = await uploadFile(formData);
        // await formRequest('/public/getS3Url', 'post', formData);
        setUploading(false);
        if (res.status === 200) {
            setVideoUrl(res.data.data.s3_url);
            setVideoName(res.data.data.s3_url.substring(res.data.data.s3_url.lastIndexOf('/') + 1).split('-')[1])
        }
    }

    // if (loading) {
    //     return <div className='flex justify-center items-center h-full w-full'>
    //         <Sppiner />
    //     </div>
    // }

    return (
        <>
            {createEventForm && !progress && !isPublished &&
                <div className=' overflow-auto h-full w-full' id='scrollingDiv' onScroll={handleScroll}>
                    <div className={`${isScrolled ? 'shadow-[0px_20px_50px_rgba(18,17,39,0.08)]' : ''} px-5 py-3.5 border-b border-black200 sticky top-0 bg-white z-10 flex items-center gap-2`} >
                        {/* <BackIcon className='cursor-pointer' onClick={() => setIsExitModalOpen(true)} /> */}
                        <h1 className='black text-2xl leading-[30px] font-medium'> {uuid ? 'Edit' : 'Create New'} Event</h1>
                    </div>
                    <>
                        <div className='flex lg:flex-nowrap flex-wrap pt-5'>
                            <div className='lg:w-3/5 w-full sm:mt-0'>
                                <form
                                    className='w-full sm:grid sm:grid-cols-2 grid-cols-1 flex flex-col gap-y-4 sm:gap-x-8 pl-5 lg:pr-0 sm:pr-5 pr-4 pb-10'
                                    id='event-create'
                                // onSubmit={handleSubmit(onFormSubmit)}
                                >
                                    <div>
                                        <Controller
                                            control={control}
                                            name='eventName'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                                        <label>Event Name<sup className='text-red top-[-2px]'> *</sup></label>
                                                        {name && <label className='black700 text-xs font-normal opacity-70'>{`Characters left: ${name.length}/62`}</label>}
                                                    </div>
                                                    <InputText
                                                        name='eventName'
                                                        placeholder='e.g. TTP Blue Waters - Channel Partner'
                                                        type='text'
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        inputref={`${ref}`}
                                                        required={true}
                                                        maxLength={'62'}
                                                        errorMessage={errors?.eventName?.message}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='developerName'

                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputText
                                                    name='developerName'
                                                    placeholder='eg. Omaxe'
                                                    type='text'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputref={`${ref}`}
                                                    label='Primary Developer'
                                                    required={true}
                                                    errorMessage={errors?.developerName?.message}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <Controller
                                            control={control}
                                            name='date'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputText
                                                    name='date'
                                                    placeholder='Select Event Date'
                                                    type='date'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputref={`${ref}`}
                                                    label='Event Date'
                                                    required={true}
                                                    errorMessage={errors?.date?.message}
                                                />
                                            )}

                                        />
                                        {/* <DateIcon className='absolute top-[45px] right-3 z-0' /> */}
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='otherDeveloper'

                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputText
                                                    name='otherDeveloper'
                                                    placeholder='eg. Omaxe'
                                                    type='text'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputref={`${ref}`}
                                                    label='Other Developer '
                                                    required={false}
                                                // errorMessage={errors?.developerName?.message}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <Controller
                                            control={control}
                                            name='startTime'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputText
                                                    name='startTime'
                                                    placeholder='e.g. 1500'
                                                    type='time'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputref={`${ref}`}
                                                    label='Start Time'
                                                    required={true}
                                                    errorMessage={errors?.startTime?.message}
                                                />
                                            )}
                                        />
                                        {/* <TimeIcon className='absolute top-[45px] right-3 z-0' /> */}
                                    </div>
                                    <div className='relative'>
                                        <Controller
                                            control={control}
                                            name='endTime'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputText
                                                    name='endTime'
                                                    placeholder='e.g. 130'
                                                    type='time'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputref={`${ref}`}
                                                    label='End Time'
                                                    required={true}
                                                    errorMessage={errors?.endTime?.message}
                                                />
                                            )}
                                        />
                                        {/* <TimeIcon className='absolute top-[45px] right-3 z-0' /> */}
                                    </div>
                                    <div className=''>
                                        <Controller
                                            control={control}
                                            name='state'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputSelect
                                                        label='State'
                                                        placeholder='Choose State'
                                                        onChange={(value) => {
                                                            onChange(value);
                                                            setValue('city', '');
                                                            getCityByState(value.value);
                                                        }}
                                                        value={value}
                                                        options={stateList}
                                                        error={errors?.state?.message ? 'Please select State' : ''}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className=''>
                                        <Controller
                                            control={control}
                                            name='city'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (

                                                <>
                                                    <label className='block text-black700 text-sm mb-2 font-medium'>Project City<sup className='text-red top-[-2px]'> *</sup> </label>
                                                    <InputSelect
                                                        styles={customStyles}
                                                        value={value}
                                                        options={cityList}
                                                        placeholder='Choose City'
                                                        onChange={onChange}
                                                        error={errors?.city?.message ? 'Please select City' : ''}
                                                    />
                                                </>
                                            )}
                                        />
                                        {/* {errors?.city?.message && <div className={` text-red mt-2 text-xs font-medium`}>Please Choose City</div>} */}
                                    </div>
                                    <div className=''>
                                        <Controller
                                            control={control}
                                            name='propertyType'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputSelect
                                                        label='Property Type'
                                                        placeholder='Choose Property Type'
                                                        onChange={(e) => {
                                                            if (e.length > 0) {
                                                                onChange(e);
                                                                return
                                                            }
                                                            onChange('')
                                                        }}
                                                        value={value}
                                                        options={PROPERTY_TYPE}
                                                        error={errors?.propertyType?.message ? 'Please select Property Type' : ''}
                                                        isMulti={true}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>


                                    {/* <div className='col-span-2'>
                                        <Controller
                                            control={control}
                                            name='description'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                                        <label>Short Event Description<sup className='text-red top-[-2px]'> *</sup></label>
                                                        {description && <label className='black700 text-xs font-normal opacity-70'>{`Characters left: ${description.length}/120`}</label>}
                                                    </div>
                                                    <InputGroup
                                                        name='description'
                                                        placeholder='e.g. Join with us for a grand Pune channel partner meet at TTP Blue water launch party'
                                                        type='text'
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        inputref={`${ref}`}
                                                        required={true}
                                                        maxLength={'120'}
                                                        errorMessage={errors?.description?.message}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div> */}
                                    <div className='xl:inline-block hidden'></div>
                                    <div className='xl:col-span-1 col-span-2'>
                                        <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                            <label>Event Video</label>
                                            <label className='black700 font-normal opacity-70 text-xs'>800 X 700px , 10MB</label>

                                        </div>
                                        <div className='videoupload border border-grayLight text-black text-sm rounded-lg  w-full bg-black100 h-[44px] flex items-center justify-between px-4'>
                                            <p className='text-black600 text-sm'>{videoName}</p>
                                            <input type="file" className='hidden' id='upload-video' accept='video/*' onChange={handleVideoOnChange} />
                                            {!videoUrl && <div>
                                                {!uploading ? <button
                                                    type='button'
                                                    className='py-1 px-2.5 text-primary text-xs hover:bg-primary hover:text-white font-medium border border-grayLight bg-white rounded-md tracking-[0.5px] relative'
                                                    onClick={() => {
                                                        document.getElementById('upload-video').click();
                                                    }}
                                                    disabled={uploading}
                                                >
                                                    + Upload
                                                </button> : ''
                                                    // <Sppiner />
                                                }
                                            </div>}


                                            {videoUrl && <div className='flex items-end gap-x-1 relative '>
                                                {!uploading ? <> <button
                                                    type='button'
                                                    className='py-[3px] px-2.5 text-white text-xs font-medium border hover:opacity- border-primary bg-primary rounded-md  tracking-[0.5px] inline-flex items-center' onClick={() => {
                                                        document.getElementById('upload-video').click();
                                                    }}
                                                >
                                                    {/* <Plus /> */}
                                                    Replace</button>
                                                    <button
                                                        type='button'
                                                        className='trashbtn w-8 h-8 bg-white border border-black200 rounded-md inline-flex items-center justify-center'
                                                        onClick={() => {
                                                            setVideoUrl('');
                                                            setVideoName('');
                                                        }}
                                                    >
                                                        <TrashIcon />
                                                    </button> </> : ''
                                                    // <Sppiner />
                                                }
                                            </div>
                                            }
                                        </div>
                                        {videoFileError && <div className='text-red mt-2 text-xs font-medium'>{videoFileError}</div>}
                                    </div>
                                    <div className='xl:inline-block hidden'></div>
                                    <div className='upload md:col-span-1 col-span-2'>
                                        <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                            <label>Event Card Thumbnail<sup className='text-red top-[-2px]'> *</sup>
                                                {/* <InfograyIcon data-tooltip-id="infoone-tooltip" data-tooltip-variant='light' className='sm:inline hidden ml-1' />
                                                <Tooltip id="infoone-tooltip" className='bg-white border border-grayLight lighttooltp' place="top" >
                                                    <div className='inline-flex gap-2  max-w-[246px]'>
                                                        <div className='w-5 basis-5 mt-[3px]' ><InfoblueIcon /></div>
                                                        <div className='text-xs text-black700 whitespace-break-spaces font-normal'>This will be the creative that will be featured on the event card</div>
                                                    </div>
                                                </Tooltip> */}
                                            </label>
                                            <label className='black700 font-normal opacity-70 text-xs'>800X700, Max: 2MB</label>
                                        </div>
                                        <div className={`imageuploader border  ${(thumbnailFileError || isThumbnailMissing) ? 'border-red' : ' border-solid'} bg-black100 w-100 h-[154px] rounded-lg flex items-center justify-center relative`}>
                                            <figure className='absolute w-full h-full left-0 right-0 bottom-0 top-0 -z-0 overflow-hidden rounded-lg flex m-auto'><img src={thumbnailURL} alt="" className='m-auto' />
                                                {thumbnailURL && <div className='layer'></div>}
                                            </figure>
                                            <input type="file" className='hidden' id='upload-thumbnail' accept='image/*' onChange={handleOnChange} />
                                            {!thumbnailURL && <button
                                                type='button'
                                                className='py-1 px-2.5 text-primary text-xs hover:bg-primary hover:text-white	font-medium	border border-grayLight bg-white rounded-md tracking-[0.5px] relative'
                                                onClick={() => {
                                                    document.getElementById('upload-thumbnail').click()
                                                }}
                                            >
                                                + Upload
                                            </button>}
                                            {/* <button></button> */}
                                            {thumbnailURL && <div className='flex items-end gap-x-1 relative opacity-0 rplsbtngrouup'>
                                                <button
                                                    type='button'
                                                    className='py-[3px] px-2.5 text-white text-xs font-medium border hover:opacity- border-primary bg-primary rounded-md  tracking-[0.5px] inline-flex items-center'
                                                    onClick={() => {
                                                        document.getElementById('upload-thumbnail').click()
                                                    }}
                                                >
                                                    {/* <Plus /> */}
                                                    Replace</button>
                                                <button
                                                    type='button'
                                                    className='trashbtn w-8 h-8 bg-white border border-black200 rounded-md inline-flex items-center justify-center'
                                                    onClick={() => setThumbnailURL('')}
                                                >
                                                    <TrashIcon />
                                                </button>
                                            </div>}
                                        </div>
                                        {thumbnailFileError && <div className='text-red mt-2 text-xs font-medium'>{thumbnailFileError}</div>}
                                        {isThumbnailMissing && <div className='text-red mt-2 text-xs font-medium'>Please upload Thumbnail</div>}
                                    </div>
                                    <div className='upload md:col-span-1 col-span-2'>
                                        <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                            <label>
                                                Event Banner (Launch Day)<sup className='text-red top-[-2px]'> *</sup>
                                                {/* <InfograyIcon data-tooltip-id="info-tooltip" data-tooltip-variant='light' className='sm:inline hidden ml-1' />
                                                <Tooltip id="info-tooltip" className='bg-white border border-grayLight lighttooltp' place="top" >
                                                    <div className='inline-flex gap-2  max-w-[246px]'>
                                                        <div className='w-5 basis-5 mt-[3px]' ><InfoblueIcon /></div>
                                                        <div className='text-xs text-black700 whitespace-break-spaces font-normal'>On the day of the event, your event will be prominently featured at the top of the page in a carousel.</div>
                                                    </div>
                                                </Tooltip> */}
                                            </label>
                                            <label className='black700 font-normal opacity-70 text-xs'>1800 X 800px , 2MB</label>
                                        </div>
                                        <div className={`imageuploader border  ${(bannerFileError || isBannerMissing) ? 'border-red' : ' border-solid'} bg-black100 w-100 h-[154px] rounded-lg flex items-center justify-center relative`}>
                                            <figure className='absolute w-full h-full left-0 right-0 bottom-0 top-0 -z-0 overflow-hidden rounded-lg flex m-auto'><img src={bannerUrl} alt="" className='m-auto' />
                                                {bannerUrl && <div className='layer'></div>}
                                            </figure>
                                            <input type="file" className='hidden' id='upload-banner' accept='image/*' onChange={handleBannerOnChange} />
                                            {!bannerUrl && <button
                                                type='button'
                                                className='py-1 px-2.5 text-primary text-xs hover:bg-primary hover:text-white font-medium	border border-grayLight bg-white rounded-md tracking-[0.5px] relative'
                                                onClick={() => {
                                                    document.getElementById('upload-banner').click()
                                                }}
                                            >
                                                + Upload
                                            </button>}
                                            {/* <button></button> */}
                                            {bannerUrl && <div className='flex items-end gap-x-1 relative opacity-0 rplsbtngrouup'>
                                                <button
                                                    type='button'
                                                    className='py-[3px] px-2.5 text-white text-xs font-medium border hover:opacity- border-primary bg-primary rounded-md  tracking-[0.5px] inline-flex items-center'
                                                    onClick={() => {
                                                        document.getElementById('upload-banner').click()
                                                    }}
                                                >
                                                    {/* <Plus /> */}
                                                    Replace</button>
                                                <button
                                                    type='button'
                                                    className='trashbtn w-8 h-8 bg-white border border-black200 rounded-md inline-flex items-center justify-center'
                                                    onClick={() => setBannerUrl('')}
                                                >
                                                    <TrashIcon />
                                                </button>
                                            </div>}
                                        </div>
                                        {bannerFileError && <div className='text-red mt-2 text-xs font-medium'>{bannerFileError}</div>}
                                        {isBannerMissing && <div className='text-red mt-2 text-xs font-medium'>Please upload Banner</div>}
                                    </div>

                                    <div className={`${errors?.eventType?.message ? 'invalid' : ''}`}>
                                        <Controller
                                            control={control}
                                            name='eventType'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <InputSelect
                                                    label='Event Type'
                                                    placeholder='Choose Event Type'
                                                    onChange={onChange}
                                                    value={value}
                                                    options={EVENT_TYPE}
                                                    error={errors?.eventType?.message ? 'Please select Event Type.' : ''}
                                                />
                                            )}

                                        />
                                    </div>

                                    {eventType?.value === 'online' && <div className=''>
                                        <label className='block text-black700 text-sm mb-2 font-medium'>How to join Online Event?</label>
                                        <div className='border border-black200 p-4 rounded-lg'>
                                            <figure className='mb-2'>
                                                {/* <img src={JoinzoomIcon} alt="" className='w-full' /> */}
                                            </figure>
                                            <p className='text-xs text-black700 leading-4'>For online event, zoom link will be availabel 2 hours before start time. Simply go to events section of your dashboard & click <span className='font-medium'>"Join Zoom"</span> button.</p>
                                        </div>
                                    </div>}
                                    {eventType?.value === 'offline' &&
                                        <>
                                            {/* <div className=''>
                                                <Controller
                                                    control={control}
                                                    name='foodType'
                                                    render={({
                                                        field: { onChange, onBlur, value, ref },
                                                    }) => (
                                                        <SelectField
                                                            label='Food Type'
                                                            placeholder='Food & Drinks'
                                                            onChange={onChange}
                                                            value={value}
                                                            options={FOOD_TYPE}
                                                            error={errors?.foodType?.message ? 'Please select Food Type' : ''}
                                                        />
                                                    )}
                                                    rules={{ required: eventType?.value === 'offline' }}
                                                    shouldUnregister={eventType?.value === 'online'}
                                                />
                                            </div> */}
                                            <div className='col-span-2'>
                                                <Controller
                                                    control={control}
                                                    name='address'
                                                    render={({
                                                        field: { onChange, onBlur, value, ref },
                                                    }) => (
                                                        <>
                                                            <div className='flex justify-between text-black700 text-sm mb-2 font-medium'>
                                                                <label>Event Location<sup className='text-red top-[-2px]'> *</sup></label>
                                                                {address && <label className='black700 text-xs font-normal opacity-70'>{`Characters left: ${address.length}/62`}</label>}
                                                            </div>
                                                            <InputText
                                                                name='address'
                                                                placeholder='Hotel Mayura, RK Nagar, Pune'
                                                                type='text'
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputref={`${ref}`}
                                                                required={true}
                                                                maxLength={'62'}
                                                                errorMessage={errors?.address?.message}
                                                            />
                                                        </>
                                                    )}
                                                    rules={{ required: eventType?.value === 'offline' }}
                                                />
                                            </div> </>}
                                    <div className='h-14 col-span-2 md:d-block hidden'></div>

                                </form>
                                <div className='flex justify-end items-center !pt-5 !pb-5 border-t grayLight gap-4 sm:px-0 !px-5' >
                                    <button onClick={handleSubmit(onFormSubmit)} data-tooltip-id="save-tooltip" data-tooltip-variant='light'
                                        className='border border-grayLight rounded-lg text-black text-sm font-medium sm:!py-2 !py-3 sm:!px-6 !px-10 inline-flex items-center gap-1 bg-white hover:bg-black100' >
                                        Save
                                    </button>
                                    {/* <Tooltip id="save-tooltip" className='bg-white border border-grayLight lighttooltp' place="bottom" >
                                        <div className='inline-flex gap-2  max-w-[246px]'>
                                            {/* <div className='w-5 basis-5 mt-[3px]' ><InfoblueIcon /></div>
                                            <div className='text-xs text-black700 whitespace-break-spaces font-normal'>Your project has been saved. Submit for approval to publish.</div>
                                        </div>
                                    </Tooltip> */}
                                    <button
                                        form='event-create'
                                        disabled={isSubmitting}
                                        className=' leading-[22px] font-medium sm:!px-6 !px-8 sm:!py-2 !py-3 bg-primary text-sm text-white rounded-lg '
                                        type='submit'
                                        data-tooltip-id="submit-tooltip"
                                        data-tooltip-variant='light'
                                        onClick={handleSubmit(onFormSubmitForReview)}
                                    >

                                        Submit For Review
                                    </button>
                                    {/* <Tooltip id="submit-tooltip" className='bg-white border border-grayLight lighttooltp' place="bottom" >
                                        <div className='inline-flex gap-2  max-w-[246px]'>
                                            {/* <div className='w-5 basis-5 mt-[3px]'><InfoblueIcon /></div>
                                            <div className='text-xs text-black700 whitespace-break-spaces font-normal'>Once approved by Propacity, your event will be live on Launchpad shortly</div>
                                        </div>
                                    </Tooltip> */}

                                </div>
                            </div>
                            <div className='lg:w-2/5 w-full md:!pl-10 !pl-5 md:!pr-5 !pr-5 md:mt-0'>
                                <div className='md:sticky top-20'>
                                    <p className='block text-black text-sm mb-2 font-normal'>Event Card Preview</p>
                                    <div className='sm:w-[320px]  w-full'>
                                        <Event
                                            name={name}
                                            description={description}
                                            propertyType={propertyType}
                                            event={eventType?.value}
                                            date={date}
                                            startTime={startTime}
                                            endTime={endTime}
                                            thumbnail={thumbnailURL}
                                            uuid={uuid}
                                            address={address}
                                            city={city}
                                        />
                                        {bannerUrl && <div className='mt-5' >
                                            <p className='text-textblack text-sm '>Event Banner Preview (Launch Day) </p>
                                            <div style={bgimage}>
                                                <figure className='mt-2'>
                                                    {/* <img src={overlayImg} alt="" /> */}
                                                </figure>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                </div >
            }
            {!createEventForm && progress && !isPublished && <EventProgess eventPublish={publishEvent} />}
            {
                !createEventForm && !progress && isPublished &&
                <EventPublished
                    name={name}
                    description={description}
                    developerName={developerName}
                    propertyType={propertyType}
                    address={address}
                    food={food?.value}
                    event={eventType?.value}
                    date={date}
                    startTime={startTime}
                    endTime={endTime}
                    thumbnail={thumbnailURL}
                    banner={bannerUrl}
                    uuid={uuid}
                />
            }

            {/* <RefreshModal show={isExitModalOpen} closeModal={closeExitModal} /> */}
        </>
    )
}

export default CreateEvent