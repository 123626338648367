import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { getMasterEnumsCall } from '../../../../services/private/company.service';
import { getActivityStatus } from '../../../../services/private/leads.service';
import DetailSidebar from '../../../Lead Details/components/Detail Sidebar/DetailSidebar'

export default function LeadDetailBodyRight({ leadInfoData, leadAccess }) {
    const params = useParams();

    const [notesCount, setNotesCount] = useState(0);
    const [followupsCount, setFollowUpsCount] = useState(0);
    const [siteVisitsCount, setSiteVisitCount] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const [intentOptions, setIntentOptions] = useState([])
    const [statusOptions, setStatusOptions] = useState([])

    const getAllCountsForDetails = () => {
        getActivityStatus(params.id).then(res => {
            if (res.status === 200) {
                setNotesCount(Number(res.data.data.notesCount))
                setFollowUpsCount(Number(res.data.data.followupsCount))
                setSiteVisitCount(Number(res.data.data.siteVisitsCount))
            }
        })
    }

    const getStatusandIntent = async () => {
        await getMasterEnumsCall().then(res => {
            if (res.status === 200) {
                const intents = res.data.data.intents.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.display_name
                    }
                })
                setIntentOptions(intents)
                const statuses = res.data.data.statuses.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.display_name
                    }
                })

                setStatusOptions(statuses)
            }
        })
    }

    useEffect(() => {
        getStatusandIntent()
    }, [])

    useEffect(() => {
        getAllCountsForDetails();
    }, [reloadData])


    return (
        <>
            <DetailSidebar
                notesCount={notesCount}
                followupsCount={followupsCount}
                siteVisitsCount={siteVisitsCount}
                reloadSideBarCount={() => setReloadData(!reloadData)}
                intentOptions={intentOptions}
                leadInfoData={leadInfoData}
                statusOptions={statusOptions}
                leadAccess={leadAccess}
            />
        </>
    )
}
