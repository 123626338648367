import React, { useEffect, useState } from 'react'
import RightModal from '../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import InputText from '../../../components/InputGroup/InputText';
import TextEditor from '../Editor';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import { insertProjectReviews, updateProjectReviews, uploadFile } from '../../../services/public/public.service';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { toast } from 'react-toastify';

const ReviewModal = ({ show, closeModal, style, projectUuid, getReviews, selectedReview }) => {
    const [review, setReview] = useState(selectedReview || {});

    useEffect(() => {
        if (selectedReview?.uuid) {
            setReview(selectedReview);
        }
    }, [selectedReview]);

    const handleSubmit = async () => {
        const res = await insertProjectReviews(projectUuid, review);
        if ((res.data.status === 200)) {
            console.log(res.data.data);
            toast.success(res.data.message);
            getReviews();
        }
        // setAllReviews(review)
        closeModal();
    }

    const handleUpdate = async () => {
        const res = await updateProjectReviews(selectedReview?.uuid, review);
        if ((res.data.status === 200)) {
            toast.success(res.data.message);
            getReviews();
            closeModal();
        }
    }

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];

        // console.log(file)
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);
        // console.log({ response })
        if (response.data.status === 200) {
            let newReviews = { ...review };
            newReviews['reviewer_image'] = response.data.data.s3_url
            setReview(newReviews);
        }
    }

    return (
        <RightModal
            show={show}
            closeModal={() => { closeModal(); }}
            modalClass={`configurationModal`}
        >

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Reviews</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>

            <div className={`${style.modalForm} d-flex h-100 overflow-auto flex-column px-20`}>

                {/* {
                    reviews.map((review, index) => ( */}
                <div className=''>
                    <div className='!mb-3 flex justify-between'>
                        {/* <h5 className="text-textblack text-sm leading-6 font-semibold tracking-[-0.2px]" >
                            Review
                        </h5> */}
                        {/* {reviews.length > 1 &&
                                    <button className='border-0 bg-tr ' onClick={() => {
                                        const newReviews = [...reviews];
                                        newReviews.splice(index, 1)
                                        setReviews(newReviews);
                                    }}
                                    >
                                        <CloseIcon />
                                    </button>} */}
                    </div>
                    <div className='flex flex-col gap-y-4'>
                        <InputText
                            label='Rating'
                            type={'number'}
                            placeholder={'Enter Rating'}
                            value={review?.rating}
                            onChange={(e) => {
                                let newReviews = { ...review };
                                newReviews['rating'] = e.target.value;
                                setReview(newReviews);
                            }}
                        />
                        <InputText
                            label="Title"
                            placeholder="Enter title"
                            type="text"
                            value={review?.review_title}
                            inputclass='m-0 inter'
                            onChange={(e) => {
                                let newReviews = { ...review };
                                newReviews['review_title'] = e.target.value;
                                setReview(newReviews);
                            }}
                        />
                        <div className=''>
                            <label htmlFor="">Review Description</label>
                            <TextEditor
                                data={review.review}
                                onChange={(value) => {
                                    let newReviews = { ...review };
                                    newReviews['review'] = value;
                                    setReview(newReviews);
                                }} />
                        </div>
                        <InputText
                            label="Reviewer Name"
                            placeholder="Enter reviewer name"
                            type="text"
                            value={review?.user_name}
                            inputclass='m-0 inter'
                            onChange={(e) => {
                                let newReviews = { ...review };
                                newReviews['user_name'] = e.target.value;
                                setReview(newReviews);
                            }}
                        />
                        <DateTimePicker
                            label='Reviewer Post Date'
                            inputclass='mt-0'
                            placeholder='Select Date'
                            value={review?.date_posted}
                            onValueChange={(date) => {
                                let newReviews = { ...review };
                                newReviews['date_posted'] = date;
                                setReview(newReviews);
                            }}
                            showTime={false}
                        />
                        <div>
                            <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                <span>Reviewer Image</span>
                                <span>PNG, 2MB</span>
                            </div>
                            <div className={`${style.upload}`}>
                                {review.reviewer_image && <img src={review.reviewer_image} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                <input type="file" className='d-none' name='developer-logo' id='reviewer-img' accept='image/*' onChange={(e) => handleOnChangeImage(e)} />
                                {!review.reviewer_image && <div className={`${style.uplaodbtngrp}`}>
                                    <button
                                        type='button'
                                        className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('reviewer-img').click()
                                        }}
                                    ><PluswhiteIcon />Upload</button>
                                </div>}
                                {review.reviewer_image && <div className={`${style.hoverbox}`}>
                                    <button
                                        type='button'
                                        className={`${style.replacebtn} pr-btn inter flex items-center`}
                                        onClick={() => {
                                            document.getElementById('reviewer-img').click()
                                        }}
                                    ><PluswhiteIcon />Replace</button>
                                    <button
                                        type='button'
                                        className={`${style.delbtn} inter border-0 bg-white`}
                                        onClick={() => {
                                            let newReviews = { ...review };
                                            newReviews['reviewer_image'] = '';
                                            setReview(newReviews);
                                        }}
                                    ><TrashIcon /></button>
                                </div>}
                            </div>
                            {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                        </div>
                        <InputText
                            label="Reviewer Image Alt Text"
                            placeholder="Enter Reviewer Image Alt Text"
                            type="text"
                            value={review?.reviewer_image_alt_text}
                            inputclass='m-0 inter'
                            onChange={(e) => {
                                let newReviews = { ...review };
                                newReviews['reviewer_image_alt_text'] = e.target.value;
                                setReview(newReviews);
                            }}
                        />
                    </div>

                </div>
                {/* ))
                } */}
                {/* <div className="!mt-3 flex items-center justify-end">
                    <button
                        type='button'
                        className=' !text-white p-2 text-sm font-medium inter bg-primary rounded-lg gap-2 inter'
                        onClick={() => {
                            setReviews([...reviews, {}])
                        }}
                    >
                        + Add Reviews</button>
                </div> */}
            </div>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button type='button'
                    onClick={() => {
                        if (selectedReview?.uuid) {
                            handleUpdate()
                        } else {
                            handleSubmit()
                        }
                    }} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal>
    )
}

export default ReviewModal;