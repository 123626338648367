import React from 'react';
import { ReactComponent as RupayIcon } from '../../assets/icons/rupay.svg';

export default function InputText({
    value,
    onChange,
    handleBlur,
    index,
    error,
    touched,
    type,
    placeholder,
    name,
    label,
    max,
    disable,
    required,
    onFocus,
    autoFocus,
    inputclass,
    rupayclass,
    id = '',
    readOnly = false,
    showRupees = false,
}) {

    return (
        <div key={index || 0} className={`form-group ${inputclass} ${error ? 'invalid' : ''}  position-relative`}>
            {label && <label>{label}{required && <sup>*</sup>}</label>}
            <input
                value={value ? value : ''}
                id={id}
                onChange={onChange}
                onBlur={handleBlur ? handleBlur : () => { }}
                className={`${error ? 'form-control text-input error' : 'form-control text-input  !pr-4'} ${disable ? 'cursor-not-allowed bg-[#f2f2f2]' : ''} ${showRupees ? '!pl-8' : '!pl-4'}`}
                type={type || "text"}
                placeholder={placeholder}
                name={name}
                max={max}
                disabled={disable && disable}
                autoComplete="off"
                onFocus={onFocus}
                autoFocus={autoFocus}
            // readOnly={readOnly}
            />
            {showRupees && <div className={`${rupayclass ? rupayclass : 'top-[45px] left-3'} absolute `}><RupayIcon /></div>
            }
            {error && (
                <>
                    <span className="invalid-icon">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#FC5A5A" />
                            <path d="M7.00195 11C7.00195 10.8687 7.02782 10.7386 7.07807 10.6173C7.12833 10.496 7.20199 10.3857 7.29485 10.2929C7.38771 10.2 7.49794 10.1264 7.61927 10.0761C7.7406 10.0259 7.87063 10 8.00195 10C8.13328 10 8.26331 10.0259 8.38464 10.0761C8.50596 10.1264 8.6162 10.2 8.70906 10.2929C8.80192 10.3857 8.87558 10.496 8.92583 10.6173C8.97609 10.7386 9.00195 10.8687 9.00195 11C9.00195 11.2652 8.8966 11.5196 8.70906 11.7071C8.52152 11.8946 8.26717 12 8.00195 12C7.73674 12 7.48238 11.8946 7.29485 11.7071C7.10731 11.5196 7.00195 11.2652 7.00195 11ZM7.09995 4.995C7.08664 4.86884 7.09999 4.74129 7.13915 4.62062C7.17831 4.49996 7.24241 4.38888 7.32727 4.29458C7.41214 4.20029 7.51588 4.12489 7.63176 4.07328C7.74765 4.02167 7.87309 3.995 7.99995 3.995C8.12681 3.995 8.25226 4.02167 8.36814 4.07328C8.48403 4.12489 8.58777 4.20029 8.67263 4.29458C8.7575 4.38888 8.82159 4.49996 8.86075 4.62062C8.89991 4.74129 8.91327 4.86884 8.89995 4.995L8.54995 8.502C8.53819 8.63977 8.47516 8.76811 8.37331 8.86163C8.27146 8.95515 8.13823 9.00705 7.99995 9.00705C7.86168 9.00705 7.72844 8.95515 7.6266 8.86163C7.52475 8.76811 7.46171 8.63977 7.44995 8.502L7.09995 4.995Z" fill="#FC5A5A" />
                        </svg> */}
                    </span>
                    <div className="input-feedback mt-8">{error}
                    </div>
                </>

            )}

        </div>
    )
}