import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { toast } from 'react-toastify';
import InputText from "../../../components/InputGroup/InputText";
import InputSelect from "../../../components/InputGroup/InputSelect";
import {
  getCompanyDetails,
  getUserProfile,
  updateCompanyInfo,
} from "../../../services/private/company.service";
import { getStates, getCitiesFromState, getAllReraDocuments, uploadFile } from "../../../services/public/public.service";
import { companyInfoSchema } from '../../../validators/company.validator';
import MobileInput from "../../../components/InputGroup/MobileInput";
import { ReactComponent as PDFlogoIcon } from '../../../assets/pdf.svg';
import { ReactComponent as BlueEyeIcon } from '../../../assets/blueeye.svg';
import { ReactComponent as WhitePlushIcon } from '../../../assets/whitePlush.svg';
import PdfViewModal from "../../../components/Modals/PdfViewModal";
import { Modal } from "react-bootstrap";
import Consumer from '../../../helpers/context';
import style from '../../../modules/Settings/Projects/style.module.css'
import '../../../modules/Settings/Projects/style.css';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as Pluswhite } from '../../../assets/icons/Pluswhite.svg';
import { Oval } from "react-loader-spinner";

const getNameFromURL = (item) => {
  const decodedUrl = decodeURIComponent(item);
  return decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);
}

const getFileExtension = (fileName) => {
  return fileName.split('.').pop();
}

const CompanyInfo = () => {
  const [companyInfo, setCompanyInfo] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [reraDocuments, setReraDocuments] = useState([]);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfItem, setPdfItem] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [fileSizeError, setFileSizeError] = useState('');
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [logo, setLogo] = useState('');
  const [loading, setLoading] = useState(false);

  const { userProfile } = useContext(Consumer);

  const updateCompanyInfoHandler = async (updatedCompanyInfo) => {
    setLoading(true);
    await updateCompanyInfo({ ...updatedCompanyInfo, rera_documents: reraDocuments, logo })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Company info updated");
          setLoading(false);
          window.location.reload(false);
        }
      })
      .catch((e) => {
        console.log(e?.response, 'erroro')
        toast.error(e?.response?.data?.message);
        setLoading(false)
      });
  };

  const getReraDocuments = async () => {
    const res = await getAllReraDocuments();
    if (res.status === 200) {
      setReraDocuments(res.data.data);
    }
  }

  const getAllStates = async () => {
    getStates().then((res) => {
      if (res.status === 200) {
        const states = res.data.data.map((data) => {
          return {
            label: data.name,
            value: data.name,
          };
        });
        setStates(states);
      }
    });
  };

  const getAllCitiesByState = async (state) => {
    getCitiesFromState(state).then((res) => {
      if (res.status === 200) {
        const cities = res.data.data.map((data) => {
          return {
            label: data.name,
            value: data.name,
          };
        });
        setCities(cities);
      }
    });
  };

  const getCompanyInfo = async () => {
    await getCompanyDetails().then((res) => {
      if (res.status === 200) {
        const { data: { data } } = res;
        setCompanyInfo({
          ...data,
          city: { label: data.city, value: data.city },
          state: { label: data.state, value: data.state }
        })
        // setLogo(data.logo)
        getAllCitiesByState(data.state)
      }
    });
  };

  const getUserProfileData = async () => {
    const res = await getUserProfile();
    if (res.data.status === 200) {
      setLogo(res.data.data.company.logo)
    }
  }
  useEffect(() => {
    getCompanyInfo();
    getAllStates();
    getUserProfileData();
  }, []);

  useEffect(() => {
    if (userProfile.is_owner) {
      getReraDocuments();
    }
  }, [userProfile])

  const handleShowPDF = (item) => {
    setPdfItem(item);
    setShowPDF(true);
  };

  const handleClosePDF = () => {
    setPdfItem();
    setShowPDF(false);
  }

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size / 1048576 > 5) {
      setFileSizeError('Size exceeds 5mb');
      return;
    }

    const formData = new FormData();
    formData.append("file", file)
    const res = await uploadFile(formData);
    if (res.status === 200) {
      let reraDocs = [...reraDocuments];
      reraDocs[selectedIndex].rera_document = res.data.data.s3_url;
      setReraDocuments(reraDocs);
    }
  }

  const handleChangeReraState = (value, index) => {
    let reraDocs = [...reraDocuments];
    reraDocs[index].state = value.value;
    setReraDocuments(reraDocs);
  }

  const handleChangeReraNumber = (value, index) => {
    let reraDocs = [...reraDocuments];
    reraDocs[index].rera = value;
    setReraDocuments(reraDocs);
  }

  const handleShowImage = (item) => {
    setPdfItem(item);
    setShowImagePreview(true);
  }

  const closeImagePreview = () => {
    setPdfItem();
    setShowImagePreview(false);
  }

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file)
    const response = await uploadFile(formData);
    if (response.status === 200) {
      setLogo(response.data.data.s3_url)
    }
  }

  const handleOnChange = async (e) => {
    const files = [...e.target.files];
    uploadFileToS3(files[0]);
  }

  return (
    <div className="w-100 ">
      <div className="row">
        <div className="col-md-12">
          <div className="settings-title mb-16">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h1 className="fz24 mb-0 black fw-po-medium">Company Info</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company-info ml-24 mt-24">
        <div className="row">
          <div className="col-xl-8">

            <Formik
              enableReinitialize
              initialValues={companyInfo}
              onSubmit={async (values) => {
                updateCompanyInfoHandler({ ...values, city: values.city.value, state: values.state.value });
              }}
              validationSchema={companyInfoSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {(props) => {
                const {
                  isValid,
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-16">
                      <div className="col-md-6">
                        <InputText
                          value={values["name"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["name"]}
                          touched={touched["name"]}
                          type="text"
                          placeholder="Company Name"
                          name="name"
                          label={
                            <>
                              Company Name
                            </>
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputText
                          value={values["website_url"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["website_url"]}
                          touched={touched["website_url"]}
                          type="text"
                          placeholder="Company Url"
                          name="website_url"
                          label={
                            <>
                              Company Website URL (optional)
                            </>
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-16">
                      {/* <div className="col-md-6">
                      <InputText
                        value={values["logo"]}
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        index={0}
                        error={errors["logo"]}
                        touched={touched["logo"]}
                        type="text"
                        placeholder="Logo"
                        name="logo"
                        label={"Company Logo"}
                      />
                    </div> */}
                      <div className="col-md-6">
                        <InputText
                          value={values["owner_name"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["owner_name"]}
                          touched={touched["owner_name"]}
                          type="text"
                          placeholder="Owner Name"
                          name="owner_name"
                          label={
                            <>
                              Owner Name
                            </>
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <MobileInput
                          value={values["owner_mobile"]}
                          onChange={({ phone }) => {
                            const value = {
                              target: {
                                name: "owner_mobile", value: phone
                              }
                            }

                            handleChange(value)
                          }}
                          index={0}
                          error={errors["owner_mobile"]}
                          touched={touched["owner_mobile"]}
                          type="text"
                          // countryCode='+91'
                          placeholder="Owner Mobile"
                          name="owner_mobile"
                          label='Owner Mobile'
                        />
                        {/* <InputText
                        value={values["owner_mobile"]}
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        index={0}
                        error={errors["owner_mobile"]}
                        touched={touched["owner_mobile"]}
                        type="text"
                        placeholder="Owner Mobile"
                        name="owner_mobile"
                        label={
                          <>
                            Owner Mobile
                          </>
                        }
                      /> */}
                      </div>
                    </div>
                    <div className="row mb-16">

                      <div className="col-md-6">
                        <InputText
                          value={values["owner_email"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["owner_email"]}
                          touched={touched["owner_email"]}
                          type="text"
                          placeholder="Owner Email"
                          name="owner_email"
                          label={
                            <>
                              Owner Email
                            </>
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputText
                          value={values["gst"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["gst"]}
                          touched={touched["gst"]}
                          type="text"
                          placeholder="e.g. 24AAACC4175D1Z4"
                          name="gst"
                          label={
                            <>
                              GST Number  (optional)
                            </>
                          }
                        />
                      </div>

                    </div>
                    <div className="row mb-16">
                      <div className="col-md-6">
                        <InputText
                          value={values["address_1"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["address_1"]}
                          touched={touched["address_1"]}
                          type="text"
                          placeholder="Address"
                          name="address_1"
                          label={
                            <>
                              Address 1 (optional)
                            </>
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputText
                          value={values["address_2"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["address_2"]}
                          touched={touched["address_2"]}
                          type="text"
                          placeholder="Address 2"
                          name="address_2"
                          label={
                            <>
                              Address 2 (optional)
                            </>
                          }
                        />
                      </div>
                    </div>

                    <div className="row mb-16">
                      <div className="col-md-6">
                        <InputText
                          value={values["zip_code"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["zip_code"]}
                          touched={touched["zip_code"]}
                          type="text"
                          placeholder="Zip Code"
                          name="zip_code"
                          label={
                            <>
                              Pincode (optional)
                            </>
                          }
                        />

                      </div>
                      <div className="col-md-6">
                        <InputText
                          value={values["country"]}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          index={0}
                          error={errors["country"]}
                          touched={touched["country"]}
                          type="text"
                          placeholder="Country"
                          name="country"
                          label={
                            <>
                              Country
                            </>
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-16">
                      <div className="col-md-6">
                        <InputSelect
                          index={4}
                          name="state"
                          id="state"
                          menuPlacement="top"
                          error={errors["state"]}
                          touched={touched["state"]}
                          value={values["state"]}
                          options={states}
                          placeholder="Enter State"
                          onChange={(e) => {
                            const value = { target: { name: "state", value: e } };
                            handleChange(value);
                            getAllCitiesByState(e.value);
                            setFieldValue("city", null)
                          }}
                          label={
                            <>
                              State
                            </>
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputSelect
                          index={4}
                          name="city"
                          id="city"
                          menuPlacement="top"
                          error={errors["city"]}
                          touched={touched["city"]}
                          value={values["city"]}
                          options={cities}
                          placeholder="Enter City"
                          onChange={(e) => {
                            const value = { target: { name: "city", value: e } };
                            handleChange(value);
                          }}
                          label={
                            <>
                              City (optional)
                            </>
                          }
                        />
                      </div>

                    </div>
                    <div className="col-md-6 !mb-4">
                      <label className='text-sm text-black700 !mb-2 inter'>Company Logo</label>
                      {!logo && <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden cursor-pointer'>
                        <input type="file" className='d-none' id={`upload-company-logo`} accept="image/*" onChange={(e) => handleOnChange(e)} />
                        <button
                          type='button'
                          className='!bg-primary w-auto flex items-center justify-center py-1 text-white gap-1 rounded-lg text-sm font-medium'
                          onClick={() => {
                            document.getElementById(`upload-company-logo`).click();
                          }}
                        >
                          <Pluswhite /> Upload
                        </button>
                      </div>}
                      {logo && <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                        <input type="file" className='d-none' id={`replace-logo`} accept="image/*" onChange={(e) => handleOnChange(e)} />
                        <figure className='mb-0 !table !mx-auto relative group'>

                          <img src={logo} alt="" className='h-full w-full' />

                          <div className={`${style.hoverbox} hoverpdf group-hover:!opacity-100 rounded-lg`}>
                            <button
                              type='button'
                              className={`${style.replacebtn} pr-btn`}
                              onClick={() => {
                                console.log(document.getElementById('replace-logo'), '')
                                document.getElementById('replace-logo').click();
                              }}
                            ><PluswhiteIcon />Replace</button>

                            <button
                              className={`${style.delbtn} inter border-0 bg-white`}
                              onClick={() => {
                                setLogo('')

                              }}
                            ><TrashIcon /></button>
                          </div>
                        </figure>
                      </div>}
                    </div>

                    {reraDocuments.length > 0 && <div className="row mb-16 mt-40">
                      <div className="col-12">
                        <h2 className="block border-b">RERA info</h2>
                      </div>

                    </div>}
                    {
                      reraDocuments.length > 0 && reraDocuments.map((item, index) => (
                        <React.Fragment key={`${item.uuid}_${index}`}>
                          <div className="row mb-16">
                            <div className="col-md-6 mb-16">
                              <InputSelect
                                value={{ label: item.state, value: item.state }}
                                options={states}
                                index={0}
                                onChange={(value) => {
                                  handleChangeReraState(value, index)
                                }}
                                type="text"
                                placeholder="State"
                                name="State"
                                label={
                                  <>
                                    State
                                  </>
                                }
                              />
                            </div>
                            <div className="col-md-6 mb-16">
                              <InputText
                                value={item.rera}
                                index={0}
                                type="text"
                                placeholder="Rera number"
                                onChange={(e) => {
                                  handleChangeReraNumber(e.target.value, index);
                                }}
                                name="rera"
                                label={
                                  'RERA Number'
                                }
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="file"
                                  className='d-none'
                                  id='upload-rera'
                                  onChange={handleUpload}
                                />
                                <label className="d-flex justify-content-between"><span>RERA Document </span> <span>PDF, 5 MB</span></label>
                                <div className="uplaodpdf position-relative">
                                  <PDFlogoIcon />
                                  <div className='hoverpdf'>
                                    <button className="outline-btn" type='button' onClick={() => {

                                      const file = {
                                        file_url: item.rera_document,
                                        name: getNameFromURL(item.rera_document)
                                      }
                                      if (getFileExtension(getNameFromURL(item.rera_document)) === 'pdf') {
                                        handleShowPDF(file)
                                      } else {
                                        handleShowImage(file)
                                      }
                                    }}><BlueEyeIcon />View</button>
                                    <button
                                      className="pr-btn"
                                      type='button'
                                      onClick={() => {
                                        setSelectedIndex(index);
                                        document.getElementById('upload-rera').click();
                                      }}
                                    ><WhitePlushIcon />Replace</button>
                                  </div>
                                </div>
                                {(fileSizeError && selectedIndex === index) && <div className='invalid'>
                                  <div className="input-feedback mt-8">{fileSizeError}</div>
                                </div>}
                                <p className="black-dark-700  fz14 fw-po-medium mb-32">
                                  {getNameFromURL(item.rera_document)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="line"></div>
                        </React.Fragment>
                      ))
                    }
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group d-flex flex-row align-items-center">
                          <button type="button" className='px-20 cancel outline-btn mr-24'>Cancel</button>
                          {!loading && <button
                            className="pr-btn px-20"
                            type="submit"
                          // disabled={!isValid}
                          >
                            Save
                          </button>}
                          {loading && <button className="pr-btn px-20 flex items-center justify-center" type='button'>
                            <Oval
                              height={24}
                              width={24}
                              color="#ffffff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel='oval-loading'
                              secondaryColor="#ffffff"
                              strokeWidth={4}
                              strokeWidthSecondary={4}
                            />
                          </button>}
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div className='modal-pdf'>
        <PdfViewModal show={showPDF} handleClose={handleClosePDF} pdfItem={pdfItem} />
      </div>
      {showImagePreview && <Modal size="xl" show={showImagePreview} onHide={closeImagePreview} backdrop="static" fullscreen centered dialogClassName="modal-radius">
        <a onClick={closeImagePreview} className='cursor-pointer close position-absolute video-close'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#ffff"></path></svg></a>
        <img src={pdfItem.file_url} alt="" />
      </Modal>}
    </div>

  );
};

export default CompanyInfo;
