import moment from 'moment';
import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PdfViewModal from '../../Modals/PdfViewModal';

const Guides = ({ guides }) => {
    const [show, setShow] = useState(false);
    const [pdfItem, setPdfItem] = useState();
    const [backdrop, setBackDrop] = useState(false)


    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setPdfItem(item)
        setShow(true)
    };

    return (
        <div className="video-cards">
            <div className="learing-center-grid">
                {
                    guides.map((item, index) => {
                        return <div className="learning-block" onClick={() => handleShow(item)} key={`${item.name}_${index}`} >
                            <div className="card d-flex flex-fill">
                                <figure className='position-relative mb-0' >
                                    <img src={item.thumbnail} />
                                </figure>
                                {item.name.length >
                                    30 ?
                                    <OverlayTrigger className=''
                                        placement="bottom"

                                        overlay={
                                            <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                                <div className='info-msg'>
                                                    {/* <h4>Total Leads</h4> */}
                                                    <p className='d-flex fz18 w-auto'>{item.name}</p>
                                                </div>
                                            </Tooltip>
                                        }
                                    >

                                        <h1 className='fz16 black fw-po-medium mt-12 mb-0'>{item.name}</h1>
                                    </OverlayTrigger>
                                    :
                                    <h1 className='fz16 black fw-po-medium mt-12 mb-0'>{item.name}</h1>
                                }

                                <div className="video-time-date mt-8 d-flex align-items-start worksans fz14 black-dark-800 mt4">
                                    <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg>
                                    <div className="date">{moment(item.created_on).format('Do MMM YYYY')} </div>
                                </div>

                            </div>
                        </div>
                    })
                }
            </div>

            {
                guides.length === 0 && <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '60vh' }} >
                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                    <h2 className="po black fz16 fw-po-medium mb-8"> No Result Found.</h2>
                </div>
            }
            <div className='modal-pdf'>
                <PdfViewModal show={show} handleClose={handleClose} pdfItem={pdfItem} />
            </div>
        </div >
    )
}

export default Guides;