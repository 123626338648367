import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchIcon from '../../../../assets/icons/search.svg'
import { getMyUsers } from '../../../../services/private/company.service';
import { getLeadsFilter } from '../../../../services/private/leads.service';

const AssignedToFilters = ({ handleFilter, leadTopFilters }) => {
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
    const [selectedEmployeeName, setSelectedEmployeeName] = useState([]);
    const [showEmployee, setShowEmployee] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [usersList, setUsersList] = useState([{ label: 'Unassigned', value: null }]);
    const [allUser, setAllUser] = useState([]);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [filters, setFilters] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const employeeRef = useRef();
    const [appliedFilter, setAppliedFilter] = useState(false);

    // Listen to screen resizing to update isMobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleOutsideClick = (e) => {
        if ((employeeRef.current && !employeeRef.current.contains(e.target))) {
            setShowEmployee(false);
            // if (!isFilterApplied) {
            //     setSelectedEmployee([]);
            //     setSelectedEmployeeName([]);
            // }
        }
    }

    const getDefaultUser = async () => {
        const res = await getMyUsers();
        if (res.data.status === 200) {
            const users = res.data.data.map(user => { return { ...user, label: user.name, value: user.name, user_id: user.id } });
            setAllUser([...usersList, ...users]);
            setUsersList([...usersList, ...users]);
        }
    }

    useEffect(() => {
        getDefaultUser()
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, []);

    useEffect(() => {
        if (leadTopFilters.length > 0) {
            let filtersArr = {};
            let newFilters = []
            for (const filterData of leadTopFilters) {
                filtersArr = { ...filtersArr, ...filterData }
                if (!['Assigned To'].includes(Object.keys(filterData)[0])) {
                    newFilters.push(filterData);
                }
            }
            let filterKeys = Object.keys(filtersArr);
            if (filterKeys.includes("Assigned To")) {
                const ids = filtersArr['Assigned To'].map(item => item.id);
                setSelectedEmployee(ids);
                setSelectedEmployeeName(filtersArr['Assigned To'] || []);

                const notFiltered = allUser?.filter(user => !ids.includes(user.id))
                setUsersList(notFiltered);
                setAppliedFilter(true);
            }

            setFilters(newFilters)
        }
    }, [leadTopFilters, allUser])

    const handleSelectAllEmployee = useCallback(() => {
        const userSelection = allUser.map(item => item.id);
        setSelectedEmployeeName([...allUser])
        setSelectedEmployee(userSelection);
        setUsersList([])
    }, [allUser]);

    const handleClearEmployeeSearch = useCallback(() => {
        setSearchEmployee('');
        let result = allUser.filter(itemB =>
            !selectedEmployeeName.some(itemA => itemA.user_id === itemB.user_id)
        );
        setUsersList(result)
        // getAllRecords();
    }, [allUser]);

    const handleEmployeeSearch = async (e) => {
        setSearchEmployee(e.target.value)
        // const employees = allUser.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);

        const res = await getLeadsFilter({ filterType: 'Assign To', value: e.target.value });
        if (res.status === 200) {
            let result = res.data.data.filter(itemB =>
                !usersList.some(itemA => itemA.user_id === itemB.user_id)
            );
            const users = result.map(user => { return { ...user, label: user.value, } });
            setUsersList(users)
        }
    };

    const handleChangeEmployee = (e, data) => {
        e.preventDefault();
        if (selectedEmployee.includes(data.id)) {
            const employee = selectedEmployee.filter(item => item != data.id);
            const filteredName = selectedEmployeeName.filter(item => item.label != data.label);
            // const index = usersList.findIndex(item => item.value === data.value)
            setSelectedEmployee(employee);
            setSelectedEmployeeName(filteredName);
            // if (index < 0) {
            //     setUsersList([...usersList, data])
            // }
            setUsersList([...usersList, data])

        } else {
            setSelectedEmployee([...selectedEmployee, data.id])
            setSelectedEmployeeName([...selectedEmployeeName, data])
            const index = usersList.findIndex(list => list.id === data.id);
            usersList.splice(index, 1)
        }
    };


    const handleClearEmployeeSelection = useCallback(() => {
        if (selectedEmployeeName.length > 0) {
            setUsersList([...usersList, ...selectedEmployeeName])
            // setSelectedEmployeeList([])
            setSelectedEmployeeName([]);
        }
        setSelectedEmployee([]);
        // setShowEmployee(false)        
    }, [usersList, selectedEmployeeName]);

    const handleCloseEmployee = useCallback(() => {
        if (selectedEmployee.length > 1) {
            const newItems = [];
            selectedEmployee.forEach(item => {
                const index = usersList.findIndex(list => { return list.id === item });
                if (index >= 0) {
                    newItems.push(usersList[index])
                    usersList.splice(index, 1)
                }
            })
            setSelectedEmployeeList([...selectedEmployeeList, ...newItems])
        } else {
            setUsersList([...allUser])
            setSelectedEmployeeList([])
        }
    }, [selectedEmployee, usersList]);

    return (
        <div className="assigned-drop" ref={employeeRef}>
            <Dropdown align="start" className={"short-btn"} show={showEmployee}>
                {/* Add active class when filtter is applyed */}
                <Dropdown.Toggle variant="short" className={`inter border border-[#CFD5DE] !text-[#344054] !text-sm !font-semibold hover:!bg-white !flex items-center !rounded-3xl ${selectedEmployeeName.length > 0 ? 'active' : ' '}`} id="dropdown-autoclose-true" onClick={() => setShowEmployee(!showEmployee)}>
                    Assigned to
                    {appliedFilter && selectedEmployeeName.length > 0 &&
                        <span className='!ml-2 bg-[#1570EF] text-white !text-xs rounded-full h-5 w-5 flex items-center justify-center'>{selectedEmployeeName.length} </span>
                        // : <span>{selectedProjectName[0]?.label || ''}</span>
                    }
                    <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className='!w-[297px] !px-0 inter !fixed' flip={isMobile ? false : undefined}
                    popperConfig={
                        isMobile
                            ? { strategy: 'fixed', modifiers: [{ name: 'preventOverflow', options: { boundary: 'viewport' } }] }
                            : undefined
                    }>
                    <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear !px-3">
                        <a className='fz12 black-dark-700 fw-po-medium pr-hover' onClick={handleSelectAllEmployee}>Select all</a>
                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0'
                            onClick={() => {
                                let oldFilters = leadTopFilters;
                                let newFilters = []
                                for (const filterData of oldFilters) {
                                    // Check filter values and select element at pos 0 to compare with selected key
                                    if (!['Assigned To'].includes(Object.keys(filterData)[0])) {
                                        newFilters.push(filterData);
                                    }
                                }
                                if (newFilters.length > 0) {
                                    localStorage.setItem('new_filters', JSON.stringify(newFilters))
                                } else {
                                    localStorage.removeItem('new_filters')
                                }
                                handleFilter(newFilters);
                                setAppliedFilter(false);
                                handleClearEmployeeSelection()
                            }}
                        >Clear all</a>
                    </div>
                    <div className='drop-search w-full !my-4 !px-3'>
                        <div className={`form-group position-relative search-input ${searchEmployee ? 'input-active' : ''} `}>
                            <div className="serch-icon">
                                <img src={SearchIcon} className="" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search"
                                className={`searchinput !w-full !rounded-3xl`}
                                value={searchEmployee}
                                onChange={(e) => handleEmployeeSearch(e)}
                            />
                            {searchEmployee &&
                                <div className="close-icon" onClick={handleClearEmployeeSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                    </svg>
                                </div>}
                        </div>
                    </div>
                    <div className='max-h-[200px] overflow-auto !px-3'>
                        <div className='input-list space-y-2'>
                            {selectedEmployeeName.length > 0 && selectedEmployeeName.map((data, index) => {
                                return (
                                    <div className='dropdown-item !px-0' key={`employee_${data.label}_${data.id}_${index}`}>
                                        <div className="!px-2 rounded-sm !flex justify-between cursor-pointer" onClick={(e) => {
                                            handleChangeEmployee(e, data)
                                        }}>
                                            <div className='flex flex-col'>
                                                <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`employee_${data.label}_${data.id}_${index}`}>
                                                    {data.label}
                                                </label>
                                                <span className='text-[10px]'>{data.email}</span>
                                            </div>
                                            <input
                                                className=""
                                                id={`employee_${data.label}_${data.id}_${index}`}
                                                type="checkbox"
                                                name={data.label}
                                                // onChange={() => handleChangeEmployee(data)}
                                                checked={selectedEmployee.includes(data.id)}
                                            />

                                        </div>
                                    </div>
                                )
                            })}
                            {usersList.length > 0 ? usersList.map((data, index) => {
                                return (
                                    <div className='dropdown-item rounded-lg !px-0' key={`employee_${data.label}_${data.id}_${index}`}>
                                        <div className="!px-2  !flex justify-between cursor-pointer" onClick={(e) => {
                                            handleChangeEmployee(e, data)
                                        }}>
                                            <div className='flex flex-col' >
                                                <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`employee_${data.label}_${data.id}_${index}`}>
                                                    {data.label}
                                                </label>
                                                <span className='text-[10px]'>{data.email}</span>
                                            </div>
                                            <input
                                                className=""
                                                id={`employee_${data.label}_${data.id}_${index}`}
                                                type="checkbox"
                                                name={data.label}
                                            // onChange={() => handleChangeEmployee(data)}
                                            // checked={selectedEmployee.includes(data.id)}
                                            />

                                        </div>
                                    </div>
                                )
                            }) : selectedEmployeeName.length === 0 && <p>No Records Found</p>}
                        </div>
                    </div>
                    <div className="aply-btn w-full !px-3">
                        <button className='pr-btn d-block w-full' onClick={() => {
                            // setIsFilterApplied(true);
                            // getAllRecords();
                            if (selectedEmployeeName?.length > 0) {
                                handleFilter([
                                    ...filters,
                                    {
                                        "Assigned To": selectedEmployeeName
                                    }]);
                                localStorage.setItem('new_filters', JSON.stringify([
                                    ...filters,
                                    {
                                        "Assigned To": selectedEmployeeName
                                    }
                                ]))
                                // handleCloseEmployee();
                                setAppliedFilter(selectedEmployeeName?.length > 0)
                            }
                            setShowEmployee(false);
                        }}>Apply filter</button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default AssignedToFilters