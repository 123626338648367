import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getLeadReEngagedSources } from '../../../services/private/company.service'
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

export default function ReengagedSource({
    uuid,
    sources
}) {

    const [showMenu, setShowMenu] = useState(false);
    const [sourcesList, setSourcesList] = useState([])

    const leadTimeLineRef = useRef();

    const getReengagedSource = async () => {
        const res = await getLeadReEngagedSources(uuid)
        if (res.data.status === 200) {
            setShowMenu(true)
            console.log(res.data.data)
            setSourcesList(res.data.data);
        }
    }

    // useEffect(() => {
    //     if (uuid)
    //         getReengagedSource();
    // }, [uuid])

    const handleClickOutside = useCallback((e) => {
        if (leadTimeLineRef.current && !leadTimeLineRef.current.contains(e.target)) {
            setShowMenu(false);
        }
    }, []);

    useEffect(() => {
        if (showMenu) {
            document.body.classList.add('show-mini-timeline')
            document.addEventListener('click', handleClickOutside, true);
        }

        return (() => {
            document.body.classList.remove('show-mini-timeline')
            document.removeEventListener('click', handleClickOutside, true)
        })
    }, [showMenu])

    return (
        <>
            <div className='flex items-center' onClick={getReengagedSource}>
                <span className='text-capitalize'>{sources?.length > 0 &&
                    <span className='three-dots cursor-pointer'>{sources[0]}</span>}
                </span>
                {
                    sources?.length > 1 && <div className="tag-dropdown ml-1 !mb-1" >
                        <button
                            className="border-0"
                        >
                            +{sources?.length - 1}
                        </button>
                    </div>}
            </div>
            {sourcesList.length > 0 && < Modal
                className="reengaged modal-background"
                show={showMenu}
                onHide={() => setShowMenu(false)}
                centered

            >
                <Modal.Body>
                    <div ref={leadTimeLineRef} className='!py-4 !pl-4'>
                        <div className='flex justify-between'>
                            <h4 className='text-base'>Reengaged Source</h4>
                            <button onClick={() => setShowMenu(false)}>
                                <CloseIcon />
                            </button>
                        </div>
                        {sourcesList.map((item, index) => {
                            return <div className='!mt-3 border-b border-grayLight !pb-2'>
                                <p key={index} className="m-0 !p-0 !text-black700 !font-semibold dropdown-item truncate ..." >{item.source_name}
                                    {/* {index == 0 ? <span className='drop-tag'>Primary</span> : ''} */}
                                </p>
                                <p className='m-0 text-xs flex justify-end text-black700'>{moment(item.created_on).format('h:mm a, Do MMM YYYY')}</p>
                            </div>

                        })}

                    </div>
                </Modal.Body>
            </Modal >}

            {
                (sources?.length === 0) && <div className='view-notes'>
                    <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                        onClick={() => {
                            getReengagedSource()
                        }}
                    >
                        View
                    </button>
                </div>
            }
        </>
    )
}