import React, { useState } from 'react'
import { Oval, ThreeDots } from 'react-loader-spinner'
import InputSelect from '../InputGroup/InputSelect'
import Secure from '../Secure'
import FormMapingRulesModal from '../Modals/FormMapingRulesModal';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';

const ConnectForm = ({
    facebookAccountList,
    getFbLoginUrl,
    handleFbAccountChange,
    pageList,
    selectedFbAccount,
    handlePageChange,
    selectedPage,
    handleConnectAccountNext,
    isLoading,
    connectPageError,
    pageFetching
}) => {

    return (
        <>
            <div className="fb-contect-form h-full overflow-auto">
                <div className="contact-account">
                    <div className="fb-leads-add-accoung">
                        <div className="form-group invalid">
                            <div className="fz16 black fw-po-medium mb-8">Facebook  Leads Ads Account <sup>*</sup> </div>
                            <div className='fb_account_connect d-flex align-items-center flex-sm-row flex-column'>
                                <div className="fb_account_select-dropdown">
                                    <InputSelect className="form-control"
                                        name='Facebook Accounts'
                                        options={facebookAccountList}
                                        value={selectedFbAccount}
                                        onChange={handleFbAccountChange}
                                        placeholder='Select Facebook account'
                                    />
                                </div>
                                <button className='pr-btn px-16 py-7 cursor-pointer' onClick={getFbLoginUrl}>
                                    <FacebookIcon />
                                    Connect new account</button>
                            </div>
                            {/* <p className="fz14 mb-0 black-dark-600 mt-8">Facebook Lead Ads is a secure partner with Propacity. Your credentials are secure.</p> */}
                            {connectPageError && !selectedFbAccount && <div className="input-feedback mt-8">
                                Please Select Facebook Account.
                            </div>}
                        </div>
                    </div>
                    <div className="import-pages-and-form">
                        <div className="import-pages invalid position-relative">
                            <div className="fz16 black fw-po-medium mb-8">Facebook Page<sup>*</sup> </div>
                            {/* <input type="text" className="form-control" placeholder="Select Page" /> */}
                            <InputSelect
                                name='Pages'
                                options={pageList}
                                value={selectedPage}
                                onChange={handlePageChange}
                                minMenuHeight={'40px'}
                                placeholder='Select Page'
                                disable={pageFetching}
                            />
                            <p className="fz14 mb-0 black-dark-600 mt-8">Don't see your Page listed?  Re-connect your account above and <span className="pr-color pr-hover cursor-pointer" onClick={getFbLoginUrl}>allow permission.</span></p>
                            {connectPageError && !selectedPage && <div className="input-feedback mt-8">
                                Please Select Facebook Page.
                            </div>}
                            {pageFetching &&
                                <div className='position-absolute' style={{ top: '43px', right: '50px' }}>
                                    <ThreeDots
                                        height={17}
                                        width={30}
                                        color="#0062FF"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#0062FF"
                                        strokeWidth={4}
                                        strokeWidthSecondary={4}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="import-pages-and-form import-forms">
                                <div className="fz16 black fw-po-medium mb-8">Marketing Agency </div>
                                <InputSelect
                                    name='Form'
                                    options={agencyDetails}
                                    value={selectedAgency}
                                    onChange={handleChangeAgency}
                                    placeholder='Select Agency'
                                />                               
                            </div> */}
                </div>


            </div>
            <div className="footer-modal d-flex align-items-center justify-content-center">
                <Secure />

                <button className="pr-btn fz16 py-8 px-40 d-table ml-auto mr-20" onClick={handleConnectAccountNext} disabled={isLoading}>
                    {!isLoading ? 'Next' : ''}
                    {isLoading &&
                        <Oval

                            height={17}
                            width={30}
                            color="#FFFFFF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#FFFFFF"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    }
                </button>
            </div>


            {/* Here is the Form Mapping Rules Modal pleae integrate this  */}


        </>
    )
}

export default ConnectForm