import React, { useEffect, useState, useRef, useCallback } from 'react';
import './CustomTable.css';
import { createDatewithTime } from '../../helpers/date';
import { getCompanyAgency, getMasterEnumsCall, getTimelineCall, knowlarityClickToCall, knowlarityClickToCallAdvanced, updateleadByKeyValue } from '../../services/private/company.service';
import NoResultFound from '../NotFound/NoResultFound';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import LeadName from './TableColumns/LeadName';
import Projects from './TableColumns/Projects';
import SourceLabel from './TableColumns/SourceLabel';
import StatusDropDown from './TableColumns/StatusDropDown';
import DynamicIntent from './TableColumns/DynamicIntent';
import AssignedTo from './TableColumns/AssignedTo';
import checkAssignedTo from '../../utils/checkAssignedTo';
import MobileNo from './TableColumns/MobileNo';
import ASCIcon from '../../assets/icons/asc_sort.svg';
import DSCIcon from '../../assets/icons/dsc_sort.svg';
import SortIconDisplay from './sortIconDisplay';
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities';
import MiniTimline from '../Modals/MiniTimline';
import RenderTableData from './RenderTableData';
import CustomColumnModal from '../Modals/CustomColumnModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ShareLeadAccessModal from '../Modals/ShareLeadAccessModal';
import { checkIsSomeItemAvailableInList, mergeUniqueByKey } from '../../helpers/helpers';
import SharedWithUserName from './TableColumns/SharedWithUserName';
import AdvanceCallModal from '../Modals/AdvanceCallModal';
import { toast } from 'react-toastify';
import LeadTaggingModal from '../Modals/LeadTaggingModal';
import AreYouSureModal from '../Modals/AreYouSureModal';
import { getEOIDetails, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import DetailsModal from '../../modules/Inventory/Modal/details';

export default function LeadsTable({
    leadData,
    filters,
    startIndex,
    showCheck,
    updateData,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    handleUserDeletion,
    handleUserReassignLead,
    columns,
    col,
    count,
    searchText,
    reAssignRole,
    sort,
    intentStatuses,
    statuses,
    sourceStatuses,
    setLeadName,
    selectedLeadName,
    agencyList,
    showCreateLeadModal,
    allLeadsColumn,
    saveCurrentUserColumnPrefrence,
    dashboardColumns,
    handleChangeSelection,
    updateColumnList,
    allowedPermissions,
    isRolesModule,
    userProfile,
    callerList
}) {
    const [deadUuid, setDeadUuid] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedSingleUserIdFromTable, setSelectedSingleUserIdFromTable] = useState();
    const [selectedUserName, setSelectedUserName] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineLeadName, setTimelineLeadName] = useState('');
    const [showCustomColumn, setShowCustomColumn] = useState(false)
    const [columnsList, setColumnsList] = useState(allLeadsColumn);
    const [tableColumn, setTableColumn] = useState([]);
    const [isCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [showAdvanceCallmodal, setShowAdvanceCallModal] = useState(false);
    const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false);
    const [tagTitle, setTagTitle] = useState('');
    const [selectedBooking, setSelectedBooking] = useState({});
    const [bookingStatus, setBookingStatus] = useState('pending');
    const [bookingUUId, setBookingUUID] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [showVerifyToken, setShowVerifyToken] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showCancelEOI, setShowCancelEOI] = useState(false);

    // state to update status change 
    const ROLE_NAME = localStorage.getItem('role');

    const tableRef = useRef();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            if (reAssignRole?.includes('re_assign_leads')) {
                setReAssignAvailable(true);
            }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_leads')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    useEffect(() => {
        if (selectedTableValues?.length >= leadData?.length) {
            const allSelected = leadData.every(lead => selectedTableValues.includes(lead.id))
            setSelectAll(allSelected)
        }
    }, [selectedTableValues, selectedTableData, leadData]);

    // TBD REMOVED AFTER USE CASE SOLVED
    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name)
            setSelectedTableValues([...selectedTableValues, data.id])
            setSelectedTableData([...selectedTableData, data])
        }
        else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('')
        }
    }

    useEffect(() => {
        if (!selectedLeadName && selectedTableValues?.length === 1) {
            const leadVal = leadData.find((lead) => lead.id === selectedTableValues[0]);
            setLeadName(leadVal.name)
        }
    }, [selectedLeadName, selectedTableValues])

    useEffect(() => {
        const sortedList = allLeadsColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [allLeadsColumn]);

    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = leadData.map((lead) => lead.id)
            // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
            setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
            const mergedLeads = mergeUniqueByKey(selectedTableData, leadData, 'id')
            // setSelectedTableData([...selectedTableData, ...leadData]);
            setSelectedTableData([...mergedLeads]);
        } else {
            if (selectedTableValues?.length > leadData?.length) {
                const leadIds = leadData.map(lead => lead.id);
                const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
                const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
                setSelectedTableValues(filtered)
                setSelectedTableData(filteredData)
            } else {

                setSelectedTableValues([])
                setSelectedTableData([]);
            }
        }
        setSelectAll(!selectAll)
    }

    const handleRowPosition = (e, index) => {
        localStorage.setItem('rowIndex', index);
    }

    useEffect(() => {
        const handleCopy = (e) => {
            // Optionally prevent default copy behavior
            e.preventDefault();
        };
        const handleContextMenu = (e) => {
            // Optionally prevent default mouse right click behavior
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        // document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('copy', handleCopy);
            // document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {
        tableRef.current.scrollTop = parseInt(localStorage.getItem('rowIndex')) * 50;
    }, [leadData])

    const handleLeadTimeline = useCallback((data, index) => {
        setSelectedIndex(index)
        if (data.id) {
            setTimelineLeadName(data.name)
            getTimelineCall(data.id).then((res) => {
                if (res.data.status === 200) {
                    setTimeline(res.data.data)
                    setShowTimeline(true)
                }
            }).catch((err) => {
                console.log({ err })
            })
        }
    }, []);

    const handleClickToCall = async (lead) => {
        const res = await knowlarityClickToCall(lead.id, {});
        if (res.data.status === 200) {
            toast.success(res.data.message)
        }
    }

    const handleAdvanceClickToCall = async (useAlternate, callerId) => {
        const payload = {
            use_alternate: useAlternate,
            caller_id: callerId
        }

        const res = await knowlarityClickToCall(selectedItem.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSelectedItem({})
            setShowAdvanceCallModal(false)
        }
    }

    const closeTimelineDropdown = useCallback(() => {
        setSelectedIndex('');
        setTimeline([]);
        setShowTimeline(false);
    }, []);

    const closeCustomColumnModal = useCallback(() => {
        setShowCustomColumn(false)
    }, []);

    const handleOpenVerifyPage = (data) => {
        setLeadStatus('eoi')
        setSelectedItem(data);
        // setShowDetailsModal(true)
        setShowVerifyToken(true)
    }

    const getEOILeadDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (userProfile?.company_id === 581 || res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
                setShowVerifyToken(false);
            }
        }
    }

    const handleChangeEOIStatus = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }


    const handleUpdateEOi = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            updateData();
            setShowCancelEOI(false);
            setSelectedItem({});
        }
    }

    return (
        <>
            <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedUserName}?`}
                deleteHandler={() => handleUserDeletion(selectedSingleUserIdFromTable)}
            />
            <div className="all-leads !pt-1 md:!pl-5 !pl-4">
                <div
                    className={`lead-table all_lead-table  h-full ${leadData?.length === 0 ? 'overflow-hidden shownofound' : ''} no-select`}
                    ref={tableRef}
                    id='allLeadsTable'
                // onScroll={handleScroll}
                >
                    <table className="table tableisscrolling">
                        <thead>
                            <tr>
                                <th
                                    className={`${showCheck ? 'cursor-pointer' : ''}`}
                                >
                                    {showCheck ?

                                        <input
                                            type="checkbox"
                                            onChange={onHandleSelectAllLeads}
                                            checked={selectAll}
                                            id='selectAll_leads'
                                            className='select-all-check resasign-input'
                                            title='Select all'

                                        />

                                        :
                                        'S.No.'
                                    }
                                </th>
                                {
                                    tableColumn?.map((item, index) => {
                                        if (item.label === 'Marketing Agency' && agencyList?.length === 0) {
                                            return null
                                        }
                                        if (item.label === 'View Lead Share' && !userProfile?.allowed_modules?.includes('lead_sharing')) {
                                            return null
                                        }
                                        return (
                                            <th
                                                key={`${item.db_value}_${index}`}
                                                className={`${item.label === 'Name' ? 'sticky-th' : ''} ${(item.label === 'Mobile No.' && userProfile?.company_id === 581) ? 'sticky left-[252px]' : ''}`}
                                            >
                                                <span className='flex items-center gap-1'>
                                                    {item.label}
                                                    <SortIconDisplay sortCol={col} sort={sort} colName={item.label} />
                                                </span>
                                            </th>
                                        )
                                    })
                                }
                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <th>Lead Tagged</th>}
                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <th>Last CP tagged</th>}
                                {/* {userProfile?.allowed_modules?.includes('lead_sharing') && <th>View lead share</th>}
                                <th>Shared With User</th>
                                <th>Marked By</th>
                                <th>Marked Date</th>
                                <th>Lead Access Validity</th>
                                <th>Access</th> */}

                                {isCustomColumnAvailable ? <th>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                                <div className='info-msg'>
                                                    Customise Columns
                                                </div>
                                            </Tooltip>
                                        }
                                    >

                                        <button className='cust-col-btn' onClick={() => setShowCustomColumn(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <line x1="5.81445" y1="10.1094" x2="14.4082" y2="10.1094" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                                <line x1="10.1113" y1="5.8125" x2="10.1113" y2="14.4062" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                            </svg>

                                        </button>
                                    </OverlayTrigger>
                                </th> : <th></th>}

                            </tr>
                        </thead>
                        <tbody  >
                            {
                                leadData && leadData?.length > 0 &&
                                leadData.map((data, index) => {
                                    return (
                                        <tr key={data.id + data.intent + data.status} className={`${(!data.status) ? 'new-leads' : 'old-leads'}`} id={`leads_table_${index}`} onClick={(e) => handleRowPosition(e, index)}>
                                            <td>
                                                {showCheck ?
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => handelChange(e, data)}
                                                        checked={selectedTableValues.includes(data.id)}
                                                    /> :
                                                    index + startIndex
                                                }
                                            </td>
                                            {
                                                tableColumn?.map((item, index) => {
                                                    return (
                                                        <RenderTableData
                                                            item={item}
                                                            data={data}
                                                            updateData={updateData}
                                                            intentStatuses={intentStatuses}
                                                            statuses={statuses}
                                                            leadData={leadData}
                                                            agencyList={agencyList}
                                                            sourceStatuses={sourceStatuses}
                                                            key={`${item.db_value}_${index}`}
                                                            userProfile={userProfile}
                                                            setShowShareLeadAccessModal={setShowShareLeadAccessModal}
                                                            setSelectedItem={setSelectedItem}
                                                            handleOpenVerifyPage={handleOpenVerifyPage}
                                                        />
                                                    )
                                                })
                                            }
                                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <td>
                                                <div className={`${data?.broker_user_name ? 'text-[#2064FA] bg-[#2064FA]/10' : 'text-[#667085] bg-[#F1F1F5]'}  text-xs rounded-lg text-center font-semibold !py-1.5 `}>
                                                    {data?.broker_user_name ? 'Tagged' : 'Untagged'}
                                                </div>
                                            </td>}
                                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <td>{data?.last_tag_cp_user || '-'}</td>}
                                            {/* {(ROLE_NAME === 'ADMIN' || reAssignRole?.includes('re_assign_leads')) && <td className='dropdown view-more'> */}
                                            {/* {userProfile?.allowed_modules?.includes('lead_sharing') && <td>
                                                {
                                                    data.shared_with_count > 0 ?
                                                        <div className='view-notes'>
                                                            <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                                                                onClick={() => {
                                                                    setSelectedItem(data)
                                                                    setShowShareLeadAccessModal(true)
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                        </div>
                                                        : '-'
                                                }
                                            </td>}
                                            <td>
                                                {data?.shared_with_names ? <SharedWithUserName shareWithNameArr={data.shared_with_names} /> : '-'}
                                            </td>
                                            <td>
                                                {data?.status_changed_by_name || '-'}
                                            </td>
                                            <td>
                                                {
                                                    data?.status_changed_on ? createDatewithTime(new Date(data?.status_changed_on)) : '-'
                                                }
                                            </td>
                                            <td>{data.validity ? `${data?.validity} ${data.validity_unit}` : '-'}</td>
                                            <td className='capitalize'>{data?.access ? data?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td> */}
                                            <td className='dropdown view-more'>
                                                <>
                                                    <button
                                                        className={`bg-tr border-0 ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} `}
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                                                    >

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                        {reAssignAvailable &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setLeadName(data.name);
                                                                    handleUserReassignLead(data.id)
                                                                }}
                                                            >
                                                                Re-assign Lead
                                                            </a>}
                                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit Lead Tagging
                                                        </a>}
                                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit CP User
                                                        </a>}
                                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                            setTagTitle('Remove Lead Title')
                                                        }}>
                                                            Remove Lead Tagging
                                                        </a>}
                                                        <a className="dropdown-item" onClick={() => {
                                                            setTimeline([]);
                                                            handleLeadTimeline(data, index);
                                                        }}>
                                                            View Recent Activity
                                                        </a>
                                                        {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                            checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                            <a className="dropdown-item" onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}>

                                                                Share Lead Access
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => handleClickToCall(data)}
                                                                    >
                                                                Quick Call
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => {
                                                                            setSelectedItem(data);
                                                                            setShowAdvanceCallModal(true);
                                                                        }}>
                                                                Advanced Call
                                                            </a>}
                                                        {deleteAvailable && <a className="dropdown-item del-menu-itme" onClick={() => {
                                                            setShowDeleteModal(true)
                                                            setSelectedSingleUserIdFromTable(data.id)
                                                            setSelectedUserName(data.name)
                                                        }}>Delete Lead</a>}


                                                    </div>

                                                </>
                                                {/* {selectedIndex === index && <div className="">
                                                    <div className="dropdown-menu mini-timeline show">

                                                        {timeline?.length > 0 && <RecentActivities ActivityTimeline={timeline} leadName={data.name} closeTimelineDropdown={closeTimelineDropdown} />}
                                                        {timeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                                            <Oval
                                                                height={30}
                                                                width={30}
                                                                color="#0062FF"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                                ariaLabel='oval-loading'
                                                                secondaryColor="#0062FF"
                                                                strokeWidth={2}
                                                                strokeWidthSecondary={2}
                                                            />
                                                        </div>}

                                                    </div>
                                                </div>} */}
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                            <td></td>
                        </tbody>
                    </table>
                    {leadData?.length === 0 && <NoResultFound count={count} filters={filters} searchText={searchText} showCreateLeadModal={showCreateLeadModal} />}
                    {showTimeline &&
                        <MiniTimline
                            setShow={setShowTimeline}
                            show={showTimeline}
                            ActivityTimeline={timeline}
                            leadName={timelineLeadName}
                            closeTimelineDropdown={closeTimelineDropdown}
                        />}

                    {showCustomColumn &&
                        <CustomColumnModal
                            dashboardColumns={dashboardColumns}
                            activeColumnTab='All'
                            show={showCustomColumn}
                            setShow={closeCustomColumnModal}
                            updateColumnList={updateColumnList}
                            handleChangeSelection={handleChangeSelection}
                            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        />
                    }
                    {showShareLeadAccessModal &&
                        <ShareLeadAccessModal
                            show={showShareLeadAccessModal}
                            selectedItem={selectedItem}
                            closeModal={() => {
                                setShowShareLeadAccessModal(false)
                                setSelectedItem({})
                            }}
                            onSuccess={updateData}
                            allowedPermissions={allowedPermissions}
                        />
                    }
                    {
                        showAdvanceCallmodal &&
                        <AdvanceCallModal
                            show={showAdvanceCallmodal}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowAdvanceCallModal(false)
                            }}
                            handleSubmit={handleAdvanceClickToCall}
                            callerList={callerList}
                        />
                    }

                    {
                        showLeadTaggingModal &&
                        <LeadTaggingModal
                            show={showLeadTaggingModal}
                            uuid={selectedItem.id}
                            selectedItem={selectedItem}
                            updateData={updateData}
                            title={tagTitle}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowLeadTaggingModal(false)
                            }}
                        />
                    }
                    {showVerifyToken && <AreYouSureModal
                        show={showVerifyToken}
                        title='Verify Token before booking'
                        subText={'Kindly verify the received token amount before marking the booking status for this lead.'}
                        closeModal={() => {
                            setShowVerifyToken(false)
                        }}
                        confirmClose={() => {
                            setBookingStatus(selectedItem.approval_status);
                            getEOILeadDetails(selectedItem.eoi_uuid);
                        }}
                        buttonTitle={'Verify Token'}
                    />}
                    {showDetailsModal && <DetailsModal
                        show={showDetailsModal}
                        unit={{}}
                        selectedBooking={selectedBooking}
                        closeModal={() => setShowDetailsModal(false)}
                        modalOpenFrom={'table'}
                        bookingStatus={bookingStatus}
                        ModalType='Token'
                        handleChangeApprovalStatus={handleChangeEOIStatus}
                        data={selectedItem}
                        handleSubmit={handleUpdateEOi}
                        updateData={updateData}
                        operation={!allowedPermissions.includes('verify_bookings_eoi') ? 'view only' : ''}
                    />}
                </div>
            </div>
        </>
    )
}