import React, { useContext, useRef } from 'react'
import { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getAuthRolePermissions } from '../../services/private/company.service';
import Consumer from '../../helpers/context';

export default function DateTimePicker({
    handelBlur,
    onValueChange,
    label,
    name,
    value,
    error,
    completed,
    inputclass,
    required,
    placeholder = 'Select Date and Time',
    showTime = true
}) {

    const [startDate, setStartDate] = useState(value ? new Date(value) : '');
    const [permissions, setPermissions] = useState();
    const [previousDateAllow, setPreviousDateAllow] = useState(false);

    const now = moment();
    const ROLE_NAME = localStorage.getItem('role');
    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const getUsersAuthRolePermissions = () => {
        getAuthRolePermissions().then((res) => {
            res.data.data.map((role) => {
                if (role.module === "leads") {
                    setPermissions(role.permissions)
                }
            })
        })
    }
    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN' || permissions?.includes('past_date_creation')) {
                setPreviousDateAllow(true);
            }
        } else {
            if (allowedPermissions?.includes('past_date_followup/site_visit')) {
                setPreviousDateAllow(true);
            }
        }
    }, [isRolesModule, allowedPermissions, permissions]);

    useEffect(() => {
        getUsersAuthRolePermissions();
    }, []);

    useEffect(() => {
        if (value) {
            setStartDate(new Date(value));
        }
    }, [value]);

    const setInputValue = (date) => {
        // date.setHours(12, 0, 0, 0);
        setStartDate(date);
        onValueChange(date)
    }

    const filterTime = (date) => {
        const isPastTime = new Date().getTime() > date.getTime();
        return !isPastTime;
    };

    return (
        <>
            <div className={`form-group ${inputclass} ${error ? 'invalid' : ''}  position-relative`}>
                {label && <label className=''>{label} {required && <sup>*</sup>}</label>}
                <div className='d-flex'>
                    {/* <input
                        onChange={(e) => { setDate(e.target.value); setInputValue() }}
                        className={`form-control text-input ${error ? 'error': ''}`}
                        type="date"
                        onBlur={handelBlur}
                        max="2099-12-10"
                        name={name}
                        min={moment().format("YYYY-MM-DD")}
                        value={value? moment(value).format("YYYY-MM-DD"): date}
                    />
                    <input
                        onChange={(e) => {setTime(e.target.value); setInputValue() }}
                        className={`form-control text-input ml-2 ${error ? 'error': ''}`}
                        type="time"
                        onBlur={handelBlur}
                        name={name}
                        value={value? moment(value).format("HH:mm"): time}
                    /> */}

                    <DatePicker
                        showTimeSelect={showTime}
                        selected={startDate}
                        timeIntervals={15}
                        timeFormat="h:mm a"
                        placeholderText={placeholder}
                        minDate={previousDateAllow ? '' : new Date()}
                        onChange={(date) => setInputValue(date)}
                        dateFormat={showTime ? "h:mm aa, dd MMM yyyy " : "dd MMM yyyy "}
                        className={`form-control text-input ${error ? 'error' : ''}`}
                        maxDate={completed ? new Date() : ''}
                        filterTime={previousDateAllow ? '' : filterTime}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </div>
                {error && (
                    <>
                        <div className="input-feedback mt-8">{error}
                        </div>
                    </>

                )}
            </div>
        </>
    )
}

