import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'


const AllowCsvExport = ({ show, closeCancelModal, onSubmit, title, bodyText, submitButtonType, cancelButtonText, submitButtonText }) => {

    return (

        <PopUpModal
            setShow={closeCancelModal}
            show={show}
            body={<>
                <div className='csv-export-modal'>
                    <div className='csv-export-header flex items-center justify-center'>
                        <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0361 16.7539V26.7539C23.0361 27.8585 23.9316 28.7539 25.0361 28.7539C26.1407 28.7539 27.0361 27.8585 27.0361 26.7539V16.7539C27.0361 15.6493 26.1407 14.7539 25.0361 14.7539C23.9316 14.7539 23.0361 15.6493 23.0361 16.7539Z" fill="white" />
                            <path d="M27.0361 32.7539C27.0361 33.8585 26.1407 34.7539 25.0361 34.7539C23.9316 34.7539 23.0361 33.8585 23.0361 32.7539C23.0361 31.6493 23.9316 30.7539 25.0361 30.7539C26.1407 30.7539 27.0361 31.6493 27.0361 32.7539Z" fill="white" />
                            <path d="M21.827 4.93212C23.3123 2.62331 26.6877 2.62331 28.173 4.93213L47.2403 34.5704C48.8556 37.0813 47.0529 40.3846 44.0673 40.3846H5.9327C2.94714 40.3846 1.14439 37.0813 2.75971 34.5704L21.827 4.93212Z" stroke="white" strokeWidth="3.39562" strokelinejoin="round" />
                        </svg>

                    </div>
                    <h5>Allow CSV Export?</h5>
                    <p className='black-dark-800 fz16 po'>Are your sure you want to allow standard user to export data - he/she can export all leads from your company? Please confirm</p>
                    <div className="justify-content-end d-flex modal-footer">

                        <button variant="" className="cancel outline-btn" onClick={() => closeCancelModal(false)}>
                            Cancel
                        </button>

                        <button variant="" className={`${submitButtonType === 'delete' ? 'fw-po-medium del-btn px-16' : 'fw-po-medium del-btn px-16'}`} onClick={onSubmit}>
                            Yes, Confirm
                        </button>
                    </div>
                </div>
            </>}
        />

    )
}

export default AllowCsvExport;