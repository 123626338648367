import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import RightModal from '../../Modals/RightModal';
import InputRupees from '../../InputGroup/InputRupees';
import InputText from '../../InputGroup/InputText';
import { validityUnit } from '../../../helpers/enums';
import { insertOffer, updateOfferByUUID, uploadFile } from '../../../services/public/public.service';
import InputTextarea from '../../InputGroup/InputTextarea';
import InputSelect from '../../InputGroup/InputSelect';
import DateTimePicker from '../../InputGroup/DateTime';
import { toast } from 'react-toastify';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';

const OfferType = [
    {
        label: 'Booking',
        value: 'Booking'
    },
    {
        label: 'Site visit',
        value: 'Site visit'
    }
]

const ProjectOfferModal = ({ show, closeModal, style, uuid, getOffers, selectedOffer }) => {

    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [amount, setAmount] = useState('');
    const [validity, setValidity] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);
    const [image, setImage] = useState('')

    useEffect(() => {

        if (selectedOffer && Object.keys(selectedOffer).length) {
            setTitle(selectedOffer?.title);
            setType(selectedOffer?.offer_type);
            setValidity(selectedOffer?.validity);
            setDescription(selectedOffer?.description);
            setAmount(selectedOffer?.amount);
            setImage(selectedOffer?.image_url)
        }
    }, [selectedOffer]);

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setImage(response.data.data.s3_url)
        }
    }

    const handleSubmit = async () => {
        if (!title || !amount || !validity || !type) {
            setError(true);
            return
        } else {
            setError(false);
        }

        const payload = {
            title: title,
            amount: amount,
            project_id: uuid,
            description: description,
            validity: validity,
            offer_type: type,
            image_url: image
        }
        let res = {}
        if (selectedOffer?.uuid) {
            res = await updateOfferByUUID(selectedOffer?.uuid, payload);
        } else {
            res = await insertOffer(payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message)
            getOffers();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal}>

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Offers</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <form className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                <div className='mt-3'>
                    <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                        <span>Offer Image</span>
                        {/* <span>PNG, 2MB</span> */}
                    </div>
                    <div className={`${style.upload}`}>
                        {image && <img src={image} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                        <input type="file" className='d-none' name='offer-img' id='offer-img' accept='image/*' onChange={(e) => handleOnChangeImage(e)} />
                        {!image && <div className={`${style.uplaodbtngrp}`}>
                            <button
                                type='button'
                                className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('offer-img').click()
                                }}
                            ><PluswhiteIcon />Upload</button>
                        </div>}
                        {image && <div className={`${style.hoverbox}`}>
                            <button
                                type='button'
                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('offer-img').click()
                                }}
                            ><PluswhiteIcon />Replace</button>
                            <button
                                type='button'
                                className={`${style.delbtn} inter border-0 bg-white`}
                                onClick={() => {
                                    setImage('')
                                }}
                            ><TrashIcon /></button>
                        </div>}
                    </div>
                    {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                    {/* {logoDeveloperFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoDeveloperFileError}</p>} */}
                </div>
                <InputText
                    inputclass='!mt-0'
                    placeholder='Enter Title'
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    label={'Title'}
                    error={error && !title ? 'Please enter title' : ''}
                />
                <InputSelect
                    inputclass='!mt-0'
                    placeholder='Select Offer Type'
                    value={type ? OfferType.find(i => i.value === type) : ''}
                    onChange={(value) => {
                        setType(value.value)
                    }}
                    options={OfferType}
                    label={'Offer Type'}
                    error={error && !type ? 'Please select type' : ''}
                />
                <InputRupees
                    label="Amount"
                    placeholder="Enter Amount"
                    type="text"
                    name='amount'
                    value={amount}
                    inputclass='m-0 inter'
                    showRupees={true}
                    onChange={(value) => {
                        setAmount(value)
                    }}
                    handleBlur={() => { }}
                    error={error && !amount ? 'Please enter amount' : ''}
                />
                <div>
                    <DateTimePicker
                        onValueChange={(e) => {
                            setValidity(e)
                        }}
                        label="Validity"
                        name="validity"
                        value={validity}
                        showTime={false}
                        error={error && !validity ? 'Please enter title' : ''}
                    />

                </div>
                <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={closeModal}>Cancel</button>
                <button type='button' onClick={handleSubmit} className='pr-btn w-100'>Submit</button>
            </div>
        </RightModal >
    )
}

export default ProjectOfferModal
