import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import LeadsTopBar from "./LeadsTopBar/LeadsTopBar";
import LeadsTable from "../../components/Custom Table/LeadsTable";
import "./Leads.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
  getALLLeads,
  getAuthRolePermissions,
  getCompanyAgency,
  getCurrentUserColumnPreference,
  getExportLead,
  getKnowlarityIvrCallerIdByCompanyId,
  getLeadCounts,
  getUniqueFacebookForms,
  getUsersForSettings,
  saveColumnPreferenceNew
} from "../../services/private/company.service";
import LeadsFooterBar from "./LeadsFooterBar";
import { leadTypesEnum } from "../../config/enums";
import FollowupsTable from "../../components/Custom Table/FollowupsTable";
import SiteVisitTable from "../../components/Custom Table/SiteVisitTable";
import BookingsTable from "../../components/Custom Table/BookingsTable";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { bulkSourceChange, deleteLeads, mergeLeads, reassignLeads } from "../../services/private/leads.service";
import ReassignLeadModal from "../../components/Modals/ReassignLeadModal";
import TableSkeleton from "../../components/Skeleton/TableSkeletion";
import { useCallback } from "react";
import NewLeadsTable from "../../components/Custom Table/NewLeads";
import DeadLeadsTable from "../../components/Custom Table/DeadLeadTable";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { enums } from "../../helpers/filtersEnum";
import Consumer from '../../helpers/context'
import { getCustomerEnumsCall } from "../../services/private/customer.service";
import { getDeadReasons } from "../../services/public/public.service";
import NewBookingsTable from "../../components/Custom Table/NewBookingTable";
import NewEoiTable from "../../components/Custom Table/NewEoiTable";
import FinalNegotiationTable from "../../components/Custom Table/FinalNegotiationTable";
import { getAllJobs } from "../../services/private/csv.service";
import ReassignTeamLeadModal from "../../components/Modals/ReassignTeamLeadModal";
import ShareLeadAccessModal from "../../components/Modals/ShareLeadAccessModal";
import BulkLeadShareModal from "../../components/Modals/BulkLeadShare";
import ReassignLeadToTeamAndUserModal from "../../components/Modals/ReassignLeadToTeamAndUserModal";
import { InProgessReasonsList, NotConnectedReasonsList, SVCancelledReasonList, svTypes } from "../../utils/constants";
import BulkSourceChange from "../../components/Modals/BulkSourceChange";
import SiteVisitCompleteTable from "../../components/Custom Table/SiteVisitCompleteTable";
import InternalTabs from "../../components/HOC/InternalTabs";
import subscriptionStrip from '../../assets/icons/subscription_strip.png'
import { useHistory } from "react-router-dom";
import CRMExpiryModal from "./LeadsTopBar/Modal/CRMExpiryModal";

const columnEnum = {
  'Assigned To': 'Assigned User',
  'dead_lead_date': 'Marked Date'
}

const scheduleTabList = [
  {
    name: 'All',
    value: 'ALL_SITE_VISITS'
  },
  {
    name: 'Upcoming',
    value: 'UPCOMING_SITE_VISITS_FOLLOWUPS'
  },
  {
    name: 'Revisits',
    value: 'REVISIT_SITE_VISITS'
  }
]

const bookingTabList = [
  {
    name: 'All',
    value: null
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Approved',
    value: 'approved'
  },
  {
    name: 'Cancelled',
    value: 'cancelled'
  }
]

const completedTabList = [
  {
    name: 'All',
    value: 'ALL_SITE_VISITS'
  },
  {
    name: 'Revisits',
    value: 'REVISIT_SITE_VISITS'
  }
]

export default function LeadsManager() {
  const [leadsCount, setLeadsCount] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [followupLeads, setFollowupLeads] = useState([]);
  const [newLeads, setNewLeads] = useState([]);
  const [bookingLeads, setBookingLeads] = useState([]);
  const [svLeads, setSvLeads] = useState([]);
  const [svScheduleLeads, setSvScheduleLeads] = useState([])
  const [svCompleteLeads, setSvCompleteLeads] = useState([]);
  const [finalNegotiationLeads, setFinalNegotiationLeads] = useState([]);
  const [deadLeads, setDeadLeads] = useState([]);
  const [bookingOnlyLeads, setBookingOnlyLeads] = useState([]);
  const [eoiOnlyLeads, setEoiOnlyLeads] = useState([])
  const [columns, setColumns] = useState([]);
  const [leadType, setLeadType] = useState(localStorage.getItem('leadType') || leadTypesEnum.ALL_LEADS);
  const [loading, setLoading] = useState(true);
  const [showMerge, setShowMerge] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [showTeamReassign, setShowTeamReassign] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showSourceChangeModal, setShowSourceChangeModal] = useState(false)
  const [showReassignToTeam, setShowReassignToTeam] = useState(false);
  const [showBulkLeadShare, setShowBulkLeadShare] = useState(false);
  const [showBulkLeadSourceChange, setShowBulkLeadSourceChange] = useState(false);
  const [showBulkLeadShareModal, setShowBulkLeadShareModal] = useState(false)
  const [showingResults, setShowingResults] = useState(parseInt(localStorage.getItem('showingResults')) || 100);
  const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('currentPage')) || 1);
  const [maxPage, setMaxPage] = useState(1);
  const [startIndex, setStartIndex] = useState(1);
  const [reAssignRole, setReAssignRole] = useState();
  const [projectPermission, setProjectPermission] = useState();
  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filter')) || []);
  const [leadTopFilters, setLeadToFilters] = useState(JSON.parse(localStorage.getItem('new_filters')) || [])
  const [col, setCol] = useState(localStorage.getItem('col') || "");
  const [text, setText] = useState(localStorage.getItem('searchText') || "");
  const [sort, setSort] = useState(localStorage.getItem('sort') || "");
  const [selectedTableValues, setSelectedTableValues] = useState([]);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [count, setCount] = useState({
    leadsCount: 0,
    bookingLeadsCount: 0,
    followupsCount: 0,
    siteVisitsCount: 0,
    newLeadsCount: 0,
    deadLeadsCount: 0,
    bookingsOnlyCount: 0,
    eoiOnlyCount: 0,
    finalNegotiationCount: 0,
    siteVisitScheduledCount: 0,
    siteVisitCompletedCount: 0

  })
  const [refreshTime, setRefreshTime] = useState('')
  const [showSortToaster, setShowSortToaster] = useState(false);
  const [showFilterToaster, setShowFilterToaster] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let [intentStatuses, setIntentStatuses] = useState([]);
  let [statuses, setStatuses] = useState([]);
  let [sourceStatuses, setSourceStatuses] = useState([]);
  const [selectedLeadName, setSelectedLeadName] = useState('');
  const [agencyList, setAgencyList] = useState([]);
  const [newLeadModal, showNewLeadModal] = useState(false);
  const [allLeadsColumn, setAllLeadsColumn] = useState({});
  const [newLeadsColumn, setNewLeadsColumn] = useState({});
  const [followupLeadsColumn, setFollowupLeadsColumn] = useState({})
  const [finalNegotiationLeadsColumn, setFinalNegotiationLeadsColumn] = useState({})
  const [sitevisitLeadColumn, setSitevisiteLeadColumn] = useState({});
  const [bookingLeadsColumn, setBookingLeadsColumn] = useState({})
  const [deadLeadsColumn, setDeadLeadsColumn] = useState({})
  const [dashboardColumns, setDashboardColumns] = useState();
  const [project, setProject] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [csvList, setCSVList] = useState([]);
  const [fbFormList, setFbFormList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [leadCount, setLeadCount] = useState('');
  const [showExportLeadModal, setShowExportLeadModal] = useState(false)
  const [propertyEnums, setPropertyEnums] = useState({
    enum_furnishing: [],
    enum_facing: [],
    enum_funding_source: [],
    enum_industry: [],
    enum_possession: [],
    enum_property_category: [],
    enum_property_type: [],
    enum_purpose: [],
    enum_transaction_type: [],
    enum_usage_type: []
  });
  const [loadingCount, setLoadingCount] = useState(false);
  const [callerList, setCallerList] = useState([]);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [deleteLeading, setDeleteLeading] = useState(false);
  const [sitevisitEventFilter, setSitevisitEventFilter] = useState(localStorage.getItem('sitevisit_event') || '');
  const [selectedTab, setSelectedTab] = useState({
    name: 'All',
    value: 'ALL_SITE_VISITS'
  });
  const [selectedBookingTab, setSelectedBookingTab] = useState({
    name: 'All',
    value: null
  });
  const [tabRowCount, setTabRowCount] = useState({});
  const [showExpiryModal, setShowExpiryModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)

  const ROLE_NAME = localStorage.getItem('role');

  let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('check_expiry') && userProfile?.company?.subscription_expiry) {
      setShowExpiryModal(true);
    }
  }, [userProfile])

  const initLoadData = async () => {
    setLoading(true);
  };

  const getDeadReasonsStatus = () => {
    getDeadReasons().then(res => {
      if (res.status === 200) {
        const reasonsData = res.data.data.map((data) => {
          return {
            label: data.reason,
            value: data.uuid
          }
        })
        setReasons(reasonsData);
      }
    })
  }

  const getAllLeadsCount = async () => {
    setLoadingCount(true)
    await getLeadCounts({
      limit: showingResults,
      pageNumber: currentPage,
      filters: [...filters, ...leadTopFilters],
      sort: sort,
      col: col,
      text: text,
      filterType: leadType,
      site_visits_filter: (leadType === leadTypesEnum.SITE_VISITS_SCHEDULE || leadType === leadTypesEnum.SITE_VISITS_COMPLETE) ? selectedTab?.value : (leadType === leadTypesEnum.NEWBOOKINGS || leadType === leadTypesEnum.NEWEOI) ? selectedBookingTab?.value : '',
    }).then(res => {
      if (res.data.status === 200) {
        setLoadingCount(false)
        if (leadType === 'all_leads' && res.data.data?.allLeadsCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.allLeadsCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.allLeadsCount);
        }
        if (leadType === 'new_leads' && res.data.data?.newLeadsCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.newLeadsCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.newLeadsCount)
        }
        if (leadType === 'bookings' && res.data.data?.bookingLeadsCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.bookingLeadsCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.bookingLeadsCount);
        }
        if (leadType === 'site_visits_scheduled' && res.data.data?.siteVisitScheduledCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.siteVisitScheduledCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.siteVisitScheduledCount);
        }
        if (leadType === 'site_visits_completed' && res.data.data?.siteVisitCompletedCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.siteVisitCompletedCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.siteVisitCompletedCount);
        }
        if (leadType === 'followups' && res.data.data?.followupsCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.followupsCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.followupsCount);
        }
        if (leadType === 'dead_leads' && res.data.data?.deadLeadsCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.deadLeadsCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.deadLeadsCount)
        }

        if (leadType === 'booking_only' && res.data.data?.bookingsOnlyCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.bookingsOnlyCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.bookingsOnlyCount);
        }
        if (leadType === 'final_negotiation' && res.data.data?.finalNegotiationCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.finalNegotiationCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.finalNegotiationCount);
        }

        if (leadType === 'eoi_only' && res.data.data?.eoiOnlyCount) {
          const totalPages = Math.ceil(
            Number(res.data.data?.eoiOnlyCount) / showingResults
          );
          setMaxPage(totalPages);
          setLeadsCount(res.data.data?.eoiOnlyCount);
        }
        setCount({
          leadsCount: res.data.data.allLeadsCount,
          newLeadsCount: res.data.data.newLeadsCount,
          bookingLeadsCount: res.data.data.bookingLeadsCount,
          followupsCount: res.data.data.followupsCount,
          siteVisitsCount: res.data.data.sitevisitsCount,
          deadLeadsCount: res.data.data.deadLeadsCount,
          bookingsOnlyCount: res.data.data.bookingsOnlyCount,
          eoiOnlyCount: res.data.data.eoiOnlyCount,
          finalNegotiationCount: res.data.data.finalNegotiationCount,
          siteVisitScheduledCount: res.data.data.siteVisitScheduledCount,
          siteVisitCompletedCount: res.data.data.siteVisitCompletedCount

        })
      }
    })

    setLoadingCount(false)

  }

  const getExportLeadsData = async () => {
    setExportLoading(true)
    await getExportLead({
      limit: showingResults,
      pageNumber: currentPage,
      filters: [...filters, ...leadTopFilters],
      sort: sort,
      col: col,
      text: text,
      filterType: leadType
    }).then(res => {
      setExportLoading(false)
      if (res.data.status === 200) {
        const link = document.createElement("a");
        link.href = res.data.data;
        link.download = `dashboard_exported`;
        link.click();
        setShowExportLeadModal(false);
      }
    })
  }

  // useEffect(() => {
  //   
  // }, [count, leadType, showingResults]);

  const getLeadsData = async () => {
    setLoading(true);
    await getALLLeads({
      limit: showingResults,
      pageNumber: currentPage,
      filters: [...filters, ...leadTopFilters],
      sort: sort,
      col: col,
      text: text,
      filterType: leadType,
      site_visits_filter: (leadType === leadTypesEnum.SITE_VISITS_SCHEDULE || leadType === leadTypesEnum.SITE_VISITS_COMPLETE) ? selectedTab?.value : (leadType === leadTypesEnum.NEWBOOKINGS || leadType === leadTypesEnum.NEWEOI) ? selectedBookingTab?.value : '',
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.data.searchText && (localStorage.getItem('searchText') !== res.data.data.searchText)) {
          return;
        }
        setAllLeads(res.data.data.allLeads);
        // setLeadsCount(res.data.data.allLeads?.length);
        setFollowupLeads(res.data.data.followups)
        setNewLeads(res.data.data.newLeads)
        setBookingLeads(res.data.data.bookings)
        setSvCompleteLeads(res.data.data.siteVisitsCompleted)
        setSvScheduleLeads(res.data.data.siteVisitsScheduled)
        setDeadLeads(res.data.data.deadLeads)
        setBookingOnlyLeads(res.data.data.bookingOnly)
        setEoiOnlyLeads(res.data.data.eoiOnly)
        setFinalNegotiationLeads(res.data.data.finalNegotiation)
        setColumns(res.data.data.Columns)
        setRefreshTime(Date.now())
        setStartIndex((currentPage - 1) * showingResults + 1);
        if (leadType === 'all_leads') {
          const ids = res.data.data.allLeads.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.allLeads?.length)
        }
        if (leadType === 'new_leads') {
          const ids = res.data.data.newLeads.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.newLeads?.length)
        }
        if (leadType === 'bookings') {
          const ids = res.data.data.bookings.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.bookings?.length)
        }
        if (leadType === 'site_visits') {
          const ids = res.data.data.sitevisits.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.sitevisits?.length)
        }
        if (leadType === 'followups') {
          const ids = res.data.data.followups.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.followups?.length)
        }
        if (leadType === 'booking_only') {
          const ids = res.data.data.bookingOnly.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.bookingOnly?.length)
        }
        if (leadType === 'final_negotiation') {
          const ids = res.data.data.finalNegotiation.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.finalNegotiation?.length)
        }
        if (leadType === 'eoi_only') {
          const ids = res.data.data.eoiOnly.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.eoiOnly?.length)
        }
        if (leadType === 'dead_leads') {
          const ids = res.data.data.deadLeads.map(l => l.id)
          localStorage.setItem('leadIds', JSON.stringify(ids))
          setLeadCount(res.data.data.deadLeads?.length)
        }
        if (sort && showSortToaster) {
          toast.success(`${columnEnum[col] ? columnEnum[col] : col} sorted  ${sort === 'asc' ? 'A-Z' : 'Z-A'}`);
          setShowSortToaster(false);
        }
        if (filters.length === 1 && showFilterToaster) {
          toast.success("Data Filtered");
          setShowFilterToaster(false);
        }
        if (filters.length > 1 && showFilterToaster) {
          toast.success("All Filter applied ");
          setShowFilterToaster(false);
        }

      }
    });
    setLoading(false);
  };

  const getAllDataJobs = () => {
    getAllJobs()
      .then((res) => {
        if (res.data.status === 200) {
          // setMappedLeads(res.data.data)
          const mappedData = res.data.data.map(item => ({ ...item, label: item.filename, value: item.job_id }))
          setCSVList(mappedData)
        }
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  const handleLeadTypeChange = (leadType) => {
    localStorage.setItem('leadType', leadType);
    localStorage.removeItem('currentPage');
    localStorage.removeItem('rowIndex');
    if (leadType !== 'dead_leads' && col === 'dead_lead_date') {
      setCol("")
      setSort("")
      localStorage.removeItem('sort');
      localStorage.removeItem('col');
      toast.success('Sort removed')
    }
    setShowingResults(localStorage.getItem('showingResults') || 100);
    setCurrentPage(1);
    setLeadType(leadType);
    if (leadType === 'all_leads') {
      const totalPages = Math.ceil(
        Number(count?.leadsCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.leadsCount);
    }
    if (leadType === 'new_leads') {
      const totalPages = Math.ceil(
        Number(count?.newLeadsCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.newLeadsCount)
    }
    if (leadType === 'bookings') {
      const totalPages = Math.ceil(
        Number(count?.bookingLeadsCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.bookingLeadsCount)
    }
    if (leadType === 'site_visits_scheduled') {
      const totalPages = Math.ceil(
        Number(count?.siteVisitScheduledCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.siteVisitScheduledCount)
    }
    if (leadType === 'site_visits_completed') {
      const totalPages = Math.ceil(
        Number(count?.siteVisitCompletedCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.siteVisitCompletedCount)
    }
    if (leadType === 'followups') {
      const totalPages = Math.ceil(
        Number(count?.followupsCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.followupsCount)
    }
    if (leadType === 'booking_only') {
      const totalPages = Math.ceil(
        Number(count?.bookingsOnlyCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.bookingsOnlyCount)
    }
    if (leadType === 'final_negotiation') {
      const totalPages = Math.ceil(
        Number(count.finalNegotiationCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count.finalNegotiationCount);
    }
    if (leadType === 'eoi_only') {
      const totalPages = Math.ceil(
        Number(count?.eoiOnlyCount) / showingResults
      );
      setMaxPage(totalPages);
      setLeadsCount(count?.eoiOnlyCount)
    }

    setCurrentPage(parseInt(localStorage.getItem('currentPage')) || 1);
    // setText("");
    // setFilters([]);
  };

  const removeFilterValue = (keyValue) => {
    let oldFilters = filters;
    let newFilters = []
    for (const filterData of oldFilters) {
      // Check filter values and select element at pos 0 to compare with selected key
      if (!keyValue.includes(Object.keys(filterData)[0])) {
        newFilters.push(filterData);
      }
    }
    if (newFilters.length > 0) {
      localStorage.setItem('filter', JSON.stringify(newFilters))
    } else {
      localStorage.removeItem('filter')
    }
    setFilters(newFilters);
  }

  const handleTableValuesSelection = (e) => {
    if (showMerge && e.length > 2) {
      toast.error("Only Two leads can be merged!")
      return false
    }
    setSelectedTableValues(e)
  }

  const postDeleteLead = (selectedValue) => {
    setDeleteLeading(true);
    deleteLeads(selectedValue).then(res => {
      if (res.status === 200) {
        toast.success("Lead Deleted");
        getAllLeadsCount()
        getLeadsData();
        setDeleteLeading(false);
      }
    }).catch(e => {
      toast.error(e.response.data.message)
      setDeleteLeading(false);
    })
  }

  const handleDeleteLeads = async () => {
    await postDeleteLead(selectedTableValues)
    setShowDeleteModal(false)
    resetDataMods()
  }

  const handleReassignLeads = () => {
    setShowReassignModal(true)
  }

  const handleMergeLeads = () => {
    mergeLeads(selectedTableValues,).then(res => {
      if (res.status === 200) {
        toast.success("Lead Merged");
        getAllLeadsCount();
        getLeadsData()
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleReassignModalSubmit = (userDetails, remarks) => {
    let user_array = [];
    if (userDetails?.length > 0) {
      user_array = userDetails.map(u => u.value)
    }
    reassignLeads(selectedTableValues, userDetails?.length ? user_array : userDetails.value, remarks).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned")
        getLeadsData()
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleSourceChange = (source) => {
    const payload = {
      uuid: selectedTableValues,
      source_id: source.value
    }
    setSourceLoading(true)
    bulkSourceChange(payload).then(res => {
      if (res.status === 200) {
        toast.success(res.data.message)
        getLeadsData();
        setSourceLoading(false)
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleTeamReassignModalSubmit = (userDetails, remarks) => {
    let user_array = [];
    if (userDetails?.length > 0) {
      user_array = userDetails.map(u => u.value)
    }
    reassignLeads(selectedTableValues, userDetails?.length ? user_array : userDetails.value, remarks).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned")
        getAllLeadsCount();
        getLeadsData()
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleSiteVisitEventFilter = useCallback((filterType, eventType) => {
    localStorage.setItem('leadType', filterType);
    localStorage.setItem('sitevisit_event', eventType);
    setLeadType(filterType);
    setSitevisitEventFilter(eventType);
  });

  const removeTabsFilter = useCallback(() => {
    setSitevisitEventFilter();
    // setFollowupEventFilter();
    localStorage.removeItem('sitevisit_event');
    // localStorage.removeItem('followup_event');
  }, [])

  const getUsersAuthRolePermissions = () => {
    getAuthRolePermissions().then((res) => {
      res.data.data.map((role) => {
        if (role.module === "leads") {
          setReAssignRole(role.permissions)
        }
        if (role.module === "projects") {
          setProjectPermission(role.permissions)
        }
      })
    })
  }

  const getAgencyData = async () => {
    await getCompanyAgency().then(res => {
      if (res.status === 200) {
        setAgencyList(res.data.data)
      }
    }).catch((err) => {
      console.log("Something went wrong", err);
    });
  }

  const resetDataMods = () => {
    setShowCheck(false);
    setSelectedTableValues([]);
    setSelectedTableData([]);
    setShowReassignModal(false);
    setShowMerge(false);
    setShowDelete(false);
    setShowReassign(false);
    setShowBulkLeadShare(false);
    setShowReassignToTeam(false);
    setShowTeamReassign(false);
    setShowSourceChangeModal(false);
    setShowBulkLeadSourceChange(false);
  }

  useEffect(() => {
    if (Object.keys(companyConfig).length > 0) {
      setIntentStatuses(companyConfig.Intent);
      setSourceStatuses(companyConfig.Source);
      setStatuses(companyConfig.Status);
      setProject(companyConfig.Project);
    }
  }, [companyConfig]);

  useEffect(() => {
    setCurrentPage(parseInt(localStorage.getItem('currentPage')) || 1);
  }, [showingResults]);

  useEffect(() => {
    if (allLeadsColumn?.length > 0) {
      getLeadsData();
    }
  }, [currentPage, showingResults, sort, filters, leadTopFilters, col, text, allLeadsColumn, leadType, sitevisitEventFilter, selectedTab, selectedBookingTab]);

  useEffect(() => {
    if (allLeadsColumn?.length > 0) {
      handleLeadTypeChange(leadType);
    }
  }, [showingResults, sort, filters, leadTopFilters, col, text, allLeadsColumn, leadType]);

  useEffect(() => {
    if (allLeadsColumn?.length > 0) {
      getAllLeadsCount();
      // getExportLeadsData();
    }
  }, [filters, text, leadTopFilters, allLeadsColumn, leadType, sitevisitEventFilter, selectedTab, selectedBookingTab]);

  const getUsersColumnPreferences = useCallback(() => {
    getCurrentUserColumnPreference().then(res => {
      if (res.data.status === 200 && res.data.data.length > 0) {
        let dashboardColumns = []
        res.data.data.forEach(items => {
          let columnsList = [];
          let selectedColumn = [];

          items.columns.forEach(column => {
            if (column.is_selected) {
              selectedColumn.push(column);
            }

            if (!column.is_selected) {
              columnsList.push(column);
            }
          })

          // adding column according to tab with sorting
          dashboardColumns.push({
            tab: items.tab,
            columns: {
              selected: selectedColumn.sort((a, b) => a.placement - b.placement),
              notSelected: columnsList
            }
          })

          // data for table columns
          if (items.tab === 'New') {
            setNewLeadsColumn(selectedColumn)
          }
          if (items.tab === 'All') {
            setAllLeadsColumn(selectedColumn)
          }
          if (items.tab === 'Site Visits') {
            setSitevisiteLeadColumn(selectedColumn)
          }
          if (items.tab === 'Booking') {
            setBookingLeadsColumn(selectedColumn)
          }
          if (items.tab === 'Followups') {
            setFollowupLeadsColumn(selectedColumn)
          }
          if (items.tab === 'Dead') {
            setDeadLeadsColumn(selectedColumn)
          }
          if (items.tab === 'Final Negotiation') {
            setFinalNegotiationLeadsColumn(selectedColumn)
          }
        })

        // data for customise column
        setDashboardColumns(dashboardColumns);
      }
    })
  }, [])

  /**
    * Updating re-arranged list items
    */
  const updateColumnList = useCallback((tab, list) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    const itemColumn = columnClone[index].columns
    columnClone[index].columns = { ...itemColumn, selected: list }
    setDashboardColumns([...columnClone]);
  }, [dashboardColumns]);

  const handleChangeSelection = useCallback((tab, selected, notSelected) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    columnClone[index].columns = { selected, notSelected }
    setDashboardColumns([...columnClone]);
  }, [dashboardColumns]);

  const getFbFormData = async () => {
    const res = await getUniqueFacebookForms();
    if (res.data.status === 200) {
      const list = res.data.data.map(item => ({ ...item, label: item.form_name, value: item.form_id }))
      setFbFormList(list)
    }
  }

  const getAllUsers = () => {
    getUsersForSettings().then(res => {
      if (res.status === 200) {
        const userData = res.data.data.map((data) => {
          return {
            label: data.name,
            value: data.id
          }
        })
        // setUsersForAssigned(usersForAssign);
        setAllUsers(userData)
      }
    })
  }

  const getCallerIds = async () => {
    const res = await getKnowlarityIvrCallerIdByCompanyId();
    if (res.data.status === 200) {
      const formatedData = res.data.data.map(item => ({ label: item.caller_id, value: item.uuid }))
      setCallerList(formatedData)
    }
  }

  useEffect(() => {
    getUsersAuthRolePermissions();
    getAgencyData();
    initLoadData();
    getUsersColumnPreferences();
    getDeadReasonsStatus();
    getAllDataJobs();
    getFbFormData();
    getAllUsers();
    getCallerIds();
    const load = async () => {
      const res = await getCustomerEnumsCall()
      setPropertyEnums(res.data.data);
    }
    load();
    const el = document.getElementById('main-container')
    el.classList.add('user-leads')

    return (() => el.classList.remove('user-leads'))
  }, []);

  const sortToaster = useCallback(() => {
    setShowSortToaster(true);
  }, []);

  const filterToaster = useCallback(() => {
    setShowFilterToaster(true)
  }, []);

  const setLeadName = useCallback((name) => {
    setSelectedLeadName(name)
  }, [])

  const showCreateLeadModal = useCallback(() => {
    showNewLeadModal(true);
  }, []);

  const closeCreateLeadModal = useCallback(() => {
    showNewLeadModal(false);
  }, []);

  const saveCurrentUserColumnPrefrence = useCallback(() => {
    const preference = dashboardColumns.map(items => {
      items.columns.selected.forEach((item, index) => {
        item.placement = index + 1;
      })
      return {
        tab: items.tab,
        columns: [...items.columns.selected, ...items.columns.notSelected]
      }
    })
    saveColumnPreferenceNew({ preference }).then(res => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getUsersColumnPreferences();
        // getLeadsData();
      }
    }).catch(err => {
      console.log({ err })
    })
  }, [dashboardColumns])

  const renderTableData = () => {
    switch (leadType) {
      case leadTypesEnum.ALL_LEADS:
        return (
          <LeadsTable
            leadData={allLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.leadsCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            allLeadsColumn={allLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.NEW_LEADS:
        return (
          <NewLeadsTable
            leadData={newLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.newLeadsCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            newLeadsColumn={newLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.FOLLOWUPS:
        return (
          <FollowupsTable
            leadData={followupLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.followupsCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            followupLeadsColumn={followupLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}

          />
        );
      case leadTypesEnum.SITE_VISITS_SCHEDULE:
        return (
          <SiteVisitTable
            leadData={svScheduleLeads}
            col={col}
            filters={[...filters, ...leadTopFilters]}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            count={count.siteVisitScheduledCount}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            searchText={text}
            reAssignRole={reAssignRole}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            sitevisitLeadColumn={sitevisitLeadColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.SITE_VISITS_COMPLETE:
        return (
          <SiteVisitCompleteTable
            leadData={svCompleteLeads}
            col={col}
            filters={[...filters, ...leadTopFilters]}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            count={count.siteVisitCompletedCount}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            searchText={text}
            reAssignRole={reAssignRole}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            sitevisitLeadColumn={sitevisitLeadColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.FINAL_NEGOTIATION:
        return (
          <FinalNegotiationTable
            leadData={finalNegotiationLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.finalNegotiationCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            finalNegotiationLeadsColumn={finalNegotiationLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.BOOKINGS:
        return (
          <BookingsTable
            leadData={bookingLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.bookingLeadsCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            bookingLeadsColumn={bookingLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.NEWBOOKINGS:
        return (
          <NewBookingsTable
            leadData={bookingOnlyLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.bookingsOnlyCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            bookingLeadsColumn={bookingLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.NEWEOI:
        return (
          <NewEoiTable
            leadData={eoiOnlyLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.eoiOnlyCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            bookingLeadsColumn={bookingLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      case leadTypesEnum.DEAD_LEAD:
        return (
          <DeadLeadsTable
            leadData={deadLeads}
            filters={[...filters, ...leadTopFilters]}
            col={col}
            startIndex={startIndex}
            showCheck={showCheck}
            updateData={() => {
              getAllLeadsCount()
              getLeadsData()
            }}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={handleTableValuesSelection}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            handleUserDeletion={(id) => {
              postDeleteLead([id])
            }}
            handleUserReassignLead={(id) => {
              setSelectedTableValues([id])
              handleReassignLeads()
            }}
            columns={columns}
            count={count.deadLeadsCount}
            searchText={text}
            reAssignRole={reAssignRole}
            sort={sort}
            intentStatuses={intentStatuses}
            statuses={statuses}
            sourceStatuses={sourceStatuses}
            setLeadName={setLeadName}
            selectedLeadName={selectedLeadName}
            agencyList={agencyList}
            showCreateLeadModal={showCreateLeadModal}
            deadLeadsColumn={deadLeadsColumn}
            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
            dashboardColumns={dashboardColumns}
            handleChangeSelection={handleChangeSelection}
            updateColumnList={updateColumnList}
            allowedPermissions={allowedPermissions}
            userProfile={userProfile}
            isRolesModule={isRolesModule}
            callerList={callerList}
          />
        );
      default: return (
        <Skeleton count={2} />
      )
    }
  };

  const renderFilterList = (filterLists) => {
    if (enums[Object.keys(filterLists)[0]] === 'City') {
      return filterLists.City.map((item, index) => (
        <li key={`${item.label}_${index}`}>{item.label}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Intent') {
      return (
        intentStatuses.map(item => {
          return filterLists.Intent.map((intent, index) => {
            if (intent === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Status') {
      return (
        statuses.map(item => {
          return filterLists.Status.map((status, index) => {
            if (status === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Facebook Forms') {
      return (
        fbFormList.map(item => {
          return filterLists['Form Id'].map((id, index) => {
            if (id === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'SV Type') {
      return (
        svTypes.map(item => {
          return filterLists.sv_type.map((type, index) => {
            if (type === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'import_job_id') {
      return (
        csvList.map(item => {
          return filterLists.import_job_id.map((jobId, index) => {
            if (jobId === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Project') {
      return (
        project.map(item => {
          return filterLists.Project.map((proj, index) => {
            if (proj === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Source') {
      return (
        sourceStatuses.map(item => {
          return filterLists.Source.map((src, index) => {
            if (src === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Re-Engaged Source') {
      return (
        sourceStatuses.map(item => {
          return filterLists.re_engage_sources.map((src, index) => {
            if (src === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Purpose') {
      return (
        propertyEnums.enum_purpose.map(item => {
          return filterLists.purpose.map((prps, index) => {
            if (prps === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Funding Source') {
      return (
        propertyEnums.enum_funding_source.map(item => {
          return filterLists.funding_source.map((src, index) => {
            if (src === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Facing') {
      return (
        propertyEnums.enum_facing.map(item => {
          return filterLists.facing.map((fc, index) => {
            if (fc === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Possession Preference') {
      return (
        propertyEnums.enum_possession.map(item => {
          return filterLists.possession.map((ps, index) => {
            if (ps === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Industry') {
      return (
        propertyEnums.enum_industry.map(item => {
          return filterLists.industry.map((ind, index) => {
            if (ind === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Not Connected Reason') {
      return (
        NotConnectedReasonsList.map(item => {
          return filterLists.not_connected_reason.map((ind, index) => {
            if (ind === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'In Progress Reason') {
      return (
        InProgessReasonsList.map(item => {
          return filterLists.in_progress_reason.map((ind, index) => {
            if (ind === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'SV Cancellation Reason') {
      return (
        SVCancelledReasonList.map(item => {
          return filterLists.sv_cancellation_reason.map((ind, index) => {
            if (ind === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Requirement Type') {
      return (
        propertyEnums.enum_property_type.map(item => {
          return filterLists.property_type.map((requirement, index) => {
            if (requirement === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Employment Type') {
      return filterLists.employment_type.map((item, index) => (
        <li key={`${item}_${index}`}>{item}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Broker Company') {
      return filterLists.broker_company_id.map((item, index) => (
        <li key={`${item}_${index}`}>{item.name}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Customer Address') {
      return <li>{filterLists.customer_address}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Preferred Location') {
      return <li>{filterLists.preferred_location}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Referred By') {
      return <li>{filterLists.referred_by}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Broker Code') {
      return <li>{filterLists.broker_user_code}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Configuration Type') {
      return <li>{filterLists.configuration}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Property Type') {
      return (
        propertyEnums.enum_property_category.map(item => {
          return filterLists.property_category.map((category, index) => {
            if (category === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Dead Reasons') {
      return (
        reasons.map(item => {
          return filterLists['Dead Reasons'].map((reason, index) => {
            if (reason === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Followup Date') {
      return <li>Followup Date</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Booking Date') {
      return <li>Booking Date</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Eoi Date') {
      return <li>
        Eoi Date
      </li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'SV Schedule Date') {
      return <li>SV Schedule Date</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'SV Complete Date') {
      return <li>SV Complete Date</li>
    }
    if ((Object.keys(filterLists)[0] === 'Assigned To' && enums['Assigned User']) === 'Assigned User') {
      return filterLists['Assigned To'].map((item, index) => (
        <li key={`${item.value}_${index}`}>{item.value}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Created Date') {
      return <>
        <li className="mb-1">From: {moment(filterLists['Created Date'].From).format("Do MMM YYYY")} </li>
        <li>To:  {moment(filterLists['Created Date'].To).format("Do MMM YYYY")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Re assign Date') {
      return <>
        <li className="mb-1">From: {moment(filterLists['re_assign_date'].From).format("Do MMM YYYY")} </li>
        <li>To:  {moment(filterLists['re_assign_date'].To).format("Do MMM YYYY")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Re engage Date') {
      return <>
        <li className="mb-1">From: {moment(filterLists['re_engage_date'].From).format("Do MMM YYYY")} </li>
        <li>To:  {moment(filterLists['re_engage_date'].To).format("Do MMM YYYY")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Created Time') {
      return <>
        <li className="mb-1">From: {moment(filterLists['Created Time'].From).format("Do MMM YYYY, hh:mm a")} </li>
        <li>To:  {moment(filterLists['Created Time'].To).format("Do MMM YYYY, hh:mm a")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Assigned Date') {
      return <>
        <li className="mb-1">From: {moment(filterLists['Updated Date'].From).format("Do MMM YYYY")} </li>
        <li>To:  {moment(filterLists['Updated Date'].To).format("Do MMM YYYY")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Name') {
      return filterLists.Name.map((item, index) => (
        <li key={`${item.value}_${index}`}>{item.value}</li>
      ))

    }
    if (enums[Object.keys(filterLists)[0]] === 'Shared With') {
      return (
        allUsers.map(item => {
          return filterLists.shared_with.map((shared, index) => {
            if (shared === item.value) {
              return <li key={`${item.label}_${index}`}>{item.label}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Marketing Agency') {
      return (
        agencyList.map(item => {
          return filterLists['Marketing Agency'].map((agency, index) => {
            if (agency === item.id) {
              return <li key={`${item.label}_${index}`}>{item.name}</li>
            }
          })
        })
      )
    }
    if (enums[Object.keys(filterLists)[0]] === 'Mobile No') {
      return filterLists['Mobile No.'].map((item, index) => (
        <li key={`${item.value}_${index}`}>{item.value}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Size') {
      return <li>{filterLists.size.min}-{filterLists.size.max} {filterLists.size.unit.value}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Budget') {
      return <>
        {filterLists.budget?.min?.label && filterLists.budget?.max?.label && <li>{filterLists.budget.min.label} - {filterLists.budget.max.label}</li>}
        {filterLists.budget?.min?.label && !filterLists.budget?.max?.label && <li>{filterLists.budget.min.label}</li>}
        {!filterLists.budget?.min?.label && filterLists.budget?.max?.label && <li>{filterLists.budget.max.label}</li>}
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Email') {
      return <li>{filterLists.email}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Alt. Mobile') {
      return <li>{filterLists.alternate_mobile}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Re-Engaged') {
      return <li>{filterLists.is_re_engagement && 'Re-engaged'}</li>
    }

    if (enums[Object.keys(filterLists)[0]] === 'Site Visit Completed Leads') {
      return <li>{filterLists.is_site_visit_done && 'Site Visit Completed Leads'}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Hide Team Leads') {
      return <li>{filterLists.hide_team_leads && 'Hide Team Leads'}</li>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Marked Date') {
      return <>
        <li className="mb-1">From: {moment(filterLists['status_changed_on'].From).format("Do MMM YYYY, hh:mm a")} </li>
        <li>To:  {moment(filterLists['status_changed_on'].To).format("Do MMM YYYY, hh:mm a")}</li>
      </>
    }
    if (enums[Object.keys(filterLists)[0]] === 'Marked By') {
      return filterLists['status_changed_by'].map((item, index) => (
        <li key={`${item.value}_${index}`}>{item.value}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Token Cancellation') {
      return filterLists['eoi_cancellation_reason'].map((reason, index) => (
        <li key={`${reason}_${index}`}>{reason}</li>
      ))
    }
    if (enums[Object.keys(filterLists)[0]] === 'Booking Cancellation') {
      return filterLists['booking_cancellation_reason'].map((reason, index) => (
        <li key={`${reason}_${index}`}>{reason}</li>
      ))

    }

    if (enums[Object.keys(filterLists)[0]] === 'Sitevisit Confirmed') {
      return <li>{filterLists['is_confirmed'] ? 'Yes' : 'No'}</li>
    }

    // filterLists?.entries(([key, value]) => {
    //   console.log(key, value)
    // })
  }

  const styles = {
    backgroundImage: `url(${subscriptionStrip})`,
    backgroundSize: 'cover',
    backgroundPositon: 'left center'
  };

  return (
    <>
      <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
        {showReassignModal && <ReassignLeadModal
          setShow={setShowReassignModal}
          show={showReassignModal}
          handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
          count={selectedTableValues?.length}
          selectedLeadName={selectedLeadName}
        />}

        {/* Source change */}
        {showSourceChangeModal && <BulkSourceChange
          setShow={setShowSourceChangeModal}
          show={showSourceChangeModal}
          handleSubmit={(source) => handleSourceChange(source)}
          count={selectedTableValues?.length}
          selectedLeadName={selectedLeadName}
          sourceList={companyConfig?.Source}
          loading={sourceLoading}
        />}

        {/* ReassignTeamLeadModal */}
        {
          showReassignToTeam &&
          <ReassignLeadToTeamAndUserModal
            setShow={setShowReassignToTeam}
            show={showReassignToTeam}
            handleSubmit={(userId, remarks) => handleTeamReassignModalSubmit(userId, remarks)}
            count={selectedTableData?.length}
            selectedLeadName={selectedLeadName}
            selectedTableData={selectedTableData}
            setSelectedTableData={setSelectedTableData}
            setSelectedTableValues={setSelectedTableValues}
            resetDataMods={resetDataMods}
            selectedTableValues={selectedTableValues}
            getLeadsData={getLeadsData}
            getAllLeadsCount={getAllLeadsCount}
          />
        }
        {showBulkLeadShareModal &&
          <BulkLeadShareModal
            show={showBulkLeadShareModal}

            closeModal={() => {
              setShowBulkLeadShareModal(false)
              // setSelectedItem({})
            }}
            count={selectedTableValues?.length}
            selectedLeadName={selectedLeadName}
            resetDataMods={resetDataMods}
            selectedTableValues={selectedTableValues}
            getLeadsData={getLeadsData}
            getAllLeadsCount={getAllLeadsCount}
          />
        }
        <LeadsTopBar
          setShowCheck={setShowCheck}
          handleLeadTypeChange={handleLeadTypeChange}
          setText={setText}
          text={text}
          col={col}
          setCol={setCol}
          sort={sort}
          setSort={setSort}
          filters={filters}
          setFilters={setFilters}
          setLeadToFilters={setLeadToFilters}
          leadTopFilters={leadTopFilters}
          sortToaster={sortToaster}
          setCurrentPage={setCurrentPage}
          updateLeads={getLeadsData}
          getAllLeadsCount={getAllLeadsCount}
          showMerge={showMerge}
          setShowMerge={setShowMerge}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          showReassign={showReassign}
          showCheck={showCheck}
          setShowReassign={setShowReassign}
          handleDeleteClicked={handleDeleteLeads}
          handleMergeClicked={handleMergeLeads}
          handleReassignClicked={handleReassignLeads}
          handleTeamReassignClicked={() => setShowReassignToTeam(true)}
          selectedTableValues={selectedTableValues}
          leadType={leadType}
          count={count}
          reAssignRole={reAssignRole}
          refreshTime={refreshTime}
          setSelectedTableValues={setSelectedTableValues}
          setSelectedTableData={setSelectedTableData}
          selectedTableData={selectedTableData}
          filterToaster={filterToaster}
          projectPermission={projectPermission}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          newLeadModal={newLeadModal}
          closeCreateLeadModal={closeCreateLeadModal}
          propertyEnums={propertyEnums}
          allowedPermissions={allowedPermissions}
          userProfile={userProfile}
          isRolesModule={isRolesModule}
          setShowTeamReassign={setShowTeamReassign}
          showTeamReassign={showTeamReassign}
          showBulkLeadShare={showBulkLeadShare}
          setShowBulkLeadShare={setShowBulkLeadShare}
          handleBulkLeadsClicked={() => setShowBulkLeadShareModal(true)}
          setShowSourceChangeModal={setShowSourceChangeModal}
          showBulkLeadSourceChange={showBulkLeadSourceChange}
          setShowBulkLeadSourceChange={setShowBulkLeadSourceChange}
          handleLeadsSourceClicked={() => setShowSourceChangeModal(true)}
          loadingCount={loadingCount}
          getExportLeadsData={getExportLeadsData}
          exportLoading={exportLoading}
          fbFormList={fbFormList}
          leadCount={leadCount}
          setShowExportLeadModal={setShowExportLeadModal}
          showExportLeadModal={showExportLeadModal}
          renderFilterList={renderFilterList}
          deleteLeading={deleteLeading}
          loading={loading}
          removeTabsFilter={removeTabsFilter}
          handleSiteVisitEventFilter={handleSiteVisitEventFilter}
          setSelectedBookingTab={setSelectedBookingTab}
        />
        {userProfile?.company?.subscription_expiry && <div className="!px-5">
          <div style={styles} className='w-full !py-2 my-2 flex md:flex-row flex-col items-center justify-center rounded-lg text-center gap-3 md:!mt-0 !mt-2'>
            <span className='text-white sm:text-lg text-base font-semibold'>Notice: The platform will no longer be operational after {moment(userProfile?.company.subscription_expiry).format('Do MMM YYYY')}. Please plan accordingly</span>
            <button
              className='bg-white text-black text-sm font-semibold rounded-lg px-2.5 py-1 '
              onClick={() => {
                history.push('/settings/import-export/export')
              }}
            >Export Leads</button>
          </div>
        </div>}
        <div className="flex items-center">

          {(userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && (leadType === leadTypesEnum.SITE_VISITS_SCHEDULE || leadType === leadTypesEnum.SITE_VISITS_COMPLETE)) &&
            <div className="!pl-7 !pr-4 !pt-3">
              <InternalTabs
                tabList={leadType === leadTypesEnum.SITE_VISITS_SCHEDULE ? scheduleTabList : completedTabList}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabName={selectedTab}
                tabRowCount={tabRowCount}
              />
            </div>
          }
          {(userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID &&
            (leadType === leadTypesEnum.NEWBOOKINGS || leadType === leadTypesEnum.NEWEOI)) &&
            <div className="!pl-7 !pr-4 !pt-3">
              <InternalTabs
                tabList={bookingTabList}
                selectedTab={selectedBookingTab}
                setSelectedTab={setSelectedBookingTab}
                tabName={selectedBookingTab}
                tabRowCount={tabRowCount}
              />
            </div>
          }
          {(userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && (leadType === leadTypesEnum.SITE_VISITS_SCHEDULE || leadType === leadTypesEnum.SITE_VISITS_COMPLETE)) && filters.length > 0 && <div className="border-r-2 !h-8"></div>}
          {
            filters.length > 0 ? <div className="all-leads !h-auto filter-applyed pb-2 d-flex align-items-center md:!pl-5 !pl-4 !pt-2">
              <span className="">Filtered by: &nbsp;</span>
              {/* {document.getElementById('scroll-tag')?.offsetWidth > 1100 &&
              <>
                <button className='border-0 bg-tr btn-left d-md-inline d-none' onClick={scrollRight}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                </button>
                <button className='border-0 bg-tr btn-right d-md-inline d-none' onClick={scrollLeft}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                </button>
              </>
            } */}
              <ul className="tags align-items-center pl-1" id="scroll-tag">
                <OwlCarousel
                  className='owl-theme'
                  dots={false}
                  res='true'
                  autoWidth='true'
                  loop={false}
                  navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                  margin={10}
                  responsive={{
                    0: {
                      items: 1.3,
                      nav: true,
                      margin: 0

                    },
                    600: {
                      items: 3,
                      nav: true
                    },
                    900: {
                      items: 4,
                      nav: true
                    },
                    1000: {
                      items: 5,
                      nav: true
                    },
                    1200: {
                      items: 6,
                      loop: false,
                      nav: true,
                      margin: 0

                    }
                  }
                  }>
                  {filters.map((data, index) => {
                    return (
                      <div className="item" key={String(index)}>
                        <li >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                <div className='info-msg'>
                                  <ul className="mb-0">
                                    {renderFilterList(data)}
                                    {/* <li>1. Signed up by mistake</li> */}
                                    {/* <li>2. Signed up by mistake</li> */}
                                  </ul>
                                </div>
                              </Tooltip>
                            }
                          >
                            <Button onClick={() => removeFilterValue(Object.keys(data))} className='bg-tr border-0 p-0' bsstyle="default" style={{ fontSize: 0 }}>
                              <svg className="ml-0 mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M7.02344 5.07518C7.02344 5.33376 7.12616 5.58176 7.30901 5.76461C7.49186 5.94746 7.73985 6.05018 7.99844 6.05018C8.25702 6.05018 8.50502 5.94746 8.68787 5.76461C8.87071 5.58176 8.97344 5.33376 8.97344 5.07518C8.97344 4.81659 8.87071 4.5686 8.68787 4.38575C8.50502 4.2029 8.25702 4.10018 7.99844 4.10018C7.73985 4.10018 7.49186 4.2029 7.30901 4.38575C7.12616 4.5686 7.02344 4.81659 7.02344 5.07518ZM7.35884 11.3672C7.38597 11.517 7.46487 11.6526 7.58177 11.7503C7.69867 11.8479 7.84613 11.9014 7.99844 11.9014C8.15074 11.9014 8.29821 11.8479 8.41511 11.7503C8.532 11.6526 8.6109 11.517 8.63804 11.3672L8.64844 11.2502V8.00018L8.63804 7.88318C8.6109 7.73331 8.532 7.59773 8.41511 7.5001C8.29821 7.40247 8.15074 7.34899 7.99844 7.34899C7.84613 7.34899 7.69867 7.40247 7.58177 7.5001C7.46487 7.59773 7.38597 7.73331 7.35884 7.88318L7.34844 8.00018V11.2502L7.35884 11.3672Z" fill="#92929D" />
                                <rect x="0.75" y="-0.75" width="11.5" height="11.5" rx="5.75" transform="matrix(1 0 0 -1 1.5 13)" stroke="#92929D" strokeWidth="1.5" />
                              </svg>

                            </Button>
                          </OverlayTrigger>
                          {Object.keys(data)[0] === 'Assigned To' ? enums['Assigned User'] : enums[Object.keys(data)]}
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F"></path></svg>
                        </li>
                      </div>
                    )
                  })}

                </OwlCarousel>
              </ul>
            </div> : " "
          }
        </div>
        {/* {loading || true?  */}
        {loading ?
          <div className="bg-white h-full overflow-hidden">
            <div className="lead-table all_lead-table skelton-area h-full !pl-5 !pt-1">
              <table className='table'>

                <tbody>
                  {Array(50).fill().map((data, index) => {
                    return (
                      <TableSkeleton key={String(index)} />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          : renderTableData()}

        <LeadsFooterBar
          showingResults={showingResults}
          setShowingResults={setShowingResults}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPage={maxPage}
          leadsCount={leadsCount}
          classname='!relative !basis-0 !w-full'
        />
        {showExpiryModal && <CRMExpiryModal show={showExpiryModal} closeModal={() => setShowExpiryModal(false)} userProfile={userProfile} />}
      </div>
    </>
  );
}
