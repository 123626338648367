import React, { useContext, useState } from 'react'
import { Disclosure, Switch } from '@headlessui/react'
import { Formik } from 'formik';
import makeUploadRequest from '../../services/api/uploadRequest';
import { useHistory, useParams } from 'react-router-dom';
import InputBox from '../InputGroup/InputBox';
import { MentorService } from '../../services/mentor';
import { WorkshopService } from '../../services/workshop'
import { toast } from 'react-toastify';

import { useEffect } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import EditModal from '../workshops/EditModal';
import TooltipText from '../Tooltip';
import InputSelect from '../InputGroup/InputSelect';
import { getAllSubscription, getS3SignedURL } from '../../services/public/public.service';
import axios from 'axios';
import Consumer from '../../helpers/context';

const EditWorkshop = () => {

  let [isOpen, setIsOpen] = useState(false)
  // let [doEdit, setDoEdit] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  let [updatedWorkshopData, setUpdatedWorkshopData] = useState({});

  let params = useParams();
  let history = useHistory();
  let [section_included, setSection_included] = useState(false);
  let [certificate_section, setCertificate_section] = useState(false);
  let [categories, setCategories] = useState([])
  let [mentors, setMentors] = useState([])
  let [workshop, setWorkshop] = useState({})
  let [mentor_opt, setMentorOpt] = useState([]);
  const [planList, setPlanList] = useState([]);
  let [selected_mentor_id, setSelectedMentorId] = useState([]);
  let [touched_mentor_id, setTouchedMentorId] = useState(false);
  let [uploadingProgress, setUploadingProgress] = useState('');
  let [fileName, setFileName] = useState('')

  const { allowedPermissions } = useContext(Consumer);

  const handleBulletChange = (e, index) => {
    let bulletData = workshop.section_included_points;
    bulletData[index] = e.target.value;
    setWorkshop({ ...workshop, section_included_points: bulletData });
  }
  const handleSummaryChange = (e, index) => {
    let bulletData = workshop.detail_card_points;
    bulletData[index] = e;
    setWorkshop({ ...workshop, detail_card_points: bulletData });
  }
  const handleFaqQuestionChange = (e, index) => {
    let bulletData = workshop.faq_questions;
    bulletData[index].question = e.target.value;
    setWorkshop({ ...workshop, faq_questions: bulletData });
  }
  const handleFaqAnswerChange = (e, index) => {
    let bulletData = workshop.faq_questions;
    bulletData[index].answer = e.target.value;
    setWorkshop({ ...workshop, faq_questions: bulletData });
  }
  const createCardPointArr = (currState, len) => {
    if (currState.length < len) {
      let arr = new Array(len - currState.length);
      return [...currState, ...arr];
    }
    return currState;
  }

  const [files, setFiles] = useState({})

  const getWorkshopBy_uuid = async (uuid) => {
    await WorkshopService.getWorkshopBy_uuid(uuid).then(res => {
      // console.log(res);
      if (res) {
        let cardPoints = createCardPointArr(res.detail_card_points, 4);
        let includedPoints = createCardPointArr(res.section_included_points, 8);
        let faqQuestions = res.faq_questions.map(data => { return ({ question: data.question, answer: data.answer }) });
        for (let i = 0; i < (parseInt(8) - res.faq_questions.length); i++) {
          faqQuestions.push({ question: '', answer: '' })
          // console.log(faqQuestions);
        }
        setFileName(res.file_name)
        setWorkshop({ ...res, detail_card_points: cardPoints, faq_questions: faqQuestions, section_included_points: includedPoints });
        // console.log({ ...res, detail_card_points: cardPoints, faq_questions: faqQuestions, section_included_points: includedPoints });
        setSection_included(res.section_included);
        setCertificate_section(res.certificate_section);
        let arr_selected_mentor = mentor_opt.filter(data => (res.mentor_id?.find(data.value)));
        // console.log(arr_selected_mentor);
        // setSelectedMentorId(arr_selected_mentor);
        // res.section_included_points.map((pt,index)=>setSection(...section_points,section_points[index].point:pt));
        // console.log(section_points)
      }
    }).catch(e => {
      console.log(e);
      // toast.error(e.response.data)
    })
  }

  const getCategories = async () => {
    await MentorService.getAllCategories().then(res => {
      if (res.status === 200) {
        setCategories(res.data)
      }
    }).catch(e => {
      console.log(e);
      // toast.error(e.response.data)
    })
  }
  const getMentors = async () => {
    await MentorService.getAllMentors().then(res => {
      if (res.status === 200) {
        let ar2 = res.data.map((data) => { return { value: data.id, label: data.name } });
        // console.log("AR2 : ", ar2)
        setMentorOpt(ar2)
        setMentors(res.data)
      }
    }).catch(e => {
      console.log(e);
      // toast.error(e.response.data)
    })
  }

  const getPlans = async () => {
    const res = await getAllSubscription();
    if (res.data.status === 200) {
      console.log(res.data.data, 'response');
      setPlanList(res.data.data.map(i => ({ ...i, label: i.name, value: i.id })))
    }
  }

  const updateWorkshopByUUID = async (uuid, payload) => {
    const toastId = toast.loading('Uploading Workshop Data');
    return await WorkshopService.updateWorkshopByUUID(uuid, payload).then(res => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          id: toastId
        });
        window.location.href = ("/settings/learning-centre/all-workshops");
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data, {
        id: toastId
      })
    })

  }

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file)
    // setFileName(file.name);
    const response = await getS3SignedURL({
      fileName: file.name,
      fileType: file.type,
      uuid: params.uuid
    });

    if (!formData || !response.data.data.signedURL) return;

    try {
      let url = response.data.data.signedURL;

      await axios.put(url, formData, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadingProgress(percentCompleted);
          // console.log(`Upload Progress: ${percentCompleted}%`);
        }
      }).then(res => {
        console.log(res, 'response')
        console.log('File uploaded successfully!');
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }

  }
  const getAllSelectedMentorForWorskhop = async () => {
    await WorkshopService.getWorkshopMentors(params.uuid).then((res) => {
      if (res.status === 200) {
        const mentorIds = res.data.data.mentor.map((mentorData) => {
          return {
            value: mentorData.id,
            label: mentorData.name,
            // uuid: mentorData.uuid
          }
        })
        // console.log("SelectedMentorId : ", mentorIds)
        setSelectedMentorId(mentorIds)
      }
    })
  }

  useEffect(() => {
    getAllSelectedMentorForWorskhop();
    getCategories();
    getMentors();
    getPlans();
    getWorkshopBy_uuid(params.uuid);
  }, [])

  async function updateWorkshop() {
    await updateWorkshopByUUID(params.uuid, updatedWorkshopData);
  }

  return (
    <>

      {/* {isOpen && <> <EditModal
        isOpen={isOpen}
        closeModal={closeModal}
        updateWorkshop={updateWorkshop}
      /></>} */}

      <div className="w-full basis-full	h-full overflow-auto pb-8">
        <div className="title-wraper !p-6 border-b w-full sticky top-0 bg-white z-10">
          <div className="flex w-full basis-full">
            <div className='w-100 basis-6/12'>
              <h1 className='fz24 text-black fw-po-medium	'>Edit Workshop ({workshop.name})</h1>
            </div>
          </div>
        </div>
        <Formik
          initialValues={
            {
              selected_mentors: selected_mentor_id,
              name: workshop.name,
              zoom_link: workshop.zoom_link,
              card_thumbnail_temp: '',
              start_date: new Date(workshop.start_date),
              end_date: new Date(workshop.end_date),
              start_time: new Date(workshop.start_time),
              end_time: new Date(workshop.end_time),
              detail_thumbnail_temp: '',
              category: workshop.category,
              short_description: workshop.short_description,
              section_included_points: workshop.section_included_points,
              faq_questions: workshop.faq_questions,
              detail_card_points: workshop.detail_card_points,
              recording_url: workshop.recording_url
            }
          }
          enableReinitialize={true}
          validate={values => {
            const errors = {};
            if (!values.name) errors.name = 'Required';
            if (!values.zoom_link) errors.zoom_link = 'Required';
            if (!values.start_date) errors.start_date = 'Required';
            if (!values.end_date) errors.end_date = 'Required';
            if (!values.start_time) errors.start_time = 'Required';
            if (!values.end_time) errors.end_time = 'Required';
            if (!values.category) errors.category = 'Required';
            if (!values.short_description) errors.short_description = 'Required';
            // if (!values.card_thumbnail_temp) errors.card_thumbnail_temp = 'Required';
            // if (!values.detail_thumbnail_temp) errors.detail_thumbnail_temp = 'Required';
            // console.log(errors);
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let card_thumbnail = workshop.card_thumbnail;
            let detail_thumbnail = workshop.detail_thumbnail;

            if (files.hasOwnProperty("card_thumbnail_temp")) {
              const formData1 = new FormData();
              formData1.append("document_upload", files.card_thumbnail_temp)
              let card_thumbnail_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData1);
              card_thumbnail = card_thumbnail_obj.data.data.s3_url;
              // console.log("card_thumbnail_obj new image upload");
            }
            if (files.hasOwnProperty('detail_thumbnail_temp')) {
              const formData2 = new FormData();
              formData2.append("document_upload", files.detail_thumbnail_temp)
              let detail_thumbnail_obj = await makeUploadRequest("/pr0p/uploadToS3Helper", "POST", formData2);
              detail_thumbnail = detail_thumbnail_obj.data.data.s3_url;
              // console.log("card_thumbnail_obj new image upload");
            }

            let obj = {
              name: values.name,
              zoom_link: values.zoom_link,
              start_date: moment((values.start_date), "YYYY-MM-DD").toISOString(),
              end_date: moment((values.end_date), "YYYY-MM-DD").toISOString(),
              start_time: moment(`${moment(values.start_date).format('YYYY-MM-DD')} ${moment(values.start_time).format('hh:mm a')}`).toISOString(),
              end_time: moment(`${moment(values.end_date).format('YYYY-MM-DD')} ${moment(values.end_time).format('hh:mm a')}`).toISOString(),
              category: values.category,
              mentor_id: values.selected_mentors.map(id => id.value),
              short_description: values.short_description,
              section_included_points: workshop.section_included_points,
              faq_questions: workshop.faq_questions,
              detail_card_points: workshop.detail_card_points,
              recording_url: values.recording_url,
              payment_plan_id: values?.payment_plan_id || '',
              section_included, certificate_section, card_thumbnail, detail_thumbnail
            }
            // console.log(obj)
            setUpdatedWorkshopData(obj);
            openModal();
            updateWorkshopByUUID(params.uuid, obj).then(data => console.log(data));
            // addWorkshop(obj).then(data => console.log(data));
            setSubmitting(false);
          }}

        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) =>
          (
            <form className='!px-6' onSubmit={handleSubmit}>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-base text-black font-medium focus:outline-none">
                        <span>Basic Details</span>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className=" !mt-4 grid grid-cols-2 !gap-4">

                        <div>
                          <div className="form-group">
                            <InputBox
                              title="Workshop Name"
                              name="name"
                              error={errors.name}
                              touched={touched.name}
                              placeholder="e.g. Marketing Workshop"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.name}
                            />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <InputBox
                              title="Zoom Link"
                              name="zoom_link"
                              error={errors.zoom_link}
                              touched={touched.zoom_link}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.zoom_link}
                            />
                          </div>
                        </div>

                        <div className="...">
                          <div className="form-group">
                            <label>Workshop Start Date </label>
                            <DatePicker
                              selected={values.start_date}
                              className="form-control"
                              style={(errors.start_date && touched.start_date) && { border: "2px solid #ef4444" }}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'start_date'
                                  }
                                }
                                handleChange(e)
                              }} />
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Workshop Start time <span className="text-sky-900 font-bold"></span> </label>
                          <DatePicker
                            selected={values.start_time}
                            className="form-control" style={(errors.start_time && touched.start_time) && { border: "2px solid #ef4444" }}
                            onChange={(date) => {
                              const e = {
                                target: {
                                  value: date,
                                  name: 'start_time'
                                }
                              }
                              handleChange(e)
                            }
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />

                        </div>

                        <div>
                          <div className="form-group">
                            <label>Workshop End Date </label>
                            <DatePicker selected={values.end_date} className="form-control" style={(errors.end_date && touched.end_date) && { border: "2px solid #ef4444" }}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'end_date'
                                  }
                                }
                                handleChange(e)
                              }} />
                          </div>
                        </div>

                        <div>
                          <div className="form-group">
                            <label>Workshop End time <span className="text-sky-900 font-bold"></span></label>
                            <DatePicker
                              selected={values.end_time}
                              className="form-control" style={(errors.end_time && touched.end_time) && { border: "2px solid #ef4444" }}
                              onChange={(date) => {
                                const e = {
                                  target: {
                                    value: date,
                                    name: 'end_time'
                                  }
                                }
                                handleChange(e)
                              }
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>

                        <div>
                          <div className="form-group">
                            <label>
                              Card Thumbnail (Less than 1mb, 267 X 168px)
                              <span className="font-bold text-red-500">{errors.picture_url && touched.picture_url && errors.picture_url} </span>
                              <span className="text-textblack font-semibold">{"Uploaded"}</span>
                              <img src={workshop.card_thumbnail} height="100px" width="100px" />
                            </label>
                            <input type="file"
                              className='h-[45px] w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-sm placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                              placeholder=' '
                              id="card_thumbnail_temp"
                              name="card_thumbnail_temp"
                              style={(errors.card_thumbnail_temp && touched.card_thumbnail_temp) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                setFiles({ ...files, [e.target.name]: e.target.files[0] })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.card_thumbnail_temp}
                            />
                          </div>
                        </div>
                        <div className='grid grid-cols-2 gap-9 col-span-2 !mt-4 !mb-4'>
                          <div className="form-group">
                            <label>
                              Workshop Detail Thumbnail <span className="text-textblack font-semibold">{"Uploaded"}</span>
                              <img src={workshop.detail_thumbnail} height="100px" width="100px" />
                            </label>
                            <input type="file"
                              id="detail_thumbnail_temp"
                              className='h-[45px] w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-sm placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                              placeholder=' '
                              name="detail_thumbnail_temp"
                              style={(errors.detail_thumbnail_temp && touched.detail_thumbnail_temp) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                setFiles({ ...files, [e.target.name]: e.target.files[0] })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.detail_thumbnail_temp} />
                          </div>
                        </div>

                        <div className="...">
                          <div className="form-group">
                            <InputSelect
                              label={'Workshop Category'}
                              defaultValue={[]}
                              isMulti
                              name="category"
                              placeholder={'Select Category'}
                              options={mentor_opt}
                              className="basic-multi-select form-control"
                              classNamePrefix="select"
                              value={values.category}
                              style={(errors.category && touched.category) && { border: "2px solid #ef4444" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {/* <label>Workshop Category <span className="font-bold text-red-500">{errors.category && touched.category && errors.category}</span></label>
                              <select className='form-control' id=""
                                style={(errors.category && touched.category) && { border: "2px solid #ef4444" }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.category}
                                name="category"
                              >
                                <option value="">Select Category</option>
                                {categories && categories.map(category => <option value={category}>{category}</option>)}
                              </select> */}
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <InputBox
                            title="Workshop Recording URL"
                            name="recording_url"
                            error={errors.recording_url}
                            touched={touched.recording_url}
                            placeholder="e.g. Workshop Recording URL"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.recording_url}
                          />
                        </div> */}
                        {allowedPermissions?.includes('recording_upload') &&
                          <div className="form-group">
                            <label>
                              Upload Workshop Recording URL
                              {uploadingProgress && <span className="ml-2">{`Uploading ${uploadingProgress}%`}</span>}
                              {/* <img src={workshop.card_thumbnail} height="100px" width="100px" /> */}
                            </label>
                            <input type="file"
                              className='h-[45px] w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-sm placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500'
                              placeholder=' '
                              id="workshop_recording"
                              name="workshop_recording"
                              onChange={(e) => {
                                handleUpload(e)
                              }}
                            // value={fileName}
                            />
                            {fileName && <span className="block font-medium mt-2">Uploaded file: {fileName}</span>}
                          </div>
                        }
                        <div className="...">
                          <div className="form-group">
                            <label>Mentor <span className="font-bold text-red-500">{values && values.selected_mentors.length === 0 && touched.selected_mentors && "Required"}</span></label>
                            <InputSelect
                              value={values.selected_mentors}
                              isMulti
                              name="selected_mentors"
                              options={mentor_opt}
                              className="basic-multi-select form-control"
                              classNamePrefix="select"
                              style={(values.selected_mentors.length === 0 && touched.selected_mentors) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                const changeVal = {
                                  target: {
                                    value: e,
                                    name: "selected_mentors"
                                  }
                                }
                                // console.log(e);
                                handleChange(changeVal)
                              }
                              }
                              onBlur={() => setTouchedMentorId(true)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Plan </label>
                          <InputSelect
                            value={values.payment_plan_id ? planList.find(i => i.id === values.payment_plan_id) : ''}
                            name="payment_plan_id"
                            options={planList}
                            className="basic-multi-select form-control"
                            classNamePrefix="select"
                            // style={(values.selected_mentors.length === 0 && touched.selected_mentors) && { border: "2px solid #ef4444" }}
                            onChange={(e) => {
                              const changeVal = {
                                target: {
                                  value: e.id,
                                  name: "payment_plan_id"
                                }
                              }
                              // console.log(e);
                              handleChange(changeVal)
                            }
                            }
                          // onBlur={() => setTouchedMentorId(true)}
                          />
                        </div>

                        <div className="form-group col-span-2">
                          <label>Short Description <span className="font-bold text-red-500">{errors.short_description && touched.short_description && errors.short_description}</span></label>
                          <textarea id="" cols="2" rows="10" className='form-control' placeholder='Add short description'
                            style={(errors.short_description && touched.short_description) && { border: "2px solid #ef4444" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description}
                            name="short_description"
                          ></textarea>
                        </div>

                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className='w-10/12 border-b py-2.5 flex mb-2.5'>
                <div className='w-3/12'>
                  <h2 className='text-base text-black font-medium'>What’s included Section</h2>
                </div>
                <div className='w-4/12'>
                  <div className="">
                    <Switch
                      checked={section_included}
                      onChange={() => setSection_included(!section_included)}
                      onBlur={handleBlur}
                      className={`${section_included ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${section_included ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-base text-black font-medium focus:outline-none">
                        <div className='flex items-center'>
                          What you’ll learn in the workshop
                          <div className='info-hover ml-2'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#92929D" />
                              <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#92929D" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#92929D" />
                            </svg> */}
                            <div className="hover-data drop-shadow-default">
                              <TooltipText title='Even number of inputs is advised'>
                                <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#0062FF" />
                                  <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#0062FF" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#0062FF" />
                                </svg>
                              </TooltipText>

                            </div>
                          </div>
                        </div>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="!mt-4">
                        {workshop.section_included_points &&
                          <div className="form-group grid grid-cols-2 !gap-4">
                            {workshop.section_included_points.map((pts, index) =>
                              <div className="">
                                <label>Bullet Point ({index + 1})</label>
                                <input type="text" className='form-control' value={pts} onChange={(e) => {
                                  handleBulletChange(e, index);
                                }
                                } placeholder='' />
                              </div>)}
                          </div>
                        }
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className='w-10/12 border-b py-2.5 flex mb-2.5'>
                <div className='w-3/12'>
                  <h2 className='text-base text-black font-medium'>Certificate Section </h2>
                </div>
                <div className='w-4/12'>
                  <div className="">
                    <Switch
                      checked={certificate_section}
                      onChange={() => setCertificate_section(!certificate_section)}
                      onBlur={handleBlur}
                      className={`${certificate_section
                        ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span className="sr-only">Enable notifications</span>
                      <span
                        className={`${certificate_section
                          ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-base text-black font-medium focus:outline-none">
                        <div className='flex items-center'>
                          FAQ Fold
                          <div className='info-hover ml-2'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#92929D" />
                              <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#92929D" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#92929D" />
                            </svg> */}
                            <div className="hover-data drop-shadow-default">
                              <TooltipText title='Even number of inputs is advised'>
                                <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M8.6665 10.6667V7.33335C8.6665 6.96516 8.36803 6.66669 7.99984 6.66669C7.63165 6.66669 7.33317 6.96516 7.33317 7.33335V10.6667C7.33317 11.0349 7.63165 11.3334 7.99984 11.3334C8.36803 11.3334 8.6665 11.0349 8.6665 10.6667Z" fill="#0062FF" />
                                  <path d="M7.33317 5.33335C7.33317 4.96516 7.63165 4.66668 7.99984 4.66668C8.36803 4.66668 8.6665 4.96516 8.6665 5.33335C8.6665 5.70154 8.36803 6.00002 7.99984 6.00002C7.63165 6.00002 7.33317 5.70154 7.33317 5.33335Z" fill="#0062FF" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M1.33317 8.00002C1.33317 4.31812 4.31793 1.33335 7.99984 1.33335C11.6817 1.33335 14.6665 4.31812 14.6665 8.00002C14.6665 11.6819 11.6817 14.6667 7.99984 14.6667C4.31793 14.6667 1.33317 11.6819 1.33317 8.00002ZM13.3332 8.00002C13.3332 5.0545 10.9454 2.66668 7.99984 2.66668C5.05432 2.66668 2.6665 5.0545 2.6665 8.00002C2.6665 10.9455 5.05432 13.3334 7.99984 13.3334C10.9454 13.3334 13.3332 10.9455 13.3332 8.00002Z" fill="#0062FF" />
                                </svg>
                              </TooltipText>

                            </div>
                          </div>
                        </div>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className="!mt-4">
                        <div>
                          {workshop.section_included_points && <div className="form-group">
                            {workshop.faq_questions.map((faq, index) => <>

                              <div className="grid grid-cols-2 !gap-4 !mt-3">
                                <div>
                                  <div className="form-group">
                                    <label>Question ({index + 1})</label>
                                    <input type="text" className='form-control' value={faq ? faq.question : ""} onChange={(e) => {
                                      handleFaqQuestionChange(e, index);
                                    }
                                    } placeholder='' />
                                  </div>
                                </div>
                                <div className="...">
                                  <div className="form-group">
                                    <label>Answer ({index + 1})</label>
                                    <input type="text" className='form-control' value={faq ? faq.answer : ""} onChange={(e) => {
                                      handleFaqAnswerChange(e, index);
                                    }
                                    } placeholder='' />
                                  </div>
                                </div>
                              </div>
                            </>
                            )
                            }
                          </div>
                          }
                          <br />
                        </div>

                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className="w-10/12" >
                <Disclosure >
                  {({ open }) => (
                    < div className=' border-b pb-3.5 mt-2.5'>
                      <Disclosure.Button className="flex w-full justify-between rounded text-base text-black font-medium focus:outline-none">
                        <span>Detail Card Summary</span>
                        <div
                          className={`${open ? 'rotate-180 transform' : ''
                            } h-5 w-5 `}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.3215L13.5774 7.74408C13.9028 7.41864 14.4305 7.41864 14.7559 7.74408C15.0814 8.06951 15.0814 8.59715 14.7559 8.92259L10.5893 13.0893C10.2638 13.4147 9.73618 13.4147 9.41074 13.0893L5.24408 8.92259C4.91864 8.59715 4.91864 8.06951 5.24408 7.74408C5.56951 7.41864 6.09715 7.41864 6.42259 7.74408L10 11.3215Z" fill="#171725" />
                          </svg>
                        </div>
                      </Disclosure.Button>
                      <Disclosure.Panel className=" !mt-4">
                        {workshop.detail_card_points &&
                          <div className="form-group grid grid-cols-2 !gap-4">
                            {workshop.detail_card_points.map((pts, index) => <div className='mt-3'>
                              <label>Bullet Point ({index + 1})</label>
                              <input type="text" className='form-control' value={pts} onChange={(e) => {
                                handleSummaryChange(e.target.value, index);
                              }
                              } placeholder='' />
                            </div>)}
                          </div>
                        }

                        {/* <div className="grid grid-cols-2 gap-9 mt-4">
                          <div>
                            <div className="form-group">
                              <label>Bullet Point</label>
                              <input type="text" className='detail_card_points form-control' placeholder='' />
                            </div>
                          </div>
                          <div className="...">
                            <div className="form-group">
                              <label>Bullet Point</label>
                              <input type="text" className='detail_card_points form-control' placeholder='' />
                            </div>
                          </div>
                        </div> */}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </div>
              <div className="w-10/12 mt-6" >
                <div className="flex">
                  <div className='text-primary border border-primary text-base font-medium rounded mr-4 px-10 py-2 cursor-pointer'
                    onClick={() => { window.location.href = '/settings/learning-centre/all-workshops'; }}
                  >Cancel</div>
                  <button
                    type="submit"
                    className='bg-primary text-base font-medium text-white px-8 py-2 rounded '
                  >Save & Review</button>
                </div>
              </div>
            </form>
          )}

        </Formik>
      </div>
    </>
  )
}

export default EditWorkshop;