export const unit_list = [
    {
        label: 'sqft',
        value: 'sqft'
    },
    {
        label: 'sq yard',
        value: 'sq yard'
    },
    {
        label: 'sqm',
        value: 'sqm'
    },
    {
        label: 'acres',
        value: 'acres'
    },
    {
        label: 'hectares',
        value: 'hectares'
    },
    {
        label: 'gaj',
        value: 'gaj'
    },
    {
        label: 'biga',
        value: 'biga'
    }
]

export const relationship_list = [
    {
        label: 'Single',
        value: 'Single'
    },
    {
        label: 'Engaged',
        value: 'Engaged'
    },
    {
        label: 'Married',
        value: 'Married'
    },
    {
        label: 'Divorced',
        value: 'Divorced'
    },
    {
        label: 'Widowed',
        value: 'Widowed'
    }
]

export const gender_list = [
    {
        label: 'Male',
        value: 'Male'
    },
    {
        label: 'Female',
        value: 'Female'
    },
    {
        label: 'Trans',
        value: 'Other'
    }
]

export const employment_list = [
    {
        label: 'Salaried',
        value: 'Salaried'
    },
    {
        label: 'Business',
        value: 'Business'
    },
    { label: 'Unemployed', value: 'Unemployed' }
]

export const time_to_buy_list = [
    { label: '0-2 Months', value: '0-2' },
    { label: '2-6 Months', value: '2-6' },
    { label: '6-12 Months', value: '6-12' },
    { label: '12-18 Months', value: '12-18' }
]

export const MAP_CURRENCY_NUMBER_TO_TEXT = {
    5000: '5 K',
    10000: '10 K',
    20000: '20 K',
    50000: '50 K',
    100000: '1 L',
    500000: '5 L',
    1000000: '10 L',
    1500000: '15 L',
    2000000: '20 L',
    2500000: '25 L',
    3000000: '30 L',
    3500000: '35 L',
    4000000: '40 L',
    4500000: '45 L',
    5000000: '50 L',
    5500000: '55 L',
    6000000: '60 L',
    6500000: '65 L',
    7000000: '70 L',
    7500000: '75 L',
    8000000: '80 L',
    8500000: '85 L',
    9000000: '90 L',
    9500000: '95 L',
    10000000: '1 Cr',
    15000000: '1.5 Cr',
    20000000: '2 Cr',
    25000000: '2.5 Cr',
    30000000: '3 Cr',
    35000000: '3.50 Cr',
    40000000: '4 Cr',
    45000000: '4.5 Cr',
    50000000: '5 Cr',
    55000000: '5.5 Cr',
    60000000: '6 Cr',
    65000000: '6.5 Cr',
    70000000: '7 Cr',
    75000000: '7.5 Cr',
    80000000: '8 Cr',
    85000000: '8.5 Cr',
    90000000: '9 Cr',
    95000000: '9.5 Cr',
    100000000: '10 Cr',
    150000000: '15 Cr',
    200000000: '20 Cr',
    300000000: '30 Cr',
    500000000: '50 Cr',
    1000000000: '100 Cr',
}

export const budgetRange = [
    { label: '5 K', value: 5000 },
    { label: '10 K', value: 10000 },
    { label: '20 K', value: 20000 },
    { label: '50 K', value: 50000 },
    { label: '1 L', value: 100000 },
    { label: '5 L', value: 500000 },
    { label: '10 L', value: 1000000 },
    { label: '15 L', value: 1500000 },
    { label: '20 L', value: 2000000 },
    { label: '25 L', value: 2500000 },
    { label: '30 L', value: 3000000 },
    { label: '35 L', value: 3500000 },
    { label: '40 L', value: 4000000 },
    { label: '45 L', value: 4500000 },
    { label: '50 L', value: 5000000 },
    { label: '55 L', value: 5500000 },
    { label: '60 L', value: 6000000 },
    { label: '65 L', value: 6500000 },
    { label: '70 L', value: 7000000 },
    { label: '75 L', value: 7500000 },
    { label: '80 L', value: 8000000 },
    { label: '85 L', value: 8500000 },
    { label: '90 L', value: 9000000 },
    { label: '95 L', value: 9500000 },
    { label: '1 Cr', value: 10000000 },
    { label: '1.5 Cr', value: 15000000 },
    { label: '2 Cr', value: 20000000 },
    { label: '2.5 Cr', value: 25000000 },
    { label: '3 Cr', value: 30000000 },
    { label: '3.5 Cr', value: 35000000 },
    { label: '4 Cr', value: 40000000 },
    { label: '4.5 Cr', value: 45000000 },
    { label: '5 Cr', value: 50000000 },
    { label: '5.5 Cr', value: 55000000 },
    { label: '6 Cr', value: 60000000 },
    { label: '6.5 Cr', value: 65000000 },
    { label: '7 Cr', value: 70000000 },
    { label: '7.5 Cr', value: 75000000 },
    { label: '8 Cr', value: 80000000 },
    { label: '8.5 Cr', value: 85000000 },
    { label: '9 Cr', value: 90000000 },
    { label: '9.5 Cr', value: 95000000 },
    { label: '10 Cr', value: 100000000 },
    { label: '15 Cr', value: 150000000 },
    { label: '20 Cr', value: 200000000 },
    { label: '30 Cr', value: 300000000 },
    { label: '50 Cr', value: 500000000 },
    { label: '100 Cr', value: 1000000000 },
]

export const calenderFilterItems = [
    { label: 'Tomorrow', value: 'tomorrow' },
    { label: 'This Week', value: 'this_Week' },
    { label: 'Next Week', value: 'next_week' },
    { label: 'This Month', value: 'this_month' },
    { label: 'Next Month', value: 'next_month' }
]

export const property_type = [
    {
        "value": 1,
        "label": "Residential"
    },
    {
        "value": 2,
        "label": "Commercial"
    }
]

export const EOICancelReasonList = [
    {
        label: 'Didn’t like the project',
        value: 'Didn’t like the project',
    },
    {
        label: 'Found a better project',
        value: 'Found a better project',
    },
    {
        label: 'Found better pricing',
        value: 'Found better pricing'
    },
    {
        label: 'Wanted a different unit',
        value: 'Wanted a different unit',
    },
    {
        label: 'Booked Another Unit in Another Project',
        value: 'Booked Another Unit in Another Project',
    },
    {
        label: 'Booked Another Unit in Same Project',
        value: 'Booked Another Unit in Same Project',
    },
    {
        label: 'Changed plan',
        value: 'Changed plan',
    },
    {
        label: 'Vaastu Issue',
        value: 'Vaastu Issue',
    },
    {
        label: 'Loan Eligibility',
        value: 'Loan Eligibility',
    },
    {
        label: 'Already bought from competitor',
        value: 'Already bought from competitor',
    },
    {
        label: 'Already bought by CP/Broker',
        value: 'Already bought by CP/Broker',
    },
    {
        label: 'Others',
        value: 'Others',
    }
]

export const SVCancelledReasonList = [
    {
        label: 'Not Available',
        value: 'Not Available'
    },
    {
        label: 'Dropped Plans',
        value: 'Dropped Plans'
    },
    {
        label: 'Will Visit Later',
        value: 'Will Visit Later'
    },
    {
        label: 'Looking for Another Project',
        value: 'Looking for Another Project'
    },
    {
        label: 'Already Visited',
        value: 'Already Visited'
    },
    {
        label: 'Interested in Competitor Project',
        value: 'Interested in Competitor Project'
    },
    {
        label: 'Others',
        value: 'Others'
    },
    {
        label: 'Location issue',
        value: 'Location issue'
    },
    {
        label: 'Timing issue',
        value: 'Timing issue'
    }
]

export const InProgessReasonsList = [
    {
        label: 'Details shared',
        value: 'Details shared'
    },
    {
        label: 'Interested / Followup',
        value: 'Interested / Followup'
    },
    {
        label: 'Call back',
        value: 'Call back'
    }
]


export const NotConnectedReasonsList = [
    {
        label: 'Not Responding',
        value: 'Not Responding'
    },
    {
        label: 'Ringing',
        value: 'Ringing'
    },
    {
        label: 'Busy',
        value: 'Busy'
    },
    {
        label: 'Not Reachable',
        value: 'Not Reachable'
    },
    {
        label: 'Switch Off',
        value: 'Switch Off'
    },
    {
        label: 'Call Back',
        value: 'Call Back'
    },
    {
        label: 'Call disconnected',
        value: 'Call disconnected'
    },
    {
        label: 'Incoming not available',
        value: 'Incoming not available'
    },
    {
        label: 'Dialled number does not exist',
        value: 'Dialled number does not exist'
    },
    {
        label: 'Unable to connect',
        value: 'Unable to connect'
    }
]


export const followupStatuses = [
    {
        label: 'Overdue',
        value: 'Overdue'
    },
    {
        label: 'Due',
        value: 'Due'
    }
]

export const approvalStatuses = [
    {
        label: 'Approved',
        value: 'Approved'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Rejected',
        value: 'Rejected'
    }
]

export const svTypes = [
    {
        label: 'Onsite',
        value: 'offline'
    },
    {
        label: 'Video',
        value: 'online'
    }
]