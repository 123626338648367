import React from 'react'

const NotFound = ({message,imageLink}) => {
  return (
    <div>
        {imageLink && <img src={imageLink}/>}
        {message}
    </div>
  )
}

export default NotFound