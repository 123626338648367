import { Menu } from '@headlessui/react';
import React, { useState } from 'react'
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/threedots.svg';
// import { ReactComponent as DownGrayIcon } from 'src/assets/Icons/downgray.svg';
// import { ReactComponent as DownWhiteIcon } from 'src/assets/Icons/downwhite.svg';
// import { ReactComponent as InfoWhiteIcon } from 'src/assets/Icons/infowhite.svg';
// import { ReactComponent as InfoblueIcon } from 'src/assets/Icons/infoblue.svg';
import { Link } from 'react-router-dom';
import moment from 'moment';

const STATUS = {
    active: 'Active',
    inactive: 'In-Active',
    registeration_closed: 'Registration Closed'
}

const EventsTable = ({ allEvents, handleShowDelete, handleChangeStatus, handleShowApprovalModal }) => {

    const [scrolledRight, setScrolledRight] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();

    const handleTableScroll = (event) => {
        if (event.target.scrollLeft >= 10) {
            setScrolledRight(true);
        } else {
            setScrolledRight(false);
        }
    }

    return (

        <div className='overflow-auto overscroll-none w-full pb-1' onScroll={handleTableScroll}>
            <table className='w-full border-separate border-spacing-0' >
                <thead className='sticky top-0 z-50'>
                    <tr className='text-left '>
                        <th className={`${scrolledRight ? 'tableisscrollingmob' : ''} table-heading bg-black100 border-black-400 text-black800 border-l rounded-tl-md rounded-bl-md`}>S.No</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Event Name</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Status</th>
                        {/* <th className='table-heading bg-black100 border-black-400 text-black800'>Project</th> */}
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Event Date & Time</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>CP/Broker Registration</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Property Type</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>City</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Event Type</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800'>Created Date</th>
                        <th className='table-heading bg-black100 border-black-400 text-black800 border-r rounded-tr-md rounded-br-md w-16 sm:sticky right-0 shadow-[-3px_0px_9px_rgba(0,0,0,0.05)]'></th>
                    </tr>
                </thead>
                <tbody>
                    {allEvents.length > 0 && allEvents.map((item, index) => (
                        <tr key={`${item.uuid}_${index}`} className={`${selectedIndex == index ? 'relative z-10' : ''}`}>
                            <td className={`${scrolledRight ? 'tableisscrollingmob' : ''}  table-data text-textblack  border-black400 sm:relative `}>{index + 1}</td>
                            <td className='table-data text-textblack  border-black400  max-w-[200px] min-w-[200px] whitespace-break-spaces'>
                                <Link
                                    to={`/dashboard/user/${item.uuid}`}
                                    state={{ name: item.name }}
                                    className='text-textblack hover:text-primary text-sm leading-5' data-tooltip-id="EventName" data-tooltip-variant='dark'>
                                    {item.name}
                                </Link>
                                {/* <Tooltip id="EventName" className='' place="bottom">
                                    <div className='inline-flex gap-2 max-w-[246px]'>
                                        <div className='text-xs text-white whitespace-break-spaces font-sm'>View Registrations</div>
                                    </div>
                                </Tooltip> */}
                            </td>
                            <td className='table-data text-textblack  border-black400 relative' >
                                {item.is_submitted && item.is_approved && <Menu>
                                    <Menu.Button
                                        className={`${item.status === 'active' ? 'bg-green text-white' : item.status === 'inactive' ? 'bg-red text-white' : 'bg-[#E2E2EA] text-black700 reClose'} rounded py-1.5 px-2 text-sm traking-[0.06] capitalize font-medium inline-flex items-center justify-between w-[180px] outline-none`}>
                                        {STATUS[item.status]}
                                        {/* {item.status === 'registeration_closed' ? <DownGrayIcon /> : <DownWhiteIcon />} */}
                                    </Menu.Button>
                                    <Menu.Items className='absolute w-[180px] left-[15px] top-[60px] flex flex-col border border-black-200 py-2 px-2 cursor-pointer bg-white gap-y-2 z-20 rounded shadow-[2px_2px_8px_rgba(219,226,239,0.5)] outline-none'>
                                        <Menu.Item >
                                            {({ active }) => (
                                                <a
                                                    className={`${active && 'bg-[#DFF7EB]'} text-sm bg-green text-white hover:opacity-80 leading-[22px] cursor-pointer font-medium px-2 py-1.5 rounded capitalize`}
                                                    onClick={() => handleChangeStatus('active', item.uuid)}
                                                >
                                                    Active
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    className={`${active && 'bg-[#E2E2EA]'} bg-[#E2E2EA] text-black700 text-sm hover:opacity-80 leading-[22px] cursor-pointer font-medium px-2 py-1.5 rounded capitalize`}
                                                    onClick={() => handleChangeStatus('registeration_closed', item.uuid)}
                                                >
                                                    Registration Closed
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    className={`${active && 'bg-red'} bg-red text-sm text-white hover:opacity-80 leading-[22px] font-medium px-2 py-1.5 rounded capitalize`}
                                                    onClick={() => handleChangeStatus('inactive', item.uuid)}
                                                >In-Active
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>}

                                {(!item.is_submitted && !item.is_approved) &&
                                    <div className='bg-primary text-white text-left leading-[22px] text-sm font-medium px-2 py-1.5 rounded capitalize w-[180px]'>
                                        Draft
                                        {/* <InfoWhiteIcon data-tooltip-id="my-tooltip" data-tooltip-variant='light' className='cursor-pointer' /> */}
                                    </div>
                                }

                                {(item.is_submitted && !item.is_approved) && <>
                                    <div className='bg-orange text-white leading-[22px] text-sm font-medium px-2 py-1.5 rounded capitalize w-[180px] inline-flex justify-between'>
                                        Approval Pending
                                        {/* <InfoWhiteIcon data-tooltip-id="my-tooltip" data-tooltip-variant='light' className='cursor-pointer outline-none' /> */}
                                    </div>
                                    {/* <Tooltip id="my-tooltip" className='bg-white z-50 border border-grayLight lighttooltp' place="bottom">
                                        <div className='inline-flex gap-2  max-w-[246px]'>
                                            <div className='w-5 basis-5' ><InfoblueIcon /></div>
                                            <div className='text-xs text-black700 whitespace-break-spaces font-normal'>Once approved by Propacity event will be live on Platform Launchpad shortly.</div>
                                        </div>
                                    </Tooltip> */}
                                </>}
                            </td>
                            {/* <td className='table-data text-textblack  border-black400 '>{item.project || '-'}</td> */}
                            < td className='table-data text-textblack  border-black400 ' > {`${moment(item.start_date).format('D MMMM YYYY')}, ${moment(item.start_time).format('h:mmA')}`}</td>
                            <td className='table-data text-textblack  border-black400 '>
                                {item.user_joined > 0 ? <><Link to={`/dashboard/user/${item.uuid}`} state={{ name: item.name }} data-tooltip-id="cpBroker" data-tooltip-variant='dark' className='text-textblack hover:text-primary text-sm leading-5'>
                                    {item.user_joined}
                                </Link>
                                    {/* <Tooltip id="cpBroker" className='' place="bottom">
                                        <div className='inline-flex gap-2 max-w-[246px]'>

                                            <div className='text-xs text-white whitespace-break-spaces font-sm'>View Registrations</div>
                                        </div>
                                    </Tooltip> */}
                                </>
                                    :
                                    '-'
                                }
                            </td>
                            <td className='table-data text-textblack  border-black400 '>{item.property_type?.join(', ') || '-'}</td>
                            <td className='table-data text-textblack  border-black400 '>{item.city || '-'}</td>
                            <td className='table-data text-textblack  border-black400 capitalize'>{item.type || '-'}</td>
                            <td className='table-data text-textblack  border-black400 capitalize'>{moment(item.created_on).format('D MMMM YYYY, h:mmA') || '-'}</td>
                            <td className={`${scrolledRight ? 'tableisscrolling' : ''} table-data text-textblack  border-black400 sm:sticky sm:right-0 bg-white  shadow-[-3px_0px_9px_rgba(0,0,0,0.05)]`}>
                                <div className='flex items-center justify-end relative'>
                                    {item.meet_link && <button className='bg-primary mr-4 text-14 py-1 px-2 text-white rounded' onClick={() => window.open(item.meet_link)}>Join Zoom</button>}
                                    {!item.is_submitted && <button className='bg-primary mr-4 text-14 py-1 px-2 text-white rounded' onClick={() => handleShowApprovalModal(item.uuid)}>Submit</button>}
                                    <Menu>
                                        <Menu.Button className='outline-none' onClick={() => setSelectedIndex(index)}>
                                            <ThreedotsIcon />
                                        </Menu.Button>
                                        <Menu.Items className='absolute right-[60px] top-6  flex flex-col w-[180px] border border-black-200 py-2 px-2 bg-white gap-y-1 z-10 rounded shadow-[2px_2px_8px_rgba(219,226,239,0.5)] outline-none '>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        className={`${active && 'bg-black200'} text-sm text-black leading-[22px] font-medium px-2 py-1 rounded cursor-pointer`}
                                                        to={`/create-event/${item.uuid}`}
                                                    >
                                                        Edit Event
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        className={`${active && 'bg-black200'} text-sm text-black leading-[22px] font-medium px-2 py-1 rounded cursor-pointer`}
                                                        to={`/dashboard/user/${item.uuid}`}
                                                        state={{ name: item.name }}
                                                    >
                                                        View Registrations
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            {/* <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        className={`${active && 'bg-red text-white'} text-sm text-red leading-[22px] font-medium px-2 py-1 rounded cursor-pointer`}
                                                        onClick={() => handleShowDelete(item.uuid)}
                                                    >
                                                        Delete Event
                                                    </a>
                                                )}
                                            </Menu.Item> */}
                                        </Menu.Items>
                                    </Menu>
                                </div>
                            </td>
                        </tr>
                    ))
                    }

                </tbody>
            </table >
        </div >
    )
}

export default EventsTable;