import React from 'react'
import cardimg from '../../assets/cardbg.jpg'
import moment from 'moment';
import { ReactComponent as ZoomIcon } from '../../assets/zoom.svg';
import { ReactComponent as AddressIcon } from '../../assets/address.svg';
import { ReactComponent as ProperyHomeIcon } from '../../assets/phome.svg';
import { ReactComponent as LPCheckIcone } from '../../assets/LPCheck.svg';
import { ReactComponent as LpInfoIcon } from '../../assets/lpInfoIcon.svg';
import { ReactComponent as DevloperIcon } from '../../assets/devloper.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const LaunchingTodaySlider = ({ item, style, handleSendOTP }) => {
    const imageurl = '../../assets/cardbg.jpg'
    const bgimage = {
        backgroundImage: `url(${item.banner})`,
        backgroundSize: 'content',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: 'contain'

    };
    let timeDiff = moment(item.start_time).diff(moment(), 'hours');

    const getCTA = () => {
        if (item.registered_by) {
            if (item.type === 'online') {
                if (item.meet_link && timeDiff <= 2) {
                    return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16`} onClick={() => { window.open(item.meet_link) }}>
                        Join Now
                    </button>
                } else {
                    return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} onClick={() => handleSendOTP(item)}>
                        <LPCheckIcone className='mr-10' />
                        Seat Reserved
                    </button>
                }
            } else {
                return <button className={`${style.directionbtn} pr-btn w-100 py-10 fz16 inter`} onClick={() => {
                    window.open(`https://www.google.com/search?q=${item.location}`, '_blank')
                }}>Get Directions</button>
            }
        } else {
            return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} disabled={item.status === 'registeration_closed'} onClick={() => handleSendOTP(item)}>
                {item.status === 'registeration_closed' ? 'Registeration Closed' : 'Reserve Seat'}
            </button>
        }
    }

    return (
        <div className={`${style.lunchTodyCard}`} style={bgimage}>
            <div className={`${style.card_block}`} >
                <div className={`${style.mobileImg}`}><img src={item.banner} alt="" /></div>
                <div className={`${style.dateLogo} d-flex flex-row itms-center align-items-center`}>

                    <div className={`${style.date} inter`}> {`${moment(item.start_date).format("MMM DD")}, 
                        ${moment(item.start_time).format('h:mmA')} - 
                        ${moment(item.end_time).format('h:mmA')}`}
                    </div>
                    {item.type === 'online' && <div className={`${style.onlineevent} text-capitalize `}>{item.type}</div>}
                </div>
                <div className={`${style.lunchTodyCarddesc}`}>
                    <h1 className={`${style.card_heading} fz24 inter text-white fw-po-bold mb-8`}>{item.event_name}</h1>
                    {/* <p className={`${style.card_subheading} fz14 inter mb-0`}>{item.description}</p> */}
                    <div className={`${style.linedes}`}></div>
                    <div className={`${style.zoom_address}`}>
                        {/* {item.type && <div className={`${style.zoom}`}>
                            <ZoomIcon />
                            {item.type} {item.type === 'Online Event' ? '(Zoom)' : ''}

                        </div>} */}
                        {item.property_type && <div className={`${style.propertytype}`}><ProperyHomeIcon />{item.property_type.join(', ')}</div>}
                        {item.developer_name && <div className={`${style.developer}`}><DevloperIcon />{item.developer_name}</div>}
                        {item?.city && <div className={`${style.developer}`}><DevloperIcon />{item?.city}</div>}

                        {item.type === 'offline' ? <div className={`${style.address}`}>
                            <AddressIcon />
                            {item.location}</div>
                            : ''
                        }
                    </div>

                    <div className={`${style.reservbtn}`}>
                        {getCTA()}
                        {(item.registered_by && !item.meet_link) && <div>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="button-tooltip" className='lpcardtooptip' >
                                        <div className='info-msg'>

                                            {item.type === 'online' ?
                                                <p>Your seat has been reserved, Zoom link will be shared 2 hours before the event.</p>
                                                :
                                                <p>Be present at the event location 30 minutes prior to the start time</p>
                                            }
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <div className='bg-tr border-0 p-0' bsstyle="default">
                                    <LpInfoIcon />
                                </div>
                            </OverlayTrigger>
                        </div>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LaunchingTodaySlider
