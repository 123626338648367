import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { getAllSourcesData, getUsersForSettings } from "../../services/private/company.service";
import InputSelect from "../InputGroup/InputSelect";
import SliderModal from "./SliderModal";
import Consumer from '../../helpers/context';
import { Oval } from "react-loader-spinner";

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',

        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};


export default function BulkSourceChange({
    setShow,
    show,
    handleSubmit,
    assignedTo,
    count,
    selectedLeadName,
    sourceList,
    loading
}) {

    const [selectedSources, setSelectedSource] = useState()
    const [error, setError] = useState();
    const [remarks, setRemarks] = useState();
    // const [sourceList, setSourceList] = useState([])

    const { userProfile } = useContext(Consumer);

    // const getSources = async () => {
    //     await getAllSourcesData().then(res => {
    //         if (res.status === 200) {
    //             console.log()
    //             setAllSources(res.data.data);
    //             setSourceList(res.data.data);
    //         }
    //     }).catch((err) => {
    //         console.log("Something went wrong", err);
    //     });
    // }

    const handleSubmitChanges = () => {
        if (!selectedSources || count === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        handleSubmit(selectedSources);
    }

    // useEffect(() => {
    //     getSources()
    // }, [])

    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title="Source"
            body={<>
                <div className="h-full overflow-auto !pl-5 !pr-5">
                    {count === 1 &&
                        <div className="form-group">
                            <label className=''>Source</label>
                            <div className='text-capitalize fz16 fw-po-medium '>
                                {selectedLeadName ? selectedLeadName : '-'}
                            </div>
                        </div>}

                    <InputSelect
                        // index={4}
                        name="source"
                        menuPlacement="bottom"
                        value={selectedSources}
                        options={sourceList}
                        onChange={setSelectedSource}
                        placeholder="Select Source"
                        label="Select Source(s)"
                        error={error && !selectedSources ? 'Please Select Source' : ''}
                        isMulti={false}
                        styles={customStyles}
                    />

                </div>
                <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                    <button
                        className="cancel outline-btn"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </button>
                    {!loading && <button
                        className="pr-btn"
                        onClick={handleSubmitChanges}
                    >
                        Save
                        {count > 1 && <span className="ml-1 fz14">{`(${count})`}</span>}
                    </button>}
                    {loading && <button className="pr-btn px-20 flex items-center justify-center" type='button'>
                        <Oval
                            height={24}
                            width={24}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />
                    </button>}

                </div>
            </>}
        />
    )
}