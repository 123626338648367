import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';
import 'firebase/compat/messaging';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { insertNotificationDevice } from './services/public/public.service';


// const firebaseConfig = {
//     apiKey: "AIzaSyATKwBvFVRmGPxyT2q-n31yd0ip-J3itUE",
//     authDomain: "propacity-71241.firebaseapp.com",
//     projectId: "propacity-71241",
//     storageBucket: "propacity-71241.appspot.com",
//     messagingSenderId: "604274112476",
//     appId: "1:604274112476:web:dd2a35b4109fef3f52c119"
// };
const firebaseConfig = {
    apiKey: "AIzaSyDCvAx5E6z7FTZBdadPhn_KoQAu6kwh5g0",
    authDomain: "propacity-for-developers.firebaseapp.com",
    projectId: "propacity-for-developers",
    storageBucket: "propacity-for-developers.appspot.com",
    messagingSenderId: "376044922514",
    appId: "1:376044922514:web:fe7b7a361f068e6beaa84b",
    measurementId: "G-336GC1N849"
};

firebase.initializeApp(firebaseConfig);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// export const messaging = firebase.messaging();
export const fetchToken = async (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BKaUCnJ9B8U4a6Q9WpzopCekwYTxgAmtmjri0IO_Q2QkC5eZ0pOqjJwmAFBzTSv_RpPEqiMyuYqkkTU36qG0oIU' })
        .then(async (currentToken) => {
            if (currentToken) {
                localStorage.setItem('fbToken', currentToken);
                let deviceId = localStorage.getItem('deviceId');
                let authToken = localStorage.getItem('authToken');

                if (!deviceId) {
                    deviceId = uuidv4();
                    localStorage.setItem('deviceId', deviceId);
                }
                const payload = {
                    firebaseToken: currentToken,
                    device_id: deviceId
                }
                if (authToken) {
                    await insertNotificationDevice(payload)
                }
                // console.log('current token for client: ', currentToken);
                setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
                // shows on the UI that permission is required 
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });