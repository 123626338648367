import React, { useCallback, useEffect, useState } from 'react';
import { createDatewithTime } from '../../helpers/date';
import { getUnprocessedLeads, resolveError } from '../../services/private/company.service';
import SliderModal from './SliderModal';
import SmallCenterModal from './small-center-modal';
import RequestRaisedModal from './RequestRaisedModal';

const PageErrorModal = ({ showSideModal, closeSideModal, pageName, form, errorInfo }) => {

    const [showUnprocessLeadModal, setShowUnprocessLeadModal] = useState(false);
    const [errorLeadsList, setErrorLeadsList] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showRequestRaisedModal, setShowRequestRaisedModal] = useState(false);
    const [allErrorFieldSelection, setAllErrorFieldSelection] = useState(true);
    const [errorLeadsListId, setErrorLeadsListId] = useState([]);

    useEffect(() => {
        if (isSubmitted) {
            setShowUnprocessLeadModal(false)
        }
    }, [isSubmitted]);

    const handleLeadErrorEncounter = () => {
        getUnprocessedLeads(form.id || form.form_id).then(res => {
            const errorLeadList = res.data.data.map(item => { return { ...item, isChecked: true } })
            const ids = errorLeadList.map(item => item.uuid);
            setErrorLeadsListId(ids);
            setErrorLeadsList(errorLeadList);
            setIsSubmitted(false);
            setShowUnprocessLeadModal(true)
        }).catch(err => {
            console.log({ err })
        })
        // setOpenErrorModal(true)
    }

    const handleChangeAllErrorField = (value) => {
        setAllErrorFieldSelection(value);
        if (!value) {
            setErrorLeadsListId([]);
        } else {
            const ids = errorLeadsList.map(item => item.uuid)
            setErrorLeadsListId(ids);
        }
    }

    const changeErrorLeadValue = useCallback((uuid) => {
        const idsList = [...errorLeadsListId]
        const index = errorLeadsListId.indexOf(uuid);
        if (index === -1) {
            console.log('heel')
            idsList.push(uuid);
        } else {
            idsList.splice(index, 1);
        }
        setErrorLeadsListId(idsList);

    }, [errorLeadsListId])

    const handleImport = useCallback((submitType) => {
        const payload = submitType === 'import' ? errorLeadsListId : []
        resolveError(form.id || form.form_id, payload).then(res => {
            setIsSubmitted(true);
        }).catch(err => {
            console.log({ err })
        })
    }, [form, errorLeadsListId]);

    const handleCloseRequestRaisedModal = useCallback(() => {
        setShowRequestRaisedModal(false)
    }, [])

    return (
        <SliderModal
            title={'Error Encountered'}
            show={showSideModal}
            setShow={closeSideModal}
            body={
                <>
                    <div className="page-error">
                        <div className="page-name">
                            <h2 className='fz14 ro black-dark-600 mb-0'>Page Name</h2>
                            <p className='mb-0 fz14 black po'>{pageName}</p>
                        </div>
                        <div className="form-name mt-20 d-flex align-items-center justify-content-between">
                            <div>
                                <h2 className='fz14 ro black-dark-600 mb-0'>Form Name</h2>
                                <p className='mb-0 fz14 black po'>{form.name} </p>
                            </div>
                            <div><svg className="ml-0" xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none"><rect width="18" height="18" transform="translate(2)" fill="white"></rect><path d="M10.1453 2.15002C10.539 1.53808 11.4336 1.53808 11.8273 2.15002L19.7377 14.446C20.1658 15.1115 19.688 15.987 18.8967 15.987H3.07594C2.28462 15.987 1.80681 15.1115 2.23495 14.446L10.1453 2.15002Z" fill="#FBBC05" stroke="#FBBC05" strokeWidth="2.26374" strokeLinejoin="round"></path><path d="M10.25 6.4V10.9C10.25 11.3971 10.6529 11.8 11.15 11.8C11.6471 11.8 12.05 11.3971 12.05 10.9V6.4C12.05 5.90294 11.6471 5.5 11.15 5.5C10.6529 5.5 10.25 5.90294 10.25 6.4Z" fill="white"></path><path d="M12.05 13.6C12.05 14.0971 11.6471 14.5 11.15 14.5C10.6529 14.5 10.25 14.0971 10.25 13.6C10.25 13.1029 10.6529 12.7 11.15 12.7C11.6471 12.7 12.05 13.1029 12.05 13.6Z" fill="white"></path></svg></div>
                        </div>
                        <div className="error-reson mt-20">
                            <p className='mb-0 pr-color fz14 fw-po-semi '>{errorInfo.unprocessedLeadCount} Leads unprocessed</p>
                        </div>
                        <div className="error-para mt-20">
                            <p className='black-dark-700 mb-0 fz14 ro'>
                                <span className='black-dark-800 po fw-po-semi '>
                                    Error :
                                </span>
                                {errorInfo?.error?.message || ' Integration failed.'}
                            </p>
                        </div>
                        {/* <div className="resolution mt-20">
                            <p className='black-dark-700 mb-0 fz14 ro'>
                                <span className='black-dark-800 po fw-po-semi'>
                                    Resolution :
                                </span>
                                Connect the Facebook page with Propacity Platform again to get the lead flow again on the system.
                            </p>
                        </div> */}
                    </div>
                    {!isSubmitted && <div className='d-flex justify-content-between mt-24 errorEncounted'>
                        <button className='px-32 py-8 fw-po-medium d-table outline-btn' onClick={() => setShowRequestRaisedModal(true)}>Contact support</button>
                        <button className='pr-btn px-24 py-8 fw-po-medium d-table' onClick={handleLeadErrorEncounter}>Resume Integration</button>
                    </div>}
                    {isSubmitted && <button className='pr-btn px-32 py-8 fw-po-medium ml-auto mt-16 d-table' onClick={closeSideModal}>Resolve Error</button>}

                    {/* Error lead modal */}
                    {showUnprocessLeadModal && <SmallCenterModal show={showUnprocessLeadModal}
                        dialogClassName="unprocessed-leads errormoadl"
                    >
                        <div className=''>
                            <figure className='mb-8'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48" fill="none">
                                    <rect width="48" height="48" transform="translate(1)" fill="white" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="#FBBC05" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint0_linear_6217_80535)" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" fill="url(#paint1_linear_6217_80535)" fillOpacity="0.2" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="#FBBC05" strokeWidth="2.26374" strokeLinejoin="round" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint2_linear_6217_80535)" strokeWidth="2.26374" strokeLinejoin="round" />
                                    <path d="M22.0184 5.32243C23.3963 3.18062 26.5275 3.18063 27.9054 5.32244L47.4728 35.7381C48.9712 38.0673 47.2989 41.1317 44.5293 41.1317H5.39454C2.62493 41.1317 0.952588 38.0673 2.45106 35.738L22.0184 5.32243Z" stroke="url(#paint3_linear_6217_80535)" strokeOpacity="0.2" strokeWidth="2.26374" strokeLinejoin="round" />
                                    <path d="M23 14.9V26.9C23 28.2255 24.0745 29.3 25.4 29.3C26.7255 29.3 27.8 28.2255 27.8 26.9V14.9C27.8 13.5745 26.7255 12.5 25.4 12.5C24.0745 12.5 23 13.5745 23 14.9Z" fill="white" />
                                    <path d="M27.8 34.1C27.8 35.4255 26.7255 36.5 25.4 36.5C24.0745 36.5 23 35.4255 23 34.1C23 32.7745 24.0745 31.7 25.4 31.7C26.7255 31.7 27.8 32.7745 27.8 34.1Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.999" y2="68.501" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FF974A" stopOpacity="0" />
                                            <stop offset="1" stopColor="#FF974A" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FF974A" stopOpacity="0" />
                                            <stop offset="1" stopColor="#FDA82C" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_6217_80535" x1="24.9619" y1="0.74707" x2="24.9619" y2="54.5932" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </figure>
                            <h1 className='fz20 fw-po-medium black mb-8'>Unprocessed Leads</h1>
                            <p>Do you wish to import the unprocessed due to error in form connection?</p>
                            <div className="sucesstable ">
                                <div className="def-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th className='text-left'>Leadgen ID</th>
                                                <th>Create Time</th>
                                                <th>
                                                    <input type="checkbox" name={'leadgen'} value={allErrorFieldSelection} checked={allErrorFieldSelection} onChange={(e) => handleChangeAllErrorField(!allErrorFieldSelection)} />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                errorLeadsList.map((list, index) => {
                                                    return (
                                                        <tr key={`${list.leadgen}_${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td className='text-left'>{list.leadgen}</td>
                                                            <td>{createDatewithTime(list.created_on)}</td>
                                                            <td>
                                                                <input type="checkbox" name={list.leadgen} checked={errorLeadsListId.includes(list.uuid)} value={list.isChecked} onChange={() => changeErrorLeadValue(list.uuid)} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end mt-16'>
                                <button className='px-24 py-6 fw-po-medium outline-btn d-table' onClick={() => handleImport('skip')}>Skip</button>
                                <button className='pr-btn px-24 py-6 fw-po-medium d-table ml-20' onClick={() => handleImport('import')}>Import</button>
                            </div>
                        </div>
                    </SmallCenterModal>}

                    {showRequestRaisedModal && <RequestRaisedModal show={showRequestRaisedModal} closeModal={handleCloseRequestRaisedModal} />}
                </>
            }
        />
    )
}

export default PageErrorModal