import React from 'react'
import { ReactComponent as MenuVertical } from '../../../../assets/icons/MenuVertical.svg';
import { ReactComponent as BuildingIcon } from '../../../../assets/icons/Building.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import TooltipText from '../../../../components/Tooltip';

const TeamCard = ({ team, setSelectedTeam, setShowAddTeamModal, handleRemove }) => {

    const history = useHistory();

    return (
        <div className='!border border-grayLight rounded-lg shadow-boxshadow !p-4 relative'>
            <Link to={`/settings/user-roles/teamDetails/${team?.uuid}`} className='absolute left-0 right-0 top-0 bottom-0 w-100 h-100'></Link>
            <div className='flex flex-row justify-between gap-2'>
                <div className='w-full text-black text-lg font-semibold leading-[26px] inter'>{team.name}</div>
                <div className='w-6 h-6 shrink-0 basis-[24px] group-0: '>

                    <div className='dropdown view-more'>
                        <button
                            className="bg-tr border-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <MenuVertical className='fillblack800' />

                        </button>
                        <div
                            className="dropdown-menu dropdown-menu-right w-auto"
                            aria-labelledby="dropdownMenuButton"

                        >
                            <a className="dropdown-item"
                                onClick={() => {
                                    history.push(`/settings/user-roles/teamDetails/${team?.uuid}`)
                                }}
                            >View Team Detail</a>
                            <a className="dropdown-item" onClick={() => {
                                setSelectedTeam(team)
                                setShowAddTeamModal(true)
                            }} >Edit Team</a>
                            {/* <a
                                className="dropdown-item del-menu"
                                onClick={() => {
                                    handleRemove(team.id)
                                }}
                            >
                                Delete Team
                            </a> */}
                        </div></div>
                </div>
            </div>
            {team?.description && <div className='!mt-2'>
                <p className='text-sm text-black700 inter'>{team?.description}</p>
            </div>}
            <div className='mt-2 flex gap-2 !flex-wrap relative z-10'>
                <div className='cursor-default p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack  leading-4'>Lead: {team?.leader_name}</div>

                {team?.projects?.length && <TooltipText title={team.projects.join(', ')}>
                    <div className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer'>
                        <div><BuildingIcon /></div>
                        {team?.projects[0]} {team.projects.length > 1 ? `+${team.projects.length - 1}` : ''}
                    </div>
                </TooltipText>}

            </div>
            {team?.users?.length && <div
                className='p-2 bg-[#F2F3F6] rounded-md text-xs font-medium text-textblack inline-flex items-center gap-x-1.5 leading-4 cursor-pointer mt-2'
            >
                {/* <div><BuildingIcon /></div> */}
                Users: <span>{team?.users?.length}</span>
            </div>}
            {/* <div className='!mt-4'>
                <div className='relative flex justify-end'>
                    <div
                        className='cursor-default z-10 translate-x-5 !border-2 !border-white bg-gradient-to-r from-[#4141FF] to-[#4141FF] text-white text-sm font-medium leading-4 rounded-full p-2 w-8 h-8 inline-flex items-center justify-center'
                    >
                        NI
                    </div>
                    <div
                        className='cursor-default z-20 translate-x-2.5 !border-2 !border-white bg-gradient-to-r from-[#4141FF] to-[#4141FF] text-white text-sm font-medium leading-4 rounded-full p-2 w-8 h-8 inline-flex items-center justify-center'
                    >
                        AP
                    </div>
                    <div
                        className='cursor-default z-30 !border-2 !border-white bg-gradient-to-r from-[#444450] to-[#444450] text-white text-sm font-medium leading-4 rounded-full p-2 w-8 h-8 inline-flex items-center justify-center'>
                        +12
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default TeamCard
