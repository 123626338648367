import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert.svg'

const ResetPermission = ({ show, closeModal, handleSubmit }) => {
    return (
        <Modal show={show} onHide={closeModal} backdrop="static" centered className='w-[400px]'>
            <div className='p-4'>
                <div className='mb-4 h-12 w-12 bg-[#FEE4E2] rounded-full flex items-center justify-center'>
                    <AlertIcon />
                </div>
                <h6 className='mb-2.5'>
                    Caution!
                </h6>
                <p>Are you sure you want to reset all permissions to default?</p>

                <div className='grid grid-cols-2 gap-3 mt-3'>
                    <button className='border rounded-lg py-2.5' onClick={closeModal}>Cancel</button>
                    <button className='bg-red text-white rounded-lg py-2.5' onClick={handleSubmit}>Yes</button>
                </div>
            </div>

        </Modal>
    )
}

export default ResetPermission