import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DownICon } from '../../../assets/icons/Down.svg';
import { ReactComponent as PlusPrimary } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as InfoYellow } from '../../../assets/icons/infoYellow.svg';
import { ReactComponent as EditBlackIcon } from '../../../assets/icons/Edit_rectangle.svg';
import InputText from '../../InputGroup/InputText';
import InputRupees from '../../InputGroup/InputRupees';
import InputSelect from '../../InputGroup/InputSelect';
import { getUnitPricing, updateEOIPricing } from '../../../services/private/inventory.service';
import { unit_type } from '../../../utils/project-constant';
import { checkIsArrayEmpty, formatPrice, getAreaConfig, getConfigString, getPriceFromConfig, getUnitArea, priceTwoDecimalPlaces, toINRFormat } from '../../../helpers/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { toast } from 'react-toastify';
import TooltipText from '../../Tooltip';
import { ReactComponent as ReraCloseIcon } from '../../../assets/icons/reraclose.svg';
import { ReactComponent as CheckblackIcon } from '../../../assets/icons/checkblack.svg';
import style from '../../../modules/Settings/Projects/style.module.css';
import '../../../modules/Settings/Projects/style.css';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const discountList = [
    {
        label: 'Base Price Discount',
        value: 'Base Price Discount'
    },
    {
        label: 'Exclusive Discount',
        value: 'Exclusive Discount'
    },
    {
        label: 'Assured Return Discount',
        value: 'Assured Return Discount'
    },
    {
        label: 'Rate Per Unit',
        value: 'Rate Per Unit'
    }
]

const getUnitType = (type) => {
    const unitType = unit_type.find(item => item.value === type);
    if (unitType) {
        return unitType.label
    } else {
        return '-';
    }
}

const calculatePrice = (basePrice, amountPercent) => {
    return formatPrice(basePrice * (Number(amountPercent) / 100));
}

const EOIPricing = ({ setSteps, unit, data, lead, eoiId, setTotalPrice, selectedBooking, updateAdjustedPrice, allowedPermissions, selectedProject }) => {
    const [pricingList, setPricingList] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [discountType, setDiscountType] = useState([{}]);
    const [selectedEDC, setSelectedEDC] = useState({});
    const [selectedPLC, setSelectedPLC] = useState({});
    const [selectedIDC, setSelectedIDC] = useState({});
    const [selectedIFDC, setSelectedIFDC] = useState({});
    const [selectedRegistrationCharges, setSelectedRegistrationCharges] = useState({});
    const [selectedLegelCharges, setSelectedLegelCharges] = useState({});
    const [selectedGSTCharges, setSelectedGSTCharges] = useState({});
    const [selectedStampDuty, setSelectedStampDuty] = useState({});
    const [isAmenitySelect, setIsAmenity] = useState(false);
    const [isEDCSelect, setIsEDC] = useState(false);
    const [isIDCSelect, setIsIDC] = useState(false);
    const [isIFDCSelect, setIsIFDC] = useState(false);
    const [isPLCSelect, setIsPLC] = useState(false);
    const [isRegistrantionSelect, setIsRegistration] = useState(false);
    const [isLegalSelecte, setIsLegal] = useState(false);
    const [isStampDutySelect, setIsStampDuty] = useState(false);
    const [isGSTSelect, setIsGST] = useState(false);
    const [adjustedPrice, setAdjustedPrice] = useState('');
    const [exclusiveDiscount, setExclusiveDiscount] = useState('');
    const [unitBasePrice, setUnitBasePrice] = useState(0);
    const [assuredReturnDiscount, setAssuredReturnDiscount] = useState(0);
    const [basePriceDiscount, setBasePriceDiscount] = useState(0);
    const [unitBasePriceDiscount, setUnitBasePriceDiscount] = useState(0);
    const [unitBasePriceFix, setUnitBasePriceFix] = useState(0);
    const [totalAminitiesPrice, setTotalAminitiesPrice] = useState(0);
    const [isCustomPrice, setIsCustomPrice] = useState(false);
    const [customRate, setCustomRate] = useState('');
    const [customBaseRate, setCustomBaseRate] = useState('');
    const [editBasePrice, setEditBasePrice] = useState('');

    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);

    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const rotatetwo = isSpanClassAdded ? 'rotate-180' : '';
    const rotateone = isSpanClassAdded ? 'rotate-180' : '';
    const rotattree = isSpanClassAdded ? 'rotate-180' : '';

    const getPricing = async () => {
        const res = await getUnitPricing(unit.uuid);
        if (res.data.status === 200) {
            setUnitBasePrice(res.data.data?.basePricing[0]?.rate || '');
            setUnitBasePriceFix(res.data.data?.basePricing[0]?.rate || '');
            setPricingList(res.data.data);
            setCustomRate(res.data.data?.basePricing[0]?.rate)
            const list = res.data.data?.amenitiesPricing.map(item => {
                return { ...item }
            })
            setAmenities(list);
        }
    }

    // This useEffect is for prefill previous data (Edit EOI) if available
    useEffect(() => {
        if ((selectedBooking && Object.keys(selectedBooking).length > 0) && (selectedBooking?.booking?.approval_status &&
            !(selectedBooking?.booking?.approval_status === 'rejected' || selectedBooking?.booking?.approval_status === 'cancelled'))) {
            const { bookingPricing, bookingAmeniites, bookingDiscounts } = selectedBooking;
            // setAdjustedPrice(bookingPricing?.adjusted_price)
            if (bookingAmeniites.length > 0) {
                const list = bookingAmeniites.map(item => {
                    return { ...item }
                })
                setIsAmenity(true);
                setAmenities(list);
                handleAddAmenityPrice()
            } else {
                const list = pricingList?.amenitiesPricing?.map(item => {
                    return { ...item }
                })
                setAmenities(list);
            }

            if (bookingPricing?.is_custom_base_rate && bookingPricing?.custom_rate) {
                setCustomBaseRate(bookingPricing?.custom_rate)
                setCustomRate(bookingPricing?.custom_rate);
                setUnitBasePrice(parseInt(bookingPricing?.custom_rate));
                setIsCustomPrice(bookingPricing?.is_custom_base_rate);
            }

            if ((bookingPricing?.plc_charge || bookingPricing?.plc_charge_percentage) && (pricingList?.plcPricing?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.plc_charge) {
                    paymentAmount = pricingList?.plcPricing.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.plc_charge)));
                } else {
                    paymentAmount = pricingList?.plcPricing.find(item => item.payment_percentage === bookingPricing?.plc_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsPLC(true);
                    setSelectedPLC(paymentAmount)
                }
            }
            if ((bookingPricing?.edc_charge || bookingPricing?.edc_charge_percentage) && (pricingList?.edcPricing?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.edc_charge) {
                    paymentAmount = pricingList?.edcPricing.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.edc_charge)));
                } else {
                    paymentAmount = pricingList?.edcPricing.find(item => item.payment_percentage === bookingPricing?.edc_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsEDC(true);
                    setSelectedEDC(paymentAmount);
                }
            }
            if ((bookingPricing?.idc_charge || bookingPricing?.idc_charge_percentage) && (pricingList?.idcPricing?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.idc_charge) {
                    paymentAmount = pricingList?.idcPricing.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.idc_charge)));
                } else {
                    paymentAmount = pricingList?.idcPricing.find(item => item.payment_percentage === bookingPricing?.idc_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsIDC(true);
                    setSelectedIDC(paymentAmount);
                }
            }
            if ((bookingPricing?.ifdc_charge || bookingPricing?.ifdc_charge_percentage) && (pricingList?.ifdcPricing?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.ifdc_charge) {
                    paymentAmount = pricingList?.ifdcPricing.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.ifdc_charge)));
                } else {
                    paymentAmount = pricingList?.ifdcPricing.find(item => item.payment_percentage === bookingPricing?.ifdc_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsIFDC(true)
                    setSelectedIFDC(paymentAmount);
                }
            }
            if ((bookingPricing?.registeration_charge || bookingPricing?.registeration_charge_percentage) && (pricingList?.registerationCharges?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.registeration_charge) {
                    paymentAmount = pricingList?.registerationCharges.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.registeration_charge)));
                } else {
                    paymentAmount = pricingList?.registerationCharges.find(item => item.payment_percentage === bookingPricing?.registeration_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsRegistration(true);
                    setSelectedRegistrationCharges(paymentAmount);
                }
            }
            if ((bookingPricing?.stamp_duty || bookingPricing?.stamp_duty_percentage) && (pricingList?.stampDuty?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.stamp_duty) {
                    paymentAmount = pricingList?.stampDuty.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.stamp_duty)));
                } else {
                    paymentAmount = pricingList?.stampDuty.find(item => item.payment_percentage === bookingPricing?.stamp_duty_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsStampDuty(true)
                    setSelectedStampDuty(paymentAmount)
                }
            }
            if ((bookingPricing?.legal_charge || bookingPricing?.legal_charge_percentage) && (pricingList?.legalCharges?.length > 0)) {
                let paymentAmount = {};
                if (bookingPricing?.legal_charge) {
                    paymentAmount = pricingList?.legalCharges.find(item => Math.trunc(parseInt(item.payment_amount)) === Math.trunc(parseInt(bookingPricing?.legal_charge)));
                } else {
                    paymentAmount = pricingList?.legalCharges.find(item => item.payment_percentage === bookingPricing?.legal_charge_percentage);
                }
                if (paymentAmount && Object.keys(paymentAmount).length > 0) {
                    setIsLegal(true);
                    setSelectedLegelCharges(paymentAmount);
                }
            }
            if (bookingDiscounts.length > 0) {
                let discount = []
                bookingDiscounts.forEach(item => {
                    discount.push({
                        type: item.type,
                        payment_amount: parseInt(item.payment_amount)
                    })
                    if (item.type === 'Assured Return Discount') {
                        setAssuredReturnDiscount(parseInt(item.payment_amount))
                    }
                    if (item.type === 'Base Price Discount') {
                        setBasePriceDiscount(parseInt(item.payment_amount))
                    }
                    if (item.type === 'Exclusive Discount') {
                        setExclusiveDiscount(parseInt(item.payment_amount));
                    }
                    if (item.type === 'Rate Per Unit') {
                        setUnitBasePriceDiscount(parseInt(item.payment_amount))
                    }
                })
                setDiscountType(discount);
            }
        }
    }, [selectedBooking, data, pricingList]);

    const basePrice = useMemo(() => {
        let price = 0
        if (pricingList?.basePricing?.length > 0) {
            price = getPriceFromConfig(pricingList?.basePricing[0].config[0], parseInt(unitBasePrice));

        }
        return price;
    }, [pricingList, unitBasePrice]);

    const totalAminityDiscount = useMemo(() => {
        let total = 0;
        if (amenities?.length > 0) {
            amenities.forEach(item => {
                if (item.discount) {
                    total += parseInt(item.discount);
                }
            })
        }
        return total;
    }, [amenities])

    const totalAddedDiscount = useMemo(() => {
        let discount = 0
        discountType.forEach(item => {
            if (item.payment_amount && item.type !== 'Exclusive Discount' && item.type !== 'Rate Per Unit') {
                discount += parseInt(item.payment_amount);
            }
        })
        return parseInt(discount) + parseInt(totalAminityDiscount || 0);
    }, [discountType, totalAminityDiscount]);

    const handleAddAmenityPrice = () => {
        let total = 0;
        if (pricingList?.amenitiesPricing?.length > 0) {
            pricingList?.amenitiesPricing.forEach(item => {
                if (item.payment_amount) {
                    total += parseInt(item.payment_amount);
                }
            })
        }
        setTotalAminitiesPrice(total);
    };

    useEffect(() => {
        if (Object.keys(pricingList).length > 0) {
            discountType.forEach(item => {
                if (item.type === 'Rate Per Unit') {
                    let unitPrice = parseInt(pricingList?.basePricing[0]?.rate) - parseInt(item.payment_amount || 0);
                    setUnitBasePrice(unitPrice)
                }
            })
        }
    }, [discountType, pricingList]);

    const totalUnitPrice = useMemo(() => {
        let total = parseInt(basePrice);
        if (selectedPLC?.payment_amount || selectedPLC?.payment_percentage) {
            total += selectedPLC.payment_amount ? parseInt(selectedPLC.payment_amount) : calculatePrice(basePrice, selectedPLC.payment_percentage)
        }
        return total;
    }, [basePrice, selectedPLC]);

    const totalPrice = useMemo(() => {
        let total = parseInt(totalUnitPrice);
        if (selectedEDC?.payment_amount || selectedEDC?.payment_percentage) {
            total += selectedEDC.payment_amount ? parseInt(selectedEDC.payment_amount) : calculatePrice(basePrice, selectedEDC.payment_percentage)
        }
        if (selectedIDC?.payment_amount || selectedIDC?.payment_percentage) {
            total += selectedIDC.payment_amount ? parseInt(selectedIDC.payment_amount) : calculatePrice(basePrice, selectedIDC.payment_percentage)
        }
        if (selectedIFDC?.payment_amount || selectedIFDC?.payment_percentage) {
            total += selectedIFDC.payment_amount ? parseInt(selectedIFDC.payment_amount) : calculatePrice(basePrice, selectedIFDC.payment_percentage)
        }

        if (selectedLegelCharges?.payment_amount || selectedLegelCharges?.payment_percentage) {
            total += selectedLegelCharges.payment_amount ? parseInt(selectedLegelCharges.payment_amount) : calculatePrice(basePrice, selectedLegelCharges.payment_percentage)
        }

        if (totalAminitiesPrice) {
            total += parseInt(totalAminitiesPrice)
        }

        return total;
    }, [
        basePrice,
        totalUnitPrice,
        selectedEDC,
        selectedIDC,
        selectedIFDC,
        selectedLegelCharges,
        totalAminitiesPrice,
    ]);

    const totalPriceWithoutDiscount = useMemo(() => {
        let total = parseInt(totalPrice);
        if (selectedGSTCharges?.payment_amount || selectedGSTCharges?.payment_percentage) {
            total += selectedGSTCharges.payment_amount ? parseInt(selectedGSTCharges.payment_amount) : calculatePrice(basePrice, selectedGSTCharges.payment_percentage)
        }
        if (selectedStampDuty?.payment_amount || selectedStampDuty?.payment_percentage) {
            total += selectedStampDuty.payment_amount ? parseInt(selectedStampDuty.payment_amount) : calculatePrice(basePrice, selectedStampDuty.payment_percentage)
        }
        if (selectedRegistrationCharges?.payment_amount || selectedRegistrationCharges?.payment_percentage) {
            total += selectedRegistrationCharges.payment_amount ? parseInt(selectedRegistrationCharges.payment_amount) : calculatePrice(basePrice, selectedRegistrationCharges.payment_percentage)
        }
        return total;
    }, [totalPrice, selectedGSTCharges, selectedRegistrationCharges, selectedStampDuty]);

    const totalUnitPriceWithDiscount = useMemo(() => {
        let total = parseInt(totalPriceWithoutDiscount);
        if (totalAddedDiscount) {
            total -= totalAddedDiscount
        }
        // if (totalAminityDiscount) {
        //     total -= totalAminityDiscount
        // }
        // if (unitBasePriceDiscount && pricingList && pricingList?.basePricing) {
        //     total -= getPriceFromConfig(pricingList?.basePricing[0].config[0], parseInt(unitBasePriceDiscount))
        // }        
        return total;
    }, [
        totalPriceWithoutDiscount,
        totalAddedDiscount,
        // totalAminityDiscount
    ]);

    useEffect(() => {
        let amount = (parseInt(totalUnitPriceWithDiscount) - parseInt(exclusiveDiscount || 0))
        setAdjustedPrice(amount)
    }, [exclusiveDiscount, totalUnitPriceWithDiscount]);

    const subTotal = useMemo(() => {
        let total = 0;
        if (selectedGSTCharges?.payment_amount || selectedGSTCharges?.payment_amount) {
            total = totalPriceWithoutDiscount + selectedGSTCharges.payment_amount ? parseInt(selectedGSTCharges.payment_amount) : calculatePrice(basePrice, selectedGSTCharges.payment_percentage)
        }
        return total;
    }, [totalPriceWithoutDiscount, selectedGSTCharges])

    useEffect(() => {
        if (unit?.uuid)
            getPricing();
    }, [unit])

    const handleSubmit = async () => {
        const payload = {
            pricing: {
                base_price: parseInt(basePrice),
                base_price_discount: parseInt(totalAddedDiscount) || null,
                base_price_discount_percentage: null,
                stamp_duty: parseInt(selectedStampDuty?.payment_amount) || null,
                stamp_duty_percentage: parseInt(selectedStampDuty?.payment_percentage) || null,
                registeration_charge: parseInt(selectedRegistrationCharges?.payment_amount) || null,
                registeration_charge_percentage: parseInt(selectedRegistrationCharges?.payment_percentage) || null,
                legal_charge: parseInt(selectedLegelCharges?.payment_amount) || null,
                legal_charge_percentage: parseInt(selectedLegelCharges?.payment_percentage) || null,
                edc_charge: parseInt(selectedEDC?.payment_amount) || null,
                edc_charge_percentage: parseInt(selectedEDC?.payment_percentage) || null,
                plc_charge: parseInt(selectedPLC?.payment_amount) || null,
                plc_charge_percentage: parseInt(selectedPLC?.payment_percentage) || null,
                idc_charge: parseInt(selectedIDC?.payment_amount) || null,
                idc_charge_percentage: parseInt(selectedIDC?.payment_percentage) || null,
                ifdc_charge: parseInt(selectedIFDC?.payment_amount) || null,
                ifdc_charge_percentage: parseInt(selectedIFDC?.payment_percentage) || null,
                gst: parseInt(selectedGSTCharges?.payment_amount) || null,
                gst_percentage: parseInt(selectedGSTCharges?.payment_percentage) || null,
                total_base_price: (parseInt(basePrice + totalAminitiesPrice)) || null,
                adjusted_price: parseInt(adjustedPrice) || null,
                total_price: parseInt(totalPriceWithoutDiscount) || null,
                sub_price: parseInt(subTotal) || null,
                final_price: parseInt(adjustedPrice) || null,
                total_due: null,
                custom_rate: customBaseRate || null,
                is_custom_base_rate: isCustomPrice

            },
            amenities: amenities,
            discounts: !checkIsArrayEmpty(discountType) ? discountType : null
        }

        const res = await updateEOIPricing(eoiId, payload)
        if (res.data.status === 200) {
            setTotalPrice(adjustedPrice);
            updateAdjustedPrice(adjustedPrice)
            setSteps('EOIPaymentplan');
        }
    }

    return (
        <>
            <div className='!pt-5 sm:!px-6 !px-3 pb-8 h-full overflow-auto'>

                <h2 className="inter text-textblack text-lg mt-2 !mb-5 font-semibold">Pricing for {selectedProject?.name}</h2>
                <div>
                    <div id="accordion" className='flex flex-col !gap-4'>
                        {amenities?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer grp" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter">
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isAmenitySelect}
                                        checked={isAmenitySelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsAmenity(!isAmenitySelect)
                                            if (isAmenitySelect) {
                                                if (selectedBooking && selectedBooking?.bookingAmeniites?.length > 0) {
                                                    const list = selectedBooking?.bookingAmeniites.map(item => {
                                                        return { ...item }
                                                    })
                                                    setAmenities(list);
                                                } else {
                                                    const list = pricingList?.amenitiesPricing?.map(item => {
                                                        return { ...item }
                                                    })
                                                    setAmenities(list);
                                                }
                                                setTotalAminitiesPrice(0);
                                            } else {
                                                handleAddAmenityPrice();
                                            }
                                        }}
                                    />
                                    Amenities
                                </h5>
                                <div className='flex items-center sm:gap-3 !gap-1'>
                                    {totalAminitiesPrice > 0 && <h6 className='inter sm:text-base text-sm text-black700 m-0'>Total : <span className='text-textblack'> ₹ {toINRFormat(totalAminitiesPrice - totalAminityDiscount)}</span></h6>}
                                    <span className=''><DownICon /></span>
                                </div>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            amenities?.map((item, index) => (

                                                <div>
                                                    <div className='flex justify-between text-sm inter !mb-2'>
                                                        <div className='text-black700'>{item.charge_type}</div>
                                                        <div className='text-textblack'>{item.payment_amount ? `₹ ${toINRFormat(item.payment_amount)}` : ''}</div>
                                                    </div>
                                                    <InputRupees
                                                        // label="Unit Number"
                                                        placeholder="Enter discount"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        rupayclass='top-4 left-3'
                                                        value={item.discount}
                                                        onChange={(value) => {
                                                            if (parseInt(value) > parseInt(item.payment_amount)) {
                                                                return
                                                            }
                                                            let newAmenities = [...amenities];
                                                            newAmenities[index]['discount'] = value;
                                                            newAmenities[index]['final_price'] = parseInt(item.payment_amount) - parseInt(value)
                                                            setAmenities(newAmenities);
                                                        }}
                                                        disable={!isAmenitySelect || !allowedPermissions?.includes('apply_discount_eoi')}
                                                        handleBlur={() => { }}
                                                    />
                                                </div>
                                            ))
                                        }
                                        {/* <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Club Membership Charges</div>
                                                <div className='text-textblack'>₹ 40,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Waste Management</div>
                                                <div className='text-textblack'>₹ 9,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>EEC </div>
                                                <div className='text-textblack'>₹ 4,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {pricingList?.edcPricing?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isEDCSelect}
                                        checked={isEDCSelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsEDC(!isEDCSelect)
                                            if (isEDCSelect) {
                                                setSelectedEDC({})
                                            }
                                        }} />
                                    EDC Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedEDC?.payment_amount || selectedEDC?.payment_percentage) &&
                                        <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                            <span className='text-textblack'>  {selectedEDC?.payment_amount ? '₹' + toINRFormat(parseInt(selectedEDC?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedEDC?.payment_percentage))}</span>
                                        </h6>
                                    }
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.edcPricing.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{getUnitType(item?.unit_type)}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='edc'
                                                                value={item.uuid}
                                                                checked={selectedEDC?.uuid === item.uuid}
                                                                onChange={() => setSelectedEDC(item)}
                                                                disabled={!isEDCSelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {/* <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {pricingList?.plcPricing?.length > 0 &&
                            <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                                <div
                                    className="flex justify-between items-center cursor-pointer collapsed"
                                    id="plcCharge"
                                    data-toggle="collapse"
                                    data-target="#collapsePlc"
                                    aria-expanded="false" aria-controls="collapsePlc" >
                                    <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                        <input
                                            type="checkbox"
                                            name="" id=""
                                            className='w-5 h-5'
                                            value={isPLCSelect}
                                            checked={isPLCSelect}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setIsPLC(!isPLCSelect)
                                                if (isPLCSelect) {
                                                    setSelectedPLC({})
                                                }
                                            }} />
                                        PLC Charges
                                    </h5>
                                    <div className='flex items-center sm:gap-3 gap-1'>
                                        {(selectedPLC?.payment_amount || selectedPLC?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                            <span className='text-textblack'>
                                                {selectedPLC?.payment_amount ? '₹' + toINRFormat(selectedPLC?.payment_amount) : '₹' + toINRFormat(calculatePrice(basePrice, selectedPLC?.payment_percentage))}
                                            </span></h6>}
                                        <span><DownICon /></span>
                                    </div>
                                </div>
                                <div id="collapsePlc" className="collapse" aria-labelledby="plcCharge" data-parent="#accordion">

                                    <div className='!mt-5'>
                                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                            {
                                                pricingList?.plcPricing.map((item, index) => (
                                                    <div>
                                                        <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                            <div className=' flex flex-col'>
                                                                <span className='text-textblack font-semibold'>{item?.charge_type}</span>
                                                                {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                            </div>
                                                            <div className='text-textblack'>
                                                                <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                                <input
                                                                    type="radio"
                                                                    name='plc'
                                                                    value={item.uuid}
                                                                    checked={selectedPLC?.uuid === item.uuid}
                                                                    onChange={() => setSelectedPLC(item)}
                                                                    disabled={!isPLCSelect}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            {/* <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>

                                        </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>}
                        {pricingList?.idcPricing?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div
                                className="flex justify-between items-center cursor-pointer collapsed"
                                id="idcCharges"
                                data-toggle="collapse"
                                data-target="#collapseIDC"
                                aria-expanded="false"
                                aria-controls="collapseIDC"
                            >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isIDCSelect}
                                        checked={isIDCSelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsIDC(!isIDCSelect)
                                            if (isIDCSelect) {
                                                setSelectedIDC({})
                                            }
                                        }}
                                    />
                                    IDC Charges
                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedIDC?.payment_amount || selectedIDC?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                        <span className='text-textblack'> {selectedIDC?.payment_amount ? '₹' + toINRFormat(parseInt(selectedIDC?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedIDC?.payment_percentage))}</span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseIDC" className="collapse" aria-labelledby="idcCharges" data-parent="#accordion">
                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.idcPricing.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{getUnitType(item?.unit_type)}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='idc'
                                                                value={item.uuid}
                                                                checked={selectedIDC?.uuid === item.uuid}
                                                                onChange={() => setSelectedIDC(item)}
                                                                disabled={!isIDCSelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {/* <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                <div className=' flex flex-col'>
                                                    <span className='text-textblack font-semibold'>Car Parking</span>
                                                    <span className='text-black700'>4%</span>
                                                </div>
                                                <div className='text-textblack'>
                                                    <span className='mr-2 font-semibold' >₹ 6,400</span>
                                                    <input type="checkbox" />
                                                </div>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {pricingList?.ifdcPricing?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div
                                className="flex justify-between items-center cursor-pointer collapsed"
                                id="ifdcCharges"
                                data-toggle="collapse"
                                data-target="#collapseIfdc"
                                aria-expanded="false"
                                aria-controls="collapseIfdc" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isIFDCSelect}
                                        checked={isIFDCSelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsIFDC(!isIFDCSelect)
                                            if (isIFDCSelect) {
                                                setSelectedIFDC({});
                                            }
                                        }}
                                    />
                                    IFDC Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedIFDC?.payment_amount || selectedIFDC?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                        <span className='text-textblack'>
                                            {selectedIFDC?.payment_amount ? '₹' + toINRFormat(parseInt(selectedIFDC?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedIFDC?.payment_percentage))}
                                        </span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseIfdc" className="collapse" aria-labelledby="ifdcCharges" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.ifdcPricing.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{getUnitType(item?.unit_type)}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='ifdc'
                                                                value={item.uuid}
                                                                checked={selectedIFDC?.uuid === item.uuid}
                                                                onChange={() => setSelectedIFDC(item)}
                                                                disabled={!isIFDCSelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {pricingList?.registerationCharges?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer collapsed"
                                id="registration"
                                data-toggle="collapse"
                                data-target="#collapseRegister"
                                aria-expanded="false"
                                aria-controls="collapseRegister" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isRegistrantionSelect}
                                        checked={isRegistrantionSelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsRegistration(!isRegistrantionSelect)
                                            if (isRegistrantionSelect) {
                                                setSelectedRegistrationCharges({})
                                            }
                                        }}
                                    />
                                    Registration Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedRegistrationCharges?.payment_amount || selectedRegistrationCharges?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                        <span className='text-textblack'> {selectedRegistrationCharges?.payment_amount ? '₹' + toINRFormat(parseInt(selectedRegistrationCharges?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedRegistrationCharges?.payment_percentage))}</span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseRegister" className="collapse" aria-labelledby="registration" data-parent="#accordion">
                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.registerationCharges.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{item.state}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='register'
                                                                value={item.uuid}
                                                                checked={selectedRegistrationCharges?.uuid === item?.uuid}
                                                                onChange={() => setSelectedRegistrationCharges(item)}
                                                                disabled={!isRegistrantionSelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {pricingList?.stampDuty?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div
                                className="flex justify-between items-center cursor-pointer collapsed"
                                id="stampDuty"
                                data-toggle="collapse"
                                data-target="#collapseStamp"
                                aria-expanded="false"
                                aria-controls="collapseStamp" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isStampDutySelect}
                                        checked={isStampDutySelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsStampDuty(!isStampDutySelect)
                                            if (isStampDutySelect) {
                                                setSelectedStampDuty({});
                                            }
                                        }}
                                    />
                                    Stamp Duty Charges
                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedStampDuty?.payment_amount || selectedStampDuty?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                        <span className='text-textblack'>
                                            {selectedStampDuty?.payment_amount ? '₹' + toINRFormat(parseInt(selectedStampDuty?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedStampDuty?.payment_percentage))}</span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseStamp" className="collapse" aria-labelledby="stampDuty" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.stampDuty.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{item.state} ({item.category})</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='stampDuty'
                                                                value={item.uuid}
                                                                checked={selectedStampDuty?.uuid === item.uuid}
                                                                onChange={() => setSelectedStampDuty(item)}
                                                                disabled={!isStampDutySelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {pricingList?.legalCharges?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div
                                className="flex justify-between items-center cursor-pointer collapsed"
                                id="legelCharge"
                                data-toggle="collapse"
                                data-target="#collapseLegel"
                                aria-expanded="false"
                                aria-controls="collapseLegel"
                            >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isLegalSelecte}
                                        checked={isLegalSelecte}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsLegal(!isLegalSelecte)
                                            if (isLegalSelecte) {
                                                setSelectedLegelCharges({});
                                            }
                                        }}
                                    />
                                    Legal Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedLegelCharges?.payment_amount || selectedLegelCharges?.payment_percentage) && <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                        <span className='text-textblack'> {selectedLegelCharges?.payment_amount ? '₹' + toINRFormat(parseInt(selectedLegelCharges?.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedLegelCharges?.payment_percentage))}</span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseLegel" className="collapse" aria-labelledby="legelCharge" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.legalCharges.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{item.state}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : toINRFormat(calculatePrice(basePrice, item.payment_percentage))}</span>
                                                            <input
                                                                type="radio"
                                                                name='legal'
                                                                value={item.uuid}
                                                                checked={selectedLegelCharges?.uuid === item.uuid}
                                                                onChange={() => setSelectedLegelCharges(item)}
                                                                disabled={!isLegalSelecte}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>}
                        {/* <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div className="flex justify-between items-center cursor-pointer collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input type="checkbox" name="" id="" className='w-5 h-5' />
                                    SDR Charges

                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    <h6 className='inter sm:text-base text-sm text-black700 m-0'><span className='text-textblack'> ₹ 40,000</span></h6>
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Car Parking</div>
                                                <div className='text-textblack'>₹ 6,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Club Membership Charges</div>
                                                <div className='text-textblack'>₹ 40,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>Waste Management</div>
                                                <div className='text-textblack'>₹ 9,400</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                        <div>
                                            <div className='flex justify-between text-sm inter !mb-2'>
                                                <div className='text-black700'>EEC</div>
                                                <div className='text-textblack'>₹ 4,000</div>
                                            </div>
                                            <InputText
                                                // label="Unit Number"
                                                placeholder="A601"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}
                        {pricingList?.gst?.length > 0 && <div className="!border !border-grayLight !p-5 rounded-xl hover:!border-primary hover:bg-[#F9FAFD]">
                            <div
                                className="flex justify-between items-center cursor-pointer collapsed collapsed"
                                id="headingThree" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                                <h5 className="mb-0 flex items-center gap-2 sm:text-base text-sm text-textblack font-medium inter" >
                                    <input
                                        type="checkbox"
                                        name="" id=""
                                        className='w-5 h-5'
                                        value={isGSTSelect}
                                        checked={isGSTSelect}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setIsGST(!isGSTSelect)
                                            if (isGSTSelect) {
                                                setSelectedGSTCharges({});
                                            }
                                        }}
                                    />
                                    GST
                                </h5>
                                <div className='flex items-center sm:gap-3 gap-1'>
                                    {(selectedGSTCharges.payment_amount || selectedGSTCharges.payment_percentage) &&
                                        <h6 className='inter sm:text-base text-sm text-black700 m-0'>
                                            <span className='text-textblack'> {selectedGSTCharges.payment_amount ? '₹' + toINRFormat(parseInt(selectedGSTCharges.payment_amount)) : '₹' + toINRFormat(calculatePrice(basePrice, selectedGSTCharges.payment_percentage))}</span></h6>}
                                    <span><DownICon /></span>
                                </div>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">

                                <div className='!mt-5'>
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                        {
                                            pricingList?.gst.map((item, index) => (
                                                <div>
                                                    <div className='flex justify-between items-center text-sm inter !mb-2'>
                                                        <div className=' flex flex-col'>
                                                            <span className='text-textblack font-semibold'>{item.state}</span>
                                                            {item.payment_percentage && <span className='text-black700'>{item.payment_percentage}% (of Base Price)</span>}
                                                        </div>
                                                        <div className='text-textblack'>
                                                            <span className='mr-2 font-semibold' >₹ {item.payment_amount ? toINRFormat(item.payment_amount) : calculatePrice(basePrice, item.payment_percentage)}</span>
                                                            <input
                                                                type="radio"
                                                                name='gst'
                                                                value={item.uuid}
                                                                checked={selectedGSTCharges.uuid === item.uuid}
                                                                onChange={() => setSelectedGSTCharges(item)}
                                                                disabled={!isGSTSelect}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
                {allowedPermissions?.includes('apply_discount_eoi') &&
                    <>
                        <h2 className="inter text-textblack text-lg !my-5 font-semibold">Discount</h2>
                        <div className='!border !border-primary !py-4 !px-4 bg-[#F9FAFD] !mt-5 rounded-lg gap-y-4 flex flex-col'>
                            {
                                discountType.map((item, index) => (
                                    <div key={index}>
                                        <h4 className='inter text-sm text-black700 !mb-2'>Discount ({index + 1})</h4>
                                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                            <InputSelect
                                                placeholder='Select Discount Type'
                                                isMulti={false}
                                                styles={customStyles}
                                                options={discountList}
                                                value={item.type ? discountList.find(list => list.value === item.type) : ''}
                                                onChange={(value) => {
                                                    const newDiscountType = [...discountType];
                                                    newDiscountType[index].type = value.value;
                                                    setDiscountType(newDiscountType);
                                                }}

                                            />
                                            <div className='flex'>
                                                <InputRupees
                                                    // label="Unit Number"
                                                    placeholder="0"
                                                    type="text"
                                                    name="name"
                                                    rupayclass='top-4 left-3'
                                                    inputclass='m-0 inter'
                                                    disable={!item.type}
                                                    value={item.payment_amount}
                                                    onChange={(value) => {
                                                        const newDiscountType = [...discountType];
                                                        if (newDiscountType[index].type === 'Rate Per Unit') {
                                                            if (value < unitBasePrice) {
                                                                newDiscountType[index].payment_amount = value;
                                                                setUnitBasePriceDiscount(parseInt(value))
                                                            }
                                                        } else {
                                                            newDiscountType[index].payment_amount = value;
                                                        }
                                                        setDiscountType(newDiscountType);
                                                        if (newDiscountType[index].type === 'Exclusive Discount') {
                                                            setExclusiveDiscount(value);
                                                        }
                                                        if (newDiscountType[index].type === 'Assured Return Discount') {
                                                            setAssuredReturnDiscount(parseInt(value))
                                                        }
                                                        if (newDiscountType[index].type === 'Base Price Discount') {
                                                            setBasePriceDiscount(parseInt(value))
                                                        }
                                                    }}
                                                    handleBlur={() => { }}
                                                />
                                                {(discountType.length > 1 || item.type) && <button className='ml-2' onClick={() => {
                                                    let types = [...discountType];
                                                    if (types[index].type === 'Exclusive Discount') {
                                                        setExclusiveDiscount(0)
                                                    }
                                                    if (types[index].type === 'Rate Per Unit') {
                                                        setUnitBasePrice(parseInt(pricingList?.basePricing[0]?.rate))
                                                    }
                                                    if (types[index].type === 'Assured Return Discount') {
                                                        setAssuredReturnDiscount(0)
                                                    }
                                                    if (types[index].type === 'Base Price Discount') {
                                                        setBasePriceDiscount(0)
                                                    }
                                                    types.splice(index, 1);
                                                    if (discountType.length === 1) {
                                                        setDiscountType([{}]);
                                                    } else {
                                                        setDiscountType(types);
                                                    }
                                                }} >
                                                    <CloseIcon />
                                                </button>}

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            <div>
                                <button
                                    className='bg-blueHighlight !text-primary text-sm font-medium inter flex items-center gap-1 !px-4 !py-2 rounded-lg float-right'
                                    onClick={() => {
                                        setDiscountType([...discountType, {}])
                                    }}
                                >
                                    <PlusPrimary />Add Discount
                                </button>
                            </div>
                        </div>
                    </>
                }
                {/* <div className='w-1/2 !border !border-grayLight !p-5 rounded-xl mt-4'>
                    <label htmlFor="custom" className={`flex items-center gap-x-2 text-sm text-black700 cursor-pointer font-medium ${isCustomPrice ? 'mb-1' : 'm-0'}`}>
                        <input
                            type="checkbox"
                            name="base_rate"
                            id="custom"
                            value={isCustomPrice}
                            checked={isCustomPrice}
                            onChange={() => {
                                setIsCustomPrice(!isCustomPrice)
                                if (isCustomPrice) {
                                    setCustomRate('');
                                    setUnitBasePrice(unitBasePriceFix);
                                    setCustomBaseRate('');
                                }
                            }}
                        />
                        Custom Base Rate
                    </label>
                    {
                        isCustomPrice && <InputRupees
                            placeholder='Enter Custom Base Rate'
                            value={customRate}
                            onChange={(value) => {
                                setCustomRate(value)
                            }}
                            handleBlur={(value) => {
                                if (parseInt(value) > parseInt(unitBasePriceFix)) {
                                    setUnitBasePrice(parseInt(value));
                                    setCustomBaseRate(value);
                                } else {
                                    setUnitBasePrice(parseInt(unitBasePriceFix))
                                    toast.warning('Custom base rate should be greater than unit base rate.')
                                }
                            }}
                            rupayclass={'top-4 left-3'}
                        />
                    }
                </div> */}
                <div className='!mt-5 flex flex-col gap-y-3'>
                    {(unitBasePriceFix && pricingList?.basePricing?.length > 0) && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Unit Rate Price</div>
                        {!editBasePrice && <div className='sm:text-base text-sm font-semibold leading-6 text-textblack flex items-center justify-center'>
                            <span> {`₹ ${toINRFormat(isCustomPrice && unitBasePrice > unitBasePriceFix ? unitBasePrice : unitBasePriceFix)} / ${getUnitArea(pricingList?.basePricing[0].config[0])}`}</span>
                            <span className='ml-2'>
                                <TooltipText title='Edit Custom Base Price'>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setEditBasePrice(true);
                                        }}
                                        className={`${style.editbtn}`}
                                    >
                                        <EditBlackIcon className='h-4 w-4' />
                                    </button>
                                </TooltipText>
                            </span>
                        </div>}
                        {editBasePrice && <>
                            <div className='mr-1 flex'>
                                <InputRupees
                                    placeholder='Enter Custom Base Rate'
                                    value={customRate}
                                    onChange={(value) => {
                                        setCustomRate(value)
                                    }}
                                    handleBlur={() => { }}
                                    rupayclass={'top-4 left-3'}
                                />
                                <div className={`${style.editdelgrp} ${style.delsave} ml-2`}>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            if (parseInt(customRate) > parseInt(unitBasePriceFix)) {
                                                setUnitBasePrice(parseInt(customRate));
                                                setCustomBaseRate(customRate);
                                                setEditBasePrice(false);
                                                setIsCustomPrice(true);
                                            } else {
                                                setUnitBasePrice(parseInt(unitBasePriceFix))
                                                toast.warning('Custom base rate should be greater than unit base rate.')
                                            }
                                        }}
                                        className={`${style.editbtn}`}
                                    >
                                        <CheckblackIcon className='h-4 w-4' />
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setEditBasePrice(false);
                                            if (!(parseInt(customRate) > parseInt(unitBasePriceFix))) {
                                                setCustomRate('');
                                            }
                                        }}
                                        className={`${style.delbtn}`}
                                    >
                                        <ReraCloseIcon />
                                    </button>
                                </div>
                            </div>
                        </>
                        }
                    </div>}
                    {(unitBasePriceDiscount > 0 && pricingList?.basePricing) && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Rate per sq.ft. Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- {`₹ ${toINRFormat(unitBasePriceDiscount)} / ${getUnitArea(pricingList?.basePricing[0].config[0])}`} </div>
                    </div>}
                    {selectedPLC && Object.keys(selectedPLC).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">PLC Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedPLC?.payment_amount ? selectedPLC.payment_amount : calculatePrice(basePrice, selectedPLC?.payment_percentage))}</div>
                    </div>}
                    {(pricingList?.basePricing?.length > 0) && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Area</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> {getAreaConfig(pricingList?.basePricing[0].config[0])} </div>
                    </div>}

                    {/* 
                        totalUnitPrice = basePrice + plc charges
                        */}
                    {totalUnitPrice > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Unit Base Price</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {toINRFormat(totalUnitPrice)}</div>
                    </div>}

                    {totalAminitiesPrice > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Amenities</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {toINRFormat(totalAminitiesPrice)}</div>
                    </div>}
                    {selectedIDC && Object.keys(selectedIDC).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">IDC Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedIDC?.payment_amount ? selectedIDC.payment_amount : calculatePrice(basePrice, selectedIDC.payment_percentage))}</div>
                    </div>}
                    {/* <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Unit Price</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ 64,00,000</div>
                    </div> */}
                    {selectedEDC && Object.keys(selectedEDC).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">EDC Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedEDC?.payment_amount ? selectedEDC.payment_amount : calculatePrice(basePrice, selectedEDC.payment_percentage))}</div>
                    </div>}

                    {selectedIFDC && Object.keys(selectedIFDC).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">IFDC Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedIFDC?.payment_amount ? selectedIFDC.payment_amount : calculatePrice(basePrice, selectedIFDC.payment_percentage))}</div>
                    </div>}
                    {selectedLegelCharges && Object.keys(selectedLegelCharges).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Legal Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {toINRFormat(selectedLegelCharges?.payment_amount ? selectedLegelCharges.payment_amount : calculatePrice(basePrice, selectedLegelCharges.payment_percentage))}</div>
                    </div>}
                    {selectedStampDuty && Object.keys(selectedStampDuty).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Stamp Duty Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedStampDuty?.payment_amount ? selectedStampDuty.payment_amount : calculatePrice(basePrice, selectedStampDuty.payment_percentage))}</div>
                    </div>}
                    {selectedRegistrationCharges && Object.keys(selectedRegistrationCharges).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Registration Charges</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedRegistrationCharges?.payment_amount ? selectedRegistrationCharges.payment_amount : calculatePrice(basePrice, selectedRegistrationCharges.payment_percentage))}</div>
                    </div>}
                    {selectedGSTCharges && Object.keys(selectedGSTCharges).length > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">GST</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(selectedGSTCharges?.payment_amount ? selectedGSTCharges.payment_amount : calculatePrice(basePrice, selectedGSTCharges.payment_percentage))}</div>
                    </div>}
                    {totalPriceWithoutDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Total Unit Price</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>₹ {toINRFormat(totalPriceWithoutDiscount)}</div>
                    </div>}
                    {totalAminityDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Amenities Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ {toINRFormat(totalAminityDiscount)}</div>
                    </div>}

                    {basePriceDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Base Price Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ {toINRFormat(basePriceDiscount)} </div>
                    </div>}
                    {assuredReturnDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-black700">Assured Return Discount </div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ {toINRFormat(assuredReturnDiscount)}  </div>
                    </div>}
                    {totalAddedDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm font-semibold leading-6 text-textblack">Total Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ {toINRFormat(totalAddedDiscount)}</div>
                    </div>}

                    {
                        totalUnitPriceWithDiscount && <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-base text-sm leading-6 text-textblack">Purchase Price</div>
                            <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(totalUnitPriceWithDiscount)}</div>
                        </div>
                    }
                    {exclusiveDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Exclusive Discount</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'>- ₹ {toINRFormat(exclusiveDiscount)}</div>
                    </div>}
                    {/* {exclusiveDiscount > 0 && <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-base text-sm leading-6 text-textblack">Adjusted Price</div>
                        <div className='sm:text-base text-sm font-semibold leading-6 text-textblack'> ₹ {toINRFormat(adjustedPrice)}</div>
                    </div>} */}
                </div>
                <div className='!mt-5 !border-t border-b !border-grayLigh !pt-4 !pb-4 '>
                    <div className='flex flex-row justify-between items-center inter'>
                        <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold">Net Purchase Price</div>
                        <div className='sm:text-xl text-lg font-semibold leading-6 text-textblack text-right'>
                            <div>₹ {toINRFormat(adjustedPrice)}</div>
                            <div className='sm:text-sm text-xs text-black700 font-normal'>For {data.name}</div>
                        </div>
                    </div>
                </div>
                {/* <div className='!border border-[#FBBC05] rounded-lg !p-4 flex flex-row items-center gap-3 !mt-5 bg-[#FBBC05] bg-opacity-5'>
                    <div className='w-6 basis-6 shrink-0 '><InfoYellow /></div>
                    <span className='text-sm inter text-black700'>The Quote will be sent to your senior for Approval. Edit Quote to fix the total discount limit.</span>
                </div> */}
            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Net Purchase Price : ₹ {toINRFormat(adjustedPrice)}</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For {unit?.name} ({`${getConfigString(unit?.configuration)}`}) </p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center' onClick={() => setSteps('EOIUnitDetail')}>Previous</button>
                    <button className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center' onClick={handleSubmit}>Next</button>
                </div>
            </div>
        </>
    )
}

export default EOIPricing
