import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Skeleton from 'react-loading-skeleton';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ResultBar = ({ eventsCount, loading }) => {
    const [eventLoading, setEventLoading] = useState(false)
    const [tabScrollPosition, setTabScrollPosition] = useState(0)

    useEffect(() => {
        setEventLoading(loading)
    }, [loading]);

    const scrollRight = () => {
        document.getElementById('pills-tab').scrollLeft -= 80;
        setTabScrollPosition(parseInt(document.getElementById('pills-tab').scrollLeft))
    }
    const scrollLeft = () => {
        document.getElementById('pills-tab').scrollLeft += 80;
        setTabScrollPosition(parseInt(document.getElementById('pills-tab').scrollLeft))
    }

    return (
        <div className="result-box">
            <div style={{ maxWidth: '1299px' }} className='analytic-nav-bar'>


                {/* {
                statusLeads && statusLeads.map((lead, index) => {
                    if (index >= 5) return null

                    return (
                        <div className="box" key={index}>
                            <div className='position-relative d-flex flex-row align-items-center'><h3>{lead.display_name ? lead.display_name : 'New Leads'}</h3>
                                <div className="info-alert"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                                    <path d="M9 15.875C12.797 15.875 15.875 12.797 15.875 9C15.875 5.20304 12.797 2.125 9 2.125C5.20304 2.125 2.125 5.20304 2.125 9C2.125 12.797 5.20304 15.875 9 15.875Z" stroke="#828282" strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 6.25V9" stroke="#828282" strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 11.75H9.0075" stroke="#828282" strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                    <div className="info-msg">
                                        <h4>Total Leads</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    </div>
                                </div>
                            </div>
                            <p>{lead.count}</p>
                        </div>
                    )
                })
            } */}
                {/* <OwlCarousel
                    className='owl-theme'
                    dots={false}
                    // res='true'
                    loop={true}
                    nav={true}
                    navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                    margin={10}
                    responsive={{
                        0: {
                            items: 2,
                            nav: true,
                            margin: 0

                        },
                        600: {
                            items: 3,
                            nav: true
                        },
                        900: {
                            items: 4,
                            nav: true
                        },
                        1000: {
                            items: 5,
                            nav: true
                        },
                        1200: {
                            items: 6,
                            loop: false,
                            nav: true,
                            margin: 0

                        }
                    }
                    }>

                    <div className='item' style={{ width: '298px' }}>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'>
                                <h3> Untouched (New)</h3>
                            </div >
                            {!eventLoading && <p>{parseInt(eventsCount?.untouched_leads) || '-'}</p>}
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'><h3>Followups</h3>
                            </div >
                            {!eventLoading && <div className="info-alert cursor-pointer">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>
                                                <p className="d-flex justify-content-between mb-2">
                                                    <span>Upcoming Followups</span> <span>&nbsp;&nbsp;{parseInt(eventsCount?.total_followups) - parseInt(eventsCount?.pending_followups) || '-'}</span>
                                                </p>
                                                <p className="d-flex justify-content-between">
                                                    <span>Missed Followups</span>  <span>&nbsp;&nbsp;{parseInt(eventsCount?.pending_followups) || '-'}</span>
                                                </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    {/* <Button className='bg-tr border-0' bsstyle="default">  
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                    </Button>
                                    <p>{parseInt(eventsCount?.total_followups) || '-'}</p>
                                </OverlayTrigger>
                            </div>
                            }
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>

                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'><h3>SV Scheduled</h3>
                            </div >
                            {!eventLoading && <div className="info-alert">
                                <p>{parseInt(eventsCount?.sv) || '-'}</p>
                            </div>
                            }
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'><h3>SV Completed</h3>
                            </div >
                            {!eventLoading && <div className="info-alert ">
                                <p>{parseInt(eventsCount?.sv_completed) || '-'}</p>
                            </div>
                            }
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'><h3>Bookings</h3>
                                {/* <div className="info-alert">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>
                                                <h4>Total Leads</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            </div >
                            {!eventLoading && <p>{parseInt(eventsCount?.bookings) || '-'}</p>}
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'><h3> Dead</h3>
                                {/* <div className="info-alert">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>
                                                <h4>Total Leads</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            </div >
                            {!eventLoading && <p>{parseInt(eventsCount?.dead_leads) || "-"}</p>}
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'>
                                <h3>Token Done</h3>
                            </div >
                            {!eventLoading && <p>{parseInt(eventsCount?.eoi) || '-'}</p>}
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>
                    <div className='item'>
                        <div className="box">
                            <div className='position-relative d-flex flex-row align-items-center'>
                                <h3>Unassigned</h3>
                            </div >
                            {!eventLoading && <p>{parseInt(eventsCount?.un_assigned_leads) || '-'}</p>}
                            {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                        </div >
                    </div>

                </OwlCarousel> */}

                <div className="display-col-tab-header border-0 ">
                    {tabScrollPosition > 0 && <button className='border-0 bg-tr btn-left d-md-inline d-none' onClick={scrollRight}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                    </button>}
                    {tabScrollPosition < 188 && <button className='border-0 bg-tr btn-right d-md-inline d-none' onClick={scrollLeft}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                    </button>}
                    <button className='border-0 bg-tr btn-left d-md-none' onClick={scrollRight}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                    </button>
                    <button className='border-0 bg-tr btn-right d-md-none' onClick={scrollLeft}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
                    </button>

                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link !border-0" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="true">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'>
                                        <h3> Untouched (New)</h3>
                                    </div >
                                    {!eventLoading && <p>{parseInt(eventsCount?.untouched_leads) || '-'}</p>}
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="true">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'><h3>Followups</h3>
                                    </div >
                                    {!eventLoading && <div className="info-alert cursor-pointer">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        <p className="d-flex justify-content-between mb-2">
                                                            <span>Upcoming Followups</span> <span>&nbsp;&nbsp;{parseInt(eventsCount?.total_followups) - parseInt(eventsCount?.pending_followups) || '-'}</span>
                                                        </p>
                                                        <p className="d-flex justify-content-between">
                                                            <span>Missed Followups</span>  <span>&nbsp;&nbsp;{parseInt(eventsCount?.pending_followups) || '-'}</span>
                                                        </p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <p>{parseInt(eventsCount?.total_followups) || '-'}</p>
                                        </OverlayTrigger>
                                    </div>
                                    }
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-new-tab" data-toggle="pill" href="#pills-new" role="tab" aria-controls="pills-new" aria-selected="false">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'><h3>SV Scheduled</h3>
                                    </div >
                                    {!eventLoading && <div className="info-alert">
                                        <p>{parseInt(eventsCount?.sv) || '-'}</p>
                                    </div>
                                    }
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-followups-tab" data-toggle="pill" href="#pills-followups" role="tab" aria-controls="pills-followups" aria-selected="false">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'><h3>SV Completed</h3>
                                    </div >
                                    {!eventLoading && <div className="info-alert ">
                                        <p>{parseInt(eventsCount?.sv_completed) || '-'}</p>
                                    </div>
                                    }
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link " id="pills-site-visits-tab" data-toggle="pill" href="#pills-site-visits" role="tab" aria-controls="pills-site-visits" aria-selected="true">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'><h3>Bookings</h3>
                                        {/* <div className="info-alert">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>
                                                <h4>Total Leads</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                    </Button>
                                </OverlayTrigger>
                                </div>*/}
                                    </div >
                                    {!eventLoading && <p>{parseInt(eventsCount?.bookings) || '-'}</p>}
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-booking-tab" data-toggle="pill" href="#pills-booking" role="tab" aria-controls="pills-booking" aria-selected="false">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'><h3> Dead</h3>
                                        {/* <div className="info-alert">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg'>
                                                <h4>Total Leads</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <Button className='bg-tr border-0' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                    </Button>
                                </OverlayTrigger>
                                </div>*/}
                                    </div >
                                    {!eventLoading && <p>{parseInt(eventsCount?.dead_leads) || "-"}</p>}
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-dead-tab" data-toggle="pill" href="#pills-dead" role="tab" aria-controls="pills-dead" aria-selected="false">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'>
                                        <h3>Token Done</h3>
                                    </div >
                                    {!eventLoading && <p>{parseInt(eventsCount?.eoi) || '-'}</p>}
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                        <li className='nav-item' style={{ width: '200px' }}>
                            <a className="nav-link" id="pills-dead-tab" data-toggle="pill" href="#pills-dead" role="tab" aria-controls="pills-dead" aria-selected="false">
                                <div className="box" style={{ width: '200px' }}>
                                    <div className='position-relative d-flex flex-row align-items-center'>
                                        <h3>Unassigned</h3>
                                    </div >
                                    {!eventLoading && <p>{parseInt(eventsCount?.un_assigned_leads) || '-'}</p>}
                                    {eventLoading && <Skeleton count={1} height="20px" width="50px" />}
                                </div >
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default ResultBar