import React, { useEffect, useState } from 'react'
import RightModal from '../../../../../components/Modals/RightModal';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import InputSelect from '../../../../../components/InputGroup/InputSelect';
import { validityUnit } from '../../../../../helpers/enums';
import InputText from '../../../../../components/InputGroup/InputText';
import { toast } from 'react-toastify';
import { insertTagValidityAutomation, updateTagValidityAutomation } from '../../../../../services/private/company.service';
import { getProjectsForFilter } from '../../../../../services/private/leads.service';

const AddTagging = ({ show, closeModal, getTaggingData, selectedTagging }) => {

    const [taggingValidity, setTaggingValidity] = useState('30');
    const [taggingValidityUnit, setTaggingValidityUnit] = useState('days');
    const [isActive, setIsActive] = useState(true);
    const [project, setProject] = useState('');
    const [projectList, setProjectList] = useState([]);

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ label: item.name, value: item.id }))
            setProjectList(mapped);
        }
    }

    useEffect(() => {
        getProjects()
    }, []);

    useEffect(() => {
        if (Object.keys(selectedTagging).length > 0) {
            setProject(selectedTagging?.project_id);
            setTaggingValidity(selectedTagging?.tag_validity);
            setTaggingValidityUnit(selectedTagging?.tag_validity_unit);
            setIsActive(selectedTagging?.is_active)
        }
    }, [selectedTagging])
    const handleSubmit = async () => {
        if (!project || !taggingValidity) {
            toast.error('Please fill required fields');
            return
        }
        const payload = {
            project_id: project,
            tag_validity: taggingValidity,
            tag_validity_unit: taggingValidityUnit,
            is_active: isActive
        }
        let res = {}
        try {
            if (selectedTagging?.uuid) {
                res = await updateTagValidityAutomation(selectedTagging?.uuid, payload);
            } else {
                res = await insertTagValidityAutomation(payload);
            }

            if (res.data.status === 200) {
                toast.success(res.data.message);
                getTaggingData();
                closeModal();
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal}>
            <div className='d-flex justify-content-between !px-5 !py-4'>
                <h1 className='text-black text-2xl inter m-0'>{selectedTagging?.uuid ? 'Edit' : 'Add'} Tag Validity Automation</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <div className='flex h-100 overflow-auto flex-column !px-5'>
                <InputSelect
                    label='Project'
                    placeholder='Select Project'
                    value={project ? projectList?.find(i => i.value === project) : ''}
                    onChange={(value) => {
                        setProject(value.value)
                    }}
                    options={projectList}
                    required={true}
                />
                <div className='!my-4'>
                    <label htmlFor="">Lead Tagging  <sup className='text-red'>*</sup></label>
                    <div className='grid grid-cols-2 gap-2'>
                        <InputText
                            placeholder={'e.g. 30'}
                            value={taggingValidity}
                            onChange={(e) => {
                                setTaggingValidity(e.target.value)
                            }}
                        />
                        <InputSelect
                            inputclass={'m-0'}
                            placeholder={'Select Time'}
                            value={taggingValidityUnit ? validityUnit.find(validity => validity.value === taggingValidityUnit) : ''}
                            options={validityUnit}
                            onChange={(value) => {
                                setTaggingValidityUnit(value.value)
                            }}
                        />
                    </div>
                </div>
                <div className='form-group'>
                    <label>Is Tagging Active?</label>
                    <div>
                        <label className='mr-4'>
                            <input
                                type='radio'
                                name='active'
                                value='yes'
                                checked={isActive}
                                onChange={() => {
                                    setIsActive(true)
                                }}
                            /> Yes
                        </label>
                        <label>
                            <input
                                type='radio'
                                name='active'
                                value='no'
                                checked={!isActive}
                                onChange={() => {
                                    setIsActive(false)
                                }}
                            /> No
                        </label>
                    </div>

                </div>
            </div>
            <div className={`d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow`}>
                <button className='outline-btn w-36 basis-36 shrink-0 grow-0  !text-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button
                    type='button'
                    // disabled={addedUsers.length === 0}
                    onClick={handleSubmit}
                    className='pr-btn w-100 !text-lg'
                >
                    {selectedTagging?.uuid ? 'Update' : 'Confirm'}
                </button>
            </div>
        </RightModal>
    )
}

export default AddTagging