import makeRequest, { formRequest, makeParams } from "../services/api/makeRequest"
import url from "../services/api/urls"
import { RequstMethods } from "../services/api/requestMethods";

const base = url.learning_center.workshop;

export const WorkshopService = {
    async getAllWorkshop(pageCount, pageNumber) {
        try {
            const params = makeParams([{
                index: "perPage",
                value: pageCount
            }, {
                index: "pageNumber",
                value: pageNumber
            }])

            let res = await makeRequest(base.getAllWorkshop + params, RequstMethods.GET);
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            // console.log(err);
            return [];
        }

    },

    async getWorkshopBy_uuid(id) {
        try {
            let res = await makeRequest(`${base.getWorkshopBy_uuid}/${id}`, RequstMethods.GET);
            if (res.status === 200) {
                return res.data.data.workshop;
            }
            else {
                return {};
            }
        }
        catch (err) {
            // console.log(err);
            return {};
        }
    },
    async updateWorkshopByUUID(uuid,payload) {
        return await makeRequest(`${base.updateWorkshopByUUID}/${uuid}`, RequstMethods.PUT,payload);
        // try {
        //     let res = await makeRequest(`${base.updateWorkshopByUUID}/${uuid}`, RequstMethods.GET);
        //     if (res.status === 200) {
        //         return res.data.data.workshop;
        //     }
        //     else {
        //         return {};
        //     }
        // }
        // catch (err) {
        //     // console.log(err);
        //     return {};
        // }
    },

    async getWorkshopFaqs() {
        try {
            let res = await makeRequest(base.getWorkshopFaqs, RequstMethods.GET);
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            // console.log(err);
            return [];
        }
    },

    async getWorkshopStatus() {
        try {
            let res = await makeRequest(base.getAllWorkshopStatuses, RequstMethods.GET);
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            // console.log(err);
            return [];
        }
    },

    async getWorkshopEnrolledUsers() {
        try {
            let res = await makeRequest(base.getWorkshopEnrolledUsers, RequstMethods.GET);
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            // console.log(err);
            return [];
        }
    },
    async getWorkshopUsersbyUuid(uuid) {
        try {
            let res = await makeRequest(`${base.getWorkshopUsersbyUuid}/${uuid}`, RequstMethods.GET);   
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return [];
            }
        }
        catch (err) {
            // console.log(err);
            return [];
        }
    },

    async addWorkshop(formData) {
        console.log("in");
        return await makeRequest(base.createWorkshop, RequstMethods.POST, formData);
    },
    async getAllWorkshopStatuses() {
        return await makeRequest(base.getAllWorkshopStatuses, RequstMethods.GET);
    },
    async changeWorkshopState(uuid, state) {
        return await makeRequest(base.changeWorkshopState + "/" + uuid, RequstMethods.PUT, state);
    },
    async getWorkshopMentors(uuid) {
        return await makeRequest(base.getWorkshopMentors + "/" + uuid, RequstMethods.GET);
    },
    async deleteWorkshopByUUID(uuid) {
        console.log(uuid)
        return await makeRequest(`${base.deleteWorkshopByUUID}/${uuid}`, RequstMethods.DELETE);
    }
}
