import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AddUserInUnassignedLeadsModal from '../Modals/AddUsers'
import { getCancelledEoiAssignmentUsers, getUnassignedLeadDistributionUsers } from '../../../../services/private/company.service';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import AddReassignEoi from '../Modals/AddReassignEoi';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';


const ReassignEoiCancelled = () => {
    const [showAddNewUserModal, setShowddNewUserModal] = useState(false);
    const [users, setUsers] = useState([])

    const getUnassignedLeadsUser = async () => {
        const res = await getCancelledEoiAssignmentUsers();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(u => ({ ...u, label: u.name, value: u.id }))
            setUsers(mapped);
        }
    }

    useEffect(() => {
        getUnassignedLeadsUser();
    }, []);

    return (
        <>
            <div className="roles w-100 flex flex-col h-full">
                <div className="settings-title">
                    <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                        <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                            <li>
                                <Link to={`/settings/automation/lead-automation`}>All Automation</Link>
                            </li>
                            <li className='flex items-center'><RightArrow />
                                Re-assign EOI after cancellation
                            </li>
                        </ul>
                    </nav>

                    <div className="w-100 flex justify-between">
                        <div className="w-1/2">
                            <div className='flex items-center'>
                                <h2 className="text-2xl  !mb-2 mr-2 black fw-po-medium flex items-center gap-x-2">
                                    <Link
                                        to='/settings/automation/lead-automation'
                                        className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                    >
                                        <Pageback className="group-hover:stroke-black" />
                                    </Link>
                                    Re-assign EOI after cancellation
                                </h2>
                            </div>
                            <p className="text-sm black-dark-700 mb-0 pl-10">
                                Cancelled EOI will be assigned to selected users automatically.
                            </p>
                        </div>
                        <div className="w-1/2 flex items-start space-x-2 justify-end">
                            <div>
                                <button className='btn-primary px-3 py-2 rounded-lg' onClick={() => setShowddNewUserModal(true)}>Edit User</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="roles-table ml-24 mt-24 h-full overflow-auto">
                    <div className="def-table pr-0 !h-full">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Users</th>
                                    <th>Mobile No.</th>
                                    <th>Email</th>
                                    {/*<th>Edited Time</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 && users.map((item, index) => (
                                    <tr
                                    // key={`${item.value}`}
                                    >
                                        <td>1</td>
                                        <td className="leading-5"> {item.name}</td>
                                        <td className="leading-5"> {item.mobile}</td>
                                        <td className="leading-5"> {item.email}</td>
                                        {/* <td className="leading-5"> Status</td>
                                        <td className="leading-5"> Status</td> */}
                                    </tr>
                                ))
                                }
                                {
                                    users.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize"> No User Added </h2>
                                                <div>
                                                    <button className='btn-primary px-3 py-2 rounded-lg' onClick={() => setShowddNewUserModal(true)}>Add User</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showAddNewUserModal && <AddReassignEoi
                    show={showAddNewUserModal}
                    closeModal={() => setShowddNewUserModal(false)}
                    getUnassignedLeadsUser={getUnassignedLeadsUser}
                    selectedUsers={users}
                />}
            </div>
        </>
    )
}

export default ReassignEoiCancelled