import React from 'react'
import { DropdownButton } from 'react-bootstrap'
import moment from 'moment'

export default function NotesDropDown({
    note,
    date
}) {
    return (
        <>
            {note ?
                <>
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        View Note
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className="notes drop-notes">
                            <div className="left-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#0062FF" />
                            </svg></div>
                            <div className="right-text">
                                {/* <h2 className='black fz14'>Note</h2> */}
                                <p className='black-dark-700 fz16 mb4 ro'>{note}</p>

                            </div>

                        </div>
                        <p className='black-dark-700 fz12 mb-0 text-right mt-1'>{date ? moment(date).format('h:mm a, Do MMM YYYY') : ''}</p>
                    </div>
                </>
                // <DropdownButton align="end" title="View Note" id="dropdown-menu-align-end">
                //     <div className="notes drop-notes">
                //         <div className="left-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                //             <path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#0062FF" />
                //         </svg></div>
                //         <div className="right-text">
                //             {/* <h2 className='black fz14'>Note</h2> */}
                //             <p className='black-dark-700 fz16 mb4 ro'>{note}</p>

                //         </div>

                //     </div>
                //     <p className='black-dark-700 fz12 mb-0 text-right mt-1'>{date ? moment(date).format('h:mm a, Do MMM YYYY') : ''}</p>
                // </DropdownButton>
                : " - "}

        </>
    )
}