import * as Yup from 'yup';
import { ALL_REGEX } from '../config/static';

export const companyInfoSchema = Yup.object().shape({
    "name": Yup
        .string()
        .required('Country Name is required')
        .matches(/^[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, "Company Name can't start with special character"),
    // "website_url": Yup.string().required('Company Url is required').nullable(),
    // "logo": Yup.string(),
    "owner_name": Yup
        .string()
        .required('Owner Name is required'),
    "owner_mobile": Yup
        .string()
        .required('Owner Mobile is required')
        .min(10, "Phone number is not valid"),
    "owner_email": Yup.string().required('Owner Email is required').matches(
        ALL_REGEX.EMAIL,
        "Please enter valid email id"
    ),
    // "address_1": Yup.string().required('Address1 is required').nullable(),
    "state": Yup.object().required('State required').nullable(),
    // "zip_code": Yup.string().required('Zip Code is required').nullable(),
    // "city": Yup.object().required('City is required').nullable(),
    "country": Yup.string().required('Country is required').nullable(),
})