import React, { useState, useContext } from 'react'
import { useEffect } from 'react';
import { getUsersForSettings } from '../../services/private/company.service';
import InputSelect from '../InputGroup/InputSelect';
import Consumer from '../../helpers/context';
import SuccesDataImport from './succes-data-import';
import { processCSV, saveConfig } from '../../services/private/csv.service';
import moment from 'moment';
import { toast } from 'react-toastify';

const Assigment = ({ uuid, closeModal, getAllDataJobs, updateStep }) => {
    const [routing, setRouting] = useState('CSV_VALUE');
    const [source, setSource] = useState('csv');
    const [routingOrder, setRoutingOrder] = useState([]);
    const [selectedSources, setSelectedSources] = useState([]);
    const [allUser, setAllUsers] = useState([]);
    const [showFinalStep, setShowFinalStep] = useState(false);
    const [creationDate, setCreationDate] = useState('CSV_VALUE');
    const [leadDuplicacy, setLeadDuplicate] = useState('IGNORE');

    let { companyConfig } = useContext(Consumer);

    const assignedToLength = localStorage.getItem('assigned_to')
    const sourceLength = localStorage.getItem('source')
    const date = localStorage.getItem('date')

    useEffect(() => {
        if (!parseInt(assignedToLength)) {
            setRouting('MANUAL')
        }
        if (!date) {
            setCreationDate('CURRENT')
        }
        if (!parseInt(sourceLength)) {
            setSource('assign')
        }


    }, [assignedToLength, date, sourceLength])

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        value: data.uuid
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const processCSVData = () => {
        processCSV(uuid)
            .then((res) => {
                console.log({ res })
                if (res.data.status === 200) {
                    setShowFinalStep(true)
                }
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    const handleSubmit = () => {
        let selectedRouting = [];
        if (routing === 'AUTO' && routingOrder.length === 0) {
            document.getElementById('lead_assingment').scrollTo({ top: 0, behavior: 'smooth' })
            toast.error('Please select assignment value.');
            return;
        }

        if (source === 'assign' && selectedSources.length === 0) {
            toast.error('Please select source value.')
            return;
        }

        if (routingOrder.length > 0) {
            selectedRouting = routingOrder.map((route) => route.value)
        }
        const payload = {
            routing,
            routingOrder: selectedRouting,
            source: source === 'csv' ? null : selectedSources.value,
            creationDate,
            leadDuplicacy
        }
        saveConfig(uuid, payload)
            .then((res) => {
                if (res.data.status === 200) {
                    processCSVData();
                }
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    return (
        <>
            {!showFinalStep &&
                <div className="lead-assignment">
                    <h2 className='black fz24 fw-po-semi mb-18 '> Additional Options</h2>
                    <div className="line"></div>
                    <div className="lead-assignment-inner" id='lead_assingment'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="div">
                                    <h1 className='fz18 black fw-po-medium black flex items-center  mb-16'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mr-8' width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.82692 5.86765C5.82692 3.86968 7.41545 2.25 9.375 2.25C11.3345 2.25 12.9231 3.86968 12.9231 5.86765C12.9231 7.12219 12.2968 8.22758 11.3456 8.8765C13.4802 9.69069 15 11.7894 15 14.25V15.75H3.75V14.25C3.75 11.7894 5.26976 9.69069 7.40443 8.8765C6.45323 8.22758 5.82692 7.12219 5.82692 5.86765ZM9.375 7.89706C10.4743 7.89706 11.3654 6.98846 11.3654 5.86765C11.3654 4.74683 10.4743 3.83824 9.375 3.83824C8.27574 3.83824 7.38462 4.74683 7.38462 5.86765C7.38462 6.98846 8.27574 7.89706 9.375 7.89706ZM9.375 10.1029C7.15761 10.1029 5.35465 11.9121 5.30859 14.1618H13.4414C13.3954 11.9121 11.5924 10.1029 9.375 10.1029Z" fill="#171725" />
                                        </svg>
                                        Lead Assignment Rules
                                    </h1>
                                    <div className="blocks">
                                        {parseInt(assignedToLength) > 0 &&
                                            <div className="block flex-column">

                                                <div className="input">
                                                    <label className='w-100 d-flex align-items-center'>
                                                        <input
                                                            type="radio"
                                                            name='routing'
                                                            id='CSV_VAL_1'
                                                            value={'CSV_VALUE'}
                                                            checked={routing === 'CSV_VALUE'}
                                                            onChange={() => setRouting('CSV_VALUE')}
                                                        />
                                                        <h3>Assigned user column of CSV File</h3>
                                                    </label>
                                                </div>
                                                <div className="description" htmlFor="CSV_VAL_1">
                                                    <p>All leads will be assigned to users as per the mapped assigned user column from csv.</p>
                                                </div>
                                            </div>}
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        name='routing'
                                                        id='CSV_VAL_2'
                                                        value={'MANUAL'}
                                                        checked={routing === 'MANUAL'}
                                                        onChange={() => {
                                                            setRoutingOrder([]);
                                                            setRouting('MANUAL');
                                                        }}
                                                    />
                                                    <h3>Manual assignment</h3>
                                                </label>
                                            </div>
                                            <div className="description" htmlFor="CSV_VAL_2">
                                                <p>All leads will be assigned manually by admins to users</p>
                                            </div>
                                        </div>
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        name='routing'
                                                        id='CSV_VAL_3'
                                                        value={'AUTO'}
                                                        checked={routing === 'AUTO'}
                                                        onChange={() => setRouting('AUTO')}
                                                    />
                                                    <h3>Auto Lead Assignment</h3>
                                                </label>
                                            </div>
                                            <div className="description" htmlFor="CSV_VAL_3">
                                                <p className='mb-2'>All leads will be assigned equally to below selected users automatically by the platform.</p>
                                                <InputSelect
                                                    isMulti={true}
                                                    name='assignment'
                                                    options={allUser}
                                                    value={routingOrder}
                                                    onChange={(value) => setRoutingOrder(value)}
                                                    disable={routing !== 'AUTO'}
                                                    placeholder='Search and Select'
                                                />
                                                {/* <select name="" id="" className='form-control mt-16'></select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="div mt-18">
                                    <h1 className='fz18 black fw-po-medium black flex items-center mb-16'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mr-8' width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.75 6L9 2.25L5.25 6" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9 2.25V11.25" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Updating Leads in Platform
                                    </h1>
                                    <div className="blocks">
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        name='lead_duplicate'
                                                        value={'IGNORE'}
                                                        checked={leadDuplicacy === 'IGNORE'}
                                                        onChange={() => setLeadDuplicate('IGNORE')}
                                                    />
                                                    <h3>Ignore Duplicate Leads</h3>
                                                </label>
                                            </div>
                                            <div className="description">
                                                <p>All duplicate leads with same mobile number will be ignored & non duplicate leads will be uploaded as new lead.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blocks">
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        value={'NEW_LEAD'}
                                                        name='lead_duplicate'
                                                        checked={leadDuplicacy === 'NEW_LEAD'}
                                                        onChange={() => setLeadDuplicate('NEW_LEAD')}
                                                    />
                                                    <h3>Import all Lead as New </h3>
                                                </label>
                                            </div>
                                            <div className="description">
                                                <p>All leads will be uploaded as new lead.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="div mt-18">
                                    <h1 className='fz18 black fw-po-medium black  mb-16 flex items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mr-8' width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <g clipPath="url(#clip0_437_1022)">
                                                <path d="M1.5 9H3.75M14.25 9H16.5M9 1.5V3.75M9 14.25V16.5" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 14.25C11.8995 14.25 14.25 11.8995 14.25 9C14.25 6.10051 11.8995 3.75 9 3.75C6.10051 3.75 3.75 6.10051 3.75 9C3.75 11.8995 6.10051 14.25 9 14.25Z" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_437_1022">
                                                    <rect width="18" height="18" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        Updating Source in Platform
                                    </h1>
                                    <div className="blocks ">
                                        {parseInt(sourceLength) > 0 &&
                                            <div className="block flex-column">
                                                <div className="input">
                                                    <label className='w-100 d-flex align-items-center'>
                                                        <input
                                                            type="radio"
                                                            name='source'
                                                            value={'csv'}
                                                            checked={source === 'csv'}
                                                            onChange={() => setSource('csv')}
                                                        />
                                                        <h3>Source column of CSV File</h3>
                                                    </label>
                                                </div>
                                                <div className="description">

                                                    <p>All Leads will be uploaded with source(s) as per the source column (CSV).</p>
                                                </div>
                                            </div>}
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        name='source'
                                                        value='assign'
                                                        checked={source === 'assign'}
                                                        onChange={() => setSource('assign')}
                                                    />
                                                    <h3>Assign New Source</h3>
                                                </label>
                                            </div>
                                            <div className="description">

                                                <p className='mb-2'>All leads will be uploaded with the below selected source</p>
                                                <InputSelect
                                                    name='source'
                                                    options={companyConfig.Source}
                                                    value={selectedSources}
                                                    onChange={(value) => { setSelectedSources(value) }}
                                                    disable={source !== 'assign'}
                                                    placeholder='Search and Select'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="div mt-18">
                                    <h1 className='fz18 black fw-po-medium black flex items-center mb-16'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className='mr-8' width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 1.5V4.5" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6 1.5V4.5" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.25 7.5H15.75" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Updating Date in Platform
                                    </h1>
                                    <div className="blocks">
                                        {date && <div className="block flex-column">
                                            <div className="input d-felx align-items-centre w-100">
                                                <label className='w-100 d-flex align-itms-center'>
                                                    <input
                                                        type="radio"
                                                        name='date'
                                                        id='cheedd'
                                                        value={'CSV_VALUE'}
                                                        checked={creationDate === 'CSV_VALUE'}
                                                        onChange={() => setCreationDate('CSV_VALUE')}
                                                    />
                                                    <h3 htmlFor="cheedd">Created Date Column of CSV File</h3></label>
                                            </div>
                                            <div className="description">

                                                <p>All leads will be uploaded as per created date column (CSV). Leads without any date in CSV will be uploaded with today’s date.</p>
                                            </div>
                                        </div>}
                                        <div className="block flex-column">
                                            <div className="input">
                                                <label className='w-100 d-flex align-items-center'>
                                                    <input
                                                        type="radio"
                                                        name='date'
                                                        value={'CURRENT'}
                                                        checked={creationDate === 'CURRENT'}
                                                        onChange={() => setCreationDate('CURRENT')}
                                                    />
                                                    <h3>Upload with Created Date as {moment(Date.now()).format("Do MMMM YYYY")} (Today) </h3>
                                                </label>
                                            </div>
                                            <div className="description">

                                                <p className='mb-2'>All leads will be uploaded with created date as {moment(Date.now()).format("Do MMMM YYYY")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div>
            }
            {showFinalStep && <SuccesDataImport closeModal={closeModal} getAllDataJobs={getAllDataJobs} />}

            <div className="d-flex flex-row align-items-center justify-content-center def-btn-set">
                <button className='outline-btn' onClick={() => updateStep(3)}>Previous</button>
                <button className='pr-btn ml-8' onClick={handleSubmit}>Upload Leads</button>
            </div>
        </>
    )
}

export default Assigment;