import React, { useEffect, useState } from 'react'
import Breadcrumb from './Breadcrumb';
import CertificationBy from './CertificationBy';
import CourseDetailBlock from './CourseDetailBlock';
import CourseOutline from './CourseOutline';
import FAQ from './FAQ';
import Mentor from './Mentor';
import OtherRelevantCourses from './OtherRelevantCourses';
import style from './style.module.css';
import './CourseDetail.css'
import { useParams } from 'react-router';
import { getAllCourses, getAllFaqs, getCourseById, getCourseContent } from '../../../services/public/public.service';
import moment from 'moment';

const Index = () => {

    const [courseDetail, setCourseDetail] = useState({});
    const [courseContent, setCourseContent] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [courses, setCourses] = useState([]);
    const [mentors, setMentors] = useState([]);
    const [mentorsSideBar, setMentorsSideBar] = useState([])
    const [count, setCount] = useState({
        live: 0,
        recording: 0,
        guide: 0
    });

    const { id } = useParams();

    const getCourseDetails = async () => {
        const res = await getCourseById(id);
        if (res.data.status === 200) {
            setCourseDetail(res.data.data)
        }
    }

    const getCourseContentById = async () => {
        const res = await getCourseContent(id)
        if (res.data.status) {
            let mergedMentor = [];
            let mentorsList = [];
            res.data.data.forEach((item) => {
                if (item.content_type === 'live') {
                    setCount(prev => ({
                        ...prev,
                        'live': prev.live + 1
                    }))
                }
                if (item.content_type === 'recording') {
                    setCount(prev => ({
                        ...prev,
                        'recording': prev.recording + 1
                    }))
                }
                if (item.content_type === 'guides') {
                    setCount(prev => ({
                        ...prev,
                        'guide': prev.guide + 1
                    }))
                }
                mentorsList.push(item.mentors)
                mergedMentor = mergedMentor.concat(item.mentors).reduce((acc, current) => {
                    if (current?.id && !acc.find(item => item.id === current.id && item.name === current.name)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
            })
            setMentorsSideBar(mentorsList);
            setMentors(mergedMentor)
            setCourseContent(res.data.data);
        }
    }

    const getFAQByUuid = async () => {
        const res = await getAllFaqs(id)
        if (res.data.status === 200) {
            setFaqs(res.data.data);
        }
    }

    const getAll = async () => {
        const res = await getAllCourses();
        if (res.data.status === 200) {
            let courses = []
            res.data.data.forEach((course, index) => {
                courses[index] = { ...course }
                courses[index]['liveSession'] = course?.course_content?.find(item => {
                    return item?.content_type === 'live' && (moment(item.session_start_time).add('5:30') >= moment() || moment(item.session_end_time).add('5:30') >= moment())
                });
                if (courses[index]['liveSession']) {
                    courses[index]['liveSession'].session_start_time = moment(courses[index]['liveSession'].session_start_time).add('5:30')
                    courses[index]['liveSession'].session_end_time = moment(courses[index]['liveSession'].session_end_time).add('5:30')
                }
            })
            setCourses(courses)
            const filteredCourses = courses.filter(item => item.uuid != id)
            setCourses(filteredCourses);
        }
    }

    useEffect(() => {
        if (id) {
            getCourseDetails();
            getCourseContentById();
            getFAQByUuid();
            getAll();
        }
    }, [id]);

    return (
        <div className={`${style.coursDetailMainSection} main-section`}>
            <div className={`${style.CourseDetails_page}`}>
                <Breadcrumb style={style} name={courseDetail.name} />
                <div className='mt-8'>
                    <CourseDetailBlock
                        style={style}
                        courseDetail={courseDetail}
                        mentors={mentors}
                        liveSession={courseContent.find(item => item.content_type === 'live' && (moment(item.session_start_time) > moment() || moment(item.session_end_time) > moment()))}
                    />
                </div>
                <div>
                    <CourseOutline
                        style={style}
                        courseContent={courseContent}
                        learningSection={courseDetail.learn_section}
                        displayCertificate={courseDetail.display_certificate}
                        count={count}
                    />
                </div>
                {mentors.length > 0 && <div>
                    <Mentor style={style} mentors={mentors} />
                </div>}
                {courseDetail.display_certificate && <div>
                    <CertificationBy style={style} />
                </div>}
                {courses?.length > 0 && <div><OtherRelevantCourses style={style} courses={courses} /></div>}
                {faqs.length > 0 && <div><FAQ style={style} faqs={faqs} /></div>}
            </div >
        </div>
    )
}

export default Index
