import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Skeleton from "react-loading-skeleton";

export default function TableSkeleton() {
    return (
        <>
            <tr>
                <td style={{ width: '45px' }}><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td><Skeleton count={1} height="10px" width="100%" /></td>
                <td className='bg-tr' ><Skeleton count={1} height="10px" width="100%" /></td>
            </tr>



            {/* <Row className="mt-3 d-flex align-items-center skelton-area">


                <Col>
                    <Skeleton count={1} height="25px" width="25px" />
                </Col>

                <Col md={2}>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" />
                </Col>
                <Col>
                    <Skeleton count={1} height="20px" width="10px" />
                </Col>
            </Row> */}
        </>

    )
}