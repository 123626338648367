import moment from 'moment'
import React, { useState } from 'react'
import Countdown from 'react-countdown'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const CoursesCard = ({ course }) => {

    let timeDiff = moment(course?.liveSession?.session_start_time).diff(moment(), 'hours');

    return (
        <div >
            {/* Live course card */}
            {!course.is_promo && <Link to={`/learning-center/course-detail/${course.uuid}`}>
                <div className="card d-flex flex-fill shadow-none p-0">
                    <figure className='position-relative mb-0 radius-8'>
                        <img src={course.card_thumbnail} alt="" />
                        <div className="view-details">
                            <span>View Details</span>
                        </div>
                        {
                            course?.liveSession && course?.liveSession?.session_start_time &&
                            timeDiff < 24 &&
                            <span className='live-now' style={{ color: '#ffffff' }}>
                                <Countdown
                                    date={`${course?.liveSession?.session_start_time}`}
                                    renderer={
                                        ({ days, hours, minutes, seconds, completed }) => {
                                            if (completed && minutes === 0 && seconds === 0) {
                                                return <> <div className="an_circles">
                                                    <div className="circle1"></div>
                                                    <div className="circle2"></div>
                                                    <div className="circle3"></div>
                                                </div>
                                                    Live </>
                                            }
                                            if (!(days > 0)) {
                                                if (hours > 0) {
                                                    return <div className='liveintimeonly'> Live in {hours} : {minutes} hrs</div>
                                                } else if (minutes + 1 > 0) {
                                                    return <div className='liveintimeonly'> Live in {minutes + 1} mins</div>
                                                }
                                            } else {
                                                return <></>;
                                            }
                                        }
                                    }
                                />
                            </span>
                        }

                    </figure >
                    {
                        course.name.length >
                            70 ?
                            <OverlayTrigger className=''
                                placement="auto"

                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                        <div className='info-msg'>
                                            <p className='d-flex fz18 w-auto'>{course.name}</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <h2 className='fz16 black-dark-800 po fw-po-medium mt-8 mb-0 courseH2dots'>{course.name}</h2>
                            </OverlayTrigger>
                            :
                            <h2 className='fz16 black-dark-800 po fw-po-medium mt-8 mb-0'>{course.name}</h2>
                    }
                    {
                        (course.mentors || course.basic_mentors) && (course.mentors?.join(', ') || course.basic_mentors?.join(', ')).length > 30 ?
                            <OverlayTrigger className=''
                                placement="auto"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                        <div className='info-msg'>
                                            <p className='d-flex fz18 w-auto'>By {course.mentors?.join(', ') || course.basic_mentors?.join(', ')}</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <p className='fz14 black-dark-700 worksans mb-0 mt-8 mentor'>By {course.mentors?.join(', ') || course.basic_mentors?.join(', ')}</p>

                            </OverlayTrigger>
                            :
                            (course.mentors || course.basic_mentors) && <p className='fz14 black-dark-700 worksans mb-0 mt-8 '>By {course.mentors?.join(', ') || course.basic_mentors?.join(', ')}</p>
                    }
                    {/* if no live session */}
                    {!course.liveSession &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            {moment(course?.start_time) <= moment() && <div className='d-flex flex-wrap'> Started at {`${moment(course?.start_time).format('h:mm a')}`}, {moment(course?.session_date).format('DD MMM')}</div>}
                            {moment(course?.start_time) > moment() && <div className='d-flex flex-wrap'> Starts at {`${moment(course?.start_time).format('h:mm a')}`}, {moment(course?.session_date).format('DD MMM')}</div>}
                        </div>
                    }
                    {/* if course start time less than 24 hrs */}
                    {/* {course.liveSession && (moment(course?.liveSession?.session_start_time).diff(moment(), 'hours') < 24) && (moment(course?.liveSession.session_start_time) > moment()) &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'> Live Session at {`${moment(course?.liveSession?.session_start_time).format('h:mm a')}`}, {moment(course?.liveSession?.session_date).format('DD MMM')}</div>
                        </div>} */}

                    {/* if session is live */}
                    {/* {course.liveSession && ((moment(course?.liveSession.session_start_time) < moment())) && ((moment(course?.liveSession.session_end_time) > moment())) &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'>Live Session at {`${moment(course?.liveSession?.session_start_time).format('h:mm a')}`}, {moment(course?.liveSession?.session_date).format('DD MMM')}</div>
                        </div>} */}

                    {/* session start time greater 24 hrs */}
                    {/* {course.liveSession && (moment(course?.liveSession?.session_start_time).diff(moment(), 'hours') >= 24) &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'>Live Session at {`${moment(course?.liveSession?.session_start_time).format('h:mm a')}`}, {moment(course?.liveSession?.session_date).format('DD MMM')}</div>
                        </div>} */}

                    {course.liveSession && course?.liveSession?.session_date && course?.liveSession?.session_start_time &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'>Live Session at {`${moment(course?.liveSession?.session_start_time).format('h:mm a')}`}, {moment(course?.liveSession?.session_date).format('DD MMM')}</div>
                        </div>}
                </div >
            </Link >}
            {/* Promotional session card */}
            {course.is_promo &&
                <div className="card d-flex flex-fill shadow-none p-0">
                    <figure className='position-relative mb-0 radius-8'>
                        <img src={course.card_thumbnail} alt="" />
                        {!(moment(course?.session_date) < moment()) && <div className='coming-soon-btn'>Coming Soon</div>}
                    </figure >
                    {
                        course.name.length > 70 ?
                            <OverlayTrigger className=''
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                        <div className='info-msg'>
                                            <p className='d-flex fz18 w-auto'>{course.name}</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <h2 className='fz16 black-dark-800 po fw-po-medium mt-8 mb-0 courseH2dots'>{course.name}</h2>
                            </OverlayTrigger>
                            :
                            <h2 className='fz16 black-dark-800 po fw-po-medium mt-8 mb-0'>{course.name}</h2>
                    }
                    {
                        course.basic_mentors?.length > 0 && course.basic_mentors?.join(', ').length > 30 ?
                            <OverlayTrigger className=''
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" className='sidebar-tooltip guidetooltip' >
                                        <div className='info-msg'>
                                            <p className='d-flex fz18 w-auto'>By {course.basic_mentors?.join(', ')}</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <p className='fz14 black-dark-700 worksans mb-0 mt-8 mentor'>By {course.basic_mentors.join(', ')}</p>
                            </OverlayTrigger>
                            :
                            course.basic_mentors?.length > 0 && <p className='fz14 black-dark-700 worksans mb-0 mt-8 '>By {course.basic_mentors?.join(', ')}</p>
                    }
                    {/* future promo */}
                    {
                        (course?.session_date && course?.start_time) &&
                        moment(course?.session_date) > moment() &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'>{`${moment(course?.start_time).format('hh:mm a')}`}, {moment(course?.session_date).format('DD MMM YYYY')}</div>
                        </div>
                    }
                    {
                        (course?.session_date && !course?.start_time) &&
                        moment(course?.session_date) > moment() &&
                        <div className="video-time-date mt-8 fw-po-medium d-flex align-items-start worksans fz14 black-dark-800">
                            <div className='calender'><svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7.0793 4.83329C7.03749 5.2544 6.68219 5.58329 6.25008 5.58329C5.81797 5.58329 5.46267 5.2544 5.42086 4.83329H3.33341V6.16663H16.6667V4.83329H15.3293C15.2875 5.2544 14.9322 5.58329 14.5001 5.58329C14.068 5.58329 13.7127 5.2544 13.6709 4.83329H7.0793ZM5.41675 3.16663V2.49996C5.41675 2.03972 5.78984 1.66663 6.25008 1.66663C6.71032 1.66663 7.08342 2.03972 7.08342 2.49996V3.16663H13.6667V2.49996C13.6667 2.03972 14.0398 1.66663 14.5001 1.66663C14.9603 1.66663 15.3334 2.03972 15.3334 2.49996V3.16663H16.7501C17.6245 3.16663 18.3334 3.87551 18.3334 4.74996V15.8333C18.3334 17.214 17.2141 18.3333 15.8334 18.3333H4.16675C2.78604 18.3333 1.66675 17.214 1.66675 15.8333V4.74996C1.66675 3.87551 2.37563 3.16663 3.25008 3.16663H5.41675ZM16.6667 7.83329H3.33341V15.8333C3.33341 16.2935 3.70651 16.6666 4.16675 16.6666H15.8334C16.2937 16.6666 16.6667 16.2935 16.6667 15.8333V7.83329ZM5.50008 10.8333C5.03984 10.8333 4.66675 10.4602 4.66675 9.99996C4.66675 9.53972 5.03984 9.16663 5.50008 9.16663H8.50008C8.96032 9.16663 9.33342 9.53972 9.33342 9.99996C9.33342 10.4602 8.96032 10.8333 8.50008 10.8333H5.50008ZM5.50008 13.8333C5.03984 13.8333 4.66675 13.4602 4.66675 13C4.66675 12.5397 5.03984 12.1666 5.50008 12.1666H11.5001C11.9603 12.1666 12.3334 12.5397 12.3334 13C12.3334 13.4602 11.9603 13.8333 11.5001 13.8333H5.50008Z" fill="#92929D"></path></svg></div>
                            <div className='d-flex flex-wrap'>{moment(course?.session_date).format('DD MMM YYYY')}</div>
                        </div>
                    }

                    {/* for past promo date */}
                    {
                        course?.session_date &&
                        moment(course?.session_date) < moment() &&
                        <p className='fz14 black-dark-700 worksans mb-0 mt-8 '>Coming Soon</p>
                    }
                </div >
            }
        </div >
    )
}

export default CoursesCard
