import React from 'react';
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/threedots.svg';
import { Oval } from 'react-loader-spinner';

const CompanyUsers = ({ users, setSelectedUser, setShowAddBrokerCompanyUser, isLoading }) => {
    return (
        <div className="user-table">
            <div className="def-table pr-24">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No.</th>
                            <th>Role </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.length > 0 && users.map((user, index) => (
                                <tr key={user.uuid}>
                                    <td>{index + 1}</td>
                                    <td className="text-capitalize">{user.name}</td>
                                    <td>{user.email || '-'}</td>
                                    <td>{user.mobile || '-'}</td>
                                    <td>{user.role_name === 'owner' ? 'Owner' : 'Employee'}</td>
                                    <td >
                                        {!user.is_owner && <div className='dropdown view-more'>
                                            <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <ThreedotsIcon />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item text-capitalize"
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                        setShowAddBrokerCompanyUser(true)
                                                    }}
                                                >
                                                    Edit User
                                                </a>

                                            </div>
                                        </div>}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {isLoading && <div className='d-flex justify-content-center flex-column text-center h-full'>
                    <div className='flex items-center flex-col'>
                        <Oval
                            height={50}
                            width={50}
                            color="#0062FF"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#0062FF"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                        />

                    </div>
                </div>}
            </div>
        </div>
    )
}

export default CompanyUsers