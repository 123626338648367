import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import projectcommingsoon from '../../assets/projectcommingsoon.png';
import AddYourListModal from '../../components/Modals/AddYourListModal';
import ContactModal from '../../components/Modals/ContactModal';
import Card from './project-card';
import { addToProjectList, getAllProjectsForCRM, insertProjectRegisteration } from '../../services/public/public.service';
import cardbg from '../../assets/upcardimg.png';
import { ReactComponent as PlusgrayIcon } from '../../assets/plusgray.svg';
import { ReactComponent as TrendingIcon } from '../../assets/trending.svg';
import { ReactComponent as NewIcon } from '../../assets/new.svg';
import { ReactComponent as CallbtnIcon } from '../../assets/callbtn.svg';
import FiltersListing from '../Settings/Projects/filtersListing';


const Projects = ({ style, filters, tab, searchText }) => {

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [showAddToListModal, setShowAddToListModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [allProjects, setAllProjects] = useState([])

    const getAllProjects = async () => {
        const res = await getAllProjectsForCRM({ ...filters, searchText });
        if (res.data.status === 200) {
            setProjects(res.data.data.splice(0, 9));
            setAllProjects(res.data.data);
        }
    }

    const handleShowAddToList = useCallback((item) => {
        setSelectedProject(item);
        if (localStorage.getItem('dontShow') === 'true') {
            handleAddToList(item.uuid)
        } else {
            setShowAddToListModal(true);
        }
    }, []);

    const handleShowContactModal = useCallback((item) => {
        setSelectedProject(item)
        setShowContactModal(true)
    }, []);

    const handleAddToList = useCallback(async (uuid) => {
        const res = await addToProjectList(uuid)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAllProjects();
        }
    }, []);

    const handleLoadMore = () => {
        let newList = [...allProjects];
        const spliced = newList.splice(0, 9);
        setProjects([...projects, ...spliced]);
        setAllProjects(newList);
    }

    const handleContact = useCallback(async (uuid) => {
        const res = await insertProjectRegisteration(uuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            setShowContactModal(false);
        }
    }, []);

    useEffect(() => {
        if (tab === 'Project') {
            getAllProjects();
        }
    }, [filters, searchText]);

    return (
        <>
            <div className={`${style.PMcommingsoon} mx-sm-0 d-none`}>
                <figure className=''><img src={projectcommingsoon} alt="" className='w-100' /></figure>
                <h1 className={`${style.heading} inter black fz16 fw-po-medium mb-8 text-center`}>Project Manager Coming Soon! </h1>
                {/* <p className={`${style.subheading} inter d-table mx-auto text-center black-dark-600 fz14 mb-0`}>
                The ultimate platform for brokers to connect with
                <span className='d-md-block mx-auto'> developers and discover the best projects in the market.</span>
                <span className='d-md-block mx-auto'> Stay tuned for more updates!</span></p> */}
            </div>

            <FiltersListing filters={filters} />
            <div className={`${style.projectcardBlock}`}>
                {/* filters */}
                {
                    projects.map((item, index) => (
                        <Card
                            style={style}
                            item={item}
                            key={`${item.uuid}_${index}`}
                            index={index}
                            handleAddToList={handleAddToList}
                            handleShowAddToList={handleShowAddToList}
                            handleShowContactModal={handleShowContactModal}
                        />
                    ))
                }
            </div >
            {allProjects.length > 0 && <button className={`${style.loadmorebtn} inter mt-3`} onClick={handleLoadMore}>Load More</button>}

            <AddYourListModal
                show={showAddToListModal}
                closeModal={() => setShowAddToListModal(false)}
                item={selectedProject}
                style={style}
                handleAddToList={handleAddToList}
            />
            <ContactModal
                show={showContactModal}
                closeModal={() => setShowContactModal(false)}
                item={selectedProject}
                style={style}
                handleContact={handleContact}
            />
        </>
    )
}

export default Projects