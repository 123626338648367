import React, { useEffect, useState } from 'react'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as PlushIcons } from '../../../assets/icons/whitePlush.svg';
import SearchBox from '../../InputGroup/Searchbox';
import { toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';

const getStatusClasses = (status) => {
    switch (status) {
        // case 'pending':
        //     return 'text-yellow-dark bg-yellow-100'
        // case 'rejected':
        //     return 'text-red bg-red-light'
        case 'approved':
            return 'text-green bg-green100'
        default:
            return 'text-yellow-dark bg-yellow-100'
    }
}

const calculatePercentage = (total, paid) => {
    return ((paid / total) * 100).toFixed(2);
}

const Collectionstab = ({ collections, collectionStats, planName, totalPrice, setShowAddCollectionModal }) => {
    const [searchText, setSearchText] = useState('');
    const [collectionList, setCollectionList] = useState([]);
    const [tempConfig, setTempConfig] = useState([]);

    useEffect(() => {
        setCollectionList(collections);
    }, [collections])

    useEffect(() => {
        if (searchText) {
            const filtered = collections.filter(item => item?.total_amount_paid?.indexOf(searchText) > -1);
            setCollectionList(filtered);

        } else {
            setCollectionList(collections);
        }
    }, [searchText]);

    // export csv
    const exportConfig = () => {
        let temp = collectionList.map((item, index) => {
            return {
                "Demand ID": item?.demand_id || '-',
                "Collection Id": item?.unique_id || '-',
                "Milestone": item?.demand_milestone_no?.join(', ') || '-',
                "Raise Date": item?.demand_raised_date ? moment(item?.demand_raised_date).format('Do MMM YYYY') : '-',
                "Added Date": item?.collection_added_date ? moment(item?.collection_added_date).format('Do MMM YYYY') : '-',
                "Status": item.approval_status,
                "Gross Amt. (i)": toINRFormat(item?.total_amount_due || '-'),
                "Paid Amt. (i)</th>": toINRFormat(item?.total_amount_paid || '-'),
                "Collection Type": item?.collection_type,
                "Paid By": item?.paid_by || '-',
                "Payment Mode": item?.transaction_mode || '-',
                "Cheque No.": item?.transaction_cheque_no || '-',
                "Cheque Date": item?.transaction_cheque_date ? moment(item?.transaction_cheque_date).format('Do MMM YYYY') : '-',
                "Bank Name (Sender)": item?.sender_bank_name || '-',
                "Bank A/C No. (Sender)": item?.sender_bank_account || '-',
                "Bank Name (Receiver)": item?.receiver_bank_name || '-',
                "Bank A/C No. (Receiver)": item?.receiver_bank_account || '-',
                "Transaction ID": item?.transaction_id || '-',
                "Transaction Date": item?.transaction_date ? moment(item?.transaction_date).format('Do MMM YYYY') : '-',
                "Received Date": item?.amount_received_date ? moment(item?.amount_received_date).format('Do MMM YYYY') : '-',
                "Payment Proof": '-',
                "Approved By": item?.collection_approved_by || '-',
                "Approved Date": item?.collection_approval_time ? moment(item?.collection_approval_time).format('Do MMM YYYY') : '-',
                "Added By": item?.collection_added_by || '-'
            }
        })
        setTempConfig(temp);

    };

    return (
        <div className='bg-white !mr-2.5 !border !border-grayLight shadow-boxshadow flex flex-col gap-y-8 !px-5 !py-5 rounded-lg'>
            <div>
                <div className='flex flex-row items-center'>
                    <div className='w-6/12'>
                        <h2 className='inter text-textblack font-semibold text-xl mb-0'>Collection</h2>
                    </div>
                    <div className='w-6/12'>
                        <div className='flex items-center gap-x-3 justify-end'>
                            <div className='w-[218px] shrink-0 grow-0 basis-[218px]'>
                                <SearchBox
                                    inputClass='!pl-8 !py-1.5 !pr-8 text-sm leading-5 rounded'
                                    searchIconClass='w-5 h-5'
                                    placeholder='Search Paid Amount'
                                    value={searchText}
                                    onChange={(e) => setSearchText(
                                        e.target.value
                                            .replace(/^0+/g, '')
                                            .replace(/[^0-9]/g, ''))}
                                    handelClear={() => setSearchText('')}
                                />
                            </div>

                            {/* <DownloadIcon />Download */}
                            <CSVLink
                                className={`inline-flex items-center text-black700 gap-x-2 text-sm font-medium !border !border-grayLight rounded bg-black100 h-[32px] !px-4 inter`}
                                data={tempConfig}
                                onClick={exportConfig}
                                filename={'configuration.csv'}>
                                <DownloadIcon />Download
                            </CSVLink>
                            <div>
                                <button
                                    className='h-[32px] pr-btn inline-flex items-center gap-x-1 inter'
                                    onClick={() => setShowAddCollectionModal(true)}
                                >
                                    <PlushIcons />
                                    Add New Collection
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div>
                    <div className='inline-flex flex-row !border border-grayLight rounded-lg shadow-boxshadow divide-x divide-grayLight !mt-5'>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{planName?.name || '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Payment Plan</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.total_demand_raised ? `₹ ${toINRFormat(collectionStats?.total_demand_raised)}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Demand Raised</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.total_amount_paid ? `₹ ${toINRFormat(collectionStats?.total_amount_paid)}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Amt. Collected</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <h3 className='text-xl inter text-textblack font-semibold leading-7 m-0'>{collectionStats?.total_amount_paid && collectionStats?.total_demand_raised ? `₹ ${toINRFormat(parseFloat(collectionStats?.total_demand_raised) - parseFloat(collectionStats?.total_amount_paid))}` : '-'}</h3>
                            <p className='inter text-black700 text-sm leading-6 m-0'>Total Remaining Amt.</p>
                        </div>
                        <div className='flex flex-col gap-y-3 py-4 px-9'>
                            {(totalPrice && collectionStats?.total_amount_paid) ? <h4 className='inter text-xl text-textblack font-semibold leading-6 !mb-1'>{calculatePercentage(parseFloat(totalPrice), parseFloat(collectionStats?.total_amount_paid))} % Collected</h4> : '-'}
                            <p className='inter text-black700 text-sm leading-6 m-0'> {collections.length} Collections</p>
                        </div>
                        {/* <div className='flex flex-col gap-y-3 py-4 px-9'>
                            <div className='flex items-center gap-x-3'>
                                <div className='w-11 h-11 grow-0 shrink-0 basis-11'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                        <mask id="path-1-inside-1_570_4318" fill="white">
                                            <path d="M22.5587 1.87164C22.5873 0.84264 23.4464 0.0234649 24.4693 0.138995C28.2401 0.564891 31.8482 1.96066 34.9349 4.20423C38.5257 6.81418 41.2429 10.4493 42.7296 14.6321C44.2163 18.8148 44.4029 23.3494 43.2648 27.6401C42.2865 31.3285 40.3687 34.6884 37.7126 37.3986C36.992 38.1338 35.8087 38.0406 35.1371 37.2605L30.3926 31.7491C29.721 30.9689 29.8239 29.8004 30.4796 29.0069C31.4863 27.7886 32.2235 26.3617 32.6324 24.8201C33.2015 22.6747 33.1082 20.4074 32.3648 18.316C31.6215 16.2247 30.2629 14.4071 28.4675 13.1021C27.1773 12.1643 25.705 11.5228 24.1552 11.2132C23.1457 11.0115 22.3284 10.1701 22.357 9.14105L22.5587 1.87164Z" />
                                        </mask>
                                        <path d="M22.5587 1.87164C22.5873 0.84264 23.4464 0.0234649 24.4693 0.138995C28.2401 0.564891 31.8482 1.96066 34.9349 4.20423C38.5257 6.81418 41.2429 10.4493 42.7296 14.6321C44.2163 18.8148 44.4029 23.3494 43.2648 27.6401C42.2865 31.3285 40.3687 34.6884 37.7126 37.3986C36.992 38.1338 35.8087 38.0406 35.1371 37.2605L30.3926 31.7491C29.721 30.9689 29.8239 29.8004 30.4796 29.0069C31.4863 27.7886 32.2235 26.3617 32.6324 24.8201C33.2015 22.6747 33.1082 20.4074 32.3648 18.316C31.6215 16.2247 30.2629 14.4071 28.4675 13.1021C27.1773 12.1643 25.705 11.5228 24.1552 11.2132C23.1457 11.0115 22.3284 10.1701 22.357 9.14105L22.5587 1.87164Z" fill="url(#paint0_linear_570_4318)" stroke="white" strokeWidth="1.49111" mask="url(#path-1-inside-1_570_4318)" />
                                        <g opacity="0.4">
                                            <mask id="path-2-inside-2_570_4318" fill="white">
                                                <path d="M35.0557 37.3301C35.7232 38.1138 35.6331 39.2974 34.7957 39.8961C32.412 41.6004 29.7065 42.8151 26.8357 43.462C23.3573 44.2457 19.7407 44.1722 16.297 43.248C12.8534 42.3237 9.68598 40.5763 7.06755 38.1563C4.44912 35.7362 2.45813 32.716 1.26601 29.3556C0.0738884 25.9953 -0.28364 22.3956 0.224211 18.8665C0.732066 15.3373 2.09008 11.9845 4.18132 9.09663C6.27256 6.20879 9.03436 3.87251 12.2289 2.28893C14.8655 0.981935 17.7317 0.22001 20.6565 0.0410784C21.684 -0.0217801 22.4999 0.840426 22.4756 1.86954L22.3038 9.13972C22.2795 10.1688 21.42 10.9672 20.4016 11.1168C19.2625 11.2841 18.1529 11.6297 17.1144 12.1445C15.5172 12.9363 14.1363 14.1044 13.0906 15.5483C12.045 16.9922 11.366 18.6687 11.1121 20.4332C10.8582 22.1978 11.0369 23.9977 11.633 25.6778C12.229 27.358 13.2245 28.8681 14.5338 30.0781C15.843 31.2882 17.4267 32.1619 19.1485 32.624C20.8703 33.0861 22.6787 33.1228 24.4178 32.731C25.5485 32.4762 26.6279 32.0453 27.6177 31.4573C28.5027 30.9316 29.6731 31.0099 30.3406 31.7936L35.0557 37.3301Z" />
                                            </mask>
                                            <path d="M35.0557 37.3301C35.7232 38.1138 35.6331 39.2974 34.7957 39.8961C32.412 41.6004 29.7065 42.8151 26.8357 43.462C23.3573 44.2457 19.7407 44.1722 16.297 43.248C12.8534 42.3237 9.68598 40.5763 7.06755 38.1563C4.44912 35.7362 2.45813 32.716 1.26601 29.3556C0.0738884 25.9953 -0.28364 22.3956 0.224211 18.8665C0.732066 15.3373 2.09008 11.9845 4.18132 9.09663C6.27256 6.20879 9.03436 3.87251 12.2289 2.28893C14.8655 0.981935 17.7317 0.22001 20.6565 0.0410784C21.684 -0.0217801 22.4999 0.840426 22.4756 1.86954L22.3038 9.13972C22.2795 10.1688 21.42 10.9672 20.4016 11.1168C19.2625 11.2841 18.1529 11.6297 17.1144 12.1445C15.5172 12.9363 14.1363 14.1044 13.0906 15.5483C12.045 16.9922 11.366 18.6687 11.1121 20.4332C10.8582 22.1978 11.0369 23.9977 11.633 25.6778C12.229 27.358 13.2245 28.8681 14.5338 30.0781C15.843 31.2882 17.4267 32.1619 19.1485 32.624C20.8703 33.0861 22.6787 33.1228 24.4178 32.731C25.5485 32.4762 26.6279 32.0453 27.6177 31.4573C28.5027 30.9316 29.6731 31.0099 30.3406 31.7936L35.0557 37.3301Z" fill="url(#paint1_linear_570_4318)" fillOpacity="0.8" stroke="white" strokeWidth="1.49111" mask="url(#path-2-inside-2_570_4318)" />
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_570_4318" x1="29.8314" y1="22.2424" x2="23.4576" y2="-86.2269" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#1B59F8" />
                                                <stop offset="1" stopColor="#1B59F8" stopOpacity="0" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_570_4318" x1="14.1686" y1="21.7576" x2="20.5424" y2="130.227" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#1B59F8" />
                                                <stop offset="1" stopColor="#1B59F8" stopOpacity="0" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className='flex flex-col gap-y-3 py-4 px-9'>
                                    {(totalPrice && collectionStats?.total_amount_paid) ? <h4 className='inter text-xl text-textblack font-semibold leading-6 !mb-1'>{calculatePercentage(parseFloat(totalPrice), parseFloat(collectionStats?.total_amount_paid))} % Collected</h4> : '-'}
                                    <p className='inter text-black700 text-sm leading-6 m-0'> {collections.length} Collections</p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div className='!mt-5'>
                    {/* <div className='def-table plantable h-auto !overflow-auto !p-0'> */}
                    <div className={`def-table plantable h-auto !p-0 ${collectionList.length === 0 ? '!overflow-hidden' : '!overflow-auto'}`}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th class="">S. No.</th>
                                    <th>Demand ID</th>
                                    <th>Collection Id</th>
                                    <th>Milestone</th>
                                    {/* <th>Milestone Description</th> */}
                                    <th>Raise Date</th>
                                    <th>Added Date</th>
                                    <th>Status</th>
                                    <th>Gross Amt. (i)</th>
                                    <th>Paid Amt. (i)</th> {/* comments in inventory exel*/}
                                    <th>Collection Type</th>
                                    <th>Paid By</th>
                                    <th>Payment Mode</th>
                                    <th>Cheque No.</th>
                                    <th>Cheque Date</th>
                                    <th>Bank Name (Sender)</th>
                                    <th>Bank A/C No. (Sender)</th>
                                    <th>Bank Name (Receiver)</th>
                                    <th>Bank A/C No. (Receiver)</th>
                                    <th>Transaction ID</th>
                                    <th>Transaction Date</th>
                                    <th>Received Date</th>
                                    <th>Payment Proof</th>
                                    <th>Approved By</th>
                                    <th>Approved Date</th>
                                    <th>Added By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    collectionList.length > 0 && collectionList.map((item, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item?.demand_unique_id || '-'}</td>
                                            <td>{item?.unique_id || '-'}</td>
                                            <td>{item?.demand_milestone_no?.join(', ') || '-'}</td>
                                            {/* <td>{item?.remarks || '-'}</td> */}
                                            <td>{item?.demand_raised_date ? moment(item?.demand_raised_date).format('Do MMM YYYY') : '-'}</td>
                                            <td>{item?.collection_added_date ? moment(item?.collection_added_date).format('Do MMM YYYY') : '-'}</td>
                                            <td><span className={`${getStatusClasses(item.approval_status)}  text-sm font-medium !px-2.5 !py-1 rounded capitalize w-20`}>{item.approval_status || 'Pending'}</span></td>
                                            <td>{item?.total_amount_due ? `₹ ${toINRFormat(item?.total_amount_due)}` : '-'}</td>
                                            <td>{item?.total_amount_paid ? `₹ ${toINRFormat(item?.total_amount_paid)}` : '-'}</td>
                                            <td>{item?.collection_type}</td>
                                            <td>{item?.paid_by || '-'}</td>
                                            <td>{item?.transaction_mode || '-'}</td>
                                            <th>{item?.transaction_cheque_no || '-'}</th>
                                            <th>{item?.transaction_cheque_date ? moment(item?.transaction_cheque_date).format('Do MMM YYYY') : '-'}</th>
                                            <td>{item?.sender_bank_name || '-'}</td>
                                            <td>{item?.sender_bank_account || '-'}</td>
                                            <td>{item?.receiver_bank_name || '-'}</td>
                                            <td>{item?.receiver_bank_account || '-'}</td>
                                            <td>{item?.transaction_id || '-'}</td>
                                            <td>{item?.transaction_date ? moment(item?.transaction_date).format('Do MMM YYYY') : '-'}</td>
                                            <td>{item?.amount_received_date ? moment(item?.amount_received_date).format('Do MMM YYYY') : '-'}</td>
                                            <td>-</td>
                                            <td>{item?.collection_approved_by || '-'}</td>
                                            <td>{item?.collection_approval_time ? moment(item?.collection_approval_time).format('Do MMM YYYY') : '-'}</td>
                                            <td>{item?.collection_added_by || '-'}</td>
                                        </tr>
                                    ))
                                }
                                {
                                    searchText && collectionList.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={12} style={{ height: '' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                                    {/* <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn> */}
                                                </p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                             <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                         } */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {
                                    !searchText && collectionList.length === 0 && <tr className='nohover'>
                                        <td colSpan={12}>
                                            <div className='flex justify-center flex-column items-center h-100'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8">No collection added</h2>
                                                {/* <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add document to see them here</p> */}
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {/* <tr>
                                    <td>1</td>
                                    <td>23423342</td>
                                    <td>Milestone 1</td>
                                    <td>Lorem ipsum dolor sit amet..</td>
                                    <td>07/06/2023</td>
                                    <td>189299</td>
                                    <td>10%</td>
                                    <td>189299</td>
                                    <td>189299</td>
                                    <td>189299000</td>
                                    <td>189299000</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>23423342</td>
                                    <td>Milestone 1</td>
                                    <td>Lorem ipsum dolor sit amet..</td>
                                    <td>07/06/2023</td>
                                    <td>189299</td>
                                    <td>10%</td>
                                    <td>189299</td>
                                    <td>189299</td>
                                    <td>189299000</td>
                                    <td>189299000</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collectionstab
