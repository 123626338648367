import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';

const LocationSearchInput = ({ value, onChange }) => {

    const [address, setAddress] = useState(value);

    const handleChange = address => {
        setAddress(address);
    };

    useEffect(() => {
        if (value) {
            setAddress(value);
        }
    }, [value])

    const extractAddress = (place) => {
        console.log({ place })
        const address = {
            city: "",
            state: "",
            pincode: "",
            country: "",
            district: "",
            formattedAddress: '',
        }

        if (!Array.isArray(place[0]?.address_components)) {
            return address;
        }

        address.formattedAddress = place[0].formatted_address;

        place[0].address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes("locality")) {
                address.city = value;
            }

            if (types.includes("administrative_area_level_1")) {
                address.state = value;
            }

            if (types.includes("administrative_area_level_2")) {
                address.district = value;
            }

            if (types.includes("postal_code")) {
                address.pincode = value;
            }

            if (types.includes("country")) {
                address.country = value;
            }
        });

        onChange(address);
    }

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => extractAddress(results))
            .catch(error => console.error('Error', error));
    };


    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={{ componentRestrictions: { country: ['in'] } }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='position-relative'>
                    <input
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'form-control text-input',
                        })}
                    />
                    <div className="cursor-pointer position-absolute" style={{ zIndex: '99', backgroundColor: '#ffffff', boxShadow: '2px 2px 8px 2px rgba(219, 226, 239, 0.5)' }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active px-3 py-2'
                                : 'suggestion-item px-3 py-2';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                >
                                    <span className='my-2'>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}

export default LocationSearchInput;