import React, { useEffect, useState } from 'react'
import SmallCenterModal from '../../../../../components/Modals/small-center-modal'
import { toast } from 'react-toastify';
import { updateRoleDetails } from '../../../../../services/private/company.service';

const EditRole = ({ show, closeModal, getUsersByUUID, role, descrip, uuid, handleUpdateNameAndDescription }) => {
    const [name, setName] = useState('');
    const [description, setDescrition] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (role) {
            setName(role);
        }
        if (descrip) {
            setDescrition(descrip);
        }
    }, [role, descrip]);

    const handleSubmit = async () => {
        if (!role) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const res = await updateRoleDetails(uuid, { name, description });
        if (res.data.status === 200) {
            toast.success(res.data.message);

            handleUpdateNameAndDescription(name, description)
            closeModal();
        }
    }
    return (
        <SmallCenterModal show={show} closeModal={closeModal}>
            <div className='p-4'>

                <h3>
                    Edit Role
                </h3>
                <div className='space-y-4'>
                    <div>
                        <label htmlFor="" className={`block ${error ? 'text-red' : ''}`}>Role Name <span className="text-red">*</span></label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ borderColor: error ? '#FC5A5A' : '' }}
                            placeholder="eg. Team Lead"
                            className="border py-2.5 px-3 rounded-md w-100"
                        />
                        {error && <p className="text-red">Please Enter Role Name</p>}
                    </div>
                    <div>
                        <label htmlFor="" className="block">Description</label>
                        <textarea type="text" placeholder="eg. Team Lead" value={description !== 'null' ? description : ''} onChange={(e) => setDescrition(e.target.value)} className="border py-2.5 px-3 rounded-md w-100" rows={4}></textarea>
                    </div>
                </div>
                <div className={` flex flex-row items-center justify-between w-100 space-x-3 mt-4`}>
                    <button className='w-1/2 border py-1 rounded-md outline-btn' onClick={() => {
                        closeModal();
                    }}>Cancel</button>
                    <button className='pr-btn w-1/2' style={{ fontSize: '16px' }} onClick={handleSubmit}>Save</button>
                </div>
            </div>

        </SmallCenterModal>
    )
}

export default EditRole