import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';
import Consumer from '../../helpers/context';

const PipelineAnalysisTable = ({ statusLeads, totalLead, companyConfig, fromDate, toDate, selectedDate }) => {

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [formattedData, setFormattedData] = useState(null);

    useEffect(() => {

        let formattedData = [
            { tooltip: 'New Lead', value: statusLeads ? parseInt(statusLeads.newLead || '0') : 0, color: '#F0F6FF' },
            { tooltip: 'Not Connected', value: statusLeads ? parseInt(statusLeads.notConnected || '0') : 0, color: '#A4C6FF' },
            { tooltip: 'In Progress', value: statusLeads ? parseInt(statusLeads.inProgress || '0') : 0, color: '#7BADFF' },
            { tooltip: 'SV Scheduled', value: statusLeads ? parseInt(statusLeads.svScheduled || '0') : 0, color: '#5294FF' },
            { tooltip: 'SV Completed', value: statusLeads ? parseInt(statusLeads.svCompleted || '0') : 0, color: '#2478FF' },
            { tooltip: 'Token Done', value: statusLeads ? parseInt(statusLeads.eoiCompleted || '0') : 0, color: '#0062FF' },
            { tooltip: 'Booking Completed', value: statusLeads ? parseInt(statusLeads.bookingCompleted || '0') : 0, color: '#002D75' },
            { tooltip: 'Dump', value: statusLeads ? parseInt(statusLeads.deadLead || '0') : 0, color: '#CCE0FF' },
            { tooltip: 'Final Negotiation', value: statusLeads ? parseInt(statusLeads.finalNegotiation || '0') : 0, color: '#0b4db8' },
        ]

        setFormattedData(formattedData)
    }, [statusLeads]);

    const makeTooltipContent = (entry) => {
        return <div>
            <p className='mb4'>LEADS</p>
            <p className="d-flex justify-content-between mb-0">
                <span>{entry.tooltip}</span>
                <span className='ml-4'>{`${entry.value} (${((entry.value * 100) / totalLead).toFixed(2)}%)`}</span>
            </p>
        </div>
    }

    const handleAddFilter = (status) => {
        let payload = []
        let filterPayload = []
        const usersSatus = companyConfig.Status.find(s => s.label === status);

        if (selectedDate !== 'allTime') {
            filterPayload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }

        payload.push(
            { Status: [usersSatus.value] }
        )
        if (filterPayload.length > 0) {
            localStorage.setItem('new_filters', JSON.stringify(filterPayload));
        }
        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    // console.log(companyConfig, 'companyConfig')

    return (
        <div className="table-card Pipeline-Analysis-table d-flex flex-fill flex-column">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className="row w-100 d-flex align-items-center flex-fill">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center">
                        <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>
                            Status Analysis
                        </h2>
                        <div className="info-alert">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" >
                                        <div className='info-msg'>
                                            {/* <h4>Total Leads</h4> */}
                                            <p>Current status-wise breakup of leads added in platform for selected date range.</p>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <Button className='bg-tr border-0' bsstyle="default">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                        <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                        <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="d-flex flex-row justify-content-md-end">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip" >
                                        <div className='info-msg'>
                                            Total no. of leads created as per selected date filter
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <p className='mb-0 fz14 fw-po-medium black-dark-800 '>Total Leads : <span className='ro fw-po-bold'>
                                    {totalLead}
                                </span></p>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>

            </div>
            {formattedData && (statusLeads && Object.keys(statusLeads)?.length > 0) && <div className='map_list'>
                <div className="map position-relative" style={{ height: '240px', width: '240px' }} data-tip="" data-for="chart">
                    <PieChart
                        data={formattedData}
                        center={[50, 50]}
                        lineWidth={60}
                        radius={50}
                        onMouseOver={(_, index) => {
                            setSelectedIndex(index)
                        }}
                        onMouseOut={() => {
                            setSelectedIndex(null);
                        }}
                    />
                    <ReactTooltip
                        id="chart"
                        backgroundColor={"#162C50"}
                        getContent={() =>
                            typeof selectedIndex === 'number' ? makeTooltipContent(formattedData[selectedIndex]) : null
                        }
                    />
                </div>
                <div className='pipline-maplist'>
                    <div className="row">
                        <div className="col-md-5">
                            <ul>
                                <li
                                    className={`${formattedData[0]?.value ? 'cursor-pointer' : ''}`}
                                // onClick={() => handleAddFilter('Not Connected')}
                                ><span className='new'></span>New Lead ({formattedData[0]?.value})</li>
                                <li
                                    className={`${formattedData[7]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('Dead Lead')}
                                ><span className='dead'></span>Dump ({formattedData[7]?.value})</li>
                                <li
                                    className={`${formattedData[1]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('Not Connected')}
                                ><span className='no'></span>Not Connected ({formattedData[1]?.value})</li>
                                <li
                                    className={`${formattedData[2]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('In Progress')}
                                ><span className='process'></span>In Progress ({formattedData[2]?.value})</li>
                                <li
                                    className={`${formattedData[6]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('Booking Completed')}
                                ><span className='booking'></span>Booking Completed ({formattedData[6]?.value})</li>
                            </ul>
                        </div>
                        <div className="col-md-7">
                            <ul>
                                <li
                                    className={`${formattedData[3]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('SV Scheduled')}
                                ><span className='schedule'></span>SV Schedule ({formattedData[3]?.value})</li>
                                <li
                                    className={`${formattedData[4]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('SV Completed')}
                                ><span className='svcompleted'></span>SV Completed ({formattedData[4]?.value})</li>
                                <li
                                    className={`${formattedData[8]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('Final Negotiation')}
                                ><span className='finalNegotiation'></span>Final Negotiation ({formattedData[8]?.value})</li>
                                <li
                                    className={`${formattedData[5]?.value ? 'cursor-pointer' : ''}`}
                                    onClick={() => handleAddFilter('EOI Completed')}
                                ><span className='eoicompleted'></span>Token Done ({formattedData[5]?.value})</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            }
            {(!statusLeads || statusLeads?.length === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
        </div>
    )
}

export default PipelineAnalysisTable