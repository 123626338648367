import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as LPCheckIcone } from '../../assets/LPCheck.svg';
import { ReactComponent as VideoIcon } from '../../assets/vidoicon.svg';
import cardbg from '../../assets/cardbg.jpg'
import { Modal } from 'react-bootstrap';
const city = 'pune'

const UpcomingCard = ({ style, item, handleSendOTP, isPast }) => {


    const [isHovered, setIsHovered] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const closeVideoModal = () => {
        setShowVideo(false);
    }

    const getCTA = () => {
        if (item.registered_by) {
            if (item.type === 'online') {
                if (item.meet_link) {
                    return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16`} onClick={() => { window.open(item.meet_link) }}>
                        Join Now
                    </button>
                } else {
                    return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} onClick={() => handleSendOTP(item)}>
                        <LPCheckIcone className='mr-10' />
                        Seat Reserved
                    </button>
                }
            } else {
                return <button className={`${style.directionbtn} pr-btn w-100 py-10 fz16 inter`} onClick={() => {
                    window.open(`https://www.google.com/search?q=${item.location}`, '_blank')
                }}>Get Directions</button>
            }
        } else {
            return <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} disabled={item.status === 'registeration_closed'} onClick={() => handleSendOTP(item)}>
                {item.status === 'registeration_closed' ? 'Registeration Closed' : 'Reserve Seat'}
            </button>
        }
    }

    if (!item) {
        return <p>No Data Found</p>
    }

    return (
        <>
            <div className={`${style.lpNewCard}  `} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <figure onClick={() => {
                    if (item.video_url) {
                        setShowVideo(true);
                    }
                }} className={`${item.video_url ? 'cursor-pointer' : ''}`}>
                    <img src={item.thumbnail} alt="" />
                    {item.video_url && <button className={`${style.videoIcon}`} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 100 100" fill="none"><rect x="34.375" y="28.125" width="32.8125" height="40.625" fill="#171725"></rect><path d="M50 12.5C42.5832 12.5 35.333 14.6993 29.1661 18.8199C22.9993 22.9404 18.1928 28.7971 15.3545 35.6494C12.5162 42.5016 11.7736 50.0416 13.2206 57.3159C14.6675 64.5902 18.239 71.272 23.4835 76.5165C28.728 81.761 35.4098 85.3325 42.6841 86.7794C49.9584 88.2264 57.4984 87.4838 64.3506 84.6455C71.2029 81.8072 77.0596 77.0007 81.1801 70.8339C85.3007 64.667 87.5 57.4168 87.5 50C87.4876 40.0582 83.5327 30.5271 76.5028 23.4972C69.4729 16.4673 59.9418 12.5124 50 12.5ZM63.1394 52.4014L45.8317 63.9399C45.357 64.2545 44.8003 64.4226 44.2308 64.4231C43.4657 64.4231 42.732 64.1192 42.191 63.5782C41.6501 63.0372 41.3462 62.3035 41.3462 61.5385V38.4615C41.3458 37.9392 41.4873 37.4265 41.7556 36.9783C42.0238 36.5301 42.4088 36.1631 42.8693 35.9166C43.3298 35.67 43.8486 35.5531 44.3704 35.5784C44.8921 35.6037 45.3972 35.7702 45.8317 36.0601L63.1394 47.5986C63.5351 47.8619 63.8596 48.2189 64.084 48.6378C64.3085 49.0568 64.4259 49.5247 64.4259 50C64.4259 50.4753 64.3085 50.9432 64.084 51.3622C63.8596 51.7811 63.5351 52.1381 63.1394 52.4014Z" fill="white"></path></svg></button>}
                    {item.type === 'online' && <div className={`${city.length > 10 ? 'order-1 ml-0 mb-12' : 'order-1 mb-12'} ${style.onlinebtn}`}>ONLINE</div>}
                </figure>
                <div className={`${style.absoluteDesc} ${isHovered ? `${style.hovered}` : ''}`}>
                    <div>
                        <div className='d-flex align-items-center justify-start flex-wrap'>
                            <p className={`${city.length > 10 ? 'order-2 w-100' : 'order-2 w-100'} ${style.dateCity} mb-0`}>
                                {/* <span>May 7, 6:30pm - 10:25pm</span> */}
                                {item.start_date && item.start_time && item.end_time && <span>
                                    {`${moment(item?.start_date).format("MMM DD")}, 
                                        ${moment(item.start_time).format('h:mmA')} - 
                                        ${moment(item.end_time).format('h:mmA')}`
                                    }

                                </span>}
                                <span className='mx-1'>{'| '}</span>
                                <span>{item.city}</span>
                            </p>

                        </div>
                        <h1 className=''>{item.event_name}</h1>
                    </div>
                    <div className='d-md-block d-none'>
                        <div className={`${style.hoveredata} `}>
                            <p>
                                <span>{item?.property_type?.join(', ')}</span> |
                                {item.address && <><span>{item.address} </span> | </>}
                                <span> {item.developer_name}</span>
                            </p>
                            {!isPast && getCTA()}
                            {
                                isPast && <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} disabled={true}>
                                    Event Completed
                                </button>
                            }
                        </div>
                    </div>
                    <div className='d-md-none'>
                        <div className={`${style.hoveredata} `}>
                            <p>
                                <span>{item?.property_type?.join(', ')}</span> |
                                {item.address && <><span>{item.address}</span> | </>}
                                <span> {item.developer_name}</span>
                            </p>
                            {!isPast && getCTA()}
                            {
                                isPast && <button className={`${style.directionbtn} inter pr-btn  w-100 py-10 fz16 `} disabled={true}>
                                    Event Completed
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div >
            {
                showVideo && <Modal size="xl" show={showVideo} onHide={closeVideoModal} backdrop="static" fullscreen centered dialogClassName="modal-radius">
                    <a onClick={closeVideoModal} className='cursor-pointer close position-absolute video-close'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#ffff"></path></svg></a>
                    <video controls autoPlay controlsList="nodownload" preload='preloadauto' className='learnvideo' width={'100%'} height={'auto'}>
                        <source src={item.video_url} type="video/mp4" />
                    </video>
                </Modal>
            }
        </>
    )
}

export default UpcomingCard
