import React, { useContext } from "react";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomColumnModal from "../../../../components/Modals/CustomColumnModal";
import EditRolesModal from "../../../../components/Modals/EditRolesModal";
import { addNewProject, addReportingUser, addUserTeam, cancelInvite, deleteUserByUuid, generateUserInviteLink, getAddedUsers, getColumnPreferenceNew, getExistingUsers, getMasterRoles, getProjects, getRoles, markUserActiveByUuid, markUserInactiveByUuid, resetUserPassword, saveColumnPreferenceNew, updateSmsNotification, updateTeamLead } from "../../../../services/private/company.service"
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as EditBorderIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/arrowback.svg';
import { ReactComponent as ResultSearchIcon } from '../../../../assets/icons/searchicon.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import ReportingTo from "./Modal/ReportingTo";
import TeamUsers from "./Modal/TeamUsers";
import Projects from "./Modal/Projects";
import EditRole from "./Modal/EditRole";
import { checkExpiryDate, getInitials } from "../../../../helpers/helpers";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import Consumer from '../../../../helpers/context';
import PopUpModal from "../../../../components/Modals/PopUpModal";
import InputSelect from "../../../../components/InputGroup/InputSelect";
import InviteUserModal from "../../../../components/Modals/InviteUsers.modal";
import InviteExistingUsers from "./Modal/InviteExistingUser";
import InviteNewUser from "./Modal/InviteNewUser";
import AddUser from "./Modal/AddUser";
import { ReactComponent as SearchCloseIcon } from '../../../../assets/icons/searchClose.svg';
import TooltipText from "../../../../components/Tooltip";

const RolesDetail = () => {

    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showReportingToModal, setShowReportingToModal] = useState(false);
    const [showTeamUserModal, setShowTeamUserModal] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [users, setUsers] = useState([])
    const [selectedItem, setSelectedItem] = useState();
    const [name, setName] = useState('');
    const [description, setDescrition] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [reAssignUser, setReAssignUser] = useState();
    const [error, setError] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [masterRoles, setMasterRoles] = useState([]);
    const [showExistingUserModal, setShowExistingUserModal] = useState(false);
    const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [userListForDelete, setUserListForDelete] = useState([]);
    const [projects, setProjects] = useState([]);
    const [showReassignTeamMember, setShowReassignTeamMember] = useState(false);
    const [reAssignTeamLead, setReassignTeamlead] = useState('');

    const { uuid } = useParams();
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const roleName = searchParams.get('name');
    const roleDescription = searchParams.get('description');
    const roleUser = searchParams.get('user');
    const restricted = searchParams.get('restricted');

    let { allowedPermissions } = useContext(Consumer);


    const getAllMasterRoles = async () => {
        const res = await getMasterRoles()
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.uuid }))
            setMasterRoles(mapped)
        }
    }

    const getAllProjects = async () => {
        const res = await getProjects('');
        if (res.data.status === 200) {
            let mappedProjects = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.uuid }))

            setProjects(mappedProjects);
        }
    }

    const getUsers = async () => {
        const res = await getExistingUsers();
        if (res.data.status === 200) {
            const userData = res.data.data.map(item => ({ ...item, label: `${item.name} (${item.role_name})`, value: item.uuid }))
            setUserListForDelete(userData)
        }
    }

    const getUsersByUUID = async () => {
        const res = await getAddedUsers(uuid);
        if (res.data.status === 200) {
            if (roleUser) {
                const mappedUser = res.data.data.filter(item => item.uuid === roleUser);
                setUsers(mappedUser)
                setAllUsers(mappedUser);
            } else {
                setUsers(res.data.data)
                setAllUsers(res.data.data);
            }
            // const users = res.data.data
            //     .filter(user => user.is_active)
            //     .map(user => { return { ...user, label: user.name, value: user.uuid } })

            // setUserListForDelete(users)

        }
    }

    useEffect(() => {
        getAllProjects();
        getUsersByUUID();
        getAllMasterRoles();
        getUsers();
    }, [])

    const handleRemoveProject = async (uuid, projectList, projectUUID) => {
        const projectIds = projectList.filter(item => item.uuid !== projectUUID).map(item => item.uuid);
        const res = await addNewProject(uuid, { projects: projectIds })
        if (res.data.status === 200) {
            getUsersByUUID();
        } else {
            toast.error(res.data.message)
        }
    }

    const handleRemoveTeam = async (uuid, userList, userUUID) => {
        const userIds = userList.filter(item => item.uuid !== userUUID).map(item => item.uuid);
        const res = await addUserTeam(uuid, { users: userIds })
        if (res.data.status === 200) {
            getUsersByUUID();
        } else {
            toast.error(res.data.message)
        }
    }
    const handleRemoveReportingTo = async (uuid, userList, userUUID) => {
        const userIds = userList.filter(item => item.uuid !== userUUID).map(item => item.uuid);
        const res = await addReportingUser(uuid, { users: userIds })
        if (res.data.status === 200) {
            getUsersByUUID();
        } else {
            toast.error(res.data.message)
        }
    }
    const handleSearch = () => {
        const searchedData = allUsers.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        setUsers(searchedData)
    }

    useEffect(() => {
        handleSearch()
    }, [searchText])

    const handleUpdateNameAndDescription = (name, descrip) => {
        setName(name);
        setDescrition(descrip);
        history.replace({ search: (new URLSearchParams({ name: name, description: descrip })).toString() });
    }

    const resetPassword = async (uuid) => {
        await resetUserPassword(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleReassignTeamLead = async () => {
        const res = await updateTeamLead(selectedUser.uuid, { teamLead: reAssignTeamLead.uuid })
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setReassignTeamlead('')
            setShowReassignTeamMember(false);
            setShowDeleteUser(true);
        }
    }
    const deleteUserAction = async () => {
        await deleteUserByUuid(selectedUser.uuid, reAssignUser.value).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUsersByUUID();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleDeleteUser = async () => {
        if (!reAssignUser) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        await deleteUserAction();
        setReAssignUser('')
        setShowDeleteUser(false)
    }

    const handleActive = (uuid) => {
        markUserActiveByUuid(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUsersByUUID();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleInactive = (uuid) => {
        markUserInactiveByUuid(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUsersByUUID();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleUpdateSMSNotification = (uuid, value) => {
        updateSmsNotification(uuid, { sms_notification: value }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getUsersByUUID();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const handleCancelInvite = async (uuid) => {
        const res = await cancelInvite(uuid)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getUsersByUUID();
        } else {
            toast.error(res.data.message);
        }
    }

    const handleGenerateInviteLink = async (uuid) => {
        const res = await generateUserInviteLink(uuid)
        if (res.data.status === 200) {
            navigator.clipboard.writeText(res.data.data).then(function () {
                toast.success('Invite link Copied');
                getUsersByUUID();
            }, function (err) {
                toast.error('Could not copy invite link');
            });
        }
    }

    return (
        <>
            <div className="roles w-100 h-full flex flex-col">
                <div className="settings-title">
                    <div >
                        <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                            <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                                <li>
                                    <Link to='/settings/user-roles/roles'>Roles</Link>
                                </li>
                                <li className='flex items-center'><RightArrow />
                                    {name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name || roleName}
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="w-100 flex justify-between sm:gap-4 lg:gap-0 lg:items-center sm:items-start sm:flex-col lg:flex-row">
                        <div className="">
                            <h2 className="text-2xl !text-black font-medium flex items-center !gap-x-2 m-0">
                                <Link
                                    // onClick={() => history.goBack()}
                                    to='/settings/user-roles/roles'
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>

                                <TooltipText title={name || roleName}>
                                    <span className="w-8 h-8  !border !border-grayLight rounded text-grey-300 text-sm font-medium inline-flex items-center justify-center">
                                        {getInitials(name || (roleName ? roleName : ''))}
                                    </span>
                                </TooltipText>
                                {name === 'ADMIN' ? 'Admin' : name === 'STANDARD' ? 'Standard' : name || roleName}
                                {roleName !== 'Super Admin' && <span className="bg-white w-6 h-6 flex items-center justify-center cursor-pointer strokeprimary" onClick={() => setShowEditRole(true)}>
                                    <EditBorderIcon />
                                </span>}
                            </h2>
                            {/* <p className="fz16 black-dark-700 mb-0 mt-2">
                                {description || (roleDescription !== 'null' ? roleDescription : '')}
                            </p> */}
                        </div>
                        <div className=" flex items-center space-x-3 justify-end">
                            <div>
                                <div className={`border rounded px-2 py-1  flex items-center ${searchText ? 'border-primary' : ''} `}>
                                    <SearchIcon className='mr-2' />
                                    <input
                                        type="text"
                                        placeholder='Search For Users'
                                        className=' w-100 focus:outline-none text-sm'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {searchText &&
                                        <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                            <SearchCloseIcon />
                                        </div>}
                                </div>
                            </div>
                            {(roleName !== 'Super Admin' && allowedPermissions.includes('create_role') && !!restricted) && <div>
                                <button className="flex border rounded p-1.5 !px-2 leading-5 hover:bg-black100 text-black700"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/settings/user-roles/roles/permissions`,
                                            // pathname: `/settings/user-roles/roles/new/permissions/${uuid}`,                                        
                                            search: `?role=edit&from=detail&name=${roleName === 'ADMIN' ? 'Admin' : roleName === 'STANDARD' ? 'Standard' : roleName}&description=${roleDescription}&restricted=${restricted}&p=detail&pageuuid=${uuid}`,
                                        })
                                    }}
                                >
                                    <EditBorderIcon className="mr-2" />
                                    Edit Permission
                                </button>
                            </div>}
                            <div>
                                {roleName !== 'Super Admin' && allowedPermissions.includes('add_new_user') && <button className="pr-btn inline-flex items-center !gap-x-1 py-1.5" onClick={() => {
                                    // history.push({
                                    //     pathname: `/settings/user-roles/roles/new/invite-user/${uuid}`,
                                    //     state: { name: location.state.name }
                                    // })
                                    setShowAddUserModal(true)
                                }}>
                                    <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="path-1-inside-1_1117_451" fill="white">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                        </mask>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                        <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                    </svg>
                                    User
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="roles-table ml-24 mt-24 h-full overflow-auto">
                    <div className="def-table pr-0 !h-full">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    {projects.length > 0 && <th>Projects</th>}
                                    <th>Team</th>
                                    <th>Reporting To</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users && users.length > 0 && users.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="font-medium leading-5">{user.name}</td>
                                            <td className="font-medium leading-5">{user.email}</td>
                                            {projects.length > 0 && <td>
                                                {user.projects.length > 0 ? <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                    <div className="flex flex-wrap items-center">

                                                        {
                                                            user.projects.map((project, index) => (
                                                                index < 3 &&
                                                                <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 p-1 w-min">
                                                                    {project.name.length > 30 ? <TooltipText title={project.name || ''}>
                                                                        <p className="w-[180px] truncate m-0">{project.name}</p>
                                                                    </TooltipText> : <p className="m-0">{project.name}</p>}

                                                                    <CloseIcon className="h-4 w-4 ml-1 cursor-pointer" onClick={() => handleRemoveProject(user.uuid, user.projects, project.uuid)} />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            user.projects.length > 3 &&
                                                            <div className="tag-dropdown" >
                                                                <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    +{user.projects.length - 3}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {user.projects.map((item, index) => {
                                                                        return <a key={index} className="dropdown-item text-capitalize"  >{item.name}

                                                                        </a>

                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TooltipText title='Edit'>
                                                        <div className="border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                            setShowProjectModal(true)
                                                            setSelectedItem(user)
                                                        }}>
                                                            <EditIcon />
                                                        </div>
                                                    </TooltipText>
                                                </div> : <span className="border rounded-lg flex cursor-pointer w-20 hover:bg-black200 items-center justify-center" onClick={() => {
                                                    setShowProjectModal(true)
                                                    setSelectedItem(user)
                                                }}>
                                                    + Add
                                                </span>}
                                            </td>}
                                            <td>
                                                {user.team.length > 0 ? <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                    <div className="flex flex-wrap items-center">

                                                        {
                                                            user.team.map((item, index) => (
                                                                index < 3 && <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 w-min p-1">
                                                                    {item.role_name && <TooltipText title={item.role_name || ''}>

                                                                        <span className="mr-2.5 border p-1 rounded-md text-sm bg-primary font-medium text-white">
                                                                            {getInitials(item.role_name || '')}
                                                                        </span>
                                                                    </TooltipText>}
                                                                    {item.name}
                                                                    <CloseIcon className="h-4 w-4 cursor-pointer" onClick={() => handleRemoveTeam(user.uuid, user.team, item.uuid)} />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            user.team.length > 3 &&
                                                            <div className="tag-dropdown" >
                                                                <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    +{user.team.length - 3}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {user.team.map((item, index) => {
                                                                        return <a key={index} className="dropdown-item text-capitalize"  >{item.name}
                                                                        </a>

                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TooltipText title='Edit'>
                                                        <div className="bg-white border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                            setShowTeamUserModal(true)
                                                            setSelectedItem(user)
                                                        }}>
                                                            <EditIcon />
                                                        </div>
                                                    </TooltipText>
                                                </div> : <span className=" border rounded-lg hover:bg-black200 cursor-pointer w-20 flex items-center justify-center" onClick={() => {
                                                    setShowTeamUserModal(true)
                                                    setSelectedItem(user)
                                                }}>
                                                    + Add
                                                </span>}
                                            </td>
                                            <td>
                                                {user.reporting_to.length > 0 ? <div className="flex border bg-black100 rounded-lg p-2 justify-between items-center">
                                                    <div className="flex flex-wrap items-center">

                                                        {
                                                            user.reporting_to.map((item, index) => (
                                                                index < 3 && <div key={index} className="flex rounded mb-1 items-center bg-grey-100 mr-1 w-min p-1">
                                                                    {item.role_name && <TooltipText title={item.role_name || ''}>

                                                                        <span className="mr-2.5 border p-1 rounded-md text-sm bg-primary font-medium text-white">
                                                                            {getInitials(item.role_name || '')}
                                                                        </span>
                                                                    </TooltipText>}
                                                                    {item.name}
                                                                    <CloseIcon className="h-4 w-4 cursor-pointer" onClick={() => handleRemoveReportingTo(user.uuid, user.reporting_to, item.uuid)} />
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            user.reporting_to.length > 3 &&
                                                            <div className="tag-dropdown" >
                                                                <button className="border rounded-lg w-6 h-6 flex items-center justify-center bg-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    +{user.reporting_to.length - 3}
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {user.reporting_to.map((item, index) => {
                                                                        return <a key={index} className="dropdown-item text-capitalize"  >{item.name}

                                                                        </a>

                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TooltipText title='Edit'>
                                                        <div className="bg-white border rounded-lg w-6 h-6 p-1 cursor-pointer flex items-center justify-center" onClick={() => {
                                                            setShowReportingToModal(true)
                                                            setSelectedItem(user)
                                                        }}>

                                                            <EditIcon />
                                                        </div>
                                                    </TooltipText>
                                                </div> : <span className=" border rounded-lg w-20 hover:bg-black200 cursor-pointer flex items-center justify-center" onClick={() => {
                                                    setShowReportingToModal(true)
                                                    setSelectedItem(user)
                                                }}>
                                                    + Add
                                                </span>}
                                            </td>
                                            <td className="dropdown view-more right-0 " style={{ position: 'sticky' }}>

                                                <button
                                                    className="bg-tr border-0"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                            fill="#B5B5BE"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                            fill="#B5B5BE"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                            fill="#B5B5BE"
                                                        />
                                                    </svg>
                                                </button>
                                                {user.invite_link && <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                    {!checkExpiryDate(user.invite_link_expiry) && <a className="dropdown-item" onClick={() => {
                                                        navigator.clipboard.writeText(user.invite_link).then(function () {
                                                            toast.success('Invite link Copied');
                                                        }, function (err) {
                                                            toast.error('Could not copy invite link');
                                                        });
                                                    }} >
                                                        Copy Invite Link
                                                    </a>}
                                                    {checkExpiryDate(user.invite_link_expiry) && <a className="dropdown-item" onClick={() => {
                                                        handleGenerateInviteLink(user.uuid)
                                                    }} >
                                                        Generate Invite Link
                                                    </a>}
                                                    <a className="dropdown-item" onClick={() => handleCancelInvite(user.uuid)}>
                                                        Cancel Invite
                                                    </a>
                                                </div>}
                                                {!user.invite_link && <div
                                                    className="dropdown-menu dropdown-menu-right w-auto"
                                                    aria-labelledby="dropdownMenuButton"
                                                    onClick={() => setSelectedUser(user)}
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => resetPassword(user.uuid)}
                                                    >
                                                        Reset Password
                                                    </a>
                                                    {allowedPermissions.includes('add_new_user') && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => {
                                                                setSelectedItem(user)
                                                                setShowAddNewUserModal(true)
                                                            }
                                                        }
                                                    >
                                                        Edit User
                                                    </a>}
                                                    {(allowedPermissions.includes('add_new_user') || !user.is_owner) && !user.is_active && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => handleActive(user.uuid)}
                                                    >
                                                        Activate User
                                                    </a>}

                                                    {roleName !== 'Super Admin' && (allowedPermissions.includes('add_new_user') || !user.is_owner) && user.is_active && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => handleInactive(user.uuid)}
                                                    >
                                                        Deactivate User
                                                    </a>}

                                                    {allowedPermissions.includes('edit_sms_notifications') && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => handleUpdateSMSNotification(user.uuid, !user.sms_notification)
                                                        }
                                                    >
                                                        {`${user.sms_notification ? 'Disable' : 'Enable'} SMS Notification`}
                                                    </a>}
                                                    {roleName !== 'Super Admin' && allowedPermissions.includes('delete_user') && <a
                                                        className="dropdown-item del-menu"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => {
                                                            if (user.team.length > 0) {
                                                                setShowReassignTeamMember(true)
                                                            } else {
                                                                setShowDeleteUser(true)
                                                            }
                                                        }
                                                        }
                                                    >
                                                        Delete User
                                                    </a>}
                                                </div>}

                                            </td>
                                        </tr>
                                    )
                                })}


                                {
                                    searchText && users.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                                    <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                             <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                         } */}
                                            </div>
                                        </td>
                                    </tr>
                                }

                                {!searchText && users.length === 0 &&
                                    <tr className='nohover'>
                                        <td colSpan={9} style={{ height: '60vh' }}>
                                            <div className='flex justify-center flex-column items-center h-100'>
                                                <figure><ResultSearchIcon /></figure>
                                                <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Users Added</h2>
                                                <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add users to see them here</p>
                                                {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                                {/* {projectPermission?.includes('write') &&
                                                    <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                                } */}
                                            </div>
                                        </td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    showEditRole && <EditRole
                        show={showEditRole}
                        uuid={uuid}
                        closeModal={() => setShowEditRole(false)}
                        getUsersByUUID={getUsersByUUID}
                        role={roleName}
                        descrip={roleDescription !== 'null' ? (roleDescription || description) : ''}
                        handleUpdateNameAndDescription={handleUpdateNameAndDescription}
                    />
                }
                {
                    showTeamUserModal && <TeamUsers
                        show={showTeamUserModal}
                        user={selectedItem}
                        getUsersByUUID={getUsersByUUID}
                        closeModal={() => {
                            setSelectedItem()
                            setShowTeamUserModal(false)
                        }}
                    />
                }
                {
                    showProjectModal && <Projects
                        user={selectedItem}
                        show={showProjectModal}
                        getUsersByUUID={getUsersByUUID}
                        closeModal={() => {
                            setSelectedItem()
                            setShowProjectModal(false)
                        }} />
                }
                {
                    showReportingToModal && <ReportingTo
                        user={selectedItem}
                        show={showReportingToModal}
                        getUsersByUUID={getUsersByUUID}
                        closeModal={() => {
                            setSelectedItem()
                            setShowReportingToModal(false)
                        }}
                    />
                }
                {showReassignTeamMember ?
                    <PopUpModal
                        setShow={setShowReassignTeamMember}
                        show={showReassignTeamMember}
                        heading={<>
                            Reassign {`${selectedUser?.name} Team`} ?
                        </>}
                        body={<div className="p-3">
                            <div className="mb-4">
                                <InputSelect
                                    index={4}
                                    name="user"
                                    value={reAssignTeamLead}
                                    options={userListForDelete?.filter(user => user.value !== selectedUser?.uuid)}
                                    onChange={(e) => setReassignTeamlead(e)}
                                    placeholder="Select User"
                                    label={`Select team lead to reassign ${selectedUser?.name}'s team`}
                                    error={error ? 'Please select user' : ''}
                                />
                            </div>
                            {/* <div className="mb-4">
                             {`Deleting ${selectedUser?.name} will re-assign all ${selectedUser?.name} leads to admin?`}
                            </div> */}
                            <div className="justify-content-end d-flex">
                                <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => {
                                    setShowReassignTeamMember(false)
                                    setReassignTeamlead('')
                                }}>Cancel</button>
                                <button className="fw-po-medium btn-primary btn px-16" onClick={handleReassignTeamLead}>Reassign</button>

                            </div>
                        </div>}
                    /> : null}
                {showDeleteUser ?
                    <PopUpModal
                        setShow={setShowDeleteUser}
                        show={showDeleteUser}
                        heading={<>
                            Delete {`${selectedUser?.name}`} ?
                        </>}
                        body={<div className="p-3">
                            <div className="mb-4">
                                <InputSelect
                                    index={4}
                                    name="user"
                                    value={reAssignUser}
                                    options={userListForDelete?.filter(user => user.value !== selectedUser?.uuid)}
                                    onChange={(e) => setReAssignUser(e)}
                                    placeholder="Select User"
                                    label={`Select user to reassign ${selectedUser?.name} Leads`}
                                    error={error ? 'Please select user' : ''}
                                />
                            </div>
                            {/* <div className="mb-4">
                             {`Deleting ${selectedUser?.name} will re-assign all ${selectedUser?.name} leads to admin?`}
                            </div> */}
                            <div className="justify-content-end d-flex">
                                <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => {
                                    setReAssignUser('')
                                    setShowDeleteUser(false)
                                }}>Cancel</button>
                                <button className="fw-po-medium del-btn px-16" onClick={handleDeleteUser}>Confirm Delete</button>

                            </div>
                        </div>}
                    /> : null}

                {showUserModal &&
                    <InviteUserModal
                        show={showUserModal}
                        setShow={setShowUserModal}
                        selectedUser={selectedUser}
                        getUsersByUUID={getUsersByUUID}
                        roles={masterRoles}
                    />
                }
                {
                    showExistingUserModal &&
                    <InviteExistingUsers
                        show={showExistingUserModal}
                        closeModal={() => {
                            setSelectedItem()
                            setShowExistingUserModal(false)
                        }}
                        uuid={uuid}
                        roleName={roleName}
                        getUsersByUUID={getUsersByUUID}
                        handleBackBtn={() => {
                            setShowAddUserModal(true)
                            setShowExistingUserModal(false);
                        }}
                    />
                }
                {
                    showAddNewUserModal && <InviteNewUser
                        show={showAddNewUserModal}
                        closeModal={() => {
                            setSelectedItem()
                            setShowAddNewUserModal(false)
                        }}
                        uuid={uuid}
                        roleName={roleName}
                        getUsersByUUID={getUsersByUUID}
                        selectedItem={selectedItem}
                        handleBackBtn={() => {
                            setShowAddUserModal(true)
                            setShowExistingUserModal(false);
                        }}
                    />
                }
                {
                    showAddUserModal && <AddUser
                        show={showAddUserModal}
                        closeModal={() => {
                            setShowAddNewUserModal(false);
                            setShowExistingUserModal(false);
                            setShowAddUserModal(false)
                        }}
                        handleExistingUser={() => {
                            setShowAddUserModal(false);
                            setShowAddNewUserModal(false);
                            setShowExistingUserModal(true);
                        }}
                        handleAddNewUser={() => {
                            setShowAddUserModal(false);
                            setShowExistingUserModal(false);
                            setShowAddNewUserModal(true);
                        }}
                    />
                }
            </div>
        </>
    );
}

export default RolesDetail;
