import React, { useContext, useEffect, useState } from 'react'
import ProjectHeader from './project-header';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Consumer from '../../helpers/context';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import AddBrokrageModal from './Modal/AddBrokrageModal';
import AddIncentiveModal from './Modal/AddIncentiveModal';
import AdditionalIncentiveModal from './Modal/AdditionalIncentiveModal';
import AddLeadTaggingModal from './Modal/AddLeadTaggingModal';
import AddFloorPlan from './Modal/AddFloorPlan';
import { deleteBrokerageByUUID, deleteOfferByUUID, findAllBrokerageForProject, findOffersByprojectId } from '../../services/public/public.service';
import { ReactComponent as HeaderMenu } from '../../assets/icons/HeaderMenu.svg';
import ProjectOfferModal from './Modal/ProjectOfferModal';
import moment from 'moment';
import PopUpModal from '../Modals/PopUpModal';
import { toast } from 'react-toastify';

const Broker = () => {
  const [showAddBorkerageModal, setShowAddBrokerageModal] = useState(false);
  const [showExtraIncentiveModal, setShowExtraIncentiveModal] = useState(false);
  const [showAdditionalIncentiveModal, setShowAdditionalIncentiveModal] = useState(false);
  const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false);
  const [brokrageDetails, setBrokrageDetails] = useState([]);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState();
  const [selectedBrokerage, setSelectedBrokerage] = useState();
  const [showBrokerageDelete, setShowBrokerageDelete] = useState(false);
  const [showOfferDelete, setShowOfferDelete] = useState(false);

  const { uuid } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const property = searchParams.get('pt');
  const transaction = searchParams.get('tt');
  let { userProfile, allowedPermissions, isRolesModule } = useContext(Consumer);

  const getBrokrageDetails = async () => {
    const res = await findAllBrokerageForProject(uuid);
    if (res.data.status === 200) {
      setBrokrageDetails(res.data.data)
    }
  }
  const handleDeleteOffer = async () => {
    const res = await deleteOfferByUUID(selectedOffer.uuid);
    if (res.data.status === 200) {
      toast.success(res.data.message);
      getOffers();
      setShowOfferDelete(false);
    }
  }

  const handleDeleteBrokerage = async () => {
    const res = await deleteBrokerageByUUID(selectedBrokerage.uuid);
    if (res.data.status === 200) {
      toast.success(res.data.message);
      getBrokrageDetails();
      setShowBrokerageDelete(false)
    }
  }
  const getOffers = async () => {
    const res = await findOffersByprojectId(uuid);
    if (res.data.status === 200) {
      setOffers(res.data.data);
    }
  }

  useEffect(() => {
    getBrokrageDetails();
    getOffers();
  }, [])
  const handleSubmit = () => {

  }

  console.log(offers, 'offers')
  return (
    <div className={`${style.creatproject}`}>
      <ProjectHeader style={style} />
      <div className={`${style.creatproject} overflow-hidden `}>
        <div className='d-flex flex-fill flex-column border-top border-color h-full overflow-auto !pt-5 sm:!pl-6 sm:!pr-6 !pl-4 !pr-4 !pb-10'>
          <div className='flex flex-col gap-y-9'>
            {/* Brokerage Payout */}
            <div>
              <div className='max-w-[915px]'>
                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                  <h1 className='text-textblack text-lg inter font-semibold m-0'>Brokerage Slabs</h1>
                  {brokrageDetails.length > 0 && <button className='pr-btn inter inline-flex items-center mr-2.5' onClick={() => setShowAddBrokerageModal(true)} ><PlushIcon />Add</button>}
                </div>
                {brokrageDetails.length === 0 && <div className='block !mt-4'>
                  <button
                    className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                    onClick={() => setShowAddBrokerageModal(true)}
                  >+ Add Brokerage</button>
                </div>}
              </div>
              {brokrageDetails.length > 0 && <div className='!mt-4 max-w-[915px]'>
                <div className="def-table !h-auto p-0">
                  <table className='table mb-0'>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Booking Range</th>
                        <th>Brokerage</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        brokrageDetails.map((data, index) => (
                          <tr key={`${data.uuid}_${index}`}>
                            <td>{index + 1}</td>
                            <td>{data.min_value} - {data.max_value}</td>
                            <td>{data.brokerage_percentage}%</td>

                            <td className='dropdown view-more !sticky right-0'>
                              <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <HeaderMenu />
                              </button>
                              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                <a className='dropdown-item' onClick={() => {
                                  // getEOIPricing(data)
                                  setSelectedBrokerage(data)
                                  setShowAddBrokerageModal(true)
                                }}>Edit</a>
                                <a
                                  className="dropdown-item !text-red hover:!bg-red hover:!text-white"
                                  onClick={() => {
                                    setSelectedBrokerage(data);
                                    setShowBrokerageDelete(true);
                                  }}
                                >Delete</a>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>}
            </div>
            {/* Offers */}
            {allowedPermissions.includes('edit_project_offer') && <div>
              <div className='max-w-[915px]'>
                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                  <h1 className='text-textblack text-lg inter font-semibold m-0'>Offers</h1>
                  {offers.length > 0 && <button
                    className='pr-btn inter inline-flex items-center mr-2.5'
                    onClick={() => {
                      setShowOfferModal(true)
                    }}
                  ><PlushIcon />Add</button>}
                </div>
                {offers.length === 0 && <div className='block !mt-4'>
                  <button
                    className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                    onClick={() => {
                      setShowOfferModal(true)
                    }}
                  >+ Add Offer</button>
                </div>}
              </div>
              {offers.length > 0 && <div className='!mt-4 max-w-[915px]'>
                <div className="def-table !h-auto p-0">
                  <table className='table mb-0'>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Offer Title</th>
                        <th>Offer Type</th>
                        <th>Amount</th>
                        <th>Validity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        offers.map((data, index) => (
                          <tr key={`${data.uuid}_${index}`}>
                            <td>{index + 1}</td>
                            <td>{data?.title || '-'}</td>
                            <td>{data?.offer_type || '-'}</td>
                            <td>{data?.amount || '-'} </td>
                            <td>{data?.validity ? moment(data.validity).format('DD MMM YYYY') : '-'}</td>
                            <td className='dropdown view-more !sticky right-0'>
                              <button className="bg-tr border-0 px-0" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <HeaderMenu />
                              </button>
                              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                                <a className='dropdown-item' onClick={() => {
                                  setSelectedOffer(data)
                                  setShowOfferModal(true)
                                }}>Edit</a>
                                <a
                                  className="dropdown-item !text-red hover:!bg-red hover:!text-white"
                                  onClick={() => {
                                    setSelectedOffer(data);
                                    setShowOfferDelete(true)
                                  }}
                                >Delete</a>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>}
            </div>}
            {/* Extra Incentive on Every Booking */}
            {/* <div>
              <div className='max-w-[915px]'>
                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                  <h1 className='text-textblack text-lg inter font-semibold m-0'>Extra Incentive on Every Booking</h1>
                  <button className='pr-btn inter inline-flex items-center mr-2.5' ><PlushIcon />Add</button>
                </div>
                <div className='block !mt-4'>
                  <button
                    className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                    onClick={() => {
                      setShowExtraIncentiveModal(true)
                    }}
                  >+ Add Incentive</button>
                </div>
              </div>
            </div> */}
            {/* Additional Incentive on Spot Booking */}
            {/* <div>
              <div className='max-w-[915px]'>
                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                  <h1 className='text-textblack text-lg inter font-semibold m-0'>Additional Incentive on Spot Booking</h1>
                  <button className='pr-btn inter inline-flex items-center mr-2.5' ><PlushIcon />Add</button>
                </div>
                <div className='block !mt-4'>
                  <button
                    className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                    onClick={() => setShowAdditionalIncentiveModal(true)}
                  >+ Add Incentive</button>
                </div>
              </div>
            </div> */}
            {/*  */}
            {/* <div>
              <div className='max-w-[915px]'>
                <div className='!border-b !border-grayLight  flex justify-between items-center pb-1.5'>
                  <h1 className='text-textblack text-lg inter font-semibold m-0'>Lead Taging</h1>
                  <button className='pr-btn inter inline-flex items-center mr-2.5' ><PlushIcon />Add</button>
                </div>
                <div className='block !mt-4'>
                  <button
                    className='flex items-center !border !border-primary bg-blue100 !text-primary w-full text-sm inter justify-center rounded-lg py-2.5 leading-[22px]'
                    onClick={() => setShowLeadTaggingModal(true)}
                  >
                    + Add Lead Tagging
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
          <button
            className={`${style.cancelbtn}`}
            onClick={() => {
              history.push(`/projects/create-projects/pricing/${uuid}?pt=${property}&tt=${transaction}`)
            }}
          ><LeftIcon />Previous </button>
          <button onClick={() => {
            if (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) {
              history.push(`/projects/create-projects/seo-info/${uuid}?pt=${property}&tt=${transaction}`);
            } else {
              history.push(`/projects/create-projects/construction-updates/${uuid}?pt=${property}&tt=${transaction}`)
            }
          }} className={`${style.savebtn}`}>Save</button>
        </div>
      </div>
      {
        showAddBorkerageModal && <AddBrokrageModal
          uuid={uuid}
          getBrokrageDetails={getBrokrageDetails}
          show={showAddBorkerageModal}
          selectedBrokerage={selectedBrokerage}
          closeModal={() => {
            setShowAddBrokerageModal(false)
            setSelectedBrokerage()
          }} />
      }
      {
        showOfferModal && <ProjectOfferModal
          show={showOfferModal}
          style={style}
          closeModal={() => {
            setShowOfferModal(false)
            setSelectedOffer()
          }}
          selectedOffer={selectedOffer}
          uuid={uuid}
          getOffers={getOffers}
        />
      }
      {/* {
        showExtraIncentiveModal && <AddIncentiveModal show={showExtraIncentiveModal} closeModal={() => setShowExtraIncentiveModal(false)} />
      }
      {
        showAdditionalIncentiveModal && <AdditionalIncentiveModal show={showAdditionalIncentiveModal} closeModal={() => setShowAdditionalIncentiveModal(false)} />
      }
      {
        showLeadTaggingModal && <AddLeadTaggingModal show={showLeadTaggingModal} closeModal={() => setShowLeadTaggingModal(false)} />
      }
     */}
      {
        showBrokerageDelete && selectedBrokerage?.uuid ? <PopUpModal
          setShow={setShowBrokerageDelete}
          show={showBrokerageDelete}
          heading={<>
            Delete ?
          </>}
          body={<div className=' p-3'>
            <div className="mb-4">
              Are you sure you want to delete project brokerage?
            </div>
            <div className="justify-content-end d-flex">
              <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowBrokerageDelete(false)}>Cancel</button>
              <button className="fw-po-medium del-btn px-16" onClick={handleDeleteBrokerage}>Confirm Delete</button>

            </div>
          </div>}
        /> : null
      }
      {
        showOfferDelete && selectedOffer?.uuid ? <PopUpModal
          setShow={setShowOfferDelete}
          show={showOfferDelete}
          heading={<>
            Delete ?
          </>}
          body={<div className=' p-3'>
            <div className="mb-4">
              Are you sure you want to delete Offer?
            </div>
            <div className="justify-content-end d-flex">
              <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowOfferDelete(false)}>Cancel</button>
              <button className="fw-po-medium del-btn px-16" onClick={handleDeleteOffer}>Confirm Delete</button>

            </div>
          </div>}
        /> : null
      }
    </div>
  )
}

export default Broker;