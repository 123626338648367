import React from 'react'
import PropTypes from 'prop-types';

const FileInputField = ({ label, type, name, placeholder, error, errorMessage, onChange, fieldRef, divClass, inputClass, inputRequired, ...props }) => {

    return (
        <div className={`${divClass} w-full`}>
            <label className=" text-sm text-black700 mb-2" htmlFor={name}>{label}{inputRequired && <sup className='text-red text-sm'>*</sup>}</label>
            <input
                type='file'
                id={name}
                name={name}
                placeholder={placeholder}
                ref={fieldRef}
                onChange={onChange}
                {...props}
                style={{ border: error ? "2px solid #ef4444" : '' }}
                className={`${inputClass}  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500`}
            />
        </div>
    )
}

FileInputField.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    defaultValue: PropTypes.any,
    fieldRef: PropTypes.any
};

FileInputField.defaultProps = {
    type: 'text',
    placeholder: '',
    error: false,
    errorMessage: '',
    fieldRef: undefined,
    divClass: '',
    inputClass: '',
    label: '',
    // value: ''
};

export default FileInputField
