import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';

import { bhk_type, carpet_area, facing, furnished_unit, maintenance_payee, plot_area, property_age, sharing, preferred_tenant, project_area, usages_status, heightMeasurment } from '../../../utils/project-constant';
import { insertConfiguration, updateConfigurationByUUID, updateListingConfigurationByUUID, uploadFile } from '../../../services/public/public.service';
import { toINRFormat } from '../../../helpers/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as RupayIcon } from '../../../assets/icons/rupay.svg';
import RightModal from '../../../components/Modals/RightModal';
import InputSelect from '../../../components/InputGroup/InputSelect';
import Dropdown from '../../../components/InputGroup/dropdown';
import InputText from '../../../components/InputGroup/InputText';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};

const AreaType = [
    {
        label: 'Super Area',
        value: 'Super Area'
    },
    {
        label: 'Build Up Area',
        value: 'Build Up Area'
    },
    {
        label: 'Carpet Area',
        value: 'Carpet Area'
    },
    {
        label: 'Plot Area',
        value: 'Plot Area'
    }
]
const textareastyle = {
    minHeight: 'unset!important',
    important: true

};

const ConfigurationModal = ({
    show,
    closeModal,
    style,
    getConfig,
    uuid,
    selectedConfig,
    setConfigurations,
    configurationsData,
    unitTypes,
    handleChangeUnitType,
    inventory,
    preferredTenent,
    selectedConfigIndex
}) => {

    const [showSuperArea, setShowSuperArea] = useState(false);
    const [showBuildupArea, setShowBuildupArea] = useState(false);
    const [showPlotArea, setShowPlotArea] = useState(false);
    const [configImage, setConfigImage] = useState('')

    let error = '';

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            title: '',
            unit_type: '',
            unit_configuration: '',
            area_range: '',
            area_type: '',
            price_range: '',
            alt_tag: '',
            carpet_area: '',
            carpet_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            super_area: '',
            super_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            built_up_area: '',
            built_up_area_unit: {
                label: 'Sq.Feet',
                value: 'Sq.ft'
            },
            plot_area: '',
            plot_area_unit: {
                label: 'Acres',
                value: 'acres'
            },
            floor: '',
            tower: '',
            unit_no: '',
            similar_unit_count: ''
        },
        // resolver: yupResolver(basicDetailsvalidationSchema)
    });

    useEffect(() => {
        if (Object.keys(selectedConfig).length > 0) {
            reset({
                unit_type: selectedConfig?.unit_type ? unitTypes.find(item => item.value == selectedConfig.unit_type) : '',
                price_range: selectedConfig?.price_range ? selectedConfig?.price_range : '',
                unit_configuration: selectedConfig?.unit_configuration ? bhk_type.find(item => item.value === selectedConfig.unit_configuration) : '',
                area_range: selectedConfig?.area_range,
                area_type: selectedConfig?.area_type ? AreaType.find(item => item.value === selectedConfig?.area_type) : '',
                title: selectedConfig?.unit_title,
                alt_tag: selectedConfig?.unit_plan_image_alt_tag,
                carpet_area: selectedConfig?.carpet_area || '',
                carpet_area_unit: selectedConfig?.carpet_area_unit ? project_area.find(item => item.value === selectedConfig.carpet_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                super_area: selectedConfig?.super_area || '',
                super_area_unit: selectedConfig?.super_area_unit ? project_area.find(item => item.value === selectedConfig.super_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                built_up_area: selectedConfig?.built_up_area || '',
                built_up_area_unit: selectedConfig?.built_up_area_unit ? project_area.find(item => item.value === selectedConfig.built_up_area_unit) : {
                    label: 'Sq.Feet',
                    value: 'Sq.ft'
                },
                plot_area: selectedConfig?.plot_area || '',
                plot_area_unit: selectedConfig?.plot_area ? plot_area.find(item => item.value === selectedConfig.plot_area) : {
                    label: 'Acres',
                    value: 'acres'
                },
                tower: selectedConfig?.tower || '',
                floor: selectedConfig?.floor || '',
                unit_no: selectedConfig?.unit_no || '',
                similar_unit_count: selectedConfig?.similar_unit_count || '',


            },
                { keepDefaultValues: true }
            )
            setConfigImage(selectedConfig?.unit_plan_image)
            setShowSuperArea(!!selectedConfig?.super_area || false);
            setShowBuildupArea(!!selectedConfig?.built_up_area || false);
            setShowPlotArea(!!selectedConfig?.plot_area || '');
        }
    }, [selectedConfig])

    const unitTypeVal = watch('unit_type');

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);
        if (response.data.status === 200) {
            setConfigImage(response.data.data.s3_url)

        }
    }

    const onFormSubmit = async (values) => {
        if (!configImage || !values.alt_tag || !values?.unit_type?.value || !values?.unit_configuration?.value) {
            toast.error('Please enter all required fields');
            return;
        }
        const payload = {
            unit_plan_image: configImage,
            unit_title: values.title,
            unit_plan_image_alt_tag: values.alt_tag,
            ...(values.unit_type) && { unit_type: values.unit_type.value },
            ...(values.unit_configuration) && { unit_configuration: values.unit_configuration.value },
            ...(values.price_range) && { price_range: values.price_range },
            ...(values.carpet_area) && { carpet_area: +values.carpet_area || null },
            ...(values.carpet_area_unit) && { carpet_area_unit: values.carpet_area_unit.value },
            ...(values.super_area) && { super_area: +values.super_area || null },
            ...(values.super_area_unit) && { super_area_unit: values.super_area_unit.value },
            ...(values.built_up_area) && { built_up_area: +values.built_up_area || null },
            ...(values.built_up_area_unit) && { built_up_area_unit: values.built_up_area_unit.value },
            ...(values.plot_area) && { plot_area: +values.plot_area || null },
            ...(values.plot_area_unit) && { plot_area_unit: values.plot_area_unit.value },
            ...(values.floor) && { floor: +values.floor || null },
            ...(values.tower) && { tower: +values.tower || null },
            ...(values.unit_no) && { unit_no: values.unit_no || null },
            ...(values.similar_unit_count) && { similar_unit_count: values.similar_unit_count || null },
        }

        // let res = {};
        if (selectedConfig?.uuid) {
            let res = await updateListingConfigurationByUUID(selectedConfig?.uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message);
                // getConfig();
                const index = configurationsData?.findIndex(c => c.uuid === selectedConfig.uuid);
                let configData = [...configurationsData]
                configData[index] = payload
                setConfigurations(configData)
            }
        } else if (uuid) {
            setConfigurations([...configurationsData, { ...payload, is_new: true }])
        } else if (!selectedConfig?.uuid && !uuid && selectedConfigIndex >= 0) {
            const configList = [...configurationsData];
            configList[selectedConfigIndex] = { ...payload }
            setConfigurations(configList)
        } else {
            setConfigurations([...configurationsData, { ...payload }])
        }
        // if (selectedConfig?.uuid) {
        //     res = await updateConfigurationByUUID(selectedConfig?.uuid, payload)
        //     if (res.data.status === 200) {
        //         toast.success(res.data.message);
        //         getConfig();
        //     }

        // } else if (uuid) {
        //     res = await insertConfiguration(uuid, payload)
        //     if (res.data.status === 200) {
        //         toast.success(res.data.message);
        //         getConfig();
        //     }
        // } else if (selectedConfigIndex >= 0) {
        //     const configList = [...configurationsData];
        //     configList[selectedConfigIndex] = { ...payload }
        //     setConfigurations(configList)
        // } else {
        //     setConfigurations([...configurationsData, { ...payload }])
        // }

        reset();
        closeModal();
    }

    return (
        <RightModal show={show} closeModal={() => {
            reset();
            closeModal();
        }} modalClass={`${style.configurationModal} configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {Object.keys(selectedConfig).length > 0 ? 'Edit' : 'Add'} Configuration
                </h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    reset();
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)} id='config-form' className={`${style.modalForm} modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                {/* <div>
                    <Controller
                        control={control}
                        name='title'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputText
                                    label='Configuration Title'
                                    placeholder='Enter Configuration Title'
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div> */}
                {/* unit type*/}
                <div className={`form-group position-relative`}>
                    <div>
                        <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                            <span>Unit Plan <sup>*</sup></span>
                            <span>PNG, 2MB</span>
                        </div>
                        <div className={`${style.upload}`}>
                            {configImage && <img src={configImage} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                            <input type="file" className='d-none' name='developer-logo' id='config-img' accept='image/*' onChange={(e) => handleOnChangeImage(e)} />
                            {!configImage && <div className={`${style.uplaodbtngrp}`}>
                                <button
                                    type='button'
                                    className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('config-img').click()
                                    }}
                                ><PluswhiteIcon />Upload</button>
                            </div>}
                            {configImage && <div className={`${style.hoverbox}`}>
                                <button
                                    type='button'
                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                    onClick={() => {
                                        document.getElementById('config-img').click()
                                    }}
                                ><PluswhiteIcon />Replace</button>
                                <button
                                    type='button'
                                    className={`${style.delbtn} inter border-0 bg-white`}
                                    onClick={() => {
                                        setConfigImage('');
                                    }}
                                ><TrashIcon /></button>
                            </div>}
                        </div>
                        {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name='alt_tag'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <InputText
                                        label='Unit Plan Alt Tag'
                                        inputclass={'mt-3'}
                                        placeholder='Enter Unit Plan Alt Tag'
                                        type="text"
                                        onChange={onChange}
                                        value={value}
                                        required={true}
                                    // error={errors?.state?.message ? 'Please select State' : ''}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <Controller
                        control={control}
                        name='unit_type'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <label className='mt-3'>
                                    Unit Type <sup>*</sup>
                                </label>
                                <CreatableSelect
                                    placeholder='Select Type'
                                    onCreateOption={(value) => {
                                        handleChangeUnitType({ label: value, value: value })
                                        onChange({ label: value, value: value })
                                    }}
                                    onChange={onChange}
                                    value={value}
                                    options={unitTypes}
                                    styles={customStyles}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>
                {/* unit configuration */}
                {unitTypeVal?.value !== 'Plot' && <div>
                    <Controller
                        control={control}
                        name='unit_configuration'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputSelect
                                    label='Unit Configuration'
                                    // inputclass={'mt-3'}
                                    placeholder='Select Unit Configuration'
                                    onChange={onChange}
                                    value={value}
                                    options={bhk_type}
                                    styles={customStyles}
                                    required={true}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                </div>}
                {/* Price */}

                <div className='form-group position-relative'>
                    <label>Price</label>
                    <Controller
                        control={control}
                        name='price_range'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <InputText
                                    placeholder='E.g. 2cr - 3.5cr'
                                    type="text"
                                    onChange={onChange}
                                    value={value}
                                // error={errors?.state?.message ? 'Please select State' : ''}
                                />
                            </>
                        )}
                    />
                    {/* <RupayIcon className={`${style.absrupay}`} /> */}
                </div>
                {/* Area Type */}
                {/* unit configuration */}
                <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Carpet Area </label>
                    <Controller
                        control={control}
                        name='carpet_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={carpet_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g 1200'
                        {...register('carpet_area')}
                    />
                </div>
                {(!showSuperArea || !showBuildupArea || !showPlotArea) && <div className='d-flex flex-wrap align-items-start gap-8'>
                    {!showSuperArea && <button type='button' onClick={() => setShowSuperArea(true)} className={`${style.btntags}`}>+ Super Area</button>}
                    {!showBuildupArea && <button type='button' onClick={() => setShowBuildupArea(true)} className={`${style.btntags}`}>+ Built up Area</button>}
                    {!showPlotArea && <button type='button' onClick={() => setShowPlotArea(true)} className={`${style.btntags}`}>+ Plot Area</button>}

                </div>}
                {/* Super Area */}
                {showSuperArea && <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Super Area </label>
                    <Controller
                        control={control}
                        name='super_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={project_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g. 1400'
                        {...register('super_area')}
                    />
                </div>}
                {/* built up Area */}
                {showBuildupArea && <div className={` form-group position-relative`}>
                    <label className='block text-black700 text-sm mb-2 font-medium'>Built up Area </label>
                    <Controller
                        control={control}
                        name='built_up_area_unit'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <>
                                <Dropdown
                                    list={project_area}
                                    onChange={onChange}
                                    value={value}
                                    style={style}
                                />
                            </>
                        )}
                    />

                    <input
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        type={"number"}
                        placeholder='e.g. 1300'
                        {...register('built_up_area')}
                    />
                </div>}
                {/* Plot area */}
                {showPlotArea &&
                    <div className={` form-group position-relative`}>
                        <label className='block text-black700 text-sm mb-2 font-medium'>Plot Area </label>
                        <Controller
                            control={control}
                            name='plot_area_unit'
                            render={({
                                field: { onChange, onBlur, value, ref },
                            }) => (
                                <>
                                    <Dropdown
                                        list={plot_area}
                                        onChange={onChange}
                                        value={value}
                                        style={style}
                                    />
                                </>
                            )}
                        />
                        <input
                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                            type={"number"}
                            placeholder='e.g. 33'
                            {...register('plot_area')}
                        />

                    </div>}
                <div className='form-group'>
                    <label >Tower No.</label>
                    <input
                        type='text'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 8'
                        {...register('tower')}
                    />
                </div>
                <div className='form-group'>
                    <label className='flex items-center gap-1'>Floor <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4> */}
                                    <p className=''>On which floor is it situated
                                    </p>
                                </div>
                            </Tooltip>
                        }
                    >
                        <InfoIcon className='cursor-pointer' />
                    </OverlayTrigger></label>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 3'
                        {...register('floor')}
                    />

                </div>
                {/* Unit No.  */}
                <div className='form-group'>
                    <div className='d-flex justify-content-between align-items-center mb-8'>
                        <label className='mb-0'>Unit No.</label>
                    </div>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 1'
                        {...register('unit_no')}
                    />
                </div>
                <div className='form-group'>
                    <label >No. of Similar Units</label>
                    <input
                        type='number'
                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                        placeholder='e.g. 100'
                        {...register('similar_unit_count')}
                    />

                </div>
            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button type='button' className='outline-btn w-100' onClick={() => {
                    reset();
                    closeModal();
                }}>Cancel</button>
                <button type='button' onClick={handleSubmit(onFormSubmit)} form='config-form' className='pr-btn w-100'>Add</button>
            </div>
        </RightModal>

    )
}

export default ConfigurationModal
