import React, { useEffect, useState } from 'react';
import { ReactComponent as TextFileIcon } from '../../../../assets/icons/fileTextGrey.svg';
import SliderModal from "../../../../components/Modals/SliderModal";
import { getFinalNegotiationAutomationTeams, getUsersForSettings, insertFinalNegotiationAutomationTeams } from '../../../../services/private/company.service';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { getInitials } from '../../../../helpers/helpers';
import { getAllTeams } from '../../../../services/public/public.service';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as RightIcon } from '../../../../assets/icons/NavRight.svg';
import { toast } from 'react-toastify';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};


const TeamForFinalNegotiationModal = ({ show, setShow, teams, getSelectedTeams }) => {
    // const [hideTimeline, setHideTimeline] = useState(false);    
    const [allTeams, setAllTeams] = useState([]);
    const [selectedTeam, setSeletecedTeam] = useState([]);
    const [viewMoreIndex, setViewMoreIndex] = useState([]);


    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const mapped = res.data.data.map(data => ({ ...data, label: data.name, value: data.id, isTeam: true }))
            setAllTeams(mapped);
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    useEffect(() => {
        if (allTeams.length > 0 && teams.length > 0) {
            const filteredData = allTeams.filter(item => teams.includes(item.id))
            setSeletecedTeam(filteredData)
        }
    }, [allTeams, teams])

    const handleSubmit = async () => {
        const teamIds = selectedTeam.map(item => item.id);
        const res = await insertFinalNegotiationAutomationTeams({ teams: teamIds })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getSelectedTeams();
            setShow(false);
        }
    }


    return (
        <SliderModal
            setShow={setShow}
            show={show}
            title={`Select Teams`}
            body={<>
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="h-full !pl-5 !pr-5 !pb-5 overflow-auto">

                        <div className='flex flex-col !gap-y-4'>
                            <InputSelect
                                label='Select Users/Teams'
                                placeholder='Select Users/Teams'
                                value={''}
                                onChange={(value) => {
                                    const index = selectedTeam.findIndex(i => i.value === value.value)
                                    if (index === -1) {
                                        setSeletecedTeam([...selectedTeam, value])
                                        // let usersList = [...teamAndUsersList];
                                        // let uIndex = usersList.findIndex(i => i.value === value.value)
                                        // usersList.splice(uIndex, 1);
                                        // setTeamAndUsersList(usersList);
                                    }
                                }}
                                styles={customStyles}
                                options={allTeams}
                                required={true}
                            />
                        </div>
                        <div className=''>

                            <div className=''>
                                {selectedTeam.length > 0 && selectedTeam.map((item, index) => (
                                    <div className="mt-4 border p-4 rounded-lg shadow-bottomshadow" key={`${item.id}_${index}`}>
                                        <div className="flex justify-between items-start mb-3">
                                            <div>
                                                <p className="m-0 text-base font-semibold">{item.name}</p>
                                                <p className="m-0 text-xs text-black700 font-medium">Lead: {item.leader_name}</p>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    let selectedTeamCopy = [...selectedTeam];
                                                    selectedTeamCopy.splice(index, 1);
                                                    setSeletecedTeam(selectedTeamCopy);
                                                }}
                                            >
                                                <CloseIcon className="h-5 w-5 text-black700" />
                                                {/* Remove */}

                                            </button>
                                        </div>
                                        <div className='px-4 bg-blue-light rounded-lg'>
                                            {viewMoreIndex.includes(index) && item.users?.length > 0 && item.users.map((user, userIndex) => (
                                                <div className={`flex justify-between ${userIndex + 1 < item.users?.length ? 'border-b' : ''}  pb-2 `}>
                                                    <div className="py-2 ">
                                                        <p className="m-0 text-sm font-semibold">{user.name}</p>
                                                        <p className="m-0 text-xs text-black700 font-medium">{user?.email}</p>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <button
                                                className="flex items-center font-medium"
                                                onClick={() => {
                                                    if (viewMoreIndex.includes(index)) {
                                                        let indexes = [...viewMoreIndex];
                                                        indexes.splice(index, 1);
                                                        setViewMoreIndex(indexes)
                                                    } else {
                                                        setViewMoreIndex([...viewMoreIndex, index])
                                                    }
                                                }}>
                                                {viewMoreIndex.includes(index) ? 'Hide' : 'View'} Users
                                                <RightIcon className={`ml-1 h-5 w-5 ${viewMoreIndex.includes(index) ? '-rotate-90' : 'rotate-90'}`} />
                                            </button>
                                        </div>
                                    </div>))}
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button
                            className="cancel outline-btn"
                            onClick={() => {
                                setShow(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="pr-btn"
                            onClick={handleSubmit}
                        >
                            Save

                        </button>

                    </div>
                </div>

            </>}
        />
    )
}

export default TeamForFinalNegotiationModal