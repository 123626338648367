import React from 'react'
import LeadDetailLeftBottom from './LeadDetailLeftBottom'
import LeadDetailLeftTop from './LeadDetailLeftTop'

export default function LeadDetailBodyLeft(props) {
    return (
        <>
            <LeadDetailLeftTop leadAccess={props.leadAccess} leadInfoData={props.leadInfoData} customerInfoData={props.customerInfoData} />
            <LeadDetailLeftBottom leadAccess={props.leadAccess} leadInfoData={props.leadInfoData} customerInfoData={props.customerInfoData} campaignInfo={props.campaignInfo} />
        </>
    )
}
