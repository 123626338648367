import React, { useState, useEffect } from 'react';
import SideBanner from '../../components/SideBanner/SideBanner';
import { useParams, Link } from 'react-router-dom'
import '../Signup-Form/signup.css';
import { verifyEmail, verifyLinkForSignUp } from '../../services/public/public.service';

export default function VerificationView() {
    let Heading = `A Real Estate Platform That 'Works For You'`;
    let SubHeadingText = `Manage & engage with customers, increase conversations, & measure your results in an easy & efficient way.`;

    const params = useParams();

    const [isValid, setisValid] = useState(false);
    // const [isValidLink, setisValidLink] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('user-login')

        return (() => el.classList.remove('user-login'))
    }, [])

    const handleVerifyLink = async () => {
        await verifyLinkForSignUp(params.uuid).then(res => {
            if (res.status === 200) {
                setisValid(true)
                setLoading(false)
            }
        }).catch(e => {
            setisValid(false)
            setLoading(false)
            console.error(e);
        })
    }

    // const handleVerifyLink = async() => {
    //     await verifyLinkForSignUp(params.uuid).then(res=>{
    //         if(res.status === 200){
    //             setisValid(true)
    //             setLoading(false)
    //         }
    //     })
    // }

    useEffect(() => {
        handleVerifyLink()
    }, [])

    // useEffect(()=>{
    //     if(isValidLink === true){
    //         handleVerifyLink()
    //     }
    // },[isValid])


    const isLoaded = () => {
        return (
            <div className="col-lg-8 col-md-8 col-sm-4 login-div d-flex flex-fill pl-0">
                {
                    isValid ?
                        // Completd page
                        <div className="thankyou d-flex align-items-center justify-content-center flex-column flex-fill">
                            <figure>
                                <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none">
                                    <path d="M36.4997 72C56.3817 72 72.4993 55.8823 72.4993 36C72.4993 16.1178 56.3817 0 36.4997 0C16.6176 0 0.5 16.1178 0.5 36C0.5 55.8823 16.6176 72 36.4997 72Z" fill="#32BA7C" />
                                    <path d="M27.3018 52.2396L45.8127 70.7507C61.1438 66.6623 72.5001 52.6939 72.5001 35.9999C72.5001 35.6592 72.5001 35.3185 72.5001 34.9778L57.964 21.5771L27.3018 52.2396Z" fill="#0AA06E" />
                                    <path d="M37.4081 44.0656C38.998 45.6555 38.998 48.3811 37.4081 49.971L34.1148 53.2644C32.5249 54.8543 29.7994 54.8543 28.2095 53.2644L13.7869 38.7281C12.197 37.1382 12.197 34.4126 13.7869 32.8227L17.0802 29.5293C18.6701 27.9394 21.3957 27.9394 22.9856 29.5293L37.4081 44.0656Z" fill="white" />
                                    <path d="M50.0126 18.9668C51.6025 17.3769 54.328 17.3769 55.9179 18.9668L59.2112 22.2602C60.8011 23.8501 60.8011 26.5757 59.2112 28.1656L34.2273 53.0362C32.6374 54.6261 29.9118 54.6261 28.3219 53.0362L25.0286 49.7429C23.4387 48.153 23.4387 45.4274 25.0286 43.8375L50.0126 18.9668Z" fill="white" />
                                </svg>
                            </figure>
                            <h1 className='fz48 fw-po-semi black mb-12'>Your Account is Verified!</h1>
                            <p className='fz18 fw-po-medium black-dark-700 mb-40 text-center'>
                                The login credentials for Propacity Platform have been sent on your registered email.
                            </p>
                            {/* <Link to='/' className='pr-btn w-auto fz18 px-22'>Back to Homepage </Link> */}

                        </div>
                        :
                        // Invalid link
                        <div className="thankyou d-flex align-items-center justify-content-center flex-column flex-fill">
                            <figure>
                                <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.5 72C46.0478 72 55.2045 68.2072 61.9558 61.4558C68.7072 54.7045 72.5 45.5478 72.5 36C72.5 26.4522 68.7072 17.2955 61.9558 10.5442C55.2045 3.79285 46.0478 0 36.5 0C26.9522 0 17.7955 3.79285 11.0442 10.5442C4.29285 17.2955 0.5 26.4522 0.5 36C0.5 45.5478 4.29285 54.7045 11.0442 61.4558C17.7955 68.2072 26.9522 72 36.5 72ZM31.1 52.2C31.1 50.7678 31.6689 49.3943 32.6816 48.3816C33.6943 47.3689 35.0678 46.8 36.5 46.8C37.9322 46.8 39.3057 47.3689 40.3184 48.3816C41.3311 49.3943 41.9 50.7678 41.9 52.2C41.9 53.6322 41.3311 55.0057 40.3184 56.0184C39.3057 57.0311 37.9322 57.6 36.5 57.6C35.0678 57.6 33.6943 57.0311 32.6816 56.0184C31.6689 55.0057 31.1 53.6322 31.1 52.2ZM32.9576 17.352C33.1079 16.522 33.5449 15.7711 34.1923 15.2303C34.8397 14.6896 35.6565 14.3934 36.5 14.3934C37.3435 14.3934 38.1603 14.6896 38.8077 15.2303C39.4551 15.7711 39.8921 16.522 40.0424 17.352L40.1 18V36L40.0424 36.648C39.8921 37.478 39.4551 38.2289 38.8077 38.7697C38.1603 39.3104 37.3435 39.6066 36.5 39.6066C35.6565 39.6066 34.8397 39.3104 34.1923 38.7697C33.5449 38.2289 33.1079 37.478 32.9576 36.648L32.9 36V18L32.9576 17.352Z" fill="#FD9A39" />
                                </svg>
                            </figure>

                            <h1 className='fz48 fw-po-semi black mb-12'>Invalid Invite Link!</h1>
                            <p className='fz18 fw-po-medium black-dark-700 mb-40 text-center'>Your invite link is invalid.
                                <span className='d-block'> Please contact support@propacity.in to help you.</span></p>

                        </div>
                }


            </div>

        )
    }

    const isLoading = () => {
        return (
            <div className="col-lg-8 col-md-8 col-sm-4 login-div d-flex flex-fill pl-md-0">
                <div className="thankyou d-flex align-items-center justify-content-center flex-column flex-fill">
                    <figure>

                    </figure>

                    <h1 className='fz48 fw-po-semi black mb-12'>Checking Your Link...</h1>
                    <p className='fz18 fw-po-medium black-dark-700 mb-40 text-center'>
                        <span className='d-block'> Do not Refresh your browser</span></p>

                </div>
            </div>
        )
    }

    return (
        <>
            <div className="container-fluid d-flex flex-fill">
                <div className="row d-flex flex-fill">

                    <div className="col-lg-4 col-md-4 col-sm-4 p-0 d-flex flex-fill">

                        <SideBanner Heading={Heading} Text={SubHeadingText} />
                    </div>

                    {
                        loading ? isLoading() : isLoaded()
                    }

                </div>
            </div>
        </>
    )
}