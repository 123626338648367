import React, { useEffect, useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { getNextandPreviousLead, getLeadByUuid, getCampaignInfo } from '../../services/private/leads.service';
import LeadDetailBody from './LeadDetailBody/LeadDetailBody.index';
import LeadDetailHeader from './LeadDetailHeader/LeadDetailHeader';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
// import  from '../../services/private/leads.service';

export default function LeadDetailIndex() {

    const params = useParams();
    const [prevNNextLead, setPrevNNextLead] = useState({});
    const [leadInfoData, setLeadInfoData] = useState({});
    const [customerInfoData, setCustomerInfoData] = useState({});
    const [projectInfoData, setProjectInfoData] = useState([]);
    const [userNotFound, setUserNotFound] = useState(false)
    const [campaignInfo, setCampaignInfo] = useState();
    const [leadAccess, setLeadAccess] = useState();
    const [index, setIndex] = useState('');
    const [leadIds, setLeadIds] = useState([]);

    useDocumentTitle(customerInfoData?.data?.name);

    const history = useHistory();
    const getNextAndPreviLead = async () => {
        await getNextandPreviousLead(params.id).then(res => {
            if (res.status === 200) {
                setPrevNNextLead(res.data.data)
            }
        })
    }

    const getLeadCampaignInfo = () => {
        getCampaignInfo(params.id).then(res => {
            if (res.data.status === 200) {
                setCampaignInfo(res.data.data);
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const getLeadDetails = async () => {
        await getLeadByUuid(params.id).then(res => {
            if (res.status === 200) {
                setCustomerInfoData(res.data.data.customer)
                setLeadInfoData(res.data.data.lead)
                setProjectInfoData(res.data.data.projects.data)
                setCampaignInfo(res.data.data.campaign)
                setLeadAccess(res.data.data.access);
                if (res.data.data.lead.data.facebook_ad_id) {
                    getLeadCampaignInfo()
                }
            }

        })
            .catch((err) => {
                if (err.response?.data)
                    setUserNotFound(true)
            });
    }

    useEffect(() => {
        if (params.id && localStorage.getItem('leadIds')) {
            setLeadIds(JSON.parse(localStorage.getItem('leadIds')));
            const ids = JSON.parse(localStorage.getItem('leadIds'))
            const idIndex = ids.findIndex(i => i === params.id);
            setIndex(idIndex);

        }
    }, [params.id]);

    useEffect(() => {
        const el = document.getElementById('main-container')
        el.classList.add('user-leads-details')
        // getNextAndPreviLead()
        getLeadDetails()

        const handleCopy = (e) => {
            // Optionally prevent default copy behavior
            e.preventDefault();
        };
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        // document.addEventListener('contextmenu', handleContextMenu);

        return (() => {
            el.classList.remove('user-leads-details')
            document.removeEventListener('copy', handleCopy);
            // document.removeEventListener('contextmenu', handleContextMenu);
        })
    }, [])

    return (
        <>
            {userNotFound && <Redirect to='/404' />}
            <div className="main-section position-relative d-flex flex-wrap pr-0 no-select">
                <LeadDetailHeader
                    preNNextLead={prevNNextLead}
                    customerInfoData={customerInfoData}
                    leadAccess={leadAccess}
                    leadIds={leadIds}
                    index={index}
                />
                {Object.keys(leadInfoData).length > 0 &&
                    Object.keys(customerInfoData).length > 0 &&
                    <LeadDetailBody
                        leadInfoData={leadInfoData}
                        customerInfoData={customerInfoData}
                        campaignInfo={campaignInfo}
                        leadAccess={leadAccess}
                    />
                }
            </div>

        </>
    )
}