import { all } from "redux-saga/effects";
import loginSaga from "../Login/saga";
import siginSaga from "../Signup/saga";
import forgotSaga from "../Forgot Password/saga";
import leadSaga from "../Lead/saga";
import fbIntegerationSaga from "../Integerations/Facebook Lead Gen/saga";
import leadDetailSaga from "../Lead Details/saga";
import { tagsSaga } from "../Lead Details/components/Tags/saga";
import { notesSaga } from "../Lead Details/components/Create Note Sidebar/saga";
import { followupSaga } from "../Lead Details/components/Create Followup Sidebar/saga";
import { svSaga } from "../Lead Details/components/Create Site Visit Sidebar/saga";
import { timelineSaga } from "../Lead Details/components/Recent Activities/saga";

export function* mainSaga() {
  yield all([
    loginSaga(),
    siginSaga(),
    forgotSaga(),
    leadSaga(),
    leadDetailSaga(),
    tagsSaga(),
    followupSaga(),
    svSaga(),
    fbIntegerationSaga(),
    notesSaga(),
    timelineSaga()
  ]);
}