import React, { useCallback, useEffect, useState } from 'react';
// import makeRequest from 'src/httpHandler/httpHandler';

// import { ReactComponent as DashboarIcon } from '../assets/Icons/DashboarIcon.svg';
// import { ReactComponent as SearchIcon } from '../assets/Icons/Search.svg';
import { ReactComponent as SearchCloseIcon } from '../../../assets/icons/searchClose.svg';
// import { ReactComponent as PlusIcon } from '../assets/Icons/Plus.svg';
// import DevLogo from '../assets/Icons/Userlog.png';
import { Link } from 'react-router-dom';
// import { toast } from 'react-hot-toast';
// import SubmitForAprrovalModal from 'src/components/dashboard/SubmitForAprrovalModal';
import EventsTable from './EventsTable';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';
import { toast } from 'react-toastify';
import { getEvents, submitEventForReview, updateEventStatusByUUID } from '../../../services/private/company.service';



const Events = () => {
    const [allEvents, setAllEvents] = useState([]);
    const [userProfile, setUserProfile] = useState({});
    const [searchText, setSearchText] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedUuid, setSelectedUuid] = useState('');
    const [loading, setLoading] = useState(true);
    const [openModalForApproval, setOpenModalForApproval] = useState(false);

    const getUserProfile = async () => {
        const res = {}
        // await makeRequest('auth/getDeveloperProfile', 'get')
        if (res.status === 200) {
            setUserProfile(res.data.company)
        }
    }

    const getAllEvents = async () => {
        const res = await getEvents(searchText)
        // await makeRequest(`/launchpad/getAllEvents?searchText=${searchText}`, 'get');
        if (res.data.status === 200) {
            setAllEvents(res.data.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        getUserProfile();
        getAllEvents();
    }, []);

    useEffect(() => {
        getAllEvents();
    }, [searchText]);

    const handleChangeStatus = async (value, uuid) => {
        const res = await updateEventStatusByUUID(uuid, { status: value })
        // await makeRequest(`/launchpad/updateEventStatusByUUID/${uuid}`, 'put', { status: value });
        if (res.status === 200) {
            getAllEvents();
            toast.success('Status changed successfully')
        }
    }

    const handleDelete = useCallback(async () => {
        const res = {}
        // await makeRequest(`/launchpad/markEventDeletedByUUID/${selectedUuid}`, 'put')
        if (res.status === 200) {
            getAllEvents();
            toast.success(res.message);
        } else {
            toast.error(res.data.message);
        }
        setShowDeleteModal(false);
        setSelectedUuid('');
    }, [selectedUuid])

    const handleShowDelete = useCallback((uuid) => {
        setSelectedUuid(uuid);
        setShowDeleteModal(true);
    }, []);

    const handleShowApprovalModal = useCallback((uuid) => {
        setSelectedUuid(uuid);
        setOpenModalForApproval(true);
    }, []);

    const handleSubmitForApproval = useCallback(async () => {
        const res = await submitEventForReview(selectedUuid)
        // await makeRequest(`/launchpad/submitEventForReview/${selectedUuid}`, 'put')
        if (res.status === 200) {
            getAllEvents();
            toast.success(res.message);
        } else {
            toast.error(res.data.message);
        }
        setOpenModalForApproval(false);
        setSelectedUuid('');
    }, [selectedUuid]);

    const closeDeleteModal = () => {
        setSelectedUuid('');
        setShowDeleteModal(false);
    }

    const closeApprovalModal = useCallback(() => {
        setSelectedUuid('');
        setOpenModalForApproval(false);
    }, []);


    return (
        <>
            {/* <img src={userProfile?.logo} alt="" className='sm:h-full h-[60px] object-contain' /> */}
            <div className='!p-5 border-b border-black400 w-full'>
                <div className='flex items-center justify-between sm:flex-nowrap flex-wrap'>
                    <h2 className='fz24 mb-0 black fw-po-medium inter'>Events</h2>
                    {/* <div className='sm:hidden text-center block w-full mt-2 mb-7'>
                        <h2 className='text-2xl font-bold text-black traking-[0.1px] leading-[30px] mt-2 '>Launchpad Dashboard</h2>
                    </div> */}
                    <div className='sm:w-2/4 w-full !mt-4 sm:mt-0'>
                        <div className='flex justify-end items-start gap-2'>
                            <div className='relative md:max-w-[266px] max-w-full md:w-auto w-full'>
                                {/* <SearchIcon className='absolute left-2 top-[3px] z-10' /> */}
                                <input
                                    type="text"
                                    placeholder='Search'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className={`${searchText ? 'border-primary' : ''} text-black700 text-sm rounded border border-solid border-black400 block w-full bg-white placeholder:text-black700 placeholder:text-[14px] placeholder:font-normal placeholder:leading-[22px] focus:border-primary outline-0 leading-[22px] py-1 pl-9 pr-9 relative`}
                                />
                                {searchText &&
                                    <SearchCloseIcon
                                        className='absolute right-2 bottom-2 cursor-pointer hover:path searchCloseIcon w-3'
                                        onClick={() => setSearchText('')}
                                    />}
                            </div>
                            <Link to='/settings/listing/events/create-event' className='text-14 bg-primary text-white inline-flex gap-x-2 rounded text-sm font-medium items-center py-1 px-2 traking-[0.5px] outline-none whitespace-nowrap'> + New Event</Link>
                        </div>

                    </div>
                </div>
            </div>

            {allEvents.length > 0 ? <div className='pt-5 pr-0  pl-5 h-full w-full overflow-auto flex flex-auto'>
                <EventsTable
                    allEvents={allEvents}
                    handleShowDelete={handleShowDelete}
                    handleChangeStatus={handleChangeStatus}
                    handleShowApprovalModal={handleShowApprovalModal}
                />
            </div>
                :
                <>
                    {!loading && <div className='mt-16 w-full h-full'>
                        <div className='flex flex-col justify-center h-full items-center mt-[50px]'>
                            {searchText ? <>
                                <figure><ResultSearchIcon /></figure>
                                <h2 className='black text-base font-medium mb-2'>Didn’t find any matches </h2>
                                <p className='text-center text-black600'>We're sorry, but we couldn't find any results for your search.
                                    <span className='sm:block'>Please try again with different keywords.</span></p>
                            </>
                                :
                                <>
                                    <h2 className='black text-base font-medium mb-2'>No Events Found </h2>
                                    <p className='text-center text-black600'>Create a New Event to see it here.</p>
                                    <Link to='/settings/listing/events/create-event' className='text-14 border mt-6 border-primary text-primary inline-flex gap-x-2 rounded text-sm font-medium items-center py-1 px-2 traking-[0.5px] whitespace-nowrap'>
                                        {/* <PlusIcon /> */}
                                        + New Event</Link>
                                </>}

                        </div>
                    </div>}
                    {loading &&
                        <div className='flex justify-center items-center h-full w-full'>
                            {/* <Sppiner /> */}
                        </div>
                    }
                </>
            }
            {/* {showDeleteModal && <DeleteModal
                title="Delete Project?"
                subText='Deleting this project will permanently remove all associated data, including Media and Documents. Please note that once you delete a project, it cannot be restored.'
                show={showDeleteModal}
                closeModal={closeDeleteModal}
                onClick={() => handleDelete()}
            />} */}

            {/* {openModalForApproval && <SubmitForAprrovalModal show={openModalForApproval} closeModal={closeApprovalModal} onClick={handleSubmitForApproval} />} */}


            {/* <SubmitForAprrovalModal /> */}
        </>
    )
}

export default Events;