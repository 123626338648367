import React, { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import PopUpModal from "../../../../../components/Modals/PopUpModal";

const FacebookLeadAdsTable = ({
  apiKey,
  projectFormIDs,
  inactiveProjectFormId,
  integrationPermission,
  updateIntergratedLead,
  showEditLead
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [selectedProjectFormUUID, setSelectedProjectFormUUID] = useState("");

  const copyFunction = (apiKey) => {
    navigator.clipboard.writeText(apiKey).then(function () {
      toast.success('API Key Copied');
    }, function (err) {
      toast.error('Could not copy api');
    });
  };

  return (
    <>
      {showAlertDialog ? (
        <PopUpModal
          setShow={showAlertDialog}
          show={setShowAlertDialog}
          heading={<>Integration Delete from Make?</>}
          body={
            <div className="p-3">
              <div className="mb-4">
                Make sure Facebook lead integration is deleted from Make before deleting
                the integration on propacity platform.
              </div>
              <div className="justify-content-end d-flex modal-footer">
                <Button
                  variant=""
                  className="mr-2 cancel"
                  size="sm"
                  onClick={() => setShowAlertDialog(!showAlertDialog)}
                >
                  Cancel
                </Button>
                <Button
                  variant=""
                  size="sm"
                  className='del-btn'
                  onClick={() => {
                    inactiveProjectFormId(selectedProjectFormUUID);
                    setShowAlertDialog(!showAlertDialog);
                  }}
                >
                  Confirm Delete
                </Button>
              </div>
            </div>
          }
        />
      ) : null}

      <div className="fb-integration">
        <div className="fz16 po fw-midum mb-16 black-dark-800">API Key for Make</div>
        <div className="fz14 black-dark-800 po fw-midum flex">
          {apiKey}
          <span onClick={() => copyFunction(apiKey)} title='Copy API Key' className="cursor-pointer fw-po-medium copy-btn" style={{ display: "flex", alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="17" height="19" viewBox="0 0 17 19" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M3.5 4C1.84315 4 0.5 5.34315 0.5 7V16C0.5 17.6569 1.84315 19 3.5 19H9.5C11.1569 19 12.5 17.6569 12.5 16V9.06569C12.5 8.7219 12.3819 8.38855 12.1655 8.12143L9.27662 4.55574C8.99181 4.2042 8.56356 4 8.11113 4H3.5ZM9.21875 6.86712V8.05H10.1771L9.21875 6.86712ZM3.5 5.5C2.67157 5.5 2 6.17157 2 7V16C2 16.8284 2.67157 17.5 3.5 17.5H9.5C10.3284 17.5 11 16.8284 11 16V9.55H9.21875C8.39032 9.55 7.71875 8.87843 7.71875 8.05V5.5H3.5Z" fill="#92929D"></path><path fillRule="evenodd" clipRule="evenodd" d="M7.5 0C5.84315 0 4.5 1.34315 4.5 3V4.5L7.5 5.06569C7.5 5.75473 10.6918 6.78762 11.5 8.5C12.6352 10.9052 11.5 14.1238 11.5 15H13.5C15.1569 15 16.5 13.6569 16.5 12V5.06569C16.5 4.7219 16.3819 4.38855 16.1655 4.12143L13.2766 0.555737C12.9918 0.204205 12.5636 0 12.1111 0H7.5ZM13.2188 2.86712V4.05H14.1771L13.2188 2.86712ZM7.5 1.5C6.67157 1.5 6 2.17157 6 3V4.5L10.5 6.5C10.5 7.32843 11.6716 13.5 12.5 13.5H13.5C14.3284 13.5 15 12.8284 15 12V5.55H13.2188C12.3903 5.55 11.7188 4.87843 11.7188 4.05V1.5H7.5Z" fill="#92929D"></path></svg> Copy
          </span>
        </div>
      </div>
      <div className="mt-24 h-full overflow-auto">
        <div className="def-table pr-24 !h-full">
          <table className="table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Form ID</th>
                <th>Project</th>
                <th>Agency</th>
                <th>Total Leads</th>
                <th>Date Modified</th>
                {<th></th>}
              </tr>
            </thead>
            <tbody>
              {projectFormIDs.map((projectFormID, index) => {
                const { form_id, name: project, uuid, count, updated_on, marketing_agency } = projectFormID;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td title={form_id}>{form_id}</td>
                    <td>
                      {project.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{project}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{project}</span>
                        </OverlayTrigger>
                        :

                        <span className='text-capitalize three-dots'>{project}</span>
                      }

                    </td>
                    <td>
                      {marketing_agency?.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>

                                <p className='text-capitalize'>{marketing_agency || '-'}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{marketing_agency || '-'}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{marketing_agency || '-'}</span>
                      }


                    </td>
                    <td>{count}</td>
                    <td>{moment(updated_on).format('h:mm a, Do MMM YYYY')}</td>
                    {/* <td>{createDatewithTime(new Date(updated_on))}</td> */}
                    {<td className="dropdown view-more " onClick={() => setSelectedProjectFormUUID(uuid)}>

                      <button
                        className="bg-tr border-0"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                            fill="#B5B5BE"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                            fill="#B5B5BE"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                            fill="#B5B5BE"
                          />
                        </svg>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            // setShowAlertDialog(!showAlertDialog);
                            updateIntergratedLead(projectFormID);
                            showEditLead();
                          }}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item del-menu"
                          onClick={() => {
                            setShowAlertDialog(!showAlertDialog);
                          }}
                        >
                          Delete
                        </a>

                      </div>
                    </td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default FacebookLeadAdsTable;
