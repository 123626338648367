import React, { useCallback, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import ConnectForm from '../FacebookIntegration/connectForm';
import Final from '../FacebookIntegration/final';
import LeadMapping from '../FacebookIntegration/leadMapping';
import Success from '../FacebookIntegration/success';
import {
    createFbIntegration,
    facebookLinkedAccounts,
    generateFacebookToken,
    getAllProjects,
    getCompanyAgency,
    getCRMValues,
    getFacebookLoginUrl,
    getFacebookPageForms,
    getFacebookPages,
    getInetgrationsPageForms,
    getMappingByFormId,
    getPageFormByIntegrationId,
    getTestInetgrationsPageForms,
    sendTestLead,
    sendtestLead,
    updateIntegration
} from '../../services/private/company.service'
import FormMapping from '../FacebookIntegration/formMapping';
import TestLeadResponse from '../FacebookIntegration/testLeadResponse';
import { getUnion } from '../../helpers/helpers';
import TestleadProcess from './TestleadProcess';
import IntegrationCreatedModal from './IntegrationCreatedModal';
import AreYouSureModal from './AreYouSureModal';

const FbIntegrationModal = ({
    showFbModal,
    closeFbModal,
    editMapping,
    selectedIntegration,
    getFacebookIntegrations,
    selectedFormList,
    questionList,
    selectedUnmappedForms,
    showFinalConfirmaionModal,
    integrations,
    handleEditMapping,
    editFormList,
    isLoading,
    integrationState
}) => {
    const [steps, setSteps] = useState(1);
    const [facebookAccountList, setFacebookAccountList] = useState([]);
    const [selectedFbAccount, setSelectedFbAccount] = useState();
    const [pageList, setPageList] = useState([]);
    const [selectedPage, setSelectedPage] = useState();
    const [formList, setFormList] = useState([]);
    const [selectedForm, setSelectedForm] = useState();
    const [platformField, setPlatformField] = useState([]);
    const [projects, setProjects] = useState([]);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [leadMapping, setLeadMapping] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [selectedAgency, setSelectedAgency] = useState();
    const [facebookQuestions, setFacebookQuestions] = useState([]);
    const [formMapping, setFormMapping] = useState([]);
    const [unmappedForms, setUnmappedForm] = useState(true);
    const [formIdList, setFormIdList] = useState([]);
    const [pageUuid, setPageUuid] = useState();
    const [count, setCount] = useState(0);
    const [selectedMappedFormList, setSelectedMappedFormList] = useState([]);
    const [testLeadProcessModal, setTestLeadProcessModal] = useState(false);
    const [pageMappingValidation, setPageMappingValidation] = useState(false);
    const [integratedPage, setIntegratedPage] = useState();
    const [submitingIntegration, setSubmitingIntegration] = useState(false);
    const [isSendingLead, setIsSendingLead] = useState(false);
    const [sendTestLeadIndex, setSendTestLeadIndex] = useState();
    const [testLeadFailed, setTestLeadFailed] = useState('-');
    const [integrationCreated, setIntegrationCreated] = useState(false)
    const [showCloseConfirmationModal, setShowCloseConfirmationModa] = useState(false);
    const [connectPageError, setConnectPageError] = useState(false);

    const updateStep = useCallback((step) => {
        if (integrationCreated) setIntegrationCreated(false);
        setSteps(step);
    }, []);

    const getFacebookLinkedPages = useCallback(() => {
        facebookLinkedAccounts().then(res => {
            const leadList = res.data.data.map(item => { return { label: item.name, value: item.uuid, ...item } })
            setFacebookAccountList(leadList)
            if (localStorage.getItem('fb_modal')) {
                setSelectedFbAccount(leadList[0]);
            }
        }).catch(err => {
            console.log({ err })
        })
    }, [])

    const generateFbAccessToken = useCallback((code) => {
        generateFacebookToken({ code }).then(res => {
            getFacebookLinkedPages();
            localStorage.removeItem('fb_code')
            localStorage.removeItem('fb_modal')
        }).catch(err => {
            console.log({ err })
        })
    }, [])

    const getFbLoginUrl = useCallback(() => {
        getFacebookLoginUrl().then(res => {
            window.open(res.data.data, "_self")
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const getFbAccountPages = useCallback(() => {
        getFacebookPages(selectedFbAccount.value).then(res => {
            const pages = res.data.data.data.map(item => {
                return { label: item.name, value: item.id, ...item }
            })
            setPageList(pages)
        }).catch(err => {
            console.log({ err })
        })
    }, [selectedFbAccount]);

    const getFbPageForms = useCallback(() => {
        const integratedPage = integrations.find(integration => integration.page_id === selectedPage.id);
        setIntegratedPage(integratedPage)
        if (!integratedPage) {
            getFacebookPageForms({ id: selectedPage.value, token: selectedPage.access_token }).then(res => {
                const forms = res.data.data.data.map(item => {
                    return { label: item.name, value: item.id, ...item }
                })
                setFormList(forms);
            }).catch(err => {
                console.log({ err })
            })
        }
    }, [selectedPage])

    const getCRMValuesForMapping = useCallback(() => {
        getCRMValues().then(res => {
            setPlatformField(res.data.data)
        }).catch(err => {
            console.log({ err })
        })
    }, []);

    const getAllProjectsByCompanyId = useCallback(async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjects(projects);
            }
        })
    })

    const getAgencyData = useCallback(async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }, [])

    useEffect(() => {
        getFacebookLinkedPages();
        getCRMValuesForMapping();
        getAgencyData();
        getAllProjectsByCompanyId();
        if (localStorage.getItem('fb_code')) {
            generateFbAccessToken(localStorage.getItem('fb_code'))
        }

    }, []);

    const handleFbAccountChange = useCallback((value) => {
        setSelectedFbAccount(value);
        setSelectedPage('')
    }, []);

    const handlePageChange = useCallback((value) => {
        setSelectedPage(value);
    }, []);

    const handleFormChange = useCallback((value) => {
        setSelectedForm(value);
    }, []);

    const getFormMapping = useCallback(() => {
        getMappingByFormId(selectedIntegration.form_id).then(res => {
            const leadVal = res.data.data.map(lead => {
                return {
                    key: lead.facebook_value,
                    value: lead.crm_value,
                    label: lead.facebook_value
                }
            })
            setLeadMapping(leadVal);
        }).catch(err => {
            console.log({ err })
        })
    }, [selectedIntegration, platformField]);

    const getFormByIntegrationId = useCallback(() => {
        getPageFormByIntegrationId(selectedIntegration.uuid).then(res => {
            setSelectedForm(res.data.data)
        }).catch(err => {
            console.log({ err })
        })
    }, [selectedIntegration]);

    useEffect(() => {
        if (editMapping) {
            setFormList(selectedFormList);
            setFacebookQuestions(questionList);
            setUnmappedForm(selectedUnmappedForms);
            updateStep(2);
            let mapping = []
            // selection for pre-fill form mapping list
            mapping = selectedFormList
                .filter(form => form.projectId)
                .map(form => {
                    return {
                        formId: form.id,
                        formName: form.name,
                        projectId: form.projectId
                    }
                })
            setFormMapping([...mapping])
            let queMapping = [];
            queMapping = questionList.map(que => {
                return {
                    crmValue: que.crmValue,
                    facebookValue: que.key,
                    facebookLabel: que.label
                }
            })
            setLeadMapping([...queMapping])
        }
    }, [editMapping, selectedFormList, questionList, selectedUnmappedForms, editFormList]);

    useEffect(() => {
        let interval;
        if (count > 0 && count < 4) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount + 1)
                getPageLinkForm();
            }, 1000)
        } else {
            clearInterval(interval);
            setCount(0)
        }

        return (() => clearInterval(interval))
    }, [count]);

    useEffect(() => {
        const selectedFormList = formMapping.map(item => {
            return formList.find(form => form.id === item.formId)
        })
        setSelectedMappedFormList(selectedFormList)
    }, [formMapping])

    useEffect(() => {
        if (selectedFbAccount) {
            getFbAccountPages();
        }
    }, [selectedFbAccount]);

    useEffect(() => {
        if (selectedPage) {
            getFbPageForms();
        }
    }, [selectedPage]);

    const updateLeadMapping = (facebookQue) => {
        let mapping = []
        platformField.forEach(item => {
            facebookQue.forEach(question => {
                if (['email'].includes(question.key) && item.value === 'email') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['name', 'full_name'].includes(question.key) && item.value === 'name') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['mobile', 'phone_number'].includes(question.key) && item.value === 'mobile') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (['city'].includes(question.key) && item.value === 'city') {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
                if (!(['name', 'email', 'mobile', 'city', 'full_name', 'phone_number'].includes(question.key)) &&
                    !(['name', 'email', 'mobile', 'city'].includes(item.value))) {
                    mapping.push({
                        crmValue: item.value,
                        facebookValue: question.key,
                        facebookLabel: question.label
                    })
                }
            })
        })
        setLeadMapping(mapping);
    }

    useEffect(() => {
        if (steps === 2 && !editMapping) {
            updateLeadMapping(facebookQuestions);
        }
    }, [facebookQuestions])

    const handleChangeFormMapping = useCallback((value, form, mapIndex) => {
        const mappingVal = {
            formId: form.id,
            formName: form.name,
            projectId: value.value
        }
        const forms = [...formMapping];
        const index = forms.findIndex(item => item.formId === form.id)
        if (index > -1) {
            forms[index] = mappingVal
        } else {
            forms.push(mappingVal)
        }
        const newFormList = [...formList];
        newFormList[mapIndex].projectId = value.value;
        setFormList([...newFormList])
        setFormMapping([...forms]);
        setFormIdList([...formIdList, form.id])
        setFacebookQuestions(getUnion(facebookQuestions, form.questions))
    }, [facebookQuestions, formList, formIdList]);

    const handleFormMappingNext = useCallback(() => {
        let formsQuestion = [];
        formList.forEach(form => {
            formsQuestion = (getUnion(formsQuestion, form.questions))
        })
        updateLeadMapping(formsQuestion);
        setFacebookQuestions(formsQuestion);
        updateStep(3);
    }, [formList]);

    const handleChangeMapping = useCallback((value, key, label, mapIndex) => {
        const mappingVal = {
            crmValue: value.value,
            facebookValue: key,
            facebookLabel: label
        }
        const leads = [...leadMapping];
        const index = leads.findIndex(lead => lead.key === key);
        if (index > -1) {
            leads[index] = mappingVal;
        } else {
            leads.push(mappingVal)
        }
        const questionList = [...facebookQuestions];
        questionList[mapIndex].crmValue = value.value;
        setFacebookQuestions([...questionList]);
        setLeadMapping(leads);
    }, [leadMapping, facebookQuestions]);

    const handleChangeAgency = useCallback((value) => {
        setSelectedAgency(value)
    }, []);

    const handleChangeProject = useCallback((value) => {
        setSelectedProject(value)
    }, []);

    const handleUnmappedForm = useCallback((e, val) => {
        e.stopPropagation();
        setUnmappedForm(val)
    }, []);

    const handleSumitMapping = useCallback(() => {
        const payload = {
            pageId: selectedPage.id,
            pageName: selectedPage.name,
            marketingAgency: selectedAgency?.value,
            accessToken: selectedPage.access_token,
            mapping: leadMapping,
            profileId: selectedFbAccount.profile_id,
            formMapping,
            unmappedForms
        }

        setSubmitingIntegration(true);
        createFbIntegration(payload).then(res => {
            if (res.data.status === 200) {
                setSubmitingIntegration(false);
                setPageUuid(res.data.data.uuid)
                getFacebookIntegrations();
                // updateStep(4);
                setIntegrationCreated(true);
            }
        }).catch(err => {
            console.log({ err })
            setSubmitingIntegration(false);
        })
    }, [leadMapping, selectedPage, selectedForm, selectedAgency, selectedProject, selectedFbAccount, formMapping]);

    const updateFbIntegration = useCallback(() => {
        const payload = {
            mapping: leadMapping,
            formMapping,
            unmappedForms
        }
        updateIntegration(selectedIntegration.uuid, payload).then(res => {
            if (res.data.status === 200) {
                setPageUuid(res.data.data.uuid);
                getFacebookIntegrations();
                setIntegrationCreated(true);
            }
        }).catch(err => {
            console.log({ err })
        })
    }, [formMapping, leadMapping, unmappedForms, selectedIntegration]);

    const getPageLinkForm = useCallback(() => {
        getTestInetgrationsPageForms(pageUuid, { isTest: 'Test' }).then(res => {
            if (res.data.status === 200) {
                res.data.data.forms.forEach(item => {
                    if (item.test_status || count === 3) {
                        setIsSendingLead(false);
                        setCount(0);
                    }
                    if (!item.status && count === 3) {
                        setTestLeadFailed('Failed');
                    }
                    if (item.test_status) {
                        setTestLeadFailed('Success');
                    }
                })
                setSelectedMappedFormList(res.data.data.forms);
            }
        }).catch(err => {
            console.log(err)
            if (err.response.data.status === 500) {
                setTestLeadProcessModal(true);
            }
        })
    }, [pageUuid])

    const handleTestLead = useCallback((form, index) => {
        if (pageUuid) {
            setIsSendingLead(true);
            setSendTestLeadIndex(index)
            sendTestLead(pageUuid, { formIds: [form.id] }).then(res => {
                if (res.data.status === 200) {
                    if (count === 0) {
                        setCount(count + 1);
                    }
                }
                // updateStep(4);
            }).catch(err => {
                console.log({ err })
                if (err.response.data.status === 500) {
                    setTestLeadProcessModal(true);
                    setIsSendingLead(false);
                }
            })
        }
    }, [pageUuid, count]);

    const closeTestLeadProcessModal = useCallback(() => {
        setTestLeadProcessModal(false);
    }, []);

    const handlePageValidation = useCallback(() => {
        if (formMapping.length === 0) {
            setPageMappingValidation(true);
        } else {
            setPageMappingValidation(false);
            updateStep(3)
        }
    }, [formMapping]);

    const handleConnectAccountNext = useCallback(() => {
        if (!selectedFbAccount || !selectedPage) {
            setConnectPageError(true);
            return;
        } else {
            setConnectPageError(false);
        }

        if (integratedPage && Object.keys(integratedPage).length > 0) {
            handleEditMapping(integratedPage, 'new')
        } else {
            updateStep(2)
        }
    }, [integratedPage, selectedFbAccount, selectedPage]);

    const closeIntegrationCreatedModal = useCallback(() => {
        setIntegrationCreated(false);
    }, [])

    const handleCloseFbModal = () => {
        if (steps <= 3) {
            setShowCloseConfirmationModa(true);
        } else {
            showFinalConfirmaionModal();
        }
    }

    const closeConfirmationModal = () => {
        setShowCloseConfirmationModa(false);
        closeFbModal();
    }

    return (
        <>
            <a onClick={handleCloseFbModal} className='cursor-pointer close position-absolute'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#000000"></path></svg></a>
            {
                (steps === 1 ||
                    steps === 2 ||
                    steps === 3) &&
                <>
                    <figure className="d-table mx-auto mb-24">
                        <img src={`${process.env.PUBLIC_URL}/fb-import.png`} style={{ height: '78px' }} />
                    </figure>
                    <div className="modal-bradcum 100">
                        <nav aria-label="breadcrumb " className=" d-table mx-auto">
                            <ol className="breadcrumb justify-content-center d-sm-none for-mobile-brand">
                                <li className={`breadcrumb-item ${steps === 1 ? 'active' : ''}`}><a><span>1</span> </a></li>
                                <li className={`breadcrumb-item ${steps === 2 ? 'active' : ''}`}><a><span>2</span></a></li>
                                <li className={`breadcrumb-item ${steps === 3 || steps === 4 ? 'active' : ''}`}><a><span>3</span></a></li>
                            </ol>

                            <ol className="breadcrumb justify-content-center d-sm-flex d-none">
                                <li className={`breadcrumb-item ${steps === 1 ? 'active' : ''}`}><a><span>1</span> <span>Connect Account</span> </a></li>
                                <li className={`breadcrumb-item ${steps === 2 ? 'active' : ''}`}><a><span>2</span> <span>Form Mapping</span></a></li>
                                <li className={`breadcrumb-item ${steps === 3 ? 'active' : ''}`}><a><span>3</span> <span>Lead Mapping</span></a></li>
                                {/* <li className={`breadcrumb-item ${steps === 3 || steps === 4 ? 'active' : ''}`}><a><span>3</span> <span>Final Step</span> </a></li> */}
                            </ol>
                        </nav>
                    </div>
                </>
            }
            {steps === 1 &&
                <ConnectForm
                    updateStep={updateStep}
                    facebookAccountList={facebookAccountList}
                    getFbLoginUrl={getFbLoginUrl}
                    handleFbAccountChange={handleFbAccountChange}
                    selectedFbAccount={selectedFbAccount}
                    pageList={pageList}
                    handlePageChange={handlePageChange}
                    selectedPage={selectedPage}
                    formList={formList}
                    selectedForm={selectedForm}
                    handleFormChange={handleFormChange}
                    agencyDetails={agencyDetails}
                    selectedAgency={selectedAgency}
                    handleChangeAgency={handleChangeAgency}
                    handleConnectAccountNext={handleConnectAccountNext}
                    isLoading={isLoading}
                    connectPageError={connectPageError}
                />}
            {steps === 2 &&
                <FormMapping
                    updateStep={updateStep}
                    formList={formList}
                    selectedForm={selectedForm}
                    platformField={platformField}
                    handleChangeFormMapping={handleChangeFormMapping}
                    editMapping={editMapping}
                    projects={projects}
                    handleUnmappedForm={handleUnmappedForm}
                    unmappedForms={unmappedForms}
                    formMapping={formMapping}
                    handleFormMappingNext={handleFormMappingNext}
                    handlePageValidation={handlePageValidation}
                    pageMappingValidation={pageMappingValidation}
                    integrationState={integrationState}
                />}
            {steps === 3 &&
                <LeadMapping
                    updateStep={updateStep}
                    selectedForm={selectedForm}
                    platformField={platformField}
                    handleChangeMapping={handleChangeMapping}
                    leadMapping={leadMapping}
                    editMapping={editMapping}
                    facebookQuestions={facebookQuestions}
                    handleSumitMapping={handleSumitMapping}
                    updateFbIntegration={updateFbIntegration}
                    submitingIntegration={submitingIntegration}
                    integrationState={integrationState}
                />}
            {/* {steps === 3 &&
                <Final
                    updateStep={updateStep}
                    projects={projects}
                    agencyDetails={agencyDetails}
                    handleSumitMapping={handleSumitMapping}
                    handleChangeAgency={handleChangeAgency}
                    handleChangeProject={handleChangeProject}
                    selectedAgency={selectedAgency}
                    selectedProject={selectedProject}
                />} */}
            {steps === 4 && <Success updateStep={updateStep} />}
            {steps === 5 && <TestLeadResponse
                closeFbModal={showFinalConfirmaionModal}
                formList={selectedMappedFormList}
                handleTestLead={handleTestLead}
                sendTestLeadIndex={sendTestLeadIndex}
                isSendingLead={isSendingLead}
                testLeadFailed={testLeadFailed}
            />}

            {testLeadProcessModal && <TestleadProcess
                show={testLeadProcessModal}
                closeModal={closeTestLeadProcessModal}
            />}

            {integrationCreated &&
                <IntegrationCreatedModal
                    show={integrationCreated}
                    closeModal={closeIntegrationCreatedModal}
                    updateStep={updateStep}
                    showFinalConfirmaionModa={showFinalConfirmaionModal}
                />
            }

            {showCloseConfirmationModal &&
                <AreYouSureModal
                    show={showCloseConfirmationModal}
                    closeModal={() => setShowCloseConfirmationModa(false)}
                    subText={'Do you wish to cancel Integration? Changes that you have made may not have been saved.'}
                    confirmClose={() => {
                        closeConfirmationModal();
                        localStorage.removeItem('fb_steps');
                    }}
                />}

        </>
    )
}

export default FbIntegrationModal;