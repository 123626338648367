import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getUsersForSettings, saveLeadStatusAssignedToConfig } from '../../../../../services/private/company.service'
import InputSelect from '../../../../../components/InputGroup/InputSelect';
import Consumer from '../../../../../helpers/context';

const AssignedUsers = ({ show, closeModal, onSuccess, selectedItem, userUpdateFrom }) => {

    const [allUsers, setAllUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    let { allowedPermissions } = useContext(Consumer);

    const ROLE_NAME = localStorage.getItem('role');

    useEffect(() => {
        if (selectedItem?.assigned_users?.length) {
            setAssignedTo(selectedItem?.assigned_users[0].user_id)
        }
    }, [selectedItem])
    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);


    const handleSubmit = async () => {
        const payload = {
            status_id: selectedItem?.id,
            assigned_to: assignedTo
        }
        setLoading(true);
        const res = await saveLeadStatusAssignedToConfig(payload);

        if (res.data.status === 200) {
            toast.success(res.data.message);
            setLoading(false);
            onSuccess();
            closeModal();
        }
    }

    console.log(selectedItem, 'selectedItem')
    return (
        <Modal
            show={show}
            onHide={closeModal}
            className="main-filter leadsDisplayColumns"
        // backdrop='static'
        // keyboard= {false}
        >
            <Modal.Body>
                <div className="modal-conents">
                    <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">

                        <h1 className='fz24 h-font fw-regular mb-0'>Assigned User</h1>
                        {!userUpdateFrom && <div className={`form-group  position-relative mt-3`}>
                            <label className=''>Lead Name</label>
                            <div className='text-capitalize fz16 fw-po-medium'>
                                {selectedItem.name}
                            </div>

                        </div>}
                        {userUpdateFrom &&
                            <div className={`form-group  position-relative mt-3`}>
                                <div className='text-capitalize fz16 fw-po-medium'>
                                    {selectedItem?.display_name}
                                </div>
                            </div>
                        }
                        {/* <p className='fz12 ro black-dark-700 mb4'>{subHeading}</p> */}
                        <a className='close' onClick={closeModal} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725" />
                        </svg>
                        </a>
                    </div>
                    <div className='h-full overflow-auto px-3'>
                        {userUpdateFrom && userUpdateFrom === 'statusPermission' && <div className='mb-3'>
                            <InputSelect
                                label={'Assigned User'}
                                placeholder={'Select User'}
                                options={allUsers}
                                value={assignedTo ? allUsers.find(user => user.value === assignedTo) : ''}
                                onChange={(value) => {
                                    if (value) {
                                        setAssignedTo(value.value);
                                    } else {
                                        setAssignedTo(null);
                                    }
                                }}
                                isClearable={true}
                            />
                        </div>}
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="pr-btn" onClick={handleSubmit} disabled={loading} >Confirm</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AssignedUsers;