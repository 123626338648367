import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchIcon from '../../assets/icons/search.svg'
import TooltipText from '../Tooltip';


const GRID = 8;

const getListStyle = isDraggingOver => ({
    // border: isDraggingOver ? '2px dashed #E2E2EA' : '',
    // padding: GRID + 2,
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 10px 0`,

    // change background colour if dragging
    background: isDragging ? '#F1F1F5' : '',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getInnerItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? '#ffffff' : '',
    border: isDragging ? '1px solid #0062FF' : '',
    // styles we need to apply on draggables
    ...draggableStyle
});

//  Reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const CustomColumnTab = ({ activeColumn, handleChangeSelection, updateColumnList }) => {

    const { tab, columns } = activeColumn;

    const [list, setList] = useState(columns);
    const [searchVal, setSearchVal] = useState('');
    const [searchEnable, setSearchEnable] = useState(false);

    useEffect(() => {
        if (searchVal) {
            let selected = columns.selected.filter(item => item.label.toLowerCase().indexOf(searchVal.toLowerCase()) > -1)
            let notSelected = columns.notSelected.filter(item => item.label.toLowerCase().indexOf(searchVal.toLowerCase()) > -1)
            setList({
                selected,
                notSelected
            })
        } else {
            setList(columns)
        }
    }, [columns]);

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
        let selected = columns.selected.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
        let notSelected = columns.notSelected.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
        setList({
            selected,
            notSelected
        })
    }
    useEffect(() => {
        if (searchVal) {
            setSearchEnable(true);
        } else {
            setSearchEnable(false);
        }
    }, [searchVal]);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                columns.selected,
                source.index,
                destination.index
            );
            updateColumnList(tab, items);
        }
    }

    const handleChangeColumnSelection = (index, value, listItem, type) => {
        let newIndex = index
        let selectedColumn = [...columns.selected];
        let notSelected = [...columns.notSelected];
        if (type === 'selectedList') {
            newIndex = selectedColumn.findIndex(item => item.db_value === listItem.db_value)
        }
        if (type === 'notSelected') {
            newIndex = notSelected.findIndex(item => item.db_value === listItem.db_value)
        }

        if (type === 'notSelected') {
            notSelected[newIndex].is_selected = value;
            const selected = notSelected.splice(newIndex, 1);
            selectedColumn.push(selected[0])
        }
        if (type === 'selectedList') {
            selectedColumn[newIndex].is_selected = value;
            const selected = selectedColumn.splice(newIndex, 1);
            notSelected.unshift(selected[0])
        }

        handleChangeSelection(tab, selectedColumn, notSelected)
    }

    const FirstItem = ({ item, index }) => (
        <Droppable droppableId={'static'} index={index} isDragDisabled={true}>
            {(provided) => (
                <TooltipText title={'This can’t be move'}>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`item_${index + 1} d-flex align-items-center`}
                    >
                        <div className='d-flex'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="6.5" cy="4" r="1.5" fill="#92929D" />
                                <circle cx="12.5" cy="4" r="1.5" fill="#92929D" />
                                <circle cx="6.5" cy="10" r="1.5" fill="#92929D" />
                                <circle cx="12.5" cy="10" r="1.5" fill="#92929D" />
                                <circle cx="6.5" cy="16" r="1.5" fill="#92929D" />
                                <circle cx="12.5" cy="16" r="1.5" fill="#92929D" />
                            </svg>
                        </div>
                        <div className='item d-flex align-items-center justify-content-between'
                        >

                            <div className='item-box d-flex justify-content-between w-100'>
                                <div className='name-index'>
                                    <div className='item-label' style={{ color: '#92929D' }}>{`${item.label}`}</div>
                                </div>

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g opacity="0.5">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5 2H15C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2Z" fill="#0062FF" />
                                            <path d="M7.36072 9.2801C6.96767 8.89211 6.33452 8.89621 5.94653 9.28925C5.55855 9.6823 5.56265 10.3155 5.95569 10.7034L8.49665 13.2117C8.91467 13.6243 9.59664 13.5895 9.97048 13.1365L14.0962 8.13648C14.4477 7.71049 14.3873 7.08021 13.9613 6.72871C13.5353 6.37721 12.9051 6.43759 12.5536 6.86358L9.12364 11.0203L7.36072 9.2801Z" fill="white" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </TooltipText>
            )}
        </Droppable>
    );

    return (
        <>
            <div
                className={`form-group position-relative search-input ${searchVal ? 'input-active' : ''}`}
            >
                <div className="serch-icon">
                    <Image src={SearchIcon} />
                </div>
                <input
                    type="text"
                    placeholder="Search columns"
                    className='w-100 searchinput'
                    value={searchVal}
                    onChange={handleSearch}
                />
                {searchVal && <div className="close-icon" onClick={() => setSearchVal('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974"></path>
                    </svg>
                </div>}
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className='itms-body'>
                    <Droppable droppableId="activeList" >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className="dragable"
                            >
                                {/* list alternate on search enable disable  */}
                                {[searchEnable ? list?.selected : columns?.selected][0].map((item, index) => (
                                    <React.Fragment key={item.db_value}>
                                        {index === 0 && !searchEnable ? (
                                            <FirstItem item={item} index={index} />
                                        ) : (
                                            <Draggable
                                                key={item.db_value}
                                                draggableId={item.db_value}
                                                index={index}
                                                isDragDisabled={searchEnable}
                                            >
                                                {(provided, snapshot) => (
                                                    <div className={`item_${index + 1} d-flex align-items-center`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className='d-flex'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <circle cx="6.5" cy="4" r="1.5" fill="#92929D" />
                                                                <circle cx="12.5" cy="4" r="1.5" fill="#92929D" />
                                                                <circle cx="6.5" cy="10" r="1.5" fill="#92929D" />
                                                                <circle cx="12.5" cy="10" r="1.5" fill="#92929D" />
                                                                <circle cx="6.5" cy="16" r="1.5" fill="#92929D" />
                                                                <circle cx="12.5" cy="16" r="1.5" fill="#92929D" />
                                                            </svg>
                                                        </div>
                                                        <div className='item d-flex align-items-center justify-content-between'
                                                            style={getInnerItemStyle(
                                                                snapshot.isDragging
                                                            )}>
                                                            {item.is_locked &&
                                                                <div className='item-box d-flex justify-content-between w-100'>
                                                                    <div className='name-index'>
                                                                        <div className='item-label' style={{ color: '#92929D' }}>{`${item.label}`}</div>
                                                                    </div>
                                                                    <TooltipText title="This can't be change" placement='left'>
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <g opacity="0.5">
                                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 2H15C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2Z" fill="#0062FF" />
                                                                                    <path d="M7.36072 9.2801C6.96767 8.89211 6.33452 8.89621 5.94653 9.28925C5.55855 9.6823 5.56265 10.3155 5.95569 10.7034L8.49665 13.2117C8.91467 13.6243 9.59664 13.5895 9.97048 13.1365L14.0962 8.13648C14.4477 7.71049 14.3873 7.08021 13.9613 6.72871C13.5353 6.37721 12.9051 6.43759 12.5536 6.86358L9.12364 11.0203L7.36072 9.2801Z" fill="white" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </TooltipText>
                                                                </div>
                                                            }
                                                            {!item.is_locked && <label className='item-box d-flex justify-content-between w-100'>
                                                                <div className='item-label'>{`${item.label}`}</div>
                                                                <div> <input
                                                                    type="checkbox"
                                                                    value={item.is_selected}
                                                                    checked={item.is_selected}
                                                                    onChange={() => handleChangeColumnSelection(index, !item.is_selected, item, 'selectedList')}
                                                                /></div>
                                                            </label>}
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </Draggable >
                                        )
                                        }
                                    </React.Fragment>
                                ))}
                                {provided.placeholder}
                            </div >
                        )}
                    </Droppable>
                    {list?.notSelected?.map((item, index) => (
                        <div
                            className={`item_${columns.selected.length + index + 1} d-flex align-items-center`}
                            key={item.db_value}
                        >
                            <div style={{ opacity: 0 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7 12C6.46957 12 5.96086 11.7893 5.58579 11.4142C5.21071 11.0391 5 10.5304 5 10C5 9.46957 5.21071 8.96086 5.58579 8.58579C5.96086 8.21071 6.46957 8 7 8C7.53043 8 8.03914 8.21071 8.41421 8.58579C8.78929 8.96086 9 9.46957 9 10C9 10.5304 8.78929 11.0391 8.41421 11.4142C8.03914 11.7893 7.53043 12 7 12ZM7 6C6.46957 6 5.96086 5.78929 5.58579 5.41421C5.21071 5.03914 5 4.53043 5 4C5 3.46957 5.21071 2.96086 5.58579 2.58579C5.96086 2.21071 6.46957 2 7 2C7.53043 2 8.03914 2.21071 8.41421 2.58579C8.78929 2.96086 9 3.46957 9 4C9 4.53043 8.78929 5.03914 8.41421 5.41421C8.03914 5.78929 7.53043 6 7 6ZM7 18C6.46957 18 5.96086 17.7893 5.58579 17.4142C5.21071 17.0391 5 16.5304 5 16C5 15.4696 5.21071 14.9609 5.58579 14.5858C5.96086 14.2107 6.46957 14 7 14C7.53043 14 8.03914 14.2107 8.41421 14.5858C8.78929 14.9609 9 15.4696 9 16C9 16.5304 8.78929 17.0391 8.41421 17.4142C8.03914 17.7893 7.53043 18 7 18Z" fill="#92929D" stroke="white" />
                                    <path d="M13 12C12.4696 12 11.9609 11.7893 11.5858 11.4142C11.2107 11.0391 11 10.5304 11 10C11 9.46957 11.2107 8.96086 11.5858 8.58579C11.9609 8.21071 12.4696 8 13 8C13.5304 8 14.0391 8.21071 14.4142 8.58579C14.7893 8.96086 15 9.46957 15 10C15 10.5304 14.7893 11.0391 14.4142 11.4142C14.0391 11.7893 13.5304 12 13 12ZM13 6C12.4696 6 11.9609 5.78929 11.5858 5.41421C11.2107 5.03914 11 4.53043 11 4C11 3.46957 11.2107 2.96086 11.5858 2.58579C11.9609 2.21071 12.4696 2 13 2C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4C15 4.53043 14.7893 5.03914 14.4142 5.41421C14.0391 5.78929 13.5304 6 13 6ZM13 18C12.4696 18 11.9609 17.7893 11.5858 17.4142C11.2107 17.0391 11 16.5304 11 16C11 15.4696 11.2107 14.9609 11.5858 14.5858C11.9609 14.2107 12.4696 14 13 14C13.5304 14 14.0391 14.2107 14.4142 14.5858C14.7893 14.9609 15 15.4696 15 16C15 16.5304 14.7893 17.0391 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18Z" fill="#92929D" stroke="white" />
                                </svg>
                            </div>
                            <label className='item item-box d-flex justify-content-between w-100'>
                                <div className='item-label'>{`${item.label}`}</div>
                                <div> <input
                                    type="checkbox"
                                    value={item.is_selected}
                                    checked={item.is_selected}
                                    onChange={() => handleChangeColumnSelection(index, !item.is_selected, item, 'notSelected')}
                                /></div>
                            </label>
                        </div>
                    ))}
                </div >
                {list.selected.length == 0 && list.notSelected.length == 0 &&

                    <div class="d-flex justify-content-center items-center flex-column text-center h-[calc(100%-100px)]">
                        <figure>
                            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none">
                                <circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg>
                        </figure>
                        <h2 class="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                        {/* <p class="text-center black-dark-600 fz14 mb-0"> Please try again</p> */}
                    </div>}

            </DragDropContext >


        </>
    )
}

export default CustomColumnTab