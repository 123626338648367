import React, { useEffect, useCallback, useState } from 'react'
import moment from 'moment';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import {
    getAllProjects,
    getUsersForLeadIntegration,
    markRoutingLeadActive,
    markRoutingLeadInactive,
    routingUpdateProject,
    markRoutingDisable,
    markRoutingEnable
} from '../../../../services/private/company.service';
import './LeadIntegration.css'
import { toast } from 'react-toastify';
import searchIcon from '../../../../assets/icons/search.svg'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getInitials } from '../../../../helpers/helpers';
import TooltipText from '../../../../components/Tooltip';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',

        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },

        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    multiValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '160px',
    }),

    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
        color: state.isSelected ? '#44444F' : '#44444F',
        fontWeight: state.isSelected ? '500' : '',
    }),
};



function LeadIntegrations() {

    const [projectList, setProjectList] = useState();
    const [userList, setUserList] = useState();
    const [selectedProject, setSelectedProject] = useState();
    const [searchText, setSearchText] = useState('');
    const [enableRouting, setEnableRouting] = useState(false);
    const [apiResponse, setApiResponse] = useState(false);
    const [allProjectIds, setAllProjectIds] = useState([]);

    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                const ids = res.data.data.map(item => item.id)
                setAllProjectIds(ids)
                setProjectList(projects);
            }
        })
    }

    const getUsers = () => {
        getUsersForLeadIntegration(searchText).then(res => {
            setApiResponse(true);
            if (res.status === 200) {
                setEnableRouting(res.data.data.isRouting);
                let routingUsers = res.data.data.config
                routingUsers = routingUsers.map(item => (
                    { ...item, hiddenProject: item.project_id.filter((id) => !allProjectIds.includes(id)) || [] }
                ))
                setUserList(routingUsers);
            }
        }).catch((err) => {
            console.log(err.response.data.message);
            setApiResponse(true);
        });
    }

    const handleSelectedProject = (projectIds) => {
        const projList = projectIds.map((id) => projectList?.find((proj) => proj.value === id))
        return projList;
    }

    useEffect(() => {
        getAllProjectsByCompanyId();
    }, [])

    useEffect(() => {
        if (projectList?.length > 0)
            getUsers();
    }, [projectList])

    useEffect(() => {
        if (!searchText)
            getUsers();
    }, [searchText])

    const handleRouting = () => {
        if (enableRouting) {
            markRoutingDisable().then((res) => {
                if (res.status === 200) {
                    getUsers();
                    toast.success(res.data.message)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        } else {
            markRoutingEnable().then((res) => {
                if (res.status === 200) {
                    getUsers();
                    toast.success(res.data.message)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }

    const handleAssignLead = (leadAssign, uuid) => {
        if (leadAssign) {
            markRoutingLeadInactive(uuid).then((res) => {
                if (res.status === 200) {
                    getUsers()
                    toast.success(res.data.message)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        } else {
            markRoutingLeadActive(uuid).then((res) => {
                if (res.status === 200) {
                    getUsers()
                    toast.success(res.data.message)
                }
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }

    const handleProjectOnBlur = (user) => {
        if (!selectedProject) {
            return
        }
        let projectId = selectedProject[user.uuid].map(project => project.value);
        if (user?.hiddenProject?.length > 0) {
            projectId = [...projectId, ...user.hiddenProject]
        }

        routingUpdateProject(user.uuid, projectId).then((res) => {
            if (res.status === 200) {
                getUsers()
                toast.success(res.data.message)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
        if (e.target.value.length >= 3) {
            getUsers();
        }
    }

    const handelCLick = () => {
        setSearchText("");
    }

    if (!projectList) {
        return null;
    }
    return (
        <>
            <div className="leadassignment w-100 flex flex-col h-full">
                <div className="settings-title assignment-title">
                    <div className="row d-flex d-flex flex-fill">
                        <div className="col-lg-7 d-flex flex-fill flex-column">
                            <div className='d-flex'>
                                <h1 className="fz24 mb-16 black fw-po-medium mr-2">Auto Lead Assignment</h1>
                                {/* {apiResponse && <button
                                    type="button"
                                    className={`btn btn-lg btn-toggle ${enableRouting ? 'active' : ''} mr-2`}
                                    data-toggle="button"
                                    aria-pressed='true'
                                    autoComplete="off"
                                    value={enableRouting}
                                    onClick={handleRouting}
                                >
                                    <div className="handle"></div>
                                    <span className={`${enableRouting ? 'on' : 'off'}`}>{enableRouting ? 'ON' : 'OFF'}</span>
                                </button>} */}
                                {apiResponse && <div>
                                    <div className='border rounded-md w-40 '>
                                        <button
                                            type='button'
                                            className={`text-center w-1/2 py-2 rounded-l-md ${!enableRouting ? 'text-primary font-semibold bg-blueHighlight' : 'text-black700'}`}
                                            onClick={handleRouting}

                                        >Manual</button>
                                        <button
                                            type='button'
                                            className={`text-center w-1/2 py-2 rounded-r-md ${enableRouting ? 'text-primary font-semibold bg-blueHighlight' : 'text-black700'}`}
                                            onClick={handleRouting}

                                        >Auto</button>
                                    </div>
                                </div>}
                            </div>
                            <p className="fz16 black-dark-700 mb-0">Equal leads distribution to users for selected projects</p>
                        </div>

                        <div className="col-lg-5 d-flex justify-content-md-end align-items-end d-flex flex-fill">
                            <div className="form-inline lead-title">
                                <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                    <div className="serch-icon">
                                        <img src={searchIcon} />
                                    </div>
                                    <input type="text" placeholder="Search user" className="searchinput" onChange={handleSearchChange} value={searchText} />
                                    {searchText &&
                                        <div className="close-icon" onClick={handelCLick} style={{ top: '12px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                            </svg>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lead-assignment_table ml-24 mt-24 !h-full overflow-auto">
                    <div className="def-table pr-24 !h-full">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>User Name</th>
                                    <th>Assign Leads</th>
                                    <th>Project</th>
                                    <th>Lead Count</th>
                                    <th>Last Lead Received</th>

                                </tr >
                            </thead >
                            <tbody>
                                {userList?.length > 0 ? userList.map((user, index) => {
                                    return (
                                        <tr key={user.uuid}>
                                            <td>{index + 1}</td>
                                            <td className='text-capitalize'>
                                                {user.name.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{user.name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>
                                                            {user.role_name && <span className="!border-0 p-1 rounded-md text-sm !bg-primary font-medium text-white w-8 h-8 inline-flex items-center justify-center mr-2.5">
                                                                {getInitials(user.role_name || '')}
                                                            </span>}
                                                            {user.name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize'>
                                                        {user.role_name &&
                                                            <TooltipText title={user.role_name}>
                                                                <span className="!border-0 p-1 rounded-md text-sm !bg-primary font-medium text-white w-8 h-8 inline-flex items-center justify-center mr-2.5">
                                                                    {getInitials(user.role_name || '')}
                                                                </span>
                                                            </TooltipText>
                                                        }
                                                        {user.name}
                                                    </span>
                                                }

                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className={`btn btn-lg btn-toggle ${user.is_allowed ? 'active' : ''} ${!enableRouting ? 'btn-deactive' : ''} mr-2`}
                                                    data-toggle="button"
                                                    aria-pressed={user.is_allowed}
                                                    autoComplete="off"
                                                    value={user.is_allowed}
                                                    onClick={() => handleAssignLead(user.is_allowed, user.uuid)}
                                                    disabled={!enableRouting}
                                                >
                                                    <div className="handle"></div>
                                                    <span className={`${user.is_allowed ? 'on' : 'off'} ${!enableRouting ? 'btn-deactive' : ''}`}>{user.is_allowed ? 'ON' : 'OFF'}</span>
                                                </button>

                                            </td>
                                            <td>
                                                <InputSelect
                                                    index={4}
                                                    name="Project Name"
                                                    isMulti={true}
                                                    value={selectedProject?.[user.uuid] || handleSelectedProject(user.project_id)}
                                                    options={projectList}
                                                    placeholder="Select Project"
                                                    onChange={(e) => {
                                                        setSelectedProject({ ...selectedProject, [user.uuid]: e })
                                                    }}
                                                    onBlur={() => handleProjectOnBlur(user)}
                                                    styles={customStyles}
                                                />
                                            </td>
                                            <td>{user.lead_count} </td>
                                            <td>{user.last_lead_received ? moment(user.last_lead_received).format('h:mm a, Do MMM YYYY') : '-'}</td>
                                        </tr>)
                                }) :
                                    <><tr className=''>
                                        <td colSpan={9}>
                                            <div className='d-flex justify-content-center flex-column text-center lead-pagination'>
                                                <div className='flex items-center flex-col'>

                                                    <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize"> No Users Found.</h2>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </>
                                }
                            </tbody>
                        </table >
                    </div >
                </div >
            </div >

        </>
    )
}

export default LeadIntegrations;