import React from 'react'
import { Link } from 'react-router-dom';
import searchIcon from "../../../../../assets/icons/search.svg";
import { Image, Nav } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown'
import RecordTable from './RecordTable';

const UserAccount = () => {

    return (
        <>
            <div className="w-100">
                <div className="lead-title mb-16">
                    <div className="row">
                        <div className="col-md-5">
                            <h1 className='fz24 black mb-8 fw-po-medium ls5'>
                                <Link to='/settings/integrations/all-integrations/facebook-acocunts' className='pr-hover'>
                                    <svg className='mr-8' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9949 9.34939 20.9397 6.80881 19.0655 4.93455C17.1912 3.06028 14.6506 2.00508 12 2ZM12 20.4615C10.3265 20.4615 8.69052 19.9653 7.29902 19.0355C5.90753 18.1057 4.823 16.7842 4.18256 15.2381C3.54213 13.6919 3.37456 11.9906 3.70105 10.3492C4.02754 8.70786 4.83343 7.20016 6.01679 6.01679C7.20016 4.83342 8.70786 4.02754 10.3492 3.70105C11.9906 3.37456 13.6919 3.54212 15.2381 4.18256C16.7842 4.82299 18.1057 5.90753 19.0355 7.29902C19.9653 8.69051 20.4615 10.3265 20.4615 12C20.459 14.2434 19.5667 16.3941 17.9804 17.9804C16.3941 19.5667 14.2434 20.459 12 20.4615ZM16.6154 12C16.6154 12.204 16.5343 12.3997 16.3901 12.5439C16.2458 12.6882 16.0502 12.7692 15.8462 12.7692H10.0096L11.9615 14.7212C12.1054 14.8654 12.1862 15.0607 12.1862 15.2644C12.1862 15.4681 12.1054 15.6635 11.9615 15.8077C11.8899 15.8798 11.8044 15.9365 11.7102 15.9745C11.616 16.0125 11.515 16.031 11.4135 16.0288C11.3133 16.0308 11.2139 16.0122 11.1213 15.9742C11.0286 15.9362 10.9448 15.8795 10.875 15.8077L7.60577 12.5481C7.46133 12.4022 7.38029 12.2053 7.38029 12C7.38029 11.7947 7.46133 11.5978 7.60577 11.4519L10.875 8.19231C11.0213 8.05972 11.213 7.98849 11.4104 7.99335C11.6078 7.99821 11.7958 8.07879 11.9354 8.21842C12.0751 8.35804 12.1556 8.54601 12.1605 8.74341C12.1654 8.94081 12.0941 9.13252 11.9615 9.27884L10.0096 11.2308H15.8462C16.0502 11.2308 16.2458 11.3118 16.3901 11.4561C16.5343 11.6003 16.6154 11.796 16.6154 12Z" fill="#44444F" />
                                    </svg>
                                </Link>
                                User Name
                            </h1>
                            <p className='fz16 black-dark-700 ls5 ml-28 mb-0'>Connect Facebook to get leads</p>

                        </div>
                        <div className="col-md-7 d-flex justify-content-end aligin-item-end">
                            <div className="d-flex flex-md-row align-items-md-center justify-content-md-end flex-md-nowrap flex-wrap flex-column mr-md-3">
                                <div className="serch-and-filter-blcok d-flex">
                                    <div className="form-inline mr-12 ">
                                        <div className="form-group position-relative search-input  ">
                                            <div className="serch-icon">
                                                <Image src={searchIcon} />
                                            </div>
                                            <input type="text" placeholder="Search" className="searchinput" value="" />
                                            <div className="close-icon" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mr-12">
                                        <Dropdown className={"short-btn"} >
                                            <Dropdown.Toggle>
                                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 6.00012H13" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 12.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 18.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15 15.0001L18 18.0001L21 15.0001" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18 6.00012V18.0001" stroke="#696974" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                <span className="text-capitalize">Sort</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="filter-heading d-flex align-items-center justify-content-between mb-12">
                                                    <h3 className="fz14 black mb-0"> Sort by</h3>
                                                    <button type="button" className="close float-right" aria-label="Close">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#000">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="form-group">

                                                </div>

                                                <div className="mt-12 a-z-short">
                                                    <button className="btnAsc active" title="Ascending">
                                                        Ascending
                                                    </button>
                                                    <button className="btnDes" title='Descending'>
                                                        Descending
                                                    </button>
                                                </div>
                                                <div className="remove mt-22">
                                                    <a className="d-flex align-items-center fz14  ml-auto justify-content-end black-dark-700">
                                                        <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.10904 3.13331L5.55709 1.78916C5.64783 1.51693 5.90259 1.33331 6.18954 1.33331H9.78954C10.0765 1.33331 10.3313 1.51693 10.422 1.78916L10.87 3.13331H13.9895C14.3577 3.13331 14.6562 3.43179 14.6562 3.79998C14.6562 4.16817 14.3577 4.46665 13.9895 4.46665H13.4181L12.9289 12.7841C12.8667 13.8412 11.9913 14.6666 10.9323 14.6666H5.04675C3.9878 14.6666 3.11238 13.8412 3.0502 12.7841L2.56094 4.46665H2.00016C1.63197 4.46665 1.3335 4.16817 1.3335 3.79998C1.3335 3.43179 1.63197 3.13331 2.00016 3.13331H5.10904ZM6.51449 3.13331H9.46459L9.30904 2.66665H6.67005L6.51449 3.13331ZM12.0825 4.46665H3.89658L4.38123 12.7058C4.40196 13.0582 4.69377 13.3333 5.04675 13.3333H10.9323C11.2853 13.3333 11.5771 13.0582 11.5979 12.7058L12.0825 4.46665ZM9.12417 6.15839C9.14714 5.79092 9.46366 5.51164 9.83113 5.53461C10.1986 5.55758 10.4779 5.87409 10.4549 6.24157L10.1549 11.0416C10.1319 11.409 9.81543 11.6883 9.44796 11.6653C9.08048 11.6424 8.80121 11.3259 8.82417 10.9584L9.12417 6.15839ZM7.15491 10.9584C7.17788 11.3259 6.8986 11.6424 6.53113 11.6653C6.16366 11.6883 5.84714 11.409 5.82417 11.0416L5.52417 6.24157C5.50121 5.87409 5.78048 5.55758 6.14796 5.53461C6.51543 5.51164 6.83194 5.79092 6.85491 6.15839L7.15491 10.9584Z" fill="#696974" />
                                                        </svg> Clear Sort
                                                    </a>
                                                </div>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="filter-btn mr-12">
                                        <button className="border-0 pr-hover " type="button">
                                            <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                            <span className="text-capitalize">Filter</span>

                                        </button>
                                    </div>
                                    {/* <div className="dropdown view-more ">
                                        <button className="bg-tr border-0 " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#696974" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#696974" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#696974" />
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link className="dropdown-item" to='/settings/integrations/all-integrations/facebook-accounts' >Manage account</Link>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecordTable />
            </div>
        </>
    )
}

export default UserAccount; 