import SmallCenterModal from "./small-center-modal";
import React from "react";
import { useHistory } from "react-router";


const IntegrationCreatedModal = ({ show, closeModal, updateStep, heading }) => {
    const history = useHistory();
    return (
        <div>
            <SmallCenterModal show={show} closeModal={closeModal} dialogClassName={'unprocessed-leads'}>
                <div className=''>
                    <figure className='mb-20'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                            <rect x="4" y="4" width="64" height="64" rx="32" fill="#DBFCB9" />
                            <circle cx="36" cy="36" r="15" stroke="#6FB328" strokeWidth="2" />
                            <path d="M31.6754 35.3687C31.0577 34.759 30.0628 34.7655 29.4531 35.3831C28.8434 36.0007 28.8498 36.9957 29.4675 37.6054L33.4604 41.5469C34.1173 42.1953 35.1889 42.1406 35.7764 41.4287L42.2596 33.5716C42.812 32.9022 42.7171 31.9118 42.0477 31.3594C41.3783 30.807 40.3878 30.9019 39.8355 31.5713L34.4457 38.1033L31.6754 35.3687Z" fill="#6FB328" />
                            <rect x="4" y="4" width="64" height="64" rx="32" stroke="#EBFED7" strokeWidth="8" />
                        </svg>
                    </figure>
                    <h1 className='fz28 fw-po-medium black mb-12 po'>{heading}</h1>
                    <p className="black-dark-700 fz16 ls2 ro mb-0"><span className="black-dark-800 fw-po-medium">Test your Facebook Integration.</span> You can send test Leads from all your mapped forms to the Propacity platform.</p>

                    <div className='d-flex justify-content-end mt-24'>
                        <button className='px-24 py-6 fw-po-medium outline-btn d-table fz16 w-50' onClick={() => {
                            closeModal();
                            history.push('/settings/integrations/all-integrations/facebook-forms')
                        }} >Skip</button>
                        <button className='pr-btn px-24 py-6 fw-po-medium d-table ml-20 fz16 w-50' onClick={() => {
                            closeModal();
                            updateStep(4);
                        }} >Send Test Lead</button>
                    </div>
                </div>
            </ SmallCenterModal >
        </div >
    )
}

export default IntegrationCreatedModal
