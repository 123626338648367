import React, { useState, useCallback } from 'react'
import InputSelect from '../InputGroup/InputSelect'
import Secure from '../Secure'
import SearchIcon from './SearchIcon'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import inputSearchIcon from '../../assets/icons/search.svg';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getFormMappingRouting, insertCompanyAgency } from '../../services/private/company.service'
import FormMapingRulesModal from '../Modals/FormMapingRulesModal'
import { Oval } from 'react-loader-spinner'
import FacebookTeamRoutingBulkShare from '../Modals/FacebookTeamRoutingBulkShare'

const FormMapping = ({
    updateStep,
    handleChangeFormMapping,
    editMapping,
    formList,
    projects,
    handleUnmappedForm,
    unmappedForms,
    formMapping,
    handlePageValidation,
    pageMappingValidation,
    integrationState,
    agencyDetails,
    handleChangeAgency,
    getAgencyData,
    formMappingCount,
    formMappingPrevious,
    showInfoModal,
    handleCloseInfoModal,
    allTeams,
    handleChangeTeamRouting
}) => {
    const [isActive, setIsActive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedFormList, setSearchedFormList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showTeamRouting, setShowTeamRouting] = useState(false);
    const [selectedForm, setSelectedForm] = useState();
    const [formRouting, setFormRouting] = useState([]);
    const [formLocalRouting, setFormLocalRouting] = useState([]);

    function handleClick() {
        setIsActive(true);
    }

    useEffect(() => {
        const result = formList.filter(item => item.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        setSearchedFormList(result);
    }, [searchText, formList]);

    const handelClearSearch = useCallback(() => {
        setSearchText('')
        setIsActive(false);
    }, []);

    const handleCreate = async (inputValue, form) => {
        setIsLoading(true);
        const res = await insertCompanyAgency({ name: inputValue });
        setIsLoading(false);
        if (res.data.status === 200) {
            getAgencyData();
            handleChangeAgency(form, { label: res.data.data.name, value: res.data.data.id })
            toast.success('Marketing agency created.')
        }
    }

    const getFormRouting = async (uuid) => {
        const res = await getFormMappingRouting(uuid);
        if (res.data.status === 200) {
            setFormRouting(res.data.data);
            setShowTeamRouting(true)
        }
    }

    const handleSetTeamRouting = (selectedTeam, teamName) => {
        handleChangeTeamRouting(selectedForm, selectedTeam, teamName)
    }

    return (
        <>
            <div className="blankdiv"></div>
            <div className='pl-24 h-full overflow-hidden'>
                <div className='scroll-div !pb-5'>
                    <div className="formmapingmain">
                        <div className="form-maping-left">
                            <div className="lead-maping">
                                <div className="maphead">
                                    <div className="s_no">S.No</div>
                                    <div className="facebook_forms">
                                        {!isActive ? <div className='inline-flex items-center'>
                                            Facebook Forms
                                            ({`${formMappingCount}/${formList.length}`})
                                            <button className='bg-tr border-0 p-0' onClick={handleClick}><SearchIcon /></button>
                                        </div>
                                            :
                                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                                <div className="serch-icon">
                                                    <img src={inputSearchIcon} className="" /></div>
                                                <input type="text" placeholder="Search Form" onChange={(e) => setSearchText(e.target.value)} className="searchinput w-100" value={searchText} />
                                                <div className="close-icon" onClick={handelClearSearch}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                    </svg>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="platform_projects">Platform Projects
                                        {/* {pageMappingValidation && <div className="invalid">
                                            <p className='input-feedback mb-0 fz12'>Please map atleast one form to project! </p>
                                        </div>} */}
                                    </div>
                                    {agencyDetails.length > 0 && <div className="marketing_agency">Marketing Agency</div>}
                                    <div className="marketing_agency">Teams</div>
                                </div>
                                {/* name, id, label selectedProjectId*/}
                                {searchedFormList.length > 0 ? <div className="map-fileds">
                                    {
                                        searchedFormList && searchedFormList.map((form, index) => {
                                            return (
                                                <div className="forminpuline" key={`${form.key}_${index}`}>
                                                    <div className="s_no d-lg-inline-flex d-none">
                                                        <span className='d-lg-none'>Form</span> {index + 1}
                                                    </div>
                                                    <div className="facebook_forms">
                                                        <span className='d-lg-none'>Form {index + 1} : </span>
                                                        {form.label.length > 50 ?
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            <p className=''>{form.label}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='virticledots cursor-pointer'>{form.label}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <span className=''>{form.label}</span>
                                                        }
                                                    </div>
                                                    <div className={`${pageMappingValidation ? 'invalid' : ''} platform_projects`}>
                                                        <InputSelect
                                                            menuPlacement={'auto'}
                                                            name='Facebook Accounts'
                                                            minMenuHeight={120}
                                                            options={projects}
                                                            value={projects.find(project => project.value === form.projectId)}
                                                            onChange={(value) => handleChangeFormMapping(value, form, index)}
                                                            placeholder='Select Project'
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                    {agencyDetails.length > 0 && <div className="marketing_agency">
                                                        <CreatableSelect
                                                            isClearable
                                                            menuPlacement='auto'
                                                            maxMenuHeight={150}
                                                            name='Marketing Agency'
                                                            options={agencyDetails}
                                                            onCreateOption={(value) => handleCreate(value, form)}
                                                            value={form.marketingAgency ? agencyDetails.find(agency => agency.value === form.marketingAgency) : ''}
                                                            onChange={(value) => handleChangeAgency(form, value)}
                                                            isDisabled={!projects.find(project => project.value === form.projectId)}
                                                            placeholder='Select Agency'
                                                        />
                                                    </div>}
                                                    <div
                                                        className={`max-w-[200px] grow-0 shrink-0 basis-[200px] border p-2 rounded ${!projects.find(project => project.value === form.projectId) ? 'bg-black200 cursor-not-allowed' : ''}`}
                                                    // disabled={!projects.find(project => project.value === form.projectId)}
                                                    >
                                                        {form?.team_names?.length > 0 ?
                                                            <button onClick={() => {
                                                                setSelectedForm(form);
                                                                if (form?.routing_id) {
                                                                    getFormRouting(form.formMappingUUID)
                                                                } else {
                                                                    setFormLocalRouting(form.routing);
                                                                    setShowTeamRouting(true)
                                                                }
                                                            }}
                                                                disabled={!projects.find(project => project.value === form.projectId)}
                                                            >
                                                                {form.team_names.join(', ')}
                                                            </button>
                                                            : <button
                                                                className='disabled:text-black disabled:opacity-70 disabled:bg-black200'
                                                                onClick={() => {
                                                                    setSelectedForm(form);
                                                                    setShowTeamRouting(true)
                                                                }}
                                                                disabled={!projects.find(project => project.value === form.projectId)}
                                                            >Add Team</button>}
                                                        {/* <InputSelect
                                                            menuPlacement={'auto'}
                                                            name='Facebook Accounts'
                                                            minMenuHeight={120}
                                                            options={allTeams}
                                                            placeholder='Select Team'
                                                            isClearable={true}
                                                            isMulti={true}
                                                        /> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                    :
                                    // <div className="d-flex justify-content-center flex-column text-center align-items-center fb-integrationnofound">
                                    //     <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                    //     <h2 className="po black fz16 fw-po-mediumm mb-8">No Facebook Forms found{" "}</h2>
                                    // </div>
                                    <div className="d-flex justify-content-center flex-column text-center align-items-center fb-integrationnofound">
                                        <Oval
                                            height={60}
                                            width={60}
                                            color="#0062ff"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#0062ff"
                                            strokeWidth={4}
                                            strokeWidthSecondary={4}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="unmapedform">
                            <div className='receive-unmapped-forms'>
                                <h2 className='ro fz14 fw-po-medium black mb-6 ro ls5'>Capture unmapped form leads</h2>
                                <p className='ro fz14 mb-0 black-dark-700 ro ls5'>You will receive leads from Facebook forms that are not mapped to any project.</p>
                                <button
                                    type="button"
                                    className={`btn btn-lg btn-toggle ${unmappedForms ? 'active' : ''} mr-0 d-table ml-auto mt-16`}
                                    data-toggle="button"
                                    aria-pressed="true"
                                    autoComplete="off"
                                    onClick={(e) => handleUnmappedForm(e, !unmappedForms)}
                                >
                                    <div className="handle"></div>
                                    <span className="on ">{unmappedForms ? 'ON' : 'OFF'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="footer-modal d-flex align-items-center justify-content-end">
                <Secure />
                {(!editMapping || integrationState === 'new') && <button className="outline-btn fz16 py-6  ml-auto d-table" onClick={formMappingPrevious}>Previous</button>}
                < button className="pr-btn fz16 py-6  mr-20 d-table" onClick={() => {
                    handlePageValidation()
                }}>Next</button>
            </div >

            {showInfoModal && < FormMapingRulesModal show={showInfoModal} closeModal={handleCloseInfoModal} />}
            {showTeamRouting &&
                <FacebookTeamRoutingBulkShare
                    show={showTeamRouting}
                    closeModal={() => {
                        setShowTeamRouting(false);
                        setFormRouting([]);
                        setFormLocalRouting([]);
                    }}
                    handleSetTeamRouting={handleSetTeamRouting}
                    formRouting={formRouting}
                    allTeams={allTeams}
                    formLocalRouting={formLocalRouting}
                />}
        </>
    )
}

export default FormMapping