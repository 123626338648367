import React from 'react';

export default function ConfirmMailSend({
    email
}) {
    return (
        <>
          <div className="forget-pass-msg">
            <p className="fz18 text-center black po fw-regular ls-2">Check your email {email} for reset instructions</p>


            <p className='fz14 text-center ls-2 black-dark-700 ro mt-12'>
                Didn’t get the email? Check your spam folder or <a href="#" className="pr-color">resend</a>.
            </p>
            </div>
        </>
    )
}