import React from 'react';
import AsyncSelect from 'react-select/async';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import InputText from '../InputGroup/InputText';
import LocationSearchInput from '../InputGroup/AddressField1';
import { addProjectSchema } from '../../validators/leads.validator';
import { Formik } from 'formik';
import InputSelect from '../InputGroup/InputSelect';
import { addProject, editProject, getProjectAddress } from '../../services/private/company.service';
import { getCitiesFromState } from '../../services/public/public.service'

// working
export default function AddProjectModal({
    show,
    setShow,
    selectedProject,
    getAllProjects,
    // cities
}) {

    const addProjectHandle = async (payload) => {
        await addProject(payload).then(res => {
            if (res.status === 200) {
                toast.success(`Project ${payload.name} is added`)
                setShow(false)
                getAllProjects()
            }
        }).catch(e => {
            toast.error(e.response.data.message)
            console.error(e.response.data)
        })
    }

    const editProjectHandle = async (payload) => {
        await editProject(payload).then(res => {
            if (res.status === 200) {
                toast.success(` ${selectedProject.name} info is updated`)
                setShow(false)
                getAllProjects()
            }
        }).catch(e => {
            toast.error(e.response.data.message)
            console.error(e.response.data)
        })
    }

    return (

        <SliderModal
            show={show}
            setShow={setShow}
            title={
                selectedProject ? "Edit Project" : "Create New Project"
            }
            body={
                <>
                    <Formik
                        initialValues={selectedProject ? {
                            name: selectedProject.name,
                            developer: selectedProject.developer,
                            site_address: selectedProject.site_address,
                            area: {
                                formattedAddress: selectedProject.area,
                                city: selectedProject.city,
                                pincode: selectedProject.zip_code,
                                state: selectedProject.state,
                                district: selectedProject.district
                            }
                        } : ''}

                        onSubmit={async (values) => {
                            if (selectedProject) {
                                await editProjectHandle({
                                    ...values,
                                    uuid: selectedProject.uuid,
                                    area: values.area.formattedAddress,
                                    city: values.area.city,
                                    zip_code: values.area.pincode,
                                    state: values.area.state,
                                    district: values.area.district,
                                    country: values.area.country
                                })
                            } else {
                                await addProjectHandle({
                                    ...values,
                                    area: values.area.formattedAddress,
                                    city: values.area.city,
                                    zip_code: values.area.pincode,
                                    state: values.area.state,
                                    district: values.area.district,
                                    country: values.area.country
                                })
                            }
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={addProjectSchema}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { isValid, values, touched, errors, handleChange, handleBlur, handleSubmit } = props;

                                return (

                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-10'>
                                            <InputText
                                                value={values['name']}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                index={0}
                                                error={errors['name']}
                                                touched={touched['name']}
                                                type="text"
                                                placeholder="Project Name"
                                                name="name"
                                                label={<> Project Name </>}
                                            />
                                            <InputText
                                                value={values['developer']}
                                                onChange={handleChange}
                                                handleBlur={handleBlur}
                                                index={1}
                                                error={errors['developer']}
                                                touched={touched['developer']}
                                                type="text"
                                                placeholder="Developer Name"
                                                name="developer"
                                                label={<>Developer Name </>}
                                            />

                                            <div className={`form-group position-relative ${errors.area ? 'invalid' : ''}`}>
                                                <label>Project Address (Searchable) </label>
                                                <LocationSearchInput
                                                    value={values['area']?.formattedAddress}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "area", value: e
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                />
                                                {errors.area && (
                                                    <>
                                                        <span className="invalid-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z" fill="#FC5A5A" />
                                                                <path d="M7.00195 11C7.00195 10.8687 7.02782 10.7386 7.07807 10.6173C7.12833 10.496 7.20199 10.3857 7.29485 10.2929C7.38771 10.2 7.49794 10.1264 7.61927 10.0761C7.7406 10.0259 7.87063 10 8.00195 10C8.13328 10 8.26331 10.0259 8.38464 10.0761C8.50596 10.1264 8.6162 10.2 8.70906 10.2929C8.80192 10.3857 8.87558 10.496 8.92583 10.6173C8.97609 10.7386 9.00195 10.8687 9.00195 11C9.00195 11.2652 8.8966 11.5196 8.70906 11.7071C8.52152 11.8946 8.26717 12 8.00195 12C7.73674 12 7.48238 11.8946 7.29485 11.7071C7.10731 11.5196 7.00195 11.2652 7.00195 11ZM7.09995 4.995C7.08664 4.86884 7.09999 4.74129 7.13915 4.62062C7.17831 4.49996 7.24241 4.38888 7.32727 4.29458C7.41214 4.20029 7.51588 4.12489 7.63176 4.07328C7.74765 4.02167 7.87309 3.995 7.99995 3.995C8.12681 3.995 8.25226 4.02167 8.36814 4.07328C8.48403 4.12489 8.58777 4.20029 8.67263 4.29458C8.7575 4.38888 8.82159 4.49996 8.86075 4.62062C8.89991 4.74129 8.91327 4.86884 8.89995 4.995L8.54995 8.502C8.53819 8.63977 8.47516 8.76811 8.37331 8.86163C8.27146 8.95515 8.13823 9.00705 7.99995 9.00705C7.86168 9.00705 7.72844 8.95515 7.6266 8.86163C7.52475 8.76811 7.46171 8.63977 7.44995 8.502L7.09995 4.995Z" fill="#FC5A5A" />
                                                            </svg>
                                                        </span>
                                                        <div className="input-feedback mt-8">{errors.area}
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                            <InputText
                                                index={4}
                                                name="site_address"
                                                id="site_address"
                                                value={values['site_address']}
                                                placeholder="Address"
                                                onChange={(e) => {
                                                    const value = { target: { name: "site_address", value: e.target.value } }
                                                    handleChange(value)
                                                }}
                                                label={<>Custom Address (Optional) </>}
                                            />
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                            <button
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                                type='button'
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="pr-btn"
                                                type="submit"
                                            >
                                                {
                                                    selectedProject ? "Update Project" : "Create New Project"
                                                }
                                            </button>

                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}