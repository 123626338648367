import React from 'react'
import PropTypes from 'prop-types';

const PrimaryButton = ({ className, name, onClick }) => {
    return (
        <button
            type='button'
            className={`${className} flex items-center rounded-md border border-transparent !bg-primary text-white text-sm font-medium hover:opacity-90`}
            onClick={onClick}
        >
            {name}
        </button >
    )
}
PrimaryButton.propTypes = {
    variant: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

PrimaryButton.defaultProps = {
    variant: 'primary',
    className: '',
    value: ''
};
export default PrimaryButton
