import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import DisableReEngageModal from '../../../../components/Modals/DisableReEngageModal';
import EnableReEngagementModal from '../../../../components/Modals/EnableReEngagementModal';
import { getReEngagement, updateAlternateNumberDuplicacy, updateReEngagement } from '../../../../services/private/company.service';

const LeadDuplication = () => {
    const [reEngagement, setReEngament] = useState(false);
    const [alternateReEngagement, setAlternateReEngagement] = useState(false)
    const [showDisableReEngageModal, setShowDisableReEngageModal] = useState(false);
    const [showEnableReEngagementModal, setShowEnableReEngagementModal] = useState(false);
    const [apiResponse, setapiResponse] = useState(false);

    const getReEngagementStatus = useCallback(() => {
        getReEngagement().then(res => {
            setapiResponse(true);
            if (res.data.status === 200) {
                console.log(res.data.data, 'data')
                setReEngament(res.data.data.re_engagement);
                setAlternateReEngagement(res.data.data.alternate_number_re_engagement)
            }
        }).catch(err => {
            console.log({ err })
            setapiResponse(true);
        })
    }, []);

    useEffect(() => {
        getReEngagementStatus()
    }, []);

    const updateAlternetRengagement = async () => {
        const payload = {
            value: !alternateReEngagement
        }
        const res = await updateAlternateNumberDuplicacy(payload);
        if (res.data.status === 200) {
            toast.success(res.data.data ? 'Re-Engagement Enabled' : 'Re-Engagement disabled')

            setAlternateReEngagement(!alternateReEngagement);
        }
    }
    const updateLeadReEngagement = useCallback(() => {
        const payload = {
            reEngagement: !reEngagement
        }

        updateReEngagement(payload).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.data ? 'Re-Engagement Enabled' : 'Re-Engagement disabled')
                setShowDisableReEngageModal(false)
                setShowEnableReEngagementModal(false)
                setReEngament(!reEngagement);

            }
        }).catch(err => {
            console.log({ err })
        })
    }, [reEngagement])

    const closeModal = useCallback(() => {
        setShowDisableReEngageModal(false)
    }, []);

    const closeEngagementModal = useCallback(() => {
        setShowEnableReEngagementModal(false)
    }, [])
    return (
        <div className="settings-title assignment-title border-0">
            <div className="row d-flex d-flex flex-fill">
                <div className="col-lg-7 d-flex flex-fill flex-column">
                    <div className='d-flex align-items-center'>
                        <h1 className="fz24 mb-16 black fw-po-medium mr-2">Allow duplicate leads to re-engage</h1>
                        {apiResponse && <button
                            type="button"
                            className={`btn btn-lg btn-toggle ${reEngagement ? 'active' : ''} mr-2`}
                            value={reEngagement}
                            onClick={() => {
                                if (reEngagement) {
                                    setShowDisableReEngageModal(true)
                                } else {
                                    setShowEnableReEngagementModal(true)
                                }
                            }}
                        >
                            <div className="handle"></div>
                            <span className={`${reEngagement ? 'on' : 'off'}`}>{reEngagement ? 'ON' : 'OFF'}</span>
                        </button>}
                    </div>
                    <div className='duplicate-leads-blocks'>
                        <div className='d-flex flex-row align-items-start'>
                            <div className='svg-icons'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M11 14V9C11 8.44771 10.5523 8 10 8C9.44771 8 9 8.44771 9 9V14C9 14.5523 9.44771 15 10 15C10.5523 15 11 14.5523 11 14Z" fill="#0062FF" />
                                    <path d="M9 6C9 5.44771 9.44771 5 10 5C10.5523 5 11 5.44771 11 6C11 6.55229 10.5523 7 10 7C9.44771 7 9 6.55229 9 6Z" fill="#0062FF" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10Z" fill="#0062FF" />
                                </svg>
                            </div>
                            <div>
                                <p className="fz16 black-dark-700 mb-12">If a lead already exists in the platform, the existing lead with the same mobile number will be marked as re-engaged & its assigned date will be updated. Also, it will remain assigned to the same user.</p>
                                <p className="fz16 black-dark-700 mb-0 flex items-center">Denoted by <svg className="ml-1" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.1013 6.55114C4.93404 6.36494 4.69755 6.25561 4.44736 6.24861C4.19718 6.24176 3.95512 6.33789 3.77798 6.51454L1.58987 8.70264C1.2119 9.08045 0.999611 9.59293 0.999611 10.1273C0.999611 10.6619 1.21191 11.1743 1.58987 11.5522L3.75508 13.7196C3.90325 13.8719 4.09932 13.9687 4.31035 13.9936C4.52138 14.0185 4.73463 13.9701 4.91414 13.8566C5.1457 13.7067 5.29688 13.4602 5.32571 13.1858C5.35451 12.9116 5.25775 12.639 5.06248 12.4444L3.65482 11.0389H8.29528C9.66077 11.0399 10.9678 10.4845 11.9149 9.50078C12.862 8.51708 13.3674 7.19013 13.3146 5.82561C13.2073 3.11056 10.8894 1.00019 8.1722 1.00019H5.13997C4.90762 0.99557 4.68179 1.07721 4.50607 1.22936C4.33036 1.3815 4.21738 1.59317 4.18858 1.82378C4.16343 2.08096 4.24825 2.33655 4.42236 2.5275C4.59631 2.71832 4.84298 2.8267 5.10128 2.82543H8.29533C9.17128 2.82431 10.0093 3.18303 10.6132 3.81756C11.2171 4.45207 11.534 5.30671 11.4894 6.18151C11.4325 7.01129 11.0601 7.78762 10.4488 8.35148C9.83732 8.91535 9.03349 9.22376 8.20179 9.21374H3.65463L5.02363 7.84474H5.02347C5.19853 7.67939 5.30388 7.45356 5.31821 7.21325C5.33269 6.97294 5.25503 6.73629 5.10113 6.5512L5.1013 6.55114Z" fill="#0062FF" stroke="white" strokeWidth="0.3"></path></svg></p>
                            </div>

                        </div>
                    </div>
                    <div className='mt-2 flex items-center'>
                        <p className='fz16 black-dark-700 mb-12 mr-2'>Include duplicate alternate number</p>
                        {apiResponse && <button
                            type="button"
                            className={`btn btn-lg btn-toggle ${alternateReEngagement ? 'active' : ''} mr-2`}
                            value={alternateReEngagement}
                            onClick={updateAlternetRengagement}
                        >
                            <div className="handle"></div>
                            <span className={`${alternateReEngagement ? 'on' : 'off'}`}>{alternateReEngagement ? 'ON' : 'OFF'}</span>
                        </button>}
                    </div>
                </div>
            </div>
            {showDisableReEngageModal && <DisableReEngageModal show={showDisableReEngageModal} onSubmit={updateLeadReEngagement} closeModal={closeModal} />}
            {showEnableReEngagementModal && <EnableReEngagementModal show={showEnableReEngagementModal} onSubmit={updateLeadReEngagement} closeModal={closeEngagementModal} />}
        </div >

    )
}

export default LeadDuplication;