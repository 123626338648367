import React from 'react'

const StaticFieldAccordian = ({
    accordionName,
    value,
    setValue,
    placeholder,
    defaultExapanded,
    label,
    type
}) => {
    return (
        <div id='accordion'>
            <div className="filter-card cursor-pointer">
                <div id="name-heading" className="filter-header" data-toggle="collapse" data-target={"#collapse" + String(accordionName).replaceAll(/\s/g, '')} aria-expanded={defaultExapanded} aria-controls={"collapse" + String(accordionName).replaceAll(/\s/g, '')}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <h2 className="fz16 black mb-0 fw-regular">{label} {value?.length > 0 && <span>1</span>}</h2>
                        <div className="icon mr-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                            </svg>
                        </div>
                    </div>
                </div>
                {/* Name Filter Function */}
                <div id={"collapse" + String(accordionName).replaceAll(/\s/g, '')} className={defaultExapanded ? "filter-body collapse show" : "filter-body collapse"} aria-labelledby={String(accordionName).replaceAll(/\s/g, '') + "-heading"} data-parent="#accordion">
                    <div className="px-20">
                        <div className="search-filter">
                            {/* <button className={`border-0 bg-tr clear-btn px-0 ${value.length > 0 ? "" : 'd-none'} `} onClick={() => {
                                setValue([]);
                                setInputValues({ ...inputValues, [accordionName]: '' });
                                setInputValuesNotFound({ ...inputValuesNotFound, [accordionName]: '' })
                            }}
                            >
                                Clear All
                            </button> */}
                            <div className={`form-group position-relative d-flex search-input  ${value ? 'input-active' : ''} `}>
                                <div className="serch-icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.10369 6.1455C3.29877 9.00618 3.29877 13.6442 6.10369 16.5049C8.90862 19.3656 13.4563 19.3656 16.2612 16.5049C19.0662 13.6442 19.0662 9.00618 16.2612 6.1455C13.4563 3.28483 8.90862 3.28483 6.10369 6.1455ZM7.2126 7.27645C9.40509 5.04037 12.9598 5.04037 15.1523 7.27645C17.3448 9.51252 17.3448 13.1379 15.1523 15.374C12.9598 17.6101 9.40509 17.6101 7.2126 15.374C5.0201 13.1379 5.0201 9.51252 7.2126 7.27645Z" fill="#696974"></path><path d="M19.7562 18.6204L16.5182 15.3181C15.779 14.5641 14.6701 15.6951 15.4093 16.449L18.6473 19.7514C19.3866 20.5053 20.4955 19.3744 19.7562 18.6204Z" fill="#696974"></path></svg></div>
                                <input
                                    autoComplete="off"
                                    className="form-control w-100 searchinput"
                                    placeholder={placeholder || ''}
                                    name={accordionName}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    type={type || 'text'}
                                />
                                {value && <div className="close-icon" onClick={() => setValue('')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974"></path>
                                    </svg>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaticFieldAccordian