import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Disclosure, Switch, Transition } from '@headlessui/react'
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import InputBox from '../InputGroup/InputBox';
import { MentorService } from '../../services/mentor';
import { toast } from 'react-toastify';
import { cleanObj, convertToFormData } from '../../helpers/form.helper';
import makeUploadRequest from '../../services/api/uploadRequest'
import ConfirmBack from '../Modals/ConfirmBack';
import InputSelect from '../InputGroup/InputSelect';

const CreateMentor = () => {
  let history = useHistory();

  let [categories, setCategories] = useState([])

  const [files, setFiles] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showCancel, setShowCancel] = useState(false);

  const getCategories = async () => {
    await MentorService.getAllCategories().then(res => {
      if (res.status === 200) {
        const category = res.data.map(c => ({ label: c, value: c }))
        setCategories(category)
      }
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data)
    })
  }

  const addMentor = async (formData) => {
    const toastId = toast.loading('Uploading Mentor Data');

    return await MentorService.addMentor(formData).then(res => {
      if (res.status === 200) {
        toast.success(res.data, {
          id: toastId
        });
        history.push("all-mentors")
        toast.dismiss(toastId)
      }
      // else
    }).catch(e => {
      console.log(e);
      toast.error(e.response.data.message, {
        id: toastId
      })
    })

  }

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      e.target.value = "";
      setFiles({ ...files, [e.target.name]: '' });
      return;
    }

    if (e.target.files[0].size < 1000000) {
      setFiles({ ...files, [e.target.name]: e.target.files[0] });
    } else {
      e.target.value = "";
      toast.error("File Size must be less than 1MB");
    }
  }

  useEffect(() => {
    getCategories();
  }, [])

  return (
    <>
      <ConfirmBack
        handleTrue={() => {
          setShowCancel(false)
          history.push('all-mentors');
        }}
        isOpen={showCancel}
        closeModal={() => setShowCancel(false)}
      />
      <div className='users w-100 h-full flex flex-col'>
        <div className="settings-title">
          <h1 className='text-2xl text-black font-medium'>Create Mentor</h1>
        </div>
        <div className='w-full h-full overflow-auto'>
          <Formik
            initialValues={
              {
                name: '',
                picture_url: '',
                designation: '',
                company: '',
                category: '',
                short_description: '',
                logo_1: '',
                logo_2: '',
                logo_3: ''
              }
            }
            validate={values => {
              const errors = {};
              if (!values.name) errors.name = 'Required';
              if (!values.designation) errors.designation = 'Required';
              if (!values.company) errors.company = 'Required';
              if (!values.category) errors.category = 'Required';
              if (!values.short_description) errors.short_description = 'Required';
              if (!values.picture_url) errors.picture_url = 'Required';
              if (!values.logo_1) errors.logo_1 = 'Required';
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values, 'values')
              // const form_data_body = convertToFormData({...values, ...files});
              let pictureUrl = '', logo_1 = '', logo_2 = '', logo_3 = '';
              if (files.hasOwnProperty('picture_url')) {
                const formData1 = new FormData();
                formData1.append("document_upload", files.picture_url)
                let pictureUrl_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData1);
                pictureUrl = pictureUrl_obj.data.data.s3_url;
                console.log("pictureUrl new image upload");
              }
              if (files.hasOwnProperty('logo_1')) {
                const formData1 = new FormData();
                formData1.append("document_upload", files.logo_1)
                let logo_1_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData1);
                logo_1 = logo_1_obj.data.data.s3_url;
                console.log("logo_1 new image upload");
              }
              if (files.hasOwnProperty('logo_2')) {
                const formData1 = new FormData();
                formData1.append("document_upload", files.logo_2)
                let logo_2_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData1);
                logo_2 = logo_2_obj.data.data.s3_url;
                console.log("logo_2 new image upload");
              }
              if (files.hasOwnProperty('logo_3')) {
                const formData1 = new FormData();
                formData1.append("document_upload", files.logo_3)
                let logo_3_obj = await makeUploadRequest("/pr0p/crm/uploadToS3Helper", "POST", formData1);
                logo_3 = logo_3_obj.data.data.s3_url;
                console.log("logo_3 new image upload");
              }
              const obj = {
                name: values.name,
                designation: values.designation,
                company: values.company,
                category: values.category.value,
                short_description: values.short_description,
                picture_url: pictureUrl,
                logo_1: logo_1,
                logo_2: logo_2,
                logo_3: logo_3
              }
              await addMentor(cleanObj(obj));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => {
              console.log(errors, 'errors')
              return (
                <form className=' h-full overflow-auto flex flex-col' onSubmit={handleSubmit}>
                  <div className="w-full h-full overflow-auto !pb-6 !pt-6">
                    <div className='w-10/12 !px-6'>
                      <div className="grid grid-cols-2 gap-9">
                        <div>
                          <div className="form-group">
                            <InputBox
                              title="Mentor Name"
                              name="name"
                              error={errors.name}
                              touched={touched.name}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.name}
                            />
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Mentor Picture(Less than 1mb, 295 X 230px) <span className="font-bold text-red">{errors.picture_url && touched.picture_url && errors.picture_url}</span>
                              {files.picture_url &&
                                <img src={URL.createObjectURL(files.picture_url)} height="221px" width="248px" />}
                            </label>
                            <input type="file" className='h-[45px]  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500' placeholder=' '
                              name="picture_url"
                              style={(errors.picture_url && touched.picture_url) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                handleFileChange(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.picture_url}
                            />
                          </div>
                        </div>

                      </div>

                      <div className="grid grid-cols-2 gap-9 mt-4">
                        <div>
                          <div className="form-group">
                            <InputBox
                              title="Designation"
                              name="designation"
                              error={errors.designation}
                              touched={touched.designation}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.designation}
                            />
                          </div>
                        </div>
                        <div className="...">

                          <div className="form-group">
                            <InputBox
                              title="Company"
                              name="company"
                              error={errors.company}
                              touched={touched.company}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.company}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="grid grid-cols-2 gap-9 mt-4">
                        <div>
                          <InputSelect
                            label='Mentor Category'
                            inputRequired={true}
                            name='Select Category'
                            placeholder='Select Category'
                            inputClass='h-[45px]'
                            options={categories}
                            value={values.category || ''}
                            onChange={(e) => {
                              const value = { target: { name: "category", value: e } }
                              handleChange(value)
                            }}
                            isMulti={false}
                            error={errors.category}
                          />
                          {/* <div className="form-group">
                          <label>Mentor Category <span className="font-bold text-red">{errors.category && touched.category && errors.category}</span>
                          </label>
                          <select id="" className='form-control'
                            name="category"
                            style={(errors.category && touched.category) && { border: "2px solid #ef4444" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.category}
                          >
                            {categories.map((data, index) => {
                              return (
                                <option value={data} key={`${data}_${index}`}>{data}</option>
                              )
                            })}
                          </select>
                        </div> */}
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Company Logo 1 (Less than 1mb, 130 X 50)<span className="font-bold text-red">{errors.logo_1 && touched.logo_1 && errors.logo_1}</span>
                              {files.logo_1 &&
                                <img src={URL.createObjectURL(files.logo_1)} height="130px" width="50px" />}
                              {files.logo_1 && <a className='hover:text-primary' onClick={() => {
                                if (files?.logo_1) {
                                  setFiles(prev => ({
                                    ...prev,
                                    logo_1: ''
                                  }))
                                }
                              }}>Remove Logo</a>}
                            </label>

                            <input type="file" className='h-[45px]  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500' placeholder=' '
                              name="logo_1"
                              style={(errors.logo_1 && touched.logo_1) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                handleFileChange(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.logo_1}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="grid grid-cols-2 gap-9 mt-4">
                        <div>
                          <div className="form-group">
                            <label>Short Description(140 Character limit)
                              <span className="font-bold text-red">{errors.short_description && touched.short_description && errors.short_description}</span>
                            </label>
                            <textarea id="" cols="5" rows="5" className='form-control' maxlength="140"
                              name="short_description"
                              style={(errors.short_description && touched.short_description) && { border: "2px solid #ef4444" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.short_description}
                            ></textarea>
                          </div>
                        </div>
                        <div className="...">
                          <div className="form-group">
                            <label>Company Logo 2 (Less than 1mb, 130 X 50)
                              {files.logo_2 &&
                                <img src={URL.createObjectURL(files.logo_2)} height="130px" width="50px" />}
                              {files.logo_2 && <a className='hover:text-primary' onClick={() => {
                                if (files?.logo_2) {
                                  setFiles(prev => ({
                                    ...prev,
                                    logo_2: ''
                                  }))
                                }
                              }}>Remove Logo</a>}
                            </label>
                            <input type="file" className='h-[45px]  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500' placeholder=' '
                              name="logo_2"
                              // style={(errors.logo_2 && touched.logo_2) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                handleFileChange(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.logo_2}
                            />
                          </div>
                          <div className="form-group">
                            <label>Company Logo 3 (Less than 1mb, 130 X 50)
                              {files.logo_3 &&
                                <img src={URL.createObjectURL(files.logo_3)} height="130px" width="50px" />}
                              {files.logo_3 && <a className='hover:text-primary' onClick={() => {
                                if (files?.logo_3) {
                                  setFiles(prev => ({
                                    ...prev,
                                    logo_3: ''
                                  }))
                                }
                              }}>Remove Logo</a>}
                            </label>
                            <input type="file" className='h-[45px]  w-full px-3 py-2.5 bg-black100 border border-black200 rounded-md text-14 placeholder-black600 focus:outline-none focus:border-primary focus:ring-primary invalid:border-pink-500 invalid:text-pink-600  focus:invalid:border-pink-500 focus:invalid:ring-pink-500' placeholder=' '
                              name="logo_3"
                              // style={(errors.logo_3 && touched.logo_3) && { border: "2px solid #ef4444" }}
                              onChange={(e) => {
                                handleFileChange(e)
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                              value={values.logo_3}
                            />
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className="flex justify-end !border-t broder-black400 !px-6 !py-2.5">
                    <div className='!text-primary !border !border-primary text-16 font-medium rounded mr-4 px-10 py-2 cursor-pointer'
                      onClick={() => { setShowCancel(true) }}
                    >Cancel</div>
                    <button type='submit' className='pr-btn font-medium !px-10'>Save</button>
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>


      </div >

    </>
  )
}

export default CreateMentor
