import React, { useContext, useEffect, useState } from 'react';

import BasicModal from './BasicModal'
import { ReactComponent as NavRight } from '../../assets/icons/NavRight.svg';
import { ReactComponent as Owlleft } from '../../assets/icons/owlleft.svg';
import { ReactComponent as OwlRight } from '../../assets/icons/owlright.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { getBookingDoneDetails, getBookingDoneDetailsByLeadId, getDetailsByLeadId, getEOIDetails, getPendingDemandByBookingId, getPendingDemandByEOIId } from '../../services/private/inventory.service';
import { toINRFormat } from '../../helpers/helpers';
import { getPricingBasePrice } from '../../services/public/public.service';
import AreYouSureModal from './AreYouSureModal';

import Consumer from '../../helpers/context'
import { getLeadByUuid } from '../../services/private/leads.service';

import AdditionalInfo from '../New_Booking/AdditionalInfo';
import BasicDetail from '../New_Booking/BasicDetail';
import UnitDetail from '../New_Booking/UnitDetail';
import Pricing from '../New_Booking/Pricing';
import Paymentplan from '../New_Booking/Paymentplan';
import Other from '../New_Booking/Other';
import LeadExistInBookingModal from './LeadExistInBookingModal';

const NewBookingModal = ({ show, closeModal, lead, onSuccess, data, isEdit, showTab }) => {

    const [steps, setSteps] = useState('basic');
    const [selectedProject, setSelectedProject] = useState({});
    const [unit, setUnit] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [selectedBooking, setSelectedBooking] = useState();
    const [demandList, setDemandList] = useState([]);
    const [basePriceData, setBasePriceData] = useState([]);
    const [basePriceConfigMap, setBasePriceConfigMap] = useState(null);
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const [selectedEoiDetails, setSelectedEoiDetails] = useState();
    const [userEmail, setUserEmail] = useState('');
    const [selectedCP, setSelectedCP] = useState('');
    const [selectedCPUser, setSelectedCPUser] = useState('');
    const [initialProject, setInitialProject] = useState('');
    const [isPrefferedUnit, setIsPrefferedUnit] = useState('yes');
    const [projectName, setProjectName] = useState('');

    const { allowedPermissions, userProfile } = useContext(Consumer);

    const getLeadDetails = async (id) => {
        await getLeadByUuid(id).then(res => {
            if (res.status === 200) {
                setUserEmail(res.data.data.customer.data.email);
                setInitialProject(res.data.data?.projects.length > 0 ? res.data.data?.projects[0] : '')
            }

        })
            .catch((err) => {
                if (err.response?.data)
                    // setUserNotFound(true)
                    console.log(err.response?.data)
            });
    }

    const getEOILeadDetails = async (uuid) => {
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                const { bookingUnit, booking } = res.data.data;
                if (bookingUnit?.uuid) {
                    setIsPrefferedUnit('yes');
                } else {
                    setIsPrefferedUnit('no');
                }
                setSelectedEoiDetails({ ...res.data.data, eoiData: true });
                if (!data.booking_uuid && steps === 'basic') {
                    setSelectedBooking({ ...res.data.data, eoiData: true });
                    setProjectName(res.data.data?.project.name)
                }
            }
        }
    }

    const getEOIDetailsByLeadId = async (uuid) => {
        const res = await getDetailsByLeadId(uuid);
        if (res.data.status === 200) {
            setSelectedEoiDetails({ ...res.data.data, eoiData: true });
        }
    }

    const getBookingDetails = async (uuid) => {
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                const { bookingUnit, booking } = res.data.data;
                if (bookingUnit?.uuid) {
                    setIsPrefferedUnit('yes');
                } else {
                    setIsPrefferedUnit('no');
                }
                setTotalPrice(res.data.data?.bookingPricing?.adjusted_price || res.data.data?.bookingPricing?.final_price || 0)
                setUnit(bookingUnit)
                setSelectedBooking({ ...res.data.data, eoiData: false });
                setProjectName(res.data.data?.project.name)

            }
        }
    }

    const getBookingLeadDetails = async (uuid) => {
        getBookingDoneDetailsByLeadId(uuid).then(res => {
            if (res.status === 200) {
                if (res.data.data?.customer?.uuid) {
                    const { bookingUnit, booking } = res.data.data;
                    if (bookingUnit?.uuid) {
                        setIsPrefferedUnit('yes');
                    } else {
                        setIsPrefferedUnit('no');
                    }
                    setTotalPrice(res.data.data?.bookingPricing?.adjusted_price || res.data.data?.bookingPricing?.final_price || 0)
                    setUnit(bookingUnit)
                    setSelectedBooking({ ...res.data.data, eoiData: false });

                }
            }
        })
    }

    const getPreviousDemand = async (uuid, demandFrom) => {
        let res = {}
        if (demandFrom === 'eoi') {
            res = await getPendingDemandByEOIId(uuid);
        } else {
            res = await getPendingDemandByBookingId(uuid);
        }
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: `#${item.unique_id} - Due ₹ ${toINRFormat(item.total_amount_due)}`, value: item.unique_id }))
            setDemandList(mapped)
        }
    }

    const getBasePriceData = async (uuid) => {
        const res = await getPricingBasePrice(uuid);
        if (res.data.status === 200) {
            setBasePriceData(res.data.data);
            if (res.data.data && res.data.data.length) {
                let temp = {};
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element.config && element.config.length) {
                        temp[element.config[0].id] = element.rate
                    }
                }
                if (Object.keys(temp).length) {
                    setBasePriceConfigMap(temp);
                }
            }
        }
    }

    useEffect(() => {
        if (showTab) {
            setSteps(showTab)
        }
    }, [showTab]);

    useEffect(() => {
        if (selectedProject?.uuid) {
            getBasePriceData(selectedProject?.uuid)
        }
    }, [selectedProject]);

    useEffect(() => {
        if (bookingId) {
            getBookingDetails(bookingId);
        }

    }, [bookingId, steps]);

    useEffect(() => {
        if (data.eoi_uuid) {
            getEOILeadDetails(data.eoi_uuid);
            getPreviousDemand(data.eoi_uuid, 'eoi');
        }
        if (data.id) {
            getEOIDetailsByLeadId(data.id);
            getBookingLeadDetails(data.id);
            getLeadDetails(data.id);
        }
        if (data.booking_uuid) {
            setBookingId(data?.booking_uuid)
            getPreviousDemand(data?.booking_uuid);
            getBookingDetails(data.booking_uuid, 'booking');
        }

    }, [data]);

    // console.log(selectedBooking, 'selected Booking')
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='pt-6 relative'>
                    <h1 className='sm:mx-6 mx-3 text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-4 inter flex justify-between capitalize'>
                        {isEdit ? 'Edit' : ''} Booking Completed For {data.name}
                        <button onClick={() => setShowCloseConfirmationModal(true)}>
                            <CloseIcon />
                        </button>
                    </h1>
                    <div className='flex items-center no-scrollbar border-b border-[#E2E2EA]'>
                        <button className='absolute left-1 lg:hidden'><Owlleft /></button>
                        <ul className='flex gap-x-3 items-center mb-0 pb-2  md:!px-0 lg:w-[100%] sm:w-[90%] w-[80%] md:!mx-5  !mx-auto no-scrollbar lg:overflow-hidden overflow-auto'>
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('basic');
                                    }
                                }}
                            >
                                <span className={`${steps === 'basic' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Basic Details</span> <NavRight />
                            </li>
                            {/* <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'>
                            <span className={`${steps === 'quotes' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Select Quote</span> <NavRight /></li
                            > */}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('UnitDetail');
                                    }
                                }}
                            >
                                <span className={`${steps === 'UnitDetail' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Unit Details</span> <NavRight />
                            </li>
                            {isPrefferedUnit && isPrefferedUnit === 'yes' && <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('Pricing');
                                    }
                                }}
                            >
                                <span className={`${steps === 'Pricing' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Pricing </span><NavRight />
                            </li>}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('Additional');
                                    }
                                }}
                            >
                                <span className={`${steps === 'Additional' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Additional details  </span><NavRight />
                            </li>
                            {isPrefferedUnit && isPrefferedUnit === 'yes' && <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('Paymentplan');
                                    }
                                }}
                            >
                                <span className={`${steps === 'Paymentplan' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Payment Plan  </span><NavRight />
                            </li>}
                            {/* <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('PaymentProof');
                                    }
                                }}
                            >
                                <span className={`${steps === 'PaymentProof' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Payment Proof  </span><NavRight />
                            </li> */}
                            {/* <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'>
                            <span className={`${steps === 'BrokerDetails' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Broker Details</span> <NavRight /></li
                            > */}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('Other');
                                    }
                                }}
                            >
                                <span className={`${steps === 'Other' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Others</span>
                            </li>
                        </ul>
                        <button className='absolute right-1 lg:hidden'><OwlRight /></button>
                    </div>
                </div>
                {steps === 'basic' &&
                    <BasicDetail
                        lead={lead}
                        data={data}
                        setSteps={setSteps}
                        selectedBooking={selectedBooking}
                        setSelectedProject={setSelectedProject}
                        setBookingId={setBookingId}
                        closeModal={closeModal}
                        demandList={demandList}
                        bookingId={bookingId}
                        userEmail={userEmail}
                        setSelectedCP={setSelectedCP}
                        setSelectedCPUser={setSelectedCPUser}
                        userProfile={userProfile}
                        initialProject={initialProject}
                        steps={steps}
                        selectedProject={selectedProject}
                    />}
                {/* {steps === 'quotes' && <Quotes setSteps={setSteps} bookingId={bookingId} lead={lead} />} */}
                {steps === 'UnitDetail' && <UnitDetail
                    data={data}
                    setSteps={setSteps}
                    lead={lead}
                    selectedProject={selectedProject}
                    bookingId={bookingId}
                    setUnit={setUnit}
                    selectedBooking={selectedBooking}
                    basePriceData={basePriceData}
                    basePriceConfigMap={basePriceConfigMap}
                    selectedEoiDetails={selectedEoiDetails}
                    setIsPrefferedUnit={setIsPrefferedUnit}
                />}
                {steps === 'Pricing' && <Pricing
                    data={data}
                    unit={unit}
                    setSteps={setSteps}
                    lead={lead}
                    bookingId={bookingId}
                    setTotalPrice={setTotalPrice}
                    selectedBooking={selectedBooking}
                    allowedPermissions={allowedPermissions}
                    selectedEoiDetails={selectedEoiDetails}
                    selectedProject={selectedProject}
                />}
                {
                    steps === 'Additional' && <AdditionalInfo
                        bookingId={bookingId}
                        setSteps={setSteps}
                        selectedBooking={selectedBooking}
                        isPrefferedUnit={isPrefferedUnit}
                        selectedEoiDetails={selectedEoiDetails}
                    />
                }
                {steps === 'Paymentplan' && <Paymentplan
                    data={data}
                    setSteps={setSteps}
                    lead={lead}
                    unit={unit}
                    bookingId={bookingId}
                    selectedProject={selectedProject}
                    totalPrice={totalPrice}
                    selectedBooking={selectedBooking}
                    selectedEoiDetails={selectedEoiDetails}
                />}
                {/* {steps === 'BrokerDetails' && <BrokerDetails
                    data={data}
                    setSteps={setSteps}
                    lead={lead}
                    bookingId={bookingId}
                    selectedBooking={selectedBooking}
                />} */}
                {/* {
                    steps === 'PaymentProof' && <PaymentProof
                        setSteps={setSteps}
                        bookingId={bookingId}
                        demandList={demandList}
                        selectedBooking={selectedBooking}
                        selectedEoiDetails={selectedEoiDetails}
                        selectedProject={selectedProject}
                    />
                } */}
                {steps === 'Other' && <Other
                    data={data}
                    setSteps={setSteps}
                    bookingId={bookingId}
                    lead={lead}
                    onSuccess={onSuccess}
                    selectedBooking={selectedBooking}
                    closeModal={closeModal}
                    selectedEoiDetails={selectedEoiDetails}
                    selectedProject={selectedProject}
                    selectedCP={selectedCP}
                    selectedCPUser={selectedCPUser}
                    isPrefferedUnit={isPrefferedUnit}
                    projectName={projectName}
                />}

            </div>
            {showCloseConfirmationModal && <AreYouSureModal
                show={showCloseConfirmationModal}
                closeModal={() => setShowCloseConfirmationModal(false)}
                subText={'Do you want to cancel the Booking Complete process? Any unsaved changes you made may be lost.'}
                confirmClose={closeModal}
            />}
        </BasicModal >
    )
}

export default NewBookingModal
