import React from 'react';
import { toast } from 'react-toastify';
import { forgotPassword } from '../../../services/public/public.service';

export default function EnterMailModal({
    setEmail,
    email,
    setSentMail
}) {

    const onForgotPasswordSubmit = () => {
        forgotPassword(email).then(res => {
            if (res.status === 200) {
                setSentMail(true)
            }
        }).catch(e => {
            toast.error("Unable to send Email. Contact Support")
        })
    }

    return (
        <>
            <h3 className="fz24 black text-center mt-40 mb-12">Forgot your password</h3>
            <p className="fz14 text-center black-dark-700 ro">Please enter your Email address for receiving password reset instructions on your email</p>
            <div className={`form-group position-relative mt-40`}>
                <label htmlFor="email" className="custom-txt" >Enter your Email</label>

                <input
                    type="email"
                    autoComplete="off"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control text-input'"
                    name="email"
                />

                <div className="form-group mt-24">
                    <button type="submit" onClick={onForgotPasswordSubmit}
                        className="pr-btn w-100 fz18 py-10">
                        Continue
                    </button>


                </div>
            </div>
        </>
    )
}