import React from 'react'
import { Button } from 'react-bootstrap'
import PopUpModal from './PopUpModal'

const CancelCSV = ({ show, closeCancelModal, onSubmit, title, bodyText, submitButtonType, cancelButtonText, submitButtonText }) => {

    return (
        <PopUpModal
            setShow={closeCancelModal}
            show={show}
            heading={<>
                {title}
            </>}
            body={<>

                <p className='black-dark-800 fz16 po'>{bodyText}</p>

                <div className="justify-content-end d-flex modal-footer">
                    {cancelButtonText && <button variant="" className="cancel outline-btn" onClick={() => closeCancelModal(false)}>
                        {cancelButtonText}
                    </button>}
                    <button variant="" className={`${submitButtonType === 'delete' ? 'del-btn btn' : 'pr-btn'}`} onClick={onSubmit}>
                        {submitButtonText}
                    </button>
                </div>
            </>}
        />
    )
}

export default CancelCSV;