import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import { Redirect } from 'react-router';
import { Button, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AddProjectModal from '../../../components/Modals/AddProjectModal';
import { deleteProjectByUUID, getAuthRolePermissions, getCompanyAgency, getProjectIntegrations, getProjects } from "../../../services/private/company.service"
import { getCities, getDeadReasons, updateProjectStatusByUUID } from '../../../services/public/public.service';
import PaginationFooter from '../User&Teams/PaginationFooter';
import { createDatewithTime } from '../../../helpers/date';
import PopUpModal from '../../../components/Modals/PopUpModal';
import SearchIcon from '../../../assets/icons/search.svg';
import { ReactComponent as SearchClosIoicn } from '../../../assets/icons/searchClose.svg';
import { ReactComponent as PluswhiteIcon } from '../../../assets/icons/Pluswhite.svg';
import { ReactComponent as ViewlistIcon } from '../../../assets/icons/Viewlist.svg';
import { ReactComponent as ViewgridIcon } from '../../../assets/icons/Viewgrid.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as LaunchIcon } from '../../../assets/icons/Launch.svg';
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/hrdotes.svg';
import { ReactComponent as Searchicon } from '../../../assets/icons/searchicon.svg';
import { ReactComponent as HomeWhiteIcon } from '../../../assets/icons/homeWhite.svg';
import { ReactComponent as Vthreedots } from '../../../assets/icons/threedots.svg';

import { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import style from './style.module.css';
import './style.css'
import FilterProjectsModal from '../../../components/Projects/Modal/FilterProjectsModal';
import MultiLevelDropdown from '../../../components/Projects/multi-level-dropdown';
import { useHistory, Link } from 'react-router-dom';
import FiltersListing from './filtersListing';
import { checkIsSomeItemAvailableInList, convertToTitleCase, numDifferentiation } from '../../../helpers/helpers';
import { Oval } from 'react-loader-spinner';
import Consumer from '../../../helpers/context';
import TooltipText from '../../../components/Tooltip';
import useDocumentTitle from '../../../custom hooks/useDocumentTitile';
import downArrow from "../../../assets/icons/downarrow.svg";
import { ReactComponent as DownArrawIcon } from '../../../assets/icons/downarrow.svg';

const ProjectStatusEnum = {
    active: 'ACTIVE',
    draft: 'DRAFT',
    published: 'PUBLISHED'
}

const StatusList = [
    {
        label: 'ACTIVE',
        value: 'active'
    },
    {
        label: 'DRAFT',
        value: 'draft'
    },
    {
        label: 'PUBLISHED',
        value: 'published'
    }

]

const getIntentClassName = (intent) => {
    switch (intent) {
        case 'active':
            return 'text-[#2064FA] bg-[#2064FA]/10';
        case 'published':
            return 'text-[#05A36F] bg-[#05A36F]/10';

        case 'draft':
            return 'text-[#FF974A] bg-[#FF974A]/10';
    }
}


const getProjectAddress = (project) => {
    const unitConfig = project.configurations
        .filter(item => item.unit_configuration)
        .map(item => item.unit_configuration)
    if (project.configurations?.length) {
        return `${unitConfig?.length ? unitConfig.join(', ') : ''} ${project.configurations[0].unit_type || ''} ${(project.city && project.state) ? `in ${project.city || ''}, ${project.state || ''}` : ''}`
    }
    // else {
    //     return `${project.configurations[0].unit_configuration ? project.configurations[0].unit_configuration : ''} ${project.configurations[0].unit_type || ''} ${(project.city && project.state) ? `in ${project.city || ''}, ${project.state || ''}` : ''}`
    // }
}

export default function Projects() {

    const [projects, setProjects] = useState();
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showDeleteProject, setShowDeleteProject] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showfilter, setShowfilter] = useState(false);
    const [viewMore, setviewMore] = useState(false)
    const [countPerPage, setCountPerPage] = useState({
        value: 10,
        label: "10/Page"
    });
    const [selectedProject, setSelectedProject] = useState(null);
    const [cities, setCities] = useState([])
    const [deleteProjectUUID, setDeleteProjectUUID] = useState('');
    const [showIntegrationPopup, setShowIntegrationPopup] = useState(false)
    const [integrationMsg, setIntegrationMsg] = useState('');
    const [projectPermission, setProjectPermission] = useState();
    const [searchText, setSearchText] = useState('');
    const [isGridViewActive, setGridViewActive] = useState(true);
    const [isListViewActive, setListViewActive] = useState(false);
    const [filters, setFilters] = useState({});
    const [allProjects, setAllProjects] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);

    let { allowedPermissions, isRolesModule } = useContext(Consumer);
    useDocumentTitle('Projects');
    const history = useHistory();

    const handleGridViewClick = () => {
        setGridViewActive(true);
        setListViewActive(false);
    };

    const handleListViewClick = () => {
        setGridViewActive(false);
        setListViewActive(true);
    };

    const removeFilterValue = (keyValue) => {
        // let oldFilters = filters;
        // let newFilters = []
        // for (const filterData of oldFilters) {
        //     // Check filter values and select element at pos 0 to compare with selected key
        //     if (!keyValue.includes(Object.keys(filterData)[0])) {
        //         newFilters.push(filterData);
        //     }
        // }
        // if (newFilters.length > 0) {
        //     localStorage.setItem('filter', JSON.stringify(newFilters))
        // } else {
        //     localStorage.removeItem('filter')
        // }
        // setFilters(newFilters);
    }

    useEffect(() => {
        if (isGridViewActive) {
            const timer = setTimeout(() => {
                document.getElementById('gridLayout').style.opacity = '1';
            }, 200);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isGridViewActive]);


    const getAllProjects = async () => {
        setLoading(true);
        await getProjects({ ...filters }, searchText).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                // console.log(res.data.data, 'data')
                let projects = [...res.data.data.projects];
                setProjectList(res.data.data.projects)
                const spliced = projects.splice(0, 12);
                setProjects(spliced);
                setAllProjects(projects)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        })
    }

    const getAllCities = async () => {
        getCities().then(res => {
            if (res.status === 200) {
                const cities = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        value: data.name
                    }
                })
                setCities(cities)
            }
        })
    }

    useEffect(() => {
        getAllProjects();
    }, [currentPage, countPerPage])

    const deleteProject = async () => {
        if (deleteProjectUUID) {
            await deleteProjectByUUID(deleteProjectUUID).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message)
                    getAllProjects();
                }
            }).catch((e) => {
                console.log({ e })
                toast.error(e.response.data.message);
            });
            setShowDeleteProject(false)
        }
    }

    const onCheckProjectIntergration = (uuid) => {

        getProjectIntegrations(uuid).then((res) => {
            setDeleteProjectUUID(uuid);
            setShowDeleteProject(true);
        }).catch((err) => {
            console.log(err.response.data)
            if (err.response.status === 400) {
                setIntegrationMsg(err.response.data.message);
                setShowIntegrationPopup(true);
            }
        })
    }
    const getUsersAuthRolePermissions = () => {
        getAuthRolePermissions().then((res) => {
            res.data.data.map((role) => {
                if (role.module === "projects") {
                    setProjectPermission(role.permissions)
                }
            })
        })
    }

    useEffect(() => {
        getAllProjects();
        getAllCities();
        getUsersAuthRolePermissions();
    }, [])

    useEffect(() => {
        getAllProjects();
    }, [searchText, filters]);

    const handelClearSearch = useCallback(() => {
        setSearchText('')
    }, []);

    const handleChangeProjectType = useCallback((property, transaction) => {
        if (property && transaction) {
            // localStorage.setItem('property_type', property);
            // localStorage.setItem('transaction_type', transaction)
            // setPropertyType(property);
            // setTransactionType(transaction);
            const param = {
                pt: property,
                tt: transaction
            }
            localStorage.setItem('projectStatus', 'new');
            history.push(`/projects/create-projects/basic-details?pt=${property}&tt=${transaction}`)
        }
    }, []);

    const handleApplyFilters = useCallback((filtersArr) => {
        setFilters(filtersArr);
        setShowfilter(false)
    }, [])

    const findMin = (config) => {
        let min = ''
        config.reduce((previous, current) => {
            if (current?.price && previous?.price) {
                min = current['price'] < previous['price'] ? current['price'] : previous['price'];
            }
            if (current?.unit_rent_price && previous?.unit_rent_price) {
                min = current['rent_price'] < previous['rent_price'] ? current['rent_price'] : previous['rent_price'];
            }
        });
        return min || ''
    }

    const findMax = (config) => {
        let max = ''
        config.reduce((previous, current) => {
            if (current?.price && previous?.price) {
                max = current['price'] > previous['price'] ? current['price'] : previous['price'];
            }
            if (current?.rent_price && previous?.rent_price) {
                max = current['rent_price'] > previous['rent_price'] ? current['rent_price'] : previous['rent_price'];
            }
        });

        return max || ''
    }

    const getConfigPrice = (config, project) => {

        if (config?.length === 0) return '-';
        if (config?.length === 1) {
            let price = config[0].price || config[0].rent_price
            return price ? `₹ ${numDifferentiation(price)}` : '-';
        }
        if (config?.length > 1) {
            let min = findMin(config);
            let max = findMax(config);
            let string = '';
            if (min) {
                string += '₹ ' + `${numDifferentiation(min)}`;
                if (max) {
                    string += ` - ${numDifferentiation(max)}`;
                }

            } else if (max) {
                string += '₹ ' + `${numDifferentiation(max)}`;
            }
            return string || '-';
        }
    }

    const handleStatusChange = async (option, uuid) => {
        try {
            const res = await updateProjectStatusByUUID(uuid, { status: option.value });
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getAllProjects()
            }
        } catch (err) {
            console.log({ err })
            toast.error(err?.response?.data?.message)
        }
    }

    const handleLoadMore = () => {
        let newList = [...allProjects];
        const spliced = newList.splice(0, 12);
        setProjects([...projects, ...spliced]);
        setAllProjects(newList);
    }

    return (
        <>
            {showProjectModal &&
                <AddProjectModal
                    setShow={setShowProjectModal}
                    show={showProjectModal}
                    selectedProject={selectedProject}
                    getAllProjects={getAllProjects}
                    cities={cities} />
            }

            {showIntegrationPopup && <PopUpModal
                setShow={setShowIntegrationPopup}
                show={showIntegrationPopup}
                heading={<>
                    Project cannot be deleted.
                </>}
                body={<div className=' p-3'>
                    <div className="mb-4">
                        {integrationMsg}
                    </div>
                    <div className="justify-content-end d-flex modal-footer">
                        <Button variant="" className="cancel" size="sm" onClick={() => setShowIntegrationPopup(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>}
            />}

            {showDeleteProject ? <PopUpModal
                setShow={setShowDeleteProject}
                show={showDeleteProject}
                heading={<>
                    Confirm Delete?
                </>}
                body={<div className=' p-3'>
                    <div className="mb-4">
                        Are you sure you want to delete project?
                    </div>
                    <div className="justify-content-end d-flex">
                        <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteProject(false)}>Cancel</button>
                        <button className="fw-po-medium del-btn px-16" onClick={deleteProject}>Confirm Delete</button>

                    </div>
                </div>}
            /> : null}

            <div className="all-pr d-flex flex-column flex-fill w-100 h-100 overflow-hidden">
                <div className="settings-title">
                    <div className="row d-flex flex-fill flex-wrap">
                        <div className="col-xl-4 d-flex flex-fill flex-row justify-content-between">
                            <h1 className="fz24 mb-0 black fw-po-medium inter">Project Manager {projectList?.length > 0 && `(${projectList?.length})`}</h1>
                            <div className={`${style.view_grid_listBtngrp} d-sm-none`}>
                                <button className={`${isGridViewActive ? style.active : ''}`} onClick={handleGridViewClick}
                                ><ViewgridIcon /></button>
                                <button className={`${isListViewActive ? style.active : ''}`} onClick={handleListViewClick}><ViewlistIcon /></button>
                            </div>
                        </div>
                        <div className="col-xl-8 justify-content-md-end d-flex align-items-center align-items-end gap-12 mt-xl-0 mt-3">
                            <div className={`form-group position-relative search-input ${searchText ? 'input-active' : ''} `}>
                                <div className="serch-icon">
                                    <img src={SearchIcon} className="" /></div>
                                <input type="text" placeholder="Search Project" onChange={(e) => setSearchText(e.target.value)} className="searchinput" value={searchText} />
                                {searchText &&
                                    <div className="close-icon" onClick={handelClearSearch} style={{ top: '12px' }}>
                                        <SearchClosIoicn />
                                    </div>}
                            </div>
                            {/* <div>
                                <button
                                    className={`${style.filter_btn} ${style.filter_btn_active} inter fz14 fw-po-medium black-dark-700 inline-flex items-center`}
                                    onClick={() => setShowfilter(true)}
                                >
                                    <FilterIcon />
                                    Filter ({Object.keys(filters).length})
                                </button>
                            </div> */}
                            <div className={`${style.view_grid_listBtngrp} d-sm-inline d-none`}>

                                <button className={`${isGridViewActive ? style.active : ''} flex items-center`} onClick={handleGridViewClick}
                                >
                                    <ViewgridIcon className='fill-[#DEDEDE]' />
                                    Grid View
                                </button>

                                <button className={`${isListViewActive ? style.active : ''} flex items-center`} onClick={handleListViewClick}>
                                    <ViewlistIcon className='fill-[#92929D]' />
                                    List View
                                </button>

                            </div>
                            {((!isRolesModule && projectPermission?.includes('write')) || allowedPermissions?.includes("create_project")) &&
                                <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                            }
                        </div>
                    </div>
                </div>
                {/* filters */}
                <FiltersListing filters={filters} />

                <div className={`${style.all_project_table} all-project-table d-flex flex-fill flex-col overflow-auto  pl-24 pt-20 w-100`}>
                    <div className='w-100 h-100'>
                        {isGridViewActive &&
                            <>
                                < div className={`${style.cardcenterview}`}>
                                    <div className={`${style.project_grid_layout}`} id='gridLayout' style={{ opacity: '0', transition: 'opacity 0.5s ease' }}
                                    >
                                        {projects?.map((project, index) => {
                                            const { uuid, name, developer, area, city, state, district, zip_code, site_address, updated_on } = project
                                            return (
                                                // <Link to={(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project']) ||
                                                //     checkIsSomeItemAvailableInList(projectPermission, ['read', 'write'])) ?
                                                //     `/project-launchpad/details/${uuid}` : ''}
                                                //     className={`${style.girdProjectCard}`}
                                                //     key={index}
                                                // >
                                                <div className={`${style.girdProjectCard} relative `}
                                                    key={index}
                                                >
                                                    <figure className='position-relative mb-16'>
                                                        {project.developer_created &&
                                                            <div className={`${style.CardLaunchIcon}`}>
                                                                <LaunchIcon className='mr-8' />
                                                            </div>
                                                        }
                                                        {/* <div className={`${style.cardNewupdate} ${style.newupdate}`}>New Update</div> */}
                                                        <div className={`${style.projectcardMenu} view-more dropdown projectcardMenu`}>
                                                            {(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project', 'delete_project']) || checkIsSomeItemAvailableInList(projectPermission, ['read', 'write'])) &&
                                                                <TooltipText title='View Option'>
                                                                    <button className="bg-tr border-0 d-table ml-auto hover:bg-[#E5E9EB] cursor-pointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon className='hover:bg-[#E5E9EB]' />
                                                                    </button>
                                                                </TooltipText>
                                                            }
                                                            <div className="dropdown-menu dropdown-menu-right w-auto" aria-labelledby="dropdownMenuButton">
                                                                {(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project']) || checkIsSomeItemAvailableInList(projectPermission, ['read', 'write'])) &&
                                                                    <Link to={`/project-launchpad/details/${uuid}`} className="dropdown-item text-capitalize fz12">
                                                                        View Project
                                                                    </Link>}
                                                                {/* <a className="dropdown-item text-capitalize fz12">View Insights</a> */}
                                                                {((!isRolesModule && projectPermission?.includes('write')) || checkIsSomeItemAvailableInList(allowedPermissions, ['create_project', 'edit_project'])) &&
                                                                    <a className="dropdown-item text-capitalize fz12 fw-po-medium lh-24" onClick={
                                                                        () => {
                                                                            // setSelectedProject({ uuid, name, developer, area, city, state, district, zip_code, site_address, updated_on });
                                                                            // setShowProjectModal(true)
                                                                            localStorage.setItem('projectStatus', 'edit');
                                                                            localStorage.setItem('projectName', name);
                                                                            history.push(`/projects/create-projects/basic-details/${uuid}?pt=${project.property_type}&tt=${project.transaction_type}`)
                                                                        }
                                                                    }>Edit Project</a>}
                                                                {((!isRolesModule && projectPermission?.includes('write')) || allowedPermissions.includes('delete_project')) &&
                                                                    <a className="dropdown-item del-menu text-capitalize fz12 red-color fw-po-medium lh-24" onClick={() => { onCheckProjectIntergration(uuid) }}>
                                                                        Delete Project</a>}
                                                            </div>
                                                        </div>
                                                        {project?.media?.length && <img src={project?.media[0].url} alt="" className='w-100' />}
                                                        {!project?.media?.length &&
                                                            <div className={`${style.cardthumbnail} d-flex align-items-center justify-content-center`}>
                                                                <HomeWhiteIcon />
                                                            </div>
                                                        }
                                                        {project?.possession_type && <div className={`${style.positondate}`}>
                                                            {
                                                                project.possession_type === 'ready_to_move_in' ? 'Ready to move in' :
                                                                    project.possession_type === 'Under_Construction' ? ` Possession: ${moment(project.possession_date).format('MMM YYYY')}` : ''
                                                            }

                                                        </div>}
                                                    </figure>
                                                    {/* <div className={` view-more dropdown w-full left-0 z-10 !mb-2`}>
                                                        <button
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            className={`${getIntentClassName(project.status)} ${!project.status ? 'border' : ''} rounded-xl p-2 w-full flex  justify-between items-center  `}
                                                        >
                                                            {project.status ? `Status: ${ProjectStatusEnum[project.status]}` : 'Select Status'}                                                            
                                                            <DownArrawIcon className=' fill-black ' />
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {
                                                                StatusList.map((option, index) => {
                                                                    return (

                                                                        <a tabIndex={''}
                                                                            key={index}
                                                                            className={`dropdown-item  text-capitalize`}
                                                                            onClick={() => {
                                                                                handleStatusChange(option, project.uuid)
                                                                            }}
                                                                        >{option.label}
                                                                        </a>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div> */}
                                                    {project.property_type && <div className={`${style.proparty_type} text-capitalize`}>
                                                        {project.property_type}  {project.transaction_type === 'rent' ? ` - ${project.transaction_type}` : ''} {project.inventory_type && ` - ${convertToTitleCase(project.inventory_type || '')}`}
                                                    </div>}
                                                    {name?.length > 30 ? <TooltipText title={name}>
                                                        <h1 className='black inter fz18 fw-po-semi mb4 lh-22 text-capitalize w-[300px] truncate'>
                                                            {name}
                                                        </h1>
                                                    </TooltipText> :
                                                        <h1 className='black inter fz18 fw-po-semi mb4 lh-22 text-capitalize'>
                                                            {name}
                                                        </h1>
                                                    }
                                                    <p className='fz12 mb4 lh-16 black-dark-600 inter text-capitalize'>
                                                        {
                                                            project.configurations ?
                                                                getProjectAddress(project)
                                                                : '-'
                                                        }
                                                    </p>
                                                    <p className='fz14 lh-24 mb-0 fw-po-semi text-black'>
                                                        {
                                                            getConfigPrice(project?.configurations, project)
                                                        }
                                                    </p>

                                                    {(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project']) || checkIsSomeItemAvailableInList(projectPermission, ['read', 'write']))
                                                        &&
                                                        <Link to={`/project-launchpad/details/${uuid}`} className={`${style.cardviewProjectbtn}`}></Link>}
                                                </div>
                                                /* </Link> */
                                            )
                                        })
                                        }
                                    </div >
                                    {
                                        allProjects?.length > 6 ? <div className={`${style.loadMorediv}`}>
                                            <button className={`${style.loadMore}`} onClick={handleLoadMore}> Load More</button>
                                        </div> : ''
                                    }

                                </div>
                                {/* {allProjects?.length === 0 &&
                                    <div className='d-flex justify-content-center flex-column text-center h-100'>
                                        <div>
                                            <figure><Searchicon /></figure>
                                            <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Projects Added</h2>
                                            <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add projects to see them here</p>
                                            <button className={`${style.addnewprojectbtn}`}>+ New Project</button>

                                        </div>
                                    </div>
                                } */}
                            </>
                        }
                        {
                            isListViewActive && projectList?.length > 0 && <div className={`${style.projectlistdeftable} def-table pr-24 h-100 pb-0`}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Project Name</th>
                                            <th>Developer</th>
                                            <th>Property Type</th>
                                            <th>Inventory Type</th>
                                            {/* <th>Last Updated</th> */}
                                            <th>Transaction Type</th>
                                            <th>City</th>
                                            {/* <th>Sale Type</th> */}
                                            <th>Project Address</th>
                                            <th>Price</th>
                                            <th>Created Date</th>
                                            <th>Updated Date</th>
                                            {/* <th>Status</th>
                                            <th>Permalink</th> */}
                                            <th className='sticky -right-[10px]'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectList.map((project, index) => {
                                            const { uuid, name, developer, area, city, state, district, zip_code, site_address, updated_on } = project
                                            return (
                                                <tr key={index}>
                                                    <td>{((currentPage - 1) * countPerPage.value) + index + 1}</td>
                                                    <td className=''>
                                                        {name?.length > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className='text-capitalize'>{name}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >

                                                                <div className='d-inline-flex align-items-center gap-8'>
                                                                    {project.developer_created && <LaunchIcon className='' />}
                                                                    <Link to={`/project-launchpad/details/${uuid}`} className='pr-hover black three-dots'>{name}</Link>
                                                                    {/* <span className={`${style.newupdate} ml-0`}>New Update</span> */}
                                                                </div>
                                                            </OverlayTrigger>
                                                            :
                                                            <>
                                                                {project.developer_created && <LaunchIcon className='mr-8' />}
                                                                <Link to={`/project-launchpad/details/${uuid}`} className='pr-hover black'>{name}</Link>
                                                                {/* <span className={`${style.newupdate}`}>New Update</span> */}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-capitalize'>
                                                        {developer?.length > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className='text-capitalize'>{developer}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='text-capitalize three-dots'>{developer || '-'}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <span className='text-capitalize'>{developer || '-'}</span>
                                                        }
                                                    </td>
                                                    <td className='text-capitalize'>{project.property_type || '-'}</td>
                                                    <td>{convertToTitleCase(project.inventory_type || '-')}</td>
                                                    {/* <td>-</td> */}
                                                    <td className='text-capitalize'>{project.transaction_type || '-'}</td>
                                                    <td>
                                                        {(project.city?.length + project.state?.length) > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className='text-capitalize'>{project.city && project.state ? `${project.city}, ${project.state}` : ''}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='text-capitalize three-dots'>{project.city && project.state ? `${project.city}, ${project.state}` : ''}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <span className='text-capitalize'>{project.city && project.state ? `${project.city}, ${project.state}` : '-'}</span>
                                                        }

                                                    </td>

                                                    <td>
                                                        {project?.google_address?.length > 20 ?
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip" >
                                                                        <div className='info-msg'>
                                                                            {/* <h4>Total Leads</h4> */}
                                                                            <p className='text-capitalize'>{project.google_address}</p>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='text-capitalize three-dots'>{project.google_address}</span>
                                                            </OverlayTrigger>
                                                            :
                                                            <span className='text-capitalize'>{project.google_address || '-'}</span>
                                                        }
                                                    </td>
                                                    <td>{getConfigPrice(project?.configurations, project) || '-'}</td>
                                                    <td>{createDatewithTime(new Date(project.created_on))}</td>

                                                    <td>{createDatewithTime(new Date(updated_on))}</td>
                                                    {/* <td>
                                                        <button
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            className={`${getIntentClassName(project.status)} ${!project.status ? 'border' : ''} rounded-xl p-2 flex `}
                                                        >                                                            
                                                            {project.status ? `Status: ${ProjectStatusEnum[project.status]}` : 'Select Status'}
                                                            <Image src={downArrow} />
                                                        </button>

                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            {
                                                                StatusList.map((option, index) => {
                                                                    return (

                                                                        <a tabIndex={''}
                                                                            key={index}
                                                                            className={`dropdown-item  text-capitalize`}
                                                                            onClick={() => {
                                                                                handleStatusChange(option, project.uuid)
                                                                            }}
                                                                        >{option.label}
                                                                        </a>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{project?.permalink || '-'}</td> */}
                                                    <td className='dropdown view-more'>

                                                        {(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project']) ||
                                                            checkIsSomeItemAvailableInList(projectPermission, ['read', 'write'])) &&
                                                            <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <TooltipText title='View Option' placement='left'>
                                                                    <Vthreedots />
                                                                </TooltipText>
                                                            </button>
                                                        }
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            {(checkIsSomeItemAvailableInList(allowedPermissions, ['view_project', 'view_all_projects', 'create_project', 'edit_project', 'delete_project']) ||
                                                                checkIsSomeItemAvailableInList(projectPermission, ['read', 'write'])) &&
                                                                <Link to={`/project-launchpad/details/${uuid}`} className="dropdown-item text-capitalize">
                                                                    View Project
                                                                </Link>}
                                                            {((!isRolesModule && projectPermission?.includes('write')) || checkIsSomeItemAvailableInList(allowedPermissions, ['create_project', 'edit_project'])) && <a className="dropdown-item text-capitalize" onClick={
                                                                () => {
                                                                    localStorage.setItem('projectStatus', 'edit');
                                                                    localStorage.setItem('projectName', name);
                                                                    history.push(`/projects/create-projects/basic-details/${uuid}?pt=${project.property_type}&tt=${project.transaction_type}`)
                                                                }
                                                            }>Edit Project</a>}
                                                            {((!isRolesModule && projectPermission?.includes('write')) || allowedPermissions.includes('delete_project')) &&
                                                                <a className="dropdown-item del-menu text-capitalize"
                                                                    onClick={() => { onCheckProjectIntergration(uuid) }}>
                                                                    Delete
                                                                </a>}
                                                        </div>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {loading &&
                            <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                                <Oval
                                    height={30}
                                    width={30}
                                    color="#0062ff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#0062ff"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />

                            </div>}
                        {searchText && projectList?.length === 0 &&
                            <div className='d-flex justify-content-center flex-column text-center h-100'>
                                <div className='flex justify-center flex-column items-center'>
                                    <figure><Searchicon /></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">Didn’t find any matches </h2>
                                    <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                                        <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>
                                    {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                    {/* {projectPermission?.includes('write') &&
                                     <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                 } */}
                                </div>

                            </div>}
                        {!searchText && projectList?.length === 0 &&
                            <div className='flex justify-center flex-column text-center h-100'>
                                <div className='flex justify-center flex-column items-center'>
                                    <figure><Searchicon /></figure>
                                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Projects Added</h2>
                                    <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>Please add projects to see them here</p>
                                    {/* <button className={`${style.addnewprojectbtn}`}>+ New Project</button> */}
                                    {(projectPermission?.includes('write') || allowedPermissions?.includes("create_project")) &&
                                        <MultiLevelDropdown handleChangeProjectType={handleChangeProjectType} style={style} />
                                    }
                                </div>
                            </div>
                        }

                    </div >
                </div >
            </div >
            <FilterProjectsModal show={showfilter} closeModal={() => setShowfilter(false)} handleApplyFilters={handleApplyFilters} style={style} />
        </>
    )
}

