import makeRequest, { formRequest } from "./api/makeRequest";
import url from "../services/api/urls";
import { RequstMethods } from "../services/api/requestMethods";

export const guideServices = {
    async getAllGuides() {
        return await makeRequest(url.learning_center.guide.get, RequstMethods.GET);
    },

    async insertNewGuide(payload) {
        return await makeRequest(url.learning_center.guide.insert, RequstMethods.POST, payload);
    },
    async update(uuid, payload) {
        return await makeRequest(`${url.learning_center.guide.update}/${uuid}`, RequstMethods.PUT, payload);
    },
    async updateStatus(uuid, payload) {
        return await makeRequest(`${url.learning_center.guide.updateStatus}/${uuid}`, RequstMethods.PUT, payload);
    },

    async uploadGuides(payload) {
        return await makeRequest(url.learning_center.guide.upload, RequstMethods.POST, payload);
        
    },
   
}